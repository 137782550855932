// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import PensionCard from "../Card/pensionCard";
import PensionInput from "../InputContainer/PensionInput";

// Import Connection Image (if exists)
import pensionConnectionImage from "../../Assets/_optionDescriptionIcons/Income, Retirement, Pension.png";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";
import bigComingSoonImage from "../../Assets/_optionDescriptionIcons/bigComingSoon.png";
import comingSoonImage from "../../Assets/_optionDescriptionIcons/comingSoon.png";

// Import Coloured PNGs
import pensionBlack from "../../Assets/_eventNodeTypes/pension-black.png";
import pensionBlue from "../../Assets/_eventNodeTypes/pension-blue.png";
import pensionWhite from "../../Assets/_eventNodeTypes/pension-white.png";
import pensionGrey from "../../Assets/_eventNodeTypes/pension-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/RetirementPlanning2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - pensionObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - pensionObject.checkInput.call(pensionObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const pensionObject = {
    constant: function () {
        return Pension_Constant();
    },

    checkInput: function (state) {
        return Pension_CheckInput(state);
    },

    svg: function () {
        return Pension_Svg();
    },

    eventNodeMenu: function (focus) {
        return Pension_EventNodeMenu(focus);
    },

    indexText: function () {
        return Pension_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Pension_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Pension_OptionDescription();
    },

    colouredPngs: function () {
        return Pension_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Pension_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Pension_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.person) return false;
    if (state.pensionCalculationType === "estimates") {
        if (!state.contributionType) return false;
        if (!state.startAge) return false;
        if (!state.pensionableRate) return false;
    }
    if (state.pensionCalculationType === "actuals") {
        if (!state.actualsData) return false;
    }
    return true;
};

// EFFECTS: Returns constant for event
const Pension_Constant = () => {
    return "Pension";
};

// EFFECTS: Returns black png / svg for event
const Pension_Svg = () => {
    return pensionBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Pension_EventNodeMenu = (focus) => {
    return <PensionCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Pension_IndexText = () => {
    return "Select an input field to learn more about your pension calculations.";
};

// EFFECTS: Returns all icons for event ??
const Pension_Icons = () => {
    let pngs = new Map([
        ["black", pensionBlack],
        ["blue", pensionBlue],
        ["white", pensionWhite],
        ["grey", pensionGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Pension_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Pension_InputComponentInfoPassing = (minProps) => {
    return <PensionInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, pensions for event ??
const Pension_OptionDescription = () => {
    const tags = ["Option"];
    const type = pensionObject.constant();
    const description = `The Canada Pension Plan (CPP) retirement pension is a monthly, taxable benefit that replaces part of your income when you retire. If you qualify, you’ll receive the CPP retirement pension for the rest of your life. 

    To qualify, you must: 
    - be at least 60 years old 
    - have made at least one valid contribution to the CPP  

    Valid contributions can be either from work you did in Canada, or as the result of receiving credits from a former spouse or former common-law partner at the end of the relationship.`;
    const usedForText =
        "Pension Node can calculate estimated amount of Retirement Pension for you based on your CPP and QPP contribution, the age you decide to start your pension and your average earnings throughout working life.";
    const connection = "Connect one or more Person Node before Pension Node.";
    const connectionImage = pensionConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/services/benefits/publicpensions/cpp.html";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
        {
            id: 1,
            name: "Refinance Your Home",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 2,
            name: "Purchasing an Income Property",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 3,
            name: "Relocating",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 4,
            name: "Adding a Rental Suite",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 5,
            name: "Listing on AirBnB",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Pension_SideModalImage = () => {
    return sideImage;
};

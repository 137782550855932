import UserScenarios from "../UserScenarios";
import { useSpring, animated } from "react-spring";

export default function ScenariosPanel(props) {
    const containerAnimation = useSpring({
        to: { width: props.showScenarios ? 350 : 0 },
    });

    return (
        <animated.div className="Scenarios" style={containerAnimation}>
            <UserScenarios
                toggleScenarios={props.toggleScenarios}
                showScenarios={props.showScenarios}
                openLogin={props.openLogin}
                history={props.history}
                setNodeGraphState={props.setNodeGraphState}
                promptScenarioCreation={props.promptScenarioCreation}
                sampleScenarioCreation={props.sampleScenarioCreation}
                promptScenarioEdit={props.promptScenarioEdit}
                sampleScenarioEdit={props.sampleScenarioEdit}
                togglePromptScenarioCreation={
                    props.togglePromptScenarioCreation
                }
                toggleSampleScenarioCreation={
                    props.toggleSampleScenarioCreation
                }
                setSampleScenarioCreation={props.setSampleScenarioCreation}
                togglePromptScenarioEdit={props.togglePromptScenarioEdit}
                setSampleScenarioEdit={props.setSampleScenarioEdit}
                focus={props.focus}
                showDropdown={props.showDropdown}
                rightDisplayContent={props.rightDisplayContent}
            />
        </animated.div>
    );
}

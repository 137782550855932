//@ts-nocheck

import React from "react";
import {
    Description,
    InputButtons,
    EventDetails,
    SingleEntity,
    EventHeader,
    ModalRow,
    SelectDropDown,
    ModalRowHalf,
    ToggledContainer,
    ToggledContainerHalf,
    Value,
    EventEntityWrapper,
    SelectEntities,
    MuiCalendar,
} from "../Components";
import { cacObject } from "../../Registry/CAC";
import Switch from "react-switch";

const CustomerAcquisitionCostInputView = ({
    entitiesMap,
    currentEntity,
    onChangeNameDescription,
    toggleInheritStartDate,
    handleChangeInterpolationType,
    getDynamicPanel,
    onHandleSubmit,
    edit,
    passedCheck,
    eventData,
    handleOnChange,
    handleDateSelection,
}) => {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={cacObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={cacObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.data
                                    ?.selectedCustomer ?? {}
                            }
                            nodes={Object.values(
                                entitiesMap?.[currentEntity]?.data?.customers ||
                                []
                            )}
                            className="mlsInput"
                            id="customer"
                            label="Select Customer"
                            groupEventType="Customer"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow>
                    <Switch
                        id="inheritStartDate"
                        checked={
                            entitiesMap?.[currentEntity]?.data.inheritStartDate
                        }
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={toggleInheritStartDate}
                    />
                    <div className="InflationText">
                        Inherit Start Date from Customer
                    </div>
                </ModalRow>
                {!entitiesMap?.[currentEntity]?.data.inheritStartDate && (
                    <ModalRow>
                        <ModalRowHalf>
                            <MuiCalendar id="startDate" value={entitiesMap?.[currentEntity]?.startDate} required={true} onChangeInput={handleDateSelection} label="Custom Date" />
                        </ModalRowHalf>
                    </ModalRow>
                )}
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="constant"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data.subType ===
                            "constant"
                        }
                        onClick={handleChangeInterpolationType}
                        title="Constant Cost"
                    />
                    <ToggledContainerHalf
                        id="dynamic"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data.subType ===
                            "dynamic"
                        }
                        onClick={handleChangeInterpolationType}
                        title="Dynamic Costs"
                    />
                </ToggledContainer>
                {entitiesMap?.[currentEntity]?.data.subType === "constant" ? (
                    <ModalRow>
                        <ModalRowHalf>
                            <Value
                                label="Estimated CAC"
                                onChangeInput={handleOnChange}
                                value={
                                    entitiesMap[currentEntity].data
                                        .constantValue
                                }
                                id="constantValue"
                                className="mlsInput"
                                sign={true}
                                unit="$"
                                required={true}
                            />
                        </ModalRowHalf>
                    </ModalRow>
                ) : (
                    <>
                        {entitiesMap[currentEntity].data.subType ===
                            "dynamic" && (
                                <ModalRow twoInputs>
                                    <ModalRow>
                                        <SelectDropDown
                                            onChangeInput={handleOnChange}
                                            value={
                                                entitiesMap[currentEntity].data
                                                    .interpolateSubType
                                            }
                                            options={[
                                                "Interpolate Costs",
                                                "Step Function Costs",
                                            ]}
                                            className="mlsInput"
                                            label="Select a Dynamic Cost Type"
                                            id="interpolateSubType"
                                            required={true}
                                        />
                                    </ModalRow>
                                </ModalRow>
                            )}
                        {
                            <ModalRow>
                                <div className="PTTLabel">
                                    Specified Values:
                                </div>
                            </ModalRow>
                        }
                        {getDynamicPanel()}
                    </>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default CustomerAcquisitionCostInputView;

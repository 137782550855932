// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    ToggledContainer,
    ToggledContainerHalf,
    InflationInput,
    EventEntityWrapper,
    ModalRowHalf,
    SelectDropDown,
    Value,
    CheckBox,
    ModalRowOneThird,
    Rate,
} from "../Components";
import { unitCostObject } from "Components/Registry/Unit Cost";

const options = [
    "annually",
    "semi-annually",
    "quarterly",
    "monthly",
    // "semi-monthly",
    // "bi-weekly",
    // "weekly",
    "one-time",
];

export default function UnitCostInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    updateInflation,
    onHandleSubmit,
    handleOnChange,
    handleChangeUnitCostType,
    isExpense,
    edit,
    passedCheck,
    eventData,
    toggleIncludeCostOfGoodsSold,
    handleEntityStateChange,
}) {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={unitCostObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={unitCostObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[unitCostObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="Revenue"
                        isSelected={!isExpense}
                        onClick={handleChangeUnitCostType}
                        title="Revenue"
                    />
                    <ToggledContainerHalf
                        id="Expense"
                        isSelected={isExpense}
                        onClick={handleChangeUnitCostType}
                        title="Expense"
                    />
                </ToggledContainer>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap[currentEntity].cadence}
                            options={options}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Payment Frequency"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <Value
                            label="Value"
                            onChangeInput={handleOnChange}
                            value={entitiesMap[currentEntity].data.cost}
                            id="value"
                            unit="$"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                {!isExpense && (
                    <>
                        <ModalRow>
                            <CheckBox
                                label="Include Cost of Goods Sold"
                                toggleIsActive={toggleIncludeCostOfGoodsSold}
                                id="includeCostOfGoodsSold"
                                isActive={
                                    entitiesMap[currentEntity].data
                                        .includeCostOfGoodsSold
                                }
                            />
                        </ModalRow>
                        <ModalRow twoInputs>
                            {entitiesMap[currentEntity].data
                                .includeCostOfGoodsSold && (
                                    <>
                                        <ModalRowOneThird>
                                            <Value
                                                onChangeInput={handleOnChange}
                                                value={
                                                    entitiesMap[currentEntity].data
                                                        .costOfGoodsSold
                                                }
                                                id="costOfGoodsSold"
                                                label="Cost of Goods Sold"
                                                unit="$"
                                                required={true}
                                            />
                                        </ModalRowOneThird>
                                        <ModalRowOneThird>
                                            <Value
                                                onChangeInput={handleOnChange}
                                                value={
                                                    entitiesMap[currentEntity].data
                                                        .margin
                                                }
                                                id="margin"
                                                label="Margin"
                                                unit="$"
                                                required={true}
                                            />
                                        </ModalRowOneThird>
                                        <ModalRowOneThird>
                                            <Rate
                                                label="Margin"
                                                onChangeInput={handleOnChange}
                                                value={
                                                    entitiesMap[currentEntity].data
                                                        .marginPercent
                                                }
                                                id="marginPercent"
                                                className="mlsInput"
                                                sign={true}
                                                required={true}
                                            />
                                        </ModalRowOneThird>
                                    </>
                                )}
                        </ModalRow>
                    </>
                )}
                <ModalRow>
                    <InflationInput
                        applyInflation={
                            entitiesMap[currentEntity].data.inflation
                        }
                        inflationRate={
                            entitiesMap[currentEntity].data.inflationRate
                        }
                        onChangeInput={handleOnChange}
                        toggleInflation={updateInflation}
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
                updateValues={onHandleSubmit}

            // Library code - not implemented, not sure if it ever will be
            // None of these props are passed in so everything below is undefined

            // isAddingLibraryEvent={this.props.isAddingLibraryEvent}
            // isLibraryEvent={this.state.isLibraryEvent}
            // addLibraryEventToScenario={() => {
            //     this.props.addLibraryEventToScenario(this.state);
            // }}
            // divorceLibraryEvent={() => {
            //     this.props.divorceFromLibraryEvent(
            //         this.state,
            //         this.props.updateValues
            //     );
            // }}
            />
        </EventEntityWrapper>
    );
}

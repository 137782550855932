import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { camelCase } from "lodash";

/*
 * Helper function that handles Unit Cost Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
const UnitCostInputsHandler = (
    value: string,
    id:
        | "value"
        | "url"
        | "entityName"
        | "cadence"
        | "rating"
        | "inflationRate"
        | "costOfGoodsSold"
        | "margin"
        | "marginPercent",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "value":
            const multiplier = data.unitCostType === "Expense" ? -1 : 1;
            data.cost = value;
            data.value =
                multiplier * Math.abs(Number(Number(value).toFixed(2)));
            currentEntityObject.data = data;
            // Logic for updating cost of goods sold fields
            if (value && data.costOfGoodsSold) {
                data.margin = data.value - Number(data.costOfGoodsSold);
                data.marginPercent = cogsToMarginPercent(
                    Number(data.costOfGoodsSold),
                    data.value
                );
            }
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "url":
            data.url = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            const finalString = camelCase(value);
            data.tag = `@${finalString}`;
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inflationRate":
            data.inflationRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "costOfGoodsSold":
            data.costOfGoodsSold = value;
            if (value && data.value) {
                data.margin = data.value - Number(value);
                data.marginPercent = cogsToMarginPercent(
                    Number(value),
                    data.value
                );
            }
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "margin":
            if (data.costOfGoodsSold && data.value) {
                data.margin = value;
                const marginCogs = data.value - Number(value);
                data.costOfGoodsSold = marginCogs.toString();
                data.marginPercent = cogsToMarginPercent(
                    marginCogs,
                    data.value
                );
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "marginPercent":
            if (data.costOfGoodsSold && data.value) {
                data.marginPercent = value;
                const cogs = marginPercentToCogs(Number(value), data.value);
                data.costOfGoodsSold = cogs.toFixed(2);
                data.margin = (data.value - cogs).toFixed(2);
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;

        default:
        // Noop
    }

    return newEntitiesMap;
};

const marginPercentToCogs = (percent: number, revenue: number) => {
    return (1 - percent / 100) * revenue;
};

const cogsToMarginPercent = (cogs: number, revenue: number) => {
    return Number((100 * (1 - cogs / revenue)).toFixed(2));
};

export default UnitCostInputsHandler;

// Default Imports
import React from "react";

// Import Event Card and Event Input
import CACCard from "../Card/cacCard";

// Import Connection Image (if exists)
import CACConnectionImage from "Assets/_optionDescriptionIcons/CAC white.jpg";

// Import Coloured PNGs
import cacBlack from "../../Assets/_eventNodeTypes/cac-black-updated.svg";
import cacBlue from "../../Assets/_eventNodeTypes/cac-blue.png";
import cacWhite from "../../Assets/_eventNodeTypes/cac-white.png";
import cacGrey from "../../Assets/_eventNodeTypes/cac-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import { CustomerAcquisitionCostInputExperimental } from "Components/InputContainer/CustomerAcquisitionExperimental/CustomerAcquisitionCostInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - cacObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - cacObject.checkInput.call(cacObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const cacObject = {
    constant: function () {
        return CAC_Constant();
    },

    name: function () {
        return "Customer Acquisition Cost";
    },

    checkInput: function (state) {
        return CAC_CheckInput(state);
    },

    svg: function () {
        return CAC_Svg();
    },

    eventNodeMenu: function (focus) {
        return CAC_EventNodeMenu(focus);
    },

    indexText: function () {
        return CAC_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return CAC_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return CAC_OptionDescription();
    },

    colouredPngs: function () {
        return CAC_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return CAC_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return CAC_GenerateDisplayFieldData();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const CAC_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (
            !entity.name ||
            !entity.data.customerEventId ||
            !entity.data.subType
        ) {
            return false;
        }
        if (!entity.data.inheritStartDate && !entity.startDate) {
            return false;
        }
        if (entity.data.subType == "constant" && !entity.data.constantValue) {
            return false;
        }
        if (
            entity.data.subType == "dynamic" &&
            entity.data.specificPercentages?.length < 2
        )
            return false;
        if (
            entity.data.subType == "dynamic" &&
            (!entity.data.interpolateSubType ||
                !entity.data.specificPercentages.reduce(function (acc, obj) {
                    return acc && obj.month && obj.year && obj.value;
                }, true))
        ) {
            return false;
        }
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const CAC_Constant = () => {
    return "852d13ea-ecbf-4d7d-9f5d-a2e9e7d744ce";
};

// EFFECTS: Returns black png / svg for event
const CAC_Svg = () => {
    return cacBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const CAC_EventNodeMenu = (focus) => {
    return <CACCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const CAC_IndexText = () => {
    return "The CAC Event produces Cashflow based on Customer Growth and the estimated cost to acquire a single customer.";
};

// EFFECTS: Returns all icons for event ??
const CAC_Icons = () => {
    let pngs = new Map([
        ["black", cacBlack],
        ["blue", cacBlue],
        ["white", cacWhite],
        ["grey", cacGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const CAC_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const CAC_InputComponentInfoPassing = (minProps) => {
    return <CustomerAcquisitionCostInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, CACs for event ??
const CAC_OptionDescription = () => {
    const tags = ["Option"];
    const type = cacObject.name();
    const description =
        "CAC, or Customer Acquisition Cost, is a common KPI. and represents the cost of acquiring a new customer. This can include sales, marketing costs, overhead and the salaries of sales focussed employees. While CAC can be calculated, this Event instead use manually entered values that are based on industry estimates or a client’s historical CAC.";
    const usedForText =
        "Use the Customer Acquisition Event as part of a customer signup, growth, churn, and conversion funnel. CAC will be recognized as an Expense and offset the Revenue generated from these customers.";
    const connection =
        "The CAC Event is commonly used alongside the Customer, Customer Growth, Customer Churn and Unit Cost Events.";
    const connectionImage = CACConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const CAC_SideModalImage = () => {
    return sideImage;
};

const CAC_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

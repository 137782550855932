// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    EntityName,
    ModalRowHalf,
    SelectDropDown,
    ToggledContainer,
    ToggledContainerHalf,
    SelectionBox,
} from "../Components";
import { groupObject } from "Components/Registry/Group";

export default function GroupInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleChangeGroupMode,
    handleChangeEventType,
    handleEntityStateChange,
}) {
    const EventOptions = [
        "Contract",
        "Customer",
        "Debit Credit",
        "Debt",
        "Debt Repayment",
        "Employee",
        "Expense",
        "Income",
        "Loan",
        "Revenue",
        "Unit Cost",
        "Unit Adjustment",
    ];

    const groupMode = entitiesMap?.[currentEntity]?.data.groupMode;

    function isEventSelected(id) {
        const selectedEvents =
            entitiesMap?.[currentEntity]?.data.selectedEvents;
        return selectedEvents && selectedEvents.includes(id);
    }

    function isEntitySelected(id) {
        const selectedEntities =
            entitiesMap?.[currentEntity]?.data.selectedEntities;
        return selectedEntities && selectedEntities.includes(id);
    }

    function getEarliestStartDate(entities) {
        let earliestStartDate = "";

        if (!entities) {
            return earliestStartDate;
        }

        entities.forEach((entity) => {
            if (entity.startDate) {
                if (earliestStartDate) {
                    if (
                        new Date(entity.startDate) < new Date(earliestStartDate)
                    ) {
                        earliestStartDate = entity.startDate;
                    }
                } else {
                    earliestStartDate = entity.startDate;
                }
            }
        });

        return earliestStartDate;
    }

    function getLatestEndDate(entities) {
        let latestEndDate = "";

        if (!entities) {
            return latestEndDate;
        }

        entities.forEach((entity) => {
            if (entity.endDate) {
                if (latestEndDate) {
                    if (new Date(entity.endDate) > new Date(latestEndDate)) {
                        latestEndDate = entity.endDate;
                    }
                } else {
                    latestEndDate = entity.endDate;
                }
            }
        });

        return latestEndDate;
    }

    const getEventName = (id) => {
        const events = entitiesMap?.[currentEntity]?.data.events;
        for (const event of events) {
            if (event.id === id) {
                return event.name;
            }
        }
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={groupObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={groupObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[groupObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>

                <ToggledContainer>
                    <ToggledContainerHalf
                        id="entities"
                        isSelected={groupMode == "entities"}
                        onClick={handleChangeGroupMode}
                        title="Group Records"
                    />
                    <ToggledContainerHalf
                        id="events"
                        isSelected={groupMode == "events"}
                        onClick={handleChangeGroupMode}
                        title="Group Events"
                    />
                </ToggledContainer>

                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={handleChangeEventType}
                            options={EventOptions}
                            value={entitiesMap?.[currentEntity]?.data.eventType}
                            className="select-dropdown"
                            label="Select Event Type"
                        />
                    </ModalRowHalf>
                </ModalRow>

                {groupMode == "entities" &&
                    Object.entries(
                        entitiesMap?.[currentEntity]?.data.entities
                    ).map((pair) => {
                        return (
                            <SelectionBox
                                label={getEventName(pair[0])}
                                items={pair[1]}
                                isItemSelected={isEntitySelected}
                                handleOnChange={handleOnChange}
                                type="entities"
                                key={pair[0]}
                            />
                        );
                    })}
                {groupMode == "events" && (
                    <SelectionBox
                        label="Available Events"
                        items={Object.values(
                            entitiesMap?.[currentEntity]?.data.events
                        )}
                        isItemSelected={isEventSelected}
                        handleOnChange={handleOnChange}
                        type="events"
                        getEarliestStartDate={(id) =>
                            getEarliestStartDate(
                                entitiesMap?.[currentEntity]?.data.entities[id]
                            )
                        }
                        getLatestEndDate={(id) =>
                            getLatestEndDate(
                                entitiesMap?.[currentEntity]?.data.entities[id]
                            )
                        }
                    />
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

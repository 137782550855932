import React from "react";
import styles from "./index.module.css";
import profileSvg from "../../Assets/_headerIcons/profileSvg.svg";
import settingSvg from "../../Assets/_headerIcons/settingsSvg.svg";
import notificationSvg from "../../Assets/_headerIcons/notificationBlack.svg";
import Setting from "./Setting";
import Notification from "./Notification";
import Profile from "./Profile";
import Billing from "./Billing";
import { CustomAccountManager } from "./CustomAccountManager";
import { connect } from "react-redux";
import { openCustomAccountModal } from "actions/customAccountActions";

const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

class SettingsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "settings",
            notification: [],
            notificationCount: 0,
        };
    }

    componentDidMount() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        this.props.getUserScenarios();
        if (loggedInUser) {
            this.props.fetchSharedScenarios(loggedInUser.account);
        }

        const pathname = this.props.history.location.pathname;
        const path = pathname.split("/");
        const filtered = path.filter((path) => {
            return path !== "";
        });

        if (filtered.length >= 2) {
            this.setState({ selected: path[path.length - 1] });
        } else {
            this.setState({ selected: "settings" });
        }

        let yourNotification;
        let sharedNotification;
        let notif = [];

        if (this.props.userScenarios) {
            this.props.userScenarios.forEach((scenario) => {
                if (scenario.comments && scenario.comments.notification) {
                    yourNotification = scenario.comments.notification;
                }
            });
        }
        if (this.props.sharedScenario) {
            this.props.sharedScenario.forEach((scenario) => {
                if (scenario.comments && scenario.comments.notification) {
                    sharedNotification = scenario.comments.notification;
                }
            });
        }

        if (yourNotification && yourNotification.length > 0) {
            yourNotification.forEach((notification) => {
                return notif.push(notification);
            });
        }

        if (sharedNotification && sharedNotification.length > 0) {
            sharedNotification.forEach((notification) => {
                return notif.push(notification);
            });
        }

        const filteredNotif = notif.filter((notification) => {
            return notification.commenterAccount !== loggedInUser.account;
        });

        let notifCount = 0;
        filteredNotif &&
            filteredNotif.forEach((filtered) => {
                if (!filtered.seen) {
                    notifCount += 1;
                }
            });

        this.setState({
            notification: filteredNotif,
            notificationCount: notifCount,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selected !== this.state.selected) {
            this.setState({});
        }

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (
            (prevProps.userScenarios !== this.props.userScenarios ||
                prevProps.sharedScenario !== this.props.sharedScenario) &&
            loggedInUser
        ) {
            let yourNotification;
            let sharedNotification;
            let notif = [];

            if (this.props.userScenarios) {
                this.props.userScenarios.forEach((scenario) => {
                    if (scenario.comments && scenario.comments.notification) {
                        yourNotification = scenario.comments.notification;
                    }
                });
            }
            if (this.props.sharedScenario) {
                this.props.sharedScenario.forEach((scenario) => {
                    if (scenario.comments && scenario.comments.notification) {
                        sharedNotification = scenario.comments.notification;
                    }
                });
            }

            if (yourNotification && yourNotification.length > 0) {
                yourNotification.forEach((notification) => {
                    return notif.push(notification);
                });
            }

            if (sharedNotification && sharedNotification.length > 0) {
                sharedNotification.forEach((notification) => {
                    return notif.push(notification);
                });
            }

            const filteredNotif = notif.filter((notification) => {
                return notification.commenterAccount !== loggedInUser.account;
            });

            let notifCount = 0;
            filteredNotif &&
                filteredNotif.forEach((filtered) => {
                    if (!filtered.seen) {
                        notifCount += 1;
                    }
                });

            this.setState({
                notification: filteredNotif,
                notificationCount: notifCount,
            });
        }
    }

    onHandleSelected = (id) => {
        this.setState({ selected: id });
        this.props.history.push(`/settings/${id}`);
    };

    onHandleOpenPortal = () => {
        this.props.openPortal(loggedInUser, this.props.history);
    };

    onHandleBackToMain = () => {
        this.props.history.push("/");
    };

    render() {
        const { selected, notification, notificationCount } = this.state;

        let renderComponent;
        switch (selected) {
            case "settings":
                renderComponent = <Setting />;
                break;
            case "profile":
                renderComponent = (
                    <Profile
                        history={this.props.history}
                        getSubscription={this.props.getSubscription}
                        openPortal={this.props.openPortal}
                        getUserData={this.props.getUserData}
                        saveUserDetails={this.props.saveUserDetails}
                        updateUserData={this.props.updateUserData}
                    />
                );
                break;
            case "notification":
                renderComponent = (
                    <Notification
                        notification={notification}
                        notificationCount={notificationCount}
                    />
                );
                break;
            case "billing":
                renderComponent = (
                    <Billing
                        openPortal={this.onHandleOpenPortal}
                        loggedInUser={loggedInUser}
                    />
                );
                break;
            case "customAccountManager":
                renderComponent = <CustomAccountManager />;
                break;
            default:
        }

        return (
            <div className={styles.container}>
                <div className={styles.leftPanel}>
                    <div
                        className={styles.backButton}
                        onClick={this.onHandleBackToMain}
                    >
                        Back
                    </div>
                    <div
                        className={
                            selected === "profile"
                                ? styles.optionContainerSelected
                                : styles.optionContainer
                        }
                        onClick={(_e) => this.onHandleSelected("profile")}
                    >
                        <img
                            alt="profile"
                            className={styles.image}
                            src={profileSvg}
                        />
                        <div className={styles.tabTitle}>Profile</div>
                    </div>
                    <div
                        onClick={(_e) => this.onHandleSelected("notification")}
                        className={
                            selected === "notification"
                                ? styles.optionContainerSelected
                                : styles.optionContainer
                        }
                    >
                        <img
                            alt="notification"
                            className={styles.image}
                            src={notificationSvg}
                        />
                        <div className={styles.tabTitle}>Notification</div>
                    </div>
                    <div
                        onClick={(_e) => this.onHandleSelected("settings")}
                        className={
                            selected === "settings"
                                ? styles.optionContainerSelected
                                : styles.optionContainer
                        }
                    >
                        <img
                            alt="settings"
                            className={styles.image}
                            src={settingSvg}
                        />
                        <div className={styles.tabTitle}>Settings</div>
                    </div>
                    <div
                        onClick={(_e) => this.onHandleSelected("billing")}
                        className={
                            selected === "billing"
                                ? styles.optionContainerSelected
                                : styles.optionContainer
                        }
                    >
                        <img
                            alt="billing"
                            className={styles.image}
                            src={settingSvg}
                        />
                        <div className={styles.tabTitle}>Billing</div>
                    </div>
                    <div
                        onClick={() => this.props.openCustomAccountModal(true)}
                        className={styles.optionContainer}
                    >
                        <img
                            alt="settings gear"
                            className={styles.image}
                            src={settingSvg}
                        />
                        <div className={styles.tabTitle}>
                            Create Custom Accounts
                        </div>
                    </div>
                    <div
                        onClick={(_e) =>
                            this.onHandleSelected("customAccountManager")
                        }
                        className={
                            selected === "customAccountManager"
                                ? styles.optionContainerSelected
                                : styles.optionContainer
                        }
                    >
                        <img
                            alt="customAccountManager"
                            className={styles.image}
                            src={settingSvg}
                        />
                        <div className={styles.tabTitle}>
                            Custom Account Manager
                        </div>
                    </div>
                </div>
                <div className={styles.rightPanel}>{renderComponent}</div>
            </div>
        );
    }
}

const mapStateToProps = (_state) => {
    return;
};

const mapDispatchToProps = {
    openCustomAccountModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import {ModifierCard} from "../Card/modifierCard.tsx";
import ModifierInput from "../InputContainer/ModifierInput";

// Import Connection Image (if exists)
import ModifierConnectionImage from "../../Assets/_optionDescriptionIcons/Customers, Unit Cost, Modifier.png";

// Import Coloured PNGs
import modifierBlack from "../../Assets/_eventNodeTypes/modifier-black-updated.svg";
import modifierBlue from "../../Assets/_eventNodeTypes/modifier-blue.png";
import modifierWhite from "../../Assets/_eventNodeTypes/modifier-white.png";
import modifierGrey from "../../Assets/_eventNodeTypes/modifier-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import { SpecificNode } from "helpers/modifierHelpers";

// Notes
// To call an object with no parameters, can simply use
//  - modifierObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - modifierObject.checkInput.call(modifierObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const modifier2Object = {
    constant: function () {
        return Modifier2_Constant();
    },

    name: function () {
        return "Modifier2";
    },

    checkInput: function (state) {
        return Modifier2_CheckInput(state);
    },

    svg: function () {
        return Modifier2_Svg();
    },

    eventNodeMenu: function (focus) {
        return Modifier2_EventNodeMenu(focus);
    },

    indexText: function () {
        return Modifier2_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Modifier2_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Modifier2_OptionDescription();
    },

    colouredPngs: function () {
        return Modifier2_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Modifier2_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Modifier2_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (entity.data.customEffectPeriod) {
            if (!entity.startDate) return false;
            if (entity.data.revertValue && !entity.endDate) return false;
        }
        if (entity.data.mode === SpecificNode) {
            if (
                !entity.data.curNodeId ||
                !entity.dependencies?.entity?.eventId ||
                !entity.data.subMode
            )
                return false;
        }
        if (!entity.data.propertySelected) return false;
        if (
            entity.data.value === undefined ||
            entity.data.value === "" ||
            entity.data.value === null
        )
            return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Modifier2_Constant = () => {
    return "dfbef0aa-126a-4024-97c3-de76f229b632";
};

// EFFECTS: Returns black png / svg for event
const Modifier2_Svg = () => {
    return modifierBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Modifier2_EventNodeMenu = (focus) => {
    return <ModifierCard eventData={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Modifier2_IndexText = () => {
    return "Select an input field to learn more about your modifier calculations.";
};

// EFFECTS: Returns all icons for event ??
const Modifier2_Icons = () => {
    let pngs = new Map([
        ["black", modifierBlack],
        ["blue", modifierBlue],
        ["white", modifierWhite],
        ["grey", modifierGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Modifier_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Modifier2_InputComponentInfoPassing = (minProps) => {
    return <ModifierInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, modifiers for event ??
const Modifier2_OptionDescription = () => {
    const tags = ["Option"];
    const type = modifier2Object.name();
    const description =
        "The Modifier Event is our most powerful Event. It allows a User to modify almost any value on any Event, or types of Events, upstream of itself. With a Modifier, you can change start dates, end dates, or any field or value. Values can be replaced, incremented or have a percent change - and using start and end dates, you can control when this modification occurs. A specific Event can be targeted. Or any type of Event (like all Expense Events) can all be modified at once.";
    const usedForText =
        "This Event is best used when modeling a “what if” question. For example, if a company was considering a price increase in a year’s time, they would use the Modifier Event to modify a Unit Cost Event upstream. Using multiple Modifiers, they could also quickly test various prices, various start dates, all without having to manually edit or duplicate the upstream Unit Cost Event. ";
    const connection =
        "Modifiers only need to be downstream of the Event(s) that are being modified.";
    const connectionImage = ModifierConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Modifier2_SideModalImage = () => {
    return sideImage;
};

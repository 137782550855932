import React, { Component } from "react";
import { connect } from "react-redux";
// import Modal from "react-modal";
// import Facebook from "react-facebook-login/dist/facebook-login-render-props";
import Google from "react-google-login";
import axios from "axios";
import swal from "sweetalert";
import { config } from "../../config";
import { EventsModal } from "../EventsModal";
import "./index.css";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import hidePasswordSvg from "../../Assets/hidePassword.svg";
import { signupCheck, loginCheck } from "../../helpers/checkInputs";
import * as EmailValidator from "email-validator";
import * as Sentry from "@sentry/browser";
import { Mixpanel } from "../../helpers/mixpanel";
// import loginImage from "../../Assets/_onboarding/loginImage.jpg";
import whatifiLogo from "../../Assets/_onboarding/whatifiLogo.svg";
import lockSvg from "../../Assets/_onboarding/lock.svg";
import emailSvg from "../../Assets/_onboarding/email.svg";
import nameSvg from "../../Assets/_onboarding/name.svg";
import {
    SIGN_UP,
    // INDIVIDUAL,
    BUSINESS,
    AGENCY,
    STUDENT,
    GUEST,
    LOG_IN,
} from "../../helpers/constants";
import { openOnboardingModal } from "actions/onboardingModalActions";

class LoginButton extends Component {
    logo = () => {
        switch (this.props.type) {
            case "Google":
                return (
                    <svg
                        id="GoogleLoginLogo"
                        className="LoginLogo"
                        viewBox="0 0 18 18"
                    >
                        <g fill="#000" fillRule="evenodd">
                            <path
                                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                                fill="#EA4335"
                            />
                            <path
                                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                                fill="#4285F4"
                            />
                            <path
                                d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                                fill="#FBBC05"
                            />
                            <path
                                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                                fill="#34A853"
                            />
                            <path fill="none" d="M0 0h18v18H0z" />
                        </g>
                    </svg>
                );

            case "Facebook":
                return (
                    <svg
                        id="FacebookLoginLogo"
                        className="LoginLogo"
                        viewBox="0 0 32 32"
                    >
                        <path
                            d="m8 14.41v-4.17c0-.42.35-.81.77-.81h2.52v-2.08c0-4.84 2.48-7.31 7.42-7.35 1.65 0 3.22.21 4.69.64.46.14.63.42.6.88l-.56 4.06c-.04.18-.14.35-.32.53-.21.11-.42.18-.63.14-.88-.25-1.78-.35-2.8-.35-1.4 0-1.61.28-1.61 1.73v1.8h4.52c.42 0 .81.42.81.88l-.35 4.17c0 .42-.35.71-.77.71h-4.21v16c0 .42-.35.81-.77.81h-5.21c-.42 0-.8-.39-.8-.81v-16h-2.52a.78.78 0 0 1 -.78-.78"
                            fill="white"
                        />
                    </svg>
                );

            default:
                return null;
        }
    };

    text = () => {
        return;
    };

    render = () => {
        return (
            <button className="LoginOption" onClick={this.props.onClick}>
                <div className="LoginOptionContent">{this.logo()}</div>
            </button>
        );
    };
}

class LoginModal extends Component {
    state = {
        // showSignup: this.props.loginPopUpValue === SIGN_UP ? true : false,
        termsAccepted: false,
        name: null,
        email: null,
        password: null,
        showPassword: false,
        forgotPassword: false,
        resetCode: false,
        inputEmail: false,
        code: null,
        account: null,
        token: null,
        showAccountTypeBlockedMessage: false,
    };

    onHandleInput = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        switch (id) {
            case "name":
                this.setState({ name: value });
                break;
            case "email":
                this.setState({ email: value });
                break;
            case "password":
                this.setState({ password: value });
                break;
            case "code":
                this.setState({ code: value });
                break;
            default:
        }
    };

    obtainAccount(token, socialType, image, userRole) {
        console.log(userRole, "<=== userRole");
        axios
            .post(`${config.host}/auth/socialAccount`, {
                socialType,
                token,
                userRole,
            })
            .then((response) => {
                if (response.data.newUser && response.status === 200) {
                    this.props.signup(response.data.data, Mixpanel, userRole);

                    //call create accout and call mixpanel!:)))
                } else if (!response.data.newUser && response.status === 200) {
                    const { name, email, account, token, userRole } =
                        response.data;
                    this.props.setLoggedInUser({
                        name,
                        account,
                        email,
                        token,
                        image,
                        userRole,
                    });
                    // response.data.userRole = role;
                    Mixpanel.track("Login", {
                        name: name,
                        account: account,
                        email: email,
                    });
                    // Mixpanel.identify(account);
                    Mixpanel.people.set({
                        name: name,
                        $email: email, // only reserved properties need the $
                        "Sign up date": new Date(), // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
                        USER_ID: account, // use human-readable names
                        credits: 150, // ...or numbers
                        image: image,
                    });
                    this.props.getUserData(response.data);
                    // window.location.reload();
                } else {
                    swal({
                        icon: "error",
                        title: "Authentication Error",
                        text: "Failed to obtain Account, please try again later.",
                    });
                }
            })
            .catch((error) => {
                swal({
                    icon: "error",
                    title: "Authentication Error",
                    text: "Failed to obtain user details, please try again later.",
                });
                Sentry.withScope((scope) => {
                    scope.setExtras(token, socialType);
                    Sentry.captureException(error);
                });
            });
    }

    responseFacebook = (response) => {
        if (response.accessToken) {
            this.obtainAccount(
                response.accessToken,
                "facebook",
                response.picture.data.url
            );
        }
    };

    responseGoogle = (response) => {
        if (response.error == null) {
            this.obtainAccount(
                response.tokenId,
                "google",
                response.profileObj.imageUrl,
                this.props.accountSelected
            );
        }
    };

    // onHandleLogin = () => {
    //     this.setState({ showSignup: false });
    // };

    // onHandleSignup = () => {
    //     this.setState({ showSignup: true });
    // };

    login = () => {
        if (loginCheck(this.state)) {
            this.props.login(this.state, (data) => {
                if (data) {
                    Mixpanel.track("Login", {
                        name: data.name,
                        account: data.account,
                        email: data.email,
                    });
                    // Mixpanel.identify(data.account);

                    Mixpanel.people.set({
                        name: data.name,
                        $email: data.email, // only reserved properties need the $
                        "Sign up date": new Date(), // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
                        USER_ID: data.account, // use human-readable names
                        credits: 150, // ...or numbers
                    });

                    this.props.getUserData(data);
                }
            });
        } else {
            swal({
                icon: "error",
                title: "Log-In Failed",
                text: "Please ensure all fields are completed",
            });
        }
    };

    signup = () => {
        if (signupCheck(this.state)) {
            if (EmailValidator.validate(this.state.email)) {
                this.props.signup(
                    { ...this.state, role: this.props.accountSelected },
                    Mixpanel
                );
            } else {
                swal({
                    icon: "error",
                    title: "Invalid Email",
                    text: "Please enter a valid email address",
                });
            }
        } else {
            swal({
                icon: "error",
                title: "Sign-Up Failed",
                text: "Please ensure all fields are completed",
            });
        }
    };

    showPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    onHandleTerm = () => {
        this.setState({ termsAccepted: !this.state.termsAccepted });
    };

    forgotPassword = () => {
        this.setState({ forgotPassword: true, inputEmail: true });
    };

    resetPassword = () => {
        const {
            email,
            password,
            code,
            inputEmail,
            resetCode,
            setNewPassword,
            account,
            token,
        } = this.state;
        if (email && inputEmail) {
            this.props.resetPassword(email, (err, data) => {
                if (data) {
                    return this.setState({
                        resetCode: true,
                        inputEmail: false,
                    });
                }
            });
        } else if (email && code && resetCode) {
            this.props.resetCode(email, code, (err, data) => {
                if (err) {
                    return swal({
                        icon: "error",
                        title: "Authentication Error",
                        text: "Invalid Reset Code",
                    });
                } else {
                    return this.setState({
                        resetCode: false,
                        setNewPassword: true,
                        account: data.data.account,
                        token: data.data.token,
                    });
                }
            });
        } else if (setNewPassword && password && email && code) {
            this.props.updatePassword(
                password,
                code,
                email,
                account,
                token,
                (err, _data) => {
                    if (err) {
                        console.log(err, "<---- errr");
                        return swal({
                            icon: "error",
                            title: "Authentication Error",
                            text: "Update password error",
                        });
                    } else {
                        this.login();
                    }
                }
            );
        }
    };

    handleRole = (event) => {
        const id = event.target.id;
        this.props.toggleAccountType(id);
    };

    timer;
    blockedAccountType;
    handleClickBlockedAccountType = (accountType) => {
        this.blockedAccountType = accountType;
        window.clearTimeout(this.timer);
        this.setState({ showAccountTypeBlockedMessage: true });
        this.timer = window.setTimeout(() => {
            this.setState({ showAccountTypeBlockedMessage: false });
        }, 3000);
    };

    handleGuestClose = () => {
        this.props.closeLogin();
        this.props.openOnboardingModal();
    };

    handleModalView = () => {
        const { accountSelected } = this.props;
        const {
            // showSignup,
            termsAccepted,
            resetCode,
            setNewPassword,
            inputEmail,
        } = this.state;

        switch (this.props.loginPopUpValue) {
            case GUEST:
                return (
                    <div className="signupContainer">
                        <div className="colored">
                            <div className="whatifiLogo">
                                <img
                                    alt="alt"
                                    src={whatifiLogo}
                                    className="logoSvg"
                                />
                                <div className="logoText">
                                    make bigger business decisions. better.
                                </div>
                            </div>
                            <div className="have-account-container">
                                <div className="orangeLabel">
                                    Already have an account?
                                </div>
                                <div
                                    className="loginButton loginButton--reducedMargin"
                                    onClick={() => this.props.openLogin()}
                                >
                                    LOG IN
                                </div>
                                <div
                                    className="loginButton loginButton--reducedMargin"
                                    onClick={() => this.props.openSignup()}
                                >
                                    CREATE AN ACCOUNT
                                </div>
                            </div>
                        </div>
                        <div className="plain">
                            <article className="guest__container">
                                <div className="guest__innerContainer">
                                    <h1 className="guest__header">
                                        whatifi - Guest Mode
                                    </h1>
                                    <span className="guest__subheader">
                                        We help decision-makers{" "}
                                        <span className="guest__subheader guest__subheader--highlight">
                                            Visually
                                        </span>{" "}
                                        build, compare and share &#8220;
                                        <span className="guest__subheader guest__subheader--define">
                                            what if
                                        </span>
                                        &#8221; strategic and financial models -
                                        in seconds.
                                    </span>
                                    <ul className="guest__list">
                                        <li className="guest__item">
                                            No Formulas. No Spreadsheets.
                                        </li>
                                        <li className="guest__item">
                                            Connect with Banks and Accounting
                                            Packages.
                                        </li>
                                        <li className="guest__item">
                                            Automate and compare multiple
                                            outcomes in real-time.
                                        </li>
                                    </ul>
                                </div>
                                <div className="guestAccess">
                                    <h2 className="guestAccess__heading">
                                        Guest Mode Access
                                    </h2>
                                    <div className="guestAccess__listsContainer">
                                        <ul className="guestAccess__list">
                                            <li className="guestAccess__item">
                                                view Shared Scenarios
                                            </li>
                                            <li className="guestAccess__item">
                                                compare Scenario Threads
                                            </li>
                                        </ul>
                                        <ul className="guestAccess__list">
                                            <li className="guestAccess__item">
                                                Scenarios are not editable
                                            </li>
                                            <li className="guestAccess__item">
                                                create a free account to access
                                                more features
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="guest__buttonsContainer">
                                    <a
                                        className="guest__button"
                                        href="https://www.whatifi.io"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        visit main website
                                    </a>
                                    <button
                                        className="guest__button guest__button--highlight"
                                        onClick={() => this.handleGuestClose()}
                                    >
                                        Get Started Now
                                    </button>
                                </div>
                            </article>
                        </div>
                    </div>
                );
            case LOG_IN:
                return (
                    <div className="signupContainer">
                        <div className="colored">
                            <div className="whatifiLogo">
                                <img
                                    alt="alt"
                                    src={whatifiLogo}
                                    className="logoSvg"
                                />
                                <div className="logoText">
                                    make bigger business decisions. better.
                                </div>
                            </div>
                            {/* <img
                                alt="alt"
                                src={loginImage}
                                className="loginImage"
                            />{" "} */}
                            <div className="have-account-container">
                                <div className="orangeLabel">
                                    Don&apos;t have an account?
                                </div>
                                <div className="orangeSubLabel">
                                    Let us help you to make bigger business
                                    decisions better today.
                                </div>
                                <div
                                    className="loginButton"
                                    onClick={() => this.props.openSignup()}
                                >
                                    SIGN UP
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className="plain">
                            {/* <div>
                                    <img
                                        alt="alt"
                                        src={closeSvg}
                                        className="closeSvg"
                                        onClick={closeLogin}
                                    />
                                </div> */}
                            <div className="loginRootContainer">
                                {this.state.forgotPassword ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div className="label">
                                            Reset Password
                                        </div>

                                        {inputEmail && (
                                            <div className="loginInputContainer">
                                                <img alt="alt" src={emailSvg} />
                                                <div className="loginInput">
                                                    <input
                                                        onChange={
                                                            this.onHandleInput
                                                        }
                                                        id="email"
                                                        placeholder="Email"
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {resetCode && (
                                            <div className="loginInputContainerReset">
                                                <div className="inputSubLabel">
                                                    Please paste the reset code
                                                    we sent you
                                                </div>
                                                <div className="loginInputContainer">
                                                    <img
                                                        alt="alt"
                                                        src={lockSvg}
                                                    />
                                                    <div className="loginInput">
                                                        <input
                                                            onChange={
                                                                this
                                                                    .onHandleInput
                                                            }
                                                            id="code"
                                                            placeholder="Password"
                                                            type={
                                                                this.state
                                                                    .showPassword
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                        />
                                                        <img
                                                            alt="alt"
                                                            className="showPassword"
                                                            src={
                                                                hidePasswordSvg
                                                            }
                                                            onClick={
                                                                this
                                                                    .showPassword
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {setNewPassword && (
                                            <div className="loginInputContainerReset">
                                                <div className="inputSubLabel">
                                                    Please Set New Password
                                                </div>
                                                <div className="loginInputContainer">
                                                    <img
                                                        alt="alt"
                                                        src={lockSvg}
                                                    />
                                                    <div className="loginInput">
                                                        <input
                                                            onChange={
                                                                this
                                                                    .onHandleInput
                                                            }
                                                            id="password"
                                                            placeholder="New Password"
                                                            type={
                                                                this.state
                                                                    .showPassword
                                                                    ? "text"
                                                                    : "password"
                                                            }
                                                        />
                                                        <img
                                                            alt="alt"
                                                            className="showPassword"
                                                            src={
                                                                hidePasswordSvg
                                                            }
                                                            onClick={
                                                                this
                                                                    .showPassword
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div
                                            className="signUpButton"
                                            onClick={this.resetPassword}
                                        >
                                            {resetCode ? "Submit" : "Reset"}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="label">
                                            Log in to your account
                                        </div>
                                        <div className="inputSubLabel" />

                                        <div className="loginInputContainer">
                                            <img alt="alt" src={emailSvg} />
                                            <div className="loginInput">
                                                <input
                                                    onChange={
                                                        this.onHandleInput
                                                    }
                                                    id="email"
                                                    placeholder="Email"
                                                />
                                            </div>
                                        </div>

                                        <div className="loginInputContainer">
                                            <img alt="alt" src={lockSvg} />
                                            <div className="loginInput">
                                                <input
                                                    onChange={
                                                        this.onHandleInput
                                                    }
                                                    id="password"
                                                    placeholder="Password"
                                                    type={
                                                        this.state.showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                />
                                                <img
                                                    alt="alt"
                                                    className="showPassword"
                                                    src={hidePasswordSvg}
                                                    onClick={this.showPassword}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            onClick={this.forgotPassword}
                                            className="forgotPassword"
                                        >
                                            Forgot password
                                        </div>

                                        <div
                                            className="signUpButton"
                                            onClick={this.login}
                                        >
                                            LOG IN
                                        </div>
                                        <div className="text">
                                            Or sign in with
                                        </div>
                                        <div>
                                            <Google
                                                clientId="633969127252-oc5pfu3e12ugetmsegtlg3mio42alg95.apps.googleusercontent.com"
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseGoogle}
                                                cookiePolicy={
                                                    "single_host_origin"
                                                }
                                                render={(renderProps) => {
                                                    return (
                                                        <LoginButton
                                                            type="Google"
                                                            onClick={
                                                                renderProps.onClick
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            case SIGN_UP:
                return (
                    <div className="signupContainer">
                        <div className="colored">
                            <div className="whatifiLogo">
                                <img
                                    alt="alt"
                                    src={whatifiLogo}
                                    className="logoSvg"
                                />
                                <div className="logoText">
                                    make bigger business decisions. better.
                                </div>
                            </div>
                            {/* <img
                                alt="alt"
                                src={loginImage}
                                className="loginImage"
                            />{" "} */}
                            <div className="have-account-container">
                                <div className="orangeLabel">
                                    Already have an account?
                                </div>
                                <div
                                    className="loginButton"
                                    onClick={() => this.props.openLogin()}
                                >
                                    LOG IN
                                </div>
                            </div>
                        </div>
                        <div className="plain">
                            {/* <div>
                                <img
                                    alt="alt"
                                    src={closeSvg}
                                    className="closeSvg"
                                    onClick={closeLogin}
                                />
                            </div> */}
                            <div className="loginRootContainer">
                                {accountSelected !== "student" ? (
                                    <div className="loginRoleContainer">
                                        {/* <div
                                            id={INDIVIDUAL}
                                            onClick={this.handleRole}
                                            className={
                                                accountSelected ===
                                                INDIVIDUAL
                                                    ? "roleSelected"
                                                    : "notSelected"
                                            }
                                        >
                                            Personal
                                        </div> */}
                                        <div
                                            id={BUSINESS}
                                            onClick={this.handleRole}
                                            // used to block account from being selected
                                            // onClick={() => {
                                            //     this.handleClickBlockedAccountType(
                                            //         "Business"
                                            //     );
                                            // }}
                                            className={
                                                accountSelected === BUSINESS
                                                    ? "roleSelected"
                                                    : "notSelected"
                                            }
                                        >
                                            Business
                                        </div>
                                        {this.state
                                            .showAccountTypeBlockedMessage && (
                                            <div className="business-blocked-message-container">
                                                <span className="business-blocked-message">
                                                    whatifi{" "}
                                                    {this.blockedAccountType} is
                                                    launching soon!
                                                </span>
                                            </div>
                                        )}
                                        <div
                                            id={AGENCY}
                                            // onClick={this.handleRole}
                                            // used to block account from being selected
                                            onClick={() => {
                                                this.handleClickBlockedAccountType(
                                                    "Agency"
                                                );
                                            }}
                                            className={
                                                accountSelected === AGENCY
                                                    ? "roleSelected"
                                                    : "notSelected"
                                            }
                                        >
                                            Agency
                                        </div>
                                    </div>
                                ) : (
                                    <div className="loginStudentRoleContainer">
                                        <div
                                            id={STUDENT}
                                            onClick={this.handleRole}
                                            className={
                                                accountSelected === STUDENT
                                                    ? "roleSelected"
                                                    : "notSelected"
                                            }
                                        >
                                            STUDENT
                                        </div>
                                    </div>
                                )}

                                <div className="label">
                                    Signup today, <br />
                                    and start creating clarity <br />
                                    for your{" "}
                                    {this.props.accountSelected === "agency"
                                        ? "clients"
                                        : "business"}
                                    .
                                </div>
                                <div className="inputSubLabel" />

                                <div className="loginInputContainer">
                                    <img alt="alt" src={nameSvg} />
                                    <div className="loginInput">
                                        <input
                                            onChange={this.onHandleInput}
                                            id="name"
                                            placeholder="Name"
                                        />
                                    </div>
                                </div>
                                <div className="loginInputContainer">
                                    <img alt="alt" src={emailSvg} />
                                    <div className="loginInput">
                                        <input
                                            onChange={this.onHandleInput}
                                            id="email"
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>
                                <div className="loginInputContainer">
                                    <img alt="alt" src={lockSvg} />
                                    <div className="loginInput">
                                        <input
                                            onChange={this.onHandleInput}
                                            id="password"
                                            placeholder="Password"
                                            type={
                                                this.state.showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                        />
                                        <img
                                            alt="alt"
                                            className="showPassword"
                                            src={hidePasswordSvg}
                                            onClick={this.showPassword}
                                        />
                                    </div>
                                </div>

                                <div className="termContainer">
                                    <img
                                        alt="alt"
                                        src={
                                            termsAccepted
                                                ? checkboxOn
                                                : checkboxOff
                                        }
                                        className="checkBox"
                                        onClick={this.onHandleTerm}
                                    />
                                    <div className="term">
                                        I accept whatifi&apos;s{" "}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.iubenda.com/terms-and-conditions/83071811"
                                        >
                                            Terms and Conditions
                                        </a>
                                    </div>
                                </div>
                                <div
                                    onClick={this.signup}
                                    className="signUpButton"
                                >
                                    Get Started Now
                                </div>
                                <div className="text">Or sign in with</div>
                                <div />

                                <div>
                                    <Google
                                        clientId="633969127252-oc5pfu3e12ugetmsegtlg3mio42alg95.apps.googleusercontent.com"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                        render={(renderProps) => {
                                            return (
                                                <LoginButton
                                                    type="Google"
                                                    onClick={
                                                        renderProps.onClick
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    render() {
        // const { closeLogin, accountSelected } = this.props;
        // const {
        //     showSignup,
        //     termsAccepted,
        //     resetCode,
        //     setNewPassword,
        //     inputEmail,
        // } = this.state;

        return (
            <div className="LoginModal">
                <EventsModal
                    preventClose={true}
                    onHandleClose={this.props.closeLogin}
                    login={true}
                >
                    {this.handleModalView()}
                    {/* <div id="LoginOptions">
                        <Facebook
                            appId="1301628593347285"
                            autoLoad={false}
                            fields="name,email,picture"
                            onClick={this.componentClicked}
                            callback={this.responseFacebook}
                            render={(renderProps) => {
                                return (
                                    <LoginButton
                                        type="Facebook"
                                        onClick={renderProps.onClick}
                                    />
                                );
                            }}
                        />
                        <Google
                            clientId="633969127252-oc5pfu3e12ugetmsegtlg3mio42alg95.apps.googleusercontent.com"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            cookiePolicy={"single_host_origin"}
                            render={(renderProps) => {
                                return (
                                    <LoginButton
                                        type="Google"
                                        onClick={renderProps.onClick}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div id="LoginFooter">
                        <button onClick={this.props.closeLogin}>Exit</button>
                    </div> */}
                </EventsModal>
            </div>
        );
    }
}
const mapStateToProps = (state, _ownProps) => {
    return {
        loginPopUpValue: state.scenario.loginPopUpValue,
    };
};

const mapDispatchToProps = {
    openOnboardingModal,
};

export const LoginModalConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal);

import axios from "axios";
import { config } from "config";
import {
    ModifierSchema,
    ModifiersSchema,
} from "reducers/typesSchema/modifiersSchema";
import { requestHeader } from "./scenario";

interface ModifierProperty {
    id?: string | number;
    account?: string;
    associatedEntity?: string;
    //Any additional fields shared by all entities that can be queried go here
}

export function queryModifiersAPI(modifierProperty: ModifierProperty) {
    return axios.get(`${config.host}/modifiers/query`, {
        headers: requestHeader().headers,
        params: { name: modifierProperty },
    });
}

export function createModifiersAPI(newModifiers: ModifierSchema[]) {
    if (newModifiers.length === 0) return Promise.resolve<string[]>([]);
    const reqHeader = requestHeader();
    const modifiers = newModifiers.map((modifier) => ({
        ...modifier,
        account: reqHeader.headers.account,
    }));
    return axios.post<string[]>(
        `${config.host}/modifiers`,
        { modifiers },
        reqHeader
    );
}

export function updateModifiersAPI(modifierChanges: ModifiersSchema) {
    if (Object.keys(modifierChanges).length === 0)
        return Promise.resolve<string[]>([]);
    // Filter out all modifiers leaving only overrides, modifiers do not have 'id' fields
    Object.keys(modifierChanges).forEach((key) => {
        const modifierArray = modifierChanges[key];
        const filteredArray = modifierArray.filter((modifier) => {
            return "id" in modifier;
        });
        modifierChanges[key] = filteredArray;
    });
    return axios.put(
        `${config.host}/modifiers`,
        { modifiers: modifierChanges },
        requestHeader()
    );
}

export function deleteModifiersAPI(toDelete: string[]) {
    if (toDelete.length === 0) return Promise.resolve<string[]>([]);
    return axios.delete(`${config.host}/modifiers`, {
        headers: requestHeader().headers,
        data: { modifiers: toDelete },
    });
}

// export function submitModifiersAPI(
//     createObjs: ModifierSchema,
//     updateObjs: ModifierSchema
//     // deleteIds: string[]
// ) {
//     return Promise.allSettled([
//         createModifiersAPI(createObjs),
//         updateModifiersAPI(updateObjs),
//     ]);
// }

import React, { Component } from "react";
import "./InputContainer.css";
import Calendar from "../Calendar";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import Switch from "react-switch";
import LocationSearchInput from "../LocationSearchInput";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import {
    Name,
    Description,
    SelectDropDown,
    Value,
    Stars,
    Rate,
    Url,
    InputButtons,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { rentObject } from "../Registry/Rent";

const options = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

const rentalTypes = ["Apartment", "Studio", "House"];
const roomsCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

class RentalInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: getDefaultName(rentObject.constant(), this.props),
            rent: null,
            rooms: 0,
            type: rentObject.constant(),
            rentalType: "",
            roommates: "",
            url: "",
            start: null,
            end: null,
            rating: 0,
            showSettings: false,
            description: "",
            cadence: "",
            showRequired: false,
            address: null,
            lat: null,
            lng: null,
            sharedAccomodationTerm: "accomodationPercentage",
            accomodationRate: "",
            deposit: "",
            depositTerm: "depositPercentage",
            depositRate: "",
            depositCost: "",
            accomodationCost: "",
            insurance: "",
            insuranceTerm: "insurancePercentage",
            insuranceRate: "",
            insuranceCost: "",
            rentalIncrease: false,
            increaseRate: null,
            showAdditionalExpenses: false,
            showAccomodation: false,
            showRentalIncrease: false,
            upfrontPayment: "",
            upfrontTerm: "upfrontPercentage",
            upfrontRate: "",
            upfrontCost: "",
            utilitiesPayment: "",
            utilitiesTerm: "utilitiesPercentage",
            utilitiesRate: "",
            utilitiesCost: "",
            utilitiesFrequency: "",
            insuranceFrequency: "",
            value: "0",
            tag: `@${rentObject.constant()}`,
            onHoverStar: null,
            inflation: false,
            baseline: false,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData, showSettings: false });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "rooms":
                this.setState({ rooms: value });
                break;
            case "utilitiesFrequency":
                if (value === "Select Frequency") {
                    this.setState({
                        utilitiesFrequency: null,
                    });
                } else {
                    this.setState({
                        utilitiesFrequency: value,
                    });
                }
                break;
            case "insuranceFrequency":
                if (value === "Select Frequency") {
                    this.setState({
                        insuranceFrequency: null,
                    });
                } else {
                    this.setState({
                        insuranceFrequency: value,
                    });
                }

                break;
            case "value":
                const rent = parseInt(value);
                this.setState({ rent, value: rent });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "rentalType":
                if (value === "Select Type") {
                    this.setState({ rentalType: null });
                } else {
                    this.setState({ rentalType: value });
                }
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "cadence":
                if (value === "Select Frequency") {
                    this.setState({ cadence: null });
                } else {
                    this.setState({ cadence: value });
                }
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "accomodationPercentage":
                this.setState({ sharedAccomodationTerm: id });
                break;
            case "accomodationFixed":
                this.setState({ sharedAccomodationTerm: id });
                break;
            case "roommates":
                const roommates = parseInt(value);
                const ratePerPerson = parseInt(100 / (roommates + 1));
                const accomodationCostTotal = parseInt(
                    this.state.rent * (ratePerPerson / 100)
                );
                this.setState({
                    roommates: parseInt(value),
                    accomodationCost: accomodationCostTotal,
                    accomodationRate: ratePerPerson,
                    depositRate: ratePerPerson,
                    insuranceRate: ratePerPerson,
                    upfrontRate: ratePerPerson,
                    utilitiesRate: ratePerPerson,
                });
                if (this.state.deposit) {
                    let cost = parseInt(
                        this.state.deposit * (ratePerPerson / 100)
                    );
                    this.setState({ depositCost: cost });
                }
                if (this.state.insurance) {
                    let cost = parseInt(
                        this.state.insurance * (ratePerPerson / 100)
                    );
                    this.setState({ insuranceCost: cost });
                }
                if (this.state.upfrontPayment) {
                    let cost = parseInt(
                        this.state.upfrontPayment * (ratePerPerson / 100)
                    );
                    this.setState({ upfrontCost: cost });
                }
                if (this.state.utilitiesPayment) {
                    let cost = parseInt(
                        this.state.utilitiesPayment * (ratePerPerson / 100)
                    );
                    this.setState({ utilitiesCost: cost });
                }
                break;
            case "accomodationRate":
                const accomodationRate = parseInt(value);
                const rentAmount = this.state.rent;
                const cost = parseInt(rentAmount * (accomodationRate / 100));
                this.setState({ accomodationRate, accomodationCost: cost });
                break;
            case "deposit":
                const deposit = parseInt(value);
                if (!this.state.depositRate) {
                    this.setState({ deposit, depositRate: 100 });
                }
                if (this.state.depositRate) {
                    const totaldepositCost = parseInt(
                        deposit * (this.state.depositRate / 100)
                    );
                    this.setState({ deposit, depositCost: totaldepositCost });
                } else {
                    this.setState({ deposit });
                }

                break;
            case "depositPercentage":
                this.setState({ depositTerm: id });
                break;
            case "depositFixed":
                this.setState({ depositTerm: id });
                break;
            case "depositRate":
                const depositRate = parseInt(value);
                const depositTotal = parseInt(
                    this.state.deposit * (depositRate / 100)
                );
                this.setState({ depositRate, depositCost: depositTotal });
                break;
            case "depositCost":
                const depositCost = parseInt(value);

                const depositRateTotal = parseInt(
                    (depositCost / this.state.deposit) * 100
                );
                this.setState({
                    depositCost,
                    depositRate: depositRateTotal,
                });

                break;
            case "accomodationCost":
                const accomodationCost = parseInt(value);
                const totalRate = parseInt(
                    (accomodationCost / this.state.rent) * 100
                );
                this.setState({
                    accomodationCost,
                    accomodationRate: totalRate,
                });
                break;
            case "insurance":
                const insurance = parseInt(value);
                if (!this.state.insuranceRate) {
                    this.setState({ insurance, insuranceRate: 100 });
                }
                if (this.state.insuranceRate) {
                    const totalInsuranceCost = parseInt(
                        insurance * (this.state.insuranceRate / 100)
                    );
                    this.setState({
                        insurance,
                        insuranceCost: totalInsuranceCost,
                    });
                } else {
                    this.setState({ insurance });
                }
                break;
            case "insurancePercentage":
                this.setState({ insuranceTerm: id });
                break;
            case "insuranceFixed":
                this.setState({ insuranceTerm: id });
                break;
            case "insuranceRate":
                const insuranceRate = parseInt(value);
                const insuranceTotal = parseInt(
                    this.state.insurance * (insuranceRate / 100)
                );
                this.setState({ insuranceRate, insuranceCost: insuranceTotal });
                break;
            case "insuranceCost":
                const insuranceCost = parseInt(value);
                const totalInsuranceRate = parseInt(
                    (insuranceCost / this.state.insurance) * 100
                );
                this.setState({
                    insuranceCost,
                    insuranceRate: totalInsuranceRate,
                });
                break;
            case "increaseRate":
                this.setState({ increaseRate: value });
                break;
            case "showAccomodation":
                this.setState({
                    showAccomodation: !this.state.showAccomodation,
                });
                break;
            case "showAdditionalExpenses":
                this.setState({
                    showAdditionalExpenses: !this.state.showAdditionalExpenses,
                });
                break;
            case "showRentalIncrease":
                this.setState({
                    showRentalIncrease: !this.state.showRentalIncrease,
                });
                break;
            case "upfrontPayment":
                const upfrontPayment = parseInt(value);
                if (!this.state.upfrontRate) {
                    this.setState({ upfrontPayment, upfrontRate: 100 });
                }
                if (this.state.upfrontRate) {
                    const totalUpfrontCost = parseInt(
                        upfrontPayment * (this.state.upfrontRate / 100)
                    );
                    this.setState({
                        upfrontPayment,
                        upfrontCost: totalUpfrontCost,
                    });
                } else {
                    this.setState({ upfrontPayment });
                }
                break;
            case "upfrontPercentage":
                this.setState({ upfrontTerm: id });
                break;
            case "upfrontFixed":
                this.setState({ upfrontTerm: id });
                break;
            case "upfrontRate":
                const upfrontRate = parseInt(value);
                const upfrontTotal = parseInt(
                    this.state.upfrontPayment * (upfrontRate / 100)
                );

                this.setState({ upfrontRate, upfrontCost: upfrontTotal });
                break;
            case "upfrontCost":
                const upfrontCost = parseInt(value);
                const totalUpfrontRate = parseInt(
                    (upfrontCost / this.state.upfrontPayment) * 100
                );
                this.setState({ upfrontCost, upfrontRate: totalUpfrontRate });
                break;
            case "utilitiesPayment":
                const utilitiesPayment = parseInt(value);
                if (!this.state.utilitiesRate) {
                    this.setState({ utilitiesPayment, utilitiesRate: 100 });
                }
                if (this.state.utilitiesRate) {
                    const totalUtilitiesCost = parseInt(
                        utilitiesPayment * (this.state.utilitiesRate / 100)
                    );
                    this.setState({
                        utilitiesPayment,
                        utilitiesCost: totalUtilitiesCost,
                    });
                } else {
                    this.setState({ utilitiesPayment });
                }

                break;
            case "utilitiesPercentage":
                this.setState({ utilitiesTerm: id });
                break;
            case "utilitiesFixed":
                this.setState({ utilitiesTerm: id });
                break;
            case "utilitiesRate":
                const utilitiesRate = parseInt(value);
                const utilitiesTotal = parseInt(
                    this.state.utilitiesPayment * (utilitiesRate / 100)
                );

                this.setState({ utilitiesRate, utilitiesCost: utilitiesTotal });
                break;
            case "utilitiesCost":
                const utilitiesCost = parseInt(value);
                const totalUtilitiesRate =
                    (utilitiesCost / this.state.utilitiesPayment) * 100;
                this.setState({
                    utilitiesCost,
                    utilitiesRate: totalUtilitiesRate,
                });
                break;
            default:
        }
    };

    onHandleDate = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
    };
    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const passedCheck = rentObject.checkInput(this.state);
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onCancelOptional = () => {
        this.setState({ ...this.state, showSettings: false });
    };

    onChangeRentalIncrease = (checked) => {
        this.setState({ rentalIncrease: checked });
    };

    onHandleLocation = (address, lat, lng) => {
        this.setState({ address, lat, lng });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        const { edit } = this.props;
        const passedCheck = rentObject.constant(this.state);

        const {
            name,
            rooms,
            rentalType,
            description,
            cadence,
            value,
            url,
            onHoverStar,
            rating,
        } = this.state;

        return (
            <div className="mainRootContainer">
                {!this.state.showSettings ? (
                    <div className="inputRoot">
                        <div className="headerContainer">
                            <img
                                alt="alt"
                                src={rentObject.svg()}
                                className="flinksLogo"
                            />
                            <div className="headerInput">
                                <div className="headerLabel">RENT</div>
                                <div className="inputContainer">
                                    <div className="Required">*</div>
                                    <Name
                                        name={name}
                                        onChangeInput={this.onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ModalRow">
                            {/* <div className="Required">*</div> */}
                            <LocationSearchInput
                                id="address"
                                handleLocation={this.onHandleLocation}
                                value={this.state.address}
                                edit={this.props.edit}
                            />
                        </div>

                        <div className="ModalRowSpaceBetween">
                            <div className="twoThird">
                                {/* <div className="Required">*</div> */}
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={rentalType}
                                    options={rentalTypes}
                                    className="select-dropdown"
                                    id="rentalType"
                                    label="Select Type"
                                />
                            </div>
                            <div className="oneThird">
                                {/* <div className="Required">*</div> */}
                                <Rate
                                    value={rooms}
                                    label="Bedrooms"
                                    className="mlsInput"
                                    id="rooms"
                                    onChangeInput={this.onChangeInput}
                                    sign={false}
                                />
                            </div>
                        </div>
                        <div className="ModalRow">
                            <Description
                                description={description}
                                onChangeInput={this.onChangeInput}
                                placeholder="Note"
                            />
                        </div>

                        <div className="ModalRowSpaceBetween">
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={cadence}
                                    options={options}
                                    className="select-dropdown"
                                    id="cadence"
                                    label="Payment Frequency"
                                />
                            </div>

                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <Value
                                    label="Amount"
                                    onChangeInput={this.onChangeInput}
                                    value={value}
                                    id="value"
                                />
                            </div>
                        </div>

                        <div className="ModalRow">
                            <Url
                                value={url}
                                className="UrlLink"
                                onChangeInput={this.onChangeInput}
                                id="url"
                                label="URL"
                            />
                        </div>

                        <div className="ModalRow">
                            <div className="Required">*</div>
                            <div className="keyLabel">Date</div>
                            <Calendar
                                startDate={this.state.start}
                                endDate={this.state.end || null}
                                onHandleDate={this.onHandleDate}
                                cadence={cadence}
                            />
                        </div>
                        <div className="ModalRow">
                            <div className="Required" />
                            <div className="keyLabel">Rating</div>
                            <div className="StarsHolder">
                                {stars.map((star, i) => {
                                    const id = i + 1;
                                    const onHover = onHoverStar;
                                    if (i < rating || i < onHover) {
                                        return (
                                            <Stars
                                                key={i}
                                                className="starFilledSvg"
                                                src={starFilledSvg}
                                                id={id}
                                                onChangeInput={(e) =>
                                                    this.onChangeInput(
                                                        e,
                                                        "rating",
                                                        id
                                                    )
                                                }
                                                onHandleStarHover={
                                                    this.onHandleStarHover
                                                }
                                                onMouseLeave={this.onMouseLeave}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Stars
                                                key={i}
                                                className="starUnfilledSvg"
                                                src={starUnfilledSvg}
                                                id={id}
                                                onChangeInput={(e) =>
                                                    this.onChangeInput(
                                                        e,
                                                        "rating",
                                                        id
                                                    )
                                                }
                                                onHandleStarHover={
                                                    this.onHandleStarHover
                                                }
                                                onMouseLeave={this.onMouseLeave}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        <div className="ModalRow">
                            <Switch
                                id="inflation"
                                checked={this.state.inflation}
                                className="InflationToggle"
                                height={20}
                                width={40}
                                onChange={this.updateInflation}
                            />
                            <div className="InflationText">Apply inflation</div>
                            {/* <div className="InflationRateContainer">
                        {(this.props.manager.inflation * 100).toFixed(2)} %
                    </div> */}
                        </div>
                        <div className="ModalRow">
                            <div className="halfDiv">
                                <img
                                    alt="alt"
                                    onClick={this.onHandleAdvanceSetting}
                                    className="selectedIcon"
                                    src={
                                        this.state.showSettings
                                            ? dropdownSvg
                                            : expandSvg
                                    }
                                />
                                <div className="advanceSettingLabel">
                                    Advance settings
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="inputRoot">
                        <button
                            onClick={this.onCancelOptional}
                            className="BackToRequired"
                        >
                            Back
                        </button>
                        <div className="ModalRow">
                            <div className="OptionalLabel">
                                Optional settings
                            </div>
                        </div>
                        <div className="inputRoot">
                            <div className="ModalRow">
                                <div
                                    className="RentLabel"
                                    onClick={(e) =>
                                        this.onChangeInput(
                                            e,
                                            "showAccomodation"
                                        )
                                    }
                                    id="showAccomodation"
                                >
                                    <img
                                        alt="alt"
                                        className="selectedIcon"
                                        src={
                                            this.state.showAccomodation
                                                ? dropdownSvg
                                                : expandSvg
                                        }
                                        onClick={(e) =>
                                            this.onChangeInput(
                                                e,
                                                "showAccomodation"
                                            )
                                        }
                                        id="showAccomodation"
                                    />
                                    Shared Accomodation Settings
                                </div>
                            </div>
                            {this.state.showAccomodation && (
                                <div className="modalBox">
                                    <div className="modalBoxLabel">
                                        Accomodation
                                    </div>

                                    <div className="boxRow">
                                        <div className="modalBoxLabel">
                                            Roommates
                                        </div>
                                        <SelectDropDown
                                            onChangeInput={this.onChangeInput}
                                            value={this.state.roommates}
                                            options={roomsCount}
                                            className="SharedSelect"
                                            id="roommates"
                                            label="Roommates"
                                        />
                                    </div>
                                    <div className="boxRow">
                                        <div className="modalBoxLabel">
                                            Your Percentage
                                        </div>
                                        <Rate
                                            value={this.state.accomodationRate}
                                            label="Rate"
                                            className="SharedSelect"
                                            id="accomodationRate"
                                            onChangeInput={this.onChangeInput}
                                            sign={true}
                                        />
                                    </div>
                                    <div className="boxRow">
                                        <div className="modalBoxLabel">
                                            Your Total Accomodation
                                        </div>
                                        <div className="ExpenseAmountContainerLeft">
                                            <Value
                                                label={`Accomodation Cost / ${this.state.cadence}`}
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                value={
                                                    this.state.accomodationCost
                                                }
                                                id="accomodationCost"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="ModalRow">
                                <div
                                    className="RentLabel"
                                    onClick={(e) =>
                                        this.onChangeInput(
                                            e,
                                            "showAdditionalExpenses"
                                        )
                                    }
                                    id="showAdditionalExpenses"
                                >
                                    <img
                                        alt="alt"
                                        className="selectedIcon"
                                        src={
                                            this.state.showAdditionalExpenses
                                                ? dropdownSvg
                                                : expandSvg
                                        }
                                        onClick={(e) =>
                                            this.onChangeInput(
                                                e,
                                                "showAdditionalExpenses"
                                            )
                                        }
                                        id="showAdditionalExpenses"
                                    />
                                    Additional Expenses
                                </div>
                            </div>

                            {this.state.showAdditionalExpenses && (
                                <div>
                                    <div className="modalBox">
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Deposit
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Deposit`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={this.state.deposit}
                                                    id="deposit"
                                                />
                                            </div>
                                        </div>

                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Percentage
                                            </div>
                                            <Rate
                                                value={this.state.depositRate}
                                                label="Rate"
                                                className="SharedSelect"
                                                id="depositRate"
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                sign={true}
                                            />
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Total Deposit Cost
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Deposit Cost`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={
                                                        this.state.depositCost
                                                    }
                                                    id="depositCost"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modalBox">
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Insurance
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Insurance`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={this.state.insurance}
                                                    id="insurance"
                                                />
                                            </div>
                                        </div>

                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Frequency
                                            </div>
                                            <SelectDropDown
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                value={
                                                    this.state
                                                        .insuranceFrequency
                                                }
                                                options={options}
                                                className="SharedSelect"
                                                id="insuranceFrequency"
                                                label="Select Frequency"
                                            />
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Percentage
                                            </div>
                                            <Rate
                                                value={this.state.insuranceRate}
                                                label="Rate"
                                                className="SharedSelect"
                                                id="insuranceRate"
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                sign={true}
                                            />
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Total Insurance Cost
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Insurance Cost`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={
                                                        this.state.insuranceCost
                                                    }
                                                    id="insuranceCost"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modalBox">
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Upfront Expense
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Upfront`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={
                                                        this.state
                                                            .upfrontPayment
                                                    }
                                                    id="upfrontPayment"
                                                />
                                            </div>
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Percentage
                                            </div>
                                            <Rate
                                                value={this.state.upfrontRate}
                                                label="Rate"
                                                className="SharedSelect"
                                                id="upfrontRate"
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                sign={true}
                                            />
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Total Upfront Cost
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Upfront Cost`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={
                                                        this.state.upfrontCost
                                                    }
                                                    id="upfrontCost"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="modalBox">
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Utilities Expense
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Utilities`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={
                                                        this.state
                                                            .utilitiesPayment
                                                    }
                                                    id="utilitiesPayment"
                                                />
                                            </div>
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Frequency
                                            </div>
                                            <SelectDropDown
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                value={
                                                    this.state
                                                        .utilitiesFrequency
                                                }
                                                options={options}
                                                className="SharedSelect"
                                                id="utilitiesFrequency"
                                                label="Select Frequency"
                                            />
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Percentage
                                            </div>
                                            <Rate
                                                value={this.state.utilitiesRate}
                                                label="Rate"
                                                className="SharedSelect"
                                                id="utilitiesRate"
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                sign={true}
                                            />
                                        </div>
                                        <div className="boxRow">
                                            <div className="modalBoxLabel">
                                                Your Total Utilities Expense
                                            </div>
                                            <div className="ExpenseAmountContainerLeft">
                                                <Value
                                                    label={`Total Utilities Cost`}
                                                    onChangeInput={
                                                        this.onChangeInput
                                                    }
                                                    value={
                                                        this.state.utilitiesCost
                                                    }
                                                    id="utilitiesCost"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* <div className="ModalRow">
                                <div
                                    className="RentLabel"
                                    onClick={(e) =>
                                        this.onChangeInput(
                                            e,
                                            "showRentalIncrease"
                                        )
                                    }
                                    id="showRentalIncrease"
                                >
                                    <img
                                        alt="alt"
                                        className="selectedIcon"
                                        src={
                                            this.state.showRentalIncrease
                                                ? dropdownSvg
                                                : expandSvg
                                        }
                                        onClick={(e) =>
                                            this.onChangeInput(
                                                e,
                                                "showRentalIncrease"
                                            )
                                        }
                                        id="showRentalIncrease"
                                    />
                                    Rental Increases
                                </div>
                            </div> */}

                            {/* {this.state.showRentalIncrease && (
                                <div className="ModalRow">
                                    <Switch
                                        id="rentalIncrease"
                                        checked={this.state.rentalIncrease}
                                        className="InflationToggle"
                                        height={20}
                                        width={40}
                                        onChange={this.onChangeRentalIncrease}
                                    />
                                    <div className="RentText">
                                        Assume yearly rental increase
                                    </div>
                                    {this.state.rentalIncrease && (
                                        <div className="rateBox">
                                            <input
                                                className="mlsInput"
                                                type="number"
                                                id="increaseRate"
                                                onChange={(e) =>
                                                    this.onChangeInput(
                                                        e,
                                                        "increaseRate"
                                                    )
                                                }
                                                value={this.state.increaseRate}
                                                placeholder="Rate"
                                            />
                                            <div className="percentage">%</div>
                                        </div>
                                    )}
                                </div>
                            )} */}
                        </div>
                    </div>
                )}
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                    isLibraryEvent={this.state.isLibraryEvent}
                    addLibraryEventToScenario={() => {
                        this.props.addLibraryEventToScenario(this.state);
                    }}
                    divorceLibraryEvent={() => {
                        this.props.divorceFromLibraryEvent(
                            this.state,
                            this.props.updateValues
                        );
                    }}
                />
            </div>
        );
    }
}

export default RentalInput;

/**
    This functions takes in the scenario start date (when the graph begins displaying data) and
    the calculation start date (when DE3 begins calculating ledgers), and returns the number of
    months from the calculation start date to the end of the scenario.
 */
export const getModifiedRange = (
    scenarioStart: string,
    calculationStart: string,
    range: number
) => {
    // NOTE: scenarioStart will always be ahead of or equal to calculationStart, never behind
    // Convert strings to date objects
    const scenarioStartDate = new Date(scenarioStart);
    const calculationStartDate = new Date(calculationStart);
    // Get the difference in months between the dates (Note months are 0-indexed so, January = 0)
    const monthsDiff =
        scenarioStartDate.getMonth() - calculationStartDate.getMonth();
    // Get the difference in years between the dates
    const yearsDiff =
        scenarioStartDate.getFullYear() - calculationStartDate.getFullYear();
    // Convert years and range to months and add all the months together
    const modifiedRange = monthsDiff + 12 * yearsDiff + 12 * range;
    return modifiedRange;
};

import React, { Component } from "react";
import "./Card.css";
import { NodeTypesBlack } from "../../Events";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import expiredSvg from "../../Assets/_cardIcons/cardExpired.svg";
import bypassedSvg from "../../Assets/_cardIcons/cardBypassed.svg";
import { throwError } from "../../helpers/swalError";
import { SIGN_UP, INDIVIDUAL } from "../../helpers/constants";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { updateEntitiesAPI } from "actions/entityAPIActions";
import { upsertEntities } from "actions/entitiesActions";
import store from "store";
import { editScenario } from "actions/scenario";
import { getNameFromType } from "helpers/getNameFromType";

class StartCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSettings: false,
            baseline: null,
            nodeBypassed: null,
            allBaselineNodesSelected: true,
            excludedBaselineNode: [],
        };
    }

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];

        if (this.props.manager) {
            if (this.props.baselineDataManager.hasActiveBaseline()) {
                this.props.manager.importBaseline(
                    this.props.baselineDataManager.getActiveBaseline().data
                );
            } else {
                this.props.manager.resetBaseline();
            }
            this.setState({ baseline: this.props.manager.baseline });
        }

        if (firstEntity.data.excludedBaselineNode) {
            this.setState({
                excludedBaselineNode: firstEntity.data.excludedBaselineNode,
                allBaselineNodesSelected:
                    firstEntity.data.excludedBaselineNode.length <= 0,
            });
        }
    }

    handleEditBaseline = () => {
        this.props.toggleShowAgencyBaselineView(false);
        this.props.history.push("/baseline");
    };

    submitExcludedBaselineNode(entity, baselineNodes) {
        const newEntity = {
            ...entity,
            data: {
                ...entity.data,
                excludedBaselineNode: baselineNodes,
            },
        };
        updateEntitiesAPI({ [newEntity.id]: newEntity })
            .then((_res) => {
                store.dispatch(upsertEntities({ [newEntity.id]: newEntity }));
                this.setState({
                    excludedBaselineNode: baselineNodes,
                    allBaselineNodesSelected: baselineNodes.length === 0,
                });
                this.props.manager.calculate(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onHandleNodeSelected = (baselineNode) => {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];

        let newArray = firstEntity.data.excludedBaselineNode
            ? [...firstEntity.data.excludedBaselineNode]
            : [];
        if (newArray.includes(baselineNode.id)) {
            newArray = newArray.filter((id) => id !== baselineNode.id);
        } else {
            newArray.push(baselineNode.id);
        }

        this.submitExcludedBaselineNode(firstEntity, newArray);
    };

    onHandleToggleAll = (baselineNodes) => {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];

        let newArray = firstEntity.data.excludedBaselineNode
            ? [...firstEntity.data.excludedBaselineNode]
            : [];

        if (newArray.length !== baselineNodes.length) {
            newArray = [...baselineNodes.map((node) => node.id)];
        } else {
            newArray = [];
        }
        this.submitExcludedBaselineNode(firstEntity, newArray);
    };

    onClickSignUp = () => {
        this.props.showLogin(true, SIGN_UP, INDIVIDUAL);
    };

    onChangeBaseline = (e) => {
        const baselineId = e.target.value;
        const updatedScenario = { ...this.props.loadedScenario };
        updatedScenario.baselineid = baselineId;
        store
            .dispatch(
                editScenario(updatedScenario, () => {
                    return;
                })
            )
            .then(() => {
                this.props.loadScenario(
                    this.props.loadedScenario,
                    this.props.manager,
                    this.props.baselineDataManager,
                    baselineId
                );
            });
    };

    setDefaultBaseline = () => {
        const { loadedScenario, manager, baselineDataManager } = this.props;
        if (loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }
        const baselineId = baselineDataManager.getActiveBaseline().id;
        loadedScenario.baselineid = baselineId;
        this.props.updateScenario(this.props.loadedScenario);
        this.props.loadScenario(
            this.props.loadedScenario,
            manager,
            baselineDataManager,
            baselineId
        );
    };

    render() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];
        const { birthMonth, birthYear, state, country, hasDisability } =
            firstEntity.data;
        const { version } = firstEntity;
        const baseline =
            this.props.baselineDataManager.getActiveBaseline() || {};
        const { inBaseline, loadedScenario } = this.props;
        const selectedBaselineId = loadedScenario.baselineid ?? baseline.id;
        const loggedInUser =
            JSON.parse(localStorage.getItem("loggedInUser")) || {};
        const baselines = this.props.baselineDataManager.getBaselines();
        const baselineNodes = this.props.baselineDataManager
            ? this.props.baselineDataManager.getBaseline(selectedBaselineId)
                  ?.data?.nodes ?? []
            : [];
        const nodeCount =
            baselineNodes && baselineNodes.length > 0
                ? baselineNodes.length - 1
                : 0;
        const activeBaselineIsDefault =
            !baseline || baseline.id === loadedScenario.baselineid;
        const isAgencyUser = localStorage.getItem("userRole") === "agency";

        if (!isAgencyUser) {
            return <div></div>;
        }

        return (
            <div className="Container">
                {!this.props.inBaseline && baselines.length > 0 && (
                    <div className="row">
                        {" "}
                        <select
                            className="selectBaseline"
                            onChange={this.onChangeBaseline}
                            placeholder="Choose a Baseline"
                            value={selectedBaselineId}
                        >
                            {baselines.map((bl, i) => {
                                return (
                                    <option value={bl.id} key={bl.id}>
                                        {`${bl.name}${
                                            bl.name === "Baseline"
                                                ? ` ${i + 1}`
                                                : ""
                                        } ${
                                            bl.account !== loggedInUser.account
                                                ? "(shared)"
                                                : ""
                                        }`}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
                {!this.props.inBaseline &&
                    (baselineNodes && baselineNodes.length) > 0 && (
                        <>
                            <div className="Row">
                                {" "}
                                <div
                                    onClick={this.handleEditBaseline}
                                    className="baselineButton"
                                >
                                    Edit Your Baseline Events
                                </div>
                            </div>
                            {loadedScenario.type !== "sample" && (
                                <div className="Row">
                                    {" "}
                                    <div
                                        onClick={
                                            activeBaselineIsDefault
                                                ? () => {
                                                      // no op
                                                  }
                                                : this.setDefaultBaseline
                                        }
                                        className={
                                            activeBaselineIsDefault
                                                ? "baselineButtonDisabled"
                                                : "baselineButton"
                                        }
                                    >
                                        {"Set as Default Baseline"}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                {!this.props.inBaseline && this.props.accountData && (
                    <div className="Row">
                        <div className="ExpenseLabel">{nodeCount} Nodes</div>
                    </div>
                )}

                {!this.props.inBaseline && this.props.accountData && (
                    <div className="nodesToggleContainer">
                        <img
                            alt="alt"
                            className="threadsCheckbox"
                            src={
                                this.state.allBaselineNodesSelected
                                    ? checkboxOn
                                    : checkboxOff
                            }
                            onClick={() =>
                                this.onHandleToggleAll(baselineNodes)
                            }
                        />
                        <div>Toggle all Baseline Events</div>
                    </div>
                )}
                {baselines.length === 0 &&
                    !this.props.accountData &&
                    !this.props.inBaseline && (
                        <div className="guest-no-baseline-message-wrapper">
                            <p className="guest-no-baseline-message">
                                The Baseline contains all of the Events that are
                                fixed and constant for an individual or a
                                business, such as mortgage payments, regular
                                income or bank account balances. Every scenario
                                that you create inherits this Baseline when
                                running future calculations and projections.
                            </p>
                            <p className="guest-no-baseline-message">
                                As a guest, the Baseline feature is not
                                available.{" "}
                                <span
                                    className="guest-no-baseline-cta"
                                    onClick={this.onClickSignUp}
                                >
                                    Sign up
                                </span>{" "}
                                for a free account and give it a try!
                            </p>
                        </div>
                    )}
                <div className="baselineContainer">
                    {baselineNodes &&
                        !this.props.inBaseline &&
                        baselineNodes.map((node, i) => {
                            const isBypassed =
                                this.state.excludedBaselineNode?.includes(
                                    node.id
                                );
                            const typeImagesBlack = {};

                            NodeTypesBlack.forEach((data) => {
                                typeImagesBlack[data.type] = data.image;
                            });

                            let cardStateSvg;
                            // NOTE* Used object fields instead of relevant Event functions such as
                            // isBypassed(), isNodeExpired(), isNodeOutdated() because strangely
                            // the nodes in the processed baseline of baselineDataManager do not
                            // have access to those class functions
                            if (node.bypassed) {
                                cardStateSvg = bypassedSvg;
                            } else if (node.isExpired) {
                                cardStateSvg = expiredSvg;
                            } else if (node.isOutdated) {
                                // update the expiredSvg
                                cardStateSvg = expiredSvg;
                            }

                            let image = typeImagesBlack[node.type];

                            if (node.type === "Baseline") {
                                return null;
                            } else {
                                return (
                                    <div key={i} className="meNodeContainer">
                                        <img
                                            alt="alt"
                                            src={image}
                                            className="baselineImage"
                                        />
                                        <div className="baselineText">
                                            <div className="baselineName">
                                                <div className="name">
                                                    {node.name}
                                                </div>
                                                {cardStateSvg && (
                                                    <img
                                                        alt="alt"
                                                        className="cardStateSvg"
                                                        src={cardStateSvg}
                                                    />
                                                )}
                                            </div>
                                            <div className="baselineType">
                                                {getNameFromType(node.type)}
                                            </div>
                                        </div>
                                        <img
                                            alt="checkbox"
                                            src={
                                                isBypassed
                                                    ? checkboxOff
                                                    : checkboxOn
                                            }
                                            className="baselineCheckBox"
                                            onClick={() => {
                                                this.onHandleNodeSelected(node);
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                </div>
                {inBaseline && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="Row">
                            <div className="ExpenseLabel">Country:</div>
                            <div className="cardValue">{country}</div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Province:</div>
                            <div className="cardValue">{state}</div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Birth Month:</div>
                            <div className="cardValue">{birthMonth}</div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Birth Year:</div>
                            <div className="cardValue">{birthYear}</div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Has Disability:</div>
                            <div className="cardValue">
                                {hasDisability ? "Yes" : "No"}
                            </div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Version:</div>
                            <div className="cardValue">
                                {version ? version : ""}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default StartCard;

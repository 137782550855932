// @ts-nocheck
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import * as React from "react";

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    SelectDropDown,
    Value,
    // Url,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    ModalRowTwoThirds,
    ModalRowOneThird,
    // StarsRating,
    EntityName,
    Rate,
    BasicHeading,
    ToggledContainer,
    ToggledContainerHalf,
    MuiCalendar,
    CheckBoxId,
} from "../Components";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
// import SingleCalendar from "Components/Calendar/singleCalendar";
import { loanInputsHandler } from "../OnInputChangeHandlers/loanInputsHandler";
import { loanObject } from "../../Registry/Loan";
import { getLoanPayments } from "helpers/getLoanPayments";
import { useState } from "react";

// const borrowingReasons = [
//     "Buying a car",
//     "Home renovation",
//     "Debt consolidation",
//     "Education",
//     "RRSP",
//     "Other",
// ];

const options = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const LoanInputView = ({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    passedCheck,
    edit,
    eventData,
    handleEntityStateChange,
    handleOnChange,
    onHandleClickSubmitOrUpdate,
}) => {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = loanInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const updateAccount = (accountType, id) => {
        const star = 0;
        const newEntitiesMap = loanInputsHandler(
            JSON.stringify(accountType),
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const curEntityObject = entitiesMap[currentEntity];
    const interestPayments = getLoanPayments(
        curEntityObject?.data?.loan,
        curEntityObject?.data?.interestRate,
        curEntityObject?.data?.amortization,
        curEntityObject?.cadence
    );

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={loanObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={loanObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[loanObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                {creatingCustomAccounts ? (
                    <div>Creating custom accounts...</div>
                ) : (
                    <>
                        <ModalRow>
                            <RequiredStar />
                            <EntityName
                                name={entitiesMap?.[currentEntity]?.name}
                                onChangeInput={handleOnChange}
                                entityData={entitiesMap?.[currentEntity]}
                                handleEntityStateChange={
                                    handleEntityStateChange
                                }
                            />
                        </ModalRow>
                        <ToggledContainer>
                            <ToggledContainerHalf
                                id="standard"
                                isSelected={
                                    entitiesMap[currentEntity]?.data
                                        ?.loanType === "standard"
                                }
                                onClick={handleOnChange}
                                title="Standard Loan"
                            />
                            <ToggledContainerHalf
                                id="revenueBased"
                                isSelected={
                                    entitiesMap[currentEntity]?.data
                                        ?.loanType === "revenueBased"
                                }
                                onClick={handleOnChange}
                                title="Revenue Based Financing"
                            />
                        </ToggledContainer>
                        {entitiesMap[currentEntity]?.data?.loanType ===
                            "standard" && (
                            <>
                                <ModalRow>
                                    <BasicHeading copy="Loan Details" />
                                </ModalRow>
                                <ModalRow cssOverrides={{ gap: "1rem" }}>
                                    <ModalRowHalf>
                                        <Value
                                            label="Loan Amount"
                                            onChangeInput={handleOnChange}
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.loan
                                            }
                                            id="loan"
                                            unit="$"
                                            variant={"outlined"}
                                            required={true}
                                        />
                                    </ModalRowHalf>
                                    <ModalRowOneThird>
                                        <Rate
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.interestRate
                                            }
                                            label="Interest Rate"
                                            className="mlsInput"
                                            id="interestRate"
                                            onChangeInput={handleOnChange}
                                            sign={true}
                                            required={true}
                                        />
                                    </ModalRowOneThird>
                                </ModalRow>
                                <ModalRow cssOverrides={{ gap: "1rem" }}>
                                    <ModalRowHalf>
                                        <SelectDropDown
                                            onChangeInput={handleOnChange}
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.termUnit
                                            }
                                            options={["Years", "Months"]}
                                            className="select-dropdown"
                                            id="termUnit"
                                            label="Select Term Unit"
                                            required={true}
                                        />
                                    </ModalRowHalf>
                                    <ModalRowOneThird>
                                        <Rate
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.numTermUnits
                                            }
                                            label="Term Length"
                                            id="numTermUnits"
                                            className="mlsInput"
                                            onChangeInput={handleOnChange}
                                            inputAdornmentOverride={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.termUnit
                                            }
                                            sign={false}
                                            required={true}
                                        />
                                    </ModalRowOneThird>
                                </ModalRow>
                                <ModalRow>
                                    <ModalRowHalf>
                                        <MuiCalendar
                                            id="loanStart"
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.startDate === ""
                                                    ? null
                                                    : entitiesMap?.[
                                                          currentEntity
                                                      ]?.startDate
                                            }
                                            required={true}
                                            onChangeInput={handleDateSelection}
                                            label="Loan Start"
                                        />
                                    </ModalRowHalf>
                                </ModalRow>
                                <ModalRow>
                                    <BasicHeading
                                        copy="Payment Details"
                                        margin="2.5rem 0 0"
                                    />
                                </ModalRow>
                                <ModalRow twoInputs>
                                    <ModalRowHalf>
                                        <SelectDropDown
                                            onChangeInput={handleOnChange}
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.cadence
                                            }
                                            options={options}
                                            className="select-dropdown"
                                            id="cadence"
                                            label="Payment Frequency"
                                            required={true}
                                        />
                                    </ModalRowHalf>
                                    <ModalRowHalf>
                                        <CheckBoxId
                                            label="Interest Payments Only"
                                            id="interestPaymentsOnly"
                                            isActive={
                                                entitiesMap[currentEntity]?.data
                                                    ?.interestPaymentsOnly
                                            }
                                            onClick={handleOnChange}
                                        />
                                    </ModalRowHalf>
                                </ModalRow>
                                <ModalRow>
                                    <div className="loanPaymentsContainer">
                                        <div className="paymentsAmountContainer">
                                            <div className="paymentsLabel">
                                                For this Loan, your approximate
                                                payment <br />
                                                <span className="cadenceHolder">
                                                    {entitiesMap?.[
                                                        currentEntity
                                                    ]?.cadence
                                                        ? entitiesMap?.[
                                                              currentEntity
                                                          ]?.cadence
                                                        : "frequency payment"}
                                                </span>{" "}
                                                will be
                                            </div>
                                            <div className="paymentsValue">
                                                {formatter.format(
                                                    interestPayments.payments
                                                )}
                                            </div>
                                        </div>

                                        <div className="paymentsSubLabel">
                                            You&rsquo;ll pay{" "}
                                            {formatter.format(
                                                interestPayments.termInterest
                                            )}{" "}
                                            in interest after{" "}
                                            {entitiesMap?.[currentEntity]?.data
                                                ?.numTermUnits || 0}{" "}
                                            {entitiesMap?.[
                                                currentEntity
                                            ]?.data?.termUnit?.toLowerCase() ||
                                                "years"}
                                            . <br /> This is the total interest
                                            paid throughout the duration of the
                                            loan.
                                        </div>
                                    </div>
                                </ModalRow>
                            </>
                        )}
                        {entitiesMap[currentEntity]?.data?.loanType ===
                            "revenueBased" && (
                            <>
                                <ModalRow>
                                    <BasicHeading copy="Loan Details" />
                                </ModalRow>
                                <ModalRow>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={
                                            entitiesMap[currentEntity]?.data
                                                ?.interestVsPremium
                                        }
                                        name="radio-buttons-group"
                                        onChange={(e) =>
                                            handleOnChange(
                                                e,
                                                "interestVsPremium",
                                                1
                                            )
                                        }
                                    >
                                        <FormControlLabel
                                            value="premium"
                                            sx={{
                                                color: "rgba(0, 0, 0, 0.87)",
                                            }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: "#f7ab5f",
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Loan + Premium"
                                        />
                                        <FormControlLabel
                                            value="interest"
                                            sx={{
                                                color: "rgba(0, 0, 0, 0.87)",
                                            }}
                                            control={
                                                <Radio
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: "#f7ab5f",
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Loan + Interest"
                                        />
                                    </RadioGroup>
                                </ModalRow>
                                <ModalRow twoInputs>
                                    {entitiesMap[currentEntity]?.data
                                        ?.interestVsPremium === "premium" ? (
                                        <>
                                            <ModalRowHalf>
                                                <Value
                                                    label="Loan Amount"
                                                    onChangeInput={
                                                        handleOnChange
                                                    }
                                                    value={
                                                        entitiesMap?.[
                                                            currentEntity
                                                        ]?.data?.loan
                                                    }
                                                    id="loan"
                                                    unit="$"
                                                    variant={"outlined"}
                                                    required={true}
                                                />
                                            </ModalRowHalf>
                                            <ModalRowHalf>
                                                <Value
                                                    label="Loan Premium"
                                                    onChangeInput={
                                                        handleOnChange
                                                    }
                                                    value={
                                                        entitiesMap?.[
                                                            currentEntity
                                                        ]?.data?.loanPremium
                                                    }
                                                    id="loanPremium"
                                                    unit="$"
                                                    variant={"outlined"}
                                                    required={true}
                                                />
                                            </ModalRowHalf>
                                        </>
                                    ) : (
                                        <>
                                            <ModalRowTwoThirds>
                                                <Value
                                                    label="Loan Amount"
                                                    onChangeInput={
                                                        handleOnChange
                                                    }
                                                    value={
                                                        entitiesMap?.[
                                                            currentEntity
                                                        ]?.data?.loan
                                                    }
                                                    id="loan"
                                                    unit="$"
                                                    variant={"outlined"}
                                                    required={true}
                                                />
                                            </ModalRowTwoThirds>
                                            <ModalRowOneThird>
                                                <Rate
                                                    value={
                                                        entitiesMap?.[
                                                            currentEntity
                                                        ]?.data?.interestRate
                                                    }
                                                    label="Interest Rate"
                                                    className="mlsInput"
                                                    id="interestRate"
                                                    onChangeInput={
                                                        handleOnChange
                                                    }
                                                    sign={true}
                                                    required={true}
                                                />
                                            </ModalRowOneThird>
                                        </>
                                    )}
                                </ModalRow>
                                <ModalRow>
                                    <ModalRowHalf>
                                        <MuiCalendar
                                            id="loanStart"
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.startDate === ""
                                                    ? null
                                                    : entitiesMap?.[
                                                          currentEntity
                                                      ]?.startDate
                                            }
                                            required={true}
                                            onChangeInput={handleDateSelection}
                                            label="Loan Start"
                                        />
                                    </ModalRowHalf>
                                </ModalRow>

                                <ModalRow>
                                    <BasicHeading
                                        copy="Payment Details"
                                        margin="2.5rem 0 0"
                                    />
                                </ModalRow>
                                <ModalRow twoInputs>
                                    <ModalRowTwoThirds>
                                        <InputDropDownButton
                                            id="revenueAccount"
                                            required={true}
                                            helperText={
                                                "Revenue that will be used as a source for calculations"
                                            }
                                            buttonLabel="Source Revenue Account"
                                            topLevelFilters={{
                                                "488dd61d-8697-4213-8978-cf91755365a4":
                                                    true,
                                            }}
                                            callback={updateAccount}
                                            initialValue={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.dependantAccount ??
                                                ""
                                            }
                                        />
                                    </ModalRowTwoThirds>
                                    <ModalRowOneThird>
                                        <Rate
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.revenuePercentage
                                            }
                                            label="Percentage of Revenue"
                                            className="mlsInput"
                                            id="revenuePercentage"
                                            onChangeInput={handleOnChange}
                                            sign={true}
                                            helperText={
                                                "Percent of revenue applied to the loan"
                                            }
                                            required={true}
                                        />
                                    </ModalRowOneThird>
                                </ModalRow>
                                <ModalRow>
                                    <ModalRowHalf>
                                        <MuiCalendar
                                            id="paymentStart"
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.paymentStart === ""
                                                    ? null
                                                    : entitiesMap?.[
                                                          currentEntity
                                                      ]?.data?.paymentStart
                                            }
                                            required={true}
                                            onChangeInput={handleDateSelection}
                                            label="Payment Start"
                                            minDate={
                                                entitiesMap?.[currentEntity]
                                                    ?.startDate ?? ""
                                            }
                                        />
                                    </ModalRowHalf>
                                </ModalRow>
                            </>
                        )}
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmitValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmit={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                passedCheck={creatingCustomAccounts ? false : passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default LoanInputView;

const cellFormatter = (value, formatting) => {
    if (!formatting) return value;
    let formattedValue = value;
    if (typeof value === "number") {
        const numberValue = value.toFixed(2);
        const formNumber = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        formattedValue = formNumber;
    }
    if (formatting?.beforeValue) {
        formattedValue = `${formatting?.display ?? ""}${
            formatting?.spacingBetween ? " " : ""
        }${formattedValue}`;
    } else {
        formattedValue = `${formattedValue}${
            formatting?.spacingBetween ? " " : ""
        }${formatting?.display ?? ""}`;
    }

    return formattedValue;
};

export const getAccountColumnData = (
    selectedAccount,
    currentThreadSelectedLedgerData
) => {
    const allColumns: any[] = [
        {
            headerName: "Id",
            field: "id",
            pinned: "left",
            hide: true,
        },
        {
            headerName: "Account",
            field: "accountName",
            pinned: "left",
            hide: false,
            width: 200,
        },
    ];

    const allMonthlyDatesArray = Object.keys(
        currentThreadSelectedLedgerData ?? {}
    );

    allMonthlyDatesArray.forEach((date) => {
        const columnObject = {
            headerName: date,
            field: date,
            sortable: true,
            filter: true,
            editable: false,
            valueFormatter: (params) => {
                if (!params?.value && params?.value !== 0) return;
                return cellFormatter(params?.value, selectedAccount?.unit);
            },
            cellStyle: (params) => {
                const { smallestValue, largestValue } = params?.data;
                const value = params?.value;

                if (value > 0) {
                    const percentage = value / largestValue;
                    if (percentage === 1) {
                        return { backgroundColor: `rgba(119, 221, 118, 1)` };
                    } else if (percentage > 0.66) {
                        return {
                            backgroundColor: `rgba(119, 221, 118, ${percentage})`,
                        };
                    } else if (percentage > 0.33) {
                        const relativePercentage =
                            value / (0.66 * largestValue);
                        return {
                            backgroundColor: `rgba(189, 231, 189, ${relativePercentage})`,
                        };
                    } else if (percentage > 0.0) {
                        const relativePercentage =
                            value / (0.33 * largestValue);
                        return {
                            backgroundColor: `rgba(231, 241, 232, ${relativePercentage})`,
                        };
                    }
                } else if (value < 0) {
                    const percentage = value / smallestValue;
                    if (percentage === 1) {
                        return { backgroundColor: `rgba(255, 105, 98, 1)` };
                    } else if (percentage > 0.66) {
                        return {
                            backgroundColor: `rgba(255, 105, 98, ${percentage})`,
                        };
                    } else if (percentage > 0.33) {
                        const relativePercentage =
                            value / (0.66 * smallestValue);
                        return {
                            backgroundColor: `rgba(255, 182, 179, ${relativePercentage})`,
                        };
                    } else if (percentage > 0) {
                        const relativePercentage =
                            value / (0.33 * smallestValue);
                        return {
                            backgroundColor: `rgba(255, 213, 212, ${relativePercentage})`,
                        };
                    }
                } else {
                    return { backgroundColor: `rgba(255, 244, 0, 0.125)` };
                }
                return null;
            },
        };

        allColumns.push(columnObject);
    });

    return allColumns;
};

export const getAccountRowData = (
    availableChildAccounts,
    currentThreadLedgerData,
    cumulative
) => {
    const allAccountDataRows: any = [];
    let smallestValue = null;
    let largestValue = null;

    availableChildAccounts.forEach((childAccount) => {
        const entityRowData = {
            id: childAccount?.id,
            accountName: childAccount?.name,
        };

        const currentThreadSelectedLedgerData = cumulative
            ? currentThreadLedgerData?.[`Cumulative-${childAccount?.id}`]
            : currentThreadLedgerData?.[childAccount?.id];
        const selectedLedgerValues: any = Object.values(
            currentThreadSelectedLedgerData ?? {}
        );
        if (selectedLedgerValues?.length > 0) {
            const accountAllsMap = selectedLedgerValues.map(
                (accountValues) => accountValues?._All
            );
            //@ts-ignore
            accountAllsMap.sort((a, b) => a - b);
            const ledgerSmallestValue = accountAllsMap?.[0];
            const ledgerLargestValue =
                accountAllsMap?.[accountAllsMap?.length - 1];
            if (smallestValue === null || ledgerSmallestValue < smallestValue)
                smallestValue = ledgerSmallestValue;
            if (largestValue === null || ledgerLargestValue > largestValue)
                largestValue = ledgerLargestValue;
        }

        for (const date in currentThreadSelectedLedgerData) {
            const value = currentThreadSelectedLedgerData?.[date]?._All ?? 0;
            entityRowData[date] = value;
        }
        allAccountDataRows.push(entityRowData);
    });

    const finalAccountDataRows = allAccountDataRows?.map((rowData) => {
        return {
            ...rowData,
            smallestValue,
            largestValue,
        };
    });

    return finalAccountDataRows;
};

import { EventsModal } from "../EventsModal";
import { useCookies } from "react-cookie";
import moment from "moment";

export const ExpiredEventModal = ({
    onClose,
    expiredNodes,
    expiredNodeSvg,
}) => {
    const [cookies, setCookies] = useCookies(["expiredEventDismissCookie"]);

    const handleClose = () => {
        const tomorrow = moment().add(1, "days").format();
        setCookies("expiredEventDismissCookie", true, {
            expires: new Date(tomorrow),
        });
        onClose();
    };

    if (cookies?.expiredEventDismissCookie === "true") return <></>;

    return (
        //@ts-ignore
        <EventsModal onHandleClose={handleClose} confirm={true}>
            <div className="headerText">
                <span>
                    You have {expiredNodes.length}{" "}
                    {expiredNodes.length > 1 ? "Events" : "Event"} expired in
                    this scenario
                </span>
            </div>
            <img alt="alt" src={expiredNodeSvg} className="expiredNodeSvg" />
            <div className="subHeaderText">
                Some of the Events in this Scenario are past their end date and
                will no longer be included in the current calculations. To
                include them, select the grey Events and update the end dates.
            </div>
            <div className="promptButtonContainer">
                <div onClick={handleClose} className="buttonOrange">
                    Got it
                </div>
            </div>
        </EventsModal>
    );
};

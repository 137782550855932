import React, { Component } from "react";
import { toast } from "react-toastify";
import { Router, Switch, Route } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { config } from "./config";
import { LoginModalConnected as LoginModal } from "./Components/LoginModal";
import history from "./History";
import Admin from "../src/routes/admin";
import { connect } from "react-redux";
import * as actions from "./actions/scenario";
import * as eventActions from "./actions/canvasEventActions";
import * as lineActions from "./actions/canvasLineActions";
import { upsertEventsTemplates } from "actions/eventsTemplatesActions";
import { upsertCardTemplates } from "actions/canvasCardTemplatesActions";
import { upsertMenusTemplates } from "actions/menusTemplatesActions";
import { upsertActionsData } from "actions/canvasActionsDataActions";
import { upsertEntities } from "./actions/entitiesActions";
import { getAllEventDetails } from "./actions/scenario";

import successSvg from "./Assets/check.svg";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import MainContainer from "./Container/Main";
import Baseline from "./routes/baseline";
import CsvImport from "./routes/csvImport";
import Header from "./Components/Header";
import Footer from "./Components/Footer/Footer";
import ShareScenario from "./Components/ShareScenario";
import Onboarding from "./Components/Onboarding";
import Settings from "../src/routes/Settings";
import * as Sentry from "@sentry/browser";
import Billing from "./Components/Billing";
import {
    EventsToast,
    DeleteEventToast,
    DisconnectionWarning,
} from "./Components/EventsToast";
import PlanCard from "./Components/Onboarding/Plan";
import Home from "./routes/home";
import { PLAN, ONBOARDING } from "./actions/types";
import { EventManager } from "./Events";
import TagManager from "react-gtm-module";
import BaselineDataManager from "./Events/baselineManager";
import { LOG_IN, SIGN_UP, AGENCY, BUSINESS } from "./helpers/constants";
import { throwError } from "./helpers/swalError";
import SplashScreen from "./Components/SplashScreen/splashScreen";
import { isActiveUserAdmin } from "./helpers/userHelpers";
import CanvasEventTemplates from "./exampleConfigJson/canvasEventTemplates.json";
import CanvasCardTemplates from "./exampleConfigJson/canvasCardTemplates.json";
import CanvasEventMenuTemplates from "./exampleConfigJson/canvasEventMenuTemplates.json";
import CanvasActionsData from "./exampleConfigJson/canvasActionsData.json";
import StudentsLanding from "routes/students/StudentsLanding";
import Modal from "@mui/material/Modal";
import { CustomAccountModal } from "Components/CustomAccountModal";
import { OnboardingModal } from "Components/CanvasOnboardingModal";
import { AccountPickerModal } from "Components/AccountPickerModal";
import { AccountCardModal } from "Components/AccountCardModal";
import { closeCustomAccountModal } from "actions/customAccountActions";
import { closeAccountPickerModal } from "actions/accountPickerActions";
import { closeAccountMappingModal } from "actions/accountMappingActions";
import { closeAccountFieldMappingModal } from "actions/accountFieldMappingActions";
import { closeAccountCardModal } from "actions/canvasCardModalsActions";
import { closeCellRowModal } from "actions/modalActions";

const tagManagerArgs = {
    gtmId: "GTM-NMNPS9Q",
};
TagManager.initialize(tagManagerArgs);

import { AccountMappingModal } from "Components/AccountMappingModal/accountMappingModal";
import { AccountFieldMappingModal } from "Components/AccountFieldMappingModal/accountFieldMappingModal";
import { CellRowModal } from "Components/CellRowModal/CellRowModal";
import { ChartModal } from "Components/InputContainer/ChartModal/ChartModal";

toast.configure();

class PrivacyPolicy extends Component {
    UNSAFE_componentWillMount() {
        axios.get(`${config.host}/test`).then((res) => {
            console.log(res, "<--- res!!!");
        });
    }

    render() {
        return <div className="Privacy">Placeholder Privacy Policy</div>;
    }
}

class App extends Component {
    constructor() {
        super();

        this.baselineManager = new EventManager(
            this.updateCanvas,
            this.updateScenarioCanvas
        );

        this.baselineDataManager = new BaselineDataManager(
            this.updateCanvas,
            this.updateScenarioCanvas
        );

        this.state = {
            iconSelected: null,
            showModal: false,
            inflation: 1.9,
            interest: 0.0,
            appreciation: 0.0,
            loggedInUser: false,
            toastData: null,
            baselineNodes: null,
            disclaimerAccepted: false,
            selectedSampleScenario: null,
        };
    }

    updateCanvas = () => {
        this.setState({ baselineNodes: this.baselineDataManager.allNodes() });
        // this.exportForCalculations(details);
    };

    // this is for BASELINE manager only...
    updateScenarioCanvas = (nodeData, isBaseline) => {
        const { loadedScenario, manager } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const baselineDataManager = this.baselineDataManager;
        if (loggedInUser) {
            const userRole = localStorage.getItem("userRole");
            if (userRole === AGENCY)
                if (
                    loadedScenario &&
                    loadedScenario.data.root === nodeData.root
                ) {
                    const newScenario = {
                        ...loadedScenario,
                        data: nodeData,
                    };

                    this.props
                        .updateBaseline(newScenario, baselineDataManager)
                        .then((data) => {
                            if (data) {
                                this.props.loadScenario(
                                    newScenario,
                                    manager,
                                    baselineDataManager,
                                    null,
                                    null,
                                    isBaseline
                                );
                            }
                        })
                        .catch((err) => {
                            console.log(err, "<==== err");
                        });
                }
        } else {
            const newScenario = {
                ...loadedScenario,
                data: nodeData,
            };
            this.props.loadScenario(newScenario, manager, baselineDataManager);
        }
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.accountData &&
            prevProps.accountData !== this.props.accountData
        ) {
            this.setLoggedInUser(this.props.accountData);
        }
    }

    onHandleClose = () => {
        this.props.closeModal();
        this.props.loadScenario(
            this.props.loadedScenario,
            this.props.manager,
            this.props.baselineDataManager
        );
    };

    onHandleAcceptDisclaimer = () => {
        this.setState({ disclaimerAccepted: true });
    };

    setLoggedInUser = (loggedInUser) => {
        const { name, email, account, token, image, userRole } = loggedInUser;
        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
        this.setState({
            name,
            account,
            email,
            token,
            image,
            userRole,
        });
        this.props.showLogin(false);
        if (!loggedInUser.plan)
            this.props.getSubscription(loggedInUser, (err, data) => {
                if (data && !data.data.plan) {
                    this.createAndUpdateFreePlan(loggedInUser);
                }
            });
        if (!this.baselineDataManager) {
            this.baselineDataManager = new BaselineDataManager();
        }
        this.props.getBaseline(this.baselineDataManager);
        if (!this.props.agencyClients || this.props.agencyClients.length === 0)
            this.props.getAgencyClients();
        if (!this.props.eventLibrary) this.props.getLibraryEvents();
        this.props.getTemplates();
        this.props.getUserScenarios();
        this.props.getUserEntities();
        this.props.getUserOverrides();
        this.props.getUserAccount();
        this.props.getUserAccountMapping();
        this.props.setBaselineManager(this.baselineManager);
        this.props.setBaselineDataManager(this.baselineDataManager);
    };

    createAndUpdateFreePlan = (loggedInUser) => {
        const createPlanCb = () => {
            this.props.getSubscription(loggedInUser, (err) => {
                if (err)
                    throwError(
                        "error",
                        "Subscription Failed",
                        "Something went wrong retrieving your subscription data."
                    );
            });
        };
        if (loggedInUser.userRole === "student") {
            // noop, we create student account after
        } else {
            this.props.createFreePlan(loggedInUser).then(createPlanCb);
        }
    };
    verifySession = (loggedInUser) => {
        const { name, email, account, token, image, data, settingsdata } =
            loggedInUser;

        const renewToken = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                email,
                account,
            },
            url: `${config.host}/auth/token`,
        };

        axios(renewToken)
            .then((response) => {
                if (response.status === 200) {
                    const renewedToken = response.data.token;

                    this.setState({
                        name,
                        account,
                        email,
                        image,
                        token: renewedToken,
                        data,
                        settingsdata,
                    });

                    window.intercomSettings = {
                        app_id: "a0xv082m",
                        name, // Full name
                        email, // Email address
                    };
                }
            })
            .catch((error) => {
                console.log(error, "<---- ERROR verifySession");
                localStorage.removeItem("loggedInUser");
                swal({
                    icon: "warning",
                    title: "Session Expired",
                    text: "Your session has expired, please login.",
                });
                Sentry.withScope((scope) => {
                    scope.setExtras(loggedInUser);
                    Sentry.captureException(error);
                });
            });
    };

    logout = () => {
        localStorage.clear();
        this.setState({
            name: null,
            account: null,
            email: null,
            token: null,
            image: null,
            data: null,
            settingsdata: null,
        });
        this.props.showLogin(false);
        history.push("/");
        window.location.reload();
    };

    componentDidMount() {
        this.props.getTemplates();
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            this.verifySession(loggedInUser);
            this.props.getUserData(loggedInUser);
            this.props.getBaseline(this.baselineDataManager);
            if (
                !this.props.agencyClients ||
                this.props.agencyClients.length === 0
            )
                this.props.getAgencyClients();
            this.props.getSubscription(loggedInUser, (err, data) => {
                if (data && !data.data.plan) {
                    // console.log("HERE3333");
                    this.createAndUpdateFreePlan(loggedInUser);
                }
            });
            this.props.setBaselineManager(this.baselineManager);
            this.props.getLibraryEvents();
            this.props.getUserScenarios();
            this.props.getUserAccountMapping();
            this.setState({ loggedInUser: true });
        }
        this.props.getUserAccount();

        if (!this.props.baselineDataManager) {
            this.props.setBaselineDataManager(this.baselineDataManager);
        }

        if (history.location.pathname !== "/") {
            this.props.selectTab("/");
        } else {
            // if (!process.env.REACT_APP_ENV === "development") {
            this.props.selectTab("/home");
            history.push("/home");
            // }
        }
        this.props.upsertEventsTemplates(CanvasEventTemplates);
        this.props.upsertCardTemplates(CanvasCardTemplates);
        this.props.upsertMenusTemplates(CanvasEventMenuTemplates);
        this.props.upsertActionsData(CanvasActionsData);
        this.props.getAllEventDetails();
    }

    openLogin = () => {
        this.props.showLogin(true, LOG_IN);
    };

    showSignUp = () => {
        this.props.showLogin(true, SIGN_UP, BUSINESS);
    };

    closeLogin = () => {
        this.props.showLogin(false);
    };

    onHoverSetting = (event) => {
        const id = event.target.id;
        this.setState({ iconSelected: id, showModal: true });
    };

    onHoverAccount = (event) => {
        const id = event.target.id;
        this.setState({ iconSelected: id, showModal: true });
    };

    onMouseLeave = () => {
        this.setState({ iconSelected: null, showModal: false });
    };

    onHandleInflation = (event) => {
        const id = event.target.id;
        if (id === "inflationPlus") {
            this.setState({ inflation: this.state.inflation + 0.1 });
        } else if (id === "inflationMinus") {
            this.setState({ inflation: this.state.inflation - 0.1 });
        }
    };

    onHandleInterest = (event) => {
        const id = event.target.id;
        if (id === "interestPlus") {
            this.setState({ interest: this.state.interest + 0.1 });
        } else if (id === "interestMinus") {
            this.setState({ interest: this.state.interest - 0.1 });
        }
    };

    onHandleAppreciation = (event) => {
        const id = event.target.id;
        if (id === "appreciationPlus") {
            this.setState({ appreciation: this.state.appreciation + 0.1 });
        } else if (id === "appreciationMinus") {
            this.setState({
                appreciation: this.state.appreciation - 0.1,
            });
        }
    };

    cancelToast = () => {
        this.setState({ toastData: null });
    };

    onHandleToast = (toastData) => {
        this.setState(
            {
                toastData,
            },
            () => {
                setTimeout(() => {
                    this.setState({ toastData: null });
                }, 7000);
            }
        );
    };

    componentDidCatch() {
        throwError(
            "error",
            "Something Went Wrong!",
            "Please reload the page to continue"
        );
    }

    handleClickSampleScenarioCard = (scenario) => {
        this.setState({ selectedSampleScenario: scenario });
    };

    render() {
        const { shareScenario } = this.props;
        const { showModal } = this.state;
        const {
            account,
            iconSelected,
            image,
            name,
            inflation,
            interest,
            appreciation,
            token,
            email,
        } = this.state;
        const showSplashScreen =
            window.innerWidth < 813 &&
            history.location.pathname !== "/students";

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        return (
            <div className="App">
                {showSplashScreen && <SplashScreen />}
                <div id="header">
                    {history.location.pathname !== "/students" && (
                        <Header
                            account={account}
                            onHoverSetting={this.onHoverSetting}
                            onHoverAccount={this.onHoverAccount}
                            showModal={showModal}
                            iconSelected={iconSelected}
                            onMouseLeave={this.onMouseLeave}
                            onHandleInflation={this.onHandleInflation}
                            image={image && image}
                            name={name}
                            logout={this.logout}
                            inflation={inflation}
                            onHandleInterest={this.onHandleInterest}
                            interest={interest}
                            onHandleAppreciation={this.onHandleAppreciation}
                            appreciation={appreciation}
                            token={token}
                            history={history}
                            email={email}
                        />
                    )}

                    {this.props.showModal && (
                        <div className="SuccesModal">
                            <img
                                alt="alt"
                                src={successSvg}
                                className="successSvg"
                            />
                            <div className="SuccesModalLabel">
                                Update Success
                            </div>
                            <div
                                onClick={this.onHandleClose}
                                className="SuccesModalClose"
                            >
                                Close
                            </div>
                        </div>
                    )}
                    {this.props.loginPopUp && (
                        <LoginModal
                            promptLogin={
                                this.state.account == null &&
                                this.props.loginPopUp
                            }
                            setLoggedInUser={this.setLoggedInUser}
                            closeLogin={this.closeLogin}
                            openLogin={this.openLogin}
                            openSignup={this.showSignUp}
                            signup={this.props.signup}
                            login={this.props.login}
                            getUserData={this.props.getUserData}
                            resetPassword={this.props.resetPassword}
                            resetCode={this.props.resetCode}
                            updatePassword={this.props.updatePassword}
                            loginPopUpValue={this.props.loginPopUpValue}
                            accountSelected={this.props.accountSelected}
                            toggleAccountType={this.props.toggleAccountType}
                            createFreePlan={this.props.createFreePlan}
                        />
                    )}
                </div>
                <Modal
                    open={this.props.accountMappingModalOpen}
                    onClose={this.props.closeAccountMappingModal}
                >
                    <div>
                        <AccountMappingModal />
                    </div>
                </Modal>
                <Modal
                    open={this.props.accountFieldMappingModalOpen}
                    onClose={this.props.closeAccountFieldMappingModal}
                >
                    <div>
                        <AccountFieldMappingModal />
                    </div>
                </Modal>
                <Modal
                    open={this.props.customAccountModalOpen}
                    onClose={this.props.closeCustomAccountModal}
                >
                    <div>
                        <CustomAccountModal />
                    </div>
                </Modal>
                <Modal
                    open={this.props.accountPickerModalOpen}
                    onClose={this.props.closeAccountPickerModal}
                >
                    <div>
                        <AccountPickerModal />
                    </div>
                </Modal>
                <Modal
                    open={this.props.cellRowModalOpen}
                    onClose={this.props.closeCellRowModal}
                >
                    <div>
                        <CellRowModal />
                    </div>
                </Modal>
                <Modal open={this.props.chartModalOpen}>
                    <div>
                        <ChartModal />
                    </div>
                </Modal>
                <Modal
                    open={this.props.createAccountCardModalOpen}
                    onClose={this.props.closeAccountCardModal}
                >
                    <AccountCardModal />
                </Modal>
                <Modal open={this.props.onboardingModalOpen}>
                    <div>
                        <OnboardingModal />
                    </div>
                </Modal>
                {shareScenario && (
                    <ShareScenario
                        history={history}
                        onHandleToast={this.onHandleToast}
                    />
                )}

                {this.props.signupState === ONBOARDING && (
                    <Onboarding history={history} />
                )}
                {this.props.signupState === PLAN &&
                    this.props.accountData?.plan?.id && (
                        <PlanCard
                            history={history}
                            setOnboardingState={this.props.setOnboardingState}
                            setOnboardingData={this.props.setOnboardingData}
                            manager={this.props.manager}
                            loadScenario={this.props.loadScenario}
                            userScenarios={this.props.userScenarios}
                            onboardingScenario={this.props.onboardingScenario}
                            userPlanData={this.props.accountData.plan}
                        />
                    )}

                {this.props.deletionData && (
                    <DeleteEventToast
                        eventName={this.props.deletionData?.root?.name}
                    >
                        <div className="promptButtonContainer">
                            <div
                                onClick={() =>
                                    this.props.addEventForDeletion(null)
                                }
                                className="deletePromptButton"
                            >
                                Cancel
                            </div>
                            <div
                                onClick={() => this.props.deleteEvents()}
                                className="deletePromptButton"
                            >
                                Yes
                            </div>
                        </div>
                    </DeleteEventToast>
                )}

                {this.props.lineDisconnection.lineId && (
                    <DisconnectionWarning>
                        <div className="promptButtonContainer">
                            <div
                                onClick={() =>
                                    this.props.selectLineForDisconnection(null)
                                }
                                className="deletePromptButton"
                            >
                                Cancel
                            </div>
                            <div
                                onClick={() => this.props.disconnectLine()}
                                className="deletePromptButton"
                            >
                                Yes
                            </div>
                        </div>
                    </DisconnectionWarning>
                )}

                {this.state.toastData && (
                    <EventsToast
                        data={this.state.toastData}
                        close={this.cancelToast}
                        history={history}
                    />
                )}
                <div id="container">
                    <Router history={history}>
                        <Switch>
                            <Route
                                path="/billing"
                                render={(routeProps) => (
                                    <Billing
                                        {...routeProps}
                                        history={history}
                                        openLogin={this.openLogin}
                                        showSignUp={this.showSignUp}
                                    />
                                )}
                            />
                            <Route path="/privacy" component={PrivacyPolicy} />
                            <Route path="/csv" component={CsvImport} />
                            <Route
                                path="/settings"
                                render={(routeProps) => (
                                    <Settings
                                        history={history}
                                        {...routeProps}
                                    />
                                )}
                            />
                            <Route
                                path="/settings/:id"
                                render={(routeProps) => (
                                    <Settings
                                        history={history}
                                        {...routeProps}
                                    />
                                )}
                            />
                            {isActiveUserAdmin() ? (
                                <Route
                                    path="/admin"
                                    render={(routeProps) => (
                                        <Admin
                                            history={history}
                                            {...routeProps}
                                        />
                                    )}
                                />
                            ) : (
                                <Route
                                    path="/admin"
                                    render={(routeProps) => (
                                        <MainContainer
                                            {...routeProps}
                                            openLogin={this.openLogin}
                                            closeLogin={this.state.closeLogin}
                                            baselineDataManager={
                                                this.baselineDataManager
                                            }
                                        />
                                    )}
                                />
                            )}
                            <Route
                                path="/shareScenario/:id"
                                render={(routeProps) => (
                                    <MainContainer
                                        {...routeProps}
                                        openLogin={this.openLogin}
                                        closeLogin={this.state.closeLogin}
                                        baselineDataManager={
                                            this.baselineDataManager
                                        }
                                    />
                                )}
                                onEnter={(routeProps) => {
                                    console.log(routeProps.match.params.id);
                                }}
                            />
                            {/* <Route
                                path="/baseline"
                                render={() => <Redirect to="/" />}
                            /> */}
                            <Route
                                path="/baseline"
                                render={(routeProps) => (
                                    <Baseline
                                        {...routeProps}
                                        manager={this.props.manager}
                                        baselineDataManager={
                                            this.baselineDataManager
                                        }
                                    />
                                )}
                            />

                            <Route
                                path="/home"
                                render={(routeProps) => (
                                    <Home
                                        {...routeProps}
                                        manager={this.props.manager}
                                        selectedSampleScenario={
                                            this.state.selectedSampleScenario
                                        }
                                        handleClickSampleScenarioCard={
                                            this.handleClickSampleScenarioCard
                                        }
                                        openLogin={this.openLogin}
                                    />
                                )}
                            />

                            <Route
                                path="/students"
                                render={() => (
                                    <StudentsLanding
                                        loggedInUser={loggedInUser}
                                        history={history}
                                    />
                                )}
                            />

                            <Route
                                path="/dashboard"
                                render={(routeProps) => (
                                    <MainContainer
                                        {...routeProps}
                                        openLogin={this.openLogin}
                                        closeLogin={this.state.closeLogin}
                                        baselineDataManager={
                                            this.baselineDataManager
                                        }
                                        onHandleAcceptDisclaimer={
                                            this.onHandleAcceptDisclaimer
                                        }
                                        disclaimerAccepted={
                                            this.state.disclaimerAccepted
                                        }
                                        accountData={this.props.accountData}
                                        selectedSampleScenario={
                                            this.state.selectedSampleScenario
                                        }
                                        handleClickSampleScenarioCard={
                                            this.handleClickSampleScenarioCard
                                        }
                                    />
                                )}
                            />

                            <Route
                                path="/"
                                render={(routeProps) => (
                                    <MainContainer
                                        {...routeProps}
                                        openLogin={this.openLogin}
                                        closeLogin={this.state.closeLogin}
                                        baselineDataManager={
                                            this.baselineDataManager
                                        }
                                        onHandleAcceptDisclaimer={
                                            this.onHandleAcceptDisclaimer
                                        }
                                        disclaimerAccepted={
                                            this.state.disclaimerAccepted
                                        }
                                        accountData={this.props.accountData}
                                        selectedSampleScenario={
                                            this.state.selectedSampleScenario
                                        }
                                        handleClickSampleScenarioCard={
                                            this.handleClickSampleScenarioCard
                                        }
                                    />
                                )}
                            />
                        </Switch>
                    </Router>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.scenario.showModal,
        manager: state.scenario.manager,
        loadedScenario: state.scenario.loadedScenario,
        shareScenario: state.scenario.shareScenario,
        userScenarios: state.scenario.userScenarios,
        accountData: state.scenario.accountData,
        signupState: state.scenario.signupState,
        onboardingData: state.scenario.onboardingData,
        loginPopUp: state.scenario.loginPopUp,
        baseline: state.scenario.baseline,
        loginPopUpValue: state.scenario.loginPopUpValue,
        accountSelected: state.scenario.accountSelected,
        agencyClients: state.scenario.agencyClients,
        deletionData: state.scenario.deletionData,
        lineDisconnection: state.scenario.lineDisconnection,
        onboardingModalOpen: state.onboardingModal.modalOpen,
        customAccountModalOpen: state.customAccounts.modalOpen,
        accountPickerModalOpen: state.accountPicker.modalOpen,
        accountMappingModalOpen: state.scenario.showAccountMappingModal.show,
        accountFieldMappingModalOpen:
            state.scenario.showAccountFieldMappingModal.show,
        createAccountCardModalOpen:
            state.canvasCardModals.createAccountCardModalOpen,
        cellRowModalOpen: state.scenario.showCellRowModal.show,
        chartModalOpen: state.scenario.showChartModal.show,
    };
};

const mapDispatchToProps = {
    closeModal: actions.closeModal,
    loadScenario: actions.loadScenario,
    fetchSharedScenarios: actions.fetchSharedScenarios,
    signup: actions.signup,
    login: actions.login,
    getUserData: actions.getUserData,
    getAllEventDetails,
    setOnboardingState: actions.setOnboardingState,
    setOnboardingData: actions.setOnboardingData,
    resetPassword: actions.resetPassword,
    resetCode: actions.resetCode,
    updatePassword: actions.updatePassword,
    onboardingScenario: actions.onboardingScenario,
    createScenarioPopup: actions.createScenarioPopup,
    showLogin: actions.showLogin,
    selectTab: actions.selectTab,
    updateBaseline: actions.updateBaseline,
    getBaseline: actions.getBaseline,
    setBaselineManager: actions.setBaselineManager,
    setBaselineDataManager: actions.setBaselineDataManager,
    toggleAccountType: actions.toggleAccountType,
    getLibraryEvents: actions.getLibraryEvents,
    getAgencyClients: actions.getAgencyClients,
    toggleShowAgencyBaselineView: actions.toggleShowAgencyBaselineView,
    getUserScenarios: actions.getUserScenarios,
    getUserEntities: actions.getUserEntities,
    getUserOverrides: actions.getUserOverrides,
    getUserAccount: actions.getUserAccount,
    getUserAccountMapping: actions.getUserAccountMapping,
    getSubscription: actions.getSubscription,
    createFreePlan: actions.createFreePlan,
    getTemplates: actions.getTemplates,
    upsertEventsTemplates,
    upsertCardTemplates,
    upsertMenusTemplates,
    upsertActionsData,
    upsertEntities: upsertEntities,
    addEventForDeletion: eventActions.addEventForDeletion,
    deleteEvents: eventActions.deleteEvents,
    selectLineForDisconnection: lineActions.selectLineForDisconnection,
    disconnectLine: lineActions.disconnectLine,
    closeCustomAccountModal,
    closeAccountPickerModal,
    closeAccountMappingModal,
    closeAccountFieldMappingModal,
    closeAccountCardModal,
    closeCellRowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import InitialBalanceCard from "../Card/initialBalanceCard";

// Import Connection Image
import InitialBalanceConnectionImage from "Assets/_optionDescriptionIcons/Initial Balance white.jpg";

// Import Coloured PNGs
import initialBalanceDarkGrey from "../../Assets/_eventNodeTypes/initialBalanceSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import InitialBalanceInputExperimental from "Components/InputContainer/InitialBalanceInputExperimental/InitialBalanceInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const initialBalanceObject = {
    constant: function () {
        return initialBalance_Constant();
    },

    name: function () {
        return "Initial Balance";
    },

    checkInput: function (state) {
        return initialBalance_CheckInput(state);
    },

    svg: function () {
        return initialBalance_Svg();
    },

    eventNodeMenu: function (focus) {
        return initialBalance_EventNodeMenu(focus);
    },

    indexText: function () {
        return initialBalance_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return initialBalance_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return initialBalance_OptionDescription();
    },

    colouredPngs: function () {
        return initialBalance_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return initialBalance_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return InitialBalance_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const initialBalance_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.startDate) return false;
        if (!entity.data.accountName) return false;
        if (!entity.data.value) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const initialBalance_Constant = () => {
    return "536bca78-ed04-11ec-8ea0-0242ac120002";
};

// EFFECTS: Returns svg for event
const initialBalance_Svg = () => {
    return initialBalanceDarkGrey;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const initialBalance_EventNodeMenu = (focus) => {
    return <InitialBalanceCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const initialBalance_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const initialBalance_Icons = () => {
    let pngs = new Map([
        ["black", initialBalanceDarkGrey],
        ["blue", initialBalanceDarkGrey],
        ["white", initialBalanceDarkGrey],
        ["grey", initialBalanceDarkGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const initialBalance_InputComponentInfoPassing = (minProps) => {
    return <InitialBalanceInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const initialBalance_OptionDescription = () => {
    const tags = ["Option"];
    const type = initialBalanceObject.name();
    const description =
        "The Initial Balance Event is an Event used to set an initial balance for a given account.";
    const usedForText =
        "Use this Event to set an initial balance to an account";
    const connection = "";
    const connectionImage = InitialBalanceConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const initialBalance_SideModalImage = () => {
    return sideImage;
};

const InitialBalance_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

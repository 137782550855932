import React, { Component } from "react";
import styles from "./ScenarioContainer.module.css";
import deleteSvg from "../../Assets/scenarioIcons/delete.svg";
import editSvg from "../../Assets/scenarioIcons/edit.svg";

export default class ScenarioInfo extends Component {
    render() {
        const { data, editScenario, deleteScenario } = this.props;
        const actions = [
            {
                id: "edit",
                icon: editSvg,
                method: editScenario,
            },
            {
                id: "delete",
                icon: deleteSvg,
                method: deleteScenario,
            },
        ];
        const inflation = data && data.inflation * 100;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        return (
            <div className={styles.infoRoot}>
                <div className={styles.infoName}>{data.name}</div>
                {loggedInUser && (
                    <div className={styles.flexRowContainer}>
                        {actions.map((action) => {
                            return (
                                <div
                                    key={action.id}
                                    onClick={() => action.method(data)}
                                    className={styles.infoSvgContainer}
                                >
                                    <img
                                        alt="alt"
                                        className={styles.infoActionSvg}
                                        src={action.icon}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className={styles.infoDescription}>{data.description}</div>
                <div className={styles.flexRowContainer}>
                    <div className={styles.infoKey}>Scenario Range</div>
                    <div className={styles.infoValue}>{data.range} yrs</div>
                </div>
                <div className={styles.flexRowContainer}>
                    <div className={styles.infoKey}>Inflation</div>
                    <div className={styles.infoValue}>{inflation} %</div>
                </div>
            </div>
        );
    }
}

import { connect } from "react-redux";
import * as actions from "../../actions/scenario";
import OnboardingContainer from "./Onboarding";

const mapStateToProps = (state) => {
    return {
        sampleScenarios: state.scenario.sampleScenarios,
        accountData: state.scenario.accountData,
        manager: state.scenario.manager,
    };
};

const mapDispatchToProps = {
    getSampleScenario: actions.getSampleScenario,
    saveUserDetails: actions.saveUserDetails,
    getUserData: actions.getUserData,
    createScenario: actions.createScenario,
    getUserScenarios: actions.getUserScenarios,
    loadScenario: actions.loadScenario,
    onboardingScenario: actions.onboardingScenario,
    setOnboardingState: actions.setOnboardingState,
    createFreePlan: actions.createFreePlan,
    createStudentPlan: actions.createStudentPlan,
};

const Onboarding = connect(
    mapStateToProps,
    mapDispatchToProps
)(OnboardingContainer);

export default Onboarding;

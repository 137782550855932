import store from "store";
import type {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import Event from "Events/_event";
import { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import { EventManager } from "Events";

export const getRelevantEntities = (
    ids: string[] | { id: string; active: boolean }[]
) => {
    const relevantEntities: EntitiesSchema = {};
    const allEntities = store.getState().entities;
    for (const id of ids) {
        const actualId = typeof id === "string" ? id : id.id;
        if (actualId in allEntities) {
            relevantEntities[actualId] = allEntities[actualId];
        }
    }
    return relevantEntities;
};

export const getSingleEntity = (
    id: string | { id: string; active: boolean }
): EntitySchema | null => {
    if (!id) return null;
    const allEntities = store.getState().entities;
    const actualId = typeof id === "string" ? id : id.id;
    if (actualId in allEntities) {
        return allEntities[actualId];
    }
    return null;
};

export const getEvent = (
    id: string,
    exportData = false
): Event | EventStructure | undefined => {
    const manager = store.getState().scenario?.manager;
    if (manager) {
        const event = manager._findEvent(id);
        if (exportData && event) {
            return event.exportData();
        }
        return event;
    }
    return undefined;
};

export const getManager = (): EventManager | undefined => {
    return store.getState().scenario?.manager;
};

import { modifierAlreadyExists } from "helpers/modifiers";
import type { ModifiersSchema } from "reducers/typesSchema/modifiersSchema";
import type { AppThunk } from "store";

export const UPSERT_MODIFIERS = "modifiers/UPSERT_MODIFIERS";
export const SET_MODIFIERS = "modifiers/SET_MODIFIERS";

export function upsertModifiers(
    upsertModifiers: ModifiersSchema
): AppThunk<void> {
    return (dispatch, getState) => {
        // TODO: Check to make sure mutations are not occurring
        const modifiersCopy: ModifiersSchema = { ...getState().modifiers };

        Object.entries(upsertModifiers).forEach(([entity_id, modifiers]) => {
            if (entity_id in modifiersCopy) {
                modifiers.forEach((modifier) => {
                    const [modifierExists, existingModifier, index] =
                        modifierAlreadyExists(
                            modifiersCopy[entity_id],
                            modifier.associatedEntity,
                            modifier.field
                        );
                    if (modifierExists) {
                        const updatedModifier = {
                            ...existingModifier,
                            ...modifier,
                        };
                        modifiersCopy[entity_id][index] = updatedModifier;
                        return;
                    }
                    modifiersCopy[entity_id].push(modifier);
                });
            } else {
                modifiersCopy[entity_id] = modifiers;
            }
        });

        dispatch({
            type: UPSERT_MODIFIERS,
            payload: modifiersCopy,
        });
    };
}

export function removeModifiers(
    ids: { entity_id: string; modifier_id: string }[]
): AppThunk<void> {
    return (dispatch, getState) => {
        // TODO: Check to make sure mutations are not occurring
        const modifiersCopy: ModifiersSchema = { ...getState().modifiers };
        ids.forEach((id) => {
            const entity_id = id.entity_id;
            const modifier_id = id.modifier_id;
            if (entity_id in modifiersCopy) {
                // TODO: Check to see if there is a more efficient way to remove modifiers
                modifiersCopy[entity_id] = modifiersCopy[entity_id].filter(
                    (modifier) => {
                        return modifier.id !== modifier_id;
                    }
                );
                if (modifiersCopy[entity_id].length < 1) {
                    delete modifiersCopy[entity_id];
                }
            }
        });

        dispatch({
            type: SET_MODIFIERS,
            payload: modifiersCopy,
        });
    };
}

export const getModifiers = () => {
    return (_dispatch, getState) => {
        return () => {
            return getState().modifiers;
        };
    };
};

import { useMemo, useContext, useState } from "react";
import { Group } from "react-konva";
import { animated, useSpring } from "@react-spring/konva";
import { InsertMenuIdContext } from "../../contexts/InsertMenuIdContext";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import Konva from "konva";
import { FocusedLineContext } from "../../contexts/focusedLineContext";
import { InsertMenuIcon } from "./partials/InsertMenuIcon";
import { InsertMenuToolTip } from "./partials/insertMenuToolTip";
import {
    HoveredButtonIdContext,
    SetHoveredButtonIdContext,
} from "./contexts/hoveredButtonIdContext";
import { useMenuTemplate } from "../../../CanvasEvents/CanvasEvent/partials/EventNodeBaseMenu/hooks/useMenuTemplate";

export function InsertMenu() {
    const templateId = useContext(InsertMenuIdContext);
    const insertMenuTemplate = useMenuTemplate(templateId);
    const insertMenuActionIds = insertMenuTemplate?.actionsOrder;
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);
    const focused = useContext(FocusedLineContext);
    const [hoveredButtonId, setHoveredButtonId] = useState("");

    const baseMenuFullBodyPositioning = useMemo(() => {
        const _styles =
            insertMenuTemplate?.canvasStyles?.baseMenuPositioning?.styles;
        const _default = _styles?.default;
        const _zoomCategory = _styles?.[zoomCategory];

        const _finalStyles = {
            ..._default,
            ..._zoomCategory,
        };

        return _finalStyles;
    }, [
        insertMenuTemplate?.canvasStyles?.baseMenuPositioning?.styles,
        zoomCategory,
    ]);

    const [containerWidth, containerLargestHeight] = useMemo(() => {
        let w = 0;
        let h = 0;
        insertMenuActionIds.forEach((insertMenuActionId) => {
            const _styles =
                insertMenuTemplate?.canvasStyles?.buttons?.[insertMenuActionId]
                    ?.buttonDimensions?.styles;
            const _default = _styles?.default;
            const _focused = focused ? _styles?.focused : {};
            const _zoomCategory = _styles?.[zoomCategory];

            const _finalStyles = {
                ..._default,
                ..._focused,
                ..._zoomCategory,
            };

            w += _finalStyles?.width ?? 0;
            if ((_finalStyles?.height ?? 0) > h) h = _finalStyles?.height ?? 0;
        });

        return [w, h];
    }, [
        focused,
        insertMenuActionIds,
        insertMenuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const allIconsHorizontalCenters = useMemo(() => {
        const _allXPositions = {};
        let curPosition = 0 - containerWidth / 2;
        insertMenuActionIds.forEach((buttonId) => {
            const _styles =
                insertMenuTemplate?.canvasStyles?.buttons?.[buttonId]
                    ?.buttonDimensions?.styles;
            const _default = _styles?.default;
            const _focused = focused ? _styles?.focused : {};
            const _zoomCategory = _styles?.[zoomCategory];

            const _finalStyles = {
                ..._default,
                ..._focused,
                ..._zoomCategory,
            };

            curPosition += _finalStyles?.width ?? 0;
            _allXPositions[buttonId] =
                curPosition - (_finalStyles?.width ?? 0) / 2;
        });

        return _allXPositions;
    }, [
        containerWidth,
        focused,
        insertMenuActionIds,
        insertMenuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const baseMenuBackgroundStyles = useMemo(() => {
        const _styles =
            insertMenuTemplate?.canvasStyles?.baseMenuBackground?.styles;
        const _default = _styles?.default;
        const _focused = focused
            ? {
                  x: -(containerWidth / 2),
                  width: containerWidth,
                  height: containerLargestHeight,
              }
            : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _finalStyles = {
            ..._default,
            ..._focused,
            ..._zoomCategory,
        };

        if (hoveredButtonId)
            _finalStyles.height =
                _finalStyles.height + _styles?.extraHeightForText?.height;

        return _finalStyles;
    }, [
        containerLargestHeight,
        containerWidth,
        focused,
        insertMenuTemplate?.canvasStyles?.baseMenuBackground?.styles,
        zoomCategory,
        hoveredButtonId,
    ]);

    const baseMenuBackgroundAnimations = useMemo(() => {
        const _animationOverride =
            insertMenuTemplate?.canvasStyles?.baseMenuBackground
                ?.animationOverride;
        const _default = _animationOverride?.default;
        const _focused = focused ? _animationOverride?.focused : {};
        const _zoomCategory = _animationOverride?.[zoomCategory];

        const _finalStyles = {
            ..._default,
            ..._focused,
            ..._zoomCategory,
        };

        return _finalStyles;
    }, [
        focused,
        insertMenuTemplate?.canvasStyles?.baseMenuBackground?.animationOverride,
        zoomCategory,
    ]);

    const animatedBaseMenuBackground = useSpring<Konva.RectConfig>({
        to: baseMenuBackgroundStyles,
        config: baseMenuBackgroundAnimations,
    });

    return (
        <HoveredButtonIdContext.Provider value={hoveredButtonId}>
            <SetHoveredButtonIdContext.Provider value={setHoveredButtonId}>
                <Group {...baseMenuFullBodyPositioning}>
                    {/* @ts-ignore */}
                    <animated.Rect {...animatedBaseMenuBackground} />
                    {insertMenuActionIds?.map((insertMenuActionId) => (
                        <InsertMenuIcon
                            key={insertMenuActionId}
                            buttonId={insertMenuActionId}
                            horizontalCenter={
                                allIconsHorizontalCenters[insertMenuActionId]
                            }
                        />
                    ))}
                    <InsertMenuToolTip />
                </Group>
            </SetHoveredButtonIdContext.Provider>
        </HoveredButtonIdContext.Provider>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { Provinces } from "helpers/constants";
import {
    EntityCard,
    EntityName,
    EventEntityWrapper,
    SelectDropDown,
    ModalRow,
    Description,
    InputButtons,
    EventDetails,
    EventHeader,
    RequiredStar,
} from "../Components";
import { ChangeEvent } from "react";
import { incomeTaxInputsHandler } from "../OnInputChangeHandlers/incomeTaxInputsHandler";

export default function IncomeTaxInputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
}) {
    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "person" | "province" | "entityName",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = incomeTaxInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={incomeTaxObject.constant()}
                    onChangeInput={onChangeNameDescription}
                    image={incomeTaxObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() => {
                    handleClickAddEntityCard(
                        EntityStructureData[incomeTaxObject.constant()].data
                    );
                }}
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                    />
                </ModalRow>
                <ModalRow>
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap?.[currentEntity]?.data?.personName}
                        options={Object.keys(
                            entitiesMap?.[currentEntity]?.data?.people || {}
                        )}
                        className="IncomeSelect"
                        id="person"
                        label="Select Person"
                    />
                </ModalRow>
                <ModalRow>
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap?.[currentEntity]?.data?.province}
                        options={Provinces}
                        className="select-dropdown"
                        id="province"
                        label="Select Province"
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import type { EventNodeDetailDescription } from "reducers/typesSchema/eventsTemplatesSchema";
import { EventNodeDetailImage } from "./EventNodeDetailImage";
// import { EventNodeDetailText } from "./EventNodeDetailText";

interface EventNodeDetailControllerProps {
    eventNodeDetailDescription: EventNodeDetailDescription;
}

/**
 * Renders different Konva components depending on what `konvaComponentType` was passed
 */
export function EventNodeDetailController({
    eventNodeDetailDescription,
}: EventNodeDetailControllerProps) {
    if (eventNodeDetailDescription?.konvaComponentType === "Image")
        return (
            <EventNodeDetailImage
                eventNodeDetailDescription={eventNodeDetailDescription}
            />
        );
    if (eventNodeDetailDescription?.konvaComponentType === "Text") return null;
    // (
    //     <EventNodeDetailText
    //         eventNodeDetailDescription={eventNodeDetailDescription}
    //     />
    // );

    console.error(
        "-endc Unknown konvaComponentType:",
        eventNodeDetailDescription?.konvaComponentType
    );
    return null;
}

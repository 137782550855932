import React, { Component } from "react";
import {
    CardContainer,
    Description,
    DollarValue,
    PercentValue,
    Row,
    RowLabel,
    TextValue,
} from "./Components";

class MortgageInsuranceCard extends Component {
    render() {
        const {
            description,
            paymentMethod,
            premiumPercent,
            premiumAmount,
            taxAmount,
            taxRate,
            version,
        } = this.props.data.metadata;

        return (
            <CardContainer>
                <Row>
                    <Description text={description} />
                </Row>
                <Row>
                    <RowLabel text="Payment Method" />
                    <TextValue text={paymentMethod} />
                </Row>
                <Row>
                    <RowLabel text="Premium Percentage" />
                    <PercentValue value={premiumPercent} />
                </Row>
                <Row>
                    <RowLabel text="Premium Amount" />
                    <DollarValue value={premiumAmount} />
                </Row>
                {taxRate > 0 && (
                    <Row>
                        <RowLabel text="Sales Tax" />
                        <DollarValue value={taxAmount} />
                    </Row>
                )}
                <Row>
                    <RowLabel text="Version" />
                    <TextValue text={version} />
                </Row>
            </CardContainer>
        );
    }
}

export default MortgageInsuranceCard;

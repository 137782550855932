import { createContext } from "react";
export const FocusedCardIdContext = createContext<string>("");
FocusedCardIdContext.displayName = "FocusedCardIdContext";

export const HoveredCardIdContext = createContext("");
HoveredCardIdContext.displayName = "HoveredCardIdContext";

export const SetHoveredCardIdContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetHoveredCardIdContext.displayName = "SetHoveredCardIdContext";

import React, { Component } from "react";
import "./InputContainer.css";
import moment from "moment";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import swal from "sweetalert";
import { Bank, BankPlaid } from "../../helpers/constants";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import { getDefaultName } from "../../helpers";
import { bankPlaidObject } from "../Registry/BankPlaid";

const options = [
    "Chequing",
    "Saving",
    "RRSP",
    "RESP",
    "RDSP",
    "Investing",
    "Credit Card",
    "Other",
];

class BankPlaidInput extends Component {
    constructor(props) {
        super(props);

        this.state = props.edit
            ? { ...props.editData }
            : {
                  ...BankPlaidInput.getDefaultState(
                      props.inflationRate,
                      getDefaultName(Bank, props),
                      props.baseline
                  ),
              };
        this.state.accountData = props.accountData || null;
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            name: name,
            flinksState: "default",
            proceed: false,
            selected: [],
            ids: [],
            type: BankPlaid,
            bankData: null,
            description: "",
            rating: 0,
            bank: null,
            tag: `@${BankPlaid}`,
            beta: true,
            onHoverStar: null,
            baseline: isInBaseline,
            inflationRate: inflationRate,
            inflation: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bankData !== this.props.bankData && this.props.bankData) {
            const newBankData = {
                Accounts: this.props.bankData.Accounts.map((account) => {
                    const {
                        TransitNumber,
                        InstitutionNumber,
                        Title,
                        AccountNumber,
                        Balance,
                        Type,
                        Currency,
                        Id,
                    } = account;
                    const acct = {
                        TransitNumber,
                        InstitutionNumber,
                        Title,
                        AccountNumber,
                        Balance,
                        Type,
                        Currency,
                        Id,
                    };
                    return acct;
                }),
            };
            this.setState({
                proceed: true,
                bankData: newBankData,
            });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);

                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    handleSelectAccount = (id, account) => {
        const { selected, ids } = this.state;
        if (ids.includes(id)) {
            const filteredArray = selected.filter((item) => {
                return item.account_id !== account.account_id;
            });
            const filterIds = ids.filter((id) => {
                return id !== account.account_id;
            });

            this.props.handlePlaidSelectedAccount(filteredArray, filterIds);
            this.setState({
                selected: filteredArray,
                ids: filterIds,
            });
        } else {
            const selected = [...this.state.selected, account];
            const ids = [...this.state.ids, account.account_id];
            this.props.handlePlaidSelectedAccount(selected, ids);
            this.setState({
                selected: [...this.state.selected, account],
                ids: [...this.state.ids, account.account_id],
            });
        }
    };

    onHandleNickname = (e) => {
        const value = e.target.value;
        const id = e.target.id;
        const { selected } = this.state;

        const updatedSelected = selected.map((item) => {
            if (item.Id === id) {
                const newItem = {
                    ...item,
                    Nickname: value,
                };
                return newItem;
            }
            return item;
        });

        this.setState({ selected: updatedSelected });
    };

    onHandleCategory = (e) => {
        const value = e.target.value;
        const id = e.target.id;
        const { selected } = this.state;

        const updatedSelected = selected.map((item) => {
            if (item.Id === id) {
                const newItem = {
                    ...item,
                    BankCategory: value === "Account Category" ? null : value,
                };
                return newItem;
            }
            return item;
        });

        this.setState({ selected: updatedSelected });
    };

    updateValues = () => {
        let value = 0;
        this.state.selected.map((account) => {
            return (value += account.balances.current);
        });
        const newState = {
            ...this.state,
            value: Math.floor(value),
            isFilled: true,
            valid: true,
        };

        if (bankPlaidObject.checkInput(this.state)) {
            this.props.updateValues(newState);
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up bank name and select accounts"
            );
        }
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onSuccess = (token) => {
        this.props.getPlaidAccount(token, (err, data) => {
            if (data) {
                this.setState({ bankData: data.data });
            } else {
                swal({
                    icon: "error",
                    title: "Cannot get bank account details",
                });
            }
        });
    };

    submitBankDataValues = () => {
        const { baseline, confirmBaselineAction } = this.props;

        let value = 0;
        this.state.selected.map((account) => {
            return (value += account.balances.current);
        });

        const newState = {
            ...this.state,
            value: Math.floor(value),
            start: moment(new Date()).format("YYYY-MM-DD"),
            isFilled: true,
            valid: true,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.submitBankData(newState);
        }
    };

    submitBankData = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            value: 0,
            start: moment(new Date()).format("YYYY-MM-DD"),
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.submitBankData(newState);
        }
    };

    render() {
        // const isPassed = bankPlaidObject.checkInput(this.state);
        // const { edit } = this.props;
        // const { name } = this.state;

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    {this.state.bankData && (
                        <div>
                            <div className="inputRoot">
                                <div className="accountsHeader">
                                    Select the accounts that you want whatifi to
                                    track and keep up-to-date.
                                </div>
                                <div className="accountsContainerRoot">
                                    <div className="titleContainer">
                                        <div className="titleHeader">
                                            #Title
                                        </div>
                                        <div className="balanceHeader">
                                            Balance
                                        </div>
                                        <div className="accountHeader">
                                            Account #
                                        </div>
                                        <div className="typeHeader">Type</div>
                                    </div>
                                    <div className="bankAccountsContainer">
                                        {this.state.bankData &&
                                            this.state.bankData.map(
                                                (account, i) => {
                                                    const wholeNum =
                                                        account.balances
                                                            .current;

                                                    const isSelected =
                                                        this.state.ids.includes(
                                                            account.account_id
                                                        );

                                                    return (
                                                        <div
                                                            key={i}
                                                            className="account"
                                                        >
                                                            <div className="accountInfo">
                                                                <img
                                                                    alt="alt"
                                                                    className="bankCheckBox"
                                                                    src={
                                                                        isSelected
                                                                            ? checkboxOn
                                                                            : checkboxOff
                                                                    }
                                                                    onClick={() =>
                                                                        this.handleSelectAccount(
                                                                            account.account_id,
                                                                            account
                                                                        )
                                                                    }
                                                                />
                                                                <div className="number">
                                                                    {i + 1}
                                                                </div>
                                                                <div className="title">
                                                                    {
                                                                        account.name
                                                                    }
                                                                </div>
                                                                <div className="balance">
                                                                    {
                                                                        account
                                                                            .balances
                                                                            .iso_currency_code
                                                                    }{" "}
                                                                    {wholeNum}
                                                                </div>
                                                                <div className="accountNumber">
                                                                    {
                                                                        account.mask
                                                                    }
                                                                </div>
                                                                <div className="accountType">
                                                                    {
                                                                        account.type
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="accountInputContainer">
                                                                <input
                                                                    type="text"
                                                                    className="accountNickname"
                                                                    placeholder="Acct Nickname"
                                                                    id={
                                                                        account.account_id
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .onHandleNickname
                                                                    }
                                                                    defaultValue={
                                                                        account.Nickname
                                                                    }
                                                                />
                                                                <select
                                                                    placeholder="Account Category”"
                                                                    className="accountSelect"
                                                                    onChange={
                                                                        this
                                                                            .onHandleCategory
                                                                    }
                                                                    id={
                                                                        account.account_id
                                                                    }
                                                                >
                                                                    <option
                                                                        value={
                                                                            null
                                                                        }
                                                                    >
                                                                        Account
                                                                        Category
                                                                    </option>
                                                                    {options.map(
                                                                        (
                                                                            option,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                    value={
                                                                                        option
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>
                                </div>
                            </div>
                            {/* <InputButtons edit={edit} updateValues={this.updateValues} onHandleSubmit={this.submitBankData} passedCheck={isPassed} withSelected={true} selected={this.state.selected.length} totalNumber={this.state.bankData.length} /> */}
                        </div>
                    )}
                </div>
                {/* <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmit={this.submitBankData}
                    onHandleSubmitValues={this.submitBankDataValues}
                    passedCheck={isPassed}
                    withSelected={this.state.selected.length > 0}
                    selected={this.state.selected.length}
                    totalNumber={
                        this.state.bankData && this.state.bankData.length
                    }
                /> */}
            </div>
        );
    }
}

export default BankPlaidInput;

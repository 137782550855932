import React, { Component } from "react";
import "./Card.css";
// import starFilledSvg from "../../Assets/star_filled.svg";
// import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import moment from "moment";
// import { debitCreditObject } from "../Registry/Debit Credit";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";

class unitCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }

        // const stars = [1, 2, 3, 4, 5];
        const {
            value,
            url,
            accountName,
            increaseOrDecrease,
            ongoingOrAmortized,
            amortizedValue,
            calulatedAmortizedValue,
            amortizationPeriod,
        } = this.state.allEntities[this.state.currentIndex].data;
        const { cadence, endDate, startDate, version, name } =
            this.state.allEntities[this.state.currentIndex];
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Name:</div>
                    <div className="cardValue">{name}</div>
                </div>
                {ongoingOrAmortized === "amortized" ? (
                    <>
                        <div className="Row">
                            <div className="ExpenseLabel">{`${increaseOrDecrease} Amount:`}</div>
                            <div className="cardValue">
                                {`
                            ${
                                amortizedValue
                                    ? new Intl.NumberFormat().format(
                                          parseFloat(amortizedValue).toFixed(2)
                                      )
                                    : ""
                            }`}
                            </div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">
                                Number Of Payments:
                            </div>
                            <div className="cardValue">
                                {amortizationPeriod ?? ""}
                            </div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">
                                Payment Frequency:
                            </div>
                            <div className="cardValue">{cadence ?? ""}</div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Payment Price:</div>
                            <div className="cardValue">
                                {" "}
                                {`
                            ${
                                calulatedAmortizedValue
                                    ? new Intl.NumberFormat().format(
                                          parseFloat(
                                              calulatedAmortizedValue
                                          ).toFixed(2)
                                      )
                                    : ""
                            }`}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="Row">
                        <div className="ExpenseLabel">{`${increaseOrDecrease} Amount:`}</div>
                        <div className="cardValue">
                            {`
                        ${
                            value
                                ? new Intl.NumberFormat().format(
                                      parseFloat(value).toFixed(2)
                                  )
                                : ""
                        } ${cadence ? `${cadence} /` : ""}
                            `}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Account:</div>
                    <div className="cardValue">{accountName}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Expense Type:</div>
                    <div className="cardValue">{ongoingOrAmortized}</div>
                </div>
                {/* <div className="Row">
                    <div className="ExpenseLabel">Type:</div>
                    <div className="cardValue">{debitCreditObject.name()}</div>
                </div> */}
                <div className="Row">
                    <div className="ExpenseLabel">Date:</div>
                    <div className="cardValue">
                        {startDate
                            ? moment(startDate).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {endDate
                            ? moment(endDate).format("MMM D YYYY")
                            : "No End Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">URL:</div>
                    <a
                        className="ExpenseLink"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {url}
                    </a>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                {/* <div className="Row">
                    <div className="ExpenseLabel"> Rating</div>
                    <div className="StarsContainer">
                        {stars.map((star, i) => {
                            if (i < rating) {
                                return (
                                    <img
                                        alt="alt"
                                        key={i}
                                        className="starFilledSvg"
                                        src={starFilledSvg}
                                        id="rating"
                                    />
                                );
                            } else {
                                return (
                                    <img
                                        alt="alt"
                                        className="starUnfilledSvg"
                                        src={starUnfilledSvg}
                                        key={i}
                                        id="rating"
                                    />
                                );
                            }
                        })}
                    </div>
                </div> */}
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() => this.handleArrowClick("left")}
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentIndex + 1} of{" "}
                        {this.state.allEntities.length}
                    </span>
                    <div
                        className={
                            this.state.currentIndex ===
                            this.state.allEntities.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() => this.handleArrowClick("right")}
                    ></div>
                </div>
            </div>
        );
    }
}

export default unitCard;

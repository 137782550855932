// Default Imports
import React from "react";

// Import Event Card and Event Input
import TaxDeductibleCard from "../Card/taxDeductibleCard";
import TaxDeductibleInput from "../InputContainer/TaxDeductibleInput";

// Import Connection Image (if exists)
import TaxDeductibleConnectionImage from "../../Assets/_optionDescriptionIcons/Income, Tax Deductible, Income Tax.png";

// Import Coloured PNGs
import taxDeductibleBlack from "../../Assets/_eventNodeTypes/expense-black.png";
import taxDeductibleBlue from "../../Assets/_eventNodeTypes/expense-blue.png";
import taxDeductibleWhite from "../../Assets/_eventNodeTypes/expense-white.png";
import taxDeductibleGrey from "../../Assets/_eventNodeTypes/expense-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

// "Interface" object for Event
export const taxDeductibleObject = {
    constant: function () {
        return TaxDeductible_Constant();
    },

    checkInput: function (state) {
        return TaxDeductible_CheckInput(state);
    },

    svg: function () {
        return TaxDeductible_Svg();
    },

    eventNodeMenu: function (focus) {
        return TaxDeductible_EventNodeMenu(focus);
    },

    indexText: function () {
        return TaxDeductible_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return TaxDeductible_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return TaxDeductible_OptionDescription();
    },

    colouredPngs: function () {
        return TaxDeductible_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return TaxDeductible_SideModalImage();
    },
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const TaxDeductible_CheckInput = (state) => {
    if (state.taxDeductible === undefined || state.taxDeductible === "")
        return false;
    if (!state.start) return false;
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (!state.personId) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const TaxDeductible_Constant = () => {
    return "Tax Deductible";
};

// EFFECTS: Returns black png / svg for event
const TaxDeductible_Svg = () => {
    return taxDeductibleBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const TaxDeductible_EventNodeMenu = (focus) => {
    return <TaxDeductibleCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const TaxDeductible_IndexText = () => {
    return "Select an input field to learn more about how your Tax Deductions affect your Income Tax.";
};

// EFFECTS: Returns all icons for event ??
const TaxDeductible_Icons = () => {
    let pngs = new Map([
        ["black", taxDeductibleBlack],
        ["blue", taxDeductibleBlue],
        ["white", taxDeductibleWhite],
        ["grey", taxDeductibleGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const TaxDeductible_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const TaxDeductible_InputComponentInfoPassing = (minProps) => {
    return <TaxDeductibleInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, taxDeductibles for event ??
const TaxDeductible_OptionDescription = () => {
    const tags = ["Option"];
    const type = taxDeductibleObject.constant();
    const description =
        "A Tax Deductible is an expense that can be used to reduce the amount of taxable income an individual has. Tax deductions can come from donations and other expenses specific to the individual.";
    const usedForText =
        "Use the Tax Deductible Event to model an expense that should reduce the amount of taxable income.";
    const connection = "";
    const connectionImage = TaxDeductibleConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/t/tax-deduction.asp#:~:text=A%20tax%20deduction%20is%20a,how%20much%20tax%20is%20owed.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const TaxDeductible_SideModalImage = () => {
    return sideImage;
};

import React, { Component } from "react";
import styles from "./Options.module.css";
import { NodeTypesBlack } from "../../Events";
import collapse from "../../Assets/_optionPicker/collapsed.svg";
import toggle from "../../Assets/_optionPicker/toggle.svg";
import {
    AgGridColumn,
    AgGridReact,
    SortableHeaderComponent,
} from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moreUnSelectedSvg from "../../Assets/more_unselected.svg";
import addIconGrey from "../../Assets/_eventLibraryIcons/add_white.svg";
import _ from "lodash";
import Event from "Events/_event";
import { getManager } from "actions/getNodeEntityActions";

class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }
    btnClickedHandler() {
        this.props.clicked(this.props.value);
    }
    render() {
        return (
            <div>
                <button
                    onClick={this.btnClickedHandler}
                    className={styles.addToScenarioButton}
                >
                    Add
                </button>
                <img
                    onClick={this.btnClickedHandler}
                    src={moreUnSelectedSvg}
                    alt="alt"
                    className="image"
                />
            </div>
        );
    }
}

class LibraryTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            useCompactView: false,
        };
    }

    onHandleToggleOpen = (category) => {
        const { eventCategories } = this.props;
        const newCategories = eventCategories.map((item) => {
            if (item.type === category.type) {
                const newItem = {
                    ...item,
                    isOpen: !item.isOpen,
                };
                return newItem;
            }
            return item;
        });

        this.props.updateLibraryCategories(newCategories);
    };

    compactView = () => {
        const { eventCategories } = this.props;

        const typeImagesBlack = {};

        NodeTypesBlack.forEach((data) => {
            typeImagesBlack[data.type] = data.image;
        });

        function resizeGrid(params) {
            params.columnApi.autoSizeAllColumns();
        }

        function filterForType(type) {
            switch (type) {
                case "number":
                    return "agNumberColumnFilter";
                case "date":
                    return "agDateColumnFilter";
                case "text":
                default:
                    return "agTextColumnFilter";
            }
        }
        // comparisonDate is JS native date object, cellDate is string with format YYYY-MM-DD
        function dateComparator(comparisonDate, cellDate) {
            const date = new Date(cellDate);
            date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
            if (date < comparisonDate) return -1;
            else if (date > comparisonDate) return 1;
            else return 0;
        }

        return (
            <div className={styles.utilityContainer}>
                <button
                    onClick={() => {
                        this.setState({
                            useCompactView: !this.state.useCompactView,
                        });
                    }}
                >
                    Use Compact View
                </button>
                {eventCategories &&
                    eventCategories.map((category, i) => {
                        const image = typeImagesBlack[category.type];
                        return (
                            <>
                                <div
                                    onClick={() => {
                                        this.onHandleToggleOpen(category);
                                    }}
                                    className={styles.compactUtilityDiv}
                                    style={
                                        i % 2 === 0
                                            ? { background: "#F8F8F8" }
                                            : {}
                                    }
                                >
                                    <img
                                        className={styles.utilitySvg}
                                        src={image}
                                        style={{
                                            height: "32px",
                                            width: "32px",
                                        }}
                                        alt={category.type}
                                    />
                                    <div
                                        className={
                                            styles.optionsDescriptionContainer
                                        }
                                        style={{ width: "30%" }}
                                    >
                                        <div className={styles.utilityType}>
                                            {category.type}{" "}
                                        </div>
                                    </div>
                                    <div className="libraryEntryButton">
                                        <div
                                            className={styles.libraryEntryText}
                                        >
                                            {"Add New"}{" "}
                                        </div>
                                    </div>
                                    <div className="libraryEntryButton">
                                        <div
                                            className={styles.libraryEntryText}
                                        >
                                            {"Import CSV"}{" "}
                                        </div>
                                    </div>
                                    <div className="libraryEntryButton">
                                        <div
                                            className={styles.libraryEntryText}
                                            style={{
                                                marginRight: "40px",
                                                color: "black",
                                            }}
                                        >
                                            {category.data.length}
                                            {" Events"}
                                        </div>
                                    </div>

                                    <img
                                        onClick={() =>
                                            this.onHandleToggleOpen(category)
                                        }
                                        src={
                                            category.isOpen ? toggle : collapse
                                        }
                                        className={styles.collapse}
                                        alt={
                                            category.isOpen
                                                ? "toggle"
                                                : "collapse"
                                        }
                                    />
                                </div>
                                {category.isOpen && (
                                    <div
                                        className="ag-theme-alpine"
                                        style={{
                                            height: "40vh",
                                            width: "100%",
                                        }}
                                    >
                                        <AgGridReact
                                            rowData={category.data}
                                            defaultColDef={{
                                                sortable: true,
                                                filter: true,
                                                headerComponentFramework:
                                                    SortableHeaderComponent,
                                                headerComponentParams: {
                                                    menuIcon: "fa-bars",
                                                },
                                                resizable: true,
                                                suppressMovable: true,
                                                maxWidth: 150,
                                            }}
                                            onGridReady={resizeGrid}
                                            frameworkComponents={{
                                                btnCellRenderer:
                                                    BtnCellRenderer,
                                            }}
                                        >
                                            <AgGridColumn
                                                field="Actions"
                                                cellRenderer="btnCellRenderer"
                                                cellRendererParams={{
                                                    clicked: function (field) {
                                                        alert(
                                                            `${field} was clicked`
                                                        );
                                                    },
                                                }}
                                                width={70}
                                                pinned={true}
                                            />
                                            {category.requiredFields.map(
                                                (field) => {
                                                    return (
                                                        <AgGridColumn
                                                            key={field.id}
                                                            field={field.id}
                                                            filter={filterForType(
                                                                field.type
                                                            )}
                                                            filterParams={{
                                                                comparator:
                                                                    field.type ===
                                                                    "date"
                                                                        ? dateComparator
                                                                        : null,
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                        </AgGridReact>
                                    </div>
                                )}
                            </>
                        );
                    })}
            </div>
        );
    };

    generateEditNode = (nodeData) => {
        return new Event(
            nodeData.type,
            nodeData,
            nodeData.libraryEventId,
            getManager()
        );
    };

    matchQueryString = (str, query) => {
        return str.toUpperCase().includes(query.toUpperCase());
    };

    sortEvents = (eventArray) => {
        const { sortAscending } = this.props;
        if (sortAscending) {
            return _.sortBy(eventArray, [
                function (o) {
                    return o.name;
                },
            ]);
        } else {
            return _.reverse(
                _.sortBy(eventArray, [
                    function (o) {
                        return o.name;
                    },
                ])
            );
        }
    };

    tileView = () => {
        const {
            toggleAddingLibraryEvent,
            editNode,
            deleteLibraryEvents,
            eventLibrary,
            searchQuery,
        } = this.props;

        const typeImagesBlack = {};

        NodeTypesBlack.forEach((data) => {
            typeImagesBlack[data.type] = data.image;
        });

        let filteredEvents = [];
        Object.keys(eventLibrary).map((eventType) => {
            filteredEvents = filteredEvents.concat(
                this.sortEvents(
                    eventLibrary[eventType].filter((event) => {
                        return this.matchQueryString(event.name, searchQuery);
                    })
                )
            );
        });

        return (
            <div className={styles.optionNodeContainer}>
                <div className={styles.optionRootContainer}>
                    <div className={styles.optionBoxWrap}>
                        <button
                            onClick={() => {
                                toggleAddingLibraryEvent(true);
                            }}
                            className={styles.addLibraryEventButton}
                        >
                            <img
                                src={addIconGrey}
                                alt="add new"
                                className={styles.libraryButtonIcon}
                            />
                            <div className={styles.libraryButtonText}>
                                Add New
                            </div>
                        </button>
                    </div>
                    {filteredEvents.map((node) => {
                        const image = typeImagesBlack[node.type];
                        return (
                            <div
                                key={node.libraryEventId}
                                onMouseEnter={() => {
                                    this.setState({
                                        onHoverId: node.libraryEventId,
                                    });
                                }}
                                onMouseLeave={() => {
                                    this.setState({ onHoverId: null });
                                }}
                                className={styles.optionBoxWrap}
                            >
                                {this.state.onHoverId ===
                                    node.libraryEventId && (
                                    <button
                                        className={styles.optionBoxDeleteButton}
                                        onClick={() => {
                                            deleteLibraryEvents(
                                                [node.libraryEventId],
                                                node.type,
                                                eventLibrary
                                            );
                                            this.forceUpdate();
                                        }}
                                    >
                                        X
                                    </button>
                                )}
                                <div
                                    onClick={() => {
                                        toggleAddingLibraryEvent(true);
                                        editNode(this.generateEditNode(node));
                                    }}
                                    className={
                                        node.valid
                                            ? styles.optionBox
                                            : styles.optionBoxInvalid
                                    }
                                >
                                    <img
                                        className={styles.optionSvg}
                                        src={image}
                                        alt="alt"
                                    />
                                    <div className={styles.optionName}>
                                        {node.name}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    render() {
        const { useCompactView } = this.state;

        return useCompactView ? this.compactView() : this.tileView();
    }
}

export default LibraryTab;

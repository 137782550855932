import "./PasteOptionModal.css";
import "../EventsModal/EventsModal.css";
import pasteLink from "Assets/_modalIcons/pasteLink.png";
import pasteDuplicate from "Assets/_modalIcons/pasteDuplicate.png";
import closeSvg from "Assets/close.svg";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { setShowPasteOptionModal } from "actions/modalActions";

export const PasteOptionModal = () => {
    const showPasteModal = useAppSelector(
        (state) => state.scenario?.showPasteModal
    );
    return showPasteModal?.show ? (
        <div className="ModalContainer">
            <div className="paste-option-modal">
                <img
                    alt="alt"
                    src={closeSvg}
                    className="CloseModal"
                    onClick={() => {
                        setShowPasteOptionModal({ show: false });
                    }}
                />
                <div className="paste-option-modal-title">Paste Options</div>
                <div className="paste-option-modal-box">
                    <div className="paste-option-modal-option">
                        <div className="paste-option-modal-option-text">
                            Paste Event and create new duplicate Records
                        </div>
                        <img
                            src={pasteDuplicate}
                            className="paste-option-modal-option-image"
                        />
                        <div
                            className="paste-option-modal-option-button"
                            onClick={() => {
                                if (showPasteModal?.callback)
                                    showPasteModal.callback("duplicate");
                                setShowPasteOptionModal({ show: false });
                            }}
                        >
                            Paste Duplicate Event
                        </div>
                    </div>
                    <div className="paste-option-modal-separator" />
                    <div className="paste-option-modal-option">
                        <div className="paste-option-modal-option-text">
                            Paste Event and link to the same Records
                        </div>
                        <img
                            src={pasteLink}
                            className="paste-option-modal-option-image"
                        />
                        <div
                            className="paste-option-modal-option-button"
                            onClick={() => {
                                if (showPasteModal?.callback)
                                    showPasteModal.callback("link");
                                setShowPasteOptionModal({ show: false });
                            }}
                        >
                            Paste Linked Event
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

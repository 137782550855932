// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import LumpSumpPaymentsCard from "../Card/lumpSumPaymentsCard";
import LumpSumPaymentsInput from "Components/InputContainer/LumpSumPaymentsInput";

// Import Connection Image (if exists)
import lumpSumPaymentsConnectionImage from "../../Assets/_optionDescriptionIcons/Loan, Lump Sum Payment, Expense.png";

// Import Coloured PNGs
import lumpSumPaymentBlack from "../../Assets/_eventNodeTypes/lumpSumPayments-black.png";
import lumpSumPaymentBlue from "../../Assets/_eventNodeTypes/lumpSumPayments-blue.png";
import lumpSumPaymentWhite from "../../Assets/_eventNodeTypes/lumpSumPayments-white.png";
import lumpSumPaymentGrey from "../../Assets/_eventNodeTypes/lumpSumPayments-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - lumpSumPaymentObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - lumpSumPaymentObject.checkInput.call(lumpSumPaymentObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const lumpSumPaymentObject = {
    constant: function () {
        return LumpSumpPayment_Constant();
    },

    checkInput: function (state) {
        return LumpSumpPayment_CheckInput(state);
    },

    svg: function () {
        return LumpSumpPayment_Svg();
    },

    eventNodeMenu: function (focus) {
        return LumpSumpPayment_EventNodeMenu(focus);
    },

    indexText: function () {
        return LumpSumpPayment_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return LumpSumpPayment_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return LumpSumpPayment_OptionDescription();
    },

    colouredPngs: function () {
        return LumpSumpPayment_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return LumpSumpPayment_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const LumpSumpPayment_CheckInput = (state) => {
    if (
        !state.name ||
        state.value === undefined ||
        state.value === null ||
        state.value === "" ||
        isNaN(state.value) ||
        !state.cadence ||
        !state.start ||
        !state.mortgage
    ) {
        return false;
    }
    return true;
};

// EFFECTS: Returns constant for event
const LumpSumpPayment_Constant = () => {
    return "Lump Sum Payments";
};

// EFFECTS: Returns black png / svg for event
const LumpSumpPayment_Svg = () => {
    return lumpSumPaymentBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const LumpSumpPayment_EventNodeMenu = (focus) => {
    return <LumpSumpPaymentsCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const LumpSumpPayment_IndexText = () => {
    return "Select an input field to learn more about your lumpSumPayment calculations.";
};

// EFFECTS: Returns all icons for event ??
const LumpSumpPayment_Icons = () => {
    let pngs = new Map([
        ["black", lumpSumPaymentBlack],
        ["blue", lumpSumPaymentBlue],
        ["white", lumpSumPaymentWhite],
        ["grey", lumpSumPaymentGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const LumpSumpPayment_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const LumpSumpPayment_InputComponentInfoPassing = (minProps) => {
    return <LumpSumPaymentsInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, lumpSumPayments for event ??
const LumpSumpPayment_OptionDescription = () => {
    const tags = ["Option"];
    const type = lumpSumPaymentObject.constant();
    const description =
        "Increasing mortgage payments or paying a one-time lump sum is a common option to consider when managing a mortgage. Use the Lump Sum Payments Event to calculate this. ";
    const usedForText =
        "Use the Lump Sum Payments Event to discover how a one-time payment, or increasing/decreasing your regular payments affects mortgage premiums, principle, interest, and the duration of a mortgage. This Event modifies an upstream Mortgage Event.";
    const connection = "";
    const connectionImage = lumpSumPaymentsConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/l/lump-sum-payment.asp#:~:text=A%20lump%2Dsum%20payment%20is,when%20dealing%20with%20a%20loan.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const LumpSumpPayment_SideModalImage = () => {
    return sideImage;
};

import Konva from "konva";
import { useContext, useMemo } from "react";
import useImage from "use-image";
import { Group } from "react-konva";
import { animated, useSpring } from "@react-spring/konva";
import { EventNodeBypassedEntityContext } from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/contexts/EventNodeStateContexts";

import { CanvasStageScaleContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageScaleContext";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";

export const BypassedEntityIndicator = () => {
    const containsBypassedEntity = useContext(EventNodeBypassedEntityContext);
    const stageScale = useContext(CanvasStageScaleContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);

    const [bypassIcon] = useImage("/images/icons/eventActionIcons/bypass.png");

    const containerStyles = useMemo(() => {
        let _containerStyles = {
            x: 0,
            y: -12.5,
        };
        if (zoomCategory === "zoom-xs") {
            _containerStyles = {
                ..._containerStyles,
                x: -5,
                y: -0,
            };
        }
        if (zoomCategory === "zoom-s") {
            _containerStyles = {
                ..._containerStyles,
                x: -5,
                y: -5,
            };
        }
        if (zoomCategory === "zoom-m") {
            _containerStyles = {
                ..._containerStyles,
            };
        }
        if (zoomCategory === "zoom-l") {
            _containerStyles = {
                ..._containerStyles,
            };
        }
        if (zoomCategory === "zoom-xl") {
            _containerStyles = {
                ..._containerStyles,
            };
        }
        return _containerStyles;
    }, [zoomCategory]);

    const backgroundStyles = useMemo(() => {
        let _backgroundStyles = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            cornerRadius: [50, 50, 50, 50],
            fill: "rgba(201, 110, 101, 0.8)",
            shadowBlur: 4,
            shadowColor: "#000000",
            shadowOffsetX: 0,
            shadowOffsetY: 2,
            shadowOpacity: 0.25,
        };
        if (zoomCategory === "zoom-xs") {
            _backgroundStyles = {
                ..._backgroundStyles,
                x: -5,
                y: -5,
                width: 10,
                height: 10,
            };
        }
        if (zoomCategory === "zoom-s") {
            _backgroundStyles = {
                ..._backgroundStyles,
                x: -8,
                y: -8,
                width: 16,
                height: 16,
            };
        }
        if (zoomCategory === "zoom-m") {
            _backgroundStyles = {
                ..._backgroundStyles,
                x: -9,
                y: -9,
                width: 18,
                height: 18,
            };
        }
        if (zoomCategory === "zoom-l") {
            _backgroundStyles = {
                ..._backgroundStyles,
                x: -11,
                y: -11,
                width: 22,
                height: 22,
            };
        }
        if (zoomCategory === "zoom-xl") {
            _backgroundStyles = {
                ..._backgroundStyles,
                x: -13,
                y: -13,
                width: 26,
                height: 26,
            };
        }
        if (!containsBypassedEntity) {
            _backgroundStyles = {
                ..._backgroundStyles,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            };
        }
        return _backgroundStyles;
    }, [containsBypassedEntity, zoomCategory]);

    const backgroundAnimation = useMemo(() => {
        const _backgroundAnimation = {};
        return _backgroundAnimation;
    }, []);

    const animatedBackgroundStyles = useSpring<Konva.RectConfig>({
        to: backgroundStyles,
        config: backgroundAnimation,
    });

    const iconStyles = useMemo(() => {
        let _iconStyles = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
        if (zoomCategory === "zoom-xs") {
            _iconStyles = {
                ..._iconStyles,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            };
        }
        if (zoomCategory === "zoom-s") {
            _iconStyles = {
                ..._iconStyles,
                x: -4.5,
                y: -4.5,
                width: 10,
                height: 10,
            };
        }
        if (zoomCategory === "zoom-m") {
            _iconStyles = {
                ..._iconStyles,
                x: -5.5,
                y: -5.5,
                width: 12,
                height: 12,
            };
        }
        if (zoomCategory === "zoom-l") {
            _iconStyles = {
                ..._iconStyles,
                x: -6,
                y: -6.5,
                width: 14,
                height: 14,
            };
        }
        if (zoomCategory === "zoom-xl") {
            _iconStyles = {
                ..._iconStyles,
                x: -7,
                y: -7.5,
                width: 16,
                height: 16,
            };
        }
        if (!containsBypassedEntity) {
            _iconStyles = {
                ..._iconStyles,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            };
        }
        return _iconStyles;
    }, [containsBypassedEntity, zoomCategory]);

    const iconAnimation = useMemo(() => {
        const _iconAnimation = {};
        return _iconAnimation;
    }, []);

    const animatedIconStyles = useSpring<Konva.RectConfig>({
        to: iconStyles,
        config: iconAnimation,
    });

    return (
        <Group
            {...containerStyles}
            scaleX={1 / stageScale}
            scaleY={1 / stageScale}
        >
            {/* @ts-ignore - Seems to be a react spring type error */}
            <animated.Rect {...animatedBackgroundStyles} />
            <animated.Image {...animatedIconStyles} image={bypassIcon} />
        </Group>
    );
};

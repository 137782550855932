import React, { Component } from "react";
import { Name, InputButtons, Description } from "./Components";
import radioButtonOff from "../../Assets/_nodeInputs/radioButtonOff.svg";
import radioButtonOn from "../../Assets/_nodeInputs/radioButtonOn.svg";
import { throwError } from "../../helpers/swalError";
import { getDefaultName } from "../../helpers";
import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";

export default class PropertyTransferTaxInput extends Component {
    constructor(props) {
        super(props);

        //if we are using this on baseline it should inherit the baseline manager if not ordinary manager

        let manager;
        if (props.manager && !this.props.baseline) {
            manager = props.manager;
        } else if (props.baselineManager) {
            manager = props.baselineManager;
        }
        // Context passing
        const parentId = props.focus
            ? props.focus.id
            : props.line.parentEvent.id;
        let node = manager._findEvent(parentId);
        let isValid = false;
        // checks for existing house node
        while (node && node.type !== "Me") {
            if (node.type === "House") {
                isValid = true;
                break;
            }
            if (node.parents[0] === undefined) break;
            node = manager._findEvent(node.parents[0].id);
        }

        this.state = {
            valid: isValid,
            foreignBuyerTrue: false,
            foreignBuyerFalse: false,
            firstTimeBuyerTrue: false,
            firstTimeBuyerFalse: false,
            type: propertyTransferTaxObject.constant(),
            name: getDefaultName(
                propertyTransferTaxObject.constant(),
                this.props
            ),
            tag: "@PropertyTransferTax",
            description: "",
            value: null,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.editData,
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onHandleSubmitValues = () => {
        const passedCheck = propertyTransferTaxObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }

        this.onHandleMouseLeave();
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }

        this.onHandleMouseLeave();
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    updateIsForeignBuyer = (checked) => {
        this.setState({ foreignBuyer: checked });
    };

    updateIsFirstTimeBuyer = (checked) => {
        this.setState({ firstTimeBuyer: checked });
    };

    //This is how we do the onchange values just put it in one function so it would be easy to locate stuff when needed
    //all you need to pass is onChangeInput to all the components that we have e.g. Name, Description etc. and it should be good. if you are not sure then look into components and check what is the expected props :)
    onChangeInput = (e, id, _star) => {
        const value = e.target.value;
        switch (id) {
            case "name":
                this.setState({ name: value });
                break;
            case "description":
                this.setState({ description: value });
                break;
            default:
        }
    };

    onHandleRadioButton = (id, value) => {
        switch (id) {
            case "foreignBuyer":
                if (value) {
                    this.setState({
                        foreignBuyerTrue: !this.state.foreignBuyerTrue,
                        foreignBuyerFalse: false,
                    });
                } else {
                    this.setState({
                        foreignBuyerFalse: !this.state.foreignBuyerFalse,
                        foreignBuyerTrue: false,
                    });
                }
                this.onHandleMouseEnter(id);
                break;
            case "firstTimeBuyer":
                if (value) {
                    this.setState({
                        firstTimeBuyerTrue: !this.state.firstTimeBuyerTrue,
                        firstTimeBuyerFalse: false,
                    });
                } else {
                    this.setState({
                        firstTimeBuyerFalse: !this.state.firstTimeBuyerFalse,
                        firstTimeBuyerTrue: false,
                    });
                }

                this.onHandleMouseEnter(id);
                break;
            default:
        }
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "firstTimeBuyer":
                data = {
                    title: "First time Buyer",
                    content:
                        "The First Time Home Buyers' (FTHB) Program gives a PTT exemption to eligible first time home buyers.",
                    subContent:
                        "To see whether you're qualified for full exemption",
                    link: "https://www.rebgv.org/content/dam/rebgv_org_content/pdfs/matrices-and-fact-sheets/PTT-Fact%20Sheet-August-2019.pdf",
                };
                break;
            case "foreignBuyer":
                data = {
                    title: "Foreign Buyer",
                    content:
                        "An additional 20% Property Transfer Tax (known as the Foreign Buyer Tax) is paid if the buyer is foreign entity and buys a property in Metro Vancouver.",
                    subContent:
                        "Foreign nationals with work permits coming through the BC Provincial Nominee Program are exempt from the additional 20% Property Transfer Tax when they buy a home. ",
                    link: "https://www.rebgv.org/content/dam/rebgv_org_content/pdfs/matrices-and-fact-sheets/PTT-Fact%20Sheet-August-2019.pdf",
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    onHandleMouseLeave = () => {
        this.props.onhandleFocusedInput(null);
    };

    render() {
        const {
            name,
            description,
            firstTimeBuyerTrue,
            firstTimeBuyerFalse,
            foreignBuyerTrue,
            foreignBuyerFalse,
        } = this.state;
        const { edit } = this.props;

        const passedCheck = propertyTransferTaxObject.checkInput(this.state);

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={propertyTransferTaxObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">
                                LAND TRANSFER TAX (CANADA)
                            </div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div
                        onMouseEnter={() =>
                            this.onHandleMouseEnter("firstTimeBuyer")
                        }
                        className="ModalRow"
                    >
                        <div className="oneThird">
                            <div className="Required">*</div>
                            <div className="PTTLabel">First Time Buyer</div>
                        </div>
                        <div className="oneThird">
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "firstTimeBuyer",
                                        true
                                    )
                                }
                                src={
                                    firstTimeBuyerTrue
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">Yes</div>
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "firstTimeBuyer",
                                        false
                                    )
                                }
                                id="firstTimeBuyer"
                                src={
                                    firstTimeBuyerFalse
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">No</div>
                        </div>
                    </div>
                    <div
                        onMouseEnter={() =>
                            this.onHandleMouseEnter("foreignBuyer")
                        }
                        className="ModalRow"
                    >
                        <div className="oneThird">
                            <div className="Required">*</div>
                            <div className="PTTLabel">Foreign Buyer</div>
                        </div>
                        <div className="oneThird">
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "foreignBuyer",
                                        true
                                    )
                                }
                                src={
                                    foreignBuyerTrue
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">Yes</div>
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "foreignBuyer",
                                        false
                                    )
                                }
                                src={
                                    foreignBuyerFalse
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">No</div>
                        </div>
                    </div>
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </div>
        );
    }
}

import { businessObject } from "Components/Registry/Business";

export const createBaselineScenario = (
    baselineManager,
    createBaseline,
    loadScenario,
    focus,
    selected,
    baselineDataManager,
    client
) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    baselineManager._reset();

    const nodeType =
        client.clientdata.clientType === "business"
            ? businessObject.constant()
            : "Me";
    baselineManager.addMeBusiness(true, nodeType, client.clientdata);

    const normalData = baselineManager.exportData();

    const baselineScenario = {
        name: client ? `${client.clientdata.name}'s Baseline` : "Baseline",
        description: "This is a scenario baseline",
        data: normalData,
        range: 10,
        inflation: 0.019,
        type: "baseline",
        account: loggedInUser.account,
        // default permissions for baseline
        permissions: {
            whitelist: {},
            default: {
                view: false,
                edit: false,
                comment: false,
                share: false,
                clone: false,
                changePermissions: false,
            },
        },
    };
    let id = null;
    return createBaseline(baselineScenario, (_err, _data) => {
        return;
    }).then((data) => {
        if (data) {
            baselineScenario.id = data.data;

            baselineDataManager.addNewBaseline(baselineScenario);
            baselineDataManager.setActiveBaseline(baselineScenario.id);
            loadScenario(
                baselineScenario,
                baselineManager,
                baselineDataManager
            );
            id = baselineScenario.id;
        } else {
            console.log("error on saving baseline from createBaselineScenario");
            return null;
        }
        return id;
    });
};

export const addNodeToBaseline = (focus, parent, baselineManager) => {
    const data = {
        ...focus,
        baseline: true,
        isMovedToBaseline: false,
    };
    const type = focus.type;
    const node = baselineManager.createEvent(type, data, focus.id);

    baselineManager.attachToNodes(node, [parent], [], false);
    baselineManager.calculate();
    // focus.toggleBaseline();
};

export const addNodeToNewPerson = (baselineManager, focus) => {
    //create person node and attach to root

    const personNode = baselineManager.createEvent("Person", {
        name: "Person",
    });
    baselineManager.attachToNodes(personNode, [baselineManager.root], []);

    const data = {
        ...focus,
        baseline: true,
        isMovedToBaseline: false,
    };
    const type = focus.type;
    const node = baselineManager.createEvent(type, data, focus.id);
    const parent = baselineManager._findEvent(personNode.id);
    baselineManager.attachToNodes(node, [parent], []);
};

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { setShowCellRowModal } from "actions/modalActions";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SelectDropDown } from "Components/InputContainer/Components";
import moment from "moment";
import { getScenarioEndDate } from "helpers/getScenarioEndDate";
import { formatValueWithUnitOld } from "helpers/getUnit";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { OverrideData } from "reducers/typesSchema/cellRowModalSchemas";
import { Modifier } from "Components/InputContainer/CustomHooks/useOverrides";

export const Override = ({
    field,
    entityData,
    ledgerAccount,
    createOverride,
}: OverrideData) => {
    const [scenarioStartDate, scenarioRange] = useAppSelector((state) => {
        if (
            state.scenario?.loadedScenario.start_date_preference ===
            "currentDate"
        ) {
            return [
                moment().format("YYYY-MM-DD"),
                state.scenario?.loadedScenario.range,
            ];
        } else if (
            state.scenario?.loadedScenario.start_date_preference ===
            "monthStart"
        ) {
            return [
                moment().startOf("month").format("YYYY-MM-DD"),
                state.scenario?.loadedScenario.range,
            ];
        } else {
            return [
                state.scenario?.loadedScenario.custom_start_date,
                state.scenario?.loadedScenario.range,
            ];
        }
    });

    const startDate = entityData.startDate || "";
    const endDate =
        entityData.endDate ||
        getScenarioEndDate(scenarioStartDate, scenarioRange);

    const entityCadence = entityData.cadence || "monthly";
    const prevOverrides: Modifier[] = entityData?.data?.modsCreated ?? [];
    const value: number | string =
        entityData.type !== accountModifierObject.constant()
            ? parseInt(entityData?.data?.income) ||
              parseInt(entityData?.data?.value)
            : "-";

    // TODO: Create new schema for context overrides
    const [prevOverride, _setPrevOverride] = useState<Modifier | null>(() => {
        if (prevOverrides && prevOverrides.length > 0) {
            let overrideCandidate;
            prevOverrides.forEach((override) => {
                if (override.keyToModify === field && !overrideCandidate) {
                    overrideCandidate = override;
                }
            });
            return overrideCandidate;
        } else {
            return null;
        }
    });

    const [cadence, setCadence] = useState(
        prevOverride ? prevOverride.cadence : entityCadence
    );

    function currencyFormatter(params) {
        return formatValueWithUnitOld(ledgerAccount, params.value);
    }

    const getOptions = () => {
        // Account modifiers are an exception and won't have varying cadences
        if (entityData.type !== accountModifierObject.constant()) {
            switch (entityCadence) {
                case "annually":
                    return ["annually"];
                case "monthly":
                    return ["annually", "monthly"];
                case "weekly":
                    return ["annually", "monthly", "weekly"];
                case "daily":
                    return ["annually", "monthly", "weekly", "daily"];
                default:
                    return [];
            }
        } else {
            return ["monthly"];
        }
    };

    const getNumColumns = (start: string, end: string, cadence: string) => {
        const startDate = moment(start);
        const endDate = moment(end);
        let dateDifference = 11;
        switch (cadence) {
            case "daily":
                dateDifference = endDate.diff(startDate, "days");
                break;
            case "weekly":
                dateDifference = endDate.diff(startDate, "days") / 7;
                break;
            case "monthly":
                dateDifference = Math.floor(
                    endDate.diff(startDate, "months", true)
                );
                break;
            case "annually":
                dateDifference = Math.floor(
                    endDate.diff(startDate, "years", true)
                );
                break;
            default:
                break;
        }
        return dateDifference;
    };

    const getDateStrings = useCallback(() => {
        const columns: string[] = [];
        const start = moment(startDate);
        const numColumns = getNumColumns(startDate, endDate, cadence);
        for (let i = 0; i <= numColumns; i++) {
            let dateString = "";
            switch (cadence) {
                case "annually":
                    start.add(i === 0 ? 0 : 1, "years");
                    dateString = start.format("YYYY");
                    break;
                case "monthly":
                    start.add(i === 0 ? 0 : 1, "month");
                    dateString = start.format("YYYY-MM");
                    break;
                case "weekly":
                    start.add(i === 0 ? 0 : 7, "days");
                    dateString = start.format("YYYY-MM-DD");
                    break;
                case "daily":
                    start.add(i === 0 ? 0 : 1, "days");
                    dateString = start.format("YYYY-MM-DD");
                    break;
                default:
                    break;
            }
            columns.push(dateString);
        }
        return columns;
    }, [cadence, endDate, startDate]);

    const [dates, setDates] = useState(getDateStrings());

    const generateColumnDefs = () => {
        const columns: {}[] = [];
        const format =
            cadence === "daily" || cadence === "weekly"
                ? "MMM DD YYYY"
                : "MMM YYYY";
        dates.forEach((dateString) => {
            columns.push({
                headerName: moment(dateString).format(format),
                field: dateString,
                width: 100,
                valueFormatter: currencyFormatter,
                cellClassRules: {
                    overriddenCellClass: (params) => {
                        const cellCurrentValue = params?.value;
                        const cellContainsOverriddenValue = !!(
                            value !== cellCurrentValue
                        );
                        return cellContainsOverriddenValue;
                    },
                },
            });
        });
        return columns;
    };

    const handleChangeCadence = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCadence(value);
    };

    useEffect(() => {
        setDates(getDateStrings());
    }, [cadence, getDateStrings]);

    useEffect(() => {
        setRowData([
            dates.reduce((resultObj, date) => {
                if (
                    prevOverride?.value[date] ||
                    prevOverride?.value[date] === 0
                ) {
                    resultObj[date] = prevOverride.value[date].toString();
                    return resultObj;
                }
                resultObj[date] = value;
                return resultObj;
            }, {}),
        ]);
        setColumnDefs(generateColumnDefs());
        // eslint-disable-next-line
    }, [dates, prevOverride, value]);

    const [rowData, setRowData] = useState([
        dates.reduce((resultObj, date) => {
            resultObj[date] = value;
            return resultObj;
        }, {}),
    ]);

    const [columnDefs, setColumnDefs] = useState(generateColumnDefs());

    const clearRowData = () => {
        const newRowData = [
            dates.reduce((resultObj, date) => {
                resultObj[date] = value;
                return resultObj;
            }, {}),
        ];
        gridApi.setRowData(newRowData);
    };

    const [gridApi, setGridApi] = useState<any>(null);

    const defaultColDef = {
        editable: true,
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const handleSubmitModifier = (
        gridApi,
        value,
        cadence: string,
        field: string
    ) => {
        const finalRowData = gridApi.getRowNode("0").data;
        createOverride(finalRowData, value, cadence, field);
        setShowCellRowModal({ show: false, modified: true });
    };

    return (
        <>
            <div className="gridContainer">
                <div className="ag-theme-balham" style={{ height: 80 }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        gridOptions={{
                            suppressContextMenu: true,
                            enableRangeSelection: true,
                        }}
                    ></AgGridReact>
                </div>
            </div>
            <div className="overrideLowerContainer">
                <SelectDropDown
                    onChangeInput={handleChangeCadence}
                    value={cadence}
                    options={getOptions()}
                    className="cadenceSelector"
                    id="cadence"
                    label="Select Frequency"
                    mainId={undefined}
                    disabled={undefined}
                    hideNullOption={true}
                    error={undefined}
                    helperText={undefined}
                />
                <div className="overrideButtonsContainer">
                    <button
                        className="overrideButton"
                        onClick={() => clearRowData()}
                    >
                        Clear Overrides
                    </button>
                    <button
                        className="overrideButton"
                        onClick={() =>
                            handleSubmitModifier(gridApi, value, cadence, field)
                        }
                    >
                        Submit
                    </button>
                </div>
            </div>
        </>
    );
};

import React from "react";
import styles from "./index.module.css";
import profilePlaceholder from "../../Assets/_settingsIcons/big.png";
import editSvg from "../../Assets/_budgetIcons/edit.svg";
import { EventsModal } from "../../Components/EventsModal";
import {
    Name,
    Url,
    SelectDropDownCountry,
} from "../../Components/InputContainer/Components";
import getGeolocation from "countrycitystatejson";

export default class Profile extends React.Component {
    state = {
        planId: null,
        edit: false,
        countries: null,
    };

    componentDidMount() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            this.props.getSubscription(loggedInUser, (err, data) => {
                if (data && data.data) {
                    this.setState({
                        planId: data.data.plan.id,
                    });
                } else {
                    this.setState({ planId: "free-plan" });
                }
            });

            this.props.getUserData(loggedInUser).then((data) => {
                if (data.data.data && data.data.data.country) {
                    const state = getGeolocation.getStatesByShort(
                        data.data.data.country
                    );
                    this.setState({ stateList: state });
                }
                this.setState({ ...data.data.data, userData: data.data });
            });

            const countries = getGeolocation.getCountries();
            this.setState({ countries });
        }
    }

    upgradePlan = () => {
        this.props.history.push("/billing");
    };

    managePortal = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            this.props.openPortal(loggedInUser, this.props.history);
        }
    };

    handleEdit = () => {
        this.setState({ edit: !this.state.edit });
    };

    onChangeInput = (e, id, _star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const newData = { ...this.state.userData, name: value };
                this.setState({ userData: newData });
                break;
            case "email":
                const newData2 = { ...this.state.userData, email: value };
                this.setState({ userData: newData2 });
                break;
            case "country":
                const newData3 = {
                    ...this.state.userData,
                    data: { ...this.state.userData.data, country: value },
                };
                const state = getGeolocation.getStatesByShort(value);
                this.setState({ userData: newData3, stateList: state });
                break;
            case "state":
                const newData4 = {
                    ...this.state.userData,
                    data: { ...this.state.userData.data, state: value },
                };
                this.setState({ userData: newData4 });
                break;
            default:
        }
    };

    toggleDisability = () => {
        const newData = {
            ...this.state.userData,
            data: {
                ...this.state.userData.data,
                hasDisability: !this.state.userData.data.hasDisability,
            },
        };
        this.setState({ userData: newData });
    };

    submitNewValue = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const loadedScenario = JSON.parse(
            localStorage.getItem("loadedScenario")
        );
        const newData = {
            ...loggedInUser,
            name: this.state.userData.name,
            data: JSON.stringify(this.state.userData.data),
            range: loadedScenario.range,
        };

        this.props.updateUserData(newData).then((data) => {
            if (data) {
                this.props.getUserData(loggedInUser).then((data) => {
                    if (data) {
                        this.props.getUserData(loggedInUser).then((data) => {
                            if (data.data.data && data.data.data.country) {
                                const state = getGeolocation.getStatesByShort(
                                    data.data.data.country
                                );
                                this.setState({ stateList: state });
                            }
                            this.setState({
                                ...data.data.data,
                                userData: data.data,
                            });
                        });

                        const countries = getGeolocation.getCountries();
                        this.setState({ countries });
                        this.handleEdit();
                    }
                });
            }
        });

        //send new data here
        // this.props.saveUserDetails(newData, this.state.userData.data)
    };

    render() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const {
            country,
            state,
            edit,
            userData,
        } = this.state;

        let maxOffset = 100;
        let thisYear = new Date().getFullYear();
        let allYears = [];
        for (let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x);
        }

        return (
            <div className={styles.settingContainer}>
                <div className={styles.headerOne}>
                    Profile{" "}
                    <img
                        onClick={this.handleEdit}
                        src={editSvg}
                        className={styles.editIcon}
                        alt="edit"
                    />
                </div>
                <div style={{ display: "flex" }}>
                    <div className={styles.leftSetting}>
                        <div className={styles.containers}>
                            <div className={styles.headerTwo}>
                                Account Information
                            </div>
                            <img
                                src={
                                    loggedInUser.image
                                        ? loggedInUser.image
                                        : profilePlaceholder
                                }
                                className={styles.profilePicture}
                                alt={"User Profile"}
                            />
                            <div className={styles.headerThree}>User Name</div>
                            <div className={styles.profileText}>
                                {loggedInUser.name}
                            </div>
                            <div className={styles.headerThree}>
                                Primary Email
                            </div>
                            <div className={styles.profileText}>
                                {loggedInUser.email}
                            </div>
                            <div className={styles.headerThree}>Plan</div>
                            <div className={styles.profileText}>
                                {this.state.planId}
                            </div>
                            <div
                                onClick={this.upgradePlan}
                                className={styles.profileTextButton}
                            >
                                Change to Other Plans
                            </div>
                            {this.state.planId !== "free-plan" && (
                                <div
                                    onClick={this.managePortal}
                                    className={styles.profileTextButton}
                                >
                                    Manage Plan
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.rightSetting}>
                        <div className={styles.leftSetting}>
                            <div className={styles.containers}>
                                <div className={styles.headerTwo}>About Me</div>
                                <div style={{ height: "30px" }} />
                                <div className={styles.headerThree}>
                                    Country of Residence
                                </div>
                                <div className={styles.profileText}>
                                    {country ? country : ""}
                                </div>

                                <div className={styles.headerThree}>
                                    Province / State
                                </div>
                                <div className={styles.profileText}>
                                    {state ? state : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {edit && (
                    <EventsModal onHandleClose={this.handleEdit} edit={true}>
                        <div className={styles.modalRow}>
                            <div className={styles.headerOne}>Edit Profile</div>
                        </div>
                        <div className={styles.modalRow}>
                            <Name
                                name={userData.name}
                                onChangeInput={this.onChangeInput}
                            />
                        </div>
                        <div className={styles.modalRow}>
                            <Url
                                value={userData.email}
                                className={styles.outlineInput}
                                onChangeInput={this.onChangeInput}
                                id="email"
                                label="Email"
                            />
                        </div>
                        <div className={styles.dropdownContainer}>
                            <SelectDropDownCountry
                                onChangeInput={this.onChangeInput}
                                value={userData.data.country}
                                options={this.state.countries}
                                className={styles.country}
                                id="country"
                                label="Select Country"
                            />
                            <SelectDropDownCountry
                                onChangeInput={this.onChangeInput}
                                value={userData.data.state}
                                options={this.state.stateList}
                                className={styles.country}
                                id="state"
                                label="Select State"
                            />
                        </div>
                        <div className={styles.modalRow}>
                            <div
                                className={styles.submitButton}
                                onClick={this.submitNewValue}
                            >
                                Submit
                            </div>
                        </div>
                    </EventsModal>
                )}
            </div>
        );
    }
}

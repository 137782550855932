export const SHOW_CUSTOM_ACCOUNT_MODAL = "SHOW_CUSTOM_ACCOUNT_MODAL";

interface SelectedAccount {
    name: string;
    ids: any[];
}

export const openCustomAccountModal = (
    sticky = false,
    initialTopLevelAccount: SelectedAccount = {
        name: "",
        ids: [],
    },
    initialSubAccount: SelectedAccount = {
        name: "",
        ids: [],
    },
    initialLeafAccount: SelectedAccount = {
        name: "",
        ids: [],
    }
) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CUSTOM_ACCOUNT_MODAL,
            payload: {
                modalOpen: true,
                sticky: sticky,
                initialTopLevelAccount: initialTopLevelAccount,
                initialSubAccount: initialSubAccount,
                initialLeafAccount: initialLeafAccount,
            },
        });
    };
};

export const closeCustomAccountModal = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CUSTOM_ACCOUNT_MODAL,
            payload: {
                modalOpen: false,
                sticky: false,
                initialTopLevelAccount: {
                    name: "",
                    ids: [],
                },
                initialSubAccount: {
                    name: "",
                    ids: [],
                },
                initialLeafAccount: {
                    name: "",
                    ids: [],
                },
            },
        });
    };
};

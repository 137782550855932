// import type {ChangeEvent} from "react";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { camelCase } from "lodash";
import swal from "sweetalert";
import moment from "moment";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { handleDependencies } from "helpers/updateDependencyMap";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const contractInputsHandler = (
    value: string,
    id:
        | "value"
        | "initialPayout"
        | "contractLength"
        | "url"
        | "entityName"
        | "startDate"
        | "netInitial"
        | "netFinal"
        | "rating"
        | "business"
        | "payoutPercentage"
        | "offset"
        | "net"
        | "accountName",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const updateInitialPayoutPercent = (currentEntityObject, data, value) => {
        // Checks if the new percentage values are <=100 and then updates the relevant states
        const totalPercent = getTotalPercentage(
            data.specificPercentages,
            Number(value)
        );
        if (totalPercent <= 100) {
            data.initialPayout = value;
        }
        const finalPay = 100 - totalPercent;
        if (finalPay >= 0) {
            data.finalPayout = finalPay;
        }
        currentEntityObject.data = data;
        return currentEntityObject;
    };

    const updateMilestonePercentages = (
        currentEntityObject,
        data,
        value,
        mainID
    ) => {
        // Checks if the new percentage values are <=100 and then updates the relevant states
        const specificPercentages = data.specificPercentages;
        const initialPayout = Number(data.initialPayout);
        const newData = specificPercentages.map((milestoneData) => {
            if (milestoneData.id === mainID) {
                const newPercentageData = {
                    ...milestoneData,
                    [`value`]: value,
                };
                return newPercentageData;
            }
            return milestoneData;
        });
        const totalPercent = getTotalPercentage(newData, initialPayout);
        if (totalPercent <= 100) {
            data.specificPercentages = newData;
        }
        const finalPay = 100 - totalPercent;
        if (finalPay >= 0) {
            data.finalPayout = finalPay;
        }
        currentEntityObject.data = data;
        return currentEntityObject;
    };

    const getTotalPercentage = (milestones, initialPayout) => {
        // Calculates and returns the total percentage payout of the milestones + the initialPayout percent
        let totalPercent = 0;
        if (milestones.length > 0) {
            milestones.forEach((currentVal) => {
                totalPercent += Number(currentVal.value);
            });
        }
        return totalPercent + initialPayout;
    };

    const updateModEnd = (currentEntityObject, data, value) => {
        const end = currentEntityObject.endDate;
        const start = currentEntityObject.startDate;

        if (start && end) {
            if (value) {
                const modEnd = new Date(end);
                modEnd.setDate(modEnd.getDate() + Number(value.split(" ")[0]));
                const finalEndDate = modEnd.toISOString().split("T")[0];
                data.netFinal = value;
                data.modifiedEnd = finalEndDate;
                if (start && finalEndDate) {
                    const diff = calcDateDiff(start, finalEndDate);
                    data.dayDiff = diff;
                }
            } else {
                // If no net days option is chosen the modifiedEnd is equal to endDate
                data.netFinal = value;
                data.modifiedEnd = end;
                if (start && end) {
                    const diff = calcDateDiff(start, end);
                    data.dayDiff = diff;
                }
            }
        } else {
            data.netFinal = value;
        }

        currentEntityObject.data = data;
    };

    const onChangeDynamicValues = (
        currentEntityObject,
        data,
        value,
        mainID,
        key
    ) => {
        const milestones = data.specificPercentages;
        let newData = milestones.map((milestone) => {
            if (milestone.id === mainID) {
                const newMilestone = {
                    ...milestone,
                    [`${key}`]: value,
                };
                return newMilestone;
            }
            return milestone;
        });

        newData = updateMilestoneDates(newData);
        data.specificPercentages = newData;
        currentEntityObject.data = data;
        return currentEntityObject;
    };

    function handleChangeContractLength() {
        if (data.calculationMode == "days") {
            data.contractLengthDays = value;
        } else {
            data.contractLengthMonths = value;
        }
        const completionDate = getContractCompletionDate(value);
        currentEntityObject.endDate = completionDate;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEndDate(completionDate);
    }

    const setEndDate = (endDate) => {
        // Checks if the end date land after the start date and all milestones
        // If it does we then update the endDate, modifiedEnd date, and the daysDiff fields
        const startDate = currentEntityObject.startDate;
        const netFinal = data.netFinal;
        const modifiedEnd = data.modifiedEnd;

        currentEntityObject.endDate = endDate;
        if (modifiedEnd === "" && netFinal === "") {
            data.modifiedEnd = endDate;
        } else if (modifiedEnd !== "" && netFinal === "") {
            data.modifiedEnd = endDate;
        } else if (modifiedEnd === "" && netFinal !== "") {
            const modEnd = new Date(endDate);
            modEnd.setDate(modEnd.getDate() + Number(netFinal.split(" ")[0]));
            const finalEndDate = modEnd.toISOString().split("T")[0];
            data.modifiedEnd = finalEndDate;
            if (startDate && finalEndDate) {
                const diff = calcDateDiff(startDate, finalEndDate);
                data.dayDiff = diff;
            }
        } else {
            const modEnd = new Date(endDate);
            modEnd.setDate(modEnd.getDate() + Number(netFinal.split(" ")[0]));
            const finalEndDate = modEnd.toISOString().split("T")[0];
            data.modifiedEnd = finalEndDate;
            if (startDate && finalEndDate) {
                const diff = calcDateDiff(startDate, finalEndDate);
                data.dayDiff = diff;
            }
        }
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
    };

    function getContractCompletionDate(offset) {
        const startDate = currentEntityObject.startDate;
        if (!startDate) {
            return;
        }

        const calculationMode = currentEntityObject.data.calculationMode;
        const completionDate = moment(startDate)
            .add(Number(offset), calculationMode)
            .format("YYYY-MM-DD");
        return completionDate;
    }

    function updateMilestoneDates(milestones) {
        const startDate = currentEntityObject.startDate;
        if (!startDate) {
            return milestones;
        }

        const calculationMode = currentEntityObject.data.calculationMode;
        const newMilestones = milestones.map((milestone) => {
            const offset =
                calculationMode == "days"
                    ? milestone.offsetDays
                    : milestone.offsetMonths;

            if (!offset)
                return {
                    ...milestone,
                    date: "",
                };

            const newDate = moment(startDate)
                .add(Number(offset), calculationMode)
                .format("YYYY-MM-DD");

            return {
                ...milestone,
                date: newDate,
            };
        });

        return newMilestones;
    }

    switch (id) {
        case "value":
            data.income = value;
            data.value = parseInt(value);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "initialPayout":
            newEntitiesMap[currentEntity] = updateInitialPayoutPercent(
                currentEntityObject,
                data,
                value
            );
            break;
        case "contractLength":
            if (value.trim() == "") {
                if (data.calculationMode == "days") {
                    data.contractLengthDays = value;
                } else {
                    data.contractLengthMonths = value;
                }
                currentEntityObject.endDate = "";
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            } else if (!isNaN(Number(value))) {
                handleChangeContractLength();
            }
            break;
        case "url":
            data.url = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            const finalString = camelCase(value);
            data.tag = `@${finalString}`;
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.startDate = startDateString;

            const calculationMode = data.calculationMode;
            const contractLength =
                calculationMode == "days"
                    ? data.contractLengthDays
                    : data.contractLengthMonths;
            if (contractLength) {
                currentEntityObject.endDate = moment(startDateString)
                    .add(Number(contractLength), calculationMode)
                    .format("YYYY-MM-DD");
            } else {
                currentEntityObject.endDate = "";
            }

            updateModEnd(currentEntityObject, data, data.netFinal ?? 0);
            const modifiedEnd = data.modifiedEnd;
            if (startDateString && modifiedEnd) {
                const diff = calcDateDiff(startDateString, modifiedEnd);
                data.dayDiff = diff;
            }

            // update milestone dates
            data.specificPercentages.forEach((milestone) => {
                const offset =
                    calculationMode == "days"
                        ? milestone.offsetDays
                        : milestone.offsetMonths;

                if (!offset) {
                    milestone.date = "";
                } else {
                    milestone.date = moment(startDateString)
                        .add(Number(offset), calculationMode)
                        .format("YYYY-MM-DD");
                }
            });

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "netInitial":
            data.netInitial = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "netFinal":
            updateModEnd(currentEntityObject, data, value);
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "business":
            const parsedBusiness = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedBusiness,
                eventId,
                currentEntityObject.id,
                data.businessEventId,
                data.businessIds
            );

            data.selectedBusiness = parsedBusiness;
            data.businessEventId = parsedBusiness?.eventId ?? "";
            data.businessIds = parsedBusiness?.entityIds ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "payoutPercentage":
            // the star value passed to updateMilestonePercentages is the id of the relevant milestone
            newEntitiesMap[currentEntity] = updateMilestonePercentages(
                currentEntityObject,
                data,
                value,
                star
            );
            break;
        case "offset":
            if (value.trim() == "") {
                data.specificPercentages.forEach((milestone) => {
                    if (milestone.id === star) {
                        if (data.calculationMode == "days") {
                            milestone.offsetDays = "";
                        } else {
                            milestone.offsetMonths = "";
                        }
                        milestone.date = "";
                    }
                });
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            } else if (!isNaN(Number(value))) {
                newEntitiesMap[currentEntity] = onChangeDynamicValues(
                    currentEntityObject,
                    data,
                    value,
                    star,
                    data.calculationMode == "days"
                        ? "offsetDays"
                        : "offsetMonths"
                );
            }
            break;
        case "net":
            // the star value passed to updateMilestonePercentages is the id of the relevant milestone
            newEntitiesMap[currentEntity] = onChangeDynamicValues(
                currentEntityObject,
                data,
                value,
                star,
                id
            );
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name;
            data.accountIds = account.ids;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

// HELPER FUNCTIONS TO EXPORT

/**
 *
 * Calculates the number of days between the start and end dates
 */
export const calcDateDiff = (startDate, endDate) => {
    if (startDate !== null && endDate !== null) {
        const start = moment(startDate);
        const end = moment(endDate);
        const difference = end.diff(start, "days");

        return difference;
    }
    return 0;
};

export function validateContractLengthInput(entity, reset) {
    const milestones = entity.data.specificPercentages;
    const calculationMode = entity.data.calculationMode;
    const value =
        calculationMode == "days"
            ? entity.data.contractLengthDays
            : entity.data.contractLengthMonths;

    let isValid = true;

    if (isNaN(Number(value))) {
        isValid = false;
    }

    // Check if the milestones are within the start and end dates
    for (const milestone of milestones) {
        const offset =
            calculationMode == "days"
                ? milestone.offsetDays
                : milestone.offsetMonths;
        if (offset == "") {
            break;
        }
        if (Number(offset) <= 0 || Number(offset) >= Number(value)) {
            isValid = false;
        }
    }

    if (!value || value.trim() == "") {
        isValid = true;
    }

    // if not valid, erase contract length input
    if (!isValid) {
        reset();
        swal({
            title: "Error",
            text: "Completion date must occur after the start date and latest milestone",
        });
    }
}

export function validateMilestoneOffsetInput(entity, reset, milestone) {
    const calculationMode = entity.data.calculationMode;
    const contractLength =
        calculationMode == "days"
            ? entity.data.contractLengthDays
            : entity.data.contractLengthMonths;
    const offset =
        calculationMode == "days"
            ? milestone.offsetDays
            : milestone.offsetMonths;
    const id = milestone.id;

    let isValid = true;

    if (isNaN(Number(offset))) {
        isValid = false;
    }

    if (Number(offset) <= 0 || Number(offset) >= contractLength) {
        isValid = false;
    }

    if (!offset || offset.trim() == "") {
        isValid = true;
    }

    // if not valid, erase milestone offset input
    if (!isValid) {
        reset(id);
        swal({
            title: "Error",
            text: "Milestone must occur after the start date and before the completion date",
        });
    }
}

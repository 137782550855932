import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";

export const percentageInputsHandler = (
    value: string,
    id:
        | "value"
        | "startDate"
        | "endDate"
        | "entityName"
        | "sourceAccount"
        | "targetAccount"
        | "targetContraAccount",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "value":
            data.value = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            const endDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "sourceAccount":
            if (!account) break;
            data.sourceAccountName = account.name;
            data.sourceAccountIds = account.ids;

            data.accountIds = [
                ...data.sourceAccountIds,
                ...data.targetAccountIds,
                ...data.targetContraAccountIds,
            ];

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "targetAccount":
            if (!account) break;
            data.targetAccountName = account.name;
            data.targetAccountIds = account.ids;

            data.accountIds = [
                ...data.sourceAccountIds,
                ...data.targetAccountIds,
                ...data.targetContraAccountIds,
            ];

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "targetContraAccount":
            if (!account) break;
            data.targetContraAccountName = account.name ?? "Cash (default)";
            data.targetContraAccountIds = account.ids ?? [];

            data.accountIds = [
                ...data.sourceAccountIds,
                ...data.targetAccountIds,
                ...data.targetContraAccountIds,
            ];

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

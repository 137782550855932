// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

import {
    Description,
    Rate,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    EntityName,
    MuiCalendar,
    BasicHeading,
    ToggledContainer,
    ToggledContainerHalf,
} from "../Components";
import Switch from "react-switch";
import { percentageObject } from "Components/Registry/Percentage";

const PercentageInputView = ({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleEntityStateChange,
    updateAccount,
    handleOnChange,
    handleChangeDebitCredit,
    toggleCustomEffectPeriod,
    handleDateSelection,
}) => {
    const entityData = entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={percentageObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={percentageObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[percentageObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ModalRow>
                    <BasicHeading
                        copy="Select Source Account"
                        margin="15px 0"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <InputDropDownButton
                            id="sourceAccount"
                            callback={updateAccount}
                            initialValue={entityData?.sourceAccountName ?? ""}
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <Rate
                            label="Percentage Amount"
                            onChangeInput={handleOnChange}
                            value={entityData.value ?? ""}
                            id="value"
                            className="mlsInput"
                            sign={true}
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="debit"
                        isSelected={entityData?.debitOrCredit === "debit"}
                        onClick={handleChangeDebitCredit}
                        title="Debit"
                    />
                    <ToggledContainerHalf
                        id="credit"
                        isSelected={entityData?.debitOrCredit === "credit"}
                        onClick={handleChangeDebitCredit}
                        title="Credit"
                    />
                </ToggledContainer>
                <ModalRow>
                    <BasicHeading
                        copy="Select Target Accounts"
                        margin="15px 0"
                    />
                </ModalRow>
                <ModalRow>
                    <InputDropDownButton
                        id="targetAccount"
                        callback={updateAccount}
                        initialValue={entityData?.targetAccountName ?? ""}
                        required={true}
                    />
                </ModalRow>
                <ModalRow>
                    <InputDropDownButton
                        id="targetContraAccount"
                        callback={updateAccount}
                        initialValue={entityData?.targetContraAccountName}
                        contraAccount={true}
                        accountSelectedForContra={
                            entityData?.targetContraAccountIds.length
                                ? entityData?.targetContraAccountIds[0]
                                : ""
                        }
                    />
                </ModalRow>
                <ModalRow>
                    <Switch
                        checked={entityData?.customEffectPeriod}
                        className="InflationToggle"
                        onChange={toggleCustomEffectPeriod}
                        height={20}
                        width={40}
                    />
                    <div className="InflationText">Custom Date Range</div>
                </ModalRow>
                <ModalRow twoInputs>
                    {entityData?.customEffectPeriod && (
                        <>
                            <ModalRowHalf>
                                <MuiCalendar
                                    id="startDate"
                                    value={
                                        entitiesMap?.[currentEntity]
                                            ?.startDate === ""
                                            ? null
                                            : entitiesMap?.[currentEntity]
                                                  ?.startDate
                                    }
                                    required={true}
                                    onChangeInput={handleDateSelection}
                                    label="Start Date"
                                />
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <MuiCalendar
                                    id="endDate"
                                    value={
                                        entitiesMap?.[currentEntity]
                                            ?.endDate === ""
                                            ? null
                                            : entitiesMap?.[currentEntity]
                                                  ?.endDate
                                    }
                                    onChangeInput={handleDateSelection}
                                    label="End Date"
                                    helperText="An end date is optional"
                                />
                            </ModalRowHalf>
                        </>
                    )}
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default PercentageInputView;

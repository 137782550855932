import type {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import { useEffect, useState, useRef } from "react";
import * as uuid from "uuid";
import EntityTypeStructure from "../../../exampleConfigJson/entityTypesStructure.json";
import { updateNode } from "actions/scenario";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";
import { customer2Object } from "Components/Registry/Customer2";
import {
    duplicateCustomer2Entity,
    duplicateEntityAccount,
    duplicateWebsiteVisitorEntity,
} from "helpers/duplicateEntityHelpers";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import {
    deleteEntityFromMap,
    handleDuplicatedEntity,
} from "helpers/updateDependencyMap";
export interface EventStructure {
    children: string[];
    parents: string[];
    description: string;
    entities: {
        id: string;
        active: boolean;
    }[];
    id: string;
    name: string;
    type: string;
    version: string;
    baseline: boolean;
    locked: boolean;
    linked: boolean;
    bypassed: boolean;
    mostRecentEntity: number;
    userSetX?: number;
    userSetY?: number;
}

/**
 * This hook stores all entity-related state. It also provides the functions to
 * traverse through Entity Cards (left and right arrows), duplicate, add or delete
 * Entities within an Event in the canvas.
 */
const useEntities = (
    entitiesObject: EntitiesSchema,
    eventData: EventStructure,
    edit: boolean,
    eventObject?: Record<any, any>,
    dependencyMap?: DependencyMapSchema
) => {
    const cancelTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [entityIds, setEntityIds] = useState<string[]>(() => {
        return eventData.entities.map((entity) => entity.id);
    });

    const [entityIndex, setEntityIndex] = useState<number>(
        eventData.mostRecentEntity || 0
    );
    const [entitiesMap, setEntitiesMap] = useState<EntitiesSchema>(() => {
        const eventEntities = {};

        if (edit) {
            for (let i = 0; i < eventData.entities.length; i++) {
                if (entitiesObject[eventData.entities[i].id]) {
                    const entity = {
                        ...entitiesObject[eventData.entities[i].id],
                    };
                    const entityData = { ...entity.data };
                    if (entityData?.modsCreated) {
                        const entityMods = entityData.modsCreated.map((m) => {
                            return { ...m, value: { ...m.value } };
                        });
                        entityData.modsCreated = entityMods;
                    }
                    entity.data = entityData;
                    eventEntities[eventData.entities[i].id] = entity;
                }
            }
        } else {
            eventEntities[eventData.entities[0].id] = {
                ...EntityTypeStructure[eventData.type],
                id: eventData.entities[0].id,
            };
        }

        return eventEntities;
    });

    const [currentEntity, setCurrentEntity] = useState<string>(() => {
        return entityIds[entityIndex] || "";
    });

    useEffect(() => {
        setCurrentEntity(entityIds[entityIndex]);
    }, [entityIds, entityIndex]);

    useEffect(() => {
        if (eventObject) {
            if (currentEntity !== entityIds?.[eventData.mostRecentEntity]) {
                if (cancelTimeoutRef.current) {
                    clearTimeout(cancelTimeoutRef.current);
                }

                cancelTimeoutRef.current = setTimeout(() => {
                    let doesEntityExist = false;
                    store
                        ?.getState()
                        ?.scenario?.editData?.entities?.every((entity) => {
                            if (entity.id === entityIds?.[entityIndex]) {
                                doesEntityExist = true;
                                return false;
                            } else {
                                return true;
                            }
                        });
                    eventData.mostRecentEntity =
                        eventObject?.checkInput(entitiesMap) && doesEntityExist
                            ? entityIndex
                            : eventData.mostRecentEntity;
                    if (edit) {
                        store.dispatch(
                            updateNode(
                                eventData,
                                store.getState().scenario?.loadedScenario,
                                store.getState().scenario?.manager,
                                eventData
                            )
                        );
                    } else {
                        store.dispatch(
                            updateNodeKeepFocus(
                                eventData,
                                store.getState().scenario?.loadedScenario,
                                eventData
                            )
                        );
                    }
                }, 1000);
            }
        }
    }, [
        currentEntity,
        edit,
        entitiesMap,
        entityIds,
        entityIndex,
        eventData,
        eventObject,
    ]);

    const handleClickChangeEntity = (leftOrRight) => {
        if (leftOrRight === "left" && entityIndex > 0) {
            setEntityIndex((prevIndex) => prevIndex - 1);
        } else if (
            leftOrRight === "right" &&
            entityIndex < entityIds.length - 1
        ) {
            setEntityIndex((prevIndex) => prevIndex + 1);
        } else return;
    };

    const handleClickAddEntityCard = (defaultData) => {
        // defaultData: provide the shape of the data obj inside entity, depending on its type

        const newEntity: Omit<EntitySchema, "associatedEventIds"> = {
            ...defaultData,
            id: uuid.v4(),
        };

        // Adding the new Entity's ID at the end of entityIds
        const newEntityIds = [...entityIds, newEntity.id];
        setEntityIds(newEntityIds);

        // Adding new empty entity object to the entitiesMap object
        const newEntitiesMap = { ...entitiesMap };
        newEntitiesMap[newEntity.id] = newEntity;
        setEntitiesMap(newEntitiesMap);

        // Switching the index to the newly added entity card (last index of array)
        setEntityIndex(newEntityIds.length - 1);
    };

    const handleClickDeleteEntity = () => {
        // TO DO: Implement 2-step confirmation logic!
        // i.e. "ARE YOU SURE TO DELETE? YAY OR NAY"

        if (dependencyMap) {
            // Remove entity from dependencies_map
            deleteEntityFromMap(dependencyMap, currentEntity);
        }

        if (entityIds.length > 1) {
            // Delete Entity object from entitiesMap
            const newEntitiesMap = { ...entitiesMap };
            delete newEntitiesMap[currentEntity];

            // Also remove entity ID from entityIds array
            const newEntityIds = [...entityIds];
            newEntityIds.splice(entityIndex, 1);

            setEntitiesMap(newEntitiesMap);

            if (entityIndex === entityIds.length - 1)
                setCurrentEntity(entityIds[entityIndex - 1]);
            else setCurrentEntity(entityIds[entityIndex + 1]);

            setEntityIds([...newEntityIds]),
                setEntityIndex(
                    entityIndex < newEntityIds.length - 1
                        ? entityIndex
                        : newEntityIds.length - 1
                );
        }
    };

    const handleClickDuplicateEntity = () => {
        const newEntitiesMap = { ...entitiesMap };
        const duplicatedEntity = {
            ...entitiesMap[currentEntity],
        };
        duplicatedEntity.id = uuid.v4(); // replacing duplicated ID with a new ID
        duplicatedEntity.name = `${duplicatedEntity.name} Copy`; // adding the word Copy to the end of each duplicated entitiy name

        const duplicatedData = { ...duplicatedEntity.data };
        // Handles duplicating implicit modifier/overrides
        if (duplicatedData?.modsCreated) {
            const entityMods = duplicatedData.modsCreated.map((m) => {
                return {
                    ...m,
                    value: { ...m.value },
                    id: uuid.v4(),
                    entityId: duplicatedEntity.id,
                };
            });
            duplicatedData.modsCreated = entityMods;
        }
        duplicatedEntity.data = duplicatedData;

        if (duplicatedEntity.type == customer2Object.constant()) {
            duplicateCustomer2Entity(duplicatedEntity);
        } else if (duplicatedEntity.type == websiteVisitorsObject.constant()) {
            duplicateWebsiteVisitorEntity(duplicatedEntity);
        } else if (duplicatedEntity.data.accountStructure) {
            duplicateEntityAccount(duplicatedEntity);
        }

        // Add outgoing dependencies to duplicated entity (incoming dependencies are not retained)
        if (dependencyMap) {
            handleDuplicatedEntity(
                dependencyMap,
                eventData.id,
                duplicatedEntity
            );
        }

        // Adding the duplicated entity to the entitiesMap object (with a new ID)
        newEntitiesMap[duplicatedEntity.id] = { ...duplicatedEntity };

        // Also push the duplicated entity's ID to entityIds array
        const newEntityIds = [...entityIds, duplicatedEntity.id];

        setEntitiesMap(newEntitiesMap);
        setEntityIds(newEntityIds);
        setEntityIndex(newEntityIds.length - 1);
    };

    const onHandleDate = (startDate: string, endDate?: string) => {
        const newEntitiesMap = { ...entitiesMap };
        const newEntityObject = { ...newEntitiesMap[currentEntity], startDate };
        if (!endDate) {
            newEntityObject.endDate = "";
        } else {
            newEntityObject.endDate = endDate;
        }
        newEntitiesMap[currentEntity] = newEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onToggleCheckBox = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.disableOnRetirement = !data.disableOnRetirement;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        setEntitiesMap(newEntitiesMap);
    };

    const onHandleStarHover = (i) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.onHoverStar = i;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        setEntitiesMap(newEntitiesMap);
    };

    const onMouseLeave = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.onHoverStar = null;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        setEntitiesMap(newEntitiesMap);
    };

    const updateInflation = (checked) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.inflation = checked;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        setEntitiesMap(newEntitiesMap);
    };

    return {
        currentEntity,
        entitiesMap,
        entityIds,
        entityIndex,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setCurrentEntity,
        setEntitiesMap,
        setEntityIds,
        setEntityIndex,
        onHandleDate,
        onToggleCheckBox,
        onHandleStarHover,
        onMouseLeave,
        updateInflation,
    };
};

export default useEntities;

import { editScenario, getUserScenarios } from "actions/scenario.js";
import type { AppThunk } from "store";
import { requestHeader } from "actions/scenario";
import axios from "axios";
import { config } from "config";
import { UPDATE_FOCUSED_CARD_ID } from "actions/types";

export const SET_CANVAS_CARDS = "SET_CANVAS_CARDS";

export const createCard = (cardObject): AppThunk<void> => {
    return (dispatch, getState) => {
        return axios
            .post(`${config.host}/cards`, { cardObject }, requestHeader())
            .then((response) => {
                const createdCardId = response?.data;
                const loadedScenario = getState()?.scenario?.loadedScenario;

                let newScenario = loadedScenario;

                newScenario = {
                    ...loadedScenario,
                    data: {
                        ...loadedScenario.data,
                        canvasCardIds: [
                            createdCardId,
                            ...(loadedScenario?.data?.canvasCardIds ?? []),
                        ],
                    },
                };

                dispatch(
                    editScenario(newScenario, (err, data) => {
                        if (data) {
                            dispatch(getUserScenarios());
                        } else if (err) {
                            console.log("error favouriting an account", err);
                        }
                    })
                );

                dispatch(getCards());
            })
            .catch((err) => {
                console.log(err, "<==== ERRR");
            });
    };
};

export const getCards = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/cards`, requestHeader())
            .then((response) => {
                dispatch({
                    type: SET_CANVAS_CARDS,
                    payload: {
                        cards: response?.data?.cards ?? {},
                    },
                });
            })
            .catch((err) => {
                console.log(err, "<==== ERRR");
            });
    };
};

export const updateCard = (cardObject): AppThunk<void> => {
    return (dispatch) => {
        if (!cardObject?.id) return;
        return axios
            .put(
                `${config.host}/cards`,
                { cardsData: { [cardObject.id]: cardObject } },
                requestHeader()
            )
            .then(() => {
                dispatch(getCards());
            })
            .catch((err) => {
                console.log(err, "<==== ERRR");
            });
    };
};

export const deleteCard = (cardIds): AppThunk<void> => {
    return (dispatch, getState) => {
        return axios
            .delete(`${config.host}/cards`, {
                headers: requestHeader().headers,
                data: { cardIds: cardIds },
            })
            .then(() => {
                const loadedScenario = getState()?.scenario?.loadedScenario;

                let newScenario = loadedScenario;

                const newCanvasCardIds = newScenario?.data?.canvasCardIds;

                cardIds?.forEach((cardId) => {
                    const cardIdIndex = newCanvasCardIds?.indexOf(cardId);
                    newCanvasCardIds?.splice(cardIdIndex, 1);
                });

                newScenario = {
                    ...loadedScenario,
                    data: {
                        ...loadedScenario.data,
                        canvasCardIds: newCanvasCardIds ?? [
                            ...loadedScenario?.data?.canvasCardIds,
                        ],
                    },
                };

                dispatch(
                    editScenario(newScenario, (err, data) => {
                        if (data) {
                            dispatch(getUserScenarios());
                        } else if (err) {
                            console.log("error favouriting an account", err);
                        }
                    })
                );

                dispatch(getCards());
            })
            .catch((err) => {
                console.log(err, "<==== ERRR");
            });
    };
};

export const updatedFocusedCardId = (cardId: null | string): AppThunk<void> => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_FOCUSED_CARD_ID,
            payload: cardId ? cardId : "",
        });
    };
};

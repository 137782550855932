// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    EntityName,
    Rate,
    MuiCalendar,
    SelectEntities,
} from "../Components";
import { customerObject } from "../../Registry/Customer";
import { useState } from "react";

export default function CustomerInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleClickSubmitOrUpdate,
    edit,
    handleEntityStateChange,
    handleDateSelection,
    handleOnChange,
    isSubmitted,
}) {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={customerObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={customerObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[customerObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                {creatingCustomAccounts ? (
                    <div>Creating custom accounts...</div>
                ) : (
                    <>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <Rate
                                    label="Initial Customer Count"
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.customer
                                    }
                                    id="customer"
                                    className="mlsInput"
                                    sign={false}
                                    required={true}
                                />
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <MuiCalendar
                                    id="startDate"
                                    value={
                                        entitiesMap?.[currentEntity]
                                            ?.startDate === ""
                                            ? null
                                            : entitiesMap?.[currentEntity]
                                                  ?.startDate
                                    }
                                    required={true}
                                    onChangeInput={handleDateSelection}
                                    label="Start Date"
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        {(!isSubmitted ||
                            entitiesMap?.[currentEntity]?.data
                                ?.selectedCustomer) && (
                            <ModalRow>
                                <SelectEntities
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedCustomer ?? {}
                                    }
                                    nodes={Object.values(
                                        entitiesMap[currentEntity]?.data
                                            ?.customers ?? []
                                    )}
                                    className="mlsInput"
                                    id="customerSelect"
                                    label="Select Customer"
                                    showAll={false}
                                    required={true}
                                    helperText="Optional - Nest under existing customer (cannot be changed)"
                                    disabled={isSubmitted}
                                />
                            </ModalRow>
                        )}
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmitValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmit={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                passedCheck={creatingCustomAccounts ? false : passedCheck}
            />
        </EventEntityWrapper>
    );
}

// import { EventNodeBody } from "Components/EventsCanvas/Event/_eventNodeComponents";
import Konva from "konva";
import type {
    ClickableEventNodeBodyCanvasStylesFields,
    BottomLayerVisualEventNodeBodyCanvasStylesFields,
    TopLayerVisualEventNodeBodyCanvasStylesFields,
} from "reducers/typesSchema/eventsTemplatesSchema";
import { useContext, useMemo, useState } from "react";
import { Group } from "react-konva";
import {
    LayerOne,
    LayerTwo,
    LayerThree,
    LayerFour,
    LayerFive,
    LayerSix,
    LayerSeven,
} from "./partials/Layers";
import { useEventTemplate } from "./hooks/useEventTemplate";
import { EventNodeBody } from "./partials/EventNodeBody";
import { EventNodeDetails } from "./partials/EventNodeDetails";
import { EventNodeBaseMenu } from "./partials/EventNodeBaseMenu";
import { PlusButton } from "./partials/PlusButton";
import { StateIndicators } from "./partials/StateIndicators";
import { EventDescriptiveText } from "./partials/EventDescriptiveText";

import { EventNodeIDContext } from "./contexts/EventNodeIDContext";
import { MenuIdContext } from "./partials/EventNodeBaseMenu/contexts/MenuIdContext";

import { useAppDispatch } from "store/useAppSelectorDispatch";
import { EventActionIds, eventActionsMap } from "actions/canvasEventActions";
import { CanvasBaselineContext } from "../../../contexts/CanvasBaselineContext";
import { EventNodeFocusedContext } from "./contexts/EventNodeStateContexts";
import {
    HoveredEventIdContext,
    SetHoveredEventIdContext,
} from "../../../contexts/CanvasHoveredEventContext";
import {
    HoverButtonIdContext,
    SetHoverButtonIdContext,
} from "./contexts/HoverButtonIdContext";
// import { updateEvent } from "actions/eventHelpers";
// import { getEvent } from "actions/getNodeEntityActions";
// import {
//     SetEventDragActiveContext,
//     SetDraggedEventTempXContext,
//     SetDraggedEventTempYContext,
// } from "../../../contexts/CanvasEventDragContext";
import { selectEvent } from "actions/scenario";

interface EventNodeMainProps {
    x: number;
    y: number;
}

/**
 * This component is the visual representation of an event on the canvas. It consists of these parts, from the bottom layer to the highest layer.
 * - {@link LayerOne} - Anything underneath the EventNodeBody (as of this writing, no idea what would go here).
 * - {@link LayerTwo} - The bottom-layer VISUAL EventNodeBody. It cannot be interacted with. It is only there for visual representation. Should only contain this one component.
 * - {@link LayerThree} - Any visual elements within the EventNodeBody. Can have multiple items in here. Potentially create a group of its own or a component of its own.
 * - {@link LayerFour} - The top-layer VISUAL EventNodeBody.
 * - {@link LayerFive} - The CLICKABLE EventNodeBody. Exact same dimensions as its VISUAL component. It cannot be seen, only clickable. Should only contain this one component.
 * - {@link LayerSix} - Any clickable elements within the EventNodeBody (ie. star ratings). Can contain multiple components.
 * - {@link LayerSeven} - Base Menus. This layer always starts at the BOTTOM of the EventNodeBody, then moves down a little more by itself to determine its own center.
 *                        This is because of the requirement that the menus remain the same size regardless of zoom level. This will allow the menus to always be offset a proper amount
 *                        regardless of how much it is scaled up or down.
 * When working with each layer, their [x,y] will always represent the center of the event in its default state.
 * DEV NOTES: Add layers as required, but make sure to document as you go.
 */
export function EventNodeMain({ x, y }: EventNodeMainProps) {
    const isBaseline = useContext(CanvasBaselineContext);
    const eventId = useContext(EventNodeIDContext);
    const hoveredEventId = useContext(HoveredEventIdContext);
    const setHoveredEventId = useContext(SetHoveredEventIdContext);
    const focused = useContext(EventNodeFocusedContext);
    const { eventTemplateObject } = useEventTemplate(eventId);
    const [hoverButtonId, setHoverButtonId] = useState("");

    // const setEventDragActive = useContext(SetEventDragActiveContext);
    // const setDraggedEventTempX = useContext(SetDraggedEventTempXContext);
    // const setDraggedEventTempY = useContext(SetDraggedEventTempYContext);

    // const [canDrag, setCanDrag] = useState(false);

    // useEffect(() => {
    //     if (hoveredEventId === eventId) {
    //         setCanDrag(true);
    //     } else {
    //         setCanDrag(false);
    //     }
    // }, [hoveredEventId, eventId]);

    const [bottomLayerVisualEventNodeBodyCanvasStylesFields] =
        useState<BottomLayerVisualEventNodeBodyCanvasStylesFields>([
            "eventNodeBodyColorsBottomLayer",
            "eventNodeBodyDimensions",
        ]);
    const [topLayerVisualEventNodeBodyCanvasStylesFields] =
        useState<TopLayerVisualEventNodeBodyCanvasStylesFields>([
            "eventNodeBodyColorsTopLayer",
            "eventNodeBodyDimensions",
        ]);
    const [clickableEventNodeBodyCanvasStylesFields] =
        useState<ClickableEventNodeBodyCanvasStylesFields>([
            "eventNodeBodyDimensions",
        ]);

    const dispatch = useAppDispatch();

    /**
     * The default width and height of the EventNodeBody
     */
    const eventNodeBodyDefaultDimensions = useMemo(() => {
        const defaultVisualStyles =
            eventTemplateObject?.canvasStyles?.eventNodeBodyDimensions?.styles
                ?.default;
        const width = defaultVisualStyles?.width ?? 0;
        const height = defaultVisualStyles?.height ?? 0;

        return { width, height };
    }, [
        eventTemplateObject?.canvasStyles?.eventNodeBodyDimensions?.styles
            ?.default,
    ]);

    /**
     * The center of this event's default dimensions. This allows us to work like everything begins at the center of the default dimensions.
     */
    const eventNodeBodyDefaultCenter = useMemo(() => {
        const x = eventNodeBodyDefaultDimensions.width / 2;
        const y = eventNodeBodyDefaultDimensions.height / 2;

        return { x, y };
    }, [eventNodeBodyDefaultDimensions]);

    /**
     * Changes styling of the cursor.
     *
     * Source: https://konvajs.org/docs/styling/Mouse_Cursor.html
     * Should be able to use most values that CSS accept. https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
     */
    const handleOnMouseEnterNodeBody = (
        e: Konva.KonvaEventObject<MouseEvent>,
        _eventId: string
    ) => {
        if (eventId !== hoveredEventId) {
            setHoveredEventId(eventId);
        }

        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = "pointer";
    };

    /**
     * Changes styling of the cursor.
     *
     * Source: https://konvajs.org/docs/styling/Mouse_Cursor.html
     * Should be able to use most values that CSS accept. https://developer.mozilla.org/en-US/docs/Web/CSS/cursor
     */
    const handleOnMouseLeaveNodeBody = (
        e: Konva.KonvaEventObject<MouseEvent>,
        _eventId: string
    ) => {
        setHoveredEventId("");
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = "default"; // TODO: Instead of 'default', determine what the state was before entering.
    };

    const handleClickEventNodeBody = (
        e: Konva.KonvaEventObject<MouseEvent>,
        _eventId: string
    ) => {
        if (e?.evt?.metaKey || e?.evt?.ctrlKey) {
            dispatch(selectEvent(eventId, "canvas"));
        } else {
            dispatch(
                eventActionsMap?.[EventActionIds?.focusEvent](
                    eventId,
                    isBaseline
                )
            );
        }
    };

    const handleDoubleClickEventNodeBody = (
        _e: Konva.KonvaEventObject<MouseEvent>,
        _eventId: string
    ) => {
        if (!focused) {
            dispatch(
                eventActionsMap?.[EventActionIds?.focusEvent](
                    eventId,
                    isBaseline
                )
            );
        }
        dispatch(
            eventActionsMap?.[EventActionIds?.editEvent](eventId, isBaseline)
        );
    };

    // const onDragStart = (_e: Konva.KonvaEventObject<DragEvent>) => {
    //     setEventDragActive(true);
    // };

    // const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
    //     setEventDragActive(false);
    //     setDraggedEventTempX(e.target.attrs.x);
    //     setDraggedEventTempY(e.target.attrs.y);
    //     const eventData: any = getEvent(eventId);
    //     if (!eventData) return;
    //     const exportedEventData = eventData?.exportData();
    //     const newEventData = {
    //         ...exportedEventData,
    //         userSetX: e.target.attrs.x,
    //         userSetY: e.target.attrs.y,
    //     };
    //     updateEvent(newEventData);
    // };

    // const onDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
    //     setDraggedEventTempX(e.target.attrs.x);
    //     setDraggedEventTempY(e.target.attrs.y);
    // };

    return (
        <SetHoverButtonIdContext.Provider value={setHoverButtonId}>
            <HoverButtonIdContext.Provider value={hoverButtonId}>
                <Group
                    x={x}
                    y={y}
                    // draggable={canDrag}
                    // onDragMove={onDragMove}
                    // onDragStart={onDragStart}
                    // onDragEnd={onDragEnd}
                >
                    <Group
                        x={-eventNodeBodyDefaultCenter.x}
                        y={-eventNodeBodyDefaultCenter.y}
                        // onContextMenu={(e) => console.log("e", e)}
                    >
                        <LayerOne
                            listening={false}
                            {...eventNodeBodyDefaultCenter}
                        ></LayerOne>
                        <LayerTwo
                            listening={false}
                            {...eventNodeBodyDefaultCenter}
                        >
                            <EventNodeBody
                                // Bottom-Layer Visual EventNodeBody. Should be visible, unclickable. Dimensions must be same as Clickable EventNodeBody EventNodeBody.
                                canvasStylesFields={
                                    bottomLayerVisualEventNodeBodyCanvasStylesFields
                                }
                            />
                        </LayerTwo>
                        <LayerThree
                            listening={false}
                            {...eventNodeBodyDefaultCenter}
                        >
                            <EventNodeDetails />
                        </LayerThree>
                        <LayerFour
                            listening={false}
                            {...eventNodeBodyDefaultCenter}
                        >
                            <EventNodeBody
                                // Top-Layer Visual EventNodeBody. Should be visible, unclickable. Dimensions must be same as Clickable EventNodeBody EventNodeBody.
                                canvasStylesFields={
                                    topLayerVisualEventNodeBodyCanvasStylesFields
                                }
                            />
                        </LayerFour>
                        <LayerFive {...eventNodeBodyDefaultCenter}>
                            <EventNodeBody
                                // Clickable EventNodeBody. Should be completely invisible, clickable. Dimensions must be same as Visual EventNodeBody.
                                canvasStylesFields={
                                    clickableEventNodeBodyCanvasStylesFields
                                }
                                onClick={handleClickEventNodeBody}
                                onDblClick={handleDoubleClickEventNodeBody}
                                onMouseEnter={handleOnMouseEnterNodeBody}
                                onMouseLeave={handleOnMouseLeaveNodeBody}
                            />
                        </LayerFive>
                        <LayerSix {...eventNodeBodyDefaultCenter}></LayerSix>
                        <LayerSeven
                            {...eventNodeBodyDefaultCenter}
                            y={eventNodeBodyDefaultDimensions.height}
                        >
                            <PlusButton />
                            <StateIndicators />
                            <MenuIdContext.Provider value="base-menu-template-id-abc123">
                                <EventNodeBaseMenu />
                            </MenuIdContext.Provider>
                            <EventDescriptiveText />
                        </LayerSeven>
                    </Group>
                </Group>
            </HoverButtonIdContext.Provider>
        </SetHoverButtonIdContext.Provider>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { constraintObject } from "Components/Registry/Constraint";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    EntityName,
    RequiredStar,
    BasicHeading,
    ModalRowHalf,
    SelectDropDown,
    SelectEntities,
    SingleEntity,
    Rate,
    MuiCalendar,
    EntitySummaryHighlightBlank,
} from "../Components";
import Switch from "react-switch";

export default function ConstraintInputView({
    entitiesMap,
    currentEntity,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    toggleCustomEffectPeriod,
    handleDateSelection,
    getEntitySummary,
}) {
    const entityData = entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={constraintObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={constraintObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ModalRow>
                    <BasicHeading
                        copy="Target Record or Event"
                        margin="15px 0"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entityData?.selectedEventType ?? ""}
                            options={
                                Object.keys(entityData?.eventsMappedByType) ??
                                []
                            }
                            className="select-dropdown"
                            id="eventType"
                            label="Target Event Type"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            disabled={entityData?.selectedEventType === ""}
                            onChangeInput={handleOnChange}
                            value={entityData?.selectedEventId ?? ""}
                            options={entityData?.availableEvents ?? []}
                            className="select-dropdown"
                            id="event"
                            label="Target Event"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            disabled={entityData?.selectedEventId === ""}
                            onChangeInput={handleOnChange}
                            showAll={true}
                            value={entityData?.selectedEvent ?? {}}
                            nodes={[entityData?.selectedEventId]}
                            className="IncomeSelect"
                            id="entity"
                            label="Target Record"
                            required={true}
                            hideEventName={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            disabled={
                                entityData?.selectedEvent?.eventId !==
                                entityData?.selectedEventId
                            }
                            onChangeInput={handleOnChange}
                            value={entityData?.property ?? ""}
                            options={entityData?.properties ?? []}
                            className="select-dropdown"
                            id="property"
                            label="Target Property"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <BasicHeading copy="Constraint" margin="15px 0" />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Rate
                            label="Min Value"
                            onChangeInput={handleOnChange}
                            value={entityData.minValue ?? ""}
                            id="minValue"
                            className="mlsInput"
                            sign={false}
                            required={false}
                            helperText={"Not a required field"}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <Rate
                            label="Max Value"
                            onChangeInput={handleOnChange}
                            value={entityData.maxValue ?? ""}
                            id="maxValue"
                            className="mlsInput"
                            sign={false}
                            required={false}
                            helperText={"Not a required field"}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <ModalRowHalf>
                        <Switch
                            checked={entityData?.customEffectPeriod}
                            className="InflationToggle"
                            onChange={toggleCustomEffectPeriod}
                            height={20}
                            width={40}
                        />
                        <div className="InflationText">
                            Custom effect period
                        </div>
                    </ModalRowHalf>
                </ModalRow>
                {entityData?.customEffectPeriod && (
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <MuiCalendar
                                views={["year", "month"]}
                                inputFormat="YYYY-MM"
                                id="startDate"
                                value={
                                    entitiesMap?.[currentEntity]?.startDate ===
                                    ""
                                        ? null
                                        : entitiesMap?.[currentEntity]
                                              ?.startDate
                                }
                                required={entityData?.customEffectPeriod}
                                onChangeInput={handleDateSelection}
                                label="Start Date"
                            />
                        </ModalRowHalf>
                        <ModalRowHalf>
                            <MuiCalendar
                                views={["year", "month"]}
                                inputFormat="YYYY-MM"
                                id="endDate"
                                value={
                                    entitiesMap?.[currentEntity]?.endDate === ""
                                        ? null
                                        : entitiesMap?.[currentEntity]?.endDate
                                }
                                onChangeInput={handleDateSelection}
                                label="End Date"
                                required={false}
                                helperText={"An end date is optional"}
                            />
                        </ModalRowHalf>
                    </ModalRow>
                )}
                {passedCheck && (
                    <ModalRow>
                        <EntitySummaryHighlightBlank
                            copy={getEntitySummary(
                                entitiesMap?.[currentEntity]
                            )}
                        />
                    </ModalRow>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

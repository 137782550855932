import {
    RESET_CONTAINER_CREATION,
    UPSERT_CONTAINER_CREATION,
} from "../actions/containerCreationActions";
import { ContainerCreationSchema } from "./typesSchema/containerCreationReducersSchema";

const initialState: ContainerCreationSchema = {
    isCreatingContainer: false,
    prospectiveHeadNode: null,
    prospectiveTailNode: null,
};

export default (state: ContainerCreationSchema = initialState, action) => {
    switch (action.type) {
        case RESET_CONTAINER_CREATION:
            return { ...initialState };
        case UPSERT_CONTAINER_CREATION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

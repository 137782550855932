import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { cloneDeep } from "lodash";
import type {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import modifierEntityMap from "helpers/modifierEntityMap.json";
import moment from "moment";

export const growthInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "eventType"
        | "event"
        | "entity"
        | "property"
        | "cadence"
        | "growthType"
        | "maxGrowthRate"
        | "constantGrowthRate"
        | "interpolate"
        | "rateStartDate"
        | "startDate"
        | "endDate"
        | "growthRate"
        | "period",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema,
    index?: number
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            let startDateString = "";
            if (value) {
                const startMoment = moment(value);
                startDateString = startMoment
                    .set("date", 1)
                    .format("YYYY-MM-DD");
            }
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            let endDateString = "";
            if (value) {
                const endMoment = moment(value);
                endDateString = endMoment.set("date", 1).format("YYYY-MM-DD");
            }
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const parsedEvent = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedEvent,
                eventId,
                currentEntityObject.id,
                data.selectedEventId,
                data.selectedIds
            );

            data.selectedEvent = parsedEvent;
            data.selectedIds = parsedEvent?.entityIds ?? [];

            const properties: string[] = [];
            if (parsedEvent?.eventId && parsedEvent?.entityIds?.length === 0) {
                const event = getEvent(parsedEvent?.eventId);
                if (event) {
                    const entities = event.entities.map((entity) => entity.id);
                    const entity =
                        getRelevantEntities(entities)?.[entities[0]] ?? {};

                    data.entityType = entity?.type ?? "";
                    getProperties(properties, entity, data.entityType);
                }
            } else {
                const entity =
                    getRelevantEntities(parsedEvent.entityIds)?.[
                        parsedEvent.entityIds[0]
                    ] ?? {};

                data.entityType = entity?.type ?? "";
                getProperties(properties, entity, data.entityType);
            }
            data.properties = properties;

            // If entity is changed we reset property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "eventType":
            data.selectedEventType = value ?? "";
            const availableEvents: {}[] = [];
            data.eventsMappedByType[value]?.forEach((eventId) => {
                const event = getEvent(eventId);
                if (event) {
                    availableEvents.push({
                        value: event.id,
                        displayName: event.name,
                    });
                }
            });

            // If eventType is changed we reset event, entity, property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            data.availableEvents = availableEvents;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "event":
            const selectedEventId = value ?? "";
            data.selectedEventId = selectedEventId;
            const event = getEvent(selectedEventId);
            if (event) {
                data.selectedEventName = event?.name;
            } else {
                data.selectedEventName = "";
            }

            // If event is changed we reset entity, property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "property":
            data.property = value;
            const valueKey = value?.split(" - ")?.[0];

            const decisionEngineName =
                modifierEntityMap?.[data.entityType]?.[valueKey]?.["deName"] ??
                "";
            decisionEngineName
                ? (data.deName = decisionEngineName)
                : (data.deName = "");

            // If property is changed we reset function and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "growthType":
            data.growthType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "constantGrowthRate":
            data.constantGrowthRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "maxGrowthRate":
            data.maxGrowthRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interpolate":
            data.interpolate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "growthRate":
            if (!index && index !== 0) break;

            const growthSegments = cloneDeep(data.growthSegments);
            const segment = growthSegments[index];

            segment.rateDisplay = value;

            if (segment.period == "monthly")
                segment.monthlyRate = Number(value) / 100;
            if (segment.period == "yearly")
                segment.monthlyRate = yearlyToMonthlyRate(Number(value));

            growthSegments[index] = segment;
            data.growthSegments = growthSegments;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rateStartDate":
        case "period":
            if (!index && index !== 0) break;

            const growthSegs = cloneDeep(data.growthSegments);
            const seg = growthSegs[index];

            if (id === "period") {
                seg[id] = value;
                if (value === "monthly")
                    seg.monthlyRate = Number(seg.rateDisplay) / 100;
                if (value === "yearly")
                    seg.monthlyRate = yearlyToMonthlyRate(
                        Number(seg.rateDisplay)
                    );
            } else {
                const startDateString = value
                    ? moment(value).format("YYYY-MM")
                    : "";
                seg["startDate"] = startDateString;
            }

            growthSegs[index] = seg;
            data.growthSegments = growthSegs;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

const resetFields = (changedField: string, data: { [x: string]: any }) => {
    if (
        changedField === "entity" ||
        changedField === "event" ||
        changedField === "eventType"
    ) {
        data.property = "";
        data.deName = "";
    }

    if (changedField === "event" || changedField === "eventType") {
        data.selectedEvent = {};
        data.selectedIds = [];
        data.entityType = "";
    }

    if (changedField === "eventType") {
        data.selectedEventId = "";
        data.selectedEventName = "";
    }
};

const getProperties = (
    properties: string[],
    targetEntity: EntitySchema,
    entityType: string
) => {
    for (const property in modifierEntityMap?.[entityType]) {
        const propertyObject =
            modifierEntityMap?.[entityType]?.[property] ?? {};

        if (propertyObject?.type !== "numerical") continue;

        const propertyType = propertyObject?.feName;

        const numberValue = Number(targetEntity?.data?.[propertyType]) ?? 0;
        properties?.push(
            `${property} - ${new Intl.NumberFormat(undefined, {
                currency: "USD",
                style: "currency",
            }).format(numberValue)}`
        );
    }
};

export const yearlyToMonthlyRate = (rate: number) => {
    return Math.pow(1 + rate / 100, 1 / 12) - 1;
};

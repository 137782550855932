import type { AllAccountLedgersSchema } from "reducers/typesSchema/allAccountLedgersSchema";
import { UPSERT_ALL_ACCOUNT_LEDGERS } from "actions/allAccountLedgersActions";

const initialState: AllAccountLedgersSchema = {
    ledgersMetadata: {},
    relevantLedgers: [],
};

export default function allAccountLedgersReducer(
    state = initialState,
    action: { type: string; payload: Partial<AllAccountLedgersSchema> }
) {
    switch (action.type) {
        case UPSERT_ALL_ACCOUNT_LEDGERS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import LoanCard from "../Card/loanCard";
import LoanInput from "../InputContainer/LoanInput";

// Import Connection Image (if exists)
import LoanConnectionImage from "Assets/_optionDescriptionIcons/Loan white.jpg";

// Import Coloured PNGs
import loanBlack from "../../Assets/_eventNodeTypes/loan-black-updated.svg";
import loanBlue from "../../Assets/_eventNodeTypes/loan-blue.png";
import loanWhite from "../../Assets/_eventNodeTypes/loan-white.png";
import loanGrey from "../../Assets/_eventNodeTypes/loan-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - loanObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - loanObject.checkInput.call(loanObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const loanObject = {
    constant: function () {
        return Loan_Constant();
    },

    name: function () {
        return "Loan";
    },

    checkInput: function (state) {
        return Loan_CheckInput(state);
    },

    svg: function () {
        return Loan_Svg();
    },

    eventNodeMenu: function (focus) {
        return Loan_EventNodeMenu(focus);
    },

    indexText: function () {
        return Loan_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Loan_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Loan_OptionDescription();
    },

    colouredPngs: function () {
        return Loan_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Loan_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Loan_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Loan_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.loan) return false;
        if (!entity.startDate) return false;

        if (entity.data.loanType === "standard") {
            if (!entity.data.interestRate) return false;
            if (!entity.data.termUnit) return false;
            if (!entity.data.numTermUnits || entity.data.numTermUnits <= 0)
                return false;
            if (!entity.cadence) return false;
        }

        if (entity.data.loanType === "revenueBased") {
            if (entity.data.interestVsPremium === "premium") {
                if (!entity.data.loanPremium) return false; // maybe remove so that zero can be a default
                if (!entity.data.dependantAccountId) return false;
                if (!entity.data.revenuePercentage) return false;
                if (!entity.data.paymentStart) return false;
            } else if (entity.data.interestVsPremium === "interest") {
                if (!entity.data.interestRate) return false;
                if (!entity.data.dependantAccountId) return false;
                if (!entity.data.revenuePercentage) return false;
                if (!entity.data.paymentStart) return false;
            }
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Loan_Constant = () => {
    return "d0af45b1-99f1-4596-8878-0a135bb6bd2e";
};

// EFFECTS: Returns black png / svg for event
const Loan_Svg = () => {
    return loanBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Loan_EventNodeMenu = (focus) => {
    return <LoanCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Loan_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Loan calculations.";
};

// EFFECTS: Returns all icons for event ??
const Loan_Icons = () => {
    let pngs = new Map([
        ["black", loanBlack],
        ["blue", loanBlue],
        ["white", loanWhite],
        ["grey", loanGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Loan_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Loan_InputComponentInfoPassing = (minProps) => {
    return <LoanInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, loans for event ??
const Loan_OptionDescription = () => {
    const tags = ["Option"];
    const type = loanObject.name();
    const description =
        "A loan is a borrowed sum of money that is to be paid back on a certain interval with a fixed or variable rate of interest. This Loan Event currently supports fixed rate interest only. Use the Loan Event to both principal and interest payments over time.";
    const usedForText =
        "A Loan Event is used to generate one-time cash flow and the ongoing payments required to pay off the initial loan amount. Use the Employee Event to model current staffing, plan for future hires, or build placeholder Scenarios with entire segments of Employee types and roles.";
    const connection =
        "The Loan Event calculates without any additional Events required, but you can use a Repayment Event to model accelerated or lump sum payments.";
    const connectionImage = LoanConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/l/loan.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Loan_SideModalImage = () => {
    return sideImage;
};

const Loan_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: false,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            editable: false,
            // cellRenderer: "dateSelectorCellRenderer",
        },
        cadence: {
            displayName: "Payment Frequency",
            editable: false,
            // cellRenderer: "dropDownCellRenderer",
            // cellRendererDropDownOptions: [
            //     "annually",
            //     "quarterly",
            //     "monthly",
            //     "semi-monthly",
            //     "bi-weekly",
            //     "weekly",
            //     "daily",
            //     "one-time",
            // ],
        },
        bypassState: {
            displayName: "Bypassed",
            editable: false,
            // cellRenderer: "checkBoxCellRenderer",
        },
    };

    const entityDataFieldsMap = {
        loanType: {
            displayName: "Loan Type",
            editable: false,
            // conditionalTarget: true,
            // cellRenderer: "dropDownCellRenderer",
            // cellRendererDropDownOptions: ["standard", "revenueBased"],
        },
        value: {
            displayName: "Approximate Payment",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
        },
        loan: {
            displayName: "Loan Amount",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
        },
        interestRate: {
            displayName: "Interest Rate",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "%",
                signLocation: "after",
                incudeSignSpace: false,
            },
            editable: false,
        },
        termUnit: {
            displayName: "Term Unit",
            editable: false,
        },
        numTermUnits: {
            displayName: "Term Length",
            editable: false,
        },
    };

    const displayOnly = true;

    return { entityFieldsMap, entityDataFieldsMap, displayOnly };
};

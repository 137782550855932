import React, { Component } from "react";
import "./Card.css";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import moment from "moment";

class MaternityCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        const { cadence, description, url, start, end, rating, version } =
            this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="DollarSvg">$</div>
                    <div className="Frequency">
                        {cadence ? `${cadence} /` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">URL:</div>
                    <a
                        className="ExpenseLink"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {url}
                    </a>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Date</div>
                    <div className="cardValue">
                        {start
                            ? moment(start).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {end ? moment(end).format("MMM D YYYY") : "No End Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel"> Rating</div>
                    <div className="StarsContainer">
                        {stars.map((star, i) => {
                            if (i < rating) {
                                return (
                                    <img
                                        alt="alt"
                                        key={i}
                                        className="starFilledSvg"
                                        src={starFilledSvg}
                                        id="rating"
                                    />
                                );
                            } else {
                                return (
                                    <img
                                        alt="alt"
                                        className="starUnfilledSvg"
                                        src={starUnfilledSvg}
                                        key={i}
                                        id="rating"
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default MaternityCard;

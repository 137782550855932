import { createContext } from "react";

export const HoveredEventIdContext = createContext("");
HoveredEventIdContext.displayName = "HoveredEventIdContext";

export const SetHoveredEventIdContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetHoveredEventIdContext.displayName = "SetHoveredEventIdContext";

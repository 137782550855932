import type { AppThunk } from "store";

export const UPSERT_CARD_TEMPLATES = "UPSERT_CARD_TEMPLATES";

export function upsertCardTemplates(upsertCardTemplates: any): AppThunk<void> {
    return (dispatch, getState) => {
        const curCardTemplates = getState().cardTemplates;

        const newCardTemplates: any = {};
        Object.entries(upsertCardTemplates).forEach(
            ([cardTemplateTypeId, cardTemplate]) => {
                newCardTemplates[cardTemplateTypeId] = {
                    ...curCardTemplates[cardTemplateTypeId],
                    //@ts-ignore
                    ...cardTemplate,
                };
            }
        );

        dispatch({
            type: UPSERT_CARD_TEMPLATES,
            payload: newCardTemplates,
        });
    };
}

import React, { Component } from "react";
import styles from "./ScenarioContainer.module.css";
import searchSvg from "../../Assets/scenarioIcons/find.svg";
import sampleSvg from "../../Assets/scenarioIcons/sampleScenario.svg";
import sharedSvg from "../../Assets/scenarioIcons/sharedScenario.svg";
import myScenario from "../../Assets/scenarioIcons/myScenario.svg";
import expandSvg from "../../Assets/scenarioIcons/expand.svg";
import collapseSvg from "../../Assets/scenarioIcons/collapse.svg";
import { AGENCY } from "../../helpers/constants";
import { sortBy } from "lodash";

export default class ScenarioFind extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAgency: props.userRole === AGENCY,
            scenarioList: [],
            queryValue: "",
            showClientCategories: false,
            showSharedClientCategories: false,
            hasLoadedScenario: false,
            useCategorySearch: false,
            numScenarios: 0,
            numSharedScenarios: 0,
        };
    }

    listEntryWasOpen = (previousList, title) => {
        if (!previousList) return false;
        const prevEntry = previousList.find((entry) => {
            return entry.title === title;
        });
        return prevEntry && prevEntry.open;
    };

    createScenarioList = (isAgency, previousList = null) => {
        const { clientScenarios, clientSharedScenarios, archivedScenarios } =
            this.props;
        let myScenarioString = isAgency ? "Client Scenarios" : "My Scenarios";
        let scenarioList = [
            {
                icon: sampleSvg,
                title: "Sample Scenarios",
                open: this.listEntryWasOpen(previousList, "Sample Scenario"),
                id: 0,
                type: "sample",
            },
            {
                icon: sampleSvg,
                title: "Tutorial Scenarios",
                open: this.listEntryWasOpen(previousList, "Tutorial Scenario"),
                id: 1,
                type: "tutorial",
            },
            {
                icon: myScenario,
                title: myScenarioString,
                open: this.listEntryWasOpen(previousList, myScenarioString),
                id: 2,
                type: "scenario",
            },
        ];
        let i = 3;
        const clientKeys = sortBy(Object.keys(clientScenarios));
        clientKeys.forEach((clientName) => {
            const client = this.props.agencyClients.find(
                (candidate) => candidate.clientdata.name === clientName
            );
            const open =
                client &&
                this.props.loadedScenario &&
                this.props.loadedScenario.data.clientId === client.id;
            scenarioList.push({
                icon: sharedSvg,
                title: clientName,
                open: open || this.listEntryWasOpen(previousList, clientName),
                id: client.id,
                type: "scenario",
                subType: "client",
            });
            i++;
        });
        scenarioList.push({
            icon: sharedSvg,
            title: "Shared With Me",
            open: this.listEntryWasOpen(previousList, "Shared With Me"),
            id: i,
            type: "shared",
        });
        i++;

        const sharedClientKeys = sortBy(Object.keys(clientSharedScenarios));
        sharedClientKeys.forEach((clientName) => {
            scenarioList.push({
                icon: sharedSvg,
                title: clientName,
                open: this.listEntryWasOpen(previousList, clientName),
                id: i,
                type: "shared",
                subType: "client-shared",
            });
            i++;
        });
        if (archivedScenarios.length > 0) {
            scenarioList.push({
                icon: myScenario,
                title: "Archived Scenarios",
                open: this.listEntryWasOpen(previousList, "Archived Scenarios"),
                id: i,
                type: "archived",
            });
            i++;
        }
        return scenarioList;
    };

    componentDidMount() {
        const loadedScenario = this.props.loadedScenario;
        if (loadedScenario && loadedScenario.type) {
            const hasLoadedScenario =
                loadedScenario.type === "baseline" ? false : true;
            this.setState({ scenarioList: [], hasLoadedScenario });
        }
        this.setState({ numSharedScenarios: this.props.sharedScenario.length });
    }

    determineOpenCategories = (
        scenarioList,
        loadedScenario,
        clientScenarios,
        showClientCategories,
        showSharedClientCategories
    ) => {
        const newScenarioList = scenarioList.map((list) => {
            if (loadedScenario && list && list.type === loadedScenario.type) {
                let isOpen = true;
                if (list.subType === "client") {
                    if (
                        clientScenarios[list.title].find((scenario) => {
                            return scenario.props.data.id === loadedScenario.id;
                        })
                    ) {
                        isOpen = true;
                    } else {
                        isOpen = false;
                    }
                }

                if (list.subType === "client" || list.type === "scenario") {
                    showClientCategories = showClientCategories || isOpen;
                }
                if (
                    list.subType === "client-shared" ||
                    list.type === "shared"
                ) {
                    showSharedClientCategories =
                        showSharedClientCategories || isOpen;
                }

                const newList = {
                    ...list,
                    open: isOpen,
                };

                return newList;
            }
            return list;
        });
        return {
            newScenarioList,
            showClientCategories,
            showSharedClientCategories,
        };
    };

    componentDidUpdate(prevProps, _prevState) {
        const {
            loadedScenario,
            clientScenarios,
            clientSharedScenarios,
            clientListShouldUpdate,
            scenario,
            shared,
        } = this.props;
        const { hasLoadedScenario, scenarioList, isAgency } = this.state;
        let previousShouldShowClients = this.state.showClientCategories;

        // this if statement describes
        // a. Going from Baseline to Canvas,
        // b. Going from Home to Canvas,
        // c. List has updated
        if (
            (loadedScenario &&
                loadedScenario.type !== "baseline" &&
                !hasLoadedScenario) ||
            (hasLoadedScenario && scenarioList.length === 0) ||
            prevProps.scenario.length !== scenario.length ||
            clientListShouldUpdate
        ) {
            // calculate length fields for 'Client Scenarios' and 'Shared Scenarios' when user is Agency
            let numScenarios = scenario.length;

            Object.values(clientScenarios).forEach(
                (list) => (numScenarios += list.length)
            );
            let numSharedScenarios = shared.length;
            Object.values(clientSharedScenarios).forEach(
                (list) => (numSharedScenarios += list.length)
            );

            // build scenarios list and determine which folders are open
            const scenarioList = this.createScenarioList(isAgency);
            const {
                newScenarioList,
                showClientCategories,
                showSharedClientCategories,
            } = this.determineOpenCategories(
                scenarioList,
                loadedScenario,
                clientScenarios,
                previousShouldShowClients
            );

            this.setState({
                scenarioList: newScenarioList,
                hasLoadedScenario: true,
                showClientCategories,
                showSharedClientCategories,
                numScenarios,
                numSharedScenarios,
            });
            this.props.toggleClientListShouldUpdate(false);
        }
    }

    onHandleShowList = (list) => {
        const { id, type, subType } = list;
        const { scenarioList } = this.state;
        const newList = scenarioList.map((list) => {
            if (list.id === id) {
                const newScenario = {
                    ...list,
                    open: !list.open,
                };
                if (type === "scenario" && subType !== "client")
                    this.setState({ showClientCategories: newScenario.open });
                if (type === "shared" && subType !== "client-shared") {
                    this.setState({
                        showSharedClientCategories: newScenario.open,
                    });
                }
                return newScenario;
            }
            return list;
        });
        this.setState({ scenarioList: newList });
    };

    handleQuerySearch = (e) => {
        this.setState({ queryValue: e.target.value });
    };

    getResultsFromQuery = (projects) => {
        const { queryValue } = this.state;
        if (queryValue) {
            return projects.filter(
                (project) =>
                    project.props.data &&
                    project.props.data.name &&
                    project.props.data.name
                        .toUpperCase()
                        .includes(queryValue.toUpperCase())
            );
        }
        return projects;
    };

    categorySearch = (categoryName) => {
        if (!categoryName) return false;
        const { queryValue } = this.state;
        return categoryName.toUpperCase().includes(queryValue.toUpperCase());
    };

    getListEntry = (id, list, data) => {
        let numEntries = data.length;
        const isShared = list.subType === "client-shared";
        const isClient = list.subType === "client" || isShared;
        if (
            list.type === "scenario" &&
            !list.subType &&
            this.props.userScenarios.length === 0
        )
            numEntries = 0;
        else if (list.type === "scenario" && !list.subType)
            numEntries = this.state.numScenarios;
        else if (list.type === "shared" && !list.subType)
            numEntries = this.props.sharedScenario.length;

        return (
            <div className={styles.scenarioListsContainer} key={id}>
                <div
                    onClick={() => {
                        this.onHandleShowList(list);
                    }}
                    key={id}
                    className={
                        isClient ? styles.listSubCategory : styles.listContainer
                    }
                >
                    <img
                        alt="alt"
                        className={styles.scenarioIcon}
                        src={list.icon}
                    />
                    <div className={styles.title}>
                        {`${list.title}${isShared ? " (shared) " : " "}`}(
                        {numEntries})
                    </div>
                    <img
                        alt="alt"
                        className={styles.scenarioIcon}
                        src={list.open ? collapseSvg : expandSvg}
                    />
                </div>

                <div
                    className={
                        list.open ? styles.sampleList : styles.sampleListClosed
                    }
                >
                    {data}
                </div>
            </div>
        );
    };

    render() {
        const {
            sample,
            tutorial,
            shared,
            scenario,
            clientSharedScenarios,
            clientScenarios,
            archivedScenarios,
        } = this.props;
        const {
            scenarioList,
            queryValue,
            useCategorySearch,
            showClientCategories,
            showSharedClientCategories,
        } = this.state;
        const filteredSample = useCategorySearch
            ? sample
            : this.getResultsFromQuery(sample);
        const filteredTutorial = useCategorySearch
            ? sample
            : this.getResultsFromQuery(tutorial);
        const filteredScenario = useCategorySearch
            ? scenario
            : this.getResultsFromQuery(scenario);
        const filteredShared = useCategorySearch
            ? shared
            : this.getResultsFromQuery(shared);

        return (
            <div className={styles.ScenarioFindWrapper}>
                <div className={styles.findSearch}>
                    <img
                        alt="alt"
                        src={searchSvg}
                        className={styles.searchSvg}
                    />
                    <input
                        type="text"
                        className={styles.SearchInput}
                        placeholder={
                            this.state.useCategorySearch
                                ? "Quick Find Categories"
                                : "Quick Find Scenarios"
                        }
                        value={queryValue}
                        onChange={this.handleQuerySearch}
                    />
                    <button
                        className={styles.swapSearchFunction}
                        onClick={() => {
                            this.setState({
                                useCategorySearch:
                                    !this.state.useCategorySearch,
                            });
                        }}
                    >
                        S
                    </button>
                </div>
                {scenarioList.map((list, i) => {
                    let data;
                    switch (list.type) {
                        case "sample":
                            data = filteredSample;
                            break;
                        case "tutorial": 
                            data = filteredTutorial;
                            break
                        case "scenario":
                            if (list.subType === "client") {
                                data = useCategorySearch
                                    ? clientScenarios[list.title]
                                    : this.getResultsFromQuery(
                                          clientScenarios[list.title]
                                      );
                            } else {
                                data = filteredScenario;
                            }
                            break;
                        case "shared":
                            if (list.subType === "client-shared") {
                                data = useCategorySearch
                                    ? clientSharedScenarios[list.title]
                                    : this.getResultsFromQuery(
                                          clientSharedScenarios[list.title]
                                      );
                            } else {
                                data = filteredShared;
                            }
                            break;
                        case "archived":
                            data = archivedScenarios;
                            break;
                        default:
                    }
                    if (useCategorySearch && !this.categorySearch(list.title))
                        return <div />;
                    if (!useCategorySearch && queryValue && data.length === 0)
                        return <div />;
                    if (
                        list.subType === "client" &&
                        !showClientCategories &&
                        !queryValue
                    )
                        return <div />;
                    if (
                        list.subType === "client-shared" &&
                        !showSharedClientCategories &&
                        !queryValue
                    ) {
                        return <div />;
                    }

                    const filteredData = !data
                        ? []
                        : data.filter((data) => {
                              return data.key !== "PromptUserScenarios";
                          });

                    return this.getListEntry(i, list, filteredData);
                })}
            </div>
        );
    }
}

import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment"

const campaignInputsHandler = (
    value: string,
    id:
        | "websiteVisitor"
        | "entityName"
        | "startDate"
        | "endDate"
        | "value"
        | "cadence"
        | "costPerVisit"
        | "accountName",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "websiteVisitor":
            const parsedWebsiteVis = JSON.parse(value || "{\"eventId\": \"\", \"entityIds\": []}")

            handleDependencies(dependencyMap, parsedWebsiteVis, eventId, currentEntityObject.id, data.websiteVisEventId, data.websiteVisIds)

            data.selectedWebsiteVis = parsedWebsiteVis
            data.websiteVisEventId = parsedWebsiteVis?.eventId ?? ""
            data.websiteVisIds = parsedWebsiteVis?.entityIds ?? []
            
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            if (!isNaN(Number(value))) {
                data.value = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate": 
            const startDateString = value ? moment(value).format("YYYY-MM-DD") : ""
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate": 
            const endDateString = value ? moment(value).format("YYYY-MM-DD") : ""
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "costPerVisit":
            if (!isNaN(Number(value))) {
                data.costPerVisit = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name;
            data.accountIds = account.ids;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

export default campaignInputsHandler;

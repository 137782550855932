import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const expressionInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "accountName"
        | "contraAccountName"
        | "selectEvent"
        | "selectEntity"
        | "expression"
        | "cadence"
        | "value",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    evaluatedExpression?: number,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name;
            data.accountIds = account.ids;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.contraAccountName = account.name;
            data.contraAccountIds = account.ids;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "selectEvent":
            data.selectedEvent = value;
            if (data.selectedEntity) data.selectedEntity = "";
            data.expression = "";
            data.value = 0;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "selectEntity":
            data.selectedEntity = value;
            data.expression = "";
            data.value = 0;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "expression":
            data.expression = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            if (evaluatedExpression != null) {
                data.value = evaluatedExpression;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        default:
    }
    return newEntitiesMap;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { getEvent } from "actions/getNodeEntityActions";
import { groupObject } from "Components/Registry/Group";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    EntityName,
    SelectEntities,
    ModalRowHalf,
    ToggledContainer,
    ToggledContainerHalf,
    Rate,
    ModalRowComponent,
    RadioButton,
    PlusButton,
} from "../Components";
import { RateSegment } from "../GrowthInputExperimental/GrowthInputView";
import ReactSwitch from "react-switch";
import SingleCalendar from "../../Calendar/singleCalendar";
import { CAC2Object } from "Components/Registry/CAC2";

export default function CAC2InputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    onChangeValue,
    addNewSegment,
    deleteSegment,
    onHandleDate,
}) {
    const isGroup = (id) => {
        const node = id ? getEvent(id) : null;
        return node ? node.type === groupObject.constant() : null;
    };

    const entityData = entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={CAC2Object.name()}
                    onChangeInput={onChangeNameDescription}
                    image={CAC2Object.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[CAC2Object.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>

                <ToggledContainer>
                    <ToggledContainerHalf
                        id="constant"
                        isSelected={entityData.costType == "constant"}
                        onClick={(_, id) => onChangeValue(id, "costType")}
                        title="Constant Cost"
                    />
                    <ToggledContainerHalf
                        id="dynamic"
                        isSelected={entityData.costType == "dynamic"}
                        onClick={(_, id) => onChangeValue(id, "costType")}
                        title="Dynamic Cost"
                    />
                </ToggledContainer>

                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            value={
                                entitiesMap[currentEntity].dependencies
                                    ?.entity ?? ""
                            }
                            nodes={Object.values(entityData.customers || [])}
                            showAll={true}
                            className="IncomeSelect"
                            id="entity"
                            label="Select Record"
                            groupEventType={
                                isGroup(entityData.selectedEventId) && "All"
                            }
                        />
                    </ModalRowHalf>
                </ModalRow>
                {!entityData.inheritStartDate && (
                    <ModalRow>
                        <RequiredStar />
                        <SingleCalendar
                            onHandleDate={onHandleDate}
                            date={entitiesMap[currentEntity].startDate}
                            title="Custom Date"
                            entityIndex={entityIndex}
                        />
                    </ModalRow>
                )}
                <div style={{ height: "2px" }}></div>
                <ModalRow>
                    <ReactSwitch
                        id="inheritStartDate"
                        checked={entityData.inheritStartDate}
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={() =>
                            onChangeValue(
                                !entityData.inheritStartDate,
                                "inheritStartDate"
                            )
                        }
                    />
                    <div className="ToggleText">
                        Inherit Start Date from Customer Event
                    </div>
                </ModalRow>

                <div style={{ height: "5px" }}></div>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Rate
                            label="Estimated CAC"
                            onChangeInput={handleOnChange}
                            value={entityData.constantCost}
                            id="constantCost"
                            className="mlsInput"
                            sign={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                {entityData.costType == "dynamic" && (
                    <>
                        <ModalRow>
                            <div className="entity-section-label">
                                Date/Cost Inputs
                            </div>
                        </ModalRow>
                        <ModalRow>
                            <ModalRowComponent>
                                <RadioButton
                                    onClick={onChangeValue}
                                    isActive={entityData.interpolate}
                                    label="Interpolate Cost"
                                    value={true}
                                    id="interpolate"
                                />
                            </ModalRowComponent>
                            <ModalRowComponent>
                                <RadioButton
                                    onClick={onChangeValue}
                                    isActive={!entityData.interpolate}
                                    label="Stair Step Cost"
                                    value={false}
                                    id="interpolate"
                                />
                            </ModalRowComponent>
                        </ModalRow>
                        {entityData.costSegments.map((segment, i) => {
                            return (
                                <RateSegment
                                    key={i}
                                    startDate={segment.startDate}
                                    rate={segment.rateDisplay}
                                    period={segment.period}
                                    index={i}
                                    handleOnChange={handleOnChange}
                                    onChangeValue={onChangeValue}
                                    deleteSegment={deleteSegment}
                                    entityIndex={entityIndex}
                                    type="cost"
                                />
                            );
                        })}
                        <ModalRow>
                            <PlusButton
                                onClick={addNewSegment}
                                label="Add New Cost Segment"
                            />
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import React, { Component } from "react";
// import { EventsModal } from "../EventsModal";
import "./InputContainer.css";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import Switch from "react-switch";
// import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
// import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import SingleCalendar from "../Calendar/singleCalendar";
import swal from "sweetalert";
import mortgageOptionSvg from "../../Assets/_eventNodeTypes/mortgage-black.png";
import _ from "lodash";
import { mortgageCheck } from "helpers/checkInputs";

const terms = [
    "1 year fixed closed",
    "2 year fixed closed",
    "3 year fixed closed",
    "4 year fixed closed",
    "5 year fixed closed",
    "7 year fixed closed",
    "10 year fixed closed",
    "1 year variable closed",
];

const options = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

const amortization = [
    "5 years",
    "10 years",
    "15 years",
    "20 years",
    "25 years",
    "30 years",
];

const rates = {
    fixed: {
        oneyr: "3.64",
        twoyr: "3.39",
        threeyr: "3.89",
        fouryr: "3.94",
        fiveyr: "5.19",
        sevenyr: "5.69",
        tenyr: "6.10",
    },
    variable: {
        fiveyr: "3.60",
    },
};

class MortgageInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            "first payment": null,
            cadence: null,
            price: null,
            downpayment: null,
            "mortgage term": null,
            rate: null,
            "amortization period": null,
            payments: null,
            url: null,
            end: null,
            rating: 0,
            description: "",
            showRequired: false,
            address: null,
            downpaymentRate: null,
            mlsNumber: null,
            type: "Mortgage",
            value: null,
            showSettings: false,
            tag: null,
            onHoverStar: null,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
    }

    onChangeInput = (e, star) => {
        const value = e.target.value;
        const id = e.target.id;
        e.persist();
        switch (id) {
            case "price":
                this.setState({ price: value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "rate":
                this.setState({ rate: value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "term":
                this.setState({ "mortgage term": value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "cadence":
                if (options.includes(value)) {
                    this.setState({ cadence: value }, () => {
                        this.handleCalculation(e);
                    });
                }
                break;
            case "amortization":
                if (amortization.includes(value)) {
                    this.setState({ "amortization period": value }, () => {
                        this.handleCalculation(e);
                    });
                }
                break;
            case "address":
                this.setState({ address: value });
                break;
            case "downpayment":
                this.downpaymentCalculator(e);
                break;
            case "downpaymentRate":
                this.downpaymentCalculator(e);
                this.handleCalculation(e);
                break;
            case "mlsNumber":
                this.setState({ mlsNumber: value });
                break;
            default:
        }
    };

    downpaymentCalculator = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        if (this.state.price) {
            if (id === "downpayment") {
                let downpaymentRate =
                    (parseInt(value) / this.state.price) * 100;
                if (!Number.isInteger(downpaymentRate)) {
                    downpaymentRate = downpaymentRate.toFixed(2);
                }
                this.setState({
                    downpayment: value,
                    downpaymentRate,
                });
            } else if (id === "downpaymentRate") {
                const rate = parseInt(value) / 100;
                const downpayment = this.state.price * rate;
                this.setState({
                    downpayment,
                    downpaymentRate: value,
                });
            }
        }
    };

    updateValue = (id, value) => {
        switch (id) {
            case "rate":
                this.setState({ rate: value });
                break;
            case "payments":
                this.setState({
                    payments: value,
                    value: parseInt(value),
                });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
        // this.props.setNodeType(null);
        // if (this.props.onEdit) {
        //     this.props.resetSource();
        // }
    };

    onHandleDate = (value, type) => {
        if (type === "start") {
            this.setState({ "first payment": value });
        }
    };

    updateAppreciation = (checked) => {
        this.setState({ appreciation: checked });
    };

    onHandleSubmit = () => {
        const passedCheck = mortgageCheck(this.state);
        if (passedCheck) {
            this.props.confirmAction(this.state);
        } else {
            swal({
                icon: "error",
                title: "Missing Input",
                text: "Please fill up all required inputs.",
            });
        }
    };

    updateValues = () => {
        this.props.updateValues(this.state);
    };

    getMortgageRate(mortgageType, stateObject) {
        let rate = stateObject["rate"];
        switch (mortgageType) {
            case "1 year fixed closed":
                rate = rates["fixed"]["oneyr"];
                break;
            case "2 year fixed closed":
                rate = rates["fixed"]["twoyr"];
                break;
            case "3 year fixed closed":
                rate = rates["fixed"]["threeyr"];
                break;
            case "4 year fixed closed":
                rate = rates["fixed"]["fouryr"];
                break;
            case "5 year fixed closed":
                rate = rates["fixed"]["fiveyr"];
                break;
            case "7 year fixed closed":
                rate = rates["fixed"]["sevenyr"];
                break;
            case "10 year fixed closed":
                rate = rates["fixed"]["tenyr"];
                break;
            case "5 year variable closed":
                rate = rates["variable"]["fiveyr"];
                break;
            default:
        }
        return rate;
    }

    getMortgagePayments(stateObject) {
        // canadian fixed rate mortgages compound semi-annually
        let payments;
        let principal = stateObject["price"] - stateObject["downpayment"];
        let rate = stateObject["rate"] * 0.01;
        let amortization = stateObject["amortization period"].match(/\d+/)[0];
        let N = amortization * 12;
        let monthlyRate = (1 + rate / 2) ** (2 / 12) - 1;
        let monthlyPayment =
            (principal * monthlyRate * (1 + monthlyRate) ** N) /
            ((1 + monthlyRate) ** N - 1);
        //todo cadence helper
        switch (stateObject["cadence"]) {
            case "annually":
                payments = monthlyPayment * 12;
                break;
            case "monthly":
                payments = monthlyPayment;
                break;
            case "semi-monthly":
                payments = monthlyPayment / 2;
                break;
            case "bi-weekly":
                payments = (monthlyPayment * 12) / 26;
                break;
            case "weekly":
                payments = (monthlyPayment * 12) / 52;
                break;
            case "one-time":
                payments = monthlyPayment * 12 * amortization;
                break;
            default:
        }
        return payments.toFixed(2);
    }

    handleCalculation = (e) => {
        const id = e.target.id;

        //get mortgage rate
        if (id === "term") {
            let rate = this.getMortgageRate(e.target.value, this.state);
            this.updateValue("rate", rate);
        }
        //calculate mortgage payments
        if (
            this.state["cadence"] !== (undefined || null) &&
            this.state["price"] !== (undefined || null) &&
            this.state["downpayment"] !== (undefined || null) &&
            this.state["mortgage term"] !== (undefined || null) &&
            this.state["rate"] !== (undefined || null) &&
            this.state["amortization period"] !== (undefined || null)
        ) {
            let payments = parseFloat(
                this.getMortgagePayments(this.state)
            ).toFixed(2);

            this.updateValue("payments", payments);
        } else {
            return null;
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        // const { showSettings } = this.state;
        // const { edit } = this.props;
        return (
            <div>
                <div className="headerContainer">
                    <img
                        alt="alt"
                        src={mortgageOptionSvg}
                        className="flinksLogo"
                    />
                    <div className="headerInput">
                        <div className="headerLabel">MORTGAGE</div>
                        <div className="inputContainer">
                            <div className="Required">*</div>
                            <input
                                className="ExpenseInputLabel"
                                placeholder="Name"
                                id="name"
                                onChange={this.onChangeInput}
                                value={this.state.name}
                            />
                        </div>
                    </div>
                </div>
                <div className="ModalRow">
                    <div className="UrlContainer">
                        <input
                            id="address"
                            onChange={this.onChangeInput}
                            value={this.state.address}
                            className="UrlLink"
                            placeholder="Address"
                        />
                    </div>
                </div>
                <div className="ModalRow">
                    <textarea
                        placeholder="Description"
                        id="description"
                        onChange={this.onChangeInput}
                        className="ExpenseTextArea"
                        value={this.state.description}
                    />
                </div>
                <div className="ModalRow">
                    <div className="ExpenseAmountContainer">
                        <div className="Required">*</div>
                        <div className="Dollar">$</div>
                        <input
                            className="InputAmount"
                            value={this.state.price}
                            onChange={this.onChangeInput}
                            type="number"
                            id="price"
                            placeholder="Purchase Price"
                        />
                    </div>
                    <div className="HalfBox">
                        <div className="mlsLabel">MLS #</div>
                        <input
                            className="mlsInput"
                            id="mlsNumber"
                            onChange={this.onChangeInput}
                            value={this.state.mlsNumber}
                        />
                    </div>
                </div>
                <div className="ModalRow">
                    <div className="UrlContainer">
                        <div className="UrlLabel">URL:</div>
                        <input
                            className="UrlLink"
                            type="text"
                            id="url"
                            onChange={this.onChangeInput}
                            value={this.state.url}
                        />
                    </div>
                </div>
                <div className="ModalRow">
                    <div className="ExpenseAmountContainer">
                        <div className="Required">*</div>
                        <div className="Dollar">$</div>
                        <input
                            className="InputAmount"
                            value={this.state.downpayment}
                            onChange={this.onChangeInput}
                            type="number"
                            id="downpayment"
                            placeholder="Downpayment"
                        />
                    </div>
                    <div className="Required">*</div>
                    <div className="HalfBox">
                        <input
                            placeholder="Downpayment"
                            className="mlsInput"
                            id="downpaymentRate"
                            onChange={this.onChangeInput}
                            type="number"
                            value={this.state.downpaymentRate}
                        />
                        <div className="percentage">%</div>
                    </div>
                </div>
                <div className="ModalRow">
                    <div className="Required">*</div>
                    <select
                        className="termsContainer"
                        id="term"
                        onChange={this.onChangeInput.bind(this)}
                        value={this.state["mortgage term"]}
                    >
                        <option className="selectPlaceHolder" value="" selected>
                            Term
                        </option>
                        {terms.map((term, id) => {
                            return (
                                <option value={term} key={id}>
                                    {term}
                                </option>
                            );
                        })}
                    </select>
                    <div className="Required">*</div>
                    <div className="rateBox">
                        <input
                            className="mlsInput"
                            type="number"
                            id="rate"
                            onChange={this.onChangeInput}
                            value={this.state.rate}
                            placeholder="Rate"
                        />
                        <div className="percentage">%</div>
                    </div>
                </div>
                <div className="ModalRow">
                    <div className="Required">*</div>
                    <select
                        id="cadence"
                        onChange={this.onChangeInput}
                        className="IncomeSelect"
                        value={this.state.cadence}
                    >
                        <option selected>Payment Frequency</option>
                        {options.map((frequency, i) => {
                            return (
                                <option key={i} value={frequency}>
                                    {frequency}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="ModalRow">
                    <div className="Required">*</div>
                    <select
                        className="select-dropdown"
                        onChange={this.onChangeInput}
                        id="amortization"
                        value={this.state["amortization period"]}
                        onBlur={this.handleCalculation}
                    >
                        <option selected>Amortization</option>
                        {amortization.map((period, i) => {
                            return (
                                <option value={period} key={i}>
                                    {period}
                                </option>
                            );
                        })}
                    </select>
                    {/* <div className="Required">*</div>
                    <div className="ExpenseAmountContainer">
                        <div className="Dollar">$</div>
                        <input
                            className="InputAmount"
                            value={this.state.payments}
                            onChange={this.onChangeInput}
                            id="payments"
                            placeholder="Payment"
                            onBlur={this.handleCalculation}
                        />
                    </div> */}
                </div>
                <div className="ModalRow">
                    <div className="loanPaymentsContainer">
                        <div className="paymentsAmountContainer">
                            <div className="paymentsLabel">
                                Your approximate payment <br />
                                <span className="cadenceHolder">
                                    {this.state.cadence
                                        ? this.state.cadence
                                        : "frequency payment"}
                                </span>{" "}
                                will be
                            </div>
                            <div className="paymentsValue">
                                ${" "}
                                {this.state.payments
                                    ? this.state.payments
                                    : "0.00"}
                            </div>
                        </div>

                        <div className="paymentsSubLabel">
                            You`&apos;`ll pay $0.00 in the interest after 0
                            years. <br /> Term interest is the total interest
                            during the time you have the loan.
                        </div>
                    </div>
                </div>
                <div className="ModalRow">
                    <div className="Required">*</div>
                    <div className="keyLabel">Date</div>
                    <SingleCalendar
                        onHandleDate={this.onHandleDate}
                        date={this.state["first payment"]}
                    />
                </div>
                <div className="ModalRow">
                    <div className="Required" />
                    <div className="keyLabel">Rating</div>
                    <div className="StarsHolder">
                        {stars.map((star, i) => {
                            const id = i + 1;
                            const onHover = this.state.onHoverStar;
                            if (i < this.state.rating || i < onHover) {
                                return (
                                    <img
                                        alt="alt"
                                        key={i}
                                        className="starFilledSvg"
                                        src={starFilledSvg}
                                        id="rating"
                                        value={i + 1}
                                        onClick={(e) =>
                                            this.onChangeInput(e, id)
                                        }
                                        onMouseEnter={() =>
                                            this.onHandleStarHover(id)
                                        }
                                        onMouseLeave={this.onMouseLeave}
                                    />
                                );
                            } else {
                                return (
                                    <img
                                        alt="alt"
                                        className="starUnfilledSvg"
                                        src={starUnfilledSvg}
                                        key={i}
                                        id="rating"
                                        value={i + 1}
                                        onClick={(e) =>
                                            this.onChangeInput(e, id)
                                        }
                                        onMouseEnter={() =>
                                            this.onHandleStarHover(id)
                                        }
                                        onMouseLeave={this.onMouseLeave}
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
                <div className="ModalRow">
                    <Switch
                        id="appreciation"
                        checked={this.state.appreciation}
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={this.updateAppreciation}
                    />
                    <div className="InflationText">Apply Appreciation</div>
                    <div className="InflationRateContainer">1.19 %</div>
                </div>

                <div className="ModalRow">
                    <div className="RequiredField">* Required Field </div>
                </div>

                {/* <div className="decriptionBtnContainer">
                    <div
                        onClick={edit ? this.updateValues : this.onHandleSubmit}
                        className={
                            passedCheck
                                ? "proceedButton"
                                : "proceedButtonDisabled"
                        }
                    >
                        {edit ? "Update" : "Add"}
                    </div>
                </div> */}
            </div>
        );
    }
}

export default MortgageInput;

import React, { Component } from "react";
import ".././SignUp.css";
import LinkIcon from "../../../Assets/_signUpIcons/link.svg";
import BackArrowIcon from "../../../Assets/_signUpIcons/back-arrow-orange.svg";

export default class VideoPanel extends Component {
    state = {};

    render() {
        const { planDetails } = this.props;

        return (
            <div className="sign-up__video-panel-wrapper">
                <div
                    className="sign-up__video-back-wrapper"
                    onClick={this.props.toggleShowVideo}
                >
                    <img
                        className="sign-up__video-back-arrow"
                        src={BackArrowIcon}
                        alt="back arrow"
                    />
                    <span className="sign-up__video-back-text">
                        Back to Create an Account
                    </span>
                </div>
                <div className="sign-up__title-video-buttons-wrapper">
                    <div className="sign-up__video-title-wrapper">
                        <h2 className="sign-up__video-title">
                            {planDetails.accountTitle} in 5 mins
                        </h2>
                    </div>
                    <div className="sign-up__video-wrapper">
                        <iframe
                            width="100%"
                            height="100%"
                            src={planDetails.videoLink}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen="true"
                            title="Onboarding Video"
                        />
                    </div>
                    {planDetails.buttonsEnabled ? (
                        <div className="sign-up__right-panel-buttons-wrapper">
                            <a
                                className="sign-up__right-panel-buttons-button1-link"
                                target="_blank"
                                rel="noreferrer"
                                href={planDetails.learnMoreLink}
                            >
                                <div className="sign-up__right-panel-buttons-button1">
                                    <span className="sign-up__right-panel-buttons-button1-text">
                                        {planDetails.buttonOneText}
                                    </span>
                                    <img
                                        className="sign-up__right-panel-button1-img"
                                        src={LinkIcon}
                                        alt="leave current page"
                                    />
                                </div>
                            </a>
                            <div
                                className="sign-up__right-panel-buttons-button2"
                                onClick={() =>
                                    this.props.onClickSignUp(
                                        planDetails.buttonType
                                    )
                                }
                            >
                                <span className="sign-up__right-panel-buttons-button2-text">
                                    {planDetails.buttonTwoText}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="sign-up__right-panel-buttons-wrapper">
                            <div className="sign-up__right-panel-buttons-button1-inactive">
                                <span className="sign-up__right-panel-buttons-button2-text">
                                    {planDetails.buttonOneText}
                                </span>
                            </div>
                            <div className="sign-up__right-panel-buttons-button1-inactive">
                                <span className="sign-up__right-panel-buttons-button2-text">
                                    {planDetails.buttonTwoText}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

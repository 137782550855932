import React, { Component } from "react";
import "./InputContainer.css";
import _ from "lodash";
import getGeolocation from "countrycitystatejson";
import { clientCheck } from "../../helpers/checkInputs";
import { throwError } from "../../helpers/swalError";
import * as EmailValidator from "email-validator";
import {
    Rate,
    Description,
    SelectDropDown,
    Url,
    SelectDropDownCountry,
    ClientNameInput,
    ModalRow,
    RequiredStar,
} from "./Components";
const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const countries = getGeolocation.getCountries();

export default class ClientInput extends Component {
    constructor(props) {
        super(props);
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        this.state = {
            name: "",
            clientType: "business",
            firstName: "",
            lastName: "",
            country: null,
            stateList: null,
            state: null,
            description: "",
            email: "",
            agentEmail: loggedInUser.email,
            prevEmail: "",
            // Individual Client Type
            birthMonth: "",
            birthYear: "",
            // Business Client Type
            businessName: "",
        };
    }

    componentDidMount() {
        const { editData } = this.props;
        if (editData) {
            this.setState({
                ...editData.clientdata,
                prevEmail: editData.clientdata.email,
            });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;
        const { name, firstName, lastName, clientType } = this.state;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "businessName":
                if (name === this.state.businessName || !name) {
                    this.setState({ name: value });
                }
                this.setState({ businessName: value });
                break;
            case "firstName":
                if (
                    (name === `${firstName} ${lastName}` || !name) &&
                    clientType === "individual"
                ) {
                    this.setState({ name: `${value} ${lastName}` });
                }
                this.setState({ firstName: value });
                break;
            case "lastName":
                if (
                    (name === `${firstName} ${lastName}` || !name) &&
                    clientType === "individual"
                ) {
                    this.setState({ name: `${firstName} ${value}` });
                }
                this.setState({ lastName: value });
                break;
            case "country":
                const shortName = e.target.value;
                const state = getGeolocation.getStatesByShort(shortName);
                this.setState({
                    stateList: state,
                    state: null,
                    country: shortName,
                });
                break;
            case "state":
                this.setState({ state: e.target.value });
                break;
            case "birthMonth":
                this.setState({ birthMonth: value });
                break;
            case "birthYear":
                this.setState({ birthYear: value });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "email":
                this.setState({ email: value });
                break;
            default:
        }
    };

    handleToggleClientType = (event) => {
        const id = event.target.id;
        switch (event.target.id) {
            case "business":
                this.setState({ name: this.state.businessName });
                break;
            case "individual":
                this.setState({
                    name: `${this.state.firstName} ${this.state.lastName}`,
                });
                break;
            default:
        }

        this.setState({ clientType: id });
    };

    onHandleAddClient = () => {
        const passedCheck = clientCheck(this.state);
        let validEmail = false;
        let existingEmail = false;

        if (passedCheck) {
            let newState = { ...this.state };
            if (!this.state.name) {
                newState.name = newState.firstName + " " + newState.lastName;
            }
            if (EmailValidator.validate(newState.email)) {
                validEmail = true;
            } else {
                throwError(
                    "error",
                    "Invalid Email Address",
                    "Please enter a valid email address."
                );
            }

            for (let client of this.props.agencyClients) {
                if (this.state.email === client.clientdata.email) {
                    existingEmail = true;
                    throwError(
                        "error",
                        "Email Already in Use",
                        "This email address already exists in your clients."
                    );
                    break;
                } else {
                    existingEmail = false;
                }
            }

            if (validEmail && !existingEmail) {
                this.setState(newState);
                this.props.addClient(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleUpdateClient = () => {
        const passedCheck = clientCheck(this.state);
        let validEmail = false;
        let existingEmail = null;

        if (passedCheck) {
            let newState = { ...this.state };
            if (!this.state.name) {
                newState.name = newState.firstName + " " + newState.lastName;
            }

            if (EmailValidator.validate(newState.email)) {
                validEmail = true;
            } else {
                throwError(
                    "error",
                    "Invalid Email Address",
                    "Please enter a valid email address."
                );
            }

            // Check to see if email already exists in other client's records
            for (let i = 0; i < this.props.agencyClients.length; i++) {
                if (
                    this.state.email ===
                    this.props.agencyClients[i].clientdata.email
                ) {
                    existingEmail = true;
                    break;
                } else {
                    existingEmail = false;
                }
            }

            if (validEmail) {
                if (
                    existingEmail === true &&
                    this.state.prevEmail === newState.email
                ) {
                    this.setState(newState);
                    this.props.updateClient(newState);
                } else if (
                    existingEmail === true &&
                    this.state.prevEmail !== newState.email
                ) {
                    throwError(
                        "error",
                        "Email Already in Use",
                        "This email address already exists in your clients."
                    );
                } else if (
                    !existingEmail &&
                    this.state.prevEmail !== newState.email
                ) {
                    this.setState(newState);
                    this.props.updateClient(newState);
                }
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    render() {
        const {
            name,
            clientType,
            firstName,
            lastName,
            birthMonth,
            birthYear,
            email,
            description,
        } = this.state;

        const { editData } = this.props;
        const passedCheck = clientCheck(this.state);

        let maxOffset = 100;
        let thisYear = new Date().getFullYear();
        let allYears = [];
        for (let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x);
        }

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <div className="headerInput">
                            <div className="headerLabel MarginBottomLabel">
                                {!editData
                                    ? "ADD A NEW CLIENT"
                                    : "UPDATE CLIENT"}
                            </div>
                            <div className="inputContainer">
                                <div className="ModalRowNoMarginBottom">
                                    <div className="Required">*</div>
                                    <ClientNameInput
                                        name={name}
                                        onChangeInput={this.onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="toggledContainer">
                        <div
                            id="individual"
                            onClick={this.handleToggleClientType}
                            className={
                                clientType === "individual"
                                    ? "toggleBoxActive"
                                    : "toggleBox"
                            }
                        >
                            Individual
                        </div>
                        <div
                            id="business"
                            onClick={this.handleToggleClientType}
                            className={
                                clientType === "business"
                                    ? "toggleBoxActive"
                                    : "toggleBox"
                            }
                        >
                            Business
                        </div>
                    </div> */}
                    {clientType === "business" && (
                        <ModalRow>
                            <RequiredStar />
                            <Rate
                                value={this.state.businessName || ""}
                                label="Business name"
                                className="mlsInput"
                                id="businessName"
                                onChangeInput={this.onChangeInput}
                                sign={false}
                            />
                        </ModalRow>
                    )}
                    {clientType === "individual" ? (
                        <div className="ModalRowSpaceBetween">
                            <div className="ExpenseAmountContainerNarrow">
                                <div className="Required">*</div>
                                <Rate
                                    value={firstName}
                                    label="First Name"
                                    className="mlsInput"
                                    id="firstName"
                                    onChangeInput={this.onChangeInput}
                                    sign={false}
                                />
                            </div>
                            <div className="ExpenseAmountContainerNarrow">
                                <div className="Required">*</div>
                                <Rate
                                    value={lastName}
                                    label="Last Name"
                                    className="mlsInput"
                                    id="lastName"
                                    onChangeInput={this.onChangeInput}
                                    sign={false}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="ModalRowSpaceBetween">
                            <div className="ExpenseAmountContainerNarrow">
                                <div className="Required">*</div>
                                <Rate
                                    value={firstName}
                                    label="Primary Contact First Name"
                                    className="mlsInput"
                                    id="firstName"
                                    onChangeInput={this.onChangeInput}
                                    sign={false}
                                />
                            </div>
                            <div className="ExpenseAmountContainerNarrow">
                                <div className="Required">*</div>
                                <Rate
                                    value={lastName}
                                    label="Primary Contact Last Name"
                                    className="mlsInput"
                                    id="lastName"
                                    onChangeInput={this.onChangeInput}
                                    sign={false}
                                />
                            </div>
                        </div>
                    )}
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDownCountry
                            onChangeInput={this.onChangeInput}
                            value={this.state.country ? this.state.country : ""}
                            options={countries}
                            className="termsContainer"
                            id="country"
                            label="Select Country"
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDownCountry
                            onChangeInput={this.onChangeInput}
                            value={this.state.state}
                            options={this.state.stateList}
                            className="termsContainer"
                            id="state"
                            label="Select State"
                        />
                    </div>
                    {clientType === "individual" && (
                        <div className="ModalRowSpaceBetween">
                            <div className="ExpenseAmountContainerNarrow">
                                <div className="Required">*</div>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={birthMonth}
                                    options={monthList}
                                    className="select-dropdown"
                                    id="birthMonth"
                                    label="Select Birth Month"
                                />
                            </div>

                            <div className="ExpenseAmountContainerNarrow">
                                <div className="Required">*</div>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={birthYear}
                                    options={allYears}
                                    className="select-dropdown"
                                    id="birthYear"
                                    label="Select Birth Year"
                                />
                            </div>
                        </div>
                    )}
                    {clientType === "individual" ? (
                        <div className="ModalRow">
                            <div className="Required">*</div>
                            <Url
                                value={email}
                                className="UrlLink"
                                onChangeInput={this.onChangeInput}
                                id="email"
                                label="Email Address"
                            />
                        </div>
                    ) : (
                        <div className="ModalRow">
                            <div className="Required">*</div>
                            <Url
                                value={email}
                                className="UrlLink"
                                onChangeInput={this.onChangeInput}
                                id="email"
                                label="Primary Contact Email Address"
                            />
                        </div>
                    )}
                    <div className="ModalRowDescription">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div className="ModalRow">
                        <div
                            onClick={
                                editData
                                    ? this.onHandleUpdateClient
                                    : this.onHandleAddClient
                            }
                            style={
                                passedCheck
                                    ? {
                                          background: "#f8b46a",
                                          padding: "10px 20px",
                                          color: "white",
                                          cursor: "pointer",
                                          margin: "0px 5px 0px auto",
                                          borderRadius: "5px",
                                          fontFamily: "CeraPro-Regular",
                                      }
                                    : {
                                          background: "#a3a19e",
                                          padding: "10px 20px",
                                          color: "white",
                                          cursor: "pointer",
                                          margin: "0px 5px 0px auto",
                                          borderRadius: "5px",
                                          fontFamily: "CeraPro-Regular",
                                      }
                            }
                        >
                            {editData ? "Update" : "Add"}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

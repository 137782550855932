// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    ToggledContainer,
    ToggledContainerHalf,
    SelectEntities,
    EntityName,
    SelectDropDown,
    ModalRowComponent,
    Value,
    SelectEventsDropdown,
    BasicHeading,
    FillAreaTextBlock,
} from "../Components";

import type { ChangeEvent } from "react";
import Calendar from "../../Calendar";
import { instanceInputsHandler } from "../OnInputChangeHandlers/instanceInputsHandlers";
import { instanceObject } from "Components/Registry/Instance";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
const cadences = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "daily",
    "one-time",
];

export default function InstanceInputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    onHandleDate,
    passedCheck,
    onHandleSubmit,
    edit,
    handleEventChange,
    isOverrideValid,
    handleEntityStateChange,
    ledgerAccount,
    checkIfFieldIsOverridden,
    createOverride,
    getOverride,
    updateOverride,
}) {
    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "customer"
            | "entityName"
            | "event"
            | "record_Instance"
            | "group_Instance"
            | "isBypassed",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = instanceInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={instanceObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={instanceObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[instanceObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="groupInstance"
                        isSelected={
                            entitiesMap[currentEntity]?.data?.instanceType ==
                            "groupInstance"
                        }
                        onClick={handleOnChange}
                        title="Instance a Group"
                    />
                    <ToggledContainerHalf
                        id="recordInstance"
                        isSelected={
                            entitiesMap[currentEntity]?.data?.instanceType ==
                            "recordInstance"
                        }
                        onClick={handleOnChange}
                        title="Instance a Record"
                    />
                </ToggledContainer>
                {/* <ModalRowComponent width={50}>
                    <CheckBoxId
                        label="bypass target record"
                        id="isBypassed"
                        isActive={entitiesMap[currentEntity]?.data?.isBypassed}
                        onClick={handleOnChange}
                    />
                </ModalRowComponent> */}

                {entitiesMap?.[currentEntity]?.data?.instanceType ==
                    "groupInstance" ? (
                    //isntance a group ====================================================
                    <>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <RequiredStar />
                                <SelectEventsDropdown
                                    events={entitiesMap?.[
                                        currentEntity
                                    ]?.data?.event.filter((obj) => {
                                        if (
                                            obj.type ==
                                            groupObject.constant() ||
                                            obj.type == projectObject.constant()
                                        ) {
                                            return obj;
                                        }
                                    })}
                                    onChangeInput={handleEventChange}
                                    label="Select Group Event"
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedGroupEvent
                                    }
                                    selectedId={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedEvent?.id
                                    }
                                />
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <RequiredStar />
                                <SelectEntities
                                    onChangeInput={handleOnChange}
                                    showAll={true}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedGroupEntity
                                    }
                                    nodes={Object.values(
                                        entitiesMap?.[currentEntity]?.data
                                            ?.groupEntities || []
                                    )}
                                    className="IncomeSelect"
                                    id="group_Instance"
                                    label="Select Group Record"
                                    groupEventType="All"
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    </>
                ) : (
                    // instance a record ====================================================
                    <>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <RequiredStar />
                                <SelectEventsDropdown
                                    events={entitiesMap?.[
                                        currentEntity
                                    ]?.data?.event.filter((obj) => {
                                        if (
                                            obj.type !=
                                            groupObject.constant() &&
                                            obj.type != projectObject.constant()
                                        ) {
                                            return obj;
                                        }
                                    })}
                                    id="event"
                                    onChangeInput={handleEventChange}
                                    label="Select Event"
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedEvent
                                    }
                                    selectedId={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedEvent?.id
                                    }
                                />
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <RequiredStar />
                                <SelectEntities
                                    onChangeInput={handleOnChange}
                                    showAll={true}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedEntity
                                    }
                                    nodes={Object.values(
                                        entitiesMap?.[currentEntity]?.data
                                            ?.entities || []
                                    )}
                                    className="IncomeSelect"
                                    id="record_Instance"
                                    label="Select Record"
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    </>
                )}

                <ModalRow>
                    <RequiredStar />
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity]?.cadence}
                        options={cadences}
                        className="SelectFrequency"
                        id="cadence"
                        label="Frequency"
                        disabled={getOverride("value") !== undefined}
                    />
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <Calendar
                        onHandleDate={onHandleDate}
                        startDate={entitiesMap?.[currentEntity]?.startDate}
                        endDate={entitiesMap?.[currentEntity]?.endDate || null}
                        cadence={entitiesMap?.[currentEntity]?.cadence}
                        entityIndex={entityIndex}
                        optionalEndDate={true}
                    />
                </ModalRow>
                <ModalRow>
                    <BasicHeading copy="Instance Settings" />
                </ModalRow>
                <ModalRow>
                    {entitiesMap?.[currentEntity]?.data?.instanceType ===
                        "groupInstance" ? (
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={
                                entitiesMap[currentEntity]?.data?.groupClipType
                            }
                            name="radio-buttons-group"
                            onChange={(e) => handleOnChange(e, "groupClip", 1)}
                        >
                            <FormControlLabel
                                value="Full"
                                control={
                                    <Radio
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "#f7ab5f",
                                            },
                                        }}
                                    />
                                }
                                label="Full Range"
                            />
                            <FormControlLabel
                                value="Inherit"
                                control={
                                    <Radio
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "#f7ab5f",
                                            },
                                        }}
                                    />
                                }
                                label="Inherit Range"
                            />
                            <FormControlLabel
                                value="Clip"
                                control={
                                    <Radio
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "#f7ab5f",
                                            },
                                        }}
                                    />
                                }
                                label="Clip Range (uses End Date)"
                            />
                        </RadioGroup>
                    ) : (
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={
                                entitiesMap[currentEntity]?.data?.recordClipType
                            }
                            name="radio-buttons-group"
                            onChange={(e) => handleOnChange(e, "recordClip", 1)}
                        >
                            <FormControlLabel
                                value="Full"
                                control={
                                    <Radio
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "#f7ab5f",
                                            },
                                        }}
                                    />
                                }
                                label="Full Range"
                            />
                            <FormControlLabel
                                value="Clip"
                                control={
                                    <Radio
                                        sx={{
                                            "&.Mui-checked": {
                                                color: "#f7ab5f",
                                            },
                                        }}
                                    />
                                }
                                label="Clip Range (uses End Date)"
                            />
                        </RadioGroup>
                    )}
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowComponent width={30}>
                        <Value
                            label="Instance Amount"
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.data?.value}
                            id="value"
                            variant="outlined"
                            override={isOverrideValid(
                                entitiesMap,
                                currentEntity
                            )}
                            entityData={entitiesMap?.[currentEntity]}
                            overridden={checkIfFieldIsOverridden("value")}
                            ledgerAccount={ledgerAccount}
                            createOverride={createOverride}
                            updateOverride={updateOverride}
                        />
                    </ModalRowComponent>
                    <ModalRowComponent width={65}>
                        <FillAreaTextBlock
                            copy={`The number of times each month, from 
                            ${entitiesMap?.[currentEntity]?.startDate !== ""
                                    ? moment(
                                        entitiesMap?.[currentEntity]
                                            ?.startDate
                                    ).format("MMM Do YYYY")
                                    : "the selected start date"
                                } to
                            ${entitiesMap?.[currentEntity]?.endDate !== ""
                                    ? moment(
                                        entitiesMap?.[currentEntity]?.endDate
                                    ).format("MMM Do YYYY")
                                    : "the scenario's end date"
                                }, the selected Record will calculate. Right-click to
                            manually override this value on a month-by-month
                            basis.`}
                        ></FillAreaTextBlock>
                    </ModalRowComponent>
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

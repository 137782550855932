import { UPSERT_GRAPH_ACCOUNT_SELECTION } from "actions/graphAccountSelectionActions";
import { GraphAccountSelectionSchema } from "reducers/typesSchema/graphAccountSelectionSchema";

const initialState: GraphAccountSelectionSchema = {
    listedAccountTypes: [],
    selectedAccountTypes: [],
};

export default function graphAccountSelectionReducer(
    state = initialState,
    action: { type: string; payload?: Partial<GraphAccountSelectionSchema> }
) {
    switch (action.type) {
        case UPSERT_GRAPH_ACCOUNT_SELECTION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

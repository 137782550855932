// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    InflationSwitch,
    EntityName,
    ToggledContainer,
    ToggledContainerHalf,
} from "../Components";
import incomeInputsHandler from "../OnInputChangeHandlers/incomeInputsHandler";
import type { ChangeEvent } from "react";
import { incomeObject } from "../../Registry/Income";
import AmortizedExpensePanel from "Components/AmortizedExpensePanel";
import OngoingExpensePanel from "Components/OngoingExpensePanel";

const IncomeInputView = ({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    updateInflation,
    passedCheck,
    onHandleSubmit,
    edit,
    isOverrideValid,
    checkIfFieldIsOverridden,
    handleEntityStateChange,
    ledgerAccount,
    updateAccount,
    createOverride,
    getOverride,
    updateOverride,
    handleChangeIncomeType,
}) => {
    const entityData = entitiesMap?.[currentEntity]?.data;

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = incomeInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "value"
            | "startDate"
            | "endDate"
            | "entityName"
            | "cadence"
            | "accountName"
            | "amortizationPeriod",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = incomeInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={incomeObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={incomeObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[incomeObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="ongoing"
                        isSelected={
                            entityData?.ongoingOrAmortized === "ongoing"
                        }
                        onClick={handleChangeIncomeType}
                        title="Ongoing Income"
                    />
                    <ToggledContainerHalf
                        id="amortized"
                        isSelected={
                            entityData?.ongoingOrAmortized === "amortized"
                        }
                        onClick={handleChangeIncomeType}
                        title="Amortized Income"
                    />
                </ToggledContainer>
                {entityData?.ongoingOrAmortized === "ongoing" && (
                    <OngoingExpensePanel
                        entitiesMap={entitiesMap}
                        currentEntity={currentEntity}
                        handleOnChange={handleOnChange}
                        handleDateSelection={handleDateSelection}
                        isOverrideValid={isOverrideValid}
                        checkIfFieldIsOverridden={checkIfFieldIsOverridden}
                        ledgerAccount={ledgerAccount}
                        createOverride={createOverride}
                        getOverride={getOverride}
                        updateOverride={updateOverride}
                        value={entityData?.value}
                    />
                )}
                {entityData?.ongoingOrAmortized !== "ongoing" && (
                    <AmortizedExpensePanel
                        entitiesMap={entitiesMap}
                        currentEntity={currentEntity}
                        handleOnChange={handleOnChange}
                        handleDateSelection={handleDateSelection}
                        unitless={false}
                        increase={true}
                        ledgerAccount={ledgerAccount}
                        overrideValue={
                            isOverrideValid(entitiesMap, currentEntity) &&
                            entityData?.ongoingOrAmortized === "ongoing"
                        }
                        createOverride={createOverride}
                        updateOverride={updateOverride}
                        amortizedValue={
                            entitiesMap?.[currentEntity]?.data?.value
                        }
                        numPayments={
                            entitiesMap?.[currentEntity]?.data?.numPayments
                        }
                        amortizationPeriod={
                            entitiesMap?.[currentEntity]?.data
                                ?.amortizationPeriod
                        }
                        cadence={entitiesMap?.[currentEntity]?.cadence}
                        isValueOverriden={checkIfFieldIsOverridden("value")}
                        startDate={entitiesMap?.[currentEntity]?.startDate}
                        endDate={entitiesMap?.[currentEntity]?.endDate}
                    />
                )}
                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={entityData?.accountName ?? ""}
                        topLevelFilters={{
                            "488dd61d-8697-4213-8978-cf91755365a4": true,
                        }}
                        required={true}
                    />
                </ModalRow>
                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={entityData?.contraAccountName}
                        contraAccount={true}
                        // this is the top level account which is at position 0
                        accountSelectedForContra={
                            entityData?.accountIds?.length
                                ? entityData.accountIds[0]
                                : ""
                        }
                    />
                </ModalRow>
                <ModalRow>
                    <InflationSwitch
                        applyInflation={entityData?.inflation}
                        toggleInflation={updateInflation}
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default IncomeInputView;

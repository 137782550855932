import React from "react";
import styles from "./Options.module.css";
import { NodeTypesBlack } from "../../Events";
import tourPoint from "../../Assets/_onboarding/tourPoint.svg";
import { decisionObject } from "Components/Registry/Decision";
import { kpiObject } from "Components/Registry/KPI";

export const UtilityTab = ({
    nodes,
    onHandleOptionSelected,
    _addEventType,
    onboardingData,
    isBaseline,
}) => {
    return (
        <div className={styles.utilityContainer}>
            {nodes.map((node) => {
                // Hide Decision node if we are in baseline
                if (isBaseline && node.type === decisionObject.constant())
                    return <></>;
                if (process.env.NODE_ENV !== "development" && node.type === kpiObject.constant()) return <></>
                // const isDescriptionOpen =
                //     node.type === "House" ||
                //     node.type === "Mortgage" ||
                //     node.type === "Person" ||
                //     node.type === "Pension";

                const typeImagesBlack = {};

                NodeTypesBlack.forEach((data) => {
                    typeImagesBlack[data.type] = data.image;
                });

                let image = typeImagesBlack[node.type];
                return (
                    <div
                        key={node.type}
                        // onClick={() =>
                        //     isDescriptionOpen
                        //         ? onHandleOptionSelected(node.type)
                        //         : addEventType(node.type)
                        // }
                        onClick={() => onHandleOptionSelected(node.type)}
                        className={styles.utilityDiv}
                    >
                        {node.type === "Decision" &&
                            (onboardingData === 2 || onboardingData === 10) && (
                                <img
                                    alt="tourPoint"
                                    src={tourPoint}
                                    className={styles.utilityTourPoint}
                                />
                            )}

                        <img className={styles.utilitySvg} src={image} />
                        <div className={styles.utilityDescriptionContainer}>
                            <div className={styles.utilityType}>
                                {node.name}
                            </div>
                            <div>{node.description}</div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

import {
    RefObject,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import Konva from "konva";
import type { EventManager } from "Events";
import type Event from "Events/_event";
import { useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import { CanvasStageScaleContext } from "../../contexts/CanvasStageScaleContext";
import { getStageNewCenter } from "../../helpers/getStageNewCenter";
import { getScenarioDimensions } from "../../helpers/getScenarioDimensions";

import ZoomInIcon from "Assets/actionsIcons/zoom-in.svg";
import ZoomOutIcon from "Assets/actionsIcons/zoom-out.svg";
import FrameCanvasIcon from "Assets/actionsIcons/center.svg";
// import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CanvasZoomButtonsStyles from "./CanvasZoomButtonsStyles.module.css";
import { StageViewableAreaDimensionsContext } from "../../contexts/StageViewableAreaDimensionsContext";
import { CanvasStageWrapperElementDimensions } from "../../contexts/CanvasStageWrapperElementDimensions";
import { CanvasBaselineContext } from "../../contexts/CanvasBaselineContext";
import { getManager } from "helpers/getManager";
import RunOrange from "Assets/scenarioIcons/runOrange.png";
import RunGrey from "Assets/scenarioIcons/runGrey.png";
import PauseOrange from "Assets/scenarioIcons/pauseOrange.png";
import PauseGrey from "Assets/scenarioIcons/pauseGrey.png";
import { handleDecisionEnginePause } from "actions/scenario";
// import { resetAllEventPositions } from "actions/canvasEventActions";

const SCALE_BY_FACTOR = 1.1;
const MAX_SCALE = 1.5;
const MIN_SCALE = 0.25;

interface CanvasZoomButtonsProps {
    stageRef: RefObject<Konva.Stage>;
    setStageScale: Dispatch<SetStateAction<number>>;
    setStagePosition: Dispatch<SetStateAction<[number, number]>>;
}

export function CanvasZoomButtons({
    stageRef,
    setStageScale,
    setStagePosition,
}: CanvasZoomButtonsProps) {
    const dispatch = useAppDispatch();

    const _isBaseline = useContext(CanvasBaselineContext);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const stageScale = useContext(CanvasStageScaleContext);
    const viewableAreaDomRect = useContext(StageViewableAreaDimensionsContext);
    const canvasStageWrapperDomRect = useContext(
        CanvasStageWrapperElementDimensions
    );

    const [isPaused, setIsPaused] = useState(false);

    const manager: EventManager = useAppSelector((state) =>
        getManager(_isBaseline, state)
    );

    const currentScenarioId = useAppSelector(
        (state) => state?.scenario?.loadedScenario?.id,
        shallowEqual
    );

    const handleRunPauseClick = (action) => {
        const pausedState = action === "pause";
        setIsPaused(pausedState);
        dispatch(handleDecisionEnginePause(action));
        manager.calculate();
    };

    const { addEventOpen, editEventOpen, shareScenarioOpen } = useAppSelector(
        (state) => {
            const addEventOpen = !!state?.scenario?.showOptions;
            const editEventOpen = !!state?.scenario?.edit;
            const shareScenarioOpen = !!state?.scenario?.shareScenario;

            return {
                addEventOpen,
                editEventOpen,
                shareScenarioOpen,
            };
        },
        shallowEqual
    );

    const keydownEvents = (e) => {
        if (!addEventOpen && !editEventOpen && !shareScenarioOpen) {
            if ((e.keyCode === 187 || e.keyCode === 107) && e.shiftKey)
                clickZoomIn();
            if ((e.keyCode === 189 || e.keyCode === 109) && e.shiftKey)
                clickZoomOut();
            if (e.keyCode === 70 && e.altKey) centerScenario();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keydownEvents);

        return function cleanup() {
            document.removeEventListener("keydown", keydownEvents);
        };
    });

    const centerScenario = () => {
        // Step 1: Get the full scenario's width & height as if it were scale == 1
        const events = Object.values(manager?.nodes ?? {}) as Event[];
        const { scenarioWidth, scenarioHeight } = getScenarioDimensions(events);

        // Step 2: Get the smallest scale between the x, y, and max scale (so the entire scenario will fit in the viewable area)
        const xScale = viewableAreaDomRect?.width / scenarioWidth;
        const yScale = viewableAreaDomRect?.height / scenarioHeight;
        const scale = Math.min(xScale, yScale, MAX_SCALE);

        if (!scale) return;

        if (isFirstLoad) setIsFirstLoad(false);
        // Step 3: Get the x & y positions of the stage (to re-center in the viewable area)
        /**
         * Represents how far from the left we need to offset.
         * While canvasStageWrapperDomRect.left is 0 all the time currently, that is not guaranteed in the future.
         */
        const leftOffset =
            (viewableAreaDomRect?.left ?? 0) -
            (canvasStageWrapperDomRect?.left ?? 0);
        /**
         * Represents how far from the top we need to offset.
         * While the resulting value is always 0 currently, that is not guaranteed in the future.
         */
        const topOffset =
            (viewableAreaDomRect?.top ?? 0) -
            (canvasStageWrapperDomRect?.top ?? 0);
        const xPositionBias = xScale === xScale ? 50 : 0; // NOTE: x needs a small positive bias if we're using the xScale
        const yPositionBias = 50; // NOTE: y always needs a negative bias of ~50 for some reason
        const stageX =
            leftOffset +
            viewableAreaDomRect?.width / 2 -
            (scenarioWidth * scale) / 2 +
            xPositionBias * scale;
        const stageY =
            topOffset +
            viewableAreaDomRect?.height / 2 -
            (scenarioHeight * scale) / 2 -
            yPositionBias * scale;

        // Step 4: Get an extremely small random number that will ensure re-render happens
        /**
         * Ensures re-render will happen, but value is so small that it's unnoticeable to the human eye.
         */
        const smallRandom = Math.random() * 0.0001 - 0.0001 / 2;
        // Step 5: Set the stage scale & stage positioning to re-render
        setStageScale(scale + smallRandom);
        setStagePosition([stageX + smallRandom, stageY + smallRandom]);
    };

    useEffect(() => {
        if (isFirstLoad) {
            centerScenario();
        }
        /* eslint-disable */ // quick fix because useCallback broke the desired behavior.
    }, [viewableAreaDomRect, canvasStageWrapperDomRect]);

    useEffect(() => {
        if (currentScenarioId) centerScenario();
        /* eslint-disable */ // quick fix because useCallback broke the desired behavior.
    }, [currentScenarioId]);

    const clickZoomIn = () => {
        const stage = stageRef.current as Konva.Stage;
        const newScale = Math.min(MAX_SCALE, stageScale * SCALE_BY_FACTOR);

        /**
         * Represents how far from the left we need to offset.
         * While canvasStageWrapperDomRect.left is 0 all the time currently, that is not guaranteed in the future.
         */
        const leftOffset =
            (viewableAreaDomRect?.left ?? 0) -
            (canvasStageWrapperDomRect?.left ?? 0);
        /**
         * Represents how far from the top we need to offset.
         * While the resulting value is always 0 currently, that is not guaranteed in the future.
         */
        const topOffset =
            (viewableAreaDomRect?.top ?? 0) -
            (canvasStageWrapperDomRect?.top ?? 0);
        const newPos = getStageNewCenter(
            viewableAreaDomRect?.width,
            viewableAreaDomRect?.height,
            stage?.x?.(),
            stage?.y?.(),
            stageScale,
            newScale,
            leftOffset,
            topOffset
        );

        setStageScale(newScale);
        if (!isNaN(newPos.x) && !isNaN(newPos.y))
            setStagePosition([newPos.x, newPos.y]);
    };

    const clickZoomOut = () => {
        const stage = stageRef.current as Konva.Stage;
        const newScale = Math.max(MIN_SCALE, stageScale / SCALE_BY_FACTOR);

        /**
         * Represents how far from the left we need to offset.
         * While canvasStageWrapperDomRect.left is 0 all the time currently, that is not guaranteed in the future.
         */
        const leftOffset =
            (viewableAreaDomRect?.left ?? 0) -
            (canvasStageWrapperDomRect?.left ?? 0);
        /**
         * Represents how far from the top we need to offset.
         * While the resulting value is always 0 currently, that is not guaranteed in the future.
         */
        const topOffset =
            (viewableAreaDomRect?.top ?? 0) -
            (canvasStageWrapperDomRect?.top ?? 0);
        const newPos = getStageNewCenter(
            viewableAreaDomRect?.width,
            viewableAreaDomRect?.height,
            stage?.x?.(),
            stage?.y?.(),
            stageScale,
            newScale,
            leftOffset,
            topOffset
        );

        setStageScale(newScale);
        if (!isNaN(newPos.x) && !isNaN(newPos.y))
            setStagePosition([newPos.x, newPos.y]);
    };

    // const resetEventPositions = () => {
    //     dispatch(resetAllEventPositions());
    // };

    return (
        <div className={CanvasZoomButtonsStyles.OverlayButtonsWrapper}>
            <div className={CanvasZoomButtonsStyles.CenterFrameWrapper}>
                <img
                    className={CanvasZoomButtonsStyles.OverlayButton}
                    src={FrameCanvasIcon}
                    onDragStart={(e) => e?.preventDefault?.()}
                    onClick={centerScenario}
                    title="Press alt f"
                />
                <img
                    className={CanvasZoomButtonsStyles.OverlayButtonSmall}
                    src={isPaused ? RunGrey : RunOrange}
                    onDragStart={(e) => e?.preventDefault?.()}
                    onClick={() => handleRunPauseClick("run")}
                    title="Run Calculations"
                />
                <img
                    className={CanvasZoomButtonsStyles.OverlayButtonSmall}
                    src={isPaused ? PauseOrange : PauseGrey}
                    onDragStart={(e) => e?.preventDefault?.()}
                    onClick={() => handleRunPauseClick("pause")}
                    title="Pause Calculations"
                />
            </div>
            <div className={CanvasZoomButtonsStyles.ZoomButtonsWrapper}>
                <img
                    className={CanvasZoomButtonsStyles.OverlayButton}
                    src={ZoomInIcon}
                    onClick={clickZoomIn}
                    onDragStart={(e) => e?.preventDefault?.()}
                    title="Press shift +"
                />
                <img
                    className={CanvasZoomButtonsStyles.OverlayButton}
                    src={ZoomOutIcon}
                    onClick={clickZoomOut}
                    onDragStart={(e) => e?.preventDefault?.()}
                    title="Press shift -"
                />
                {/* <RestartAltIcon
                    className={CanvasZoomButtonsStyles.OverlayButton}
                    onClick={resetEventPositions}
                /> */}
                <span className={CanvasZoomButtonsStyles.ZoomLevel}>
                    {Math.floor(stageScale * 100)}%
                </span>
            </div>
        </div>
    );
}

import { Group } from "react-konva";

/**
 * Anything underneath the EventNodeBody (as of this writing, no idea what would go here).
 */
export const LayerOne = Group;
/**
 * The bottom-layer VISUAL EventNodeBody. It cannot be interacted with. It is only there for visual representation and to give shape to the event. Should only contain this one component.
 */
export const LayerTwo = Group;
/**
 * Any visual elements within the EventNodeBody. Can have multiple items in here. Potentially create a group of its own or a component of its own.
 */
export const LayerThree = Group;
/**
 * The top-layer VISUAL EventNodeBody. It cannot be interacted with. It is only there for visual representation to give additional visual info above {@link LayerThree}. Should only contain this one component.
 */
export const LayerFour = Group;
/**
 * The CLICKABLE EventNodeBody. Exact same dimensions as its VISUAL component. It cannot be seen, only clickable. Should only contain this one component.
 */
export const LayerFive = Group;
/**
 * Any clickable elements within the EventNodeBody (LayerTwo) (ie. star ratings). Can contain multiple components.
 */
export const LayerSix = Group;
/**
 * Any visual & clickable elements outside the EventNodeBody (LayerTwo). Could be used for things like buttons.
 * Could theoretically be grouped with LayerFive, but it is easier to organize in this layer. This layer may be separated into visual and clickable layers like with EventNodeBody.
 */
export const LayerSeven = Group;

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import CustomerTransfer2Card from "../Card/customerTransfer2Card";

// Import Connection Image (if exists)
import ModifierConnectionImage from "../../Assets/_optionDescriptionIcons/Customer, Customer, Transfer.png";

// Import Coloured PNGs
import customerTransferBlack from "../../Assets/_eventNodeTypes/customerTransfer.svg";
import customerTransferBlue from "../../Assets/_eventNodeTypes/customerTransfer.svg";
import customerTransferWhite from "../../Assets/_eventNodeTypes/customerTransfer.svg";
import customerTransferGrey from "../../Assets/_eventNodeTypes/customerTransfer.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import CustomerTransfer2InputExperimental from "Components/InputContainer/CustomerTransfer2Experimental/CustomerTransfer2InputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - modifierObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - modifierObject.checkInput.call(modifierObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const customerTransfer2Object = {
    constant: function () {
        return CustomerTransfer2_Constant();
    },

    name: function () {
        return "Customer Transfer 2";
    },

    checkInput: function (state) {
        return CustomerTransfer2_CheckInput(state);
    },

    svg: function () {
        return CustomerTransfer2_Svg();
    },

    eventNodeMenu: function (focus) {
        return CustomerTransfer2_EventNodeMenu(focus);
    },

    indexText: function () {
        return CustomerTransfer2_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return CustomerTransfer2_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return CustomerTransfer2_OptionDescription();
    },

    colouredPngs: function () {
        return CustomerTransfer2_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return CustomerTransfer2_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const CustomerTransfer2_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (!entity.dependencies) return false;
        if (!entity.dependencies.sourceEntity) return false;
        if (!entity.dependencies.sourceEntity.eventId) return false;
        if (!entity.dependencies.targetCustomer) return false;
        if (!entity.dependencies.targetCustomer.eventId) return false;

        if (!entity.data.modType) return false;

        if (entity.data.transferType == "constant") {
            if (!entity.data.constantTransferRate) return false;
        } else if (entity.data.transferType == "dynamic") {
            if (entity.data.transferSegments.length <= 0) return false;

            for (const segment of entity.data.transferSegments) {
                if (!segment.startDate || !segment.period) return false;
                if (segment.period == "monthly") {
                    if (!segment.monthlyRate && segment.monthlyRate !== 0) {
                        return false;
                    }
                } else if (segment.period == "yearly") {
                    if (!segment.yearlyRate && segment.yearlyRate !== 0) {
                        return false;
                    }
                }
            }
        }

        if (entity.data.customEffectPeriod) {
            if (!entity.startDate) return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const CustomerTransfer2_Constant = () => {
    return "a0b65412-4ab5-4d78-b947-4e0baeb47c65";
};

// EFFECTS: Returns black png / svg for event
const CustomerTransfer2_Svg = () => {
    return customerTransferBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const CustomerTransfer2_EventNodeMenu = (focus) => {
    return <CustomerTransfer2Card data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const CustomerTransfer2_IndexText = () => {
    return "Customer Transfer Event In Progress";
};

// EFFECTS: Returns all icons for event ??
const CustomerTransfer2_Icons = () => {
    let pngs = new Map([
        ["black", customerTransferBlack],
        ["blue", customerTransferBlue],
        ["white", customerTransferWhite],
        ["grey", customerTransferGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Modifier_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const CustomerTransfer2_InputComponentInfoPassing = (minProps) => {
    return <CustomerTransfer2InputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, modifiers for event ??
const CustomerTransfer2_OptionDescription = () => {
    const tags = ["Option"];
    const type = customerTransfer2Object.name() + " (BETA)";
    const description =
        "The Customer Transfer Event is a unique event that allows a User to move customers from one cohort to another.";
    const usedForText =
        "This Event can be used to model the movement of customers from one revenue stream to another. For example, every month 10% of customers upgrade to a more expensive gym membership";
    const connection =
        "Customer Transfer only needs to be downstream of the Customer Events being modified";
    const connectionImage = ModifierConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const CustomerTransfer2_SideModalImage = () => {
    return sideImage;
};

export default function GraphLoading({ isLoading }: { isLoading: boolean }) {
    if (isLoading)
        return (
            <img
                alt="loadingGif"
                className="loadingGif"
                src="https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/assets/loading.gif"
            />
        );
    return null;
}

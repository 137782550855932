import Checkbox from "@mui/material/Checkbox";
import styles from "./checkboxStyles.module.css";
export const CheckBoxCellRenderer = (props) => {
    const handleCheck = () => {
        const checked = !!props?.value;
        const colId = props.column.colId;
        props.node.setDataValue(colId, !checked);
    };

    return (
        <div className={styles?.checkboxCellContainer}>
            <Checkbox
                onClick={handleCheck}
                checked={props?.value ?? false}
                sx={{
                    padding: "0",
                    paddingTop: "2px",
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                }}
            />
        </div>
    );
};

import React, { Component } from "react";
import "./InputContainer.css";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import { mortgage1Metadata } from "../../helpers/onboardingInputs";
import {
    Description,
    SelectDropDown,
    Value,
    Rate,
    InputButtons,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    ToggledContainer,
    ToggledContainerHalf,
    RequiredStar,
    ModalRowTwoThirds,
    ModalRowOneThird,
    ModalRowHalf,
    StarsRating,
    AdvancedSettingsToggle,
} from "./Components";
import { getDefaultName, prependBaselinePrefix } from "../../helpers";
import {
    cadenceOptions,
    Downpayment,
    Me,
    Person,
} from "../../helpers/constants";
import SingleCalendar from "../Calendar/singleCalendar";
import moment from "moment";
import {
    amortization,
    currentMortgageAmortization,
    terms,
    getMortgageRate,
} from "../../helpers/mortgageHelpers";
import {
    DownpaymentRate,
    DownpaymentAmount,
} from "../../helpers/downpaymentHelpers";
import {
    getClosestDependency,
    getDependencyCandidates,
    getPresentableDependenciesOfManyTypes,
} from "../../helpers/nodeDependencyDetectionHelpers";
import { rrspObject } from "Components/Registry/RRSP";
import { investmentObject } from "Components/Registry/Investment";
import { houseObject } from "Components/Registry/House";
import { mortgageObject } from "Components/Registry/Mortgage";

class MortgageALT extends Component {
    constructor(props) {
        super(props);

        // only perform traversals on initial add; else it receives state data from fe context passing
        let data = {};
        let people = {};
        let rrspNodes = [];
        data = this.handleDependencyDetection(props);
        this.validateDependencyData(data);
        const rrspDependencyData = this.getPeopleAndRRSPs();
        people = rrspDependencyData.peopleWithRRSP;
        rrspNodes = rrspDependencyData.allRRSPNodes;

        // note: we must keep track of the downpayment data directly inputted into the node

        const defaultState = {
            ...MortgageALT.getDefaultState(
                props.inflationRate,
                getDefaultName(mortgageObject.constant(), props),
                props.baseline
            ),
            dependencyPayments: data.houseId ? { [data.houseId]: null } : {},
        };
        const initialState = props.editData
            ? {
                  ...defaultState,
                  ...props.editData,
              }
            : defaultState;

        // state related to downpayments, that can either be inherited or configured
        const variableState = {
            downpayment: data.downpayment
                ? data.downpayment
                : props.editData
                ? props.editData.downpayment
                : null,
            downpaymentRate: data.rate
                ? Number(data.rate)
                : props.editData
                ? props.editData.downpaymentRate
                : null,
            lastChangedDownpaymentProperty:
                data.lastChangedDownpaymentProperty ||
                initialState.lastChangedDownpaymentProperty,
        };

        this.state = {
            ...initialState,
            // dependency data
            ...variableState,
            "first payment": this.props.baseline ? null : data.start,
            isDownpayment: data.isDownpayment,
            valid: data.isValid,
            description: "",
            houseId: data.houseId,
            price: this.props.baseline ? 0 : data.price,
            // data for RRSP as down payment:
            showRRSPSettings: false,
            people,
            rrspNodes,
            personOne: data.personOne || "",
            personTwo: data.personTwo || "",
            rrspContributionOne: data.rrspContributionOne || 0,
            rrspContributionTwo: data.rrspContributionTwo || 0,
            rrspMaxOne: data.rrspMaxOne || 0,
            rrspMaxTwo: data.rrspMaxTwo || 0,
            personOneIsFTB: data.personOneIsFTB || "No",
            personTwoIsFTB: data.personTwoIsFTB || "No",
        };
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            name: name,
            "first payment": null,
            cadence: "",
            price: 0,
            downpayment: null,
            inputtedDownpayment: null,
            isDownpayment: false,
            inputtedDownpaymentRate: null,
            "mortgage term": null,
            rate: null,
            "amortization period": null,
            payments: null,
            url: null,
            end: null,
            rating: 0,
            description: "",
            showRequired: false,
            lastChangedDownpaymentProperty: DownpaymentAmount,
            downpaymentRate: null,
            mlsNumber: null,
            type: mortgageObject.constant(),
            value: "0",
            showSettings: false,
            valid: false,
            houseId: null,
            dependencyPayments: {},
            tag: `@${mortgageObject.constant()}`,
            onHoverStar: null,
            inflation: false,
            inflationRate: inflationRate,
            termInterest: null,
            baseline: isInBaseline,
            isNew: !isInBaseline,
            // data for RRSP as down payment:
            showRRSPSettings: false,
            people: {},
            rrspNodes: [],
            personOne: null,
            personTwo: null,
            rrspContributionOne: 0,
            rrspContributionTwo: 0,
            rrspMaxOne: 0,
            rrspMaxTwo: 0,
            personOneIsFTB: "No",
            personTwoIsFTB: "No",
        };
    }

    validateDependencyData = (data) => {
        if (!data.isValid) {
            throwError(
                "warning",
                "Failed to find an upstream house node.",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }
    };

    handleDependencyDetection = (props) => {
        const dependencyData = {
            downpayment: null,
            rate: null,
            price: 0,
            start: 0,
            isValid: false,
            isDownpayment: false,
            houseId: null,
            personOne: null,
            personTwo: null,
            rrspContributionOne: 0,
            rrspContributionTwo: 0,
            rrspMaxOne: 0,
            rrspMaxTwo: 0,
            personOneIsFTB: "No",
            personTwoIsFTB: "No",
        };

        const config = {
            scenarioHandler: MortgageALT.scenarioThreadTraversalHandler,
        };
        const nearestHouse =
            getClosestDependency(props, [houseObject.constant()], null, {
                ...config,
                requiredDependencyTypes: [houseObject.constant()],
            }) || {};
        let nearestDownpayment =
            getClosestDependency(props, [Downpayment], null, {
                ...config,
                requiredDependencyTypes: [Downpayment],
            }) || {};

        let houseIsUpstreamOfDownpayment = false;
        let curNode = nearestHouse[houseObject.constant()];
        let dp = nearestDownpayment[Downpayment];
        while (curNode && dp) {
            if (curNode.id === dp.id) {
                houseIsUpstreamOfDownpayment = true;
                curNode = null;
            } else {
                curNode = curNode.parents[0];
            }
        }
        if (houseIsUpstreamOfDownpayment) nearestDownpayment = {};
        const dependencies = { ...nearestDownpayment, ...nearestHouse };
        // const dependencies = getClosestDependency(
        //     this.props,
        //     [Downpayment, House],
        //     null,
        //     config
        // );
        if (!dependencies) return dependencyData;
        this.processDependencies(dependencies, dependencyData);
        return dependencyData;
    };

    getRRSPCandidates = (props) => {
        let rrspInvestmentNodes = [],
            rrspNodes = [];
        const dependencies = getDependencyCandidates(
            props,
            [rrspObject.constant(), investmentObject.constant()],
            null,
            false
        );
        const rrspCandidates = dependencies[rrspObject.constant()];
        const investmentCandidates = dependencies[investmentObject.constant()];

        if (dependencies && investmentCandidates) {
            for (const investment of investmentCandidates) {
                if (investment.metadata.subType === rrspObject.constant()) {
                    rrspInvestmentNodes.push(investment);
                }
            }
        }
        if (dependencies && rrspCandidates) {
            rrspNodes = rrspCandidates;
        }

        return { rrspNodes, rrspInvestmentNodes };
    };

    // modifiers data based on dependencies
    processDependencies = (dependencies, data) => {
        if (!dependencies) return;
        if (dependencies[houseObject.constant()]) {
            this.processHouse(dependencies[houseObject.constant()], data);
        }
        if (dependencies[Downpayment]) {
            this.processDownpayment(
                dependencies[Downpayment],
                dependencies[houseObject.constant()],
                data
            );
        }
    };

    // retrieves all Person and Me nodes with corresponding RRSP & Investment ( w/ subtype RRSP) nodes
    getPeopleAndRRSPs = () => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            this.props,
            "Person"
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        const modifiedRRSPIds = [];
        const peopleWithRRSP = {};
        const { rrspNodes, rrspInvestmentNodes } = this.getRRSPCandidates(
            this.props
        );

        for (const person of Object.keys(people)) {
            for (const rrsp of rrspNodes) {
                if (rrsp.metadata.personId === people[person]) {
                    peopleWithRRSP[person] = people[person];
                    modifiedRRSPIds.push(rrsp.id);
                    break;
                }
            }
            for (const rrspInvestment of rrspInvestmentNodes) {
                if (
                    rrspInvestment.metadata.personId === people[person] &&
                    !peopleWithRRSP[person]
                ) {
                    peopleWithRRSP[person] = people[person];
                    modifiedRRSPIds.push(rrspInvestment.id);
                    break;
                }
            }
        }

        const allRRSPNodes = [];
        for (const rrsp of rrspNodes.concat(rrspInvestmentNodes)) {
            allRRSPNodes.push({
                id: rrsp.id,
                personId: rrsp.metadata.personId,
            });
        }

        return { peopleWithRRSP, allRRSPNodes };
    };

    static scenarioThreadTraversalHandler = (node, dependencies, target) => {
        switch (node.type) {
            case Downpayment:
                dependencies[Downpayment] = node;
                break;
            case houseObject.constant():
                dependencies[houseObject.constant()] = node;
                break;
            case mortgageObject.constant():
                if (node.id !== target.id) {
                    dependencies[houseObject.constant()] = null;
                    dependencies[Downpayment] = null;
                }
                break;
            default:
        }
    };

    processDownpayment = (downpaymentNode, houseNode, data) => {
        data.isDownpayment = true;
        data.rate = downpaymentNode.metadata.downpaymentRate;
        data.downpayment = downpaymentNode.metadata.downpayment;
        data.lastChangedDownpaymentProperty =
            downpaymentNode.metadata.lastChangedDownpaymentProperty;
        data.personOne = downpaymentNode.metadata.personOne;
        data.personTwo = downpaymentNode.metadata.personTwo;
        data.rrspContributionOne = downpaymentNode.metadata.rrspContributionOne;
        data.rrspContributionTwo = downpaymentNode.metadata.rrspContributionTwo;
        data.rrspMaxOne = downpaymentNode.metadata.rrspMaxOne;
        data.rrpsMaxTwo = downpaymentNode.metadata.rrspMaxTwo;
        data.personOneIsFTB = downpaymentNode.metadata.personOneIsFTB;
        data.personTwoIsFTB = downpaymentNode.metadata.personTwoIsFTB;
        if (
            houseNode &&
            data.lastChangedDownpaymentProperty === DownpaymentAmount
        ) {
            // amount
            data.rate = (data.downpayment / houseNode.metadata.price) * 100;
        } else if (houseNode) {
            // rate
            data.downpayment =
                (houseNode.metadata.price * Number(data.rate)) / 100;
        }
    };

    processHouse = (houseNode, data, baselinePrefix) => {
        data.price = houseNode.metadata.price;
        data.start = houseNode.metadata["first payment"];

        data.isValid = true;
        data.houseId = `${
            baselinePrefix ? prependBaselinePrefix(houseNode.id) : houseNode.id
        }`;
    };

    componentDidMount() {
        if (this.props.onboardingData === 4) {
            this.setState({ ...mortgage1Metadata });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;
        // e.persist();
        // e.preventDefault()
        switch (id) {
            case "price":
                this.setState({ price: value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "rate":
                this.setState({ rate: value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "term":
                let rate = getMortgageRate(value, this.state);
                this.updateValue("rate", rate);
                this.setState({ "mortgage term": value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "cadence":
                this.setState({ cadence: value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "amortization":
                this.setState({ "amortization period": value }, () => {
                    this.handleCalculation(e);
                });
                break;
            case "downpayment":
                if (!this.state.isDownpayment) {
                    this.downpaymentCalculator(e, id);
                    this.handleCalculation(e);
                } else {
                    throwError(
                        "warning",
                        "Upstream downpayment node dependency detected.",
                        "Please edit the downpayment data there, or delete the node to make changes here."
                    );
                }
                break;
            case "downpaymentRate":
                if (!this.state.isDownpayment) {
                    this.downpaymentCalculator(e, id);
                    this.handleCalculation(e);
                } else {
                    throwError(
                        "warning",
                        "Upstream downpayment node dependency detected.",
                        "Please edit the downpayment data there, or delete the node to make changes here."
                    );
                }
                break;
            case "mlsNumber":
                this.setState({ mlsNumber: value });
                break;
            case "personOne":
            case "personTwo":
                this.setPerson(id, value);
                break;
            case "rrspContributionOne":
                this.setState({ rrspContributionOne: Math.max(value, 0) });
                break;
            case "rrspContributionTwo":
                this.setState({ rrspContributionTwo: Math.max(value, 0) });
                break;
            case "personOneIsFTB":
            case "personTwoIsFTB":
                this.setPersonIsFTB(id, value);
                break;
            default:
        }
    };

    setPerson(id, value) {
        if (id === "personOne") {
            const rrspMax =
                this.state.personOneIsFTB === "Yes" && value ? 35000 : 0;
            this.setState({
                rrspMaxOne: rrspMax,
                personOne: value,
            });
        } else if (id === "personTwo") {
            const rrspMax =
                this.state.personTwoIsFTB === "Yes" && value ? 35000 : 0;
            this.setState({
                rrspMaxTwo: rrspMax,
                personTwo: value,
            });
        }
    }

    setPersonIsFTB(id, value) {
        if (id === "personOneIsFTB") {
            const rrspMax = value === "Yes" && this.state.personOne ? 35000 : 0;
            this.setState({
                personOneIsFTB: value,
                rrspMaxOne: rrspMax,
            });
        } else if (id === "personTwoIsFTB") {
            const rrspMax = value === "Yes" && this.state.personTwo ? 35000 : 0;

            this.setState({
                personTwoIsFTB: value,
                rrspMaxTwo: rrspMax,
            });
        }
    }

    downpaymentCalculator = (e, id) => {
        e.preventDefault();
        const value = e.target.value;
        if (id === "downpayment") {
            let downpaymentRate = (parseInt(value) / this.state.price) * 100;
            if (!Number.isInteger(downpaymentRate)) {
                downpaymentRate = downpaymentRate.toFixed(2);
            }
            this.setState(
                {
                    downpayment: value,
                    inputtedDownpayment: value,
                    inputtedDownpaymentRate: downpaymentRate,
                    downpaymentRate: downpaymentRate,
                    lastChangedDownpaymentProperty: DownpaymentAmount,
                },
                () => {
                    this.handleCalculation(e);
                }
            );
        } else if (id === "downpaymentRate") {
            const rate = parseInt(value) / 100;
            const downpayment =
                rate > 100 ? this.state.price : this.state.price * rate;
            this.setState(
                {
                    downpayment: downpayment,
                    inputtedDownpayment: downpayment,
                    downpaymentRate: value,
                    inputtedDownpaymentRate: value,
                    lastChangedDownpaymentProperty: DownpaymentRate,
                },
                () => {
                    this.handleCalculation(e);
                }
            );
        }
    };

    updateValue = (id, value) => {
        switch (id) {
            case "rate":
                this.setState({ rate: value });
                break;
            case "payments":
                this.setState({
                    payments: value,
                    value: parseInt(value),
                });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleDate = (value) => {
        this.setState({ "first payment": value });
    };

    updateAppreciation = (checked) => {
        this.setState({ appreciation: checked });
    };

    onHandleSubmitValues = () => {
        const passedCheck = mortgageObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    updateValues = () => {
        const passedCheck = mortgageObject.checkInput(this.state);

        this.props.fillPartialValues(
            this.state,
            { },
            passedCheck
        );
    };

    static getMortgagePayments(stateObject) {
        // canadian fixed rate mortgages compound semi-annually
        let payments;
        let principal = stateObject["price"] - stateObject["downpayment"];
        let rate = stateObject["rate"];
        let amortization = parseInt(stateObject["amortization period"]);

        let monthlyPayment =
            (principal * ((1 + rate / 200) ** (1 / 6) - 1)) /
            (1 - ((1 + rate / 200) ** (1 / 6)) ** -(amortization * 12));
        let termInterest;

        switch (stateObject["cadence"]) {
            case "annually":
                payments = monthlyPayment * 12;
                termInterest = payments * amortization - principal;
                break;
            case "monthly":
                payments = monthlyPayment;
                termInterest = payments * amortization * 12 - principal;
                break;
            case "semi-monthly":
                payments = monthlyPayment / 2;
                termInterest = payments * amortization * 24 - principal;
                break;
            case "bi-weekly":
                payments = (monthlyPayment * 12) / 26;
                termInterest = payments * amortization * 26 - principal;
                break;
            case "weekly":
                payments = (monthlyPayment * 12) / 52;
                termInterest = payments * amortization * 52 - principal;
                break;
            case "one-time":
                payments = monthlyPayment * 12 * amortization;
                termInterest = payments - principal;
                break;
            default:
        }
        payments = Math.round(payments);
        return { payments: payments, termInterest: Math.round(termInterest) };
    }

    handleCalculation = (e) => {
        e.preventDefault();
        let downpaymentEntered = true; // only care about it for new mortgages
        if (
            this.state.isNew &&
            (this.state["downpayment"] === null ||
                this.state["downpayment"] === undefined)
        )
            downpaymentEntered = false;
        if (
            this.state["cadence"] !== (undefined || null) &&
            this.state["price"] !== (undefined || null) &&
            downpaymentEntered &&
            this.state["mortgage term"] !== (undefined || null) &&
            this.state["rate"] !== (undefined || null) &&
            this.state["amortization period"] !== (undefined || null)
        ) {
            let data = MortgageALT.getMortgagePayments(this.state);
            let payments = parseFloat(data.payments).toFixed(2);
            this.setState({ termInterest: data.termInterest });
            this.updateValue("payments", payments);
        } else {
            return null;
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    onCancelOptional = () => {
        this.setState({ ...this.state, showSettings: false });
    };

    handleMortgageTypeToggle = (e) => {
        const id = e.target.id;
        if (id === "newMortgage" && !this.state.isNew) {
            const data = this.handleDependencyDetection(this.props);
            this.setState(
                {
                    price: data.price,
                    downpayment: data.downpayment
                        ? data.downpayment
                        : this.state.downpayment,
                    isDownpayment: data.isDownpayment,
                    downpaymentRate: data.rate
                        ? data.rate
                        : this.state.downpaymentRate,
                    valid: data.isValid,
                    houseId: data.houseId,
                    "first payment": data.start,
                    isNew: true,
                    personOne: data.personOne || "",
                    personTwo: data.personTwo || "",
                    rrspContributionOne: data.rrspContributionOne || 0,
                    rrspContributionTwo: data.rrspContributionTwo || 0,
                    rrspMaxOne: data.rrspMaxOne || 0,
                    rrspMaxTwo: data.rrspMaxTwo || 0,
                    personOneIsFTB: data.personOneIsFTB || "No",
                    personTwoIsFTB: data.personTwoIsFTB || "No",
                },
                () => {
                    this.handleCalculation(new Event(null));
                }
            );
        } else if (id === "currentMortgage" && this.state.isNew) {
            this.setState(
                {
                    price: 0,
                    downpayment: 0,
                    downpaymentRate: 0,
                    "first payment": moment().format("YYYY-MM-DD"),
                    isNew: false,
                },
                () => {
                    this.handleCalculation(new Event(null));
                }
            );
        }
    };

    _onHandleRRSPSettings = () => {
        this.setState({ showRRSPSettings: !this.state.showRRSPSettings });
    };

    _getAdvancedSettingsMenu() {
        const { showRRSPSettings } = this.state;
        return (
            <EventInputs>
                <button
                    onClick={this.onCancelOptional}
                    className="BackToRequired"
                >
                    Back
                </button>
                <ModalRow>
                    <div className="OptionalLabel">Optional settings</div>
                </ModalRow>
                <ModalRow>
                    <div
                        className="RentLabel"
                        onClick={this._onHandleRRSPSettings}
                        id="showRrspAsDP"
                    >
                        <img
                            alt="alt"
                            className="selectedIcon"
                            src={showRRSPSettings ? dropdownSvg : expandSvg}
                            onClick={this._onHandleRRSPSettings}
                            id="showRrspAsDP"
                        />
                        Use RRSP as Down Payment
                    </div>
                </ModalRow>
                {showRRSPSettings ? this._getRRSPSettingsMenu() : <></>}
            </EventInputs>
        );
    }

    _getRRSPSettingsMenu() {
        const {
            people,
            personOne,
            personTwo,
            personOneIsFTB,
            personTwoIsFTB,
            rrspMaxOne,
            rrspMaxTwo,
            isDownpayment,
        } = this.state;
        const peopleListOne = Object.keys(people).filter((person) => {
            return person !== personTwo;
        });
        const peopleListTwo = Object.keys(people).filter((person) => {
            return person !== personOne;
        });

        const personOneMax = rrspMaxOne > 0 ? "35,000" : "0";
        const personTwoMax = rrspMaxTwo > 0 ? "35,000" : "0";

        return isDownpayment ? (
            <div className="modalBox">
                Please configure RRSP as Down Payment in the upstream Down
                Payment event.
            </div>
        ) : (
            <div className="modalBox">
                <div className="modalBoxLabel">RRSP as Down Payment</div>

                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Person");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">Person 1:</div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personOne}
                        options={peopleListOne}
                        className="SharedSelect"
                        id="personOne"
                        label="Person 1"
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Person");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">Person 2:</div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personTwo}
                        options={peopleListTwo}
                        className="SharedSelect"
                        id="personTwo"
                        label="Person 2"
                    />
                </div>

                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("First Time Buyer");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personOne ? personOne : "Person 1"} Is First Time
                        Buyer:
                    </div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personOneIsFTB}
                        options={["Yes", "No"]}
                        className="SharedSelect"
                        id="personOneIsFTB"
                        label="Person 1"
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("First Time Buyer");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personTwo ? personTwo : "Person 2"} Is First Time
                        Buyer:
                    </div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personTwoIsFTB}
                        options={["Yes", "No"]}
                        className="SharedSelect"
                        id="personTwoIsFTB"
                        label="Person 2"
                    />
                </div>

                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Contribution");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personOne ? personOne : "Person 1"} Max Contribution:
                    </div>
                    <div className="ExpenseAmountContainerLeft">
                        <Value
                            label={`No more than ${personOneMax}`}
                            onChangeInput={this.onChangeInput}
                            value={this.state.rrspContributionOne || 0}
                            id="rrspContributionOne"
                        />
                    </div>
                </div>
                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Contribution");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personTwo ? personTwo : "Person 2"} Max Contribution:
                    </div>
                    <div className="ExpenseAmountContainerLeft">
                        <Value
                            label={`No more than ${personTwoMax}`}
                            onChangeInput={this.onChangeInput}
                            value={this.state.rrspContributionTwo || 0}
                            id="rrspContributionTwo"
                        />
                    </div>
                </div>
            </div>
        );
    }

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "Person":
                data = {
                    title: "RRSPs and You",
                    content:
                        "Up to two partners may contribute to the down payment on a home with their RRSP savings.",
                    subContent:
                        "Your 'Person' and 'Me' nodes will only show up here if they have a corrresponding RRSP event",
                    link: null,
                };
                break;
            case "First Time Buyer":
                data = {
                    title: "First Time Buyers",
                    content:
                        "You can only use your RRSP savings as a down payment if you are buying a home for the first time.",
                    subContent:
                        "First time buyers can contribute up to $35,000 each.",
                    link: null,
                };
                break;
            case "Contribution":
                data = {
                    title: "Contribution Amounts",
                    content:
                        "Enter the amount you would like to contribute to the down payment from your RRSP savings.",
                    subContent:
                        "If your RRSP balance is less than the entered amount at time of purchase, only an amount equal to the balance will be put towards your down payment.",
                    link: null,
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    throwDownpaymentError = () => {
        if (this.state.isDownpayment)
            throwError(
                "warning",
                "Upstream Downpayment Event",
                "Please edit the downpayment data there, or delete the event to make changes here."
            );
    };

    getStandardInputs = () => {
        const {
            name,
            cadence,
            description,
            rating,
            onHoverStar,
            downpaymentRate,
            downpayment,
            rate,
            isNew,
            price,
            showSettings,
            isDownpayment,
        } = this.state;

        return (
            <EventInputs>
                <EventHeader
                    name={name}
                    eventType={"Mortgage (Canada)"}
                    onChangeInput={this.onChangeInput}
                    image={mortgageObject.svg()}
                />

                <ModalRow onMouseEnter={this.onHandleMouseEnter}>
                    <Description
                        description={description}
                        onChangeInput={this.onChangeInput}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="currentMortgage"
                        isSelected={!isNew}
                        onClick={this.handleMortgageTypeToggle}
                        title="Current Mortgage"
                    />
                    <ToggledContainerHalf
                        id="newMortgage"
                        isSelected={isNew}
                        onClick={this.handleMortgageTypeToggle}
                        title="New Mortgage"
                    />
                </ToggledContainer>

                {!isNew && (
                    <ModalRow twoInputs onMouseEnter={this.onHandleMouseEnter}>
                        <ModalRowTwoThirds>
                            <RequiredStar />
                            <SingleCalendar
                                onHandleDate={this.onHandleDate}
                                date={this.state["first payment"]}
                                useFullWidth
                                title={"Start Date"}
                            />
                        </ModalRowTwoThirds>
                        <ModalRowOneThird>
                            <div className="slightlyLoweredAmountContainer">
                                <RequiredStar />
                                <Value
                                    label="Principal Amount"
                                    onChangeInput={this.onChangeInput}
                                    value={price}
                                    id="price"
                                />
                            </div>
                        </ModalRowOneThird>
                    </ModalRow>
                )}

                {isNew && (
                    <ModalRow twoInputs>
                        <ModalRowHalf
                            onMouseEnter={this.onHandleMouseEnter}
                            onClick={this.throwDownpaymentError}
                        >
                            <RequiredStar />
                            <Rate
                                value={downpaymentRate}
                                label="Downpayment Rate"
                                className="mlsInput"
                                id="downpaymentRate"
                                onChangeInput={this.onChangeInput}
                                sign={true}
                                disabled={isDownpayment}
                            />
                        </ModalRowHalf>
                        <ModalRowHalf
                            onMouseEnter={this.onHandleMouseEnter}
                            onClick={this.throwDownpaymentError}
                        >
                            <RequiredStar />
                            <Value
                                label="Downpayment Amount"
                                onChangeInput={this.onChangeInput}
                                value={downpayment}
                                id="downpayment"
                                disabled={isDownpayment}
                            />
                        </ModalRowHalf>
                    </ModalRow>
                )}
                <ModalRow twoInputs>
                    <ModalRowTwoThirds onMouseEnter={this.onHandleMouseEnter}>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={this.state["mortgage term"]}
                            options={terms}
                            className="termsContainer"
                            id="term"
                            label="Select Term"
                        />
                    </ModalRowTwoThirds>
                    <ModalRowOneThird onMouseEnter={this.onHandleMouseEnter}>
                        <RequiredStar />
                        <Rate
                            value={rate}
                            label="Rate"
                            className="mlsInput"
                            id="rate"
                            onChangeInput={this.onChangeInput}
                            sign={true}
                        />
                    </ModalRowOneThird>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf onMouseEnter={this.onHandleMouseEnter}>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={cadence}
                            options={cadenceOptions}
                            className="select-dropdown"
                            id="cadence"
                            label="Payment Frequency"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf onMouseEnter={this.onHandleMouseEnter}>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={this.state["amortization period"]}
                            options={
                                isNew
                                    ? amortization
                                    : currentMortgageAmortization
                            }
                            className="select-dropdown"
                            id="amortization"
                            label={`Select Amortization Period`}
                            onBlur={this.handleCalculation}
                        />
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow onMouseEnter={this.onHandleMouseEnter}>
                    <div className="loanPaymentsContainer">
                        <div className="paymentsAmountContainer">
                            <div className="paymentsLabel">
                                Your approximate payment <br />
                                <span className="cadenceHolder">
                                    {this.state.cadence
                                        ? this.state.cadence
                                        : "frequency payment"}
                                </span>{" "}
                                will be
                            </div>
                            <div className="paymentsValue">
                                ${" "}
                                {this.state.payments
                                    ? this.state.payments
                                    : "0.00"}
                            </div>
                        </div>

                        <div className="paymentsSubLabel">
                            You`&apos;`ll pay{" "}
                            {this.state.termInterest
                                ? "$" + this.state.termInterest + " "
                                : "$0.00 "}
                            in interest after{" "}
                            {this.state["amortization period"]
                                ? this.state["amortization period"] + " "
                                : "0 years"}
                            .
                            <br /> Term interest is the total interest paid
                            throughout the duration of the loan.
                        </div>
                    </div>
                </ModalRow>

                <ModalRow>
                    <StarsRating
                        onHoverStar={onHoverStar}
                        onHandleStarHover={this.onHandleStarHover}
                        rating={rating}
                        onChangeInput={this.onChangeInput}
                        onMouseLeave={this.onMouseLeave}
                    />
                </ModalRow>
                <ModalRow>
                    <div className="RequiredField">* Required Field</div>
                </ModalRow>
                <ModalRow>
                    <AdvancedSettingsToggle
                        showSettings={showSettings}
                        toggleAdvancedSettings={this.onHandleAdvanceSetting}
                    />
                </ModalRow>
            </EventInputs>
        );
    };

    render() {
        const { edit } = this.props;
        const passedCheck = mortgageObject.checkInput(this.state);
        const onboarding = this.props.onboardingData === 4;
        const { showSettings } = this.state;

        return (
            <EventInputModal>
                {showSettings
                    ? this._getAdvancedSettingsMenu()
                    : this.getStandardInputs()}
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    onboarding={onboarding}
                />
            </EventInputModal>
        );
    }
}

export default MortgageALT;

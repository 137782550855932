/* eslint-disable @typescript-eslint/no-unused-vars */
// **************************************** READ ME ****************************************
// *** I disabled eslint because it was complaining that lines 9-37 imports were unused. ***
// *** However, removing those lines causes the frontend to crash.                       ***
// **************************************** READ ME ****************************************
import React from "react";
import closeSvg from "../../Assets/close.svg";
import "./EventsModal.css";
import {
    PropertyTransferTax,
    House,
    PropertyValue,
    Person,
    Pension,
    Bank,
    ClosingCosts,
    Downpayment,
    Mortgage,
    HomeMaintenance,
    Rent,
    Income,
    Decision,
    Goal,
    Modifier,
    IncomeTax,
    Investment,
    RRSP,
    BankPlaid,
    PropertyResale,
    Employee,
    LumpSumPayments,
    RenewalRefinance,
    RealEstateFee,
    MortgageInsurance,
    CapitalGains,
    ShortTermRental,
} from "../../helpers/constants";
import RealEstateImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";
import { events } from "Components/InputContainer/Master Registry";

export const EventsModal = ({
    children,
    onHandleClose,
    preventClose,
    login,
    pricing,
    plan,
    landingPage,
    confirm,
    selectedEvent,
    hoveredInput,
    subscription,
    clickAway = false,
}) => {
    let design = "ContentContainer";
    if (login) {
        design = "loginContainer";
    } else if (plan) {
        design = "planContainer";
    } else if (pricing) {
        design = "pricingContainer";
    } else if (landingPage) {
        design = "landingPageContainer";
    } else if (confirm) {
        design = "confirmContainer";
    } else if (subscription) {
        design = "subscriptionContainer";
    } else {
        design = "ContentContainer";
    }

    let contentText;
    let sideImage = RealEstateImage;
    let validType = false;

    events.forEach((event) => {
        if (event.constant() === selectedEvent) {
            contentText = event.indexText();
            sideImage = event.sideModal();
            validType = true;
        }
    });
    const showSideModal = selectedEvent && validType;

    if (showSideModal) {
        return (
            <div
                className={
                    clickAway ? "modalClickAwayContainer" : "ModalContainer"
                }
                tabIndex="-1"
                style={{outline:'none'}}
            >
                <div className="ModalWiderContent">
                    <div className="ModalLeftContent">
                        <div className="ModalInnerContainer">
                            <img
                                alt="alt"
                                className="sideModalImg"
                                src={sideImage}
                            />
                            {!hoveredInput && (
                                <div className="sideModalDefaultText">
                                    {contentText}
                                </div>
                            )}
                            {hoveredInput && (
                                <div>
                                    <div className="sideModalTitle">
                                        {hoveredInput.title}
                                    </div>
                                    <div className="sideModalContent">
                                        {hoveredInput.content}{" "}
                                    </div>
                                    <div className="sideModalContent">
                                        {hoveredInput.subContent}
                                    </div>
                                    {hoveredInput.link && (
                                        <div
                                            onClick={() =>
                                                window.open(hoveredInput.link)
                                            }
                                            className="sideContentLearnMore"
                                        >
                                            Learn more
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="ModalRightContent">
                        {!preventClose && (
                            <img
                                alt="alt"
                                src={closeSvg}
                                className="CloseModal"
                                onClick={onHandleClose}
                            />
                        )}

                        {children}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={
                    clickAway ? "modalClickAwayContainer" : "ModalContainer"
                }
                tabIndex="-1"
                style={{outline:'none'}}
            >
                <div className={design}>
                    {!preventClose && (
                        <img
                            alt="alt"
                            src={closeSvg}
                            className="CloseModal"
                            onClick={onHandleClose}
                        />
                    )}

                    {children}
                </div>
            </div>
        );
    }
};
import React, { Component } from "react";
import "./InputContainer.css";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    InputButtons,
    EventInputModal,
    EventInputs,
    EventHeader,
} from "./Components";
import { startObject } from "Components/Registry/Start";

class StartInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: startObject.name(),
            type: startObject.constant(),
            applyInflation: false,
            tag: `@${startObject.name()}`,
            baseline: false,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (startObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    updateValues = () => {
        const passedCheck = startObject.checkInput(this.state);
        this.props.fillPartialValues(this.state, {}, passedCheck);
    };

    toggleInflation = () => {
        this.setState({ applyInflation: !this.state.applyInflation });
    };

    onHandleDate = (value, type) => {
        if (type === "start") {
            this.setState({ birthday: value });
        }
    };

    toggleDisability = () => {
        this.setState({ hasDisability: !this.state.hasDisability });
    };

    render() {
        const passedCheck = startObject.checkInput(this.state);
        const { edit } = this.props;
        const { name } = this.state;

        let maxOffset = 100;
        let thisYear = new Date().getFullYear();
        let allYears = [];
        for (let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x);
        }

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        eventType={startObject.name()}
                        name={name}
                        onChangeInput={this.onChangeInput}
                        image={startObject.svg()}
                    />
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default StartInput;

import type { MouseEvent } from "react";
import type { EventManager } from "Events";
import { useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import {
    addThreadsToSelectedThreads,
    removeThreadsFromSelectedThreads,
} from "actions/scenarioViewSelectedThreadsActions";
import { highlightThread } from "actions/scenariov2Actions";
import { zoomThread } from "actions/zoomThreadActions";

// Image assets
import checkboxOff from "Assets/_budgetIcons/checkbox-off.svg";
import checkboxOn from "Assets/_budgetIcons/checkbox-on.svg";
import ScenarioArrowIcon from "Assets/scenarioIcons/collapse_icon.svg";
import {
    getDisplayValue,
    getMontlyOrCumulativeText,
} from "Components/ScenarioView/helpers/getDisplayValue";
import moreUnSelectedSvg from "Assets/more_unselected.svg";
import ThreadOptions from "./ThreadOptions";
import { exportScenarioCSV } from "helpers/exportScenarioCSV";

interface ScenarioViewThreadProps {
    threadId: string;
}

export function ScenarioViewThread({ threadId }: ScenarioViewThreadProps) {
    const {
        calculatedThread,
        focus,
        loadedScenario,
        isHighlighted,
        manager,
        monthlyCumulative,
        accountLedgerId,
        selectedLedgerAverage,
    } = useAppSelector((state) => {
        const focus = state.scenario?.focus;
        const manager = state.scenario?.manager as EventManager;
        const loadedScenario = state.scenario?.loadedScenario;

        // Find the display value
        const calculatedThread = state.calculatedThreads[threadId];
        const monthlyCumulative = state.threadsList.monthlyCumulative;
        const accountLedgerId = state.threadsList.accountLedgerId;
        const finalAccountLedgerId =
            monthlyCumulative === "monthly"
                ? accountLedgerId
                : `Cumulative-${accountLedgerId}`;
        const selectedLedgerAverage =
            calculatedThread?.ledgersDataAverages?.[finalAccountLedgerId]
                ?._All || 0;

        const isHighlighted =
            state.scenario?.highlightedThread?.signature === threadId;

        return {
            calculatedThread,
            focus,
            loadedScenario,
            isHighlighted,
            manager,
            monthlyCumulative,
            accountLedgerId,
            selectedLedgerAverage,
        };
    }, shallowEqual);
    const selectedThreads = useAppSelector(
        (state) => state.scenarioViewSelectedThreads.selectedThreads || [],
        shallowEqual
    );

    const ledgerMetadata = useAppSelector((state) => {
        const ledgersMetadata = state?.allAccountLedgers?.ledgersMetadata;

        return ledgersMetadata[accountLedgerId];
    }, shallowEqual);

    const allLedgersMetadata = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata,
        shallowEqual
    );

    const dispatch = useAppDispatch();

    const isInSelectedThreads = useMemo(
        () => selectedThreads.includes(threadId),
        [selectedThreads, threadId]
    );

    const showActiveThreadIndicator = useMemo(() => {
        let _showActiveThreadIndicator = false;
        if (focus && manager) {
            const threadNodes = manager.getNodesFromThread(threadId) || [];
            _showActiveThreadIndicator = manager.isNodeInThread(
                focus,
                threadNodes
            );
        }
        return _showActiveThreadIndicator;
    }, [focus, manager, threadId]);

    const handleHighlightThread = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (isHighlighted) return;

        dispatch(highlightThread(threadId));
    };

    const handleSelectThread = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isInSelectedThreads) {
            dispatch(removeThreadsFromSelectedThreads([threadId]));
        } else {
            dispatch(addThreadsToSelectedThreads([threadId]));
        }
    };

    const handleZoomThread = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(highlightThread(threadId));
        dispatch(zoomThread(threadId));
    };

    const [showDropdown, setShowDropdown] = useState(false);

    const handleThreadOptions = (e?: MouseEvent<HTMLImageElement>) => {
        e?.preventDefault();

        setShowDropdown((prevShowDropdown) => !prevShowDropdown);
    };

    const handleExport = () => {
        exportScenarioCSV(
            calculatedThread.accountData,
            allLedgersMetadata,
            `${loadedScenario?.name} - ${calculatedThread?.option}`
        );
        setShowDropdown((prevShowDropdown) => !prevShowDropdown);
    };

    // TODO/DENTON "ScenarioName: {calculatedThread.option} is TEMPORARY FIX, should be pulling .name field but it's not being set"
    return (
        <div className="Scenario-Container">
            <div className="Scenario-Active-Indicator-Wrapper">
                {showActiveThreadIndicator ? (
                    <div className="Scenario-Active-Indicator" />
                ) : (
                    <div className="Scenario-Inactive-Indicator" />
                )}
            </div>
            <div
                className={`Scenario-Background ${
                    isHighlighted && "Scenario-Background--highlighted"
                }`}
                onClick={handleHighlightThread}
            >
                <div
                    style={{
                        background: `${calculatedThread?.color}`,
                        width: "2%",
                        borderRadius: "2px 0 0 2px",
                    }}
                />
                <div
                    className={`Scenario ${
                        isHighlighted && "Scenario--highlighted"
                    }`}
                >
                    <div className="ScenarioInfoArrowWrapper">
                        <div className="ScenarioInfoWrapper">
                            <div className="ScenarioHeader">
                                <img
                                    alt="alt"
                                    // id={this.props.data.id}
                                    onClick={handleSelectThread}
                                    src={
                                        isInSelectedThreads
                                            ? checkboxOn
                                            : checkboxOff
                                    }
                                />
                                <span className="ScenarioName">
                                    {calculatedThread?.option}
                                </span>
                                <img
                                    alt="alt"
                                    src={moreUnSelectedSvg}
                                    onClick={handleThreadOptions}
                                    className="ScenarioCSVButton"
                                />
                                {showDropdown && (
                                    <ThreadOptions
                                        onHandleThreadOptions={
                                            handleThreadOptions
                                        }
                                        onHandleExport={handleExport}
                                    />
                                )}
                            </div>
                            <div className="ScenarioDetails">
                                {ledgerMetadata && (
                                    <div className="ScenarioMonthly">
                                        <span className="monthly">
                                            {getDisplayValue(
                                                ledgerMetadata.unit,
                                                selectedLedgerAverage
                                            )}
                                        </span>
                                        <span className="monthlyText">
                                            {getMontlyOrCumulativeText(
                                                ledgerMetadata,
                                                monthlyCumulative
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            className="ScenarioArrowWrapper"
                            onClick={handleZoomThread}
                        >
                            <div className="ScenarioArrow">
                                <img
                                    className="ScenarioArrowImg"
                                    src={ScenarioArrowIcon}
                                    alt="Focus Thread"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

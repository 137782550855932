import type { MouseEvent } from "react";
import type { EventManager } from "Events";
import { useState } from "react";
import { memo } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import { resetChartGraphGroupNodesMode } from "actions/chartGraphGroupNodesModeActions";
import { unzoomThread } from "actions/zoomThreadActions";
import { ScenarioViewEvent } from "./ScenarioViewEvent";
import ThreadOptions from "./ThreadOptions";
import { ZoomedEvent } from "./ZoomedEvent";
import { exportScenarioCSV } from "helpers/exportScenarioCSV";
import { highlightThread } from "actions/scenariov2Actions";
import { zoomThread } from "actions/zoomThreadActions";

// Image assets
import BackArrowOrangeIcon from "Assets/_budgetIcons/back_orange.svg";
import moreUnSelectedSvg from "Assets/more_unselected.svg";
import { getDisplayValue } from "../helpers/getDisplayValue";

interface ZoomedThreadProps {
    threadId: string;
}

function _ZoomedThread({ threadId }: ZoomedThreadProps) {
    const [showDropdown, setShowDropdown] = useState(false);

    const {
        manager,
        calculatedThread,
        monthlyCumulative,
        ledgersMetadata,
        accountLedgerId,
        selectedLedgerAverage,
        threadColor,
        loadedScenario,
        allThreads,
        totalThreadsLength,
        selectedThreads,
        selectedThreadsLength,
        indexOfSelectedThread,
    } = useAppSelector((state) => {
        const manager = state?.scenario?.manager as EventManager;
        const calculatedThreads = state.calculatedThreads;
        const calculatedThread = calculatedThreads[threadId];
        const threadColor = calculatedThread?.color ?? "#313131";
        const ledgersMetadata = state.allAccountLedgers.ledgersMetadata;
        const loadedScenario = state.scenario?.loadedScenario;
        const monthlyCumulative = state.threadsList.monthlyCumulative;
        const accountLedgerId = state.threadsList.accountLedgerId;
        const finalAccountLedgerId =
            monthlyCumulative === "monthly"
                ? accountLedgerId
                : `Cumulative-${accountLedgerId}`;
        const selectedLedgerAverage =
            calculatedThread?.ledgersDataAverages?.[finalAccountLedgerId]
                ?._All || 0;

        const allThreads = Object.keys(state.calculatedThreads);
        const selectedThreads =
            state.scenarioViewSelectedThreads.selectedThreads;
        const totalThreadsLength = allThreads?.length;
        const selectedThreadsLength = selectedThreads?.length;
        const indexOfSelectedThread =
            selectedThreadsLength > 0
                ? selectedThreads?.indexOf(threadId)
                : allThreads?.indexOf(threadId);

        return {
            manager,
            calculatedThread,
            monthlyCumulative,
            ledgersMetadata,
            accountLedgerId,
            selectedLedgerAverage,
            threadColor,
            loadedScenario,
            allThreads,
            totalThreadsLength,
            selectedThreads,
            selectedThreadsLength,
            indexOfSelectedThread,
        };
    }, shallowEqual);

    /**
     * This selector is separated because its array is built up each time, meaning shallowEqual will always return false if returned in an object.
     * Thus, we want to shallowEqual itself. We don't want to deepEqual since something like calculatedThread is HUGE to compare.
     */
    const eventIds = useAppSelector((state) => {
        // Get events in this thread
        const manager = state?.scenario?.manager as EventManager;
        const threadEvents = manager?.getNodesFromThread(threadId, true) || [];
        const allScenarioNodes: string[] = manager?.getSortedEventIds() ?? []; // NOTE: Using manager's threadEvents only results in a phantom "Baseline" node
        const eventIds = allScenarioNodes?.filter((eventId) =>
            manager?.isNodeInThread(eventId, threadEvents)
        );

        return eventIds;
    }, shallowEqual);

    const zoomedEventId = useAppSelector(
        (state) => state?.scenario?.zoomedEventId
    );

    const dispatch = useAppDispatch();

    const handleGoBack = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        dispatch(resetChartGraphGroupNodesMode());

        dispatch(unzoomThread());
    };

    const handleThreadOptions = (e?: MouseEvent<HTMLImageElement>) => {
        e?.preventDefault();

        setShowDropdown((prevShowDropdown) => !prevShowDropdown);
    };

    const handleExport = () => {
        exportScenarioCSV(
            calculatedThread.accountData,
            ledgersMetadata,
            `${loadedScenario?.name} - ${calculatedThread?.option}`
        );
        setShowDropdown((prevShowDropdown) => !prevShowDropdown);
    };

    const handleNavThread = (direction) => {
        let chosenThreadId = "";

        if (direction === "up") {
            const indexOfNewThread = indexOfSelectedThread + 1;
            if (selectedThreadsLength > 0) {
                chosenThreadId = selectedThreads[indexOfNewThread];
                if (!chosenThreadId) chosenThreadId = selectedThreads[0];
            } else {
                chosenThreadId = allThreads[indexOfNewThread];
                if (!chosenThreadId) chosenThreadId = allThreads[0];
            }
        } else {
            const indexOfNewThread = indexOfSelectedThread - 1;
            if (selectedThreadsLength > 0) {
                chosenThreadId = selectedThreads[indexOfNewThread];
                if (!chosenThreadId)
                    chosenThreadId = selectedThreads[selectedThreadsLength - 1];
            } else {
                chosenThreadId = allThreads[indexOfNewThread];
                if (!chosenThreadId)
                    chosenThreadId = allThreads[totalThreadsLength - 1];
            }
        }

        dispatch(zoomThread(chosenThreadId));
        manager?.calculate(false);
        dispatch(highlightThread(chosenThreadId));
    };

    if (zoomedEventId) {
        return <ZoomedEvent />;
    }

    return (
        <div className="ZoomedThreadContainer">
            <div className="ZoomedThreadContent">
                <div className="ZoomedThreadBackWrapper" onClick={handleGoBack}>
                    <img
                        className="ZoomedThreadBackIcon"
                        src={BackArrowOrangeIcon}
                        alt="back"
                    />
                    <span className="ZoomedThreadBackText">
                        Back to all threads
                    </span>
                </div>
                <span className="ZoomedThreadIndex">
                    {selectedThreadsLength > 0
                        ? `Displaying ${
                              indexOfSelectedThread + 1
                          } of ${selectedThreadsLength} Selected Threads`
                        : `Displaying ${
                              indexOfSelectedThread + 1
                          } of ${totalThreadsLength} Total Threads`}
                </span>
                <div className="ZoomedThreadHeaderEventListColorBarWrapper">
                    <div className="ZoomedThreadHeaderEventListWrapper">
                        <div className="ZoomedThreadHeaderWrapper">
                            <div
                                className="ZoomedThreadColorBar"
                                style={{
                                    backgroundColor:
                                        calculatedThread?.color ?? "#313131",
                                }}
                            />
                            <div className="ZoomedThreadHeader">
                                <div className="ZoomedThreadHeader__leftContainer">
                                    <span className="ZoomedThreadHeader__info">
                                        i
                                    </span>
                                    <div className="ZoomedThreadHeader__optionsButtonContainer">
                                        <img
                                            alt="alt"
                                            src={moreUnSelectedSvg}
                                            onClick={handleThreadOptions}
                                            className="threadParent"
                                        />
                                        {showDropdown && (
                                            <ThreadOptions
                                                onHandleThreadOptions={
                                                    handleThreadOptions
                                                }
                                                onHandleExport={handleExport}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="ZoomedThreadHeader__scenarioContentContainer">
                                    <span className="ZoomedThreadHeader__scenarioName">
                                        {calculatedThread?.option ?? ""}
                                    </span>
                                    <span className="ZoomedThreadHeader__scenarioValue">
                                        {getDisplayValue(
                                            ledgersMetadata?.[accountLedgerId]
                                                ?.unit,
                                            selectedLedgerAverage
                                        )}
                                    </span>
                                    <span className="ZoomedThreadHeader__scenarioDetail">
                                        {monthlyCumulative === "monthly"
                                            ? ledgersMetadata[accountLedgerId]
                                                  .monthlyCumulativeText.monthly
                                            : ledgersMetadata[accountLedgerId]
                                                  .monthlyCumulativeText
                                                  .cumulative}
                                    </span>
                                    <span className="ZoomedThreadHeader__scenarioDetail">{`${
                                        monthlyCumulative === "cumulative"
                                            ? "Cumulative "
                                            : ""
                                    }${
                                        ledgersMetadata?.[accountLedgerId]
                                            ?.name ?? ""
                                    }`}</span>
                                </div>
                                <div className="ZoomedThreadHeader__navContainer">
                                    <button
                                        className="ZoomedThreadHeader__navButton"
                                        onClick={() => handleNavThread("up")}
                                    >
                                        ^
                                    </button>
                                    <button
                                        className="ZoomedThreadHeader__navButton ZoomedThreadHeader__navButton--down"
                                        onClick={() => handleNavThread("down")}
                                    >
                                        ^
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="ZoomedThreadEventListWrapper">
                            <ul className="ZoomedThreadEventList">
                                {eventIds.map((eventId) => (
                                    <ScenarioViewEvent
                                        key={eventId}
                                        eventId={eventId}
                                        threadId={threadId}
                                        threadColor={threadColor}
                                        eventIds={eventIds}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ZoomedThread = memo(_ZoomedThread);

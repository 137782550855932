import { ScenarioSchema } from "../reducers/typesSchema/ScenarioSchema";
import EventManager from "../Events/eventManager";
import store from "store";
import { RESET_SCENARIO_VIEW_SELECTED_THREADS } from "./scenarioViewSelectedThreadsActions";
import {
    CLOSE_POP_UP_OPTION,
    HOVERED_INPUT,
    LOAD_SCENARIO,
    UPDATE_NODE_CLEAR_FOCUS,
} from "./types";
import { resetChartGraphGroupNodesMode } from "./chartGraphGroupNodesModeActions";
import { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import { getUserScenarios, hasPermission, requestHeader } from "./scenario";
import axios from "axios";
import { config } from "config";
import * as Sentry from "@sentry/browser";
import Swal from "sweetalert2";

export const loadScenario = (
    scenario: ScenarioSchema,
    manager: EventManager
) => {
    if (!scenario || !scenario?.data) return;

    manager.importData(scenario.data, false);

    let expiredCount: EventStructure[] = [];
    let outdatedCount: EventStructure[] = [];

    if (scenario.data.nodes) {
        expiredCount = scenario.data.nodes.filter((node) => {
            const nodeValue = manager._findEvent(node.id);
            return nodeValue.isNodeExpired() && !nodeValue.isBaseline();
        });
    }

    if (scenario.data.nodes) {
        outdatedCount = scenario.data.nodes.filter((node) => {
            const nodeValue = manager._findEvent(node.id);
            return nodeValue.isNodeOutdated() && !nodeValue.isBaseline();
        });
    }

    store.dispatch({
        type: RESET_SCENARIO_VIEW_SELECTED_THREADS,
    });
    store.dispatch(resetChartGraphGroupNodesMode());
    store.dispatch({
        type: LOAD_SCENARIO,
        payload: scenario,
        expiredNode: expiredCount,
        outdatedNode: outdatedCount,
    });

    // Used to allow the user to return to their last used scenario when clicking into the Scenario Canvas
    // (Along with the many other instances of this in the codebase, mainly scenario.js)
    localStorage.setItem("loadedScenario", JSON.stringify(scenario));
};

export const updateEventAndScenario = async (
    updatedEvent: EventStructure,
    loadedScenario: ScenarioSchema,
    manager: EventManager
) => {
    if (loadedScenario) {
        const header = requestHeader();
        const { email, account } = header.headers;

        const newData = loadedScenario.data.nodes.map((data) => {
            if (data.id === updatedEvent.id)
                return { ...data, ...updatedEvent };
            return { ...data };
        });

        const scenario: ScenarioSchema = {
            ...loadedScenario,
            data: {
                version: loadedScenario.data.version,
                nodes: newData,
                root: loadedScenario.data.root,
                favouriteAccounts: { ...loadedScenario.data.favouriteAccounts },
                canvasCardIds: loadedScenario?.data?.canvasCardIds
                    ? [...loadedScenario?.data?.canvasCardIds]
                    : [],
            },
        };

        if (hasPermission("edit", loadedScenario, email, account)) {
            try {
                // Update current scenario in database
                await axios.put(`${config.host}/scenario`, scenario, header);

                // Update current scenario in redux
                store.dispatch({
                    type: UPDATE_NODE_CLEAR_FOCUS,
                    payload: scenario,
                });
                loadScenario(scenario, manager);

                // Get newly updated scenario(s) from the database
                store.dispatch(getUserScenarios());
            } catch (err) {
                Sentry.withScope((scope) => {
                    scope.setTag("Updated event", updatedEvent as any);
                    scope.setTag("Loaded scenario", loadedScenario as any);
                    scope.setLevel(Sentry.Severity.Error);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err updateNode");
            }
        } else {
            loadScenario(scenario, manager);
            store.dispatch({
                type: UPDATE_NODE_CLEAR_FOCUS,
                payload: scenario,
            });
            Swal.fire({
                title: "Warning",
                text: "You are editing a view-only scenario. Changes will not persist.",
            });
        }
    }
};

export const updateScenario = async (
    newScenario: ScenarioSchema,
    loadedScenario: ScenarioSchema,
    manager: EventManager
) => {
    if (loadedScenario) {
        const header = requestHeader();
        const { email, account } = header.headers;

        if (hasPermission("edit", loadedScenario, email, account)) {
            try {
                // Update current scenario in database
                await axios.put(`${config.host}/scenario`, newScenario, header);

                // Update current scenario in redux
                store.dispatch({
                    type: UPDATE_NODE_CLEAR_FOCUS,
                    payload: newScenario,
                });
                loadScenario(newScenario, manager);

                // Get newly updated scenario(s) from the database
                store.dispatch(getUserScenarios());
            } catch (err) {
                Sentry.withScope((scope) => {
                    // scope.setTag("Updated event", updatedEvent as any);
                    scope.setTag("Loaded scenario", loadedScenario as any);
                    scope.setLevel(Sentry.Severity.Error);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err updateNode");
            }
        } else {
            loadScenario(newScenario, manager);
            store.dispatch({
                type: UPDATE_NODE_CLEAR_FOCUS,
                payload: newScenario,
            });
            Swal.fire({
                title: "Warning",
                text: "You are editing a view-only scenario. Changes will not persist.",
            });
        }
    }
};

export const handleCloseModal = () => {
    // Close pop up modal
    store.dispatch({
        type: CLOSE_POP_UP_OPTION,
    });

    // Clear focused input
    store.dispatch({
        type: HOVERED_INPUT,
        payload: null,
    });
};

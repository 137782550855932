import type { CustomAccountsSchema } from "./typesSchema/customAccountsSchema";
import { SHOW_CUSTOM_ACCOUNT_MODAL } from "actions/customAccountActions";

const initialState: CustomAccountsSchema = {
    modalOpen: false,
    sticky: false,
    initialTopLevelAccount: {
        name: "",
        ids: [],
    },
    initialSubAccount: {
        name: "",
        ids: [],
    },
    initialLeafAccount: {
        name: "",
        ids: [],
    },
};

export default function customAccounts(
    state: CustomAccountsSchema = initialState,
    action: { type: string; payload?: any }
): CustomAccountsSchema {
    switch (action.type) {
        case SHOW_CUSTOM_ACCOUNT_MODAL:
            return {
                ...state,
                modalOpen: action.payload.modalOpen,
                sticky: action.payload.sticky,
                initialTopLevelAccount: action.payload.initialTopLevelAccount,
                initialSubAccount: action.payload.initialSubAccount,
                initialLeafAccount: action.payload.initialLeafAccount,
            };
        default:
            return state;
    }
}

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import moment from "moment";

/*
 * Helper function that handles Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */

export const resourceInputsHandler = (
    value: string,
    id:
        | "value"
        | "cadence"
        | "entityName"
        | "startDate"
        | "endDate"
        | "entity"
        | "resourceCadence"
        | "resourceDuration"
        | "accountName"
        | "contraAccountName"
        | "adjustedAccountName",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "value":
            data.value = parseInt(value);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            let startDateString = "";
            if (value) {
                const startMoment = moment(value);
                startDateString = startMoment.isValid()
                    ? startMoment.format("YYYY-MM-DD")
                    : "";
            }
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            let endDateString = "";
            if (value) {
                const endMoment = moment(value);
                endDateString = endMoment.isValid()
                    ? endMoment.format("YYYY-MM-DD")
                    : "";
            }

            currentEntityObject.data = data;
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const parsedEvent = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedEvent,
                eventId,
                currentEntityObject.id,
                data.selectedEventId,
                data.selectedIds
            );

            data.selectedEvent = parsedEvent;
            data.selectedEventId = parsedEvent?.eventId ?? "";
            data.selectedIds = parsedEvent?.entityIds ?? [];

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "resourceCadence":
            data.resourceCadence = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "resourceDuration":
            data.resourceDuration = parseInt(value);

            const startDate = moment(currentEntityObject.startDate);
            startDate.add(data.resourceDuration ?? 0, "M");

            currentEntityObject.endDate = startDate.format("YYYY-MM-DD");
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name ?? "";
            data.accountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.contraAccountName = account.name ?? "";
            data.contraAccountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "adjustedAccountName":
            if (!account) break;
            data.adjustedAccountName = account.name ?? "";
            data.adjustedAccountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
};

import { getRelevantEntities } from "actions/getNodeEntityActions";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EntitiesSchema, EntitySchema } from "reducers/typesSchema/entitiesSchema";
import swal from "sweetalert";
import moment from "moment";

const getTimePercentage = (salesTargets) => {
    let totalTimePercentage = 0;
    salesTargets.forEach(
        (target) =>
            (totalTimePercentage =
                totalTimePercentage + Number(target?.timePercentage))
    );

    const availableTimePercentage = 100 - totalTimePercentage;

    return `${availableTimePercentage}`;
};

const salespersonInputsHandler = (
    value: string,
    id:
        | "employee"
        | "startDate"
        | "endDate"
        | "entityName"
        | "specific"
        | "placeholder"
        | "inheritStartDate"
        | "inheritEndDate"
        | "outboundSales"
        | "timePercentage"
        | "multiplier"
        | "totalSalespeople",
    index: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    const salesProfiles = data.salesTargets.map((target) => ({
        ...target,
    }));
    const targetProfile = salesProfiles[index];

    switch (id) {
        case "employee":
            const employeeType = data.employeeType;

            const parsedEmployee = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedEmployee,
                eventId,
                currentEntityObject.id,
                data.employeeEventId,
                data.employeeIds
            );

            const entity = getRelevantEntities(parsedEmployee?.entityIds ?? [])[
                parsedEmployee?.entityIds[0]
            ];

            if (entity) {
                if (entity.data.employeeState !== employeeType) {
                    swal({
                        title: "Error",
                        text: `Selected Employee is of type ${entity.data.employeeState}. Please select an Employee of type ${employeeType}`,
                    });
                    break;
                }

                if (data.employeeType == "placeholder")
                    data.noEmployees = entity.data.noEmployees;
                else data.noEmployees = "1";

                if (data.inheritEndDate) {
                    data.employeeEndDate = entity.endDate;
                    currentEntityObject.endDate = entity.endDate;
                }

                if (data.inheritStartDate) {
                    data.employeeStartDate = entity.startDate;
                    currentEntityObject.startDate = entity.startDate;
                }
            } else {
                data.employeeStartDate = "";
                data.noEmployees = "";
            }

            data.selectedEmployee = parsedEmployee;
            data.employeeEventId = parsedEmployee?.eventId ?? "";
            data.employeeIds = parsedEmployee?.entityIds ?? [];

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "outboundSales":
            if (!index && index !== 0) break;
            const targetOutboundSales = data.salesTargets[index];

            const parsedOutboundSales = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedOutboundSales,
                eventId,
                currentEntityObject.id,
                targetOutboundSales.outboundSales.eventId,
                targetOutboundSales.outboundSales.entityIds
            );

            const entityOS = getRelevantEntities(
                parsedOutboundSales?.entityIds ?? []
            )[parsedOutboundSales?.entityIds[0]];

            targetOutboundSales.outboundSales = parsedOutboundSales;

            targetOutboundSales.cadence = entityOS?.cadence ?? "";
            targetOutboundSales.leadFrequency =
                entityOS?.data?.leadTimeFrequency ?? "";
            targetOutboundSales.contractsClosed = Number(
                entityOS?.data?.value ?? 0
            );
            targetOutboundSales.leadTime = Number(
                entityOS?.data?.leadTime ?? 0
            );

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDateString = value ? moment(value).format("YYYY-MM-DD") : "";
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            const endDateString = value ? moment(value).format("YYYY-MM-DD") : "";
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "specific":
        case "placeholder":
            data.employeeType = id;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inheritStartDate":
            data.inheritStartDate = !data.inheritStartDate;
            if (data.inheritStartDate) {
                currentEntityObject.startDate = data.inheritedStartDate;
            }
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inheritEndDate":
            data.inheritEndDate = !data.inheritEndDate;
            if (data.inheritEndDate) {
                currentEntityObject.endDate = data.inheritedEndDate;
            }
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "timePercentage":
            if (!index && index !== 0) break;
            if (isNaN(Number(value))) break;

            targetProfile[id] = value;

            const allNonTargetProfiles = salesProfiles.filter(
                (salesProfile) => salesProfile?.id !== targetProfile?.id
            );

            const availableTimePercentage =
                getTimePercentage(allNonTargetProfiles);

            if (
                Number(targetProfile.timePercentage) >
                Number(availableTimePercentage)
            ) {
                targetProfile.timePercentage = availableTimePercentage;
            }

            salesProfiles[index] = targetProfile;
            data.salesTargets = salesProfiles;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "multiplier":
            if (!index && index !== 0) break;
            if (isNaN(Number(value))) break;

            targetProfile[id] = value;

            salesProfiles[index] = targetProfile;
            data.salesTargets = salesProfiles;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "totalSalespeople":
            if (isNaN(Number(value))) break;

            if (Number(value) > Number(data.noEmployees)) {
                swal({
                    title: "Error",
                    text: `Cannot enter an amount greater than the ${data.noEmployees} available placeholder Employees`,
                });
                break;
            }

            data.totalSalespeople = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

export default salespersonInputsHandler;

export const remapSalespersonDependencies = (
    entity: EntitySchema,
    newEntityIdsMap: { [key: string]: string }
) => {
    if (entity?.data?.employeeIds?.length > 0 && newEntityIdsMap?.[entity?.data?.employeeIds?.[0]]) {
        const selectedEmployee = {...entity.data.selectedEmployee}
        entity.data.employeeIds = [newEntityIdsMap?.[entity?.data?.employeeIds?.[0]]] ?? []
        selectedEmployee.entityIds = entity?.data?.employeeIds
        entity.data.selectedEmployee = selectedEmployee
    }

    if (entity?.data?.salesTargets?.length > 0) {  
        const newSalesTargets =  entity?.data?.salesTargets?.map((salesTarget) => {
            const destructuredSalesTarget = {
                cadence: salesTarget?.cadence,
                contractsClosed: salesTarget?.contractsClosed,
                customerAccountId: salesTarget?.customerAccountId,
                grownCustomerAccountId: salesTarget?.grownCustomerAccountId,
                id: salesTarget?.id,
                leadFrequency: salesTarget?.leadFrequency,
                leadTime: salesTarget?.leadTime,
                multiplier: salesTarget?.multiplier,
                outboundSales: {...salesTarget?.outboundSales},
                timePercentage: salesTarget?.timePercentage
            }

            if (destructuredSalesTarget?.outboundSales?.entityIds?.length > 0 && newEntityIdsMap?.[destructuredSalesTarget?.outboundSales?.entityIds?.[0]]) {
                const newEntityIds = [newEntityIdsMap?.[destructuredSalesTarget?.outboundSales?.entityIds?.[0]]] ?? []
                destructuredSalesTarget.outboundSales.entityIds = newEntityIds
            }
            return destructuredSalesTarget
        })
        entity.data.salesTargets = newSalesTargets
    }

}


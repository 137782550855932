import { connect } from "react-redux";
import * as actions from "../../actions/scenario";
import ScenariosContainer from "./ScenariosContainer";

const mapStateToProps = (state) => {
    return {
        sampleScenarios: state.scenario.sampleScenarios,
        sampleScenariosLoading: state.scenario.sampleScenariosLoading,
        userScenarios: state.scenario.userScenarios,
        loadedScenario: state.scenario.loadedScenario,
        loadedScenarioId: state.scenario.loadedScenarioId,
        manager: state.scenario.manager,
        sharedScenario: state.scenario.sharedScenario,
        accountData: state.scenario.accountData,
        showCreateScenario: state.scenario.showCreateScenario,
        baseline: state.scenario.baseline,
        baselines: state.scenario.baselines,
        baselineManager: state.scenario.baselineManager,
        baselineDataManager: state.scenario.baselineDataManager,
        expiredNodes: state.scenario.expiredNodes,
        showExpiredModal: state.scenario.showExpiredModal,
        outdatedNodes: state.scenario.outdatedNodes,
        showOutdatedModal: state.scenario.showOutdatedModal,
        eventLibrary: state.scenario.eventLibrary,
        agencyClients: state.scenario.agencyClients,
        activeAgencyClients: state.scenario.activeAgencyClients,
        archivedAgencyClients: state.scenario.archivedAgencyClients,
        archivedScenarios: state.scenario.archivedScenarios,
        onboardingData: state.scenario.onboardingData,
        scenarioTemplates: state.scenario.scenarioTemplates,
        zoomedThreadId: state?.threadsList?.zoomedThreadId,
    };
};

const mapDispatchToProps = {
    getSampleScenario: actions.getSampleScenario,
    editScenario: actions.editScenario,
    loadScenario: actions.loadScenario,
    createScenario: actions.createScenario,
    deleteScenario: actions.deleteScenario,
    getUserScenarios: actions.getUserScenarios,
    copyScenario: actions.copyScenario,
    shareScenario: actions.shareScenario,
    updateScenarioCanvas: actions.updateScenarioCanvas,
    fetchSharedScenarios: actions.fetchSharedScenarios,
    editScenarioComments: actions.editScenarioComments,
    getSubscription: actions.getSubscription,
    onboardingScenario: actions.onboardingScenario,
    getBaseline: actions.getBaseline,
    getAdminValues: actions.getAdminValues,
    closeExpiredNodeModal: actions.closeExpiredNodeModal,
    closeOutdatedNodeModal: actions.closeOutdatedNodeModal,
    updateClient: actions.updateClient,
    createSampleScenario: actions.createSampleScenario,
    deleteSampleScenario: actions.deleteSampleScenario,
    updateSampleScenario: actions.updateSampleScenario,
    getTemplates: actions.getTemplates,
};

const UserScenarios = connect(
    mapStateToProps,
    mapDispatchToProps
)(ScenariosContainer);

export default UserScenarios;

import { useState, ChangeEvent, useEffect, useMemo, useContext, MutableRefObject, useRef } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { getDefaultName } from "helpers";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import outboundSalesInputsHandler from "../OnInputChangeHandlers/outboundSalesInputsHandler";
import OutboundSalesInputView from "./OutboundSalesInputView";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { throwError } from "helpers/swalError";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { customerObject } from "Components/Registry/Customer";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";

export default function OutboundSalesInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);

    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({ ...useAppSelector((state) => state?.scenario?.loadedScenario?.dependency_map) })

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(outboundSalesObject);
            _eventData.name = getDefaultName(
                outboundSalesObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, outboundSalesObject, dependencyMap.current); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const selectedCustomerName = useMemo(() => {
        if (!entitiesMap[currentEntity]?.data?.customerEventId) return ""
        const selectedCustomer = entitiesMap[currentEntity]?.data?.selectedCustomer
        const dependantEvent = getEvent(selectedCustomer?.eventId)
        const dependantEntity = Object.values(getRelevantEntities(selectedCustomer?.entityIds))?.[0]
        return `${dependantEvent?.name} - ${dependantEntity?.name ?? "All"}`
    }, [currentEntity, entitiesMap])

    const getCustomers = () => {
        const customers = {};

        getPresentableDependencies(
            customers,
            customerObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(customers).length) {
            throwError(
                "warning",
                "No Customer nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return customers;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const customers = getCustomers();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.customers = customers;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "customer"
            | "entityName"
            | "cadence"
            | "value"
            | "leadTimeFrequency"
            | "leadTime",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = outboundSalesInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && outboundSalesObject.checkInput(entitiesMap);

    return (
        <OutboundSalesInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            onHandleSubmit={onHandleSubmit}
            handleOnChange={handleOnChange}
            passedCheck={passedCheck}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            selectedCustomerName={selectedCustomerName}
        />
    );
}

import React from "react";

import SalespersonCard from "../Card/SalespersonCard";

import SalespersonConnectionImage from "Assets/_optionDescriptionIcons/Salesperson, Employee, Outbound Sales white .jpg";

import salespersonSvg from "../../Assets/_eventNodeTypes/salespersonSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import SalespersonInputExperimental from "Components/InputContainer/SalespersonInputExperimental/SalespersonInputExperimental";

export const salespersonObject = {
    constant: function () {
        return Salesperson_Constant();
    },

    name: function () {
        return "Salesperson";
    },

    checkInput: function (state) {
        return Salesperson_CheckInput(state);
    },

    svg: function () {
        return Salesperson_Svg();
    },

    eventNodeMenu: function (focus) {
        return Salesperson_EventNodeMenu(focus);
    },

    indexText: function () {
        return Salesperson_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Salesperson_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Salesperson_OptionDescription();
    },

    colouredPngs: function () {
        return Salesperson_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Salesperson_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Salesperson_GenerateDisplayFieldData();
    },
};

// Helper functions for Event

const Salesperson_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.startDate) return false;

        if (entity.data.employeeType == "placeholder") {
            if (!entity.data.employeeEventId) return false;
            if (!entity.data.totalSalespeople) return false;
        } else if (entity.data.employeeType == "specific") {
            if (!entity.data.employeeEventId) return false;
        }

        if (!entity.data.salesTargets.length) return false;

        for (const salesTarget of entity.data.salesTargets) {
            if (!salesTarget.outboundSales.entityIds.length) return false;
            if (!salesTarget.timePercentage || !salesTarget.multiplier)
                return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

const Salesperson_Constant = () => {
    return "41c11258-1bff-4790-9133-5f3bff339724";
};

const Salesperson_Svg = () => {
    return salespersonSvg;
};

const Salesperson_EventNodeMenu = (focus) => {
    return <SalespersonCard data={focus} />;
};

const Salesperson_IndexText = () => {
    return "The Salesperson Event takes in an Outbound Sales Profile and increases a given customer cohort using the sales profile.";
};

const Salesperson_Icons = () => {
    let pngs = new Map([
        ["black", salespersonSvg],
        ["blue", salespersonSvg],
        ["white", salespersonSvg],
        ["grey", salespersonSvg],
    ]);
    return pngs;
};

const Salesperson_InputComponentInfoPassing = (minProps) => {
    return <SalespersonInputExperimental {...minProps} />;
};

const Salesperson_OptionDescription = () => {
    const tags = ["Option"];
    const type = salespersonObject.name();
    const description =
        "The Salesperson Event represents a salesperson who sells to a customer cohort.";
    const usedForText =
        "Use the Salesperson Event to increase a given customer pool.";
    const connection = "";
    const connectionImage = SalespersonConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/r/revenue.asp#:~:text=Revenue%20is%20the%20income%20generated,subtracted%20to%20determine%20net%20income.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Salesperson_SideModalImage = () => {
    return sideImage;
};

const Salesperson_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Group, Text } from "react-konva";
// import { animated } from "@react-spring/konva";
import { HoveredButtonIdContext } from "../contexts/hoveredButtonIdContext";
import { InsertMenuIdContext } from "../../../contexts/InsertMenuIdContext";
import { FocusedLineContext } from "../../../contexts/focusedLineContext";
import { useMenuTemplate } from "../../../../CanvasEvents/CanvasEvent/partials/EventNodeBaseMenu/hooks/useMenuTemplate";
// import { useSpring } from "react-spring";
import { useActionsData } from "Components/EventsCanvas/Experimental/CanvasStage/hooks/useActionsData";
import { getInsertActionActive } from "./InsertMenuIcon/helpers/getInsertActionActive";
import { HasCopiedEventContext } from "../../../contexts/HasCopiedEventContext";
import { HasSiblingEventContext } from "../../../contexts/HasSiblingEventContext";
import { LineIdContext } from "../../../contexts/lineIdContext";
import { getEvent } from "actions/getNodeEntityActions";
import { containerObject } from "Components/Registry/Container";

export function InsertMenuToolTip() {
    const textRef = useRef<any>(null);
    const lineId = useContext(LineIdContext);
    const templateId = useContext(InsertMenuIdContext);
    const insertMenuTemplate = useMenuTemplate(templateId);
    const focused = useContext(FocusedLineContext);
    const hovered = useContext(HoveredButtonIdContext);
    const hasCopiedEvent = useContext(HasCopiedEventContext);
    const hasSiblingEvent = useContext(HasSiblingEventContext);
    const actionData = useActionsData(hovered);
    const [textRealWidth, setTextRealWidth] = useState(0);

    const lineReferencesContainer = () => {
        const splitId = lineId?.split("_");
        const parentEventId = splitId?.[0];
        const childEventId = splitId?.[1]?.split("-background")[0];
        const parentEvent: any = getEvent(parentEventId);
        const childEvent: any = getEvent(childEventId);
        if (
            parentEvent?.relevantContainerId ||
            childEvent?.relevantContainerId ||
            parentEvent?.type === containerObject?.constant() ||
            childEvent?.type === containerObject?.constant()
        ) {
            return true;
        }
        return false;
    };

    const textValue = useMemo(() => {
        if (!actionData) return "";

        const activeTooltip = getInsertActionActive(
            actionData?.action,
            !lineReferencesContainer() && hasCopiedEvent,
            hasSiblingEvent
        );

        if (activeTooltip) {
            return actionData?.activeTooltipText;
        } else {
            return actionData?.inactiveTooltipText;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionData, hasCopiedEvent, hasSiblingEvent]);

    useEffect(() => {
        if (textValue) {
            const _textRealWidth = textRef.current?.getTextWidth?.();
            setTextRealWidth(_textRealWidth);
        }
    }, [textValue]);

    const _textStyling = useMemo(() => {
        const _styles = insertMenuTemplate?.canvasStyles?.tooltipText?.styles;

        const _default = _styles?.default;
        const _focused = focused ? _styles?.focused : {};

        const _stylesMid = {
            ..._default,
            ..._focused,
        };

        const x = -(textRealWidth || (_stylesMid?.width ?? 0)) / 2;
        const y = -(_stylesMid?.height ?? _stylesMid?.fontSize ?? 0) / 2;

        const _finalStyles: any = { ..._stylesMid, x, y };

        if (!textRealWidth) _finalStyles.x = 0;

        return _finalStyles;
    }, [
        focused,
        insertMenuTemplate?.canvasStyles?.tooltipText?.styles,
        textRealWidth,
    ]);

    // the following animation config could be used to give animation to the tool tip.

    // const animationConfig = useMemo(() => {
    //     const defaultAnimationConfig =
    //         insertMenuTemplate?.defaultAnimationConfig;

    //     const _animationOverrides =
    //         insertMenuTemplate?.canvasStyles?.tooltipText?.animationOverride;
    //     const _default = _animationOverrides?.default;
    //     const _focused = focused ? _animationOverrides?.focused : {};

    //     const _animationConfig: any = {
    //         ...defaultAnimationConfig,
    //         ..._default,
    //         ..._focused,
    //     };

    //     return _animationConfig;
    // }, [
    //     focused,
    //     insertMenuTemplate?.canvasStyles?.tooltipText?.animationOverride,
    //     insertMenuTemplate?.defaultAnimationConfig,
    // ]);

    // const textStyling = useSpring({
    //     to: _textStyling,
    //     config: animationConfig,
    // });

    const _containerPositioning = useMemo(() => {
        const _styles =
            insertMenuTemplate?.canvasStyles?.toolTipContainerDimensions
                ?.styles;

        const _default = _styles?.default;
        const _focused = focused ? _styles?.focused : {};

        const _finalStyles = {
            ..._default,
            ..._focused,
        };

        return _finalStyles;
    }, [
        focused,
        insertMenuTemplate?.canvasStyles?.toolTipContainerDimensions?.styles,
    ]);

    return (
        <Group {..._containerPositioning}>
            {/* @ts-ignore - Seems to be a react spring type error */}
            <Text ref={textRef} text={textValue} {..._textStyling} />
        </Group>
    );
}

import React, { Component } from "react";
import "./Card.css";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import moment from "moment";

class RentCard extends Component {
    state = {
        showSettings: false,
        walkScoreData: null,
    };

    componentDidMount() {
        const { address, lat, lng } = this.props.data.metadata;
        if (address && lat && lng) {
            this.props.getWalkScore(address, lat, lng, (err, data) => {
                if (err) {
                    console.log(err, "<-- walkscore");
                } else {
                    this.setState({ walkScoreData: data });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.id !== this.props.data.id) {
            const { address, lat, lng } = this.props.data.metadata;
            if (address && lat && lng) {
                this.props.getWalkScore(address, lat, lng, (err, data) => {
                    if (err) {
                        console.log(err, "<-- walkscore");
                    } else {
                        this.setState({ walkScoreData: data });
                    }
                });
            }
        }
    }

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };
    render() {
        const stars = [1, 2, 3, 4, 5];
        const { showSettings, walkScoreData } = this.state;
        const {
            value,
            rooms,
            address,
            cadence,
            url,
            start,
            end,
            rating,
            roommates,
            accomodationRate,
            accomodationCost,
            rent,
            deposit,
            insurance,
            increaseRate,
            version,
        } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="locationData">
                        <span className="highlighted">{rooms} BDRM</span> at{" "}
                        <span className="highlighted">{address}</span>
                    </div>
                </div>
                {walkScoreData && (
                    <div className="Row">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={walkScoreData.ws_link}
                        >
                            <img alt="alt" src={walkScoreData.logo_url} />
                        </a>
                        <div className="walkScoreValue">
                            {walkScoreData.walkscore}
                        </div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={walkScoreData.more_info_link}
                        >
                            <img alt="alt" src={walkScoreData.more_info_icon} />
                        </a>
                    </div>
                )}
                <div className="Row">
                    <div
                        className={
                            this.props.data.metadata.description
                                ? "Description"
                                : "NoDescription"
                        }
                    >
                        {this.props.data.metadata.description}
                    </div>
                </div>
                {value && (
                    <div className="Row">
                        <div className="DollarSvg">$</div> {""}
                        <div className="Amount">
                            {parseFloat(value).toFixed(2)}
                        </div>
                        <div className="Frequency">/ {cadence}</div>
                    </div>
                )}

                <div className="Row">
                    <div className="ExpenseLabel">URL:</div>
                    <a
                        className="ExpenseLink"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {url}
                    </a>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Date</div>
                    <div className="cardValue">
                        {start
                            ? moment(start).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {end ? moment(end).format("MMM D YYYY") : "No End Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel"> Rating</div>
                    <div className="StarsContainer">
                        {stars.map((star, i) => {
                            if (i < rating) {
                                return (
                                    <img
                                        alt="alt"
                                        key={i}
                                        className="starFilledSvg"
                                        src={starFilledSvg}
                                        id="rating"
                                    />
                                );
                            } else {
                                return (
                                    <img
                                        alt="alt"
                                        className="starUnfilledSvg"
                                        src={starUnfilledSvg}
                                        key={i}
                                        id="rating"
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
                <div className="Row">
                    <img
                        alt="alt"
                        onClick={this.onHandleAdvanceSetting}
                        className="selectedIcon"
                        src={showSettings ? dropdownSvg : expandSvg}
                    />
                    <div className="advanceSettingLabel">Advance settings</div>
                </div>
                {showSettings && (
                    <div>
                        <div className="Row">
                            <div className="rentOptionalLabel">
                                Shared Accomodation
                            </div>
                        </div>
                        <div className="Row">
                            <div className="rentAccomodationData">
                                Sharing with{" "}
                                <span className="highlighted">
                                    {roommates || 0}
                                </span>{" "}
                                {/* <span className="highlighted"> */}(
                                {accomodationRate || 100}% ){/* </span>{" "} */}{" "}
                                <span className="highlighted">
                                    ${accomodationCost || rent} each
                                </span>
                            </div>
                        </div>
                        <div className="Row">
                            <div className="rentOptionalLabel">
                                Shared Accomodation
                            </div>
                        </div>
                        <div className="Row">
                            <div className="rentAccomodationData">
                                Deposit total:{" "}
                                <span className="highlighted">
                                    ${deposit || 0}
                                </span>
                            </div>
                        </div>
                        <div className="Row">
                            <div className="rentAccomodationData">
                                Insurance total:{" "}
                                <span className="highlighted">
                                    ${insurance || 0}
                                </span>
                            </div>
                        </div>
                        <div className="Row">
                            <div className="rentOptionalLabel">
                                Rental Increases
                            </div>
                        </div>
                        <div className="Row">
                            <div className="rentAccomodationData">
                                Yearly rental Increase of{" "}
                                <span className="highlighted">
                                    {increaseRate || 0}%
                                </span>
                            </div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Version:</div>
                            <div className="cardValue">
                                {version ? version : ""}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default RentCard;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    ModalRow,
    ModalRowHalf,
    MuiCalendar,
    SelectDropDown,
    Value,
} from "Components/InputContainer/Components";

const options = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "daily",
    "one-time",
];

export default function OngoingExpensePanel({
    entitiesMap,
    currentEntity,
    handleOnChange,
    handleDateSelection,
    isOverrideValid,
    checkIfFieldIsOverridden,
    ledgerAccount,
    createOverride,
    getOverride,
    updateOverride,
    value,
    unitless = false,
}) {
    return (
        <>
            <ModalRow twoInputs>
                <ModalRowHalf>
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap?.[currentEntity]?.cadence}
                        options={options}
                        className="select-dropdown"
                        id="cadence"
                        label="Select Frequency"
                        required={true}
                        disabled={getOverride("value") !== undefined}
                    />
                </ModalRowHalf>
                <ModalRowHalf>
                    <Value
                        label={unitless ? "Amount" : "Cost"}
                        onChangeInput={handleOnChange}
                        value={value}
                        id="value"
                        override={isOverrideValid(entitiesMap, currentEntity)}
                        entityData={entitiesMap?.[currentEntity]}
                        overridden={checkIfFieldIsOverridden("value")}
                        ledgerAccount={ledgerAccount}
                        startAdornment={unitless ? "" : "$"}
                        unit="$"
                        required={true}
                        createOverride={createOverride}
                        updateOverride={updateOverride}
                    />
                </ModalRowHalf>
            </ModalRow>
            <ModalRow twoInputs>
                <ModalRowHalf>
                    <MuiCalendar
                        id="startDate"
                        value={
                            entitiesMap?.[currentEntity]?.startDate === ""
                                ? null
                                : entitiesMap?.[currentEntity]?.startDate
                        }
                        required={true}
                        onChangeInput={handleDateSelection}
                        label="Start Date"
                    />
                </ModalRowHalf>
                {entitiesMap?.[currentEntity]?.cadence !== "one-time" && (
                    <ModalRowHalf>
                        <MuiCalendar
                            id="endDate"
                            value={
                                entitiesMap?.[currentEntity]?.endDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.endDate
                            }
                            onChangeInput={handleDateSelection}
                            label="End Date"
                            helperText="An end date is optional"
                        />
                    </ModalRowHalf>
                )}
            </ModalRow>
        </>
    );
}

export const SHOW_ACCOUNT_PICKER_MODAL = "SHOW_ACCOUNT_PICKER_MODAL";

export const openAccountPickerModal = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_ACCOUNT_PICKER_MODAL,
            payload: {
                modalOpen: true,
            },
        });
    };
};

export const closeAccountPickerModal = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_ACCOUNT_PICKER_MODAL,
            payload: {
                modalOpen: false,
            },
        });
    };
};

import React, { Component } from "react";
import "./Card.css";
import { getRelevantEntities } from "actions/getNodeEntityActions";

class BusinessCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];
        const { country, state } = firstEntity.data;
        const { version } = firstEntity;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Country:</div>
                    <div className="cardValue">{country ? country : ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">State:</div>
                    <div className="cardValue">{state ? state : ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default BusinessCard;

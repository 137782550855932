import React, { Component } from "react";
import "./InputContainer.css";
import { Row, Col } from "reactstrap";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import axios from "axios";

import {
    Description,
    Rate,
    InputButtons,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    StarsRating,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { equityObject } from "Components/Registry/Equity";

class EquityInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticker: "",
            quantity: "",
            rating: 0,
            showOptions: false,
            name: getDefaultName(equityObject.constant(), this.props),
            description: "",
            type: equityObject.constant(),
            value: null,
            showSettings: false,
            tag: `@${equityObject.constant()}`,
            onHoverStar: null,
            baseline: false,
            stockList: [],
            exDividendDate: "",
            exDividend: "",
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "ticker":
                this.setState({
                    ticker: value,
                });
                break;
            case "quantity":
                this.setState({
                    quantity: parseInt(value),
                });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = equityObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onToggleCheckBox = () => {
        this.setState({ disableOnRetirement: !this.state.disableOnRetirement });
    };

    addStock = () => {
        const stockTicker = this.state.ticker.toUpperCase().trim();
        let exdivDate = "";
        let exdivAmt = "";
        var options = {
            method: "GET",
            url: "https://apidojo-yahoo-finance-v1.p.rapidapi.com/stock/v2/get-statistics",
            params: { symbol: stockTicker },
            headers: {
                "x-rapidapi-host": "apidojo-yahoo-finance-v1.p.rapidapi.com",
                "x-rapidapi-key":
                    "27f68789f9msh5c58c5bafab24d1p170b97jsn25c6b1af7f18",
            },
        };
        axios
            .request(options)
            .then((response) => {
                exdivDate =
                    "Ex-dividend date " +
                    response.data["summaryDetail"]["exDividendDate"]["fmt"];
                exdivAmt =
                    "Dividend Issused $ " +
                    response.data["summaryDetail"]["dividendRate"]["fmt"];
                if (
                    response.data["summaryDetail"]["dividendRate"]["fmt"] ===
                    undefined
                ) {
                    exdivAmt = "Dividend Issused $ " + 0.0;
                }
                let tempObj = {
                    ticker: stockTicker,
                    quantity: parseInt(this.state.quantity),
                    exDividend: exdivAmt,
                    exDividendDate: exdivDate,
                };
                this.setState({
                    stockList: this.state.stockList.concat(tempObj),
                });
            })
            .catch((_error) => {
                exdivDate = "No Dividends Issued or Invalid Stock";
                exdivAmt = "NA";
                let tempObj = {
                    ticker: stockTicker,
                    quantity: parseInt(this.state.quantity),
                    exDividend: exdivAmt,
                    exDividendDate: exdivDate,
                };
                this.setState({
                    stockList: this.state.stockList.concat(tempObj),
                });
            });
    };

    deleteStock = (ticker, quantity) => {
        let tempStockList = this.state.stockList;
        for (let i = 0; i < tempStockList.length; i++) {
            if (
                tempStockList[i]["ticker"] === ticker &&
                tempStockList[i]["quantity"] === quantity
            ) {
                tempStockList.splice(i, 1);
                i--;
            }
        }
        this.setState({
            stockList: tempStockList,
        });
    };

    render() {
        const passedCheck = equityObject.checkInput(this.state);
        const { edit } = this.props;

        const {
            name,
            description,
            ticker,
            quantity,
            rating,
            onHoverStar,
            stockList,
        } = this.state;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={equityObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={equityObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Rate
                                label="Stock Ticker"
                                onChangeInput={this.onChangeInput}
                                value={ticker}
                                id="ticker"
                                className="mlsInput"
                            />
                        </ModalRowHalf>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Rate
                                label="Share Quantity"
                                onChangeInput={this.onChangeInput}
                                value={quantity}
                                id="quantity"
                                className="mlsInput"
                            />
                        </ModalRowHalf>
                        <div className="flinksButtonContainer">
                            <div
                                className="manualBankButton"
                                onClick={this.addStock}
                            >
                                Add stock
                            </div>
                        </div>
                    </ModalRow>

                    <div className="cardContainer">
                        {stockList.map((stock, i) => {
                            return (
                                <div key={i} className="cardHolder">
                                    <div className="cardHeader">
                                        <Row xs="5">
                                            <Col>{stock.ticker}</Col>
                                            <Col>{stock.quantity}</Col>
                                            <Col>{stock.exDividend}</Col>
                                            <Col>{stock.exDividendDate}</Col>
                                            <Col>
                                                {" "}
                                                <div
                                                    className="manualBankButton"
                                                    onClick={() =>
                                                        this.deleteStock(
                                                            stock.ticker,
                                                            stock.quantity
                                                        )
                                                    }
                                                >
                                                    Delete Stock
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <ModalRow>
                        <StarsRating
                            onHoverStar={onHoverStar}
                            onHandleStarHover={this.onHandleStarHover}
                            rating={rating}
                            onChangeInput={this.onChangeInput}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ModalRow>
                    {this.state.showRequired && (
                        <ModalRow>
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </ModalRow>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default EquityInput;

// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    ModalRowHalf,
    SelectEntities,
    SelectDropDown,
    ModalRowComponent,
    MuiCalendar,
    Value
} from "../Components";
import { campaignObject } from "Components/Registry/Campaign";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

const cadences = ["monthly", "weekly"];

export default function CampaignInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    onHandleSubmit,
    handleOnChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    updateAccount,
    handleDateSelection
}) {
    const entityData = entitiesMap[currentEntity].data;
    let numDisplay = 0;
    if (entityData.value && entityData.costPerVisit) {
        numDisplay =
            Math.round(
                (Number(entityData.value) / Number(entityData.costPerVisit)) *
                100
            ) / 100;
    }

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={campaignObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={campaignObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[campaignObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <div style={{ height: "10px" }} />
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.data?.selectedWebsiteVis ?? {}
                            }
                            nodes={Object.values(
                                entitiesMap[currentEntity].data.websiteVisitors
                            )}
                            className="mlsInput"
                            id="websiteVisitor"
                            label="Target Website Visitor"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <div className="entity-subsection-label">
                        Campaign Details
                    </div>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowComponent width={40}>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.cadence}
                            options={cadences}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Frequency"
                            required={true}
                        />
                    </ModalRowComponent>
                    <ModalRowComponent width={25}>
                        <Value
                            label="Ad Spend"
                            onChangeInput={handleOnChange}
                            value={entityData.value}
                            id="value"
                            className="mlsInput"
                            sign={false}
                            required={true}
                            showSettings={false}
                            variant={"outlined"}
                        />
                    </ModalRowComponent>
                    <ModalRowComponent width={25}>
                        <Value
                            label="Cost Per Paid Visit"
                            onChangeInput={handleOnChange}
                            value={entityData.costPerVisit}
                            id="costPerVisit"
                            className="mlsInput"
                            sign={false}
                            required={true}
                            showSettings={false}
                            variant={"outlined"}
                        />
                    </ModalRowComponent>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar id="startDate" value={entitiesMap?.[currentEntity]?.startDate} required={true} onChangeInput={handleDateSelection} label="Start Date" />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <MuiCalendar id="endDate" value={entitiesMap?.[currentEntity]?.endDate} onChangeInput={handleDateSelection} label="End Date" helperText="An end date is optional" />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={
                                entitiesMap?.[currentEntity]?.data
                                    ?.accountName ?? ""
                            }
                            topLevelFilters={{
                                "7faf0285-78ca-411b-b875-d900929d7c94": true,
                            }}
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <div style={{ height: "10px" }} />
                <ModalRow>
                    <div className="loanPaymentsContainer">
                        <div className="paymentsAmountContainer">
                            <div className="noticeLabel">
                                For this Campaign, based on spending $
                                {entityData.value || 0} monthly, the approximate
                                new Website Visitors added{" "}
                                {entitiesMap[currentEntity].cadence} will be:
                            </div>
                            <div className="paymentsValue">{numDisplay}</div>
                        </div>
                    </div>
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                passedCheck={passedCheck}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
            />
        </EventEntityWrapper>
    );
}

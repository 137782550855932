// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import RetirementCard from "../Card/retirementCard";
import RetirementInput from "../InputContainer/RetirementInput";

// Import Connection Image (if exists)
import RetirementConnectionImage from "../../Assets/_optionDescriptionIcons/Income, Retirement, Pension.png";

// Import Coloured PNGs
import retirementBlack from "../../Assets/_eventNodeTypes/retirement-black.png";
import retirementBlue from "../../Assets/_eventNodeTypes/retirement-blue.png";
import retirementWhite from "../../Assets/_eventNodeTypes/retirement-white.png";
import retirementGrey from "../../Assets/_eventNodeTypes/retirement-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/RetirementPlanning2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - retirementObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - retirementObject.checkInput.call(retirementObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const retirementObject = {
    constant: function () {
        return Retirement_Constant();
    },

    checkInput: function (state) {
        return Retirement_CheckInput(state);
    },

    svg: function () {
        return Retirement_Svg();
    },

    eventNodeMenu: function (focus) {
        return Retirement_EventNodeMenu(focus);
    },

    indexText: function () {
        return Retirement_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Retirement_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Retirement_OptionDescription();
    },

    colouredPngs: function () {
        return Retirement_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Retirement_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Retirement_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.person) return false;
    if (!state.start) return false;
    return true;
};

// EFFECTS: Returns constant for event
const Retirement_Constant = () => {
    return "Retirement";
};

// EFFECTS: Returns black png / svg for event
const Retirement_Svg = () => {
    return retirementBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Retirement_EventNodeMenu = (focus) => {
    return <RetirementCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Retirement_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Retirement calculations.";
};

// EFFECTS: Returns all icons for event ??
const Retirement_Icons = () => {
    let pngs = new Map([
        ["black", retirementBlack],
        ["blue", retirementBlue],
        ["white", retirementWhite],
        ["grey", retirementGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Retirement_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Retirement_InputComponentInfoPassing = (minProps) => {
    return <RetirementInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, retirements for event ??
const Retirement_OptionDescription = () => {
    const tags = ["Option"];
    const type = retirementObject.constant();
    const description =
        "Retirement is a big milestone for many people. Time to sit back, relax, and enjoy your journey.";
    const usedForText =
        "Use the Retirement Event as a trigger to stop your Income(s) (if selected to do so) and activate other events such as your Pension.";
    const connection = "";
    const connectionImage = RetirementConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/financial-consumer-agency/services/retirement-planning.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Retirement_SideModalImage = () => {
    return sideImage;
};

import React, { Component } from "react";
import styles from "./Options.module.css";
import InputContainer from "../../Components/InputContainer";
import OptionDescription from "./OptionDescription";
import { UtilityTab } from "./UtilityTab";
import { RecentlyUsedTab } from "./RecentlyUsedTab";
import OptionsTab from "./OptionsTab";
import sortIcon from "../../Assets/_optionPicker/sort.svg";
import _ from "lodash";

import {
    // These are never used
    // Commute,
    // Daycare,
    // fourZeroOneK,
    // Nanny,
    // HouseMaintenance,
    // Childcare,
    // ChildcareTax,
    // ChildNode,
    // MortgageUSA,
    // IncomeTaxUSA,
    optionNodes,
    supportedLibraryEvents,
} from "../../helpers/constants";
import { FavoriteTab } from "./FavoritesTab";
import LibraryTab from "./LibraryTab";
import { LibraryOptionsTab } from "./LibraryOptionsTab";
import { expenseObject } from "../Registry/Expense";
import { incomeObject } from "../Registry/Income";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { events } from "Components/InputContainer/Master Registry";
import { goalObject } from "Components/Registry/Goal";
import { decisionObject } from "Components/Registry/Decision";
import { nullObject } from "Components/Registry/Null";
import { houseObject } from "Components/Registry/House";
// import { businessObject } from "Components/Registry/Business";
import { kpiObject } from "Components/Registry/KPI";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { modifierObject } from "Components/Registry/Modifier";
import { growthObject } from "Components/Registry/Growth";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { accountExpressionObject } from "Components/Registry/AccountExpression";

// This is never used
// const comingSoon = [
//     { type: Commute },
//     { type: Daycare },
//     { type: fourZeroOneK },
//     { type: Nanny },
//     { type: HouseMaintenance },
//     { type: Childcare },
//     { type: ChildcareTax },
//     { type: ChildNode },
//     { type: MortgageUSA },
//     { type: IncomeTaxUSA },
// ];

const types = [];

events.forEach(function (event) {
    types.push({ type: event.constant() });
});

const Options = "Options";
const Utility = "Utility Events";
const Favorite = "Favorite";
const RecentlyUsed = "Recently Used";
const Library = "Library";

const utilityNodes = [
    {
        type: decisionObject.constant(),
        description:
            "Add a Decision Event to your Canvas whenever you are considering more than one set of options.",
        name: decisionObject.name(),
    },
    {
        type: accountExpressionObject.constant(),
        description: "Account Expression event base copy",
        name: accountExpressionObject.name(),
    },
    {
        type: accountModifierObject.constant(),
        description:
            "An Account Modifier is a powerful Event that let's you manually override or augment any account utilised in your scenario.",
        name: accountModifierObject.name(),
    },
    {
        type: modifierObject.constant(),
        description:
            "A Modifier is a powerful Event that let's you manually override or augment any value generated earlier in your scenario, including dates, percentages, or statuses.",
        name: modifierObject.name(),
    },
    {
        type: growthObject.constant(),
        description:
            "A Modifier is a powerful Event that let's you manually override or augment any value generated earlier in your scenario, including dates, percentages, or statuses.",
        name: growthObject.name(),
    },
    {
        type: constraintObject.constant(),
        description:
            "A Constraint lets you set a minimum or maximum limit to a value.",
        name: constraintObject.name(),
    },
    {
        type: nullObject.constant(),
        description:
            'A Null Event can be used as a placeholder or a "nothing" option that does not generate context.',
        name: nullObject.name(),
    },
    // {
    //     type: businessObject.constant(),
    //     description:
    //         "Associate downstream business-related events with a Business Event. Typically, Business Events are most commonly used in the Baseline.",
    //     name: businessObject.name(),
    // },
    {
        type: goalObject.constant(),
        description:
            "A Goal is a target value on a future date. It can be an Account balance, a customer count, or any other type of value that we track in whatifi.",
        name: goalObject.name(),
    },
    {
        type: kpiObject.constant(),
        description:
            "COMING SOON - The KPI event allows you to calculate and display many commonly used KPIs such as LTV and Profit Margin.",
        name: kpiObject.name(),
    },
    {
        type: groupObject.constant(),
        description:
            "COMING SOON - A Group Event is a container for different events or entities. It can be used to apply the same modification to a group of events or entities",
        name: groupObject.name(),
    },
    {
        type: projectObject.constant(),
        description:
            "COMING SOON - A Project Event is a container for combinations of events and entities. It can be used to apply date offsets to a group of regular events, entities, or group events",
        name: projectObject.name(),
    },
];

const libraryCategories = [
    {
        type: houseObject.constant(),
        requiredFields: [
            { id: "name", type: "text" },
            { id: "address", type: "text" },
            { id: "purchasePrice", type: "number" },
            { id: "purchaseDate", type: "date" },
            { id: "primaryResidence", type: "text" },
            { id: "firstTimeBuyer", type: "text" },
        ],
        optionalFields: [
            "Description",
            "MLS #",
            "Appreciation",
            "Appreciation Rate",
            "Rating",
        ],
        data: [
            {
                id: "12345678",
                name: "House 1",
                primaryResidence: "Yes",
                firstTimeBuyer: "Yes",
                address: "1381 Chine Crescent, Coquitlam, BC, Canada",
                purchasePrice: 1000000,
                purchaseDate: "2001-01-01",
            },
            {
                id: "87654321",
                name: "House 2",
                primaryResidence: "Yes",
                firstTimeBuyer: "No",
                address: "4669 W. 11th Avenue, Vancouver, BC, Canada",
                purchasePrice: 500000,
                purchaseDate: "1965-01-01",
            },
        ],
    },
    {
        type: incomeObject.constant(),
        requiredFields: [],
        optionalFields: [],
        data: [],
    },
    {
        type: expenseObject.constant(),
        requiredFields: [],
        optionalFields: [],
        data: [],
    },
];
export default class OptionsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: false,
            searchQuery: "",
            sort: false,
            favoriteNodes: null,
            optionNodes: optionNodes,
            libraryCategories: libraryCategories,
            supportedLibraryEvents,
        };
    }

    componentDidMount() {
        if (
            this.props.onboardingData &&
            (this.props.onboardingData === 2 ||
                this.props.onboardingData === 10)
        ) {
            this.props.toggleAddingLibraryEvent(false);
            this.props.setOptionPickerTab(Utility);
        }
    }

    onHandleChangeTab = (event) => {
        const id = event.target.id;
        this.props.toggleAddingLibraryEvent(false);
        this.props.setOptionPickerTab(id);
    };

    toggleSort = () => {
        this.setState({ sort: !this.state.sort });
    };

    getResultsFromQuery = (projects, type) => {
        const { searchQuery } = this.state;
        //for the options filter is base on the node name
        if (type === "options") {
            if (searchQuery !== "") {
                const filterByNodes = projects.filter((proj) => {
                    const isTypePresent = proj.type
                        .toUpperCase()
                        .includes(searchQuery.toUpperCase());
                    const isPresentActive =
                        proj.activeNodes &&
                        proj.activeNodes.find((activeNode) => {
                            return activeNode.type
                                .toUpperCase()
                                .includes(searchQuery.toUpperCase());
                        });
                    const isPresentProgress =
                        proj.inProgressNodes &&
                        proj.inProgressNodes.find((activeNode) => {
                            return activeNode.type
                                .toUpperCase()
                                .includes(searchQuery.toUpperCase());
                        });
                    if (isPresentActive || isPresentProgress || isTypePresent) {
                        return proj;
                    }
                });
                return filterByNodes;
            } else if (searchQuery === "") {
                const openedType = [];
                this.state.optionNodes.forEach((node) => {
                    if (node.isOpen) {
                        openedType.push(node.type);
                    }
                });
                const newOptions = optionNodes.map((node) => {
                    if (openedType.includes(node.type)) {
                        const newNode = {
                            ...node,
                            isOpen: true,
                        };
                        return newNode;
                    }
                    return node;
                });

                return newOptions;
            }
        } else {
            if (searchQuery) {
                return projects.filter((project) =>
                    project.type
                        .toUpperCase()
                        .includes(searchQuery.toUpperCase())
                );
            }

            return projects;
        }

        // return projects
    };

    onHandleQuery = (event) => {
        const value = event.target.value;
        this.setState({ searchQuery: value });
    };

    sortBy = (projects) => {
        let newProject;
        if (this.state.sort) {
            newProject = _.reverse(
                _.sortBy(projects, [
                    function (o) {
                        return o.type;
                    },
                ])
            );
        } else {
            newProject = projects;
        }
        return newProject;
    };

    changeFavorite = () => {
        const favoriteNodes =
            JSON.parse(localStorage.getItem("favoriteNodes")) || [];
        this.setState({ favoriteNodes });
    };

    onHandleOptionNodes = (newData) => {
        this.setState({ optionNodes: newData });
    };

    onHandleChangeLibraryCategories = (newData) => {
        this.setState({ libraryCategories: newData });
    };

    handleRemoveFavorite = (node) => {
        const favoriteNodes =
            JSON.parse(localStorage.getItem("favoriteNodes")) || [];
        const isPresent = favoriteNodes.find((fave) => fave.type === node.type);
        if (isPresent) {
            const newFave = favoriteNodes.filter((fave) => {
                return fave.type !== node.type;
            });
            localStorage.setItem("favoriteNodes", JSON.stringify(newFave));
        } else {
            const newFavoriteNodes = [...favoriteNodes, { type: node.type }];
            localStorage.setItem(
                "favoriteNodes",
                JSON.stringify(newFavoriteNodes)
            );
        }
        this.changeFavorite();
    };

    close = () => {
        this.props.onHandleOptionSelected(null);
    };

    navigationBar = (tabSelected) => {
        return (
            <div className={styles?.tabContainer}>
                {/* <div
                    className={
                        tabSelected === Utility ? styles?.selected : undefined
                    }
                    id={Utility}
                    onClick={this.onHandleChangeTab}
                >
                    Utility Events
                </div> */}
                <div
                    className={
                        tabSelected === Options || !tabSelected
                            ? styles?.selected
                            : undefined
                    }
                    id={Options}
                    onClick={this.onHandleChangeTab}
                >
                    Event Options
                </div>
                {/* <div
                    className={
                        tabSelected === Favorite ? styles?.selected : undefined
                    }
                    id={Favorite}
                    onClick={this.onHandleChangeTab}
                >
                    Favorite
                </div>
                <div
                    className={
                        tabSelected === RecentlyUsed
                            ? styles?.selected
                            : undefined
                    }
                    id={RecentlyUsed}
                    onClick={this.onHandleChangeTab}
                >
                    Recently Used
                </div> */}
                {/* <div
                    className={tabSelected === Library && styles.selected}
                    id={Library}
                    onClick={this.onHandleChangeTab}
                >
                    {Library}
                </div> */}
            </div>
        );
    };

    filterBar = () => {
        return (
            <div className={styles?.filterContainer}>
                <input
                    onChange={this.onHandleQuery}
                    className={styles?.filterInput}
                    placeholder={"Search..."}
                />
                <div
                    onClick={this.toggleSort}
                    className={styles?.filterTextContainer}
                >
                    <img src={sortIcon} alt="alt" />
                    <div className={styles?.filterText}>Sort</div>
                </div>
            </div>
        );
    };

    render() {
        const { searchQuery, optionNodes } = this.state;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const recentlyUsed =
            (loggedInUser && loggedInUser.recentlyusednodes) || [];
        const favoriteNodes =
            JSON.parse(localStorage.getItem("favoriteNodes")) || [];
        const utilityNodesFiltered = this.sortBy(
            this.getResultsFromQuery(utilityNodes, "utility")
        );
        const optionsFiltered = searchQuery
            ? this.sortBy(this.getResultsFromQuery(optionNodes, "options"))
            : optionNodes;
        const recentlyUsedFiltered = searchQuery
            ? this.getResultsFromQuery(recentlyUsed, "recentlyUsed")
            : recentlyUsed;
        const favoriteFiltered = searchQuery
            ? this.sortBy(this.getResultsFromQuery(favoriteNodes, "favorite"))
            : favoriteNodes;

        const {
            close,
            addEventType,
            // This is never used
            // loadedScenario,
            onboardingData,
            onHandleOptionSelected,
            optionSelected,
            manager,
            loadScenario,
            getUserScenarios,
            createScenario,
            isBaseline,
            selectedEvent,
            history,
            closeBaselineOption,
            baselineManager,
            isAddingLibraryEvent,
            toggleAddingLibraryEvent,
            editNode,
            edit,
            currentOptionsTab,
        } = this.props;

        // why was this added ???
        // const incomeTax =
        //     loadedScenario &&
        //     loadedScenario.data.nodes.filter((node) => {
        //         return node.metadata.type === "IncomeTax";
        //     });

        if (optionSelected) {
            return (
                <OptionDescription
                    addEventType={addEventType}
                    onHandleOptionSelected={onHandleOptionSelected}
                    optionSelected={optionSelected}
                    close={close}
                    manager={manager}
                    loadScenario={loadScenario}
                    getUserScenarios={getUserScenarios}
                    createScenario={createScenario}
                    onboardingData={onboardingData}
                />
            );
        } else if (selectedEvent) {
            return (
                <InputContainer
                    isAddingLibraryEvent={isAddingLibraryEvent}
                    toggleAddingLibraryEvent={toggleAddingLibraryEvent}
                    isBaseline={isBaseline}
                    history={history}
                    onHandleOptionSelected={addEventType}
                    closeBaselineOption={closeBaselineOption}
                    baselineManager={baselineManager}
                />
            );
        } else if (
            currentOptionsTab === Library &&
            isAddingLibraryEvent &&
            !edit
        )
            return (
                <div className={styles?.libraryOptionsContainer}>
                    <div className={styles?.optionsTitle}>
                        Add a New Library Event
                    </div>

                    {/* {this.filterBar()} */}

                    <div
                        className={styles?.libraryOptionsList}
                        style={{ overflow: "hidden" }}
                    >
                        <LibraryOptionsTab
                            nodes={supportedLibraryEvents}
                            onHandleOptionSelected={onHandleOptionSelected}
                            addEventType={addEventType}
                        />
                    </div>

                    <div className={styles?.libraryOptions}>
                        <div className={styles?.libraryOptionsRow}>
                            <div
                                onClick={() => {
                                    toggleAddingLibraryEvent(false);
                                }}
                                className={styles?.libraryOptionsButton}
                            >
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <>
                    <div className={styles?.optionsTitle}>
                        Add to Your Canvas
                    </div>

                    {/* {this.navigationBar(currentOptionsTab)} */}

                    {/* {this.filterBar()} */}

                    {currentOptionsTab === Utility && (
                        <UtilityTab
                            nodes={utilityNodesFiltered}
                            onHandleOptionSelected={onHandleOptionSelected}
                            addEventType={addEventType}
                            onboardingData={onboardingData}
                            isBaseline={isBaseline}
                        />
                    )}
                    {(currentOptionsTab === Options || !currentOptionsTab) && (
                        <OptionsTab
                            onHandleOptionNodes={this.onHandleOptionNodes}
                            changeFavorite={this.changeFavorite}
                            favoriteNodes={favoriteNodes}
                            nodes={optionsFiltered}
                            onboardingData={onboardingData}
                            isBaseline={isBaseline}
                            IncomeTax={incomeTaxObject.constant()}
                            onHandleOptionSelected={onHandleOptionSelected}
                            addEventType={addEventType}
                        />
                    )}
                    {currentOptionsTab === RecentlyUsed && (
                        <RecentlyUsedTab
                            nodes={recentlyUsedFiltered}
                            onHandleOptionSelected={onHandleOptionSelected}
                            addEventType={addEventType}
                            eventLibrary={this.props.eventLibrary}
                            toggleAddingLibraryEvent={toggleAddingLibraryEvent}
                            editNode={editNode}
                        />
                    )}
                    {currentOptionsTab === Favorite && (
                        <FavoriteTab
                            nodes={favoriteFiltered}
                            onHandleOptionSelected={onHandleOptionSelected}
                            addEventType={addEventType}
                            handleRemoveFavorite={this.handleRemoveFavorite}
                        />
                    )}
                    {currentOptionsTab === Library && (
                        <LibraryTab
                            toggleAddingLibraryEvent={toggleAddingLibraryEvent}
                            eventCategories={this.state.libraryCategories}
                            updateLibraryCategories={
                                this.onHandleChangeLibraryCategories
                            }
                            addEventType={addEventType}
                            eventLibrary={this.props.eventLibrary}
                            editNode={editNode}
                            deleteLibraryEvents={this.props.deleteLibraryEvents}
                            searchQuery={this.state.searchQuery}
                            sortAscending={this.state.sort}
                        />
                    )}
                </>
            );
    }
}

import type { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import { bankObject } from "Components/Registry/Bank";
import { cacObject } from "Components/Registry/CAC";
import { contractObject } from "Components/Registry/Contract";
import { customerObject } from "Components/Registry/Customer";
import { customerChurnObject } from "Components/Registry/CustomerChurn";
import { customerGrowthObject } from "Components/Registry/CustomerGrowth";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { debtObject } from "Components/Registry/Debt";
import { debtRepaymentObject } from "Components/Registry/Debt Repayment";
import { employeeObject } from "Components/Registry/Employee";
import { expenseObject } from "Components/Registry/Expense";
import { expressionObject } from "Components/Registry/Expression";
import { incomeObject } from "Components/Registry/Income";
import { kpiObject } from "Components/Registry/KPI";
import { loanObject } from "Components/Registry/Loan";
import { revenueObject } from "Components/Registry/Revenue";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { Ledger, ledgerMap, ledgerSuperAccountMap } from "./ledgerList";

// Returns all relevant ledger descriptions for DE, given a list of nodes.
export const getRelevantLedgers = (nodes: EventStructure[]) => {
    const allRelevantLedgers: { [key: string]: Ledger } = {};
    nodes.forEach((node) => {
        const relevantLedgers = getRelevantAccountsForNode(node);
        relevantLedgers.forEach((relevantLedger) => {
            // Get all parent/relevant ledgers
            const superLedgerNames = ledgerSuperAccountMap[relevantLedger];
            Object.keys(superLedgerNames).forEach((ledgerName) => {
                if (!allRelevantLedgers[ledgerName]) {
                    allRelevantLedgers[ledgerName] = ledgerMap[ledgerName];
                    const cumName = "Cumulative " + ledgerName;
                    if (ledgerMap[cumName])
                        allRelevantLedgers[cumName] = ledgerMap[cumName];
                }
            });
        });
    });
    if (Object.values(allRelevantLedgers)?.length === 0) return [];
    allRelevantLedgers["Cashflow"] = ledgerMap["Cashflow"];
    allRelevantLedgers["Cumulative Cashflow"] =
        ledgerMap["Cumulative Cashflow"];
    allRelevantLedgers["Calculated Equity"] = ledgerMap["Calculated Equity"];
    allRelevantLedgers["Cumulative Calculated Equity"] =
        ledgerMap["Cumulative Calculated Equity"];
    const ledgers = Object.values(allRelevantLedgers);
    return ledgers;
};

// Returns all relevant Accounts that the given node aggregates to in DE.
const getRelevantAccountsForNode = (node: EventStructure) => {
    switch (node.type) {
        case expressionObject.constant():
        case debitCreditObject.constant():
            return [
                // top level accounts
                "Assets",
                "Liabilities",
                "Income",
                "Expenses",
                "Equity",

                // // sub accounts
                "Employment Expenses",
                "Cash",
                "Paypal",
                "Stripe",
                "Accounts Receivable",
                "Current Assets",
                "Non-Current Assets",
                "Accounts Payable",
                "Current Liabilities",
                "Non-Current Liabilities",
                "Cost of Labor",
                "Cost of Goods Sold",
                "Operating Expenses",
                "Interest Paid",
                "Other Expenses",
                "Other Income" /*"Calculated Equity","Cashflow","Profit"*/,
            ];

        case incomeObject.constant():
            return ["Cash", "Income", "Other Income"];
        case expenseObject.constant():
            return [
                "Expenses",
                "Cash",
                "Cost of Goods Sold",
                "Cost of Labor",
                "Operating Expenses",
                "Employment Expenses",
                "Other Expenses",
                "Interest Paid",
            ];
        case bankObject.constant():
            return ["Liabilities", "Stripe", "Paypal", "Equity"]; // Cumulative only
        case loanObject.constant():
            return ["Cash", "Liabilities", "Interest Paid"];
        case debtObject.constant():
            return [
                "Liabilities",
                "Non-Current Liabilities",
                "Current Liabilities",
                "Expenses",
                "Other Expenses",
                "Interest Paid",
                "Accounts Payable",
            ];
        case debtRepaymentObject.constant():
            return [
                "Liabilities",
                "Cash",
                "Non-Current Liabilities",
                "Expenses",
                "Other Expenses",
                "Interest Paid",
                "Accounts Payable",
            ];
        case employeeObject.constant():
            return ["Cash", "Cost of Labor", "Employment Expenses"];
        case customerObject.constant():
            return ["Customer"]; // Cumulative Only
        case customerGrowthObject.constant():
            return ["Customer", "Customer Growth"];
        case customerChurnObject.constant():
            return ["Customer", "Customer Churn"];
        case unitCostObject.constant():
            return [];
        case revenueObject.constant():
            return ["Cash", "Income", "Cost of Goods Sold"];
        case cacObject.constant():
            return ["Expenses", "Cash"];
        case contractObject.constant():
            return ["Accounts Receivable", "Cash", "Income"];
        case kpiObject.constant():
            return ["ARPU", "LTV", "Profit Margin"];
        default:
            return [];
    }
};

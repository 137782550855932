import { CanvasBaselineContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasBaselineContext";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Group, Text } from "react-konva";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { EventNodeIDContext } from "../../contexts/EventNodeIDContext";
import { EventNodeFocusedContext } from "../../contexts/EventNodeStateContexts";
import { getManager } from "helpers/getManager";
import { shallowEqual } from "react-redux";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { HoveredEventIdContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasHoveredEventContext";
import { CanvasStageScaleContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageScaleContext";

export function EventDescriptiveText() {
    const _isBaseline = useContext(CanvasBaselineContext);

    const eventId = useContext(EventNodeIDContext);

    const focused = useContext(EventNodeFocusedContext);
    const hoveredEventId = useContext(HoveredEventIdContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);
    const stageScale = useContext(CanvasStageScaleContext);

    const eventObject = useAppSelector(
        (state) => getManager(_isBaseline, state)?.nodes?.[eventId] ?? {},
        shallowEqual
    );
    const textRef = useRef<any>(null);

    const textValue: string = eventObject?.name ?? "";
    const [textRealWidth, setTextRealWidth] = useState(0);

    useEffect(() => {
        if (textValue && zoomCategory) {
            const _textRealWidth = textRef.current?.getTextWidth?.();
            setTextRealWidth(_textRealWidth);
        }
    }, [textValue, zoomCategory]);

    const textStyles = useMemo(() => {
        let _textStyles = {
            x: textRealWidth ? -(textRealWidth / 2) : 0,
            y: 15,
            fontSize: 11.5,
            fontFamily: "CeraPro-Regular",
            fill: "#4C4C4C",
            visible: true,
            ellipsis: true,
            width: 180,
            wrap: "none",
        };
        if (zoomCategory === "zoom-xs") {
            _textStyles = {
                ..._textStyles,
                visible: false,
            };
        }
        if (zoomCategory === "zoom-s") {
            _textStyles = {
                ..._textStyles,
                y: 5,
                width: 105,
            };
        }
        if (zoomCategory === "zoom-m") {
            _textStyles = {
                ..._textStyles,
                width: 150,
            };
        }
        if (zoomCategory === "zoom-l") {
            _textStyles = {
                ..._textStyles,
                fontSize: 13,
                width: 200,
            };
        }
        if (zoomCategory === "zoom-xl") {
            _textStyles = {
                ..._textStyles,
                fontSize: 13,
                width: 250,
            };
        }
        if (hoveredEventId === eventId) {
            _textStyles = {
                ..._textStyles,
                fill: "#4B86DC",
                visible: true,
            };
        }
        if (focused) {
            _textStyles = {
                ..._textStyles,
                visible: false,
            };
        }
        return _textStyles;
    }, [textRealWidth, zoomCategory, hoveredEventId, eventId, focused]);

    return (
        <Group scaleX={1 / stageScale} scaleY={1 / stageScale}>
            <Text
                ref={textRef}
                text={eventObject?.name ?? ""}
                {...textStyles}
            />
        </Group>
    );
}

import Checkbox from "@mui/material/Checkbox";
import styles from "./checkboxStyles.module.css";
import { useMemo } from "react";
export const CheckBoxCellRenderer = (props) => {
    const editable = props?.colDef?.editable(props);

    const handleCheck = () => {
        if (!editable) return;
        const checked = !!props?.value;
        const colId = props.column.colId;

        props.node.setDataValue(colId, !checked);
    };

    const checked = useMemo(() => {
        const value = props?.value;

        if (typeof value === "boolean") {
            return value;
        } else if (typeof value === "string") {
            if (value === "true") return true;
            if (value === "false") return false;
        }

        return false;
    }, [props]);

    return (
        <div className={styles?.checkboxCellContainer}>
            <Checkbox
                onClick={handleCheck}
                checked={checked}
                sx={{
                    padding: "0",
                    paddingTop: "2px",
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                    "&.Mui-checked": {
                        color: editable ? "blue" : "rgb(128, 128, 128)",
                    },
                }}
            />
        </div>
    );
};

import { Group } from "react-konva";

/**
 * Anything underneath the EventNodeBody (as of this writing, no idea what would go here).
 */
export const MenuLayerOne = Group;
/**
 * The bottom-layer VISUAL EventNodeBody. It cannot be interacted with. It is only there for visual representation and to give shape to the event. Should only contain this one component.
 */
export const MenuLayerTwo = Group;
/**
 * Any visual elements within the EventNodeBody. Can have multiple items in here. Potentially create a group of its own or a component of its own.
 */
export const MenuLayerThree = Group;

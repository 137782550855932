import * as uuid from "uuid";
import store from "store";
import { upsertAllAccountLedgers } from "actions/allAccountLedgersActions";
import { updateAccountsAPI } from "actions/accountsActions";
import { cloneDeep } from "lodash";
import { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { getObjectFromUUID } from "./getObjectFromUUID";
import {
    LedgerMetadata,
    Ledgers,
} from "reducers/typesSchema/allAccountLedgersSchema";
import { projectsObject } from "Components/Registry/Projects";

type Account = [prefix: string, id: string];

interface AccountRoot {
    topLevel: string;
    topLevelChildren: string[];
}

interface CustomAccountRoot {
    topLevel: Account;
    topLevelChildren: Account[];
}

interface CustomAccountUnit {
    beforeValue: boolean;
    display: string;
    singular: string;
    type: string;
    spacingBetween: boolean;
    nearestDecimal: number;
}

// This interface only supports custom accounts to a depth of 2. For example we can have an account
// 'Customer - Cohort 1' that is nested under the top level default account `Customers`, this would be one level.
// Then we could have an account `Growth Customer - Cohort 1` which is nested under `Customer - Cohort 1`, this would
// be two levels. We can have an arbitrary number of accounts at depth 1 (which nest under the default account), and an
// arbitrary number of accounts at depth 2 (which nest under a depth 1 account).
export interface EntityCustomAccount {
    Name: string;
    Type: string;
    Display: string;
    Root: AccountRoot;
    customRoots: CustomAccountRoot[];
}

const displayAccountsId = "5a508f09-cf61-4207-8950-28a32be3a0d8";
const employeesTopLevelAccount = "e18faebd-5cb1-40ac-8c18-442943677d4a";
const employeesNeededTopLevel = "852b5a0b-2a32-4817-867c-cca92295141b";
const capacityTopLevel = "8175db4a-66e9-4cd6-866b-b8ae06bddd74";

export const submitLedgerAccount = async (
    parentAccount: { name: string; ids: string[] },
    accountName: string,
    accountUnit?: CustomAccountUnit
) => {
    const parentAccountId = parentAccount.ids[parentAccount.ids.length - 1];
    await submitAccount(accountName, parentAccountId, accountUnit);
};

export const submitDisplayAccount = async (
    accountName: string,
    accountUnit?: CustomAccountUnit
) => {
    await submitAccount(accountName, displayAccountsId, accountUnit);
};

// This function returns the parent account ID of the given accounts shadow account equivalent, if it exists.
// Eg. if you pass in the account 'Jr. Compositors' this will return either 'Compositors Needed' or 'Compositors Capacity'
const getShadowParentAccount = (
    employeesSubAccount: LedgerMetadata,
    shadowAccountTopLevel: string,
    allAccountLedgers: Ledgers
): string => {
    const isEmployeesNeeded = shadowAccountTopLevel === employeesNeededTopLevel;
    const subAccountParent = allAccountLedgers[employeesSubAccount.parents[0]];
    if (subAccountParent?.shadowIds)
        return isEmployeesNeeded
            ? subAccountParent.shadowIds[0]
            : subAccountParent.shadowIds[1];
    return shadowAccountTopLevel;
};

// Creates the shadow accounts for Employees Needed and Capacity top level accounts
const createEmployeeShadowAccounts = (
    newAccount: LedgerMetadata,
    allAccountLedgers: Ledgers
): { parentId: string; accountData: LedgerMetadata } => {
    // Capacity
    let capacityParentAccount = capacityTopLevel;
    if (newAccount.parents[0] !== employeesTopLevelAccount) {
        capacityParentAccount = getShadowParentAccount(
            newAccount,
            capacityTopLevel,
            allAccountLedgers
        );
    }

    const newCapacityAccount: LedgerMetadata = {
        ...newAccount,
        id: newAccount.shadowIds ? newAccount.shadowIds[1] : uuid.v4(),
        name: `${newAccount.name} - Capacity`,
        parents: [capacityParentAccount],
        children: [],
    };
    delete newCapacityAccount.shadowIds;

    return { parentId: capacityParentAccount, accountData: newCapacityAccount };
};

const copyAccountStruct = (
    allAccountLedgers: Ledgers,
    oldRootId: string,
    newRootId: string,
    prefix?: string
) => {
    const oldAccount = allAccountLedgers[oldRootId];
    const newAccount = allAccountLedgers[newRootId];

    for (const child of oldAccount.children) {
        const childAccount = allAccountLedgers[child];

        const newId = uuid.v4();
        const name = prefix
            ? `${prefix}${childAccount.name}`
            : childAccount.name;

        // TODO: Generalize the unit field, currently only works for employees accounts
        const newChildAccount: LedgerMetadata = {
            id: newId,
            name,
            parents: [newRootId],
            children: [],
            alwaysDisplayed: childAccount.alwaysDisplayed,
            type: childAccount.type,
            monthlyCumulativeText: { ...childAccount.monthlyCumulativeText },
            unit: {
                display: "Employees",
                spacingBetween: true,
                singular: "Employee",
                beforeValue: false,
                nearestDecimal: 1,
            },
        };
        allAccountLedgers[newId] = newChildAccount;

        newAccount.children.push(newId);
        copyAccountStruct(allAccountLedgers, child, newId, prefix);
    }
};

const submitAccount = async (
    accountName: string,
    parentAccountId?: string,
    accountUnit?: CustomAccountUnit
) => {
    // data from accountUnit or ledger defaults
    let type = accountUnit?.type ?? "ledger";
    const display = accountUnit?.display ?? "$";
    const singular = accountUnit?.singular ?? "$";
    const spacingBetween = accountUnit?.spacingBetween ?? false;
    const nearestDecimal = accountUnit?.nearestDecimal ?? 2;
    const beforeValue = accountUnit?.beforeValue ?? true;

    // No parent account Id indicates display account
    if (!parentAccountId) {
        parentAccountId = displayAccountsId;
    }

    const topLevelAccountId = getTopLevelAccount(parentAccountId);
    const isEmployeesAccount = topLevelAccountId === employeesTopLevelAccount;

    if (isEmployeesAccount || parentAccountId === displayAccountsId) {
        type = "display";
    }

    const allAccountLedgers = {
        ...store.getState().allAccountLedgers.ledgersMetadata,
    };

    const newId = uuid.v4();
    const newAccount: LedgerMetadata = {
        id: newId,
        name: accountName,
        parents: [parentAccountId],
        children: [],
        alwaysDisplayed: false,
        type: type,
        monthlyCumulativeText: {
            monthly: "per month",
            cumulative: "total",
        },
        unit: {
            display,
            spacingBetween,
            singular,
            beforeValue,
            nearestDecimal,
        },
    };
    // Index 0 refers to 'Employees Needed', Index 1 refers to 'Capacity'
    if (isEmployeesAccount) newAccount.shadowIds = [uuid.v4(), uuid.v4()];

    const updatedParentAccount = { ...allAccountLedgers[parentAccountId] };
    updatedParentAccount.children.push(newId);

    allAccountLedgers[parentAccountId] = updatedParentAccount;
    allAccountLedgers[newId] = newAccount;

    // If we create a custom account under the Employees account we then need to
    // create an additional account under the Capacity top level account
    if (isEmployeesAccount) {
        const shadowAccount = createEmployeeShadowAccounts(
            newAccount,
            allAccountLedgers
        );

        const updatedShadowParentAccount = {
            ...allAccountLedgers[shadowAccount.parentId],
        };
        updatedShadowParentAccount.children.push(shadowAccount.accountData.id);

        allAccountLedgers[shadowAccount.parentId] = updatedShadowParentAccount;
        allAccountLedgers[shadowAccount.accountData.id] =
            shadowAccount.accountData;
    }

    store.dispatch(
        upsertAllAccountLedgers({ ledgersMetadata: allAccountLedgers })
    );
    await updateAccountsAPI(allAccountLedgers);
};

export const getTopLevelAccount = (accountId: string) => {
    if (!accountId) {
        return null;
    }

    const allAccountLedgers = {
        ...store.getState().allAccountLedgers.ledgersMetadata,
    };

    let currentAcc = allAccountLedgers[accountId];

    if (!currentAcc) return null;

    while (currentAcc.parents.length > 0) {
        currentAcc = allAccountLedgers[currentAcc.parents[0]];
    }

    return currentAcc.id;
};

export const getAccountIds = (accountName) => {
    const ledgersMetadata = {
        ...store.getState().allAccountLedgers.ledgersMetadata,
    };

    for (const ledgerData of Object.values(ledgersMetadata)) {
        if (accountName == ledgerData.name) {
            const accountId = ledgerData.id;

            const accounts: string[] = [];
            let currentAcc = ledgersMetadata[accountId];
            accounts.unshift(currentAcc.id);

            while (currentAcc.parents.length > 0) {
                currentAcc = ledgersMetadata[currentAcc.parents[0]];
                accounts.unshift(currentAcc.id);
            }

            return accounts;
        }
    }

    return null;
};

//                       ***********************************************************************
//                       ** Beginning of general custom account framework for events/entities **
//                       ***********************************************************************

// Returns account/ledger with given ID, if it doesn't exist returns undefined.
export const getLedger = (id: string) => {
    const allAccountData = store.getState().allAccountLedgers.ledgersMetadata;
    return allAccountData[id];
};

export const recurseToTopAccount = (id: string) => {
    const allAccountData = store.getState().allAccountLedgers.ledgersMetadata;
    if (id == "") {
        return [];
    }

    const accounts: string[] = [];
    let currentAcc = allAccountData[id];
    accounts.unshift(currentAcc.id);
    while (currentAcc.parents.length > 0) {
        currentAcc = allAccountData[currentAcc.parents[0]];
        accounts.unshift(currentAcc.id);
    }
    return accounts;
};

// Create a path of ledger ids from the given ledgers top-most parent to itself
const getParents = (
    ledger: LedgerMetadata,
    accountsAndLedgers: Ledgers,
    pathFromTop: string[]
) => {
    if ((ledger?.parents?.length ?? 0) >= 1) {
        ledger.parents.forEach((parentUUID) => {
            const parentLedger = accountsAndLedgers[parentUUID];
            getParents(parentLedger, accountsAndLedgers, pathFromTop);
        });
    }
    pathFromTop.push(ledger.id);
};

// Construct all account paths from the given accountStructure
export const getPaths = (accountStructures: EntityCustomAccount[]) => {
    const accountPaths: string[][] = [];
    const accountsAndLedgers =
        store.getState().allAccountLedgers.ledgersMetadata;

    // Construct each path from the accountStructure
    for (const accountStructure of accountStructures) {
        for (const topLevelChild of accountStructure.Root.topLevelChildren) {
            const pathFromTop: string[] = [];
            getParents(
                accountsAndLedgers[accountStructure.Root.topLevel],
                accountsAndLedgers,
                pathFromTop
            );
            accountPaths.push([...pathFromTop, topLevelChild]);
            for (const customRoot of accountStructure.customRoots) {
                if (customRoot.topLevel[1] === topLevelChild) {
                    for (const customTopLevelChild of customRoot.topLevelChildren) {
                        accountPaths.push([
                            ...pathFromTop,
                            topLevelChild,
                            customTopLevelChild[1],
                        ]);
                    }
                }
            }
        }
    }

    return accountPaths;
};

export const getTopLevelGrownChurn = (topLevelId: string) => {
    const accountsAndLedgers =
        store.getState().allAccountLedgers.ledgersMetadata;

    let grown = "";
    let churn = "";

    for (const child of accountsAndLedgers[topLevelId].children) {
        const childLedger = accountsAndLedgers[child];
        if (childLedger.name.split(" ")[0] === "Grown") grown = childLedger.id;
        if (childLedger.name.split(" ")[0] === "Churned")
            churn = childLedger.id;

        if (grown !== "" && churn !== "") break;
    }

    return [grown, churn];
};

// Creates all account paths for the various customer sub accounts
export const getCustomerSubAccountPaths = (
    topLevelId: string,
    customerId: string,
    grownId: string,
    churnedId: string,
    transInId: string,
    transOutId: string
) => {
    const accountsAndLedgers =
        store.getState().allAccountLedgers.ledgersMetadata;
    const ledger = accountsAndLedgers[topLevelId];

    const grownPath: string[] = [];
    const churnedPath: string[] = [];
    const transferredInPath: string[] = [];
    const transferredOutPath: string[] = [];

    grownChurnedHelper(
        ledger,
        accountsAndLedgers,
        grownPath,
        churnedPath,
        transferredInPath,
        transferredOutPath
    );

    return [
        [...grownPath, customerId, grownId],
        [...churnedPath, customerId, churnedId],
        [...transferredInPath, customerId, transInId],
        [...transferredOutPath, customerId, transOutId],
    ];
};

const grownChurnedHelper = (
    ledger: LedgerMetadata,
    accountsAndLedgers: Ledgers,
    grownPath: string[],
    churnedPath: string[],
    transferredInPath: string[],
    transferredOutPath: string[]
) => {
    if ((ledger?.parents?.length ?? 0) >= 1) {
        ledger.parents.forEach((parentUUID) => {
            const parentLedger = accountsAndLedgers[parentUUID];
            grownChurnedHelper(
                parentLedger,
                accountsAndLedgers,
                grownPath,
                churnedPath,
                transferredInPath,
                transferredOutPath
            );
        });
    }

    grownPath.push(ledger.id);
    churnedPath.push(ledger.id);
    transferredInPath.push(ledger.id);
    transferredOutPath.push(ledger.id);

    // TODO: break out of loop once we find all relevant accounts
    for (const child of ledger.children) {
        const childLedger = accountsAndLedgers[child];
        const splitName = childLedger.name.split(" ");

        if (splitName[0] === "Grown") {
            grownPath.push(childLedger.id);
        }

        if (splitName[0] === "Churned") {
            churnedPath.push(childLedger.id);
        }

        if (`${splitName[0]} ${splitName[1]}` === "Transferred In") {
            transferredInPath.push(childLedger.id);
        }

        if (`${splitName[0]} ${splitName[1]}` === "Transferred Out") {
            transferredOutPath.push(childLedger.id);
        }
    }
};

// grab all the customer custom account ids from deleted entities so we can delete them from the list of custom accounts later
export const getAccountsToDelete = (
    newEntitiesMap: EntitiesSchema,
    eventData: EventStructure
) => {
    const accountsToDelete: EntityCustomAccount[][] = [];

    const event = getEvent(eventData.id);
    const entities = event?.entities || [];

    for (const entityData of entities) {
        if (!(entityData.id in newEntitiesMap)) {
            const entity = getRelevantEntities([entityData.id])[entityData.id];
            accountsToDelete.push(entity?.data?.accountStructure);
        }
    }

    return accountsToDelete;
};

// Handles the Creation, update, and deletion of custom accounts
export const handleCustomAccountStructures = (
    passedCheck: boolean,
    entitiesMap: EntitiesSchema,
    createAccountStructure: (entityData: EntitySchema) => EntitySchema,
    onHandleSubmit: (
        entitiesMap: EntitiesSchema,
        partialValue: boolean
    ) => void,
    accountsToDelete: EntityCustomAccount[][]
) => {
    // Entities that need their custom accounts created or updated
    const changedEntities: EntitySchema[] = [];

    const newEntitiesMap = { ...entitiesMap };

    for (const entity of Object.values(entitiesMap)) {
        const entityObject = getObjectFromUUID(entity.type);
        if (entityObject.checkInput({ [entity.type]: entity })) {
            if (!entity.data?.accountStructure) {
                // create the new custom customer accounts
                const newEntity = createAccountStructure(entity);
                newEntitiesMap[newEntity.id] = newEntity;

                changedEntities.push(newEntity);
            } else if (entity.data?.accountStructure[0]?.Name !== entity.name) {
                // update the custom customer account names
                const currentEntityObject = {
                    ...(newEntitiesMap[entity.id] || {}),
                };
                const data = { ...(currentEntityObject?.data || {}) };

                for (const accountStructure of data.accountStructure) {
                    accountStructure.Name = entity.name;
                }

                currentEntityObject.data = data;
                newEntitiesMap[entity.id] = currentEntityObject;

                changedEntities.push(currentEntityObject);
            }
        }
    }

    deleteAndCreateCustomAccounts(changedEntities, accountsToDelete).then(
        () => {
            if (changedEntities.length == 0 && accountsToDelete?.length == 0) {
                onHandleSubmit(newEntitiesMap, passedCheck);
            } else {
                // Not sure if this is needed anymore since we use async/await
                setTimeout(() => {
                    onHandleSubmit(newEntitiesMap, passedCheck);
                }, 1100 + 100 * (changedEntities.length - 1));
            }
        }
    );
};

// Performs the actual creation, update, or deletion of custom accounts
export const deleteAndCreateCustomAccounts = async (
    changedEntities: EntitySchema[],
    accountsToDelete: EntityCustomAccount[][]
) => {
    const allAccountLedgers = {
        ...store.getState().allAccountLedgers.ledgersMetadata,
    };
    const relevantLedgers = [
        ...store.getState().allAccountLedgers.relevantLedgers,
    ];

    const newRelevantLedgers: { name: string; id: string }[] = [];

    // Delete custom accounts
    if (accountsToDelete?.length) {
        const allCustomAccounts: string[] = [];
        const topLevelCustomAccounts: string[] = [];

        // Iterate through each account structure to create list of all accounts up for removal
        for (const accountStructures of accountsToDelete) {
            for (const accountStructure of accountStructures) {
                topLevelCustomAccounts.push(
                    ...accountStructure.Root.topLevelChildren
                );
                allCustomAccounts.push(
                    ...accountStructure.Root.topLevelChildren
                );
                for (const customRoot of accountStructure.customRoots) {
                    allCustomAccounts.push(
                        ...customRoot.topLevelChildren.map(
                            (account) => account[1]
                        )
                    );
                }
            }
        }

        // Create new relevant ledgers that filter soon to be deleted accounts
        for (const ledger of relevantLedgers) {
            if (!allCustomAccounts?.includes(ledger.id)) {
                newRelevantLedgers.push(cloneDeep(ledger));
            }
        }

        // delete accounts from all ledgers
        for (const id of allCustomAccounts) {
            if (id in allAccountLedgers) {
                delete allAccountLedgers[id];
            }
        }

        // detach accounts from children
        // TODO: move this loop into the above accountStructures loop
        for (const accountStructures of accountsToDelete) {
            for (const accountStructure of accountStructures) {
                const topLevelDefaultAccount: string =
                    accountStructure.Root.topLevel;

                const topLevelCustomerAcc = {
                    ...allAccountLedgers[topLevelDefaultAccount],
                };
                const newChildren: string[] = [];

                for (const childId of topLevelCustomerAcc.children) {
                    if (!topLevelCustomAccounts.includes(childId)) {
                        newChildren.push(childId);
                    }
                }

                topLevelCustomerAcc.children = newChildren;
                allAccountLedgers[topLevelDefaultAccount] = topLevelCustomerAcc;
            }
        }
    }

    // Create custom accounts
    for (const entity of changedEntities) {
        const accountStructures: EntityCustomAccount[] =
            entity?.data?.accountStructure;

        for (const accountStructure of accountStructures) {
            const topLevelDefaultAccount: string =
                accountStructure.Root.topLevel;
            const updatedParentAccount = {
                ...allAccountLedgers[topLevelDefaultAccount],
            };

            for (const parentAccountId of accountStructure.Root
                .topLevelChildren) {
                const parentAccountStructure =
                    accountStructure.customRoots.find((customRoot) => {
                        return customRoot.topLevel[1] === parentAccountId;
                    });

                if (parentAccountStructure) {
                    const prefix = parentAccountStructure.topLevel[0];
                    const entityName = accountStructure.Name;
                    const parentAccountName = `${prefix}${entityName}`;

                    // This case updates the account name
                    if (parentAccountId in allAccountLedgers) {
                        if (
                            allAccountLedgers[parentAccountId].name ===
                            parentAccountName
                        ) {
                            continue;
                        }

                        const parentAccount = {
                            ...allAccountLedgers[parentAccountId],
                        };
                        parentAccount.name = parentAccountName;
                        allAccountLedgers[parentAccountId] = parentAccount;

                        for (const child of parentAccountStructure.topLevelChildren) {
                            const [childPrefix, childId] = child;
                            const childAccountName = `${childPrefix}${parentAccountName}`;

                            const childAccount = {
                                ...allAccountLedgers[childId],
                            };
                            childAccount.name = childAccountName;
                            allAccountLedgers[childId] = childAccount;
                        }
                    }
                    // This case creates the account
                    else {
                        const childrenIds =
                            parentAccountStructure.topLevelChildren.map(
                                (child) => child[1]
                            );
                        const display = accountStructure.Display;

                        const newParentAccount = {
                            id: parentAccountId,
                            name: parentAccountName,
                            parents: [topLevelDefaultAccount],
                            children: childrenIds,
                            alwaysDisplayed: false,
                            type: accountStructure.Type,
                            monthlyCumulativeText: {
                                monthly: "per month",
                                cumulative: "total",
                            },
                            unit: {
                                display: `${display}`,
                                spacingBetween: true,
                                singular: display,
                                beforeValue: false,
                                nearestDecimal: 1,
                            },
                        };

                        allAccountLedgers[parentAccountId] = newParentAccount;
                        updatedParentAccount.children.push(parentAccountId);

                        // If we have a projects entity we need to copy the entire employees
                        // account structure as a sub-account under this newly created account
                        if (entity.type === projectsObject.constant()) {
                            const employeesTopLevelId =
                                "e18faebd-5cb1-40ac-8c18-442943677d4a";
                            copyAccountStruct(
                                allAccountLedgers,
                                employeesTopLevelId,
                                parentAccountId,
                                prefix
                            );
                        }

                        for (const child of parentAccountStructure.topLevelChildren) {
                            const [childPrefix, childId] = child;
                            const childAccountName = `${childPrefix}${parentAccountName}`;

                            const newChildAccount = {
                                id: childId,
                                name: childAccountName,
                                parents: [parentAccountId],
                                children: [],
                                alwaysDisplayed: false,
                                type: accountStructure.Type,
                                monthlyCumulativeText: {
                                    monthly: "per month",
                                    cumulative: "total",
                                },
                                unit: {
                                    display: `${display}s`,
                                    spacingBetween: true,
                                    singular: display,
                                    beforeValue: false,
                                    nearestDecimal: 1,
                                },
                            };

                            allAccountLedgers[childId] = newChildAccount;
                        }
                        allAccountLedgers[topLevelDefaultAccount] =
                            updatedParentAccount;
                    }
                }
            }
        }
    }

    // Update redux state
    store.dispatch(
        upsertAllAccountLedgers({
            ledgersMetadata: allAccountLedgers,
            relevantLedgers: newRelevantLedgers,
        })
    );

    // Update database
    await updateAccountsAPI(allAccountLedgers);
};

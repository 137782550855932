// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import CustomerGrowthCard from "../Card/customerGrowthCard";

// Import Connection Image (if exists)
import CustomerGrowthConnection from "Assets/_optionDescriptionIcons/Customer, Growth, Churn white.jpg";

// Import Coloured PNGs

// TODO Grab pngs from Michelle or Jon
import customerGrowthBlack from "../../Assets/_eventNodeTypes/customer-growth-black.svg";
import customerGrowthBlue from "../../Assets/_eventNodeTypes/growth-blue.png";
import customerGrowthWhite from "../../Assets/_eventNodeTypes/growth-white.png";
import customerGrowthGrey from "../../Assets/_eventNodeTypes/growth-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import CustomerGrowthInputExperimental from "Components/InputContainer/CustomerGrowthInputExperimental/CustomerGrowthInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - customerObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - customerObject.checkInput.call(customerObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const customerGrowthObject = {
    constant: function () {
        return CustomerGrowth_Constant();
    },

    name: function () {
        return "Customer Growth";
    },

    checkInput: function (state) {
        return CustomerGrowth_CheckInput(state);
    },

    svg: function () {
        return CustomerGrowth_Svg();
    },

    eventNodeMenu: function (focus) {
        return CustomerGrowth_EventNodeMenu(focus);
    },

    indexText: function () {
        return CustomerGrowth_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return CustomerGrowth_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return CustomerGrowth_OptionDescription();
    },

    colouredPngs: function () {
        return CustomerGrowth_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return CustomerGrowth_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return CustomerGrowth_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const CustomerGrowth_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (
            !entity.name ||
            !entity.data.customerEventId ||
            !entity.data.subType
        ) {
            return false;
        }
        if (!entity.data.inheritStartDate && !entity.startDate) {
            return false;
        }
        if (entity.data.subType == "constant" && !entity.data.constantValue) {
            return false;
        }
        if (
            entity.data.subType == "dynamic" &&
            entity.data.specificPercentages?.length < 2
        )
            return false;
        if (
            entity.data.subType == "dynamic" &&
            (!entity.data.interpolateSubType ||
                !entity.data.specificPercentages.reduce(function (acc, obj) {
                    return acc && obj.month && obj.year && obj.value;
                }, true))
        ) {
            return false;
        }
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const CustomerGrowth_Constant = () => {
    return "cdc081a9-09a4-4422-8803-e80a04b617a3";
};

// EFFECTS: Returns black png / svg for event
const CustomerGrowth_Svg = () => {
    return customerGrowthBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const CustomerGrowth_EventNodeMenu = (focus) => {
    return <CustomerGrowthCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const CustomerGrowth_IndexText = () => {
    return "The Customer Growth Event alters an existing upstream Customer Event. The number of initial customers gained per month is first specified. This gets added to the Customer count each month. The growth rates specify how this Customer/month value increases or decreases over time.";
};

// EFFECTS: Returns all icons for event ??
const CustomerGrowth_Icons = () => {
    let pngs = new Map([
        ["black", customerGrowthBlack],
        ["blue", customerGrowthBlue],
        ["white", customerGrowthWhite],
        ["grey", customerGrowthGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const CustomerGrowth_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const CustomerGrowth_InputComponentInfoPassing = (minProps) => {
    return <CustomerGrowthInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const CustomerGrowth_OptionDescription = () => {
    const tags = ["Option"];
    const type = customerGrowthObject.name();
    const description =
        "The Customer Growth Event calculates month-over-month growth for a Customer cohort or segment. This event supports a constant or a dynamic growth rate that can change over time.";
    const usedForText =
        "Use the Customer Growth Event, along with the Customer, Churn, Unit Cost and Revenue Events, to model out various subscription and SaaS models.";
    const connection = "";
    const connectionImage = CustomerGrowthConnection;
    const learnMoreLink =
        "https://www.investopedia.com/terms/g/growthrates.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const CustomerGrowth_SideModalImage = () => {
    return sideImage;
};

const CustomerGrowth_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import { bankObject } from "Components/Registry/Bank";
import { bankAltObject } from "Components/Registry/BankAlt";
import { bankPlaidObject } from "Components/Registry/BankPlaid";
import { budgetObject } from "Components/Registry/Budget";
import { cacObject } from "Components/Registry/CAC";
import { capitalGainsObject } from "Components/Registry/Capital Gains";
import { closingCostsObject } from "Components/Registry/Closing Costs";
import { customerObject } from "Components/Registry/Customer";
import { customerChurnObject } from "Components/Registry/CustomerChurn";
import { customerGrowthObject } from "Components/Registry/CustomerGrowth";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { debtObject } from "Components/Registry/Debt";
import { debtRepaymentObject } from "Components/Registry/Debt Repayment";
import { decisionObject } from "Components/Registry/Decision";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { employeeObject } from "Components/Registry/Employee";
import { equityObject } from "Components/Registry/Equity";
import { expenseObject } from "Components/Registry/Expense";
import { goalObject } from "Components/Registry/Goal";
import { graduationObject } from "Components/Registry/Graduation";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { grantObject } from "Components/Registry/Grant";
import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
import { houseObject } from "Components/Registry/House";
import { incomeObject } from "Components/Registry/Income";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { investmentObject } from "Components/Registry/Investment";
import { loanObject } from "Components/Registry/Loan";
import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
import { maternityObject } from "Components/Registry/Maternity";
import { meObject } from "Components/Registry/Me";
import { modifierObject } from "Components/Registry/Modifier";
import { mortgageObject } from "Components/Registry/Mortgage";
import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
import { nullObject } from "Components/Registry/Null";
import { containerObject } from "Components/Registry/Container";
import { pensionObject } from "Components/Registry/Pension";
import { personObject } from "Components/Registry/Person";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";
import { propertyValueObject } from "Components/Registry/Property Value";
import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";
import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";
import { rentObject } from "Components/Registry/Rent";
import { rentalIncomeObject } from "Components/Registry/Rental Income";
import { retirementObject } from "Components/Registry/Retirement";
import { revenueObject } from "Components/Registry/Revenue";
import { rrspObject } from "Components/Registry/RRSP";
import { shortTermRentalObject } from "Components/Registry/Short Term Rental";
import { startObject } from "Components/Registry/Start";
import { studentBudgetObject } from "Components/Registry/Student Budget";
import Student_IncomeObject from "Components/Registry/Student Income";
import { studentLoanObject } from "Components/Registry/Student Loan";
import { taxDeductibleObject } from "Components/Registry/Tax Deductible";
import { tuitionObject } from "Components/Registry/Tuition";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { condoFeeObject } from "Components/Registry/CondoFee";
import { contractObject } from "Components/Registry/Contract";
import { businessObject } from "Components/Registry/Business";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import { kpiObject } from "Components/Registry/KPI";
import { instanceObject } from "Components/Registry/Instance";
import { modifier2Object } from "Components/Registry/Modifier2";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { expressionObject } from "Components/Registry/Expression";
import { importObject } from "Components/Registry/Import";
import { accountImportObject } from "Components/Registry/AccountImport";
import { growthObject } from "Components/Registry/Growth";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { salespersonObject } from "Components/Registry/Salesperson";
import { segmentObject } from "Components/Registry/Segment";
import { customer2Object } from "Components/Registry/Customer2";
import { customerGrowth2Object } from "Components/Registry/CustomerGrowth2";
import { customerChurn2Object } from "Components/Registry/CustomerChurn2";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { campaignObject } from "Components/Registry/Campaign";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { CAC2Object } from "Components/Registry/CAC2";
import { allocationObject } from "Components/Registry/Allocation";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { percentageObject } from "Components/Registry/Percentage";
import { resourceObject } from "Components/Registry/Resource";
import { capacityObject } from "Components/Registry/Capacity";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { unitObject } from "Components/Registry/Unit";
import { projectsObject } from "Components/Registry/Projects";
import { utilizationObject } from "Components/Registry/Utilization";

// Main registry for event objects
export const events = [
    accountImportObject,
    bankObject,
    bankAltObject,
    bankPlaidObject,
    budgetObject,
    capitalGainsObject,
    closingCostsObject,
    customerObject,
    projectsObject,
    customer2Object,
    customerChurnObject,
    customerGrowthObject,
    customerGrowth2Object,
    customerChurn2Object,
    customerTransfer2Object,
    debitCreditObject,
    debtRepaymentObject,
    debtObject,
    decisionObject,
    downpaymentObject,
    employeeObject,
    equityObject,
    expenseObject,
    growthObject,
    goalObject,
    graduationObject,
    grantObject,
    groupObject,
    projectObject,
    homeMaintenanceObject,
    houseObject,
    importObject,
    incomeTaxObject,
    incomeObject,
    initialBalanceObject,
    investmentObject,
    loanObject,
    lumpSumPaymentObject,
    maternityObject,
    meObject,
    businessObject,
    modifierObject,
    mortgageInsuranceObject,
    mortgageObject,
    nullObject,
    containerObject,
    outboundSalesObject,
    campaignObject,
    CAC2Object,
    pensionObject,
    personObject,
    propertyResaleObject,
    propertyTransferTaxObject,
    propertyValueObject,
    realEstateFeeObject,
    renewalRefinanceObject,
    rentObject,
    rentalIncomeObject,
    retirementObject,
    revenueObject,
    rrspObject,
    salespersonObject,
    segmentObject,
    allocationObject,
    shortTermRentalObject,
    startObject,
    studentBudgetObject,
    Student_IncomeObject,
    studentLoanObject,
    taxDeductibleObject,
    tuitionObject,
    unitCostObject,
    condoFeeObject,
    cacObject,
    contractObject,
    customerTransferObject,
    kpiObject,
    instanceObject,
    modifier2Object,
    expressionObject,
    websiteVisitorsObject,
    constraintObject,
    accountModifierObject,
    percentageObject,
    resourceObject,
    utilizationObject,
    capacityObject,
    averageOrderObject,
    accountExpressionObject,
    unitObject,
];

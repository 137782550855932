import React, { Component } from "react";
import "./index.css";
import swal from "sweetalert";

class ChangePTTValues extends Component {
    state = {};

    onHandleInput = (e) => {
        const value = e.target.value / 100;
        this.setState({ inflation: value.toFixed(4) });
    };

    componentDidMount() {
        this.setState({
            ...this.props.pttValues,
        });
    }

    changeValue = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        this.setState({ [`${id}`]: Number(value) });
    };

    onHandleUpdate = () => {
        console.log("update");
        this.props
            .updateAdminValues("PTTValues", this.state)
            .then((_data) => {
                swal({
                    icon: "success",
                    title: "Congrats Jon!",
                    text: "you successfully updated all ptt values:)",
                });
            })
            .catch((_err) => {
                swal({
                    icon: "error",
                    title: "Ohhh Uhhh!",
                    text: "something went wrong",
                });
            });
    };

    render() {
        const arrayValues = [];
        const object = this.props.pttValues;
        for (const property in object) {
            arrayValues.push({
                name: property,
                value: object[property],
            });
        }

        return (
            <div className="adminContainer">
                <div className="adminBox">
                    <div className="adminLabel">Set PTT Values</div>

                    {arrayValues &&
                        arrayValues.map((values) => {
                            return (
                                <div key={values.name} className="pttValues">
                                    <div className="pttName">
                                        {values.name}{" "}
                                    </div>
                                    <input
                                        id={values.name}
                                        value={this.state[`${values.name}`]}
                                        onChange={this.changeValue}
                                        className="pttInput"
                                    />
                                </div>
                            );
                        })}
                </div>
                <div className="inflationButton" onClick={this.onHandleUpdate}>
                    Update
                </div>
            </div>
        );
    }
}

export default ChangePTTValues;

import moment from "moment";
import { shallowEqual } from "react-redux";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import styles from "./ScenarioViewEntity.module.css";
import { getNameFromType } from "helpers/getNameFromType";
import CheckboxOffIcon from "Assets/_budgetIcons/checkbox-off.svg";
import CheckboxOnIcon from "Assets/_budgetIcons/checkbox-on.svg";
import { selectEntity } from "actions/scenario";

export const ScenarioViewEntity = ({ entityData, relatedEvent }) => {
    const dispatch = useAppDispatch();

    const relatedEventType = getNameFromType(relatedEvent?.type);

    const { isEntitySelected } = useAppSelector((state) => {
        const isEntitySelected =
            !!state?.scenario?.selectedEntities?.[entityData?.id];

        return {
            isEntitySelected,
        };
    }, shallowEqual);

    return (
        <article className={styles.article}>
            <div className={styles.actionsContainer}>
                <img
                    className={styles.checkbox}
                    onClick={() => dispatch(selectEntity(entityData.id))}
                    src={isEntitySelected ? CheckboxOnIcon : CheckboxOffIcon}
                    alt="checkbox"
                />
            </div>
            <div className={styles.entityInfo}>
                <span className={styles.entityName}>{entityData.name}</span>
                <span className={styles.entityType}>{relatedEventType}</span>
                <span className={styles.entityDates}>
                    {entityData.startDate
                        ? moment(entityData.startDate).format("MMM D YYYY")
                        : "No Start Date"}{" "}
                    -{" "}
                    {entityData.endDate
                        ? moment(entityData.endDate).format("MMM D YYYY")
                        : "No End Date"}
                </span>
            </div>
            <figure className={styles.figure}></figure>
        </article>
    );
};

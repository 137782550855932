import moment from "moment";

// Takes in a date string and cadence and returns a new date string that is incremented by the cadence
const incrementDateByCadence = (date: string, cadence: string): string => {
    const newDate = moment(date);

    switch (cadence) {
        case "annually":
            newDate.add(1, "y");
            break;
        case "semi-annually":
            newDate.add(6, "M");
            break;
        case "quarterly":
            newDate.add(4, "M");
            break;
        case "monthly":
            newDate.add(1, "M");
            break;
        case "semi-monthly":
            newDate.add(14, "d");
            break;
        case "bi-weekly":
            newDate.add(14, "d");
            break;
        case "weekly":
            newDate.add(7, "d");
            break;
        case "daily":
            newDate.add(1, "d");
            break;
    }

    return newDate.format("YYYY-MM-DD");
};

export default incrementDateByCadence;

import { Component } from "react";
import "../NodeGraph.css";
import CheckboxChecked from "../../../Assets/_nodeGraphIcons/checkbox-checked.svg";
import CheckboxUnchecked from "../../../Assets/_nodeGraphIcons/checkbox-unchecked.svg";
import { connect } from "react-redux";
import { appMapState, appMapDispatch } from "store/appMapStateDispatch";

class AccountType extends Component {
    state = {};

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedAccountTypes !== this.props.selectedAccountTypes
        ) {
            if (this.props.selectedAccountTypes.includes(this.props.account)) {
                this.setState({ accountSelected: true });
            } else this.setState({ accountSelected: false });
        }
    }

    render() {
        const { account, getLedgerDisplayName } = this.props;

        return (
            <li
                className="graph-accounts__other-accounts-panel-accounts-list-item"
                onClick={() => this.props.selectDeselectAccountTypes(account)}
            >
                <img
                    className="graph-accounts__other-accounts-panel-accounts-checkbox"
                    src={
                        this.props.selectedAccountTypes.includes(
                            this.props.account
                        )
                            ? CheckboxChecked
                            : CheckboxUnchecked
                    }
                    alt="checkbox checked"
                />
                <span className="graph-accounts__other-accounts-panel-accounts-list-item-text">
                    {getLedgerDisplayName(account)}
                </span>
            </li>
        );
    }
}

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

const mapStateToProps = appMapState((state) => {
    const selectedAccountTypes =
        state?.graphAccountSelection?.selectedAccountTypes;

    return {
        selectedAccountTypes,
    };
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountType);

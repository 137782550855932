import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Calendar as DayPicker } from "react-date-range";
import closeSvg from "../../Assets/close.svg";
import "./Calendar.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { createDate } from "../../helpers"; // theme css file
import { ModalRow } from "Components/InputContainer/Components";

const Calendar = (props) => {
    const convertPropsToState = (date) => {
        return date ? createDate(date) : date;
    };

    const validateProps = () => {
        if (!props.startDate || !props.endDate) return;
        if (createDate(props.startDate) > createDate(props.endDate)) {
            setEnd(null);
        }
    };

    const [start, setStart] = useState(convertPropsToState(props.startDate));
    const [end, setEnd] = useState(convertPropsToState(props.endDate));
    const [isShowCalendar, setShowCalendar] = useState(false);
    const [isShowCalendarStart, setShowCalendarStart] = useState(false);
    const [isShowCalendarEnd, setShowCalendarEnd] = useState(false);

    validateProps();

    const componentEnd = useRef();

    const scrollToBottom = () => {
        componentEnd.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (!isShowCalendar) {
            setStart(convertPropsToState(props.startDate));
            setEnd(convertPropsToState(props.endDate));
            validateProps();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        closeCalendar();
    }, [props.entityIndex]);

    useEffect(() => {
        if (isShowCalendar) {
            scrollToBottom();
        }
    }, [isShowCalendar]);

    const setCalendar = (type) => {
        if (type === "start") {
            setShowCalendarStart(true);
        }
        if (type === "end") {
            setShowCalendarEnd(true);
        }
        setShowCalendar(true);
    };

    const closeCalendar = () => {
        setShowCalendar(false);
        setShowCalendarStart(false);
        setShowCalendarEnd(false);
    };

    const onHandleCalendarSelection = (date, type) => {
        if (type === "start") {
            if (!date && !end) {
                props.onHandleDate(null, null);
            } else if (!date) {
                props.onHandleDate(null, moment(end).format("YYYY-MM-DD"));
            } else if (end) {
                let curEnd = end;
                setEnd(date);
                setStart(curEnd);
                if (moment(end).isBefore(moment(date))) {
                    props.onHandleDate(
                        moment(end).format("YYYY-MM-DD"),
                        moment(date).format("YYYY-MM-DD")
                    );
                } else {
                    setStart(date);
                    props.onHandleDate(
                        moment(date).format("YYYY-MM-DD"),
                        moment(end).format("YYYY-MM-DD")
                    );
                }
            } else {
                props.onHandleDate(moment(date).format("YYYY-MM-DD"), null);
            }

            setShowCalendarStart(false);
        }
        if (type === "end") {
            if (!date && !start) {
                props.onHandleDate(null, null);
            } else if (!date) {
                props.onHandleDate(moment(start).format("YYYY-MM-DD"), null);
            } else if (start) {
                let curStart = start;
                setStart(date);
                setEnd(curStart);
                if (moment(start).isAfter(moment(date))) {
                    props.onHandleDate(
                        moment(date).format("YYYY-MM-DD"),
                        moment(start).format("YYYY-MM-DD")
                    );
                } else {
                    setEnd(date);
                    props.onHandleDate(
                        moment(start).format("YYYY-MM-DD"),
                        moment(date).format("YYYY-MM-DD")
                    );
                }
            } else {
                props.onHandleDate(null, moment(date).format("YYYY-MM-DD"));
            }
            setShowCalendarEnd(false);
        }
        setShowCalendar(false);
    };

    const displayDate = (date) => {
        return date ? moment(date).format("MMM Do YYYY") : "";
    };

    return (
        <div ref={componentEnd} className="CalendarContainer">
            <div
                className={
                    isShowCalendar
                        ? "calendarContainerActive"
                        : "calendarContainer"
                }
            >
                {(() => {
                    if (isShowCalendarStart) {
                        return (
                            <>
                                <div className="dateTitle">
                                    Date{" "}
                                    <img
                                        alt="alt"
                                        src={closeSvg}
                                        className="close"
                                        onClick={() => closeCalendar()}
                                    />
                                </div>
                                <DayPicker
                                    format="YYYY/MM/DD"
                                    editableDateInputs={true}
                                    onChange={(date) =>
                                        onHandleCalendarSelection(date, "start")
                                    }
                                    date={start}
                                    months={1}
                                    direction="horizontal"
                                    showSelectionPreview={true}
                                    scroll={{ enabled: false }}
                                    className="singleCalendar"
                                    calendarWidth={10}
                                    calendarHeight={10}
                                    monthHeight={10}
                                    monthWidth={10}
                                    maxDate={createDate("2150-01-01")}
                                />
                            </>
                        );
                    }
                    if (isShowCalendarEnd) {
                        return (
                            <>
                                <div className="dateTitle">
                                    Date{" "}
                                    <img
                                        alt="alt"
                                        src={closeSvg}
                                        className="close"
                                        onClick={() => closeCalendar()}
                                    />
                                </div>
                                <DayPicker
                                    format="YYYY/MM/DD"
                                    editableDateInputs={true}
                                    onChange={(date) =>
                                        onHandleCalendarSelection(date, "end")
                                    }
                                    date={end}
                                    months={1}
                                    direction="horizontal"
                                    showSelectionPreview={true}
                                    scroll={{ enabled: false }}
                                    className="singleCalendar"
                                    calendarWidth={10}
                                    calendarHeight={10}
                                    monthHeight={10}
                                    monthWidth={10}
                                    maxDate={createDate("2150-01-01")}
                                />
                            </>
                        );
                    } else {
                        return (
                            <div className={"calendarInputContainer"}>
                                {props.cadence !== "one-time" ? (
                                    <>
                                        <div className="dateContainer">
                                            <input
                                                type="text"
                                                readOnly
                                                onClick={() =>
                                                    setCalendar("start")
                                                }
                                                value={displayDate(start)}
                                                className="dateInput"
                                                placeholder="Start Date"
                                            />
                                            <img
                                                src={closeSvg}
                                                alt="X"
                                                className="clearIcon"
                                                onClick={() => {
                                                    onHandleCalendarSelection(
                                                        null,
                                                        "start"
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="dateContainer">
                                            <input
                                                type="text"
                                                readOnly
                                                onClick={() =>
                                                    setCalendar("end")
                                                }
                                                value={displayDate(end)}
                                                className="dateInput"
                                                placeholder="End Date"
                                            />
                                            {props.optionalEndDate && (
                                                <span className="optionalText">
                                                    An end date is optional
                                                </span>
                                            )}
                                            <img
                                                src={closeSvg}
                                                alt="X"
                                                className="clearIcon"
                                                onClick={() => {
                                                    onHandleCalendarSelection(
                                                        null,
                                                        "end"
                                                    );
                                                }}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <ModalRow>
                                        <input
                                            type="text"
                                            readOnly
                                            onClick={() => setCalendar("start")}
                                            value={displayDate(start)}
                                            className="dateInput"
                                            placeholder="Start Date"
                                        />
                                        <img
                                            src={closeSvg}
                                            alt="X"
                                            className="clearStartDate"
                                            onClick={() => {
                                                onHandleCalendarSelection(
                                                    null,
                                                    "start"
                                                );
                                            }}
                                        />
                                    </ModalRow>
                                )}
                            </div>
                        );
                    }
                })()}
            </div>
        </div>
    );
};

export default Calendar;

import { bankObject } from "Components/Registry/Bank";
import { bankAltObject } from "Components/Registry/BankAlt";
import { bankPlaidObject } from "Components/Registry/BankPlaid";
import { budgetObject } from "Components/Registry/Budget";
import { businessObject } from "Components/Registry/Business";
import { cacObject } from "Components/Registry/CAC";
import { capitalGainsObject } from "Components/Registry/Capital Gains";
import { closingCostsObject } from "Components/Registry/Closing Costs";
import { condoFeeObject } from "Components/Registry/CondoFee";
import { contractObject } from "Components/Registry/Contract";
import { customerObject } from "Components/Registry/Customer";
import { customerChurnObject } from "Components/Registry/CustomerChurn";
import { customerGrowthObject } from "Components/Registry/CustomerGrowth";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { debtObject } from "Components/Registry/Debt";
import { debtRepaymentObject } from "Components/Registry/Debt Repayment";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { employeeObject } from "Components/Registry/Employee";
import { equityObject } from "Components/Registry/Equity";
import { expenseObject } from "Components/Registry/Expense";
import { goalObject } from "Components/Registry/Goal";
import { graduationObject } from "Components/Registry/Graduation";
import { grantObject } from "Components/Registry/Grant";
import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
import { houseObject } from "Components/Registry/House";
import { incomeObject } from "Components/Registry/Income";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { investmentObject } from "Components/Registry/Investment";
import { loanObject } from "Components/Registry/Loan";
import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
import { maternityObject } from "Components/Registry/Maternity";
import { meObject } from "Components/Registry/Me";
import { modifierObject } from "Components/Registry/Modifier";
import { modifier2Object } from "Components/Registry/Modifier2";
import { mortgageObject } from "Components/Registry/Mortgage";
import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
import { nullObject } from "Components/Registry/Null";
import { containerObject } from "Components/Registry/Container";
import { pensionObject } from "Components/Registry/Pension";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";
import { propertyValueObject } from "Components/Registry/Property Value";
import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";
import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";
import { rentObject } from "Components/Registry/Rent";
import { rentalIncomeObject } from "Components/Registry/Rental Income";
import { retirementObject } from "Components/Registry/Retirement";
import { revenueObject } from "Components/Registry/Revenue";
import { rrspObject } from "Components/Registry/RRSP";
import { shortTermRentalObject } from "Components/Registry/Short Term Rental";
import { startObject } from "Components/Registry/Start";
import { studentBudgetObject } from "Components/Registry/Student Budget";
import Student_IncomeObject from "Components/Registry/Student Income";
import { studentLoanObject } from "Components/Registry/Student Loan";
import { taxDeductibleObject } from "Components/Registry/Tax Deductible";
import { tuitionObject } from "Components/Registry/Tuition";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { decisionObject } from "Components/Registry/Decision";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import { kpiObject } from "Components/Registry/KPI";
import { instanceObject } from "Components/Registry/Instance";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { expressionObject } from "Components/Registry/Expression";
import { importObject } from "Components/Registry/Import";
import { accountImportObject } from "Components/Registry/AccountImport";
import { growthObject } from "Components/Registry/Growth";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { salespersonObject } from "Components/Registry/Salesperson";
import { segmentObject } from "Components/Registry/Segment";
import { customer2Object } from "Components/Registry/Customer2";
import { customerGrowth2Object } from "Components/Registry/CustomerGrowth2";
import { customerChurn2Object } from "Components/Registry/CustomerChurn2";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { campaignObject } from "Components/Registry/Campaign";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { CAC2Object } from "Components/Registry/CAC2";
import { allocationObject } from "Components/Registry/Allocation";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { percentageObject } from "Components/Registry/Percentage";
import { resourceObject } from "Components/Registry/Resource";
import { capacityObject } from "Components/Registry/Capacity";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { unitObject } from "Components/Registry/Unit";
import { projectsObject } from "Components/Registry/Projects";
import { utilizationObject } from "Components/Registry/Utilization";

// Given a UUID string, returns the corresponding eventObject specified in {EventName}.js. Defaults to nullObject.

export const getObjectFromUUID: any = (uuid: string) => {
    switch (uuid) {
        case startObject.constant():
            return startObject;
            break;
        case instanceObject.constant():
            return instanceObject;
            break;
        case kpiObject.constant():
            return kpiObject;
            break;
        case meObject.constant():
            return meObject;
            break;
        case decisionObject.constant():
            return decisionObject;
            break;
        case businessObject.constant():
            return businessObject;
            break;
        case bankAltObject.constant():
            return bankAltObject;
        case incomeObject.constant():
            return incomeObject;
            break;
        case expenseObject.constant():
            return expenseObject;
            break;
        case tuitionObject.constant():
            return tuitionObject;
            break;
        case mortgageObject.constant():
            return mortgageObject;
            break;
        case loanObject.constant():
            return loanObject;
            break;
        case studentLoanObject.constant():
            return studentLoanObject;
            break;
        case bankObject.constant():
            return bankObject;
            break;
        case budgetObject.constant():
            return budgetObject;
            break;
        case studentBudgetObject.constant():
            return studentBudgetObject;
            break;
        case goalObject.constant():
            return goalObject;
            break;
        case rentObject.constant():
            return rentObject;
            break;
        case houseObject.constant():
            return houseObject;
            break;
        case incomeTaxObject.constant():
            return incomeTaxObject;
            break;
        case bankPlaidObject.constant():
            return bankPlaidObject;
            break;
        case maternityObject.constant():
            return maternityObject;
            break;
        case downpaymentObject.constant():
            return downpaymentObject;
            break;
        case equityObject.constant():
            return equityObject;
            break;
        case rrspObject.constant():
            return rrspObject;
            break;
        case propertyTransferTaxObject.constant():
            return propertyTransferTaxObject;
            break;
        case investmentObject.constant():
            return investmentObject;
            break;
        case taxDeductibleObject.constant():
            return taxDeductibleObject;
            break;
        case propertyValueObject.constant():
            return propertyValueObject;
            break;
        case propertyResaleObject.constant():
            return propertyResaleObject;
            break;
        case modifierObject.constant():
            return modifierObject;
            break;
        case modifier2Object.constant():
            return modifier2Object;
            break;
        case employeeObject.constant():
            return employeeObject;
            break;
        case lumpSumPaymentObject.constant():
            return lumpSumPaymentObject;
            break;
        case renewalRefinanceObject.constant():
            return renewalRefinanceObject;
            break;
        case realEstateFeeObject.constant():
            return realEstateFeeObject;
            break;
        case retirementObject.constant():
            return retirementObject;
            break;
        case nullObject.constant():
            return nullObject;
            break;
        case containerObject.constant():
            return containerObject;
            break;
        case graduationObject.constant():
            return graduationObject;
            break;
        case pensionObject.constant():
            return pensionObject;
            break;
        case mortgageInsuranceObject.constant():
            return mortgageObject;
            break;
        case capitalGainsObject.constant():
            return capitalGainsObject;
            break;
        case closingCostsObject.constant():
            return closingCostsObject;
            break;
        case homeMaintenanceObject.constant():
            return homeMaintenanceObject;
            break;
        case shortTermRentalObject.constant():
            return shortTermRentalObject;
            break;
        case rentalIncomeObject.constant():
            return rentalIncomeObject;
            break;
        case debitCreditObject.constant():
            return debitCreditObject;
            break;
        case expressionObject.constant():
            return expressionObject;
            break;
        case debtObject.constant():
            return debtObject;
            break;
        case grantObject.constant():
            return grantObject;
            break;
        case groupObject.constant():
            return groupObject;
            break;
        case projectObject.constant():
            return projectObject;
            break;
        case importObject.constant():
            return importObject;
            break;
        case accountImportObject.constant():
            return accountImportObject;
            break;
        case growthObject.constant():
            return growthObject;
            break;
        case initialBalanceObject.constant():
            return initialBalanceObject;
            break;
        case debtRepaymentObject.constant():
            return debtRepaymentObject;
            break;
        case customerObject.constant():
            return customerObject;
            break;
        case projectsObject.constant():
            return projectsObject;
            break;
        case customer2Object.constant():
            return customer2Object;
            break;
        case customerGrowthObject.constant():
            return customerGrowthObject;
            break;
        case customerGrowth2Object.constant():
            return customerGrowth2Object;
            break;
        case customerChurn2Object.constant():
            return customerChurn2Object;
            break;
        case customerTransfer2Object.constant():
            return customerTransfer2Object;
            break;
        case customerChurnObject.constant():
            return customerChurnObject;
            break;
        case unitCostObject.constant():
            return unitCostObject;
            break;
        case revenueObject.constant():
            return revenueObject;
            break;
        case Student_IncomeObject.constant():
            return Student_IncomeObject;
            break;
        case condoFeeObject.constant():
            return condoFeeObject;
            break;
        case cacObject.constant():
            return cacObject;
            break;
        case contractObject.constant():
            return contractObject;
            break;
        case customerTransferObject.constant():
            return customerTransferObject;
            break;
        case outboundSalesObject.constant():
            return outboundSalesObject;
            break;
        case campaignObject.constant():
            return campaignObject;
            break;
        case CAC2Object.constant():
            return CAC2Object;
            break;
        case salespersonObject.constant():
            return salespersonObject;
            break;
        case segmentObject.constant():
            return segmentObject;
            break;
        case allocationObject.constant():
            return allocationObject;
            break;
        case websiteVisitorsObject.constant():
            return websiteVisitorsObject;
            break;
        case constraintObject.constant():
            return constraintObject;
        case accountModifierObject.constant():
            return accountModifierObject;
        case percentageObject.constant():
            return percentageObject;
        case resourceObject.constant():
            return resourceObject;
        case utilizationObject.constant():
            return utilizationObject;
        case capacityObject.constant():
            return capacityObject;
        case averageOrderObject.constant():
            return averageOrderObject;
        case accountExpressionObject.constant():
            return accountExpressionObject;
        case unitObject.constant():
            return unitObject;
        default:
            return nullObject;
    }
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import StudentLoanCard from "../Card/studentLoanCard";
import StudentLoanInput from "../InputContainer/StudentLoanInput";

// Import Connection Image (if exists)
import studentLoanConnection from "../../Assets/_optionDescriptionIcons/Student Budget, Student Loan, Graduation.png";

// Import Coloured PNGs
import studentLoanBlack from "../../Assets/_eventNodeTypes/studentLoan-black.png";
import studentLoanBlue from "../../Assets/_eventNodeTypes/studentLoan-blue.png";
import studentLoanWhite from "../../Assets/_eventNodeTypes/studentLoan-white.png";
import studentLoanGrey from "../../Assets/_eventNodeTypes/studentLoan-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - studentStudentLoanObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - studentStudentLoanObject.checkInput.call(studentStudentLoanObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const studentLoanObject = {
    constant: function () {
        return StudentLoan_Constant();
    },

    checkInput: function (state) {
        return StudentLoan_CheckInput(state);
    },

    svg: function () {
        return StudentLoan_Svg();
    },

    eventNodeMenu: function (focus) {
        return StudentLoan_EventNodeMenu(focus);
    },

    indexText: function () {
        return StudentLoan_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return StudentLoan_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return StudentLoan_OptionDescription();
    },

    colouredPngs: function () {
        return StudentLoan_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return StudentLoan_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const StudentLoan_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.loan) return false;
    if (!state.cadence) return false;
    if (!state.amortization) return false;
    if (!state.rate) return false;
    if (!state.start) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const StudentLoan_Constant = () => {
    return "Student Loan";
};

// EFFECTS: Returns black png / svg for event
const StudentLoan_Svg = () => {
    return studentLoanBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const StudentLoan_EventNodeMenu = (focus) => {
    return <StudentLoanCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const StudentLoan_IndexText = () => {
    return "Maximum government student loans for an 8-month academic year is $13,000. Student lines of credit range from $5,000 to $8,000 a year depending on the bank. More funding is available for professional programs. Part-time students are eligible for part-time funding at an reduced amount and cannot expect to stay interest free throughout their study periods.";
};

// EFFECTS: Returns all icons for event ??
const StudentLoan_Icons = () => {
    let pngs = new Map([
        ["black", studentLoanBlack],
        ["blue", studentLoanBlue],
        ["white", studentLoanWhite],
        ["grey", studentLoanGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const StudentLoan_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const StudentLoan_InputComponentInfoPassing = (minProps) => {
    return <StudentLoanInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, sStudentLoans for event ??
const StudentLoan_OptionDescription = () => {
    const tags = ["Option"];
    const type = studentLoanObject.constant();
    const description =
        "A Student Loan can help eligible students finance their academic career. Funding is calculated according to your financial and tax history and repayment options vary from type of loan to institution.";
    const usedForText =
        "The Student Loan Event can be used to track cash flows and see the effect that a loan and its repayment will have on your finances. Choose between full-time and part-time funding, as well as government and line-of-credit funding. ";
    const connection = "";
    const connectionImage = studentLoanConnection;
    const learnMoreLink = "https://www.csnpe-nslsc.canada.ca/en/home";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const StudentLoan_SideModalImage = () => {
    return sideImage;
};

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

export const groupInputsHandler = (
    value: string,
    id: "entityName" | "select-event" | "select-entity",
    mainId: string,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "select-event":
            const selectedEvents = [
                ...newEntitiesMap[currentEntity].data.selectedEvents,
            ];
            if (selectedEvents.includes(mainId)) {
                selectedEvents.splice(selectedEvents.indexOf(mainId), 1);
            } else {
                selectedEvents.push(mainId);
            }
            data.selectedEvents = selectedEvents;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "select-entity":
            const selectedEntities = [
                ...newEntitiesMap[currentEntity].data.selectedEntities,
            ];
            if (selectedEntities.includes(mainId)) {
                selectedEntities.splice(selectedEntities.indexOf(mainId), 1);
            } else {
                selectedEntities.push(mainId);
            }
            data.selectedEntities = selectedEntities;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";
import { valueDoesNotExist } from "helpers";

// Import Event Card and Event Input
import PropertyValueCard from "../Card/propertyValueCard";
import PropertyValueInputExperimental from "../InputContainer/PropertyValueInputExperimental/PropertyValueInputExperimental";

// Import Connection Image (if exists)
// import PropertyValueConnectionImage from "../../Assets/_optionDescriptionIcons/propertyValueConnectionImage.png";
import propertyValueConnectionImage from "../../Assets/_optionDescriptionIcons/House, Property Value, Property Transfer Tax.png";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";

// Import Coloured PNGs
import propertyValueBlack from "../../Assets/_eventNodeTypes/propertyValue-black.png";
import propertyValueBlue from "../../Assets/_eventNodeTypes/propertyValue-blue.png";
import propertyValueWhite from "../../Assets/_eventNodeTypes/propertyValue-white.png";
import propertyValueGrey from "../../Assets/_eventNodeTypes/propertyValue-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - propertyValueObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - propertyValueObject.checkInput.call(propertyValueObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const propertyValueObject = {
    constant: function () {
        return PropertyValue_Constant();
    },

    checkInput: function (state) {
        return PropertyValue_CheckInput(state);
    },

    svg: function () {
        return PropertyValue_Svg();
    },

    eventNodeMenu: function (focus) {
        return PropertyValue_EventNodeMenu(focus);
    },

    indexText: function () {
        return PropertyValue_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return PropertyValue_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return PropertyValue_OptionDescription();
    },

    colouredPngs: function () {
        return PropertyValue_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return PropertyValue_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const PropertyValue_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.propertyValue) return false;
        if (!entity.startDate) return false;
        if (
            entity.data.appreciation &&
            valueDoesNotExist(entity.data.appreciationRate)
        )
            return false;
        if (!entity.data.house) return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const PropertyValue_Constant = () => {
    return "Property Value";
};

// EFFECTS: Returns black png / svg for event
const PropertyValue_Svg = () => {
    return propertyValueBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const PropertyValue_EventNodeMenu = (focus) => {
    return <PropertyValueCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const PropertyValue_IndexText = () => {
    return "Select an input field to learn more about your propertyValue calculations.";
};

// EFFECTS: Returns all icons for event ??
const PropertyValue_Icons = () => {
    let pngs = new Map([
        ["black", propertyValueBlack],
        ["blue", propertyValueBlue],
        ["white", propertyValueWhite],
        ["grey", propertyValueGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const PropertyValue_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const PropertyValue_InputComponentInfoPassing = (minProps) => {
    return <PropertyValueInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, propertyValues for event ??
const PropertyValue_OptionDescription = () => {
    const tags = ["Option"];
    const type = propertyValueObject.constant();
    const description =
        "Use the Property Value Event to directly set the value of a home. Use a recent assessment or experiment with potential valuations in the future to better estimate Net Worth. ";
    const usedForText =
        "The Property Vale Event overrides the calculated or appreciated value of a property. In some cases, a home may appreciate more quickly than the average, or renovations may increase the potential value of a home. Use this to calculate the potential equity in a property.";
    const connection =
        "Add a Property Value Event downstream of your current House Event and after any existing Mortgage Event.";
    const connectionImage = propertyValueConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/articles/realestate/12/real-estate-valuation.asp";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const PropertyValue_SideModalImage = () => {
    return sideImage;
};

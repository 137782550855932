import "./Card.css";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { useMemo, useState } from "react";

export const PercentageCard = ({ data }) => {
    const [currentIndex, _setCurrentIndex] = useState(
        data?.mostRecentEntity ?? 0
    );

    const currentEntityData = useMemo(() => {
        const entityIds = data?.entities ?? [];
        const entityDataArray = Object.values(getRelevantEntities(entityIds));
        const currentEntityData = entityDataArray?.[currentIndex];
        return currentEntityData;
    }, [data, currentIndex]);

    return (
        <div className="Container">
            <div className="Row">
                <div className="ExpenseLabel">Percentage Amount:</div>
                <div className="cardValue">
                    {currentEntityData.data.value.toString() + "%"}
                </div>
            </div>
        </div>
    );
};

import { MapStateToProps, MapDispatchToProps } from "react-redux";
import { RootState, AppDispatch } from "./index";

export function appMapState(
    mapStateCallback: MapStateToProps<any, any, RootState>
) {
    return mapStateCallback;
}

export function appMapDispatch(
    mapDispatchCallback: MapDispatchToProps<AppDispatch, any>
) {
    return mapDispatchCallback;
}

// Default Imports
import React from "react";
import { PercentageCard } from "Components/Card/percentageCard";
import PercentageConnectionImage from "Assets/_optionDescriptionIcons/Income, Expense white.jpg";
import percentageBlack from "../../Assets/_eventNodeTypes/percentSvg.svg";
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import Percentage from "Components/InputContainer/Percentage/PercentageInput";

// "Interface" object for Rental Income
export const percentageObject = {
    constant: function () {
        return Percentage_Constant();
    },

    name: function () {
        return "Percentage";
    },

    checkInput: function (state) {
        return Percentage_CheckInput(state);
    },

    svg: function () {
        return Percentage_Svg();
    },

    eventNodeMenu: function (focus) {
        return Percentage_EventNodeMenu(focus);
    },

    indexText: function () {
        return Percentage_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Percentage_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Percentage_OptionDescription();
    },

    colouredPngs: function () {
        return Percentage_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Percentage_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Percentage_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Percentage_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (!entity.data.value) return false;

        if (entity.data.customEffectPeriod) {
            if (!entity.startDate) return false;
        }

        if (!entity.data.sourceAccountName) return false;
        if (!entity.data.sourceAccountIds.length) return false;
        if (!entity.data.targetAccountName) return false;
        if (!entity.data.targetAccountIds.length) return false;

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Percentage_Constant = () => {
    return "4536daec-8927-40e5-a10a-88f316093b03";
};

// EFFECTS: Returns svg for event
const Percentage_Svg = () => {
    return percentageBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Percentage_EventNodeMenu = (focus) => {
    return <PercentageCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Percentage_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Percentage calculations.";
};

// EFFECTS: Returns all icons for event ??
const Percentage_Icons = () => {
    let pngs = new Map([
        ["black", percentageBlack],
        ["blue", percentageBlack],
        ["white", percentageBlack],
        ["grey", percentageBlack],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Percentage_InputComponentInfoPassing = (minProps) => {
    return <Percentage {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Percentage_OptionDescription = () => {
    const tags = ["Option"];
    const type = percentageObject.name();
    const description =
        "Percentage is a generic Event that can be used to take a percentage of an account/record every month, and assign it to another account.";
    const usedForText =
        "The Percentage Node can be used to assign and move values between accounts.";
    const connection = "";
    const connectionImage = PercentageConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/i/income.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Percentage_SideModalImage = () => {
    return sideImage;
};

const Percentage_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: true,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            unit: "date",
            editable: true,
        },
        endDate: {
            displayName: "End Date",
            unit: "date",
            editable: true,
        },
        bypassState: {
            displayName: "Bypassed",
            editable: true,
        },
    };

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./InputContainer.css";
import { withStyles } from "@material-ui/core/styles";
import tourPoint from "../../Assets/_onboarding/tourPoint.svg";
import Switch from "react-switch";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import { Text } from "react-konva";
import { roundNumberForDisplay } from "../../helpers";
import { ReactComponent as AddSVG } from "../../Assets/actionsIcons/add_entity.svg";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";
import { setShowCellRowModal } from "actions/modalActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { groupObject } from "Components/Registry/Group";
import addSvg from "../../Assets/_budgetIcons/add.svg";
import deleteSvg from "../../Assets/_budgetIcons/delete.svg";
import editSvg from "../../Assets/scenarioIcons/edit.svg";
import radioButtonOff from "../../Assets/_nodeInputs/radioButtonOff.svg";
import radioButtonOn from "../../Assets/_nodeInputs/radioButtonOn.svg";
import calculateStateON from "../../Assets/entityStateIcons/AcitveCalculationON.svg";
import calculateStateOFF from "../../Assets/entityStateIcons/AcitveCalculationOFF.svg";
import entityBypassON from "../../Assets/entityStateIcons/EntityBypassON.svg";
import entityBypassOFF from "../../Assets/entityStateIcons/EntityBypassOFF.svg";
import nonCalculatingON from "../../Assets/entityStateIcons/NonCalculatingON.svg";
import nonCalculatingOFF from "../../Assets/entityStateIcons/NonCalculatingOFF.svg";
import CsvImportSvg from "../../Assets/csvImportButton.svg";
import {
    showLogin,
    closeOption,
    toggleEventModalActions,
} from "actions/scenario";
import swal from "sweetalert";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { LOG_IN } from "helpers/constants";
import { projectObject } from "Components/Registry/Project";
import arrowSvg from "../../Assets/arrow.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NumericFormat } from "react-number-format";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WarningIcon from "@mui/icons-material/Warning";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import directionSwapIcon from "Assets/directionSwap.svg";

// const NumericFormatCustom = React.forwardRef(
//     function NumericFormatCustom(props, ref) {
//       const { onChange, ...other } = props;
//       // Can't find a reference for this prop, but it causes an error in the console so I'm removing it
//       if (other["inputRef"]) delete other["inputRef"]

//       return (
//         <NumericFormat
//           {...other}
//           getInputRef={ref}
//           onValueChange={(values) => {
//             onChange({
//               target: {
//                 name: props.name,
//                 value: values.value,
//               },
//             });
//           }}
//           thousandSeparator
//           valueIsNumericString
//           prefix="$"
//         />
//       );
//     },
//   );

const NumericFormatCustomNoPrefix = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        // Can't find a reference for this prop, but it causes an error in the console so I'm removing it
        if (other["inputRef"]) delete other["inputRef"];

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
            />
        );
    }
);

const KPIListItemButtonStyled = styled(ToggleButton)({
    // width: "fit-content",
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    // lineHeight: 1,
    padding: "8px 18px",
    // border: '1px solid #f7ab5f',
    border: "none",
    // borderRadius: 16,
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "CeraPro-Regular",
    "&.Mui-selected": {
        backgroundColor: "rgba(25, 118, 210, 0.08);",
        color: "rgba(0, 0, 0, 0.87);",
    },
    "&.Mui-selected:hover": {
        backgroundColor: "rgba(25, 118, 210, 0.02);",
        color: "rgba(0, 0, 0, 0.87);",
    },
});

export const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#f8b46a",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f8b46a",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#c7c7c7",
                color: "#f8b46a",
            },
            "&:hover fieldset": {
                borderColor: "#4a4a4a",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f8b46a",
            },
        },
        "& .MuiInputBase-root": {
            fontFamily: "CeraPro-Regular",
        },
        "& .MuiFormLabel-root": {
            fontFamily: "CeraPro-Regular",
        },
    },
})(TextField);

const OverriddenCssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#f8b46a",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#f8b46a",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#c7c7c7",
                color: "#f8b46a",
            },
            "&:hover fieldset": {
                borderColor: "#4a4a4a",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#f8b46a",
            },
        },
        "& .MuiInputBase-root": {
            fontFamily: "CeraPro-Regular",
            "& .MuiInputBase-input": {
                color: "#0000FF",
                fontWeight: 600,
                fontStyle: "italic",
            },
        },
        "& .MuiFormLabel-root": {
            fontFamily: "CeraPro-Regular",
        },
    },
})(TextField);

export const Name = ({ onChangeInput, name, onKeyPress, defaultValue }) => {
    return (
        <CssTextField
            value={defaultValue ? defaultValue : name}
            onChange={(e) => onChangeInput(e, "name")}
            id="standard-basic"
            className="ExpenseInputLabel"
            label="Name"
            onKeyPress={onKeyPress ? onKeyPress : null}
        />
    );
};

export const EntityName = ({
    onChangeInput,
    name,
    onKeyPress,
    defaultValue,
    entityData = {},
    handleEntityStateChange,
    required,
}) => {
    return (
        <div className="entityHeader">
            <div className="entityNameWrapper">
                <CssTextField
                    value={defaultValue ? defaultValue : name}
                    onChange={(e) => onChangeInput(e, "entityName")}
                    id="standard-basic"
                    className="ExpenseInputLabel"
                    label="Name"
                    onKeyPress={onKeyPress ? onKeyPress : null}
                    required={required}
                />
            </div>

            <div className="entityActionsContainer">
                <img
                    className="entityActionImage"
                    src={
                        entityData.calculateState && !entityData.bypassState
                            ? calculateStateON
                            : calculateStateOFF
                    }
                    alt="caluclating"
                    title="Active"
                    onClick={() =>
                        handleEntityStateChange(
                            "calculateState",
                            entityData.name
                        )
                    }
                />
                <img
                    className="entityActionImage"
                    src={
                        !entityData.calculateState && !entityData.bypassState
                            ? nonCalculatingON
                            : nonCalculatingOFF
                    }
                    alt="non-calculating"
                    title="Non-Calculating"
                    onClick={() =>
                        handleEntityStateChange(
                            "nonCalculateState",
                            entityData.name
                        )
                    }
                />
                <div className="entityActionDivider"></div>
                <img
                    className="entityActionImage"
                    src={
                        entityData.bypassState
                            ? entityBypassON
                            : entityBypassOFF
                    }
                    alt="toggle bypass"
                    title="Bypassed/Inactive"
                    onClick={() =>
                        handleEntityStateChange("bypassState", entityData.name)
                    }
                />
            </div>
        </div>
    );
};

export const AgencyGoal = ({
    onChangeInput,
    value,
    onKeyPress,
    label,
    placeholder,
}) => {
    return (
        <CssTextField
            value={value}
            onChange={(e) => onChangeInput(e, "agencyGoal")}
            id="standard-basic"
            className="AgencyGoal"
            label={label ? label : ""}
            onKeyPress={onKeyPress ? onKeyPress : null}
            placeholder={placeholder}
        />
    );
};

export const ClientNameInput = ({
    onChangeInput,
    name,
    onKeyPress,
    defaultValue,
}) => {
    return (
        <CssTextField
            value={defaultValue ? defaultValue : name}
            onChange={(e) => onChangeInput(e, "name")}
            id="standard-basic"
            className="ClientNameInput"
            label="Client Name"
            onKeyPress={onKeyPress ? onKeyPress : null}
            InputProps={{ style: { fontSize: 27 } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 27 } }} // font size of input label
        />
    );
};

export const Description = ({ onChangeInput, description, placeholder }) => {
    return (
        <CssTextField
            id="outlined-textarea"
            label={placeholder ? placeholder : "Description"}
            placeholder="Description"
            multiline
            rows="3"
            variant="outlined"
            onChange={(e) => onChangeInput(e, "description")}
            className="ExpenseTextArea"
            value={description}
            // InputProps={{ style: { minHeight: 200 } }} // This makes the input box taller
        />
    );
};

export const AccountDesc = ({
    onChangeId,
    onChangeInput,
    value,
    placeholder,
}) => {
    return (
        <CssTextField
            id="outlined"
            label={placeholder || "Account Name"}
            placeholder={placeholder || "Account Name"}
            variant="outlined"
            onChange={(e) => onChangeInput(e, onChangeId || "accName")}
            className="ExpenseTextArea"
            value={value}
        />
    );
};

export const NameDesc = ({
    onChangeInput,
    description,
    placeholder,
    required = false,
}) => {
    return (
        <CssTextField
            id="outlined-textarea"
            label={placeholder ? placeholder : "Role"}
            placeholder="Role"
            multiline
            variant="outlined"
            onChange={(e) => onChangeInput(e, "personName")}
            className="ExpenseTextArea"
            value={description}
            required={required}
        />
    );
};

// /**
//  * @param {object} props
//  * @param {string[] | { value: string, displayName: string }[]} props.options
//  */
export const SelectDropDown = ({
    onChangeInput,
    value,
    options,
    className,
    id,
    label,
    mainId,
    disabled,
    hideNullOption,
    error,
    helperText,
    required = false,
    fullWidth = false,
}) => {
    return (
        <CssTextField
            id="outlined-select-currency-native"
            select
            label={label}
            className={className || "termsContainer"}
            onChange={(e) => onChangeInput(e, id, mainId)}
            value={value}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
            disabled={disabled}
            required={required ?? false}
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
        >
            {!hideNullOption && <option value="" />}
            {options.map((option, i) => {
                let optionValue = option;
                let optionDisplayName = option;
                if (typeof option === "object") {
                    optionValue = option.value;
                    optionDisplayName = option.displayName;
                }

                return (
                    <option value={optionValue} key={i}>
                        {optionDisplayName}
                    </option>
                );
            })}
        </CssTextField>
    );
};

export const SelectSegments = ({
    onChangeInput,
    segments,
    id,
    value,
    className,
    label,
    helperText,
    disabled,
    required,
    hideNullOption,
}) => {
    const collectSegments = () => {
        const segmentValues = [];
        const segmentNames = [];
        if (segments?.length > 0) {
            segments?.map((segmentId) => {
                const segmentEvent = getEvent(segmentId);

                const segmentEntity = Object.values(
                    getRelevantEntities(segmentEvent?.entities)
                )?.[0];

                segmentEntity?.data?.segments?.map((segment) => {
                    segmentValues?.push({
                        eventId: segmentEvent?.id,
                        entityId: segmentEntity?.id,
                        segmentId: segment?.id,
                    });
                    segmentNames.push(
                        `${segmentEntity.name} - ${segment.name}`
                    );
                });
            });
        }
        return [segmentValues, segmentNames];
    };

    const [segmentValues, segmentNames] = collectSegments();

    return (
        <CssTextField
            id="outlined-select-currency-native"
            select
            label={label}
            className={className || "termsContainer"}
            onChange={(e) => onChangeInput(e, id)}
            value={JSON.stringify(value)}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
            disabled={disabled}
            helperText={helperText}
            required={required}
        >
            {!hideNullOption && <option value="" />}
            {segmentValues.map((segment, i) => {
                return (
                    <option value={JSON.stringify(segment)} key={i}>
                        {segmentNames[i]}
                    </option>
                );
            })}
        </CssTextField>
    );
};

export const SelectEntities = ({
    nodes, // an array of node ID's
    showAll,
    onChangeInput,
    value, // {entityIds: string[], eventId: string}
    className,
    id,
    label,
    mainId,
    disabled,
    hideNullOption,
    error,
    helperText,
    groupEventType, // specifies which group nodes to include, "All" to include all group types
    required = false,
    hideEventName = false,
}) => {
    const collectEntities = () => {
        const returnedOptions = [];
        const optionNames = [];
        for (let nodeID of nodes) {
            let node = getEvent(nodeID);
            if (!node) continue;

            const isProjectNode = node.type == projectObject.constant();
            // handle group events
            if (
                (node.type == groupObject.constant() || isProjectNode) &&
                id != "record_Instance"
            ) {
                const groupEntities = getRelevantEntities(node.entities);
                Object.values(groupEntities).forEach((groupEntity) => {
                    if (
                        isProjectNode ||
                        groupEventType === "All" ||
                        groupEntity.data.eventType == groupEventType
                    ) {
                        returnedOptions.push({
                            entityIds: [groupEntity.id],
                            eventId: nodeID,
                        });

                        optionNames.push(
                            `${node.name} - ${groupEntity.name} (${
                                groupEntity.data.eventType || "n/a"
                            })`
                        );
                    }
                });

                continue;
            }

            let relevantEntities = getRelevantEntities(node?.entities ?? []);
            // If we want to show the option for all entities, then add them

            if (showAll) {
                returnedOptions.push({
                    entityIds: [],
                    eventId: nodeID,
                });
                optionNames.push(hideEventName ? `All` : `${node.name} - All`);
            }
            // Adding option for each individual entity
            Object.values(relevantEntities).forEach((entity) => {
                returnedOptions.push({
                    entityIds: [entity.id],
                    eventId: nodeID,
                });
                optionNames.push(
                    hideEventName
                        ? entity.name
                        : `${node.name} - ${entity.name}`
                );
            });
        }

        return [returnedOptions, optionNames];
    };
    const [options, names] = collectEntities();
    return (
        <CssTextField
            id="outlined-select-currency-native"
            select
            label={label}
            className={className || "termsContainer"}
            onChange={(e) => onChangeInput(e, id, mainId)}
            // This value is used to match and display the correct option
            // For this component the value is always the stringified Object in the option value field
            value={JSON.stringify(value)}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
            disabled={disabled}
            error={error}
            helperText={helperText}
            required={required}
        >
            {!hideNullOption && <option value="" />}
            {options.map((option, i) => {
                return (
                    <option value={JSON.stringify(option)} key={i}>
                        {names[i]}
                    </option>
                );
            })}
        </CssTextField>
    );
};

export const SelectDropDownPerson = ({
    onChangeInput,
    value,
    options,
    className,
    id,
    label,
    mainId,
}) => {
    return (
        <CssTextField
            id="outlined-select-currency-native"
            select
            label={label}
            className={className}
            onChange={(e) => onChangeInput(e, id, mainId)}
            value={value}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
        >
            <option value="" />
            {options.map((option, i) => {
                return (
                    <option value={option} key={i}>
                        {option}
                    </option>
                );
            })}
        </CssTextField>
    );
};

export const WeeksOff = ({
    label,
    onChangeInput,
    value,
    id,
    disabled,
    error,
    helperText,
}) => {
    return (
        <CssTextField
            id="standard-number"
            label={label}
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e) => onChangeInput(e, id)}
            className="InputAmount"
            value={value}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start"> Weeks: </InputAdornment>
                ),
            }}
            error={error}
            helperText={helperText}
            onWheel={(e) => e.target.blur()}
        />
    );
};

export const SelectDropDownHouse = ({
    onChangeInput,
    value,
    options,
    className,
    id,
    label,
    mainId,
}) => {
    return (
        <CssTextField
            id="outlined-select-currency-native"
            select
            label={label}
            className={className}
            onChange={(e) => onChangeInput(e, id, mainId)}
            value={value}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
        >
            <option value="" />
            {options.map((option, i) => {
                return (
                    <option value={option} key={i}>
                        {option}
                    </option>
                );
            })}
        </CssTextField>
    );
};

export const SelectDropDownCountry = ({
    onChangeInput,
    value,
    options,
    className,
    id,
    label,
}) => {
    return (
        <CssTextField
            id="outlined-select-currency-native"
            select
            label={!value && label}
            className={className}
            onChange={(e) => onChangeInput(e, id)}
            value={value}
            SelectProps={{
                native: true,
            }}
            variant="outlined"
        >
            <option value="" />
            {options &&
                options.map((option, i) => {
                    return (
                        <option
                            value={id === "state" ? option : option.shortName}
                            key={i}
                        >
                            {id === "state" ? option : option.name}
                        </option>
                    );
                })}
        </CssTextField>
    );
};

export const SelectEventsDropdown = ({
    events,
    onChangeInput,
    error,
    helperText,
    label,
    value,
}) => {
    const options = events || [];
    return (
        <CssTextField
            SelectProps={{
                native: true,
            }}
            className="termsContainer"
            select
            variant="outlined"
            onChange={onChangeInput}
            error={error}
            helperText={helperText}
            value={value}
            label={label}
        >
            <option value="" />
            {options.map((option, i) => {
                return (
                    <option value={option.id} key={i}>
                        {option.name}
                    </option>
                );
            })}
        </CssTextField>
    );
};

export const Value = ({
    label,
    onChangeInput,
    value,
    id,
    disabled,
    error,
    mainId,
    helperText,
    override,
    entityData,
    overridden,
    ledgerAccount,
    startAdornment = "$",
    variant,
    required,
    createOverride,
    updateOverride,
    showSettings = true,
}) => {
    const [contextMenu, setContextMenu] = useState(null);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    return (
        <div className="wrapper">
            {overridden ? (
                <OverriddenCssTextField
                    id="standard-number"
                    label={label}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant={variant ?? "standard"}
                    onChange={(e) => onChangeInput(e, id, mainId)}
                    className="InputAmount"
                    value={value}
                    disabled={disabled}
                    InputProps={{
                        inputComponent: NumericFormatCustomNoPrefix,
                        startAdornment: startAdornment && (
                            <InputAdornment position="start">
                                {startAdornment}
                            </InputAdornment>
                        ),
                        endAdornment: showSettings && (
                            <InputAdornment position="end">
                                <SettingsIcon
                                    sx={{
                                        cursor: "pointer",
                                        color: "#f7ab5f",
                                    }}
                                    onClick={handleContextMenu}
                                />
                            </InputAdornment>
                        ),
                    }}
                    required={required ?? false}
                    error={error}
                    helperText={helperText}
                    onWheel={(e) => e.target.blur()}
                    onContextMenu={
                        entityData && override
                            ? handleContextMenu
                            : () => {
                                  return;
                              }
                    }
                />
            ) : (
                <CssTextField
                    id="standard-number"
                    label={label}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant={variant ?? "standard"}
                    onChange={(e) => onChangeInput(e, id, mainId)}
                    className="InputAmount"
                    value={value}
                    disabled={disabled}
                    InputProps={{
                        inputComponent: NumericFormatCustomNoPrefix,
                        startAdornment: startAdornment && (
                            <InputAdornment position="start">
                                {startAdornment}
                            </InputAdornment>
                        ),
                        endAdornment: showSettings && (
                            <InputAdornment position="end">
                                <SettingsIcon
                                    sx={{
                                        cursor: "pointer",
                                        color: "#A9A9A9",
                                    }}
                                    onClick={handleContextMenu}
                                />
                            </InputAdornment>
                        ),
                    }}
                    required={required}
                    error={error}
                    helperText={helperText}
                    onWheel={(e) => e.target.blur()}
                    onContextMenu={
                        entityData && override
                            ? handleContextMenu
                            : () => {
                                  return;
                              }
                    }
                />
            )}
            <Menu
                className="valueFieldContextMenu"
                style={{ zIndex: 5000 }}
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem disabled={true} onClick={handleClose}>
                    Inherit Upstream Value
                </MenuItem>
                <MenuItem
                    disabled={!(entityData && override)}
                    onClick={() => {
                        entityData && override
                            ? setShowCellRowModal({
                                  show: true,
                                  type: "override",
                                  field: "value",
                                  entityData: entityData,
                                  ledgerAccount: ledgerAccount,
                                  createOverride: createOverride,
                              })
                            : handleClose();
                        handleClose();
                    }}
                >
                    Manually Override Values
                </MenuItem>
                <MenuItem
                    disabled={!(entityData && overridden)}
                    onClick={() => {
                        entityData && override
                            ? (entityData.data.modsCreated = [])
                            : handleClose();
                        updateOverride();
                        handleClose();
                    }}
                >
                    Delete Override
                </MenuItem>
                <MenuItem disabled={true} onClick={handleClose}>
                    Link to Google Sheets
                </MenuItem>
                <MenuItem disabled={true} onClick={handleClose}>
                    Import from CSV
                </MenuItem>
            </Menu>
        </div>
    );
};

export const PercentValue = ({
    label,
    onChangeInput,
    value,
    id,
    disabled,
    error,
    mainId,
    helperText,
    required = false,
}) => {
    return (
        <div className="wrapper">
            <CssTextField
                id="standard-number"
                label={label}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => onChangeInput(e, id, mainId)}
                className="InputAmount"
                value={value}
                disabled={disabled}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                    ),
                }}
                error={error}
                helperText={helperText}
                onWheel={(e) => e.target.blur()}
                required={required}
            />
        </div>
    );
};

export const NoUnitValue = ({
    label,
    onChangeInput,
    value,
    id,
    disabled,
    error,
    mainId,
    helperText,
    override,
    entityData,
    overridden,
    required = false,
}) => {
    const [contextMenu, setContextMenu] = useState(null);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };
    return (
        <div className="wrapper">
            {overridden ? (
                <OverriddenCssTextField
                    id="standard-number"
                    label={label}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => onChangeInput(e, id, mainId)}
                    className="InputAmount"
                    value={value}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    onWheel={(e) => e.target.blur()}
                    onContextMenu={
                        entityData && override
                            ? handleContextMenu
                            : () => {
                                  return;
                              }
                    }
                    required={required}
                />
            ) : (
                <CssTextField
                    id="standard-number"
                    label={label}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => onChangeInput(e, id, mainId)}
                    className="InputAmount"
                    value={value}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    onWheel={(e) => e.target.blur()}
                    onContextMenu={
                        entityData && override
                            ? handleContextMenu
                            : () => {
                                  return;
                              }
                    }
                    required={required}
                />
            )}
            <Menu
                className="valueFieldContextMenu"
                style={{ zIndex: 5000 }}
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={handleClose}>
                    Inherit Upstream Value
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setShowCellRowModal({
                            show: false,
                        });
                        handleClose();
                    }}
                >
                    Manually Override Values
                </MenuItem>
                <MenuItem onClick={handleClose}>Link to Google Sheets</MenuItem>
                <MenuItem onClick={handleClose}>Import from CSV</MenuItem>
            </Menu>
        </div>
    );
};

export const ValueOutlined = ({
    value,
    label,
    onChangeInput,
    id,
    error,
    helperText,
    adornment,
}) => {
    return (
        <CssTextField
            type="number"
            id="outlined-basic"
            label={label}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e) => onChangeInput(e, id)}
            className="InputAmount"
            value={value}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {adornment ?? "$"}
                    </InputAdornment>
                ),
            }}
            variant="outlined"
            error={error}
            helperText={helperText}
            onWheel={(e) => e.target.blur()}
        />
    );
};
/**
 * Numerical (dollar) input, where number is displayed as a localeString with
 * commas. Unlike a plain string input, this forbids non-numbers, and has a few
 * QOL features such as double-click to select, and handling unordinary syntax
 * such as "-.1" elegantly
 *
 * @param  {string} label
 * @param  {function(e, id)} onChangeInput
 * @param  {number} value
 * @param  {string} id
 * @param  {boolean} disabled
 * @param  {boolean} allowNegative
 */
export const SelfCorrectingValue = ({
    label,
    onChangeInput,
    value,
    id,
    disabled,
    allowNegative,
}) => {
    const [val, setVal] = useState(String(value).toLocaleString("en"));
    function onClick(e) {
        e.target.length > 0 && e.setSelectionRange(0, e.target.length - 1);
    }
    function onChange(e) {
        let cleanVal = String(e.target.value)
            .replace(/[^0-9\-.]+/g, "") // remove non-numericals
            .replace(/[.]+/g, ".") // remove sequential periods e.g. "1..1"
            .replace(/[-]+/g, "-"); // remove sequential dashes e.g. "--1"

        // remove every period after the first
        if (cleanVal.lastIndexOf(".") !== cleanVal.indexOf(".")) {
            const fragments = cleanVal.split(".");
            const nFrags = fragments.length;
            let finalString = fragments[0].concat(".");
            for (let i = 1; i < nFrags; i++) {
                finalString = finalString.concat(fragments[i]);
            }
            cleanVal = finalString;
        }

        if (allowNegative) {
            // remove every dash not in initial position
            let i = cleanVal.lastIndexOf("-");
            while (cleanVal !== "" && i > 0) {
                let tmp = cleanVal.split("");
                tmp.splice(i, 1);
                cleanVal = tmp.join("");
                i = cleanVal.lastIndexOf("-");
            }
        } else {
            // remove all dashes
            cleanVal = cleanVal.replace("-", "");
        }
        // special exceptions
        let localeString =
            cleanVal === ""
                ? ""
                : cleanVal === "-"
                ? "-"
                : cleanVal === "."
                ? "."
                : cleanVal === "-."
                ? "-."
                : Number(cleanVal).toLocaleString("en");
        // reappend period in final place
        if (
            cleanVal.length > 1 &&
            cleanVal !== "-." &&
            cleanVal.charAt(cleanVal.length - 1) === "."
        ) {
            localeString = localeString.concat(".");
        }
        setVal(localeString);
        if (!isNaN(Number(cleanVal)))
            onChangeInput(
                {
                    target: {
                        value: cleanVal === "" ? null : Number(cleanVal),
                    },
                },
                id
            );
    }
    return (
        <CssTextField
            id="standard-number"
            label={label}
            type="string"
            InputLabelProps={{
                shrink: true,
            }}
            onChange={onChange}
            className="InputAmount"
            value={val}
            disabled={disabled}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                ),
            }}
            onDoubleClick={onClick}
        />
    );
};

export const NumberOutlined = ({
    value,
    label,
    onChangeInput,
    id,
    disabled,
    error,
    helperText,
}) => {
    return (
        <CssTextField
            type="number"
            id="outlined-basic"
            label={label}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e) => onChangeInput(e, id)}
            className="InputAmount"
            value={value}
            variant={"outlined"}
            disabled={disabled}
            error={error}
            helperText={helperText}
            onWheel={(e) => e.target.blur()}
        />
    );
};

export const Url = ({ value, onChangeInput, id, label, className }) => {
    return (
        <CssTextField
            value={value}
            className={className}
            onChange={(e) => onChangeInput(e, id)}
            id="outlined-basic"
            label={label}
            variant="outlined"
        />
    );
};

export const AgencyOnboardingTextField = ({
    value,
    onChangeInput,
    id,
    label,
    className,
}) => {
    return (
        <CssTextField
            value={value}
            className={className}
            onChange={(e) => onChangeInput(e, id)}
            id="outlined-basic"
            label={label}
            variant="outlined"
        />
    );
};

export const Stars = ({
    className,
    src,
    id,
    onChangeInput,
    onHandleStarHover,
    onMouseLeave,
}) => {
    return (
        <img
            alt="alt"
            className={className}
            src={src}
            id="rating"
            value={id}
            onClick={(e) => onChangeInput(e, "rating", id)}
            onMouseEnter={() => onHandleStarHover(id)}
            onMouseLeave={onMouseLeave}
        />
    );
};

export const Rate = ({
    value,
    onChangeInput,
    id,
    mainId,
    label,
    className,
    sign,
    disabled,
    edit,
    useFullWidth,
    error,
    helperText,
    placeholder,
    validate,
    onMouseEnter,
    onMouseLeave,
    inputAdornmentOverride,
    variant,
    required,
}) => {
    return (
        <CssTextField
            label={label}
            className={className || "outlined-basic"}
            onChange={(e) => !(edit === false) && onChangeInput(e, id, mainId)}
            value={value}
            id="outlined-basic"
            variant={variant ?? "outlined"}
            disabled={disabled}
            contentEditable={edit}
            InputProps={{
                inputComponent: NumericFormatCustomNoPrefix,
                endAdornment: (
                    <InputAdornment position="end">
                        {inputAdornmentOverride
                            ? inputAdornmentOverride
                            : sign && id !== "averageHours"
                            ? "%"
                            : ""}
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth={useFullWidth}
            error={error}
            required={required}
            helperText={helperText}
            placeholder={placeholder}
            onBlur={validate}
            onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
            onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
        />
    );
};

export const Period = ({
    value,
    onChangeInput,
    id,
    label,
    className,
    cadenceUnit,
    disabled,
    required,
}) => {
    return (
        <CssTextField
            label={label}
            className={className}
            onChange={(e) => onChangeInput(e, id)}
            value={value}
            id="outlined-basic"
            variant={"outlined"}
            disabled={disabled}
            required={required}
            InputProps={{
                inputComponent: NumericFormatCustomNoPrefix,
                endAdornment: (
                    <InputAdornment position="end">
                        {cadenceUnit ? cadenceUnit : ""}
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

// TODO - Adapt this function to new Entities logic!
export const InputButtons = ({
    edit,
    updateValues,
    onHandleSubmit,
    onHandleSubmitValues,
    passedCheck,
    onboarding,
    withSelected,
    selected,
    totalNumber,
    isLibraryEvent,
    isAddingLibraryEvent,
    addLibraryEventToScenario,
    divorceLibraryEvent,
}) => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const dispatch = useAppDispatch();
    const handleValuesUpdate = () => {
        if (!loggedInUser) {
            swal({
                icon: "info",
                text: "Please login or create an account to update an event",
                buttons: {
                    cancel: "Cancel",
                    login: true,
                },
            }).then((value) => {
                if (value === "login") {
                    dispatch(closeOption());
                    return dispatch(showLogin(true, LOG_IN));
                } else {
                    return null;
                }
            });
        } else if (edit) {
            updateValues();
        } else {
            onHandleSubmitValues();
        }
    };

    const handleSubmit = () => {
        if (!loggedInUser) {
            swal({
                icon: "info",
                text: "Please login or create an account to add an event",
                buttons: {
                    cancel: "Cancel",
                    login: true,
                },
            }).then((value) => {
                if (value === "login") {
                    dispatch(closeOption());
                    return dispatch(showLogin(true, LOG_IN));
                } else {
                    return null;
                }
            });
        } else {
            onHandleSubmit();
        }
    };

    const disableActions = useAppSelector(
        (state) => state?.scenario?.preventEventModalActions
    );

    return (
        <div className="buttonsWrapper">
            {/* {edit ? (
                <div className="RequiredInputLegendContainer">
                    <div className="RequiredInputLegendStar">*</div>
                    <div className="RequiredInputLegend">
                        For this event to be included in calculations, all
                        required fields must be completed
                    </div>
                </div>
            ) : (
                <div />
            )} */}

            {withSelected && (
                <div className="selected">
                    <span className="numberSelected">{selected}</span> /{" "}
                    {totalNumber} Selected
                </div>
            )}
            {isLibraryEvent && !isAddingLibraryEvent && (
                <div
                    onClick={divorceLibraryEvent}
                    className="resizable proceedButton "
                >
                    Detach from Library
                </div>
            )}
            {!passedCheck && !onboarding && (
                <div onClick={() => handleSubmit()} className="textButton">
                    Add Now, Fill Later
                </div>
            )}
            <div
                onClick={
                    passedCheck && !disableActions
                        ? () => handleValuesUpdate()
                        : null
                }
                className={
                    passedCheck && !disableActions
                        ? "proceedButton"
                        : "proceedButtonDisabled"
                }
            >
                {edit ? "Update" : "Fill & Add"}
            </div>
            {isLibraryEvent && isAddingLibraryEvent && (
                <div
                    onClick={
                        addLibraryEventToScenario
                            ? addLibraryEventToScenario
                            : () => {
                                  // noop
                              }
                    }
                    className="proceedButton"
                >
                    Add
                </div>
            )}
            {onboarding && (
                <img alt="alt" src={tourPoint} className="tourPoint" />
            )}
        </div>
    );
};

export const EventInputModal = ({ children }) => {
    return <div className="mainRootContainer">{children}</div>;
};

export const EventInputs = ({ children }) => {
    return <div className="inputRoot">{children}</div>;
};

// ENTITY COMPONENTS START

export const EntityCard = ({
    children,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    handleClickAddEntityCard,
    entityIndex,
    entitiesLength,
    passedCheck,
}) => {
    const dispatch = useAppDispatch();
    const disableActions = useAppSelector(
        (state) => state?.scenario?.preventEventModalActions
    );

    const handleClick = (clickAction) => {
        if (disableActions) return;
        dispatch(toggleEventModalActions());
        clickAction();
        setTimeout(() => {
            dispatch(toggleEventModalActions());
        }, 1500);
    };

    return (
        <div className="entityCardContainer">
            <div className="entityCard">
                <div className="entityCardInputs">{children}</div>
                <div className="entityCardFooter">
                    <div className="entityCardFooterArrowsWrapper">
                        <span
                            className={
                                entityIndex === 0
                                    ? "entityCardFooterArrowsLeftDisabled"
                                    : "entityCardFooterArrowsLeft"
                            }
                            onClick={() => handleClickChangeEntity("left")}
                        ></span>
                        <span className="entityCardFooterArrowsText">
                            {entityIndex + 1} of{" "}
                            {entitiesLength && entitiesLength}
                        </span>
                        <span
                            className={
                                entityIndex === entitiesLength - 1
                                    ? "entityCardFooterArrowsRightDisabled"
                                    : "entityCardFooterArrowsRight"
                            }
                            onClick={() => handleClickChangeEntity("right")}
                        ></span>
                    </div>
                    <div className="entityCardFooterButtons">
                        <div
                            className={
                                passedCheck && !disableActions
                                    ? "entityCardFooterButton"
                                    : "entityCardFooterButton entityCardFooterButtonDisabled"
                            }
                            onClick={
                                passedCheck
                                    ? () =>
                                          handleClick(handleClickAddEntityCard)
                                    : () => {
                                          return;
                                      }
                            }
                        >
                            {/* <span className="entityCardFooterButtonAddIcon"></span> */}
                            <AddSVG className="entityCardFooterButtonAddIcon" />
                            <span className="entityCardFooterButtonText">
                                Add New Entry
                            </span>
                        </div>
                        <div
                            className="entityCardFooterButton"
                            onClick={() => handleClick(handleClickDeleteEntity)}
                        >
                            <DeleteOutlineIcon
                                sx={{
                                    cursor: "pointer",
                                    color: disableActions
                                        ? "#bebebe"
                                        : "#f7ab5f",
                                    height: "24px",
                                    width: "24px",
                                    marginRight: "0.5rem",
                                }}
                            />
                            <span
                                className={
                                    disableActions
                                        ? "entityCardFooterButtonText entityCardFooterButtonDisabled"
                                        : "entityCardFooterButtonText"
                                }
                            >
                                Delete
                            </span>
                        </div>
                        <div
                            className={
                                passedCheck && !disableActions
                                    ? "entityCardFooterButton"
                                    : "entityCardFooterButton entityCardFooterButtonDisabled"
                            }
                            onClick={
                                passedCheck
                                    ? () =>
                                          handleClick(
                                              handleClickDuplicateEntity
                                          )
                                    : () => {
                                          return;
                                      }
                            }
                        >
                            <span className="entityCardFooterButtonIcon2"></span>
                            <span className="entityCardFooterButtonText">
                                Duplicate
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SingleEntity = ({ children }) => {
    return (
        <div className="singleEntityContainer">
            <div className="singleEntity">
                <div className="singleEntityInputs">{children}</div>
            </div>
        </div>
    );
};

export const EntityRow = ({ children, twoInputs }) => {
    return (
        <div className={twoInputs ? "entityRowSpaceBetween" : "entityRow"}>
            {children}
        </div>
    );
};

export const EventDetails = ({ children }) => {
    return <div className="eventDetailsContainer">{children}</div>;
};

export const EventEntityWrapper = ({ children }) => {
    return <div className="eventEntityWrapper">{children}</div>;
};

// ENTITY COMPONENTS END

export const ModalColumn = ({ children, cssOverrides = {} }) => {
    return (
        <div style={cssOverrides} className="modalColumn">
            {children}
        </div>
    );
};

export const ModalRow = ({
    children,
    twoInputs,
    onMouseEnter,
    onMouseLeave,
    id,
    cssOverrides = {},
}) => {
    const className = twoInputs ? "ModalRowSpaceBetween" : "ModalRow";
    return (
        <div
            style={cssOverrides}
            className={className}
            onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
            onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
        >
            {children}
        </div>
    );
};

export const ModalRowHalf = ({
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    id,
    cssOverrides = {},
}) => {
    return (
        <div
            style={cssOverrides}
            className="ExpenseAmountContainer"
            onClick={onClick}
            onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
            onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
        >
            {children}
        </div>
    );
};

export const ModalRowTwoThirds = ({
    children,
    id,
    onMouseEnter,
    onMouseLeave,
}) => {
    return (
        <div
            className="twoThird"
            onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
            onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
        >
            {children}
        </div>
    );
};

export const ModalRowOneThird = ({
    children,
    id,
    onMouseEnter,
    onMouseLeave,
}) => {
    return (
        <div
            className="oneThird"
            onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
            onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
        >
            {children}
        </div>
    );
};

export const ModalRowComponent = ({
    children,
    id,
    onMouseEnter,
    onMouseLeave,
    width,
}) => {
    return (
        <div
            className="modalRowComponent"
            style={{ width: `${width}%` }}
            onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
            onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
        >
            {children}
        </div>
    );
};

export const RequiredStar = () => {
    return <div className="Required">*</div>;
};

export const EventHeader = ({ image, eventType, name, onChangeInput }) => {
    return (
        <div className="headerContainer">
            <img alt="alt" src={image} className="flinksLogo" />
            <div className="headerInput">
                <div className="headerLabel">
                    {String(eventType).toUpperCase()}
                </div>
                <div className="inputContainer">
                    <Name name={name} onChangeInput={onChangeInput} />
                </div>
            </div>
        </div>
    );
};

/**
 * @param  {boolean} doApply
 * @param  {function()} toggleDoApply
 * @param  {number} rate
 * @param  {string} label
 * @param  {function(e, id)} onChangeInput
 * @param  {string} id
 * @param  {function()} onMouseEnter
 */
export const ToggledRate = ({
    doApply,
    toggleDoApply,
    rate,
    rateId,
    label,
    onChangeInput,
}) => {
    return (
        <>
            <Switch
                checked={doApply}
                className="InflationToggle"
                height={20}
                width={40}
                onChange={toggleDoApply}
                onColor="#F8B46A"
            />
            <div className="InflationText">{label}</div>
            <div className={doApply ? "PVRateActive" : "PVRate"}>
                <input
                    className={doApply ? "PVInput" : "PVInputDisabled"}
                    value={rate}
                    id={rateId}
                    type="number"
                    disabled={!doApply}
                    onChange={(e) => onChangeInput(e, rateId)}
                    onWheel={(e) => e.target.blur()}
                />{" "}
                %
            </div>
        </>
    );
};

/**
 * @param  {boolean} applyInflation
 * @param  {number} inflationRate
 * @param  {function(e, id)} onChangeInput
 * @param  {function()} toggleInflation
 */
export const InflationInput = ({
    applyInflation,
    inflationRate,
    onChangeInput,
    toggleInflation,
}) => {
    return (
        <ToggledRate
            doApply={applyInflation}
            rate={inflationRate}
            label="Inflation Rate"
            rateId="inflationRate"
            onChangeInput={onChangeInput}
            toggleDoApply={toggleInflation}
        />
    );
};

/**
 * @param  {boolean} applyInflation
 * @param  {function()} toggleInflation
 */
export const InflationSwitch = ({ applyInflation, toggleInflation }) => {
    return (
        <>
            <Switch
                checked={applyInflation}
                className="InflationToggle"
                height={20}
                width={40}
                onChange={toggleInflation}
                onColor="#F8B46A"
            />
            <div className="InflationText">Apply inflation</div>
        </>
    );
};

export const ToggledContainer = ({ children }) => {
    return <div className="toggledContainer">{children}</div>;
};

/**
 * @param  {string} id
 * @param  {boolean} isSelected
 * @param  {function(e)} onClick
 * @param  {string} title
 * @param  {function(id)} onMouseEnter
 */
export const ToggledContainerHalf = ({
    id,
    isSelected,
    onClick,
    title,
    onMouseEnter,
    disabled = false,
}) => {
    return (
        <div
            id={id}
            onClick={(e) => onClick(e, id)}
            className={`${
                isSelected ? "toggleBox toggleBoxActive" : "toggleBox"
            } ${disabled && "toggleBoxDisabled"}`}
            onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
        >
            {title}
        </div>
    );
};

/**
 * @param  {Object} props
 * @param  {function()} props.onHoverStar
 * @param  {number} props.rating
 * @param  {function(e, id, star)} props.onChangeInput
 * @param  {function()} props.onHandleStarHover
 * @param  {function()} props.onMouseLeave
 */
export const StarsRating = ({
    onHoverStar,
    rating,
    onChangeInput,
    onHandleStarHover,
    onMouseLeave,
}) => {
    const stars = [1, 2, 3, 4, 5];
    return (
        <>
            <div className="keyLabel">Rating</div>
            <div className="StarsHolder">
                {stars.map((star, i) => {
                    const id = i + 1;
                    const onHover = onHoverStar;
                    if (i < rating || i < onHover) {
                        return (
                            <Stars
                                key={i}
                                className="starFilledSvg"
                                src={starFilledSvg}
                                id={id}
                                onChangeInput={onChangeInput}
                                onHandleStarHover={onHandleStarHover}
                                onMouseLeave={onMouseLeave}
                            />
                        );
                    } else {
                        return (
                            <Stars
                                key={i}
                                className="starUnfilledSvg"
                                src={starUnfilledSvg}
                                id={id}
                                onChangeInput={onChangeInput}
                                onHandleStarHover={onHandleStarHover}
                                onMouseLeave={onMouseLeave}
                            />
                        );
                    }
                })}
            </div>
        </>
    );
};
/**
 * @param  {function()} {toggleAdvancedSettings
 * @param  {boolean} showSettings
 */
export const AdvancedSettingsToggle = ({
    toggleAdvancedSettings,
    showSettings,
}) => {
    return (
        <div className="halfDiv">
            <img
                alt="alt"
                onClick={toggleAdvancedSettings}
                className="selectedIcon"
                src={showSettings ? dropdownSvg : expandSvg}
            />
            <div
                className="advanceSettingLabel"
                onClick={toggleAdvancedSettings}
            >
                Advanced settings
            </div>
        </div>
    );
};

/**
 * @param  {string} id
 * @param  {boolean} isActive
 * @param  {function()} toggleIsActive
 * @param  {string} label
 */
export const CheckBox = ({ id, isActive, toggleIsActive, label }) => {
    return (
        <>
            <img
                alt="alt"
                onClick={toggleIsActive}
                src={isActive ? checkboxOn : checkboxOff}
                className="checkBox"
                id={id}
            />
            <div className="disabilityText">{label}</div>
        </>
    );
};

/**
 * @param  {string} id
 * @param  {boolean} isSelected
 * @param  {function(e)} onClick
 * @param  {string} title
 * @param  {function(id)} onMouseEnter
 */

export const CheckBoxId = ({
    id,
    isActive,
    onClick,
    label,
    onMouseEnter,
    onMouseLeave,
}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
                gap: "0.5rem",
            }}
        >
            <img
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                alt="alt"
                onClick={(e) => onClick(e, id)}
                src={isActive ? checkboxOn : checkboxOff}
                className="checkBoxId"
                id={id}
            />
            <span
                onMouseEnter={() => onMouseEnter && id && onMouseEnter(id)}
                onMouseLeave={() => onMouseLeave && id && onMouseLeave(id)}
                className="checkboxText"
            >
                {label}
            </span>
        </div>
    );
};

/**
 * @param  {string} id
 * @param  {boolean} isSelected
 * @param  {function(e)} onClick
 * @param  {string} star
 * @param  {number} index
 */

export const CheckBoxOnly = ({ id, isActive, onClick, star }) => {
    return (
        <>
            <img
                alt="alt"
                onClick={(e) => onClick(e, id, star)}
                src={isActive ? checkboxOn : checkboxOff}
                className="checkBoxId"
                id={id}
            />
        </>
    );
};

export const InfoBox = ({ children }) => {
    return (
        <ModalRow>
            <div className="loanPaymentsContainer">{children}</div>
        </ModalRow>
    );
};

export const InfoBoxRow = ({ children }) => {
    return <div className="paymentsAmountContainer">{children}</div>;
};

export const InfoBoxLabel = ({ text }) => {
    return <div className="paymentsLabel">{text}</div>;
};

export const InfoBoxPrice = ({ value }) => {
    let text = "";
    if (value || value === 0) {
        const temp =
            value < 1000 && value > -1000
                ? roundNumberForDisplay(value)
                : Math.round(value);
        text = `$ ${temp.toLocaleString("en")}`;
    }
    return <div className="paymentsValue">{text}</div>;
};

export const InfoBoxPercent = ({ value }) => {
    const text = `${roundNumberForDisplay(value)} %`;
    return <div className="paymentsValue">{text}</div>;
};

/**
 * Bold Font
 */
export const B = ({ children }) => {
    return <Text className="boldText">{children}</Text>;
};

/**
 * Orange Font
 */
export const O = ({ children }) => {
    return <Text className="orangeText">{children}</Text>;
};

// SelectionBox is currently only suited for entities or events
export const SelectionBox = ({
    label,
    items,
    isItemSelected,
    handleOnChange,
    type, // "events" or "entities"
    getEarliestStartDate,
    getLatestEndDate,
}) => {
    if (items.length < 1) {
        return null;
    }

    const valueType = items[0].data?.cost
        ? "cost"
        : items[0].data?.amount
        ? "amount"
        : "value";
    return (
        <>
            <div className="selection-box">
                <div className="selection-box-label">{label}</div>
                {items.map((item) => {
                    const isSelected = isItemSelected(item.id);

                    if (type === "events") {
                        const startDateValue =
                            getEarliestStartDate(item.id) || "n/a";
                        const endDateValue = getLatestEndDate(item.id) || "n/a";

                        return (
                            <div key={item.id}>
                                <div
                                    style={{
                                        display: "flex",
                                        paddingBottom: "5px",
                                        fontWeight: `${
                                            isSelected ? "bold" : "normal"
                                        }`,
                                    }}
                                >
                                    <CheckBoxOnly
                                        id="select-event"
                                        isActive={isSelected}
                                        onClick={handleOnChange}
                                        star={item.id}
                                    />
                                    <div
                                        style={{
                                            width: "46%",
                                            paddingLeft: "14px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                    <div
                                        style={{
                                            width: "20%",
                                            textAlign:
                                                startDateValue === "n/a"
                                                    ? "center"
                                                    : "left",
                                        }}
                                    >
                                        {startDateValue}
                                    </div>
                                    <div
                                        style={{
                                            width: "18%",
                                            textAlign:
                                                endDateValue === "n/a"
                                                    ? "center"
                                                    : "left",
                                        }}
                                    >
                                        {endDateValue}
                                    </div>
                                    <div
                                        style={{
                                            width: "16%",
                                            textAlign: "right",
                                        }}
                                    >
                                        {Object.values(item.entities).length +
                                            ` Record${
                                                Object.values(item.entities)
                                                    .length > 1
                                                    ? "s"
                                                    : ""
                                            }`}
                                    </div>
                                </div>
                            </div>
                        );
                    } else if (type === "entities") {
                        const cadenceValue = item.cadence || "n/a";
                        const startDateValue = item.startDate || "n/a";
                        const endDateValue = item.endDate || "n/a";
                        const valueValue =
                            (valueType === "value"
                                ? item.data.value
                                : valueType === "amount"
                                ? item.data.amount
                                : item.data.cost) || "n/a";

                        return (
                            <div key={item.id}>
                                <div
                                    style={{
                                        display: "flex",
                                        paddingBottom: "5px",
                                        fontWeight: `${
                                            isSelected ? "bold" : "normal"
                                        }`,
                                    }}
                                >
                                    <CheckBoxOnly
                                        id="select-entity"
                                        isActive={isSelected}
                                        onClick={handleOnChange}
                                        star={item.id}
                                    />
                                    <div
                                        style={{
                                            width: "25%",
                                            paddingLeft: "14px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                    <div
                                        style={{
                                            width: "20%",
                                            textAlign:
                                                cadenceValue === "n/a"
                                                    ? "center"
                                                    : "left",
                                        }}
                                    >
                                        {cadenceValue}
                                    </div>
                                    <div
                                        style={{
                                            width: "18%",
                                            textAlign:
                                                startDateValue === "n/a"
                                                    ? "center"
                                                    : "left",
                                        }}
                                    >
                                        {startDateValue}
                                    </div>
                                    <div
                                        style={{
                                            width: "17%",
                                            textAlign:
                                                endDateValue === "n/a"
                                                    ? "center"
                                                    : "left",
                                        }}
                                    >
                                        {endDateValue}
                                    </div>
                                    <div
                                        style={{
                                            width: "16%",
                                            textAlign:
                                                valueValue === "n/a"
                                                    ? "center"
                                                    : "right",
                                        }}
                                    >
                                        {valueValue}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </>
    );
};

export const MappingArrow = () => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <img alt="alt" src={arrowSvg} height="12" width="20" />
        </div>
    );
};

export const PlusButton = ({ onClick, label }) => {
    return (
        <>
            <img
                alt="alt"
                className="plus-button"
                onClick={onClick}
                src={addSvg}
            />
            <div onClick={onClick} className="textButton">
                {label}
            </div>
        </>
    );
};

export const DeleteButton = ({ onClick }) => {
    return (
        <>
            <img
                alt="alt"
                className="delete-button"
                onClick={onClick}
                src={deleteSvg}
            />
            <div onClick={onClick}></div>
        </>
    );
};

export const EditButton = ({ onClick }) => {
    return (
        <>
            <img
                alt="alt"
                className="edit-button"
                onClick={onClick}
                src={editSvg}
            />
            <div onClick={onClick}></div>
        </>
    );
};

export const ConfirmButton = ({ onClick, label, passedCheck }) => {
    return (
        <div className="buttonsWrapper">
            <div
                onClick={passedCheck ? onClick : undefined}
                className={
                    passedCheck ? "proceedButton" : "proceedButtonDisabled"
                }
            >
                {label || "Confirm"}
            </div>
        </div>
    );
};

export const GenericButton = ({ label, onClick, disabled, width }) => {
    return (
        <div
            onClick={onClick}
            className={
                disabled
                    ? "proceedButtonGenericDisabled"
                    : "proceedButtonGeneric"
            }
            style={{
                width: width || "156px",
            }}
        >
            {label}
        </div>
    );
};

export const RadioButton = ({ onClick, isActive, label, value, id }) => {
    return (
        <>
            <img
                alt="alt"
                onClick={() => onClick(value, id)}
                src={isActive ? radioButtonOn : radioButtonOff}
                className="pttRadioButton"
            />
            <div className="radio-button-text">{label}</div>
        </>
    );
};

export const CsvImportButton = ({ onClick }) => {
    return (
        <div className="csv-import-button-container" onClick={onClick}>
            <div className="csv-import-button">
                <img alt="alt" src={CsvImportSvg} />
                <div className="csv-button-text">Upload CSV File</div>
            </div>
        </div>
    );
};

export const ItemBox = ({
    itemId,
    itemType,
    offset,
    multiplier,
    offsetMode,
    clip,
    handleEditItem,
    handleDeleteItem,
    index,
}) => {
    const getItem = (id, type) => {
        if (type === "events") {
            return getEvent(id);
        } else {
            return getRelevantEntities([id])[id];
        }
    };
    const selectedItem = getItem(itemId, itemType);
    return (
        <div className="item-box">
            <div className="item-box-number">{index + 1}</div>
            <div className="item-box-multiplier">{`${multiplier}x`}</div>
            <div className="item-box-name">{selectedItem?.name}</div>
            <div className="item-box-clip">{clip ? "Clip" : "Full"}</div>
            <div className="item-box-itemType">
                {itemType === "events" ? "Event" : "Record"}
            </div>
            <div className="item-box-records">
                {itemType === "events"
                    ? selectedItem.entities.length +
                      ` Record${selectedItem.entities.length > 1 ? "s" : ""}`
                    : "n/a"}
            </div>
            <div className="item-box-extra">
                {`${offset} ${offsetMode === "days" ? "day" : "month"} offset`}
            </div>
            <EditButton onClick={() => handleEditItem(itemId)} />
            <div
                style={{
                    width: "25px",
                }}
            ></div>
            <DeleteButton onClick={() => handleDeleteItem(itemId)} />
        </div>
    );
};

// empty spacer that fills the maximum available horizontal space
export const HorizontalSpacer = () => {
    return (
        <div
            style={{
                display: "flex",
                flexGrow: 5,
            }}
        ></div>
    );
};

export const EntityNumericalFieldDisplay = ({ numericalFields }) => {
    return (
        <div className="entityFieldGridBox">
            {numericalFields.map((field, index) => {
                const displayString = `${field.label}`;
                return (
                    <div className="entityGridItem" key={index}>
                        {displayString}
                    </div>
                );
            })}
        </div>
    );
};

export const FillAreaTextBlock = ({ copy, textAlign }) => {
    return (
        <p
            className="fillAreaTextBlock"
            styles={{ textAlign: textAlign ?? "start" }}
        >
            {copy ?? ""}
        </p>
    );
};

export const BasicHeading = ({ copy, margin }) => {
    return (
        <h3 className="basicHeading" style={{ margin: margin ?? 0 }}>
            {copy ?? ""}
        </h3>
    );
};

export const BasicTextInput = ({
    inputLabel,
    id,
    value,
    onChangeInput,
    variant,
    placeholder,
    mainId,
    disabled,
    required = false,
    multiline = false,
    rows = "1",
}) => {
    return (
        <CssTextField
            label={inputLabel}
            onChange={(e) => onChangeInput(e, id, mainId)}
            value={value}
            id="standard-basic"
            variant={variant ?? "outlined"}
            multiline={multiline}
            rows={rows}
            placeholder={placeholder ?? ""}
            fullWidth={true}
            disabled={disabled}
            required={required}
        />
    );
};

/**
 *
 *
 * API Docs: https://mui.com/x/api/date-pickers/date-picker
 */
export const MuiCalendar = ({
    id,
    dynamicId,
    value,
    onChangeInput,
    label,
    textFieldVariant = "outlined",
    inputFormat = "MM/DD/YYYY",
    autoFocus,
    required,
    helperText,
    minDate,
    disabled,
    disablePast,
    disableFuture,
    disableOpenPicker,
    onMouseEnter,
    onMouseLeave,
    views,
}) => {
    if (value === "Invalid date" || value === "") value = null;
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                views={views}
                label={label ?? ""}
                inputFormat={inputFormat}
                value={value}
                onChange={(newValue) => onChangeInput(id, newValue, dynamicId)}
                renderInput={(params) => (
                    <CssTextField
                        variant={textFieldVariant}
                        autoFocus={autoFocus}
                        required={required}
                        fullWidth={true}
                        helperText={helperText}
                        onMouseEnter={() =>
                            onMouseEnter && id && onMouseEnter(id)
                        }
                        onMouseLeave={() =>
                            onMouseLeave && id && onMouseLeave(id)
                        }
                        {...params}
                    />
                )}
                minDate={minDate}
                disabled={disabled}
                disablePast={disablePast}
                disableFuture={disableFuture}
                disableOpenPicker={disableOpenPicker}
            />
        </LocalizationProvider>
    );
};

export const EntitySummaryHighlight = ({
    mainText = "",
    subText = "",
    highlightedData = "",
}) => {
    return (
        <div className="entitySummaryHighlight">
            <div className="entitySummaryHighlight__leftContainer">
                <h3 className="entitySummaryHighlight__mainText">{mainText}</h3>
                <p className="entitySummaryHighlight__subText">{subText}</p>
            </div>
            <span className="entitySummaryHighlight__highlightedData">
                {highlightedData}
            </span>
        </div>
    );
};

export const EntitySummaryHighlightBlank = ({ copy }) => {
    return (
        <div className="entitySummaryHighlight">
            <p className="entitySummaryHighlight__standardCopy">{copy}</p>
        </div>
    );
};

export const KPIListItem = ({
    displayName,
    activeKPIField,
    description,
    additionalInfo,
    categorys,
    isActive,
    clickAction,
    isValid,
}) => {
    return (
        <KPIListItemButtonStyled
            disableRipple={!isValid?.kpiValid}
            sx={
                !isValid?.kpiValid
                    ? {
                          color: "rgba(0, 0, 0, 0.26)",
                          cursor: "default",
                          "&:hover": {
                              backgroundColor: "#FFF",
                              color: "rgba(0, 0, 0, 0.26)",
                          },
                      }
                    : {}
            }
            selected={isActive}
            onClick={(e) => {
                isValid?.kpiValid
                    ? clickAction(e, activeKPIField)
                    : () => {
                          return;
                      };
            }}
        >
            {isActive ? (
                <CheckBoxIcon
                    sx={{ padding: "0 26px 0 8px", color: "#F8B46A" }}
                />
            ) : (
                <CheckBoxOutlineBlankIcon
                    sx={{ padding: "0 26px 0 8px", color: "#F8B46A" }}
                />
            )}
            <div className="KPIListItem__copyContainer">
                <span className="KPIListItem__name">{displayName}</span>
                <span className="KPIListItem__description">{description}</span>
            </div>
            <div className="KPIListItem__iconsContainer">
                {categorys &&
                    categorys.map((category, i) => (
                        <span className="KPIListItem__category" key={i}>
                            {category}
                        </span>
                    ))}
                {isValid?.kpiValid ? (
                    <Tooltip title={additionalInfo}>
                        <InfoOutlinedIcon
                            sx={{ color: "rgba(0, 0, 0, 0.54);" }}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title={isValid.warningMessage}>
                        <WarningIcon sx={{ color: "rgba(0, 0, 0, 0.54);" }} />
                    </Tooltip>
                )}
            </div>
        </KPIListItemButtonStyled>
    );
};

export const ScrollableFlexColumn = ({ children, maxHeight }) => {
    return (
        <div
            className="scrollableFlexColumn"
            style={{ maxHeight: maxHeight ? `${maxHeight}px` : "unset" }}
        >
            {children}
        </div>
    );
};

export const ExpressionStep = ({
    stepNumber,
    stepObject,
    allLedgersData,
    callback,
}) => {
    const [leftValue, setLeftValue] = useState(
        stepObject?.leftValue
            ? stepObject?.leftValue
            : stepNumber > 1
            ? "result"
            : ""
    );
    const [rightValue, setRightValue] = useState(stepObject?.rightValue ?? "");
    const [operator, setOperator] = useState(stepObject?.operator ?? "+");

    const handleOperatorClick = () => {
        switch (operator) {
            case "+":
                setOperator("-");
                break;
            case "-":
                setOperator("x");
                break;
            case "x":
                setOperator("/");
                break;
            case "/":
                setOperator("+");
                break;
            default:
                break;
        }
    };

    const internalAccountSelectionCallback = (accountType, position) => {
        if (position === "left") {
            setLeftValue(accountType?.id);
        } else if (position === "right") {
            setRightValue(accountType?.id);
        }
    };

    const handleSwapClick = (currentLeftValue, currentRightValue) => {
        setLeftValue(currentRightValue);
        setRightValue(currentLeftValue);
    };

    useEffect(() => {
        if (leftValue && rightValue) {
            if (
                leftValue !== stepObject.leftValue ||
                rightValue !== stepObject.rightValue ||
                operator !== stepObject.operator
            ) {
                const expressionObject = {
                    id: stepObject.id,
                    leftValue,
                    rightValue,
                    operator,
                };
                callback(expressionObject);
            }
        }
        // eslint-disable-next-line
    }, [leftValue, operator, rightValue]);

    return (
        <div className="expressionStep">
            <h2 className="expressionStep__header">Step {stepNumber}</h2>
            <div className="expressionStep__stepContainer">
                {leftValue === "result" ? (
                    <div className="expressionStep__result">
                        <span className="expressionStep__resultText">
                            result
                        </span>
                    </div>
                ) : (
                    <InputDropDownButton
                        callback={(accountType) =>
                            internalAccountSelectionCallback(
                                accountType,
                                "left"
                            )
                        }
                        initialValue={allLedgersData?.[leftValue]?.name ?? ""}
                        required={true}
                    />
                )}
                <div className="expressionStep__iconsContainer">
                    <div
                        className="expressionStep__operatorButton"
                        onClick={handleOperatorClick}
                    >
                        {operator}
                    </div>
                    <img
                        src={directionSwapIcon}
                        alt="swap"
                        className="expressionStep__swapIcon"
                        onClick={() => handleSwapClick(leftValue, rightValue)}
                    />
                </div>
                {rightValue === "result" ? (
                    <div className="expressionStep__result">
                        <span className="expressionStep__resultText">
                            result
                        </span>
                    </div>
                ) : (
                    <InputDropDownButton
                        callback={(accountType) =>
                            internalAccountSelectionCallback(
                                accountType,
                                "right"
                            )
                        }
                        initialValue={allLedgersData?.[rightValue]?.name ?? ""}
                        required={true}
                    />
                )}
            </div>
        </div>
    );
};

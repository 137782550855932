import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { addAllAverages } from "../helpers/addAllAverages";
import {
    getDisplayValue,
    getMontlyOrCumulativeText,
} from "Components/ScenarioView/helpers/getDisplayValue";

export function AverageValue() {
    const { accountLedgerId, finalAccountLedgerId, monthlyCumulative } =
        useAppSelector((state) => {
            const { monthlyCumulative, selectedAccounts } = state.chartGraph;
            const accountLedgerId = Object.keys(selectedAccounts ?? {})?.[0];
            const finalAccountLedgerId =
                monthlyCumulative === "cumulative"
                    ? `Cumulative-${accountLedgerId}`
                    : accountLedgerId;

            return {
                accountLedgerId,
                finalAccountLedgerId,
                monthlyCumulative,
            };
        }, shallowEqual);

    const ledgerMetadata = useAppSelector((state) => {
        const ledgersMetadata = state?.allAccountLedgers?.ledgersMetadata;

        return ledgersMetadata[accountLedgerId];
    }, shallowEqual);

    const nodeIds = useAppSelector((state) => {
        const { nodeIds: _nodeIds } = state.chartGraph;
        return _nodeIds.length ? _nodeIds : ["_All"];
    }, shallowEqual);

    const activeThreadCalculatedThread = useAppSelector((state) => {
        const activeThreadId = state.scenario?.highlightedThread.signature;
        const calculatedThreads = state.calculatedThreads;
        const _activeThreadCalculatedThread =
            calculatedThreads?.[activeThreadId]?.ledgersDataAverages || {};
        return _activeThreadCalculatedThread;
    }, shallowEqual);

    const averageValue = useMemo(
        () =>
            addAllAverages(
                activeThreadCalculatedThread?.[finalAccountLedgerId],
                nodeIds
            ),
        [activeThreadCalculatedThread, finalAccountLedgerId, nodeIds]
    );

    return (
        <div className="AmchartsAmount">
            <div className="AmchartsAmountValue">
                {getDisplayValue(ledgerMetadata.unit, averageValue)}
            </div>
            <span className="interval">
                {getMontlyOrCumulativeText(ledgerMetadata, monthlyCumulative)}
            </span>
        </div>
    );
}

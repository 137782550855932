import { shallowEqual } from "react-redux";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { resetZoomedEvent } from "actions/scenario";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import styles from "./ZoomedEvent.module.css";
import BackArrowOrangeIcon from "Assets/_budgetIcons/back_orange.svg";
import { ScenarioViewEntity } from "./ScenarioViewEntity";

export const ZoomedEvent = () => {
    const dispatch = useAppDispatch();

    const { zoomedEvent, zoomedEventEntitiesArray } = useAppSelector(
        (state) => {
            const zoomedEventId: string = state?.scenario?.zoomedEventId;
            const manager = state?.scenario?.manager;
            const zoomedEvent = manager?._findEvent(zoomedEventId);
            const zoomedEventEntities = getRelevantEntities(
                zoomedEvent?.entities
            );
            const zoomedEventEntitiesArray = Object.values(zoomedEventEntities);

            return {
                zoomedEvent,
                zoomedEventEntitiesArray,
            };
        },
        shallowEqual
    );

    return (
        <div className={styles.container}>
            <div
                className={styles.returnContainer}
                onClick={() => dispatch(resetZoomedEvent())}
            >
                <img
                    className={styles.returnIcon}
                    src={BackArrowOrangeIcon}
                    alt="back arrow"
                />
                <h2 className={styles.returnHeading}>Back to all events</h2>
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.entitiesContainer}>
                    <div className={styles.entitiesContainerInner}>
                        {zoomedEventEntitiesArray &&
                            zoomedEventEntitiesArray.map((entity) => (
                                <ScenarioViewEntity
                                    key={entity.id}
                                    entityData={entity}
                                    relatedEvent={zoomedEvent}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

import styles from "./dropDown.module.css";
import { useMemo } from "react";

export const DropDownCellRenderer = (props) => {
    const rowData = props?.node?.data;
    const columnDef = props?.colDef;
    const {
        conditionalFieldName,
        conditionalFieldConditionValue,
        cellRendererDropDownOptions,
    } = props;
    const editable: boolean = useMemo(() => {
        if (columnDef?.field === "cadence" && rowData?.containsModifier)
            return false;
        if (!conditionalFieldName) return true;
        if (
            String(rowData[conditionalFieldName]) ===
            String(conditionalFieldConditionValue)
        ) {
            return true;
        }
        return false;
    }, [
        conditionalFieldConditionValue,
        conditionalFieldName,
        rowData,
        columnDef,
    ]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        const colId = props.column.colId;
        props.node.setDataValue(colId, newValue);
    };

    return (
        <div className={styles?.container}>
            <select
                disabled={!editable}
                className={styles?.dropDownSelect}
                value={props?.value}
                onChange={handleChange}
            >
                {cellRendererDropDownOptions &&
                    cellRendererDropDownOptions?.length > 0 &&
                    cellRendererDropDownOptions?.map((option, i) => (
                        <option key={i} value={option}>
                            {option ?? ""}
                        </option>
                    ))}
            </select>
        </div>
    );
};

import { useMemo, useContext, useState, useEffect } from "react";
import { Group, Line, Image } from "react-konva";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { useEventCanvasData } from "../../hooks/useEventCanvasData";
import { isLineInHighlightedThread } from "./helpers/isLineInHighlightedThread";
import {
    HoveredLineIdContext,
    FocusedLineIdContext,
    SetHoveredLineIdContext,
} from "../../contexts/CanvasLinesStateContexts";
import { CanvasStageScaleContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageScaleContext";
import { focusLine } from "actions/canvasLineActions";
import moreButtonURL from "Assets/_nodeIcons/more.svg";
import type Event from "Events/_event";
import { Portal } from "react-konva-utils";

import useImage from "use-image";
import { shallowEqual } from "react-redux";
import { InsertMenuIdContext } from "./contexts/InsertMenuIdContext";
import { InsertMenu } from "./partials/InsertMenu/index";
import { FocusedLineContext } from "./contexts/focusedLineContext";
import { LineIdContext } from "./contexts/lineIdContext";

import { CanvasBaselineContext } from "../../contexts/CanvasBaselineContext";
import { HasCopiedEventContext } from "./contexts/HasCopiedEventContext";
import { HasSiblingEventContext } from "./contexts/HasSiblingEventContext";

// import { HoveredEventIdContext } from "../../contexts/CanvasHoveredEventContext";

// import {
//     EventDragActiveContext,
//     DraggedEventTempXContext,
//     DraggedEventTempYContext,
// } from "../../contexts/CanvasEventDragContext";

const moreButtonDiameter = 35;

interface CanvasLineProps {
    lineId: string;
}

export function CanvasLine({ lineId }: CanvasLineProps) {
    const _isBaseline = useContext(CanvasBaselineContext);

    const [parentId, childId] = useMemo(() => lineId.split("_"), [lineId]);
    const focusedLineId = useContext(FocusedLineIdContext);
    const hoveredLineId = useContext(HoveredLineIdContext);
    const setHoveredLineId = useContext(SetHoveredLineIdContext);
    const stageScale = useContext(CanvasStageScaleContext);
    // const hoveredEventId = useContext(HoveredEventIdContext);

    // const eventDragActive = useContext(EventDragActiveContext);
    // const draggedEventTempX = useContext(DraggedEventTempXContext);
    // const draggedEventTempY = useContext(DraggedEventTempYContext);

    const [moreIcon] = useImage(moreButtonURL);

    const dispatch = useAppDispatch();
    /**
     * Consider making this into a useAppSelector() & useMemo() combination as it could be extremely inefficient if we loop through
     * hundreds of threads when the highlighted thread had not changed.
     */
    const isInHighlightedThread = useAppSelector((state) => {
        const highlightedThreadEvents: Event[] =
            state.scenario?.highlightedThread?.nodes ?? [];
        const highlightedThreadEventIds: string[] =
            highlightedThreadEvents?.map((event) => {
                return event?.id;
            });
        return isLineInHighlightedThread(
            parentId,
            childId,
            highlightedThreadEventIds
        );
    });

    const { hideInCanvas, hasSiblingEvent, hasCopiedEvent } = useAppSelector(
        (state) => {
            const manager = state?.scenario?.manager;
            const childId = lineId.split("_")[1];
            const childEvent = manager?._findEvent(childId);
            const parentId = lineId.split("_")[0];
            const parentEvent = manager?._findEvent(parentId);
            const hideInCanvas = !!(
                parentEvent?.hideInCanvas || childEvent?.hideInCanvas
            );
            const hasSiblingEvent = !!(childEvent?.parents?.length > 1);
            const hasCopiedEvent = !!state?.scenario?.copiedEvent;
            return {
                hideInCanvas,
                hasSiblingEvent,
                hasCopiedEvent,
            };
        },
        shallowEqual
    );

    const parentData = useEventCanvasData(parentId);
    const childData = useEventCanvasData(childId);

    const isActive = false; // TODO: This should be dynamic

    const handleClickLine = () => {
        dispatch(focusLine(lineId, _isBaseline));
    };

    const handleOnMouseEnter = (e) => {
        const container = e.target.getStage()?.container();

        if (container) {
            container.style.cursor = "pointer";
        }
        if (focusedLineId !== lineId) {
            setHoveredLineId(lineId);
        }
    };

    const handleOnMouseLeave = (e) => {
        const container = e.target.getStage()?.container();
        setHoveredLineId(null);
        if (container) {
            container.style.cursor = "default";
        } // TODO: Instead of 'default', determine what the state was before entering.
    };

    const strokeColor = useMemo(() => {
        let _strokeColor = "rgba(150,150,150,0.75)";
        if (isActive) {
            _strokeColor = "#f8b46a";
        } else if (!childData?.active || !parentData?.active) {
            _strokeColor = "rgba(200,200,200,0.5)";
        } else if (hoveredLineId === lineId) {
            _strokeColor = "#F7AB5F";
        } else if (focusedLineId === lineId) {
            _strokeColor = "#F7AB5F";
        } else if (isInHighlightedThread) {
            _strokeColor = "#93C0FF";
        }
        return _strokeColor;
    }, [
        childData?.active,
        isInHighlightedThread,
        isActive,
        parentData?.active,
        lineId,
        focusedLineId,
        hoveredLineId,
    ]);

    const { x: x1, y: y1 } = parentData;
    const { x: x2, y: y2 } = childData;

    const [{ parentX, parentY }, setParentXY] = useState({
        parentX: x1,
        parentY: y1,
    });
    const [{ childX, childY }, setChildXY] = useState({
        childX: x2,
        childY: y2,
    });

    // useEffect(() => {
    //     if (eventDragActive) {
    //         if (parentId === hoveredEventId) {
    //             setParentXY({
    //                 parentX: draggedEventTempX,
    //                 parentY: draggedEventTempY,
    //             });
    //         } else if (childId === hoveredEventId) {
    //             setChildXY({
    //                 childX: draggedEventTempX,
    //                 childY: draggedEventTempY,
    //             });
    //         }
    //     }
    // }, [
    //     childId,
    //     draggedEventTempX,
    //     draggedEventTempY,
    //     eventDragActive,
    //     hoveredEventId,
    //     parentId,
    // ]);

    useEffect(() => {
        setParentXY({ parentX: x1, parentY: y1 });
        setChildXY({ childX: x2, childY: y2 });
    }, [x1, x2, y1, y2]);

    const pointsArrayTest = useMemo(() => {
        const pointsArray = [parentX, parentY, childX, childY];
        return pointsArray;
    }, [childX, childY, parentX, parentY]);

    if (hideInCanvas) return null;

    return (
        <Group>
            <Line
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onClick={handleClickLine}
                points={pointsArrayTest}
                strokeWidth={5} // TODO: Make strokeWidth dynamic
                stroke={strokeColor}
            />
            <Portal selector=".top-layer" enabled={focusedLineId === lineId}>
                <Group
                    x={(x2 + x1) / 2}
                    y={(y2 + y1) / 2}
                    scaleX={1 / stageScale}
                    scaleY={1 / stageScale}
                >
                    <Image
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                        onClick={handleClickLine}
                        image={moreIcon}
                        width={moreButtonDiameter}
                        height={moreButtonDiameter}
                        x={-moreButtonDiameter / 2}
                        y={-moreButtonDiameter / 2}
                        visible={
                            focusedLineId === lineId || hoveredLineId === lineId
                        }
                    />
                    <HasSiblingEventContext.Provider value={hasSiblingEvent}>
                        <HasCopiedEventContext.Provider value={hasCopiedEvent}>
                            <LineIdContext.Provider value={lineId}>
                                <InsertMenuIdContext.Provider value="insert-menu-template-1">
                                    <FocusedLineContext.Provider
                                        value={focusedLineId === lineId}
                                    >
                                        <InsertMenu />
                                    </FocusedLineContext.Provider>
                                </InsertMenuIdContext.Provider>
                            </LineIdContext.Provider>
                        </HasCopiedEventContext.Provider>
                    </HasSiblingEventContext.Provider>
                </Group>
            </Portal>
        </Group>
    );
}

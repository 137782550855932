import { useState, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import GoalInputView from "./GoalInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import { goalObject } from "Components/Registry/Goal";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { goalInputsHandler } from "../OnInputChangeHandlers/goalInputsHandler";
import { updateEntityState } from "helpers/updateEntityState";

export default function GoalInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector(
        (state) => state?.entities,
        shallowEqual
    );

    const allLedgerData = useAppSelector((state) => state?.allAccountLedgers?.ledgersMetadata)

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(goalObject);
            _eventData.name = getDefaultName(goalObject.name(), propsObject);
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.


    const selectedAccountData = useMemo(() => {
        const accountId = entitiesMap[currentEntity]?.data?.accountId
        if (accountId) {
            return allLedgerData?.[accountId] ?? {}
        } else {
            return {}
        }
    }, [allLedgerData, currentEntity, entitiesMap])

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { manager, line, focus };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "monthlyCumulative" | "ledgerName" | "entityName" | "value",
        star?: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = goalInputsHandler(
            value,
            id,
            // @ts-ignore Ignore for now. Ideally, `star` should just be `value` so every input follows the same structure.
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };


    const updateAccount = (accountType, id) => {
        const value = accountType;
        const star = 0;
        const newEntitiesMap = goalInputsHandler(
            "",
            id,
            star,
            entitiesMap,
            currentEntity,
            value,
            
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = goalInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    }

    const handleDisplaySwitches = (id) => {
        const star = 0;
        const newEntitiesMap = goalInputsHandler(
            "",
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    }

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = !!eventData.name && goalObject.checkInput(entitiesMap);

    return (
        <GoalInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            eventData={eventData}
            handleOnChange={handleOnChange}
            updateAccount={updateAccount}
            handleDateSelection={handleDateSelection}
            handleDisplaySwitches={handleDisplaySwitches}
            selectedAccountData={selectedAccountData}
            handleEntityStateChange={handleEntityStateChange}
        />
    );
}

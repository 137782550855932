import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

export const averageOrderInputsHandler = (
    value: string,
    id: "entityName" | "cadence",
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
};

import { BaselinePrefix } from "./constants";
import { getNameFromType } from "./getNameFromType";
// import _ from "lodash";
import { getViableChildrenToSearch } from "./index";
import { throwError } from "./swalError";

// Returns an object of node type mapped to an array of ids for possible dependencies
//   of that type. Functions in baseline. And searches baseline from scenario.
// - props must have a baselineManager or a manager, and a focus or line.parentEvent
//      properties or the optional target id field
// - dependencyTypes is an array of node types (strings)
// - dependentId (optional), is the node id of node you want to collect dependencies for
// - idOnly (optional) by default true; if false returns the entire nodes
// todo: refactor to have configuration options obj instead of additional params
//  it will facilitate extending modular functionality
export const getDependencyCandidates = (
    props,
    dependencyTypes,
    _dependentId = null,
    idOnly = true
) => {
    //debugger;
    const targetId = props.eventId; //getTargetId(dependentId, props); //
    if (!targetId) return {};

    let results;
    if (props.baseline && props.baselineManager) {
        results = handleBaselineDependencyDetection(
            targetId,
            dependencyTypes,
            props
        );
    } else {
        results = handleScenarioDependencyDetection(
            targetId,
            props.manager,
            dependencyTypes
        );
    }
    if (!results) return {};
    Object.keys(results).forEach((category) => {
        results[category] = results[category].filter((event) => {
            return event.isFilled && event.valid;
        });
    });
    if (idOnly) {
        return extractIdsFromDependencies(results);
    } else {
        return results;
    }
};

// Aimed for nodes that have automatic dependency detection based on upstream proximity to
//  target node. If there are dependencies and the target is in it, it first returns dependencies
//  from highlighted thread. Else it checks baseline, and if none are found, any of the threads
//  feeding into mortgage for dependencies.
// Return type: dictionary of node type mapped to a single node
// Params:
//  - props must have a baselineManager or a manager, and a focus or line.parentEvent
//       properties or the optional target id field
// - dependencyTypes is an array of node types (strings)
// - dependentId (optional), is the node id of node you want to collect dependencies for
//  - config: (object containing configuration properties)
//      + requiredDependencyTypes:
//          - array of node types; once all are collected in the traversal accumulator, traversal ends
//          - defaults to dependencyTypes input array
//      Optional functions: (overwrites default node processing handlers executed during traversals)
//          - default functionality is to check for and store node under its type if it's found in dependencyTypes
//          - for both of these functions, the dependencies param
//          refers to a local copy of the results object passed between nodes during the traversal; make sure the node
//              is indexed according to its type
//          - target is the node we want to get dependencies for (start of traversal for singleThreadHandler and end of travsersal for
//              scenarioHandler
//      + scenarioHandler(node, dependencies, targetNode = null):
//          - called during scenario traversal starting from me node through all threads ending at target node
//      + singleThreadHandler(node, dependencies):
//          - called in highlighted thread and baseline. Traversals moves from end of thread to me node (opposite of scenario traversal).
//
//
// todo: replace helper functions in the config
export const getClosestDependency = (
    props,
    dependencyTypes,
    dependentId = null,
    config = {
        requiredDependencyTypes: null,
        singleThreadHandler: null,
        scenarioHandler: null,
    }
) => {
    setUpConfig(dependencyTypes, config);
    const targetId = getTargetId(dependentId, props);
    if (!targetId) return {};
    let results;
    if (props.baseline && props.baselineManager) {
        results = handleBaselineAutoDependencyDetectionFromBaseline(
            targetId,
            props,
            config
        );
    } else {
        results = handleScenarioAutoDependencyDetection(
            targetId,
            props,
            config
        );
    }
    return results;
};
/**
Modifies the dependencies input object to map node names to node ids for all
possible upstream nodes of a certain type.
- props must have a baselineManager or a manager, and a focus or line.parentEvent
     properties or the optional target id field

Returns true if at least 1 dependency candidate is found.
Very useful for using the dependencies object's keys for dropdowns.
todo: lots of room for extending/ generalizing functionality
 * @param {*} dependencies
 * @param {*} type
 * @param {*} props
 * @returns
 */
export const getPresentableDependencies = (
    dependencies,
    type,
    props,
    omitError = false
) => {
    if (!dependencies) return false;
    let isValid;
    const results = getDependencyCandidates(props, [type], null, false);
    if (results) {
        const dependenciesFound = results[type];
        if (dependenciesFound && dependenciesFound.length > 0) {
            isValid = true;
            extractDisplayableNames(dependenciesFound, dependencies);
        }
    }
    if (!isValid && !omitError) {
        throwError(
            "warning",
            `No ${getNameFromType(type)} nodes found upstream or in baseline`,
            "You can still add this Event to this Scenario as a placeholder for future calculations"
        );
    }
    return isValid;
};
/**
 *
 * functions like {@link getPresentableDependencies} but takes multiple types
 * - types:
= statedType: string that summarizes the entered types. Used in error logging.
 * @param {*} dependencies - Object
 * @param {*} types - array of type
 * @param {*} props
 * @param {*} statedType
 * @returns
 */
export const getPresentableDependenciesOfManyTypes = (
    dependencies,
    types,
    props,
    statedType,
    omitError = false
) => {
    if (!dependencies) return false;
    let isValid;
    const results = getDependencyCandidates(props, types, null, false);
    if (results) {
        let dependenciesFound = [];
        types.forEach((type) => {
            if (results[type])
                dependenciesFound = dependenciesFound.concat(results[type]);
        });
        if (dependenciesFound && dependenciesFound.length > 0) {
            isValid = true;
            extractDisplayableNames(dependenciesFound, dependencies);
        }
    }
    if (!isValid && !omitError) {
        throwError(
            "warning",
            `No ${statedType} nodes found upstream or in baseline`,
            "You can still add this Event to this Scenario as a placeholder for future calculations"
        );
    }
    return isValid;
};

// Takes an array of nodes, and returns an object mapping node name to
//  node id. Adds a "- baseline" tag if node is in baseline, and dependencies were
//  received in the scenario
// note: Useful in conjunction with getDependencyIds to fetch an array of nodeIds
//      mapped to node type
// note: Nodes of "Me" type map to "me" instead of thier id to reconcile difference in
//      id between Baseline and Scenario
// todo: extend to return reference to node instead of id based on a config option
export const extractDisplayableNames = (nodes, results) => {
    if (!results) results = {};
    for (const node of nodes) {
        const id = node.type === "Me" ? "me" : node.id;
        let name = node.name;
        if (id.startsWith(BaselinePrefix)) {
            name = name + " - Baseline";
        }

        let i = 1;
        const nameCopy = name;
        while (results[name]) {
            // while name already exists add a number to rhs
            name = `${nameCopy} (${i})`;
            i++;
        }
        results[name] = id;
    }
    return results;
};

const getTargetId = (targetId, props) => {
    let parentId = targetId;
    if (!parentId && props.focus) {
        parentId = props.focus.id;
    }
    if (!parentId && props.line) {
        parentId = props.line.parentEvent ? props.line.parentEvent.id : null;
    }
    return parentId;
};

//---------------- helpers for getClosestDependency ---------------------------

// Checks if all dependency types have been collected in curDependencies based
// on config's requiredDependencyTypes object
const requiredDependenciesCollected = (curDependencies, config) => {
    if (!curDependencies) return false;
    const requiredDependencyTypes = config.requiredDependencyTypes;
    if (!requiredDependencyTypes) return true;
    for (const type of requiredDependencyTypes) {
        if (!curDependencies[type]) return false;
    }
    return true;
};

// Uses closures to setup default handlers; alternative is to pass
// dependencyTypes as a param to the handlers
const setUpConfig = (dependencyTypes, config) => {
    if (!config) config = {};
    if (!config.requiredDependencyTypes) {
        config.requiredDependencyTypes = [...dependencyTypes];
    }
    let dependencyTypesSet;
    if (!config.scenarioHandler) {
        dependencyTypesSet = new Set(dependencyTypes);
        config.scenarioHandler = (node, dependencies) => {
            if (!node) return;
            if (dependencyTypesSet.has(node.type)) {
                dependencies[node.type] = node;
            }
        };
    }
    if (!config.singleThreadHandler) {
        if (!dependencyTypesSet) {
            dependencyTypesSet = new Set(dependencyTypes);
        }
        config.singleThreadHandler = (node, dependencies) => {
            if (!node) return;
            if (dependencyTypesSet.has(node.type)) {
                dependencies[node.type] = node;
            }
        };
    }
};

// Checks for dependencies in highlighted thread first, then scenario, then baseline
const handleScenarioAutoDependencyDetection = (targetId, props, config) => {
    let dependencies = {};
    getAutoDependenciesFromHighlightedThread(
        props,
        targetId,
        dependencies,
        config
    );
    if (!requiredDependenciesCollected(dependencies, config)) {
        getBaselineAutoDependenciesFromScenario(props, dependencies, config);

        const startNode = props.manager.getRootNode();
        const target = props.manager._findEvent(targetId);
        if (!target) return dependencies;
        dependencies = recurseToTargetForAutoDependencyDetection(
            startNode,
            target,
            dependencies,
            config
        );
    }
    //  todo: force highlighting dependency thread if a dependency was found there??

    return dependencies;
};

// Picks up on last dependencies seen performing a dfs from node to target
const recurseToTargetForAutoDependencyDetection = (
    node,
    target,
    dependencies,
    config
) => {
    if (!node) return null; // reached a dead-end

    const localLastNodes = Object.assign({}, dependencies);

    if (!node.isBypassed()) {
        config.scenarioHandler(node, localLastNodes, target);
    }

    if (node === target) {
        return localLastNodes;
    }

    let nodesToSearch = getViableChildrenToSearch(node, target);

    for (let child of nodesToSearch) {
        const res = recurseToTargetForAutoDependencyDetection(
            child,
            target,
            localLastNodes,
            config
        );
        if (res && requiredDependenciesCollected(res, config)) {
            return res;
        }
    }
};

// refactor to check for parent and then check for upstream dependencies baseline style
const getAutoDependenciesFromHighlightedThread = (
    props,
    targetId,
    dependencies,
    config
) => {
    const highlightedThread = props.highlightedThread;
    if (!highlightedThread) return null;
    const nodes = highlightedThread.nodes;
    travelToTargetAndPerformReverseLinearTraversal(
        nodes,
        targetId,
        dependencies,
        config
    );
};

// note: nodes must be an array
const travelToTargetAndPerformReverseLinearTraversal = (
    nodes,
    targetId,
    dependencies,
    config
) => {
    // check to see if current node is highlighted thread
    let curNode;
    let i;
    for (i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i].id === targetId) {
            curNode = nodes[i];
            break;
        }
    }
    if (!curNode) return null;

    reverseLinearTraversal(nodes, dependencies, config, i);
};

// note different handling than when traversing baseline from the scenario: when in baseline,
//  we want to find the position of the focused node/ current dependent and
const handleBaselineAutoDependencyDetectionFromBaseline = (
    targetId,
    props,
    config
) => {
    if (!props.baselineManager) return null;
    let dependencies = {};
    travelToTargetAndPerformReverseLinearTraversal(
        Object.values(props.baselineManager.nodes),
        targetId,
        dependencies,
        config
    );
    return dependencies;
};

const getBaselineAutoDependenciesFromScenario = (
    props,
    dependencies,
    config
) => {
    if (!props.manager) return;
    reverseLinearTraversal(props.manager.baseline, dependencies, config);
};

// Performs an upstream, linear traversal starting from curNode
//  - curIndex is optional, but is assumed to be at location of target node if included
const reverseLinearTraversal = (
    threadNodes,
    dependencies,
    config,
    curIndex
) => {
    let index;
    if (!curIndex) {
        index = threadNodes.length - 1;
    } else {
        index = curIndex;
    }
    let curNode = threadNodes[index];
    while (!requiredDependenciesCollected(dependencies, config) && index >= 0) {
        if (!curNode.isBypassed()) {
            config.singleThreadHandler(curNode, dependencies);
        }
        index--;
        curNode = threadNodes[index];
    }
};

//---------------- helpers for getDependencyCandidates -------------------------

const handleBaselineDependencyDetection = (parentId, types, props) => {
    if (!props.baselineManager) return null;
    return props.baselineManager.collectTypesFromAllNodes(types, true);
};

// use with a regular manager; not baselineManager
const handleScenarioDependencyDetection = (parentId, manager, types) => {
    if (!manager) return null;
    const targetNode = manager._findEvent(parentId);
    let baselineResults = manager.getBaselineNodesByTypes(types);
    const results = manager.collectUpstreamNodesByTypes(targetNode, types);

    return mergeBaselineAndScenarioResults(baselineResults, results);
};

const mergeBaselineAndScenarioResults = (baselineResults, scenarioResults) => {
    const merged = {};
    for (const type in scenarioResults) {
        if (baselineResults[type]) {
            merged[type] = [...scenarioResults[type], ...baselineResults[type]];
        } else {
            merged[type] = scenarioResults[type];
        }
    }
    for (const type in baselineResults) {
        if (!merged[type]) {
            merged[type] = baselineResults[type];
        }
    }
    return merged;
};

const extractIdsFromDependencies = (results) => {
    const nodeTypes = {};
    for (let type in results) {
        for (let node of results[type]) {
            if (!nodeTypes[type]) {
                nodeTypes[type] = [];
            }
            nodeTypes[type].push(node.id);
        }
    }

    return nodeTypes;
};

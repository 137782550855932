import React, { Component } from "react";
import "./index.css";
import axios from "axios";
import { config } from "../../config";
import * as Sentry from "@sentry/browser";

class ChangeAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            token: null,
            account: null,
            accountOnHover: null,
        };
    }
    requestHeader = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const requestHeader = {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${loggedInUser.token}`,
                account: loggedInUser.account,
                accept: "*/*",
                email: loggedInUser.email,
            },
        };

        return requestHeader;
    };

    componentDidMount() {
        const loggedInUserValues = localStorage.getItem("loggedInUser");
        const values = JSON.parse(loggedInUserValues);
        this.setState({ token: values.token });

        axios
            .get(`${config.host}/user/getAllDetails`, this.requestHeader())
            .then((response) => {
                if (response) {
                    this.setState({ data: response.data });
                }
            })
            .catch((error) => {
                console.log("failed to save scenario details", error);
                Sentry.withScope((_scope) => {
                    Sentry.captureException(error);
                });
            });
    }

    onHandleUser = (user) => {
        const loggedInUser = {
            token: this.state.token,
            account: user.account,
            id: user.id,
            name: user.name,
            email: user.email,
        };

        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));

        this.props.props.history.push("/");
    };

    onHandleHover = (index) => {
        this.setState({ accountOnHover: index });
    };

    onMouseLeave = () => {
        this.setState({ accountOnHover: null });
    };

    render() {
        return (
            <div className="adminContainer">
                <div className="adminBox">
                    {/*                     
                    <select onChange={this.onHandleUser}>
                        <option>select option</option> */}
                    <div className="adminAccountContainer">
                        {this.state.data &&
                            this.state.data.map((user, i) => {
                                return (
                                    <div
                                        className={
                                            this.state.accountOnHover === i
                                                ? "adminAccountActive"
                                                : "adminAccount"
                                        }
                                        value={i}
                                        id={i}
                                        key={i}
                                        onClick={() => this.onHandleUser(user)}
                                        onMouseEnter={() =>
                                            this.onHandleHover(i)
                                        }
                                        onMouseLeave={this.onMouseLeave}
                                    >
                                        <div>
                                            <span className="highlighted">
                                                Email:
                                            </span>{" "}
                                            {user.email}
                                        </div>
                                        <div>
                                            <span className="highlighted">
                                                Name:
                                            </span>{" "}
                                            {user.name}
                                        </div>
                                        <div>
                                            <span className="highlighted">
                                                PlanId:
                                            </span>{" "}
                                            {user.data && user.data.planId
                                                ? user.data.planId
                                                : "No PLAN (OLD ACCOUNT)"}
                                        </div>
                                        <div className="tagsContainer">
                                            <span className="highlighted">
                                                Tags:
                                            </span>{" "}
                                            {user.data &&
                                            user.data.selectedTags ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    {user.data.selectedTags.map(
                                                        (tag, i) => {
                                                            return (
                                                                <div key={i}>
                                                                    {tag},{" "}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            ) : (
                                                "No tags (OLD ACCOUNT)"
                                            )}
                                        </div>
                                        <div>
                                            <span className="highlighted">
                                                whatifi:
                                            </span>{" "}
                                            {user.data &&
                                                user.data.whatifiInput}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    {/* </select> */}
                </div>
            </div>
        );
    }
}

export default ChangeAccount;

import type { LedgerMetadataUnit } from "reducers/typesSchema/allAccountLedgersSchema";
import type { CalculatedThread } from "reducers/typesSchema/calculatedThreadsSchema";
import type { ChartGraphPointsData } from "./buildPointsData";
import { color, Tooltip } from "@amcharts/amcharts4/core";
import { CircleBullet, LineSeries } from "@amcharts/amcharts4/charts";
import { getDisplayPattern } from "Components/ScenarioView/helpers/getDisplayValue";

function createBulletPoints() {
    const bullet = new CircleBullet();
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = color("#fff");
    bullet.circle.stroke = color("#000");

    // Make bullets grow on hover
    const bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;

    return bullet;
}

/**
 * Creates a series of points for the Chart Graph. Corresponds to one thread's data.
 */
export function createSeries({
    pointsData,
    calculatedThread,
    activeThreadId,
    ledgerMetadataUnit,
    tooltipFontSize,
    dataItem,
    ledgerName,
}: {
    pointsData: ChartGraphPointsData[];
    calculatedThread: CalculatedThread;
    activeThreadId: string;
    ledgerMetadataUnit: LedgerMetadataUnit;
    tooltipFontSize: string;
    dataItem?;
    ledgerName: string;
}) {
    const series = new LineSeries();
    series.id = calculatedThread.id;
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";

    // Set text that appears above the line on hover. We will set it as the name of the thread.
    const threadName =
        calculatedThread.id === activeThreadId
            ? "Active Thread"
            : calculatedThread.option ||
              calculatedThread.name ||
              "Unnamed Thread";
    // eslint-disable-next-line no-template-curly-in-string
    const unitText = getDisplayPattern(ledgerMetadataUnit);
    const toolTipText = `${
        dataItem?.name
            ? `${threadName} - ${ledgerName} - ${dataItem?.name}: ${unitText}`
            : `${threadName} - ${ledgerName}: ${unitText}`
    }`;
    series.tooltipText = toolTipText;
    series.tooltip = new Tooltip();
    series.tooltip.fontSize = tooltipFontSize;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = color(calculatedThread.color);

    series.stroke = color(calculatedThread.color);
    series.strokeWidth = 3;
    series.minBulletDistance = 15;

    // NOTE: uncomment these to use custom colors specified in each object in the array
    series.propertyFields.stroke = `color${calculatedThread.color}`;
    series.propertyFields.fill = `color${calculatedThread.color}`;

    // Opacity of the area under the curve/lines. Value close to 1 means you will see a color underneath the area.
    const fillOpacity = activeThreadId === calculatedThread.id ? 0.075 : 0.025;
    series.fillOpacity = fillOpacity;

    series.data = pointsData;

    const bullet = createBulletPoints(); // Should this be dynamic?
    series.bullets.push(bullet);

    return series;
}

import { getRelevantEntities } from "actions/getNodeEntityActions";
import axios from "axios";
import { config } from "config";
import { isEmpty } from "lodash";
import { ModifierSchema } from "reducers/typesSchema/modifiersSchema";
import store from "store";
import { submitModifier } from "./modifiers";

// NOTE: The Expression Event currently doesn't not work unless the expression entity cadence is the same as the cadence of the entity it's pointing to
// Also if whoever's reading this has time to fix the Expression Event you might as well also fix the bug where the selected event is lost (in the UI) if an event in the thread is bypassed

export const evaluateExpression = async (
    expression: string,
    entity: any,
    modifiers?: any[]
): Promise<[number | undefined, {} | undefined]> => {
    if (!expression) return [undefined, undefined];
    const associatedEntityData = Object.values(
        getRelevantEntities([entity?.data?.selectedEntity])
    )[0];
    let newModifier: ModifierSchema | undefined = undefined;
    const response = await Promise.resolve(
        calculateExpression(
            expression,
            getScope(associatedEntityData, modifiers)
        )
    );
    const overrideValueMap = response.overrideValueMap;
    const value: number = response.result;
    if (Object.keys(overrideValueMap).length !== 0) {
        Object.keys(overrideValueMap).forEach((key) => {
            overrideValueMap[key] = overrideValueMap[key].toString();
        });
        newModifier = submitModifier(
            overrideValueMap,
            value.toString(),
            entity.cadence,
            entity.id,
            "value"
        );
    }
    return [value, newModifier];
};

export const getEntityFields = (associatedEntityData: any) => {
    const entityFields = Object.keys(associatedEntityData);
    const numericalFields = entityFields
        .filter((field) => {
            return (
                !isNaN(+associatedEntityData[field]) &&
                associatedEntityData[field] !== "" &&
                associatedEntityData[field] != null &&
                +associatedEntityData[field] !== 0
            );
        })
        .map((field) => {
            return { label: field };
        });
    return numericalFields;
};

const getScope = (associatedEntity: any, modifiers?: any[]) => {
    const scope = {};
    const defaultScope = {};
    if (!associatedEntity) return scope;

    getEntityFields(associatedEntity.data).forEach((fieldObj) => {
        const field = fieldObj.label;
        defaultScope[field] = +associatedEntity.data[field];
    });

    scope["default"] = defaultScope;

    const override = store
        .getState()
        .modifiers[associatedEntity.id]?.find((override) => {
            return override.field === "value";
        });

    let allDates;

    if (modifiers && modifiers.length > 0) {
        const valuesMap = modifiers[0].values;

        allDates = Object.keys(valuesMap);
        // add all the dates
        for (const key of allDates) {
            scope[key] = { ...defaultScope };
        }
    }

    if (override) {
        const valuesMap = override.values;
        Object.keys(valuesMap).forEach((key) => {
            scope[key] = { ...defaultScope, value: valuesMap[key] };
        });
    }

    if (modifiers && modifiers.length > 0) {
        for (const modifier of modifiers) {
            let prevVal = null;
            let prevDate = null;
            // we need to keep track of the value after overrides and modifiers but BEFORE the compounding if the function is compounding since otherwise the formula for calculating compounding with overrides doesn't work. For example, if there is an override and a replacement modifier and then a compounding modifier, it behaves as if the override doesn't exist so we need to keep track of the result after the replacement modifier and treat those as currOverride and prevOverride.
            let prevPreCompoundingVal: any = null;
            for (const key of allDates) {
                const value = scope[key].value;
                const func = modifier.functions[key];
                const val = modifier.values[key].value;
                const newVal = handleModifyValue(
                    Number(value),
                    func,
                    Number(val),
                    prevVal,
                    Number(defaultScope["value"]),
                    override,
                    key,
                    prevDate,
                    prevPreCompoundingVal
                );

                scope[key].value = newVal;

                prevVal = newVal;
                prevDate = key;
                if (func == "compounding") {
                    prevPreCompoundingVal = Number(value);
                }
            }
        }
    }

    return scope;
};

const handleModifyValue = (
    value,
    modifierFunc,
    modifierVal,
    prevVal,
    defaultVal,
    override,
    dateKey,
    prevDateKey,
    prevPreCompoundingVal
) => {
    switch (modifierFunc) {
        case "add":
            return value + modifierVal;
        case "replace":
            return modifierVal;
        case "percentChange":
            return value * (1 + modifierVal / 100);
        case "compounding":
            if (prevVal == null) {
                return value;
            }
            if (override && !isEmpty(override.values)) {
                const valuesMap = override.values;
                const currOverride = valuesMap[dateKey] || defaultVal;
                // if this ternary is true then the override doesn't matter since it's been modified so we use the modified value
                const currValue = value == currOverride ? currOverride : value;
                const prevOverride = valuesMap[prevDateKey] || defaultVal;
                const prevValue =
                    prevPreCompoundingVal == prevOverride
                        ? prevOverride
                        : prevPreCompoundingVal;
                return (
                    (prevVal + (currValue - prevValue)) *
                    (1 + modifierVal / 100)
                );
            } else {
                return prevVal * (1 + modifierVal / 100);
            }
    }

    return value;
};

export const calculateExpression = (expression: string, variableMap: any) => {
    const requestHeaderDE3 = () => {
        const loggedInUserString = localStorage.getItem("loggedInUser");
        const loggedInUser =
            loggedInUserString !== null ? JSON.parse(loggedInUserString) : {};
        const requestHeader = {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${
                    loggedInUser.token || "WHATIFI-GUEST"
                }`,
                accept: "*/*",
            },
        };

        return requestHeader;
    };

    return axios
        .post(
            `${config.decisionEngine}`,
            JSON.stringify({
                expression,
                variableMap,
            }),
            {
                ...requestHeaderDE3(),
            }
        )
        .then((resp) => {
            return resp.data;
        });
};

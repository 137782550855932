import { useContext } from "react";
import { Group } from "react-konva";
import useImage from "use-image";
import { useAppDispatch } from "store/useAppSelectorDispatch";
import { animated, useSpring } from "@react-spring/konva";
import {
    HoveredButtonIdContext,
    SetHoveredButtonIdContext,
} from "../../contexts/hoveredButtonIdContext";
import { LineIdContext } from "../../../../contexts/lineIdContext";
import { lineActionsMap } from "actions/canvasLineActions";
import { useInsertIconStyles } from "./useInsertIconStyles";
import { useInsertIconBackground } from "./useInsertIconBackground";
import { useInsertIconImage } from "./useInsertIconImage";
import { CanvasBaselineContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasBaselineContext";

export function InsertMenuIcon({ buttonId, horizontalCenter }) {
    const isBaseline = useContext(CanvasBaselineContext);
    const lineId = useContext(LineIdContext);
    const hovered = useContext(HoveredButtonIdContext);
    const setHovered = useContext(SetHoveredButtonIdContext);

    const actionImagePath = useInsertIconImage(buttonId);

    const dispatch = useAppDispatch();

    const [actionIcon] = useImage(
        `${window.location.origin}/${actionImagePath}`
    );

    const handleButtonClick = () => {
        dispatch(lineActionsMap?.[buttonId](lineId, isBaseline));
    };

    const handleOnMouseEnter = (e) => {
        const container = e.target.getStage()?.container();

        if (container) {
            container.style.cursor = "pointer";
        }
        if (hovered !== buttonId) {
            setHovered(buttonId);
        }
    };

    const handleOnMouseLeave = (e) => {
        const container = e.target.getStage()?.container();
        setHovered("");
        if (container) {
            container.style.cursor = "default";
        } // TODO: Instead of 'default', determine what the state was before entering.
    };

    const { iconStyles, iconAnimationConfig } = useInsertIconStyles(buttonId);

    const animatedIconStyles = useSpring({
        to: iconStyles,
        config: iconAnimationConfig,
    });

    const { iconBackgroundStylesFull, iconBackgroundAnimationConfigFull } =
        useInsertIconBackground(buttonId);

    const animatedBackgroundStyles = useSpring({
        to: iconBackgroundStylesFull,
        config: iconBackgroundAnimationConfigFull,
    });

    const halfBackgroundWidth = iconBackgroundStylesFull?.width / 2;

    const iconOffest =
        (iconBackgroundStylesFull?.width - iconStyles?.width) / 2;

    return (
        <Group
            x={horizontalCenter - halfBackgroundWidth}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleButtonClick}
        >
            {/* @ts-ignore */}
            <animated.Rect {...animatedBackgroundStyles} />
            <animated.Image
                image={actionIcon}
                x={iconOffest ?? 0}
                y={iconOffest ?? 0}
                {...animatedIconStyles}
            />
        </Group>
    );
}

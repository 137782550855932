import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import ImportInputView from "./ImportInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent } from "react";
import { createNewEvent, extractEventIds } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { importInputsHandler } from "../OnInputChangeHandlers/importInputsHandler";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { camelCase, cloneDeep, isEmpty } from "lodash";
import * as uuid from "uuid";
import { incomeObject } from "Components/Registry/Income";
import { importObject } from "Components/Registry/Import";
import { expenseObject } from "Components/Registry/Expense";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { updateEntityState } from "helpers/updateEntityState";
import {
    getAdjustedAccountMappingData,
    regroupedMapping,
} from "helpers/accountMapping";
import {
    AccountMappingDataMapped,
    AccountMappingType,
    AccountRowMapped,
} from "reducers/typesSchema/accountMappingsSchema";
import { inCurrentCsv } from "helpers/csvImport";
import { getLedger, getTopLevelAccount } from "helpers/accounts";
import { addNewEvent, bypassEvents, updateEvent } from "actions/eventHelpers";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { EventManager } from "Events";
import { getTodayDateString } from "../OnInputChangeHandlers/initialBalanceInputsHandler";
import { debitCreditObject } from "Components/Registry/Debit Credit";

type CsvAccountData = {
    entityId: string;
    eventId: string;
};

export type CsvImportData = {
    csvMap: { [id: string]: { entityId: string; eventId: string } };
    eventIds: string[];
    mappingType: AccountMappingType;
    lastUpdated: string;
    importType: string;
};

export default function ImportInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = useMemo(
        () =>
            getDefaultName(
                importObject.name(),
                propsObject,
                propsObject.manager
            ),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(importObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(entitiesObject, eventData, edit);

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "importType"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = importInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const startEventCreation = (accountMappingData) => {
        const adjustedMappingData =
            getAdjustedAccountMappingData(accountMappingData);
        importCsv(adjustedMappingData);
    };

    // Handles creation/update of events and entities using csvData
    const getCsvData = (
        eventAccountId: string,
        rows: AccountRowMapped[],
        matchedEvents
    ) => {
        const currentCsvMap = entitiesMap[currentEntity].data.csvMap;

        let eventObject;
        let eventType: string;

        const topLevelAccount = getTopLevelAccount(eventAccountId);

        if (topLevelAccount == "488dd61d-8697-4213-8978-cf91755365a4") {
            eventObject = incomeObject;
            eventType = "income";
        } else if (topLevelAccount == "7faf0285-78ca-411b-b875-d900929d7c94") {
            eventObject = expenseObject;
            eventType = "expense";
        } else {
            eventObject = debitCreditObject;
            eventType = "debitCredit";
        }

        const newEvent = createNewEvent(eventObject);
        newEvent.id = uuid.v4();
        newEvent.name = getLedger(eventAccountId).name;

        let originalEvent;

        const entitiesMapNew = {};
        const entityIdsNew: string[] = [];
        const entities: any[] = [];
        const csvMap = {};
        let editEvent = false;

        rows.forEach((accountRow) => {
            const newEntity = cloneDeep(
                EntityStructureData?.[eventObject.constant()]
            );

            const entityOverrides = {};
            for (const date of Object.keys(accountRow.data)) {
                // Convert date string from YYYY-MM to YYYYMM
                entityOverrides[date] = parseFloat(accountRow.data[date]);
            }

            // too hacky of a way of getting the startDate?
            const firstDate = Object.keys(accountRow.data).sort()[0];
            newEntity.startDate = firstDate.concat("-01");

            let id;

            newEntity.name = accountRow.name;

            if (accountRow.name in currentCsvMap) {
                const entityData = currentCsvMap[accountRow.name];

                if (
                    !originalEvent &&
                    !matchedEvents.includes(entityData.eventId)
                ) {
                    editEvent = true;
                    matchedEvents.push(entityData.eventId);
                    originalEvent = getEvent(entityData.eventId);

                    newEvent.children = extractEventIds(originalEvent.children);
                    newEvent.parents = extractEventIds(originalEvent.parents);

                    newEvent.description = originalEvent.description;
                    newEvent.id = originalEvent.id;
                    newEvent.baseline = originalEvent.baseline;
                    newEvent.bypassed = originalEvent.bypassed;
                    newEvent.locked = originalEvent.locked;
                    newEvent.version = originalEvent.version;
                }

                if (originalEvent && entityData.eventId == originalEvent.id) {
                    newEntity.id = entityData.entityId;
                    id = entityData.entityId;

                    const originalEntity = getRelevantEntities([
                        entityData.entityId,
                    ])[entityData.entityId];

                    newEntity.bypassState = originalEntity.bypassState;
                    newEntity.calculateState = originalEntity.calculateState;
                }
            }

            if (!id) {
                id = uuid.v4();
                newEntity.id = id;
            }

            // common attributes between income and expense
            newEntity.cadence = "monthly";
            newEntity.data.accountName = accountRow.mappedAccountData?.name;
            const accountIds = accountRow.mappedAccountData?.ids || [];
            newEntity.data.accountIds = accountIds;
            newEntity.data.value = 0;

            if (eventType == "income") {
                newEntity.data.income = "0" as typeof newEntity.data.income;
            } else if (eventType == "expense") {
                newEntity.data.cost = "0" as typeof newEntity.data.cost;
                newEntity.data.tag = `@${camelCase(accountRow.name)}`;
            } else {
                newEntity.data.amount = "0";
            }

            // Create new override
            const newOverride = {
                id: uuid.v4(),
                cadence: newEntity.cadence,
                startDate: newEntity.startDate,
                nodeType: newEntity.type,
                keyToModify: "value",
                value: entityOverrides,
                action: "override",
                entityId: newEntity.id,
                revert: false,
                cadenceType: "Recurring",
            };
            // Add new override to entity
            newEntity.data.modsCreated = [newOverride];

            csvMap[accountRow.name] = {
                entityId: newEntity.id,
                eventId: newEvent.id,
            };

            entityIdsNew.push(newEntity.id);
            entitiesMapNew[newEntity.id] = newEntity;
            entities.push({
                id: newEntity.id,
                active: true,
            });
        });

        // Copy extra entities added by the user after the initial import
        if (
            originalEvent &&
            originalEvent.entities.length > entityIdsNew.length
        ) {
            originalEvent.entities.forEach((entity) => {
                if (
                    !entityIdsNew.includes(entity.id) &&
                    !inCurrentCsv(
                        entity,
                        entitiesMap[currentEntity].data.csvMap
                    )
                ) {
                    entityIdsNew.push(entity.id);
                    entities.push(entity);
                    const oldEntity = getRelevantEntities([entity.id])[
                        entity.id
                    ];
                    entitiesMapNew[entity.id] = oldEntity;
                }
            });
        }

        newEvent.entities = entities;

        if (originalEvent) {
            newEvent.mostRecentEntity = newEvent.entities.length - 1;
        }

        const newEventExport = {
            ...newEvent,
            isFilled: originalEvent ? originalEvent.isFilled : true,
            valid: originalEvent ? originalEvent.valid : true,
        };

        const passedCheck = eventObject.checkInput(entitiesMapNew);

        return {
            event: newEventExport,
            entitiesMap: entitiesMapNew,
            entityIds: entityIdsNew,
            csvMap: csvMap,
            passedCheck: passedCheck,
            editEvent: editEvent,
        };
    };

    const containsData = (unmappedData) => {
        for (const value of Object.values(unmappedData)) {
            if (value) {
                return true;
            }
        }

        return false;
    };

    const bypassUnmatchedEvents = (matchedEvents) => {
        const bypassedEvents: string[] = [];
        const originalEvents = entitiesMap[currentEntity].data.eventIds;
        originalEvents.forEach((eventId) => {
            if (!matchedEvents.includes(eventId)) {
                bypassedEvents.push(eventId);
            }
        });

        bypassEvents(bypassedEvents);
    };

    const setImportData = (csvMapList, eventList) => {
        const eventIds: any[] = [];
        for (const event of eventList) {
            if (event.id) {
                eventIds.push(event.id);
            }
        }

        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const csvMap = {};

        for (let i = 0; i < csvMapList.length; i++) {
            const eventCsvMap = csvMapList[i];
            for (const [name, csvData] of Object.entries(eventCsvMap)) {
                csvMap[name] = {
                    ...(csvData as CsvAccountData),
                };
            }
        }

        data.csvMap = csvMap;
        data.eventIds = eventIds;
        data.lastUpdated = getTodayDateString();
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
        onHandleSubmit(newEntitiesMap);
    };

    const submitEventData = (matchedEvents, csvMapList, eventList) => {
        bypassUnmatchedEvents(matchedEvents);
        setImportData(csvMapList, eventList);
    };

    const importCsv = async (mappingData: AccountMappingDataMapped) => {
        if (isEmpty(mappingData)) return;

        // Array of event account ids
        const eventsOrder: string[] = [];
        const eventSet: Set<string> = new Set();

        const groupedMappingData = regroupedMapping(mappingData);
        // Map from account id (for the event) to AccountRowMapped[]
        // (basically event -> entity[])
        const eventsMap = {};

        for (const accountRow of Object.values(mappingData)) {
            const eventAccountId = groupedMappingData[accountRow.name];

            if (eventAccountId in eventsMap) {
                eventsMap[eventAccountId].push(accountRow);
            } else {
                eventsMap[eventAccountId] = [accountRow];
            }

            if (!eventSet.has(eventAccountId)) {
                eventsOrder.push(eventAccountId);
                eventSet.add(eventAccountId);
            }
        }

        const eventList: EventStructure[] = [];
        const entitiesMapList: EntitiesSchema[] = [];
        const entityIdsList: string[][] = [];
        const passedCheckList: boolean[] = [];
        const toEditList: boolean[] = [];
        const csvMapList: {
            [csvRowName: string]: { entityId: string; eventId: string };
        }[] = [];

        const matchedEvents: string[] = [];

        eventsOrder.forEach((eventAccountId) => {
            const csvData = getCsvData(
                eventAccountId,
                eventsMap[eventAccountId],
                matchedEvents
            );

            if (csvData) {
                eventList.push(csvData.event);
                entitiesMapList.push(csvData.entitiesMap);
                entityIdsList.push(csvData.entityIds);
                passedCheckList.push(csvData.passedCheck);
                toEditList.push(csvData.editEvent);
                csvMapList.push(csvData.csvMap);
            }
        });

        const csvData = {
            eventList: eventList,
            entitiesMapList: entitiesMapList,
            entityIdsList: entityIdsList,
            passedCheckList: passedCheckList,
            toEditList: toEditList,
            csvMapList: csvMapList,
        };

        await createUpdateCsvEvents(csvData);

        submitEventData(matchedEvents, csvData.csvMapList, csvData.eventList);
    };

    // The purpose of this function is to provide the correct parents and children events for any new events
    // that we are creating. If the event is the first event, we always attach it to the parent import event. Otherwise
    // we find the most recent import generated event and it becomes our new events parent, and its children become our new
    // events children.
    const createAddEvent = (index: number, eventList: EventStructure[]) => {
        let parents: string[] = [];
        let children: string[] = [];
        if (index === 0) {
            const eventNode = getEvent(eventData.id, true);
            if (eventNode) {
                parents = [eventNode.id];
                children = eventNode.children;
            }
        } else {
            const eventNode = getEvent(eventList[index - 1].id, true);
            if (eventNode) {
                parents = [eventNode.id];
                children = eventNode.children;
            }
        }

        return (event: EventStructure) => {
            addNewEvent(event, parents ?? [], children, true);
        };
    };

    const createUpdateCsvEvents = async (csvDataList) => {
        const eventList = csvDataList.eventList;
        const entitiesMapList = csvDataList.entitiesMapList;
        const entityIdsList = csvDataList.entityIdsList;
        const passedCheckList = csvDataList.passedCheckList;
        const toEditList = csvDataList.toEditList;

        for (let i = 0; i < eventList.length; i++) {
            const customAddEvent = createAddEvent(i, eventList);
            const eventNode = getEvent(eventList[i].id, true);
            if (eventNode)
                eventList[i] = {
                    ...eventList[i],
                    parents: eventNode?.parents,
                    children: eventNode?.children,
                };
            // This is being awaited, even though TypeScript says otherwise
            await dispatch(
                handleSubmitNodesAndEntities(
                    customAddEvent,
                    (event: EventStructure) => updateEvent(event, true),
                    eventList[i],
                    entitiesMapList[i],
                    entityIdsList[i],
                    passedCheckList[i] || "partial",
                    toEditList[i],
                    {}
                )
            );
            // This time out is necessary b/c otherwise the eventManager seems to error out. Best guess is that
            // the getEvent() fires off before the eventManager can finish adding a new Event, so we can't retrieve the
            // most up-to-date event. Consequently, the parent and children of the event we send to be created/updated is
            // also incorrect (usually empty), so the event manager can't properly connect the events and errors out.
            await new Promise((resolve) => setTimeout(resolve, 200));
        }
    };

    const getNewEventIds = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const scenarioEventIds: string[] = manager.getSortedEventIds();
        const eventIds = data.eventIds;
        const newEventIds: any[] = [];
        eventIds.forEach((eventId) => {
            if (scenarioEventIds.includes(eventId)) {
                newEventIds.push(eventId);
            }
        });

        return newEventIds;
    };

    const getNewCsvMap = (eventIds) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const csvMap = cloneDeep(data.csvMap);

        for (const [name, entityData] of Object.entries(csvMap)) {
            if (!eventIds.includes((entityData as CsvAccountData).eventId)) {
                delete csvMap[name];
            }
        }

        return csvMap;
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            // check if created events are still there
            const newEventIds = getNewEventIds();
            const newCsvMap = getNewCsvMap(newEventIds);
            newEntitiesMap[currentEntity].data.csvMap = newCsvMap;
            newEntitiesMap[currentEntity].data.eventIds = newEventIds;
            return newEntitiesMap;
        });
        // setEntitiesMap should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap, defaultName]);

    const onHandleSubmit = (newEntitiesMap?: EntitiesSchema) => {
        let finalEventData = { ...eventData };
        const eventNode = getEvent(eventData.id, true);
        if (eventNode)
            finalEventData = {
                ...finalEventData,
                parents: eventNode?.parents,
                children: eventNode?.children,
            };
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                finalEventData,
                newEntitiesMap ?? entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = importObject.checkInput(entitiesMap);
    return (
        <ImportInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            setEntitiesMap={setEntitiesMap}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            containsData={containsData}
            startEventCreation={startEventCreation}
            dispatch={dispatch}
        />
    );
}

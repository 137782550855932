import { useState, useContext, useMemo, useCallback, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getUpstreamNodes } from "helpers/scenarioNodeDetection";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { decisionObject } from "Components/Registry/Decision";
import { modifier2Object } from "Components/Registry/Modifier2";
import { kpiObject } from "Components/Registry/KPI";
import { businessObject } from "Components/Registry/Business";
import { goalObject } from "Components/Registry/Goal";
import { nullObject } from "Components/Registry/Null";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { startObject } from "Components/Registry/Start";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { evaluateExpression } from "helpers/expressionHelper";
import {
    deleteModifiers,
    getOverrideByField,
    getOverrides,
} from "helpers/modifiers";
import useAsyncEffect from "use-async-effect";
import { expressionObject } from "Components/Registry/Expression";
import { expressionInputsHandler } from "../OnInputChangeHandlers/expressionInputsHandler";
import ExpressionInputView from "./ExpressionInputView";
import { customerObject } from "Components/Registry/Customer";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function ExpressionInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(expressionObject);
            _eventData.name = getDefaultName(
                expressionObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const [deleteOverrideFlag, setDeleteOverrideFlag] = useState({
        flag: false,
        entityId: "",
        modifierId: "",
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        onHandleDate,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, expressionObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const eventId = useContext(EventInputIDContext);

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );

    // const defaultName = useMemo(
    //     () => getDefaultName(expressionObject.name(), propsObject),
    //     [propsObject]
    // );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                setEntitiesMap(
                    expressionInputsHandler(
                        value,
                        "entityName",
                        entitiesMap,
                        currentEntity
                    )
                );
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = useCallback(
        async (
            e: ChangeEvent<HTMLInputElement> | { target: { value: string } },
            id:
                | "entityName"
                | "accountName"
                | "contraAccountName"
                | "selectEvent"
                | "selectEntity"
                | "expression"
                | "cadence"
                | "value"
        ) => {
            const eventValue = e.target.value;
            let value: number | undefined;
            if (id === "value") {
                [value] = await evaluateExpression(
                    e.target.value,
                    entitiesMap[currentEntity]
                );
            }
            const newEntitiesMap = expressionInputsHandler(
                eventValue,
                id,
                entitiesMap,
                currentEntity,
                value != null ? value : undefined
            );
            setEntitiesMap(newEntitiesMap);
        },
        [currentEntity, entitiesMap, setEntitiesMap]
    );

    const handleChangeDebitCredit = (e: ChangeEvent<HTMLInputElement>) => {
        const newType = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.debitOrCredit = newType;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const expressionResult = async (entitiesMap) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const [result, _] = await evaluateExpression(
            data.expression,
            currentEntityObject
        );
        data.value = result;

        currentEntityObject.data = { ...data };
        newEntitiesMap[currentEntity] = { ...currentEntityObject };
        setEntitiesMap(newEntitiesMap);
    };

    const firstUpdate = useRef(false);

    useAsyncEffect(async () => {
        const excludedEvents = [
            decisionObject.constant(),
            modifier2Object.constant(),
            kpiObject.constant(),
            businessObject.constant(),
            goalObject.constant(),
            nullObject.constant(),
            groupObject.constant(),
            projectObject.constant(),
            startObject.constant(),
            expressionObject.constant(),
            // excluding customer for now since I'm working on the whole customer module overhaul and will add it back later. The immediate reason for excluding it is that the override value is an object and not a value
            customerObject.constant(),
        ];

        const getEvents = () => {
            const results: { value: string; displayName: string }[] = [];
            const filteredNodes = getUpstreamNodes(
                propsObject,
                (event) => !excludedEvents.includes(event.type)
            );
            filteredNodes.forEach((eventId) => {
                const event = manager._findEvent(eventId);
                results.push({ value: eventId, displayName: event.name });
            });

            return results;
        };

        const getEntities = (selectedEvent: EventStructure) => {
            const options = Object.values(
                getRelevantEntities(selectedEvent.entities)
            ).map((entityData) => {
                return { value: entityData.id, displayName: entityData.name };
            });
            return options;
        };

        await expressionResult(entitiesMap);

        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            const currentEntityObject = {
                ...(newEntitiesMap[currentEntity] || {}),
            };
            const data = { ...(currentEntityObject?.data || {}) };

            // If event is deleted reset relevant fields
            const event = getEvent(data.selectedEvent);
            if (data?.selectedEvent && !event) {
                data.selectedEvent = "";
                data.selectedEntity = "";
                data.expression = "";
                data.value = 0;
                const overrides = getOverrides(currentEntityObject.id);
                if (overrides) {
                    const [override, _] = getOverrideByField(
                        overrides,
                        "value"
                    );
                    if (override) {
                        setDeleteOverrideFlag({
                            flag: true,
                            entityId: currentEntityObject.id,
                            modifierId: override.id,
                        });
                    }
                }
            }

            // Event exists but entity was deleted
            if (data?.selectedEvent && event) {
                const eventEntities =
                    getRelevantEntities(event?.entities) ?? {};
                if (
                    data?.selectedEntity &&
                    !eventEntities[data?.selectedEntity]
                ) {
                    data.selectedEntity = "";
                    data.expression = "";
                    data.value = 0;
                    const overrides = getOverrides(currentEntityObject.id);
                    if (overrides) {
                        const [override, _] = getOverrideByField(
                            overrides,
                            "value"
                        );
                        if (override) {
                            setDeleteOverrideFlag({
                                flag: true,
                                entityId: currentEntityObject.id,
                                modifierId: override.id,
                            });
                        }
                    }
                }
            }

            data.upstreamEvents = getEvents();
            if (event) data.eventEntities = getEntities(event);

            currentEntityObject.data = { ...data };
            newEntitiesMap[currentEntity] = { ...currentEntityObject };
            return newEntitiesMap;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectedEvent = entitiesMap[currentEntity]?.data?.selectedEvent;
    const selectedEntity = entitiesMap[currentEntity]?.data.selectedEntity;

    useAsyncEffect(async () => {
        if (!firstUpdate.current) {
            firstUpdate.current = true;
            return;
        }

        const getEntities = (eventId: string) => {
            const selectedEvent: EventStructure = manager
                ._findEvent(eventId)
                .exportData();
            const options = Object.values(
                getRelevantEntities(selectedEvent.entities)
            ).map((entityData) => {
                return { value: entityData.id, displayName: entityData.name };
            });
            return options;
        };

        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            const currentEntityObject = {
                ...(newEntitiesMap[currentEntity] || {}),
            };
            const data = { ...(currentEntityObject?.data || {}) };

            if (selectedEvent && getEvent(selectedEvent)) {
                data.eventEntities = getEntities(selectedEvent);
                const overrides = getOverrides(currentEntityObject.id);
                if (overrides) {
                    const [override, _] = getOverrideByField(
                        overrides,
                        "value"
                    );
                    if (override) {
                        setDeleteOverrideFlag({
                            flag: true,
                            entityId: currentEntityObject.id,
                            modifierId: override.id,
                        });
                    }
                }
            }

            currentEntityObject.data = { ...data };
            newEntitiesMap[currentEntity] = { ...currentEntityObject };
            return newEntitiesMap;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEvent, selectedEntity]);

    useAsyncEffect(async () => {
        if (deleteOverrideFlag.flag) {
            await deleteModifiers([
                {
                    entity_id: deleteOverrideFlag.entityId,
                    modifier_id: deleteOverrideFlag.modifierId,
                },
            ]);
            setDeleteOverrideFlag({
                flag: false,
                entityId: "",
                modifierId: "",
            });
        }
    }, [deleteOverrideFlag]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const newEntitiesMap = expressionInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            undefined,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && expressionObject.checkInput(entitiesMap);

    return (
        <ExpressionInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            updateAccount={updateAccount}
            onHandleDate={onHandleDate}
            entityIndex={entityIndex}
            handleChangeDebitCredit={handleChangeDebitCredit}
        />
    );
}

// Default Imports
import React from "react";

// Import Rental RenewalRefinance Card and Rental RenewalRefinance Input
import RenewalRefinanceInput from "Components/InputContainer/RenewalRefinanceInput";
import RenewalRefinanceCard from "Components/Card/renewalRefinanceCard";

// Import Coloured PNGs
import renewalRefinanceBlack from "../../Assets/_eventNodeTypes/renewalRefinance-black.png";
import renewalRefinanceBlue from "../../Assets/_eventNodeTypes/renewalRefinance-blue.png";
import renewalRefinanceWhite from "../../Assets/_eventNodeTypes/renewalRefinance-white.png";
import renewalRefinanceGrey from "../../Assets/_eventNodeTypes/renewalRefinance-grey.png";
import { valueDoesNotExist } from "helpers/index.js";
import { CustomDate } from "helpers/refinanceRenewalConstants.js";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

import refinanceConnectionImage from "../../Assets/_optionDescriptionIcons/Mortgage, Renewal Refinance, Mortgage Insurance.png";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";

// Notes
// To call an object with no parameters, can simply use
//  - renewalRefinanceObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - renewalRefinanceObject.checkInput.call(renewalRefinanceObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental RenewalRefinance
export const renewalRefinanceObject = {
    constant: function () {
        return RenewalRefinance_Constant();
    },

    checkInput: function (state) {
        return RenewalRefinance_CheckInput(state);
    },

    svg: function () {
        return RenewalRefinance_Svg();
    },

    eventNodeMenu: function (focus) {
        return RenewalRefinance_EventNodeMenu(focus);
    },

    indexText: function () {
        return RenewalRefinance_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return RenewalRefinance_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return RenewalRefinance_OptionDescription();
    },

    colouredPngs: function () {
        return RenewalRefinance_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return RenewalRefinance_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental RenewalRefinance

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const RenewalRefinance_CheckInput = (state) => {
    if (
        !state.name ||
        !state["mortgage term"] ||
        !state["amortization period"] ||
        !state["rate"] ||
        !state["startOption"] ||
        (state["startOption"] === CustomDate && !state["start"]) ||
        !state["mortgage"]
    )
        return false;

    if (!state.cadence) return false;

    if (state.selectedForm === "refinance") {
        if (
            valueDoesNotExist(state.refinancingFees) ||
            state.refinancingFees < 0
        )
            return false;

        if (
            state.withdrawalType === "percentEquity" &&
            (valueDoesNotExist(state.valueWithdrawn) ||
                state.valueWithdrawn < 0 ||
                state.valueWithdrawn > 100)
        )
            return false;

        if (
            state.withdrawalType === "amountEquity" &&
            (valueDoesNotExist(state.equityWithdrawn) ||
                state.equityWithdrawn < 0)
        )
            return false;

        if (
            state.refinanceType === "customValue" &&
            (state.customValue < 0 || !state.customValue)
        ) {
            return false;
        }
    }

    return true;
};

// EFFECTS: Returns constant for event
const RenewalRefinance_Constant = () => {
    return "Renewal/ Refinance";
};

// EFFECTS: Returns svg for event
const RenewalRefinance_Svg = () => {
    return renewalRefinanceBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const RenewalRefinance_EventNodeMenu = (focus) => {
    return <RenewalRefinanceCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const RenewalRefinance_IndexText = () => {
    return "Select an input field to learn more about how each value affects your RenewalRefinance calculations.";
};

// EFFECTS: Returns all icons for event ??
const RenewalRefinance_Icons = () => {
    let pngs = new Map([
        ["black", renewalRefinanceBlack],
        ["blue", renewalRefinanceBlue],
        ["white", renewalRefinanceWhite],
        ["grey", renewalRefinanceGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const RenewalRefinance_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const RenewalRefinance_InputComponentInfoPassing = (minProps) => {
    return <RenewalRefinanceInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const RenewalRefinance_OptionDescription = () => {
    const tags = ["Option"];
    const type = renewalRefinanceObject.constant();
    const description =
        "Whether it is time to plan a mortgage renewal or explore refinancing an existing mortgage, use the Renewal and Refinance Event to calculate the results.";
    const usedForText =
        "Use the Renewal function to map out the costs, interest, and timelines for renewing a mortgage when the term expires. Alternatively, explore the costs of refinancing an existing home or mortgage. This Event will update or override the values in an existing mortgage and project those new values into the future.";
    const connection = "";
    const connectionImage = refinanceConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/mortgage/refinance/when-and-when-not-to-refinance-mortgage/";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const RenewalRefinance_SideModalImage = () => {
    return sideImage;
};

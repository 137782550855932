// @ts-nocheck
import {
    SelectDropDown,
    InputButtons,
    AccountDesc,
    ValueOutlined,
    SingleEntity,
} from "../Components";
import DeleteIcon from "../../../Assets/_nodeIcons/delete.png";

const accountTypes = [
    "Chequing",
    "Saving",
    "Investing",
    "Credit Card",
    "Paypal",
    "Stripe",
    "Other",
];

export default function BankManualInputView({
    entitiesMap,
    currentEntity,
    handleOnChange,
    addAccount,
    deleteAccount,
    onHandleSubmit,
    onHandleSubmitValues,
    passedCheck,
    edit,
}) {
    let showDeleteBtn;
    if (entitiesMap[currentEntity].data.accounts.length > 1)
        showDeleteBtn = true;
    else showDeleteBtn = false;
    const isPassed = passedCheck;

    return (
        <>
            <SingleEntity>
                <div className="cardContainer">
                    {entitiesMap[currentEntity].data.accounts.map(
                        (account, i) => {
                            return (
                                <div key={account.id} className="MarginBottom">
                                    <div className="ModalRowSpaceBetween PositionRelative">
                                        <div className="CounterElement">
                                            {i + 1}
                                        </div>

                                        <div className="twoThird">
                                            <div className="Required">*</div>
                                            <AccountDesc
                                                accNum={i}
                                                value={
                                                    entitiesMap[currentEntity]
                                                        .data.accounts[i]
                                                        .accountName
                                                }
                                                onChangeInput={(e, id, star) =>
                                                    handleOnChange(
                                                        e,
                                                        id,
                                                        star,
                                                        i
                                                    )
                                                }
                                                id="accName"
                                                label="Account Name"
                                            />
                                        </div>
                                        <div className="oneThird">
                                            <div className="Required">*</div>
                                            <SelectDropDown
                                                accNum={i}
                                                onChangeInput={(e, id, star) =>
                                                    handleOnChange(
                                                        e,
                                                        id,
                                                        star,
                                                        i
                                                    )
                                                }
                                                value={
                                                    entitiesMap[currentEntity]
                                                        .data.accounts[i]
                                                        .accountType
                                                }
                                                options={accountTypes}
                                                className="IncomeSelect"
                                                id="accType"
                                                label="Account Type"
                                            />
                                        </div>
                                    </div>

                                    <div className="ModalRowSpaceBetween">
                                        <div className="oneThird MarginRight">
                                            <div className="Required">*</div>
                                            <ValueOutlined
                                                accNum={i}
                                                label="Bank Balance"
                                                onChangeInput={(e, id, star) =>
                                                    handleOnChange(
                                                        e,
                                                        id,
                                                        star,
                                                        i
                                                    )
                                                }
                                                value={
                                                    entitiesMap[currentEntity]
                                                        .data.accounts[i]
                                                        .accountBalance
                                                }
                                                id="value"
                                            />
                                        </div>
                                        <div className="last-updated">
                                            Last Updated:{" "}
                                            {
                                                entitiesMap[currentEntity].data
                                                    .lastUpdated
                                            }
                                        </div>
                                        <div className="DeleteBankAccountIconWrapper">
                                            {showDeleteBtn && (
                                                <img
                                                    className="DeleteBankAccountIcon"
                                                    src={DeleteIcon}
                                                    title="Delete Account"
                                                    onClick={() =>
                                                        deleteAccount(account)
                                                    }
                                                    alt="delete account"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
                <div className="ModalRow">
                    <span className="AddAccountBtn" onClick={addAccount}>
                        + Add Another Account
                    </span>
                </div>
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmitValues}
                onHandleSubmit={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmitValues}
                passedCheck={isPassed}
                withSelected={
                    entitiesMap[currentEntity].data.bankSelected === "manual"
                        ? false
                        : true
                }
                selected={entitiesMap[currentEntity].data.selected.length}
            />
        </>
    );
}

// @ts-nocheck

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    RequiredStar,
    SingleEntity,
    SelectDropDownCountry,
} from "../Components";
import { businessObject } from "Components/Registry/Business";
import getGeolocation from "countrycitystatejson";
import { businessInputsHandler } from "../OnInputChangeHandlers/businessInputsHandler";

const countries = getGeolocation.getCountries();

export default function BusinessInputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    eventData,
    onChangeNameDescription,
    onHandleSubmit,
    passedCheck,
    edit,
}) {
    const maxOffset = 100;
    const thisYear = new Date().getFullYear();
    const allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x);
    }

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "country" | "entityName" | "state",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = businessInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={businessObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={businessObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDownCountry
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity].data.country}
                        options={countries}
                        className="termsContainer"
                        id="country"
                        label="Select Country"
                    />
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDownCountry
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity].data.state}
                        options={entitiesMap[currentEntity].data.stateList}
                        className="termsContainer"
                        id="state"
                        label="Select Province"
                    />
                </ModalRow>
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import InitialBalanceInputView from "./InitialBalanceInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { initialBalanceInputsHandler } from "../OnInputChangeHandlers/initialBalanceInputsHandler";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function InitialBalanceInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const allLedgerData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = useMemo(
        () => getDefaultName(initialBalanceObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(initialBalanceObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entityIndex,
        entitiesMap,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "value"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = initialBalanceInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = initialBalanceInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap, defaultName]);

    const selectedAccountData = useMemo(() => {
        const accountIds = entitiesMap[currentEntity]?.data?.accountIds ?? [];
        if (accountIds?.length > 0) {
            const relevantTopLevelAccountId = accountIds?.[0];
            return allLedgerData?.[relevantTopLevelAccountId] ?? {};
        } else {
            return {};
        }
    }, [allLedgerData, currentEntity, entitiesMap]);

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && initialBalanceObject.checkInput(entitiesMap);
    return (
        <InitialBalanceInputView
            entitiesMap={entitiesMap}
            setEntitiesMap={setEntitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            entitiesLength={entityIds.length}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            selectedAccountData={selectedAccountData}
            handleDateSelection={handleDateSelection}
        />
    );
}

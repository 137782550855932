import { connect } from "react-redux";
import Main from "./MainContainer";
import * as actions from "../../actions/scenario";
import { calculateScenario } from "actions/scenariov2Actions";

const mapStateToProps = (state) => {
    return {
        sampleScenarios: state.scenario.sampleScenarios,
        userScenarios: state.scenario.userScenarios,
        loadedUserScenarios: state.scenario.loadedUserScenarios,
        focus: state.scenario.focus,
        calculatedThreads: state.calculatedThreads,
        showOptions: state.scenario.showOptions,
        selectedEvent: state.scenario.selectedOption,
        line: state.scenario.line,
        loadedScenario: state.scenario.loadedScenario,
        edit: state.scenario.edit,
        highlightedThread: state.scenario.highlightedThread,
        nodeGraphState: state.scenario.nodeGraphState,
        sharedScenario: state.scenario.sharedScenario,
        shareScenario: state.scenario.shareScenario,
        signupState: state.scenario.signupState,
        onboardingData: state.scenario.onboardingData,
        manager: state.scenario.manager,
        baselineManager: state.scenario.baselineManager,
        baseline: state.scenario.baseline, // FIXME: deprecated
        baselines: state.scenario.baselines, // FIXME: deprecated
        hoveredInput: state.scenario.hoveredInput,
        isAddingLibraryEvent: state.scenario.isAddingLibraryEvent,
        showOverrideModal: state.scenario.showOverrideModal,
        modifiers: state.modifiers,
        showAccountMappingModal: state.scenario.showAccountMappingModal,
        showAccountFieldMappingModal:
            state.scenario.showAccountFieldMappingModal,
    };
};

const mapDispatchToProps = {
    calculateScenario,
    getSampleScenario: actions.getSampleScenario,
    setFocus: actions.setFocus,
    setManager: actions.setManager,
    setBaselineManager: actions.setBaselineManager,
    getUserScenarios: actions.getUserScenarios,
    closeOption: actions.closeOption,
    changeSelectedOption: actions.changeSelectedOption,
    loadScenario: actions.loadScenario,
    updateScenarioCanvas: actions.updateScenarioCanvas,
    setHighlightedThread: actions.setHighlightedThread,
    setNodeGraphState: actions.setNodeGraphState,
    getSharedScenario: actions.getSharedScenario,
    fetchSharedScenarios: actions.fetchSharedScenarios,
    setOnboardingData: actions.setOnboardingData,
    createScenario: actions.createScenario,
    onboardingScenario: actions.onboardingScenario,
    setOnboardingState: actions.setOnboardingState,
    onHandleFocusValue: actions.onHandleFocusValue,
    onhandleFocusedInput: actions.onhandleFocusedInput,
    getAdminValues: actions.getAdminValues,
    getBaseline: actions.getBaseline,
    closeExpiredNodeModal: actions.closeExpiredNodeModal,
    closeOutdatedNodeModal: actions.closeOutdatedNodeModal,
    toggleIsAddingLibraryEvent: actions.toggleIsAddingLibraryEvent,
    setBaselineDataManager: actions.setBaselineDataManager,
    getSingleBaseline: actions.getSingleBaseline,
    updateSampleScenario: actions.updateSampleScenario,
    updateFlinksData: actions.updateFlinksData,
    getTemplates: actions.getTemplates,
    updateNode: actions.updateNode,
    updateBaseline: actions.updateBaseline,
    showLogin: actions.showLogin,
};

const MainContainer = connect(mapStateToProps, mapDispatchToProps)(Main);

export default MainContainer;

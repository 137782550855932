import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import { useEffect, useState } from "react";
import styles from "./ChartModal.module.css";
import { setShowChartModal } from "actions/modalActions";
import closeSvg from "../../../Assets/close.svg";
import { useAppSelector } from "store/useAppSelectorDispatch";
import gearIcon from "Assets/_modalIcons/customAccountIcon.svg";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const StyledButton = styled(Button)<ButtonProps>(() => ({
    color: "#2E2D2D",
    width: "120px",
    textTransform: "capitalize",
    backgroundColor: "#F8B46A",
    "&:hover": {
        backgroundColor: "rgba(248, 179, 106, 0.75)",
    },
}));

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);

const resize = (array: number[], newSize: number) => {
    while (newSize > array.length) array.push(0.5);
    array.length = newSize;
};

export const ChartModal = () => {
    const {
        numDataPoints,
        monthsStringArray,
        addUpdateDistribution,
        prevYData,
    } = useAppSelector((state) => {
        const showChartModal = state.scenario?.showChartModal;
        const numDataPoints: number = showChartModal?.numDataPoints;
        const monthsStringArray: string[] = showChartModal?.monthsStringArray;
        const addUpdateDistribution: (distData: number[]) => void =
            showChartModal?.addUpdateDistribution;
        const prevYData: number[] = [...showChartModal?.yData];

        return {
            numDataPoints,
            monthsStringArray,
            addUpdateDistribution,
            prevYData,
        };
    });

    const [newDataPoint, setNewDataPoint] = useState<{
        index: number;
        value: number;
    } | null>(null);

    const [yData, setYData] = useState(() => {
        if (prevYData?.length) {
            if (prevYData.length != numDataPoints)
                resize(prevYData, numDataPoints);
            return prevYData;
        }
        return Array(numDataPoints).fill(0.5);
    });

    const closeModal = () => {
        setShowChartModal({
            show: false,
            numDataPoints: 0,
            monthsStringArray: [],
            addUpdateDistribution: (_distData: number[]) => {
                return;
            },
            yData: [],
        });
    };

    const submitCloseModal = () => {
        addUpdateDistribution(yData);
        closeModal();
    };

    const resetData = () => {
        setYData(Array(numDataPoints).fill(0.5));
    };

    useEffect(() => {
        if (newDataPoint) {
            const newData = [...yData];
            newData[newDataPoint.index] = newDataPoint.value;
            setYData(newData);
            setNewDataPoint(null);
        }
    }, [newDataPoint, yData]);

    const data = {
        labels: monthsStringArray,
        datasets: [
            {
                data: yData,
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 2,
                fill: true,
                pointHitRadius: 20,
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                min: 0,
                max: 1,
                stepSize: 0.1,
            },
            x: {
                ticks: {
                    display: true,
                },
            },
        },
        plugins: {
            dragData: {
                round: 3, // rounds the values to n decimal places
                onDragStart: function (_e, _element) {
                    // No operation
                },
                onDrag: function (_e, datasetIndex, index, value) {
                    if (value < 0 || value > 1) return false;
                    if (index >= numDataPoints || datasetIndex > 0)
                        return false;
                },
                onDragEnd: function (_e, _datasetIndex, index, value) {
                    setNewDataPoint({ index, value });
                },
            },
        },
    };

    return (
        <div className={styles.modalBackground} onClick={() => closeModal()}>
            <div
                className={styles.modalContainer}
                onClick={(e) => e.stopPropagation()}
            >
                <img
                    className={styles.closeIcon}
                    src={closeSvg}
                    alt="close icon"
                    draggable={false}
                    onClick={() => closeModal()}
                />
                <div className={styles.headerContainer}>
                    <img
                        className={styles.headerIcon}
                        src={gearIcon}
                        alt="gear icon"
                        draggable={false}
                    />
                    <h2 className={styles.headerCopy}>Distribution Editor</h2>
                </div>
                <div className={styles.chartContainer}>
                    <Line
                        data={data}
                        // @ts-ignore
                        options={options}
                    />
                </div>
                <div className={styles.buttonsContainer}>
                    <StyledButton
                        variant="contained"
                        onClick={() => resetData()}
                    >
                        Reset
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        onClick={() => submitCloseModal()}
                    >
                        Save Curve
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

import React, { Component } from "react";
import { getDefaultName } from "../../helpers";
import _ from "lodash";
import { throwError } from "../../helpers/swalError";

import {
    Description,
    InputButtons,
    Rate,
    Value,
    InfoBox,
    InfoBoxRow,
    InfoBoxLabel,
    InfoBoxPercent,
    InfoBoxPrice,
    EventInputs,
    EventInputModal,
    EventHeader,
    ModalRow,
    ToggledContainer,
    ToggledContainerHalf,
    RequiredStar,
    ModalRowHalf,
} from "./Components";
import {
    getClosestDependency,
    getDependencyCandidates,
} from "../../helpers/nodeDependencyDetectionHelpers";
import radioButtonOff from "../../Assets/_nodeInputs/radioButtonOff.svg";
import radioButtonOn from "../../Assets/_nodeInputs/radioButtonOn.svg";
import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
import { mortgageObject } from "Components/Registry/Mortgage";
import { houseObject } from "Components/Registry/House";

class MortgageInsuranceinput extends Component {
    constructor(props) {
        super(props);

        const upstreamData = this.getUpstreamMortgageAndHouse();

        let taxData;
        if (upstreamData) {
            // valid mortgage found
            taxData = this.getTaxDataByProvince(upstreamData.province);
        } else {
            // if no valid mortgage found
            taxData = { taxRate: 0, taxAmount: 0 };
        }

        this.state = {
            name: getDefaultName(mortgageInsuranceObject.constant(), this.props),
            type: mortgageInsuranceObject.constant(),
            applyInflation: false,
            description: "",
            value: "",
            tag: `@${mortgageInsuranceObject.constant()}`,
            rating: 0,
            inflation: false,
            baseline: false,
            start: upstreamData ? upstreamData.mortgageStart : null,
            premiumAmount: "",
            premiumPercent: "",
            paymentMethod: "Lump Sum",
            mortgagePrincipal: upstreamData ? upstreamData.principal : null,
            province: upstreamData ? upstreamData.province : null,
            taxRate: taxData.taxRate,
            taxAmount: taxData.taxAmount,
            mortgageId: upstreamData ? upstreamData.mortgageId : null,
            mortgageAmortization: upstreamData
                ? upstreamData.mortgageAmortization
                : null,
            useAutoValue: false,
        };
    }

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    componentDidMount() {
        if (this.props.edit) {
            const upstreamData = this.getUpstreamMortgageAndHouse();
            this.setState({
                ...this.props.editData,
                mortgageId: upstreamData ? upstreamData.mortgageId : null,
                mortgagePrincipal: upstreamData ? upstreamData.principal : null,
                start: upstreamData ? upstreamData.mortgageStart : null,
                province: upstreamData ? upstreamData.province : null,
                mortgageAmortization: upstreamData
                    ? upstreamData.mortgageAmortization
                    : null,
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;
        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "premiumPercent":
                this.calculatePremium(id, value);
                break;
            case "premiumAmount":
                this.calculatePremium(id, value);
                break;
            default:
        }
    };

    getUpstreamMortgageAndHouse = () => {
        const dependencyData = {
            principal: null,
            mortgageStart: null,
            province: null,
            mortgageId: null,
            mortgageAmortization: null,
        };
        const data = {
            curNode: null,
            isValid: false,
        };
        const dependencies = getClosestDependency(this.props, [mortgageObject.constant()]);
        this.processDependencies(dependencies, data, mortgageObject.constant());
        if (!data.isValid) {
            throwError(
                "warning",
                "Failed to find an upstream Mortgage node.",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
            return null;
        }

        dependencyData.principal =
            data.curNode.metadata.price - data.curNode.metadata.downpayment;
        dependencyData.mortgageStart = data.curNode.metadata["first payment"];
        dependencyData.mortgageId = data.curNode.id;
        dependencyData.mortgageAmortization =
            data.curNode.metadata["amortization period"];
        const houseId = data.curNode.metadata.houseId;
        const houses = getDependencyCandidates(
            this.props,
            [houseObject.constant()],
            null,
            false
        );

        const house = houses[houseObject.constant()].filter((h) => {
            return h.id === houseId;
        })[0];
        if (!house) {
            throwError(
                "warning",
                "Failed to find an upstream House node.",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
            return null;
        }
        dependencyData.province = house.metadata.province;
        return dependencyData;
    };

    processDependencies = (dependencies, data, nodeType) => {
        if (dependencies && dependencies[nodeType]) {
            data.curNode = dependencies[nodeType];
            data.isValid = true;
        } else {
            data.isValid = false;
        }
    };

    // generates sales tax on mortgage premium by province
    // only Quebec, Ontario, and Saskatchewan charge PST on mortgage insurance as of 2020-09-28
    getTaxDataByProvince = (province) => {
        let taxRate;

        switch (province) {
            case "QC":
                taxRate = 0.09;
                break;
            case "ON":
                taxRate = 0.08;
                break;
            case "SK":
                taxRate = 0.06;
                break;
            default:
                taxRate = 0;
        }

        return { taxRate, taxAmount: 0 };
    };
    calculatePremium = (id, value) => {
        let lastChangedProp, premiumPercent, premiumAmount;
        if (id === "premiumPercent") {
            lastChangedProp = "percent";
            premiumPercent = value;
            premiumAmount = this.state.mortgagePrincipal
                ? Math.round((value / 100) * this.state.mortgagePrincipal)
                : 0;
        } else if (id === "premiumAmount") {
            lastChangedProp = "amount";
            premiumAmount = value;
            premiumPercent = this.state.mortgagePrincipal
                ? ((value / this.state.mortgagePrincipal) * 100).toFixed(2)
                : 0;
        }
        this.setState({
            lastChangedPremiumProperty: lastChangedProp,
            premiumPercent,
            premiumAmount,
            taxAmount: premiumAmount * this.state.taxRate,
        });
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (mortgageInsuranceObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    togglePremiumType = (type) => {
        if (type.target.id === "automatic") {
            this.setState({ useAutoValue: true });
        } else {
            this.setState({ useAutoValue: false });
        }
    };

    onHandleRadioButton = (paymentMethod) => {
        this.setState({ paymentMethod });
    };

    render() {
        const passedCheck = mortgageInsuranceObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            description,
            name,
            premiumAmount,
            premiumPercent,
            paymentMethod,
            taxRate,
            taxAmount,
            province,
            useAutoValue,
        } = this.state;

        const taxInfo =
            taxRate > 0 ? (
                <InfoBox>
                    <InfoBoxRow>
                        <InfoBoxLabel text={`Sales Tax in ${province}`} />
                        <InfoBoxPercent value={taxRate * 100} />
                    </InfoBoxRow>
                    <InfoBoxRow>
                        <InfoBoxLabel text="Total Sales Tax" />
                        <InfoBoxPrice value={taxAmount} />
                    </InfoBoxRow>
                    <InfoBoxRow>
                        <InfoBoxLabel text="Total Insurance Premium" />
                        <InfoBoxPrice value={taxAmount + premiumAmount} />
                    </InfoBoxRow>
                </InfoBox>
            ) : (
                <div />
            );
        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={mortgageInsuranceObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={mortgageInsuranceObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ToggledContainer>
                        <ToggledContainerHalf
                            id="custom"
                            onClick={this.togglePremiumType}
                            isSelected={!useAutoValue}
                            title="Enter Custom Premium"
                        />
                        <ToggledContainerHalf
                            id="automatic"
                            onClick={this.togglePremiumType}
                            isSelected={useAutoValue}
                            title="Calculate Premium"
                        />
                    </ToggledContainer>
                    {!useAutoValue && (
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <RequiredStar />
                                <Rate
                                    value={premiumPercent}
                                    label="Insurance Premium Percentage"
                                    className="mlsInput"
                                    id="premiumPercent"
                                    onChangeInput={this.onChangeInput}
                                    sign={true}
                                />
                            </ModalRowHalf>

                            <ModalRowHalf>
                                <RequiredStar />
                                <Value
                                    label="Insurance Premium Amount"
                                    onChangeInput={this.onChangeInput}
                                    value={premiumAmount}
                                    id="premiumAmount"
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    )}
                    <ModalRow>
                        <RequiredStar />
                        <div className="PTTLabel">Payment Method</div>
                        <img
                            alt="alt"
                            onClick={() => this.onHandleRadioButton("Lump Sum")}
                            src={
                                paymentMethod === "Lump Sum"
                                    ? radioButtonOn
                                    : radioButtonOff
                            }
                            className="pttRadioButton"
                        />
                        <div className="PTTValueLabel">Lump Sum</div>
                        <img
                            alt="alt"
                            onClick={() =>
                                this.onHandleRadioButton("Add to Mortgage")
                            }
                            id="Add to Mortgage"
                            src={
                                paymentMethod === "Add to Mortgage"
                                    ? radioButtonOn
                                    : radioButtonOff
                            }
                            className="pttRadioButton"
                        />
                        <div className="PTTValueLabel">Add to Mortgage</div>
                    </ModalRow>
                    {taxInfo}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default MortgageInsuranceinput;

import { getRelevantEntities } from "actions/getNodeEntityActions";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

const getAllocation = (segments) => {
    let totalAllocation = 0;
    segments.forEach(
        (target) =>
            (totalAllocation =
                totalAllocation + Number(target?.allocation))
    );

    const availableAllocation = 100 - totalAllocation;

    return `${availableAllocation}`;
};

const allocationInputsHandler = (
    value: string,
    id:
    | "segment"
    | "entityName"
    | "allocation",
    index: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    const segments = data.segments.map((target) => ({
        ...target,
    }));
    const targetProfile = segments[index];

    switch (id) {
        case "segment":
            const parsedSegment = JSON.parse(value || "{\"eventId\": \"\", \"entityIds\": []}")

            handleDependencies(dependencyMap, parsedSegment, eventId, currentEntityObject.id, data.segmentEventId, data.segmentIds)
            const segmentEntity = getRelevantEntities(parsedSegment?.entityIds ?? [])[parsedSegment?.entityIds[0] ?? ""]

            let customerEntity
            if (segmentEntity) customerEntity = getRelevantEntities(segmentEntity?.data?.customerIds ?? [])[segmentEntity.data.customerIds[0] ?? ""]

            data.selectedSegment = parsedSegment
            data.segmentEventId = parsedSegment?.eventId ?? ""
            data.segmentIds = parsedSegment?.entityIds ?? []
            data.segments = segmentEntity?.data.segments ?? []
            data.customerIds = segmentEntity?.data.customerIds ?? []
            data.customerName = customerEntity?.name ?? ""
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "allocation":
            if (!index && index !== 0) break;
            if (isNaN(Number(value))) break;

            targetProfile[id] = value;

            const allNonTargetProfiles = segments.filter(
                (segment) => segment?.id !== targetProfile?.id
            );

            const availableAllocation =
                getAllocation(allNonTargetProfiles);

            if (
                Number(targetProfile.allocation) >
                Number(availableAllocation)
            ) {
                targetProfile.allocation = availableAllocation;
            }

            segments[index] = targetProfile;
            data.segments = segments;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

export default allocationInputsHandler;

import { connect } from "react-redux";
import * as actions from "../../actions/scenario";
import { getEntities } from "actions/entitiesActions";
import HomeContainer from "./Home";

const mapStateToProps = (state) => {
    return {
        sampleScenarios: state.scenario.sampleScenarios,
        sampleScenariosLoading: state.scenario.sampleScenariosLoading,
        userScenarios: state.scenario.userScenarios,
        loadedScenario: state.scenario.loadedScenario,
        loadedScenarioId: state.scenario.loadedScenarioId,
        sharedScenario: state.scenario.sharedScenario,
        accountData: state.scenario.accountData,
        loginPopUp: state.scenario.loginPopUp,
        agencyClients: state.scenario.agencyClients,
        activeAgencyClients: state.scenario.activeAgencyClients,
        archivedAgencyClients: state.scenario.archivedAgencyClients,
        baselineDataManager: state.scenario.baselineDataManager,
        baselineManager: state.scenario.baselineManager,
        showExploreWhatifiOptions: state.scenario.showExploreWhatifiOptions,
        manager: state.scenario.manager,
        eventLibrary: state.scenario.eventLibrary,
        scenarioTemplates: state.scenario.scenarioTemplates,
        tabSelected: state.scenario.tabSelected,
        areBaselinesLoaded: state.scenario.areBaselinesLoaded,
    };
};

const mapDispatchToProps = {
    getSampleScenario: actions.getSampleScenario,
    editScenario: actions.editScenario,
    loadScenario: actions.loadScenario,
    createScenario: actions.createScenario,
    deleteScenario: actions.deleteScenario,
    getUserScenarios: actions.getUserScenarios,
    onboardingScenario: actions.onboardingScenario,
    showLogin: actions.showLogin,
    setOnboardingState: actions.setOnboardingState,
    setOnboardingData: actions.setOnboardingData,
    createScenarioPopup: actions.createScenarioPopup,
    selectTab: actions.selectTab,
    addClient: actions.addClient,
    getClientData: actions.getClientData,
    getAgencyClients: actions.getAgencyClients,
    updateClient: actions.updateClient,
    deleteClient: actions.deleteClient,
    createBaseline: actions.createBaseline,
    toggleExploreOptions: actions.toggleExploreOptions,
    toggleShowAgencyBaselineView: actions.toggleShowAgencyBaselineView,
    getAdminValues: actions.getAdminValues,
    updateScenario: actions.updateScenario,
    deleteBaseline: actions.deleteBaseline,
    updateBaseline: actions.updateBaseline,
    getTemplates: actions.getTemplates,
    getEntities: getEntities,
    setManager: actions.setManager,
    fetchSharedScenarios: actions.fetchSharedScenarios,
};

const Home = connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

export default Home;

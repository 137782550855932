import React from "react";
import styles from "./index.module.css";
import tourImage from "../../Assets/_home/productTour.png";
import sampleScenarioImage from "../../Assets/_home/sampleScenario.png";
import baselineImage from "../../Assets/_home/baseline.png";
import templateImage from "../../Assets/_home/template.png";
import ChildcareImage from "../../Assets/_home/1-childcare.png";
import EducationImage from "../../Assets/_home/2-education.png";
import CareerImage from "../../Assets/_home/3-career.png";
import HealthImage from "../../Assets/_home/4-health.png";
import CareerChangeImage from "../../Assets/_home/5-careerChange.png";
import RetirementImage from "../../Assets/_home/6-retirement.png";
import FamilyImage from "../../Assets/_home/7-family.png";
import DebtManagementImage from "../../Assets/_home/8-debtManagement.png";
import RealEstateImage from "../../Assets/_home/9-realEstate.png";
import InvestingImage from "../../Assets/_home/10-investing.png";
import RelocationImage from "../../Assets/_home/11-relocation.png";
import TravelImage from "../../Assets/_home/12-travel.png";
import TransportationImage from "../../Assets/_home/13-transportation.png";
import RelationshipChangeImage from "../../Assets/_home/14-relationshipChange.png";
import { DEFAULT } from "../../actions/types";
import { EventManager } from "../../Events";
import HomeAgency from "./homeAgency";
import HomeBusiness from "./homeBusiness.tsx";
import { AGENCY, _BUSINESS } from "../../helpers/constants";
import SignUpContainer from "../../Components/SignUp";
// import MoveCardsArrow from "../../Assets/_home/move-cards-arrow.svg";
// import CreateScenarioModal from "../../Components/CreateScenarioModal";
import { getMaxScenarioCount, getPlanName } from "../../helpers/userHelpers";
export default class HomeContainer extends React.Component {
    constructor(props) {
        super(props);

        this.manager = new EventManager(
            this.updateCanvas,
            this.updateScenarioCanvas,
            this.props.getEntities()
        );

        this.state = {
            options: [
                {
                    id: 0,
                    title: "Take a Product Tour",
                    subTitle:
                        "Learn the basics of whatifi by interactively building a 'rent vs buy' example scenario.",
                    image: tourImage,
                    optionId: "tour",
                    banner: false,
                },
                {
                    id: 1,
                    title: "Explore Sample Scenarios",
                    subTitle:
                        "Click through some of our pre-built sample scenarios and get a better idea what is possible in whatifi.",
                    image: sampleScenarioImage,
                    optionId: "sample",
                    banner: false,
                },
                {
                    id: 2,
                    title: "Build Your First Scenario From a Template",
                    subTitle:
                        "No need to start from scratch, we prepared templates for you!",
                    image: templateImage,
                    optionId: "template",
                    banner: true,
                },
                {
                    id: 3,
                    title: "Add Events to Your Baseline",
                    subTitle:
                        "Your Baseline is your profile. Use it to capture all that is current and real in your Life.",
                    image: baselineImage,
                    optionId: "baseline",
                    banner: true,
                },
            ],
            showLanding: true,
            showCardsArrows: window.innerWidth < 1875,
            showSampleScenarioCardsArrow: this.props.sampleScenarios.length > 3,
            sampleScenariosArrowCounter: 0,
            showSignUpModal: true,
            cardsArrowCounter: 0,
            showAddScenario: false,
            scenarioCreatedFromHome: false,
        };
    }

    updateCanvas = () => {
        const manager = this.manager;

        this.setState({ nodes: manager.allNodes() });
        // this.exportForCalculations(details);
    };

    componentDidMount() {
        this.props.getUserScenarios();
        this.props.fetchSharedScenarios();
        this.props.setManager(this.manager);
        if (this.state.scenarioCreatedFromHome === true)
            this.setState({ scenarioCreatedFromHome: false });

        window.addEventListener("resize", this.getWindowWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.getWindowWidth);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userScenarios !== this.props.userScenarios) {
            if (
                prevProps.userScenarios.length <
                    this.props.userScenarios.length &&
                this.state.scenarioCreatedFromHome
            ) {
                const justAddedScenario = this.props.userScenarios.filter(
                    (scenario1) =>
                        !prevProps.userScenarios.some(
                            (scenario2) => scenario2.id === scenario1.id
                        )
                );
                this.props.history.push("/");
                this.loadNewScenario(justAddedScenario[0]);
            }
        }

        if (prevProps.sampleScenarios !== this.props.sampleScenarios) {
            this.getWindowWidth();
        }
    }

    getWindowWidth = () => {
        if (window.innerWidth < 1875) this.setState({ showCardsArrows: true });
        else this.setState({ showCardsArrows: false });

        if (this.props.sampleScenarios?.length > 3 || window.innerWidth < 1700)
            this.setState({ showSampleScenarioCardsArrow: true });
        else {
            this.setState({ showSampleScenarioCardsArrow: false });
        }
    };

    handleTour = () => {
        this.props.history.push("/");
        this.props.selectTab("/");
        this.props.setOnboardingState(DEFAULT);
        this.props.setOnboardingData(1);
    };

    handleSampleScenarios = () => {
        this.props.history.push("/");
        this.props.setOnboardingState(DEFAULT);
    };

    handleTemplates = () => {
        this.props.history.push("/");
        this.props.createScenarioPopup(true);
    };

    handleProfile = () => {
        this.props.history.push("/baseline");
        this.props.setOnboardingState(DEFAULT);
    };

    handleOnclick = (id) => {
        const loggedInUser = localStorage.getItem("loggedInUser");
        switch (id) {
            case "tour":
                this.handleTour();
                break;
            case "sample":
                this.handleSampleScenarios();
                break;
            case "template":
                if (loggedInUser) {
                    this.handleTemplates();
                } else {
                    return null;
                }
                break;
            case "baseline":
                //hide baseline
                if (loggedInUser) {
                    this.handleProfile();
                } else {
                    return null;
                }
                break;

            default:
        }
    };

    handleLandingPageClose = () => {
        this.setState({ showLanding: false });
        this.setState({ showSignUpModal: false });
    };

    handleAccountOptionClose = () => {
        this.setState({ showSignUpModal: false });
    };

    handleClickCardsArrowRight = () => {
        if (this.state.cardsArrowCounter < 5)
            this.setState({
                cardsArrowCounter: this.state.cardsArrowCounter + 1,
            });
    };

    handleClickCardsArrowLeft = () => {
        if (this.state.cardsArrowCounter > 0)
            this.setState({
                cardsArrowCounter: this.state.cardsArrowCounter - 1,
            });
    };

    handleSampleScenariosArrowClick = (arrowDirection) => {
        if (
            arrowDirection === "right" &&
            this.state.sampleScenariosArrowCounter < 5
        )
            this.setState({
                sampleScenariosArrowCounter:
                    this.state.sampleScenariosArrowCounter + 1,
            });

        if (
            arrowDirection === "left" &&
            this.state.sampleScenariosArrowCounter > 0
        )
            this.setState({
                sampleScenariosArrowCounter:
                    this.state.sampleScenariosArrowCounter - 1,
            });
    };

    handleClickSampleScenarioCard = (scenarioData) => {
        this.props.history.push("/");
        this.props.selectTab("/");
        this.props.handleClickSampleScenarioCard(scenarioData);
    };

    closeScenarioCreation = () => {
        this.setState({ showAddScenario: false });
    };

    onHandleScenarioCountToast = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const scenarioCount = this.props.userScenarios.length + 1;
        const planName = getPlanName(loggedInUser && loggedInUser.plan);
        const maxNumScenarios = getMaxScenarioCount(
            loggedInUser && loggedInUser.plan
        );
        const scenarioRemainingCount = maxNumScenarios - scenarioCount;
        const value = scenarioRemainingCount <= 0 ? 0 : scenarioRemainingCount;

        if (maxNumScenarios !== Infinity) {
            const toastData = {
                type: "warnings",
                text: `You have ${value} Scenarios left in your ${planName}`,
                upgrade: value ? false : true,
            };
            this.setState({ toastData }, () => {
                setTimeout(() => {
                    this.setState({ toastData: null });
                }, 7000);
            });
        }
    };

    loadNewScenario = (scenario) => {
        this.props.baselineDataManager.setIsFirstLoad(true);
        this.props.loadScenario(
            scenario,
            this.manager,
            this.props.baselineDataManager,
            null,
            this.props.eventLibrary
        );
        this.props.history.push("/");
    };

    createScenario = (
        name,
        description,
        range,
        inflation,
        client,
        explainerVideoLink,
        subtitle,
        custom_start_date,
        start_date_preference
    ) => {
        this.manager.addStart();
        const normalData = this.manager.exportData();
        const normalScenario = {
            name,
            description,
            data: normalData,
            range,
            inflation,
            explainerVideoLink,
            subtitle,
            custom_start_date,
            start_date_preference,
            dependency_map: {}
        };

        this.props.createScenario(normalScenario, range, (data) => {
            if (data) {
                this.props.getUserScenarios();
            }
        });
        // this.loadNewScenario(normalScenario);
        this.onHandleScenarioCountToast();
        this.setState({ scenarioCreatedFromHome: true });
    };

    showScenarioScenarioCreationModal = () => {
        this.setState({ showAddScenario: true });
    };

    render() {
        const {
            _options,
            showSignUpModal,
            _showCardsArrows,
            cardsArrowCounter,
            _showAddScenario,
            sampleScenariosArrowCounter,
            _showSampleScenarioCardsArrow,
        } = this.state;
        const { showLogin } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const userRole = localStorage.getItem("userRole");
        let _cardsTranslate = {
            transform: `translateX(-${cardsArrowCounter * 10}%)`,
        };

        let _sampleScenarioCardsTranslate = {
            transform: `translateX(-${sampleScenariosArrowCounter * 10}%)`,
        };

        const _scenarioCards = this.props.sampleScenarios.map(
            (sampleScenario) => {
                const categories = [];
                let categoryImage = [];

                if (sampleScenario.categories.includes("career")) {
                    categories.push({
                        categoryName: "Career",
                        categoryColor: "#BCBCBC",
                    });
                    categoryImage.push(CareerImage);
                }
                if (sampleScenario.categories.includes("careerChange")) {
                    categories.push({
                        categoryName: "Career Change",
                        categoryColor: "#FFD185",
                    });
                    categoryImage.push(CareerChangeImage);
                }
                if (sampleScenario.categories.includes("childcare")) {
                    categories.push({
                        categoryName: "Childcare",
                        categoryColor: "#BAED8D",
                    });
                    categoryImage.push(ChildcareImage);
                }
                if (sampleScenario.categories.includes("debtManagement")) {
                    categories.push({
                        categoryName: "Debt Management",
                        categoryColor: "#00ADBB",
                    });
                    categoryImage.push(DebtManagementImage);
                }
                if (sampleScenario.categories.includes("education")) {
                    categories.push({
                        categoryName: "Education",
                        categoryColor: "#FFD185",
                    });
                    categoryImage.push(EducationImage);
                }
                if (sampleScenario.categories.includes("family")) {
                    categories.push({
                        categoryName: "Family",
                        categoryColor: "#BAED8D",
                    });
                    categoryImage.push(FamilyImage);
                }
                if (sampleScenario.categories.includes("health")) {
                    categories.push({
                        categoryName: "Health",
                        categoryColor: "#9969FF",
                    });
                    categoryImage.push(HealthImage);
                }
                if (sampleScenario.categories.includes("investing")) {
                    categories.push({
                        categoryName: "Investing",
                        categoryColor: "#EEEEEE",
                    });
                    categoryImage.push(InvestingImage);
                }
                if (sampleScenario.categories.includes("realEstate")) {
                    categories.push({
                        categoryName: "Real Estate",
                        categoryColor: "#FFF4DF",
                    });
                    categoryImage.push(RealEstateImage);
                }
                if (sampleScenario.categories.includes("relationshipChange")) {
                    categories.push({
                        categoryName: "Relationship Change",
                        categoryColor: "#EEFFBE",
                    });
                    categoryImage.push(RelationshipChangeImage);
                }
                if (sampleScenario.categories.includes("relocation")) {
                    categories.push({
                        categoryName: "Relocation",
                        categoryColor: "#A3EAF0",
                    });
                    categoryImage.push(RelocationImage);
                }
                if (sampleScenario.categories.includes("retirement")) {
                    categories.push({
                        categoryName: "Retirement",
                        categoryColor: "#FFBBE1",
                    });
                    categoryImage.push(RetirementImage);
                }
                if (sampleScenario.categories.includes("transportation")) {
                    categories.push({
                        categoryName: "Transportation",
                        categoryColor: "#B5D3FF",
                    });
                    categoryImage.push(TransportationImage);
                }
                if (sampleScenario.categories.includes("travel")) {
                    categories.push({
                        categoryName: "Travel",
                        categoryColor: "#9388FF",
                    });
                    categoryImage.push(TravelImage);
                }
                if (sampleScenario.categories.includes("student")) {
                    categories.push({
                        categoryName: "Student",
                        categoryColor: "#9388FF",
                    });
                    categoryImage.push(FamilyImage);
                }
                if (sampleScenario.categories.includes("business")) {
                    categories.push({
                        categoryName: "Business",
                        categoryColor: "#EEEEEE",
                    });
                    categoryImage.push(InvestingImage);
                }

                return (
                    <div
                        key={sampleScenario.name}
                        className={styles.sampleScenarioCardWrapper}
                        onClick={() =>
                            this.handleClickSampleScenarioCard(sampleScenario)
                        }
                    >
                        <div className={styles.sampleScenarioCardTopWrapper}>
                            <img
                                className={styles.sampleScenarioCardTopImg}
                                src={categoryImage[0]}
                                alt="category graphic"
                            />
                        </div>
                        <div className={styles.sampleScenarioCardBottomWrapper}>
                            <div
                                className={
                                    styles.sampleScenarioCardBottomTextWrapper
                                }
                            >
                                <span
                                    className={
                                        styles.sampleScenarioCardBottomTextOne
                                    }
                                >
                                    {sampleScenario.name}
                                </span>
                                <span
                                    className={
                                        styles.sampleScenarioCardBottomTextTwo
                                    }
                                >
                                    {sampleScenario.subtitle}
                                </span>
                                <div
                                    className={
                                        styles.sampleScenarioCategoriesWrapper
                                    }
                                >
                                    {categories.map((category, i) => {
                                        return (
                                            <div
                                                className={
                                                    styles.sampleScenarioCardBottomTextThreeWrapper
                                                }
                                                style={{
                                                    backgroundColor:
                                                        category.categoryColor,
                                                }}
                                                key={i}
                                            >
                                                <span
                                                    className={
                                                        styles.sampleScenarioCardBottomTextThree
                                                    }
                                                >
                                                    {category.categoryName}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        );

        if (loggedInUser && userRole === AGENCY) {
            return <HomeAgency {...this.props} />;
            // } else if (loggedInUser && userRole === BUSINESS) {
            //     return <HomeBusiness {...this.props} />;
        } else {
            return (
                <>
                    <HomeBusiness {...this.props} />
                    {!loggedInUser && showSignUpModal && (
                        <SignUpContainer
                            close={this.handleAccountOptionClose}
                            history={this.props.history}
                            showLogin={showLogin}
                        />
                    )}
                </>
            );
            // this is the old home page
            // return (
            //     <div className={styles.root}>
            //         {showAddScenario && (
            //             <CreateScenarioModal
            //                 title="Create Scenario"
            //                 open={showAddScenario}
            //                 close={this.closeScenarioCreation}
            //                 submit={this.createScenario}
            //                 loadScenario={this.props.loadScenario}
            //                 manager={this.manager}
            //                 onboardingScenario={this.props.onboardingScenario}
            //                 getUserScenarios={this.props.getUserScenarios}
            //                 createScenario={this.props.createScenario}
            //                 showCreateScenario={this.props.showCreateScenario}
            //                 getBaseline={this.props.getBaseline}
            //                 baseline={this.props.baseline}
            //                 getAdminValues={this.props.getAdminValues}
            //                 baselineManager={this.props.baselineManager}
            //                 baselineDataManager={this.props.baselineDataManager}
            //                 userScenarios={this.props.userScenarios}
            //                 scenarioTemplates={this.props.scenarioTemplates}
            //                 agencyClients={this.props.agencyClients}
            //                 addScenarioToClient={this.addScenarioToClient}
            //                 tabSelected={this.props.tabSelected}
            //                 history={this.props.history}
            //             />
            //         )}
            //         <div className={styles.container}>
            //             <div className={styles.cardsRow}>
            //                 <div className={styles.headerText}>
            //                     <div className={styles.headersWrapper}>
            //                         <div className={styles.boldText}>
            //                             Build Answers. Create Clarity.
            //                         </div>
            //                         <div className={styles.plainText}>
            //                             We&apos;re here to help you make bigger
            //                             life decisions. better.
            //                         </div>
            //                     </div>
            //                     <div className={styles.boldText}>
            //                         Getting Started
            //                     </div>
            //                     <div className={styles.plainText}>
            //                         Start exploring whatifi by visiting one of
            //                         our sample scenarios...
            //                     </div>
            //                 </div>
            //                 <div
            //                     className={
            //                         styles.sampleScenarioCardsWrapperContainer
            //                     }
            //                 >
            //                     {showSampleScenarioCardsArrow && (
            //                         <div
            //                             className={
            //                                 styles.sampleScenarioArrowLeftWrapper
            //                             }
            //                             onClick={() =>
            //                                 this.handleSampleScenariosArrowClick(
            //                                     "left"
            //                                 )
            //                             }
            //                         >
            //                             <img
            //                                 className={
            //                                     styles.sampleScenarioArrowLeft
            //                                 }
            //                                 src={MoveCardsArrow}
            //                                 alt="move left"
            //                             />
            //                         </div>
            //                     )}
            //                     <div
            //                         className={styles.sampleScenarioCardFadeIn}
            //                     />
            //                     <div
            //                         className={
            //                             styles.sampleScenarioCardsContainer
            //                         }
            //                         style={sampleScenarioCardsTranslate}
            //                     >
            //                         {scenarioCards}
            //                     </div>
            //                     <div
            //                         className={styles.sampleScenarioCardFadeOut}
            //                     />
            //                     {showSampleScenarioCardsArrow && (
            //                         <div
            //                             className={
            //                                 styles.sampleScenarioArrowRightWrapper
            //                             }
            //                             onClick={() =>
            //                                 this.handleSampleScenariosArrowClick(
            //                                     "right"
            //                                 )
            //                             }
            //                         >
            //                             <img
            //                                 className={
            //                                     styles.sampleScenarioArrowRight
            //                                 }
            //                                 src={MoveCardsArrow}
            //                                 alt="move right"
            //                             />
            //                         </div>
            //                     )}
            //                 </div>
            //                 {loggedInUser && (
            //                     <div className={styles.row}>
            //                         <span
            //                             className={styles.ctaText}
            //                             onClick={
            //                                 this
            //                                     .showScenarioScenarioCreationModal
            //                             }
            //                         >
            //                             + Add a New Scenario
            //                         </span>
            //                     </div>
            //                 )}
            //             </div>
            //             <div className={styles.headerCardsWrapper}>
            //                 <div className={styles.headerText}>
            //                     <div className={styles.boldText}>
            //                         Learn the whatifi Basics
            //                     </div>
            //                     <div className={styles.plainText}>
            //                         Take the product tour, explore the Baseline,
            //                         template Scenarios and more!
            //                     </div>
            //                 </div>
            //                 <div
            //                     className={styles.businessCardContainerWrapper}
            //                 >
            //                     {showCardsArrows && (
            //                         <div
            //                             className={
            //                                 styles.businessCardArrowLeftWrapper
            //                             }
            //                             onClick={this.handleClickCardsArrowLeft}
            //                         >
            //                             <img
            //                                 className={
            //                                     styles.businessCardArrowLeft
            //                                 }
            //                                 src={MoveCardsArrow}
            //                                 alt="move left"
            //                             />
            //                         </div>
            //                     )}
            //                     <div className={styles.homeCardFadeIn} />
            //                     <div
            //                         className={styles.businessCardContainer}
            //                         style={cardsTranslate}
            //                     >
            //                         {options.map((option, i) => {
            //                             return (
            //                                 <div
            //                                     key={i}
            //                                     className={
            //                                         i === 3
            //                                             ? styles.businessCardLast
            //                                             : styles.businessCard
            //                                     }
            //                                     onClick={() =>
            //                                         this.handleOnclick(
            //                                             option.optionId
            //                                         )
            //                                     }
            //                                 >
            //                                     <div
            //                                         className={
            //                                             styles.businessCardImageWrapper
            //                                         }
            //                                     >
            //                                         <img
            //                                             alt="alt"
            //                                             className={
            //                                                 styles.businessCardImage
            //                                             }
            //                                             src={option.image}
            //                                         />
            //                                     </div>

            //                                     <div
            //                                         className={
            //                                             styles.businessCardTextHolder
            //                                         }
            //                                     >
            //                                         <div
            //                                             className={
            //                                                 styles.businessCardTitle
            //                                             }
            //                                         >
            //                                             {i + 1}. {option.title}
            //                                         </div>
            //                                         <div
            //                                             className={
            //                                                 i === 3
            //                                                     ? styles.businessCardSubTitleSmall
            //                                                     : styles.businessCardSubTitle
            //                                             }
            //                                         >
            //                                             {option.subTitle}
            //                                         </div>
            //                                     </div>
            //                                 </div>
            //                             );
            //                         })}
            //                     </div>
            //                     <div className={styles.homeCardFadeOut} />
            //                     {showCardsArrows && (
            //                         <div
            //                             className={
            //                                 styles.businessCardArrowRightWrapper
            //                             }
            //                             onClick={
            //                                 this.handleClickCardsArrowRight
            //                             }
            //                         >
            //                             <img
            //                                 className={
            //                                     styles.businessCardArrowRight
            //                                 }
            //                                 src={MoveCardsArrow}
            //                                 alt="move right"
            //                             />
            //                         </div>
            //                     )}
            //                 </div>
            //             </div>
            //         </div>

            //         {!loggedInUser && showSignUpModal && (
            //             <SignUpContainer
            //                 close={this.handleAccountOptionClose}
            //                 history={this.props.history}
            //                 showLogin={showLogin}
            //             />
            //         )}
            //     </div>
            // );
        }
    }
}

import type { MouseEvent } from "react";
// import { useContext } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import {
    resetScenarioViewSelectedThreads,
    selectAllThreads,
} from "actions/scenarioViewSelectedThreadsActions";
// import PreferenceSlider from "Components/PreferenceSlider";
import SortAccountType from "./SortAccountType";

// Image assets
import checkboxOff from "Assets/_budgetIcons/checkbox-off.svg";
import checkboxOn from "Assets/_budgetIcons/checkbox-on.svg";
// import { RatingContext, SetRatingContext } from "..";

export function ThreadsControls() {
    // const rating = useContext(RatingContext);
    // const setRating = useContext(SetRatingContext);

    const {
        allThreadsSelected,
        // onboardingData,
        numThreads,
        selectedThreads,
        zoomedThreadId,
    } = useAppSelector((state) => {
        // const onboardingData = state.scenario?.onboardingData;
        const selectedThreads =
            state.scenarioViewSelectedThreads.selectedThreads;
        const zoomedThreadId = state.threadsList.zoomedThreadId;

        const allThreads = Object.keys(state.calculatedThreads);

        let allThreadsSelected = false;
        let threadsInSelectedThreads = 0;
        allThreads?.forEach((threadId) => {
            if (selectedThreads.includes(threadId)) {
                threadsInSelectedThreads++;
            }
        });
        if (
            allThreads.length > 0 &&
            threadsInSelectedThreads === allThreads.length
        ) {
            allThreadsSelected = true;
        }

        return {
            allThreadsSelected,
            // onboardingData,
            numThreads: allThreads.length,
            selectedThreads,
            zoomedThreadId,
        };
    }, shallowEqual);

    const dispatch = useAppDispatch();

    const onHandleSelectAll = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (allThreadsSelected) {
            dispatch(resetScenarioViewSelectedThreads());
        } else {
            dispatch(selectAllThreads());
        }
    };

    return (
        <div className="Decision-Engine-Container">
            <div className="PreferenceSlider-Title">Decision Engine</div>
            {/* {!zoomedThreadId && (
                <PreferenceSlider
                    value={rating}
                    onChange={setRating}
                    onboardingData={onboardingData}
                />
            )} */}

            <SortAccountType />

            <div
                className={
                    zoomedThreadId ? "threadsLabel-hidden" : "threadsLabel"
                }
            >
                <div className="threadsLabelHolder">
                    <div>Total:</div>
                    <div className="threadsLabelValue">{numThreads}</div>
                    <div className="border" />
                    <div>Selected:</div>
                    <div className="threadsLabelValue">
                        {selectedThreads.length}
                    </div>
                </div>
                <div className="threadsToggleContainer">
                    <div
                        className="ScenarioView__selectAllThreadsButton"
                        onClick={onHandleSelectAll}
                    >
                        <img
                            alt="alt"
                            className="threadsCheckbox"
                            src={allThreadsSelected ? checkboxOn : checkboxOff}
                        />
                        <div>Select all threads</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

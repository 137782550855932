import React, { Component } from "react";
import "./Card.css";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";
import { getRelevantLedgers } from "helpers/ledgers/ledgerDependencies";
import { formatValueWithUnitOld } from "helpers/getUnit";

class groupCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    getGroupMembers = (groupEntity) => {
        const members = [];
        const groupMode = groupEntity.data.groupMode;
        if (groupMode === "events") {
            groupEntity.data.selectedEvents.forEach((id) => {
                const event = getEvent(id);
                if (event) {
                    members.push(event);
                }
            });
        } else if (groupMode === "entities") {
            const entities = getRelevantEntities(
                groupEntity.data.selectedEntities
            );
            Object.values(entities).forEach((entity) => {
                members.push(entity);
            });
        }

        return members;
    };

    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }

        const groupEntity = this.state.allEntities[this.state.currentIndex];
        const groupMembers = this.getGroupMembers(groupEntity);
        const ledgerAccount = getRelevantLedgers([
            groupEntity?.data?.eventType,
        ])?.[0];
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Name:</div>
                    <div className="cardValue">{groupEntity.name}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Event Type:</div>
                    <div className="cardValue">
                        {groupEntity.data.eventType}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Group Type:</div>
                    <div className="cardValue">
                        {groupEntity.data.groupMode === "entities"
                            ? "Records"
                            : "Events"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">
                        {groupEntity.data.groupMode === "entities"
                            ? "Record Name:"
                            : "Event Name:"}
                    </div>
                    {groupEntity.data.groupMode === "entities" && (
                        <div className="ExpenseLabel">Value:</div>
                    )}
                </div>
                {groupMembers?.length > 0 &&
                    groupMembers.map((memberData) => (
                        <div className="Row" key={memberData.id}>
                            <div className="ExpenseLabel">
                                {memberData.name}
                            </div>
                            {groupEntity.data.groupMode === "entities" && (
                                <div className="ExpenseLabel">
                                    {formatValueWithUnitOld(
                                        ledgerAccount.Name,
                                        memberData.data.value
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() => this.handleArrowClick("left")}
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentIndex + 1} of{" "}
                        {this.state.allEntities.length}
                    </span>
                    <div
                        className={
                            this.state.currentIndex ===
                            this.state.allEntities.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() => this.handleArrowClick("right")}
                    ></div>
                </div>
            </div>
        );
    }
}

export default groupCard;

import { useAppSelector } from "store/useAppSelectorDispatch";
import { CanvasBaselineContext } from "../../../../contexts/CanvasBaselineContext";
import { getManager } from "helpers/getManager";
import { useContext } from "react";

/**
 * Gets the event template id and the event template object based on `eventId`
 */
export function useEventTemplate(eventId: string) {
    const _isBaseline = useContext(CanvasBaselineContext);

    const eventTemplateId = useAppSelector(
        (state) => getManager(_isBaseline, state)?.nodes?.[eventId]?.type ?? ""
    );
    /**
     * Never updates in the middle of the app, so do not need any comparison function.
     */
    const eventTemplateObject = useAppSelector(
        (state) => state.eventsTemplates?.[eventTemplateId] ?? {}
    );

    return { eventTemplateId, eventTemplateObject };
}

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import GrowthCard from "../Card/growthCard";

// Import Connection Image
import debitCreditConnectionImage from "../../Assets/_optionDescriptionIcons/Bank, Debit Credit, Debit Credit.png";

// Import Coloured PNGs
import growthDarkGrey from "../../Assets/_eventNodeTypes/growthSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import GrowthInputExperimental from "Components/InputContainer/GrowthInputExperimental/GrowthInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const growthObject = {
    constant: function () {
        return growthObject_Constant();
    },

    name: function () {
        return "Growth";
    },

    checkInput: function (state) {
        return growthObject_CheckInput(state);
    },

    svg: function () {
        return growthObject_Svg();
    },

    eventNodeMenu: function (focus) {
        return growthObject_EventNodeMenu(focus);
    },

    indexText: function () {
        return growthObject_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return growthObject_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return growthObject_OptionDescription();
    },

    colouredPngs: function () {
        return growthObject_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return growthObject_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Growth_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const growthObject_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (!entity.data.selectedEventId) return false;
        if (!entity.data.property) return false;
        if (!entity.cadence) return false;

        if (entity.data.growthType == "constant") {
            if (!entity.data.constantGrowthRate) return false;
        } else if (entity.data.growthType == "dynamic") {
            if (entity.data.growthSegments.length <= 1) return false;

            for (const segment of entity.data.growthSegments) {
                if (!segment.startDate || !segment.period) return false;
                if (!segment.monthlyRate && segment.monthlyRate !== 0)
                    return false;
            }
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const growthObject_Constant = () => {
    return "d426c7c4-69f9-4479-82e8-67f036dadaad";
};

// EFFECTS: Returns svg for event
const growthObject_Svg = () => {
    return growthDarkGrey;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const growthObject_EventNodeMenu = (focus) => {
    return <GrowthCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const growthObject_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const growthObject_Icons = () => {
    let pngs = new Map([
        ["black", growthDarkGrey],
        ["blue", growthDarkGrey],
        ["white", growthDarkGrey],
        ["grey", growthDarkGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const growthObject_InputComponentInfoPassing = (minProps) => {
    return <GrowthInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const growthObject_OptionDescription = () => {
    const tags = ["Option"];
    const type = growthObject.name();
    const description =
        "The Growth Event is a general Event that allows for compound growth on any value for a selected property in an entity. The Growth Event supports multiple growth periods";
    const usedForText =
        "Use this Event to grow a value in an entity over a period of time";
    const connection = "";
    const connectionImage = debitCreditConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const growthObject_SideModalImage = () => {
    return sideImage;
};

const Growth_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

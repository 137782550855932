import "../EventsModal/EventsModal.css";
import "./OverrideModal.css";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { setShowCellRowModal } from "actions/modalActions";
import closeSvg from "../../Assets/close.svg";
import { CellRowData } from "reducers/typesSchema/cellRowModalSchemas";
import { Override } from "./Override";
import { Schedule } from "./Schedule";

export const CellRowModal = () => {
    const data: CellRowData = useAppSelector(
        (state) => state.scenario?.showCellRowModal
    );

    let grid: JSX.Element;
    if (data.type === "override") {
        grid = Override(data);
    } else {
        grid = Schedule(data);
    }

    return (
        <div className="ModalContainer">
            <div className="overrideContainer">
                <img
                    alt="alt"
                    src={closeSvg}
                    className="closeModal"
                    onClick={() => setShowCellRowModal({ show: false })}
                />
                <h2 className="overrideHeader">
                    {data.type === "override"
                        ? "Override Editor"
                        : "Schedule Editor"}
                </h2>
                {grid}
            </div>
        </div>
    );
};

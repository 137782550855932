import React, { Component } from "react";
import "./Card.css";
import { getRelevantEntities } from "actions/getNodeEntityActions";

class MeCard extends Component {
    render() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];
        const { birthMonth, birthYear, state, country, hasDisability } =
            firstEntity.data;
        const { version } = firstEntity;
        return (
            <div className="Container">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="Row">
                        <div className="ExpenseLabel">Country:</div>
                        <div className="cardValue">{country}</div>
                    </div>
                    <div className="Row">
                        <div className="ExpenseLabel">Province:</div>
                        <div className="cardValue">{state}</div>
                    </div>
                    <div className="Row">
                        <div className="ExpenseLabel">Birth Month:</div>
                        <div className="cardValue">{birthMonth}</div>
                    </div>
                    <div className="Row">
                        <div className="ExpenseLabel">Birth Year:</div>
                        <div className="cardValue">{birthYear}</div>
                    </div>
                    <div className="Row">
                        <div className="ExpenseLabel">Has Disability:</div>
                        <div className="cardValue">
                            {hasDisability ? "Yes" : "No"}
                        </div>
                    </div>
                    <div className="Row">
                        <div className="ExpenseLabel">Version:</div>
                        <div className="cardValue">
                            {version ? version : ""}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MeCard;

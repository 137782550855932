import React, { Component } from "react";
import exportSvg from "../../../Assets/exportCSV.svg";

export default class ThreadOptions extends Component {
    state = {
        dropdownContainerClasses: "threadDropdownContainer",
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick, false);
        this.openAnimation();
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    optionsMenu = React.createRef();

    handleClick = (e) => {
        if (this.optionsMenu.contains(e.target)) {
            return;
        } else this.props.onHandleThreadOptions();
    };

    openAnimation = () => {
        let joinedClasses = [
            this.state.dropdownContainerClasses,
            "threadDropdownContainerShow",
        ];
        joinedClasses = joinedClasses.join(" ");

        setTimeout(() => {
            this.setState({ dropdownContainerClasses: joinedClasses });
        }, 1);
    };

    render() {
        let { dropdownContainerClasses } = this.state;

        return (
            <div
                ref={(optionsMenu) => (this.optionsMenu = optionsMenu)}
                className={dropdownContainerClasses}
            >
                <div
                    className="threadOptionsContainer"
                    onClick={this.props.onHandleExport}
                >
                    <img
                        alt="alt"
                        className="threadsCheckbox"
                        src={exportSvg}
                    />
                    <span className="threadOptionText">Export to .CSV</span>
                </div>
            </div>
        );
    }
}

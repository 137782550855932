// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { projectsObject } from "Components/Registry/Projects";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    ModalRowHalf,
    SelectDropDown,
} from "../Components";
import { useState } from "react";

export default function ProjectsInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    onHandleClickSubmitOrUpdate,
    handleOnChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
}) {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    const options = ["Active", "Inactive"];

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={projectsObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={projectsObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[projectsObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                {creatingCustomAccounts ? (
                    <div>Creating custom accounts...</div>
                ) : (
                    <>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap[currentEntity].data.state
                                    }
                                    options={options}
                                    className="select-dropdown"
                                    id="state"
                                    label="Project state"
                                    required={true}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmitValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmit={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                passedCheck={creatingCustomAccounts ? false : passedCheck}
            />
        </EventEntityWrapper>
    );
}

import "./Card.css";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { useMemo, useState } from "react";
import moment from "moment";

export const AccountModifierCard = ({ eventData }) => {
    const [currentIndex, _setCurrentIndex] = useState(
        eventData?.mostRecentEntity ?? 0
    );

    const currentEntityData = useMemo(() => {
        const entityIds = eventData?.entities ?? [];
        const entityDataArray = Object.values(getRelevantEntities(entityIds));
        const currentEntityData = entityDataArray?.[currentIndex];
        return currentEntityData;
    }, [eventData, currentIndex]);

    return (
        <div className="Container">
            <div className="Row">
                <div className="ExpenseLabel">Target Property:</div>
                <div className="cardValue">
                    {currentEntityData?.data?.property}
                </div>
            </div>
            <div className="Row">
                <div className="ExpenseLabel">Modifier Amount:</div>
                <div className="cardValue">
                    {currentEntityData?.data?.value}
                </div>
            </div>
            {currentEntityData?.data?.customEffectPeriod && (
                <div className="Row">
                    <div className="ExpenseLabel">Effect Period:</div>
                    <div className="cardValue">
                        {`${
                            currentEntityData?.startDate
                                ? moment(currentEntityData?.startDate).format(
                                      "MMM D YYYY"
                                  )
                                : "No Start Date"
                        } - ${
                            currentEntityData?.endDate
                                ? moment(currentEntityData?.endDate).format(
                                      "MMM D YYYY"
                                  )
                                : "No End Date"
                        }
                            `}
                    </div>
                </div>
            )}
        </div>
    );
};

import { memo, useContext, useState } from "react";
import {
    EventNodeActiveContext,
    EventNodeInSelectedThreadContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeSelectedContext,
    EventNodeLockedContext,
    EventNodeLinkedContext,
    EventNodeBypassedEntityContext,
    EventNodeConnectableContext,
    HasNoChildrenContext,
    HasCopiedEventContext,
    HasSelectedConnectionContext,
    HasMultipleChildrenContext,
    EventTypeIsDecisionContext,
    EventTypeIsContainerContext,
} from "./contexts/EventNodeStateContexts";
import { EventNodeIDContext } from "./contexts/EventNodeIDContext";
import { EventNodeMain } from "./EventNodeMain";
import { ContainerEventNodeMain } from "./ContainerEventNodeMain";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import type Event from "Events/_event";
import { Portal } from "react-konva-utils";
import { CanvasBaselineContext } from "../../../contexts/CanvasBaselineContext";
import { getManager } from "helpers/getManager";
import { decisionObject } from "Components/Registry/Decision";
import { checkEventForBypassedEntity } from "actions/eventHelpers";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { containerObject } from "Components/Registry/Container";
import { generateCanvasLine } from "./helpers/generateCanvasLine";
import {
    HoveredEventIdContext,
    SetHoveredEventIdContext,
} from "../../../contexts/CanvasHoveredEventContext";

/**
 * Wrapper for EventNodeMain. It serves one purpose and only one purpose: to wrap <EventNodeMain> inside state contexts so all children have easy access to the same states.
 * The developer needn't worry where to get the specific states from, it shall be managed from this wrapper only.
 */
function _CanvasEventWrapper({ eventId }: { eventId: string }) {
    const _isBaseline = useContext(CanvasBaselineContext);
    const [hoveredEventId, setHoveredEventId] = useState("");
    const {
        childrenIds,
        active,
        inSelectedThread,
        focused,
        selected,
        locked,
        linked,
        containsBypassedEntity,
        bypassed,
        error,
        x,
        y,
        hideInCanvas,
        connectable,
        hasNoChildren,
        hasCopiedEvent,
        hasSelectedConnection,
        hasMultipleChildren,
        isTypeDecision,
        isContainer,
    } = useAppSelector((state) => {
        const manager = getManager(_isBaseline, state);
        const event = manager?._findEvent?.(eventId) as Event;
        const childrenIds = event?.children.map((childEvent) => childEvent?.id);
        const active = !!(event?.active ?? false);
        const inSelectedThread = !!(
            state?.scenario?.highlightedThread?.eventsMap?.[eventId] ?? false
        );
        const focused = event?.id === state?.scenario?.focus?.id;
        const selected =
            state?.scenario?.selectedEvents?.[eventId] === "canvas";
        const locked = !!(event?.locked ?? false);
        const bypassed = !!(event?.bypassed ?? false);
        const error = !(event?.valid ?? false);
        const x: number = event?.x?.();
        const y: number = event?.y?.();
        const zoomedThread = !!(state?.threadsList?.zoomedThreadId ?? false);
        const connectionMode = !!state?.scenario?.connectionMode;
        const connectableEvents: {} = state?.scenario?.connectableEvents;
        const connectable = !!(connectionMode && connectableEvents?.[eventId]);
        const hasNoChildren = event?.children?.length === 0;
        const hasCopiedEvent = !!state?.scenario?.copiedEvent;
        const connectionsSelected = state?.scenario?.connectionsSelected;
        const hasSelectedConnection = !!(
            connectionsSelected && connectionsSelected?.length > 0
        );
        const hasMultipleChildren = event?.children?.length > 1;
        const isTypeDecision = event?.type === decisionObject.constant();
        const linked = !!event?.linked ?? false;
        const containsBypassedEntity = checkEventForBypassedEntity(eventId);
        let collapsedInContainer = false;
        if (event?.relevantContainerId) {
            const relevantContainer = manager?._findEvent?.(
                event?.relevantContainerId
            ) as Event;
            if (relevantContainer) {
                const isContainerExpanded =
                    !!Object.values(
                        getRelevantEntities(
                            relevantContainer?.entities ?? []
                        ) ?? {}
                    )?.[0]?.data?.expanded ?? false;
                collapsedInContainer = !isContainerExpanded;
            }
        }
        const hideInCanvas =
            (zoomedThread && !inSelectedThread) || collapsedInContainer;

        const isContainer = event?.type === containerObject?.constant();
        return {
            childrenIds,
            active,
            inSelectedThread,
            focused,
            selected,
            locked,
            linked,
            containsBypassedEntity,
            bypassed,
            error,
            x,
            y,
            hideInCanvas,
            connectable,
            hasNoChildren,
            hasCopiedEvent,
            hasSelectedConnection,
            hasMultipleChildren,
            isTypeDecision,
            isContainer,
        };
    }, shallowEqual);

    if (hideInCanvas) return null;
    return (
        <EventNodeIDContext.Provider value={eventId}>
            <EventNodeActiveContext.Provider value={active}>
                <EventNodeInSelectedThreadContext.Provider
                    value={inSelectedThread}
                >
                    <EventNodeFocusedContext.Provider value={focused}>
                        <HoveredEventIdContext.Provider value={hoveredEventId}>
                            <SetHoveredEventIdContext.Provider
                                value={setHoveredEventId}
                            >
                                <EventNodeSelectedContext.Provider
                                    value={selected}
                                >
                                    <EventNodeLockedContext.Provider
                                        value={locked}
                                    >
                                        <EventNodeLinkedContext.Provider
                                            value={linked}
                                        >
                                            <EventNodeBypassedEntityContext.Provider
                                                value={containsBypassedEntity}
                                            >
                                                <EventNodeBypassedContext.Provider
                                                    value={bypassed}
                                                >
                                                    <EventNodeErrorContext.Provider
                                                        value={error}
                                                    >
                                                        <EventNodeConnectableContext.Provider
                                                            value={connectable}
                                                        >
                                                            <HasNoChildrenContext.Provider
                                                                value={
                                                                    hasNoChildren
                                                                }
                                                            >
                                                                <HasCopiedEventContext.Provider
                                                                    value={
                                                                        hasCopiedEvent
                                                                    }
                                                                >
                                                                    <HasSelectedConnectionContext.Provider
                                                                        value={
                                                                            hasSelectedConnection
                                                                        }
                                                                    >
                                                                        <HasMultipleChildrenContext.Provider
                                                                            value={
                                                                                hasMultipleChildren
                                                                            }
                                                                        >
                                                                            <EventTypeIsDecisionContext.Provider
                                                                                value={
                                                                                    isTypeDecision
                                                                                }
                                                                            >
                                                                                <EventTypeIsContainerContext.Provider
                                                                                    value={
                                                                                        isContainer
                                                                                    }
                                                                                >
                                                                                    <Portal
                                                                                        selector=".top-layer"
                                                                                        enabled={
                                                                                            focused ||
                                                                                            hoveredEventId ===
                                                                                                eventId
                                                                                        }
                                                                                    >
                                                                                        {isContainer ? (
                                                                                            <ContainerEventNodeMain
                                                                                                x={
                                                                                                    x
                                                                                                }
                                                                                                y={
                                                                                                    y
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            <EventNodeMain
                                                                                                x={
                                                                                                    x
                                                                                                }
                                                                                                y={
                                                                                                    y
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </Portal>
                                                                                    <Portal
                                                                                        selector=".bottom-layer"
                                                                                        enabled={
                                                                                            true
                                                                                        }
                                                                                    >
                                                                                        {!hideInCanvas &&
                                                                                            childrenIds?.length >
                                                                                                0 &&
                                                                                            childrenIds?.map(
                                                                                                (
                                                                                                    childId
                                                                                                ) =>
                                                                                                    generateCanvasLine(
                                                                                                        eventId,
                                                                                                        childId
                                                                                                    )
                                                                                            )}
                                                                                    </Portal>
                                                                                </EventTypeIsContainerContext.Provider>
                                                                            </EventTypeIsDecisionContext.Provider>
                                                                        </HasMultipleChildrenContext.Provider>
                                                                    </HasSelectedConnectionContext.Provider>
                                                                </HasCopiedEventContext.Provider>
                                                            </HasNoChildrenContext.Provider>
                                                        </EventNodeConnectableContext.Provider>
                                                    </EventNodeErrorContext.Provider>
                                                </EventNodeBypassedContext.Provider>
                                            </EventNodeBypassedEntityContext.Provider>
                                        </EventNodeLinkedContext.Provider>
                                    </EventNodeLockedContext.Provider>
                                </EventNodeSelectedContext.Provider>
                            </SetHoveredEventIdContext.Provider>
                        </HoveredEventIdContext.Provider>
                    </EventNodeFocusedContext.Provider>
                </EventNodeInSelectedThreadContext.Provider>
            </EventNodeActiveContext.Provider>
        </EventNodeIDContext.Provider>
    );
}

export const CanvasEventWrapper = memo(_CanvasEventWrapper);

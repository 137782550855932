import { GraphAccountSelectionSchema } from "reducers/typesSchema/graphAccountSelectionSchema";

export const UPSERT_GRAPH_ACCOUNT_SELECTION = "UPSERT_GRAPH_ACCOUNT_SELECTION";

export const upsertGraphAccountSelection = (
    payload: Partial<GraphAccountSelectionSchema>
) => ({
    type: UPSERT_GRAPH_ACCOUNT_SELECTION,
    payload,
});

import type { AllEventDetailsSchema } from "./typesSchema/eventDetailsSchema";
import { UPSERT_EVENT_DETAILS } from "actions/eventDetailsActions";

const initialState: AllEventDetailsSchema = {};

export default function eventDetails(
    state: AllEventDetailsSchema = initialState,
    action: { type: string; payload?: any }
): AllEventDetailsSchema {
    switch (action.type) {
        case UPSERT_EVENT_DETAILS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { getRelevantEntities } from "actions/getNodeEntityActions";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const customerInputsHandler = (
    value: string,
    id: "entityName" | "customer" | "startDate" | "customerSelect",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "customer":
            data.customer = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const loanStartDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.startDate = loanStartDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "customerSelect":
            const parsedCustomer = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedCustomer,
                eventId,
                currentEntityObject.id,
                data.customerEventId,
                data.customerIds
            );

            data.selectedCustomer = parsedCustomer;
            data.customerEventId = parsedCustomer?.eventId ?? "";
            data.customerIds = parsedCustomer?.entityIds ?? [];

            const customerEntity = Object.values(
                getRelevantEntities(parsedCustomer?.entityIds)
            )[0];
            data.accountId = customerEntity.data.parentAccountId;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
    }

    return newEntitiesMap;
};

// Default Imports
import React from "react";

// Import Expression Card
import ExpressionCard from "Components/Card/expressionCard";

// Import Connection Image
import expressionConnectionImage from "Assets/_optionDescriptionIcons/Expression white.jpg";

// Import Coloured PNGs
import expressionBlack from "../../Assets/_eventNodeTypes/expressionSvg.svg";
import expressionBlue from "../../Assets/_eventNodeTypes/expressionSvg.svg";
import expressionWhite from "../../Assets/_eventNodeTypes/expressionSvg.svg";
import expressionGrey from "../../Assets/_eventNodeTypes/expressionSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import ExpressionInputExperimental from "Components/InputContainer/ExpressionInputExperimental/ExpressionInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - expressionObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - expressionObject.checkInput.call(expressionObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for expression
export const expressionObject = {
    constant: function () {
        return expression_Constant();
    },

    name: function () {
        return "Expression";
    },

    checkInput: function (state) {
        return expression_CheckInput(state);
    },

    svg: function () {
        return expression_Svg();
    },

    eventNodeMenu: function (focus) {
        return expression_EventNodeMenu(focus);
    },

    indexText: function () {
        return expression_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return expression_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return expression_OptionDescription();
    },

    colouredPngs: function () {
        return expression_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return expression_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Expression_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Expression

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const expression_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.cadence) return false;
        if (!entity.startDate) return false;
        if (!entity.name) return false;
        if (!entity.data.selectedEvent) return false;
        if (!entity.data.selectedEntity) return false;
        if (!entity.data.expression) return false;
        if (!(entity.data.value != null)) return false;
        if (!entity.data.accountName) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const expression_Constant = () => {
    return "ace1c1e0-4d70-4901-a95e-72ba41992e3a";
};

// EFFECTS: Returns svg for event
const expression_Svg = () => {
    return expressionBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const expression_EventNodeMenu = (focus) => {
    return <ExpressionCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const expression_IndexText = () => {
    return "Select an input field to learn more about your debit credit calculations.";
};

// EFFECTS: Returns all icons for event ??
const expression_Icons = () => {
    let pngs = new Map([
        ["black", expressionBlack],
        ["blue", expressionBlue],
        ["white", expressionWhite],
        ["grey", expressionGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const expression_InputComponentInfoPassing = (minProps) => {
    return <ExpressionInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const expression_OptionDescription = () => {
    const tags = ["Option"];
    const type = expressionObject.name();
    const description =
        "The Expression Event is a foundational Event that can take in numerical values from other events and utilise them to build custom mathematical expressions.";
    const usedForText =
        "Use this Event to construct custom mathematical expressions with numerical data from any upstream event.";
    const connection = "";
    const connectionImage = expressionConnectionImage;
    const learnMoreLink = "";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const expression_SideModalImage = () => {
    return sideImage;
};

const Expression_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

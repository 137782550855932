import React from "react";

import RevenueCard from "../Card/RevenueCard";

import RevenueConnection from "Assets/_optionDescriptionIcons/Unit Cost, Revenue white .jpg";

import revenueBlack from "../../Assets/_eventNodeTypes/revenue-black-updated.svg";
import revenueBlue from "../../Assets/_eventNodeTypes/revenue-blue.png";
import revenueWhite from "../../Assets/_eventNodeTypes/revenue-white.png";
import revenueGrey from "../../Assets/_eventNodeTypes/revenue-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import RevenueInputExperimental from "Components/InputContainer/RevenueInputExperimental/RevenueInputExperimental";

export const revenueObject = {
    constant: function () {
        return Revenue_Constant();
    },

    name: function () {
        return "Revenue";
    },

    checkInput: function (state) {
        return Revenue_CheckInput(state);
    },

    svg: function () {
        return Revenue_Svg();
    },

    eventNodeMenu: function (focus) {
        return Revenue_EventNodeMenu(focus);
    },

    indexText: function () {
        return Revenue_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Revenue_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Revenue_OptionDescription();
    },

    colouredPngs: function () {
        return Revenue_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Revenue_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Revenue_GenerateDisplayFieldData();
    },
};

// Helper functions for Event

const Revenue_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        return (
            entity.name &&
            entity.data.unitCostEventId &&
            (entity.data.customerEventId || entity.data.segmentEventId)
        );
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

const Revenue_Constant = () => {
    return "88b6721e-308b-4870-bbff-23bd9c6f979b";
};

const Revenue_Svg = () => {
    return revenueBlack;
};

const Revenue_EventNodeMenu = (focus) => {
    return <RevenueCard data={focus} />;
};

const Revenue_IndexText = () => {
    return "The Revenue Event takes in an upstream Customer Event and Unit Cost event, and produces cashflow based on the information.";
};

const Revenue_Icons = () => {
    let pngs = new Map([
        ["black", revenueBlack],
        ["blue", revenueBlue],
        ["white", revenueWhite],
        ["grey", revenueGrey],
    ]);
    return pngs;
};

// const Revenue_FrontEndExports = (node) => {
//     return undefined;
// };

const Revenue_InputComponentInfoPassing = (minProps) => {
    return <RevenueInputExperimental {...minProps} />;
};

const Revenue_OptionDescription = () => {
    const tags = ["Option"];
    const type = revenueObject.name();
    const description =
        "There are many ways to calculate Revenue. This Revenue Event best supports products and services with a fixed price and multiplies that by the number of Users or Customers in a given month. Other Revenue generating Events include the Contract, Income and Debit/Credit Events.";
    const usedForText =
        "Use the Revenue Event to model SaaS, E-commerce and traditional product sales. It requires an upstream Unit Cost Event and a Customer Event to calculate.";
    const connection = "";
    const connectionImage = RevenueConnection;
    const learnMoreLink =
        "https://www.investopedia.com/terms/r/revenue.asp#:~:text=Revenue%20is%20the%20income%20generated,subtracted%20to%20determine%20net%20income.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Revenue_SideModalImage = () => {
    return sideImage;
};

const Revenue_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

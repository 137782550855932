// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import OngoingExpensePanel from "Components/OngoingExpensePanel";
import AmortizedExpensePanel from "Components/AmortizedExpensePanel";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    InflationSwitch,
    EntityName,
    ToggledContainer,
    ToggledContainerHalf,
} from "../Components";
import { unitInputsHandler } from "../OnInputChangeHandlers/unitInputsHandler";
import { unitObject } from "Components/Registry/Unit";

export default function UnitInputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    handleChangeIncreaseDecrease,
    handleChangeAmortizedOngoing,
    handleDateSelection,
    updateInflation,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    isOverrideValid,
    checkIfFieldIsOverridden,
    handleEntityStateChange,
    ledgerAccount,
    createOverride,
    getOverride,
    updateOverride,
}) {
    const isOngoing =
        entitiesMap?.[currentEntity]?.data?.ongoingOrAmortized === "ongoing";

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const star = 0;
        const newEntitiesMap = unitInputsHandler(
            "",
            id,
            star,
            entitiesMap,
            currentEntity,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={unitObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={unitObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[unitObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="increase"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data
                                ?.increaseOrDecrease === "increase"
                        }
                        onClick={handleChangeIncreaseDecrease}
                        title="Increase"
                    />
                    <ToggledContainerHalf
                        id="decrease"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data
                                ?.increaseOrDecrease === "decrease"
                        }
                        onClick={handleChangeIncreaseDecrease}
                        title="Decrease"
                    />
                </ToggledContainer>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="ongoing"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data
                                ?.ongoingOrAmortized === "ongoing"
                        }
                        onClick={handleChangeAmortizedOngoing}
                        title="Ongoing Transaction"
                    />
                    <ToggledContainerHalf
                        id="amortized"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data
                                ?.ongoingOrAmortized === "amortized"
                        }
                        onClick={handleChangeAmortizedOngoing}
                        title="Amortized Transaction"
                    />
                </ToggledContainer>

                {isOngoing ? (
                    <OngoingExpensePanel
                        entitiesMap={entitiesMap}
                        currentEntity={currentEntity}
                        handleOnChange={handleOnChange}
                        handleDateSelection={handleDateSelection}
                        isOverrideValid={isOverrideValid}
                        checkIfFieldIsOverridden={checkIfFieldIsOverridden}
                        ledgerAccount={ledgerAccount}
                        createOverride={createOverride}
                        getOverride={getOverride}
                        updateOverride={updateOverride}
                        unitless={true}
                        value={entitiesMap?.[currentEntity]?.data?.value}
                    />
                ) : (
                    <AmortizedExpensePanel
                        entitiesMap={entitiesMap}
                        currentEntity={currentEntity}
                        handleOnChange={handleOnChange}
                        handleDateSelection={handleDateSelection}
                        unitless={true}
                        increase={
                            entitiesMap?.[currentEntity]?.data
                                ?.increaseOrDecrease == "increase"
                        }
                        amortizedValue={
                            entitiesMap?.[currentEntity]?.data.value
                        }
                        numPayments={
                            entitiesMap?.[currentEntity]?.data.numPayments
                        }
                        amortizationPeriod={
                            entitiesMap?.[currentEntity]?.data
                                .amortizationPeriod
                        }
                        cadence={entitiesMap?.[currentEntity]?.cadence}
                        startDate={entitiesMap?.[currentEntity].startDate}
                        endDate={entitiesMap?.[currentEntity].endDate}
                    />
                )}

                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={
                            entitiesMap?.[currentEntity]?.data?.accountName ??
                            ""
                        }
                        required={true}
                    />
                </ModalRow>
                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={
                            entitiesMap?.[currentEntity]?.data
                                ?.contraAccountName
                        }
                        contraAccount={true}
                        // this is the top level account which is at position 0
                        accountSelectedForContra={
                            entitiesMap?.[currentEntity]?.data.accountIds.length
                                ? entitiesMap?.[currentEntity]?.data
                                      .accountIds[0]
                                : ""
                        }
                    />
                </ModalRow>
                <ModalRow>
                    <InflationSwitch
                        applyInflation={
                            entitiesMap?.[currentEntity]?.data?.inflation
                        }
                        toggleInflation={updateInflation}
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import React, { Component } from "react";
import "./InputContainer.css";
import radioButtonOff from "../../Assets/_nodeInputs/radioButtonOff.svg";
import radioButtonOn from "../../Assets/_nodeInputs/radioButtonOn.svg";
import _ from "lodash";
import { throwError } from "../../helpers/swalError";
import {
    InputButtons,
    SelectDropDown,
    Rate,
    Description,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRowHalf,
    ModalRow,
    ModalRowTwoThirds,
    ModalRowOneThird,
    RequiredStar,
    ToggledContainer,
    ToggledContainerHalf,
    SelectEventsDropdown,
    Value,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { InputErrors } from "../../helpers/constants";
import { terms, getMortgageRate } from "../../helpers/mortgageHelpers";
import SingleCalendar from "../Calendar/singleCalendar";
import {
    AutoStart,
    CustomDate,
    DefaultSetting,
    ExtraAmortizationPeriods,
    ExtraCadenceOptions,
    StartDateOptions,
} from "../../helpers/refinanceRenewalConstants";
import { getDependencyCandidates } from "../../helpers/nodeDependencyDetectionHelpers";
import { mortgageObject } from "Components/Registry/Mortgage";
import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";

class RenewalRefinanceInput extends Component {
    constructor(props) {
        super(props);

        const dependencies = getDependencyCandidates(
            props,
            [mortgageObject.constant()],
            null,
            false
        );
        const mortgages =
            dependencies &&
            dependencies[mortgageObject.constant()] &&
            dependencies[mortgageObject.constant()].map((mortgage) => {
                return { id: mortgage.id, metadata: mortgage.metadata };
            });
        const isValid = mortgages && mortgages.length > 0;
        if (!isValid)
            throwError(
                "warning",
                "No Mortgage Events Found Upstream or in Baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );

        const defaultState = RenewalRefinanceInput.getDefaultState(
            props.inflationRate,
            getDefaultName(
                renewalRefinanceObject.constant(),
                props,
                props.manager
            ),
            props.baseline
        );

        this.state = {
            ...defaultState,
            ...(props.edit ? props.editData : {}),
            mortgages,
            isValid,
            baseline: props.baseline,
        };
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            name,
            inflationRate,
            baseline: isInBaseline,
            value: "",
            tag: `@${renewalRefinanceObject.constant()}`,
            type: renewalRefinanceObject.constant(),
            rating: 0,
            inflation: false,
            isAuto: true,
            cadence: DefaultSetting,
            term: "",
            rate: "",
            start: null,
            amortization: "",
            description: "",
            "amortization period": "",
            startOption: AutoStart,
            "mortgage term": "",
            mortgage: null,
            rateManuallyChanged: false,
            selectedForm: "renewal",
            isValid: false,
            mortgages: {},
            refinanceType: "originalValue",
            withdrawalType: "percentEquity",
            equityWithdrawn: null,
        };
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ rating: star });
                break;
            case "mortgage term":
                this.setState({ term: parseInt(value) });
                this.setState({ "mortgage term": value });
                if (!this.state.rateManuallyChanged) {
                    this.setState({ rate: getMortgageRate(value, this.state) });
                }
                break;
            case "cadence":
                this.setState({ cadence: value });
                break;
            case "rate":
                this.setState({ rate: value });
                if (!this.state.rateManuallyChanged) {
                    this.setState({ rateManuallyChanged: true });
                }
                break;
            case "amortization period":
                this.setState({
                    "amortization period": value,
                    amortization: parseInt(value),
                });
                break;
            case "startOption":
                this.setState({ startOption: value });
                break;
            case "mortgage":
                this.setState({ mortgage: value });
                this.scrollToBottom();
                break;
            case "valueWithdrawn":
                this.setState({ valueWithdrawn: value });
                break;
            case "refinancingFees":
                this.setState({ refinancingFees: value });
                break;
            case "equityWithdrawn":
                this.setState({ equityWithdrawn: value });
                break;
            case "customValue":
                this.setState({ customValue: value });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = renewalRefinanceObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    scrollToBottom = () => {
        setTimeout(() => {
            this.inputEnd.scrollIntoView({
                behavior: "smooth",
            });
        });
    };

    onHandleSubmit = () => {
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        const { baseline, confirmBaselineAction } = this.props;

        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleDate = (date) => {
        this.setState({ start: date });
    };

    selectForm = (e) => {
        const id = e.target.id;
        if (id !== this.state.selectedForm) {
            if (id === "renewal") {
                this.setState({
                    startOption: AutoStart,
                    selectedForm: id,
                });
            } else {
                this.setState({ selectedForm: id });
            }
        }
    };

    onHandleRadioButton = (e) => {
        const value = e.target.id;
        if (value !== this.state.refinanceType) {
            this.setState({ refinanceType: value });
        }
    };

    onHandleChangeWithdrawal = (e) => {
        const value = e.target.id;
        if (value !== this.state.refinanceType) {
            this.setState({ withdrawalType: value });
        }
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "":
                data = {
                    title: "",
                    content: "",
                    subContent: "",
                    link: "",
                };
                break;
            case "valueWithdrawn":
                data = {
                    title: "Percentage of Available Equity to Withdraw",
                    content:
                        "When refinancing a property, you may choose to cash-out up to 80% of your equity in the holding. When withdrawing value in this fashion, your remaining equity in the property cannot drop below 20%.",
                    subContent:
                        'Your "Available Equity" is then your current equity in the property, minus 20% of the value of the estate.',
                    link: "https://www.cibc.com/en/personal-banking/mortgages/resource-centre/mortgage-refinancing.html",
                };
                break;
            case "refinancingFees":
                data = {
                    title: "Refinancing Costs",
                    content:
                        "There may be costs associated with refinancing your property, including appraisal costs, legal fees, and - if you change lenders - discharge fees.",
                    subContent: "",
                    link: "",
                };
                break;
            case "refinanceValue":
                data = {
                    title: "Property Value",
                    content:
                        'Select "Original Value" if you wish to renew your mortgage at the original principal, or "Appreciated Value" to increase your loan to the value of the property at the time of renewal - allowing you to tap into the increased value for debt consolidation or reinvestment.',
                    subContent: "",
                    link: "",
                };
                break;
            case "startOption":
                data = {
                    title: "Renewal Date",
                    content:
                        "You may choose to renew the mortgage at the natural end of its latest term, or select date of your own choosing - potentially breaking your mortgage contract.",
                    subContent: "",
                    link: "",
                };
                break;
            case "amortization":
            case "frequency":
            case "rate":
            case "term":
            case "startDate":
            case "mortgage":
            case "refinance":
            case "renewal":
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    render() {
        const passedCheck = renewalRefinanceObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            name,
            description,
            cadence,
            rate,
            start,
            startOption,
            mortgages,
            mortgage,
            selectedForm,
            refinanceType,
            valueWithdrawn,
            refinancingFees,
            withdrawalType,
            equityWithdrawn,
            customValue,
        } = this.state;

        const mortgageData =
            mortgages &&
            mortgage &&
            mortgages.find((candidate) => candidate.id === mortgage);
        const mortgageStart =
            mortgageData && mortgageData.metadata["first payment"];

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={"Renewal/Refinance"}
                        onChangeInput={this.onChangeInput}
                        image={renewalRefinanceObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ToggledContainer>
                        <ToggledContainerHalf
                            id="renewal"
                            title="Renewal"
                            onClick={this.selectForm}
                            isSelected={selectedForm === "renewal"}
                            onMouseEnter={this.onHandleMouseEnter}
                        />
                        <ToggledContainerHalf
                            id="refinance"
                            title="Refinance"
                            onClick={this.selectForm}
                            isSelected={selectedForm === "refinance"}
                            onMouseEnter={this.onHandleMouseEnter}
                        />
                    </ToggledContainer>
                    <ModalRow
                        id="mortgage"
                        onMouseEnter={this.onHandleMouseEnter}
                    >
                        <RequiredStar />
                        <SelectEventsDropdown
                            events={mortgages}
                            id="mortgage"
                            onChangeInput={this.onChangeInput}
                            selectedId={mortgage}
                            label="Choose Mortgage"
                        />
                    </ModalRow>
                    {startOption !== CustomDate && (
                        <ModalRow
                            id="startOption"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={startOption}
                                options={StartDateOptions}
                                className="termsContainer"
                                id="startOption"
                                label="Start"
                                disabled={selectedForm === "renewal"}
                                hideNullOption
                            />
                        </ModalRow>
                    )}
                    {startOption === CustomDate && (
                        <ModalRow twoInputs>
                            <ModalRowOneThird
                                id="startOption"
                                onMouseEnter={this.onHandleMouseEnter}
                            >
                                <RequiredStar />
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={startOption}
                                    options={StartDateOptions}
                                    className="termsContainer"
                                    id="startOption"
                                    label="Start"
                                    disabled={selectedForm === "renewal"}
                                    hideNullOption
                                />
                            </ModalRowOneThird>
                            <ModalRowTwoThirds
                                onMouseEnter={this.onHandleMouseEnter}
                                id="startDate"
                            >
                                <ModalRow>
                                    <RequiredStar />
                                    <SingleCalendar
                                        onHandleDate={this.onHandleDate}
                                        date={start}
                                        title="Start Date"
                                        useFullWidth
                                        minDate={mortgageStart}
                                    />
                                </ModalRow>
                            </ModalRowTwoThirds>
                        </ModalRow>
                    )}
                    <ModalRow twoInputs>
                        <ModalRowTwoThirds
                            id="term"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={this.state["mortgage term"]}
                                options={terms}
                                className="termsContainer"
                                id="mortgage term"
                                label="Term"
                            />
                        </ModalRowTwoThirds>
                        <ModalRowOneThird
                            id="rate"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <Rate
                                value={rate}
                                label="Rate"
                                className="mlsInput"
                                id="rate"
                                onChangeInput={this.onChangeInput}
                                sign={true}
                                error={rate < 0 || rate > 100}
                                helperText={
                                    rate < 0
                                        ? InputErrors.NumberBelowZero
                                        : rate > 100
                                        ? InputErrors.NumberAboveLimit + "100"
                                        : ""
                                }
                            />
                        </ModalRowOneThird>
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf
                            id="frequency"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={cadence}
                                options={ExtraCadenceOptions}
                                className="select-dropdown"
                                id="cadence"
                                label="Payment Frequency"
                            />
                        </ModalRowHalf>
                        <ModalRowHalf
                            id="amortization"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={this.state["amortization period"]}
                                options={ExtraAmortizationPeriods}
                                className="select-dropdown"
                                id="amortization period"
                                label="Amortization Period"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    {selectedForm === "refinance" && (
                        <ModalRow
                            id="refinanceValue"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <ModalRowOneThird>
                                <RequiredStar />
                                <div className="PTTLabel">
                                    Refinance Property at
                                </div>
                            </ModalRowOneThird>
                            <ModalRowTwoThirds>
                                <img
                                    alt="toggle original value on"
                                    onClick={this.onHandleRadioButton}
                                    id="originalValue"
                                    src={
                                        refinanceType === "originalValue"
                                            ? radioButtonOn
                                            : radioButtonOff
                                    }
                                    className="pttRadioButton"
                                />
                                <div className="PTTValueLabel">
                                    Original Value
                                </div>
                                <img
                                    alt="toggle adjusted value on"
                                    onClick={this.onHandleRadioButton}
                                    id="appreciatedValue"
                                    src={
                                        refinanceType === "appreciatedValue"
                                            ? radioButtonOn
                                            : radioButtonOff
                                    }
                                    className="pttRadioButton"
                                />
                                <div className="PTTValueLabel">
                                    Appreciated Value
                                </div>
                                <img
                                    alt="toggle adjusted value on"
                                    onClick={this.onHandleRadioButton}
                                    id="customValue"
                                    src={
                                        refinanceType === "customValue"
                                            ? radioButtonOn
                                            : radioButtonOff
                                    }
                                    className="pttRadioButton"
                                />
                                <div className="PTTValueLabel">
                                    Custom Value
                                </div>
                            </ModalRowTwoThirds>
                        </ModalRow>
                    )}

                    {selectedForm === "refinance" &&
                        refinanceType === "customValue" && (
                            <ModalRow>
                                <Value
                                    label="New Mortgage Value"
                                    onChangeInput={this.onChangeInput}
                                    id="customValue"
                                    value={customValue}
                                    error={customValue < 0 || !customValue}
                                    helperText={
                                        customValue < 0
                                            ? InputErrors.NumberBelowZero
                                            : customValue === 0
                                            ? InputErrors.NotZero
                                            : !customValue
                                            ? InputErrors.NotEmpty
                                            : ""
                                    }
                                />
                            </ModalRow>
                        )}
                    {selectedForm === "refinance" && (
                        <ModalRow
                            id="withdrawalType"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <ModalRowOneThird>
                                <RequiredStar />
                                <div className="PTTLabel">
                                    Withdraw Equity as
                                </div>
                            </ModalRowOneThird>
                            <ModalRowTwoThirds>
                                <img
                                    alt="select percent withdrawl"
                                    onClick={this.onHandleChangeWithdrawal}
                                    id="percentEquity"
                                    src={
                                        withdrawalType === "percentEquity"
                                            ? radioButtonOn
                                            : radioButtonOff
                                    }
                                    className="pttRadioButton"
                                />
                                <div className="PTTValueLabel">Percentage</div>
                                <img
                                    alt="select amount withdrawl"
                                    onClick={this.onHandleChangeWithdrawal}
                                    id="amountEquity"
                                    src={
                                        withdrawalType === "amountEquity"
                                            ? radioButtonOn
                                            : radioButtonOff
                                    }
                                    className="pttRadioButton"
                                />
                                <div className="PTTValueLabel">
                                    Fixed Amount
                                </div>
                            </ModalRowTwoThirds>
                        </ModalRow>
                    )}
                    {selectedForm === "refinance" && (
                        <ModalRow twoInputs>
                            <ModalRowHalf
                                id="refinancingFees"
                                onMouseEnter={this.onHandleMouseEnter}
                            >
                                <RequiredStar />
                                <Value
                                    label="Refinancing Costs"
                                    onChangeInput={this.onChangeInput}
                                    id="refinancingFees"
                                    value={refinancingFees}
                                    error={refinancingFees < 0}
                                    helperText={
                                        refinancingFees < 0
                                            ? InputErrors.NumberBelowZero
                                            : ""
                                    }
                                />
                            </ModalRowHalf>
                            <ModalRowHalf
                                id="valueWithdrawn"
                                onMouseEnter={this.onHandleMouseEnter}
                            >
                                <RequiredStar />
                                {withdrawalType === "amountEquity" ? (
                                    <Value
                                        label="Equity to Withdraw"
                                        onChangeInput={this.onChangeInput}
                                        id="equityWithdrawn"
                                        value={equityWithdrawn}
                                        error={equityWithdrawn < 0}
                                        helperText={
                                            equityWithdrawn < 0 &&
                                            InputErrors.NumberBelowZero
                                        }
                                    />
                                ) : (
                                    <Rate
                                        label="Percentage of Available Equity to Withdraw"
                                        useFullWidth={true}
                                        sign={true}
                                        id="valueWithdrawn"
                                        value={valueWithdrawn}
                                        onChangeInput={this.onChangeInput}
                                        error={
                                            valueWithdrawn < 0 ||
                                            valueWithdrawn > 100
                                        }
                                        helperText={
                                            valueWithdrawn < 0
                                                ? InputErrors.NumberBelowZero
                                                : valueWithdrawn > 100
                                                ? InputErrors.NumberAboveLimit +
                                                  "100"
                                                : ""
                                        }
                                    />
                                )}
                            </ModalRowHalf>
                        </ModalRow>
                    )}

                    {/* dummy div to enable autoscroll when adding extra fields */}
                    <div
                        ref={(el) => {
                            this.inputEnd = el;
                        }}
                    />
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {  },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default RenewalRefinanceInput;

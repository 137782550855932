import { Component, memo } from "react";
import "./NodeGraph.css";
import GraphLoading from "./partials/GraphLoading";
import GraphTopBar from "./partials/GraphTopBar";
import GraphLineController from "./partials/GraphLine/GraphLineController";
import { appMapState } from "store/appMapStateDispatch";
import { connect } from "react-redux";

interface ChartGraphProps {
    isCalculatedThreadsLoading: boolean;
    nodeGraphState: string;
    setNodeGraphState: Function;
    showRightDisplay: boolean;
}

class ChartGraph extends Component<ChartGraphProps> {
    expandNodeGraph = () => {
        // NOTE to Daniel: Make this into a pure function. setNodeGraphState can either be a local state or redux state. Redux preferred because I'm not sure why setNodeGraphState is being passed from parent atm.
        if (
            this.props.nodeGraphState === "normal-wide" &&
            !this.props.showRightDisplay
        )
            this.props.setNodeGraphState("expanded-wide");
        if (
            this.props.nodeGraphState === "normal" &&
            this.props.showRightDisplay
        )
            this.props.setNodeGraphState("expanded");

        if (
            this.props.nodeGraphState === "expanded-wide" &&
            !this.props.showRightDisplay
        )
            this.props.setNodeGraphState("normal-wide");

        if (
            this.props.nodeGraphState === "expanded" &&
            this.props.showRightDisplay
        )
            this.props.setNodeGraphState("normal");
    };

    render = () => {
        return (
            <div className="NodeGraphMain">
                <div className="Whatifi-Graph">
                    {this.props.isCalculatedThreadsLoading ? (
                        <GraphLoading
                            isLoading={this.props.isCalculatedThreadsLoading}
                        />
                    ) : (
                        <>
                            <GraphTopBar />
                            <GraphLineController />
                        </>
                    )}
                </div>
            </div>
        );
    };
}

const mapStateToProps = appMapState((state) => ({
    isCalculatedThreadsLoading: state.loadingStates.isCalculatedThreadsLoading,
}));

const ChartGraphConnected = connect(mapStateToProps)(ChartGraph);

// NOTE: Remove memo after App has been cleaned up. We memoize currently because MainContainer is forcing rerender of ENTIRE app unnecessarily.
export default memo(ChartGraphConnected);

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { getEvent } from "actions/getNodeEntityActions";

/*
 * Helper function that handles Goal Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const containerInputsHandler = (
    value: string,
    id: "containedEvents" | "headNode" | "tailNode" | "expanded",
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "containedEvents":
            data.containedEvents = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "headNode":
            data.headNode = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "tailNode":
            data.tailNode = value;
            data.containedEvents = getAllContainedEvents(
                data.headNode,
                data.tailNode
            );
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "expanded":
            data.expanded = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

const getAllContainedEvents = (headNodeId, tailNodeId) => {
    if (!headNodeId || !tailNodeId) return [];
    let currentEvent: any = getEvent(headNodeId);
    const allContainedEvents = {};
    while (
        currentEvent?.id !== tailNodeId &&
        currentEvent?.children &&
        currentEvent?.children?.length > 0
    ) {
        allContainedEvents[currentEvent?.id] = currentEvent?.exportData();
        currentEvent = currentEvent?.children?.[0];
    }
    const tailNode: any = getEvent(tailNodeId);
    allContainedEvents[tailNodeId] = tailNode?.exportData();

    return allContainedEvents;
};

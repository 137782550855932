import type { EventManager, BaselineDataManager } from "Events";
/**
 * Receives the flag isBaseline as well as an instance of the redux state and returns the necessary manager.
 * getManager was created so that functionality shared by the baseline and scenario canvas will always receive the correct manager.
 */
export function getManager(isBaseline: boolean, state) {
    if (isBaseline) {
        const baselineManger = state?.scenario
            ?.baselineDataManager as BaselineDataManager;
        return baselineManger;
    } else {
        const manager = state?.scenario?.manager as EventManager;
        return manager;
    }
}

import axios from "axios";
import { config } from "config";
import { Ledgers } from "reducers/typesSchema/allAccountLedgersSchema";
import { requestHeader } from "./scenario";
import AllAccountLedgers from "../helpers/ledgers/accountsAndLedgers.json";

export function updateAccountsAPI(newLedgers: Ledgers) {
    const ledgersCopy = { ...newLedgers };
    Object.values(ledgersCopy).forEach((ledger) => {
        if (ledger.type === "shared") {
            if (ledger.parents.length > 0) {
                ledger.parents.forEach((parentUUID) => {
                    if (ledgersCopy[parentUUID]) {
                        ledgersCopy[parentUUID].children = ledgersCopy[
                            parentUUID
                        ].children.filter((uuid) => {
                            return uuid !== ledger.id;
                        });
                    }
                });
            }
            delete ledgersCopy[ledger.id];
        }
    });
    return axios.put(
        `${config.host}/accounts`,
        { ledger: ledgersCopy },
        requestHeader()
    );
}

export const createAccountsAPI = (requestHeader) => {
    return axios.post(
        `${config.host}/accounts`,
        { ledger: AllAccountLedgers },
        requestHeader
    );
};

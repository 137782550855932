import type Event from "Events/_event";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { CanvasBaselineContext } from "../contexts/CanvasBaselineContext";
import { getManager } from "helpers/getManager";
import { useContext } from "react";

/**
 * Custom hook that gets the event data from manager and converts to a regular JSON object.
 *
 * Will only force re-render of the component if anything within the given eventData has changed. Only works if everything is a primitive data type at the top level.
 */
export function useEventCanvasData(eventId: string) {
    const _isBaseline = useContext(CanvasBaselineContext);

    const eventData = useAppSelector((state) => {
        const manager = getManager(_isBaseline, state);
        const event: Event = manager._findEvent(eventId);
        const eventData = {
            active: event?.active ?? false,
            locked: event?.isLocked?.() ?? false,
            bypassed: event?.isBypassed?.() ?? false,
            valid: event?.valid ?? false,
            x: event?.x?.() ?? 0,
            y: event?.y?.() ?? 0,
        };
        return eventData;
    }, shallowEqual);

    return eventData;
}

// Default Imports
import React from "react";

import { ConstraintCard } from "../Card/constraintCard.tsx";

// Import Connection Image
import debitCreditConnectionImage from "../../Assets/_optionDescriptionIcons/Bank, Debit Credit, Debit Credit.png";

// Import Coloured PNGs
import constraintBlack from "../../Assets/_eventNodeTypes/constraintSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import ConstraintInput from "Components/InputContainer/Constraint/ConstraintInput";

export const constraintObject = {
    constant: function () {
        return constraintObject_Constant();
    },

    name: function () {
        return "Constraint";
    },

    checkInput: function (state) {
        return constraintObject_CheckInput(state);
    },

    svg: function () {
        return constraintObject_Svg();
    },

    eventNodeMenu: function (focus) {
        return constraintObject_EventNodeMenu(focus);
    },

    indexText: function () {
        return constraintObject_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return constraintObject_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return constraintObject_OptionDescription();
    },

    colouredPngs: function () {
        return constraintObject_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return constraintObject_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Constraint_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const constraintObject_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (!entity.data.selectedEventType) return false;
        if (!entity.data.selectedEventId) return false;
        if (!entity.data.selectedEvent) return false;
        if (!entity.data.property) return false;

        if (entity.data.customEffectPeriod) {
            if (!entity.startDate) return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const constraintObject_Constant = () => {
    return "86fc74e8-4690-4d97-b97f-e05f9155a3d4";
};

// EFFECTS: Returns svg for event
const constraintObject_Svg = () => {
    return constraintBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const constraintObject_EventNodeMenu = (focus) => {
    return <ConstraintCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const constraintObject_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const constraintObject_Icons = () => {
    let pngs = new Map([
        ["black", constraintBlack],
        ["blue", constraintBlack],
        ["white", constraintBlack],
        ["grey", constraintBlack],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const constraintObject_InputComponentInfoPassing = (minProps) => {
    return <ConstraintInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const constraintObject_OptionDescription = () => {
    const tags = ["Option"];
    const type = constraintObject.name();
    const description =
        "The Constraint Event is a general Event that allows for numerical values to have pre-determined minimum or maximum values.";
    const usedForText =
        "Use this Event to limit the value of an entity over time";
    const connection = "";
    const connectionImage = debitCreditConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const constraintObject_SideModalImage = () => {
    return sideImage;
};

const Constraint_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

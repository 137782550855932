import type { AccountPickerSchema } from "./typesSchema/accountPickerSchema";
import { SHOW_ACCOUNT_PICKER_MODAL } from "actions/accountPickerActions";

const initialState: AccountPickerSchema = {
    modalOpen: false,
};

export default function accountPicker(
    state: AccountPickerSchema = initialState,
    action: { type: string; payload?: any }
): AccountPickerSchema {
    switch (action.type) {
        case SHOW_ACCOUNT_PICKER_MODAL:
            return {
                ...state,
                modalOpen: action.payload.modalOpen,
            };
        default:
            return state;
    }
}

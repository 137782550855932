export const decisionMetadata = {
    name: "Rent or Buy",
    type: "Decision",
    tag: "@RentOrBuy",
};

export const mortgageDecision = {
    name: "Mortgage Decision",
    tag: "@MortgageDecision",
    type: "Decision",
};

export const house1Metadata = {
    name: "House 1",
    address: "4635 Kingsway Burnaby, BC, Canada",
    lat: 49.229286,
    lng: -123.001795,
    price: 850000,
    showSettings: false,
    actualHouse: true,
    placeHolderHouse: false,
    description: "This is a sample house for onboarding",
    mlsNumber: "YHR682H",
    url: null,
    "first payment": "2022-04-14",
    applyAppreciation: null,
    rating: 4,
    type: "House",
    value: 850000,
    tag: "@House1",
    onHoverStar: null,
    interestRate: 10,
    newlyBuiltHomeTrue: true,
    newlyBuiltHomeFalse: false,
    primaryResidenceTrue: false,
    primaryResidenceFalse: true,
};

export const mortgage1Metadata = {
    name: "Mortgage 1",
    "first payment": "2022-04-14",
    cadence: "monthly",
    price: 850000,
    downpayment: 170000,
    "mortgage term": "5 year fixed closed",
    rate: "3.64",
    "amortization period": "20 years",
    payments: "12404.74",
    url: null,
    end: null,
    rating: 5,
    description: "This is a sample onboarding mortgage",
    showRequired: false,
    downpaymentRate: "20",
    mlsNumber: null,
    type: "Mortgage",
    value: 12404,
    showSettings: false,
    valid: true,
    houseId: "a9486617-9782-4a24-a566-5d23cd608c29",
    tag: "@mortgage1",
    onHoverStar: null,
    inflation: false,
    locked: false,
    bypassed: false,
};

export const mortgage2Metadata = {
    name: "Mortgage 2",
    "first payment": "2022-04-14",
    cadence: "monthly",
    price: 850000,
    downpayment: 255000,
    "mortgage term": "5 year fixed closed",
    rate: "3.64",
    "amortization period": "10 years",
    payments: "12404.74",
    url: null,
    end: null,
    rating: 0,
    description: "This is a sample onboarding mortgage",
    showRequired: false,
    downpaymentRate: "30",
    mlsNumber: null,
    type: "Mortgage",
    value: 12404,
    showSettings: false,
    valid: true,
    houseId: "a9486617-9782-4a24-a566-5d23cd608c29",
    tag: "@Mortgage2",
    onHoverStar: null,
    inflation: false,
    locked: false,
    bypassed: false,
};

export const rentMetadata = {
    name: "Current Rent",
    rent: 1800,
    rooms: "2",
    type: "Rent",
    rentalType: "House",
    roommates: null,
    url: "www.google.com",
    start: "2022-04-14",
    end: null,
    rating: 2,
    showSettings: false,
    description: "Sample rent",
    cadence: "monthly",
    showRequired: false,
    address: "Joyce–Collingwood Station, Vancouver, BC V5R, Canada",
    lat: 49.2383732,
    lng: -123.0317578,
    sharedAccomodationTerm: "accomodationPercentage",
    accomodationRate: null,
    deposit: null,
    depositTerm: "depositPercentage",
    depositRate: null,
    depositCost: null,
    accomodationCost: null,
    insurance: null,
    insuranceTerm: "insurancePercentage",
    insuranceRate: null,
    insuranceCost: null,
    rentalIncrease: false,
    increaseRate: null,
    showAdditionalExpenses: false,
    showAccomodation: false,
    showRentalIncrease: false,
    upfrontPayment: null,
    upfrontTerm: "upfrontPercentage",
    upfrontRate: null,
    upfrontCost: null,
    utilitiesPayment: null,
    utilitiesTerm: "utilitiesPercentage",
    utilitiesRate: null,
    utilitiesCost: null,
    utilitiesFrequency: null,
    insuranceFrequency: null,
    value: 1800,
    tag: "@currentRent",
    onHoverStar: null,
    inflation: false,
    locked: false,
    bypassed: false,
};

export const incomeMetadata = {
    income: "2875",
    url: null,
    start: "2020-04-10",
    end: null,
    rating: 0,
    showOptions: false,
    name: "Current Income",
    description: "this is sample Income",
    cadence: "bi-weekly",
    incomeType: "Self Employment Income",
    type: "Income",
    value: 2875,
    showSettings: false,
    tag: "@currentIncome",
    onHoverStar: null,
    inflation: false,
    locked: false,
    bypassed: false,
    id: "e0f73993-47d1-4e21-bbe7-45d67f4e345d",
    inflationRate: "0.019",
    subType: "SelfEmploymentIncome",
};

export const incomeTaxMetadata = {
    type: "IncomeTax",
    name: "Income Tax",
    tag: "@IncomeTax",
    province: "British Columbia",
    value: 0,
};

import React, { Component } from "react";

class RetirementCard extends Component {
    render() {
        const { description, person, start, age, version } =
            this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Person</div>
                    <div className="cardNumber">
                        {person ? `${person}` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Retirement Date</div>
                    <div className="cardNumber">{start ? `${start}` : ""}</div>
                </div>

                <div className="Row">
                    <div className="ExpenseLabel">Retirement Age</div>
                    <div className="cardNumber">{age ? `${age}` : ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default RetirementCard;

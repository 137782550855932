// Default Imports
import React from "react";

import { ResourceCard } from "../Card/resourceCard.tsx";

// Import Connection Image
import resourceConnectionImage from "../../Assets/_optionDescriptionIcons/Project, Employee, Resource Highlight.png";

// Import Coloured PNGs
import resourceBlack from "../../Assets/_eventNodeTypes/resourcesSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import ResourceInput from "Components/InputContainer/Resource/ResourceInput";

import { resourceInputsHandler } from "Components/InputContainer/OnInputChangeHandlers/resourceInputsHandler.ts";

export const resourceObject = {
    constant: function () {
        return resourceObject_Constant();
    },

    name: function () {
        return "Resource";
    },

    checkInput: function (state) {
        return resourceObject_CheckInput(state);
    },

    svg: function () {
        return resourceObject_Svg();
    },

    eventNodeMenu: function (focus) {
        return resourceObject_EventNodeMenu(focus);
    },

    indexText: function () {
        return resourceObject_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return resourceObject_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return resourceObject_OptionDescription();
    },

    colouredPngs: function () {
        return resourceObject_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return resourceObject_SideModalImage();
    },

    inputsHandler: function (
        value,
        id,
        star,
        entitiesMap,
        currentEntity,
        account
    ) {
        return resourceInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            account
        );
    },

    generateDisplayFieldData: function () {
        return Resource_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const resourceObject_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.accountName) return false;

        if (entity.data.inheritedStartDate || entity.data.inheritedEndDate) {
            if (!entity.data.selectedEventId) return false;
        }

        if (!entity.data.inheritedStartDate) {
            if (!entity.startDate) return false;
        }

        if (!entity.data.inheritedEndDate) {
            if (!entity.endDate) return false;
        }

        if (entity.data.fixedOrAmortized === "amortized") {
            if (!entity.data.resourceCadence) return false;
            if (!entity.data.resourceDuration) return false;
        }

        if (!entity.data.value) return false;
        if (!entity.cadence) return false;

        if (entity.data.setOrDistributed === "distributed") {
            if (!entity.data?.distribution?.length) return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const resourceObject_Constant = () => {
    return "cdb7e4cb-69d8-45d7-89d5-920cc8c8adec";
};

// EFFECTS: Returns svg for event
const resourceObject_Svg = () => {
    return resourceBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const resourceObject_EventNodeMenu = (focus) => {
    return <ResourceCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const resourceObject_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const resourceObject_Icons = () => {
    let pngs = new Map([
        ["black", resourceBlack],
        ["blue", resourceBlack],
        ["white", resourceBlack],
        ["grey", resourceBlack],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const resourceObject_InputComponentInfoPassing = (minProps) => {
    return <ResourceInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const resourceObject_OptionDescription = () => {
    const tags = ["Option"];
    const type = resourceObject.name();
    const description =
        "Input the resources and schedule timelines for employees required to complete a Project. Resource requirements can be amortized over the schedule duration or use distribution to manually weight when those resources are required.";
    const usedForText =
        "This Event is commonly used in combination with Employees, Capacity, Project and Utilization to predict overage or shortfalls in a company’s ability to complete a project on schedule.";
    const connection = "";
    const connectionImage = resourceConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const resourceObject_SideModalImage = () => {
    return sideImage;
};

const Resource_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: true,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        endDate: {
            displayName: "End Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        cadence: {
            displayName: "Frequency",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["monthly", "daily"],
            conditionalFieldName: "setOrDistributed",
            conditionalFieldConditionValue: "set",
        },
        bypassState: {
            displayName: "Bypassed",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
    };

    const entityDataFieldsMap = {
        accountName: {
            displayName: "Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        fixedOrAmortized: {
            displayName: "Duration",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["fixed", "amortized"],
        },
        inheritedStartDate: {
            displayName: "Inherit Start",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
        inheritedEndDate: {
            displayName: "Inhert End",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
        setOrDistributed: {
            displayName: "Amount Type",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["set", "distributed"],
        },
        value: {
            displayName: "Amount",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: true,
        },
    };

    return { entityFieldsMap, entityDataFieldsMap };
};

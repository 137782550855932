import { cloneDeep } from "lodash";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { yearlyToMonthlyRate } from "./growthInputsHandler";

export const customerGrowth2InputsHandler = (
    value: string,
    id:
        | "entityName"
        | "entity"
        | "growthType"
        | "maxGrowthRate"
        | "constantGrowthRate"
        | "interpolate"
        | "startDate"
        | "growthRate"
        | "period"
        | "inheritStartDate"
        | "inheritCustomer"
        | "startGrowth",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    index?: number
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const newDependencies: {
                [key: string]: { eventId: string; entityIds: string[] };
            } = { ...(currentEntityObject.dependencies ?? {}) };
            if (!value) {
                newDependencies[id] = {
                    eventId: "",
                    entityIds: [],
                };
            } else {
                newDependencies[id] = JSON.parse(value);
            }
            currentEntityObject.dependencies = newDependencies;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "growthType":
            data.growthType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "constantGrowthRate":
            if (Number(value) < 0) break;
            data.constantGrowthRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "maxGrowthRate":
            if (Number(value) < 0) break;
            data.maxGrowthRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interpolate":
            data.interpolate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "growthRate":
            if (!index && index !== 0) break;
            if (Number(value) < 0) break;

            const growthSegments = cloneDeep(data.growthSegments);
            const segment = growthSegments[index];

            segment.rateDisplay = value;

            if (segment.period == "monthly") {
                segment.monthlyRate = Number(value);
            } else if (segment.period == "yearly") {
                segment.yearlyRate = Number(value);
                segment.monthlyRate =
                    yearlyToMonthlyRate(Number(value) * 0.01) * 100;
            }

            growthSegments[index] = segment;
            data.growthSegments = growthSegments;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inheritCustomer":
            data.inheritCustomer = !data.inheritCustomer;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inheritStartDate":
            data.inheritStartDate = !data.inheritStartDate;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
        case "period":
            if (!index && index !== 0) break;

            const growthSegs = cloneDeep(data.growthSegments);
            const seg = growthSegs[index];

            seg[id] = value;

            if (id == "period") {
                const currentGrowthRate =
                    seg.period == "monthly" ? seg.monthlyRate : seg.yearlyRate;
                seg.rateDisplay = String(
                    Math.round(Number(currentGrowthRate) * 100) / 100
                );
            }

            growthSegs[index] = seg;
            data.growthSegments = growthSegs;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startGrowth":
            if (Number(value) < 0) break;
            data.startGrowth = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

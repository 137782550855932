import { Component } from "react";
import "./InputContainer.css";
import SingleCalendar from "../Calendar/singleCalendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    Description,
    ValueOutlined,
    InputButtons,
    SelectDropDown,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    RequiredStar,
    ToggledContainer,
    ToggledContainerHalf,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { goalObject } from "Components/Registry/Goal";
import { appMapState } from "store/appMapStateDispatch";
import { connect } from "react-redux";

class GoalInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: getDefaultName(goalObject.name(), this.props),
            type: goalObject.constant(),
            applyInflation: false,
            description: "",
            accountType: "",
            accountLedgerName: "",
            monthlyCumulative: "monthly",
            value: "",
            targetDate: null,
            tag: `@${goalObject.name()}`,
            rating: 0,
            inflation: false,
            baseline: false,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "value":
                this.setState({ value: parseInt(value) });
                break;
            case "accountType":
                const finalValue =
                    this.state.monthlyCumulative === "cumulative"
                        ? `Cumulative ${value}`
                        : value;
                this.setState({
                    accountType: finalValue,
                    accountLedgerName: value,
                });
                break;
            default:
        }
    };

    onToggleChange = (e) => {
        e.preventDefault();

        this.setState({ monthlyCumulative: e.target.id });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (goalObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };
    updateValues = () => {
        const passedCheck = goalObject.checkInput(this.state);
        this.props.fillPartialValues(this.state, {}, passedCheck);
    };

    toggleInflation = () => {
        this.setState({ applyInflation: !this.state.applyInflation });
    };

    onHandleDate = (startDate) => {
        this.setState({ targetDate: startDate });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        const passedCheck = goalObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            name,
            description,
            value,
            accountLedgerName,
            monthlyCumulative,
        } = this.state;
        const { ledgersMetadata } = this.props;

        const allAccountLedgerNames = Object.values(ledgersMetadata).map(
            (ledgerMetadata) => ({
                value: ledgerMetadata.sourceName,
                displayName: ledgerMetadata.displayName,
            })
        );

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={goalObject.name()}
                        onChangeInput={this.onChangeInput}
                        image={goalObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow>
                        <ToggledContainer>
                            <ToggledContainerHalf
                                id="monthly"
                                isSelected={monthlyCumulative === "monthly"}
                                onClick={this.onToggleChange}
                                title="Monthly"
                            />
                            <ToggledContainerHalf
                                id="cumulative"
                                isSelected={monthlyCumulative === "cumulative"}
                                onClick={this.onToggleChange}
                                title="Cumulative"
                            />
                        </ToggledContainer>
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={accountLedgerName}
                            options={allAccountLedgerNames}
                            className="IncomeSelect"
                            id="accountType"
                            label="Account Type"
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <ValueOutlined
                            label="Target Account Balance"
                            onChangeInput={this.onChangeInput}
                            value={value}
                            id="value"
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SingleCalendar
                            onHandleDate={this.onHandleDate}
                            date={this.state.targetDate}
                            title="Target Date"
                        />
                    </ModalRow>
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                    isLibraryEvent={this.state.isLibraryEvent}
                    addLibraryEventToScenario={() => {
                        this.props.addLibraryEventToScenario(this.state);
                    }}
                    divorceLibraryEvent={() => {
                        this.props.divorceFromLibraryEvent(
                            this.state,
                            this.props.updateValues
                        );
                    }}
                />
            </EventInputModal>
        );
    }
}

const mapStateToProps = appMapState((state) => {
    return {
        ledgersMetadata: state.allAccountLedgers.ledgersMetadata,
    };
});

export default connect(mapStateToProps, null)(GoalInput);

import type Event from "Events/_event";

const EVENT_SIZE_BUFFER_X = 150; // TODO: How to make this dynamic?
const EVENT_SIZE_BUFFER_Y = 200; // TODO: How to make this dynamic?

export function getScenarioDimensions(events: Event[]): {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    scenarioWidth: number;
    scenarioHeight: number;
} {
    let xMin = Infinity;
    let xMax = -Infinity;
    let yMin = Infinity;
    let yMax = -Infinity;
    events?.forEach?.((event) => {
        const eventX = event?.x?.() ?? 0;
        const eventY = event?.y?.() ?? 0;
        if (eventX - EVENT_SIZE_BUFFER_X < xMin) {
            xMin = eventX - EVENT_SIZE_BUFFER_X;
        }
        if (eventX + EVENT_SIZE_BUFFER_X > xMax) {
            xMax = eventX + EVENT_SIZE_BUFFER_X;
        }
        if (eventY - EVENT_SIZE_BUFFER_Y < yMin) {
            yMin = eventY - EVENT_SIZE_BUFFER_Y;
        }
        if (eventY + EVENT_SIZE_BUFFER_Y > yMax) {
            yMax = eventY + EVENT_SIZE_BUFFER_Y;
        }
    });
    const scenarioWidth = xMax - xMin;
    const scenarioHeight = yMax - yMin;
    return { xMin, xMax, yMin, yMax, scenarioWidth, scenarioHeight };
}

import React from "react";
import styles from "./Options.module.css";
import { NodeTypesBlack } from "../../Events";
import librarySvg from "../../Assets/_canvasIcons/library-gray.svg";
import { decisionObject } from "Components/Registry/Decision";
import { getNameFromType } from "helpers/getNameFromType";

export const RecentlyUsedTab = ({
    nodes,
    onHandleOptionSelected,
    addEventType,
    eventLibrary,
    toggleAddingLibraryEvent,
    editNode,
}) => {
    const typeImagesBlack = {};
    NodeTypesBlack.forEach((data) => {
        typeImagesBlack[data.type] = data.image;
    });

    function editLibraryEvent(node, nodeDetails) {
        toggleAddingLibraryEvent(true);

        editNode({
            id: nodeDetails.libraryEventId,
            type: node.type,
            metadata: nodeDetails,
            isLibraryEvent: true,
        });
    }

    return (
        <div className={styles.optionNodeContainer}>
            <div className={styles.optionRootContainer}>
                {nodes.map((node) => {
                    const isDescriptionOpen =
                        node.type === "House" ||
                        node.type === "Mortgage" ||
                        node.type === "Person" ||
                        node.type === "Pension";

                    const isDecision = node.type === decisionObject.constant();
                    const image = typeImagesBlack[node.type];
                    let libraryEventDetails = null;
                    if (node.isLibraryEvent && eventLibrary) {
                        libraryEventDetails = eventLibrary[node.type].find(
                            (candidate) => {
                                return (
                                    node.libraryEventId &&
                                    node.libraryEventId ===
                                        candidate.libraryEventId
                                );
                            }
                        );
                    }

                    return (
                        <div key={node.type} className={styles.optionBoxWrap}>
                            <div
                                onClick={() =>
                                    node.isLibraryEvent
                                        ? editLibraryEvent(
                                              node,
                                              libraryEventDetails
                                          )
                                        : isDescriptionOpen
                                        ? onHandleOptionSelected(node.type)
                                        : addEventType(node.type)
                                }
                                className={
                                    isDecision
                                        ? styles.decisionBox
                                        : libraryEventDetails &&
                                          !libraryEventDetails.valid
                                        ? styles.optionBoxInvalid
                                        : styles.optionBox
                                }
                            >
                                <img
                                    className={styles.optionSvg}
                                    src={image}
                                    alt="option svg"
                                />
                                <div className={styles.optionName}>
                                    {libraryEventDetails
                                        ? libraryEventDetails.name
                                        : getNameFromType(node.type)}
                                </div>
                            </div>
                            {node.isLibraryEvent && (
                                <img
                                    src={librarySvg}
                                    alt="alt"
                                    className={styles.libraryImage}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

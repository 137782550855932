import { upsertEntities } from "./entitiesActions";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
// import { AppThunk } from "store";
import { isEqual } from "lodash";
import { createEntitiesAPI, submitEntitiesAPI } from "./entityAPIActions";
import { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import { getRelevantEntities } from "./getNodeEntityActions";
import { v4 as uuid } from "uuid";
import store, { AppThunk } from "store";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";
import { expressionObject } from "Components/Registry/Expression";
import { createDuplicateAccountStructure } from "helpers/duplicateEventHelpers";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { updateDependencyMap } from "./dependencyMapActions";
import {
    handleDuplicatedEntity,
    updateScenarioDependencyMap,
} from "helpers/updateDependencyMap";
import { revenueObject } from "Components/Registry/Revenue";
import { modifierObject } from "Components/Registry/Modifier";
import { salespersonObject } from "Components/Registry/Salesperson";
import { remapRevenueDependencies } from "Components/InputContainer/OnInputChangeHandlers/revenueInputsHandler";
import { remapModifierDependencies } from "Components/InputContainer/OnInputChangeHandlers/modifierInputsHandler";
import { remapSalespersonDependencies } from "Components/InputContainer/OnInputChangeHandlers/salespersonInputsHandler";
import { allocationObject } from "Components/Registry/Allocation";
import { recurseToTopAccount } from "helpers/accounts";
import { goalObject } from "Components/Registry/Goal";
import { constraintObject } from "Components/Registry/Constraint";
import { growthObject } from "Components/Registry/Growth";
import { percentageObject } from "Components/Registry/Percentage";
import { remapPercentageAccounts } from "Components/InputContainer/Percentage/PercentageInput";

// Optional parameters for the function handleSubmitNodesAndEntities()
interface optionalParams {
    dependencyMap?: DependencyMapSchema;
}

/**
 *
 * Takes in a scenario and duplicates it, replacing all its nodes'
 * entities with a copy.
 */
export const duplicateScenario = (
    scenario: ScenarioSchema
): { copiedScenario: ScenarioSchema; copiedEntities: EntitiesSchema } => {
    // Generate new ids
    const scenarioData = scenario.data;
    const newEntityIdsMap: { [key: string]: string } = {};
    scenarioData.nodes.forEach((node) => {
        node.entities.forEach((entity) => {
            const newId = newEntityIdsMap[entity.id] ?? uuid();
            newEntityIdsMap[entity.id] = newId;
            entity.id = newId;
        });
    });
    const newEntities = copyEntities(
        Object.keys(newEntityIdsMap),
        newEntityIdsMap
    );
    return {
        copiedScenario: { ...scenario, data: scenarioData },
        copiedEntities: newEntities,
    };
};

/**
 * Creates copies of existing ids, and returns a list of the new ids.
 * Optional parameter newEntityIdsMap specifies what each new entity's
 * id will be. Also remaps dependencies and copies existing overrides.
 */
export async function createEntityCopies(
    entityIds: string[] | { id: string; active: boolean }[],
    newEntityIdsMap?: { [key: string]: string },
    dependencyMap?: DependencyMapSchema,
    newEventId?: string
) {
    const newEntities = copyEntities(
        entityIds,
        newEntityIdsMap,
        dependencyMap,
        newEventId
    );

    return createEntitiesAPI(newEntities).then((response) => {
        // Upsert entities into redux state
        store.dispatch(upsertEntities(newEntities));
        // Pass along the response from createEntitiesAPI()
        return response;
    });
}

const copyEntities = (
    entityIds: string[] | { id: string; active: boolean }[],
    newEntityIdsMap?: { [key: string]: string },
    dependencyMap?: DependencyMapSchema,
    newEventId?: string
) => {
    const entities = getRelevantEntities(entityIds);
    const newEntities: EntitiesSchema = {};

    let entityType;

    const customAccountMap: { [key: string]: string } = {};
    Object.values(entities).forEach((entity) => {
        if (!entityType) entityType = entity.type;

        const newId = newEntityIdsMap?.[entity.id] ?? uuid();
        newEntities[newId] = { ...entity, id: newId };

        // Handle updating the implicit modifiers and overrides
        const duplicatedData = { ...newEntities[newId].data };
        // Handles duplicating implicit modifier/overrides
        if (duplicatedData?.modsCreated) {
            const entityMods = duplicatedData.modsCreated.map((m) => {
                return {
                    ...m,
                    value: { ...m.value },
                    id: uuid(),
                    entityId: newId,
                };
            });
            duplicatedData.modsCreated = entityMods;
        }
        newEntities[newId].data = duplicatedData;

        // Handle updating dependency map
        if (dependencyMap) {
            if (newEventId) {
                // Duplicated event case
                handleDuplicatedEntity(
                    dependencyMap,
                    newEventId,
                    newEntities[newId]
                );
            } else {
                // Duplicated scenario case
                updateScenarioDependencyMap(dependencyMap, entity.id, newId);
            }
        }

        // Check for expression entities
        if (entity.type === expressionObject.constant()) {
            const newExpressionEntity = newEntities[newId];
            const oldSelectedEntityId = newExpressionEntity.data.selectedEntity;
            const newSelectedEntityId =
                newEntityIdsMap?.[oldSelectedEntityId] ?? uuid();
            // At this point the new entity and old entity both share the data object
            // Copy data field to avoid mutations - deeper copy required if parameters nested
            const newData = { ...newExpressionEntity.data };
            newData.selectedEntity = newSelectedEntityId;
            // Assign new copied data field back to new entity
            newExpressionEntity.data = newData;

            // In case we copied expression entity before the associated entity we add
            // its potentially new ID into the newEntityIdsMap.
            if (newEntityIdsMap?.[oldSelectedEntityId]) {
                newEntityIdsMap[oldSelectedEntityId] = newSelectedEntityId;
            }
        } else if (entity.data.accountStructure) {
            newEntities[newId] = createDuplicateAccountStructure(
                newEntities[newId],
                customAccountMap
            );
        }
    });

    if (newEntityIdsMap) {
        remapEntityDependencies(newEntities, newEntityIdsMap, customAccountMap);
    }

    return newEntities;
};

/**
 * Remaps dependency ids to new entity ids.
 *
 * ASSUMES that event ids are not changed, so the `eventId` field
 * is ignored.
 */
const remapEntityDependencies = (
    newEntities: EntitiesSchema,
    newEntityIdsMap: { [key: string]: string },
    customAccountMap: { [key: string]: string }
) => {
    const accountsAndLedgers =
        store?.getState()?.allAccountLedgers?.ledgersMetadata ?? {};

    // TODO: The entire dependency system needs to be standardized and re-factored. All of these dependencies should not live in
    //       the data blob of entities, or if they do, the naming should be standardized. This would vastly simply and improve the process
    //       of updating event/entity references whenever we duplicate events or scenarios.
    for (const entity of Object.values(newEntities)) {
        // Revenue is an exception to the naming convention below b/c it contains an extra field to account for segments
        if (entity.type === revenueObject.constant()) {
            remapRevenueDependencies(entity, newEntityIdsMap);
            continue;
        }

        if (
            entity.type === modifierObject.constant() ||
            entity.type === constraintObject.constant() ||
            entity.type === growthObject.constant()
        ) {
            remapModifierDependencies(entity, newEntityIdsMap);
            continue;
        }

        if (entity.type === salespersonObject.constant()) {
            remapSalespersonDependencies(entity, newEntityIdsMap);
            continue;
        }

        // Allocation is also an exception b/c it has a reference to customer through the segment, there is no
        // customerEventId field, so we need to update the customer reference manually
        if (entity.type === allocationObject.constant()) {
            entity.data.customerIds = [
                newEntityIdsMap[entity.data.customerIds[0]],
            ];
        }

        // Percentage is an exception because it potentially targets three different accounts and each account
        // is found under a unique key. So we handle it separately from the general accountName migration below
        if (entity.type === percentageObject.constant()) {
            remapPercentageAccounts(
                entity,
                customAccountMap,
                accountsAndLedgers
            );
        }

        for (const field of Object.keys(entity.data)) {
            // As an example consider the field 'unitCostId'
            if (
                field.includes("EventId") &&
                typeof entity.data[field] === "string"
            ) {
                // Check if the field contains EventId as a substring Eg. unitCostEventId
                const suffix = field.replace("EventId", "");
                // Modify the suffix for use later on Eg. unitCost -> UnitCost
                const suffixCapitalized =
                    suffix?.length > 0
                        ? suffix.charAt(0).toUpperCase() + suffix.slice(1)
                        : suffix;
                // Grab the entityIds field for the relevant dependency if applicable
                const oldEntityIds = entity.data[`${suffix}Ids`];

                // If the field of entityIds is invalid then skip this loop
                if (!Array.isArray(oldEntityIds)) continue;

                // if there are no entityIds or there is no entity ids that are being remapped skip this loop
                if (
                    !(entity?.data?.[`${suffix}Ids`]?.length > 0) ||
                    !newEntityIdsMap?.[entity?.data?.[`${suffix}Ids`]?.[0]]
                )
                    continue;

                // Convert the array of old entity ids to an array containing the updated ids
                const newEntityIds: string[] = [];
                for (const oldEntityId of oldEntityIds) {
                    const newId = newEntityIdsMap[oldEntityId];
                    newEntityIds.push(newId);
                }
                entity.data[`${suffix}Ids`] = newEntityIds;

                // Update the selectUnitCost field so the drop down component correctly displays the event/entity information
                entity.data[`selected${suffixCapitalized}`] = {
                    entityIds: entity.data[`${suffix}Ids`],
                    eventId: entity.data[`${suffix}EventId`],
                };
            } else if (
                field.includes("accountName") &&
                typeof entity.data[field] === "string"
            ) {
                let oldAccountId = "";
                if (entity.type === goalObject.constant())
                    oldAccountId = entity.data["accountId"];
                oldAccountId =
                    entity.data["accountIds"][
                        entity.data["accountIds"].length - 1
                    ];

                if (customAccountMap[oldAccountId]) {
                    const newAccountId = customAccountMap[oldAccountId];

                    if (entity.type === goalObject.constant())
                        entity.data["accountId"] = newAccountId;
                    else
                        entity.data["accountIds"] =
                            recurseToTopAccount(newAccountId);

                    entity.data[field] = accountsAndLedgers[newAccountId].name;
                }
            }
        }
    }
};

/**
 *  1. Gets the entity diffs in terms of Create, Update, and Delete
 *  2. Makes API requests to the database
 *  3. Depending on success:
 *      a. Update Redux state for the Entities' data
 *      b. Create an updated list of entities for the current eventState
 *  4. Make Event calls
 */
export function handleSubmitNodesAndEntities(
    addEvent: (event: EventStructure) => void,
    updateEvent: (event: EventStructure) => void,
    eventState: EventStructure,
    newEntities: EntitiesSchema,
    entityIds: string[],
    passedCheck: boolean,
    edit: boolean,
    optionalParams: optionalParams
): AppThunk<Promise<string[]>> {
    const { dependencyMap } = optionalParams;

    return async (dispatch, getState) => {
        const { createObjs, updateObjs } = compareEntities(
            eventState.entities.map((entity) => entity.id),
            getState().entities,
            newEntities
        );

        try {
            const [createPromise, updatePromise] = await submitEntitiesAPI(
                createObjs,
                updateObjs
            );

            const failedOperations: string[] = [];

            // Create
            if (createPromise.status === "fulfilled") {
                dispatch(upsertEntities(createObjs));
            } else {
                failedOperations.push("create");
            }

            // Update
            if (updatePromise.status === "fulfilled") {
                dispatch(upsertEntities(updateObjs));
            } else {
                failedOperations.push("update");
            }

            // Update dependenciesMap
            if (dependencyMap) {
                dispatch(updateDependencyMap(dependencyMap));
            }

            const allEntities = getState().entities;
            const newEventEntities = entityIds
                .filter((entityId) => !!allEntities[entityId])
                .map((entityId) => ({ id: entityId, active: true }));

            // Update event
            const newEvent = {
                ...eventState,
                entities: newEventEntities,
                isFilled: passedCheck,
                valid: passedCheck,
            };

            if (edit) {
                updateEvent(newEvent);
            } else {
                addEvent(newEvent);
            }

            const manager = getState().scenario?.manager;
            const isPaused = getState().scenario?.pauseDecisionEngine;
            if (manager && !isPaused) {
                manager.updateCanvas(
                    Object.values(createObjs).length > 0 ||
                        Object.values(updateObjs).length > 0 ||
                        getState().scenario?.showCellRowModal.modified
                );
            }

            return failedOperations; /* TODO: Maybe resolve to something else? */
        } catch (err: any) {
            console.log(err);
            throw new Error(
                `Error when creating and updating entities -> ${err}`
            );
        }
    };
}

const compareEntities = (
    oldEntitiesEvent: string[],
    oldEntitiesMapAll: EntitiesSchema,
    newEntitiesMap: EntitiesSchema
) => {
    const newEntities = Object.entries(newEntitiesMap);

    const createObjs = Object.fromEntries(
        newEntities.filter(([id, _entity]) => !oldEntitiesMapAll[id])
    );

    const updateObjs = Object.fromEntries(
        newEntities.filter(
            ([id, _entity]) =>
                !!oldEntitiesMapAll[id] &&
                !isEqual(oldEntitiesMapAll[id], newEntitiesMap[id])
        )
    );

    const deleteIds = oldEntitiesEvent.filter((id) => !newEntitiesMap[id]);

    return { createObjs, updateObjs, deleteIds };
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import ContainerCard from "../Card/containerCard";
import ContainerInputExperimental from "Components/InputContainer/ContainerInputExperimental/ContainerInputExperimental";

// Import Connection Image (if exists)
import NullConnectionImage from "../../Assets/_optionDescriptionIcons/Null white.jpg";

// Import Coloured PNGs
import containerIcon from "../../Assets/_eventNodeTypes/containerSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - nullObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - nullObject.checkInput.call(nullObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const containerObject = {
    constant: function () {
        return Container_Constant();
    },

    name: function () {
        return "Container";
    },

    checkInput: function (state) {
        return Container_CheckInput(state);
    },

    svg: function () {
        return Container_Svg();
    },

    eventNodeMenu: function (focus) {
        return Container_EventNodeMenu(focus);
    },

    indexText: function () {
        return Container_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Container_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Container_OptionDescription();
    },

    colouredPngs: function () {
        return Container_Icons();
    },

    version: function () {
        return "1.0.1";
    },

    sideModal: function () {
        return Container_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Container_GenerateDisplayFieldData();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Container_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.headNode) return false;
        if (!entity.data.tailNode) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Container_Constant = () => {
    return "bc4f4471-be25-4ae0-aa18-88363f650fc2";
};

// EFFECTS: Returns black png / svg for event
const Container_Svg = () => {
    return containerIcon;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Container_EventNodeMenu = (focus) => {
    return <ContainerCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Container_IndexText = () => {
    return "Container Event – No Context Generated.";
};

// EFFECTS: Returns all icons for event ??
const Container_Icons = () => {
    let pngs = new Map([["black", containerIcon]]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Container_InputComponentInfoPassing = (minProps) => {
    return <ContainerInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, nulls for event ??
const Container_OptionDescription = () => {
    const tags = ["Option"];
    const type = containerObject.name();
    const description =
        "The Null Event has no impact on any calculations in a Scenario. It is an organizational Event.";
    const usedForText =
        "Use a Null Event when creating a Decision and modeling a “what if” Scenario. Branch a Null Event to represent a “no change” Scenario Thread.";
    const connection =
        "A Null Event is usually used as part of a Scenario Thread in a Decision to represent a calculation with no changes.";
    const connectionImage = NullConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Container_SideModalImage = () => {
    return sideImage;
};

const Container_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

// @ts-nocheck
import { PlaidLink } from "react-plaid-link";
import {
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    Description,
} from "../Components";
import BankPlaidInput from "../BankPlaidInput";
import { bankObject } from "Components/Registry/Bank";
import BankManualInputExperimental from "../BankManualInputExperimental/BankManualInputExperimental";
import plaidSvg from "../../../Assets/_flinksIcons/plaid.svg";

export default function BankInputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    onChangeNameDescription,
    onSuccess,
    handleManual,
    onHandleSubmit,
    edit,
    passedCheck,
    eventData,
}) {
    const bankSelected = entitiesMap[currentEntity].data.bankSelected;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={bankObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={bankObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            {entitiesMap[currentEntity].data.bankState === "default" && (
                <div className="pageOneContainer">
                    <div className="LinkContainer">
                        <div className="flinksContextContainer">
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                }}
                            >
                                <img src={plaidSvg} alt="plaid logo" />
                                <div className="labelBold">
                                    Add via Plaid (US)
                                </div>
                            </div>
                            <div className="subLabel">
                                Link to your bank accounts (US or Canada) using
                                Plaid&apos;s secure, 3rd party integration.
                                whatifi does not store or have access to any of
                                your banking credentials.
                            </div>
                        </div>
                        <div className="flinksButtonContainer">
                            <PlaidLink
                                clientName="whatifi"
                                // env="development"
                                env="sandbox"
                                product={["auth", "transactions"]}
                                publicKey="64848e34a7b3f0fcb899d1ab8222e8"
                                onSuccess={onSuccess}
                                className="connectBankButton"
                            >
                                Coming Soon
                            </PlaidLink>
                        </div>
                    </div>

                    <div className="LinkContainer">
                        <div className="flinksContextContainer">
                            <div
                                style={{
                                    display: "flex",
                                    margin: "15px 0px",
                                }}
                            >
                                <div className="labelBold">
                                    Add Your Account Manually
                                </div>
                            </div>
                            <div className="subLabel">
                                Enter your bank account totals manually. This
                                option does not require any banking information
                                but will not update automatically as a result.
                            </div>
                        </div>
                        <div className="flinksButtonContainer">
                            <div
                                className="manualBankButton"
                                onClick={handleManual}
                            >
                                Manual Add
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {entitiesMap[currentEntity].data.bankState === "accounts" && (
                <>
                    {bankSelected === "plaid" && this.state.bankData && (
                        // Plaid is not implemented yet, these are placeholder props
                        <BankPlaidInput
                            setEntitiesMap={setEntitiesMap}
                            entitiesMap={entitiesMap}
                            currentEntity={currentEntity}
                            editData={entitiesMap[currentEntity].data}
                            bankData={entitiesMap[currentEntity].data.bankData}
                        />
                    )}
                    {bankSelected === "manual" && (
                        <BankManualInputExperimental
                            setEntitiesMap={setEntitiesMap}
                            entitiesMap={entitiesMap}
                            currentEntity={currentEntity}
                            updateValues={onHandleSubmit}
                            onHandleSubmitValues={onHandleSubmit}
                            onHandleSubmit={onHandleSubmit}
                            edit={edit}
                            passedCheck={passedCheck}
                        />
                    )}
                </>
            )}
        </EventEntityWrapper>
    );
}

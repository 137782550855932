import type { AnimationConfig } from "reducers/typesSchema/eventsTemplatesSchema";
import { useContext, useMemo } from "react";
import {
    EventNodeActiveContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeLockedContext,
} from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/contexts/EventNodeStateContexts";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { MenuIdContext } from "../../contexts/MenuIdContext";
import { useMenuTemplate } from "../../hooks/useMenuTemplate";
import { HoverButtonIdContext } from "../../../../contexts/HoverButtonIdContext";

export function useIconContainerStylesFull(
    buttonId: string,
    _iconContainerDimensions?: any,
    _iconContainerDimensionsAnimationConfig?: any
) {
    const menuId = useContext(MenuIdContext);
    const menuTemplate = useMenuTemplate(menuId);
    const active = useContext(EventNodeActiveContext);
    const hoveredId = useContext(HoverButtonIdContext);
    const focused = useContext(EventNodeFocusedContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const error = useContext(EventNodeErrorContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);

    const iconContainerDimensions = useMemo(() => {
        if (_iconContainerDimensions) return _iconContainerDimensions;
        const _styles =
            menuTemplate?.canvasStyles?.buttons?.[buttonId]?.buttonDimensions
                ?.styles;
        const _default = _styles?.default;
        const _active = active ? _styles?.active : {};
        const _focused = focused ? _styles?.focused : {};
        const _locked = locked ? _styles?.locked : {};
        const _bypassed = bypassed ? _styles?.bypassed : {};
        const _error = error ? _styles?.error : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _dimensions = {
            ..._default,
            ..._active,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };
        const _finalStyles = {
            ..._dimensions,
            x: -((_dimensions?.width ?? 0) / 2),
            y: -((_dimensions?.height ?? 0) / 2),
        };

        return _finalStyles;
    }, [
        _iconContainerDimensions,
        active,
        buttonId,
        bypassed,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const iconContainerColors = useMemo(() => {
        const _styles =
            menuTemplate?.canvasStyles?.buttons?.[buttonId]?.buttonColors
                ?.styles;
        const _default = _styles?.default;
        const _active = active ? _styles?.active : {};
        const _hovered = hoveredId === buttonId ? _styles?.hovered : {};
        const _focused = focused ? _styles?.focused : {};
        const _locked = locked ? _styles?.locked : {};
        const _bypassed = bypassed ? _styles?.bypassed : {};
        const _error = error ? _styles?.error : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _finalStyles = {
            ..._default,
            ..._active,
            ..._hovered,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };

        return _finalStyles;
    }, [
        active,
        buttonId,
        bypassed,
        error,
        focused,
        hoveredId,
        locked,
        menuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const iconContainerDimensionsAnimationConfig = useMemo(() => {
        if (_iconContainerDimensionsAnimationConfig)
            return _iconContainerDimensionsAnimationConfig;
        const _animationOverride =
            menuTemplate?.canvasStyles?.buttons?.[buttonId]?.buttonDimensions
                ?.animationOverride;
        const _default = _animationOverride?.default;
        const _active = active ? _animationOverride?.active : {};
        const _focused = focused ? _animationOverride?.focused : {};
        const _locked = locked ? _animationOverride?.locked : {};
        const _bypassed = bypassed ? _animationOverride?.bypassed : {};
        const _error = error ? _animationOverride?.error : {};
        const _zoomCategory = _animationOverride?.[zoomCategory];

        const _animationConfig: Partial<AnimationConfig> = {
            ..._default,
            ..._active,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };

        return _animationConfig;
    }, [
        _iconContainerDimensionsAnimationConfig,
        active,
        buttonId,
        bypassed,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const iconColorsAnimationConfig = useMemo(() => {
        const _animationOverride =
            menuTemplate?.canvasStyles?.buttons?.[buttonId]?.buttonColors
                ?.animationOverride;
        const _default = _animationOverride?.default;
        const _active = active ? _animationOverride?.active : {};
        const _hovered =
            hoveredId === buttonId ? _animationOverride?.hovered : {};
        const _focused = focused ? _animationOverride?.focused : {};
        const _locked = locked ? _animationOverride?.locked : {};
        const _bypassed = bypassed ? _animationOverride?.bypassed : {};
        const _error = error ? _animationOverride?.error : {};
        const _zoomCategory = _animationOverride?.[zoomCategory];

        const _animationConfig: Partial<AnimationConfig> = {
            ..._default,
            ..._active,
            ..._hovered,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };

        return _animationConfig;
    }, [
        active,
        buttonId,
        bypassed,
        error,
        focused,
        hoveredId,
        locked,
        menuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const iconContainerStylesFull = useMemo(
        () => ({ ...iconContainerDimensions, ...iconContainerColors }),
        [iconContainerDimensions, iconContainerColors]
    );
    const iconContainerAnimationConfigFull = useMemo(
        () => ({
            ...iconContainerDimensionsAnimationConfig,
            ...iconColorsAnimationConfig,
        }),
        [iconContainerDimensionsAnimationConfig, iconColorsAnimationConfig]
    );

    return {
        iconContainerStylesFull,
        iconContainerAnimationConfigFull,
    };
}

import axios from "axios";
import { config } from "config";
import { AccountMappings } from "reducers/typesSchema/accountMappingsSchema";
import { requestHeader } from "./scenario";

export function updateAccountMappingsAPI(mappings: AccountMappings) {
    return axios.put(
        `${config.host}/accountMapping`,
        { mappings: mappings },
        requestHeader()
    );
}

export function createAccountMappingsAPI() {
    return axios.post(`${config.host}/accountMapping`, {}, requestHeader());
}

import Konva from "konva";
import type {
    AnimationConfig,
    ClickableEventNodeBodyCanvasStylesFields,
    BottomLayerVisualEventNodeBodyCanvasStylesFields,
    TopLayerVisualEventNodeBodyCanvasStylesFields,
} from "reducers/typesSchema/eventsTemplatesSchema";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { LayerTwo, LayerFour } from "./Layers"; // Keep this line so that JSDocs will link properly.
import { useContext, useMemo } from "react";
import { animated, useSpring } from "@react-spring/konva";
import { EventNodeIDContext } from "../contexts/EventNodeIDContext";
import {
    EventNodeActiveContext,
    EventNodeInSelectedThreadContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeSelectedContext,
    EventNodeLockedContext,
    EventNodeConnectableContext,
} from "../contexts/EventNodeStateContexts";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { useEventTemplate } from "../hooks/useEventTemplate";

interface EventNodeBodyProps {
    /**
     * The fields to look up in the template. For Visual, it will be both dimensions and colors, for Clickable, it will only be dimensions.
     */
    canvasStylesFields:
        | ClickableEventNodeBodyCanvasStylesFields
        | BottomLayerVisualEventNodeBodyCanvasStylesFields
        | TopLayerVisualEventNodeBodyCanvasStylesFields;
    onClick?: (
        event: Konva.KonvaEventObject<MouseEvent>,
        eventId: string
    ) => void;
    onDblClick?: (
        event: Konva.KonvaEventObject<MouseEvent>,
        eventId: string
    ) => void;
    onMouseEnter?: (
        event: Konva.KonvaEventObject<MouseEvent>,
        eventId: string
    ) => void;
    onMouseLeave?: (
        event: Konva.KonvaEventObject<MouseEvent>,
        eventId: string
    ) => void;
}

/**
 * Represents the SHAPE and COLORS of the event node in the canvas.
 *
 * Only used in {@link LayerTwo} and {@link LayerFour}
 */
export function EventNodeBody({
    canvasStylesFields,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
}: EventNodeBodyProps) {
    const eventId = useContext(EventNodeIDContext);
    const active = useContext(EventNodeActiveContext);
    const inSelectedThread = useContext(EventNodeInSelectedThreadContext);
    const focused = useContext(EventNodeFocusedContext);
    const selected = useContext(EventNodeSelectedContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const error = useContext(EventNodeErrorContext);
    const connectable = useContext(EventNodeConnectableContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);

    const { eventTemplateObject } = useEventTemplate(eventId);

    const rectStyles = useMemo(() => {
        let _rectStyles: Partial<Konva.RectConfig> = {};
        canvasStylesFields.forEach((canvasStyleField) => {
            const _styles =
                eventTemplateObject?.canvasStyles?.[canvasStyleField]?.styles;
            const _default = _styles?.default;
            const _active = active ? _styles?.active : {};
            const _inSelectedThread = inSelectedThread
                ? _styles?.inSelectedThread
                : {};
            const _focused = focused ? _styles?.focused : {};
            const _selected = selected ? _styles?.selected : {};
            const _locked = locked ? _styles?.locked : {};
            const _bypassed = bypassed ? _styles?.bypassed : {};
            const _error = error ? _styles?.error : {};
            const _connectable = connectable ? _styles?.connectable : {};
            const _zoomCategory = _styles?.[zoomCategory];

            _rectStyles = {
                ..._rectStyles,
                ..._default,
                ..._active,
                ..._inSelectedThread,
                ..._focused,
                ..._selected,
                ..._locked,
                ..._bypassed,
                ..._error,
                ..._connectable,
                ..._zoomCategory,
            };
        });

        return _rectStyles;
    }, [
        active,
        inSelectedThread,
        bypassed,
        canvasStylesFields,
        error,
        eventTemplateObject?.canvasStyles,
        focused,
        selected,
        locked,
        connectable,
        zoomCategory,
    ]);

    const animationConfig = useMemo(() => {
        let _animationConfig: Partial<AnimationConfig> = {};
        canvasStylesFields.forEach((canvasStyleField) => {
            const _animationOverride =
                eventTemplateObject?.canvasStyles?.[canvasStyleField]
                    ?.animationOverride;
            const _default = _animationOverride?.default;
            const _active = active ? _animationOverride?.active : {};
            const _inSelectedThread = inSelectedThread
                ? _animationOverride?.inSelectedThread
                : {};
            const _focused = focused ? _animationOverride?.focused : {};
            const _selected = selected ? _animationOverride?.selected : {};
            const _locked = locked ? _animationOverride?.locked : {};
            const _bypassed = bypassed ? _animationOverride?.bypassed : {};
            const _error = error ? _animationOverride?.error : {};
            const _connectable = connectable
                ? _animationOverride?.connectable
                : {};
            const _zoomCategory = _animationOverride?.[zoomCategory];

            _animationConfig = {
                ..._animationConfig,
                ..._default,
                ..._active,
                ..._inSelectedThread,
                ..._focused,
                ..._selected,
                ..._locked,
                ..._bypassed,
                ..._error,
                ..._connectable,
                ..._zoomCategory,
            };
        });

        return _animationConfig;
    }, [
        active,
        inSelectedThread,
        bypassed,
        canvasStylesFields,
        error,
        eventTemplateObject?.canvasStyles,
        focused,
        selected,
        locked,
        connectable,
        zoomCategory,
    ]);

    const animatedRectStyles = useSpring<Konva.RectConfig>({
        to: rectStyles,
        config: animationConfig,
    });

    return (
        // @ts-ignore - Seems to be a react spring type error
        <animated.Rect
            {...animatedRectStyles}
            onClick={(e) => onClick?.(e, eventId)}
            onDblClick={(e) => onDblClick?.(e, eventId)}
            onMouseEnter={(e) => onMouseEnter?.(e, eventId)}
            onMouseLeave={(e) => onMouseLeave?.(e, eventId)}
        />
    );
}

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import RealEstateFeeCard from "../Card/realEstateFeeCard";
import RealEstateFeeInput from "../InputContainer/RealEstateFeeInput";

// Import Connection Image (if exists)
import realEstateFeeConnectionImage from "../../Assets/_optionDescriptionIcons/Property Resale, Closing Costs, Real Estate Fees.png";

// Import Coloured PNGs
import realEstateFeeBlack from "../../Assets/_eventNodeTypes/realestatefee-black.png";
import realEstateFeeBlue from "../../Assets/_eventNodeTypes/realestatefee-blue.png";
import realEstateFeeWhite from "../../Assets/_eventNodeTypes/realestatefee-white.png";
import realEstateFeeGrey from "../../Assets/_eventNodeTypes/realestatefee-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";
// Notes
// To call an object with no parameters, can simply use
//  - realEstateFeeObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - realEstateFeeObject.checkInput.call(realEstateFeeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const realEstateFeeObject = {
    constant: function () {
        return RealEstateFee_Constant();
    },

    checkInput: function (state) {
        return RealEstateFee_CheckInput(state);
    },

    svg: function () {
        return RealEstateFee_Svg();
    },

    eventNodeMenu: function (focus) {
        return RealEstateFee_EventNodeMenu(focus);
    },

    indexText: function () {
        return RealEstateFee_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return RealEstateFee_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return RealEstateFee_OptionDescription();
    },

    colouredPngs: function () {
        return RealEstateFee_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return RealEstateFee_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const RealEstateFee_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.feePercent) return false;
    if (!state.feeAmount) return false;

    return true;
};

// EFFECTS: Returns constant for event
const RealEstateFee_Constant = () => {
    return "Real Estate Fee";
};

// EFFECTS: Returns black png / svg for event
const RealEstateFee_Svg = () => {
    return realEstateFeeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const RealEstateFee_EventNodeMenu = (focus) => {
    return <RealEstateFeeCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const RealEstateFee_IndexText = () => {
    return "Select an input field to learn more about your realEstateFee calculations.";
};

// EFFECTS: Returns all icons for event ??
const RealEstateFee_Icons = () => {
    let pngs = new Map([
        ["black", realEstateFeeBlack],
        ["blue", realEstateFeeBlue],
        ["white", realEstateFeeWhite],
        ["grey", realEstateFeeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const RealEstateFee_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const RealEstateFee_InputComponentInfoPassing = (minProps) => {
    return <RealEstateFeeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, realEstateFees for event ??
const RealEstateFee_OptionDescription = () => {
    const tags = ["Option"];
    const type = realEstateFeeObject.constant();
    const description =
        "The seller of a home pays the Real Estate agents a fee for the sale. The fee is typically a percentage of the resale price of the property";
    const usedForText =
        "Use the Real Estate Fees Event to estimate the cost that the seller of a property will pay. If the sale is subject to Capital Gains calculations than these fees are deducted from the eligible Capital Gains amount.";
    const connection =
        "Add a Real Estate Fees Event downstream of a Property Resale Event and before Capital Gains or Income Tax.";
    const connectionImage = realEstateFeeConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/financial-edge/0611/understanding-real-estate-commissions-who-pays.aspx";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const RealEstateFee_SideModalImage = () => {
    return sideImage;
};

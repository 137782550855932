import swal from "sweetalert";
import { config } from "../config";
import axios from "axios";
import _ from "lodash";
import * as uuid from "uuid";
import * as Sentry from "@sentry/browser";
import { supportedLibraryEvents } from "../helpers/constants";
import { throwError } from "../helpers/swalError";
import {
    SAMPLE_SCENARIO,
    SET_MANAGER,
    SET_BACKGROUND_MANAGER,
    USER_SCENARIOS,
    EDIT_SCENARIO,
    CREATE_SCENARIO,
    LOAD_SCENARIO,
    PAUSE_DECISION_ENGINE,
    DELETE_SCENARIO,
    SET_FOCUS,
    RESET_FOCUS,
    UPDATE_NODE,
    UPDATE_NODE_CLEAR_FOCUS,
    UPDATE_SUCCESS,
    CLOSE_MODAL,
    COPY_SCENARIO,
    SET_LINE_FOCUS,
    RESET_SOURCE,
    SET_SOURCE,
    COLLECT_NODE,
    DISABLE_COLLECT_NODE,
    REMOVE_COLLECTED_NODE,
    CANCEL_COLLECT_MODE,
    CANCEL_ACTIONS,
    POP_UP_OPTION,
    CLOSE_POP_UP_OPTION,
    SELECTED_OPTION,
    CLOSE_EVENTS_MODAL,
    SHARE_SCENARIO,
    CANCEL_SHARE_SCENARIO,
    SEND_EMAIL,
    SHARED_SCENARIO_PER_ACCOUNT,
    UPDATE_SCENARIO_CANVAS,
    EDIT_NODE,
    SELECT_LINE,
    UPDATE_RATING,
    HIGHLIGHTED_THREAD,
    NODEGRAPH_STATE,
    FLINK_BANK_DATA,
    SIGNUP,
    GET_SHARED_SCENARIO,
    GET_USER_DATA,
    SAVE_USER_DETAILS,
    UPDATE_COMMENTS,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    ONBOARDING_STATE,
    ONBOARDING_DATA,
    ONBOARDING_SCENARIO,
    SHOW_CREATE_SCENARIO,
    SHOW_LOGIN,
    TAB_SELECTED,
    SHOW_OPTION_PICKER,
    GET_BASELINE,
    HOVERED_INPUT,
    FOCUS_VALUE,
    BASELINE_MANAGER,
    CLOSE_EXPIRED_NODE,
    SHARE_BY_EMAIL,
    BASELINE_DATA_MANAGER,
    TOGGLE_ACCOUNT_TYPE,
    SHARE_TEMPLATE,
    GET_AGENCY_CLIENTS,
    ADD_CLIENT,
    DELETE_CLIENT,
    GET_LIBRARY,
    UPDATE_CLIENT,
    ADDING_LIBRARY_EVENT,
    SELECT_OPTIONS_TAB,
    SHOW_EXPLORE_OPTIONS,
    SHOW_AGENCY_BASELINE_PAGE,
    ARCHIVE_SCENARIO,
    REACTIVATE_ARCHIVED_SCENARIO,
    UPDATE_ACCOUNT_DATA,
    GET_SUBSCRIPTION,
    GET_TEMPLATES,
    ARE_BASELINES_LOADED,
    CLOSE_OUTDATED_NODE,
    CLEAR_SELECTED_EVENTS,
    SELECT_EVENT,
    SELECT_ENTITY,
    SET_ZOOMED_EVENT_ID,
    RESET_ZOOMED_EVENT_ID,
    SELECT_FILTERED_EVENTS_AND_ENTITIES,
    PREVENT_EVENT_MODAL_ACTIONS,
} from "./types";
import { RESET_SCENARIO_VIEW_SELECTED_THREADS } from "actions/scenarioViewSelectedThreadsActions";
import { upsertChartGraph } from "./chartGraphActions";
import { resetChartGraphGroupNodesMode } from "./chartGraphGroupNodesModeActions";
import { meObject } from "Components/Registry/Me";
import { upsertEntities } from "./entitiesActions";
import { duplicateScenario } from "./nodeEntityActions";
import { upsertEventDetails } from "./eventDetailsActions";
import store from "store";
import { upsertModifiers } from "./modifiersActions";
import AllAccountLedgers from "../helpers/ledgers/accountsAndLedgers.json";
import { correctLedgersAndUpsert } from "helpers/ledgers/ledgers";
import { upsertAccountMappings } from "./accountMappingActions";
import { createEmptyAccountMapping } from "helpers/accountMapping";
import { createAccountsAPI } from "./accountsActions";
import { loadScenario as newLoadScenario } from "./scenarioHelpers";
import { containerObject } from "Components/Registry/Container";
import { decisionObject } from "Components/Registry/Decision";
import { startObject } from "Components/Registry/Start";

export const requestHeader = () => {
    const user = localStorage.getItem("loggedInUser");
    const loggedInUser = JSON.parse(user) || {};
    const requestHeader = {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${loggedInUser.token || "WHATIFI-GUEST"}`,
            account: loggedInUser.account || "WHATIFI-GUEST",
            accept: "*/*",
            email: loggedInUser.email || "WHATIFI-GUEST",
            userRole: loggedInUser.userRole || "WHATIFI-GUEST",
        },
    };

    return requestHeader;
};

export const accountId = () => {
    const user = JSON.parse(localStorage.getItem("loggedInUser") ?? "{}");
    if (user?.account) return user.account;
    return "WHATIFI-GUEST";
};

export const saveUserDetails = (accountsData, data) => {
    const { account, email, name, token, userRole } = accountsData;
    const requestHeader = {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            account: account,
            accept: "*/*",
            email: email,
            userRole,
        },
    };
    return (dispatch) => {
        return axios
            .post(
                `${config.host}/user/userDetails`,
                {
                    account,
                    email,
                    name,
                    data,
                },
                requestHeader
            )
            .then(() => {
                data.selectedTags
                    ? window.Intercom("boot", {
                          app_id: "a0xv082m",
                          email,
                          name,
                          personal_whatifi: data.whatifiInput,
                          birth_month: data.birthMonth,
                          birth_year: data.birthYear,
                          whatifi_categories: data.selectedTags.toString(),
                          country: data.country,
                          state: data.state,
                      })
                    : window.Intercom("boot", {
                          app_id: "a0xv082m",
                          email,
                          name,
                          agency_whatifi: data.agencyGoal,
                          service_type: data.serviceType,
                          number_of_clients: data.numberOfClients,
                          team_size: data.teamSize,
                          agency_website: data.website,
                          agency_name: data.agencyName,
                          contact_name: data.contactName,
                          country: data.country,
                          state: data.state,
                      });

                dispatch({
                    type: SAVE_USER_DETAILS,
                    payload: data,
                });
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(accountsData);
                    Sentry.captureException(err);
                });
                console.log(err, "<---- err on saveUserDetails");
            });
    };
};

export const signup = (state, Mixpanel, role) => {
    let userRole = role;
    if (state.role) {
        userRole = state.role;
    }
    const { name, email, password } = state;
    return (dispatch) => {
        return axios
            .post(`${config.host}/auth/account`, {
                name,
                email,
                password,
                role: userRole,
            })
            .then((response) => {
                const accountData = {
                    name,
                    email,
                    image: null,
                    userRole,
                    ...response.data,
                };

                localStorage.setItem(
                    "loggedInUser",
                    JSON.stringify(accountData)
                );
                localStorage.setItem("userRole", userRole);

                Mixpanel.track("Sign up", {
                    name: name,
                    account: response.data.account,
                    email: email,
                    role: userRole,
                });
                // Mixpanel.identify(response.data.account);

                Mixpanel.people.set({
                    name: name,
                    $email: email, // only reserved properties need the $
                    "Sign up date": new Date(), // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
                    USER_ID: response.data.account, // use human-readable names
                    credits: 150, // ...or numbers
                });

                dispatch({
                    type: SIGNUP,
                    payload: accountData,
                });

                // Manually create request headers with newly created account information
                createAccountsAPI({
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${
                            accountData.token || "WHATIFI-GUEST"
                        }`,
                        account: accountData.account || "WHATIFI-GUEST",
                        accept: "*/*",
                        email: accountData.email || "WHATIFI-GUEST",
                        userRole: accountData.userRole || "WHATIFI-GUEST",
                    },
                });
                createEmptyAccountMapping();

                return accountData;
            })
            .catch((error) => {
                swal({
                    icon: "error",
                    title: "Email taken",
                    text: "The email you entered is already taken. please choose another email address.",
                });
                Sentry.withScope((scope) => {
                    scope.setExtras(state);
                    Sentry.captureException(error);
                });
                console.log(error, "<---- err on save signup");
            });
    };
};

export const login = (state, callback) => {
    const { email, password } = state;
    const encodedString = new Buffer(email + ":" + password).toString("base64");
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser")) || {};
    const requestHeader = {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${encodedString || "WHATIFI-GUEST"}`,
            account: loggedInUser.account || "WHATIFI-GUEST",
            accept: "*/*",
            email: loggedInUser.email || "WHATIFI-GUEST",
            userRole: loggedInUser.userRole || "WHATIFI-GUEST",
        },
    };
    return () => {
        return axios
            .get(`${config.host}/auth/login`, requestHeader)
            .then((response) => {
                if (response) {
                    callback(response.data);
                }
            })
            .catch((err) => {
                swal({
                    icon: "error",
                    title: "Email or Password does not match",
                    text: "The email or password you entered is incorrect.",
                });

                Sentry.withScope((scope) => {
                    scope.setExtras(state);
                    Sentry.captureException(err);
                });
                console.log(err, "<---- err on save login");
            });
    };
};

export const updateUserData = (data) => {
    const { token, account, userRole } = data;
    const requestHeader = {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token || "WHATIFI-GUEST"}`,
            account: account || "WHATIFI-GUEST",
            accept: "*/*",
            email: "WHATIFI-GUEST",
            userRole: userRole || "WHATIFI-GUEST",
        },
    };
    return () => {
        return axios
            .put(`${config.host}/user/details`, { ...data }, requestHeader)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                console.log(err, "<---- err!!!!");
                return err;
            });
    };
};

export const getUserData = ({ account, token, userRole }) => {
    const requestHeader = {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token || "WHATIFI-GUEST"}`,
            account: account || "WHATIFI-GUEST",
            accept: "*/*",
            email: "WHATIFI-GUEST",
            userRole: userRole || "WHATIFI-GUEST",
        },
    };
    return (dispatch) => {
        return axios
            .get(`${config.host}/user/details`, requestHeader)
            .then((response) => {
                if (response.data) {
                    const { data } = response.data;
                    const accountData = {
                        ...response.data,
                        image: null,
                        token,
                    };
                    const userDetails = { ...data };
                    if (data.userRole) {
                        // @Jenny - not sure where you wanted this so i plugged it in everywhere :)
                        accountData.userRole = data.userRole;
                        localStorage.setItem("userRole", data.userRole);
                        userDetails["userRole"] = data.userRole;
                    }

                    localStorage.setItem(
                        "userDetails",
                        JSON.stringify(userDetails)
                    );

                    dispatch({
                        type: GET_USER_DATA,
                        payload: accountData,
                    });
                }

                return response;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(account, token);
                    Sentry.captureException(err);
                });
                console.log(err, "<---- err on save getUserData");
            });
    };
};

export const saveFlinksBankData = (data, identifier = "") => {
    return (dispatch) => {
        console.log("SAVE FLINKS DATA", data);
        return axios
            .post(`${config.host}/flinksData`, { data }, requestHeader())
            .then((response) => {
                // save flinks id to local storage
                // key{identifier from bankInput.js} value{flinks }
                localStorage.setItem(identifier, data.loginId);
                dispatch({
                    type: FLINK_BANK_DATA,
                    payload: response.data,
                });
                return response;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(data);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err saveFlinksBankData");
            });
    };
};

/**
 *
 * @param {string} data.objectId
 * @param {boolean} data.update
 */
export const updateFlinksData = (data) => {
    return (dispatch) => {
        return axios
            .post(`${config.host}/flinksData/`, { data }, requestHeader())
            .then((response) => {
                dispatch({
                    type: FLINK_BANK_DATA,
                    payload: response.data,
                });
                return response;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(data);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err saveFlinksBankData");
            });
    };
};

const sortedUserScenario = (userScenarios, callback) => {
    userScenarios.sort((a, b) => {
        if (a.name === b.name) {
            return 0;
        } else {
            return a.name > b.name ? 1 : -1;
        }
    });

    callback(userScenarios);
};

export const getSampleScenario = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    return (dispatch) => {
        return axios
            .get(`${config.host}/sampleScenario`)
            .then((response) => {
                const sampleScenarios = Object.values(response.data).map(
                    (scenario) => {
                        const updatedNodes = scenario.data.nodes.map(
                            (node, index) => {
                                if (index === 0) {
                                    return {
                                        ...node,
                                        metadata: {
                                            ...node.metadata,
                                            name: loggedInUser
                                                ? loggedInUser.name.split(
                                                      " "
                                                  )[0]
                                                : "Me",
                                            tag: loggedInUser
                                                ? `@${
                                                      loggedInUser.name.split(
                                                          " "
                                                      )[0]
                                                  }`
                                                : "@Me",
                                        },
                                    };
                                }
                                return node;
                            }
                        );

                        const data = { ...scenario.data, nodes: updatedNodes };

                        scenario.type = "sample";
                        return {
                            ...scenario,
                            data,
                            type: "sample",
                        };
                    }
                );
                let finalData;
                sortedUserScenario(sampleScenarios, (data) => {
                    finalData = data;
                    dispatch({
                        type: SAMPLE_SCENARIO,
                        payload: data,
                    });
                });

                return finalData;
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getSampleScenario");
            });
    };
};

export const updateSampleScenario = (scenario, callback) => {
    return () => {
        return axios
            .put(
                `${config.host}/sampleScenario/${scenario.id}`,
                { ...scenario },
                requestHeader()
            )
            .then((response) => {
                callback(response);
            })
            .catch((err) => {
                console.log(err, "<==== ERRR");
            });
    };
};

export const createSampleScenario = (scenario, callback) => {
    return () => {
        return axios
            .post(
                `${config.host}/sampleScenario`,
                { ...scenario },
                requestHeader()
            )
            .then((response) => {
                callback(response);
            })
            .catch((err) => {
                console.log(err, "<==== ERRR");
            });
    };
};

export const deleteSampleScenario = (scenario, callback) => {
    return () => {
        return axios
            .delete(
                `${config.host}/sampleScenario/${scenario.id}`,
                requestHeader()
            )
            .then((response) => {
                callback(response);
            })
            .catch((err) => {
                console.log(err, "<==== ERRR");
            });
    };
};

export const setManager = (manager) => {
    return (dispatch) => {
        dispatch({
            type: SET_MANAGER,
            payload: manager,
        });
    };
};

export const setBackgroundManager = (backgroundManager) => {
    return (dispatch) => {
        dispatch({
            type: SET_BACKGROUND_MANAGER,
            payload: backgroundManager,
        });
    };
};

export const getUserScenarios = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/scenario`, requestHeader())
            .then((response) => {
                const userScenarios = Object.values(response.data.scenarios);
                sortedUserScenario(userScenarios, (data) => {
                    const scenarios = data.map((scenario) => {
                        scenario.type = "scenario";
                        return scenario;
                    });
                    const userScenarios = scenarios.filter(
                        (scenario) => !scenario.is_archived
                    );
                    const archivedScenarios = scenarios.filter(
                        (scenario) => scenario.is_archived
                    );

                    dispatch({
                        type: USER_SCENARIOS,
                        payload: { userScenarios, archivedScenarios },
                    });
                    return data;
                });

                return response;
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getUserScenarios");
            });
    };
};

export const getUserEntities = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/entities`, requestHeader())
            .then((response) => {
                dispatch(upsertEntities(response.data.entities));
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getUserEntities");
            });
    };
};

export const getUserOverrides = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/modifiers`, requestHeader())
            .then((response) => {
                dispatch(upsertModifiers(response.data.modifiers));
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getUserOverrides");
            });
    };
};

export const getUserAccount = () => {
    return () => {
        return axios
            .get(`${config.host}/accounts`, requestHeader())
            .then((response) => {
                const ledgers = response.data.ledger;
                correctLedgersAndUpsert(ledgers);
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getUserAccount");
                correctLedgersAndUpsert(AllAccountLedgers);
            });
    };
};

export const getUserAccountMapping = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/accountMapping`, requestHeader())
            .then((response) => {
                const mappings = response.data.mappings;
                dispatch(upsertAccountMappings(mappings));
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getUserAccountMapping");
                dispatch(
                    upsertAccountMappings({
                        Csvbox: {},
                        Railz: {},
                    })
                );
            });
    };
};

export const editScenario = (scenario, callback) => {
    return (dispatch) => {
        return axios
            .put(`${config.host}/scenario`, scenario, requestHeader())
            .then((response) => {
                dispatch({
                    type: EDIT_SCENARIO,
                    payload: scenario,
                });
                callback(null, response);
                return response;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(scenario);
                    Sentry.captureException(err);
                });
                callback(err, null);
                console.log(err, "<--- err editScenario");
                return err;
            });
    };
};

export const editScenarioComments = (scenario, callback) => {
    return (dispatch) => {
        return axios
            .put(`${config.host}/scenario`, scenario, requestHeader())
            .then((response) => {
                dispatch({
                    type: UPDATE_COMMENTS,
                    payload: scenario,
                });
                callback(null, response);
            })
            .catch((err) => {
                callback(err, null);
                Sentry.withScope((scope) => {
                    scope.setExtras(scenario);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err editScenarioComments");
            });
    };
};

export const createScenario = (scenario, range, callback) => {
    if (scenario.is_archived === null || scenario.is_archived === undefined)
        scenario.is_archived = false;
    return (dispatch) => {
        return axios
            .post(`${config.host}/scenario`, scenario, requestHeader())
            .then((response) => {
                if (response) {
                    dispatch({
                        type: CREATE_SCENARIO,
                        payload: response.data.id,
                        range,
                    });
                }
                callback(response);

                // EXTERNAL TODO: container handles ctrl-z/r - manager pops from actions/adds from redo queue(new)
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(scenario);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err createScenario");
            });
    };
};

export const loadScenario = (
    scenario,
    manager,
    baselineDataManager,
    baselineId = null,
    eventLibrary,
    isBaseline = false
) => {
    if (!scenario) scenario = { data: { nodes: [] } };
    let sendToDecisionEngine = false || scenario.type === "shared";

    // TODO: this loop is a fix for old scenarios
    scenario.data.nodes.forEach((node) => {
        if (node.type === meObject.constant()) {
            // COMMENTED OUT FOR TESTING PURPOSES
            // node.valid = meObject.checkInput(node);
            node.valid = true;
            // node.isFilled = meObject.checkInput(node);
            node.isFilled = true;
        }
    });
    if (
        baselineDataManager &&
        scenario.type !== "baseline" &&
        !baselineId &&
        baselineDataManager.isFirstLoad &&
        !isBaseline
    ) {
        baselineDataManager.setActiveBaseline(
            baselineDataManager.getDefaultBaseline(scenario).id
        );
        const bl = baselineDataManager.getActiveBaseline();
        if (bl && bl.data) {
            manager.importBaseline(bl.data);
        } else {
            manager.resetBaseline();
        }
        baselineDataManager.setIsFirstLoad(false);
    } else if (baselineDataManager && baselineId) {
        baselineDataManager.setActiveBaseline(baselineId);
        baselineDataManager.importBaseline(
            baselineDataManager.getActiveBaseline().data
        );
        sendToDecisionEngine = true;
    }

    function findLibraryNodeWithId(id, nodes) {
        for (const node of nodes) {
            if (node.libraryEventId === id) {
                return node;
            }
        }
        // else if node not found
        return null;
    }
    if (eventLibrary && scenario.data.nodes) {
        const nodesCopy = [...scenario.data.nodes];
        for (let i = 0; i < scenario.data.nodes.length; i++) {
            const node = scenario.data.nodes[i];
            if (node.isLibraryEvent) {
                const libraryData = findLibraryNodeWithId(
                    node.libraryEventId,
                    eventLibrary[node.type]
                );
                if (!libraryData) continue;
                else nodesCopy[i] = _.cloneDeep(libraryData);
            }
        }
        scenario.data.nodes = nodesCopy;
    }

    if (store.getState().scenario.pauseDecisionEngine) {
        sendToDecisionEngine = false;
    }

    manager.importData(scenario.data, sendToDecisionEngine, isBaseline);

    let expiredCount = [];
    let outdatedCount = [];

    if (scenario.data.nodes) {
        expiredCount = scenario.data.nodes.filter((node) => {
            const nodeValue = manager._findEvent(node.id);
            return nodeValue.isNodeExpired() && !nodeValue.isBaseline();
        });
    }

    if (scenario.data.nodes) {
        outdatedCount = scenario.data.nodes.filter((node) => {
            const nodeValue = manager._findEvent(node.id);
            return nodeValue.isNodeOutdated() && !nodeValue.isBaseline();
        });
    }

    if (scenario?.type && scenario?.type !== "baseline") {
        localStorage.setItem("loadedScenario", JSON.stringify(scenario));
    }

    return (dispatch) => {
        dispatch({
            type: RESET_SCENARIO_VIEW_SELECTED_THREADS,
        });
        dispatch(resetChartGraphGroupNodesMode());
        dispatch({
            type: LOAD_SCENARIO,
            payload: scenario,
            expiredNode: expiredCount,
            outdatedNode: outdatedCount,
        });
    };
};

export const deleteScenario = (scenarioId, manager, sampleScenarios) => {
    return (dispatch) => {
        return axios
            .delete(`${config.host}/scenario/${scenarioId}`, requestHeader())
            .then((response) => {
                manager.importData(sampleScenarios[0].data);

                localStorage.setItem(
                    "loadedScenario",
                    JSON.stringify(sampleScenarios[0])
                );

                dispatch({
                    type: DELETE_SCENARIO,
                    payload: scenarioId,
                });

                return response;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(scenarioId, manager, sampleScenarios);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err deleteScenario");
            });
    };
};

export const setFocus = (node) => {
    return (dispatch, getState) => {
        dispatch(setFocusSimple(node));
        if (!node) return;

        const chartGraphGroupNodesMode = getState().chartGraphGroupNodesMode;
        // Determine if we need to update the chart graph. If mode === "default" || user has pinned a ndoe, we don't need to update graphs.
        if (
            chartGraphGroupNodesMode !== "default"
            // && not pinned // We only update the chartgraph if both of these are true
        ) {
            const curNodeIds = getState().chartGraph.nodeIds;
            if (chartGraphGroupNodesMode === "singleNode") {
                const nodeIds = [node.id];
                if (_.isEqual(curNodeIds, nodeIds)) return;

                dispatch(
                    upsertChartGraph({
                        nodeIds,
                    })
                );
            } else if (chartGraphGroupNodesMode === "cumulativeNodes") {
                const manager = getState().scenario.manager;
                const curThread = getState().scenario?.highlightedThread;
                const nodeIds = manager.getNodesFromThreadUpToNode(
                    curThread.signature,
                    node.id
                );
                // DISCUSSION: Do we need to sort nodeIds?

                const curNodeIds = getState().chartGraph.nodeIds;
                if (_.isEqual(curNodeIds, nodeIds)) return;
                dispatch(
                    upsertChartGraph({
                        nodeIds,
                    })
                );
            }
        }
    };
};

export const setFocusSimple = (node) => ({
    type: SET_FOCUS,
    payload: node,
});

export const setLineFocus = (source, target) => {
    return (dispatch) => {
        dispatch({
            type: SET_LINE_FOCUS,
            source,
            target,
        });
    };
};

export const resetFocus = (node) => {
    return (dispatch) => {
        dispatch({
            type: RESET_FOCUS,
            payload: node,
        });
    };
};

export const hasPermission = (permission, scenario, email, account) => {
    if (account && account === scenario.account) {
        return true;
    } else if (
        scenario.permissions &&
        scenario.permissions.whitelist &&
        scenario.permissions.whitelist[email] &&
        scenario.permissions.whitelist[email][permission]
    ) {
        return true;
    } else if (
        scenario.permissions &&
        scenario.permissions.default &&
        scenario.permissions.default[permission]
    ) {
        return true;
    } else {
        return false;
    }
};

export const updateNode = (updatedNode, loadedScenario, manager, { id }) => {
    if (loadedScenario) {
        const header = requestHeader();
        const { email, account } = header.headers;
        const updatedValue = loadedScenario.data.nodes.map((data) => {
            if (data.id === id) {
                const newData = {
                    ...data,
                    ...updatedNode,
                };
                return newData;
            }
            return data;
        });

        const scenario = {
            ...loadedScenario,
            data: {
                version: loadedScenario.data.version,
                nodes: [...updatedValue],
                root: loadedScenario.data.root,
                favouriteAccounts: loadedScenario?.data?.favouriteAccounts
                    ? loadedScenario?.data?.favouriteAccounts
                    : {},
                canvasCardIds: loadedScenario?.data?.canvasCardIds
                    ? loadedScenario?.data?.canvasCardIds
                    : [],
            },
        };

        if (hasPermission("edit", loadedScenario, email, account)) {
            return (dispatch) => {
                return axios
                    .put(`${config.host}/scenario`, scenario, header)
                    .then((response) => {
                        if (response) {
                            // Does not do anything
                            dispatch({
                                type: UPDATE_NODE_CLEAR_FOCUS,
                                payload: scenario,
                            });

                            newLoadScenario(scenario, manager);
                        }
                        return response;
                    })
                    .catch((err) => {
                        Sentry.withScope((scope) => {
                            scope.setExtras(
                                updatedNode,
                                loadedScenario,
                                manager
                            );
                            Sentry.captureException(err);
                        });
                        console.log(err, "<--- err updateNode");
                    });
            };
        } else {
            return (dispatch) => {
                newLoadScenario(scenario, manager);
                dispatch({
                    type: UPDATE_NODE_CLEAR_FOCUS,
                    payload: scenario,
                });
                return Promise.resolve({
                    toast: "You are editing a view-only scenario. Changes will not persist.",
                });
            };
        }
    }
};

export const handleDecisionEnginePause = (action) => {
    const pauseDecisionEngine = action !== "run";

    return (dispatch) => {
        dispatch({
            type: PAUSE_DECISION_ENGINE,
            payload: pauseDecisionEngine,
        });
    };
};

export const updateNodeKeepFocus = (updatedNode, loadedScenario, { id }) => {
    if (loadedScenario) {
        const header = requestHeader();
        const { email, account } = header.headers;
        const updatedValue = loadedScenario.data.nodes.map((data) => {
            if (data.id === id) {
                const newData = {
                    ...data,
                    ...updatedNode,
                };
                return newData;
            }
            return data;
        });

        const scenario = {
            ...loadedScenario,
            data: {
                version: loadedScenario.data.version,
                nodes: [...updatedValue],
                root: loadedScenario.data.root,
            },
        };

        if (hasPermission("edit", loadedScenario, email, account)) {
            return (dispatch) => {
                return axios
                    .put(`${config.host}/scenario`, scenario, header)
                    .then((response) => {
                        if (response) {
                            dispatch({
                                type: UPDATE_NODE,
                                payload: scenario,
                            });
                        }
                        return response;
                    })
                    .catch((err) => {
                        Sentry.withScope((scope) => {
                            scope.setExtras(updatedNode, loadedScenario);
                            Sentry.captureException(err);
                        });
                        console.log(err, "<--- err updateNode");
                    });
            };
        } else {
            return (dispatch) => {
                // loadScenario(scenario, manager, baselineDataManager)(dispatch);
                dispatch({
                    type: UPDATE_NODE,
                    payload: scenario,
                });
                return Promise.resolve({
                    toast: "You are editing a view-only scenario. Changes will not persist.",
                });
            };
        }
    }
};

export const clearSelectedEvents = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SELECTED_EVENTS,
        });
    };
};

export const selectEvent = (eventId, location) => {
    return (dispatch, getState) => {
        const manager = getState()?.scenario?.manager;
        const selectedEvent = manager?._findEvent(eventId);
        if (
            selectedEvent?.type === containerObject?.constant() ||
            selectedEvent?.type === decisionObject?.constant() ||
            selectedEvent?.type === startObject?.constant()
        )
            return;
        dispatch({
            type: SELECT_EVENT,
            payload: { eventId, location },
        });
    };
};

export const selectEntity = (entityId) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_ENTITY,
            payload: entityId,
        });
    };
};

export const filterEventAndEntityData = (data) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_FILTERED_EVENTS_AND_ENTITIES,
            payload: data,
        });
    };
};

export const setZoomedEvent = (eventId) => {
    return (dispatch) => {
        dispatch({
            type: SET_ZOOMED_EVENT_ID,
            payload: eventId,
        });
    };
};

export const resetZoomedEvent = (eventId) => {
    return (dispatch) => {
        dispatch({
            type: RESET_ZOOMED_EVENT_ID,
            payload: eventId,
        });
    };
};

export const updateSuccess = () => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SUCCESS,
        });
    };
};

export const closeModal = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_MODAL,
        });
    };
};

export const copyScenario = () => {
    return (dispatch) => {
        dispatch({
            type: COPY_SCENARIO,
        });
    };
};

export const resetSource = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_SOURCE,
        });
    };
};

export const setSource = (source) => {
    return (dispatch) => {
        dispatch({
            type: SET_SOURCE,
            payload: source,
        });
    };
};

export const collectNode = (canvas, manager) => {
    return (dispatch) => {
        dispatch({
            type: COLLECT_NODE,
            canvas,
            manager,
        });
    };
};

export const disableCollectNode = () => {
    return (dispatch) => {
        dispatch({
            type: DISABLE_COLLECT_NODE,
        });
    };
};

export const removeCollectedNode = (id) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_COLLECTED_NODE,
            payload: id,
        });
    };
};

export const cancelCollectMode = () => {
    return (dispatch) => {
        dispatch({
            type: CANCEL_COLLECT_MODE,
        });
    };
};

export const cancelActions = () => {
    return (dispatch) => {
        dispatch({
            type: CANCEL_ACTIONS,
        });
    };
};

export const popUpOption = (focus, line) => {
    return (dispatch) => {
        dispatch({
            type: POP_UP_OPTION,
            focus,
            line,
        });
    };
};

export const closeOption = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_POP_UP_OPTION,
        });
    };
};

export const changeSelectedOption = (selected) => {
    return (dispatch) => {
        dispatch({
            type: SELECTED_OPTION,
            payload: selected,
        });
    };
};

export const setOptionPickerTab = (tabName) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_OPTIONS_TAB,
            payload: tabName,
        });
    };
};
export const closeEventsModal = () => {
    return (dispatch) => {
        dispatch({
            type: CLOSE_EVENTS_MODAL,
        });
    };
};

export const shareScenario = (id) => {
    return (dispatch) => {
        const urlLink = `${config.shareScenarioUrl}/shareScenario/${id}`;
        dispatch({
            type: SHARE_SCENARIO,
            link: urlLink,
        });
    };
};

export const cancelShareScenario = () => {
    return (dispatch) => {
        dispatch({
            type: CANCEL_SHARE_SCENARIO,
        });
    };
};

export const sendEmail = (emails) => {
    return (dispatch) => {
        return axios
            .post(`${config.host}/sendEmail`, { emails }, requestHeader())
            .then((response) => {
                console.log(response, "<---- RESPONSE???");
                dispatch({
                    type: SEND_EMAIL,
                });
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(emails);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err sendEmail");
            });
    };
};

/**
 * @param  {} emails - of recipients
 * @param  {} scenarioId
 * @param  {} whitelist - replaces existing scenario whitelist
 */
export const shareByEmail = (emails, scenarioId, whitelist) => {
    if (!whitelist) return;

    return (dispatch) => {
        return axios
            .post(
                `${config.host}/shareScenario/share`,
                { emails, id: scenarioId, whitelist },
                requestHeader()
            )
            .then(() => {
                dispatch({ type: SHARE_BY_EMAIL });
            })
            .catch((err) => {
                console.log(err, "<==== ERROR in shareByEmail");
            });
    };
};

export const performTemplateShare = (recipientEmail, scenario) => {
    return (dispatch) => {
        return axios
            .post(
                `${config.host}/shareScenario/template`,
                { recipientEmail, scenario, id: scenario.id },
                requestHeader()
            )
            .then(() => {
                dispatch({ type: SHARE_TEMPLATE });
            })
            .catch((err) => {
                console.log(err, "<==== ERROR in performTemplateShare");
            });
    };
};

export const fetchSharedScenarios = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/shareScenario/`, requestHeader())
            .then((data) => {
                if (!data?.data?.scenarios) return data;
                if (data.data.scenarios?.length > 0) {
                    const newSharedData = data.data.scenarios.map((shared) => {
                        const newShared = { ...shared, type: "shared" };
                        return newShared;
                    });

                    dispatch({
                        type: SHARED_SCENARIO_PER_ACCOUNT,
                        data: newSharedData,
                    });
                    dispatch(upsertEntities(data.data.entities));

                    if (data.data.modifiers) {
                        dispatch(upsertModifiers(data.data.modifiers));
                    }

                    if (data.data.ledgers) {
                        correctLedgersAndUpsert(data.data.ledgers);
                    }

                    return data;
                }
                return data;
            })
            .catch((err) => {
                // Sentry.withScope((scope) => {
                //     scope.setExtras(id);
                //     Sentry.captureException(err);
                // });
                console.log(err, "<--- there is no fetchSharedScenarios");
            });
    };
};

export const getSharedScenario = (id) => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/shareScenario/${id}`, requestHeader())
            .then((data) => {
                // For future reference, as of now the structure of the response is seems to be variable.
                // As far as I am aware depending on whether or not the user has one or multiple scenarios
                // being shared with them, the structure of the response object differs. Below I've handled both
                // cases, this is a hacky fix.
                if (!data?.data && !data?.data?.scenarios) return;
                const newSharedData = data?.data?.scenarios
                    ? data.data.scenarios.map((shared) => {
                          const newShared = { ...shared, type: "shared" };
                          return newShared;
                      })
                    : data.data.map((shared) => {
                          const newShared = { ...shared, type: "shared" };
                          return newShared;
                      });
                dispatch({
                    type: GET_SHARED_SCENARIO,
                    data: newSharedData,
                });
                dispatch(upsertEntities(data.data.entities));

                if (data.data.modifiers) {
                    dispatch(upsertModifiers(data.data.modifiers));
                }

                if (data.data.ledgers) {
                    correctLedgersAndUpsert(data.data.ledgers);
                }
                return newSharedData;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(id);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getSharedScenario");
            });
    };
};

export const updateScenario = (scenario) => {
    return () => {
        return axios
            .put(`${config.host}/scenario/`, scenario, requestHeader())
            .catch((err) => {
                console.log(err);
            });
    };
};

export const updateScenarioCanvas = (
    scenario,
    newScenario,
    manager,
    shared,
    baselineDataManager,
    callback
) => {
    const header = requestHeader();
    const { email, account } = header.headers;
    return (dispatch) => {
        if (hasPermission("edit", newScenario, email, account)) {
            return axios
                .put(`${config.host}/scenario`, newScenario, requestHeader())
                .then((response) => {
                    loadScenario(newScenario, manager, baselineDataManager);
                    dispatch({
                        type: UPDATE_SCENARIO_CANVAS,
                    });
                    callback(response);
                })
                .catch((err) => {
                    Sentry.withScope((scope) => {
                        scope.setExtras(scenario, newScenario, manager, shared);
                        Sentry.captureException(err);
                    });
                    console.log(err, "<--- err updateScenarioCanvas");
                });
        } else {
            loadScenario(newScenario, manager, baselineDataManager);
            dispatch({
                type: UPDATE_SCENARIO_CANVAS,
            });
            callback(null);
        }
    };
};

export const updateBaselineCanvas = (scenario, manager) => {
    return () => {
        return axios
            .put(`${config.host}/scenario`, scenario, requestHeader())
            .then((response) => {
                // loadScenario(scenario, manager);
                return response;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(scenario, manager);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err updateBaselineCanvas");
            });
    };
};

export const editNode = (node) => {
    return (dispatch) => {
        dispatch({
            type: EDIT_NODE,
            payload: node,
        });
    };
};

export const selectLine = (line) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_LINE,
            line,
        });
    };
};

export const updateRating = (rating, focus, manager, loadedScenario) => {
    const isScenario =
        loadedScenario.type !== "shared" && loadedScenario.type !== "sample";
    const isPassed =
        loadedScenario.type === "shared" &&
        loadedScenario.access === "can edit";
    return (dispatch) => {
        if (loadedScenario && (isScenario || isPassed)) {
            const updatedNode = loadedScenario.data.nodes.map((node) => {
                if (node.id === focus.id) {
                    const newNode = {
                        ...node,
                        metadata: {
                            ...node.metadata,
                            rating,
                        },
                    };
                    return newNode;
                }
                return node;
            });

            // return (dispatch) => {
            const scenario = {
                ...loadedScenario,
                data: {
                    version: loadedScenario.data.version,
                    nodes: [...updatedNode],
                    root: loadedScenario.data.root,
                },
            };

            return axios
                .put(`${config.host}/scenario`, scenario, requestHeader())
                .then((response) => {
                    if (response) {
                        dispatch({
                            type: UPDATE_RATING,
                            payload: scenario,
                        });

                        loadScenario(scenario, manager);
                        getUserScenarios()(dispatch);
                    }
                    return response;
                })
                .catch((err) => {
                    Sentry.withScope((scope) => {
                        scope.setExtras(rating, focus, manager, loadedScenario);
                        Sentry.captureException(err);
                    });
                    console.log(err, "<--- err updateRating");
                });
            // };
        }
    };
};

export const setHighlightedThread = (selectedThread) => {
    return (dispatch) => {
        dispatch({
            type: HIGHLIGHTED_THREAD,
            payload: selectedThread,
        });
    };
};

export const setNodeGraphState = (state) => {
    return (dispatch) => {
        dispatch({
            type: NODEGRAPH_STATE,
            payload: state,
        });
    };
};

export const getWalkScore = (address, lat, lng, callback) => {
    return () => {
        return axios
            .post(
                `${config.host}/walkScore`,
                {
                    address,
                    lat,
                    lng,
                },
                requestHeader()
            )
            .then((data) => {
                if (data) {
                    callback(null, data.data);
                }
            })
            .catch((err) => {
                callback(err, null);
                Sentry.withScope((scope) => {
                    scope.setExtras(address, lat, lng);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err getWalkScore");
            });
    };
};

export const showNotification = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_NOTIFICATION,
        });
    };
};

export const hideNotification = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_NOTIFICATION,
        });
    };
};

export const updateSharedScenario = (scenario) => {
    return (dispatch) => {
        return axios
            .put(`${config.host}/scenario`, scenario, requestHeader())
            .then((response) => {
                if (response) {
                    dispatch({
                        type: UPDATE_NODE_CLEAR_FOCUS,
                        payload: scenario,
                    });
                }
                return response;
            })
            .catch((err) => {
                Sentry.withScope((scope) => {
                    scope.setExtras(scenario);
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err updateNode");
            });
    };
};

export const createSubscription = (data, history, callback = null) => {
    console.log(data, "<=== looking for subscription id");
    return () => {
        return axios
            .post(
                `${config.host}/chargebee/createSubscription`,
                { data },
                requestHeader()
            )
            .then((data) => {
                console.log(data, "<==== DATA FROM CHARGEBEE");
                if (data.data.createNew) {
                    window.open(data.data.hosted_page.url);
                } else {
                    swal({
                        icon: "success",
                        title: "Success!",
                        text: "Your plan is successfully updated!",
                        buttons: {
                            close: true,
                        },
                    }).then((value) => {
                        if (value === "close") {
                            return history.push("/");
                        } else {
                            return history.push("/");
                        }
                    });
                }
                callback && callback();
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
                console.log(err, "<--- err createSubscription");
                throw err;
            });
    };
};

export const openPortal = (data, history) => {
    return () => {
        return axios
            .post(
                `${config.host}/chargebee/openPortal`,
                { data },
                requestHeader()
            )
            .then((data) => {
                if (data) {
                    window.open(data.data.access_url);
                }
            })
            .catch((err) => {
                history.push("/billing");
                console.log(err, "<--- err openPortal");
            });
    };
};

// retrieves and sets plan for loggedInUser
/**
 * @param  {object} data - loggedInUser
 * @param  {function(err, data)} callback
 */
export const getSubscription = (data, callback) => {
    return (dispatch) => {
        return axios
            .post(
                `${config.host}/chargebee/getSubscription`,
                { data },
                requestHeader()
            )
            .then((data) => {
                if (data.data.plan !== undefined) {
                    const loggedInUser = JSON.parse(
                        localStorage.getItem("loggedInUser")
                    );
                    if (loggedInUser) {
                        loggedInUser.plan = data.data.plan;
                        localStorage.setItem(
                            "loggedInUser",
                            JSON.stringify(loggedInUser)
                        );
                    }
                    dispatch({
                        type: GET_SUBSCRIPTION,
                        payload: data.data.plan,
                    });
                }
                callback && callback(null, data);
            })
            .catch((err) => {
                callback && callback(err, null);
                console.log(err, "<--- err getSubscription");
            });
    };
};

/**
 * @param  {function(err, data)} callback
 */
export const getAllPlans = (callback) => {
    return () => {
        return axios
            .get(`${config.host}/chargebee/getAllPLans`, requestHeader())
            .then((data) => {
                callback && callback(null, data);
            })
            .catch((err) => {
                callback && callback(err, null);
                console.log(err, "<--- err getSubscription");
            });
    };
};

export const launchAddCard = () => {
    return () => {
        return axios
            .get(`${config.host}/chargebee/addPayment`, requestHeader())
            .then((res) => {
                window.open(res.data.hosted_page.url);
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const setOnboardingState = (state) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_STATE,
            payload: state,
        });
    };
};

export const setOnboardingData = (data) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_DATA,
            payload: data,
        });
    };
};

export const resetPassword = (email, callback) => {
    return () => {
        return axios
            .post(
                `${config.host}/auth/resetPassword`,
                { email },
                requestHeader()
            )
            .then((data) => {
                callback(null, data);
            })
            .catch((err) => {
                callback(err, null);
            });
    };
};

export const resetCode = (email, code, callback) => {
    return () => {
        return axios
            .post(
                `${config.host}/auth/verifyResetCode`,
                { email, code },
                requestHeader()
            )
            .then((data) => {
                callback(null, data);
            })
            .catch((err) => {
                callback(err, null);
            });
    };
};

export const updatePassword = (
    newPassword,
    code,
    email,
    account,
    token,
    callback
) => {
    const requestHeader = {
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token || "WHATIFI-GUEST"}`,
            account: account || "WHATIFI-GUEST",
            accept: "*/*",
            email: email,
        },
    };
    return () => {
        return axios
            .post(
                `${config.host}/auth/verifyPasswordResetCode`,
                { newPassword, code, email },
                requestHeader
            )
            .then((data) => {
                callback(null, data);
            })
            .catch((err) => {
                callback(err, null);
            });
    };
};

export const onboardingScenario = (scenario) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SCENARIO,
            payload: scenario,
        });
    };
};

export const getPlaidAccount = (data, callback) => {
    return () => {
        return axios
            .post(`${config.host}/plaid/getAccount`, { data }, requestHeader())
            .then((data) => {
                callback(null, data);
            })
            .catch((err) => {
                callback(err, null);
            });
    };
};

export const createScenarioPopup = (value) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CREATE_SCENARIO,
            payload: value,
        });
    };
};

export const showLogin = (value, data, type) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_LOGIN,
            payload: { bool: value, data, type },
        });
    };
};

export const selectTab = (value) => {
    return (dispatch) => {
        dispatch({
            type: TAB_SELECTED,
            payload: value,
        });
    };
};

export const showOptionPicker = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_OPTION_PICKER,
        });
    };
};

export const createBaseline = (data, callback) => {
    return () => {
        return axios
            .post(`${config.host}/baseline/`, { data }, requestHeader())
            .then((data) => {
                callback(null, data);
                return data;
            })
            .catch((err) => {
                callback(err, null);
            });
    };
};

export const deleteBaseline = (
    baselineId,
    baselineDataManager,
    agencyClients = []
) => {
    return (dispatch) => {
        return axios
            .delete(`${config.host}/baseline/${baselineId}`, requestHeader())
            .then((data) => {
                baselineDataManager.deleteBaseline(baselineId);
                let clientToUpdate;
                agencyClients.forEach((client) => {
                    if (client.baseline === baselineId) {
                        client.baseline = null;
                        clientToUpdate = client;
                    }
                });
                if (clientToUpdate) {
                    dispatch(updateClient(clientToUpdate));
                }
                return data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

/**
 * Sets flag for areBaselinesLoaded
 * @param {boolean} newVal - New value
 */
export const setAreBaselinesLoaded = (newVal) => {
    return (dispatch) => {
        dispatch({
            type: ARE_BASELINES_LOADED,
            payload: newVal,
        });
    };
};

/**
 * Retrieves all baselines owned by and shared with the LoggedInUser
 * @param {BaselineDataManager} baselineDataManager - The singleton BaselineDataManager
 */
export const getBaseline = (baselineDataManager) => {
    return (dispatch) => {
        setAreBaselinesLoaded(false)(dispatch);
        return axios
            .get(`${config.host}/baseline/`, requestHeader())
            .then((data) => {
                return axios
                    .get(`${config.host}/shareBaseline/`, requestHeader())
                    .then((sharedData) => {
                        const baselineData = (data?.data ?? []).concat(
                            sharedData?.data?.scenarios ?? []
                        );
                        if (baselineData.length == 0) return;

                        dispatch({
                            type: GET_BASELINE,
                            payload: baselineData,
                        });

                        dispatch(upsertEntities(sharedData.data.entities));

                        if (baselineDataManager) {
                            baselineDataManager.setBaselines(baselineData);
                        }
                        setAreBaselinesLoaded(true)(dispatch);
                        return baselineData;
                    });
            })
            .catch((err) => {
                // Sentry.withScope((scope) => {
                //     Sentry.captureException(err);
                // });
                console.log(err, "<---- baseline error");
            });
    };
};

export const getSingleBaseline = (baselineId) => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/shareBaseline/${baselineId}`, requestHeader())
            .then((result) => {
                if (!result?.data?.scenarios?.[0]) return null;

                dispatch(upsertEntities(result.data.entities));
                return result.data.scenarios[0];
            })
            .catch(() => {
                return null;
            });
    };
};

export const updateBaseline = (newBaseline, baselineDataManager) => {
    return () => {
        return axios
            .put(
                `${config.host}/baseline/update`,
                { baseline: newBaseline },
                requestHeader()
            )
            .then(() => {
                baselineDataManager.updateExistingBaseline(newBaseline);
                return baselineDataManager.getActiveBaseline();
            })
            .catch((err) => {
                console.error("in error", err);
                return err;
            });
    };
};

export const shareBaseline = (emails, scenarioId) => {
    const whitelist = {};
    for (const email of emails) {
        whitelist[email] = {
            view: true,
            edit: false,
            comment: false,
            share: false,
            clone: false,
            changePermissions: false,
        };
    }
    return () => {
        return axios.post(
            `${config.host}/shareBaseline/share`,
            { emails, id: scenarioId, whitelist },
            requestHeader()
        );
    };
};

export const onhandleFocusedInput = (value) => {
    return (dispatch) => {
        dispatch({
            type: HOVERED_INPUT,
            payload: value,
        });
    };
};
export const onHandleFocusValue = (value) => {
    return (dispatch) => {
        dispatch({
            type: FOCUS_VALUE,
            payload: value,
        });
    };
};
export const saveAdminValues = (key, values) => {
    return () => {
        return axios
            .post(
                `${config.host}/admin/saveValues`,
                { key, values },
                requestHeader()
            )
            .then((data) => {
                return data;
            })
            .catch((err) => {
                console.log(err, "<---- ERR!!");
                return err;
            });
    };
};

export const getAdminValues = (key) => {
    return () => {
        return axios
            .get(`${config.host}/admin/getValues/${key}`, requestHeader())
            .then((data) => {
                return data;
            })
            .catch((err) => {
                return err;
            });
    };
};

export const updateAdminValues = (key, values) => {
    return () => {
        return axios
            .put(
                `${config.host}/admin/updateValues`,
                { key, values },
                requestHeader()
            )
            .then((data) => {
                return data;
            })
            .catch((err) => {
                return err;
            });
    };
};

export const setBaselineManager = (manager) => {
    return (dispatch) => {
        return dispatch({
            type: BASELINE_MANAGER,
            payload: manager,
        });
    };
};

export const setBaselineDataManager = (manager) => {
    return (dispatch) => {
        return dispatch({
            type: BASELINE_DATA_MANAGER,
            payload: manager,
        });
    };
};

export const closeExpiredNodeModal = () => {
    return (dispatch) => {
        return dispatch({
            type: CLOSE_EXPIRED_NODE,
        });
    };
};

export const closeOutdatedNodeModal = () => {
    return (dispatch) => {
        return dispatch({
            type: CLOSE_OUTDATED_NODE,
        });
    };
};

export const toggleAccountType = (value) => {
    return (dispatch) => {
        return dispatch({
            type: TOGGLE_ACCOUNT_TYPE,
            payload: value,
        });
    };
};

export const getClientData = (idString) => {
    return () => {
        const params = requestHeader();
        params["headers"]["id"] = idString;
        axios
            .get(`${config.host}/agencyData/getClient`, params)
            .then((data) => {
                return data;
            })
            .catch((err) => {
                return err;
            });
    };
};

export const getAgencyClients = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/agencyData/getAgencyClients`, requestHeader())
            .then((data) => {
                dispatch({
                    type: GET_AGENCY_CLIENTS,
                    payload: _.sortBy(data.data, [
                        function (o) {
                            return o.clientdata && o.clientdata.name;
                        },
                    ]),
                });
            })
            .catch((err) => {
                console.log(err, "<=== err");
                dispatch({ type: GET_AGENCY_CLIENTS, payload: [] });
            });
    };
};

//post client data
export const addClient = (data) => {
    if (data && data.clientdata && data.clientdata.countries)
        data.clientdata.countries = null;
    return (dispatch) => {
        axios
            .post(
                `${config.host}/agencyData/createClient`,
                { data },
                requestHeader()
            )
            .then(() => {
                dispatch({ type: ADD_CLIENT, payload: data });
            })
            .catch((err) => {
                return err;
            });
    };
};

export const updateClient = (data) => {
    return (dispatch) => {
        return axios
            .put(
                `${config.host}/agencyData/updateClient`,
                { data },
                requestHeader()
            )
            .then((response) => {
                dispatch({ type: UPDATE_CLIENT, payload: data });
                return response;
            })
            .catch((err) => {
                return err;
            });
    };
};

export const deleteClient = (idString) => {
    return (dispatch) => {
        const params = requestHeader();
        params["headers"]["id"] = idString;
        return axios
            .delete(`${config.host}/agencyData/deleteClient`, params)
            .then(() => {
                dispatch({ type: DELETE_CLIENT, payload: idString });
            })
            .catch((err) => {
                return err;
            });
    };
};

// retrieves the full event library for the logged in user
export const getLibraryEvents = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/library`, requestHeader())
            .then((response) => {
                const eventLibrary = response.data.data || {};
                supportedLibraryEvents.forEach((category) => {
                    if (!eventLibrary[category.type])
                        eventLibrary[category.type] = [];
                });
                dispatch({
                    type: GET_LIBRARY,
                    payload: eventLibrary,
                });
                return response || {};
            })
            .catch((err) => {
                console.log(err);
                return [];
            });
    };
};

export const toggleIsAddingLibraryEvent = (newValue) => {
    return (dispatch) => {
        dispatch({
            type: ADDING_LIBRARY_EVENT,
            payload: newValue,
        });
        return newValue;
    };
};
/**
 * @param  {object[]} events
 * @param  {string} type - all events must be of this type
 */
export const addLibraryEvents = (events, type, eventLibrary) => {
    events.forEach((eventData) => {
        eventData.isLibraryEvent = true;
        if (!eventData.libraryEventId) eventData.libraryEventId = uuid.v4();
    });

    return (dispatch) => {
        return axios
            .put(`${config.host}/library`, { events, type }, requestHeader())
            .then((response) => {
                const newEventLibrary = { ...eventLibrary };
                if (!newEventLibrary[type]) newEventLibrary[type] = [];
                newEventLibrary[type] = eventLibrary[type].concat(events);
                dispatch({
                    type: GET_LIBRARY,
                    payload: newEventLibrary,
                });
                dispatch({
                    type: ADDING_LIBRARY_EVENT,
                    payload: false,
                });
                return response;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    };
};
/**
 * @param  {object} eventData - the event to be updated
 */
export const updateLibraryEvent = (eventData, eventLibrary) => {
    return (dispatch) => {
        return axios
            .put(
                `${config.host}/library`,
                { events: [eventData], type: eventData.type },
                requestHeader()
            )
            .then((response) => {
                const newEventLibrary = { ...eventLibrary };

                let index;
                newEventLibrary[eventData.type].forEach((entry, i) => {
                    if (entry.libraryEventId === eventData.libraryEventId) {
                        index = i;
                    }
                });
                newEventLibrary[eventData.type][index] = eventData;
                dispatch({
                    type: GET_LIBRARY,
                    payload: newEventLibrary,
                });
                return response;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    };
};

/**
 * @param  {string[]} eventIds
 * @param  {string} type - all events must be of this type
 */
export const deleteLibraryEvents = (eventIds, type, eventLibrary) => {
    return (dispatch) => {
        const headers = requestHeader();
        return axios
            .delete(`${config.host}/library`, {
                headers: headers.headers,
                data: { eventIds, type },
            })
            .then((response) => {
                const newEventLibrary = { ...eventLibrary };
                newEventLibrary[type] = eventLibrary[type].filter((node) => {
                    return !eventIds.includes(node.libraryEventId);
                });
                dispatch({
                    type: GET_LIBRARY,
                    payload: newEventLibrary,
                });
                return response;
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    };
};
/**
 * @param  {boolean} newValue
 */
export const toggleExploreOptions = (newValue) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_EXPLORE_OPTIONS,
            payload: newValue,
        });
    };
};

/**
 * @param  {boolean} newValue
 */
export const toggleShowAgencyBaselineView = (newValue) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_AGENCY_BASELINE_PAGE,
            payload: newValue,
        });
    };
};

/**
 * @param  {object} scenario
 */
export const archiveScenario = (scenario) => {
    scenario.is_archived = true;
    return (dispatch) => {
        return axios
            .put(`${config.host}/scenario/`, scenario, requestHeader())
            .then(() => {
                dispatch({
                    type: ARCHIVE_SCENARIO,
                    payload: scenario,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

/**
 * @param  {object} scenario
 */
export const reactivateArchivedScenario = (scenario) => {
    scenario.is_archived = false;
    return (dispatch) => {
        return axios
            .put(`${config.host}/scenario/`, scenario, requestHeader())
            .then(() => {
                dispatch({
                    type: REACTIVATE_ARCHIVED_SCENARIO,
                    payload: scenario,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const updateAccountData = (newAccountData) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ACCOUNT_DATA,
            payload: newAccountData,
        });
    };
};

// required fields:
// data: {first_name, last_name, city, state, country, email, account}. planId

export const createFreePlan = (userDetails, callback = null) => {
    return () => {
        return axios
            .post(
                `${config.host}/chargebee/createPlanSubscription`,
                {
                    planId: "free-plan",
                    data: {
                        first_name: userDetails.name,
                        last_name: "",
                        city: "",
                        state: userDetails.state,
                        country: userDetails.country,
                        email: userDetails.email,
                        account: userDetails.account,
                    },
                },
                requestHeader()
            )
            .then((res) => {
                callback && callback(null, res);
            })
            .catch((err) => {
                callback && callback(err, null);
            });
    };
};

export const createStudentPlan = (userDetails, callback = null) => {
    return () => {
        return axios
            .post(
                `${config.host}/chargebee/createPlanSubscription`,
                {
                    planId: "student_02", // 02 for free, 01 is "paid". Be sure to also change this string in other parts of the app.
                    data: {
                        first_name: userDetails.name,
                        last_name: "",
                        city: "",
                        state: userDetails.state,
                        country: userDetails.country,
                        email: userDetails.email,
                        account: userDetails.account,
                    },
                },
                requestHeader()
            )
            .then((res) => {
                callback && callback(null, res);
            })
            .catch((err) => {
                callback && callback(err, null);
            });
    };
};

export const getTemplates = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/templates/`)
            .then((res) => {
                const loggedInUser = JSON.parse(
                    localStorage.getItem("loggedInUser")
                );
                const userData = loggedInUser
                    ? {
                          name: loggedInUser.name.split(" ")[0],
                          birthYear: loggedInUser.data.birthYear,
                          birthMonth: loggedInUser.data.birthMonth,
                          state: loggedInUser.data.state,
                          country: loggedInUser.data.country,
                      }
                    : {
                          name: "Me",
                          birthYear: 1970,
                          birthMonth: "January",
                          state: "Alberta",
                          country: "CA",
                      };
                const templates = res.data.scenarios;
                templates.forEach((template) => {
                    template.data.nodes[0].metadata = {
                        ...template.data.nodes[0].metadata,
                        ...userData,
                    };
                });
                templates.forEach((template) => {
                    if (template.categories.includes("sample")) {
                        template.type = "sample";
                    }
                });
                dispatch({
                    type: GET_TEMPLATES,
                    payload: templates,
                });
                dispatch(upsertEntities(res.data.entities));
            })
            .catch((err) => {
                Sentry.withScope(() => {
                    Sentry.captureException(err);
                });
            });
    };
};

export const createTemplate = (template) => {
    return () => {
        const { copiedScenario, copiedEntities } = duplicateScenario(template);

        return axios
            .post(
                `${config.host}/templates/`,
                {
                    scenario: copiedScenario,
                    entities: Object.values(copiedEntities),
                },
                requestHeader()
            )
            .then(() => {
                throwError(
                    "success",
                    "Template Created",
                    "Refresh app to view."
                );
            })
            .catch(() => {
                throwError("error", "Failed to Create Template");
            });
    };
};

export const updateTemplate = (template) => {
    return () => {
        const { copiedScenario, copiedEntities } = duplicateScenario(template);
        return axios
            .put(
                `${config.host}/templates/`,
                {
                    scenario: copiedScenario,
                    entities: Object.values(copiedEntities),
                },
                requestHeader()
            )
            .then(() => {
                throwError(
                    "success",
                    "Template Updated",
                    "Refresh app to view."
                );
            })
            .catch(() => {
                throwError("error", "Failed to Update Template");
            });
    };
};

export const deleteTemplate = (templateId) => {
    return () => {
        return axios
            .delete(`${config.host}/templates/${templateId}`, requestHeader())
            .then(() => {
                throwError("success", "Template Deleted", "Please refresh app");
            })
            .catch(() => {
                throwError("error", "Failed to Delete Template");
            });
    };
};

export const getAllEventDetails = () => {
    return (dispatch) => {
        return axios
            .get(`${config.host}/eventDetails`)
            .then((response) => {
                const allEventDetailsArray = response.data ?? [];
                const allEventDetails = {};
                allEventDetailsArray.map((eventDetails) => {
                    allEventDetails[eventDetails.eventId] = eventDetails;
                });
                dispatch(upsertEventDetails(allEventDetails));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const toggleEventModalActions = () => {
    return (dispatch, getState) => {
        const preventEventModalActions =
            getState()?.scenario?.preventEventModalActions;

        dispatch({
            type: PREVENT_EVENT_MODAL_ACTIONS,
            payload: !preventEventModalActions,
        });
    };
};

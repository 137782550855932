import type {
    EventsTemplatesSchema,
    EventsTemplatesInputSchema,
} from "reducers/typesSchema/eventsTemplatesSchema";
import type { AppThunk } from "store";

export const UPSERT_EVENTS_TEMPLATES =
    "eventsTemplates/UPSERT_EVENTS_TEMPLATES";
export const SET_EVENTS_TEMPLATES = "eventsTemplates/SET_EVENTS_TEMPLATES";

export function upsertEventsTemplates(
    upsertEventsTemplates: EventsTemplatesInputSchema
): AppThunk<void> {
    return (dispatch, getState) => {
        const curEventsTemplates = getState().eventsTemplates;

        const newEventsTemplates: EventsTemplatesSchema = {};
        Object.entries(upsertEventsTemplates).forEach(
            ([eventTemplateTypeId, eventTemplate]) => {
                newEventsTemplates[eventTemplateTypeId] = {
                    ...curEventsTemplates[eventTemplateTypeId],
                    ...eventTemplate,
                };
            }
        );

        dispatch({
            type: UPSERT_EVENTS_TEMPLATES,
            payload: newEventsTemplates,
        });
    };
}

export function removeEventsTemplates(
    eventsTypesIds: string[]
): AppThunk<void> {
    return (dispatch, getState) => {
        const eventsTemplatesCopy: EventsTemplatesSchema = {
            ...getState().eventsTemplates,
        };
        eventsTypesIds.forEach((eventTemplateTypeId) => {
            delete eventsTemplatesCopy[eventTemplateTypeId];
        });

        dispatch({
            type: SET_EVENTS_TEMPLATES,
            payload: eventsTemplatesCopy,
        });
    };
}

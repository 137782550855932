// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import styles from "./accountFieldMappingModal.module.css";
import { useState } from "react";
import { setShowAccountFieldMappingModal } from "actions/accountFieldMappingActions";
import {
    ConfirmButton,
    MappingArrow,
    ModalRow,
    ModalRowComponent,
    RequiredStar,
    BasicTextInput,
} from "Components/InputContainer/Components";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import { isEmpty } from "lodash";
import { Ledgers } from "reducers/typesSchema/allAccountLedgersSchema";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import {
    FieldMappingData,
    FieldMappingDataMapped,
    FieldType,
} from "reducers/typesSchema/fieldMappingSchema";

const getInitialFieldMap = (
    fieldMappingData: FieldMappingData,
    entityFieldMap,
    allAccountLedgers
): FieldMappingDataMapped => {
    const fieldMap: FieldMappingDataMapped = {};
    for (const [fieldName, { data, type }] of Object.entries(
        fieldMappingData
    )) {
        fieldMap[fieldName] = {};
        if (type === FieldType.Account) {
            const accountMapping = fieldMap[fieldName];
            for (const dataValue of Object.keys(data)) {
                let accountData = null;

                const accountId = entityFieldMap[fieldName]?.[dataValue];

                // if the mapping is already stored
                if (accountId) {
                    accountData = getDataFromAccountId(
                        allAccountLedgers,
                        accountId
                    );
                } else {
                    accountData = getDataFromAccount(
                        allAccountLedgers,
                        dataValue
                    );
                }

                if (!accountData) {
                    accountMapping[dataValue] = null;
                    continue;
                }

                accountMapping[dataValue] = {
                    name: accountData.name,
                    ids: accountData.ids,
                    id: accountData.id,
                };
            }
        }
        // TODO: Support mapping non-account types
    }
    return fieldMap;
};

const getDataFromAccountId = (allAccountLedgers, accountId) => {
    const ledgersMetadata = allAccountLedgers.ledgersMetadata as Ledgers;

    let accountName: string | null = null;

    for (const ledgerData of Object.values(ledgersMetadata)) {
        if (ledgerData.id == accountId) {
            accountName = ledgerData.name;

            const accounts: string[] = [];
            let currentAcc = ledgerData;
            accounts.unshift(currentAcc.id);

            while (currentAcc.parents.length > 0) {
                currentAcc = ledgersMetadata[currentAcc.parents[0]];
                accounts.unshift(currentAcc.id);
            }

            return {
                name: accountName,
                ids: accounts,
                id: accountId,
            };
        }
    }

    return null;
};

const getDataFromAccount = (allAccountLedgers, accountName) => {
    const ledgersMetadata = allAccountLedgers.ledgersMetadata as Ledgers;

    for (const ledgerData of Object.values(ledgersMetadata)) {
        if (accountName == ledgerData.name) {
            const accountId = ledgerData.id;

            const accounts: string[] = [];
            let currentAcc = ledgerData;
            accounts.unshift(currentAcc.id);

            while (currentAcc.parents.length > 0) {
                currentAcc = ledgersMetadata[currentAcc.parents[0]];
                accounts.unshift(currentAcc.id);
            }

            return {
                name: accountName,
                ids: accounts,
                id: accountId,
            };
        }
    }

    return null;
};

export const AccountFieldMappingModal = () => {
    const {
        fieldMappingData,
        entity,
        create,
    }: {
        fieldMappingData: FieldMappingData;
        entity: EntitySchema;
        create: any;
    } = useAppSelector((state) => {
        const showAccountFieldMappingModal =
            state.scenario?.showAccountFieldMappingModal;
        const fieldMappingData = showAccountFieldMappingModal?.fieldMappingData;
        const entity = showAccountFieldMappingModal?.entity;
        const create = showAccountFieldMappingModal?.create;

        return { fieldMappingData, entity, create };
    });

    const allAccountLedgers = useAppSelector(
        (state) => state?.allAccountLedgers
    );

    const entityFieldMap = entity?.data?.fieldMap ?? {};

    const [fieldMap, setFieldMap] = useState(
        getInitialFieldMap(fieldMappingData, entityFieldMap, allAccountLedgers)
    );

    // TODO: Support mapping multiple fields
    const [currentFieldName, _setCurrentFieldName] = useState(
        Object.keys(fieldMappingData)?.[0] ?? null
    );

    const onConfirm = () => {
        updateStoredAccountMapping();
        setShowAccountFieldMappingModal({
            show: false,
            entity: entity,
            fieldMappingData: fieldMap,
            mapped: true,
        });
        create(fieldMap);
    };

    const updateStoredAccountMapping = () => {
        if (isEmpty(fieldMap)) return;

        const storedFieldMap = entityFieldMap;

        for (const [fieldName, mappingData] of Object.entries(fieldMap)) {
            if (!(fieldName in storedFieldMap)) {
                storedFieldMap[fieldName] = {};
            }
            for (const [fieldValue, data] of Object.entries(mappingData)) {
                storedFieldMap[fieldName][fieldValue] = data.id;
            }
        }

        entity.data.fieldMap = storedFieldMap;
    };

    const passedCheck = () => {
        for (const fieldMapping of Object.values(fieldMap)) {
            for (const valueMap of Object.values(fieldMapping)) {
                if (!valueMap) {
                    return false;
                }
            }
        }

        return true;
    };

    const onChangeAccount = (accountData, id, accountName) => {
        const mappedAccountData = {
            name: accountData.name,
            ids: accountData.ids,
            id: accountData.id,
        };

        if (id == "accountName") {
            const newFieldMap = { ...fieldMap };
            const newAccountMap = { ...(newFieldMap[currentFieldName] ?? {}) };
            newAccountMap[accountName] = mappedAccountData;

            newFieldMap[currentFieldName] = newAccountMap;
            setFieldMap(newFieldMap);
        }
    };

    const AccountMappingRow = ({ name }) => {
        const basicAccounts = {
            "211dd944-359f-4fbe-af1f-0c761afa1e67": true, //Assets
            "6c9640aa-abf5-4f19-b135-356e183bcce6": true, //Liabilities
            "001a1288-cefb-4bcb-97ce-510b8a668a19": true, //Equity
            "488dd61d-8697-4213-8978-cf91755365a4": true, //Income
            "7faf0285-78ca-411b-b875-d900929d7c94": true, //Expenses
        };

        return (
            <ModalRow twoInputs>
                <ModalRowComponent width={40}>
                    <BasicTextInput
                        inputLabel="Source Name"
                        value={name}
                        className="mlsInput"
                        disabled={true}
                    />
                </ModalRowComponent>

                <MappingArrow />

                <ModalRowComponent width={40}>
                    <RequiredStar />
                    <InputDropDownButton
                        callback={(accountData, id) =>
                            onChangeAccount(accountData, id, name)
                        }
                        initialValue={
                            fieldMap[currentFieldName][name]
                                ? fieldMap[currentFieldName][name].name
                                : ""
                        }
                        unselectableAccounts={basicAccounts}
                    />
                </ModalRowComponent>
            </ModalRow>
        );
    };

    return (
        <div className="ModalContainer">
            <div className={styles.primaryContainer}>
                <div
                    className={styles.closeIcon}
                    onClick={() =>
                        setShowAccountFieldMappingModal({ show: false })
                    }
                ></div>
                <div className={styles.secondaryContainer}>
                    <div className={styles.headerContainer}>
                        <aside className={styles.aside}>
                            <figure className={styles.figure}></figure>
                        </aside>
                        <div className={styles.headerCopyContainer}>
                            <h3 className={styles.headerCopy}></h3>
                            <h3 className={styles.headerCopy}>
                                Mapping Fields
                            </h3>
                        </div>
                    </div>
                    <div className={styles.inputsContainer}>
                        {fieldMap[currentFieldName] &&
                            Object.keys(fieldMap[currentFieldName]).map(
                                (account, i) => {
                                    return (
                                        <AccountMappingRow
                                            key={i}
                                            name={account}
                                        />
                                    );
                                }
                            )}
                    </div>
                    <ConfirmButton
                        onClick={onConfirm}
                        passedCheck={passedCheck()}
                    />
                </div>
            </div>
        </div>
    );
};

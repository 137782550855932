// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import GoalCard from "../Card/goalCard";
import GoalInput from "../InputContainer/GoalInput";

// Import Connection Image (if exists)
import GoalConnectionImage from "Assets/_optionDescriptionIcons/Goal white.jpg";

// Import Coloured PNGs
import goalBlack from "../../Assets/_eventNodeTypes/goal-black-updated.svg";
import goalBlue from "../../Assets/_eventNodeTypes/goal-blue.png";
import goalWhite from "../../Assets/_eventNodeTypes/goal-white.png";
import goalGrey from "../../Assets/_eventNodeTypes/goal-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - goalObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - goalObject.checkInput.call(goalObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const goalObject = {
    constant: function () {
        return Goal_Constant();
    },

    name: function () {
        return "Goal";
    },

    checkInput: function (state) {
        return Goal_CheckInput(state);
    },

    svg: function () {
        return Goal_Svg();
    },

    eventNodeMenu: function (focus) {
        return Goal_EventNodeMenu(focus);
    },

    indexText: function () {
        return Goal_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Goal_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Goal_OptionDescription();
    },

    colouredPngs: function () {
        return Goal_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Goal_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Goal_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Goal_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.data.accountId) return false;
        if (!entity.name) return false;
        if (!entity.data.displayMarker && !entity.data.displayThreshold)
            return false;
        if (entity.data.displayMarker && !entity.startDate) return false;
        if (
            entity.data.value === undefined ||
            entity.data.value === null ||
            entity.data.value === "" ||
            isNaN(entity.data.value)
        )
            return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Goal_Constant = () => {
    return "61cd9973-dd23-4e38-ac7a-dee51bb2496b";
};

// EFFECTS: Returns black png / svg for event
const Goal_Svg = () => {
    return goalBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Goal_EventNodeMenu = (focus) => {
    return <GoalCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Goal_IndexText = () => {
    return "Select an input field to learn more about your goal calculations.";
};

// EFFECTS: Returns all icons for event ??
const Goal_Icons = () => {
    let pngs = new Map([
        ["black", goalBlack],
        ["blue", goalBlue],
        ["white", goalWhite],
        ["grey", goalGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Goal_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Goal_InputComponentInfoPassing = (minProps) => {
    return <GoalInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, goals for event ??
const Goal_OptionDescription = () => {
    const tags = ["Option"];
    const type = goalObject.name();
    const description =
        "The Goal Event is a Utility Event for visually setting a target goal, on a target date, either monthly or cumulative. Select the Account that you wish to set the goal for and view the Graph to visualize which Scenario Threads meet or exceed those goals. In future development, enable automatic notifications that will track daily progress towards goals and whether a Scenario is trending towards or away from a given goal.";
    const usedForText =
        "Use Goal Event to set business targets, such as Profit Margin or total Customers, by a given date.";
    const connection =
        "For a Goal Event to display, it must source an Account that exists upstream. Depending on which Events are being used upstream, only the Accounts associated with that Event will be present.";
    const connectionImage = GoalConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Goal_SideModalImage = () => {
    return sideImage;
};

const Goal_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import monthlyLedgers from "./monthlyLedgers.json";
import ledgerSystem from "./ledgerSystem.json";

type LedgerTreeOrNull = null | { [key: string]: LedgerTreeOrNull };

interface AggregationQuery {
    Query: {
        Type: string;
        SubTypes: string[];
        Ids: string[];
        Filters: { [key: string]: any };
    };
    ContextKey: string;
    SaveTo: string;
    Collate: boolean;
    KeepLatest: boolean;
    Multiplier?: number;
}

export interface Ledger {
    Name: string;
    LedgerQueries: AggregationQuery[];
}

// Monthly ledgers taken from JSON, Cumulative ledgers transformed from Monthly ledgers.
const monthlyLedgerImports: { [key: string]: Ledger } = monthlyLedgers;
const cumulativeLedgerImports: { [key: string]: Ledger } = (() => {
    const _cumulativeLedgerImports = {};
    Object.entries(monthlyLedgerImports).forEach(([ledgerName, ledger]) => {
        const newLedgerName = "Cumulative " + ledgerName;
        const newQueries: AggregationQuery[] = [...ledger.LedgerQueries].map(
            (query) => ({
                ...query,
                KeepLatest: true,
            })
        );
        _cumulativeLedgerImports[newLedgerName] = {
            ...ledger,
            LedgerQueries: newQueries,
            Name: newLedgerName,
        };
    });
    return _cumulativeLedgerImports;
})();
const otherLedgerImports: { [key: string]: Ledger } = {
    "Scenario Events": {
        Name: "Scenario Events",
        LedgerQueries: [
            {
                Query: {
                    Type: "Event",
                    SubTypes: [],
                    Ids: [],
                    Filters: {},
                },
                ContextKey: "event",
                SaveTo: "event",
                Collate: true,
                KeepLatest: false,
            },
        ],
    },
};
export const ledgerMap = {
    ...otherLedgerImports,
    ...monthlyLedgerImports,
    ...cumulativeLedgerImports,
};

// An mapping from Sub-accounts to all of its super-accounts and relevant Calculated accounts
export const ledgerSuperAccountMap = (() => {
    const _ledgerSuperMap: { [key: string]: { [key: string]: true } } = {};
    // Recursive function to record super-accounts
    const recordSuperAccounts = (
        parentsSoFar: { [key: string]: true },
        currentLedger: LedgerTreeOrNull
    ) => {
        if (currentLedger == null) return;
        Object.entries(currentLedger).forEach(([ledgerName, account]) => {
            _ledgerSuperMap[ledgerName] = {
                ..._ledgerSuperMap[ledgerName],
                ...parentsSoFar,
                [ledgerName]: true,
            };
            recordSuperAccounts(
                { ...parentsSoFar, [ledgerName]: true },
                account
            );
        });
    };

    // Adds the given account name to all sub-accounts
    const propagateSubAccounts = (
        accName: string,
        root: LedgerTreeOrNull,
        rootName: string
    ) => {
        if (root == null) return;
        _ledgerSuperMap[rootName][accName] = true;
        Object.entries(root).forEach(([ledgerName, account]) => {
            propagateSubAccounts(accName, account, ledgerName);
        });
    };
    recordSuperAccounts({}, ledgerSystem.ACCOUNTS);
    recordSuperAccounts({}, ledgerSystem.OTHER);
    Object.keys(ledgerSystem.CALCULATED).forEach((accName) => {
        ledgerMap[accName].LedgerQueries.forEach((query) => {
            const type = query.Query.Type;
            propagateSubAccounts(
                accName,
                ledgerSystem.ACCOUNTS[type] ?? ledgerSystem.OTHER[type] ?? null,
                type
            );
        });
    });
    return _ledgerSuperMap;
})();

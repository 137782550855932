export const RESET_CONTAINER_CREATION = "CONTAINER/RESET_CONTAINER_CREATION";
export const UPSERT_CONTAINER_CREATION = "CONTAINER/UPSERT_CONTAINER_CREATION";

export const resetContainerCreation = () => ({
    type: RESET_CONTAINER_CREATION,
});

/**
 * @param {object} newValues
 * @param {boolean?} newValues.isCreatingContainer
 * @param {Event?} newValues.prospectiveHeadNode
 * @param {Event?} newValues.prospectiveTailNode
 * @returns
 */
export const upsertContainerCreation = (newValues) => {
    return (dispatch, getState) => {
        const newContainerCreationState = {
            ...getState.containerCreation,
            ...newValues,
        };

        dispatch({
            type: UPSERT_CONTAINER_CREATION,
            payload: newContainerCreationState,
        });
    };
};

import React from "react";
import styles from "./index.module.css";
import moment from "moment";

export default class Notification extends React.Component {
    render() {
        const { notification } = this.props;
        return (
            <div className={styles.notificationContainer}>
                <div className={styles.headerOne}>Notification</div>
                <div className={styles.notifHolder}>
                    {notification &&
                        notification.map((notif, i) => {
                            return (
                                <div
                                    className={
                                        !notif.seen
                                            ? styles.notification
                                            : styles.notificationSeen
                                    }
                                    key={i}
                                >
                                    <div className={styles.commentDate}>
                                        {moment(notif.time).format(
                                            "h:mm a, MMM Do"
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            className={styles.image}
                                            src={notif.commenterImage}
                                            alt={"Notification"}
                                        />
                                        <div className={styles.text}>
                                            <span className={styles.notifName}>
                                                @{notif.commenterName}
                                            </span>{" "}
                                            {notif.message}
                                            <span
                                                className={
                                                    styles.scenarioHighlighted
                                                }
                                            >
                                                {notif.scenario.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

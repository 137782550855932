import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

const getAllocation = (segments) => {
    let totalAllocation = 0;
    segments.forEach(
        (target) =>
            (totalAllocation =
                totalAllocation + Number(target?.allocation))
    );

    const availableAllocation = 100 - totalAllocation;

    return `${availableAllocation}`;
};

const segmentInputsHandler = (
    value: string,
    id:
    | "customer"
    | "entityName"
    | "segmentName"
    | "allocation",
    index: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    const segments = data.segments.map((target) => ({
        ...target,
    }));
    const targetProfile = segments[index];

    switch (id) {
        case "customer":
            const parsedCustomer = JSON.parse(value || "{\"eventId\": \"\", \"entityIds\": []}")

            handleDependencies(dependencyMap, parsedCustomer, eventId, currentEntityObject.id, data.customerEventId, data.customerIds)

            data.selectedCustomer = parsedCustomer
            data.customerEventId = parsedCustomer?.eventId ?? ""
            data.customerIds = parsedCustomer?.entityIds ?? []
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "segmentName": 
            if (!index && index !== 0) break;

            targetProfile.name = value

            segments[index] = targetProfile;
            data.segments = segments;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject
            break;
        case "allocation":
            if (!index && index !== 0) break;
            if (isNaN(Number(value))) break;

            targetProfile[id] = value;

            const allNonTargetProfiles = segments.filter(
                (segment) => segment?.id !== targetProfile?.id
            );

            const availableAllocation =
                getAllocation(allNonTargetProfiles);

            if (
                Number(targetProfile.allocation) >
                Number(availableAllocation)
            ) {
                targetProfile.allocation = availableAllocation;
            }

            segments[index] = targetProfile;
            data.segments = segments;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

export default segmentInputsHandler;

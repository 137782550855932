import * as React from "react";
import { useMemo, useState } from "react";
import { AccountSelectionMenu } from "Components/AccountSelectionMenu";
import { ReactComponent as DropDownIcon } from "Assets/dropDownStyleable.svg";
import styles from "./DropDownButton.module.css";
import { useAppSelector } from "store/useAppSelectorDispatch";

export const DropDownButton = ({
    callback,
    selectedAccountId,
    parentAccountId = "",
    buttonLabel = "Select Account",
    disabled = false,
}) => {
    const allAccountData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );

    const activeAccount = useMemo(
        () => allAccountData?.[selectedAccountId]?.name ?? buttonLabel,
        [allAccountData, buttonLabel, selectedAccountId]
    );

    const hasUserSelection: boolean = useMemo(() => {
        if (activeAccount === buttonLabel) {
            return false;
        } else {
            return true;
        }
    }, [activeAccount, buttonLabel]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                className={`${
                    disabled
                        ? `${styles.selectContainer} ${styles.selectContainerDisabled}`
                        : `${styles.selectContainer}`
                }`}
                style={
                    hasUserSelection
                        ? { color: "#313131" }
                        : { color: "#616161" }
                }
                onClick={handleClick}
            >
                {hasUserSelection && (
                    <span className={styles.inBorderText}>{buttonLabel}</span>
                )}
                {activeAccount}
                <DropDownIcon className={styles.dropDownIcon} />
            </div>
            <AccountSelectionMenu
                parentAccountId={parentAccountId}
                anchorEl={anchorEl}
                handleClose={handleClose}
                currentSelection={selectedAccountId}
                callback={callback}
            />
        </>
    );
};

import React, { Component } from "react";
import "./index.css";
import ChangeAccount from "./changeAccount";
import ChangeInflation from "./changeInflation";
import ChangePTTValues from "./changePTTValues";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";
import PublishTemplate from "./publishTemplate";
import UpdateTemplate from "./updateTemplate";

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "changeAccount",
            pttValues: null,
            inflationRate: null,
        };
    }

    onHandleChange = (e) => {
        const id = e.target.id;
        this.setState({ selected: id });
    };

    componentDidMount() {
        this.props
            .getAdminValues("PTTValues")
            .then((data) => {
                this.setState({ pttValues: data.data.values });
            })
            .catch((err) => {
                console.log(err, "<---- errrr admin");
            });

        this.props
            .getAdminValues("InflationRate")
            .then((data) => {
                this.setState({
                    inflationRate: data.data.values.inflationRate,
                });
            })
            .catch((err) => console.log(err, "<---- err inflarion"));
    }

    updateAdminValues = (key, values) => {
        this.props
            .updateAdminValues(key, values)
            .then((_data) => {
                this.props
                    .getAdminValues("InflationRate")
                    .then((data) => {
                        this.setState({
                            inflationRate: data.data.values.inflationRate,
                        });
                    })
                    .catch((err) => console.log(err, "<---- err inflarion"));
            })
            .catch((err) => {
                console.log(err, "<---- err");
            });
    };

    render() {
        let showView;
        switch (this.state.selected) {
            case "changeAccount":
                showView = <ChangeAccount props={this.props} />;
                break;
            case "changeInflation":
                showView = (
                    <ChangeInflation
                        inflationRate={this.state.inflationRate}
                        updateAdminValues={this.updateAdminValues}
                    />
                );
                break;
            case "changePTTValues":
                showView = (
                    <ChangePTTValues
                        pttValues={this.state.pttValues}
                        updateAdminValues={this.props.updateAdminValues}
                    />
                );
                break;
            case "publishTemplate":
                showView = (
                    <PublishTemplate
                        userScenarios={this.props.userScenarios}
                        createTemplate={this.props.createTemplate}
                    />
                );
                break;
            case "updateTemplate":
                showView = (
                    <UpdateTemplate
                        userScenarios={this.props.userScenarios}
                        updateTemplate={this.props.updateTemplate}
                        deleteTemplate={this.props.deleteTemplate}
                        scenarioTemplates={this.props.scenarioTemplates}
                    />
                );
                break;
            default:
        }
        return (
            <div className="adminContainer">
                <div className="adminLabel">ADMIN PANEL</div>
                <div className="AdminOption">
                    <div
                        onClick={this.onHandleChange}
                        id="changeAccount"
                        className="option"
                    >
                        Change Account
                    </div>
                    <div
                        onClick={this.onHandleChange}
                        id="changeInflation"
                        className="option"
                    >
                        Set Inflation
                    </div>
                    <div
                        onClick={this.onHandleChange}
                        id="changePTTValues"
                        className="option"
                    >
                        Change PTT Values
                    </div>
                    <div
                        onClick={this.onHandleChange}
                        id="publishTemplate"
                        className="option"
                    >
                        Publish a Template
                    </div>
                    <div
                        onClick={this.onHandleChange}
                        id="updateTemplate"
                        className="option"
                    >
                        Update/Delete a Template
                    </div>
                </div>
                <div>{showView}</div>
            </div>
        );
    }
}

export const B = ({ children }) => {
    return <span className="boldFont">{children}</span>;
};

const mapStateToProps = (state) => {
    return {
        sampleScenarios: state.scenario.sampleScenarios,
        userScenarios: state.scenario.userScenarios,
        scenarioTemplates: state.scenario.scenarioTemplates,
    };
};

const mapDispatchToProps = {
    getAdminValues: actions.getAdminValues,
    updateAdminValues: actions.updateAdminValues,
    createTemplate: actions.createTemplate,
    updateTemplate: actions.updateTemplate,
    deleteTemplate: actions.deleteTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

// Imports for Interfaced Objects
import Student_IncomeObject from "../Components/Registry/Student Income";
import { rentalIncomeObject } from "../Components/Registry/Rental Income";

//import AddDecision from "../Assets/_nodeIcons/addDecision.png";
import Connect from "../Assets/_nodeIcons/connect.png";
import Copy from "../Assets/_nodeIcons/copy.png";
import Paste from "../Assets/_nodeIcons/paste.png";
import PasteDisabled from "../Assets/_nodeIcons/paste-disabled.png";
import Delete from "../Assets/_nodeIcons/delete.png";
import Edit from "../Assets/_nodeIcons/edit.png";
import DisabledAddOption from "../Assets/_nodeIcons/add-disabled.png";
import DisabledConnect from "../Assets/_nodeIcons/connect-disabled.png";
import PinEventIcon from "../Assets/_nodeIcons/pin-no-bg.svg";
// import More from "../Assets/_nodeIcons/more.png";
import Back from "../Assets/_nodeIcons/back.png";
import AddMenu from "../Assets/_nodeIcons/addMenu.png";
import { expenseObject } from "../Components/Registry/Expense";
import { tuitionObject } from "../Components/Registry/Tuition";
import { budgetObject } from "../Components/Registry/Budget";
import { studentBudgetObject } from "../Components/Registry/Student Budget";
import { retirementObject } from "../Components/Registry/Retirement";
import { graduationObject } from "../Components/Registry/Graduation";
import { loanObject } from "../Components/Registry/Loan";
import { studentLoanObject } from "../Components/Registry/Student Loan";
import { debtObject } from "../Components/Registry/Debt";
import { debtRepaymentObject } from "../Components/Registry/Debt Repayment";
import { customerObject } from "../Components/Registry/Customer";
import { grantObject } from "../Components/Registry/Grant";
import { customerGrowthObject } from "../Components/Registry/CustomerGrowth";
import { customerChurnObject } from "../Components/Registry/CustomerChurn";
import { unitCostObject } from "../Components/Registry/Unit Cost";
import { revenueObject } from "../Components/Registry/Revenue";
import { bankObject } from "../Components/Registry/Bank";
import { bankPlaidObject } from "../Components/Registry/BankPlaid";
import { incomeObject } from "../Components/Registry/Income";
import { rentObject } from "../Components/Registry/Rent";
import { houseObject } from "../Components/Registry/House";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { mortgageObject } from "Components/Registry/Mortgage";
import { investmentObject } from "Components/Registry/Investment";
import { rrspObject } from "Components/Registry/RRSP";
import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";
import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";
import { propertyValueObject } from "Components/Registry/Property Value";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { employeeObject } from "Components/Registry/Employee";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { capitalGainsObject } from "Components/Registry/Capital Gains";
import { closingCostsObject } from "Components/Registry/Closing Costs";
import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
import { pensionObject } from "Components/Registry/Pension";
import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";
import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { shortTermRentalObject } from "Components/Registry/Short Term Rental";
import { modifierObject } from "Components/Registry/Modifier";
import { personObject } from "Components/Registry/Person";
import { decisionObject } from "Components/Registry/Decision";
import { goalObject } from "Components/Registry/Goal";
import { meObject } from "Components/Registry/Me";
import { taxDeductibleObject } from "Components/Registry/Tax Deductible";
import { equityObject } from "Components/Registry/Equity";
import { maternityObject } from "Components/Registry/Maternity";
import { condoFeeObject } from "../Components/Registry/CondoFee";
import { cacObject } from "Components/Registry/CAC";
import { nullObject } from "Components/Registry/Null";
import { containerObject } from "Components/Registry/Container";
import { contractObject } from "../Components/Registry/Contract";
import { startObject } from "Components/Registry/Start";
import { businessObject } from "Components/Registry/Business";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import { kpiObject } from "Components/Registry/KPI";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { instanceObject } from "Components/Registry/Instance";
import { modifier2Object } from "Components/Registry/Modifier2";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { expressionObject } from "Components/Registry/Expression";
import { importObject } from "Components/Registry/Import";
import { accountImportObject } from "Components/Registry/AccountImport";
import { growthObject } from "Components/Registry/Growth";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { salespersonObject } from "Components/Registry/Salesperson";
import { segmentObject } from "Components/Registry/Segment";
import { customer2Object } from "Components/Registry/Customer2";
import { customerGrowth2Object } from "Components/Registry/CustomerGrowth2";
import { customerChurn2Object } from "Components/Registry/CustomerChurn2";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { campaignObject } from "Components/Registry/Campaign";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { CAC2Object } from "Components/Registry/CAC2";
import { allocationObject } from "Components/Registry/Allocation";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { percentageObject } from "Components/Registry/Percentage";
import { resourceObject } from "Components/Registry/Resource";
import { capacityObject } from "Components/Registry/Capacity";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { unitObject } from "Components/Registry/Unit";
import { projectsObject } from "Components/Registry/Projects";
import { utilizationObject } from "Components/Registry/Utilization";

const SINGLE_NODE_GRAPH_EXPERIMENTAL = [
    "dev.whatifi.io",
    "localhost",
    "127.0.0.1",
].includes(window.location.hostname);

const addOptionActionDetails = {
    name: "Add Event",
    image: AddMenu,
};

const PinEventActionDetails = {
    name: "Pin Event",
    image: PinEventIcon,
};

const disabledAddOptionActionDetails = {
    name: "Add Event (Disabled)",
    image: DisabledAddOption,
};

const disabledConnectActionDetails = {
    name: "Connect (Disabled)",
    image: DisabledConnect,
};

const connectActionDetails = {
    name: "Connect",
    image: Connect,
};

const disconnectActionDetails = {
    name: "Disconnect",
    image: "https://image.flaticon.com/icons/png/512/637/637826.png",
};

const copyActionDetails = {
    name: "Copy",
    image: Copy,
};

const pasteActionDetails = {
    name: "Paste",
    image: Paste,
};

const disabledPasteActionDetails = {
    name: "Paste (Disabled)",
    image: PasteDisabled,
};

// const lockActionDetails = {
//     name: "Lock",
//     image: Lock,
// };

// const bypassActionDetails = {
//     name: "Bypass",
//     image: Bypass,
// };

const deleteActionDetails = {
    name: "Delete",
    image: Delete,
};

const editActionDetails = {
    name: "Edit",
    image: Edit,
};

// const moreActionDetails = {
//     name: "More",
//     image: More,
// };

const backActionDetails = {
    name: "Back",
    image: Back,
};

const QuickAddSet = [
    addOptionActionDetails,
    connectActionDetails,
    backActionDetails,
];

const QuickAddSet_Line = [
    addOptionActionDetails,
    pasteActionDetails,
    disconnectActionDetails,
];

const NodeFocusSet = (type) => {
    switch (type) {
        case expenseObject.constant():
        case tuitionObject.constant():
        case incomeObject.constant():
        case mortgageObject.constant():
        case bankObject.constant():
        case budgetObject.constant():
        case studentBudgetObject.constant():
        case goalObject.constant():
        case rentObject.constant():
        case houseObject.constant():
        case decisionObject.constant():
        case investmentObject.constant():
        case incomeTaxObject.constant():
        case loanObject.constant():
        case studentLoanObject.constant():
        case rrspObject.constant():
        case downpaymentObject.constant():
        case bankPlaidObject.constant():
        case propertyTransferTaxObject.constant():
        case propertyValueObject.constant():
        case personObject.constant():
        case propertyResaleObject.constant():
        case modifierObject.constant():
        case modifier2Object.constant():
        case customerTransferObject.constant():
        case taxDeductibleObject.constant():
        case employeeObject.constant():
        case maternityObject.constant():
        case pensionObject.constant():
        case lumpSumPaymentObject.constant():
        case renewalRefinanceObject.constant():
        case rentalIncomeObject.constant():
        case debitCreditObject.constant():
        case expressionObject.constant():
        case customerObject.constant():
        case projectsObject.constant():
        case kpiObject.constant():
        case groupObject.constant():
        case projectObject.constant():
        case importObject.constant():
        case accountImportObject.constant():
        case growthObject.constant():
        case outboundSalesObject.constant():
        case campaignObject.constant():
        case CAC2Object.constant():
        case salespersonObject.constant():
        case segmentObject.constant():
        case allocationObject.constant():
        case initialBalanceObject.constant():
        case instanceObject.constant():
        case customerGrowthObject.constant():
        case customerChurnObject.constant():
        case unitCostObject.constant():
        case revenueObject.constant():
        case cacObject.constant():
        case retirementObject.constant():
        case nullObject.constant():
        case containerObject.constant():
        case debtObject.constant():
        case grantObject.constant():
        case debtRepaymentObject.constant():
        case graduationObject.constant():
        case Student_IncomeObject.constant():
        case realEstateFeeObject.constant():
        case equityObject.constant():
        case mortgageInsuranceObject.constant():
        case capitalGainsObject.constant():
        case closingCostsObject.constant():
        case homeMaintenanceObject.constant():
        case contractObject.constant():
        case shortTermRentalObject.constant():
        case condoFeeObject.constant():
        case constraintObject.constant():
        case accountModifierObject.constant():
        case percentageObject.constant():
        case resourceObject.constant():
        case utilizationObject.constant():
        case capacityObject.constant():
        case averageOrderObject.constant():
        case accountExpressionObject.constant():
        case unitObject.constant():
        case businessObject.constant():
            if (SINGLE_NODE_GRAPH_EXPERIMENTAL) {
                return [
                    editActionDetails,
                    addOptionActionDetails,
                    connectActionDetails,
                    deleteActionDetails,
                    copyActionDetails,
                    pasteActionDetails,
                    PinEventActionDetails,
                    //moreActionDetails,
                ];
            } else {
                return [
                    editActionDetails,
                    addOptionActionDetails,
                    connectActionDetails,
                    deleteActionDetails,
                    copyActionDetails,
                    pasteActionDetails,
                    // PinEventActionDetails,
                    //moreActionDetails,
                ];
            }
        case meObject.constant():
        case startObject.constant():
            return [
                addOptionActionDetails,
                connectActionDetails,
                pasteActionDetails,
                editActionDetails,
            ];
        default:
            return [addOptionActionDetails, editActionDetails];
    }
};

const NodeFocusSetNoBranching = (type) => {
    switch (type) {
        case expenseObject.constant():
        case tuitionObject.constant():
        case incomeObject.constant():
        case mortgageObject.constant():
        case bankObject.constant():
        case budgetObject.constant():
        case studentBudgetObject.constant():
        case goalObject.constant():
        case rentObject.constant():
        case taxDeductibleObject.constant():
        case houseObject.constant():
        case maternityObject.constant():
        case decisionObject.constant():
        case investmentObject.constant():
        case incomeTaxObject.constant():
        case loanObject.constant():
        case studentLoanObject.constant():
        case rrspObject.constant():
        case downpaymentObject.constant():
        case bankPlaidObject.constant():
        case propertyTransferTaxObject.constant():
        case propertyValueObject.constant():
        case personObject.constant():
        case propertyResaleObject.constant():
        case modifierObject.constant():
        case modifier2Object.constant():
        case customerTransferObject.constant():
        case employeeObject.constant():
        case pensionObject.constant():
        case equityObject.constant():
        case lumpSumPaymentObject.constant():
        case renewalRefinanceObject.constant():
        case retirementObject.constant():
        case nullObject.constant():
        case containerObject.constant():
        case graduationObject.constant():
        case realEstateFeeObject.constant():
        case Student_IncomeObject.constant():
        case mortgageInsuranceObject.constant():
        case capitalGainsObject.constant():
        case closingCostsObject.constant():
        case homeMaintenanceObject.constant():
        case meObject.constant():
        case constraintObject.constant():
        case resourceObject.constant():
        case utilizationObject.constant():
        case capacityObject.constant():
        case averageOrderObject.constant():
        case accountExpressionObject.constant():
        case accountModifierObject.constant():
        case percentageObject.constant():
        case businessObject.constant():
            return [
                editActionDetails,
                disabledPasteActionDetails,
                disabledAddOptionActionDetails,
                disabledConnectActionDetails,
                deleteActionDetails,
                copyActionDetails,
            ];
        case startObject.constant():
            return [
                addOptionActionDetails,
                connectActionDetails,
                pasteActionDetails,
                editActionDetails,
            ];
        default:
            return [addOptionActionDetails, editActionDetails];
    }
};

const NodeFocusSetPaste = (type) => {
    switch (type) {
        case expenseObject.constant():
        case tuitionObject.constant():
        case incomeObject.constant():
        case mortgageObject.constant():
        case bankObject.constant():
        case budgetObject.constant():
        case studentBudgetObject.constant():
        case goalObject.constant():
        case rentObject.constant():
        case maternityObject.constant():
        case houseObject.constant():
        case decisionObject.constant():
        case investmentObject.constant():
        case incomeTaxObject.constant():
        case downpaymentObject.constant():
        case loanObject.constant():
        case studentLoanObject.constant():
        case rrspObject.constant():
        case bankPlaidObject.constant():
        case taxDeductibleObject.constant():
        case propertyTransferTaxObject.constant():
        case propertyValueObject.constant():
        case propertyResaleObject.constant():
        case personObject.constant():
        case modifierObject.constant():
        case modifier2Object.constant():
        case customerTransferObject.constant():
        case employeeObject.constant():
        case pensionObject.constant():
        case retirementObject.constant():
        case nullObject.constant():
        case containerObject.constant():
        case graduationObject.constant():
        case realEstateFeeObject.constant():
        case mortgageInsuranceObject.constant():
        case capitalGainsObject.constant():
        case Student_IncomeObject.constant():
        case equityObject.constant():
        case closingCostsObject.constant():
        case shortTermRentalObject.constant():
        case businessObject.constant():
        case lumpSumPaymentObject.constant():
        case renewalRefinanceObject.constant():
        case rentalIncomeObject.constant():
        case debitCreditObject.constant():
        case expressionObject.constant():
        case customerObject.constant():
        case projectsObject.constant():
        case customer2Object.constant():
        case customerGrowthObject.constant():
        case customerGrowth2Object.constant():
        case customerChurn2Object.constant():
        case customerTransfer2Object.constant():
        case websiteVisitorsObject.constant():
        case customerChurnObject.constant():
        case kpiObject.constant():
        case groupObject.constant():
        case projectObject.constant():
        case initialBalanceObject.constant():
        case instanceObject.constant():
        case unitCostObject.constant():
        case revenueObject.constant():
        case cacObject.constant():
        case debtObject.constant():
        case grantObject.constant():
        case debtRepaymentObject.constant():
        case homeMaintenanceObject.constant():
        case contractObject.constant():
        case constraintObject.constant():
        case accountModifierObject.constant():
        case percentageObject.constant():
        case resourceObject.constant():
        case utilizationObject.constant():
        case capacityObject.constant():
        case averageOrderObject.constant():
        case accountExpressionObject.constant():
        case condoFeeObject.constant():
        case unitObject.constant():
            return [
                editActionDetails,
                addOptionActionDetails,
                connectActionDetails,
                deleteActionDetails,
                copyActionDetails,
                pasteActionDetails,
                //moreActionDetails,
            ];
        case meObject.constant():
        case startObject.constant():
            return [
                addOptionActionDetails,
                connectActionDetails,
                pasteActionDetails,
                editActionDetails,
            ];
        default:
            return [
                editActionDetails,
                addOptionActionDetails,
                deleteActionDetails,
            ];
    }
};

const NodeMoreActionSet = [
    // commentActionDetails,
    // graphActionDetails,
    backActionDetails,
    deleteActionDetails,
];

export {
    QuickAddSet,
    QuickAddSet_Line,
    NodeFocusSet,
    NodeFocusSetNoBranching,
    NodeFocusSetPaste,
    NodeMoreActionSet,
};

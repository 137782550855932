import React, { Component } from "react";
import "./index.css";

class ChangeInflation extends Component {
    state = {
        inflation: null,
        resetInflation: null,
    };

    onHandleInput = (e) => {
        const value = e.target.value;
        switch (e.target.id) {
            case "resetInflation":
                this.setState({ resetInflation: value });
                break;
            case "resetExisting":
                this.setState({ inflation: value });
                break;
            default:
        }
    };

    requestHeader = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const requestHeader = {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${loggedInUser.token}`,
                account: loggedInUser.account,
                accept: "*/*",
                email: loggedInUser.email,
            },
        };

        return requestHeader;
    };

    setInflation = () => {
        const { inflation } = this.state;
        const newInflation = Number(inflation / 100).toFixed(4);
        console.log(newInflation);
        // if (newInflation) {
        //     axios
        //         .post(
        //             `${config.host}/scenario/updateInflation`,
        //             { newInflation },
        //             this.requestHeader()
        //         )
        //         .then((data) => {
        //             swal({
        //                 icon: "success",
        //                 title: "Congrats Jon!",
        //                 text:
        //                     "you successfully updated all scenarios inflation:)",
        //             });
        //         })
        //         .catch((err) => {
        //             Sentry.withScope((scope) => {
        //                 Sentry.captureException(err);
        //             });
        //             console.log(err, "<--- update inflation error");
        //         });
        // } else {
        //     swal({
        //         icon: "error",
        //         title: "SET VALUE!",
        //         text: "Please don't submit if you don't have inflation value.)",
        //     });
        // }
    };

    setNewInflation = () => {
        //add the code to change inflation
        const key = "InflationRate";
        const values = {
            inflationRate: Number(this.state.resetInflation / 100).toFixed(4),
        };
        this.props.updateAdminValues(key, JSON.stringify(values));
    };

    render() {
        return (
            <div className="adminContainer">
                <div className="adminBox">
                    <div className="adminLabel">
                        Set All existing Scenarios Inflation Rate
                    </div>
                    <input
                        value={this.state.inflation}
                        onChange={this.onHandleInput}
                        className="inflationRate"
                        id="resetExisting"
                    />
                    <div
                        className="inflationButton"
                        onClick={this.setInflation}
                    >
                        SET ALL EXISTING SCENARIOS INFLATION
                    </div>

                    <div className="adminLabel">Reset Inflation Rate</div>
                    <div>
                        The Current InflationRate is -- &gt;{" "}
                        {this.props.inflationRate * 100}
                    </div>
                    <input
                        value={this.state.resetInflation}
                        onChange={this.onHandleInput}
                        className="inflationRate"
                        id="resetInflation"
                    />
                    <div
                        className="inflationButton"
                        onClick={this.setNewInflation}
                    >
                        RESET INFLATION
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangeInflation;

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import CustomerTransferCard from "../Card/customerTransferCard";
import CustomerTransferInput from "../InputContainer/ModifierInput";

// Import Connection Image (if exists)
import CustomerTransferConnectionImage from "Assets/_optionDescriptionIcons/Campaign Customer Transfer white.jpg";

// Import Coloured PNGs
import customerTransferBlack from "../../Assets/_eventNodeTypes/customerTransfer.svg";
import customerTransferBlue from "../../Assets/_eventNodeTypes/customerTransfer.svg";
import customerTransferWhite from "../../Assets/_eventNodeTypes/customerTransfer.svg";
import customerTransferGrey from "../../Assets/_eventNodeTypes/customerTransfer.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - modifierObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - modifierObject.checkInput.call(modifierObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const customerTransferObject = {
    constant: function () {
        return Customer_Transfer_Constant();
    },

    name: function () {
        return "Customer Transfer";
    },

    checkInput: function (state) {
        return Customer_Transfer_CheckInput(state);
    },

    svg: function () {
        return Customer_Transfer_Svg();
    },

    eventNodeMenu: function (focus) {
        return Customer_Transfer_EventNodeMenu(focus);
    },

    indexText: function () {
        return Customer_Transfer_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Customer_Transfer_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Customer_Transfer_OptionDescription();
    },

    colouredPngs: function () {
        return Customer_Transfer_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Customer_Transfer_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return CustomerTransfer_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Customer_Transfer_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) {
            return false;
        }
        if (
            !entity.data.sourceCustomerEventId ||
            !entity.data.modType ||
            !entity.data.targetCustomerEventId
        ) {
            return false;
        }
        if (entity.data.inheritStartDate && !entity.startDate) {
            return false;
        }
        if (entity.data.subType == "constant" && !entity.data.value) {
            return false;
        }
        if (
            entity.data.subType == "dynamic" &&
            entity.data.specificPercentages?.length < 2
        )
            return false;
        if (entity.data.subType == "dynamic" && !entity.data.maxGrowth) {
            return false;
        }
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Customer_Transfer_Constant = () => {
    return "6210f29d-4f58-42b7-8fbf-a863f1e97b9d";
};

// EFFECTS: Returns black png / svg for event
const Customer_Transfer_Svg = () => {
    return customerTransferBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Customer_Transfer_EventNodeMenu = (focus) => {
    return <CustomerTransferCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Customer_Transfer_IndexText = () => {
    return "Customer Transfer Event In Progress";
};

// EFFECTS: Returns all icons for event ??
const Customer_Transfer_Icons = () => {
    let pngs = new Map([
        ["black", customerTransferBlack],
        ["blue", customerTransferBlue],
        ["white", customerTransferWhite],
        ["grey", customerTransferGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Modifier_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Customer_Transfer_InputComponentInfoPassing = (minProps) => {
    return <CustomerTransferInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, modifiers for event ??
const Customer_Transfer_OptionDescription = () => {
    const tags = ["Option"];
    const type = customerTransferObject.name() + " (BETA)";
    const description =
        "The Customer Transfer Event is a unique event that allows a User to move customers from one cohort to another.";
    const usedForText =
        "This Event can be used to model the movement of customers from one revenue stream to another. For example, every month 10% of customers upgrade to a more expensive gym membership";
    const connection =
        "Customer Transfer only needs to be downstream of the Customer Events being modified";
    const connectionImage = CustomerTransferConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Customer_Transfer_SideModalImage = () => {
    return sideImage;
};

const CustomerTransfer_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import styles from "./dateSelector.module.css";
import { useMemo } from "react";

export const DateSelectorCellRenderer = (props) => {
    const rowData = props?.node?.data;
    const { conditionalFieldName, conditionalFieldConditionValue } = props;
    const editable: boolean = useMemo(() => {
        if (!conditionalFieldName) return true;
        if (
            String(rowData[conditionalFieldName]) ===
            String(conditionalFieldConditionValue)
        ) {
            return true;
        }
        return false;
    }, [conditionalFieldConditionValue, conditionalFieldName, rowData]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        const colId = props.column.colId;
        props.node.setDataValue(colId, newValue);
    };

    return (
        <div className={styles?.container}>
            <input
                disabled={!editable}
                className={styles?.dateInput}
                type="date"
                value={props?.value}
                onChange={handleChange}
            />
        </div>
    );
};

import React from "react";

// Import Event Card and Event Input
import ExpenseCard from "../Card/expenseCard";
import ExpenseInputExperimental from "../InputContainer/ExpenseInputExperimental/ExpenseInputExperimental";

import expenseConnectionImage from "Assets/_optionDescriptionIcons/Income, Expense white.jpg";

// Import Coloured PNGs
import expenseBlack from "../../Assets/_eventNodeTypes/expense-black-updated.svg";
import expenseBlue from "../../Assets/_eventNodeTypes/expense-blue.png";
import expenseWhite from "../../Assets/_eventNodeTypes/expense-white.png";
import expenseGrey from "../../Assets/_eventNodeTypes/expense-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

import { valueDoesNotExist } from "../../helpers/index";

import expenseInputsHandler from "Components/InputContainer/OnInputChangeHandlers/expenseInputsHandler";

export const expenseObject = {
    constant: function () {
        return Expense_Constant();
    },

    name: function () {
        return "Expense";
    },

    checkInput: function (state) {
        return Expense_CheckInput(state);
    },

    svg: function () {
        return Expense_Svg();
    },

    eventNodeMenu: function (focus) {
        return Expense_EventNodeMenu(focus);
    },

    indexText: function () {
        return Expense_IndexText();
    },

    inputInfoPassing: function (minProps, libraryProps) {
        return Expense_InputComponentInfoPassing(minProps, libraryProps);
    },

    optionDescription: function () {
        return Expense_OptionDescription();
    },

    colouredPngs: function () {
        return Expense_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Expense_SideModalImage();
    },

    inputsHandler: function (
        value,
        id,
        star,
        entitiesMap,
        currentEntity,
        account
    ) {
        return expenseInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            account
        );
    },

    generateDisplayFieldData: function () {
        return Expense_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Expense_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (entity.data.cost === undefined || entity.data.cost === "")
            return false;
        if (
            valueDoesNotExist(entity.data.value) ||
            isNaN(entity.data.value) ||
            !isFinite(entity.data.value)
        )
            return false;
        if (!entity.startDate) return false;
        if (!entity.name) return false;
        if (!entity.cadence) return false;
        if (
            entity.data.applyInflation &&
            valueDoesNotExist(entity.data.inflationRate)
        )
            return false;
        if (entity.data.expenseType === "amortized") {
            if (
                !entity.data.amortizationPeriod ||
                entity.data.amortizationPeriod < 0 ||
                isNaN(entity.data.amortizationPeriod) ||
                !isFinite(entity.data.amortizationPeriod)
            )
                return false;
            if (!entity.endDate) return false;
        }
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Expense_Constant = () => {
    return "c948e1e2-acac-4f8c-b241-92a16d4528b7";
};

// EFFECTS: Returns black png / svg for event
const Expense_Svg = () => {
    return expenseBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Expense_EventNodeMenu = (focus, inflation) => {
    return <ExpenseCard data={focus} inflation={inflation} />;
};

// EFFECTS: Returns the Index Text for event
const Expense_IndexText = () => {
    return "Select an input field to learn more about your expense calculations.";
};

// EFFECTS: Returns all icons for event ??
const Expense_Icons = () => {
    let pngs = new Map([
        ["black", expenseBlack],
        ["blue", expenseBlue],
        ["white", expenseWhite],
        ["grey", expenseGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Expense_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Expense_InputComponentInfoPassing = (minProps, libraryProps) => {
    return <ExpenseInputExperimental {...minProps} {...libraryProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, expenses for event ??
const Expense_OptionDescription = () => {
    const tags = ["Option"];
    const type = expenseObject.name();
    const description =
        "The Expense Event is one the most basic building block Events. It can be used for a one-time, ongoing or amortized expense and directly contributes to the Cash Flow and Expense Accounts. For more specific expenses, consider using the Debit/Credit Event.";
    const usedForText =
        "Use the Expense Event for tracking general, cash flow expenses.";
    const connection = "";
    const connectionImage = expenseConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/e/expense.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Expense_SideModalImage = () => {
    return sideImage;
};

const Expense_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: true,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        endDate: {
            displayName: "End Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
            conditionalFieldName: "expenseType",
            conditionalFieldConditionValue: "ongoing",
        },
        cadence: {
            displayName: "Payment Frequency",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: [
                "annually",
                "quarterly",
                "monthly",
                "semi-monthly",
                "bi-weekly",
                "weekly",
                "daily",
                "one-time",
            ],
        },
        bypassState: {
            displayName: "Bypassed",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
    };

    const entityDataFieldsMap = {
        expenseType: {
            displayName: "Expense Type",
            editable: false,
            conditionalTarget: true,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["amortized", "ongoing"],
        },
        value: {
            displayName: "Cost",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: true,
        },
        amortizationPeriod: {
            displayName: "Amortization Period",
            editable: false,
            conditionalFieldName: "expenseType",
            conditionalFieldConditionValue: "amortized",
        },
        accountName: {
            displayName: "Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        accountIds: {
            hide: true,
        },
        contraAccountName: {
            displayName: "Contra Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        contraAccountIds: {
            hide: true,
        },
    };

    return { entityFieldsMap, entityDataFieldsMap };
};

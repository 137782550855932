const Footer = () => {
    const copyrightYear = new Date().getFullYear().toString();

    return (
        <div id="footer">
            <div id="copyright-footer">
                <img
                    alt="alt"
                    src="https://d9hhrg4mnvzow.cloudfront.net/subscribe.whatifi.io/signup/493317e0-screen-shot-2017-10-14-at-9-02-07-pm_01z00j01z00j000000001.png"
                />
                <span>&#9400; Copyright {copyrightYear}</span>
            </div>
            <div id="email-footer">info@whatifi.io</div>
            <div id="motto-footer">make bigger decisions. better</div>
        </div>
    );
};

export default Footer;

import React from "react";
import closeSvg from "../../Assets/close.svg";
import styles from "./index.module.css";

export const EventsPopUp = ({ children, onHandleClose, specificClass }) => {
    return (
        <div className={styles["modalContainer" + specificClass]}>
            <div className={styles.contentContainer}>
                <img
                    alt="alt"
                    src={closeSvg}
                    className={styles.closeModal}
                    onClick={onHandleClose}
                />
                {children}
            </div>
        </div>
    );
};

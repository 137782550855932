import type {
    // EventsTemplatesInputSchema,
    EventsTemplatesSchema,
} from "./typesSchema/eventsTemplatesSchema";
import {
    SET_EVENTS_TEMPLATES,
    UPSERT_EVENTS_TEMPLATES,
} from "../actions/eventsTemplatesActions";

const initialState: EventsTemplatesSchema = {};

export default function eventsTemplatesReducer(
    state: EventsTemplatesSchema = initialState,
    action: { type: string; payload?: any }
): EventsTemplatesSchema {
    switch (action.type) {
        case SET_EVENTS_TEMPLATES:
            return { ...action.payload };
        case UPSERT_EVENTS_TEMPLATES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

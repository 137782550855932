// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import CustomerChurnCard from "../Card/customerChurnCard";

// Import Connection Image (if exists)
import CustomerChurnConnection from "Assets/_optionDescriptionIcons/Customer, Growth, Churn white.jpg";

// Import Coloured PNGs

// TODO Grab pngs from Michelle or Jon
import customerChurnBlack from "../../Assets/_eventNodeTypes/customer-churn-black.svg";
import customerChurnBlue from "../../Assets/_eventNodeTypes/churn-blue.png";
import customerChurnWhite from "../../Assets/_eventNodeTypes/churn-white.png";
import customerChurnGrey from "../../Assets/_eventNodeTypes/churn-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import CustomerChurnInputExperimental from "Components/InputContainer/CustomerChurnInputExperimental/CustomerChurnInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - customerObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - customerObject.checkInput.call(customerObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const customerChurnObject = {
    constant: function () {
        return CustomerChurn_Constant();
    },

    name: function () {
        return "Customer Churn";
    },

    checkInput: function (state) {
        return CustomerChurn_CheckInput(state);
    },

    svg: function () {
        return CustomerChurn_Svg();
    },

    eventNodeMenu: function (focus) {
        return CustomerChurn_EventNodeMenu(focus);
    },

    indexText: function () {
        return CustomerChurn_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return CustomerChurn_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return CustomerChurn_OptionDescription();
    },

    colouredPngs: function () {
        return CustomerChurn_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return CustomerChurn_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return CustomerChurn_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const CustomerChurn_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (
            !entity.name ||
            !entity.data.customerEventId ||
            !entity.data.subType
        ) {
            return false;
        }

        if (!entity.data.inheritStartDate && !entity.startDate) {
            return false;
        }
        if (entity.data.subType == "constant" && !entity.data.constantValue) {
            return false;
        }
        if (
            entity.data.subType == "dynamic" &&
            entity.data.specificPercentages?.length < 2
        )
            return false;
        if (
            entity.data.subType == "dynamic" &&
            (!entity.data.interpolateSubType ||
                !entity.data.specificPercentages.reduce(function (acc, obj) {
                    return acc && obj.month && obj.year && obj.value;
                }, true))
        ) {
            return false;
        }
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const CustomerChurn_Constant = () => {
    return "3bcf6472-1496-4863-91a5-7836a9be6c76";
};

// EFFECTS: Returns black png / svg for event
const CustomerChurn_Svg = () => {
    return customerChurnBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const CustomerChurn_EventNodeMenu = (focus) => {
    return <CustomerChurnCard focus={focus} />;
};

// EFFECTS: Returns the Index Text for event
const CustomerChurn_IndexText = () => {
    return "The Customer Churn Event alters an existing Customer Event. We apply the percentage churn rates to the total Customer count each month.";
};

// EFFECTS: Returns all icons for event ??
const CustomerChurn_Icons = () => {
    let pngs = new Map([
        ["black", customerChurnBlack],
        ["blue", customerChurnBlue],
        ["white", customerChurnWhite],
        ["grey", customerChurnGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const CustomerChurn_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const CustomerChurn_InputComponentInfoPassing = (minProps) => {
    return <CustomerChurnInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const CustomerChurn_OptionDescription = () => {
    const tags = ["Option"];
    const type = customerChurnObject.name();
    const description =
        "The Churn or rate of Customer attrition is the month-over-month percentage of Users or Subscribers that stop using a product or service. Customer Churn can have a dramatic impact on the profitability and financial runway of a company.";
    const usedForText =
        "Use the Customer Churn Event, along with the Customer, Growth, Unit Cost and Revenue Events, to model out various subscription and SaaS models.";
    const connection = "";
    const connectionImage = CustomerChurnConnection;
    const learnMoreLink = "https://www.investopedia.com/terms/c/churnrate.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const CustomerChurn_SideModalImage = () => {
    return sideImage;
};

const CustomerChurn_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import ClosingCostsCard from "../Card/closingCostsCard";
import ClosingCostsInput from "../InputContainer/ClosingCostsInput";

// Import Connection Image (if exists)
import ClosingCostsConnectionImage from "../../Assets/_optionDescriptionIcons/Property Resale, Closing Costs, Real Estate Fees.png";

// Import Coloured PNGs
import closingCostsBlack from "../../Assets/_eventNodeTypes/closingCosts-black.png";
import closingCostsBlue from "../../Assets/_eventNodeTypes/closingCosts-blue.png";
import closingCostsWhite from "../../Assets/_eventNodeTypes/closingCosts-white.png";
import closingCostsGrey from "../../Assets/_eventNodeTypes/closingCosts-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

import { valueDoesNotExist } from "helpers";

// Notes
// To call an object with no parameters, can simply use
//  - closingCostsObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - closingCostsObject.checkInput.call(closingCostsObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const closingCostsObject = {
    constant: function () {
        return ClosingCosts_Constant();
    },

    checkInput: function (state) {
        return ClosingCosts_CheckInput(state);
    },

    svg: function () {
        return ClosingCosts_Svg();
    },

    eventNodeMenu: function (focus) {
        return ClosingCosts_EventNodeMenu(focus);
    },

    indexText: function () {
        return ClosingCosts_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return ClosingCosts_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return ClosingCosts_OptionDescription();
    },

    colouredPngs: function () {
        return ClosingCosts_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return ClosingCosts_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const ClosingCosts_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.doesParentHouseExit) return false;

    if (
        state.includePropertyEvalFee &&
        (valueDoesNotExist(state.propertyEvaluationFee) ||
            state.propertyEvaluationFee < 0 ||
            state.propertyEvaluationFee === "")
    )
        return false;
    if (
        state.includeLandSurveyFee &&
        (valueDoesNotExist(state.landSurveyFee) ||
            state.landSurveyFee < 0 ||
            state.landSurveyFee === "")
    )
        return false;

    if (
        state.includeTitleInsurance &&
        (valueDoesNotExist(state.titleInsurance) ||
            state.titleInsurance < 0 ||
            state.titleInsurance === "")
    )
        return false;

    if (
        state.includeLegalCosts &&
        (valueDoesNotExist(state.legalCosts) ||
            state.legalCosts < 0 ||
            state.legalCosts === "")
    )
        return false;
    else return true;
};

// EFFECTS: Returns constant for event
const ClosingCosts_Constant = () => {
    return "Closing Costs";
};

// EFFECTS: Returns black png / svg for event
const ClosingCosts_Svg = () => {
    return closingCostsBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const ClosingCosts_EventNodeMenu = (focus) => {
    return <ClosingCostsCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const ClosingCosts_IndexText = () => {
    return "Select an input field to learn more about your closingCosts calculations.";
};

// EFFECTS: Returns all icons for event ??
const ClosingCosts_Icons = () => {
    let pngs = new Map([
        ["black", closingCostsBlack],
        ["blue", closingCostsBlue],
        ["white", closingCostsWhite],
        ["grey", closingCostsGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const ClosingCosts_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const ClosingCosts_InputComponentInfoPassing = (minProps) => {
    return <ClosingCostsInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, closingCostss for event ??
const ClosingCosts_OptionDescription = () => {
    const tags = ["Option"];
    const type = closingCostsObject.constant();
    const description =
        "Closing Costs are fees incurred after a Property Resale or other real estate transaction. Costs are incurred by either the buyer or the seller.";
    const usedForText =
        "The Closing Costs Node can be used to model a one-time expense due to the acquisition of a property. ";
    const connection =
        "Connect a Closing Costs Node after a House and or Property Resale Node.";
    const connectionImage = ClosingCostsConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/c/closingcosts.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const ClosingCosts_SideModalImage = () => {
    return sideImage;
};

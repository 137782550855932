import styles from "./filterableItem.module.css";
import { useAppDispatch } from "store/useAppSelectorDispatch";
import CheckboxOffIcon from "Assets/_budgetIcons/checkbox-off.svg";
import CheckboxOnIcon from "Assets/_budgetIcons/checkbox-on.svg";
import { filterEventAndEntityData } from "actions/scenario";
import dropDownArrow from "Assets/scenarioIcons/expand.svg";
import { useState } from "react";

export const FilterableItem = ({ data, filteredEventAndEntityData }) => {
    const dispatch = useAppDispatch();

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <>
            <div className={styles.item}>
                {!data.onlyEntity && (
                    <img
                        className={styles.itemCheckbox}
                        onClick={() =>
                            dispatch(filterEventAndEntityData(data.event))
                        }
                        src={
                            filteredEventAndEntityData[data.event.id]
                                ? CheckboxOnIcon
                                : CheckboxOffIcon
                        }
                        alt="check box"
                    />
                )}
                <span className={styles.itemName}>{data.event.name}</span>
                {data.entities.length > 0 && (
                    <img
                        className={`${
                            menuOpen
                                ? `${styles.dropDownArrow} ${styles.dropDownArrowOpen}`
                                : `${styles.dropDownArrow}`
                        }`}
                        onClick={() => setMenuOpen(!menuOpen)}
                        src={dropDownArrow}
                        alt="drop down arrow"
                    />
                )}
            </div>
            <div
                className={`${
                    menuOpen
                        ? `${styles.entitiesContainer} ${styles.entitiesContainerExpanded}`
                        : `${styles.entitiesContainer}`
                }`}
            >
                {data.entities &&
                    data.entities.map((entityData) => (
                        <div className={`${styles.item}`} key={entityData.id}>
                            <img
                                className={styles.itemCheckbox}
                                onClick={() =>
                                    dispatch(
                                        filterEventAndEntityData(entityData)
                                    )
                                }
                                src={
                                    filteredEventAndEntityData[entityData.id]
                                        ? CheckboxOnIcon
                                        : CheckboxOffIcon
                                }
                                alt="check box"
                            />
                            <span className={styles.itemName}>
                                {entityData.name}
                            </span>
                        </div>
                    ))}
            </div>
        </>
    );
};

import sha256 from "js-sha256";
import Event from "./_event";
import TreeManager from "../Components/TreeView/treeManager";
import { Container } from "../helpers/constants";
import _ from "lodash";
import * as uuid from "uuid";
import {
    findRootNode,
    getViableChildrenToSearch,
    prependBaselinePrefix,
} from "../helpers";
import { upsertEntitiesRaw } from "actions/entitiesActions";
import { meObject } from "Components/Registry/Me";
import { createEntitiesAPI } from "actions/entityAPIActions";
import { startObject } from "Components/Registry/Start";
import { getZoomThread } from "actions/zoomThreadActions";
import store from "store";
import { containerObject } from "Components/Registry/Container";
import { getSingleEntity } from "actions/getNodeEntityActions";
export class EventManager {
    /** - Ensure that the redux state itself is not passed to this constructor
     *
     * @param {function} updateCanvas
     * @param {*} _updateScenarioCanvas
     */
    constructor(
        updateCanvas = (_sendToDecisionEngine) => {
            /** noop */
        },
        _updateScenarioCanvas = () => {
            /** noop */
        }
    ) {
        this.isBaseline = false;
        this.nodes = {};
        this.baseline = [];
        this.baselineNodes = {};
        this.actions = [];
        this.root = null;
        this.depth = {};
        this.breadth = {};
        this.maxBreadth = 0;
        this.updateCanvas = updateCanvas;
        this._updateScenarioCanvas = () => {
            const data = this.exportData();
            _updateScenarioCanvas(data, this.isBaseline);
        };
        this.toDelete = null;
        this.personData = [];
        this.scenarioThreads = [];
        /**
         * This is a sorted array of all the event's ids in the current scenario.
         * This array is sorted in priority of lowest depth(left and right on the canvas) and then lowest breadth(up and down on the canvas).
         * There are cases where we require the sorted events from left to right, ie the decision engine panel when we zoom in.
         */
        this.sortedEventIds = [];
    }

    // count nodes with the same base name
    countNodesWithSameName = (name) => {
        let count = 0;
        let splitName = name.split(" ");
        for (let node of Object.values(this.nodes)) {
            let splitOtherName = node.name.split(" ");
            let lastPart = splitOtherName[splitOtherName.length - 1];
            if (
                node.name.startsWith(name) &&
                !Number.isNaN(parseInt(lastPart)) &&
                splitName.length + 1 === splitOtherName.length
            ) {
                count += 1;
            }
        }
        return count;
    };

    toggleBaselineNode = (rootNode, baselineNode) => {
        const root = this._findEvent(rootNode);
        root.updateNodeBaseline(baselineNode);
    };

    toggleAllBaselineNodes = (rootNode, baselineNodes) => {
        const root = this._findEvent(rootNode);
        root.toggleAllBaselineNodes(baselineNodes);
    };

    getRootNode = () => {
        const nodesArray = Object.values(this.nodes);
        if (Object.values(this.nodes).length < 1) return null;
        return findRootNode(nodesArray[0]);
    };

    // Useful with baselineManager. But with BaselineManager, none of the baseline nodes
    //  have the baseline prefix. If you want them, set addBaselinePrefix to true.
    // Note: returns deep copies of the nodes; changes to the returned nodes won't affect what's
    //  stored in scenario. (This was done to enable adding the baseline prefix)
    collectTypesFromAllNodes = (types, addBaselinePrefix) => {
        const results = {};
        for (let type of types) {
            results[type] = [];
        }
        for (const node in this.nodes) {
            if (this.nodes[node].isBypassed()) continue;
            const curNode = _.cloneDeep(this.nodes[node]);
            if (addBaselinePrefix)
                curNode.id = prependBaselinePrefix(curNode.id);
            const type = curNode.type;
            if (results[type]) results[type].push(curNode);
        }

        return results;
    };

    resetUserSetXY = () => {
        const allEventsArray = this.allNodes();
        const resetEvents = allEventsArray.map((event) => {
            const exportedData = event?.exportData();
            return {
                ...exportedData,
                userSetX: null,
                userSetY: null,
            };
        });
        return resetEvents;
    };

    /**
     * Returns array of nodes in thread w/ threadId, returns null if no thread found
     * @param {string} threadId
     * @returns {Event[]}
     */
    // if no thread has Id matching threadId
    getNodesFromThread = (threadId, excludeBaselineNodes = false) => {
        const targetThread = this.scenarioThreads.find(
            (thread) => thread.signature === threadId
        );
        if (targetThread === undefined) return null;

        const nodes = excludeBaselineNodes
            ? targetThread.nodes.filter((n) => !n.isBaseline())
            : targetThread.nodes;
        return nodes;
    };

    /**
     * Finds all the nodes in this thread up to nodeId, baseline nodes included.
     *
     * eg. allNodesInThread = [node1, node2, node3, node4, node5]
     * getNodesFromThreadUpToNode(threadId, node3) => [node1, node2, node3]
     *
     * Order is not guaranteed.
     *
     * @param {string} threadId
     * @param {string} nodeId
     * @returns {string[]}
     */
    getNodesFromThreadUpToNode = (threadId, nodeId) => {
        const traverse = (
            rootNode,
            curNodesArr,
            stopNodeId,
            allNodesInThread
        ) => {
            if (rootNode.id === stopNodeId) {
                if (!this.isNodeInThread(rootNode, curNodesArr)) {
                    curNodesArr.push(rootNode);
                }
                return;
            }
            if (!this.isNodeInThread(rootNode, allNodesInThread)) {
                return;
            } else if (!this.isNodeInThread(rootNode, curNodesArr)) {
                curNodesArr.push(rootNode);
            }

            rootNode.children.forEach((child) => {
                traverse(child, curNodesArr, stopNodeId, allNodesInThread);
            });
        };

        const allNodesInThread = this.getNodesFromThread(threadId);
        const meNode = this.getRootNode();
        const nodesArr = [];
        traverse(meNode, nodesArr, nodeId, allNodesInThread);
        const baselineNodeIds = this.baseline
            .filter((baselineNode) => baselineNode.isBaseline())
            .map((baselineNode) => baselineNode.id);
        const nodesArrString = nodesArr.map((node) => node.id); // TODO: Sort by node.depth in order to get the same consistent order for this thread.

        const finalNodeIds = [...baselineNodeIds, ...nodesArrString];
        return finalNodeIds;
    };

    // Note: won't work w/ baselineManager
    getBaselineNodesByTypes = (types) => {
        let result = {};
        for (let type of types) {
            result[type] = [];
        }
        for (let node of this.baseline) {
            if (node.isBypassed()) continue;
            if (result[node.type]) result[node.type].push(node);
        }
        return result;
    };

    // Returns obj of arrays mapped by node type containing nodes upstream
    // in targetNode's threads based on an input array of types
    //  - Example output: {House: [node1, node2], Mortgage: [node3] }
    // Note: won't work w/ baselineManager
    collectUpstreamNodesByTypes = (targetNode, types) => {
        let root = this.getRootNode();

        let tempTypeData = {};
        let results = {};
        for (let type of types) {
            results[type] = new Set();
            tempTypeData[type] = new Set();
        }

        this.dfsCollectNodes(root, targetNode, tempTypeData, results);
        // process sets into arrays for safety
        let processedResults = {};
        for (let type in results) {
            processedResults[type] = Array.from(results[type]);
        }
        return processedResults;
    };

    // Helper for collectUpstreamNodesOfTypes; performs DFS traversal from node
    // to target
    // note: nodes aren't added immediately to results, as the node may not be
    //      a child on the current path; we only add the houses/ mortgages
    //      seen on cur path when target is reached
    // todo: optimize by storing nodes seen to avoid re-computations
    dfsCollectNodes = (node, target, typeDatas, results) => {
        if (!node) return;

        // make a local copy to isolate results from other paths, and add node
        let tempTypeDatas = {};
        for (let type in typeDatas) {
            tempTypeDatas[type] = new Set(typeDatas[type]);
            if (node.isBypassed()) continue;
            if (node.type === type) tempTypeDatas[type].add(node);
        }

        // end of cur path
        if (node === target) {
            for (let type in tempTypeDatas) {
                tempTypeDatas[type].forEach(results[type].add, results[type]);
            }
            return;
        }

        let nodesToSearch = getViableChildrenToSearch(node, target);

        for (let child of nodesToSearch) {
            this.dfsCollectNodes(child, target, tempTypeDatas, results);
        }
    };

    cancelDelete = () => {
        this.toDelete = null;
    };

    createBaseline = () => {
        console.log("This function should not be getting called");
        this.createEvent("Baseline", { name: "Baseline" });
    };

    addMeBusiness = (baseline = false, type = "Me", clientData = undefined) => {
        let userDetails = JSON.parse(localStorage.getItem("loggedInUser"));
        let userData = JSON.parse(localStorage.getItem("userDetails"));
        if (clientData?.name) {
            userDetails.name = clientData.name;
            userData = clientData;
        }

        const name = userDetails
            ? userDetails.name.split(" ")[0]
            : "Whatifi-Guest";
        let data;
        // TODO - lots of redundant code, can definitely clean it up
        if (userData) {
            // The entity
            const entity = {
                name: name,
                id: uuid.v4(),
                cadence: "one-time",
                startDate: "",
                endDate: "",
                type: meObject.constant(),
                version: meObject.version(),
                data: { ...userData, tag: `@${name}` },
            };
            // The eventState
            data = {
                name,
                entities: [{ id: entity.id, active: true }],
                isFilled: true,
                valid: true,
                baseline,
                type,
            };
            // Call createEntities to update database
            // Call upsertEntities to update frontend
            createEntitiesAPI([entity])
                .then((_res) => {
                    upsertEntitiesRaw({
                        [entity.id]: entity,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            // Guest entity
            const entity = {
                id: uuid.v4(),
                cadence: "one-time",
                startDate: "",
                endDate: "",
                type: meObject.constant(),
                version: meObject.version(),
                data: {
                    applyInflation: false,
                    value: "",
                    tag: "@Guest",
                    rating: 0,
                    inflationDate: null,
                    inflation: false,
                    baseline: false,
                    description: "",
                    country: "CA",
                    state: "British Columbia",
                    hasDisability: false,
                    birthMonth: "March",
                    birthYear: "1990",
                },
                name: "Guest",
            };
            // Guest eventState
            data = {
                name: "Guest",
                entities: [{ id: entity.id, active: true }],
                isFilled: true,
                valid: true,
                baseline,
                type,
            };
            // Call upsertEntities to update frontend
            upsertEntitiesRaw({
                [entity.id]: entity,
            });
            // We don't call creatEntities because we don't want guest data in database
        }

        // Add event to canvas
        if (baseline) {
            this.createEvent(type, data);
        } else {
            this._reset();
            this.createEvent(type, data);
        }
    };
    addStart = () => {
        const userDetails = JSON.parse(localStorage.getItem("loggedInUser"));
        const userData = JSON.parse(localStorage.getItem("userDetails"));
        const name = userDetails
            ? userDetails.name.split(" ")[0]
            : "Whatifi-Guest";
        let data;
        if (userData) {
            // The entity
            const entity = {
                id: uuid.v4(),
                cadence: "one-time",
                startDate: "",
                endDate: "",
                type: startObject.constant(),
                version: startObject.version(),
                name: name,
                data: {
                    excludedBaselineNode: [],
                },
            };
            // The eventState
            data = {
                name,
                entities: [{ id: entity.id, active: true }],
                isFilled: true,
                valid: true,
                baseline: false,
                type: startObject.constant(),
            };
            // Call createEntities to update database
            // Call upsertEntities to update frontend
            createEntitiesAPI([entity])
                .then((_res) => {
                    upsertEntitiesRaw({
                        [entity.id]: entity,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            // Guest entity
            const entity = {
                id: uuid.v4(),
                cadence: "one-time",
                startDate: "",
                endDate: "",
                type: startObject.constant(),
                version: startObject.version(),
                data: {},
                name: "Guest",
            };
            // Guest eventState
            data = {
                name: "Guest",
                entities: [{ id: entity.id, active: true }],
                isFilled: true,
                valid: true,
                baseline: false,
                type: startObject.constant(),
            };
            // Call upsertEntities to update frontend
            upsertEntitiesRaw({
                [entity.id]: entity,
            });
            // We don't call creatEntities because we don't want guest data in database
        }
        this._reset();
        this.createEvent(startObject.constant(), data);
    };

    addOnboardingScenario = (type, data, loadedScenario, child) => {
        const node = this.createEvent(type, data);
        this.attachToNodes(node, loadedScenario, child, false);
        this.calculate();
    };

    deleteBaselineNode = (id) => {
        delete this.nodes[id.id];
        this.calculate();
        this._updateScenarioCanvas();
    };

    _reset = () => {
        this.nodes = {};
        this.actions = [];
        this.root = null;
        this.depth = {};
        this.breadth = {};
        this.maxBreadth = 0;
        this.sortedEventIds = [];
    };

    resetBaseline = () => {
        this.baseline = [];
        this.baselineNodes = {};
    };

    getSortedEventIds = () => {
        return this.sortedEventIds;
    };

    _treeDepth = () => {
        let depth = 0;
        Object.values(this.nodes).forEach((node) => {
            if (node.depth != null && node.depth > depth) {
                depth = node.depth;
            }
        });

        return depth;
    };

    _leafNodes = () => {
        let leaves = [];
        Object.values(this.nodes).forEach((node) => {
            if (node.children.length === 0) {
                leaves.push(node);
            }
        });

        return leaves;
    };

    collectorNode = () => {
        let depth;
        this.allNodes().forEach((node) => {
            if (depth == null || node.x() > depth) {
                depth = node.x();
            }
        });

        const collectorDetails = { display: false };
        if (this.root != null) {
            collectorDetails["display"] = true;
            collectorDetails["x"] = depth + 200;
            collectorDetails["y"] = this.root.y();
        }

        return collectorDetails;
    };

    createEvent = (
        type = "event",
        data = {},
        uuid = "",
        isBaseline = false,
        isLinked = false
    ) => {
        const _event = new Event(type, data, uuid, this);
        if (isLinked) _event.linked = true;
        if (isBaseline) {
            _event.setHideInCanvas(true);
            _event.id = prependBaselinePrefix(_event.id);
        }

        if (isBaseline) {
            _event.parents = [];
            _event.children = [];
            this.baselineNodes[_event.id] = _event;
            this.baseline.push(_event);
        } else {
            this.nodes[_event.id] = _event;

            // QUESTION: What does this actions array do?
            this.actions.push({
                action: "createEvent",
                args: { type, data, id: _event.id },
            });
        }
        if (this.root == null) {
            this.root = _event;
        }

        // this._updateScenarioCanvas();
        return _event;
    };

    _findEvent = (identifier) => {
        let node = this._findEventUtil(identifier, this.nodes);
        if (!node && this.baselineNodes) {
            node = this._findEventUtil(identifier, this.baselineNodes);
        }
        return node;
    };

    _findEventUtil = (identifier, nodes) => {
        let _event = identifier;
        if (typeof identifier === "string") {
            _event = nodes[identifier];
        }
        return _event;
    };

    /**
     *
     * This function will:
     *     1. Find the head node, and detach it from its parent nodes and place the container node in its place.
     *     2. Find the tail node, and detach it from its children nodes and place the container node in its place.
     *
     * @param {object} containerNodeData
     * @param {string} containerNodeData.id
     * @param {string} containerNodeData.headNode - The container's head node ID
     * @param {string} containerNodeData.tailNode - The container's tail node ID
     */
    attachContainerNode = (containerNodeData) => {
        // TODO - Does a container eventState contain headNode, tailNode, and isContainerExpanded fields?
        //        if not then we need to modify all references to the three above fields to be congruent with our data structure
        //        if it does then this message can be ignored
        const {
            id,
            headNode: headNodeID,
            tailNode: tailNodeID,
        } = containerNodeData;
        if (!(id && headNodeID && tailNodeID)) {
            console.error("Missing ID, headNode ID, or tailNode ID");
        }

        const containerNode = this._findEvent(id);

        // Find the head node
        const headNode = this._findEvent(headNodeID);
        // Find all parents to the head node
        const headNodeParents = [...headNode.parents];
        // Find index of the head node among its parents' children
        let headNodeIndexAsChild = -1;
        headNodeParents.forEach((parent) => {
            headNodeIndexAsChild = parent.children.indexOf(headNode);
        });
        // Detach parents from the head node
        // Detach head node from its parents
        this._detachNodes(headNodeParents, [headNode]);

        // Find the tail node
        const tailNode = this._findEvent(tailNodeID);
        // Find all children to the tail node
        const tailNodeChildren = [...tailNode.children];
        // Find index of the tail node among its children's parents
        let tailNodeIndexAsChild = -1;
        tailNodeChildren.forEach((child) => {
            tailNodeIndexAsChild = child.parents.indexOf(tailNode);
        });
        // Detach children from the tail node
        // Detach tail node from its children
        this._detachNodes([tailNode], tailNodeChildren);

        // Attach container node to head node's parents' children
        // Attach parent nodes to container node's parents array
        containerNode.setParents(
            headNodeParents,
            false,
            true,
            headNodeIndexAsChild
        );
        // Attach container node to tail node's children's parents
        // Attach children nodes to container node's children array
        containerNode.setChildren(
            tailNodeChildren,
            false,
            true,
            tailNodeIndexAsChild
        );
    };

    detachContainerNode = (containerNodeData) => {
        const {
            id,
            headNode: headNodeID,
            tailNode: tailNodeID,
        } = containerNodeData;
        if (!(id && headNodeID && tailNodeID)) {
            console.error("Missing ID, headNode ID, or tailNode ID");
        }

        // Find containerNode;
        const containerNode = this._findEvent(id);

        // Find the head node
        const headNode = this._findEvent(headNodeID);
        // Find all parents to the container node
        const containerNodeParents = [...containerNode.parents];
        // Find index of the container node among its parents' children
        let containerIndexAsChild = -1;
        containerNodeParents.forEach((parent) => {
            containerIndexAsChild = parent.children.indexOf(containerNode);
        });
        // Detach parents from the container node
        // Detach container node from its parents
        this._detachNodes(containerNodeParents, [containerNode]);

        // Find the tail node
        const tailNode = this._findEvent(tailNodeID);
        // Find all children to the container node
        const containerNodeChildren = [...containerNode.children];
        // Find index of the container node among its children's parents
        let containerIndexAsParent = -1;
        containerNodeChildren.forEach((child) => {
            containerIndexAsParent = child.parents.indexOf(containerNode);
        });
        // Detach children from the container node
        // Detach container node from its children
        this._detachNodes([containerNode], containerNodeChildren);

        // Attach head node to container node's parents' children
        // Attach parent nodes to head node's parents array
        headNode.setParents(
            containerNodeParents,
            false,
            true,
            containerIndexAsChild
        );
        // Attach tail node to container node's children's parents
        // Attach children nodes to tail node's children array
        tailNode.setChildren(
            containerNodeChildren,
            false,
            true,
            containerIndexAsParent
        );
    };

    attachToNodes = (
        identifier,
        parents = [],
        children = [],
        value = true,
        linkedReferenceEventId = ""
    ) => {
        if (linkedReferenceEventId) {
            const linkedEvent = this._findEvent(linkedReferenceEventId);
            linkedEvent.linked = true;
        }

        const _event = this._findEvent(identifier);

        parents = parents.map(this._findEvent);
        children = children.map(this._findEvent);

        //find index of the sub-thread
        let temp = parents[0]?.children;
        let index = temp.findIndex(
            (element) => element?.id === children[0]?.id
        );

        this._detachNodes(parents, children);

        // if this is new thread, move to bottom, else, keep thread where it is
        if (children.length === 0) {
            _event.setParents(parents, false, true, 9999);
        } else {
            index = index == undefined ? -1 : index;
            _event.setParents(parents, false, true, index);
        }

        _event.setChildren(children);
        // if the parent has had its position set by the user, base newly created events position(userSetX/Y) based off parents position.
        const relevantParent = _event?.parents?.[0];

        if (
            typeof relevantParent?.userSetX == "number" &&
            typeof relevantParent?.userSetY == "number"
        ) {
            _event.userSetX = relevantParent?.userSetX + 210;
            _event.userSetY = relevantParent?.userSetY;
        }

        if (value) {
            this._updateScenarioCanvas();
        }
    };

    separateNodes = (parentNode, childNode) => {
        const parentEvent = this._findEvent(parentNode);
        const childEvent = this._findEvent(childNode);

        this._detachNodes([parentEvent], [childEvent]);
        this.calculate();
        this._updateScenarioCanvas();
    };

    _findCommonEvents = (root) => {
        const threads = this._generateScenarioThreads(root, true);
        let commonEvents = [];
        if (threads.length === 1) {
            commonEvents = (threads[0].nodes || []).map((event) => {
                return event.id;
            });
        } else if (threads.length > 1) {
            const threadEvents = threads.map((thread) => {
                const events = thread.nodes.map((event) => {
                    return event.id;
                });
                events.shift();
                return events;
            });

            while (threadEvents.length > 1) {
                const t1 = threadEvents.shift(),
                    t2 = threadEvents.shift();

                const existingEvents = {};
                t1.forEach((event) => {
                    existingEvents[event] = false;
                });

                t2.forEach((event) => {
                    if (existingEvents[event] != null) {
                        existingEvents[event] = true;
                    }
                });

                const commonEvents = [];
                Object.keys(existingEvents).forEach((event) => {
                    if (existingEvents[event] === true) {
                        commonEvents.push(event);
                    }
                });
                threadEvents.push(commonEvents);
            }

            commonEvents = threadEvents[0];
        }
        return commonEvents;
    };

    updateDeletionData = (root) => {
        if (root === this.root) {
            return;
        }

        let commonEvent,
            removeAllNodes = true;
        const toDelete = [];
        if (root.children.length < 2) {
            toDelete.push(root);
            commonEvent = root.children.length === 1 ? root.children[0] : null;
        } else {
            const subThreads = this._generateScenarioThreads(root, true);
            const commonEvents = this._findCommonEvents(root);
            commonEvent =
                commonEvents.length > 0
                    ? this._findEvent(commonEvents[0])
                    : null;

            const pendingRemoval = {};
            pendingRemoval[root.id] = true;
            subThreads.forEach((thread) => {
                let stop = false;
                thread.nodes.forEach((event) => {
                    if (event.id !== root.id) {
                        if (commonEvent && event.id === commonEvent.id) {
                            stop = true;
                        }
                        if (!stop) {
                            let toRemoveParents = true;
                            event.parents.forEach((parent) => {
                                if (pendingRemoval[parent.id] == null) {
                                    toRemoveParents = false;
                                    removeAllNodes = false;
                                }
                            });
                            if (toRemoveParents) {
                                pendingRemoval[event.id] = true;
                            }
                        }
                    }
                });
            });

            Object.keys(pendingRemoval).forEach((event) => {
                const node = this._findEvent(event);
                if (node) {
                    toDelete.push(node.id);
                }
            });
        }

        if (!removeAllNodes) {
            commonEvent = null;
        }

        return {
            root: root,
            newRoot: (commonEvent || {}).id,
            toDelete,
            isBaselineNode: false,
        };
    };

    deleteWithDeletionData = (deletionData) => {
        if (deletionData) {
            const { root, newRoot, toDelete } = deletionData;

            const realNewRoot = this._findEvent(newRoot);

            let index = -1;
            index = root.parents[0]?.children?.findIndex(
                (element) => element?.id === root?.id
            );

            if (root) {
                const rootParents = [];
                if (realNewRoot) {
                    root.parents.forEach((parent) => {
                        rootParents.push(parent);
                    });
                }

                toDelete.forEach((_node) => {
                    const node = this._findEvent(_node);
                    node.detachChildren(node.children);
                    node.detachParents(node.parents);
                    delete this.nodes[node.id];
                });

                if (realNewRoot) {
                    rootParents.forEach((parent) => {
                        index = index === undefined ? -1 : index;
                        parent.setChildren([realNewRoot], true, true, index);
                    });
                }
            }

            this._updateScenarioCanvas();
        }
    };

    deleteEvent = (_root) => {
        const root = this._findEvent(_root);
        if (root === this.root) {
            return;
        }

        let commonEvent,
            removeAllNodes = true;
        const toDelete = [];
        if (root.children.length < 2) {
            toDelete.push(root);
            commonEvent = root.children.length === 1 ? root.children[0] : null;
        } else {
            const subThreads = this._generateScenarioThreads(root, true);
            const commonEvents = this._findCommonEvents(root);
            commonEvent =
                commonEvents.length > 0
                    ? this._findEvent(commonEvents[0])
                    : null;

            const pendingRemoval = {};
            pendingRemoval[root.id] = true;
            subThreads.forEach((thread) => {
                let stop = false;
                thread.nodes.forEach((event) => {
                    if (event.id !== root.id) {
                        if (commonEvent && event.id === commonEvent.id) {
                            stop = true;
                        }
                        if (!stop) {
                            let toRemoveParents = true;
                            event.parents.forEach((parent) => {
                                if (pendingRemoval[parent.id] == null) {
                                    /*
                                    console.log(
                                        "\tParent",
                                        parent.metadata.name
                                    );
                                    */
                                    toRemoveParents = false;
                                    removeAllNodes = false;
                                }
                            });
                            if (toRemoveParents) {
                                pendingRemoval[event.id] = true;
                            }
                        }
                    }
                });
            });

            Object.keys(pendingRemoval).forEach((event) => {
                const node = this._findEvent(event);
                if (node) {
                    toDelete.push(node.id);
                }
            });
        }

        if (!removeAllNodes) {
            commonEvent = null;
        }

        this.toDelete = toDelete;

        return {
            root: root,
            newRoot: (commonEvent || {}).id,
            toDelete,
        };

        /* Uncomment this if you want to prompt user verification before deletion
        return {
            root: root.id
            newRoot: (commonEvent || {}).id,
            toDelete
        }
        */
    };

    deleteNodes = (_root = null, _newRoot = null, _toDelete = []) => {
        const root = this._findEvent(_root),
            newRoot = this._findEvent(_newRoot),
            toDelete = [];

        let index = -1;
        index = root.parents[0]?.children?.findIndex(
            (element) => element?.id === root?.id
        );

        _toDelete.forEach((node) => {
            const _nodeToDelete = this._findEvent(node);
            if (_nodeToDelete) {
                if (_nodeToDelete.isLocked()) {
                    _nodeToDelete.toggleLocked();
                }
                toDelete.push(_nodeToDelete);
            }
        });

        if (root) {
            const rootParents = [];
            if (newRoot) {
                root.parents.forEach((parent) => {
                    rootParents.push(parent);
                });
            }

            toDelete.forEach((_node) => {
                const node = this._findEvent(_node);
                node.detachChildren(node.children);
                node.detachParents(node.parents);
                delete this.nodes[node.id];
            });

            if (newRoot) {
                rootParents.forEach((parent) => {
                    index = index === undefined ? -1 : index;
                    parent.setChildren([newRoot], true, true, index);
                });
            }
        }

        this._updateScenarioCanvas();
        this.toDelete = null;
    };

    _detachNodes = (parents = [], children = []) => {
        if (parents.length === 0 || children.length === 0) {
            return;
        }

        parents.forEach((parentEvent) => {
            parentEvent.detachChildren(children);
        });
    };

    _performAction = (action, args) => {
        switch (action) {
            case "createEvent":
                this.createEvent(args.type, args, args.id);
                break;
            default:
                console.log("Importing invalid event action:", action);
                break;
        }
    };

    importBaseline = (data = {}) => {
        //we can't assigned node to itself
        let nodes;
        this.baseline = [];
        this.baselineNodes = {};
        try {
            nodes = data.nodes;
        } catch (e) {
            console.log("error", e);
        }
        if (!nodes) return;

        nodes.forEach((nodeData) => {
            this.createEvent(nodeData.type, nodeData, nodeData.id, true);
        });

        /*
        console.log("=============== BASELINE ===============");
        this.baseline.forEach((n) => {
            console.log("name:",n.metadata.name,n.id);
        });
        console.log(this.baselineNodes);
*/
    };

    importData = (data = {}, _isBaseline = false) => {
        /**
         * data comes from loadScenario() action which is called by ~/src/Components/UserScenarios/Scenario.js
         * Doesn't seem like we need to edit this, as this just goes through every single node and gets the parents/children from the DB
         * You'll want to make sure the parents/children are set properly in the DB before calling this for Containers
         */
        this._reset();

        switch (data.version) {
            case "1.0":
                this._importData_1_0(data);
                break;
            default:
                this._importData_old(data);
        }
        if (!this.root && Object.values(this.nodes).length > 0) {
            this.root = Object.values(this.nodes)?.[0];
        }
        this.calculate();
    };

    _importData_old = (data = {}) => {
        let root,
            { nodes } = data;
        if (nodes == null) {
            const treeManager = new TreeManager();
            treeManager.importNodes(data);
            nodes = treeManager.exportNodes().nodes;
        } else {
            nodes = Object.values(nodes);
        }

        const importNodes = nodes.map((node) => {
            if (node.parents.length === 0) {
                root = node.id;
            }

            if (node.metadata["name"] == null) {
                node.metadata["name"] = node.name;
            }
            const { type } = node.metadata;
            node.metadata.type =
                type.charAt(0).toUpperCase() + type.substring(1);
            node.metadata["children"] = node.children;
            node.metadata["parents"] = node.parents;

            return node;
        });

        this._importData_1_0({ nodes: importNodes, root });
    };

    _importData_1_0 = (data = {}) => {
        let { nodes, root } = data;

        try {
            nodes = JSON.parse(nodes);
        } catch (e) {
            // noop
        }

        nodes.forEach((nodeData) => {
            this.createEvent(nodeData.type, nodeData, nodeData.id);
        });

        nodes.forEach((nodeData) => {
            const { id, children, parents } = nodeData;
            const node = this.nodes[id];
            const parentNodes = [];
            parents.forEach((id) => {
                const node = this._findEvent(id);
                if (node) {
                    parentNodes.push(node);
                }
            });
            const childrenNodes = [];
            children.forEach((id) => {
                const node = this._findEvent(id);
                if (node) {
                    childrenNodes.push(node);
                }
            });

            node.setParents(parentNodes, true, true);
            node.setChildren(childrenNodes, true, true);
        });

        this.root = this.nodes[root];
    };

    exportData = () => {
        const nodeData = Object.values(this.nodes).map((node) => {
            return node.exportData();
        });

        const loadedScenarioData =
            store?.getState()?.scenario?.loadedScenario?.data;
        //this is where is doesn't return any children/parent

        // TODO: Export actions when it's useful, ie. working on undo
        const exportData = {
            ...loadedScenarioData,
            version: "1.0",
            nodes: nodeData,
            root: this.root && this.root.id,
        };

        return exportData;
    };

    allNodes = () => {
        return Object.values(this.nodes);
    };

    /**
     *
     * @param {Event} node - Must be a container node
     */
    _allNodesIdsWithinContainer = (node, nodeIds = []) => {
        const attachToNodeIds = (root, _nodeIds) => {
            if (root.type === Container) {
                this._allNodesIdsWithinContainer(root, _nodeIds);
            }
            _nodeIds.push(root.id);
            root.children.forEach((child) => {
                attachToNodeIds(child, _nodeIds);
            });
        };

        const containerNode = this._findEvent(node);
        if (!containerNode) {
            throw new Error("No container node found");
        }
        if (containerNode.type !== Container) {
            throw new Error(
                "allNodesWithinContainer() can only be run on a container"
            );
        }
        const headNode = this._findEvent(containerNode.headNode);
        attachToNodeIds(headNode, nodeIds);
        return nodeIds;
    };

    _depthFirstTraverse = (
        node,
        currentThread,
        actions,
        containerNodeTraversalOptionsArray = []
    ) => {
        const {
            nodeAction = () => {
                /** noop */
            },
            stop = () => {
                return false;
            },
            stopAction = () => {
                /** noop */
            },
            forkAction = () => {
                /** noop */
            },
            rootAction = () => {
                /** noop */
            },
            depthResetAction = () => {
                /** noop */
            },
        } = actions;

        if (stop(node, currentThread)) {
            return stopAction(node, currentThread);
        }

        nodeAction(node, currentThread);

        if (node.type !== Container) currentThread.push(node);

        if (node.children.length > 0) {
            // forkAction(), it just ensures we create two different currentThread arrays by not doing anything.
            // Only rootAction() is significant. It signifies the end of a thread.
            forkAction(node, currentThread);
        } else if (
            containerNodeTraversalOptionsArray.length &&
            node.id === containerNodeTraversalOptionsArray[0].tailNodeId &&
            containerNodeTraversalOptionsArray[0].child
        ) {
            /**
             * Explanation of condition: if there are options inside the `containerNodeTraversalOptionsArray`,
             * and if the first item's (container's) tailNodeId corresponds to current node's id, AND if that
             * container had a child
             */
            // If this is the tail node of the container and the container had a child, do not run `rootAction()`
            forkAction(node, currentThread);
        } else if (node.type !== Container) {
            // If rootAction() is called, it signifies that we have reached the end of a thread (because there are no children)
            rootAction(node, currentThread);
        }

        node.children.forEach((child) => {
            if (node.type !== Container)
                this._depthFirstTraverse(
                    child,
                    Array.from(currentThread),
                    actions,
                    containerNodeTraversalOptionsArray
                );
        });

        if (node.type === Container && !node.containerIsExpanded) {
            // This folded container node needs to traverse through its headNode => tailNode => children (if applicable)
            const headNode = this._findEvent(node.headNode);
            const tailNodeId = node.tailNode;
            let child = null;
            if (node.children.length) {
                node.children.forEach((childNode) => {
                    child = childNode;
                    const newestContainerTraversalOptions = {
                        tailNodeId,
                        child,
                    };
                    this._depthFirstTraverse(
                        headNode,
                        Array.from(currentThread),
                        actions,
                        [
                            newestContainerTraversalOptions,
                            ...containerNodeTraversalOptionsArray,
                        ]
                    );
                });
            } else {
                const newestContainerTraversalOptions = { tailNodeId, child };
                this._depthFirstTraverse(
                    headNode,
                    Array.from(currentThread),
                    actions,
                    [
                        newestContainerTraversalOptions,
                        ...containerNodeTraversalOptionsArray,
                    ]
                );
            }
        }
        if (containerNodeTraversalOptionsArray.length) {
            /**
             * `firstOptions` always represents the immediate container we're currently traversing through
             * if `restOptions` exists, it means we are traversing through a container that is nested in other containers
             */
            const [firstOptions, ...restOptions] =
                containerNodeTraversalOptionsArray;
            const { tailNodeId, child } = firstOptions;
            if (node.id === tailNodeId && child) {
                // Entering this if statement signifies:
                // 1. We are at the end of the folded container (ie. container's tailNode)
                // 2. The container had child(ren)
                this._depthFirstTraverse(
                    child,
                    Array.from(currentThread),
                    actions,
                    restOptions
                );
            }
        }

        depthResetAction(node, currentThread);
    };

    _attachDetachContainerNodes = () => {
        const containerNodes = this.allNodes().filter(
            (node) => node.type === Container
        );

        // Detach all container nodes as necessary
        containerNodes.forEach((node) => {
            if (node.parents.length || node.children.length) {
                this.detachContainerNode(node);
            }
        });

        // Attach all container nodes as necessary
        containerNodes.forEach((node) => {
            if (!node.containerIsExpanded) {
                this.attachContainerNode(node);
            }
        });
    };

    expandContainer(containerId) {
        const container = this._findEvent(containerId);
        container.containerIsExpanded = !container.containerIsExpanded;
        this.calculate();
    }

    _resetHideInCanvasForAllNodes = () => {
        this.allNodes().forEach((n) => {
            n?.setHideInCanvas?.(false);
        });
    };

    _resetAllNodes = () => {
        this.breadth = {};
        this.depth = {};

        this._resetHideInCanvasForAllNodes();

        /*
        Object.values(this.nodes).forEach((node) => {
            node.depth = 0;
            node.depthSet = null;

            node.breadth = 0;
            node.breadthSet = null;
        });
*/
    };

    calculate(allowSendToDecisionEngine = true) {
        const sendToDecisionEngine =
            allowSendToDecisionEngine &&
            !(store?.getState()?.scenario?.pauseDecisionEngine ?? false);
        const root = this.root;
        const setAt = new Date();
        this._resetAllNodes();
        this._attachDetachContainerNodes();

        this._generateEventDepth(root, 0, setAt);
        Object.values(this.nodes).forEach((node) => {
            if (this.depth[node.depth] == null) {
                this.depth[node.depth] = [];
            }
            this.depth[node.depth].push(node);
        });

        this._generateLevels(root);
        this._generateEventBreadth(root, 0, setAt);
        this.scenarioThreads = this._generateScenarioThreads(root);
        this._filterActiveNodes();

        this._hideContainerNodes();

        // Create sortedEventIds
        const nodeArray = Object.values(this.nodes);
        nodeArray.sort((a, b) => a.depth - b.depth || a.breadth - b.breadth);
        this.sortedEventIds = nodeArray.map((node) => node.id);

        // Hide events not in thread if in zoomed thread mode
        // TODO: This is not an ideal solution. Remove this section once the lines & events determine themselves whether or not they should hide/show
        const zoomedThreadId = getZoomThread();
        if (zoomedThreadId) {
            this.hideNodesNotInThread(zoomedThreadId);
        }

        this.updateCanvas(sendToDecisionEngine);
    }

    _generateLevels = (root) => {
        const rootNodes = this._findCommonEvents(root);
        const rootEvents = rootNodes.map((id) => {
            return this._findEvent(id);
        });
        rootEvents.unshift(root);

        const levels = {},
            processedNodes = {};
        const sets = [rootEvents],
            nextSet = {};
        let currentLevel = 0,
            levelApplied = true;

        const processSet = (currentRoot) => {
            if (currentRoot && currentRoot?.id) {
                processedNodes[currentRoot.id] = true;
                if (levels[currentRoot.id] == null) {
                    levels[currentRoot.id] = currentLevel;
                    levelApplied = true;
                }

                const commonEvents = this._findCommonEvents(currentRoot);
                commonEvents.forEach((id) => {
                    const event = this._findEvent(id);
                    if (event && event.children && levels[id] == null) {
                        levels[id] = currentLevel;
                        levelApplied = true;
                    }
                });
                currentRoot.children.forEach((child) => {
                    if (!processedNodes[child.id]) {
                        nextSet[child.id] = child;
                    }
                });
            }
        };

        while (sets.length > 0) {
            const currentSet = sets.shift();
            currentLevel = levelApplied ? currentLevel + 1 : currentLevel;
            levelApplied = false;

            currentSet.forEach(processSet);

            const events = [];
            Object.keys(nextSet).forEach((id) => {
                if (processedNodes[id] == null) {
                    events.push(nextSet[id]);
                }
            });
            if (events.length > 0) {
                sets.push(events);
            }
        }
        Object.keys(levels).forEach((id) => {
            const event = this._findEvent(id);
            event.level = levels[id];
        });
    };

    /**
     * Goes through all nodes in the scenario and sets any node that is not in `threadId` as `hideInCanvas` = true
     *
     * @param {string} threadId
     * @returns {void}
     */
    hideNodesNotInThread = (threadId) => {
        const zoomedThread = this.scenarioThreads.find(
            (t) => t.signature === threadId
        );
        if (!zoomedThread) {
            return;
        }
        const allScenarioNodes = this.allNodes();

        // Construct a hashmap of threadNodes so we we can do O(2n) instead of O(n^2)
        const zoomedThreadNodes = {};
        zoomedThread.nodes.forEach((n) => {
            zoomedThreadNodes[n.id] = n;
        });

        // Loop through all scenario nodes and check if they exist inside zoomedThreadNodes
        allScenarioNodes.forEach((scenarioNode) => {
            if (!zoomedThreadNodes[scenarioNode.id]) {
                // If they do not exist, mark them as hidden
                scenarioNode.setHideInCanvas(true);
            }
        });
    };

    /**
     * Goes through each node that's not hidden in current thread, and places them all in one line.
     *
     * It does so by the following:
     * 1. Get Me Node's depth & breadth.
     * 2. Loop through children to find non-hidden child that has next lowest depth.
     * 3. Sets child node's depth = depth + 1, breadth = breadth
     * 4. Repeat 2-3.
     * @param {string} threadId
     */
    ultraZoomThread = (threadId) => {
        // Experimental flag
        if (process.env?.REACT_APP_ENV !== "development") return;

        // Step 1: Hide all nodes that are not a part of this thread.
        this.hideNodesNotInThread(threadId);

        // Step 2: Get all nodes in thread that are not hidden (ie. b/c of collapsed container) and not baseline nodes (ie. within Me node)
        const zoomedThread = this.scenarioThreads.find(
            (t) => t.signature === threadId
        );
        if (!zoomedThread) {
            return;
        }
        const allThreadNodes = zoomedThread.nodes;

        const _ultraZoomThreadNodes = allThreadNodes.filter(
            (n) => !n.hideInCanvas && !n.isBaseline()
        );
        const ultraZoomThreadNodes = _ultraZoomThreadNodes.filter((n) => {
            if (n.type === Container && n.containerIsExpanded) {
                return false;
            }
            return true;
        });

        // Step 3: Sort the nodes from lowest depth to highest depth
        ultraZoomThreadNodes.sort((a, b) => a.depth - b.depth);
        if (!ultraZoomThreadNodes.length) {
            return;
        }

        // Step 4: Cache the first node's breadth, pop first node out of the array
        const firstNode = ultraZoomThreadNodes.shift();
        const breadth = firstNode.breadth;

        // Loop
        let depth = firstNode.depth;
        ultraZoomThreadNodes.forEach((n) => {
            n.breadth = breadth;
            n.depth = depth + 1;
            depth++;
        });
    };

    /**
     * @param {Event} event
     * @param {Event[]} thread - An array of nodes
     * @returns {boolean}
     */
    isNodeInThread = (node, thread) => {
        let event = node;
        if (typeof node === "string") {
            event = this._findEvent(node);
        }

        let nodeInThread = false;
        if (event.type === Container) {
            // Check if container is folded, has parents/children
            if (
                !event.containerIsExpanded &&
                (event.parents.length || event.children.length)
            ) {
                const nodeIdsWithinContainer =
                    this._allNodesIdsWithinContainer(event);
                const nodeIdsInThread = thread.map((n) => n.id);
                nodeIdsWithinContainer.forEach((nodeIdWithinContainer) => {
                    if (nodeIdsInThread.includes(nodeIdWithinContainer)) {
                        nodeInThread = true;
                    }
                });
            }
        } else {
            nodeInThread = thread.includes(event);
        }

        return nodeInThread;
    };

    _filterActiveNodes = () => {
        Object.values(this.nodes).forEach((node) => {
            let activeNode = false;
            this.scenarioThreads.forEach((threadDetails) => {
                const thread = threadDetails.nodes;
                if (this.isNodeInThread(node, thread)) {
                    activeNode = true;
                }
            });
            node.active = activeNode;
        });
    };

    /**
     * Returns an array of container nodes that are contained within this thread.
     *
     * @param {Event[]} currentThread
     *
     * @returns {Event[]}
     */
    _containerNodesInThread = (currentThread) => {
        const allContainerNodes = this.allNodes().filter(
            (n) => n.type === Container
        );

        const containerNodes = allContainerNodes.filter((containerNode) => {
            const headNode = this._findEvent(containerNode.headNode);
            const tailNode = this._findEvent(containerNode.tailNode);

            return (
                currentThread.includes(headNode) ||
                currentThread.includes(tailNode)
            );
        });
        return containerNodes;
    };

    _generateScenarioThreads = (root, processAll = false) => {
        const allThreads = [],
            signatures = [],
            allNames = {};
        if (root != null) {
            const rootAction = (node, currentThread) => {
                const activeNodes = [];
                let lastDecision,
                    lastDecisions = [],
                    lastOptions = [],
                    isPrevDecision = false;
                currentThread.forEach((node) => {
                    // commenting out this check for now, in case we
                    // change our mind about the desired behaviour
                    // if (!node.isBypassed() || processAll)
                    activeNodes.push(node.id);
                    if (node.type === "Decision") {
                        lastDecision = node;
                        isPrevDecision = true;
                    } else {
                        if (isPrevDecision) {
                            lastDecisions.push(lastDecision);
                            lastOptions.push(node);
                        }
                        isPrevDecision = false;
                    }
                });
                let threadName;
                if (lastDecisions.length > 0 && lastOptions.length > 0) {
                    threadName = `${
                        lastDecisions[lastDecisions.length - 1].name
                    }: ${lastOptions[lastOptions.length - 1].name}`;
                    threadName =
                        threadName.charAt(0).toUpperCase() +
                        threadName.slice(1);
                    // MULTI-DECISION NAME
                    /*
                    lastDecisions.forEach((lastDecision,i) => {
                        if (i === 0) {
                            threadName = `${lastDecision.metadata.name}: ${lastOptions[i].metadata.name}`;
                        } else if (i < 2) {
                            threadName = `${threadName}\n${lastDecision.metadata.name}: ${lastOptions[i].metadata.name}`;
                        }
                    });
                    */
                    if (allNames[threadName] == null) {
                        allNames[threadName] = 0;
                    }
                    allNames[threadName]++;
                    if (allNames[threadName] > 1) {
                        threadName = `${threadName} (${allNames[threadName]})`;
                    }
                }

                const signature = sha256(activeNodes.sort().join("_"));

                if (!signatures.includes(signature)) {
                    /**
                     * Include all container nodes as a part of scenarioThread.nodes
                     */
                    const containerNodesInThread =
                        this._containerNodesInThread(currentThread);
                    containerNodesInThread.forEach((n) =>
                        currentThread.push(n)
                    );

                    const scenarioThread = {
                        signature,
                        nodes: currentThread,
                    };
                    if (threadName) {
                        scenarioThread["name"] = threadName;
                    }
                    allThreads.push(scenarioThread);
                    signatures.push(signature);
                } else {
                    console.log("signature exists", signature);
                }
            };

            // Get depths
            this._depthFirstTraverse(root, [], { rootAction });
        }

        const locallyIgnoredNodes = [];
        Object.values(this.nodes).forEach((node) => {
            if (node.isLocked() && !processAll) {
                let toLock = node;
                while (
                    toLock.parents.length > 0 &&
                    toLock.siblings().length === 0
                ) {
                    toLock = toLock.parents[0];
                }
                locallyIgnoredNodes.push(...toLock.siblings());
            }
        });

        const filteredThreads = [];
        allThreads.forEach((threadDetails) => {
            const thread = threadDetails.nodes;
            let containsIgnored = false;
            locallyIgnoredNodes.forEach((node) => {
                if (thread.includes(node)) {
                    containsIgnored = true;
                }
            });
            // remove duplicates if any
            const filteredNodes = [];
            for (let nodeId of thread) {
                if (!filteredNodes.includes(nodeId)) filteredNodes.push(nodeId);
            }
            const eventsMap = {};
            filteredNodes?.map((event) => (eventsMap[event?.id] = true));

            if (!containsIgnored) {
                filteredThreads.push({
                    ...threadDetails,
                    nodes: filteredNodes,
                    eventsMap: eventsMap,
                });
            }
        });

        return filteredThreads;
    };

    _hideNestedDescendants = (
        root,
        depth,
        breadth,
        tailNodeId,
        setAt = new Date()
    ) => {
        /**
         * root starts as the headNode of a container, then recursively hides all nodes, including
         * nodes within nested containers
         */
        if (root !== null) {
            root.setDepth(depth, setAt);
            root.setBreadth(breadth, setAt);
            root.setHideInCanvas(true);

            if (root.type === Container) {
                const headNode = this._findEvent(root.headNode);
                this._hideNestedDescendants(
                    headNode,
                    depth,
                    breadth,
                    root.tailNode,
                    setAt
                );
            }

            if (root.id !== tailNodeId) {
                root.children.forEach((_node) => {
                    const node = this._findEvent(_node);
                    this._hideNestedDescendants(
                        node,
                        depth,
                        breadth,
                        tailNodeId,
                        setAt
                    );
                });
            }
        }
    };

    _removeContainedNodes = (containedNodeIds) => {
        containedNodeIds?.forEach((nodeId) => {
            this.nodes[nodeId].relevantContainerId = null;
        });
        this._updateScenarioCanvas();
    };

    _bypassContainedNodes = (bypassState, containedNodeIds) => {
        containedNodeIds?.forEach((nodeId) => {
            this.nodes[nodeId].bypassed = bypassState;
        });
    };

    /**
     * This method will do the following:
     * 1. Mark any nodes that are in a folded container node as "hidden"
     * 2. Set the breadth and depth of any container node that is folded
     */
    _hideContainerNodes = () => {
        const containerNodes = this.allNodes().filter(
            (n) => n.type === Container
        );
        const setAt = new Date();
        containerNodes.forEach((containerNode) => {
            if (
                !containerNode.parents.length &&
                !containerNode.children.length &&
                containerNode.containerIsExpanded
            ) {
                // If node is expanded, hide the container node
                // Set its depth/breadth the same as the headNode
                const headNode = this._findEvent(containerNode.headNode);
                const { depth, breadth } = headNode;
                containerNode.setDepth(depth, setAt);
                containerNode.setBreadth(breadth, setAt);

                // Set container node as hidden
                // containerNode.setHideInCanvas(true);
            } else if (
                (containerNode.parents.length ||
                    containerNode.children.length) &&
                !containerNode.containerIsExpanded
            ) {
                // If node is folded, hide all its nested nodes
                const { depth, breadth } = containerNode;
                const headNode = this._findEvent(containerNode.headNode);
                this._hideNestedDescendants(
                    headNode,
                    depth,
                    breadth,
                    containerNode.tailNode
                );
            }
        });

        // Hide any containers in a container which is collapsed
        containerNodes.forEach((containerNode) => {
            const headNode = this._findEvent(containerNode.headNode);
            const tailNode = this._findEvent(containerNode.tailNode);
            if (
                containerNode.containerIsExpanded &&
                headNode.hideInCanvas &&
                tailNode.hideInCanvas
            )
                containerNode.setHideInCanvas(true);
        });
    };

    /**
     * Find the minimum and maximum depth/breadth of a container.
     * Requirements:
     * 1. containerNode.metadata.type === Container
     * 2. containerNode.metadata.containerIsExpanded === true
     */
    getMinMaxDepthBreadth = (containerNode) => {
        if (containerNode && containerNode.type !== Container) return;
        else if (containerNode && !containerNode.containerIsExpanded) return;
        const recursivelyMutateMinMax = (
            containerNode,
            curNode,
            minMaxValues
        ) => {
            const baseCase =
                curNode.id === containerNode.tailNode ||
                !curNode.children.length;
            const curNodeX = curNode.x();
            const curNodeY = curNode.y();

            if (curNodeX < minMaxValues.minX) minMaxValues.minX = curNodeX;
            if (curNodeY < minMaxValues.minY) minMaxValues.minY = curNodeY;
            if (curNodeX > minMaxValues.maxX) minMaxValues.maxX = curNodeX;
            if (curNodeY > minMaxValues.maxY) minMaxValues.maxY = curNodeY;

            if (baseCase) return minMaxValues;

            curNode.children.forEach((child) => {
                recursivelyMutateMinMax(containerNode, child, minMaxValues);
            });
        };
        // if (containerNode.id === "a45a8aef-ce02-47bf-9bd9-cf5e3e569d1d") debugger
        const headNode = this._findEvent(containerNode.headNode);
        const minMaxValues = {
            minX: headNode.x(),
            minY: headNode.y(),
            maxX: headNode.x(),
            maxY: headNode.y(),
        };
        recursivelyMutateMinMax(containerNode, headNode, minMaxValues);
        return minMaxValues;
    };

    _generateEventDepth = (root, currentDepth, setAt = new Date()) => {
        if (root != null) {
            let shouldDepthIncrease = true;

            if (root.type === containerObject.constant()) {
                shouldDepthIncrease = false;
            }

            if (root?.relevantContainerId) {
                const containerNode = this._findEvent(
                    root?.relevantContainerId
                );
                const containerEntity = getSingleEntity(
                    containerNode?.entities?.[0] ?? ""
                );
                const containerExpanded = containerEntity?.data?.expanded;
                if (!containerExpanded) {
                    shouldDepthIncrease = false;
                }

                if (containerEntity?.data?.tailNode === root?.id) {
                    shouldDepthIncrease = true;
                }
            }

            if (shouldDepthIncrease) {
                root.setDepth(currentDepth, setAt);
                root.children.forEach((_node) => {
                    const node = this._findEvent(_node);
                    this._generateEventDepth(node, currentDepth + 1, setAt);
                });
            } else {
                root.setDepth(currentDepth, setAt);
                root.children.forEach((_node) => {
                    const node = this._findEvent(_node);
                    this._generateEventDepth(node, currentDepth, setAt);
                });
            }
        }
    };

    _generateEventBreadth = (root, currentBreadth, setAt = new Date()) => {
        const eventBreadths = {};
        Object.values(this.nodes).forEach((event) => {
            if (event) {
                // if breadth doesnt exist set it the event ids key to 0
                if (eventBreadths[event.id] == null) {
                    eventBreadths[event.id] = 0;
                }

                // if event has any parents
                if (event.parents.length >= 1) {
                    const rootParent = event.parents[0];
                    if (eventBreadths[rootParent.id] == null) {
                        eventBreadths[rootParent.id] = 0;
                    }
                    if (rootParent.level < event.level) {
                        eventBreadths[rootParent.id]++;
                    }
                }
            }
        });

        this._setEventBreadth(
            root,
            currentBreadth,
            setAt,
            eventBreadths,
            {},
            {}
        );
        this._balanceEventBreadth();
    };

    _setEventBreadth = (
        root,
        currentBreadth,
        setAt,
        eventBreadths,
        processedNodes,
        depthBreadth
    ) => {
        if (root && root?.id && processedNodes[root.id] == null) {
            processedNodes[root.id] = true;
            if (this.breadth[root.depth] == null) {
                this.breadth[root.depth] = -1;
            }

            if (depthBreadth[root.depth] == null) {
                depthBreadth[root.depth] = 0;
            }

            // if (root.parents.length > 0) {
            //     const find = this._findCommonEvents(root.parents[0]).map(
            //         (id) => {
            //             return this._findEvent(id);
            //         }
            //     );
            //     let end;
            //     find.forEach((event) => {
            //         if (event.level < root.level && end == null) {
            //             end = event;
            //         }
            //     });

            //     // dynamic prob solution? - greatest breadth until end node
            // }

            // all nodes inside a container need to be skiped from getting calculated in eventBreadth
            if (root?.relevantContainerId) {
                const containerEvent = this.nodes[root.relevantContainerId];

                const containerEntity = getSingleEntity(
                    containerEvent?.entities?.[0] ?? ""
                );
                // if node is container head node and said container is expanded we dont want to calculate breadth
                if (
                    containerEntity?.data?.headNode === root.id &&
                    containerEntity?.data?.expanded
                ) {
                    // console.log("dont calculate me")
                } else {
                    const eventBreadth = this.breadth[root.depth] + 1;

                    root.setBreadth(depthBreadth[root.depth], setAt);

                    depthBreadth[root.depth] += eventBreadths[root.id] || 1;

                    this.maxBreadth = Math.max(this.maxBreadth, eventBreadth);
                    this.breadth[root.depth] = eventBreadth;
                }
            } else {
                const eventBreadth = this.breadth[root.depth] + 1; // adds 1 to the breadth at this givin depth - used for finding maxBreadth

                root.setBreadth(depthBreadth[root.depth], setAt); // sets the roots breadth - the roots breath might also get altered during the _balanceEventBreadth call

                depthBreadth[root.depth] += eventBreadths[root.id] || 1; // increases the depthBreadth at the roots givin depth

                this.maxBreadth = Math.max(this.maxBreadth, eventBreadth); // updating with the newest max breadth
                this.breadth[root.depth] = eventBreadth; // all this is doing is increasing it by 1 because thats all the eventBreadth is
            }

            if (root?.type === containerObject.constant()) {
                const containerEntity = getSingleEntity(
                    root?.entities?.[0] ?? ""
                );

                if (containerEntity?.data?.expanded) {
                    let offset = 0;
                    root.children.forEach((child) => {
                        this._setEventBreadth(
                            child,
                            currentBreadth + offset,
                            setAt,
                            eventBreadths,
                            processedNodes,
                            depthBreadth
                        );
                        // offset += eventBreadths[child.id] || 1;
                    });
                } else {
                    const tailNodeId = containerEntity?.data?.tailNode;
                    const tailNode = this._findEvent(tailNodeId);

                    let offset = 0;
                    tailNode?.children?.forEach((child) => {
                        this._setEventBreadth(
                            child,
                            currentBreadth + offset,
                            setAt,
                            eventBreadths,
                            processedNodes,
                            depthBreadth
                        );
                        // offset += eventBreadths[child.id] || 1;
                    });
                }
            } else {
                let offset = 0;
                root.children.forEach((child) => {
                    this._setEventBreadth(
                        child,
                        currentBreadth + offset,
                        setAt,
                        eventBreadths,
                        processedNodes,
                        depthBreadth
                    );
                    // offset += eventBreadths[child.id] || 1;
                });
            }
        }
    };

    _balanceEventBreadth = () => {
        const following = {};

        const mid = this.maxBreadth / 2,
            depths = Object.keys(this.depth).sort();

        depths.forEach((dk) => {
            const depth = this.depth[dk].sort((a, b) => {
                return a.breadth - b.breadth;
            });
            depth.forEach((node) => {
                if (node.relevantContainerId) {
                    const containerEvent = this.nodes[node.relevantContainerId];

                    const containerEntity = getSingleEntity(
                        containerEvent?.entities?.[0] ?? ""
                    );

                    if (!containerEntity?.data?.expanded) {
                        return;
                    }
                }

                if (node?.type === containerObject.constant()) {
                    const containerEntity = getSingleEntity(
                        node?.entities?.[0] ?? ""
                    );

                    if (containerEntity?.data?.expanded) {
                        return;
                    }
                }
                const singleNode = this.depth[node.depth].length === 1,
                    depthBreadth = this.breadth[node.depth],
                    isLinear = node.parents.length === 1;
                // node.parents.length === 1 &&
                // (node.parents[0].children.length === 1 ||
                // this.depth[node.parents[0].depth].length === 1);

                let isParentRoot = false;
                node.parents.forEach((_node) => {
                    const ev = this._findEvent(_node);
                    if (ev?.type === containerObject.constant()) {
                        const containerEntity = getSingleEntity(
                            ev?.entities?.[0] ?? ""
                        );
                        if (containerEntity?.data?.expanded) {
                            const containerParent = ev?.parents?.[0];
                            isParentRoot =
                                isParentRoot || containerParent.level === 1;
                        }
                    }
                    isParentRoot = isParentRoot || ev.level === 1;
                });

                const propagateFollow = [];
                if (following[node.id] != null) {
                    propagateFollow.push(node.id);
                }

                if (node.level === 1) {
                    node.breadth = mid;
                } else if (singleNode && node.parents.length > 0) {
                    node.breadth = node.parents[0].breadth;
                    following[node.parents[0].id] = node.id;
                } else if (isParentRoot) {
                    if (node.relevantContainerId) {
                        const containerEvent =
                            this.nodes[node.relevantContainerId];

                        const containerEntity = getSingleEntity(
                            containerEvent?.entities?.[0] ?? ""
                        );

                        if (containerEntity?.data?.expanded) {
                            node.breadth = containerEvent.breadth;
                        }
                    }
                    node.breadth += (this.maxBreadth - depthBreadth) / 2;
                } else if (isLinear && node.parents[0].breadth > node.breadth) {
                    const shift = node.parents[0].breadth - node.breadth,
                        threshold = node.breadth;

                    this.depth[node.depth].forEach((toShift) => {
                        if (toShift.breadth >= threshold) {
                            toShift.breadth += shift;
                        }
                    });
                }

                while (propagateFollow.length > 0) {
                    const propagateNode = this._findEvent(
                        propagateFollow.shift()
                    );
                    const followedBy = this._findEvent(
                        following[propagateNode.id]
                    );
                    if (followedBy) {
                        followedBy.breadth = propagateNode.breadth;
                        propagateFollow.push(followedBy.id);
                    }
                }
            });
        });
    };
}

export default EventManager;

// @ts-nocheck

import {
    Description,
    SelectDropDown,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    CheckBox,
    SingleEntity,
    SelectDropDownCountry,
} from "../Components";
import { meObject } from "Components/Registry/Me";
import getGeolocation from "countrycitystatejson";
import { meInputsHandler } from "../OnInputChangeHandlers/meInputsHandler";
import { ChangeEvent } from "react-transition-group/node_modules/@types/react";

const relationshipList = ["Spouse", "Separated", "Dependent Child", "Other"];

const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const countries = getGeolocation.getCountries();

export default function MeInputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    eventData,
    onChangeNameDescription,
    onHandleSubmit,
    toggleDisability,
    passedCheck,
    edit,
}) {
    const maxOffset = 100;
    const thisYear = new Date().getFullYear();
    const allYears: number[] = [];
    for (let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x);
    }

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "relationship"
            | "country"
            | "entityName"
            | "state"
            | "birthMonth"
            | "birthYear",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = meInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={meObject.constant()}
                    onChangeInput={onChangeNameDescription}
                    image={meObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity].data.relationship}
                        options={relationshipList}
                        className="termsContainer"
                        id="relationship"
                        label="Relationship"
                    />
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDownCountry
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity].data.country}
                        options={countries}
                        className="termsContainer"
                        id="country"
                        label="Select Country"
                    />
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDownCountry
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity].data.state}
                        options={entitiesMap[currentEntity].data.stateList}
                        className="termsContainer"
                        id="state"
                        label="Select Province"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap[currentEntity].data.birthMonth}
                            options={monthList}
                            className="select-dropdown"
                            id="birthMonth"
                            label="Select Birth Month"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap[currentEntity].data.birthYear}
                            options={allYears}
                            className="select-dropdown"
                            id="birthYear"
                            label="Select Birth Year"
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow id="hasDisability">
                    <CheckBox
                        isActive={entitiesMap[currentEntity].data.hasDisability}
                        toggleIsActive={toggleDisability}
                        label="Has a Disability"
                        id="hasDisability"
                    />
                </ModalRow>
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import {
    AnyNode,
    mPercent,
    PercentChange,
    SpecificNode,
    ValueReplacer,
} from "helpers/modifierHelpers";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { camelCase } from "lodash";
import { getDefaultName } from "helpers";
import { extractAndProcessPropertiesSpecificEntity } from "../Modifier2InputExperimental/Modifier2InputExperimental";

/*
 * Helper function that handles Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */

export const modifier2InputsHandler = (
    value: string,
    id:
        | "entityName"
        | "node"
        | "entity"
        | "newValue"
        | "mode"
        | "subMode"
        | "property",
    _star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    propsObject,
    setEventData: React.Dispatch<any>
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            const finalString = camelCase(value);
            data.tag = `@${finalString}`;
            data.nameChanged = true;
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "node":
            data.curNodeId = value;
            currentEntityObject.dependencies = undefined;
            data.subMode = undefined;
            data.propertySelected = "";
            data.curProperties = undefined;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const newDependencies: {
                [key: string]: { eventId: string; entityIds: string[] };
            } = { ...(currentEntityObject.dependencies ?? {}) };
            if (!value) {
                newDependencies[id] = {
                    eventId: "",
                    entityIds: [],
                };
            } else {
                newDependencies[id] = JSON.parse(value);
            }
            data.subMode = undefined;
            data.propertySelected = "";
            currentEntityObject.dependencies = newDependencies;
            data.curProperties =
                extractAndProcessPropertiesSpecificEntity(currentEntityObject);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "newValue":
            data.value = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "mode":
            data.mode = value;
            // reset state
            data.subMode = value === AnyNode ? PercentChange : undefined;
            data.curProperties = undefined;
            data.propertySelected = "";
            data.curNodeId = undefined;
            data.value = undefined;
            data.unit = undefined;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "subMode":
            data.subMode = value;
            if (value === PercentChange && data.unit !== mPercent) {
                data.unit = mPercent;
            }
            data.value = "";
            resetUnits(data);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "property":
            data.value = "";
            data.propertySelected = value;
            if (data.mode === SpecificNode) data.subMode = undefined;
            if (value) {
                // set unit
                resetUnits(data);

                // Change name to property selected, unless user changed name
                if (!data.nameChanged) {
                    let name = value;
                    const dashIndex = value.indexOf(":");
                    if (dashIndex >= 0) name = value.substring(0, dashIndex);
                    currentEntityObject.name = getDefaultName(
                        name,
                        propsObject
                    );

                    setEventData((prevState) => ({
                        ...prevState,
                        name: name,
                    }));
                }

                if (data.curProperties?.[value]) {
                    data.decisionEngineName = data.curProperties[value].deName;
                    data.frontEndName = data.curProperties[value].feName;

                    // Accomodates how different nodes store property/associated node data differently
                    if (data.mode === AnyNode) {
                        data.associatedIds = Array.from(
                            data.curProperties[value].nodeIds
                        );
                    }
                }
            }

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
};

const resetUnits = (data) => {
    if (data.curProperties?.[data.propertySelected]) {
        const desiredUnit = data.curProperties[data.propertySelected].unit;
        if (!data.subMode && data.unit !== desiredUnit) {
            data.unit = desiredUnit;
        } else if (
            data.unit !== desiredUnit &&
            data.subMode === ValueReplacer
        ) {
            data.unit = desiredUnit;
        } else if (data.unit !== mPercent && data.subMode === PercentChange) {
            data.unit = mPercent;
        }
    }
};

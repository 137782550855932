// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    EntityName,
    SelectDropDown,
    SelectionBox,
    PlusButton,
    ModalRowComponent,
    Rate,
    RadioButton,
    ItemBox,
} from "../Components";
import { projectObject } from "Components/Registry/Project";

export default function ProjectInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleChangeItemType,
    handleChangeEventType,
    handleAddSelectionItem,
    handleDeleteSelectionItem,
    addSelectedItem,
    handleDeleteItem,
    handleEditItem,
    handleUpdateEditItem,
    handleChangeOffsetMode,
    handleChangeClipping,
    handleEntityStateChange,
}) {
    const EventOptions = [
        "Contract",
        "Customer",
        "Debit Credit",
        "Debt",
        "Debt Repayment",
        "Employee",
        "Expense",
        "Group",
        "Income",
        "Loan",
        "Revenue",
        "Unit Cost",
    ];
    const ItemOptions = ["Records", "Events"];
    const ClipOptions = ["Clip", "Full"];

    function isEventSelected(id, index) {
        const selectionItem =
            entitiesMap?.[currentEntity]?.data.selectionItems[index];
        return selectionItem.selectedItem === id;
    }

    function isEntitySelected(id, index) {
        const selectionItem =
            entitiesMap?.[currentEntity]?.data.selectionItems[index];
        return selectionItem.selectedItem === id;
    }

    function getEarliestStartDate(entities) {
        let earliestStartDate = "";

        if (!entities) {
            return earliestStartDate;
        }

        entities.forEach((entity) => {
            if (entity.startDate) {
                if (earliestStartDate) {
                    if (
                        new Date(entity.startDate) < new Date(earliestStartDate)
                    ) {
                        earliestStartDate = entity.startDate;
                    }
                } else {
                    earliestStartDate = entity.startDate;
                }
            }
        });

        return earliestStartDate;
    }

    function getLatestEndDate(entities) {
        let latestEndDate = "";

        if (!entities) {
            return latestEndDate;
        }

        entities.forEach((entity) => {
            if (entity.endDate) {
                if (latestEndDate) {
                    if (new Date(entity.endDate) > new Date(latestEndDate)) {
                        latestEndDate = entity.endDate;
                    }
                } else {
                    latestEndDate = entity.endDate;
                }
            }
        });

        return latestEndDate;
    }

    const getEventName = (id, index) => {
        const events =
            entitiesMap?.[currentEntity]?.data.selectionItems[index].events;
        for (const event of events) {
            if (event.id === id) {
                return event.name;
            }
        }
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={projectObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={projectObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[projectObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>

                <ModalRow>
                    <PlusButton
                        onClick={handleAddSelectionItem}
                        label="Add New Item to Group"
                    />
                </ModalRow>

                {entitiesMap?.[currentEntity]?.data.selectionItems.map(
                    (selectionItem, i) => {
                        const passedCheck =
                            selectionItem.itemType &&
                            selectionItem.eventType &&
                            selectionItem.offset &&
                            selectionItem.multiplier &&
                            selectionItem.offsetMode &&
                            selectionItem.clip != null &&
                            selectionItem.selectedItem;

                        return (
                            <div
                                key={i}
                                style={{
                                    marginBottom: "50px",
                                }}
                            >
                                <ModalRow twoInputs>
                                    <ModalRowComponent width={38}>
                                        <RequiredStar />
                                        <SelectDropDown
                                            onChangeInput={handleChangeItemType}
                                            options={ItemOptions}
                                            value={
                                                selectionItem.itemType ===
                                                    "entities"
                                                    ? "Records"
                                                    : "Events"
                                            }
                                            className="select-dropdown"
                                            label="Select Item Type"
                                            mainId={i}
                                        />
                                    </ModalRowComponent>
                                    <ModalRowComponent width={38}>
                                        <RequiredStar />
                                        <SelectDropDown
                                            onChangeInput={
                                                handleChangeEventType
                                            }
                                            options={EventOptions}
                                            value={selectionItem.eventType}
                                            className="select-dropdown"
                                            label="Select Event Type"
                                            mainId={i}
                                        />
                                    </ModalRowComponent>
                                    <div
                                        onClick={() =>
                                            handleDeleteSelectionItem(i)
                                        }
                                        className={"proceedButton"}
                                        style={{
                                            marginRight: 0,
                                            marginLeft: 0,
                                            width: "20%",
                                        }}
                                    >
                                        {"Delete"}
                                    </div>
                                </ModalRow>

                                {selectionItem.itemType == "entities" &&
                                    Object.entries(selectionItem.entities).map(
                                        (pair) => {
                                            return (
                                                <SelectionBox
                                                    label={getEventName(
                                                        pair[0],
                                                        i
                                                    )}
                                                    items={pair[1]}
                                                    isItemSelected={(id) =>
                                                        isEntitySelected(id, i)
                                                    }
                                                    handleOnChange={(
                                                        e,
                                                        id,
                                                        star
                                                    ) =>
                                                        handleOnChange(
                                                            e,
                                                            id,
                                                            star,
                                                            i
                                                        )
                                                    }
                                                    type="entities"
                                                    key={pair[0]}
                                                />
                                            );
                                        }
                                    )}
                                {selectionItem.itemType == "events" && (
                                    <SelectionBox
                                        label="Available Events"
                                        items={Object.values(
                                            selectionItem.events
                                        )}
                                        isItemSelected={(id) =>
                                            isEventSelected(id, i)
                                        }
                                        handleOnChange={(e, id, star) =>
                                            handleOnChange(e, id, star, i)
                                        }
                                        type="events"
                                        getEarliestStartDate={(id) =>
                                            getEarliestStartDate(
                                                selectionItem.entities[id]
                                            )
                                        }
                                        getLatestEndDate={(id) =>
                                            getLatestEndDate(
                                                selectionItem.entities[id]
                                            )
                                        }
                                    />
                                )}
                                <ModalRow twoInputs>
                                    <ModalRowComponent width={14}>
                                        <RequiredStar />
                                        <SelectDropDown
                                            onChangeInput={handleChangeClipping}
                                            options={ClipOptions}
                                            value={
                                                selectionItem.clip
                                                    ? "Clip"
                                                    : "Full"
                                            }
                                            className="select-dropdown"
                                            label="Clip Item"
                                            mainId={i}
                                        />
                                    </ModalRowComponent>
                                    <ModalRowComponent width={12}>
                                        <RequiredStar />
                                        <Rate
                                            label="Multiplier"
                                            onChangeInput={(e, id, star) =>
                                                handleOnChange(e, id, star, i)
                                            }
                                            value={selectionItem.multiplier}
                                            id="multiplier"
                                            mainId={i}
                                            className="mlsInput"
                                            sign={false}
                                        />
                                    </ModalRowComponent>
                                    <ModalRowComponent width={12}>
                                        <RequiredStar />
                                        <Rate
                                            label="Start Offset"
                                            onChangeInput={(e, id, star) =>
                                                handleOnChange(e, id, star, i)
                                            }
                                            value={selectionItem.offset}
                                            id="offset"
                                            mainId={i}
                                            className="mlsInput"
                                            sign={false}
                                        />
                                    </ModalRowComponent>

                                    <ModalRowComponent width={12}>
                                        <RequiredStar />
                                        <Rate
                                            label="Duration"
                                            onChangeInput={(e, id, star) =>
                                                handleOnChange(e, id, star, i)
                                            }
                                            value={selectionItem.duration}
                                            id="duration"
                                            mainId={i}
                                            className="mlsInput"
                                            sign={false}
                                        />
                                    </ModalRowComponent>

                                    <div style={{ width: "10px" }}></div>
                                    <ModalRowComponent>
                                        <RadioButton
                                            onClick={handleChangeOffsetMode}
                                            isActive={
                                                selectionItem.offsetMode ===
                                                "days"
                                            }
                                            label="Days"
                                            value="days"
                                            id={i}
                                        />
                                    </ModalRowComponent>
                                    <ModalRowComponent>
                                        <RadioButton
                                            onClick={handleChangeOffsetMode}
                                            isActive={
                                                selectionItem.offsetMode ===
                                                "months"
                                            }
                                            label="Months"
                                            value="months"
                                            id={i}
                                        />
                                    </ModalRowComponent>

                                    <div
                                        onClick={
                                            passedCheck
                                                ? selectionItem.editing
                                                    ? () =>
                                                        handleUpdateEditItem(
                                                            i
                                                        )
                                                    : () => addSelectedItem(i)
                                                : () => null
                                        }
                                        className={
                                            passedCheck
                                                ? "proceedButton"
                                                : "proceedButtonDisabled"
                                        }
                                        style={{
                                            marginRight: 0,
                                            marginLeft: 0,
                                            width: "20%",
                                        }}
                                    >
                                        {selectionItem.editing
                                            ? "Update Item"
                                            : "Add Item"}
                                    </div>
                                </ModalRow>
                            </div>
                        );
                    }
                )}

                {entitiesMap?.[currentEntity]?.data.items &&
                    Object.entries(
                        entitiesMap?.[currentEntity]?.data.items
                    ).map((item, i) => {
                        return (
                            <ItemBox
                                itemId={item[0]}
                                itemType={item[1].itemType}
                                offset={item[1].offset}
                                multiplier={item[1].multiplier}
                                duration={item[1].duration}
                                offsetMode={item[1].offsetMode}
                                clip={item[1].clip}
                                handleEditItem={handleEditItem}
                                handleDeleteItem={handleDeleteItem}
                                index={i}
                                key={i}
                            />
                        );
                    })}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import HouseCard from "../Card/houseCard";
import HouseInput from "../InputContainer/HouseInput";

// Import Connection Image
import houseConnectionImage from "../../Assets/_optionDescriptionIcons/House, Downpayment, Mortgage.png";

// Import Coloured PNGs
import houseBlack from "../../Assets/_eventNodeTypes/house-black.png";
import houseBlue from "../../Assets/_eventNodeTypes/house-blue.png";
import houseWhite from "../../Assets/_eventNodeTypes/house-white.png";
import houseGrey from "../../Assets/_eventNodeTypes/house-grey.png";

// Additional Imports
import bigComingSoonImage from "../../Assets/_optionDescriptionIcons/bigComingSoon.png";
import comingSoonImage from "../../Assets/_optionDescriptionIcons/comingSoon.png";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";
import { valueDoesNotExist } from "../../helpers";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - houseObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - houseObject.checkInput.call(houseObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Income
export const houseObject = {
    constant: function () {
        return House_Constant();
    },

    checkInput: function (state) {
        return House_CheckInput(state);
    },

    svg: function () {
        return House_Svg();
    },

    eventNodeMenu: function (focus, getWalkScore) {
        return House_EventNodeMenu(focus, getWalkScore);
    },

    indexText: function () {
        return House_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return House_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return House_OptionDescription();
    },

    colouredPngs: function () {
        return House_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return House_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const House_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (
            entity.data.applyAppreciation &&
            valueDoesNotExist(entity.data.appreciationRate)
        )
            return false;
        if (!entity.data.primaryResidence) return false;
        if (!entity.data.newlyBuiltHome) return false;
        if (!entity.name) return false;
        if (!entity.data.address && !entity.data.placeHolderHouse) return false;
        if (
            entity.data.price === undefined ||
            entity.data.price === null ||
            entity.data.price === "" ||
            isNaN(entity.data.price)
        )
            return false;
        if (!entity.data["first payment"]) return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const House_Constant = () => {
    return "House";
};

// EFFECTS: Returns svg for event
const House_Svg = () => {
    return houseBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const House_EventNodeMenu = (focus, getWalkScore) => {
    return <HouseCard data={focus} getWalkScore={getWalkScore} />;
};

// EFFECTS: Returns the Index Text for event
const House_IndexText = () => {
    return "Select an input field to learn more about how each value affects your house calculations.";
};

// EFFECTS: Returns all icons for event ??
const House_Icons = () => {
    let pngs = new Map([
        ["black", houseBlack],
        ["blue", houseBlue],
        ["white", houseWhite],
        ["grey", houseGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const House_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// WARNING, how to deal with onboarding and onhandle ?
const House_InputComponentInfoPassing = (
    minProps,
    libraryProps,
    houseState
) => {
    return (
        <HouseInput
            {...minProps}
            {...libraryProps}
            onboardingData={houseState.props.onboardingData}
            onhandleFocusedInput={houseState.props.onhandleFocusedInput}
        />
    );
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const House_OptionDescription = () => {
    const tags = ["Option"];
    const type = houseObject.constant();
    const description =
        "Add an actual home or a placeholder property. Combine this node with other Real Estate options to build multiple property scenarios.";
    const usedForText =
        "Refinancing your current home, rent vs buy, income properties, relocation, adding a rental suite or posting on AirBnB.";
    const connection =
        "Add a Mortgage Option after the House node to pass purchase prices or property values to our mortgage calculations";
    const connectionImage = houseConnectionImage;
    const learnMoreLink =
        "https://www.notion.so/whatifi/The-House-Node-194637b551004750ad6ec22bb8ef1b7b";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
        {
            id: 1,
            name: "Refinance Your Home",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 2,
            name: "Purchasing an Income Property",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 3,
            name: "Relocating",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 4,
            name: "Adding a Rental Suite",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 5,
            name: "Listing on AirBnB",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const House_SideModalImage = () => {
    return sideImage;
};

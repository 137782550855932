import HeaderContainer from "./HeaderContainer";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";

const mapStateToProps = (state) => {
    return {
        loadedScenario: state.scenario.loadedScenario,
        userScenarios: state.scenario.userScenarios,
        sharedScenario: state.scenario.sharedScenario,
        showNotification: state.scenario.showNotification,
        sampleScenarios: state.scenario.sampleScenarios,
        manager: state.scenario.manager,
        loginPopUp: state.scenario.loginPopUp,
        tabSelected: state.scenario.tabSelected,
    };
};

const mapDispatchToProps = {
    shareScenario: actions.shareScenario,
    showNotificationAction: actions.showNotification,
    editScenarioComments: actions.editScenarioComments,
    updateSharedScenario: actions.updateSharedScenario,
    getUserScenarios: actions.getUserScenarios,
    fetchSharedScenarios: actions.fetchSharedScenarios,
    hideNotification: actions.hideNotification,
    deleteScenario: actions.deleteScenario,
    setOnboardingData: actions.setOnboardingData,
    createScenario: actions.createScenario,
    loadScenario: actions.loadScenario,
    onboardingScenario: actions.onboardingScenario,
    showLogin: actions.showLogin,
    selectTab: actions.selectTab,
    toggleShowAgencyBaselineView: actions.toggleShowAgencyBaselineView,
};

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);

export default Header;

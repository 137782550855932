import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import HouseInputView from "./HouseInputView";
import { houseObject } from "../../Registry/House";
import type { ChangeEvent } from "react";
import {
    extractCityFromAddress,
    extractProvinceFromAddress,
    getDefaultName,
} from "../../../helpers";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { addNewEvent, updateEvent } from "actions/eventHelpers";

export default function HouseInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(houseObject);
            _eventData.name = getDefaultName(
                houseObject.constant(),
                propsObject
            );
        }

        return _eventData;
    });
    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        onHandleStarHover,
        onMouseLeave,
        updateInflation,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, baselineManager, setEntitiesMap]);

    const onHandleFirstPayment = (startDate, _endDate) => {
        const cpyEntitiesMap = { ...entitiesMap };
        const curEntityObj = { ...(cpyEntitiesMap[currentEntity] || {}) };
        const data = { ...(curEntityObj?.data || {}) };
        data["first payment"] = startDate;

        curEntityObj.data = data;
        cpyEntitiesMap[currentEntity] = curEntityObj;
        setEntitiesMap(cpyEntitiesMap);
    };

    const onHandleLocation = (address, lat, lng) => {
        const cpyEntitiesMap = { ...entitiesMap };
        const curEntityObj = { ...(cpyEntitiesMap[currentEntity] || {}) };
        const data = { ...(curEntityObj?.data || {}) };

        data.address = address;
        data.lat = lat;
        data.lng = lng;
        data.province = extractProvinceFromAddress(address);
        data.city = extractCityFromAddress(address);
        curEntityObj.data = data;
        cpyEntitiesMap[currentEntity] = curEntityObj;
        setEntitiesMap(cpyEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };
    const passedCheck = !!eventData.name && houseObject.checkInput(entitiesMap);

    return (
        <HouseInputView
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            onHandleFirstPayment={onHandleFirstPayment}
            onHandleStarHover={onHandleStarHover}
            onHandleLocation={onHandleLocation}
            onMouseLeave={onMouseLeave}
            updateAppreciation={updateInflation}
            edit={edit}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
        />
    );
}

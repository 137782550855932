import React, { Component } from "react";
import "./InputContainer.css";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import { getDefaultName } from "../../helpers";
import { Description, InputButtons, Name, SelectDropDown } from "./Components";
import backSvg from "../../Assets/_budgetIcons/back.svg";
import customizeSvg from "../../Assets/_budgetIcons/customize.svg";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import dollarSvg from "../../Assets/_budgetIcons/dollar.svg";
import subCategorySvg from "../../Assets/_budgetIcons/subCategory.svg";
import travelSvg from "../../Assets/_budgetIcons/travel.svg";
import addSvg from "../../Assets/_budgetIcons/add.svg";
import editSvg from "../../Assets/_budgetIcons/edit.svg";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import inflationSvg from "../../Assets/_budgetIcons/inflation.svg";
import { getPresentableDependencies } from "../../helpers/nodeDependencyDetectionHelpers";
import closeSvg from "../../Assets/close.svg";
import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
import { houseObject } from "Components/Registry/House";

import * as uuid from "uuid";

class HomeMaintenanceInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            start: null,
            description: "",
            type: homeMaintenanceObject.constant(),
            name: getDefaultName(homeMaintenanceObject.constant(), this.props),
            tag: `@${homeMaintenanceObject.constant()}`,
            inflation: false,
            addNewCategories: false,
            subCategoriesList: [],
            newCategory: this.getBlankCategory(),
            budgetList: this.getDefaultCategories(),
            houses: this.handleDependencyDetection(),
        };
    }

    getDefaultCategories = () => {
        return [
            {
                title: "Plumbing",
                id: uuid.v4(),
                budget: 40,
                inflation: false,
                inflationRate: this.getInflation(),
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
                cadence: "monthly",
            },
            {
                title: "Home Exterior",
                id: uuid.v4(),
                budget: 185,
                inflation: false,
                inflationRate: this.getInflation(),
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
                cadence: "monthly",
            },
            {
                title: "Roof",
                id: uuid.v4(),
                budget: 90,
                inflation: false,
                inflationRate: this.getInflation(),
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
                cadence: "monthly",
            },
            {
                title: "Foundation",
                id: uuid.v4(),
                budget: 130,
                inflation: false,
                inflationRate: this.getInflation(),
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
                cadence: "monthly",
            },
            {
                title: "Electrical",
                id: uuid.v4(),
                budget: 40,
                inflation: false,
                inflationRate: this.getInflation(),
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
                cadence: "monthly",
            },
            {
                title: "HVAC",
                id: uuid.v4(),
                budget: 55,
                inflation: false,
                inflationRate: this.getInflation(),
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
                cadence: "monthly",
            },
            {
                title: "Drainage & Landscaping",
                id: uuid.v4(),
                budget: 50,
                inflation: false,
                inflationRate: this.getInflation(),
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
                cadence: "monthly",
            },
        ];
    };

    handleDependencyDetection = () => {
        let houses = {};
        getPresentableDependencies(houses, houseObject.constant(), this.props);
        if (Object.keys(houses).length === 0) {
            throwError(
                "error",
                "Failed to find an upstream House node.",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return houses;
    };

    deleteCategory = (id) => {
        const newCategories = this.state.budgetList.filter((category) => {
            return category.id !== id;
        });
        this.setState({
            budgetList: newCategories,
        });
    };

    getBlankCategory = () => {
        return {
            title: "",
            id: uuid.v4(),
            budget: 0,
            inflation: false,
            inflationRate: this.getInflation(),
            subCategoriesList: [],
            showSubCategory: false,
            selected: false,
        };
    };

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.editData,
                houses: this.handleDependencyDetection(),
            });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, _star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "house":
                this.onHandleHouse(value);
                break;
            case "newCategoryFreq":
                this.setState({
                    newCategory: {
                        ...this.state.newCategory,
                        frequency: value,
                    },
                });
                break;
            case "newCategoryCadence":
                this.setState({
                    newCategory: { ...this.state.newCategory, cadence: value },
                });
                break;
            default:
        }
    };

    onHandleHouse = (houseName) => {
        this.setState({
            house: houseName,
            houseId: this.state.houses[houseName],
        });
    };

    onHandleNewCategory = (e, title) => {
        let value;
        switch (title) {
            case "budget":
                value = Number(e.target.value);
                const category = {
                    ...this.state.newCategory,
                    [`budget`]: value,
                    originalValue: value,
                };
                this.setState({ newCategory: category });
                break;
            case "title":
                value = e.target.value;
                break;
            case "inflation":
                value = !this.state.newCategory.inflation;
                break;
            default:
        }
        if (title !== "budget") {
            const category = {
                ...this.state.newCategory,
                [`${title}`]: value,
            };
            this.setState({ newCategory: category });
        }
    };

    handleSelectAccount = (budget) => {
        const { budgetList } = this.state;
        const newBudget = budgetList.map((bud) => {
            if (bud.id === budget.id) {
                const newBud = {
                    ...bud,
                    selected: !bud.selected,
                };
                return newBud;
            }
            return bud;
        });

        newBudget.sort((a, b) => {
            return b.selected - a.selected;
        });

        this.setState({ budgetList: newBudget });
    };

    onHandleHover = (id) => {
        this.setState({ budgetOnHover: id });
    };

    onHandleHoverOut = () => {
        this.setState({ budgetOnHover: null });
    };

    handleEditBudget = (budget) => {
        const newBudget = {
            ...budget,
            selected: true,
        };
        this.setState({
            addNewCategories: true,
            newCategory: newBudget,
            subCategoriesList: budget.subCategoriesList,
        });
    };

    onHandleSubCategories = (budget) => {
        const newList = this.state.budgetList.map((bud) => {
            if (bud.id === budget.id) {
                const newBudget = {
                    ...bud,
                    showSubCategory: !bud.showSubCategory,
                };
                return newBudget;
            }
            return bud;
        });
        this.setState({ budgetList: newList });
    };

    onChangeSubCategoryValue = (e, id, title) => {
        const value =
            title === "budget" ? Number(e.target.value) : e.target.value;
        const { subCategoriesList } = this.state;
        const newSubCategories = subCategoriesList.map((sub) => {
            if (sub.id === id) {
                const newSub = {
                    ...sub,
                    [`${title}`]: value,
                };
                return newSub;
            }
            return sub;
        });
        this.setState({
            subCategoriesList: newSubCategories,
        });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = homeMaintenanceObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };
    updateValues = () => {
        const passedCheck = homeMaintenanceObject.checkInput(this.state);
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            this.props.updateValues(newState);
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleAddSubCategory = () => {
        const subCategory = {
            title: "",
            icon: travelSvg,
            id: uuid.v4(),
            budget: 0,
        };

        this.setState({
            subCategoriesList: [...this.state.subCategoriesList, subCategory],
        });
    };

    deleteSubCategory = (id) => {
        const { subCategoriesList } = this.state;
        const filtered = subCategoriesList.filter((sub) => {
            return sub.id !== id;
        });
        this.setState({ subCategoriesList: filtered });
    };

    toggleAddCategory = () => {
        this.setState({ addNewCategories: !this.state.addNewCategories });
    };

    addCategory = () => {
        const { newCategory } = this.state;
        let totalBudget = 0;
        let budget = newCategory.budget;
        let subBudget = 0;
        let originalValue = newCategory.originalValue;
        this.state.subCategoriesList.map((sub) => {
            return (subBudget += sub.budget);
        });

        if (budget >= 0 && budget < subBudget) {
            totalBudget = subBudget;
        } else if (subBudget < originalValue) {
            totalBudget = originalValue;
        } else if (subBudget > originalValue) {
            totalBudget = subBudget;
        } else {
            totalBudget = subBudget;
        }

        const categoryAlreadyExists =
            this.state.budgetList.filter((budget) => {
                return budget.id === this.state.newCategory.id;
            }).length > 0;

        if (categoryAlreadyExists) {
            const newBudgetList = this.state.budgetList.map((budgetList) => {
                if (budgetList.id === this.state.newCategory.id) {
                    const newBudget = {
                        ...this.state.newCategory,
                        subCategoriesList: this.state.subCategoriesList,
                        budget: totalBudget,
                    };
                    return newBudget;
                }
                return budgetList;
            });
            this.setState({
                budgetList: newBudgetList,
                newCategory: this.getBlankCategory(),
                subCategoriesList: [],
                addNewCategories: false,
            });
        } else {
            const newCategory = {
                ...this.state.newCategory,
                subCategoriesList: this.state.subCategoriesList,
                budget: totalBudget,
                id: uuid.v4(),
            };
            this.setState({
                budgetList: [newCategory, ...this.state.budgetList],
                addNewCategories: false,
                newCategory: this.getBlankCategory(),
                subCategoriesList: [],
            });
        }
    };

    backToMainPanel = () => {
        this.setState({
            addNewCategories: false,
            newCategory: this.getBlankCategory(),
            subCategoriesList: [],
        });
    };

    getInflation = () => {
        return Number(this.props.loadedScenario.inflation) * 100;
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "Associated House":
                data = {
                    title: "Associated House",
                    content:
                        "The Home Maintenance vent is bound to a specific House.",
                    subContent:
                        "Costs will be incurred before the House is purchased, or after it is sold.",
                    link: null,
                };
                break;
            case "Plumbing":
                data = {
                    title: "Plumbing",
                    content:
                        "Plumbing maintenance includes things like annual testing, clearing exterior drains, as well as infrequent major expenses such as replacing pipes, water heaters, and sump pumps.",
                    subContent: "The average annual cost is $270-$900.",
                    link: "https://www.moneysense.ca/spend/real-estate/the-ultimate-home-maintenance-guide/",
                };
                break;
            case "Home Exterior":
                data = {
                    title: "Home Exterior",
                    content:
                        "Maintaining the exterior of a home includes things like replacing weather strippin, power-washing, and infrequent major expenses such as painting, replacing windows, and replacing the siding.",
                    subContent: "The average annual cost is $1500-3000",
                    link: "https://www.moneysense.ca/spend/real-estate/the-ultimate-home-maintenance-guide/",
                };
                break;
            case "Roof":
                data = {
                    title: "Roof",
                    content:
                        "Roof maintenance includes re-caulking, replacing loose shingles, infrequent expenses such as replacing insulation.",
                    subContent: "The average annual cost is $750-$1200.",
                    link: "https://www.moneysense.ca/spend/real-estate/the-ultimate-home-maintenance-guide/",
                };
                break;
            case "Foundation":
                data = {
                    title: "Foundation",
                    content:
                        "Maintaining the foundation includes regularly filling cracks, and infrequent expenses such as mold and mildew remediation and water-proofing.",
                    subContent: "The average annual cost is $1000-$2300.",
                    link: "https://www.moneysense.ca/spend/real-estate/the-ultimate-home-maintenance-guide/",
                };
                break;
            case "Electrical":
                data = {
                    title: "Electrical",
                    content:
                        "Electrical maintenance includes regular expenses such as replacing bulbs and batteries, as well as less frequent expenses like replacing smoke detectors.",
                    subContent: "The average annual cost is $250-$650.",
                    link: "https://www.moneysense.ca/spend/real-estate/the-ultimate-home-maintenance-guide/",
                };
                break;
            case "HVAC":
                data = {
                    title: "HVAC",
                    content:
                        "Maintaining a home's HVAC includes annual furnace inspections and duct cleaning, as well as less frequent expenses like replacing the furnace and A/C.",
                    subContent: "The average annual cost is $400-$800.",
                    link: "https://www.moneysense.ca/spend/real-estate/the-ultimate-home-maintenance-guide/",
                };
                break;
            case "Drainage & Landscaping":
                data = {
                    title: "Drainage & Landscaping",
                    content:
                        "Maintaining a home's drainage includes regular expenses like patching the driveway and re-grading soil, as well as infrequent expenses such as replacing the eaves and fascia.",
                    subContent: "The average annual cost is $250-$900.",
                    link: "https://www.moneysense.ca/spend/real-estate/the-ultimate-home-maintenance-guide/",
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    mainPanel = () => {
        const passedCheck = homeMaintenanceObject.checkInput(this.state);
        const inflationRate = this.getInflation();
        const { edit } = this.props;
        const { description, name, budgetList, house, houses } = this.state;

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={homeMaintenanceObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">
                                {_.upperCase(homeMaintenanceObject.constant())}
                            </div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div
                        className="ModalRow"
                        onMouseEnter={() => {
                            this.onHandleMouseEnter("Associated House");
                        }}
                        onMouseLeave={() => {
                            this.onHandleMouseEnter("");
                        }}
                    >
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={house}
                            options={Object.keys(houses)}
                            className="FullWidthDropDown"
                            id="house"
                            label="Associated House"
                        />
                    </div>
                    <div
                        className="cardContainer"
                        onMouseLeave={() => {
                            this.onHandleMouseEnter("");
                        }}
                    >
                        <div
                            className="budgetAddButton"
                            onClick={this.toggleAddCategory}
                        >
                            <img
                                alt="alt"
                                className="budgetAddButtonSvg"
                                src={addSvg}
                            />
                            <div className="budgetAddButtonText">
                                Add a New Category
                            </div>
                        </div>
                        {budgetList.map((budget, i) => {
                            const onHoverBudget =
                                this.state.budgetOnHover &&
                                this.state.budgetOnHover === budget.id;
                            return (
                                <div
                                    key={i}
                                    className="cardHolder"
                                    onMouseEnter={() => {
                                        this.onHandleHover(budget.id);
                                        this.onHandleMouseEnter(budget.title);
                                    }}
                                    onMouseLeave={() => {
                                        this.onHandleHoverOut();
                                    }}
                                >
                                    <div className="cardHeaderWide">
                                        <img
                                            alt="alt"
                                            className="deleteCategorySvg"
                                            src={closeSvg}
                                            onClick={() => {
                                                this.deleteCategory(budget.id);
                                            }}
                                        />
                                        <img
                                            alt="alt"
                                            className="cardIcon"
                                            src={customizeSvg}
                                        />
                                        <div className="cardLabel">
                                            {budget.title}
                                        </div>

                                        <div className="editContainer">
                                            {onHoverBudget && (
                                                <div>
                                                    <img
                                                        alt="alt"
                                                        className="editIcons"
                                                        src={editSvg}
                                                        onClick={() =>
                                                            this.handleEditBudget(
                                                                budget
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <img
                                            alt="alt"
                                            src={
                                                budget.selected
                                                    ? checkboxOn
                                                    : checkboxOff
                                            }
                                            onClick={(_e) =>
                                                this.handleSelectAccount(budget)
                                            }
                                        />
                                    </div>
                                    <div className="cardRow">
                                        <div className="subCategoriesContainer">
                                            <img
                                                alt="alt"
                                                className="subCategoriesContainerIcon"
                                                src={
                                                    budget.showSubCategory
                                                        ? dropdownSvg
                                                        : expandSvg
                                                }
                                                onClick={() =>
                                                    this.onHandleSubCategories(
                                                        budget
                                                    )
                                                }
                                            />
                                            <div>
                                                {budget.subCategoriesList &&
                                                    budget.subCategoriesList
                                                        .length}{" "}
                                                sub categories
                                            </div>
                                        </div>

                                        <div className="budgetInflation">
                                            <img
                                                alt="alt"
                                                src={inflationSvg}
                                                className="inflationCheckbox"
                                            />
                                            <div className="inflationHolder">
                                                {budget.inflation
                                                    ? inflationRate
                                                    : 0}{" "}
                                                %
                                            </div>
                                        </div>
                                        <div className="amountValue">
                                            $ {budget.budget} {budget.cadence}
                                        </div>
                                    </div>
                                    {budget.showSubCategory &&
                                        budget.subCategoriesList.length > 0 && (
                                            <div className="showSubCategoryContainer">
                                                {budget.subCategoriesList.map(
                                                    (subCategory, i) => {
                                                        return (
                                                            <div
                                                                key={i}
                                                                className="subCategory"
                                                            >
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                    }}
                                                                >
                                                                    <img
                                                                        alt="alt"
                                                                        src={
                                                                            checkboxOn
                                                                        }
                                                                        className="checkBoxSvg"
                                                                    />
                                                                    <div>
                                                                        {
                                                                            subCategory.title
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="amountValue">
                                                                    ${" "}
                                                                    {
                                                                        subCategory.budget
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </div>
        );
    };

    addCategoryPanel = () => {
        const inflationRate = this.getInflation();
        const { newCategory } = this.state;
        const cadenceOptions = [
            "daily",
            "weekly",
            "bi-weekly",
            "semi-monthly",
            "monthly",
            "annually",
        ];
        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div onClick={this.backToMainPanel} className="backButton">
                        <img
                            alt="alt"
                            style={{
                                height: "12px",
                                width: "12px",
                            }}
                            src={backSvg}
                        />{" "}
                        Back
                    </div>
                    <div className="categoryTitle">Add a New Category</div>
                    <div className="budgetContainer">
                        <div className="cardHolder">
                            <div style={{ display: "flex" }}>
                                <img
                                    alt="alt"
                                    className="budgetIcon"
                                    src={customizeSvg}
                                />
                                <input
                                    placeholder="New Category Name"
                                    className="newCategoryName"
                                    value={
                                        this.state.newCategory
                                            ? this.state.newCategory.title
                                            : ""
                                    }
                                    onChange={(e) =>
                                        this.onHandleNewCategory(e, "title")
                                    }
                                />
                            </div>
                            <div className="budgetAmountContainer">
                                <div className="oneThird">
                                    <div className="Required">*</div>
                                    <img
                                        alt="alt"
                                        src={dollarSvg}
                                        className="budgetDollarSvg"
                                    />
                                    <input
                                        className="wideBudgetInput"
                                        placeholder="Set a Budget"
                                        onChange={(e) =>
                                            this.onHandleNewCategory(
                                                e,
                                                "budget"
                                            )
                                        }
                                        type="number"
                                        value={
                                            this.state.newCategory.budget
                                                ? this.state.newCategory.budget
                                                : ""
                                        }
                                    />
                                </div>

                                <div className="halfContainer">
                                    <img
                                        alt="alt"
                                        src={
                                            this.state.newCategory.inflation
                                                ? checkboxOn
                                                : checkboxOff
                                        }
                                        onClick={(e) =>
                                            this.onHandleNewCategory(
                                                e,
                                                "inflation"
                                            )
                                        }
                                        className="inflationCheckbox"
                                    />
                                    <div className="inflationText">
                                        Apply Inflation:
                                    </div>
                                    <div className="inflationHolder">
                                        {inflationRate} %
                                    </div>
                                </div>
                            </div>
                            <div className="budgetAmountContainer">
                                <div className="oneThird">
                                    <SelectDropDown
                                        onChangeInput={this.onChangeInput}
                                        value={newCategory.cadence}
                                        options={cadenceOptions}
                                        className="smallDropDown"
                                        id="newCategoryCadence"
                                        label="Frequency"
                                    />
                                </div>
                            </div>
                            <div
                                className="addSubCategory"
                                onClick={this.onHandleAddSubCategory}
                            >
                                <img
                                    alt="alt"
                                    style={{
                                        height: "12px",
                                        width: "12px",
                                    }}
                                    src={subCategorySvg}
                                />
                                {"  "}
                                Add a sub-category
                            </div>
                        </div>
                    </div>
                    <div className="subCategoryContainer">
                        {this.state.subCategoriesList &&
                            this.state.subCategoriesList.map(
                                (subCategory, i) => {
                                    return (
                                        <div key={i} className="cardHolder">
                                            <div
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                <div className="subCategoryCount">
                                                    {i + 1}.
                                                </div>
                                                <input
                                                    placeholder="New Sub Category"
                                                    className="newCategoryName"
                                                    value={subCategory.title}
                                                    onChange={(e) =>
                                                        this.onChangeSubCategoryValue(
                                                            e,
                                                            subCategory.id,
                                                            "title"
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="budgetAmountContainer">
                                                <div className="twoThirds">
                                                    <div className="Required">
                                                        *
                                                    </div>
                                                    <img
                                                        alt="alt"
                                                        src={dollarSvg}
                                                        className="budgetDollarSvg"
                                                    />

                                                    <input
                                                        className="budgetInput"
                                                        placeholder="Set a Budget"
                                                        onChange={(e) =>
                                                            this.onChangeSubCategoryValue(
                                                                e,
                                                                subCategory.id,
                                                                "budget"
                                                            )
                                                        }
                                                        type="number"
                                                        value={
                                                            subCategory.budget
                                                                ? subCategory.budget
                                                                : ""
                                                        }
                                                    />
                                                    <div className="budgetFrequency">
                                                        / mon
                                                    </div>
                                                </div>
                                                <div className="halfContainer" />
                                            </div>
                                            <div className="halfContainer">
                                                <div
                                                    onClick={() =>
                                                        this.deleteSubCategory(
                                                            subCategory.id
                                                        )
                                                    }
                                                    className="deleteSubCategory"
                                                >
                                                    Delete
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                    <div className="ConfirmContainer">
                        <div onClick={this.addCategory} className={"addButton"}>
                            {!this.state.addNewCategories
                                ? "Update"
                                : "Add & Save"}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { addNewCategories } = this.state;

        return (
            <>{addNewCategories ? this.addCategoryPanel() : this.mainPanel()}</>
        );
    }
}

export default HomeMaintenanceInput;

import FileSaver from "file-saver";
import { getTopLevelAccount } from "helpers/accounts";
import { recurseToTopAccount } from "helpers/accounts";

const getAccountFromIdString = (allLedgerData, accountId) => {
    const isCumulative = accountId.includes("Cumulative-");
    const pureAccountId = accountId.replace("Cumulative-", "");
    const accountData = {
        ...allLedgerData[pureAccountId],
        name: `${isCumulative ? "Cumulative " : ""}${
            allLedgerData[pureAccountId]?.name
        }`,
        cumulative: isCumulative,
    };
    return accountData;
};

const formatAccountNameString = (accountName: string) => {
    if (accountName.includes(",")) {
        const formattedAccountName = accountName.replace(",", " -");
        return formattedAccountName;
    }

    return accountName;
};

export const exportScenarioCSV = (
    threadAccountData,
    allLedgersMetadata,
    fileName = "scenarioThreadData"
) => {
    if (!threadAccountData || !allLedgersMetadata) return;
    const accounts = Object.keys(threadAccountData);
    const allSortedAccountsArrays: any = {};
    const finalOrderedAccountIds: string[] = [];

    accounts.forEach((accountId) => {
        const accountData = getAccountFromIdString(
            allLedgersMetadata,
            accountId
        );
        const topLevelAccountId = getTopLevelAccount(accountData?.id);
        if (topLevelAccountId) {
            if (accountData?.parents?.length > 0 || accountData?.cumulative) {
                if (topLevelAccountId in allSortedAccountsArrays) {
                    allSortedAccountsArrays[topLevelAccountId].push(accountId);
                } else {
                    allSortedAccountsArrays[topLevelAccountId] = [accountId];
                }
            }
        }
    });

    const allSortedAccountsArraysEntries = Object.entries(
        allSortedAccountsArrays
    );

    allSortedAccountsArraysEntries.forEach(
        ([topLevelAccountId, relatedAccountIdsArray]: any) => {
            finalOrderedAccountIds.push(topLevelAccountId);
            if (relatedAccountIdsArray?.length > 0) {
                relatedAccountIdsArray?.sort((a, b) => {
                    a = a.includes("Cumulative-");
                    b = b.includes("Cumulative-");
                    if (a === b) return 0;
                    if (a && !b) return 1;
                    if (!a && b) return -1;
                });
                relatedAccountIdsArray.forEach((relatedAccountId) => {
                    finalOrderedAccountIds.push(relatedAccountId);
                });
            }
        }
    );

    const firstAccountDateKeys = Object.keys(
        threadAccountData?.[accounts[0]] ?? {}
    );

    let csv =
        "Account Name, Type, Top Account, Sub Account, Leaf Account, Leaf Sub Account";
    if (!(firstAccountDateKeys?.length > 0)) return;
    firstAccountDateKeys.forEach((day) => {
        csv = csv + "," + day;
    });
    finalOrderedAccountIds.forEach((accountIdString) => {
        const cumulativeAccount = accountIdString.includes("Cumulative-");
        let str = "";
        if (cumulativeAccount) {
            const accountId = accountIdString.replace("Cumulative-", "");
            const accountName = formatAccountNameString(
                allLedgersMetadata[accountId]?.name ?? ""
            );
            const cumulativeAccountName = `Cumulative ${accountName}`;
            const recursedAccounts = recurseToTopAccount(accountId);
            const accountStructureMap = {
                topAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[0]]?.name ?? ""
                ),
                subAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[1]]?.name ?? ""
                ),
                leafAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[2]]?.name ?? ""
                ),
                leafSubAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[3]]?.name ?? ""
                ),
            };
            str = `${cumulativeAccountName},${
                cumulativeAccount ? "Cumulative" : "Monthly"
            },${accountStructureMap.topAccount},${
                accountStructureMap.subAccount
            },${accountStructureMap.leafAccount},${
                accountStructureMap.leafSubAccount
            }`;
        } else {
            const recursedAccounts = recurseToTopAccount(accountIdString);
            const accountStructureMap = {
                topAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[0]]?.name ?? ""
                ),
                subAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[1]]?.name ?? ""
                ),
                leafAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[2]]?.name ?? ""
                ),
                leafSubAccount: formatAccountNameString(
                    allLedgersMetadata[recursedAccounts?.[3]]?.name ?? ""
                ),
            };
            const accountName = formatAccountNameString(
                allLedgersMetadata[accountIdString]?.name ?? ""
            );
            str = `${accountName},${
                cumulativeAccount ? "Cumulative" : "Monthly"
            },${accountStructureMap.topAccount},${
                accountStructureMap.subAccount
            },${accountStructureMap.leafAccount},${
                accountStructureMap.leafSubAccount
            }`;
        }

        const accountsThreadData = threadAccountData[accountIdString];
        if (accountsThreadData) {
            Object.keys(accountsThreadData).forEach((day) => {
                const dayData = accountsThreadData[day];
                str = str + "," + dayData.toFixed(2);
            });
            csv = csv + "\n" + str;
        }
    });

    const csvDownload = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });

    // Download Data as CSV file
    FileSaver.saveAs(csvDownload, `${fileName}.csv`);
};

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

export const customer2InputsHandler = (
    value: string,
    id: "entityName" | "customer",
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "customer":
            if (isNaN(Number(value))) break;
            data.customer = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
    }

    return newEntitiesMap;
};

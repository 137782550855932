import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const instanceInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "customer"
        | "groupInstance"
        | "recordInstance"
        | "event"
        | "record_Instance"
        | "group_Instance"
        | "cadence"
        | "value"
        | "isBypassed"
        | "recordClip"
        | "groupClip",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    switch (id) {
        case "customer":
            data.customer = value;
            break;
        case "entityName":
            currentEntityObject.name = value;
            break;
        case "isBypassed":
            data.isBypassed = !data.isBypassed;
            break;
        case "groupInstance":
        case "recordInstance":
            data.instanceType = id;
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            break;
        case "value":
            data.value = value;
            break;
        case "event":
            data.event = value;
            break;
        case "recordClip":
            data.recordClipType = value;
            break;
        case "groupClip":
            data.groupClipType = value;
            break;
        case "record_Instance":
            data.selectedEntity = JSON.parse(value);
            break;
        case "group_Instance":
            data.selectedGroupEntity = JSON.parse(value);
            break;
    }
    currentEntityObject.data = data;
    newEntitiesMap[currentEntity] = currentEntityObject;
    return newEntitiesMap;
};

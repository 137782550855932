// Default Imports
import React from "react";

import CapacityCard from "../Card/capacityCard";

// Import Connection Image
import capacityConnectionImage from "../../Assets/_optionDescriptionIcons/Resource, Employee, Utilization, Capacity Highlight.png";

// Import Coloured PNGs
import capacityBlack from "../../Assets/_eventNodeTypes/capacitySvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import CapacityInput from "Components/InputContainer/Capacity/CapacityInput";

export const capacityObject = {
    constant: function () {
        return capacityObject_Constant();
    },

    name: function () {
        return "Capacity";
    },

    checkInput: function (state) {
        return capacityObject_CheckInput(state);
    },

    svg: function () {
        return capacityObject_Svg();
    },

    eventNodeMenu: function (focus) {
        return capacityObject_EventNodeMenu(focus);
    },

    indexText: function () {
        return capacityObject_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return capacityObject_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return capacityObject_OptionDescription();
    },

    colouredPngs: function () {
        return capacityObject_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return capacityObject_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Capacity_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const capacityObject_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.employeeAccountIds) return false;
        if (!entity.data.value) return false;
        if (!entity.data.workDays) return false;
        if (!entity.startDate) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const capacityObject_Constant = () => {
    return "c6cabc48-70fe-4d44-bd2a-e985662cf2e0";
};

// EFFECTS: Returns svg for event
const capacityObject_Svg = () => {
    return capacityBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const capacityObject_EventNodeMenu = (focus) => {
    return <CapacityCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const capacityObject_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const capacityObject_Icons = () => {
    let pngs = new Map([
        ["black", capacityBlack],
        ["blue", capacityBlack],
        ["white", capacityBlack],
        ["grey", capacityBlack],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const capacityObject_InputComponentInfoPassing = (minProps) => {
    return <CapacityInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const capacityObject_OptionDescription = () => {
    const tags = ["Option"];
    const type = capacityObject.name();
    const description =
        "Capacity is the available or required resources to complete a project or set of projects. It is most commonly used with Employees and roles to determine when a company needs to hire additional staff or if they are overstaffed. This Event also supports overtime and schedule modifications.";
    const usedForText =
        "This Event is commonly used in combination with Employees, Resources, Project and Utilization to predict overage or shortfalls in a company’s ability to complete a project on schedule.";
    const connection = "";
    const connectionImage = capacityConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const capacityObject_SideModalImage = () => {
    return sideImage;
};

const Capacity_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

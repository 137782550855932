import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { WaterfallMenu } from "Components/AccountSelectionWaterfall/WaterfallMenu";
import { ReactComponent as DropDownIcon } from "Assets/dropDownStyleable.svg";
import styles from "./InputDropDownButton.module.css";

export const InputDropDownButton = ({
    id = "",
    callback,
    initialValue,
    contraAccount = false,
    accountSelectedForContra = "",
    topLevelFilters = {},
    unselectableAccounts = {},
    quickSelectAccounts = {},
    buttonLabel = "Select Account",
    required = false,
    helperText = "",
}) => {
    const [currentSelection, setCurrentSelection] = useState<string>(
        initialValue
            ? initialValue
            : contraAccount
            ? "Select Contra Account"
            : buttonLabel
    );

    useEffect(() => {
        if (!currentSelection) {
            setCurrentSelection(
                initialValue
                    ? initialValue
                    : contraAccount
                    ? "Select Contra Account"
                    : buttonLabel
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelection]);

    useEffect(() => {
        if (initialValue !== currentSelection) {
            setCurrentSelection(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const hasUserSelection: boolean = useMemo(() => {
        if (
            currentSelection === "Select Contra Account" ||
            currentSelection === buttonLabel
        ) {
            return false;
        } else {
            return true;
        }
    }, [currentSelection, buttonLabel]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (closeCallback) => {
        closeCallback();
        setAnchorEl(null);
    };

    return (
        <>
            <div className={styles.compWrapper}>
                <div
                    className={styles.selectContainer}
                    style={
                        hasUserSelection
                            ? { color: "#313131" }
                            : { color: "#616161" }
                    }
                    onClick={handleClick}
                >
                    {hasUserSelection && (
                        <span className={styles.inBorderText}>
                            {required
                                ? `${
                                      contraAccount
                                          ? "Select Contra Account"
                                          : buttonLabel
                                  } *`
                                : contraAccount
                                ? "Select Contra Account"
                                : buttonLabel}
                        </span>
                    )}
                    {required && !hasUserSelection
                        ? `${currentSelection} *`
                        : currentSelection}
                    <DropDownIcon className={styles.dropDownIcon} />
                </div>
                {helperText !== "" && (
                    <span className={styles.helperText}>{helperText}</span>
                )}
            </div>
            <WaterfallMenu
                specificCallbackId={id}
                anchorEl={anchorEl}
                handleClose={handleClose}
                currentSelection={currentSelection}
                setCurrentSelection={setCurrentSelection}
                callback={callback}
                contraAccount={contraAccount}
                accountSelectedForContra={accountSelectedForContra}
                topLevelFilters={topLevelFilters}
                unselectableAccounts={unselectableAccounts}
                quickSelectAccounts={quickSelectAccounts}
                disableContraReset={false}
            />
        </>
    );
};

// Experimental. Move away from having events live in scenario as objects, and have a model directly in redux instead

import type { EventsSchema } from "./typesSchema/eventsSchema";
import { SET_EVENTS, UPSERT_EVENTS } from "../actions/eventsActions";

const initialState: EventsSchema = {};

export default function eventsReducer(
    state: EventsSchema = initialState,
    action: { type: string; payload?: any }
): EventsSchema {
    switch (action.type) {
        case SET_EVENTS:
            return { ...action.payload };
        case UPSERT_EVENTS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

import React, { Component } from "react";
import "./Card.css";

class PropertyTransferTaxCard extends Component {
    render() {
        const {
            description,
            estimatedTax,
            firstTimeBuyerTrue,
            foreignBuyerTrue,
            version,
        } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Estimated Tax</div>
                    <div className="cardNumber">
                        {estimatedTax !== undefined ? `$ ${estimatedTax}` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">First Time Buyer</div>
                    <div className="cardNumber">
                        {firstTimeBuyerTrue ? "Yes" : "No"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Foreign Buyer</div>
                    <div className="cardValue">
                        {foreignBuyerTrue ? "Yes" : "No"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default PropertyTransferTaxCard;

import uuidTable from "./entityTypeStructureUUIDtoName.json";

// Takes in a event type (uuid) and returns the name of event (i.e "Customer" or "Debit Credit")

export function getNameFromType(type: string) {
    for (const key in uuidTable) {
        if (uuidTable[key] === type) {
            return key;
        }
    }
    return "Missing Type";
}

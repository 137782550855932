import React from "react";
import closeSvg from "../../Assets/close.svg";
import styles from "./index.module.css";
import errorSvg from "../../Assets/_eventToast/error.svg";
import warningSvg from "../../Assets/_eventToast/warning.svg";
import successSvg from "../../Assets/_eventToast/success.svg";

export const EventsToast = ({ children, data, close, history }) => {
    const { type, text, upgrade, route } = data;
    let border;
    let icon;
    switch (type) {
        case "warnings":
            border = "warningBorder";
            icon = warningSvg;
            break;
        case "error":
            border = "errorBorder";
            icon = errorSvg;
            break;
        case "success":
            border = "successBorder";
            icon = successSvg;
            break;
        default:
    }

    return (
        <div className={styles.ModalParentContainer}>
            <div className={styles.ModalContainer}>
                <div className={styles[border]} />
                <img alt="alt" src={icon} className={styles.icon} />
                <div className={styles.textWrapper}>
                    <span className={styles.text}>{text}</span>
                </div>
                {upgrade && (
                    <div className={styles.buttonWrapper}>
                        <div
                            className={styles.button}
                            onClick={() => history.push("/billing")}
                        >
                            Upgrade Plan
                        </div>
                    </div>
                )}
                {route && (
                    <div
                        className={styles.button}
                        onClick={() => history.push("/baseline")}
                    >
                        Go to Baseline
                    </div>
                )}
                <div className={styles.children}>{children}</div>
                {!children && (
                    <img
                        alt="alt"
                        src={closeSvg}
                        className={styles.CloseModal}
                        onClick={close}
                    />
                )}
            </div>
        </div>
    );
};

export const DeleteEventToast = ({ children, eventName }) => {
    const border = "warningBorder";
    const icon = warningSvg;

    return (
        <div className={styles.ModalParentContainer}>
            <div className={styles.ModalContainer}>
                <div className={styles[border]} />
                <img alt="alt" src={icon} className={styles.icon} />
                <div className={styles.textWrapper}>
                    <span className={styles.text}>
                        Delete &#8220;{eventName}&#8221;?
                    </span>
                </div>
                <div className={styles.children}>{children}</div>
            </div>
        </div>
    );
};

export const DisconnectionWarning = ({ children }) => {
    const border = "warningBorder";
    const icon = warningSvg;

    return (
        <div className={styles.ModalParentContainer}>
            <div className={styles.ModalContainer}>
                <div className={styles[border]} />
                <img alt="alt" src={icon} className={styles.icon} />
                <div className={styles.textWrapper}>
                    <span className={styles.text}>Disconnect Line?</span>
                </div>
                <div className={styles.children}>{children}</div>
            </div>
        </div>
    );
};

import React, { Component } from "react";
import "./InputContainer.css";
import SingleCalendar from "../Calendar/singleCalendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    Description,
    InputButtons,
    SelectDropDown,
    Value,
    ModalRow,
    RequiredStar,
    EventHeader,
    EventInputModal,
    EventInputs,
    ModalRowHalf,
    ToggledContainer,
    ToggledContainerHalf,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { getPresentableDependencies } from "../../helpers/nodeDependencyDetectionHelpers";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { houseObject } from "Components/Registry/House";

class PropertyResaleInput extends Component {
    constructor(props) {
        super(props);

        let isValid = false;
        const houses = {};
        if (!this.props.edit) {
            isValid = getPresentableDependencies(
                houses,
                houseObject.constant(),
                this.props
            );
        }

        this.state = {
            name: getDefaultName(propertyResaleObject.constant(), this.props),
            type: propertyResaleObject.constant(),
            applyInflation: false,
            description: "",
            tag: `@${propertyResaleObject.constant()}`,
            rating: 0,
            inflation: false,
            baseline: false,
            curHouses: houses,
            resalePrice: 0,
            start: null,
            closingCost: 0,
            profit: null,
            house: null,
            houseId: null,
            dependencies: null,
            cadence: "monthly",
            isValid: isValid,
            houseValue: "marketValue",
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData }, () => {
                const houses = {};
                getPresentableDependencies(
                    houses,
                    houseObject.constant(),
                    this.props
                );
                this.setState({ curHouses: houses });
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "house":
                this.setState({ house: value });
                if (!value) {
                    this.setState({ houseId: null });
                } else {
                    const id = this.state.curHouses[value];
                    this.setState({ houseId: id });
                }
                break;
            case "resalePrice":
                this.setState({ resalePrice: value });
                break;
            case "closingCost":
                this.setState({ closingCost: value });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (propertyResaleObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    toggleInflation = () => {
        this.setState({ applyInflation: !this.state.applyInflation });
    };

    onHandleDate = (startDate, _endDate) => {
        this.setState({ start: startDate });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    updateAppreciation = (checked) => {
        this.setState({ appreciation: checked });
    };

    handleToggleSaleType = (e) => {
        this.setState({
            houseValue: e.target.id,
        });
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "marketValue":
                data = {
                    title: "Custom House Value",
                    content:
                        "Select this option if you expect to sell the associated House at a price other than its appreciation-adjusted value at the time of sale.",
                    subContent: "",
                    link: null,
                };
                break;
            case "customValue":
                data = {
                    title: "Projected House Value",
                    content:
                        "Select this option if you expect to sell the associated House at the value determined by its initial price and appreciation over time.",
                    subContent: "",
                    link: null,
                };
                break;
            case "closingCost":
                data = {
                    title: "Closing Costs",
                    content:
                        "Any costs associated with the sale of the home, such as legal expenses, title insurance, or land surveys.",
                    subContent:
                        "Consider using a seperate Closing Costs Event for more detailed expenses!",
                    link: null,
                };
                break;
            case "":
                data = {
                    title: "",
                    content: "",
                    subContent: "",
                    link: null,
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    render() {
        const passedCheck = propertyResaleObject.checkInput(this.state);
        const { edit } = this.props;
        const { name, description, resalePrice, closingCost, houseValue } =
            this.state;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        image={propertyResaleObject.svg()}
                        eventType={propertyResaleObject.constant()}
                        name={name}
                        onChangeInput={this.onChangeInput}
                    />
                    <ModalRow
                        onMouseEnter={this.onHandleMouseEnter}
                        id="description"
                    >
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow onMouseEnter={this.onHandleMouseEnter} id="house">
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={this.state.house}
                            options={Object.keys(this.state.curHouses) || []}
                            id="house"
                            label="Associated House"
                        />
                    </ModalRow>
                    <ToggledContainer>
                        <ToggledContainerHalf
                            id="marketValue"
                            isSelected={houseValue === "marketValue"}
                            onClick={this.handleToggleSaleType}
                            title="Projected House Value"
                            onMouseEnter={this.onHandleMouseEnter}
                        />
                        <ToggledContainerHalf
                            id="customValue"
                            isSelected={houseValue === "customValue"}
                            onClick={this.handleToggleSaleType}
                            title="Custom House Value"
                            onMouseEnter={this.onHandleMouseEnter}
                        />
                    </ToggledContainer>
                    <ModalRow twoInputs>
                        <ModalRowHalf
                            onMouseEnter={this.onHandleMouseEnter}
                            id="resalePrice"
                        >
                            <RequiredStar />
                            <Value
                                label="Resale Price"
                                onChangeInput={this.onChangeInput}
                                value={resalePrice}
                                id="resalePrice"
                                disabled={houseValue === "marketValue"}
                            />
                        </ModalRowHalf>
                        <ModalRowHalf
                            onMouseEnter={this.onHandleMouseEnter}
                            id="closingCost"
                        >
                            <RequiredStar />
                            <Value
                                label="Closing Costs"
                                onChangeInput={this.onChangeInput}
                                value={closingCost}
                                id="closingCost"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    <ModalRow onMouseEnter={this.onHandleMouseEnter} id="date">
                        <RequiredStar />
                        <SingleCalendar
                            onHandleDate={this.onHandleDate}
                            date={this.state.start}
                            title="Resale Date"
                        />
                    </ModalRow>
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default PropertyResaleInput;

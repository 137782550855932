export const House = {
    name: "My House",
    address: "",
    lat: null,
    lng: null,
    price: null,
    showSettings: false,
    actualHouse: true,
    placeHolderHouse: false,
    description: "",
    mlsNumber: "",
    url: null,
    "first payment": null,
    applyAppreciation: null,
    rating: 0,
    type: "House",
    value: null,
    tag: "@myHouse",
    onHoverStar: null,
    interestRate: null,
    baseline: true,
};

export const Mortgage = {
    name: "Mortgage",
    "first payment": null,
    cadence: null,
    price: null,
    downpayment: null,
    "mortgage term": "",
    rate: null,
    "amortization period": null,
    payments: null,
    url: null,
    end: null,
    rating: 0,
    description: "",
    showRequired: false,
    downpaymentRate: null,
    mlsNumber: null,
    type: "Mortgage",
    value: null,
    showSettings: false,
    valid: false,
    houseId: "a9486617-9782-4a24-a566-5d23cd608c29",
    tag: "@mortgage",
    onHoverStar: null,
    inflation: false,
    locked: false,
    bypassed: false,
    baseline: true,
};

export const Expense = {
    bypassed: false,
    cadence: null,
    cost: null,
    description: "",
    end: null,
    id: null,
    inflationRate: null,
    locked: false,
    name: "",
    rating: 0,
    showSettings: false,
    start: null,
    startDate: null,
    tag: "@expense",
    type: "Expense",
    url: null,
    value: null,
    baseline: true,
};

export const Rent = {
    accomodationCost: null,
    accomodationRate: null,
    address: null,
    bypassed: false,
    cadence: null,
    deposit: null,
    depositCost: null,
    depositRate: null,
    depositTerm: null,
    description: "",
    end: null,
    increaseRate: null,
    inflation: false,
    insurance: null,
    insuranceCost: null,
    insuranceFrequency: null,
    insuranceRate: null,
    insuranceTerm: null,
    lat: null,
    lng: null,
    locked: false,
    name: "Rent",
    onHoverStar: null,
    rating: 0,
    rent: 0,
    rentalIncrease: false,
    rentalType: null,
    roommates: null,
    rooms: null,
    sharedAccomodationTerm: null,
    showAccomodation: false,
    showAdditionalExpenses: false,
    showRentalIncrease: false,
    showRequired: false,
    showSettings: false,
    start: null,
    tag: "@rent",
    type: "Rent",
    upfrontCost: null,
    upfrontPayment: null,
    upfrontRate: null,
    upfrontTerm: null,
    url: null,
    utilitiesCost: null,
    utilitiesFrequency: null,
    utilitiesPayment: null,
    utilitiesRate: null,
    utilitiesTerm: null,
    value: 0,
    baseline: true,
};

import type { MouseEvent } from "react";
import { useState } from "react";
import { ultraZoomThreadExperimental } from "actions/zoomThreadActions";
import { useAppDispatch } from "store/useAppSelectorDispatch";

export function UltraZoomThreadButtonExperimental() {
    const [shouldDisplay, setShouldDisplay] = useState(true);
    const dispatch = useAppDispatch();

    if (process.env?.REACT_APP_ENV !== "development") return null;

    const ultraZoomThread = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(ultraZoomThreadExperimental());
    };

    if (!shouldDisplay) return null;

    return (
        <div
            style={{
                width: "200px",
                height: "100px",
                position: "absolute",
                bottom: "30px",
                left: "0",
                zIndex: 99,
                // border: "5px solid red",
            }}
        >
            <button
                onClick={ultraZoomThread}
                style={{
                    width: "100%",
                    height: "100%",
                    fontSize: "25px",
                }}
            >
                Ultra Zoom Thread
            </button>
            <div
                style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "23px",
                    border: "1px solid #333",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    transform: "translate(100%, -100%)",
                    cursor: "pointer",
                    userSelect: "none",
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setShouldDisplay(false);
                }}
            >
                X
            </div>
        </div>
    );
}

import _ from "lodash";
import Event from "./_event";
import EventManager from "./eventManager";

class BaselineDataManager extends EventManager {
    constructor(
        updateCanvas = (_sendToDecisionEngine) => {
            /** noop */
        },
        updateScenarioCanvas = () => {
            /** noop */
        }
    ) {
        super(updateCanvas, updateScenarioCanvas);
        this.isBaseline = true;
        this.rawBaselines = [];
        this.processedBaselines = [];
        this.activeBaseline = null;
        this.isFirstLoad = true;
    }

    setIsFirstLoad = (value) => {
        this.isFirstLoad = value;
    };

    addNewBaseline = (newBaseline) => {
        if (this.isBaselineLinked(newBaseline)) {
            this.processedBaselines.push(newBaseline);
            this.rawBaselines.push(this._processForExport(newBaseline));
        } else {
            this.rawBaselines.push(newBaseline);
            this.processedBaselines.push(
                this._connectBaselineNodes(newBaseline)
            );
        }
    };

    deleteBaseline = (baselineId) => {
        this.processedBaselines = this.processedBaselines.filter((baseline) => {
            return baseline.id !== baselineId;
        });
        this.rawBaselines = this.processedBaselines.filter((baseline) => {
            return baseline.id !== baselineId;
        });
        if (
            baselineId === this.activeBaseline.id &&
            this.processedBaselines.length > 0
        ) {
            this.setActiveBaseline(this.processedBaselines[0].id);
        } else {
            this.activeBaseline = null;
        }
    };

    // returns the default baseline for a scenario, if one exists.
    // otherwise, returns the first baseline found, or none if there are no baselines
    getDefaultBaseline = (scenario = null) => {
        const candidates = this.processedBaselines.filter((bl) => {
            return bl.id === scenario.baselineid;
        });
        if (candidates.length > 0) {
            return this._processForExport(candidates[0]);
        } else if (this.processedBaselines.length > 0) {
            return this._processForExport(this.processedBaselines[0]);
        } else {
            return {};
        }
    };

    // returns array of all known baselines
    getBaselines = (processForExport = false) => {
        if (processForExport) {
            return this.processedBaselines.map((bl) => {
                return this._processForExport(bl);
            });
        } else {
            return this.processedBaselines;
        }
    };

    getRawBaselines = () => {
        return this.rawBaselines;
    };

    // sets baselines field to given array
    setBaselines = (baselines) => {
        this.rawBaselines = baselines;

        let processedBaselines = [];
        baselines.forEach((bl) => {
            processedBaselines.push(this._connectBaselineNodes(bl));
        });

        this.processedBaselines = processedBaselines;
        this.activeBaseline = this.processedBaselines[0];
    };

    // configures BaselineManager for the inputted baseline
    setActiveBaseline = (baselineId) => {
        const candidates = this.processedBaselines.filter((candidate) => {
            return candidate.id === baselineId;
        });
        if (candidates.length === 0) {
            this.activeBaseline = {};
        } else {
            this.activeBaseline = candidates[0];
        }
    };

    existsBaselineWithId = (baselineId) => {
        let exists = false;
        this.processedBaselines.forEach((baseline) => {
            if (baseline.id === baselineId) exists = true;
        });

        return exists;
    };

    isBaselineLinked = (baseline) => {
        return (
            baseline.data &&
            baseline.data.nodes &&
            baseline.data.nodes &&
            baseline.data.nodes[0] &&
            baseline.data.nodes[0].children &&
            (baseline.data.nodes[0].children.length === 0 ||
                baseline.data.nodes[0].children[0] instanceof String)
        );
    };

    updateExistingBaseline = (newBaseline) => {
        const baselines = this.processedBaselines;
        const isLinked = this.isBaselineLinked(newBaseline);
        let index;
        for (let i = 0; i < baselines.length; i++) {
            if (baselines[i].id === newBaseline.id) index = i;
        }
        if (isLinked) {
            this.processedBaselines[index] = newBaseline;
            this.rawBaselines[index] = this._processForExport(newBaseline);
        } else {
            this.processedBaselines[index] =
                this._connectBaselineNodes(newBaseline);
            this.rawBaselines[index] = newBaseline;
        }
        if (this.activeBaseline && this.activeBaseline.id === newBaseline.id) {
            this.activeBaseline = this.processedBaselines[index];
        }
    };

    // returns the current active baseline
    getActiveBaseline = (processForExport = false) => {
        if (processForExport) {
            return this._processForExport(this.activeBaseline);
        } else {
            return this.activeBaseline;
        }
    };

    getActiveEvents = (manager) => {
        const nodes = this.activeBaseline.data.nodes;
        const events = nodes?.map((node) => {
            return new Event(node.type, node, node.id, manager);
        });
        return events;
    };

    // retrieves nodes of active baseline
    allNodes = () => {
        return this.activeBaseline.data.nodes;
    };

    reset = () => {
        this.rawBaselines = [];
        this.processedBaselines = [];
        this.activeBaseline = null;
    };

    hasActiveBaseline = () => {
        return Boolean(
            this.activeBaseline !== null &&
                this.activeBaseline !== undefined &&
                this.activeBaseline.id
        );
    };

    // takes inputted baseline where parents and children fields are arrays of UUIDs,
    // returns COPY of baseline where parents and children fields are references to corresponding nodes
    _connectBaselineNodes = (baselineToProcess) => {
        const baseline = _.cloneDeep(baselineToProcess);
        let nodes = baseline.data.nodes;

        nodes.forEach((nodeData) => {
            const { id, children, parents } = nodeData;
            const node = this._findEventFromProvidedNodes(id, nodes);
            const parentNodes = [];
            // TODO: replace with maps
            parents.forEach((id) => {
                const node = this._findEventFromProvidedNodes(id, nodes);
                if (node) {
                    parentNodes.push(node);
                }
            });
            const childrenNodes = [];
            children.forEach((id) => {
                const node = this._findEventFromProvidedNodes(id, nodes);
                if (node) {
                    childrenNodes.push(node);
                }
            });

            node.parents = parentNodes;
            node.children = childrenNodes;
        });

        return baseline;
    };

    // performs opposite of connectBaselineNodes: replaces node references in children/parents with corresponding node IDs
    _processForExport = (baselineToProcess) => {
        const baseline = _.cloneDeep(baselineToProcess);
        if (!baseline || !baseline.data || !baseline.data.nodes)
            return baseline;

        let nodes = baseline.data.nodes;

        nodes.forEach((nodeData) => {
            const { id, children, parents } = nodeData;
            const node = this._findEventFromProvidedNodes(id, nodes);
            const parentNodeIds = [];
            // TODO: replace with maps
            parents.forEach((node) => {
                parentNodeIds.push(node.id);
            });
            const childrenNodeIds = [];
            children.forEach((node) => {
                childrenNodeIds.push(node.id);
            });

            node.parents = parentNodeIds;
            node.children = childrenNodeIds;
        });

        return baseline;
    };

    // finds node in nodes with id
    _findEventFromProvidedNodes = (id, nodes) => {
        let event = null;
        for (let node of nodes) {
            if (node.id === id) event = node;
        }
        return event;
    };

    /**
     * This function takes in a baseline ID and returns the corresponding baseline.
     * If no corresponding baseline is found, returns undefined instead, so utilise the
     * nullish coalescing operator (??)
     * @param {string} baselineId
     * @returns {object} baseline
     */
    getBaseline = (baselineId) => {
        return this.processedBaselines?.find(
            (baseline) => baseline.id === baselineId
        );
    };

    attachToNodes = (
        identifier,
        parents = [],
        children = [],
        _value = true
    ) => {
        const _event = this._findEvent(identifier);
        parents = parents.map(this._findEvent);
        children = children.map(this._findEvent);

        //find index of the sub-thread
        let temp = parents[0]?.children;
        let index = temp?.findIndex(
            (element) => element?.id === children[0]?.id
        );

        this._detachNodes(parents, children);

        // if this is new thread, move to bottom, else, keep thread where it is
        if (children.length === 0) {
            _event.setParents(parents, false, true, 9999);
        } else {
            index = index == undefined ? -1 : index;
            _event.setParents(parents, false, true, index);
        }

        _event.setChildren(children);
    };

    _detachNodes = (parents = [], children = []) => {
        if (parents.length === 0 || children.length === 0) {
            return;
        }

        parents.forEach((parentEvent) => {
            parentEvent.detachChildren(children);
        });
    };

    updateDeletionData = (root) => {
        let commonEvent,
            removeAllNodes = true;
        const toDelete = [];
        if (root.children.length < 2) {
            toDelete.push(root);
            commonEvent = root.children.length === 1 ? root.children[0] : null;
        }

        if (!removeAllNodes) {
            commonEvent = null;
        }

        return {
            root: root,
            newRoot: commonEvent?.id ?? "",
            toDelete,
            isBaselineNode: true,
        };
    };

    deleteWithDeletionData = (deletionData) => {
        if (deletionData) {
            const { root, newRoot, toDelete } = deletionData;

            const realNewRoot = this._findEvent(newRoot);

            let index = -1;
            index = root.parents[0]?.children?.findIndex(
                (element) => element?.id === root?.id
            );

            if (root) {
                const rootParents = [];
                if (realNewRoot) {
                    root.parents.forEach((parent) => {
                        rootParents.push(parent);
                    });
                }
                toDelete.forEach((_node) => {
                    if (_node.children.length > 0)
                        _node.detachChildren(_node.children);
                    if (_node.parents.length > 0)
                        _node.detachParents(_node.parents);
                    delete this.nodes[_node.id];
                });

                if (realNewRoot) {
                    rootParents.forEach((parent) => {
                        index = index === undefined ? -1 : index;
                        parent.setChildren([realNewRoot], true, true, index);
                    });
                }
            }
            // Running both calculate() and _updateScenarioCanvas() as a hacky fix
            // If calculate() is run alone, then loadedScenario is not updated and this can lead to various bugs due to the
            //  loadedScenario state and baselineDataManager being out of sync (ie. refreshing can bring back a deleted event).
            // If _updateScenarioCanvas() is run alone, then a ghost event appears in it's own separate thread in the canvas,
            //  this ghost event does not interact with other events and disappears on refresh.
            this.calculate();
            this._updateScenarioCanvas();
        }
    };

    importBaseline = (data = {}) => {
        let { nodes } = data;
        try {
            nodes = JSON.parse(nodes);
        } catch (e) {
            // noop
        }

        const newNodes = nodes.map((nodeData) => {
            const newEvent = this.createEvent(
                nodeData.type,
                nodeData,
                nodeData.id
            );
            newEvent.parents = nodeData.parents;
            newEvent.children = nodeData.children;
            return newEvent;
        });

        newNodes.forEach((nodeData) => {
            const { id, children, parents } = nodeData;
            const node = this.nodes[id];
            const parentNodes = [];
            parents.forEach((id) => {
                const node = this._findEvent(id);
                if (node) {
                    parentNodes.push(node);
                }
            });
            const childrenNodes = [];
            children.forEach((id) => {
                const node = this._findEvent(id);
                if (node) {
                    childrenNodes.push(node);
                }
            });

            node.setParents(parentNodes, true, true);
            node.setChildren(childrenNodes, true, true);
        });

        this.sortedEventIds = [];
        newNodes.map((event) => {
            this.nodes[event.id] = event;
            this.sortedEventIds.push(event.id);
        });
    };

    handleExport = () => {
        const newBaselineData = this.exportData();
        this.activeBaseline.data = newBaselineData;
        return this.activeBaseline;
    };

    exportData = () => {
        const nodeData = Object.values(this.nodes).map((node) => {
            return node.exportData();
        });

        const exportData = {
            version: "1.0",
            nodes: nodeData,
            root: this.root.id,
        };

        return exportData;
    };
}

export default BaselineDataManager;

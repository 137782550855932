import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import Customer2InputView from "./Customer2InputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { customer2Object } from "Components/Registry/Customer2";
import { createNewEvent } from "helpers/createNewEvent";
import { EntitiesSchema, EntitySchema } from "reducers/typesSchema/entitiesSchema";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { updateEntityState } from "helpers/updateEntityState";
import { customer2InputsHandler } from "../OnInputChangeHandlers/customer2InputsHandler";
import type { ChangeEvent } from "react";
import { EntityCustomAccount, getAccountsToDelete, getPaths, handleCustomAccountStructures } from "helpers/accounts";
import * as uuid from "uuid";

export interface CustomerCustomAccountData {
    customerName: string;
    parentAccountId: string;
    grownAccountId: string;
    churnedAccountId: string;
    transferredInAccountId: string;
    transferredOutAccountId: string;
}

export default function Customer2InputExperimental({
    fillPartialValues,
    line,
    focus,
    edit,
    editData,
    baseline,
    confirmAction,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager = useAppSelector((state) => state?.scenario?.manager);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { baselineManager, manager, line, focus, baseline };
    const entitiesObject: EntitiesSchema = useAppSelector(
        (state) => state?.entities
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(customer2Object);
            _eventData.name = getDefaultName(
                customer2Object.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        onHandleDate,
    } = useEntities(entitiesObject, eventData, edit, customer2Object); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "customer" | "entityName"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = customer2InputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, baselineManager, setEntitiesMap]);

    const onHandleSubmitValuesCustom = (newEntitiesMap, _partialValues) => {
        dispatch(
            handleSubmitNodesAndEntities(
                confirmAction,
                fillPartialValues,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    // the in-between step of clicking submit and calling onHandleSubmit, which is updating the customer custom accounts
    const onHandleClickSubmitOrUpdate = (partialValues: boolean) => {
        const accountsToDelete =
            getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            partialValues,
            entitiesMap,
            createCustomer2AccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && customer2Object.checkInput(entitiesMap);

    return (
        <Customer2InputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            onHandleDate={onHandleDate}
            passedCheck={passedCheck}
            onHandleClickSubmitOrUpdate={onHandleClickSubmitOrUpdate}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
        />
    );
}

export const createCustomer2AccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const parentAccountId = uuid.v4();
    const grownAccountId = uuid.v4();
    const churnedAccountId = uuid.v4();
    const transferredInAccountId = uuid.v4();
    const transferredOutAccountId = uuid.v4();

    const accountStructures: EntityCustomAccount[] = [{
        Name: entity.name,
        Type: "display",
        Display: "Customer",
        Root: {
            topLevel: "b2623ec6-3492-4fa0-9dcd-0150c3443cb0",
            topLevelChildren: [parentAccountId]
        },
        customRoots: [
            {
                topLevel: ["Customer - ", parentAccountId],
                topLevelChildren: [["Grown ", grownAccountId], ["Churned ", churnedAccountId], ["Transferred In ", transferredInAccountId], ["Transferred Out ", transferredOutAccountId]]
            }
        ]
    }]

    data.accountStructure = accountStructures
    data.accountIdsPaths = getPaths(accountStructures)

    // Record accountIds in state (Maybe we can retrieve them from above struct?)
    data.parentAccountId = parentAccountId;
    data.grownAccountId = grownAccountId;
    data.churnedAccountId = churnedAccountId;
    data.transferredInAccountId = transferredInAccountId;
    data.transferredOutAccountId = transferredOutAccountId;

    data.accountIds = [
        "b2623ec6-3492-4fa0-9dcd-0150c3443cb0",
        "b93d043d-bf4c-423f-8a80-7702870a5ebb",
        "fe6d5929-4afd-4c03-91cf-121164ff212e",
        parentAccountId,
        grownAccountId,
        churnedAccountId,
        transferredInAccountId,
        transferredOutAccountId,
    ];

    currentEntityObject.data = data

    return currentEntityObject
}
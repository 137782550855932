import "./Card.css";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { useMemo, useState } from "react";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";

const CapacityCard = ({ data }) => {
    const [currentIndex, _setCurrentIndex] = useState(
        data?.mostRecentEntity ?? 0
    );

    const currentEntityData = useMemo(() => {
        const entityIds = data?.entities ?? [];
        const entityDataArray = Object.values(getRelevantEntities(entityIds));
        const currentEntityData = entityDataArray?.[currentIndex];
        return currentEntityData;
    }, [data, currentIndex]);

    const { targetEventData, targetEntityData } = useMemo(() => {
        const { eventId, entityIds } = currentEntityData?.data?.selectedEvent;
        const targetEventData = getEvent(eventId);

        let targetEntityData: null | EntitySchema = null;

        if (entityIds?.length > 0) {
            const selectedEntityData = Object.values(
                getRelevantEntities(entityIds) ?? {}
            )?.[0];
            targetEntityData = selectedEntityData;
        }

        return {
            targetEventData,
            targetEntityData,
        };
    }, [currentEntityData]);

    return (
        <div className="Container">
            <div className="Row">
                <div className="ExpenseLabel">Target Event:</div>
                <div className="cardValue">{targetEventData?.name}</div>
            </div>
            <div className="Row">
                <div className="ExpenseLabel">Target Record:</div>
                <div className="cardValue">
                    {targetEntityData?.name ?? "All"}
                </div>
            </div>
            <div className="Row">
                <div className="ExpenseLabel">Target Property:</div>
                <div className="cardValue">
                    {currentEntityData?.data?.property}
                </div>
            </div>
            <div className="Row">
                <div className="ExpenseLabel">Modifier Amount:</div>
                <div className="cardValue">
                    {currentEntityData?.data?.value}
                </div>
            </div>
            {currentEntityData?.data?.customEffectPeriod && (
                <div className="Row">
                    <div className="ExpenseLabel">Effect Period:</div>
                    <div className="cardValue">
                        {`${
                            currentEntityData?.startDate
                                ? moment(currentEntityData?.startDate).format(
                                      "MMM D YYYY"
                                  )
                                : "No Start Date"
                        } - ${
                            currentEntityData?.endDate
                                ? moment(currentEntityData?.endDate).format(
                                      "MMM D YYYY"
                                  )
                                : "No End Date"
                        }
                            `}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CapacityCard;

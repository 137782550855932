import NodeMenu from "./_eventNodeMenu";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";
import {
    resetContainerCreation,
    upsertContainerCreation,
} from "../../actions/containerCreationActions";

const mapStateToProps = (state) => {
    return {
        focus: state.scenario.focus,
        line: state.scenario.line,
        manager: state.scenario.manager,
        loadedScenario: state.scenario.loadedScenario,
        userScenarios: state.scenario.userScenarios,
        sharedScenario: state.scenario.sharedScenario,
        baseline: state.scenario.baseline,
        baselineManager: state.scenario.baselineManager,
        baselineDataManager: state.scenario.baselineDataManager,
        eventLibrary: state.scenario.eventLibrary,
        containerCreation: state.containerCreation,
    };
};

const mapDispatchToProps = {
    resetFocus: actions.resetFocus,
    popUpOption: actions.popUpOption,
    editNode: actions.editNode,
    getWalkScore: actions.getWalkScore,
    editScenario: actions.editScenario,
    fetchSharedScenarios: actions.fetchSharedScenarios,
    loadScenario: actions.loadScenario,
    getUserScenarios: actions.getUserScenarios,
    editScenarioComments: actions.editScenarioComments,
    updateBaselineCanvas: actions.updateBaselineCanvas,
    updateBaseline: actions.updateBaseline,
    createBaseline: actions.createBaseline,
    getBaseline: actions.getBaseline,
    updateNode: actions.updateNode,
    updateScenario: actions.updateScenario,
    getUserData: actions.getUserData,
    addLibraryEvents: actions.addLibraryEvents,
    toggleShowAgencyBaselineView: actions.toggleShowAgencyBaselineView,
    showLogin: actions.showLogin,
    resetContainerCreation,
    upsertContainerCreation,
};

const NodeMenuContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NodeMenu);

export default NodeMenuContainer;

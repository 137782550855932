import { useContext } from "react";
import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { ScenarioViewThread } from "./ScenarioViewThread";
import { RatingContext } from "..";

export function ThreadsList() {
    const rating = useContext(RatingContext);

    const { calculatedThreads, finalAccountLedgerId } = useAppSelector(
        (state) => {
            const monthlyCumulative = state.threadsList.monthlyCumulative;
            const accountLedgerId = state.threadsList.accountLedgerId;
            const finalAccountLedgerId =
                monthlyCumulative === "monthly"
                    ? accountLedgerId
                    : "Cumulative " + accountLedgerId;
            const calculatedThreads = state.calculatedThreads;

            return {
                calculatedThreads,
                finalAccountLedgerId,
            };
        },
        shallowEqual
    );

    /**
     * Returns a sorted array of the threadIds;
     */
    const threadIds = useMemo(() => {
        const threads = Object.values(calculatedThreads);

        // Find the Largest Absolute value
        const threadsAveragesArray = threads.map(
            (t) => t.ledgersDataAverages?.[finalAccountLedgerId]?._All || 0
        );
        const largestVal = Math.max(...threadsAveragesArray);
        const largestAbsVal = Math.abs(largestVal);

        threads.sort((a, b) => {
            const avgValueofA =
                calculatedThreads?.[a.id]?.ledgersDataAverages?.[
                    finalAccountLedgerId
                ]?._All || 0;
            const avgValueOfB =
                calculatedThreads?.[b.id]?.ledgersDataAverages?.[
                    finalAccountLedgerId
                ]?._All || 0;
            const aRating = a.rating || 0;
            const bRating = b.rating || 0;
            return (
                (100 - rating) *
                    (avgValueOfB / largestAbsVal -
                        avgValueofA / largestAbsVal) +
                rating * (bRating / 5 - aRating / 5)
            );
        });

        const threadIds = threads.map((t) => t.id);

        return threadIds;
    }, [calculatedThreads, finalAccountLedgerId, rating]);

    return (
        <div className="RightPanelScenarioThreadsContainer">
            {threadIds.map((threadId) => (
                <ScenarioViewThread key={threadId} threadId={threadId} />
            ))}
        </div>
    );
}

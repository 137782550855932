import React from "react";
import styles from "./index.module.css";
import { EventsModal } from "../EventsModal";
import checkMarkSvg from "../../Assets/checkMark.svg";
// import comingSoonSvg from "../../Assets/comingsoonPlans.png";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import { throwError } from "../../helpers/swalError";
import {
    getMaxEventsPerScenario,
    getMaxProEvents,
    getMaxScenarioCount,
    getMaxScenarioShares,
    getPlanBillingString,
    getPlanName,
} from "../../helpers/userHelpers";
import _ from "lodash";

export default class BillingContainer extends React.Component {
    state = {
        showDowngradeModal: false,
        showUpgradeModal: false,
        currentDowngradeStage: "info",
        scenariosToArchive: [],
        proEventsToKeep: [],
        scenariosToRetrieve: [],
        targetPlanId: "free-plan",
        plans: null,
        personal: [],
        business: [],
        selectedOption: "business",
        accountData: null,
    };

    subscribePlan = (planId, isDowngrade) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (loggedInUser) {
            const user = {
                ...this.props.accountData,
                data: {
                    ...this.props.accountData.data,
                    planId: planId,
                },
            };

            this.props
                .createSubscription(user, this.props.history, () => {
                    isDowngrade
                        ? this.completeDowngrade(loggedInUser)
                        : this.completeUpgrade(loggedInUser);
                })
                .then((result) => {
                    localStorage.setItem(
                        "loggedInUser",
                        JSON.stringify(loggedInUser)
                    );
                    console.log(result);
                })
                .catch((err) => {
                    if (
                        err.response &&
                        err.response.data.api_error_code ===
                            "payment_method_not_present"
                    ) {
                        this.setState({ promptAddCard: true });
                        throwError(
                            "error",
                            "No Credit Card on File",
                            "Please add a payment method"
                        );
                    } else {
                        throwError(
                            "error",
                            "Subscription failed",
                            "We were unable to change your subscription"
                        );
                    }
                });
            this.setState({ accountData: user });
        } else {
            this.props.openLogin();
            this.props.history.push("/");
        }
    };

    onHandleSelected = (e) => {
        const id = e.target.id;
        this.setState({ selectedOption: id });
    };

    componentDidMount() {
        this.setState({ accountData: this.props.accountData });

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            this.props.getUserData(loggedInUser);
        }
        this.props.getAllPlans((err, data) => {
            if (err) {
                throwError(
                    "error",
                    "Billing Error",
                    "We were unable to retrieve our subscription data"
                );
            } else {
                this.updatePlans(data.data.list);
            }
        });

        this.setState({ selectedOption: "business" });

        // if (
        //     this.props.accountData &&
        //     this.props.accountData.userRole === "agency"
        // ) {
        //     this.setState({ selectedOption: "business" });
        // }
    }

    updatePlans = (plansArray) => {
        const PERSONAL_PLAN_IDS = [
            "free-plan",
            "starter_01",
            "personal_pro_01",
        ];
        const BUSINESS_PLAN_IDS = [
            "free-plan",
            "startup-plan",
            "small-business",
            "agency_01",
        ];
        const plans = {};
        const personalPlans = [];
        const businessPlans = [];

        plansArray.forEach((entry) => {
            entry.plan.frequencyDisplay = entry.plan.price !== 0;
            plans[entry.plan.id] = entry.plan;
        });
        PERSONAL_PLAN_IDS.forEach((id) => {
            if (!plans[id]) return; // skip invalid entries
            personalPlans.push({ ...plans[id], personal: true });
        });

        // todo
        BUSINESS_PLAN_IDS.forEach((id) => {
            if (!plans[id]) return; // skip invalid entries
            businessPlans.push({ ...plans[id], personal: false });
        });

        this.setState({
            plans,
            personal: personalPlans,
            business: businessPlans,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.accountData !== this.props.accountData) {
            this.setState({
                accountData: this.props.accountData,
            });

            if (this.props.accountData.userRole === "agency")
                this.setState({ selectedOption: "business" });
        }

        if (prevState.plans !== this.state.plans) {
            // todo: update displayed plans
        }
    }

    closeBillingContainer = () => {
        this.props.history.push("/home");
    };

    onHandleDemo = () => {
        window.open(
            "https://calendly.com/whatifi/whatifi-for-cash-flow-45-min"
        );
    };

    toggleArchiveScenario = (scenario) => {
        let scenariosToArchive = _.cloneDeep(this.state.scenariosToArchive);
        if (!scenariosToArchive) scenariosToArchive = [];
        if (scenariosToArchive.includes(scenario.id)) {
            scenariosToArchive = scenariosToArchive.filter((entry) => {
                return entry !== scenario.id;
            });
        } else {
            scenariosToArchive.push(scenario.id);
        }
        this.setState({ scenariosToArchive });
    };

    resetDowngradeProgress = () => {
        this.setState({
            showDowngradeModal: false,
            proEventsToDelete: [],
            scenariosToArchive: [],
            currentDowngradeStage: "info",
        });
    };

    resetUpgradeProgress = () => {
        this.setState({
            showUpgradeModal: false,
            scenariosToRetrieve: [],
        });
    };

    launchDowngrade = (e) => {
        const id = e.target.id;
        const { userScenarios } = this.props;
        const numScenarios = userScenarios.length;
        let maxNodesPerScenario = 0;
        let maxNumSharees = 0;
        for (const scenario of userScenarios) {
            if (scenario.data.nodes.length > maxNodesPerScenario)
                maxNodesPerScenario = scenario.data.nodes.length;
            const whitelistLength =
                scenario.permissions.whitelist &&
                Object.values(scenario.permissions.whitelist).length;
            if (whitelistLength && whitelistLength > maxNumSharees)
                maxNumSharees = whitelistLength;
        }
        this.setState({
            numScenarios,
            maxNodesPerScenario,
            maxNumSharees,
            showDowngradeModal: true,
            targetPlanId: id,
        });
    };
    getDowngradeModal = () => {
        const { currentDowngradeStage } = this.state;

        switch (currentDowngradeStage) {
            case "info":
                return this.getDowngradeInfoModal();
            case "scenarios":
                return this.getArchiveScenariosModal();
            case "unshare":
                return this.getUnshareModal();
            case "proNodes":
                return this.getDowngradeProNodeModal();
            default:
                return <div />;
        }
    };

    toggleRetrieveScenario = (scenario) => {
        let scenariosToRetrieve = _.cloneDeep(this.state.scenariosToRetrieve);
        if (!scenariosToRetrieve) scenariosToRetrieve = [];
        if (scenariosToRetrieve.includes(scenario.id)) {
            scenariosToRetrieve = scenariosToRetrieve.filter((entry) => {
                return entry !== scenario.id;
            });
        } else {
            scenariosToRetrieve.push(scenario.id);
        }
        this.setState({ scenariosToRetrieve });
    };

    managePortal = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            this.props.openPortal(loggedInUser, this.props.history);
        }
    };

    getUpgradeModal = () => {
        const { userScenarios, archivedScenarios } = this.props;
        const { scenariosToRetrieve, targetPlanId, plans } = this.state;

        const newLimit = getMaxScenarioCount(plans[targetPlanId]);
        const numToRetrieve = newLimit - userScenarios.length;
        const overLimit = scenariosToRetrieve.length > numToRetrieve;

        return (
            <EventsModal onHandleClose={this.resetUpgradeProgress} subscription>
                <div className={styles.modalHeader}>Upgrade Plan</div>
                <div className={styles.modalBody}>
                    <div className={styles.modalRow}>
                        You may retrieve up to {" " + numToRetrieve + " "}{" "}
                        archived scenarios.
                    </div>
                    <div className={styles.scenarioList}>
                        {archivedScenarios.map((scenario) => {
                            const isToRetrieve = scenariosToRetrieve.includes(
                                scenario.id
                            );
                            return (
                                <div
                                    key={scenario.id}
                                    className={styles.scenarioEntry}
                                >
                                    <img
                                        alt="checkbox"
                                        className={styles.checkbox}
                                        src={
                                            isToRetrieve
                                                ? checkboxOn
                                                : checkboxOff
                                        }
                                        onClick={() => {
                                            this.toggleRetrieveScenario(
                                                scenario
                                            );
                                        }}
                                    />
                                    <div className={styles.scenarioName}>
                                        {scenario.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.modalFooter}>
                    <div
                        className={
                            overLimit
                                ? styles.proceedButtonDisabled
                                : styles.proceedButton
                        }
                        onClick={() => this.subscribePlan(targetPlanId, false)}
                    >
                        Finish
                    </div>
                    {this.state.promptAddCard && (
                        <div
                            className={styles.proceedButton}
                            onClick={this.launchAddCard}
                        >
                            Add Payment Method
                        </div>
                    )}
                </div>
            </EventsModal>
        );
    };

    getDowngradeInfoModal = () => {
        const {
            numScenarios,
            maxNodesPerScenario,
            maxNumSharees,
            targetPlanId,
            plans,
        } = this.state;
        const plan = plans[targetPlanId];
        return (
            <EventsModal
                onHandleClose={this.resetDowngradeProgress}
                subscription
            >
                <div className={styles.modalHeader}>
                    Oops, you are using some premium features...
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.modalRow}>
                        <div
                            className={styles.boldText}
                            style={{ fontSize: "14px", marginBottom: "15px" }}
                        >
                            Lorem ipsum dolor sit amet,{" "}
                            <span className={styles.orange}>in orange</span>{" "}
                            consectetur adipiscing elit. Nulla dapibus, tellus
                            nec pellentesque efficitur, ex mi suscipit sem
                        </div>
                    </div>
                    <div className={styles.modalParagraph}>
                        <div className={styles.modalRow}>
                            <div className={styles.boldText}>
                                Up to {getMaxScenarioCount(plan)} scenarios
                            </div>
                        </div>
                        <div className={styles.modalRow}>
                            <div>
                                <span className={styles.orange}>*</span> You
                                have{" "}
                                <span className={styles.orange}>
                                    {numScenarios}
                                </span>{" "}
                                scenarios.
                            </div>
                        </div>
                    </div>
                    <div className={styles.modalParagraph}>
                        <div className={styles.modalRow}>
                            <div className={styles.boldText}>
                                Up to {getMaxEventsPerScenario(plan)} events per
                                scenario
                            </div>
                        </div>
                        <div className={styles.modalRow}>
                            <div>
                                <span className={styles.orange}>*</span> You
                                have as many as{" "}
                                <span className={styles.orange}>
                                    {maxNodesPerScenario}
                                </span>{" "}
                                events per scenario.
                            </div>
                        </div>
                    </div>

                    <div className={styles.modalParagraph}>
                        <div className={styles.modalRow}>
                            <div className={styles.boldText}>
                                Share scenarios with up to{" "}
                                {getMaxScenarioShares(plan)} other user
                            </div>
                        </div>
                        <div className={styles.modalRow}>
                            <div>
                                <span className={styles.orange}>*</span> You
                                have shared scenarios with as many as{" "}
                                <span className={styles.orange}>
                                    {maxNumSharees}
                                </span>{" "}
                                users.
                            </div>
                        </div>
                    </div>
                    <div className={styles.modalRow}>
                        <div className={styles.boldText}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nulla dapibus, tellus nec pellentesque
                            efficitur, ex mi suscipit sem, vitae dapibus arcu
                            magna eget erat. Curabitur vulputate tempus risus,
                            et gravida ipsum{" "}
                        </div>
                    </div>
                </div>
                <div className={styles.modalFooter}>
                    <div
                        className={styles.proceedButton}
                        onClick={this.resetDowngradeProgress}
                    >
                        Keep Current Plan
                    </div>
                    <div
                        className={styles.cancelButton}
                        onClick={this.continueDowngrade}
                    >
                        Downgrade Anyways
                    </div>
                </div>
            </EventsModal>
        );
    };

    getDowngradeProNodeModal = () => {
        const { proEventsToKeep, targetPlanId, plans } = this.state;
        const proEventLimit = getMaxProEvents(plans[targetPlanId]);
        const isOverLimit = proEventsToKeep.length > proEventLimit;

        return (
            <EventsModal
                onHandleClose={this.resetDowngradeProgress}
                subscription
            >
                <div className={styles.modalHeader}>
                    Select Pro Events to Keep
                </div>
                <div className={styles.modalBody}>
                    WIP. Pro events will appear here as list
                </div>
                <div className={styles.modalFooter}>
                    <div
                        className={
                            isOverLimit
                                ? styles.proceedButtonDisabled
                                : styles.proceedButton
                        }
                        onClick={this.continueDowngrade}
                    >
                        Finish
                    </div>
                    <div
                        className={styles.cancelButton}
                        onClick={() =>
                            this.setState({
                                currentDowngradeStage: "scenarios",
                            })
                        }
                    >
                        Back
                    </div>
                </div>
            </EventsModal>
        );
    };

    getArchiveScenariosModal = () => {
        const { userScenarios } = this.props;
        const { scenariosToArchive, targetPlanId, plans } = this.state;
        const scenarioLimit = getMaxScenarioCount(plans[targetPlanId]);

        const isOverLimit =
            userScenarios.length - scenariosToArchive.length > scenarioLimit;
        return (
            <EventsModal
                onHandleClose={this.resetDowngradeProgress}
                subscription
            >
                <div className={styles.modalHeader}>
                    Select Scenarios to Keep
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.modalRow}>
                        {`You may select up to ${scenarioLimit} scenarios. The rest will be archived - you will still be able to view them, but they cannot be edited, shared, or sent to the Decision Engine.`}
                        <br />
                        <br />
                    </div>
                    <div className={styles.scenarioList}>
                        {userScenarios.map((entry) => {
                            const isToKeep = !scenariosToArchive.includes(
                                entry.id
                            );
                            return (
                                <div
                                    key={entry.id}
                                    className={styles.scenarioEntry}
                                >
                                    <img
                                        alt="checkbox"
                                        className={styles.checkbox}
                                        src={
                                            isToKeep ? checkboxOn : checkboxOff
                                        }
                                        onClick={() => {
                                            this.toggleArchiveScenario(entry);
                                        }}
                                    />
                                    <div className={styles.scenarioName}>
                                        {entry.name}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.modalFooter}>
                    <div
                        className={
                            isOverLimit
                                ? styles.proceedButtonDisabled
                                : styles.proceedButton
                        }
                        onClick={this.continueDowngrade}
                    >
                        Proceed
                    </div>
                    <div
                        className={styles.cancelButton}
                        onClick={() =>
                            this.setState({
                                currentDowngradeStage: "info",
                            })
                        }
                    >
                        Back
                    </div>
                </div>
            </EventsModal>
        );
    };

    toggleUnshare = (scenario, email) => {
        const unshareScenarioMap = { ...this.state.unshareScenarioMap };
        unshareScenarioMap[scenario.id][email] =
            !unshareScenarioMap[scenario.id][email];
        this.setState({ unshareScenarioMap });
    };

    // TODO
    getDowngradeConfirmModal = () => {
        // const {
        //     scenariosToArchive,
        //     scenariosToUnshare,
        // } = this.state;

        return (
            <EventsModal
                onHandleClose={this.resetDowngradeProgress}
                subscription
            >
                <div className={styles.modalHeader}>
                    Are you sure you want to downgrade?
                </div>
                <div className={styles.modalBody}>
                    {/* <div className={styles.modalRow}>
                        <span className={styles.orange}>
                            {scenariosToArchive.length}
                        </span>
                        scenarios will be archived.
                    </div>
                    <div className={styles.modalRow}>
                        <span className={styles.orange}>
                            {scenariosToUnshare.length}
                        </span>
                        scenarios will have fewer shares.
                    </div> */}
                </div>

                <div className={styles.modalFooter}>F</div>
            </EventsModal>
        );
    };

    getUnshareModal = () => {
        const { scenariosToUnshare, targetPlanId, unshareScenarioMap, plans } =
            this.state;
        const plan = plans[targetPlanId];
        const shareLimit = getMaxScenarioShares(plan);
        const planName = getPlanName(plan);
        let isPassed = true;
        Object.values(unshareScenarioMap).forEach((scenario) => {
            let count = 0;
            Object.values(scenario).forEach((user) => {
                if (!user) count++;
            });
            isPassed = isPassed && count <= shareLimit;
        });

        return (
            <EventsModal
                onHandleClose={this.resetDowngradeProgress}
                subscription
            >
                <div className={styles.modalHeader}>Unshare Scenarios</div>
                <div className={styles.modalBody}>
                    <div className={styles.modalRow}>
                        <div
                            className={styles.boldText}
                            style={{ fontSize: "14px", marginBottom: "15px" }}
                        >
                            With the{" "}
                            <span className={styles.orange}>{planName}</span>,
                            you may share scenarios with no more than{" "}
                            <span className={styles.orange}>{shareLimit}</span>{" "}
                            users
                        </div>
                    </div>
                    {scenariosToUnshare.map((scenario) => {
                        return (
                            <div
                                key={scenario.id}
                                className={styles.modalParagraph}
                            >
                                <div className={styles.modalRow}>
                                    <div
                                        className={styles.boldText}
                                        style={{ fontSize: "14px" }}
                                    >
                                        {scenario.name}
                                    </div>
                                </div>
                                <div className={styles.scenarioList}>
                                    {Object.keys(
                                        scenario.permissions.whitelist
                                    ).map((email) => {
                                        const toUnshare =
                                            unshareScenarioMap[scenario.id][
                                                email
                                            ];
                                        return (
                                            <div
                                                key={email}
                                                className={styles.scenarioEntry}
                                            >
                                                <img
                                                    alt="checkbox"
                                                    className={styles.checkbox}
                                                    src={
                                                        !toUnshare
                                                            ? checkboxOn
                                                            : checkboxOff
                                                    }
                                                    onClick={() => {
                                                        this.toggleUnshare(
                                                            scenario,
                                                            email
                                                        );
                                                    }}
                                                />
                                                <div
                                                    className={
                                                        styles.scenarioName
                                                    }
                                                >
                                                    {email}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.modalFooter}>
                    <div
                        className={
                            isPassed
                                ? styles.proceedButton
                                : styles.proceedButtonDisabled
                        }
                        onClick={this.continueDowngrade}
                    >
                        Proceed
                    </div>
                    <div
                        className={styles.cancelButton}
                        onClick={() =>
                            this.setState({
                                currentDowngradeStage: "scenarios",
                            })
                        }
                    >
                        Back
                    </div>
                </div>
            </EventsModal>
        );
    };

    continueDowngrade = () => {
        const { userScenarios } = this.props;
        const {
            scenariosToArchive,
            currentDowngradeStage,
            proEventsToKeep,
            targetPlanId,
            plans,
        } = this.state;

        const scenarioLimit = getMaxScenarioCount(plans[targetPlanId]);
        const proEventLimit = getMaxProEvents(plans[targetPlanId]);
        const shareLimit = getMaxScenarioShares(plans[targetPlanId]);

        if (currentDowngradeStage === "info") {
            this.setState({
                currentDowngradeStage: "scenarios",
            });
        } else if (currentDowngradeStage === "scenarios") {
            const isOverScenarioLimit =
                userScenarios.length - scenariosToArchive.length >
                scenarioLimit;

            if (isOverScenarioLimit) {
                throwError(
                    "error",
                    "Over Limit",
                    `Please select no more than ${scenarioLimit} scenarios`
                );
            } else {
                const scenariosToKeep = userScenarios.filter((scenario) => {
                    return !scenariosToArchive.includes(scenario.id);
                });
                const scenariosToUnshare = scenariosToKeep.filter(
                    (scenario) => {
                        return (
                            scenario.permissions &&
                            Object.keys(scenario.permissions.whitelist).length >
                                shareLimit
                        );
                    }
                );

                const unshareScenarioMap = {};
                scenariosToUnshare.forEach((scenario) => {
                    unshareScenarioMap[scenario.id] = {};
                    Object.keys(scenario.permissions.whitelist).forEach(
                        (email) => {
                            unshareScenarioMap[scenario.id][email] = false;
                        }
                    );
                });

                this.setState({
                    currentDowngradeStage: "unshare",
                    scenariosToKeep,
                    scenariosToUnshare,
                    unshareScenarioMap,
                });
            }
        } else if (currentDowngradeStage === "unshare") {
            // todo
            this.setState({ currentDowngradeStage: "proNodes" });
        } else if (currentDowngradeStage === "proNodes") {
            const isOverLimit = proEventsToKeep.length > proEventLimit;
            if (isOverLimit) {
                throwError(
                    "error",
                    "Over Limit",
                    `Please select no more than ${proEventLimit} Pro Events`
                );
            } else {
                this.subscribePlan(targetPlanId, true);
            }
        }
    };

    completeDowngrade = (loggedInUser) => {
        const { scenariosToArchive } = this.state;

        // TODO - pro nodes, shared scenarios
        this.archiveScenarios(scenariosToArchive);
        this.completeUnshare();
        this.setState({
            showDowngradeModal: false,
            currentDowngradeStage: "scenarios",
        });
        this.props.getSubscription(loggedInUser, null);

        throwError("success", "Downgrade Complete");
    };

    completeUpgrade = (loggedInUser) => {
        const { userScenarios } = this.props;
        const { scenariosToRetrieve, targetPlanId, plans } = this.state;
        const scenarioLimit = getMaxScenarioCount(plans[targetPlanId]);

        if (userScenarios.length + scenariosToRetrieve.length > scenarioLimit) {
            throwError(
                "error",
                "Over Limit",
                `Please select no more than ${
                    scenarioLimit - userScenarios.length
                } events to retrieve`
            );
        } else {
            this.reactivateArchivedScenarios(scenariosToRetrieve);
            this.props.getSubscription(loggedInUser, null);
            this.setState({ showUpgradeModal: false });
            throwError("success", "Upgrade Complete");
        }
    };

    launchAddCard = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        this.props.launchAddCard(loggedInUser);
    };

    completeUnshare = () => {
        const { unshareScenarioMap } = this.state;
        const { userScenarios } = this.props;
        Object.keys(unshareScenarioMap).forEach((scenarioId) => {
            const scenario = userScenarios.find(
                (candidate) => candidate.id === scenarioId
            );
            const whitelist = scenario.permissions.whitelist;
            Object.keys(unshareScenarioMap[scenario.id]).forEach((email) => {
                if (unshareScenarioMap[scenario.id][email]) {
                    delete whitelist[email];
                }
            });
            const emails = Object.keys(whitelist);
            this.props.shareByEmail(emails, scenario.id, whitelist);
        });
    };

    archiveScenarios = (scenarioIds) => {
        const { userScenarios } = this.props;
        scenarioIds.forEach((scenarioId) => {
            const scenario = userScenarios.find(
                (candidate) => candidate.id === scenarioId
            );
            this.props.archiveScenario(scenario);
        });
    };

    reactivateArchivedScenarios = (scenarioIds) => {
        const { archivedScenarios } = this.props;
        scenarioIds.forEach((id) => {
            const scenario = archivedScenarios.find(
                (candidate) => candidate.id === id
            );
            this.props.reactivateArchivedScenario(scenario);
        });
    };

    updateProNodes = () => {
        // TODO - "pro nodes" are not implemented
    };

    changePlan = (e) => {
        const planId = e.target.id;
        const { plans } = this.state;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (
            getMaxScenarioCount(plans[planId]) <
            getMaxScenarioCount(loggedInUser && loggedInUser.plan)
        ) {
            this.launchDowngrade(e);
        } else {
            this.setState({ targetPlanId: planId, showUpgradeModal: true });
        }
    };

    showSignUpModal = () => {
        this.props.history.push("/home");
    };

    render() {
        const {
            personal,
            business,
            selectedOption,
            showDowngradeModal,
            showUpgradeModal,
            _accountData,
        } = this.state;

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        let plansArray;

        if (loggedInUser) {
            if (selectedOption === "personal") {
                plansArray = personal;
            } else {
                plansArray = business;
            }
        } else {
            plansArray = business;
            // plansArray = [
            //     {
            //         meta_data: {
            //             displayName: "Small Business",
            //             billingString: "Price per Month",
            //             content: [
            //                 "30 day free trial",
            //                 "Access to all Small Business Events and Templates",
            //                 "Quickbooks Integration",
            //                 "Share Account with up to 10 Users",
            //                 "50 Scenarios",
            //                 "Advanced Scenario Sharing",
            //                 "Starter SMB Support",
            //             ],
            //         },
            //         frequencyDisplay: true,
            //         price: 5500,
            //     },
            //     {
            //         meta_data: {
            //             displayName: "Agency Starter",
            //             billingString: "Price per Month",
            //             content: [
            //                 "Access to all Small Business Events and Templates",
            //                 "Quickbooks Integration",
            //                 "Up to 10 Users",
            //                 "Up to 50 Scenarios",
            //                 "Unlimited Threads; One Baseline Account",
            //                 "Advanced Scenario Sharing",
            //                 "Basic Support",
            //             ],
            //         },
            //         frequencyDisplay: true,
            //         price: 9500,
            //     },
            //     {
            //         meta_data: {
            //             displayName: "Agency Pro",
            //             billingString: "Price per Month",
            //             content: [
            //                 "Access to all Small Business Events and Templates",
            //                 "Quickbooks Integration",
            //                 "Up to 10 Users",
            //                 "Up to 50 Scenarios",
            //                 "Unlimited Threads; One Baseline Account",
            //                 "Advanced Scenario Sharing",
            //                 "Basic Support",
            //             ],
            //         },
            //         frequencyDisplay: true,
            //         price: 30000,
            //     },
            // ];
            // if (selectedOption === "personal") {
            //     plansArray = [
            //         {
            //             meta_data: {
            //                 displayName: "Forever Free Plan",
            //                 billingString: "No Cost - Ever",
            //                 content: [
            //                     "Access to all Basic Events and Templates",
            //                     "5 Scenario limit",
            //                     "Up to 60 Events per Scenario",
            //                     "Scenario Sharing with 2 other user per Scenario",
            //                 ],
            //             },
            //             frequencyDisplay: false,
            //             price: 0,
            //         },
            //         {
            //             meta_data: {
            //                 displayName: "Starter",
            //                 billingString: "Price per Month",
            //                 content: [
            //                     "Access to all Starter Events and Templates",
            //                     "Up to 15 Scenarios; One Baseline Account",
            //                     "Up to 90 Events per Scenario",
            //                     "3 PRO Events included",
            //                     "Add-on Pro Events for $2/month",
            //                     "Real-time notifications and Scenario Sharing with 5 other users",
            //                     "Scenario Alerts for $1/month per Scenario",
            //                 ],
            //             },
            //             frequencyDisplay: true,
            //             price: 450,
            //         },
            //         {
            //             meta_data: {
            //                 displayName: "Personal Pro",
            //                 billingString: "Price per Month",
            //                 content: [
            //                     "Access to all Pro Events including some of our Small Business Events",
            //                     "Unlimited Scenarios",
            //                     "Unlimited Threads; One Baseline Account",
            //                     "Unlimited Scenario Sharing",
            //                     "Support Access",
            //                 ],
            //             },
            //             frequencyDisplay: true,
            //             price: 2000,
            //         },
            //     ];
            // } else {
            //     plansArray = [
            //         {
            //             meta_data: {
            //                 displayName: "Small Business",
            //                 billingString: "Price per Month",
            //                 content: [
            //                     "30 day free trial",
            //                     "Access to all Small Business Events and Templates",
            //                     "Quickbooks Integration",
            //                     "Share Account with up to 10 Users",
            //                     "50 Scenarios",
            //                     "Advanced Scenario Sharing",
            //                     "Starter SMB Support",
            //                 ],
            //             },
            //             frequencyDisplay: true,
            //             price: 5500,
            //         },
            //         {
            //             meta_data: {
            //                 displayName: "Agency Starter",
            //                 billingString: "Price per Month",
            //                 content: [
            //                     "Access to all Small Business Events and Templates",
            //                     "Quickbooks Integration",
            //                     "Up to 10 Users",
            //                     "Up to 50 Scenarios",
            //                     "Unlimited Threads; One Baseline Account",
            //                     "Advanced Scenario Sharing",
            //                     "Basic Support",
            //                 ],
            //             },
            //             frequencyDisplay: true,
            //             price: 9500,
            //         },
            //         {
            //             meta_data: {
            //                 displayName: "Agency Pro",
            //                 billingString: "Price per Month",
            //                 content: [
            //                     "Access to all Small Business Events and Templates",
            //                     "Quickbooks Integration",
            //                     "Up to 10 Users",
            //                     "Up to 50 Scenarios",
            //                     "Unlimited Threads; One Baseline Account",
            //                     "Advanced Scenario Sharing",
            //                     "Basic Support",
            //                 ],
            //             },
            //             frequencyDisplay: true,
            //             price: 30000,
            //         },
            //     ];
            // }
        }

        return (
            <EventsModal
                onHandleClose={this.closeBillingContainer}
                pricing={true}
                edit={true}
            >
                <div id="BannerHead">
                    <a className={styles.brand} href={window.location.origin}>
                        what
                        <div id="Banner-If">if</div>
                        i.io
                    </a>
                </div>

                <div className={styles.header}>
                    {loggedInUser
                        ? "Upgrade whatifi today"
                        : "Browse our selection of plans"}
                </div>
                <div className={styles.subHeader}>
                    make bigger business decisions. better.{" "}
                    <span
                        onClick={this.onHandleDemo}
                        className={styles.demoButton}
                    >
                        Book a Demo
                    </span>
                </div>
                <div className={styles.toggleContainer}>
                    <div
                        id="business"
                        onClick={this.onHandleSelected}
                        className={
                            selectedOption === "business"
                                ? styles.toggleOptionActive
                                : styles.toggleOption
                        }
                    >
                        Business
                        {selectedOption === "business" && (
                            <div className={styles.line} />
                        )}
                    </div>
                    {/* {accountData && accountData.userRole === "agency" ? (
                        <div
                            id="business"
                            onClick={this.onHandleSelected}
                            className={
                                selectedOption === "business"
                                    ? styles.toggleOptionActive
                                    : styles.toggleOption
                            }
                        >
                            Business
                            {selectedOption === "business" && (
                            <div className={styles.line} />
                            )}
                        </div>
                    ) : (
                        <>
                            <div
                                id="personal"
                                onClick={this.onHandleSelected}
                                className={
                                    selectedOption === "personal"
                                        ? styles.toggleOptionActive
                                        : styles.toggleOption
                                }
                            >
                                Personal
                                {selectedOption === "personal" && (
                                    <div className={styles.line} />
                                )}
                            </div>
                            <div
                                id="business"
                                onClick={this.onHandleSelected}
                                className={
                                    selectedOption === "business"
                                        ? styles.toggleOptionActive
                                        : styles.toggleOption
                                }
                            >
                                Business
                                {selectedOption === "business" && (
                                    <div className={styles.line} />
                                )}
                            </div>
                        </>
                    )} */}
                </div>
                <div className={styles.mainContent}>
                    <div className={styles.mainContentContainer}>
                        <div className={styles.billingContainer}>
                            {plansArray.map((item, i) => {
                                let changeVerb;
                                const showFrequency = item.frequencyDisplay;
                                if (loggedInUser) {
                                    const eventDiff =
                                        getMaxScenarioCount(item) -
                                        getMaxScenarioCount(loggedInUser.plan);
                                    changeVerb =
                                        eventDiff < 0
                                            ? "Downgrade"
                                            : eventDiff > 0
                                            ? "Upgrade"
                                            : "Change Plan";
                                }
                                if (item.id === "agency_01")
                                    changeVerb = "Coming Soon";
                                const planId =
                                    loggedInUser &&
                                    loggedInUser.plan &&
                                    loggedInUser.plan.id;

                                let planName = getPlanName(item);

                                let price = Number(item.price) / 100;
                                if (Math.floor(price) !== price) {
                                    price = price.toFixed(2);
                                }

                                return (
                                    <div
                                        key={i}
                                        className={styles.billingOption}
                                    >
                                        {/* {selectedOption === "business" && (
                                    <img
                                        alt="alt"
                                        src={comingSoonSvg}
                                        className={styles.clipPath}
                                    />
                                )} */}
                                        <div className={styles.planName}>
                                            {planName}
                                        </div>
                                        <div className={styles.costContainer}>
                                            <div className={styles.cost}>
                                                {"$"}
                                                {price}
                                            </div>
                                            {showFrequency && (
                                                <div
                                                    className={
                                                        styles.frequencyDisplay
                                                    }
                                                >
                                                    USD
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.frequency}>
                                            {getPlanBillingString(item)}
                                        </div>
                                        <div
                                            id={item.id}
                                            onClick={
                                                loggedInUser
                                                    ? planId !== item.id &&
                                                      item.id !== "agency_01"
                                                        ? this.managePortal
                                                        : null
                                                    : this.showSignUpModal
                                            }
                                            className={
                                                loggedInUser
                                                    ? planId !== item.id &&
                                                      item.id !== "agency_01"
                                                        ? styles.subscribeButton
                                                        : styles.selectedSubscribe
                                                    : styles.subscribeButton
                                            }
                                        >
                                            {planId
                                                ? planId !== item.id
                                                    ? changeVerb
                                                    : "Current Plan"
                                                : "Create Account"}
                                        </div>

                                        <div
                                            className={styles.contentContainer}
                                        >
                                            {item.meta_data.content.map(
                                                (content, i) => {
                                                    return (
                                                        <div
                                                            className={
                                                                styles.content
                                                            }
                                                            key={i}
                                                        >
                                                            <img
                                                                alt="alt"
                                                                className={
                                                                    styles.check
                                                                }
                                                                src={
                                                                    checkMarkSvg
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.contentText
                                                                }
                                                            >
                                                                {content}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <section className={styles.enterprisePlansContainer}>
                            <h3 className={styles.enterprisePlansHeader}>
                                Enterprise Plans
                            </h3>
                            <a
                                className={styles.enterprisePlansLink}
                                href="mailto:info@whatifi.io"
                            >
                                Contact us for details
                            </a>
                        </section>
                    </div>
                </div>
                {showDowngradeModal && this.getDowngradeModal()}
                {showUpgradeModal && this.getUpgradeModal()}
            </EventsModal>
        );
    }
}

import { useState, ChangeEvent } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import ProjectsInputView from "./ProjectsView";
import { getDefaultName } from "helpers";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { projectsObject } from "Components/Registry/Projects";
import projectsInputsHandler from "../OnInputChangeHandlers/projectsInputsHandler";
import {
    EntityCustomAccount,
    getAccountsToDelete,
    getPaths,
    handleCustomAccountStructures,
} from "helpers/accounts";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import * as uuid from "uuid";

export default function ProjectsInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(projectsObject);
            _eventData.name = getDefaultName(
                projectsObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, projectsObject);

    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "state"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = projectsInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmitValuesCustom = (newEntitiesMap, passedCheck) => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const onHandleClickSubmitOrUpdate = () => {
        const accountsToDelete = getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            passedCheck,
            entitiesMap,
            createProjectsAccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const passedCheck =
        !!eventData.name && projectsObject.checkInput(entitiesMap);

    return (
        <ProjectsInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleClickSubmitOrUpdate={onHandleClickSubmitOrUpdate}
            handleOnChange={handleOnChange}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
        />
    );
}

export const createProjectsAccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const neededAccountId = uuid.v4();
    const utilizedAccountId = uuid.v4();
    const adjustedAccountId = uuid.v4();

    const accountStructures: EntityCustomAccount[] = [
        {
            Name: entity.name,
            Type: "display",
            Display: "Employees",
            Root: {
                topLevel: "852b5a0b-2a32-4817-867c-cca92295141b",
                topLevelChildren: [neededAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Needed - ", neededAccountId],
                    topLevelChildren: [],
                },
            ],
        },
        {
            Name: entity.name,
            Type: "display",
            Display: "Employees",
            Root: {
                topLevel: "8762235d-676a-4ef5-be5e-9c5a27b301da",
                topLevelChildren: [utilizedAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Utilized - ", utilizedAccountId],
                    topLevelChildren: [],
                },
            ],
        },
        {
            Name: entity.name,
            Type: "display",
            Display: "Employees",
            Root: {
                topLevel: "3ebf3eb4-6cdf-4889-8796-435cb9bf0939",
                topLevelChildren: [adjustedAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Adjusted - ", adjustedAccountId],
                    topLevelChildren: [],
                },
            ],
        },
    ];

    data.accountStructure = accountStructures;
    data.accountIdsPaths = getPaths(accountStructures);

    data.neededAccountId = neededAccountId;
    data.utilizedAccountId = utilizedAccountId;
    data.adjustedAccountId = adjustedAccountId;

    data.accountIds = [
        "852b5a0b-2a32-4817-867c-cca92295141b",
        "8762235d-676a-4ef5-be5e-9c5a27b301da",
        "3ebf3eb4-6cdf-4889-8796-435cb9bf0939",
        neededAccountId,
        utilizedAccountId,
        adjustedAccountId,
    ];

    currentEntityObject.data = data;

    return currentEntityObject;
};

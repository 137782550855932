import styles from "./confirmChangesModal.module.css";
import { ReactComponent as WarningSvg } from "Assets/warningSvg.svg";

export const ConfirmChangesModal = ({ handleClose, handleSubmit }) => {
    const handleConfirm = () => {
        handleSubmit();
        handleClose();
    };

    return (
        <div className={styles.modalBackground}>
            <div className={styles.modalContainer}>
                <div className={styles.upperContent}>
                    <figure className={styles.figure}>
                        <WarningSvg className={styles.warningIcon} />
                    </figure>
                    <div className={styles.copyContainer}>
                        <h2 className={styles.heading}>Warning</h2>
                        <p className={styles.infoCopy}>
                            You are about to update your account structure. This
                            action may cause events inside your canvas to
                            require changes.
                        </p>
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <button
                        className={`${styles.button} ${styles.buttonHighlight}`}
                        onClick={() => handleConfirm()}
                    >
                        Confirm Changes
                    </button>
                    <button
                        className={styles.button}
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

import { color, Triangle } from "@amcharts/amcharts4/core";

export const createTodayRange = (dateAxis) => {
    const event = dateAxis.axisRanges.create();
    event.date = new Date();
    event.bullet = new Triangle();
    event.bullet.width = 15;
    event.bullet.height = 11;
    event.bullet.fill = color("#c00");
    event.bullet.horizontalCenter = "middle";
    event.bullet.verticalCenter = "middle";
    return event;
};

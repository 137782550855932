import styles from "./index.module.css";
import {
    SelectDropDown,
    ModalRow,
    ModalRowHalf,
    BasicTextInput,
    MuiCalendar,
    BasicHeading,
    CheckBox,
} from "../InputContainer/Components";

export const Custom = ({
    edit,
    onHandleInput,
    onHandleLeaveFocus,
    onHandleStartDateSelection,
    onHandleDate,
    startDate,
    name,
    range,
    inflation,
    description,
    isAgency,
    agencyClients,
    scenarioId,
    clientId,
    forcedClientId,
    subtitle,
    scenarioDateSelection,
    explainerVideoLink,
}) => {

    let selectedClient;
    if (forcedClientId) {
        selectedClient =
            agencyClients &&
            agencyClients.find((client) => {
                return client.id === forcedClientId;
            });
    } else if (clientId === undefined) {
        selectedClient =
            agencyClients &&
            agencyClients.find((client) => {
                return (
                    client.scenarios && client.scenarios.includes(scenarioId)
                );
            });
    } else {
        selectedClient =
            agencyClients &&
            agencyClients.find((client) => {
                return client.id === clientId;
            });
    }
    const selectedClientName =
        selectedClient &&
        selectedClient.clientdata.name +
            ` (${selectedClient.clientdata.email})`;
    const clientOptions = agencyClients
        .map((client) => {
            return (
                client &&
                client.clientdata &&
                client.clientdata.name +
                    (client.clientdata.email
                        ? ` (${client.clientdata.email})`
                        : "")
            );
        })
        .filter((client) => {
            return client; // null check
        });



    return (
        <div className={styles.customContainer}>
            <ModalRow twoInputs={isAgency}>
                <ModalRowHalf>
                    <BasicTextInput 
                        value={name}
                        inputLabel="Scenario Name"
                        onChangeInput={onHandleInput}
                        id="name"
                        required={true}
                    />
                </ModalRowHalf>
                {isAgency && <ModalRowHalf>
                    <SelectDropDown
                        fullWidth={true}
                        onChangeInput={onHandleInput}
                        value={selectedClientName}
                        options={clientOptions}
                        className={styles.selectClient}
                        id="selectClient"
                        label="Select Client"
                        disabled={forcedClientId}
                        required={true}
                    />
                </ModalRowHalf>}
            </ModalRow>
            <ModalRow>
                <BasicTextInput 
                    value={subtitle}
                    inputLabel="Scenario Subtitle (optional)"
                    onChangeInput={onHandleInput}
                    id="subtitle"
                />
            </ModalRow>
            <ModalRow twoInputs cssOverrides={{margin: "5px 0px"}}>
                <ModalRowHalf>
                    <div className={styles.selectionContainer}>
                        <div className={styles.sectionHeaderContainer}>
                            <h2 className={styles.sectionHeader}>Scenario Start Date</h2>
                            <span className={styles.sectionHeaderRequiredStar}>*</span>
                        </div>
                        <div className={styles.sectionWrapper}>
                            <div className={styles.checkboxContainer}>
                                <CheckBox
                                    label="Always Current Date"
                                    toggleIsActive={() => onHandleStartDateSelection("currentDate")}
                                    id="currentDate"
                                    isActive={scenarioDateSelection === "currentDate"}
                                />
                            </div>
                            <div className={styles.checkboxContainer}>
                                <CheckBox
                                    label="Always Start of Current Month"
                                    toggleIsActive={() => onHandleStartDateSelection("monthStart")}
                                    id="monthStart"
                                    isActive={scenarioDateSelection === "monthStart"}
                                />
                            </div>
                            <ModalRow cssOverrides={{margin: "0px"}}>
                                <MuiCalendar id="startDate" value={startDate} onChangeInput={onHandleDate} label="Set Custom Start" />
                            </ModalRow>
                        </div>
                    </div>
                </ModalRowHalf>
                <ModalRowHalf>
                    <div className={styles.selectionContainer}>
                        <div className={styles.sectionHeaderContainer}>
                            <h2 className={styles.sectionHeader}>Scenario Range</h2>
                        </div>
                        <div className={styles.sectionWrapperNoBorder}>
                            <div className={styles.rangeWrapper}>
                                <input
                                    type="range"
                                    min="1"
                                    max="60"
                                    onChange={(e) => onHandleInput(e, "range")}
                                    value={range}
                                    className={styles.createRange}
                                    id="range"
                                />
                                <div className={styles.rangeContainer}>
                                    <input
                                        value={range}
                                        onChange={(e) => onHandleInput(e, "range")}
                                        onBlur={(e) => onHandleLeaveFocus(e, "range")}
                                        id="range"
                                        type="number"
                                        className={styles.rangeValue}
                                    />{" "}
                                    yr
                                </div>
                            </div>
                            <div className={styles.inflationContainer}>
                                <BasicHeading copy="Inflation Rate" />
                                <div className={styles.rangeContainerInfation}>
                                    <input
                                        value={Number(inflation)}
                                        onChange={(e) => onHandleInput(e, "inflation")}
                                        id="range"
                                        type="number"
                                        className={styles.rangeValue}
                                    />{" "}
                                    %
                                </div>
                            </div>
                            {edit && <ModalRow cssOverrides={{margin: "0"}}>
                                <BasicTextInput 
                                    value={explainerVideoLink}
                                    inputLabel="Explainer URL (optional)"
                                    onChangeInput={(e) => onHandleInput(e, "explainerVideoLink")}
                                    id="explainerVideoLink"
                                />
                            </ModalRow>}
                        </div>
                    </div>
                </ModalRowHalf>
            </ModalRow>
            <ModalRow cssOverrides={{margin: "15px 0px 5px"}}>
                <BasicTextInput 
                    value={description}
                    inputLabel="Description (optional)"
                    onChangeInput={onHandleInput}
                    id="description"
                    multiline={true}
                    rows="3"
                />
            </ModalRow>
        </div>
    );
};

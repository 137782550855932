import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import styles from "./index.module.css";
import type Event from "Events/_event";
import { FilterableItem } from "./partials/filterableItem";
import expandArrow from "Assets/scenarioIcons/expand.svg";
import expandArrowOrange from "Assets/scenarioIcons/expandOrange.svg";
import { useState, useEffect } from "react";

export const SelectionFilter = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const {
        selectedEventIds,
        selectedEntityIds,
        selectedEventsDataArray,
        selectedEntitiesDataArray,
    } = useAppSelector((state) => {
        const manager = state?.scenario?.manager;
        const selectedEventIds = state?.scenario?.selectedEvents ?? {};
        const selectedEventIdsMap = selectedEventIds
            ? Object.keys(selectedEventIds)
            : [];
        const selectedEventsDataArray: Event[] = selectedEventIdsMap?.map(
            (eventId) => manager?._findEvent(eventId)
        );

        const selectedEntityIds = state?.scenario?.selectedEntities ?? {};
        const selectedEntityIdsMap = selectedEntityIds
            ? Object.keys(selectedEntityIds)
            : [];
        const selectedEntitiesData = getRelevantEntities(selectedEntityIdsMap);
        const selectedEntitiesDataArray = Object.values(selectedEntitiesData);
        return {
            selectedEventIds,
            selectedEntityIds,
            selectedEventsDataArray,
            selectedEntitiesDataArray,
        };
    }, shallowEqual);

    useEffect(() => {
        if (
            selectedEventsDataArray?.length > 0 ||
            selectedEntitiesDataArray?.length > 0
        )
            setMenuOpen(true);
    }, [selectedEventsDataArray?.length, selectedEntitiesDataArray?.length]);

    const allValidData = useAppSelector((state) => {
        const allNodes: Event[] = state?.scenario?.loadedScenario?.data?.nodes;
        const allValidData: any[] = [];
        allNodes.forEach((node) => {
            const validData: any = {
                event: node,
                entities: [],
                onlyEntity: false,
            };
            const entities = getRelevantEntities(node.entities);
            Object.values(entities)?.forEach((entity) => {
                if (selectedEntityIds?.[entity.id]) {
                    validData.entities.push(entity);
                }
            });
            if (!selectedEventIds?.[node.id] && validData.entities.length > 0) {
                validData.onlyEntity = true;
            }
            if (selectedEventIds?.[node.id] || validData.entities.length > 0) {
                allValidData.push(validData);
            }
        });
        return allValidData;
    }, shallowEqual);

    const filteredEventAndEntityData = useAppSelector(
        (state) => state?.scenario?.filteredEventAndEntityData ?? {}
    );

    const renderChildren = (allValidData) => {
        if (allValidData?.length > 0) {
            return (
                <>
                    {allValidData &&
                        allValidData.map((data, i) => (
                            <FilterableItem
                                key={i}
                                data={data}
                                filteredEventAndEntityData={
                                    filteredEventAndEntityData
                                }
                            />
                        ))}
                </>
            );
        } else {
            return (
                <h4 className={styles.helperText}>
                    No Events or Records selected.
                </h4>
            );
        }
    };

    return (
        <div
            className={`${
                menuOpen
                    ? `${styles.container} ${styles.containerExpanded}`
                    : `${styles.container}`
            }`}
        >
            <div className={styles.innerContainer}>
                <img
                    className={`${
                        menuOpen
                            ? `${styles.expandArrow} ${styles.expandArrowOpen}`
                            : `${styles.expandArrow}`
                    }`}
                    onClick={() => setMenuOpen(!menuOpen)}
                    src={
                        allValidData?.length > 0
                            ? expandArrowOrange
                            : expandArrow
                    }
                    alt="expand arrow"
                />
                <h3 className={styles.header}>Graph-able Items</h3>
                <div className={styles.itemsContainer}>
                    {renderChildren(allValidData)}
                </div>
            </div>
        </div>
    );
};

import React from "react";

// Import Event Card and Event Input
import CondoFeeCard from "../Card/condoFeeCard";
import CondoFeeInput from "../InputContainer/CondoFeeInput";

import expenseConnectionImage from "../../Assets/_optionDescriptionIcons/Me, Income, Condo Fee.png";

// Import Coloured PNGs
// TODO - Need blue, white, and grey assets for Condo Fee event (currently only have black)
import condoFeeBlack from "../../Assets/_eventNodeTypes/condoFeeNode-black.png";
import condoFeeBlue from "../../Assets/_eventNodeTypes/condoFeeNode-black.png";
import condoFeeWhite from "../../Assets/_eventNodeTypes/condoFeeNode-black.png";
import condoFeeGrey from "../../Assets/_eventNodeTypes/condoFeeNode-black.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

import { valueDoesNotExist } from "../../helpers/index";

export const condoFeeObject = {
    constant: function () {
        return CondoFee_Constant();
    },

    checkInput: function (state) {
        return CondoFee_CheckInput(state);
    },

    svg: function () {
        return CondoFee_Svg();
    },

    eventNodeMenu: function (focus) {
        return CondoFee_EventNodeMenu(focus);
    },

    indexText: function () {
        return CondoFee_IndexText();
    },

    inputInfoPassing: function (minProps, libraryProps) {
        return CondoFee_InputComponentInfoPassing(minProps, libraryProps);
    },

    optionDescription: function () {
        return CondoFee_OptionDescription();
    },

    colouredPngs: function () {
        return CondoFee_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return CondoFee_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const CondoFee_CheckInput = (state) => {
    if (state.cost === undefined || state.cost === "") return false;
    if (
        valueDoesNotExist(state.value) ||
        isNaN(state.value) ||
        !isFinite(state.value)
    )
        return false;
    if (!state.start) return false;
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (state.applyInflation && valueDoesNotExist(state.inflationRate))
        return false;
    if (state.expenseType === "amortized") {
        if (
            !state.amortizationPeriod ||
            state.amortizationPeriod < 0 ||
            isNaN(state.amortizationPeriod) ||
            !isFinite(state.amortizationPeriod)
        )
            return false;
        if (!state.amortizedEnd) return false;
    }
    return true;
};

// EFFECTS: Returns constant for event
const CondoFee_Constant = () => {
    return "Condo Fee";
};

// EFFECTS: Returns black png / svg for event
const CondoFee_Svg = () => {
    return condoFeeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const CondoFee_EventNodeMenu = (focus, inflation) => {
    return <CondoFeeCard data={focus} inflation={inflation} />;
};

// EFFECTS: Returns the Index Text for event
const CondoFee_IndexText = () => {
    return "Select an input field to learn more about your expense calculations.";
};

// EFFECTS: Returns all icons for event ??
const CondoFee_Icons = () => {
    let pngs = new Map([
        ["black", condoFeeBlack],
        ["blue", condoFeeBlue],
        ["white", condoFeeWhite],
        ["grey", condoFeeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const CondoFee_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const CondoFee_InputComponentInfoPassing = (minProps, libraryProps) => {
    return <CondoFeeInput {...minProps} {...libraryProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, expenses for event ??
const CondoFee_OptionDescription = () => {
    const tags = ["Option"];
    const type = condoFeeObject.constant();
    const description =
        "Fees are a part of every scenario. Including them leads to a more well thought out and realistic scenario.";
    const usedForText =
        "Use a Condo Fee node to generate a negative cashflow on a recurring basis.";
    const connection = "";
    const connectionImage = expenseConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const CondoFee_SideModalImage = () => {
    return sideImage;
};

import Konva from "konva";
import type { AnimationConfig } from "reducers/typesSchema/eventsTemplatesSchema";
import { useContext, useMemo } from "react";
import { Group } from "react-konva";
import { animated, useSpring } from "@react-spring/konva";
import {
    EventNodeActiveContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeLockedContext,
} from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/contexts/EventNodeStateContexts";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
// import { BaseMenuContainerWidthContext } from "../contexts/BaseMenuContainerWidthContext";
import { MenuIdContext } from "../contexts/MenuIdContext";
import { useMenuTemplate } from "../hooks/useMenuTemplate";
import type { MenuTemplate } from "reducers/typesSchema/menusTemplatesSchema";

interface MenusContainerProps {
    canvasStylesFields: string[];
}

export function MenusContainer({ canvasStylesFields }: MenusContainerProps) {
    const menuId = useContext(MenuIdContext);
    const active = useContext(EventNodeActiveContext);
    const focused = useContext(EventNodeFocusedContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const error = useContext(EventNodeErrorContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);
    // const baseMenuContainerWidth = useContext(BaseMenuContainerWidthContext);
    const menuTemplate = useMenuTemplate(menuId) as MenuTemplate;

    const rectStyles = useMemo(() => {
        let _rectStyles: Partial<Konva.RectConfig> = {};
        canvasStylesFields.forEach((canvasStyleField) => {
            const _styles =
                menuTemplate?.canvasStyles?.[canvasStyleField]?.styles;
            const _default = _styles?.default;
            const _active = active ? _styles?.active : {};
            const _focused = focused ? _styles?.focused : {};
            const _locked = locked ? _styles?.locked : {};
            const _bypassed = bypassed ? _styles?.bypassed : {};
            const _error = error ? _styles?.error : {};
            const _zoomCategory = _styles?.[zoomCategory];

            _rectStyles = {
                ..._rectStyles,
                ..._default,
                ..._active,
                ..._focused,
                ..._locked,
                ..._bypassed,
                ..._error,
                ..._zoomCategory,
            };
        });

        const width: number = _rectStyles?.width ?? 0;

        const _finalStyles = {
            ..._rectStyles,
            width: width,
            x: -(width / 2),
        };

        return _finalStyles;
    }, [
        active,
        bypassed,
        canvasStylesFields,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles,
        zoomCategory,
    ]);

    const animationConfig = useMemo(() => {
        let _animationConfig: Partial<AnimationConfig> = {};
        canvasStylesFields.forEach((canvasStyleField) => {
            const _animationOverride =
                menuTemplate?.canvasStyles?.[canvasStyleField]
                    ?.animationOverride;
            const _default = _animationOverride?.default;
            const _active = active ? _animationOverride?.active : {};
            const _focused = focused ? _animationOverride?.focused : {};
            const _locked = locked ? _animationOverride?.locked : {};
            const _bypassed = bypassed ? _animationOverride?.bypassed : {};
            const _error = error ? _animationOverride?.error : {};
            const _zoomCategory = _animationOverride?.[zoomCategory];

            _animationConfig = {
                ..._animationConfig,
                ..._default,
                ..._active,
                ..._focused,
                ..._locked,
                ..._bypassed,
                ..._error,
                ..._zoomCategory,
            };
        });

        return _animationConfig;
    }, [
        active,
        bypassed,
        canvasStylesFields,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles,
        zoomCategory,
    ]);

    const animatedRectStyles = useSpring<Konva.RectConfig>({
        to: rectStyles,
        config: animationConfig,
    });

    return (
        <Group>
            {/* @ts-ignore - Seems to be a react spring type error */}
            <animated.Rect
                {...animatedRectStyles}
                y={-((rectStyles?.height ?? 0) / 2)}
            />
        </Group>
    );
}

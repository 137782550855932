// import type {ChangeEvent} from "react";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { camelCase } from "lodash";
import getGeolocation from "countrycitystatejson";

/*
 * Helper function that handles Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const businessInputsHandler = (
    value: string,
    id: "country" | "entityName" | "state",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "country":
            const shortName = value;
            const state = getGeolocation.getStatesByShort(shortName);
            data.stateList = state;
            data.state = null;
            data.country = shortName;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            const finalString = camelCase(value);
            data.tag = `@${finalString}`;
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "state":
            data.state = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

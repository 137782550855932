// @ts-nocheck

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    SingleEntity,
    BasicHeading,
    MuiCalendar,
    SelectDropDown,
    Rate,
    GenericButton,
} from "../Components";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { setShowCellRowModal } from "actions/modalActions";

export default function AccountModifierInputView({
    entitiesMap,
    currentEntity,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleDateSelection,
    updateAccount,
    createOverride,
    updateOverride,
}) {
    const entityData = entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={accountModifierObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={accountModifierObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <BasicHeading
                        copy="Select Account and Modification"
                        margin="15px 0"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={entityData?.accountName ?? ""}
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entityData?.action ?? ""}
                            options={[
                                "Add",
                                "Multiply",
                                "constrain",
                                "override",
                            ]}
                            className="select-dropdown"
                            id="action"
                            label="Select Modifier Type"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            views={["year", "month"]}
                            inputFormat="YYYY-MM"
                            id="startDate"
                            value={
                                entitiesMap?.[currentEntity]?.startDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.startDate
                            }
                            required={true}
                            onChangeInput={handleDateSelection}
                            label="Start Date"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <MuiCalendar
                            views={["year", "month"]}
                            inputFormat="YYYY-MM"
                            id="endDate"
                            value={
                                entitiesMap?.[currentEntity]?.endDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.endDate
                            }
                            onChangeInput={handleDateSelection}
                            label="End Date"
                            required={entityData?.revertValue}
                            helperText={
                                entityData?.customEffectPeriod &&
                                !entityData?.revertValue
                                    ? "An end date is optional"
                                    : ""
                            }
                        />
                    </ModalRowHalf>
                </ModalRow>
                {entityData?.action && (
                    <ModalRow>
                        <BasicHeading copy="Modifier Values" margin="15px 0" />
                    </ModalRow>
                )}
                {entityData?.action && (
                    <ModalRow>
                        {entityData?.action === "constrain" ? (
                            <ModalRow twoInputs>
                                <ModalRowHalf>
                                    <Rate
                                        label="Min Value"
                                        onChangeInput={handleOnChange}
                                        value={entityData?.minValue ?? ""}
                                        id="minValue"
                                        className="mlsInput"
                                        sign={false}
                                        required={false}
                                        helperText={"At least one is required"}
                                    />
                                </ModalRowHalf>
                                <ModalRowHalf>
                                    <Rate
                                        label="Max Value"
                                        onChangeInput={handleOnChange}
                                        value={entityData?.maxValue ?? ""}
                                        id="maxValue"
                                        className="mlsInput"
                                        sign={false}
                                        required={false}
                                        helperText={"At least one is required"}
                                    />
                                </ModalRowHalf>
                            </ModalRow>
                        ) : entityData?.action === "override" ? (
                            <ModalRow twoInputs>
                                <GenericButton
                                    label={
                                        entityData?.modsCreated?.length
                                            ? "Update"
                                            : "Create"
                                    }
                                    disabled={
                                        !entitiesMap?.[currentEntity]?.startDate
                                    }
                                    onClick={() => {
                                        setShowCellRowModal({
                                            show: true,
                                            type: "override",
                                            field: "value",
                                            entityData:
                                                entitiesMap?.[currentEntity],
                                            ledgerAccount:
                                                entityData?.accountName ?? "",
                                            createOverride: createOverride,
                                        });
                                    }}
                                />
                                <GenericButton
                                    label="Delete"
                                    disabled={!entityData?.modsCreated?.length}
                                    onClick={() => {
                                        if (entityData?.modsCreated?.length) {
                                            entityData.modsCreated = [];
                                            updateOverride();
                                        }
                                    }}
                                />
                            </ModalRow>
                        ) : (
                            <Rate
                                label="Modifier Amount"
                                onChangeInput={handleOnChange}
                                value={entityData?.value ?? ""}
                                id="value"
                                className="mlsInput"
                                sign={false}
                                required={true}
                            />
                        )}
                    </ModalRow>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

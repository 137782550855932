// Default Imports
import React from "react";

// Import Income Card and Income Input
import Student_incomeCard from "../Card/student_incomeCard.js";
import Student_IncomeInput from "../InputContainer/Student_IncomeInput.js";

// Import Coloured PNGs
import incomeBlack from "../../Assets/_eventNodeTypes/income-black.png";
import incomeBlue from "../../Assets/_eventNodeTypes/income-blue.png";
import incomeWhite from "../../Assets/_eventNodeTypes/income-white.png";
import incomeGrey from "../../Assets/_eventNodeTypes/income-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

import hourlyIncomeConnection from "../../Assets/_optionDescriptionIcons/Hourly Income, Income Tax, Expense.png";

// Notes
// To call an object with no parameters, can simply use
//  - incomeObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - incomeObject.checkInput.call(incomeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Income
export const Student_IncomeObject = {
    constant: function () {
        return Income_Constant();
    },

    checkInput: function (state) {
        return Income_CheckInput(state);
    },

    svg: function () {
        return Income_Svg();
    },

    eventNodeMenu: function (focus) {
        return Income_EventNodeMenu(focus);
    },

    indexText: function () {
        return Income_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Income_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Income_OptionDescription();
    },

    colouredPngs: function () {
        return Income_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Income_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Income_CheckInput = (state) => {
    if (!state.start) return false;
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (!state.personId) return false;
    if (!state.wage) return false;
    if (!state.hours) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const Income_Constant = () => {
    return "Hourly Income";
};

// EFFECTS: Returns svg for event
const Income_Svg = () => {
    return incomeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Income_EventNodeMenu = (focus) => {
    return <Student_incomeCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Income_IndexText = () => {
    return "A part-time hourly paid job is a great way for students to make some spare income in their downtime.";
};

// EFFECTS: Returns all icons for event ??
const Income_Icons = () => {
    let pngs = new Map([
        ["black", incomeBlack],
        ["blue", incomeBlue],
        ["white", incomeWhite],
        ["grey", incomeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Income_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Income_InputComponentInfoPassing = (minProps) => {
    return <Student_IncomeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Income_OptionDescription = () => {
    const tags = ["Option"];
    const type = Student_IncomeObject.constant();
    const description =
        "Use the Hourly Income Event to estimate your income using your average hours per week.";
    const usedForText =
        "The Hourly Income Event allows you to gauge side income from part-time jobs.";
    const connection = "";
    const connectionImage = hourlyIncomeConnection;
    const learnMoreLink =
        "https://www.investopedia.com/articles/personal-finance/031115/salary-vs-hourly-how-benefits-laws-differ.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

export default Student_IncomeObject;

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Income_SideModalImage = () => {
    return sideImage;
};

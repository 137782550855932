import {
    useState,
    ChangeEvent,
    useRef,
    MutableRefObject,
    useContext,
    useEffect,
} from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { getDefaultName } from "helpers";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import UtilizationInputView from "./UtilizationView";
import { utilizationObject } from "Components/Registry/Utilization";
import utilizationInputsHandler from "../OnInputChangeHandlers/utilizationInputsHandler";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { resourceObject } from "Components/Registry/Resource";

export default function UtilizationInput({ line, focus, edit, editData }) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...useAppSelector(
            (state) => state?.scenario?.loadedScenario?.dependency_map
        ),
    });

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const propsObject = { manager, line, focus, eventId };

    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(utilizationObject);
            _eventData.name = getDefaultName(
                utilizationObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        utilizationObject,
        dependencyMap.current
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const getResources = () => {
        const resources = {};

        getPresentableDependencies(
            resources,
            resourceObject.constant(),
            propsObject,
            true
        );

        return resources;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            const newCurrentEntity = { ...newEntitiesMap[currentEntity] };
            const newData = { ...newCurrentEntity.data };

            const resources = getResources();
            newData.resources = resources;

            newCurrentEntity.data = newData;
            newEntitiesMap[currentEntity] = newCurrentEntity;
            return newEntitiesMap;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "resource" | "value"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = utilizationInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const updateAccount = (accountType, id) => {
        const newEntitiesMap = utilizationInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current,
            accountType
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck =
        !!eventData.name && utilizationObject.checkInput(entitiesMap);

    return (
        <UtilizationInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            handleOnChange={handleOnChange}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            updateAccount={updateAccount}
        />
    );
}

import { CSVBoxButton } from "@csvbox/react";
import { CsvImportButton } from "../Components";
import swal from "sweetalert";

import { accountId } from "actions/scenario";
import { setShowAccountMappingModal } from "actions/accountMappingActions";
import { parseDate, parseValue } from "helpers/csvImport";

import { AccountMappingType } from "reducers/typesSchema/accountMappingsSchema";

const csvboxDynamicColumns = [
    { column_name: "name", display_label: "Name", required: true },
];

export default function ImportAccount({
    entitiesMap,
    currentEntity,
    containsData,
    startEventCreation,
}) {
    return (
        <CSVBoxButton
            licenseKey={process.env.REACT_APP_CSVBOX_LICENSE_KEY ?? ""}
            user={{
                user_id: accountId(),
            }}
            dynamicColumns={csvboxDynamicColumns}
            onImport={(result, data) => {
                if (result) {
                    const filteredRows = data.rows.filter((row) => {
                        return (
                            "_unmapped_data" in row &&
                            containsData(row["_unmapped_data"])
                        );
                    });

                    const newRowsMap = {};

                    filteredRows.forEach((row) => {
                        const dynamic = row._dynamic_data;
                        const newRow = {
                            name: dynamic.name,
                            data: {},
                        };

                        for (const [key, val] of Object.entries(
                            row["_unmapped_data"]
                        )) {
                            const parsedDate = parseDate(key);
                            const parsedValue = parseValue(val);

                            if (parsedDate && parsedValue) {
                                newRow.data[parsedDate] = parsedValue;
                            } else if (!parsedDate) {
                                swal({
                                    title: "Error",
                                    text: `Date ${parsedDate} is invalid`,
                                });
                            } else if (!parsedValue) {
                                swal({
                                    title: "Error",
                                    text: `Value ${parsedValue} is invalid`,
                                });
                            }
                        }

                        newRowsMap[dynamic.name] = newRow;
                    });

                    setShowAccountMappingModal({
                        show: true,
                        entity: entitiesMap[currentEntity],
                        accountMappingData: newRowsMap,
                        accountMappingType: AccountMappingType.Csvbox,
                        create: startEventCreation,
                    });
                }
            }}
            render={(launch, isLoading) => {
                return (
                    <>
                        {!isLoading && <CsvImportButton onClick={launch} />}
                        {isLoading && <div>Readying Importer...</div>}
                    </>
                );
            }}
        >
            Import
        </CSVBoxButton>
    );
}

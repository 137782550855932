import styles from "./accountCardModal.module.css";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import Switch from "react-switch";

import { useState, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import { closeAccountCardModal } from "actions/canvasCardModalsActions";
import { createCard, updateCard, deleteCard } from "actions/canvasCardActions";
import { accountCardType } from "helpers/canvasCardTypeUUIDtoName";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { v4 as uuid } from "uuid";

const StyledButton = styled(Button)<ButtonProps>(() => ({
    color: "#FFFFFF",
    textTransform: "capitalize",
    backgroundColor: "#F8B46A",
    "&:hover": {
        backgroundColor: "rgba(248, 179, 106, 0.75)",
    },
}));

const InvertedStyledButton = styled(Button)<ButtonProps>(() => ({
    color: "#F8B46A",
    textTransform: "capitalize",
    backgroundColor: "#FFFFFF",
    "&:hover": {
        color: "rgba(248, 179, 106, 0.75)",
    },
}));

export const AccountCardModal = () => {
    const dispatch = useAppDispatch();

    const { existingCardData, edit } = useAppSelector((state) => {
        const cardId = state?.canvasCardModals?.cardId ?? "";
        const cardData = state?.canvasCards?.[cardId];

        return {
            existingCardData: cardData ?? {},
            edit: !!cardId,
        };
    }, shallowEqual);

    const { loadedScenario, allAccountsData } = useAppSelector((state) => {
        const loadedScenario = state?.scenario?.loadedScenario;
        const allAccountsData = state?.allAccountLedgers?.ledgersMetadata;

        return {
            loadedScenario,
            allAccountsData,
        };
    }, shallowEqual);

    const [selectedAccount, setSelectedAccount] = useState<string>("");
    const [dashboardVisible, setDashboardVisible] = useState<boolean>(
        existingCardData?.dashboardVisible ?? false
    );
    const [scenarioCanvasVisible, setScenarioCanvasVisible] = useState<boolean>(
        existingCardData?.scenarioCanvasVisible ?? false
    );

    const updateSelectedAccount = (accountInfo) => {
        setSelectedAccount(accountInfo?.id);
    };

    const passedCheck: boolean = useMemo(() => {
        if (!edit && selectedAccount === "") return false;
        if (!dashboardVisible && !scenarioCanvasVisible) return false;
        return true;
    }, [dashboardVisible, scenarioCanvasVisible, selectedAccount, edit]);

    const handleCreate = () => {
        const cardObject: any = {
            x: 0,
            y: 0,
            id: uuid(),
            data: {
                accountId: selectedAccount,
                monthlyOrCumulative: "monthly",
                pinned: false,
                pinnedThreadId: "",
                showSparkline: false,
            },
            cardType: accountCardType,
            cardTemplateId: "testing-account-card-template-1",
            dashboardVisible,
            scenarioCanvasVisible,
            account: "",
        };

        if (
            loadedScenario?.type === "sample" ||
            loadedScenario?.type === "shared"
        )
            return;

        dispatch(createCard(cardObject));

        dispatch(closeAccountCardModal());
    };

    const handleUpdate = () => {
        const updatedCardObject = {
            ...existingCardData,
            data: {
                ...existingCardData?.data,
                accountId: selectedAccount,
            },
            dashboardVisible,
            scenarioCanvasVisible,
        };

        dispatch(updateCard(updatedCardObject));

        dispatch(closeAccountCardModal());
    };

    const handleDelete = () => {
        const cardId = existingCardData?.id ?? "";
        if (!cardId) return;

        dispatch(deleteCard([cardId]));

        dispatch(closeAccountCardModal());
    };

    return (
        <div className={styles.primaryContainer}>
            <div className={styles.headerContainer}>
                <h3 className={styles.header}>Create Account Card</h3>
                <div
                    className={styles.closeIcon}
                    onClick={() => dispatch(closeAccountCardModal())}
                ></div>
            </div>
            <div className={styles.contentContainer}>
                <div>
                    <InputDropDownButton
                        callback={updateSelectedAccount}
                        initialValue={
                            allAccountsData?.[existingCardData?.data?.accountId]
                                ?.name ?? ""
                        }
                        buttonLabel="Target Account"
                    />
                    <span className={styles.accountSelectHelperText}>
                        Select the account to display in this Card
                    </span>
                </div>

                <div className={styles.toggleContainer}>
                    <Switch
                        id="inheritStartDate"
                        checked={dashboardVisible}
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={() => setDashboardVisible(!dashboardVisible)}
                    />
                    <span className={styles.toggleText}>
                        display in dashboard
                    </span>
                </div>
                <div className={styles.toggleContainer}>
                    <Switch
                        id="inheritStartDate"
                        checked={scenarioCanvasVisible}
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={() =>
                            setScenarioCanvasVisible(!scenarioCanvasVisible)
                        }
                    />
                    <span className={styles.toggleText}>
                        display in scenario canvas
                    </span>
                </div>
            </div>
            <div className={styles.footerContainer}>
                {edit && (
                    <InvertedStyledButton onClick={handleDelete}>
                        <DeleteOutlineIcon
                            sx={{
                                cursor: "pointer",
                                color: !passedCheck ? "#bebebe" : "#f7ab5f",
                                height: "24px",
                                width: "24px",
                                marginRight: "0.5rem",
                            }}
                        />
                        Delete Card
                    </InvertedStyledButton>
                )}
                <StyledButton
                    disabled={!passedCheck}
                    variant="contained"
                    onClick={edit ? handleUpdate : handleCreate}
                >
                    {edit ? "Update Card" : "Create Card"}
                </StyledButton>
            </div>
        </div>
    );
};

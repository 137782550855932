import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment"

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const customerGrowthInputsHandler = (
    value: string,
    id:
        | "customer"
        | "constantValue"
        | "entityName"
        | "startDate"
        | "interpolateSubType"
        | "maxGrowth"
        | "rating"
        | "cadence",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "customer":
            const parsedCustomer = JSON.parse(value || "{\"eventId\": \"\", \"entityIds\": []}")

            handleDependencies(dependencyMap, parsedCustomer, eventId, currentEntityObject.id, data.customerEventId, data.customerIds)

            data.selectedCustomer = parsedCustomer
            data.customerEventId = parsedCustomer?.eventId ?? ""
            data.customerIds = parsedCustomer?.entityIds ?? []
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "constantValue":
            data.constantValue = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate": 
            const startDateString = value ? moment(value).format("YYYY-MM-DD") : ""
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interpolateSubType":
            data.interpolateSubType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "maxGrowth":
            data.maxGrowth = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            if (value === "") {
                currentEntityObject.cadence = "";
                newEntitiesMap[currentEntity] = currentEntityObject;
            } else {
                currentEntityObject.cadence = value;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        default:
    }
    return newEntitiesMap;
};

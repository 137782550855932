import React, { Component } from "react";
import { getDefaultName } from "../../helpers";
import {
    Description,
    EventHeader,
    EventInputModal,
    EventInputs,
    InflationSwitch,
    InputButtons,
    ModalRow,
    ModalRowHalf,
    NumberOutlined,
    Rate,
    RequiredStar,
    Value,
} from "./Components";
import { throwError } from "../../helpers/swalError";
import Calendar from "../Calendar";
import { shortTermRentalObject } from "Components/Registry/Short Term Rental";

export default class ShortTermRentalInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: getDefaultName(shortTermRentalObject.constant(), this.props),
            type: shortTermRentalObject.constant(),
            tag: `@${shortTermRentalObject.constant()}`,
            rating: 0,
            inflation: false,
            cadence: "monthly",
            startDate: null,
            endDate: null,
            upfrontCosts: null,
            monthlyCosts: null,
            averageMonthlyNights: null,
            dailyRate: null,
            description: "",
            daysPerStay: null,
            perStayCosts: null,
            otherFeesRate: 4.5,
            hotelTaxRate: null,
            showRequired: true,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                this.setState({ name: value });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "upfrontCosts":
                this.setState({ upfrontCosts: value });
                break;
            case "monthlyCosts":
                this.setState({ monthlyCosts: value });
                break;
            case "averageMonthlyNights":
                this.setState({ averageMonthlyNights: value });
                break;
            case "dailyRate":
                this.setState({ dailyRate: value });
                break;
            case "daysPerStay":
                this.setState({ daysPerStay: value });
                break;
            case "perStayCosts":
                this.setState({ perStayCosts: value });
                break;
            case "otherFeesRate":
                this.setState({ otherFeesRate: value });
                break;
            case "hotelTaxRate":
                this.setState({ hotelTaxRate: value });
                break;
            default:
        }
    };

    onHandleDate = (startDate, endDate) => {
        this.setState({ startDate, endDate });
    };

    updateInflation = () => {
        this.setState({ inflation: !this.state.inflation });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = shortTermRentalObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    updateValues = () => {
        this.props.fillPartialValues(
            this.state,
            {  },
            shortTermRentalObject.checkInput(this.state)
        );
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "upfrontCosts":
                data = {
                    title: "Upfront Expenses",
                    content:
                        "These are the costs associated with setting up a short-term rental property.",
                    subContent:
                        "Depending on the property, this may include furniture, security deposits, the cost of monthly rent before your first rental, or other expenses.",
                    link: "",
                };
                break;
            case "monthlyCosts":
                data = {
                    title: "Monthly Expenses",
                    content:
                        "These are the costs of maintaining a property month-to-month.",
                    subContent:
                        "Monthly expenses may include rent, mortgage payments, utilities, maintenance, or anything else.",
                    link: "",
                };
                break;
            case "perStayCosts":
                data = {
                    title: "Per-Stay Expenses",
                    content:
                        "Theses are the costs associated with each rental period.",
                    subContent:
                        "Per-stay expenses may include cleaning services, consumables, repairs, and more.",
                    link: "",
                };
                break;
            case "dailyRate":
                data = {
                    title: "Revenue per Day",
                    content:
                        "This is the rate you charge to rent out your property for one night, averaged across the year.",
                    subContent: "",
                    link: "",
                };
                break;
            case "daysPerStay":
                data = {
                    title: "Days per Stay",
                    content:
                        "This is the average duration for which a client will rent your property.",
                    subContent:
                        "This value can vary greatly depending on the nature of your property - anywhere from a couple of days for a weekend getaway, to several months for a house in the suburbs.",
                    link: "",
                };
                break;
            case "averageMonthlyNights":
                data = {
                    title: "Days Leased per Month",
                    content:
                        "This is the number of days you expect your property to be occupied each month, averaged across the year.",
                    subContent:
                        "In combination with the average days per stay, this value is used to determine how many stays you expect each year - and therefore how often you need to pay for per-stay expenses.",
                    link: "",
                };
                break;
            case "hotelTaxRate":
                data = {
                    title: "Hotel Tax",
                    content:
                        "Depending on where you live, and the nature of your short-term rental property, you may have to pay the regional Hotel Tax on your rental income.",
                    subContent: "",
                    link: "",
                };
                break;
            case "otherFeesRate":
                data = {
                    title: "Other Fees",
                    content:
                        "This value includes any other fees you have to pay on your short-term rental income, and may include credit-card fees or the 3% AirBnb hosting fee.",
                    subContent: "",
                    link: "",
                };
                break;
            case "inflation":
                data = {
                    title: "Inflation",
                    content:
                        "If activated, inflation will modify your expenses and daily rate as time goes on.",
                    subContent: `The inflation rate for this scenario is ${
                        Number(this.props.loadedScenario.inflation) * 100
                    } %, and is calculated from the first day of the scenario.`,
                    link: "",
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    render() {
        const {
            name,
            description,
            upfrontCosts,
            monthlyCosts,
            averageMonthlyNights,
            dailyRate,
            daysPerStay,
            perStayCosts,
            otherFeesRate,
            hotelTaxRate,
            startDate,
            endDate,
            cadence,
            inflation,
        } = this.state;
        const { edit } = this.props;
        const passedCheck = shortTermRentalObject.checkInput(this.state);

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={shortTermRentalObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={shortTermRentalObject.svg()}
                    />
                    <ModalRow onMouseEnter={this.onHandleMouseEnter} id=" ">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf
                            id="upfrontCosts"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <Value
                                label="Upfront Expenses"
                                onChangeInput={this.onChangeInput}
                                value={upfrontCosts}
                                id="upfrontCosts"
                            />
                        </ModalRowHalf>
                        <ModalRowHalf
                            id="monthlyCosts"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <Value
                                label="Monthly Expenses"
                                onChangeInput={this.onChangeInput}
                                value={monthlyCosts}
                                id="monthlyCosts"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf
                            id="averageMonthlyNights"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <NumberOutlined
                                value={averageMonthlyNights}
                                label="Average Days Leased / Month"
                                onChangeInput={this.onChangeInput}
                                id="averageMonthlyNights"
                            />
                        </ModalRowHalf>
                        <ModalRowHalf
                            id="dailyRate"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <Value
                                label="Average Rate / Night"
                                onChangeInput={this.onChangeInput}
                                value={dailyRate}
                                id="dailyRate"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf
                            id="perStayCosts"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <Value
                                label="Average Expenses per Stay"
                                onChangeInput={this.onChangeInput}
                                value={perStayCosts}
                                id="perStayCosts"
                            />
                        </ModalRowHalf>
                        <ModalRowHalf
                            id="daysPerStay"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <NumberOutlined
                                value={daysPerStay}
                                label="Average Days per Stay"
                                onChangeInput={this.onChangeInput}
                                id="daysPerStay"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf
                            id="hotelTaxRate"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <Rate
                                value={hotelTaxRate}
                                onChangeInput={this.onChangeInput}
                                id="hotelTaxRate"
                                label="Hotel Tax"
                                className="mlsInput"
                                sign={true}
                            />
                        </ModalRowHalf>
                        <ModalRowHalf
                            id="otherFeesRate"
                            onMouseEnter={this.onHandleMouseEnter}
                        >
                            <RequiredStar />
                            <Rate
                                value={otherFeesRate}
                                onChangeInput={this.onChangeInput}
                                id="otherFeesRate"
                                label="Other Fees"
                                className="mlsInput"
                                sign={true}
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    <ModalRow onMouseEnter={this.onHandleMouseEnter} id=" ">
                        <RequiredStar />
                        <Calendar
                            onHandleDate={this.onHandleDate}
                            startDate={startDate}
                            endDate={endDate || null}
                            cadence={cadence}
                        />
                    </ModalRow>
                    <ModalRow
                        id="inflation"
                        onMouseEnter={this.onHandleMouseEnter}
                    >
                        <InflationSwitch
                            applyInflation={inflation}
                            toggleInflation={this.updateInflation}
                        />
                    </ModalRow>
                    {this.state.showRequired && (
                        <div className="ModalRow">
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </div>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import MaternityCard from "../Card/maternityCard";
import MaternityInput from "../InputContainer/MaternityInput";

// Import Connection Image (if exists)
import MaternityConnectionImage from "../../Assets/_optionDescriptionIcons/Me, Income, Maternity.png";

// Import Coloured PNGs
import maternityBlack from "../../Assets/_eventNodeTypes/parental-black.png";
import maternityBlue from "../../Assets/_eventNodeTypes/parental-blue.png";
import maternityWhite from "../../Assets/_eventNodeTypes/parental-white.png";
import maternityGrey from "../../Assets/_eventNodeTypes/parental-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/ChildcarePlanning2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - maternityObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - maternityObject.checkInput.call(maternityObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const maternityObject = {
    constant: function () {
        return Maternity_Constant();
    },

    checkInput: function (state) {
        return Maternity_CheckInput(state);
    },

    svg: function () {
        return Maternity_Svg();
    },

    eventNodeMenu: function (focus) {
        return Maternity_EventNodeMenu(focus);
    },

    indexText: function () {
        return Maternity_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Maternity_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Maternity_OptionDescription();
    },

    colouredPngs: function () {
        return Maternity_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Maternity_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Maternity_CheckInput = () => {
    return true;
};

// EFFECTS: Returns constant for event
const Maternity_Constant = () => {
    return "Maternity";
};

// EFFECTS: Returns black png / svg for event
const Maternity_Svg = () => {
    return maternityBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Maternity_EventNodeMenu = (focus) => {
    return <MaternityCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Maternity_IndexText = () => {
    return "Select an input field to learn more about your maternity calculations.";
};

// EFFECTS: Returns all icons for event ??
const Maternity_Icons = () => {
    let pngs = new Map([
        ["black", maternityBlack],
        ["blue", maternityBlue],
        ["white", maternityWhite],
        ["grey", maternityGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Maternity_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Maternity_InputComponentInfoPassing = (minProps) => {
    return <MaternityInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, maternitys for event ??
const Maternity_OptionDescription = () => {
    const tags = ["Option"];
    const type = maternityObject.constant();
    const description =
        "In Canada, Employment Insurance covers individuals and spouses a portion of their salary to support their maternity leave and transition into parent hood. Click the Learn More button below to find out if you are eligible.";
    const usedForText =
        "The Maternity Event can be used to model your surpressed cashflow and taxes during you and your spouses maternity leaves.";
    const connection = "";
    const connectionImage = MaternityConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/services/benefits/ei/ei-maternity-parental.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Maternity_SideModalImage = () => {
    return sideImage;
};

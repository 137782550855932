import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment"

/*
 * Helper function that handles Goal Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const goalInputsHandler = (
    value: string,
    id: "monthlyCumulative" | "ledgerName" | "entityName" | "value" | "accountName" | "startDate" | "displayMarker" | "displayThreshold",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; id: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };


    switch (id) {
        case "monthlyCumulative":
            data.monthlyCumulative = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name ?? "";
            data.accountId = account.id ?? "";
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate": 
            const startDateString = value ? moment(value).format("YYYY-MM-DD") : ""
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            let valueNum = parseFloat(value);
            if (!isNaN(valueNum)) {
                if (valueNum % 1 === 0) {
                    valueNum = Math.trunc(valueNum);
                }
                data.value = valueNum;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "displayMarker":
        case "displayThreshold": 
            data[id] = !data[id] ?? false
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break
        default:
        // Noop
    }

    return newEntitiesMap;
};

import React, { Component } from "react";
import "./Header.css";
import { connect } from "react-redux";
import profileSvg from "../../Assets/_headerIcons/profileSvg.svg";
import settingSvg from "../../Assets/_headerIcons/settingsSvg.svg";
import notificationSvg from "../../Assets/_headerIcons/notification.svg";
import Notification from "../Notifications";
import tutorialSvg from "../../Assets/_headerIcons/tutorial.svg";
import profileActive from "../../Assets/_headerIcons/profileActive.svg";
import settingActive from "../../Assets/_headerIcons/settingActive.svg";
import notificationActive from "../../Assets/_headerIcons/notificationActive.svg";
import profilePlaceholder from "../../Assets/_headerIcons/small.png";
import { matchPath } from "react-router";
import tutorialActive from "../../Assets/_headerIcons/tutorialSelected.svg";
import { LaunchOnboarding } from "../../helpers/onboardingFunctions";
import { LOG_IN } from "../../helpers/constants";
import { openCustomAccountModal } from "actions/customAccountActions";

class HeaderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: [],
            notificationCount: 0,
            selected: null,
            tutorialList: [
                {
                    name: "Documentation",
                    id: 1,
                    onHover: false,
                },
                {
                    name: "Demo Video",
                    id: 2,
                    onHover: false,
                },
            ],
        };
    }

    componentDidMount() {
        this.setState({ path: this.props.history.location.pathname });
    }

    componentDidUpdate(prevProps) {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (this.state.path !== this.props.history.location.pathname) {
            this.setState({ path: this.props.history.location.pathname });
        }

        if (
            (prevProps.userScenarios !== this.props.userScenarios ||
                prevProps.sharedScenario !== this.props.sharedScenario) &&
            loggedInUser
        ) {
            let yourNotification;
            let sharedNotification;
            let notif = [];

            if (this.props.userScenarios) {
                this.props.userScenarios.map((scenario) => {
                    if (scenario.comments && scenario.comments.notification) {
                        yourNotification = scenario.comments.notification;
                    }
                    return scenario;
                });
            }
            if (this.props.sharedScenario) {
                this.props.sharedScenario.map((scenario) => {
                    if (scenario.comments && scenario.comments.notification) {
                        sharedNotification = scenario.comments.notification;
                    }
                    return scenario;
                });
            }

            if (yourNotification && yourNotification.length > 0) {
                yourNotification.map((notification) => {
                    return notif.push(notification);
                });
            }

            if (sharedNotification && sharedNotification.length > 0) {
                sharedNotification.map((notification) => {
                    return notif.push(notification);
                });
            }

            if (prevProps.showNotification !== this.props.showNotification) {
                this.setState({});
            }

            const filteredNotif = notif.filter((notification) => {
                return notification.commenterAccount !== loggedInUser.account;
            });

            const sortedNotification =
                filteredNotif &&
                filteredNotif.sort((a, b) => {
                    return new Date(b.time) - new Date(a.time);
                });

            let notifCount = 0;
            filteredNotif &&
                filteredNotif.map((filtered) => {
                    if (!filtered.seen) {
                        notifCount += 1;
                    }
                    return filtered;
                });

            sortedNotification.forEach((notification) => {
                notification.commenterImage = profilePlaceholder;
            });

            this.setState({
                notification: sortedNotification,
                notificationCount: notifCount,
            });
        }
    }

    onToggleNotification = () => {
        if (!this.props.showNotification) {
            this.onHandleNotificationSeen();
        }
        this.props.showNotificationAction();
        this.setState({
            notificationCount: 0,
            showNotification: !this.state.showNotification,
        });
    };

    onHandleSettings = () => {
        const { showSettings } = this.state;
        this.props.hideNotification();
        if (!showSettings) {
            this.setState({
                showSettings: true,
                showNotification: false,
                selected: "settings",
            });
            this.props.history.push("/settings");
        } else {
            this.setState({
                showSettings: false,
                showNotification: false,
                selected: "settings",
            });
            this.props.history.push("/");
        }
    };

    onHandleProfile = () => {
        this.props.history.push("/settings/profile");
    };

    onHandleNotificationSeen = () => {
        console.log("onHandleNotificationSeen");
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        //this is for their own scenario notification
        this.props.userScenarios.map((scenario) => {
            const newNotification =
                scenario.comments &&
                scenario.comments.notification &&
                scenario.comments.notification.map((notif) => {
                    if (notif.commenterAccount !== loggedInUser.account) {
                        const newNotif = {
                            ...notif,
                            seen: true,
                        };
                        return newNotif;
                    }
                    return notif;
                });

            const newScenario = {
                ...scenario,
                comments: {
                    ...scenario.comments,
                    notification: newNotification,
                },
            };
            if (newScenario.type === "scenario") {
                this.props.editScenarioComments(newScenario, (err) => {
                    if (err) {
                        console.log(err);
                    }
                });
            }
            this.setState({ notificationCount: 0 });
            return newScenario;
        });
        this.props.getUserScenarios();

        //this is for their shared scenario

        this.props.sharedScenario.map((scenario) => {
            const newNotification =
                scenario.comments &&
                scenario.comments.notification &&
                scenario.comments.notification.map((notif) => {
                    if (notif.commenterAccount !== loggedInUser.account) {
                        const newNotif = {
                            ...notif,
                            seen: true,
                        };
                        return newNotif;
                    }
                    return notif;
                });

            const newScenario = {
                ...scenario,
                comments: {
                    ...scenario.comments,
                    notification: newNotification,
                },
            };
            if (newScenario.type === "shared") {
                this.props.updateSharedScenario(newScenario, () => {
                    return;
                });
            }

            return newScenario;
        });
        this.props.fetchSharedScenarios();
    };

    upgradePlan = () => {
        this.props.history.push("/billing");
    };

    onHandleSelected = (e) => {
        const id = e.target.id;
        if (this.state.selected === id) {
            this.setState({ selected: null });
            this.props.hideNotification();
        } else if (id === "notification" && this.state.selected !== id) {
            this.setState({ selected: id });
            this.onToggleNotification();
        } else if (id !== "notification") {
            this.props.hideNotification();
            this.setState({ selected: id });
        } else {
            this.setState({ selected: id });
        }
    };

    onHandleTutorialHover = (e) => {
        const id = Number(e.target.id);
        const { tutorialList } = this.state;
        const newList = tutorialList.map((list) => {
            if (list.id === id) {
                const newTutorial = {
                    ...list,
                    onHover: true,
                };
                return newTutorial;
            } else {
                const tutorial = {
                    ...list,
                    onHover: false,
                };
                return tutorial;
            }
        });

        this.setState({ tutorialList: newList });
    };

    onClickTutorial = (e) => {
        const id = Number(e.target.id);
        const {
            userScenarios,
            manager,
            onboardingScenario,
            loadScenario,
            createScenario,
            getUserScenarios,
        } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (id === 1) {
            window.open("https://docs.whatifi.io/");
        } else if (id === 2) {
            window.open("https://youtu.be/ZOOuTn3q_mU");
        } else {
            const onboardingScenarios =
                userScenarios &&
                userScenarios.filter((scenario) => {
                    return scenario.name === "Onboarding Scenario";
                });
            if (onboardingScenarios.length >= 1 && loggedInUser) {
                this.props
                    .deleteScenario(
                        onboardingScenarios[0].id,
                        this.props.manager,
                        this.props.sampleScenarios
                    )
                    .then((data) => {
                        if (data) {
                            LaunchOnboarding(
                                manager,
                                onboardingScenario,
                                loadScenario,
                                loggedInUser,
                                createScenario,
                                getUserScenarios
                            );
                            this.props.setOnboardingData(1);
                        }
                    })
                    .catch((err) => {
                        console.log(err, "err on deleting");
                    });
            } else {
                // this.createScenario();
                LaunchOnboarding(
                    manager,
                    onboardingScenario,
                    loadScenario,
                    loggedInUser,
                    createScenario,
                    getUserScenarios
                );
                this.props.setOnboardingData(1);
            }
        }
        this.setState({ selected: null });
    };

    onHandlePath = (path) => {
        this.props.selectTab(path);
        this.props.history.push(path);
        this.setState({ path });
    };

    render() {
        const { account, image, name, logout, email, tabSelected } = this.props;
        const { selected, tutorialList } = this.state;

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        const isPathSettings = !!matchPath(
            this.props.history.location.pathname,
            "/settings"
        );

        return (
            <div id="Whatifi-Banner">
                <div id="BannerInfo">
                    <div
                        id="BannerHead"
                        onClick={() => this.onHandlePath("/home")}
                    >
                        what<div id="Banner-If">if</div>i.io
                    </div>
                </div>
                <div className="headerTabContainer">
                    <div
                        onClick={() => this.onHandlePath("/home")}
                        className={
                            this.props.history.location.pathname === "/home"
                                ? "headerTabActive"
                                : "headerTab"
                        }
                    >
                        Home
                    </div>
                    {/* {loggedInUser && (
                        <div
                            onClick={() => {
                                this.props.toggleShowAgencyBaselineView(true);
                                this.onHandlePath("/baseline");
                            }}
                            className={
                                this.props.history.location.pathname ===
                                "/baseline"
                                    ? "headerTabActive"
                                    : "headerTab"
                            }
                        >
                            Baseline
                        </div>
                    )} */}
                    <div
                        onClick={() => this.onHandlePath("/")}
                        className={
                            this.props.history.location.pathname === "/"
                                ? "headerTabActive"
                                : "headerTab"
                        }
                    >
                        Scenario Canvas
                    </div>
                    {process.env.NODE_ENV === "development" && (
                        <div
                            onClick={() => this.onHandlePath("/dashboard")}
                            className={
                                this.props.history.location.pathname ===
                                "/dashboard"
                                    ? "headerTabActive"
                                    : "headerTab"
                            }
                        >
                            Dashboard
                        </div>
                    )}
                    <div
                        onClick={() =>
                            window.open("http://docs.whatifi.io/", "_blank")
                        }
                        className="headerTab"
                    >
                        Docs
                    </div>
                    {process.env.NODE_ENV === "development" && loggedInUser && (
                        <div
                            onClick={() =>
                                this.props.openCustomAccountModal(true)
                            }
                            className="headerTab"
                        >
                            Custom Account
                        </div>
                    )}
                </div>
                <div>
                    {account == null ? (
                        <div className="headerIconHolder">
                            <div
                                className="upgradePlanText"
                                onClick={this.upgradePlan}
                            >
                                {loggedInUser ? "Upgrade Plan" : "Browse Plans"}
                            </div>
                            {tabSelected === "/" && (
                                <div
                                    onClick={this.onHandleSelected}
                                    className="svgContainer"
                                    id="tutorial"
                                >
                                    <img
                                        alt="alt"
                                        id="tutorial"
                                        src={
                                            selected === "tutorial"
                                                ? tutorialActive
                                                : tutorialSvg
                                        }
                                        className="svg"
                                        // onMouseOver={onHoverSetting}
                                    />
                                </div>
                            )}

                            <div
                                className="loginText"
                                onClick={() =>
                                    this.props.showLogin(true, LOG_IN)
                                }
                            >
                                Login
                            </div>
                        </div>
                    ) : (
                        <div className="headerIconHolder">
                            {/* <div className="tutorialContainer">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="Banner-Link"
                                    href="https://www.notion.so/whatifi/whatifi-0-5-Beta-Early-Access-Program-8e33b2894cf54ba3b06f769d1d14cc30"
                                >
                                    <img
                                        alt="alt"
                                        className="tutorial"
                                        src={tutorialSvg}
                                    />
                                </a>
                            </div> */}

                            <div
                                className="upgradePlanText"
                                onClick={this.upgradePlan}
                            >
                                Upgrade Plan
                            </div>
                            {tabSelected === "/" && (
                                <div
                                    onClick={this.onHandleSelected}
                                    className="svgContainer"
                                    id="tutorial"
                                >
                                    <img
                                        alt="alt"
                                        id="tutorial"
                                        src={
                                            selected === "tutorial"
                                                ? tutorialActive
                                                : tutorialSvg
                                        }
                                        className="svg"
                                        // onMouseOver={onHoverSetting}
                                    />
                                </div>
                            )}

                            <div
                                className="svgContainer"
                                onClick={this.onHandleSelected}
                                id="notification"
                            >
                                <img
                                    alt="alt"
                                    id="notification"
                                    src={
                                        selected === "notification"
                                            ? notificationActive
                                            : notificationSvg
                                    }
                                    className="svg"
                                    // onMouseOver={onHoverSetting}
                                />
                                {this.state.notificationCount > 0 && (
                                    <div className="notificationCount">
                                        {this.state.notificationCount}
                                    </div>
                                )}
                                {this.props.showNotification && (
                                    <div className="notification">
                                        <Notification
                                            data={this.state}
                                            onHandleNotificationSeen={
                                                this.onHandleNotificationSeen
                                            }
                                            history={this.props.history}
                                        />
                                    </div>
                                )}
                            </div>
                            <div
                                onClick={this.onHandleSettings}
                                className="svgContainer"
                                id="settings"
                            >
                                <img
                                    alt="alt"
                                    id="setting"
                                    src={
                                        isPathSettings
                                            ? settingActive
                                            : settingSvg
                                    }
                                    className="svg"
                                    // onMouseOver={onHoverSetting}
                                />
                            </div>

                            <div
                                onClick={this.onHandleSelected}
                                className="svgContainer"
                                id="profile"
                            >
                                <img
                                    alt="alt"
                                    id="profile"
                                    src={
                                        selected === "profile"
                                            ? profileActive
                                            : profileSvg
                                    }
                                    className="svg"
                                    // onMouseOver={onHoverAccount}
                                />
                            </div>
                        </div>
                    )}
                    {selected === "profile" && (
                        <div>
                            <div className="profileModal">
                                <div id="LoginInfo">
                                    <div className="ProfileText">Profile</div>
                                    <div className="nameContainer">
                                        <img
                                            alt="alt"
                                            src={
                                                image
                                                    ? image
                                                    : profilePlaceholder
                                            }
                                            className="nameSvg"
                                        />
                                        <div className="nameHolder">
                                            <div id="username">{name}</div>
                                            <div className="profileEmail">
                                                {email}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={this.onHandleProfile}
                                        className="profileButton"
                                    >
                                        View Profile
                                    </div>

                                    <div className="logoutContainer">
                                        <div
                                            className="logoutText"
                                            onClick={logout}
                                        >
                                            Log Out
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {selected === "tutorial" && tabSelected === "/" && (
                        <div
                            className={
                                loggedInUser
                                    ? "tutorialModal"
                                    : "tutorialModalNoUser"
                            }
                        >
                            <div className="ProfileText">Help</div>
                            {tutorialList.map((list, i) => {
                                const onHover = list.onHover;
                                return (
                                    <div
                                        key={i}
                                        onMouseOver={this.onHandleTutorialHover}
                                        className={
                                            onHover
                                                ? "tutorialListActive"
                                                : "tutorialList"
                                        }
                                        id={list.id}
                                        onClick={this.onClickTutorial}
                                    >
                                        {list.name}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    openCustomAccountModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);

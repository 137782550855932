import { getEvent } from "actions/getNodeEntityActions";
import { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import * as uuid from "uuid";

export interface EventObject {
    constant: () => string;
    checkInput: (state: any) => boolean;
    svg: () => string;
    eventNodeMenu: (focus: any) => JSX.Element;
    indexText: () => string;
    inputInfoPassing: (minProps: any) => JSX.Element;
    optionDescription: () => Map<string, any>;
    colouredPngs: () => Map<string, any>;
    version: () => string;
    sideModal: () => string;
}

export const createNewEvent = (
    eventObject: EventObject,
    baseline?: boolean
) => {
    const _eventData: EventStructure = {
        children: [],
        parents: [],
        description: "",
        entities: [{ id: uuid.v4(), active: true }],
        id: "",
        name: "",
        type: eventObject.constant(),
        version: eventObject.version(),
        baseline: baseline ?? false,
        locked: false,
        linked: false,
        bypassed: false,
        mostRecentEntity: 0,
    };
    return _eventData;
};

// Extract editData from event so that it can be used to override the actual editData of the focused event
// This is necessary because when you're updating events from code and not from the UI, the focus node
// is not changed and the program assumes the focus node is the one we're updating
export const getOverrideEditData = (eventOrId) => {
    let event = eventOrId;
    const customEditData = {};

    if (typeof eventOrId == "string") {
        event = getEvent(eventOrId);
    }

    const customFields = [
        "active",
        "bypassed",
        "hideInCanvas",
        "id",
        "isExpired",
        "isFilled",
        "name",
        "parents",
        "children",
    ];

    if (!event) {
        return;
    }

    customFields.forEach((field) => {
        if (field in event) {
            customEditData[field] = event[field];
        }
    });

    return customEditData;
};

// return list of event ids given list of events or event ids
export const extractEventIds = (events) => {
    const eventIds: any[] = [];

    if (!events) return eventIds;

    events.forEach((event) => {
        if (typeof event === "string") {
            eventIds.push(event);
        } else {
            eventIds.push(event.id);
        }
    });

    return eventIds;
};

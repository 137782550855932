// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    ToggledContainer,
    ToggledContainerHalf,
    RadioButton,
    PlusButton,
    DeleteButton,
    ModalRowHalf,
    SelectEntities,
    ModalRowComponent,
    Rate,
    MuiCalendar
} from "../Components";
import { salespersonObject } from "Components/Registry/Salesperson";

const SalesTarget = ({
    index,
    handleOnChange,
    deleteSalesTarget,
    outboundSales,
    selectedOutboundSales,
    timePercentage,
    multiplier,
}) => {
    return (
        <ModalRow twoInputs>
            <ModalRowHalf>
                <SelectEntities
                    onChangeInput={handleOnChange}
                    showAll={false}
                    value={selectedOutboundSales}
                    nodes={outboundSales}
                    className="mlsInput"
                    id="outboundSales"
                    mainId={index}
                    label="Target Outbound Sales"
                    required={true}
                />
            </ModalRowHalf>
            <ModalRowComponent width={18}>
                <Rate
                    label="Time Spent"
                    onChangeInput={handleOnChange}
                    value={timePercentage}
                    id="timePercentage"
                    className="mlsInput"
                    mainId={index}
                    sign={true}
                    required={true}
                />
            </ModalRowComponent>
            <ModalRowComponent width={18}>
                <Rate
                    label="Close Multiplier"
                    onChangeInput={handleOnChange}
                    value={multiplier}
                    id="multiplier"
                    className="mlsInput"
                    mainId={index}
                    sign={true}
                    inputAdornmentOverride="X"
                    required={true}
                />
            </ModalRowComponent>
            <DeleteButton onClick={() => deleteSalesTarget(index)} />
        </ModalRow>
    );
};

export default function SalespersonInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    onHandleSubmit,
    handleOnChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    onChangeInput,
    addNewSalesTarget,
    deleteSalesTarget,
    handleDateSelection
}) {
    const entityData = entitiesMap[currentEntity].data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={salespersonObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={salespersonObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[salespersonObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="specific"
                        isSelected={entityData.employeeType == "specific"}
                        onClick={handleOnChange}
                        title="Specific Employee"
                    />
                    <ToggledContainerHalf
                        id="placeholder"
                        isSelected={entityData.employeeType == "placeholder"}
                        onClick={handleOnChange}
                        title="Placeholder Employee"
                    />
                </ToggledContainer>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.data?.selectedEmployee
                            }
                            nodes={Object.values(entityData.employees)}
                            className="mlsInput"
                            id="employee"
                            label="Salesperson"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <RadioButton
                                        onClick={onChangeInput}
                                        isActive={entityData.inheritStartDate}
                                        label="Inherit Start Date"
                                        id="inheritStartDate"
                                    />
                                </div>
                                <div style={{ height: "4px" }}></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <RadioButton
                                        onClick={onChangeInput}
                                        isActive={entityData.inheritEndDate}
                                        label="Inherit End Date"
                                        id="inheritEndDate"
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalRowHalf>
                </ModalRow>
                {entityData.employeeType == "placeholder" && (
                    <ModalRow>
                        <ModalRowComponent width={30}>
                            <Rate
                                label="Total Salespeople"
                                onChangeInput={handleOnChange}
                                value={entityData.totalSalespeople}
                                id="totalSalespeople"
                                className="mlsInput"
                                required={true}
                            />
                        </ModalRowComponent>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "10px",
                            }}
                        >
                            of {entityData.noEmployees || "0"} available
                            placeholder Employees
                        </div>
                    </ModalRow>
                )}
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar id="startDate" value={entitiesMap?.[currentEntity]?.startDate} required={true} onChangeInput={handleDateSelection} label="Start Date" />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <MuiCalendar id="endDate" value={entitiesMap?.[currentEntity]?.endDate} onChangeInput={handleDateSelection} label="End Date" helperText="An end date is optional" />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <div className="entity-section-label">Sales Targets</div>
                </ModalRow>
                {entityData.salesTargets.map((salesTarget, i) => {
                    return (
                        <SalesTarget
                            key={i}
                            index={i}
                            handleOnChange={handleOnChange}
                            deleteSalesTarget={deleteSalesTarget}
                            outboundSales={Object.values(
                                entityData.outboundSales
                            )}
                            selectedOutboundSales={salesTarget.outboundSales}
                            timePercentage={salesTarget.timePercentage}
                            multiplier={salesTarget.multiplier}
                        />
                    );
                })}
                <ModalRow>
                    <PlusButton
                        onClick={addNewSalesTarget}
                        label="Add Sales Cohort Target"
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                passedCheck={passedCheck}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
            />
        </EventEntityWrapper>
    );
}

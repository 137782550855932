// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    ModalRow,
    ModalRowHalf,
    MuiCalendar,
    Period,
    SelectDropDown,
    Value,
    NoUnitValue,
} from "Components/InputContainer/Components";
import InfoBox from "./InfoBox";
import { getCadenceUnit } from "helpers/getCadenceUnit";

const amortizationOptions = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "daily",
];

export default function AmortizedExpensePanel({
    entitiesMap,
    currentEntity,
    handleOnChange,
    handleDateSelection,
    unitless,
    increase,
    ledgerAccount,
    overrideValue,
    createOverride,
    updateOverride,
    amortizedValue,
    numPayments,
    amortizationPeriod,
    cadence,
    isValueOverriden,
    startDate,
    endDate,
}) {
    const cadenceUnit = getCadenceUnit(cadence, amortizationPeriod);

    return (
        <>
            <ModalRow>
                <ModalRowHalf>
                    {unitless ? (
                        <NoUnitValue
                            label="Amount"
                            onChangeInput={handleOnChange}
                            value={amortizedValue}
                            id="amortizedValue"
                            required={true}
                        />
                    ) : (
                        <Value
                            label="Cost"
                            onChangeInput={handleOnChange}
                            value={amortizedValue}
                            id="amortizedValue"
                            unit="$"
                            required={true}
                            override={overrideValue}
                            ledgerAccount={ledgerAccount}
                            overriden={isValueOverriden}
                            createOverride={createOverride}
                            updateOverride={updateOverride}
                        />
                    )}
                </ModalRowHalf>
            </ModalRow>
            <ModalRow twoInputs>
                <div className="ExpenseAmountContainer">
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={cadence}
                        options={amortizationOptions}
                        className="select-dropdown"
                        id="cadence"
                        label="Select Payment Frequency"
                        required={true}
                    />
                </div>
                <div className="ExpenseAmountContainer">
                    <Period
                        label="Amortization Period"
                        onChangeInput={handleOnChange}
                        value={amortizationPeriod}
                        id="amortizationPeriod"
                        className="mlsInput"
                        cadenceUnit={cadenceUnit}
                        required={true}
                    />
                </div>
            </ModalRow>
            <ModalRow twoInputs>
                <ModalRowHalf>
                    <MuiCalendar
                        id="startDate"
                        value={startDate}
                        required={true}
                        onChangeInput={handleDateSelection}
                        label="Start Date"
                    />
                </ModalRowHalf>
                <ModalRowHalf>
                    <MuiCalendar
                        id="endDate"
                        value={endDate}
                        required={false}
                        onChangeInput={handleDateSelection}
                        label="End Date"
                        helperText="An end date is optional"
                    />
                </ModalRowHalf>
            </ModalRow>
            <InfoBox
                entitiesMap={entitiesMap}
                currentEntity={currentEntity}
                numPayments={numPayments}
                cadenceUnit={cadenceUnit}
                amortizationPeriod={amortizationPeriod}
                unitless={unitless}
                increase={increase}
            />
        </>
    );
}

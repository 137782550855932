import { Component } from "react";
import "./scenario-name-select.css";
import SelectArrow from "../../Assets/scenarioIcons/select-arrow.svg";
import { appMapDispatch, appMapState } from "store/appMapStateDispatch";
import { connect } from "react-redux";
import { selectChartGraphGroupNodesMode } from "actions/chartGraphGroupNodesModeActions";

class ScenarioNameSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chartGraphGroupNodesOptions: [
                { name: "Default Mode", value: "default" },
                { name: "Individual Mode", value: "singleNode" },
                { name: "Cumulative Mode", value: "cumulativeNodes" },
            ],
            selectedViewMode: { name: "Default Mode", value: "default" },
            selectClasses:
                "scenario-name-select__select-wrapper scenario-name-select__select-wrapper--hide-start",
            showDropdown: false,
        };
    }

    onClickDropdown = (selectedViewMode) => {
        const sortedArray = [...this.state.chartGraphGroupNodesOptions];
        if (selectedViewMode.value !== this.state.selectedViewMode.value) {
            sortedArray.sort((x, y) =>
                x.value === selectedViewMode.value
                    ? -1
                    : y.value === selectedViewMode.value
                    ? 1
                    : 0
            );

            this.props.dispatch(
                selectChartGraphGroupNodesMode(selectedViewMode.value)
            );
        }
        this.setState({
            chartGraphGroupNodesOptions: sortedArray,
            showDropdown: !this.state.showDropdown,
            selectedViewMode,
        });
    };

    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.zoomedThreadId !== this.props.zoomedThreadId) {
            this.fadeInOutAnimation();
        }
        if (
            !this.state.showDropdown &&
            prevProps.chartGraphGroupNodesMode !==
                this.props.chartGraphGroupNodesMode &&
            this.props.chartGraphGroupNodesMode === "default"
        ) {
            // console.count("Entered here!")
            /**
             * NOTE: This if statement is mostly a copy pasta of onClickDropdown(). Its purpose is to note that redux.chartGraphGroupNodesMode has changed to default not due to a user's click of the options.
             * For example, if they click out of zoom thread mode, we would reset redux.chartGraphGroupNodesMode to "default", but because everything here is local state, it doesn't know of the changes in Redux.
             * Ideally, we would move the states here into Redux as well so that they will always be synchronized. Due to time constraint, we didn't do that currently.
             * This lifecycle may have dangerous unintended side effects so we should move this to Redux asap.
             * We can probably make redux.chartGraphGroupNodesMode into an object (currently it is a string).
             */
            const sortedArray = [...this.state.chartGraphGroupNodesOptions];
            sortedArray.sort((x, y) =>
                x.value === "default" ? -1 : y.value === "default" ? 1 : 0
            );
            this.setState({
                chartGraphGroupNodesOptions: sortedArray,
                selectedViewMode: { name: "Default Mode", value: "default" },
            });
        }
    }

    fadeInOutAnimation = () => {
        if (
            this.props.zoomedThreadId &&
            this.props.rightDisplayContent === "ScenarioView"
        ) {
            window.clearTimeout(this.timer);

            this.setState({
                selectClasses:
                    "scenario-name-select__select-wrapper scenario-name-select__select-wrapper--show1",
            });
            this.timer = window.setTimeout(() => {
                this.setState({
                    selectClasses:
                        "scenario-name-select__select-wrapper scenario-name-select__select-wrapper--show1 scenario-name-select__select-wrapper--show2",
                });
            }, 1);
        } else {
            window.clearTimeout(this.timer);
            this.setState({
                selectClasses:
                    "scenario-name-select__select-wrapper scenario-name-select__select-wrapper--hide-end1",
            });
            this.timer = window.setTimeout(() => {
                this.setState({
                    selectClasses:
                        "scenario-name-select__select-wrapper scenario-name-select__select-wrapper--hide-end1 scenario-name-select__select-wrapper--hide-end2",
                });
            }, 3000);
        }
    };

    render() {
        const { showDropdown, chartGraphGroupNodesOptions } = this.state;
        return (
            <div className="scenario-name-select__container">
                <div className="scenario-name-select__name">
                    {this.props.loadedScenario.name}
                </div>
                <span className="scenario-name-select__subtitle">
                    {this.props?.loadedScenario?.subtitle ?? ""}
                </span>
                {this.props.zoomedThreadId &&
                    this.props.rightDisplayContent === "ScenarioView" && (
                        <div className="scenario-name-select__select-text-wrapper">
                            <div
                                className={
                                    showDropdown
                                        ? "scenario-name-select__select-wrapper--open"
                                        : "scenario-name-select__select-wrapper--closed"
                                }
                            >
                                <img
                                    className={
                                        showDropdown
                                            ? "scenario-name-select__select-arrow-icon--open"
                                            : "scenario-name-select__select-arrow-icon--closed"
                                    }
                                    src={SelectArrow}
                                    onClick={() =>
                                        this.onClickDropdown(
                                            this.state.selectedViewMode
                                        )
                                    }
                                />
                                {chartGraphGroupNodesOptions.map((option) => {
                                    return (
                                        <li
                                            key={option.value}
                                            className="scenario-name-select__select-dropdown-item"
                                            onClick={() =>
                                                this.onClickDropdown(option)
                                            }
                                        >
                                            <span className="scenario-name-select__select-dropdown-item-text">
                                                {option.name}
                                            </span>
                                        </li>
                                    );
                                })}
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

const mapStateToProps = appMapState((state) => {
    const zoomedThreadId = state?.threadsList?.zoomedThreadId;
    const zoomedThreadName =
        state.calculatedThreads[zoomedThreadId]?.name || "";

    return {
        chartGraphGroupNodesMode: state.chartGraphGroupNodesMode,
        zoomedThreadId,
        zoomedThreadName,
    };
});

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioNameSelect);

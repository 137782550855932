type Cadence =
    | "annually"
    | "quarterly"
    | "monthly"
    | "semi-monthly"
    | "bi-weekly"
    | "weekly"
    | "one-time";

const annualPaymentFrequencyMap = {
    annually: 1,
    quarterly: 4,
    monthly: 12,
    "semi-monthly": 24,
    "bi-weekly": 26,
    weekly: 52,
};

/**
 * Assumes annual compounding.
 */
export function getLoanPayments(
    principal: number,
    interestRate: number,
    amortization: number,
    cadence: Cadence
) {
    const rate = interestRate * 0.01;
    const annualPaymentFrequency =
        cadence === "one-time"
            ? 1 / amortization
            : annualPaymentFrequencyMap[cadence];
    const numPayments = amortization * annualPaymentFrequency;
    const periodicInterestRate = rate / annualPaymentFrequency;
    /**
     * NOTE: `payments` is not rounded. I think some calculators use rounded values to determine `termInterest`
     */
    const payments =
        principal /
            (((1 + periodicInterestRate) ** numPayments - 1) /
                (periodicInterestRate *
                    (1 + periodicInterestRate) ** numPayments)) || 0;
    const termInterest = payments * numPayments - principal || 0;

    /**
     * payments - How much you need to pay at each payment period.
     * termInterest - How much you will pay in interest over the term of the loan.
     * numPayments - The total number of payments you will make.
     */
    return {
        payments,
        termInterest,
        numPayments,
    };
}

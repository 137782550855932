import { connect } from "react-redux";
import * as actions from "../../actions/scenario";
import BaselineContainer from "./Baseline";

const mapStateToProps = (state) => {
    return {
        sampleScenarios: state.scenario.sampleScenarios,
        sampleScenariosLoading: state.scenario.sampleScenariosLoading,
        userScenarios: state.scenario.userScenarios,
        loadedScenario: state.scenario.loadedScenario,
        loadedScenarioId: state.scenario.loadedScenarioId,
        manager: state.scenario.manager,
        sharedScenario: state.scenario.sharedScenario,
        accountData: state.scenario.accountData,
        onboardingData: state.scenario.onboardingData,
        showOptions: state.scenario.showOptions,
        selectedEvent: state.scenario.selectedOption,
        editData: state.scenario.editData,
        edit: state.scenario.edit,
        baseline: state.scenario.baseline,
        baselineManager: state.scenario.baselineManager,
        baselineDataManager: state.scenario.baselineDataManager,
        focus: state.scenario.focus,
        eventLibrary: state.scenario.eventLibrary,
        agencyClients: state.scenario.agencyClients,
        activeAgencyClients: state.scenario.activeAgencyClients,
        archivedAgencyClients: state.scenario.archivedAgencyClients,
        showAgencyBaselinePage: state.scenario.showAgencyBaselinePage,
        loggedInUser: state.scenario.loggedInUser,
        areBaselinesLoaded: state.scenario.areBaselinesLoaded,
    };
};

const mapDispatchToProps = {
    getSampleScenario: actions.getSampleScenario,
    editScenario: actions.editScenario,
    loadScenario: actions.loadScenario,
    createScenario: actions.createScenario,
    deleteScenario: actions.deleteScenario,
    getUserScenarios: actions.getUserScenarios,
    onboardingScenario: actions.onboardingScenario,
    setFocus: actions.setFocus,
    closeOption: actions.closeOption,
    changeSelectedOption: actions.changeSelectedOption,
    showOptionPicker: actions.showOptionPicker,
    createBaseline: actions.createBaseline,
    updateBaseline: actions.updateBaseline,
    deleteBaseline: actions.deleteBaseline,
    getBaseline: actions.getBaseline,
    updateBaselineCanvas: actions.updateBaselineCanvas,
    setBaselineManager: actions.setBaselineManager,
    setBaselineDataManager: actions.setBaselineDataManager,
    getUserData: actions.getUserData,
    toggleShowAgencyBaselineView: actions.toggleShowAgencyBaselineView,
    updateClient: actions.updateClient,
    addClient: actions.addClient,
};

const Baseline = connect(
    mapStateToProps,
    mapDispatchToProps
)(BaselineContainer);

export default Baseline;

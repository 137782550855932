export const SHOW_ACCOUNT_CARD_MODAL = "SHOW_ACCOUNT_CARD_MODAL";
import type { AppThunk } from "store";

export const openAccountCardModal = (
    cardId: string | null = null
): AppThunk<void> => {
    return (dispatch) => {
        dispatch({
            type: SHOW_ACCOUNT_CARD_MODAL,
            payload: {
                createAccountCardModalOpen: true,
                cardId: cardId,
            },
        });
    };
};

export const closeAccountCardModal = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_ACCOUNT_CARD_MODAL,
            payload: {
                createAccountCardModalOpen: false,
            },
        });
    };
};

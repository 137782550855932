import type {
    CalculatedThread,
    CalculatedThreadsResponseSchema,
    CalculatedThreadsSchema,
} from "reducers/typesSchema/calculatedThreadsSchema";
import colors from "exampleConfigJson/threadColorsScheme.json";

/**
 * Receives raw response from Decision Engine and attaches `color` and `option` to the calculatedThreads response.
 */
export function getThreadsAlias(
    calculatedThreadsResponse: CalculatedThreadsResponseSchema
): CalculatedThreadsSchema {
    const allThreads = Object.values(calculatedThreadsResponse);

    const threadsAlias = {};
    allThreads.forEach((thread, i) => {
        const counter = allThreads.length;
        const option = thread.name || `Scenario Thread ${counter - i}`;
        const numColors = colors?.length;
        // colorsIndex formula is circular array formula; in case numThreads > numColors
        const colorsIndex = (i + numColors) % numColors || 0;
        const color = colors[colorsIndex];
        const threadData: CalculatedThread = {
            ...thread,
            option,
            color,
        };
        threadsAlias[thread.id] = threadData;
    });

    return threadsAlias;
}

import { getRelevantEntities } from "actions/getNodeEntityActions";
import { customerObject } from "Components/Registry/Customer";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment"

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const customerTransferInputsHandler = (
    value: string,
    id:
        | "constantValue"
        | "entityName"
        | "startDate"
        | "endDate"
        | "interpolateSubType"
        | "rating"
        | "targetCustomer"
        | "sourceCustomer"
        | "cadence"
        | "modType"
        | "value"
        | "maxGrowth",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    switch (id) {
        case "value":
            data.value = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "targetCustomer":
            const parsedTargetCustomer = JSON.parse(value || "{\"eventId\": \"\", \"entityIds\": []}")

            handleDependencies(dependencyMap, parsedTargetCustomer, eventId, currentEntityObject.id, data.targetCustomerEventId, data.targetCustomerIds)

            data.selectedTargetCustomer = parsedTargetCustomer
            data.targetCustomerEventId = parsedTargetCustomer?.eventId ?? ""
            data.targetCustomerIds = parsedTargetCustomer?.entityIds ?? []
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "sourceCustomer":
            // Can be customer or websiteVisitor
            const parsedSourceCustomer = JSON.parse(value || "{\"eventId\": \"\", \"entityIds\": []}")

            handleDependencies(dependencyMap, parsedSourceCustomer, eventId, currentEntityObject.id, data.sourceCustomerEventId, data.sourceCustomerIds)

            const sourceEntity = getRelevantEntities(parsedSourceCustomer.entityIds ?? [])[parsedSourceCustomer.entityIds[0] ?? ""]

            sourceEntity?.type === customerObject?.constant() ? data.targetType = "customer" : data.targetType = "websiteVisitor"

            data.selectedSourceCustomer = parsedSourceCustomer
            data.sourceCustomerEventId = parsedSourceCustomer?.eventId ?? ""
            data.sourceCustomerIds = parsedSourceCustomer?.entityIds ?? []
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "constantValue":
            data.constantValue = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "modType":
            data.modType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate": 
            const startDateString = value ? moment(value).format("YYYY-MM-DD") : ""
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate": 
            const endDateString = value ? moment(value).format("YYYY-MM-DD") : ""
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interpolateSubType":
            data.interpolateSubType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            if (value === "") {
                currentEntityObject.cadence = "";
                newEntitiesMap[currentEntity] = currentEntityObject;
            } else {
                currentEntityObject.cadence = value;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "maxGrowth":
            data.maxGrowth = value
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

import React, { Component } from "react";
import "./Card.css";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import { processNodeValueForCanvasDisplay } from "../../helpers";
import { getRelevantEntities } from "actions/getNodeEntityActions";

class BankCard extends Component {
    state = {
        showOptions: false,
        bankSelected: 0,
        toggleAccount: [],
        showSettings: false,
    };

    toggleAccount = (e) => {
        const id = parseInt(e.target.id);
        const { toggleAccount } = this.state;

        if (toggleAccount.includes(id)) {
            const removeId = toggleAccount.filter((item) => {
                return item !== id;
            });
            this.setState({ toggleAccount: removeId });
        } else {
            this.setState({ toggleAccount: [...toggleAccount, id] });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        // As of right now we are just grabbing and displaying the first entity
        // TODO: Add the ability to scroll through all entities, similar to the input container
        const entity = Object.values(
            getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            )
        )[0];
        const { data } = entity;
        const { toggleAccount } = this.state;
        const { version } = data;

        return (
            <div className="Container">
                <div className="Row">
                    <div className="BankLabel">Type</div>
                    <div className="BankSubLabel">Bank Account</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                <div className="Row">
                    <div className="accountLabel">
                        {data.bankData && data.bankData.Accounts.length}{" "}
                        Accounts, {data.selected && data.selected.length} Added
                    </div>
                </div>
                <div className="selectedAccountsContainer">
                    {data.selected &&
                        data.selected.map((account, i) => {
                            const isToggled = toggleAccount.includes(i);
                            return (
                                <div key={i}>
                                    <div key={i} className="selectedAccounts">
                                        <div className="nicknameContainer">
                                            <img
                                                alt="alt"
                                                src={
                                                    isToggled
                                                        ? dropdownSvg
                                                        : expandSvg
                                                }
                                                onClick={this.toggleAccount}
                                                id={i}
                                                className="selectedIcon"
                                            />
                                            <div className="accountsNickname">
                                                {i + 1}.{" "}
                                                {account.Nickname
                                                    ? account.Nickname
                                                    : account.Title}
                                            </div>
                                        </div>
                                        <div className="balanceContainer">
                                            {account.Currency}{" "}
                                            {processNodeValueForCanvasDisplay(
                                                account.Balance.Current
                                            )}
                                        </div>
                                    </div>
                                    {isToggled && (
                                        <div className="popUpContainer">
                                            <div className="Row">
                                                <div className="accountKey">
                                                    Account #
                                                </div>
                                                <div className="accountValue">
                                                    {account.AccountNumber}
                                                </div>
                                            </div>
                                            <div className="Row">
                                                <div className="accountKey">
                                                    Type
                                                </div>
                                                <div className="accountValue">
                                                    {account.Type}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                {/* <div className="Row">
                    <img
                        alt="alt"
                        onClick={this.onHandleAdvanceSetting}
                        className="selectedIcon"
                        src={showSettings ? dropdownSvg : expandSvg}
                    />
                    <div className="advanceSettingLabel">Advance settings</div>
                </div> */}
            </div>
        );
    }
}

export default BankCard;

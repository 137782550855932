/**
 * After re-scaling the stage, finds the new center position
 */
export function getStageNewCenter(
    stageWidth: number,
    stageHeight: number,
    stageX: number,
    stageY: number,
    curStageScale: number,
    newStageScale: number,
    leftOffset: number,
    topOffset: number
): { x: number; y: number } {
    const center = {
        x: leftOffset + stageWidth / 2,
        y: topOffset + stageHeight / 2,
    };

    const relatedTo = {
        x: (center?.x - stageX) / curStageScale,
        y: (center?.y - stageY) / curStageScale,
    };

    const newPos = {
        x: center?.x - relatedTo?.x * newStageScale,
        y: center?.y - relatedTo?.y * newStageScale,
    };

    return newPos;
}

import React, { Component } from "react";
import { valueDoesNotExist } from "../../helpers";
import { throwError } from "../../helpers/swalError";
import "./index.css";

import { templateCategories } from "../../Components/CreateScenarioModal/template";
import { B } from ".";

export default class PublishTemplate extends Component {
    constructor(props) {
        super(props);
        const categoriesToInclude = {};
        templateCategories.forEach((category) => {
            categoriesToInclude[category.id] = false;
        });
        categoriesToInclude.sample = false;
        this.state = { categoriesToInclude };
    }

    selectScenario = (e) => {
        const selected = this.props.userScenarios.find((entry) => {
            return entry.id === e.target.value;
        });

        this.setState({
            scenario: selected,
            name: selected.name,
            description: selected.description,
            range: selected.range,
            inflation: selected.inflation * 100,
            subtitle: selected.subtitle,
            video_link: selected.video_link,
            is_template: false,
            is_tutorial: false,
            is_sample: false,
        });
    };

    onHandleInput = (e) => {
        const value = e.target.value;
        switch (e.target.id) {
            case "inflation":
                this.setState({ inflation: value });
                break;
            case "range":
                this.setState({ range: value });
                break;
            case "name":
                this.setState({ name: value });
                break;
            case "subtitle":
                this.setState({ subtitle: value });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "videoLink":
                let processedValue = value;

                let index, indexOne, indexTwo, videoLink, linkType;

                if (processedValue.includes("watch")) {
                    linkType = "youtube-long";
                } else if (processedValue.includes("youtu.be")) {
                    linkType = "youtube-short";
                } else if (
                    processedValue.includes("share") &&
                    processedValue.includes("personal_library")
                ) {
                    linkType = "loom-long";
                } else if (processedValue.includes("share")) {
                    linkType = "loom-short";
                }

                switch (linkType) {
                    case "youtube-long":
                        processedValue = value.replace("watch?v=", "embed/");
                        break;
                    case "youtube-short":
                        index = value.indexOf(".be") + 4;
                        videoLink = value.substring(index, value.length);
                        processedValue = `https://www.youtube.com/embed/${videoLink}`;
                        break;
                    case "loom-long":
                        indexOne = value.indexOf("share") + 6;
                        indexTwo = value.indexOf("?");
                        videoLink = value.substring(indexOne, indexTwo);
                        processedValue = `https://www.loom.com/embed/${videoLink}`;
                        break;
                    case "loom-short":
                        index = value.indexOf("share") + 6;
                        videoLink = value.substring(index, value.length);
                        processedValue = `https://www.loom.com/embed/${videoLink}`;
                        break;
                    default:
                }

                this.setState({ video_link: processedValue });
                break;
            default:
        }
    };

    handleCheckbox = (e) => {
        const id = e.target.id;
        const categoriesToInclude = this.state.categoriesToInclude;
        categoriesToInclude[id] = !categoriesToInclude[id];
        this.setState({ categoriesToInclude });

        if (id === "sample")
            this.setState({ is_sample: !this.state.is_sample });
        if (id === "tutorial")
            this.setState({ is_tutorial: !this.state.is_tutorial });
        if (id === "template")
            this.setState({ is_template: !this.state.is_template });
    };

    createTemplate = () => {
        const {
            scenario,
            name,
            description,
            range,
            inflation,
            video_link,
            subtitle,
            categoriesToInclude,
            is_sample,
            is_tutorial,
            is_template,
        } = this.state;
        let noCategoriesSelected = true;
        Object.values(categoriesToInclude).forEach((isIncluded) => {
            noCategoriesSelected = noCategoriesSelected && !isIncluded;
        });
        if (
            noCategoriesSelected ||
            !scenario ||
            !name ||
            !range ||
            !subtitle ||
            valueDoesNotExist(inflation)
        ) {
            throwError("error", "Incomplete Inputs");
        } else {
            const categories = [];
            Object.keys(categoriesToInclude).forEach((category) => {
                if (categoriesToInclude[category]) categories.push(category);
            });
            this.props.createTemplate({
                name,
                subtitle,
                description,
                range,
                inflation: inflation / 100,
                video_link,
                data: scenario.data,
                custom_start_date: scenario.custom_start_date,
                start_date_preference: scenario.start_date_preference,
                categories,
                is_sample,
                is_tutorial,
                is_template,
            });
        }
    };

    render() {
        const { userScenarios } = this.props;
        const {
            scenario,
            name,
            description,
            range,
            inflation,
            subtitle,
            video_link,
        } = this.state;
        return (
            <div className="adminContainer">
                <div className="adminBox">
                    <div className="adminLabel">Publish a New Template</div>
                    <div>
                        <div className="selectScenarioLabel">
                            <B>Select a scenario:</B>
                        </div>
                        <select
                            value={scenario && scenario.id}
                            onChange={this.selectScenario}
                        >
                            {userScenarios.map((entry) => {
                                return (
                                    <option key={entry.id} value={entry.id}>
                                        {entry.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="adminFieldsContainer">
                        {scenario && (
                            <div className="scenarioBox">
                                <div className="scenarioBoxInfoWrapper">
                                    <B>Name</B>: {scenario.name}{" "}
                                </div>
                                <div className="scenarioBoxInfoWrapper">
                                    <B>Subtitle</B>: {scenario.subtitle}{" "}
                                </div>
                                <div className="scenarioBoxInfoWrapper">
                                    <B>ID:</B> {scenario.id}
                                </div>

                                <div className="scenarioBoxInfoWrapper">
                                    <B>Number of</B> Events:{" "}
                                    {scenario.data.nodes.length}{" "}
                                </div>

                                <div className="scenarioBoxInfoWrapper">
                                    <B>Inflation Rate</B>: {scenario.inflation}{" "}
                                    %
                                </div>

                                <div className="scenarioBoxInfoWrapper">
                                    <B>Range:</B> {scenario.range} years
                                </div>

                                <div className="scenarioBoxInfoWrapper">
                                    <B>Description:</B> {scenario.description}{" "}
                                </div>

                                <div className="scenarioBoxInfoWrapper">
                                    <B>Video Link:</B> {scenario.video_link}
                                </div>
                            </div>
                        )}

                        <div className="adminInputFieldsWrapper">
                            <div className="adminLabelInputWrapper">
                                <B>Name:</B>
                                <input
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={this.onHandleInput}
                                    className="adminInputField"
                                />
                            </div>
                            <div className="adminLabelInputWrapper">
                                <B>Subtitle: </B>
                                <input
                                    id="subtitle"
                                    type="text"
                                    value={subtitle}
                                    onChange={this.onHandleInput}
                                    className="adminInputField"
                                />
                            </div>
                            <div className="adminLabelInputWrapper">
                                <B>Description:</B>
                                <textarea
                                    id="description"
                                    type="textarea"
                                    value={description}
                                    onChange={this.onHandleInput}
                                    className="adminInputFieldDescription"
                                />
                            </div>
                            <div className="adminLabelInputWrapper">
                                <B>Range (years):</B>
                                <input
                                    id="range"
                                    type="number"
                                    value={range}
                                    onChange={this.onHandleInput}
                                    className="adminInputField"
                                />
                            </div>
                            <div className="adminLabelInputWrapper">
                                <B>Inflation (%):</B>
                                <input
                                    id="inflation"
                                    type="number"
                                    value={inflation}
                                    onChange={this.onHandleInput}
                                    className="adminInputField"
                                />
                            </div>
                            <div className="adminLabelInputWrapper">
                                <B>Video Link:</B>
                                <input
                                    id="videoLink"
                                    type="text"
                                    value={video_link}
                                    onChange={this.onHandleInput}
                                    className="adminInputField"
                                />
                            </div>
                        </div>

                        <div className="adminCategoriesBoxWrapper">
                            <B>Select Categories:</B>
                            <br />
                            <span style={{ display: "flex", width: "300px" }}>
                                * The following categories determine what
                                category the scenario gets placed under when
                                publishing a template scenario. *
                            </span>
                            <div className="adminCategoriesWrapper">
                                {templateCategories.map((category) => {
                                    return (
                                        <div
                                            key={category.id}
                                            className="adminCategoryWrapper"
                                        >
                                            <input
                                                type="checkbox"
                                                id={category.id}
                                                onChange={this.handleCheckbox}
                                            />
                                            <label
                                                htmlFor={category.id}
                                                className="adminCategoryLabel"
                                            >
                                                {category.name}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="adminCategoryWrapper">
                                <input
                                    type="checkbox"
                                    id="template"
                                    onChange={this.handleCheckbox}
                                />
                                <label
                                    htmlFor="template"
                                    className="adminCategoryLabel"
                                >
                                    <B> Include as template scenario?</B>
                                </label>
                            </div>
                            <span style={{ display: "flex", width: "300px" }}>
                                * A tutorial scenario is a type of sample
                                scenario. *
                            </span>
                            <span style={{ display: "flex", width: "300px" }}>
                                * If you wish to publish a tutorial scenario,
                                please check both the sample scenario and
                                tutorial scenario checkboxes. *
                            </span>
                            <div className="adminCategoryWrapper">
                                <input
                                    type="checkbox"
                                    id="sample"
                                    onChange={this.handleCheckbox}
                                />
                                <label
                                    htmlFor="sample"
                                    className="adminCategoryLabel"
                                >
                                    <B> Include as sample scenario?</B>
                                </label>
                            </div>
                            <div className="adminCategoryWrapper">
                                <input
                                    type="checkbox"
                                    id="tutorial"
                                    onChange={this.handleCheckbox}
                                />
                                <label
                                    htmlFor="tutorial"
                                    className="adminCategoryLabel"
                                >
                                    <B> Include as sample tutorial?</B>
                                </label>
                            </div>
                            <button
                                onClick={this.createTemplate}
                                className="adminSubmitButton"
                            >
                                Create Template
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import ConstraintInputView from "./ConstraintInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent, MutableRefObject } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { constraintInputsHandler } from "../OnInputChangeHandlers/constraintInputsHandler";
import { constraintObject } from "Components/Registry/Constraint";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import modifierEntityMap from "helpers/modifierEntityMap.json";
import { getPresentableDependenciesOfManyTypes } from "helpers/nodeDependencyDetectionHelpers";
import { throwError } from "helpers/swalError";
import moment from "moment";

export default function ConstraintInput({ line, edit, editData, focus }) {
    const dispatch = useAppDispatch();

    const loadedScenario: MutableRefObject<ScenarioSchema> = useRef({
        ...useAppSelector((state) => state?.scenario?.loadedScenario),
    });
    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...loadedScenario.current?.dependency_map,
    });

    const eventId = useContext(EventInputIDContext);

    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = getDefaultName(constraintObject.name(), propsObject);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(constraintObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(
            entitiesObject,
            eventData,
            edit,
            constraintObject,
            dependencyMap.current
        );

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "entityName"
            | "eventType"
            | "event"
            | "entity"
            | "property"
            | "minValue"
            | "maxValue"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = constraintInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = constraintInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const toggleCustomEffectPeriod = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data.customEffectPeriod === true) {
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";
        }

        data.customEffectPeriod = !data.customEffectPeriod;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getEntitySummary = (currentEntity) => {
        const targetEntityData = Object.values(
            getRelevantEntities(currentEntity?.data?.selectedIds ?? [])
        )?.[0];
        if (currentEntity?.data?.minValue && currentEntity?.data?.maxValue)
            return `Constraint the existing ${
                currentEntity?.data?.property ?? "Property Error"
            } for ${
                targetEntityData
                    ? targetEntityData?.name
                    : `${currentEntity?.data?.selectedEventName} - All`
            } between a minimum of ${
                currentEntity?.data?.minValue ?? "Value Error"
            } and a maximum of ${
                currentEntity?.data?.maxValue ?? "Value Error"
            } ${
                currentEntity?.data?.customEffectPeriod
                    ? ` from ${moment(currentEntity?.startDate).format(
                          "MMM DD, YYYY"
                      )}${
                          currentEntity?.endDate !== ""
                              ? ` to ${moment(currentEntity?.endDate).format(
                                    "MMM DD, YYYY"
                                )}`
                              : ""
                      }`
                    : ""
            }.`;
        if (currentEntity?.data?.minValue)
            return `Constraint the existing ${
                currentEntity?.data?.property ?? "Property Error"
            } for ${
                targetEntityData
                    ? targetEntityData?.name
                    : `${currentEntity?.data?.selectedEventName} - All`
            } to a minimum of ${
                currentEntity?.data?.minValue ?? "Value Error"
            } ${
                currentEntity?.data?.customEffectPeriod
                    ? ` from ${moment(currentEntity?.startDate).format(
                          "MMM DD, YYYY"
                      )}${
                          currentEntity?.endDate !== ""
                              ? ` to ${moment(currentEntity?.endDate).format(
                                    "MMM DD, YYYY"
                                )}`
                              : ""
                      }`
                    : ""
            }.`;
        if (currentEntity?.data?.maxValue)
            return `Constraint the existing ${
                currentEntity?.data?.property ?? "Property Error"
            } for ${
                targetEntityData
                    ? targetEntityData?.name
                    : `${currentEntity?.data?.selectedEventName} - All`
            } to a maximum of ${
                currentEntity?.data?.maxValue ?? "Value Error"
            } ${
                currentEntity?.data?.customEffectPeriod
                    ? ` from ${moment(currentEntity?.startDate).format(
                          "MMM DD, YYYY"
                      )}${
                          currentEntity?.endDate !== ""
                              ? ` to ${moment(currentEntity?.endDate).format(
                                    "MMM DD, YYYY"
                                )}`
                              : ""
                      }`
                    : ""
            }.`;
        return "Please enter some values for the minimum and maximum constraints.";
    };

    const getValidEvents = () => {
        const events = {};

        if (!propsObject.eventId && !propsObject.line) return events;

        const validEventType: string[] = Object.keys(modifierEntityMap);

        getPresentableDependenciesOfManyTypes(
            events,
            validEventType,
            propsObject,
            "Modifiable",
            true
        );

        if (!Object.keys(events).length) {
            throwError(
                "Warning",
                "No Modifiable nodes found upstream",
                "You can still add this event to this scenario as a placeholder for future calculations"
            );
        }
        //@ts-ignore
        const eventObjects = Object.values(events)?.map((eventId: string) =>
            getEvent(eventId)
        );

        const eventsMappedByType = {};
        eventObjects?.map((event) => {
            const eventTypeName = getObjectFromUUID(event?.type)?.name();
            if (!eventsMappedByType[eventTypeName]) {
                eventsMappedByType[eventTypeName] = [];
            }
            eventsMappedByType[eventTypeName].push(event?.id);
        });
        return eventsMappedByType;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.eventsMappedByType =
                getValidEvents();
            return newEntitiesMap;
        });
        // setEntitiesMap should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = constraintObject.checkInput(entitiesMap);

    return (
        <ConstraintInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            toggleCustomEffectPeriod={toggleCustomEffectPeriod}
            handleDateSelection={handleDateSelection}
            getEntitySummary={getEntitySummary}
        />
    );
}

import { adminPlanIds, freePlan } from "./constants";

const valueExists = (value) => {
    return value !== null && value !== undefined;
};

export const getMaxEventsPerScenario = (plan) => {
    if (plan && valueExists(plan.meta_data.events_count)) {
        return Number(plan.meta_data.events_count);
    } else {
        return freePlan.events_count;
    }
};

export const getMaxScenarioCount = (plan) => {
    if (plan && valueExists(plan.meta_data.scenario_count)) {
        return Number(plan.meta_data.scenario_count);
    } else {
        return freePlan.scenario_count;
    }
};

export const getMaxScenarioShares = (plan) => {
    if (plan && valueExists(plan.meta_data.share_scenario_count)) {
        return Number(plan.meta_data.share_scenario_count);
    } else {
        return freePlan.share_scenario_count;
    }
};

export const getMaxProEvents = (plan) => {
    if (plan && valueExists(plan.meta_data.pro_events_count)) {
        return Number(plan.meta_data.pro_events_count);
    } else {
        return freePlan.pro_events_count;
    }
};

export const getPlanName = (plan) => {
    if (plan && valueExists(plan.meta_data.displayName)) {
        return plan.meta_data.displayName;
    } else {
        return freePlan.name;
    }
};

export const getPlanBillingString = (plan) => {
    if (plan && valueExists(plan.meta_data.billingString)) {
        return plan.meta_data.billingString;
    } else {
        return freePlan.billingString;
    }
};


/**
 * FUNCTIONS FOR FEATURE-FLAGGING ADMIN FUNCTIONALITY
 * 
 * These are __NOT__ a replacement for validation of permissions
 * on the back-end, and should only be used to hide UI elements from
 * regular users.
 * 
 * 
 * @param {object} user 
 */
export const isUserAdmin = (user) => {
    if (!user || !user.plan || !user.plan.id) {
        return false;
    } else if (adminPlanIds.includes(user.plan.id)) {
        return true;
    } else {
        return false;
    }
}
export const isActiveUserAdmin = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    return isUserAdmin(loggedInUser);
};

//  eslint-disable-next-line @typescript-eslint/ban-ts-comment
//  @ts-nocheck

import LocationSearchInput from "../../LocationSearchInput";
import SingleCalendar from "../../Calendar/singleCalendar";
import radioButtonOff from "../../../Assets/_nodeInputs/radioButtonOff.svg";
import radioButtonOn from "../../../Assets/_nodeInputs/radioButtonOn.svg";
import {
    EventEntityWrapper,
    EventDetails,
    InputButtons,
    StarsRating,
    EventHeader,
    EntityCard,
    EntityName,
    Value,
    Rate,
    Url,
    Description,
    ModalRow,
    RequiredStar,
} from "../Components";
import houseInputsHandler from "../OnInputChangeHandlers/houseInputsHandler";
import type { ChangeEvent } from "react";
import { houseObject } from "../../Registry/House";
import Switch from "react-switch";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

export default function HouseInputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    eventData,
    entitiesLength,
    onChangeNameDescription,
    onHandleFirstPayment,
    onHandleStarHover,
    onHandleLocation,
    onMouseLeave,
    updateAppreciation,
    edit,
    passedCheck,
    onHandleSubmit,
}) {
    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "entityName"
            | "address"
            | "price"
            | "mlsNumber"
            | "url"
            | "rating"
            | "appreciationRate",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = houseInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={houseObject.constant()}
                    onChangeInput={onChangeNameDescription}
                    image={houseObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[houseObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                    />
                </ModalRow>
                <div
                    className="ModalRow"
                >
                    <div className="oneThird">
                        <div className="Required">*</div>
                        <div className="PTTLabel">Primary Residence</div>
                    </div>
                    <div className="oneThird">
                        <img
                            alt="alt"
                            onClick={() =>
                                handleOnChange(
                                    { target: { value: "yes" } },
                                    "primaryResidence",
                                    0
                                )
                            }
                            src={
                                entitiesMap?.[currentEntity]?.data
                                    ?.primaryResidence === "yes"
                                    ? radioButtonOn
                                    : radioButtonOff
                            }
                            className="pttRadioButton"
                        />
                        <div className="PTTValueLabel">Yes</div>
                        <img
                            alt="alt"
                            onClick={() =>
                                handleOnChange(
                                    { target: { value: "no" } },
                                    "primaryResidence",
                                    0
                                )
                            }
                            id="primaryResidence"
                            src={
                                entitiesMap?.[currentEntity]?.data
                                    ?.primaryResidence === "no"
                                    ? radioButtonOn
                                    : radioButtonOff
                            }
                            className="pttRadioButton"
                        />
                        <div className="PTTValueLabel">No</div>
                    </div>
                </div>
                <div
                    className="ModalRow"
                >
                    <div className="oneThird">
                        <div className="Required">*</div>
                        <div className="PTTLabel">Newly Built Home</div>
                    </div>
                    <div className="oneThird">
                        <img
                            alt="alt"
                            onClick={() =>
                                handleOnChange(
                                    { target: { value: "yes" } },
                                    "newlyBuiltHome",
                                    0
                                )
                            }
                            src={
                                entitiesMap?.[currentEntity]?.data
                                    ?.newlyBuiltHome === "yes"
                                    ? radioButtonOn
                                    : radioButtonOff
                            }
                            className="pttRadioButton"
                        />
                        <div className="PTTValueLabel">Yes</div>
                        <img
                            alt="alt"
                            onClick={() =>
                                handleOnChange(
                                    { target: { value: "no" } },
                                    "newlyBuiltHome",
                                    0
                                )
                            }
                            src={
                                entitiesMap?.[currentEntity]?.data
                                    ?.newlyBuiltHome === "no"
                                    ? radioButtonOn
                                    : radioButtonOff
                            }
                            className="pttRadioButton"
                        />
                        <div className="PTTValueLabel">No</div>
                    </div>
                </div>
                <div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <LocationSearchInput
                            id="address"
                            handleLocation={onHandleLocation}
                            value={entitiesMap?.[currentEntity]?.data?.address}
                            edit={edit}
                        />
                    </div>
                    <div className="ModalRowSpaceBetween">
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <Value
                                label="Purchase Price"
                                onChangeInput={handleOnChange}
                                value={
                                    entitiesMap?.[currentEntity]?.data?.price
                                }
                                id="price"
                                unit="$"
                            />
                        </div>
                        <div className="ExpenseAmountContainer">
                            <Rate
                                value={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.mlsNumber
                                }
                                label="MLS #"
                                className="mlsInput"
                                id="mlsNumber"
                                onChangeInput={handleOnChange}
                                sign={false}
                            />
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Url
                            value={entitiesMap?.[currentEntity]?.data?.url}
                            className="UrlLink"
                            onChangeInput={handleOnChange}
                            id="url"
                            label="URL"
                        />
                    </div>
                </div>

                <div className="ModalRowSpaceBetween">
                    <div className="ExpenseAmountContainer">
                        <div className="PropertyValueInflationText">
                            Appreciation Rate
                        </div>
                        <Switch
                            id="appreciation"
                            checked={
                                entitiesMap?.[currentEntity]?.data?.inflation
                            }
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={updateAppreciation}
                        />
                    </div>
                    <div className="ExpenseAmountContainer">
                        <div
                            className={
                                entitiesMap?.[currentEntity]?.data
                                    ?.applyAppreciation
                                    ? "PVRateActive"
                                    : "PVRate"
                            }
                        >
                            <input
                                className={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.applyAppreciation
                                        ? "PVInput"
                                        : "PVInputDisabled"
                                }
                                value={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.appreciationRate
                                }
                                id="appreciationRate"
                                onChange={(e) =>
                                    handleOnChange(e, "appreciationRate", 0)
                                }
                            />{" "}
                            %
                        </div>
                    </div>
                </div>

                <div className="ModalRow">
                    <div className="Required">*</div>
                    <div className="keyLabel">Purchase Date</div>
                    <SingleCalendar
                        onHandleDate={onHandleFirstPayment}
                        date={
                            entitiesMap?.[currentEntity]?.data?.[
                            "first payment"
                            ]
                        }
                        entityIndex={entityIndex}
                    />
                </div>

                <ModalRow>
                    <StarsRating
                        onHoverStar={
                            entitiesMap[currentEntity].data.onHoverStar
                        }
                        onHandleStarHover={onHandleStarHover}
                        rating={entitiesMap?.[currentEntity]?.data?.rating}
                        onChangeInput={handleOnChange}
                        onMouseLeave={onMouseLeave}
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

export const getCadenceUnit = (cadence, numUnit = 0) => {
    const num = Number(numUnit);
    switch (cadence) {
        case "annually":
            return num !== 1 ? "years" : "year";
        case "quarterly":
            return num !== 1 ? "quarters" : "quarters";
        case "monthly":
        case "semi-monthly":
            return num !== 1 ? "months" : "month";
        case "bi-weekly":
        case "weekly":
            return num !== 1 ? "weeks" : "week";
        case "daily":
            return num !== 1 ? "days" : "day";
        case "one-time":
        default:
            return "";
    }
};

import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

const cellFormatter = (value, formatting) => {
    if (!formatting) return value;
    let formattedValue = value;
    if (formatting?.type === "numerical") {
        const numberValue = parseFloat(value).toFixed(2);
        const formNumber = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        formattedValue = formNumber;
    }
    if (formatting?.displaySign) {
        if (formatting?.signLocation === "before") {
            formattedValue = `${formatting?.sign ?? ""}${
                formatting?.includeSignSpace ? " " : ""
            }${formattedValue}`;
        } else if (formatting?.signLocation === "after") {
            formattedValue = formattedValue = `${formattedValue}${
                formatting?.includeSignSpace ? " " : ""
            }${formatting?.sign ?? ""}`;
        }
    }
    return formattedValue;
};

export const getEntityColumnData = (eventId) => {
    const eventData = getEvent(eventId);

    const eventObject = getObjectFromUUID(eventData?.type);
    if (!eventData?.entities) return [];
    const { entityFieldsMap, entityDataFieldsMap, displayOnly } =
        eventObject?.generateDisplayFieldData(
            getRelevantEntities(eventData?.entities)
        );

    const allColumns: any[] = [
        {
            headerName: "Id",
            field: "id",
            pinned: "left",
            hide: true,
        },
    ];

    const createFieldsMapColumns = (fieldMap) => {
        for (const dataKey in fieldMap) {
            const keyData = fieldMap[dataKey];
            const columnObject = {
                headerName: keyData?.displayName,
                field: dataKey,
                hide: keyData?.hide,
                rowDrag: keyData?.rowDrag,
                checkboxSelection: keyData?.checkboxSelection,
                cellRenderer: keyData?.cellRenderer ?? null,
                cellRendererParams: {
                    cellRendererDropDownOptions:
                        keyData?.cellRendererDropDownOptions ?? [],
                    conditionalFieldName: keyData?.conditionalFieldName ?? null,
                    conditionalFieldConditionValue:
                        keyData?.conditionalFieldConditionValue ?? null,
                    cellRendererWaterfallTopLevelFilters:
                        keyData?.cellRendererWaterfallTopLevelFilters ?? {},
                },
                valueFormatter: (params) => {
                    if (!params?.value && params?.value !== 0) return;
                    return cellFormatter(params?.value, keyData?.formatting);
                },
                editable: (params) => {
                    if (displayOnly) return false;
                    if (keyData?.editable) return true;
                    if (keyData?.cellRenderer) return false;
                    const rowData = params.data;

                    const conditionalFieldName =
                        keyData?.conditionalFieldName ?? null;
                    const conditionalFieldConditionValue =
                        keyData?.conditionalFieldConditionValue ?? null;
                    const conditionalFieldValue =
                        rowData?.[conditionalFieldName] ?? null;

                    if (
                        conditionalFieldValue &&
                        conditionalFieldConditionValue &&
                        String(conditionalFieldValue) ===
                            String(conditionalFieldConditionValue)
                    ) {
                        return true;
                    }
                    if (!rowData?.containsModifier && dataKey === "cadence")
                        return true;
                    return false;
                },
                cellClassRules: {
                    isModified: (params) => {
                        const existingModifiers =
                            params?.data?.existingModifiers;
                        if (
                            existingModifiers &&
                            existingModifiers?.length > 0
                        ) {
                            let relatedModifier = false;
                            existingModifiers?.forEach((existingModifier) => {
                                if (existingModifier?.keyToModify === dataKey)
                                    relatedModifier = true;
                            });
                            return relatedModifier;
                        }
                        return false;
                    },
                    disableEdit: (params) => {
                        const columnDefs = params?.colDef;
                        return !columnDefs?.editable(params);
                    },
                    conditionalTarget: () => {
                        return keyData?.conditionalTarget;
                    },
                    conditionalFieldActive: (params) => {
                        const rowData = params.data;
                        const conditionalFieldName =
                            keyData?.conditionalFieldName ?? null;
                        const conditionalFieldConditionValue =
                            keyData?.conditionalFieldConditionValue ?? null;
                        const conditionalFieldValue =
                            rowData?.[conditionalFieldName] ?? null;
                        if (
                            conditionalFieldValue &&
                            conditionalFieldConditionValue &&
                            String(conditionalFieldValue) ===
                                String(conditionalFieldConditionValue)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
            };

            if (dataKey === "name") {
                columnObject["pinned"] = "left";
                columnObject["lockPinned"] = true;
            }
            allColumns.push(columnObject);
        }
    };

    createFieldsMapColumns(entityFieldsMap);
    createFieldsMapColumns(entityDataFieldsMap);

    return allColumns;
};

export const getEntityRowData = (eventId, entitiesMap: EntitiesSchema) => {
    const eventData = getEvent(eventId);

    if (!eventData?.entities) return;
    const entitiesDataArray = Object.values(entitiesMap);

    const eventObject = getObjectFromUUID(eventData?.type);
    const { entityFieldsMap, entityDataFieldsMap } =
        eventObject?.generateDisplayFieldData(entitiesMap);

    const allEntityDataRows: any = [];

    entitiesDataArray.forEach((entity) => {
        const entityRowData = {
            id: entity?.id,
        };

        const topLevelKeys = Object.keys(entityFieldsMap);

        if (topLevelKeys?.length > 0) {
            topLevelKeys.forEach((topLevelKey) => {
                const topLevelValue = entity?.[topLevelKey];
                entityRowData[topLevelKey] = topLevelValue;
            });
        }

        const dataKeys = Object.keys(entityDataFieldsMap);
        const entityData = entity?.data;
        if (entityData?.modsCreated?.length > 0) {
            entityRowData["containsModifier"] = true;
            entityRowData["existingModifiers"] = entityData?.modsCreated;
        }

        if (dataKeys?.length > 0) {
            dataKeys.forEach((dataKey) => {
                const dataValue = entityData?.[dataKey];
                entityRowData[dataKey] = dataValue;
            });
        }
        allEntityDataRows.push(entityRowData);
    });

    return allEntityDataRows;
};

import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import NullInputView from "./ContainerInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import type { ChangeEvent } from "react";
import { containerObject } from "Components/Registry/Container";
import { camelCase } from "lodash";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { getEvent } from "actions/getNodeEntityActions";
import { shallowEqual } from "react-redux";

export default function ContainerInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const selectedEvents = useAppSelector((state) => {
        const selectedEvents = state?.scenario?.selectedEvents;
        const selectedEventIdsArray = Object.keys(selectedEvents ?? {});

        const canvasSelectedEventIds = selectedEventIdsArray?.filter(
            (eventId) => selectedEvents?.[eventId] === "canvas"
        );
        const eventDataMap = {};
        canvasSelectedEventIds?.forEach((eventId) => {
            const eventData: any = getEvent(eventId);
            eventDataMap[eventId] = eventData.exportData();
        });
        return eventDataMap;
    }, shallowEqual);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(containerObject);
            _eventData.name = getDefaultName(
                containerObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(entitiesObject, eventData, edit);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = useMemo(
        () => ({ manager, line, focus }),
        [focus, line, manager]
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...currentEntityObject.data };

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                const finalString = camelCase(value);
                data.tag = `@${finalString}`;
                currentEntityObject.name = value;
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].name = getDefaultName(
                containerObject.name(),
                propsObject
            );
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, setEntitiesMap, propsObject]);

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck =
        !!eventData.name && containerObject.checkInput(entitiesMap);

    return (
        <NullInputView
            eventData={eventData}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            setEntitiesMap={setEntitiesMap}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            selectedEvents={selectedEvents}
        />
    );
}

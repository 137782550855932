// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import InstanceCard from "../Card/instanceCard.js";
import IncomeInput from "../InputContainer/IncomeInput.js";

// Import Connection Image (if exists)
import InstanceConnectionImage from "Assets/_optionDescriptionIcons/Instance, Project white.jpg";

// Import Coloured PNGs
import incomeBlack from "../../Assets/_eventNodeTypes/instanceSvg.svg";
import incomeBlue from "../../Assets/_eventNodeTypes/instanceSvg.svg";
import incomeWhite from "../../Assets/_eventNodeTypes/instanceSvg.svg";
import incomeGrey from "../../Assets/_eventNodeTypes/instanceSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - incomeObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - incomeObject.checkInput.call(incomeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Income
export const instanceObject = {
    constant: function () {
        return Instance_Constant();
    },

    name: function () {
        return "Instance";
    },

    checkInput: function (state) {
        return Instance_CheckInput(state);
    },

    svg: function () {
        return Instance_Svg();
    },

    eventNodeMenu: function (focus) {
        return Instance_EventNodeMenu(focus);
    },

    indexText: function () {
        return Instance_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Instance_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Instance_OptionDescription();
    },

    colouredPngs: function () {
        return Instance_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Instance_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Instance_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Instance_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (
            !entity?.name ||
            !(entity?.data.value >= 0) ||
            !entity?.cadence ||
            !entity?.startDate ||
            ((JSON.stringify(entity?.data.selectedEntity) === "{}" ||
                !entity?.data.selectedEvent) &&
                entity.data.instanceType == "recordInstance") ||
            ((JSON.stringify(entity?.data.selectedGroupEntity) === "{}" ||
                !entity?.data.selectedGroupEvent) &&
                entity.data.instanceType != "recordInstance")
        ) {
            return false;
        }
        return true;
    };

    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Instance_Constant = () => {
    return "10f00321-ce78-4d43-8b32-2de0152f1a84";
};

// EFFECTS: Returns svg for event
const Instance_Svg = () => {
    return incomeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Instance_EventNodeMenu = (focus) => {
    return <InstanceCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Instance_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Income calculations.";
};

// EFFECTS: Returns all icons for event ??
const Instance_Icons = () => {
    let pngs = new Map([
        ["black", incomeBlack],
        ["blue", incomeBlue],
        ["white", incomeWhite],
        ["grey", incomeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Income_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Instance_InputComponentInfoPassing = (minProps) => {
    return <IncomeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Instance_OptionDescription = () => {
    const tags = ["Option"];
    const type = instanceObject.name();
    const description =
        "The Instance Event is used for iterating an upstream Event, Record or Group or Project multiple times in a Scenario. For example, all of the revenue and expenses require for a project can be grouped together to represent one “iteration” of that project. Using the Instance Event, that iteration can then be repeated multiple times over the duration of the Scenario. Each iteration or “instance” of that project inherits the values and settings from upstream.";
    // const usedForText =
    //     "The Income Node can be used to model incoming cashflows.";
    const connection =
        "The Instance Event requires an upstream Event, Record or Group to be used at the source to be instanced. Many different types of Events can be used for this source. Common examples might be a Contract Event or a set of Expenses. A Group can also be used as the source for the instance.";
    const connectionImage = InstanceConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/i/income.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        // ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Instance_SideModalImage = () => {
    return sideImage;
};

const Instance_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

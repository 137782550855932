import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import styles from "./Scenario.module.css";
import deleteSvg from "../../Assets/scenarioIcons/delete.svg";
import editSvg from "../../Assets/scenarioIcons/edit.svg";
import copySvg from "../../Assets/scenarioIcons/copy.svg";
import commentSvg from "../../Assets/_nodeIcons/comment.png";
import { isActiveUserAdmin } from "../../helpers/userHelpers";
import { unzoomThread } from "actions/zoomThreadActions";

const Scenario = ({
    commentScenario,
    copyScenario,
    data,
    deleteScenario,
    editScenario,
    highlighted,
    isHover,
    loadScenario,
    notificationCount,
    onMouseEnter,
    onMouseLeave,
    setNodeGraphState,
}) => {
    const { zoomedThreadId } = useAppSelector((state) => {
        return {
            zoomedThreadId: state.threadsList.zoomedThreadId,
        };
    }, shallowEqual);
    const dispatch = useAppDispatch();

    const ishHighlighted = highlighted === "true" || isHover;

    const CommentIcons = [
        {
            id: "comment",
            icon: commentSvg,
            method: commentScenario,
            className: "icon",
        },
        {
            id: "copy",
            icon: copySvg,
            method: copyScenario,
            className: "icon",
        },
    ];

    const icons = [
        {
            id: "edit",
            icon: editSvg,
            method: editScenario,
            className: "icon",
        },
        {
            id: "delete",
            icon: deleteSvg,
            method: deleteScenario,
            className: "icon",
        },
        {
            id: "comment",
            icon: commentSvg,
            method: commentScenario,
            className: "icon",
        },
        {
            id: "copy",
            icon: copySvg,
            method: copyScenario,
            className: "icon",
        },
    ];

    const sampleIcons = [
        {
            id: "edit",
            icon: editSvg,
            method: editScenario,
            className: "icon",
        },
        {
            id: "delete",
            icon: deleteSvg,
            method: deleteScenario,
            className: "icon",
        },
        {
            id: "copy",
            icon: copySvg,
            method: copyScenario,
            className: "icon",
        },
    ];

    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser")) || {};
    const isAdmin = isActiveUserAdmin();
    let renderIcon;
    let renderText;

    const hasPermission = (permission, scenario, email) => {
        if (
            scenario.permissions &&
            scenario.permissions.whitelist &&
            scenario.permissions.whitelist[email] &&
            scenario.permissions.whitelist[email][permission]
        ) {
            return true;
        } else if (
            scenario.permissions &&
            scenario.permissions.default &&
            scenario.permissions.default[permission]
        ) {
            return true;
        } else {
            return false;
        }
    };

    switch (data.type) {
        case "sample":
            const sampleIcon = sampleIcons.filter((icon) => {
                if (isAdmin) {
                    return (
                        icon.id === "copy" ||
                        icon.id === "delete" ||
                        icon.id === "edit"
                    );
                } else {
                    return icon.id === "copy";
                }
            });
            renderIcon = sampleIcon;
            renderText = "View Only";
            break;
        case "scenario":
            if (data.name === "Onboarding Scenario" && loggedInUser) {
                const scenarioIcon = icons.filter((icon) => {
                    return icon.id !== "edit" && icon.id !== "comment";
                });
                renderIcon = scenarioIcon;
                renderText = "Onboarding";
            } else if (!loggedInUser && data.name === "Onboarding Scenario") {
                renderIcon = null;
                renderText = "Onboarding";
            } else if (data.is_archived) {
                renderIcon = null;
                renderText = "Archived";
            } else {
                const scenarioIcon = icons.filter((icon) => {
                    return icon.id !== "comment";
                });
                renderIcon = scenarioIcon;
                renderText = "Owner";
            }

            break;
        case "shared":
            let holder = icons.filter((icon) => {
                return icon.id === "copy";
            });
            let textHolder = "View Only";
            if (hasPermission("edit", data, loggedInUser.email)) {
                const sampleIcon = icons.filter((icon) => {
                    return icon.id !== "delete";
                });
                holder = sampleIcon;
                textHolder = "Can Edit";
            } else if (hasPermission("comment", data, loggedInUser.email)) {
                holder = CommentIcons;
                textHolder = "Can Comment";
            }
            renderIcon = holder;
            renderText = textHolder;
            break;
        default:
    }

    const handleClick = (e) => {
        e?.preventDefault();

        if (data.is_archived) setNodeGraphState("default");
        loadScenario(data);

        if (zoomedThreadId) {
            dispatch(unzoomThread());
        }
    };

    return (
        <div
            highlighted={highlighted}
            onMouseEnter={() => onMouseEnter(data)}
            onMouseLeave={onMouseLeave}
            className={styles.scenarioRoot}
        >
            <div className={styles.nameContainer} onClick={handleClick}>
                <div className={styles.scenarioName}>{data.name}</div>
                {notificationCount > 0 && (
                    <div className={styles.notifCount}>{notificationCount}</div>
                )}
            </div>

            <div className={styles.iconsContainer}>
                {ishHighlighted && renderText && (
                    <div className={styles.scenarioText}>{renderText}</div>
                )}
                <div>
                    {ishHighlighted &&
                        renderIcon &&
                        renderIcon.map((icon, i) => {
                            return (
                                <img
                                    alt="alt"
                                    key={i}
                                    onClick={() => icon.method(data)}
                                    src={icon.icon}
                                    className={styles.icon}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default Scenario;

import React, { Component } from "react";
import { processNodeValueForCanvasDisplay } from "../../helpers";
import "./Card.css";
import _ from "lodash";

class InvestmentCard extends Component {
    render() {
        const {
            description,
            value,
            initialValue,
            cadence,
            interestRate,
            version,
        } = this.props.data.metadata;

        const titleCase = (str) => {
            return _.upperFirst(_.toLower(str));
        };
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Initial Balance</div>
                    <div className="cardValue">
                        {initialValue
                            ? processNodeValueForCanvasDisplay(initialValue)
                            : "$0"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Contribution Amount</div>
                    <div className="cardValue">
                        {value ? processNodeValueForCanvasDisplay(value) : "$0"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Contribution Frequency</div>
                    <div className="cardValue">
                        {cadence ? titleCase(cadence) : "One-Time"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Appreciation Rate</div>
                    <div className="cardValue">
                        {interestRate ? `${interestRate}%` : 0}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default InvestmentCard;

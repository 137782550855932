import React from "react";
import closeSvg from "../../Assets/close.svg";
import "./NodeMenu.css";
import BudgetCard from "../Card/budgetCard";
import editSvg from "../../Assets/_nodeIcons/edit.png";
// import PinEvent from "../../Assets/_nodeIcons/pin-no-bg.svg";
import deleteSvg from "../../Assets/_nodeIcons/delete.png";
import bypassSvg from "../../Assets/_canvasIcons/bypass-black.png";
import lockSvg from "../../Assets/_canvasIcons/lock-black.png";
import ScenarioComment from "../UserScenarios/comment";
import IncomeTaxCard from "../Card/incomeTaxCard";
import MeCard from "../Card/meCard";
import StartCard from "../Card/startCard";
import { EventsPopUp } from "../EventsPopUp";
import personBlack from "../../Assets/_eventNodeTypes/person-black.png";
import addOptionSvg from "../../Assets/_baseline/addOption.png";
import {
    createBaselineScenario,
    addNodeToBaseline,
    addNodeToNewPerson,
} from "../../helpers/baselineFunctions";

import { throwError } from "../../helpers/swalError";
import CreateContainerNodeMenu from "./partials/CreateContainerNodeMenu";
import { getEvent } from "actions/getNodeEntityActions";
import { expenseObject } from "../Registry/Expense";
import { tuitionObject } from "../Registry/Tuition";
import { budgetObject } from "../Registry/Budget";
import { bankObject } from "../Registry/Bank";
import { bankPlaidObject } from "../Registry/BankPlaid";
import { bankAltObject } from "../Registry/BankAlt";
import { houseObject } from "../Registry/House";
import { rentObject } from "../Registry/Rent";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { meObject } from "Components/Registry/Me";
import { startObject } from "Components/Registry/Start";
import * as uuid from "uuid";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import { EventActionIds, eventActionsMap } from "actions/canvasEventActions";
import store from "store";

const SINGLE_NODE_GRAPH_EXPERIMENTAL = [
    "dev.whatifi.io",
    "localhost",
    "127.0.0.1",
].includes(window.location.hostname);

const CONTAINER_EXPERIMENTAL = [
    "dev.whatifi.io",
    "localhost",
    "127.0.0.1",
].includes(window.location.hostname);

class NodeMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inflation: null,
            cardState: "info",
            showBaselinePopUp: false,
            baselineNode: null,
            baselineNodeSelected: null,
            noBaselineNodeSelected: "meNode",
        };
    }

    componentDidMount() {
        const { loadedScenario } = this.props;
        if (loadedScenario) {
            const inflation = parseFloat(loadedScenario.inflation) * 100;
            this.setState({ inflation });
        }

        if (this.props.baselineDataManager.hasActiveBaseline()) {
            const activeBaseline =
                this.props.baselineDataManager.getActiveBaseline();
            const personNodes = activeBaseline.data.nodes.filter((node) => {
                return node.type === "Me" || node.type === "Person";
            });
            this.setState({
                baseline: activeBaseline,
                baselineNode: activeBaseline.data.nodes,
                baselineNodeSelected: personNodes[0],
            });
        }
    }

    closeMenu = () => {
        this.props.resetFocus();
    };

    onHandleClick = (actionId) => {
        store.dispatch(
            eventActionsMap[actionId](
                this.props.focus.id,
                this.props.inBaseline
            )
        );
        store.dispatch(eventActionsMap[EventActionIds.focusEvent](null));
        store.dispatch(
            eventActionsMap[EventActionIds.focusEvent](
                this.props.focus.id,
                this.props.inBaseline
            )
        );
    };

    onHandleCardState = (cardState) => {
        this.setState({ cardState });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.loadedScenario !== this.props.loadedScenario) {
            this.setState({});
        }

        if (prevProps.reload !== this.props.reload) {
            this.setState({});
        }

        if (prevProps.focus !== this.props.focus) {
            // console.log(this.props.focus.metadata.excludedBaselineNode);
            // console.log("different");
        }
    }

    handleAddToBaselinePopUp = () => {
        //pop up to confirm
        if (this.props.loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }
        if (this.props.focus.type === "Me") {
            this.handleViewBaseline();
        } else {
            //if there is no baseline create one
            this.setState({ showBaselinePopUp: true });
        }
    };

    handleAddToLibrary = () => {
        const {
            focus,
            loadedScenario,
            eventLibrary,
            manager,
            baselineDataManager,
            updateNode,
            addLibraryEvents,
        } = this.props;
        if (loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }
        const updatedNode = {
            ...focus,
            isLibraryEvent: true,
        };
        if (!updatedNode.libraryEventId) updatedNode.libraryEventId = uuid.v4();
        updateNode(
            updatedNode,
            loadedScenario,
            manager,
            { id: focus.id },
            baselineDataManager
        );
        addLibraryEvents([updatedNode], focus.type, eventLibrary);
    };

    handleCreateContainer = () => {
        this.props.upsertContainerCreation({
            isCreatingContainer: true,
            prospectiveHeadNode: this.props.focus,
        });
    };

    handleAddToBaseline = () => {
        // This is the function that adds normal node to baseline node
        const { baselineManager, focus, baselineDataManager } = this.props;
        const { baselineNodeSelected, noBaselineNodeSelected } = this.state;
        if (!baselineDataManager.hasActiveBaseline()) {
            throwError(
                "error",
                "No Baseline",
                "Please create a Baseline in the Baseline Canvas before adding any events."
            );
            return;
        }
        const baseline = baselineDataManager.getActiveEvents();
        if (baseline) {
            const activeBaselineData = baselineDataManager.hasActiveBaseline()
                ? baselineDataManager.getActiveBaseline(true).data
                : null;
            baselineManager.importData(activeBaselineData);
            //import baseline to the manager
            if (
                baselineNodeSelected.id === "newPerson" ||
                noBaselineNodeSelected === "newPerson"
            ) {
                addNodeToNewPerson(baselineManager, focus);
            } else {
                let parentNode = baselineNodeSelected;
                let node = baselineManager._findEvent(parentNode.id);
                if (baselineNodeSelected.type === "Baseline") {
                    addNodeToBaseline(focus, node, baselineManager);
                } else {
                    while (node && node.children.length > 0) {
                        node = baselineManager._findEvent(node.children[0].id);
                    }
                    addNodeToBaseline(focus, node, baselineManager);
                }
            }
        } else {
            // when there is no baseline
            const loggedInUser = JSON.parse(
                localStorage.getItem("loggedInUser")
            );
            this.props.getUserData(loggedInUser).then((data) => {
                return data;
            });
            if (this.props.baselineManager) {
                createBaselineScenario(
                    this.props.baselineManager,
                    this.props.createBaseline,
                    this.props.loadScenario,
                    focus,
                    noBaselineNodeSelected,
                    baselineDataManager
                );
            }
        }

        const toastData = {
            type: "success",
            text: `Successfully add "${focus.name}" to My Baseline `,
            upgrade: false,
            route: "/baseline",
        };
        let newBaseline = {
            ...baselineDataManager.getActiveBaseline(),
        };
        newBaseline.data = {
            ...newBaseline.data,
            ...this.props.baselineManager.exportData(),
        };

        this.props.updateBaseline(newBaseline, baselineDataManager);
        this.props.onHandleEventPasteToast(toastData);
        focus.isMovedToBaselineToggle();
        focus.toggleBaseline();
        baselineManager.calculate();
        this.setState({ showBaselinePopUp: false });
        // baselineManager._updateScenarioCanvas();
        // this.props.manager.importBaseline(baselineManager.exportData())
        // this.props.manager._updateScenarioCanvas()
    };

    updateEvents = () => {
        this.props.manager.calculate();
    };

    handleViewBaseline = () => {
        this.props.history.push("/baseline");
    };

    cancelAddToBaseline = () => {
        this.setState({ showBaselinePopUp: false });
    };

    handleChangeSelected = (node) => {
        if (node.id === "newPerson") {
            this.setState({ noBaselineNodeSelected: "newPerson" });
        }
        this.setState({ baselineNodeSelected: node });
    };

    handleBaselineSelected = (id) => {
        this.setState({ noBaselineNodeSelected: id });
    };

    render() {
        const {
            focus,
            // baselineDataManager,
            inBaseline,
            threadsAlias,
        } = this.props;
        // const baseline = baselineDataManager.getActiveBaseline();
        const {
            cardState,
            showBaselinePopUp,
            baselineNode,
            baselineNodeSelected,
            noBaselineNodeSelected,
        } = this.state;

        let actionsArray = [];

        if (CONTAINER_EXPERIMENTAL) {
            const { containerCreation } = this.props;
            if (containerCreation.isCreatingContainer) {
                const { prospectiveHeadNode, prospectiveTailNode } =
                    containerCreation;

                return (
                    <CreateContainerNodeMenu
                        prospectiveHeadNode={prospectiveHeadNode}
                        prospectiveTailNode={prospectiveTailNode}
                        resetContainerCreation={
                            this.props.resetContainerCreation
                        }
                        manager={this.props.manager}
                    />
                );
            }
        }

        if (SINGLE_NODE_GRAPH_EXPERIMENTAL) {
            actionsArray = [
                {
                    name: EventActionIds?.editEvent,
                    image: editSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.addEventForDeletion,
                    image: deleteSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.bypassEvent,
                    image: bypassSvg,
                    active: focus.isBypassed(),
                },
                {
                    name: EventActionIds?.lockEvent,
                    image: lockSvg,
                    active: focus.isLocked(),
                },
                // {
                //     name: EventActionIds?.pinEvent,
                //     image: PinEvent,
                //     active: false,
                // },
            ];
        } else {
            actionsArray = [
                {
                    name: EventActionIds?.editEvent,
                    image: editSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.addEventForDeletion,
                    image: deleteSvg,
                    active: false,
                },
                {
                    name: EventActionIds?.bypassEvent,
                    image: bypassSvg,
                    active: focus.isBypassed(),
                },
                {
                    name: EventActionIds?.lockEvent,
                    image: lockSvg,
                    active: focus.isLocked(),
                },
            ];
        }
        // const isFocusBaseline =
        //     baseline &&
        //     baseline.data &&
        //     baseline.data.nodes.filter((node) => {
        //         return node.id === focus.id;
        //     });

        // const isBaselineNode = isFocusBaseline && isFocusBaseline.length > 0;

        const actions = actionsArray.map((action, i) => {
            if (this.props.inBaseline && action.name === "Lock") {
                return null;
            }
            return (
                <div
                    key={i}
                    className="NodeIconContainer"
                    onClick={() => this.onHandleClick(action.name)}
                    id={action.name}
                >
                    <img
                        alt="alt"
                        className={
                            action.active ? "NodeIconActive" : "NodeIcon"
                        }
                        src={action.image}
                    />
                </div>
            );
        });

        let component;

        // This switch statement is responsible for determining which card to
        // render (for the top right node menu). Most entities have an object
        // with the nodeMenu function that obeys some interface (this is the default
        // case), but some entities don't follow that (likely due to special reasons)
        switch (focus.type) {
            case startObject.constant():
                component = (
                    <StartCard
                        closeMenu={this.closeMenu}
                        data={focus}
                        history={this.props.history}
                        loadedScenario={this.props.loadedScenario}
                        updateBaselineCanvas={this.props.updateBaselineCanvas}
                        manager={this.props.manager}
                        getUserScenarios={this.props.getUserScenarios}
                        loadScenario={this.props.loadScenario}
                        baseline={this.props.baseline}
                        baselineDataManager={this.props.baselineDataManager}
                        resetFocus={this.props.resetFocus}
                        inBaseline={this.props.inBaseline}
                        updateScenario={this.props.updateScenario}
                        toggleShowAgencyBaselineView={
                            this.props.toggleShowAgencyBaselineView
                        }
                        accountData={this.props.accountData}
                        showLogin={this.props.showLogin}
                    />
                );
                break;
            case meObject.constant():
                component = (
                    <MeCard
                        closeMenu={this.closeMenu}
                        data={focus}
                        history={this.props.history}
                        loadedScenario={this.props.loadedScenario}
                        updateBaselineCanvas={this.props.updateBaselineCanvas}
                        manager={this.props.manager}
                        getUserScenarios={this.props.getUserScenarios}
                        loadScenario={this.props.loadScenario}
                        baseline={this.props.baseline}
                        baselineDataManager={this.props.baselineDataManager}
                        resetFocus={this.props.resetFocus}
                        inBaseline={this.props.inBaseline}
                        updateScenario={this.props.updateScenario}
                        toggleShowAgencyBaselineView={
                            this.props.toggleShowAgencyBaselineView
                        }
                        accountData={this.props.accountData}
                        showLogin={this.props.showLogin}
                    />
                );
                break;
            case expenseObject.constant():
                component = expenseObject.eventNodeMenu.call(
                    expenseObject,
                    focus,
                    this.state.inflation
                );
                break;
            case tuitionObject.constant():
                component = tuitionObject.eventNodeMenu.call(
                    tuitionObject,
                    focus,
                    this.state.inflation
                );
                break;
            case bankObject.constant():
                const entity = Object.values(
                    getRelevantEntities(
                        focus.entities.map((entity) => entity.id)
                    )
                )[0];
                if (entity.data.bankSelected === "plaid") {
                    component = bankPlaidObject.eventNodeMenu(focus);
                    break;
                } else if (entity.data.bankSelected === "flinks") {
                    component = bankObject.eventNodeMenu(focus);
                } else {
                    component = bankAltObject.eventNodeMenu(focus);
                }
                break;
            case budgetObject.constant():
                if (focus.metadata.beta) {
                    component = <BudgetCard data={focus} />;
                } else {
                    component = budgetObject.eventNodeMenu(focus);
                }
                break;
            case rentObject.constant():
                component = rentObject.eventNodeMenu.call(
                    rentObject,
                    focus,
                    this.props.getWalkScore
                );
                break;
            case houseObject.constant():
                component = houseObject.eventNodeMenu.call(
                    houseObject,
                    focus,
                    this.props.getWalkScore
                );
                break;
            case incomeTaxObject.constant():
                component = (
                    <IncomeTaxCard data={focus} threadsAlias={threadsAlias} />
                );
                break;
            default:
                const eventObject = getObjectFromUUID(focus.type);
                const event = getEvent(focus.id, true);
                const entities = getRelevantEntities(event.entities);

                if (eventObject.checkInput(entities)) {
                    component = eventObject.eventNodeMenu(focus);
                } else {
                    component = <div></div>;
                }
        }

        let eventName;
        try {
            eventName = getObjectFromUUID(focus.type)?.name();
        } catch {
            eventName = "error";
        }
        // TODO FOCUS.NAME should be the event type, not actual name
        return (
            <div className={inBaseline ? "NodeFocusMenuFull" : "NodeFocusMenu"}>
                <div className="NodeHeader">
                    <div className="NodeSelected">{focus.name}</div>
                    <img
                        alt="alt"
                        src={closeSvg}
                        className="NodeMenuClose"
                        onClick={this.closeMenu}
                    />
                </div>
                <div className="NodeType">{eventName}</div>
                <div className="NodeActionContainer">{actions}</div>
                {focus && (
                    <div className="NodeDescription">{focus.description}</div>
                )}
                {focus.isNodeExpired() && (
                    <div className="expiredNode">
                        {" "}
                        <div className="expiredNodeText">
                            This Event&apos;s end date is in the past and will
                            no longer be included in calculations. Click the
                            “Edit” button to modify the end date.
                        </div>
                    </div>
                )}
                {focus.isNodeOutdated() && (
                    <div className="expiredNode">
                        {" "}
                        <div className="expiredNodeText">
                            This Event has recently been updated. Please click
                            the “Edit” button refresh the state of the node to
                            compile, or re-add the node into the scenario.
                        </div>
                    </div>
                )}
                {/* {isBaselineNode && !inBaseline && (
                    <div
                        onClick={this.handleViewBaseline}
                        className="NodeMenuViewBaselineButton"
                    >
                        View & Edit in My Baseline
                    </div>
                )}
                {!isBaselineNode && !inBaseline && focus.type !== "Me" && (
                    <div
                        onClick={this.handleAddToBaselinePopUp}
                        className="NodeMenuAddBaselineButton"
                    >
                        + Add to My Baseline
                    </div>
                )}
                {!focus.isLibraryEvent &&
                    supportedLibraryEvents
                        .map((category) => category.type)
                        .includes(focus.type) && (
                        <div
                            onClick={this.handleAddToLibrary}
                            className="NodeMenuAddBaselineButton"
                        >
                            + Add to My Library
                        </div>
                    )}
                {CONTAINER_EXPERIMENTAL &&
                    !isBaselineNode &&
                    !inBaseline &&
                    focus.type !== "Me" && (
                        <div
                            onClick={this.handleCreateContainer}
                            className="NodeMenuAddBaselineButton"
                        >
                            + Create a Container
                        </div>
                    )} */}
                <div className="NodeFocusToggle">
                    <div
                        onClick={() => this.onHandleCardState("info")}
                        className={
                            cardState === "info"
                                ? "NodeOptionActive"
                                : "NodeOption"
                        }
                    >
                        Info
                    </div>
                    <div
                        // onClick={() => this.onHandleCardState("comment")}
                        onClick={() => {
                            return;
                        }}
                        className={
                            cardState === "comment"
                                ? "NodeOptionActive"
                                : "NodeOptionDisabled"
                        }
                    >
                        Comment
                    </div>
                </div>
                {cardState === "info" ? (
                    <div> {component}</div>
                ) : (
                    <ScenarioComment
                        data={this.props.loadedScenario}
                        editScenario={this.props.editScenario}
                        manager={this.props.manager}
                        fetchSharedScenarios={this.props.fetchSharedScenarios}
                        loadScenario={this.props.loadScenario}
                        userScenarios={this.props.userScenarios}
                        sharedScenario={this.props.sharedScenario}
                        getUserScenarios={this.props.getUserScenarios}
                        focus={this.props.focus}
                        loadedScenario={this.props.loadedScenario}
                        editScenarioComments={this.props.editScenarioComments}
                    />
                )}

                {showBaselinePopUp && (
                    <EventsPopUp
                        onHandleClose={this.cancelAddToBaseline}
                        specificClass={"EventMenu"}
                    >
                        <div className="baselinePopUpText">
                            Associate a new event with an exisiting Person,
                            create a new Person, or start a new Thread
                        </div>
                        <div className="baselineNodeContainer">
                            {baselineNode ? (
                                baselineNode.map((node, i) => {
                                    const isBaseline = node.type === "Baseline";
                                    if (
                                        node.type === "Me" ||
                                        node.type === "Person" ||
                                        isBaseline
                                    ) {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() =>
                                                    this.handleChangeSelected(
                                                        node
                                                    )
                                                }
                                                className={
                                                    baselineNodeSelected.id ===
                                                    node.id
                                                        ? "baselineNodeActive"
                                                        : "baselineNode"
                                                }
                                            >
                                                <img
                                                    src={
                                                        isBaseline
                                                            ? addOptionSvg
                                                            : personBlack
                                                    }
                                                    className="optionSvg"
                                                    alt="alt"
                                                />
                                                <div className="optionName">
                                                    {isBaseline
                                                        ? "New Thread"
                                                        : node.name}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return <></>;
                                })
                            ) : (
                                <div style={{ display: "flex" }}>
                                    <div
                                        onClick={() =>
                                            this.handleBaselineSelected(
                                                "meNode"
                                            )
                                        }
                                        className={
                                            noBaselineNodeSelected === "meNode"
                                                ? "baselineNodeActive"
                                                : "baselineNode"
                                        }
                                    >
                                        <img
                                            src={personBlack}
                                            className="optionSvg"
                                            alt="alt"
                                        />
                                        <div className="optionName">Me</div>
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleBaselineSelected(
                                                "newThread"
                                            )
                                        }
                                        className={
                                            noBaselineNodeSelected ===
                                            "newThread"
                                                ? "baselineNodeActive"
                                                : "baselineNode"
                                        }
                                    >
                                        <img
                                            src={addOptionSvg}
                                            className="optionSvg"
                                            alt="alt"
                                        />
                                        <div className="optionName">
                                            New Thread
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div
                                onClick={() =>
                                    this.handleChangeSelected({
                                        id: "newPerson",
                                    })
                                }
                                className={
                                    noBaselineNodeSelected === "newPerson" ||
                                    (baselineNodeSelected &&
                                        baselineNodeSelected.id === "newPerson")
                                        ? "baselineNodeActive"
                                        : "baselineNode"
                                }
                            >
                                <img
                                    src={personBlack}
                                    className="optionSvg"
                                    alt="alt"
                                />
                                <div className="optionName">New Person</div>
                            </div>
                        </div>
                        <div className="baselineBtnContainer">
                            <div
                                onClick={this.cancelAddToBaseline}
                                className="baselineCancel"
                            >
                                Cancel
                            </div>
                            <div
                                onClick={this.handleAddToBaseline}
                                className="baselineSubmit"
                            >
                                Add to Baseline
                            </div>
                        </div>
                    </EventsPopUp>
                )}
            </div>
        );
    }
}

export default NodeMenu;

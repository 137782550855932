import { useState } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import LoanInputVIew from "./LoanInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import { loanObject } from "Components/Registry/Loan";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { updateEntityState } from "helpers/updateEntityState";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import {
    EntityCustomAccount,
    getAccountsToDelete,
    getPaths,
    handleCustomAccountStructures,
} from "helpers/accounts";
import * as uuid from "uuid";
import { loanInputsHandler } from "../OnInputChangeHandlers/loanInputsHandler";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function LoanInputExperimental({ line, focus, edit, editData }) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const entitiesObject = useAppSelector(
        (state) => state?.entities,
        shallowEqual
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(loanObject);
            _eventData.name = getDefaultName(loanObject.name(), propsObject);
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, loanObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "entityName"
            | "standard"
            | "revenueBased"
            | "interestVsPremium"
            | "loan"
            | "interestRate"
            | "loanStart"
            | "loanPremium"
            | "revenueAccount"
            | "revenuePercentage"
            | "paymentStart"
            | "cadence"
            | "termUnit"
            | "numTermUnits"
            | "interestPaymentsOnly",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = loanInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmitValuesCustom = (newEntitiesMap, passedCheck) => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const onHandleClickSubmitOrUpdate = () => {
        const accountsToDelete = getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            passedCheck,
            entitiesMap,
            createLoanAccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const passedCheck = !!eventData.name && loanObject.checkInput(entitiesMap);

    return (
        <LoanInputVIew
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
            onHandleClickSubmitOrUpdate={onHandleClickSubmitOrUpdate}
        />
    );
}

export const createLoanAccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const loanAccountId = uuid.v4();
    const interestAccountId = uuid.v4();
    const premiumAccountId = uuid.v4();

    const accountStructures: EntityCustomAccount[] = [
        {
            Name: entity.name,
            Type: "ledger",
            Display: "$",
            Root: {
                topLevel: "f62ae4ae-514b-4cfe-96bf-bb7b0f8fc660",
                topLevelChildren: [loanAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Loan - ", loanAccountId],
                    topLevelChildren: [],
                },
            ],
        },
        {
            Name: entity.name,
            Type: "ledger",
            Display: "$",
            Root: {
                topLevel: "4d3f10e5-42fd-4cae-b162-d91788f63b1e",
                topLevelChildren: [interestAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Interest - ", interestAccountId],
                    topLevelChildren: [],
                },
            ],
        },
        {
            Name: entity.name,
            Type: "ledger",
            Display: "$",
            Root: {
                topLevel: "a0001f6c-43a4-4556-8b06-ce18680d7d39",
                topLevelChildren: [premiumAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Premium - ", premiumAccountId],
                    topLevelChildren: [],
                },
            ],
        },
    ];

    data.accountStructure = accountStructures;
    data.accountIdsPaths = getPaths(accountStructures);

    // Record accountIds in state (Maybe we can retrieve them from above struct?)
    data.loanAccountId = loanAccountId;
    data.interestAccountId = interestAccountId;
    data.premiumAccountId = premiumAccountId;

    data.accountIds = [
        loanAccountId, // Loan custom account (child of loans payable)
        interestAccountId, // Interest paid custom account (child of interest paid)
        premiumAccountId, // Premium expensed custom account (child of other expenses)
        "61f75c52-c089-4137-b424-e2f7bfc4d340", // Cash (child of Current Assets)
    ];

    currentEntityObject.data = data;

    return currentEntityObject;
};

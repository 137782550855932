import React, { Component } from "react";
import Modal from "react-modal";
import swal from "sweetalert";
import styles from "./index.module.css";
import { Custom } from "./custom";
import Template from "./template";
// import { Wizard } from "./wizard";
import { AGENCY } from "../../helpers/constants";
import { getMaxScenarioCount, getPlanName } from "../../helpers/userHelpers";
import { throwError } from "../../helpers/swalError";
import { getDefaultScenarioPermissions } from "../../helpers";
import { v4 as uuid } from "uuid";
import { createEntityCopies } from "actions/nodeEntityActions";
import store from "store";
import { unzoomThread } from "actions/zoomThreadActions";
import moment from "moment"

import {
    ModalRow,
    ToggledContainer,
    ToggledContainerHalf,
} from "Components/InputContainer/Components";

export default class CreateScenarioModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            range: 3,
            initRange: 3,
            edit: false,
            inflation: 1.9,
            rangeInfo: false,
            selected: "custom",
            onHoverTemplate: null,
            clientId: props.forcedClientId || undefined,
            explainerVideoLink: "",
            subtitle: "",
            startDate: "",
            scenarioDateSelection: "",
            showDateSelection: !this.props.edit,
            dependency_map: {},
            selectedTemplateData: null
        };

        const edit = this.props.edit ?? false;
        if (edit) {
            let curClient;
            if (this.props.agencyClients) {
                if (this.props.forcedClientId) {
                    curClient = this.props.agencyClients.find((candidate) => {
                        return candidate.id === this.props.forcedClientId;
                    });
                } else {
                    this.props.agencyClients.forEach((client) => {
                        if (
                            client.scenarios &&
                            client.scenarios.includes(
                                this.props.currentDetails.id
                            )
                        ) {
                            curClient = client;
                        }
                    });
                }
            }
            const {
                name,
                description,
                range,
                inflation,
                video_link,
                subtitle,
                custom_start_date,
                start_date_preference,
            } = this.props.currentDetails;
            const newInflation = parseFloat(inflation) * 100;

            let videoLink = video_link;
            let scenarioSubtitle = subtitle;

            if (videoLink === null) videoLink = "";
            if (scenarioSubtitle === null) scenarioSubtitle = "";

            this.state = {
                ...this.state,
                edit: true,
                name,
                description,
                range,
                initRange: range,
                inflation: newInflation,
                client: curClient,
                explainerVideoLink: videoLink,
                subtitle: scenarioSubtitle,
                startDate: custom_start_date,
                scenarioDateSelection: start_date_preference,
            };
        }
    }

    componentDidMount() {
        if (this.props.showCreateScenario) {
            this.setState({ selected: "template" });
        }

        this.props
            .getAdminValues("InflationRate")
            .then((data) => {
                const inflation = Number(data.data.values.inflationRate) * 100;
                this.setState({ inflation });
            })
            .catch((err) => {
                console.log(err, "<---- create value error");
            });
    }

    onChange = (e) => {
        this.setState({ range: e.target.value });
    };

    onHandleDate = (_id, startDate) => {
        this.setState({ 
            scenarioDateSelection: "custom",
            startDate: moment(startDate).format("YYYY-MM-DD")
        });
    };

    onHandleShowDateSelection = () => {
        this.setState({ showDateSelection: true });
    };

    onHandleStartDateSelection = (selection) => {
        if (selection === "currentDate") {
            this.setState({
                startDate: null,
                scenarioDateSelection: selection,
            });
        } else if (selection === "monthStart") {
            this.setState({
                startDate: null,
                scenarioDateSelection: selection,
            });
        } else if (selection === "custom") {
            this.setState({
                scenarioDateSelection: selection,
            });
        }
    };

    onHandleLeaveFocus = (e, id) => {
        const value = e.target.value;
        switch (id) {
            case "range":
                if (value > 60) {
                    swal({
                        title: "Error",
                        text: "You can only have a maximum range of 60 years",
                    });
                    this.setState({
                        range: this.state.initRange,
                    });
                } else if (value < 1) {
                    swal({
                        title: "Error",
                        text: "Scenario must be at least 1 year long",
                    });
                    this.setState({
                        range: this.state.initRange,
                    });
                }
                break;
            default:
                break;
        }
    };

    onHandleInput = (e, id) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                this.setState({ name: value });
                break;
            case "subtitle":
                this.setState({ subtitle: value });
                break;
            case "range":
                this.setState({
                    range: value,
                });
                break;
            case "description":
                this.setState({
                    description: value,
                });
                break;
            case "inflation":
                this.setState({
                    inflation: Number(value)
                })
                break
            case "selectClient":
                let client =
                    this.props.agencyClients.find((client) => {
                        if (!client) return false;
                        const clientString =
                            client.clientdata.name +
                            (client.clientdata.email
                                ? ` (${client.clientdata.email})`
                                : "");
                        return clientString === value;
                    }) || null;
                this.setState({
                    client: client,
                    clientId: client && client.id,
                });
                break;
            case "explainerVideoLink":
                let processedValue = value;

                let index, indexOne, indexTwo, videoLink, linkType;

                if (processedValue.includes("watch")) {
                    linkType = "youtube-long";
                } else if (processedValue.includes("youtu.be")) {
                    linkType = "youtube-short";
                } else if (
                    processedValue.includes("share") &&
                    processedValue.includes("personal_library")
                ) {
                    linkType = "loom-long";
                } else if (processedValue.includes("share")) {
                    linkType = "loom-short";
                }

                switch (linkType) {
                    case "youtube-long":
                        processedValue = value.replace("watch?v=", "embed/");
                        break;
                    case "youtube-short":
                        index = value.indexOf(".be") + 4;
                        videoLink = value.substring(index, value.length);
                        processedValue = `https://www.youtube.com/embed/${videoLink}`;
                        break;
                    case "loom-long":
                        indexOne = value.indexOf("share") + 6;
                        indexTwo = value.indexOf("?");
                        videoLink = value.substring(indexOne, indexTwo);
                        processedValue = `https://www.loom.com/embed/${videoLink}`;
                        break;
                    case "loom-short":
                        index = value.indexOf("share") + 6;
                        videoLink = value.substring(index, value.length);
                        processedValue = `https://www.loom.com/embed/${videoLink}`;
                }

                this.setState({ explainerVideoLink: processedValue });
                break;

            default:
        }
    };

    UNSAFE_componentWillMount() {
        Modal.setAppElement("body");

        const required = [{ key: "name", label: "Name" }];

        const optional = [
            {
                key: "description",
                label: "Description",
            },
        ];

        const scenarioDetails = {},
            currentDetails = this.props.currentDetails || {};
        required.forEach(({ key }) => {
            scenarioDetails[key] = currentDetails[key] || "";
        });
        optional.forEach(({ key }) => {
            scenarioDetails[key] = currentDetails[key] || "";
        });

        this.setState({
            required,
            optional,
            scenarioDetails,
        });
    }

    open = () => {
        const currentDetails = this.props.currentDetails || {};
        const scenarioDetails = {};
        this.state.required.forEach(({ key }) => {
            scenarioDetails[key] = currentDetails[key] || "";
        });
        this.state.optional.forEach(({ key }) => {
            scenarioDetails[key] = currentDetails[key] || "";
        });

        this.setState({ scenarioDetails });
    };

    close = () => {
        this.props.close();
        this.setState({ showDateSelection: false });
    };

    submit = () => {
        if (!this.state.name || !this.state.scenarioDateSelection) {
            swal({
                icon: "error",
                title: "Missing Scenario Details",
                text: "Scenario Name or Start Date missing",
            });
        } else if (
            this.state.explainerVideoLink !== "" &&
            !this.state.explainerVideoLink.includes("youtu.be") &&
            !this.state.explainerVideoLink.includes("youtube.com") &&
            !this.state.explainerVideoLink.includes("loom.com")
        ) {
            swal({
                icon: "error",
                title: "Incorrect Link Format",
                text: "Make sure to paste your YouTube or loom link in the correct format!",
            });
        } else {
            const { userScenarios, forcedClientId, agencyClients } = this.props;
            const loggedInUser = JSON.parse(
                localStorage.getItem("loggedInUser") || {}
            );
            const maxScenarios = getMaxScenarioCount(loggedInUser.plan);

            if (userScenarios.length >= maxScenarios) {
                throwError(
                    "error",
                    "Scenario Limit Exceeded",
                    `With the ${getPlanName(
                        loggedInUser.plan
                    )}, you may have no more than ${maxScenarios} scenarios.`
                );
                return;
            }

            const isAgency = loggedInUser.userRole === AGENCY;
            const {
                name,
                description,
                range,
                inflation,
                client,
                explainerVideoLink,
                subtitle,
                startDate,
                scenarioDateSelection,
                dependency_map
            } = this.state;

            let curClient = client;

            if (isAgency && forcedClientId) {
                curClient = agencyClients.find((candidate) => {
                    return candidate.id === forcedClientId;
                });
            }
            const newInflation = (inflation.toFixed(2) / 100).toFixed(3);
            // const client = this.props.agencyClients.find(
            //     (cl) => cl.id === clientId
            // );
            this.props.submit(
                name,
                description,
                range,
                newInflation,
                isAgency ? curClient : null,
                explainerVideoLink,
                subtitle,
                startDate,
                scenarioDateSelection,
                dependency_map
            );

            this.setState({
                name: null,
                description: null,
                range: 3,
                edit: false,
                inflation: 1.9,
                client: undefined,
                explainerVideoLink: "",
                subtitle: "",
                startDate: "",
                scenarioDateSelection: "",
            });
            this.close();
        }
    };

    keypress = (event) => {
        if (event.key === "Enter") {
            this.submit();
        }
    };

    onHandleInflation = (event) => {
        const id = event.target.id;
        if (id === "inflationPlus") {
            this.setState({
                inflation: this.state.inflation + 0.1,
            });
        } else if (id === "inflationMinus") {
            this.setState({
                inflation: this.state.inflation - 0.1,
            });
        }
    };

    onHoverRange = () => {
        this.setState({ rangeInfo: true });
    };

    onMouseOut = () => {
        this.setState({ rangeInfo: false });
    };

    handleSelected = (e) => {
        const id = e.target.id;
        this.setState({ selected: id });
    };

    onHandleHover = (e) => {
        const id = e.target.id;
        this.setState({ onHoverTemplate: id });
    };

    onHandleHoverOut = () => {
        this.setState({ onHoverTemplate: null });
    };

    // TODO: have on overarching copyScenario function that we use throughout the code-base, instead of these various
    //       functions that differ in a few lines. Would immensely reduce code redundency and have one point of control.
    createScenarioFromTemplate = (templateData) => {
        const { scenarioTemplates } = this.props;
        this.setState({ selected: "custom" });
        const template = scenarioTemplates.find((entry) => {
            return entry.id === templateData.id;
        });
        this.props.close();
        this.loadTemplate(template);

        if (this.props.tabSelected === "/home") this.props.history.push("/");
    };

    loadTemplate = (template) => {
        const { manager, baselineDataManager, agencyClients } = this.props;
        const { clientId } = this.state;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        manager._reset();
        manager.addStart();
        const startNode = manager.getRootNode();
        // The JSON parse and stringify for the data field prevent the nodes/entities from referencing the original
        // this prevents mutations and side-effects.
        const scenario = {
            name: template.name,
            description: template.description,
            range: template.range,
            inflation: template.inflation,
            baselineid: null,
            is_archived: false,
            permissions: getDefaultScenarioPermissions(),
            comments: null,
            account: loggedInUser.account,
            data: JSON.parse(JSON.stringify(template.data)),
            start_date_preference: template.start_date_preference,
            custom_start_date: template.custom_start_date,
            dependency_map: {}
        };
        let client;
        // replace default Me metadata with user/client metadata
        if (clientId) {
            client = agencyClients.find((entry) => entry.id === clientId);
            scenario.data.nodes[0].metadata = {
                ...scenario.data.nodes[0].metadata,
                ...client.clientdata,
            };
        } else {
            scenario.data.nodes[0].metadata = {
                ...scenario.data.nodes[0].metadata,
                ...startNode.metadata,
            };
        }

        // Copied from copyScenario() in ScenariosContainer.js
        // The copyScenario() function is not exported from that file so I just copied the code that invovles
        // creating novel entities.
        let scenarioData = scenario.data;
        const newEntityIdsMap = {};
        scenarioData.nodes.forEach((node) => {
            node.entities.forEach((entity) => {
                const newId = newEntityIdsMap[entity.id] ?? uuid();
                newEntityIdsMap[entity.id] = newId;
                entity.id = newId;
            });
        });
        createEntityCopies(Object.keys(newEntityIdsMap), newEntityIdsMap)
            .then((response) => {
                if (
                    response.data.length === Object.keys(newEntityIdsMap).length
                ) {
                    // no op
                } else {
                    throw new Error("Failed to copy all Records.");
                }
            })
            .catch((_error) => {
                throwError(
                    "error",
                    "Something went wrong when copying your Records. Please try again later."
                );
                console.log(_error);
            });
        // End of copied code

        // save and load scenario
        this.props.createScenario(scenario, template.range, (res) => {
            if (!res || !res.data || !res.data.id) {
                throwError(
                    "error",
                    "Failed to Load Template",
                    "Sorry, something went wrong on our end"
                );
            } else {
                const id = res.data.id;
                scenario.id = id;
                if (client && this.props.addScenarioToClient) {
                    this.props.addScenarioToClient(client, id);
                }
                this.props.getUserScenarios(); // todo
                this.props.loadScenario(
                    scenario,
                    manager,
                    baselineDataManager,
                    null,
                    null
                );
            }
        });
    };

    updateSelectedTemplate = (selectedTemplateData) => {
        if (
            this.state.selectedTemplateData &&
            this.state.selectedTemplateData === selectedTemplateData
        ) {
            this.setState({ selectedTemplateData: null });
        } else {
            this.setState({ selectedTemplateData: selectedTemplateData });
        }
    }

    handleSubmit = (e) => {
        e?.preventDefault();
        const zoomedThreadId = store?.getState().threadsList.zoomedThreadId
        if (zoomedThreadId) {
            store.dispatch(unzoomThread());
        }
        if (this.state.selectedTemplateData) {
            console.log
            this.createScenarioFromTemplate(this.state.selectedTemplateData)
        } else {
            this.submit();
        }

    };

    render() {
        const {
            selected,
            name,
            range,
            inflation,
            description,
            edit,
            client,
            subtitle,
            explainerVideoLink,
        } = this.state;
        let selectedComponent;
        const { currentDetails, agencyClients, forcedClientId } = this.props;
        const scenarioId = currentDetails && currentDetails.id;
        const userRole = localStorage.getItem("userRole");

        switch (selected) {
            case "custom":
                const clientId = forcedClientId
                    ? forcedClientId
                    : client
                    ? client.id
                    : undefined;
                selectedComponent = (
                    <Custom
                        edit={edit}
                        onHandleInput={this.onHandleInput}
                        onHandleLeaveFocus={this.onHandleLeaveFocus}
                        onHandleStartDateSelection={
                            this.onHandleStartDateSelection
                        }
                        onHandleDate={this.onHandleDate}
                        startDate={this.state.startDate}
                        name={name && name}
                        range={range}
                        inflation={inflation}
                        description={description}
                        isAgency={userRole === AGENCY}
                        agencyClients={agencyClients} // null check
                        scenarioId={scenarioId}
                        clientId={clientId}
                        forcedClientId={forcedClientId}
                        subtitle={subtitle}
                        scenarioDateSelection={this.state.scenarioDateSelection}
                        explainerVideoLink={explainerVideoLink}
                    />
                );
                break;
            case "template":
                selectedComponent = (
                    <Template
                        createScenario={this.createScenarioFromTemplate}
                        scenarioTemplates={this.props.scenarioTemplates}
                        updateSelectedTemplate={this.updateSelectedTemplate}
                        selectedTemplateData={this.state.selectedTemplateData}
                    />
                );
                break;
            // case "wizard":
            //     selectedComponent = <Wizard />;
            //     break;
            default:
                break;
        }
        return (
                <div className={styles.modalContainer}>
                    <div className={styles.closeIcon} onClick={this.close}></div>
                    <ModalRow cssOverrides={{margin: "0px"}}>
                        <h1 className={styles.title}>{!edit ? "Create New Scenario" : "Edit Scenario"}</h1>
                    </ModalRow>
                    <ModalRow cssOverrides={{margin: "0px"}}>
                        <ToggledContainer>
                            <ToggledContainerHalf
                                id="custom"
                                isSelected={selected === "custom"}
                                onClick={this.handleSelected}
                                title="Custom"
                            />
                            <ToggledContainerHalf
                                id="template"
                                isSelected={selected === "template"}
                                onClick={this.handleSelected}
                                title="Template"
                            />
                        </ToggledContainer>
                    </ModalRow>
                    <div className={styles.contentContainer}>
                        {selectedComponent}
                    </div>
                    <div className={styles.buttonContainer}>
                        <button onClick={this.handleSubmit} className={styles.addButton}>{edit ? "Update" : "Add"}</button>
                    </div>
                </div>
        );
    }
}

import { Group } from "react-konva";
import { useContext, useMemo } from "react";
import { animated, useSpring } from "@react-spring/konva";
import { EventNodeFocusedContext } from "../contexts/EventNodeStateContexts";
import Konva from "konva";

export const ContainerBackground = ({ width, height }) => {
    const focused = useContext(EventNodeFocusedContext);

    const backgroundStyles = useMemo(() => {
        let _backgroundStyles = {
            width: width,
            height: height,
            cornerRadius: [20, 20, 20, 20],
            strokeWidth: 5,
            fill: "rgba(253, 248, 230, 0.4)",
            stroke: "rgba(244, 213, 93, 0.4)",
        };
        if (focused) {
            _backgroundStyles = {
                ..._backgroundStyles,
                fill: "rgba(253, 248, 230, 1)",
                stroke: "rgba(244, 213, 93, 1)",
            };
        }
        return _backgroundStyles;
    }, [focused, height, width]);

    const backgroundAnimation = useMemo(() => {
        const _backgroundAnimation = {};
        return _backgroundAnimation;
    }, []);

    const animatedBackgroundStyles = useSpring<Konva.RectConfig>({
        to: backgroundStyles,
        config: backgroundAnimation,
    });

    return (
        <Group>
            {/* @ts-ignore - Seems to be a react spring type error */}
            <animated.Rect {...animatedBackgroundStyles} />
        </Group>
    );
};

import {
    SET_CHART_MODAL,
    SET_DUPLICATE_SCENARIO_MODAL,
    SET_CELL_ROW_MODAL,
    SET_SHOW_PASTE_MODAL,
} from "actions/types";
import { CellRowData } from "reducers/typesSchema/cellRowModalSchemas";
import store, { AppThunk } from "store";

export const setShowPasteOptionModal = (pasteData: {
    show: boolean;
    callback?: Function;
}) => {
    store.dispatch({
        type: SET_SHOW_PASTE_MODAL,
        payload: pasteData,
    });
};

export const setShowDuplicateScenarioModal = (pasteData: {
    show: boolean;
    callback?: Function;
}) => {
    store.dispatch({ type: SET_DUPLICATE_SCENARIO_MODAL, payload: pasteData });
};

export const setShowCellRowModal = (cellRowData: Partial<CellRowData>) => {
    store.dispatch({ type: SET_CELL_ROW_MODAL, payload: cellRowData });
};

export const closeCellRowModal = (): AppThunk<void> => {
    return (dispatch, _getState) => {
        dispatch({
            type: SET_CELL_ROW_MODAL,
            payload: { show: false },
        });
    };
};

export const setShowChartModal = (chartData: {
    show: boolean;
    numDataPoints: number;
    monthsStringArray: string[];
    addUpdateDistribution: (distData: number[]) => void;
    yData: number[];
}) => {
    store.dispatch({ type: SET_CHART_MODAL, payload: chartData });
};

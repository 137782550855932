import React, { Component } from "react";
import { B } from ".";

import { templateCategories } from "../../Components/CreateScenarioModal/template";
import { valueDoesNotExist } from "../../helpers";
import { throwError } from "../../helpers/swalError";

export default class UpdateTemplate extends Component {
    constructor(props) {
        super(props);
        const categoriesToInclude = {};
        templateCategories.forEach((category) => {
            categoriesToInclude[category.id] = false;
        });
        this.state = {
            categoriesToInclude,
            template: this.props.scenarioTemplates[0],
            is_sample: false,
            is_tutorial: false,
            is_template: false,
        };
    }

    onHandleInput = (e) => {
        const value = e.target.value;
        switch (e.target.id) {
            case "inflation":
                this.setState({ inflation: value });
                break;
            case "range":
                this.setState({ range: value });
                break;
            case "name":
                this.setState({ name: value });
                break;
            case "subtitle":
                this.setState({ subtitle: value });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "videoLink":
                this.setState({ video_link: value });
                break;
            default:
        }
    };

    handleCheckbox = (e) => {
        const id = e.target.id;
        const categoriesToInclude = this.state.categoriesToInclude;
        categoriesToInclude[id] = !categoriesToInclude[id];
        this.setState({ categoriesToInclude });

        if (e.target.id === "sample") {
            this.setState({ is_sample: !this.state.is_sample });
        }
        if (e.target.id === "tutorial") {
            this.setState({ is_tutorial: !this.state.id_tutorial });
        }
        if (e.target.id === "template") {
            this.setState({ is_template: !this.state.is_template });
        }
    };

    selectScenario = (e) => {
        const id = e.target.value;
        const selected = this.props.userScenarios.find((entry) => {
            return entry.id === id;
        });

        this.setState({
            scenario: selected,
            name: selected.name,
            description: selected.description,
            range: selected.range,
            inflation: selected.inflation * 100,
            subtitle: selected.subtitle,
            video_link: selected.video_link,
        });
    };

    selectTemplate = (e) => {
        const id = e.target.value;
        this.setState({
            template: this.props.scenarioTemplates.find((entry) => {
                return entry.id === id;
            }),
        });
    };

    deleteTemplate = () => {
        this.props.deleteTemplate(this.state.template.id);
    };

    updateTemplate = () => {
        const {
            template,
            scenario,
            name,
            description,
            range,
            inflation,
            subtitle,
            video_link,
            is_sample,
            is_tutorial,
            is_template,
            categoriesToInclude,
        } = this.state;
        let noCategoriesSelected = true;
        Object.values(categoriesToInclude).forEach((isIncluded) => {
            noCategoriesSelected = noCategoriesSelected && !isIncluded;
        });
        if (
            noCategoriesSelected ||
            !scenario ||
            !name ||
            !range ||
            !subtitle ||
            valueDoesNotExist(inflation)
        ) {
            throwError("error", "Incomplete Inputs");
        } else {
            const categories = [];
            Object.keys(categoriesToInclude).forEach((category) => {
                if (categoriesToInclude[category]) categories.push(category);
            });
            this.props.updateTemplate({
                id: template.id,
                name,
                subtitle,
                is_sample,
                is_tutorial,
                is_template,
                description,
                range,
                inflation: inflation / 100,
                video_link,
                data: scenario.data,
                custom_start_date: scenario.custom_start_date,
                start_date_preference: scenario.start_date_preference,
                categories,
            });
        }
    };

    render() {
        const { scenarioTemplates, userScenarios } = this.props;
        const {
            template,
            scenario,
            name,
            description,
            range,
            inflation,
            subtitle,
            video_link,
        } = this.state;
        return (
            <div className="adminContainer">
                <div className="adminBox">
                    <div className="adminLabel">
                        Update or Delete a Template
                    </div>
                    <div>
                        <B>Select a template:</B>
                        <select
                            value={template && template.id}
                            onChange={this.selectTemplate}
                        >
                            {scenarioTemplates.map((entry) => {
                                return (
                                    <option key={entry.id} value={entry.id}>
                                        {entry.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {template && (
                        <div className="scenarioBox">
                            <B>Name</B>: {template.name} <br />
                            <B>Subtitle</B>: {template.subtitle} <br />
                            <B>ID:</B> {template.id}
                            <br />
                            <B>Number of</B> Events:{" "}
                            {template.data.nodes.length}
                            <br />
                            <B>Inflation Rate</B>: {template.inflation} %
                            <br />
                            <B>Range:</B> {template.range} years
                            <br />
                            <B>Description:</B> {template.description} <br />
                            <B>Video Link:</B> {template.video_link} <br />
                            <B>Categories:</B>{" "}
                            {template.categories.map(
                                (category) => category + ", "
                            )}
                        </div>
                    )}
                    {template && (
                        <>
                            <br />
                            <div>
                                <B>Select a scenario:</B>
                                <select
                                    value={scenario && scenario.id}
                                    onChange={this.selectScenario}
                                >
                                    {userScenarios.map((entry) => {
                                        return (
                                            <option
                                                key={entry.id}
                                                value={entry.id}
                                            >
                                                {entry.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {scenario && (
                                <>
                                    <div className="scenarioBox">
                                        <B>Name</B>: {scenario.name} <br />
                                        <B>ID:</B> {scenario.id}
                                        <br />
                                        <B>Number of</B> Events:{" "}
                                        {scenario.data.nodes.length}
                                        <br />
                                        <B>Inflation Rate</B>:{" "}
                                        {scenario.inflation} %
                                        <br />
                                        <B>Range:</B> {scenario.range} years
                                        <br />
                                        <B>Description:</B>{" "}
                                        {scenario.description} <br />
                                    </div>
                                    <div>
                                        <B>Name:</B>
                                        <input
                                            id="name"
                                            type="text"
                                            value={name ?? ""}
                                            onChange={this.onHandleInput}
                                        />
                                        <br />
                                        <B>Subtitle: </B>
                                        <input
                                            id="subtitle"
                                            type="text"
                                            value={subtitle ?? ""}
                                            onChange={this.onHandleInput}
                                        />
                                        <br />
                                        <B>Description:</B>
                                        <textarea
                                            id="description"
                                            type="textarea"
                                            value={description ?? ""}
                                            onChange={this.onHandleInput}
                                        />
                                        <br />
                                        <B>Range (years):</B>
                                        <input
                                            id="range"
                                            type="number"
                                            value={range ?? ""}
                                            onChange={this.onHandleInput}
                                        />
                                        <br />
                                        <B>Inflation (%):</B>
                                        <input
                                            id="inflation"
                                            type="number"
                                            value={inflation ?? ""}
                                            onChange={this.onHandleInput}
                                        />
                                        <br />
                                        <B>Video Link:</B>
                                        <input
                                            id="videoLink"
                                            type="text"
                                            value={video_link ?? ""}
                                            onChange={this.onHandleInput}
                                        />
                                        <br />
                                        <br />
                                        <B>Select Categories:</B>
                                        <br />
                                        <em>
                                            * The following categories determine
                                            what category the scenario gets
                                            placed under when publishing a{" "}
                                            <strong>template scenario</strong>.
                                            *
                                        </em>
                                        <br />
                                        {templateCategories.map((category) => {
                                            return (
                                                <>
                                                    <input
                                                        key={category.id}
                                                        type="checkbox"
                                                        id={category.id}
                                                        onChange={
                                                            this.handleCheckbox
                                                        }
                                                    />
                                                    <label
                                                        htmlFor={category.id}
                                                    >
                                                        {category.name}
                                                    </label>
                                                    <br />
                                                </>
                                            );
                                        })}
                                    </div>
                                    <br />
                                    <input
                                        type="checkbox"
                                        id="template"
                                        onChange={this.handleCheckbox}
                                    />
                                    <label htmlFor="template">
                                        <B> Include as template scenario?</B>
                                    </label>
                                    <br />
                                    <br />
                                    <em>
                                        * A <strong>tutorial scenario</strong>{" "}
                                        is a type of{" "}
                                        <strong>sample scenario</strong>. *
                                    </em>
                                    <br />
                                    <em>
                                        * If you wish to publish a{" "}
                                        <strong>tutorial scenario</strong>,
                                        please check both the{" "}
                                        <strong>sample scenario</strong> and{" "}
                                        <strong>tutorial scenario</strong>{" "}
                                        checkboxes. *
                                    </em>
                                    <br />
                                    <br />
                                    <input
                                        type="checkbox"
                                        id="sample"
                                        onChange={this.handleCheckbox}
                                    />
                                    <label htmlFor="sample">
                                        <B> Include as sample scenario?</B>
                                    </label>
                                    <input
                                        type="checkbox"
                                        id="tutorial"
                                        onChange={this.handleCheckbox}
                                    />
                                    <label htmlFor="tutorial">
                                        <B> Include as tutorial scenario?</B>
                                    </label>
                                    <br />
                                    <br />
                                    <button onClick={this.updateTemplate}>
                                        UPDATE TEMPLATE
                                    </button>
                                </>
                            )}
                        </>
                    )}
                    <br />
                    <br />
                    {template && (
                        <button onClick={this.deleteTemplate}>
                            DELETE TEMPLATE
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

// import type {ChangeEvent} from "react";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const accountExpressionInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "startDate"
        | "endDate"
        | "accountName"
        | "contraAccountName"
        | "bypassState",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "bypassState":
            currentEntityObject.bypassState = !!value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";

            // Update all modifier/override startDates
            if (currentEntityObject?.data?.modsCreated) {
                for (const mod of currentEntityObject?.data?.modsCreated) {
                    mod.startDate = startDateString;
                }
            }

            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            const endDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.targetAccountIds = account.ids;
            data.targetAccountName = account.name;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.targetContraAccountIds = account.ids;
            data.targetContraAccountName = account.name;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    SelectDropDown,
    Rate,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    ModalRowTwoThirds,
    ModalRowOneThird,
    SingleEntity,
    ToggledContainer,
    ToggledContainerHalf,
    SelectEntities,
    MuiCalendar,
} from "../Components";
import Switch from "react-switch";
import { customerGrowthObject } from "Components/Registry/CustomerGrowth";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const options = [
    "annually",
    "semi-annually",
    "quarterly",
    "monthly"
];

export default function CustomerGrowthInputView({
    entitiesMap,
    currentEntity,
    handleAddDynamicValue,
    handleDeleteDynamicValue,
    onChangeDynamicValues,
    handleDynamicDate,
    eventData,
    onChangeNameDescription,
    handleChangeInterpolationType,
    toggleInheritStartDate,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleDateSelection,
}) {

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={customerGrowthObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={customerGrowthObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={true}
                            value={
                                entitiesMap?.[currentEntity]?.data
                                    ?.selectedCustomer ?? {}
                            }
                            nodes={Object.values(
                                entitiesMap?.[currentEntity]?.data?.customers ||
                                []
                            )}
                            className="IncomeSelect"
                            id="customer"
                            label="Select Customer"
                            groupEventType="Customer"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.cadence}
                            options={options}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Frequency"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <Switch
                        id="inheritStartDate"
                        checked={
                            entitiesMap?.[currentEntity]?.data.inheritStartDate
                        }
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={toggleInheritStartDate}
                    />
                    <div className="InflationText">
                        Inherit Start Date from Customer Event
                    </div>
                </ModalRow>
                {!entitiesMap?.[currentEntity]?.data.inheritStartDate && (
                    <ModalRow>
                        <ModalRowHalf>
                            <MuiCalendar id="startDate" value={entitiesMap?.[currentEntity]?.startDate} required={true} onChangeInput={handleDateSelection} label="Custom Date" />
                        </ModalRowHalf>
                    </ModalRow>
                )}
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="constant"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data.subType ===
                            "constant"
                        }
                        onClick={handleChangeInterpolationType}
                        title="Constant Growth Rate"
                    />
                    <ToggledContainerHalf
                        id="dynamic"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data.subType ===
                            "dynamic"
                        }
                        onClick={handleChangeInterpolationType}
                        title="Dynamic Growth Rate"
                    />
                </ToggledContainer>
                {entitiesMap?.[currentEntity]?.data.subType === "constant" ? (
                    <ModalRow>
                        <ModalRowHalf>
                            <Rate
                                label="Monthly Growth Rate"
                                onChangeInput={handleOnChange}
                                value={
                                    entitiesMap?.[currentEntity]?.data
                                        .constantValue
                                }
                                id="constantValue"
                                className="mlsInput"
                                sign={true}
                                required={true}
                            />
                        </ModalRowHalf>
                    </ModalRow>
                ) : (
                    <>
                        <ModalRow twoInputs>
                            <ModalRowTwoThirds>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.interpolateSubType
                                    }
                                    options={[
                                        "Interpolate Growth Rate",
                                        "Step Function Growth Rate",
                                    ]}
                                    className="mlsInput"
                                    label="Select a Dynamic Growth Type"
                                    id="interpolateSubType"
                                    required={true}
                                />
                            </ModalRowTwoThirds>
                            <ModalRowOneThird>
                                <Rate
                                    label="Max Growth"
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.maxGrowth
                                    }
                                    id="maxGrowth"
                                    className="mlsInput"
                                    sign={true}
                                    required={true}
                                />
                            </ModalRowOneThird>
                        </ModalRow>
                        <ModalRow>
                            <div className="PTTLabel">Specified Values:</div>
                        </ModalRow>
                        <DynamicPanel
                            specificPercentages={
                                entitiesMap?.[currentEntity]?.data
                                    ?.specificPercentages
                            }
                            onChangeDynamicValues={onChangeDynamicValues}
                            handleDynamicDate={handleDynamicDate}
                            handleAddDynamicValue={handleAddDynamicValue}
                            handleDeleteDynamicValue={handleDeleteDynamicValue}
                        />
                    </>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

function DynamicPanel({
    specificPercentages,
    onChangeDynamicValues,
    handleDynamicDate,
    handleAddDynamicValue,
    handleDeleteDynamicValue,
}) {
    return (
        <div>
            {specificPercentages.map((data, i) => {
                return (
                    <ModalRow twoInputs key={i}>
                        <ModalRowOneThird>
                            <SelectDropDown
                                onChangeInput={onChangeDynamicValues}
                                value={data.month}
                                options={months}
                                className="mlsInput"
                                mainId={data.id}
                                label="Select a Month"
                                id="month"
                                required={true}
                            />
                        </ModalRowOneThird>
                        <ModalRowOneThird>
                            <MuiCalendar id="year" value={data.year === "" ? null : data.year} dynamicId={data.id} onChangeInput={handleDynamicDate} label="Year" inputFormat="YYYY" disableOpenPicker={true} required={true} />
                        </ModalRowOneThird>
                        <ModalRowOneThird>
                            <Rate
                                label="Monthly Growth Rate"
                                onChangeInput={onChangeDynamicValues}
                                value={data.value}
                                id="value"
                                mainId={data.id}
                                className="mlsInput"
                                sign={true}
                                required={true}
                            />
                        </ModalRowOneThird>
                    </ModalRow>
                );
            })}
            <ModalRow>
                <div onClick={handleAddDynamicValue} className="textButton">
                    Add Another
                </div>
                {specificPercentages.length > 1 && (
                    <div
                        onClick={handleDeleteDynamicValue}
                        className="addButton"
                    >
                        Delete Recently Added
                    </div>
                )}
            </ModalRow>
        </div>
    );
}

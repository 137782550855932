// Default Imports
import React from "react";

// Import Rental RRSP Card and Rental RRSP Input
import RRSPCard from "../Card/rrspCard.js";
import RRSPInput from "../InputContainer/RRSPInput.js";

// Import Connection Image (if exists)
import RRSPConnectionImage from "../../Assets/_optionDescriptionIcons/RRSP, Investments, Capital Gains.png";

// Import Coloured PNGs
import rrspBlack from "../../Assets/_eventNodeTypes/rrsp-black.png";
import rrspBlue from "../../Assets/_eventNodeTypes/rrsp-blue.png";
import rrspWhite from "../../Assets/_eventNodeTypes/rrsp-white.png";
import rrspGrey from "../../Assets/_eventNodeTypes/rrsp-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/RetirementPlanning2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - rrspObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - rrspObject.checkInput.call(rrspObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental RRSP
export const rrspObject = {
    constant: function () {
        return RRSP_Constant();
    },

    checkInput: function (state) {
        return RRSP_CheckInput(state);
    },

    svg: function () {
        return RRSP_Svg();
    },

    eventNodeMenu: function (focus) {
        return RRSP_EventNodeMenu(focus);
    },

    indexText: function () {
        return RRSP_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return RRSP_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return RRSP_OptionDescription();
    },

    colouredPngs: function () {
        return RRSP_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return RRSP_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental RRSP

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const RRSP_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (
        state.value === null ||
        state.value === undefined ||
        state.value < 0 ||
        state.value === "" ||
        isNaN(state.value)
    )
        return false;
    if (
        state.initialValue === null ||
        state.initialValue === undefined ||
        state.initialValue < 0 ||
        state.initialValue === "" ||
        isNaN(state.initialValue)
    )
        return false;
    if (!state.start) return false;
    if (!state.personName || !state.personId) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const RRSP_Constant = () => {
    return "RRSP";
};

// EFFECTS: Returns svg for event
const RRSP_Svg = () => {
    return rrspBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const RRSP_EventNodeMenu = (focus) => {
    return <RRSPCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const RRSP_IndexText = () => {
    return "Select an input field to learn more about how each value affects your RRSP calculations.";
};

// EFFECTS: Returns all icons for event ??
const RRSP_Icons = () => {
    let pngs = new Map([
        ["black", rrspBlack],
        ["blue", rrspBlue],
        ["white", rrspWhite],
        ["grey", rrspGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const RRSP_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const RRSP_InputComponentInfoPassing = (minProps) => {
    return <RRSPInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const RRSP_OptionDescription = () => {
    const tags = ["Option"];
    const type = rrspObject.constant();
    const description =
        "An RRSP (registered retirement savings plan) is a type of investment account. RRSPs have various tax advantages compared to investing outside of tax-preferred accounts.";
    const usedForText =
        "Use the RRSP Event to capture your RRSPs  into your scenario and project future appreciation and growth.";
    const connection = "";
    const connectionImage = RRSPConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/rrsps-related-plans/registered-retirement-savings-plan-rrsp.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const RRSP_SideModalImage = () => {
    return sideImage;
};

import type { ChangeEvent } from "react";
import { bankManualInputsHandler } from "../OnInputChangeHandlers/bankManualInputsHandler";
import BankManualInputView from "./BankManualInputView";
import * as uuid from "uuid";

export default function BankManualInputExperimental({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    onHandleSubmitValues,
    onHandleSubmit,
    edit,
    passedCheck,
}) {
    const handleOnChange = (
        // accNum is passed by select components in BankManualInputView
        e: ChangeEvent<HTMLInputElement>,
        id: "accName" | "accType" | "entityName" | "value" | "rating",
        star: number,
        accNum = 0
    ) => {
        const value = e.target.value;
        const newEntitiesMap = bankManualInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            accNum
        );
        setEntitiesMap(newEntitiesMap);
    };

    const deleteAccountHelper = (id) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const accounts = data.accounts;
        const newAccountsArray: [{}?] = [];

        for (let i = 0; i < accounts.length; i++) {
            if (accounts[i].id !== id) newAccountsArray.push(accounts[i]);
        }

        data.accounts = newAccountsArray;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const addAccount = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const accountCounter = data.accounts.length;
        const newAccount = {
            accountName: `Bank Account ${accountCounter + 1}`,
            accountType: "",
            accountBalance: "",
            id: uuid.v4(),
        };
        const newAccounts = [...data.accounts, newAccount];
        data.accounts = newAccounts;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const deleteAccount = (account) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const today = new Date();
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
        ];
        const value = account.value;
        const id = account.id;
        let accounts: {
            accountName: string;
            accountType: string;
            accountBalance: string;
            id: string;
        }[] = data.accounts;
        const dateStr = `${
            months[today.getMonth()]
        } ${today.getDate()}, ${today.getFullYear()}`;
        const newAccountsArray: {
            accountName: string;
            accountType: string;
            accountBalance: string;
            id: string;
        }[] = [];

        for (let i = 0; i < accounts.length; i++) {
            if (accounts[i].id !== id) newAccountsArray.push(accounts[i]);
        }

        account.accountBalance = value;

        let totalBalance: number | string = 0;
        if (newAccountsArray.length > 0) {
            totalBalance = newAccountsArray
                .map((account) => {
                    if (account.accountType === "Credit Card") {
                        return -parseInt(account.accountBalance);
                    } else {
                        return parseInt(account.accountBalance);
                    }
                })
                .reduce((total, accountBalance) => {
                    if (!total) total = 0;
                    if (!accountBalance) accountBalance = 0;
                    if (isNaN((total as any) + (accountBalance as any)))
                        return "0";
                    else return (total as any) + accountBalance;
                })
                .toString();
            data.accounts = newAccountsArray;
        } else {
            accounts = [
                {
                    accountName: "",
                    accountType: "",
                    accountBalance: "",
                    id: uuid.v4(),
                },
            ];

            data.accounts = accounts;
            totalBalance = "0";
        }
        data.value = totalBalance;
        data.lastUpdated = dateStr;
        deleteAccountHelper(id);

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    // const updateValuesInput = () => {
    //     const newState = {
    //         ...entitiesMap[currentEntity].data,
    //         isFilled: true,
    //         valid: true,
    //     };
    //     if (bankObject.checkInput(entitiesMap[currentEntity].data)) {
    //         updateValues(newState);
    //     } else {
    //         throwError(
    //             "error",
    //             "Missing Input",
    //             "Please fill up bank name and select accounts"
    //         );
    //     }
    // };

    // const submitBankDataValues = () => {
    //     const bankSelected = entitiesMap[currentEntity].data.bankSelected;

    //     const newState = {
    //         ...entitiesMap[currentEntity].data,
    //         isFilled: true,
    //         valid: true,
    //     };

    //     if (baseline) {
    //         confirmBaselineAction(newState);
    //     } else {
    //         if (bankSelected === "manual") {
    //             if (bankObject.checkInput(entitiesMap[currentEntity].data)) {
    //                 confirmAction(newState);
    //             } else {
    //                 throwError(
    //                     "error",
    //                     "Missing Input",
    //                     "Please fill up bank name and select accounts"
    //                 );
    //             }
    //         } else {
    //             submitBankData(newState);
    //         }
    //     }
    // };

    // const submitBankDataInput = () => {
    //     const newState = {
    //         ...entitiesMap[currentEntity].data,
    //         value: 0,
    //         // start needs to be included in currentEntity, not in currentEntity.data
    //         // start: moment(new Date()).format("YYYY-MM-DD"),
    //         isFilled: false,
    //         valid: false,
    //     };
    //     if (baseline) {
    //         confirmBaselineAction(newState);
    //     } else {
    //         submitBankData(newState);
    //     }
    // };

    return (
        <BankManualInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            handleOnChange={handleOnChange}
            addAccount={addAccount}
            deleteAccount={deleteAccount}
            onHandleSubmitValues={onHandleSubmitValues}
            onHandleSubmit={onHandleSubmit}
            passedCheck={passedCheck}
            edit={edit}
        />
    );
}

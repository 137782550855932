// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import IncomeTaxCard from "../Card/incomeTaxCard";
import IncomeTaxInput from "../InputContainer/IncomeTaxInput";

// Import Connection Image (if exists)
import IncomeTaxConnectionImage from "../../Assets/_optionDescriptionIcons/Income, Income Tax, Expense.png";

// Import Coloured PNGs
import incomeTaxBlack from "../../Assets/_eventNodeTypes/incomeTax-black.png";
import incomeTaxBlue from "../../Assets/_eventNodeTypes/incomeTax_blue.png";
import incomeTaxWhite from "../../Assets/_eventNodeTypes/incomeTax_white.png";
import incomeTaxGrey from "../../Assets/_eventNodeTypes/incomeTax_grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/taxes2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - incomeTaxObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - incomeTaxObject.checkInput.call(incomeTaxObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const incomeTaxObject = {
    constant: function () {
        return IncomeTax_Constant();
    },

    checkInput: function (state) {
        return IncomeTax_CheckInput(state);
    },

    svg: function () {
        return IncomeTax_Svg();
    },

    eventNodeMenu: function (focus) {
        return IncomeTax_EventNodeMenu(focus);
    },

    indexText: function () {
        return IncomeTax_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return IncomeTax_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return IncomeTax_OptionDescription();
    },

    colouredPngs: function () {
        return IncomeTax_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return IncomeTax_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const IncomeTax_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.data.province) return false;
        if (!entity.data.personName) return false;
        if (!entity.name) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const IncomeTax_Constant = () => {
    return "IncomeTax";
};

// EFFECTS: Returns black png / svg for event
const IncomeTax_Svg = () => {
    return incomeTaxBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const IncomeTax_EventNodeMenu = (focus) => {
    return <IncomeTaxCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const IncomeTax_IndexText = () => {
    return "Select an input field to learn more about your incomeTax calculations.";
};

// EFFECTS: Returns all icons for event ??
const IncomeTax_Icons = () => {
    let pngs = new Map([
        ["black", incomeTaxBlack],
        ["blue", incomeTaxBlue],
        ["white", incomeTaxWhite],
        ["grey", incomeTaxGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const IncomeTax_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const IncomeTax_InputComponentInfoPassing = (minProps) => {
    return <IncomeTaxInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, incomeTaxs for event ??
const IncomeTax_OptionDescription = () => {
    const tags = ["Option"];
    const type = incomeTaxObject.constant();
    const description =
        "Most income is taxable in Canada, and ignoring income tax while financial planning is a great mistep. The Income Tax event pro-rates your salary to calculate taxes per payroll, and does an adjustment at the end of every year, modelling how Taxes are done.";
    const usedForText = "Use an Income Tax node to see your net income.";
    const connection =
        "Connect an Income Tax Node after an Income Node. This node will inherit values and properties from the House Node to calculate income tax.";
    const connectionImage = IncomeTaxConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/services/taxes/income-tax.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const IncomeTax_SideModalImage = () => {
    return sideImage;
};

import { useEffect, useRef } from "react";
import AccountType from "./AccountType";
import { connect } from "react-redux";
import { appMapDispatch, appMapState } from "store/appMapStateDispatch";

const OtherAccountsPanel = ({
    allAccountTypes,
    selectDeselectAccountTypes,
    selectedAccountTypes,
    clearAccountTypeSelection,
    handleClickAddAccounts,
    getLedgerDisplayName,
    handleClickAccountsPanel,
}) => {
    const panelRef = useRef();

    const handleClick = (e) => {
        if (panelRef.current.contains(e.target)) {
            return;
        } else handleClickAccountsPanel();
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <div
            ref={panelRef}
            className="graph-accounts__other-accounts-panel-wrapper"
        >
            <ul className="graph-accounts__other-accounts-panel-accounts-list">
                {allAccountTypes.map((account) => {
                    return (
                        <AccountType
                            key={account}
                            account={account}
                            selectDeselectAccountTypes={
                                selectDeselectAccountTypes
                            }
                            getLedgerDisplayName={getLedgerDisplayName}
                        />
                    );
                })}
            </ul>
            <div className="graph-accounts__other-accounts-panel-buttons-wrapper">
                <div className="graph-accounts__other-accounts-panel-button1">
                    <span
                        className="graph-accounts__other-accounts-panel-button1-text"
                        onClick={clearAccountTypeSelection}
                    >
                        Clear Selection
                    </span>
                </div>
                <div
                    className="graph-accounts__other-accounts-panel-button2"
                    onClick={handleClickAddAccounts}
                >
                    <span className="graph-accounts__other-accounts-panel-button2-text">
                        Add {selectedAccountTypes.length}
                    </span>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

const mapStateToProps = appMapState((state) => {
    const selectedAccountTypes =
        state?.graphAccountSelection?.selectedAccountTypes;

    return {
        selectedAccountTypes,
    };
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherAccountsPanel);

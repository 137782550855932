import React, { Component } from "react";
import "./InputContainer.css";
import Switch from "react-switch";
import Calendar from "../Calendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    Description,
    SelectDropDownPerson,
    InputButtons,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    StarsRating,
    InflationSwitch,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { getPresentableDependenciesOfManyTypes } from "../../helpers/nodeDependencyDetectionHelpers";
import { maternityObject } from "Components/Registry/Maternity";
import { personObject } from "Components/Registry/Person";
import { meObject } from "Components/Registry/Me";

class MaternityInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: "",
            start: null,
            length: 0,
            end: null,
            rating: 0,
            showOptions: false,
            name: getDefaultName(maternityObject.constant(), this.props),
            description: "",
            type: maternityObject.constant(),
            value: null,
            showSettings: false,
            tag: maternityObject.constant(),
            onHoverStar: null,
            inflation: false,
            baseline: false,
            people: this.getPeople(props),
            personId: "",
            personName: "",
            spouseId: "",
            spouseName: "",
            spouseStart: null,
            spouseLength: 0,
            spouseEnd: null,
            spouseTakingTimeOff: false,
            extended: false,
        };
    }

    getPeople = (props = this.props) => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [personObject.constant(), meObject.constant()],
            props,
            personObject.constant()
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        return people;
    };

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData, people: this.getPeople() });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "person":
                if (value === "Mother" || !value) {
                    this.setState({ personName: "", personId: null });
                } else {
                    this.setState({
                        personName: value,
                        personId: this.state.people[value],
                    });
                }
                break;
            case "spouse":
                if (value === "Spouse" || !value) {
                    this.setState({ spouseName: "", spouseId: null });
                } else {
                    this.setState({
                        spouseName: value,
                        spouseId: this.state.people[value],
                    });
                }
                break;
            case "length":
                if (value === 0 || value === "" || !value) {
                    this.setState({ length: 0 });
                } else {
                    this.setState({ length: parseInt(value) });
                    setTimeout(
                        this.checkDatesAndLengthMother(
                            this.state.start,
                            this.state.end,
                            parseInt(value)
                        ),
                        1500
                    );
                }
                break;
            case "spouseLength":
                if (value === 0 || value === "" || !value) {
                    this.setState({ spouseLength: 0 });
                } else {
                    this.setState({ spouseLength: parseInt(value) });
                    setTimeout(
                        this.checkDatesAndLengthSpouse(
                            this.state.spouseStart,
                            this.state.spouseEnd,
                            parseInt(value)
                        ),
                        1500
                    );
                }
                break;
            default:
        }
    };

    onHandleDateMother = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
        setTimeout(
            this.checkDatesAndLengthMother(
                startDate,
                endDate,
                this.state.length
            ),
            1500
        );
    };

    onHandleDateSpouse = (startDate, endDate) => {
        this.setState({ spouseStart: startDate, spouseEnd: endDate });
        setTimeout(
            this.checkDatesAndLengthSpouse(
                startDate,
                endDate,
                this.state.spouseLength
            ),
            1500
        );
    };

    howLongCanILeave = (_start, _end) => {
        let currentLeaveDuration = this.state.length + this.state.spouseLength;
        if (this.state.extended) {
            if (currentLeaveDuration <= 76) {
                return (
                    "Can receive EI for an additional " +
                    (76 - currentLeaveDuration) +
                    " weeks."
                );
            } else {
                return (
                    "Cannot receive EI for " +
                    (currentLeaveDuration - 76) +
                    " of these weeks."
                );
            }
        } else {
            if (currentLeaveDuration <= 50) {
                return (
                    "Can receive EI for an additional " +
                    (50 - currentLeaveDuration) +
                    " weeks."
                );
            } else {
                return (
                    "Cannot receive EI for " +
                    (currentLeaveDuration - 50) +
                    " of these weeks."
                );
            }
        }
    };

    howManyWeeksWillILeave = (_start, _end) => {
        let currentLeaveDuration = this.state.length + this.state.spouseLength;
        return "Currently on leave for " + currentLeaveDuration + " weeks.";
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    onHandleSubmitValues = () => {
        const passedCheck = maternityObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    // set a timeout before calling this with length because state updates too quick
    // called whenever a possible change is made to start, end, or length, for spouse
    checkDatesAndLengthSpouse = (startDate, endDate, duration) => {
        switch (true) {
            case this.state.spouseStart !== null &&
                this.state.spouseEnd !== null &&
                this.state.spouseLength > 0:
                this.setState({
                    spouseStart: startDate,
                    spouseEnd: endDate,
                    spouseLength: duration,
                });
                break;
            case duration < 0:
                this.setState({
                    spouseLength: 0,
                });
                break;
            case startDate !== null && endDate !== null:
                endDate = new Date(endDate);
                startDate = new Date(startDate);
                const time =
                    (endDate.getTime() - startDate.getTime()) /
                    (7 * 24 * 60 * 60 * 1000);
                this.setState({
                    spouseLength: Math.round(time),
                });
                break;
            case startDate === null && duration !== 0:
                endDate = new Date(endDate);
                const days1 = duration * 7;
                let date1 = new Date(
                    endDate.getTime() - days1 * 24 * 60 * 60 * 1000
                );
                this.setState({
                    spouseStart: date1,
                });
                break;
            case endDate === null && duration !== 0:
                startDate = new Date(startDate);
                const days2 = duration * 7;
                let date2 = new Date(
                    startDate.getTime() + days2 * 24 * 60 * 60 * 1000
                );
                this.setState({
                    spouseEnd: date2,
                });
                break;
            default:
                this.setState({
                    spouseStart: startDate,
                    spouseEnd: endDate,
                    spouseLength: duration,
                });
                break;
        }
    };

    // set a timeout before calling this with length because state updates too quick
    // called whenever a possible change is made to start, end, or length, for mother
    checkDatesAndLengthMother = (startDate, endDate, duration) => {
        switch (true) {
            case this.state.start !== null &&
                this.state.end !== null &&
                this.state.length > 0:
                this.setState({
                    start: startDate,
                    end: endDate,
                    length: duration,
                });
                break;
            case duration < 0:
                this.setState({
                    length: 0,
                });
                break;
            case startDate !== null && endDate !== null:
                endDate = new Date(endDate);
                startDate = new Date(startDate);
                const time =
                    (endDate.getTime() - startDate.getTime()) /
                    (7 * 24 * 60 * 60 * 1000);
                this.setState({
                    length: Math.round(time),
                });
                break;
            case startDate === null && duration !== 0:
                endDate = new Date(endDate);
                const days1 = duration * 7;
                let date1 = new Date(
                    endDate.getTime() - days1 * 24 * 60 * 60 * 1000
                );
                this.setState({
                    start: date1,
                });
                break;
            case endDate === null && duration !== 0:
                startDate = new Date(startDate);
                const days2 = duration * 7;
                let date2 = new Date(
                    startDate.getTime() + days2 * 24 * 60 * 60 * 1000
                );
                this.setState({
                    end: date2,
                });
                break;
            default:
                this.setState({
                    start: startDate,
                    end: endDate,
                    length: duration,
                });
                break;
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    resetStates = () => {
        this.setState({
            start: null,
            end: null,
            spouseStart: null,
            spouseEnd: null,
            length: 0,
            spouseLength: 0,
        });
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onToggleCheckBoxSpouse = () => {
        this.setState({ spouseTakingTimeOff: !this.state.spouseTakingTimeOff });
    };

    onToggleCheckBoxExtended = () => {
        this.setState({
            extended: !this.state.extended,
        });
    };

    getSpouse = (people) => {
        let i = 0;
        let temp = [];
        while (typeof people[i] !== "undefined") {
            if (people[i] !== this.state.personName) {
                temp.push(people[i]);
            }
            i++;
        }
        return temp;
    };

    render() {
        const passedCheck = maternityObject.checkInput(this.state);
        const { edit } = this.props;

        const {
            name,
            cadence,
            description,
            personName,
            spouseTakingTimeOff,
            spouseName,
            extended,
            spouseStart,
            end,
            spouseEnd,
            rating,
            start,
            onHoverStar,
        } = this.state;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={maternityObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={maternityObject.svg()}
                    />

                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>

                    <ModalRow>
                        <Switch
                            id="spouseTakingTimeOff"
                            checked={spouseTakingTimeOff}
                            className="SpouseToggle"
                            height={20}
                            width={40}
                            onChange={this.onToggleCheckBoxSpouse}
                        />
                        <div className="spouseText">
                            Spouse will be taking EI too
                        </div>
                    </ModalRow>

                    <ModalRow>
                        <Switch
                            id="extended"
                            checked={extended}
                            className="extendedToggle"
                            height={20}
                            width={40}
                            onChange={this.onToggleCheckBoxExtended}
                        />
                        <div className="extendedText">
                            Will you be taking an extended maternal leave?
                        </div>
                    </ModalRow>

                    {spouseTakingTimeOff === true ? (
                        <ModalRow twoInputs>
                            <RequiredStar />
                            <ModalRow>
                                <SelectDropDownPerson
                                    onChangeInput={this.onChangeInput}
                                    value={personName}
                                    options={Object.keys(this.state.people)}
                                    className="PeopleSelect"
                                    id="person"
                                    label="Mother"
                                />
                            </ModalRow>
                            <ModalRow>
                                <SelectDropDownPerson
                                    onChangeInput={this.onChangeInput}
                                    value={spouseName}
                                    options={this.getSpouse(
                                        Object.keys(this.state.people)
                                    )}
                                    className="SpouseSelect"
                                    id="spouse"
                                    label="Spouse"
                                />
                            </ModalRow>
                        </ModalRow>
                    ) : (
                        <ModalRow twoInputs>
                            <RequiredStar />
                            <ModalRowHalf>
                                <SelectDropDownPerson
                                    onChangeInput={this.onChangeInput}
                                    value={personName}
                                    options={Object.keys(this.state.people)}
                                    className="PeopleSelect"
                                    id="person"
                                    label="Mother"
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    )}

                    {extended === false ? (
                        <div className="timeText">
                            Start date must be no more than 12 weeks before and
                            end date must be no more than 52 weeks after
                            expected child.
                        </div>
                    ) : (
                        <div className="timeText">
                            Start date must be no more than 12 weeks before and
                            end date must be no more than 61 weeks after
                            expected child.
                        </div>
                    )}
                    <ModalRow twoInputs>
                        {personName === "" ? (
                            <div> Mother&apos;s Information </div>
                        ) : (
                            <div>
                                {" "}
                                {this.state.personName}&apos;s Information{" "}
                            </div>
                        )}
                        <RequiredStar />
                        <ModalRow>
                            <Calendar
                                onHandleDate={this.onHandleDateMother}
                                startDate={start}
                                endDate={end}
                                cadence={cadence}
                            />
                        </ModalRow>
                    </ModalRow>

                    {spouseTakingTimeOff === true ? (
                        <ModalRow twoInputs>
                            {spouseName === "" ? (
                                <div> Spouse&apos;s Information </div>
                            ) : (
                                <div>
                                    {" "}
                                    {this.state.spouseName}&apos;s Information{" "}
                                </div>
                            )}
                            <RequiredStar />
                            <ModalRow>
                                <Calendar
                                    onHandleDate={this.onHandleDateSpouse}
                                    startDate={spouseStart}
                                    endDate={spouseEnd}
                                    cadence={cadence}
                                />
                            </ModalRow>
                        </ModalRow>
                    ) : (
                        <div />
                    )}

                    <button onClick={this.resetStates}>Reset Fields</button>

                    <ModalRow onMouseEnter={this.onHandleMouseEnter}>
                        <div className="loanPaymentsContainer">
                            <div className="paymentsAmountContainer">
                                <div className="paymentsLabel">
                                    {this.howLongCanILeave(
                                        this.state.start,
                                        this.state.end
                                    )}
                                </div>
                            </div>
                            <div className="paymentsAmountContainer">
                                <div className="paymentsLabel">
                                    {this.howManyWeeksWillILeave(
                                        this.state.start,
                                        this.state.end
                                    )}
                                </div>
                            </div>
                        </div>
                    </ModalRow>

                    <ModalRow>
                        <StarsRating
                            onHoverStar={onHoverStar}
                            onHandleStarHover={this.onHandleStarHover}
                            rating={rating}
                            onChangeInput={this.onChangeInput}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ModalRow>

                    <ModalRow>
                        <InflationSwitch
                            applyInflation={this.state.inflation}
                            toggleInflation={this.updateInflation}
                        />
                    </ModalRow>
                    {this.state.showRequired && (
                        <ModalRow>
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </ModalRow>
                    )}
                </EventInputs>

                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default MaternityInput;

import { cloneDeep } from "lodash";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { yearlyToMonthlyRate } from "./growthInputsHandler";

export const customerChurn2InputsHandler = (
    value: string,
    id:
        | "entityName"
        | "entity"
        | "churnType"
        | "maxChurnRate"
        | "constantChurnRate"
        | "interpolate"
        | "startDate"
        | "churnRate"
        | "period"
        | "inheritStartDate"
        | "inheritCustomer"
        | "startChurn"
        | "constantChurnPeriod"
        | "cadence",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    index?: number
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const newDependencies: {
                [key: string]: { eventId: string; entityIds: string[] };
            } = { ...(currentEntityObject.dependencies ?? {}) };
            if (!value) {
                newDependencies[id] = {
                    eventId: "",
                    entityIds: [],
                };
            } else {
                newDependencies[id] = JSON.parse(value);
            }
            currentEntityObject.dependencies = newDependencies;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "churnType":
            data.churnType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "constantChurnRate":
            if (Number(value) < 0) break;

            data.constantChurnRateDisplay = value;

            if (data.constantChurnPeriod == "monthly") {
                data.constantMonthlyChurnRate = Number(value);
            } else if (data.constantChurnPeriod == "yearly") {
                data.constantYearlyChurnRate = Number(value);
                data.constantMonthlyChurnRate =
                    yearlyToMonthlyRate(Number(value) * 0.01) * 100;
            }

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "maxChurnRate":
            if (Number(value) < 0) break;
            data.maxChurnRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interpolate":
            data.interpolate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "churnRate":
            if (!index && index !== 0) break;
            if (Number(value) < 0) break;

            const churnSegments = cloneDeep(data.churnSegments);
            const segment = churnSegments[index];

            segment.rateDisplay = value;

            if (segment.period == "monthly") {
                segment.monthlyRate = Number(value);
            } else if (segment.period == "yearly") {
                segment.yearlyRate = Number(value);
                segment.monthlyRate =
                    yearlyToMonthlyRate(Number(value) * 0.01) * 100;
            }

            churnSegments[index] = segment;
            data.churnSegments = churnSegments;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inheritCustomer":
            data.inheritCustomer = !data.inheritCustomer;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inheritStartDate":
            data.inheritStartDate = !data.inheritStartDate;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
        case "period":
            if (!index && index !== 0) break;

            const churnSegs = cloneDeep(data.churnSegments);
            const seg = churnSegs[index];

            seg[id] = value;

            if (id == "period") {
                const currentChurnRate =
                    seg.period == "monthly" ? seg.monthlyRate : seg.yearlyRate;
                seg.rateDisplay = String(
                    Math.round(Number(currentChurnRate) * 100) / 100
                );
            }

            churnSegs[index] = seg;
            data.churnSegments = churnSegs;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startChurn":
            if (Number(value) < 0) break;
            data.startChurn = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "constantChurnPeriod":
            data.constantChurnPeriod = value;

            const currentChurnRate =
                value == "monthly"
                    ? data.constantMonthlyChurnRate
                    : data.constantYearlyChurnRate;
            data.constantChurnRateDisplay = String(
                Math.round(Number(currentChurnRate) * 100) / 100
            );

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

// import { getDefaultName } from "../helpers";
import moment from "moment";
import { expenseObject } from "../Components/Registry/Expense";
import { tuitionObject } from "../Components/Registry/Tuition";
import { rentalIncomeObject } from "../Components/Registry/Rental Income";
import { loanObject } from "../Components/Registry/Loan";
import { studentLoanObject } from "../Components/Registry/Student Loan";
import { rrspObject } from "Components/Registry/RRSP";
import { rentObject } from "Components/Registry/Rent";
import { mortgageObject } from "Components/Registry/Mortgage";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { incomeObject } from "Components/Registry/Income";
import { investmentObject } from "Components/Registry/Investment";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { taxDeductibleObject } from "Components/Registry/Tax Deductible";
import * as uuid from "uuid";
import { events } from "Components/InputContainer/Master Registry";
import { getRelevantEntities } from "actions/getNodeEntityActions";

export default class Event {
    constructor(type, data, id, manager) {
        // Previously known as 'metadata'
        this.id = id || uuid.v4();
        this.type = type;
        this.version = this.getEventVersion(this.version, this.type);
        // We don't actually store in the database, is it even used?
        const entities = data.entities ?? [];
        this.entities = [...entities]; // Will contain an array of objects [{ id: string, active: boolean }]
        this.name = data.name;
        this.description = data.description;
        this.baseline = data.baseline ?? false;

        // The rest of the properties
        this.children = [];
        this.parents = [];
        this.locked = data.locked ?? false; // TODO - with Entities, what does it mean to have a locked Event?
        this.linked = data.linked ?? false;
        this.bypassed = data.bypassed ?? false;
        this.movedToBaseline = data.movedToBaseline ?? false; // Previously known as "isMovedToBaseline", changed to distinguish from isMovedToBaseline() function below.
        this.isExpired = data.isExpired ?? false; // Previously known as "isNodeExpired", changed to distinguish from isNodeExpired() function below.
        this.isOutdated = data.isOutdated ?? false; // Previously known as "isNodeOutdated", changed to distinguish from isNodeOutdated() function below.
        this.excludedBaselineNode = data.excludedBaselineNode ?? []; // I think this is only useful for "Me" nodes.
        this.isFilled = data.isFilled;
        this.valid = data.valid;
        this.hideInCanvas = false;
        this.active = true;
        this.movex = null;
        this.movey = null;
        this.depth = 0;
        this.depthSet = null;
        this.breadth = 0;
        this.breadthSet = null;
        this.level = 0;
        this.levelSet = null;
        this._manager = manager;
        this.nodeKey = "value";
        this.mostRecentEntity = data.mostRecentEntity ?? 0;
        this.userSetX = data?.userSetX;
        this.userSetY = data?.userSetY;
        this.relevantContainerId = data?.relevantContainerId ?? null;
        // TODO: Still need to come up with a way to get the node's display
        // value (nodeDetail). Maybe a sum of it's Entities' values?
        //this.nodeDetail = processNodeValueForCanvasDisplay(value);

        // Most recent entities state
        this.getEntities = () =>
            getRelevantEntities(this.entities.map((entity) => entity.id));
    }

    updateNodeBaseline = (baselineNode) => {
        if (this.excludedBaselineNode.includes(baselineNode.id)) {
            const newArray = this.excludedBaselineNode.filter((id) => {
                return id !== baselineNode.id;
            });
            this.excludedBaselineNode = newArray;
        } else {
            const newArrayIds = [...this.excludedBaselineNode, baselineNode.id];
            this.excludedBaselineNode = newArrayIds;
        }
        this._manager._updateScenarioCanvas();
    };

    toggleAllBaselineNodes = (baselineNodes) => {
        let newArray = [];

        if (this.excludedBaselineNode.length !== baselineNodes.length) {
            baselineNodes.forEach((baselineNode) =>
                newArray.push(baselineNode.id)
            );
        } else {
            newArray = [];
        }
        this.excludedBaselineNode = newArray;
        this._manager._updateScenarioCanvas();
    };

    x = () => {
        return typeof this.userSetX == "number"
            ? this.userSetX
            : this.depth * 210 + 100;
    };

    isSubTreeStart = () => {
        return this.level === 1;
    };

    getEventVersion = (version, type) => {
        if (version === null || version === undefined) {
            for (let eventObj of events) {
                if (eventObj.constant() === type) {
                    return eventObj.version();
                }
            }
        } else {
            return version;
        }
    };

    _balancedBreadth = () => {
        return this.breadth;
        // balancing is now handled by the manager
    };

    y = () => {
        return typeof this.userSetY == "number"
            ? this.userSetY
            : this._balancedBreadth() * 155 + 300;
    };

    copy = () => {
        const data = this.exportData();

        data.locked = false;
        data.bypassed = false;

        // data.name = getDefaultName(this.type, this._manager);
        return JSON.parse(JSON.stringify(data));
    };

    exportData = () => {
        const data = {
            id: this.id,
            version: this.version,
            type: this.type,
            entities: this.entities,
            children: this.children.map((node) => node.id),
            parents: this.parents.map((node) => node.id),
            name: this.name,
            description: this.description,
            baseline: this.baseline,
            isFilled: this.isFilled,
            valid: this.valid,
            bypassed: this.isBypassed(),
            locked: this.isLocked(),
            linked: this.isLinked(),
            mostRecentEntity: this.mostRecentEntity,
            userSetX: this.getUserSetX(),
            userSetY: this.getUserSetY(),
            relevantContainerId: this.relevantContainerId,
        };

        return data;
    };

    siblings = () => {
        const nodeSiblings = {},
            parents = this.parents.map((p) => {
                return this._manager._findEvent(p);
            });

        parents.forEach((parent) => {
            parent.children.forEach((ch) => {
                const child = this._manager._findEvent(ch);
                if (child.id !== this.id) {
                    nodeSiblings[child.id] = child;
                }
            });
        });

        return Object.values(nodeSiblings);
    };

    getUserSetX = () => {
        return this.userSetX;
    };
    getUserSetY = () => {
        return this.userSetY;
    };

    isLocked = () => {
        return this.locked;
    };

    isLinked = () => {
        return this.linked;
    };

    isBypassed = () => {
        return this.bypassed;
    };

    toggleLocked = () => {
        this.locked = !this.locked;
        this._manager._updateScenarioCanvas();
    };

    toggleBypassed = () => {
        this.bypassed = !this.bypassed;
        this._manager._updateScenarioCanvas();
    };

    toggleBaseline = () => {
        this.baseline = !this.baseline;
        this._manager._updateScenarioCanvas();
    };

    isBaseline = () => {
        return this.baseline;
    };

    isMovedToBaselineToggle = () => {
        console.log("here in move to baseline:)");
        this.movedToBaseline = !this.movedToBaseline;
        // this._manager._updateScenarioCanvas();
        // this._manager.updateCanvas()
    };

    isMovedToBaseline = () => {
        return this.movedToBaseline;
    };

    isNodeExpired = () => {
        let nodeExpired = false;
        const entitiesState = this.getEntities();
        // The .every() loop will continue until it reaches the end of the array or the callback function returns false
        Object.values(entitiesState).every((entity) => {
            const relevantEntity = entitiesState[entity.id];
            const oneTimeFrequency = relevantEntity.cadence === "one-time";
            const startDate = relevantEntity.startDate;
            const end = relevantEntity.endDate;
            let endDate;
            switch (relevantEntity.type) {
                case downpaymentObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    }
                    break;
                case expenseObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    } else if (end) {
                        endDate = end;
                    } else {
                        return null;
                    }
                    break;
                case tuitionObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    } else if (end) {
                        endDate = end;
                    } else {
                        return null;
                    }
                    break;
                case rentalIncomeObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    } else if (end) {
                        endDate = end;
                    } else {
                        return null;
                    }
                    break;
                case incomeObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    } else if (end) {
                        endDate = end;
                    } else {
                        return null;
                    }
                    break;
                case taxDeductibleObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    } else if (end) {
                        endDate = end;
                    } else {
                        return null;
                    }
                    break;
                case investmentObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    }
                    break;
                case loanObject.constant():
                    if (oneTimeFrequency) {
                        endDate = relevantEntity.data["first payment"];
                    }
                    break;
                case studentLoanObject.constant():
                    if (oneTimeFrequency) {
                        endDate = relevantEntity.data["first payment"];
                    }
                    break;
                case mortgageObject.constant():
                    if (oneTimeFrequency) {
                        endDate = relevantEntity.data["first payment"];
                    }
                    break;
                case propertyResaleObject.constant():
                    endDate = startDate;
                    break;
                case rentObject.constant():
                    if (oneTimeFrequency) {
                        endDate = startDate;
                    } else if (end) {
                        endDate = end;
                    } else {
                        return null;
                    }
                    break;
                case rrspObject.constant():
                    if (oneTimeFrequency) {
                        endDate = relevantEntity.data["first payment"];
                    }
                    break;
                default:
            }

            if (endDate && moment().diff(endDate, "days") >= 1) {
                nodeExpired = true;
                // Return false here to break out of .every() loop
                return false;
            }
            // Return true here to keep the .every() loop going
            return true;
        });
        this.isExpired = nodeExpired;
        return nodeExpired;
    };

    isNodeOutdated = () => {
        let localVersion = String(this.version).split(".");
        let globalVersion = "1.0.0";
        for (let eventObj of events) {
            if (eventObj.constant() === this.type) {
                globalVersion = eventObj.version();
            }
        }
        let nodeOutdated;
        if (parseInt(localVersion[0]) < parseInt(globalVersion[0])) {
            nodeOutdated = true;
        } else if (parseInt(localVersion[0]) === parseInt(globalVersion[0])) {
            if (parseInt(localVersion[1]) < parseInt(globalVersion[1])) {
                nodeOutdated = true;
            } else {
                nodeOutdated = false;
            }
        } else {
            nodeOutdated = false;
        }
        this.isOutdated = nodeOutdated;
        return nodeOutdated;
    };

    setParents = (
        parents = [],
        update = false,
        setChildren = true,
        insertIndex = -1
    ) => {
        // update seems to be always false in setParent and always true for setChildren, not sure
        if (update) {
            parents.forEach((parent) => {
                if (this.parents.indexOf(parent) === -1) {
                    this.parents.push(parent);
                    if (parent && setChildren) {
                        parent.setChildren([this], true, false);
                    }
                }
            });
        } else {
            this.parents = parents;
            if (insertIndex < 0) {
                insertIndex = parents.length;
            }
            this.parents.forEach((parent) => {
                parent.children.splice(insertIndex, 0, this);

                const sharedChildren = [],
                    exclusiveChildren = [];
                parent.children.forEach((child) => {
                    if (child.parents.length > 1) {
                        sharedChildren.push(child);
                    } else {
                        exclusiveChildren.push(child);
                    }
                });
                parent.children = exclusiveChildren.concat(sharedChildren);
            });
        }
    };

    setChildren = (
        children = [],
        update = false,
        setParents = true,
        insertIndex = -1
    ) => {
        if (update) {
            children.forEach((child) => {
                if (this.children.indexOf(child) === -1) {
                    if (insertIndex < 0) {
                        insertIndex = children.length;
                    }
                    this.children.splice(insertIndex, 0, child);
                    // this.children.push(child);
                    if (child && setParents) {
                        child.setParents([this], true, false);
                    }
                }
            });
        } else {
            this.children = children;
            if (insertIndex < 0) {
                insertIndex = children.length;
            }
            this.children.forEach((child) => {
                child.parents.splice(insertIndex, 0, this);
            });
        }
    };

    detachParents = (parents = [], detachChildren = true) => {
        let toRemove = [];
        parents.forEach((parentEvent) => {
            const parentIndex = this.parents.indexOf(parentEvent);
            if (parentIndex > -1) {
                toRemove.push(parentIndex);
            }
        });

        toRemove = toRemove.sort((a, b) => {
            return b - a;
        });
        toRemove.forEach((index) => {
            const parent = this.parents.splice(index, 1);
            if (detachChildren) {
                const parentEvent = this._manager._findEvent(parent?.[0].id);
                parentEvent?.detachChildren([this], false);
            }
        });
    };

    detachChildren = (children = [], detachParents = true) => {
        let toRemove = [];
        children.forEach((childEvent) => {
            const childIndex = this.children.indexOf(childEvent);
            if (childIndex > -1) {
                toRemove.push(childIndex);
            }
        });

        toRemove = toRemove.sort((a, b) => {
            return b - a;
        });
        toRemove.forEach((index) => {
            const child = this.children.splice(index, 1);
            if (detachParents) {
                const childEvent = this._manager._findEvent(child?.[0].id);
                childEvent?.detachParents([this], false);
            }
        });
    };

    setDepth = (depth, setAt) => {
        if (
            this.depthSet == null ||
            setAt > this.depthSet ||
            (setAt === this.depthSet && depth > this.depth)
        ) {
            //console.log(`${this.depthSet == null ? "Setting" : "\tResetting"} depth of`,this.metadata.name,depth);
            this.depth = depth;
        }

        this.depthSet = setAt;
        return this.depth;
    };

    setBreadth = (breadth, setAt) => {
        let set = false;
        if (
            this.breadthSet == null ||
            setAt > this.breadthSet ||
            (setAt === this.breadthSet && breadth < this.breadth)
        ) {
            //console.log(`${this.breadthSet == null ? "Setting" : "\tResetting"} breadth of`,this.metadata.name,breadth);
            set = true;
            this.breadth = breadth;
        }

        this.breadthSet = setAt;
        return set;
    };

    setLevel = (level, setAt) => {
        let set = false;
        if (
            this.levelSet == null ||
            setAt > this.levelSet ||
            (setAt === this.levelSet && level < this.level)
        ) {
            set = true;
            this.level = level;
        }

        this.levelSet = setAt;
        return set;
    };

    /**
     *
     * @param {boolean} newVal
     */
    setHideInCanvas = (newVal) => {
        this.hideInCanvas = newVal;
    };
}

import OptionsContainer from "./Options";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";

const mapStateToProps = (state) => {
    return {
        loadedScenario: state.scenario.loadedScenario,
        onboardingData: state.scenario.onboardingData,
        manager: state.scenario.manager,
        baseline: state.scenario.baseline,
        eventLibrary: state.scenario.eventLibrary,
        edit: state.scenario.edit,
        currentOptionsTab: state.scenario.currentOptionsTab,
        accountData: state.scenario.accountData,
    };
};

const mapDispatchToProps = {
    createScenario: actions.createScenario,
    getUserScenarios: actions.getUserScenarios,
    loadScenario: actions.loadScenario,
    editNode: actions.editNode,
    deleteLibraryEvents: actions.deleteLibraryEvents,
    setOptionPickerTab: actions.setOptionPickerTab,
};

const Options = connect(
    mapStateToProps,
    mapDispatchToProps
)(OptionsContainer);

export default Options;

// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    SelectDropDown,
    Value,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    InflationSwitch,
    EntityName,
    ToggledContainer,
    ToggledContainerHalf,
    Rate,
    // SelectEntities,
    ModalRowComponent,
    MuiCalendar,
} from "../Components";
import { contractObject } from "Components/Registry/Contract";
import {
    validateContractLengthInput,
    validateMilestoneOffsetInput,
} from "../OnInputChangeHandlers/contractInputsHandler";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

const options = [
    "0 Days",
    "7 Days",
    "14 Days",
    "30 Days",
    "60 Days",
    "90 Days",
    "120 Days",
];

export default function ContractInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    updateInflation,
    onHandleSubmit,
    handleOnChange,
    handleAddDynamicValue,
    handleDeleteDynamicValue,
    passedCheck,
    eventData,
    edit,
    handleChangeCalculationMode,
    resetContractLength,
    resetMilestoneOffset,
    handleEntityStateChange,
    handleDateSelection,
    updateAccount,
}) {
    const getDynamicPanel = () => {
        const specificPercentages =
            entitiesMap[currentEntity]?.data?.specificPercentages;
        return (
            <>
                {specificPercentages &&
                    specificPercentages.map((milestoneData, index) => {
                        // Creates the milestone panels
                        return (
                            <ModalRow twoInputs key={index}>
                                <ModalRowComponent
                                    id={
                                        entitiesMap[currentEntity]?.data
                                            ?.calculationMode
                                    }
                                    width={15}
                                >
                                    <Rate
                                        label={
                                            entitiesMap[currentEntity]?.data
                                                ?.calculationMode == "days"
                                                ? "Days Out"
                                                : "Months Out"
                                        }
                                        onChangeInput={handleOnChange}
                                        value={
                                            entitiesMap[currentEntity]?.data
                                                ?.calculationMode == "days"
                                                ? milestoneData?.offsetDays ||
                                                  ""
                                                : milestoneData?.offsetMonths ||
                                                  ""
                                        }
                                        id="offset"
                                        mainId={milestoneData?.id}
                                        className="mlsInput"
                                        sign={false}
                                        validate={() =>
                                            validateMilestoneOffsetInput(
                                                entitiesMap[currentEntity],
                                                resetMilestoneOffset,
                                                milestoneData
                                            )
                                        }
                                        required={true}
                                    />
                                </ModalRowComponent>
                                <ModalRowHalf>
                                    <MuiCalendar
                                        id="milestone"
                                        value={milestoneData?.date}
                                        required={true}
                                        disabled={true}
                                        onChangeInput={handleDateSelection}
                                        label="Milestone Date"
                                    />
                                </ModalRowHalf>
                                <ModalRowComponent id="Payout" width={23.5}>
                                    <Rate
                                        label="Payout Percentage"
                                        onChangeInput={handleOnChange}
                                        value={milestoneData?.value}
                                        id="payoutPercentage"
                                        mainId={milestoneData?.id}
                                        className="mlsInput"
                                        sign={true}
                                        required={true}
                                    />
                                </ModalRowComponent>
                                <ModalRowComponent id="Net" width={23.5}>
                                    <SelectDropDown
                                        label="Net Days"
                                        onChangeInput={handleOnChange}
                                        value={milestoneData?.net}
                                        id="net"
                                        mainId={milestoneData?.id}
                                        className="mlsInput"
                                        options={options}
                                        required={true}
                                    />
                                </ModalRowComponent>
                            </ModalRow>
                        );
                    })}
                <ModalRow>
                    <div onClick={handleAddDynamicValue} className="textButton">
                        Add
                    </div>
                    {specificPercentages?.length > 0 && (
                        <div
                            onClick={handleDeleteDynamicValue}
                            className="addButton"
                        >
                            Delete Recently added
                        </div>
                    )}
                </ModalRow>
            </>
        );
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    image={contractObject.svg()}
                    eventType={contractObject.name()}
                    onChangeInput={onChangeNameDescription}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[contractObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>

                <ToggledContainer>
                    <ToggledContainerHalf
                        id="days"
                        isSelected={
                            entitiesMap[currentEntity]?.data?.calculationMode ==
                            "days"
                        }
                        onClick={handleChangeCalculationMode}
                        title="Use Days"
                    />
                    <ToggledContainerHalf
                        id="months"
                        isSelected={
                            entitiesMap[currentEntity]?.data?.calculationMode ==
                            "months"
                        }
                        onClick={handleChangeCalculationMode}
                        title="Use Months"
                    />
                </ToggledContainer>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="startDate"
                            value={entitiesMap?.[currentEntity]?.startDate}
                            required={true}
                            onChangeInput={handleDateSelection}
                            label="Start Date"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={
                                entitiesMap[currentEntity]?.data?.accountName ??
                                ""
                            }
                            topLevelFilters={{
                                "488dd61d-8697-4213-8978-cf91755365a4": true,
                            }}
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow twoInputs>
                    <ModalRowComponent id="contract" width={48.5}>
                        <Value
                            onChangeInput={handleOnChange}
                            value={entitiesMap[currentEntity]?.data?.income}
                            id="value"
                            label="Contract Amount"
                            unit="$"
                            required={true}
                        />
                    </ModalRowComponent>

                    <ModalRowComponent id="Payout" width={23.5}>
                        <Rate
                            label="Initial Payout Percentage"
                            onChangeInput={handleOnChange}
                            value={
                                entitiesMap[currentEntity]?.data?.initialPayout
                            }
                            id="initialPayout"
                            className="mlsInput"
                            sign={true}
                            required={true}
                        />
                    </ModalRowComponent>

                    <ModalRowComponent id="Net" width={23.5}>
                        <SelectDropDown
                            label="Net Days"
                            onChangeInput={handleOnChange}
                            value={entitiesMap[currentEntity]?.data?.netInitial}
                            id="netInitial"
                            className="mlsInput"
                            options={options}
                            required={true}
                        />
                    </ModalRowComponent>
                </ModalRow>

                {entitiesMap[currentEntity]?.startDate && (
                    <>
                        <ModalRow>
                            <div className="PTTLabel">On Completion:</div>
                        </ModalRow>

                        <ModalRow twoInputs>
                            <ModalRowComponent
                                id={
                                    entitiesMap[currentEntity]?.data
                                        ?.calculationMode
                                }
                                width={15}
                            >
                                <Rate
                                    label={
                                        entitiesMap[currentEntity]?.data
                                            ?.calculationMode == "days"
                                            ? "Days Out"
                                            : "Months Out"
                                    }
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap[currentEntity]?.data
                                            ?.calculationMode == "days"
                                            ? entitiesMap[currentEntity]?.data
                                                  ?.contractLengthDays || ""
                                            : entitiesMap[currentEntity]?.data
                                                  ?.contractLengthMonths || ""
                                    }
                                    id="contractLength"
                                    mainId={entitiesMap[currentEntity]?.id}
                                    className="mlsInput"
                                    sign={false}
                                    validate={() =>
                                        validateContractLengthInput(
                                            entitiesMap[currentEntity],
                                            resetContractLength
                                        )
                                    }
                                    required={true}
                                />
                            </ModalRowComponent>
                            <ModalRowHalf>
                                <MuiCalendar
                                    id="end"
                                    value={
                                        entitiesMap?.[currentEntity]?.endDate
                                    }
                                    required={true}
                                    disabled={true}
                                    onChangeInput={handleDateSelection}
                                    label="Completion Date"
                                />
                            </ModalRowHalf>
                            <ModalRowComponent id="Payout" width={23.5}>
                                <Rate
                                    label="Final Payout Percentage"
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap[currentEntity]?.data
                                            ?.finalPayout
                                    }
                                    id=""
                                    className="mlsInput"
                                    sign={true}
                                    disabled={true}
                                    required={true}
                                />
                            </ModalRowComponent>

                            <ModalRowComponent id="Net" width={23.5}>
                                <SelectDropDown
                                    label="Net Days"
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap[currentEntity]?.data
                                            ?.netFinal
                                    }
                                    id="netFinal"
                                    className="mlsInput"
                                    options={options}
                                    required={true}
                                />
                            </ModalRowComponent>
                        </ModalRow>
                    </>
                )}
                <>
                    {entitiesMap[currentEntity]?.endDate && (
                        <ModalRow>
                            <div className="PTTLabel">Optional Milestones:</div>
                        </ModalRow>
                    )}
                    {entitiesMap[currentEntity]?.endDate && getDynamicPanel()}
                </>

                {/* <ModalRow>
                    <SelectEntities
                        onChangeInput={handleOnChange}
                        showAll={false}
                        value={
                            entitiesMap?.[currentEntity]?.data?.selectedBusiness
                        }
                        nodes={Object.values(
                            entitiesMap?.[currentEntity]?.data?.businesses || []
                        )}
                        className="IncomeSelect"
                        id="business"
                        label="Select Business"
                        required={true}
                    />
                </ModalRow> */}
                <ModalRow>
                    <InflationSwitch
                        applyInflation={
                            entitiesMap[currentEntity]?.data?.inflation
                        }
                        toggleInflation={updateInflation}
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import { UPSERT_ACTIONS_DATA } from "actions/canvasActionsDataActions";
import type { ActionsDataSchema } from "./typesSchema/menusTemplatesSchema";

const initialState: ActionsDataSchema = {};

export default function canvasActionsDataReducer(
    state: ActionsDataSchema = initialState,
    action: { type: string; payload?: any }
): ActionsDataSchema {
    switch (action.type) {
        case UPSERT_ACTIONS_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

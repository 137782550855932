import type { LedgerMetadataUnit } from "reducers/typesSchema/allAccountLedgersSchema";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { useDrawGraph } from "./useDrawGraph";
import GraphLineView from "./GraphLineView";

const defaultUnit: LedgerMetadataUnit = {
    beforeValue: true,
    display: "$",
    singular: "$",
    spacingBetween: false,
    nearestDecimal: 0,
};

export default function GraphLineController() {
    const activeThreadId: string = useAppSelector(
        (state) => state.scenario?.highlightedThread?.signature || ""
    );
    const calculatedThreads = useAppSelector(
        (state) => state.calculatedThreads,
        shallowEqual
    );
    const chartGraphState = useAppSelector(
        (state) => state.chartGraph,
        shallowEqual
    );
    const selectedThreads = useAppSelector(
        (state) => state.scenarioViewSelectedThreads.selectedThreads,
        shallowEqual
    );
    const ledgerMetadataUnit = useAppSelector((state) => {
        const firstSelectedLedgerId = Object.keys(
            chartGraphState?.selectedAccounts ?? {}
        )?.[0];
        const _ledgerMetadataUnitDisplay =
            state.allAccountLedgers.ledgersMetadata[firstSelectedLedgerId]
                ?.unit || defaultUnit;
        return _ledgerMetadataUnitDisplay;
    }, shallowEqual);

    const filteredEventAndEntityData = useAppSelector((state) => {
        const filteredEventAndEntityData: any[] = Object.values(
            state?.scenario?.filteredEventAndEntityData ?? {}
        );
        return filteredEventAndEntityData;
    }, shallowEqual);

    const { xyChart, graphLoading } = useDrawGraph({
        activeThreadId,
        calculatedThreads,
        chartGraphState,
        selectedThreads,
        ledgerMetadataUnit,
        filteredEventAndEntityData,
    });

    return <GraphLineView xyChart={xyChart} graphLoading={graphLoading} />;
}

import { cloneDeep } from "lodash";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

export const CAC2InputsHandler = (
    value: string,
    id:
        | "entityName"
        | "entity"
        | "costType"
        | "constantCost"
        | "interpolate"
        | "startDate"
        | "cost"
        | "inheritStartDate",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    index?: number
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const newDependencies: {
                [key: string]: { eventId: string; entityIds: string[] };
            } = { ...(currentEntityObject.dependencies ?? {}) };
            if (!value) {
                newDependencies[id] = {
                    eventId: "",
                    entityIds: [],
                };
            } else {
                newDependencies[id] = JSON.parse(value);
            }
            currentEntityObject.dependencies = newDependencies;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "costType":
            data.costType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "constantCost":
            if (Number(value) < 0) break;
            data.constantCost = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interpolate":
            data.interpolate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cost":
            if (!index && index !== 0) break;
            if (Number(value) < 0) break;

            const costSegments = cloneDeep(data.costSegments);
            const segment = costSegments[index];

            segment.rateDisplay = value;

            segment.monthlyRate = Number(value);

            costSegments[index] = segment;
            data.costSegments = costSegments;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "inheritStartDate":
            data.inheritStartDate = !data.inheritStartDate;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            if (!index && index !== 0) break;

            const segs = cloneDeep(data.costSegments);
            const seg = segs[index];

            seg[id] = value;

            segs[index] = seg;
            data.costSegments = segs;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

import { createContext } from "react";

export const HoverButtonIdContext = createContext("");
HoverButtonIdContext.displayName = "HoverButtonIdContext";

export const SetHoverButtonIdContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetHoverButtonIdContext.displayName = "SetHoverButtonIdContext";

import Konva from "konva";
import { useContext, useRef } from "react";
import { animated, useSpring } from "@react-spring/konva";
import { Group } from "react-konva";
import { SetHoverButtonIdContext } from "../../../../contexts/HoverButtonIdContext";
import { EventNodeIDContext } from "../../../../contexts/EventNodeIDContext";
import { useIconContainerDimensions } from "./useIconContainerDimensions";
import { useIconContainerStylesFull } from "./useIconContainerStylesFull";
import { useIconStyles } from "./useIconStyles";

import { useAppDispatch } from "store/useAppSelectorDispatch";
import { eventActionsMap } from "actions/canvasEventActions";
import { CanvasBaselineContext } from "../../../../../../../contexts/CanvasBaselineContext";
import useImage from "use-image";
import { useIconImage } from "./useIconImage";

const MenuIconLayerOne = Group;
const MenuIconLayerTwo = Group;
const MenuIconLayerThree = Group;

interface MenuIconProps {
    descriptivePosition: string;
    buttonId: string;
    horizontalCenter: number;
}

/**
 * Split into three layers:
 * Layer 1: The viewable background
 * Layer 2: The icon
 * Layer 3: The clickable area (same dimensions as background)
 */
export function MenuIcon({
    descriptivePosition,
    buttonId,
    horizontalCenter,
}: MenuIconProps) {
    const isBaseline = useContext(CanvasBaselineContext);
    const eventId = useContext(EventNodeIDContext);
    const setHoverButtonId = useContext(SetHoverButtonIdContext);

    const actionImagePath = useIconImage(buttonId);

    const [actionImage] = useImage(
        `${window.location.origin}/${actionImagePath}`
    );

    const dispatch = useAppDispatch();

    const { iconContainerDimensions, iconContainerDimensionsAnimationConfig } =
        useIconContainerDimensions(descriptivePosition, buttonId);
    const { iconContainerStylesFull, iconContainerAnimationConfigFull } =
        useIconContainerStylesFull(
            buttonId,
            iconContainerDimensions,
            iconContainerDimensionsAnimationConfig
        );
    const { iconStyles, iconAnimationConfig } = useIconStyles(buttonId);

    const animatedIconContainerDimensions = useSpring({
        to: iconContainerDimensions,
        config: iconContainerDimensionsAnimationConfig,
    });

    const animatedIconContainerWithColorsStyles = useSpring({
        to: iconContainerStylesFull,
        config: iconContainerAnimationConfigFull,
    });

    const animatedIconStyles = useSpring({
        to: iconStyles,
        config: iconAnimationConfig,
    });

    const prevCursorStyle = useRef("");
    const onMouseEnterMenuIcon = (
        e: Konva.KonvaEventObject<MouseEvent>,
        buttonId: string
    ) => {
        const container = e.target.getStage()?.container();
        if (container) {
            prevCursorStyle.current = container.style.cursor;
            container.style.cursor = "pointer";
        }
        setHoverButtonId(buttonId);
    };
    const onMouseLeaveMenuIcon = (
        e: Konva.KonvaEventObject<MouseEvent>,
        _buttonId: string
    ) => {
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = prevCursorStyle.current;
        setHoverButtonId("");
    };

    const onClickMenuIcon = (
        e: Konva.KonvaEventObject<MouseEvent>,
        buttonId: string
    ) => {
        dispatch(eventActionsMap?.[buttonId](eventId, isBaseline));
    };

    return (
        <Group x={horizontalCenter}>
            <MenuIconLayerOne listening={false}>
                {/* @ts-ignore - Seems to be a react spring type error */}
                <animated.Rect {...animatedIconContainerWithColorsStyles} />
            </MenuIconLayerOne>
            <MenuIconLayerTwo listening={false}>
                <animated.Image {...animatedIconStyles} image={actionImage} />
            </MenuIconLayerTwo>
            <MenuIconLayerThree>
                <animated.Rect
                    {...animatedIconContainerDimensions}
                    onMouseEnter={(e) => onMouseEnterMenuIcon(e, buttonId)}
                    onMouseLeave={(e) => onMouseLeaveMenuIcon(e, buttonId)}
                    onClick={(e) => onClickMenuIcon(e, buttonId)}
                />
            </MenuIconLayerThree>
        </Group>
    );
}

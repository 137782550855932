// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import ImportCard from "../Card/importCard";

// Import Connection Image
import ImportConnectionImage from "Assets/_optionDescriptionIcons/Import white.jpg";

// Import Coloured PNGs
import importDarkGrey from "../../Assets/_eventNodeTypes/importSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import ImportInputExperimental from "Components/InputContainer/ImportInputExperimental/ImportInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const importObject = {
    constant: function () {
        return importObject_Constant();
    },

    name: function () {
        return "Import";
    },

    checkInput: function (state) {
        return importObject_CheckInput(state);
    },

    svg: function () {
        return importObject_Svg();
    },

    eventNodeMenu: function (focus) {
        return importObject_EventNodeMenu(focus);
    },

    indexText: function () {
        return importObject_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return importObject_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return importObject_OptionDescription();
    },

    colouredPngs: function () {
        return importObject_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return importObject_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Import_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const importObject_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const importObject_Constant = () => {
    return "3fe7eb5e-0243-11ed-b939-0242ac120002";
};

// EFFECTS: Returns svg for event
const importObject_Svg = () => {
    return importDarkGrey;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const importObject_EventNodeMenu = (focus) => {
    return <ImportCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const importObject_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const importObject_Icons = () => {
    let pngs = new Map([
        ["black", importDarkGrey],
        ["blue", importDarkGrey],
        ["white", importDarkGrey],
        ["grey", importDarkGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const importObject_InputComponentInfoPassing = (minProps) => {
    return <ImportInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const importObject_OptionDescription = () => {
    const tags = ["Option"];
    const type = importObject.name();
    const description =
        "The Import Event is an event that processes external data and populates the scenario with events.";
    const usedForText =
        "Use this event to automatically create Income and Expense events representing a Profit & Loss statement through a csv file";
    const connection = "";
    const connectionImage = ImportConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const importObject_SideModalImage = () => {
    return sideImage;
};

const Import_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

// import type { AnimationConfig } from "reducers/typesSchema/eventsTemplatesSchema";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { LayerThree } from "./Layers"; // Keep this line so that JSDocs will link properly.
import { useContext, useMemo } from "react";
import { Group } from "react-konva";
import { EventNodeDetailController } from "./EventNodeDetailController";
import { EventNodeIDContext } from "../contexts/EventNodeIDContext";
import { useEventTemplate } from "../hooks/useEventTemplate";

/**
 * Represents the visual portion of the event's data.
 * For example, the event's name, aggregate values, description, etc.
 *
 * Only used in {@link LayerThree}
 */
export function EventNodeDetails() {
    const eventId = useContext(EventNodeIDContext);
    const { eventTemplateObject } = useEventTemplate(eventId);
    const eventNodeDetailDescriptions = useMemo(
        () => eventTemplateObject?.eventNodeDetailDescriptions ?? [],
        [eventTemplateObject?.eventNodeDetailDescriptions]
    );

    return (
        <Group>
            {eventNodeDetailDescriptions?.map((eventNodeDetailDescription) => (
                <EventNodeDetailController
                    key={eventNodeDetailDescription.id}
                    eventNodeDetailDescription={eventNodeDetailDescription}
                />
            ))}
        </Group>
    );
}

import { memo } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import { AccountCardMain } from "./AccountCardMain";
import { formatLargeNumber } from "helpers/formatLargeNumber";
import { addAllAverages } from "Components/ChartGraph/helpers/addAllAverages";

import type { CanvasCardSchema } from "reducers/typesSchema/canvasCardSchema";

import {
    AccountCardIdContext,
    UpdatePositionContext,
    CardDisplayValueContext,
    AccountNameContext,
    UpdateMonthlyOrCumulative,
    MonthlyOrCumulativeContext,
    AccountCardFocusContext,
} from "./contexts/AccountCardContexts";

import { updateCard } from "actions/canvasCardActions";

const _CanvasAccountCardWrapper = ({ cardId }: { cardId: string }) => {
    const dispatch = useAppDispatch();

    const activeThreadCalculatedThread = useAppSelector((state) => {
        const activeThreadId = state.scenario?.highlightedThread?.signature;
        const calculatedThreads = state?.calculatedThreads;
        const _activeThreadCalculatedThread =
            calculatedThreads?.[activeThreadId]?.ledgersDataAverages || {};
        return _activeThreadCalculatedThread;
    }, shallowEqual);

    const nodeIds = useAppSelector((state) => {
        const { nodeIds: _nodeIds } = state?.chartGraph;
        return _nodeIds.length ? _nodeIds : ["_All"];
    }, shallowEqual);

    const {
        cardData,
        x,
        y,
        cardDisplayValue,
        accountName,
        monthlyOrCumulative,
        focused,
    } = useAppSelector((state) => {
        const cardData: CanvasCardSchema = state?.canvasCards?.[cardId] ?? {};
        const x: number = Number(cardData?.x) ?? 0;
        const y: number = Number(cardData?.y) ?? 0;
        const monthlyOrCumulative: "monthly" | "cumulative" =
            cardData?.data?.monthlyOrCumulative;
        const accountId: string = cardData?.data?.accountId;
        const accountData =
            state?.allAccountLedgers?.ledgersMetadata?.[accountId];
        const accountName: string = accountData?.name ?? "";
        const averageValue = addAllAverages(
            activeThreadCalculatedThread?.[
                monthlyOrCumulative === "monthly"
                    ? accountId
                    : `Cumulative-${accountId}`
            ],
            nodeIds
        );

        const focused = cardId === state?.scenario?.focusedCardId;
        const formattedValue = formatLargeNumber(averageValue ?? 0, 1);
        let cardDisplayValue = formattedValue;
        if (!accountData?.unit?.singular?.includes("Customer")) {
            const unitDisplay =
                averageValue > 1
                    ? accountData?.unit?.display
                    : accountData?.unit?.singular;
            const spacing = accountData?.unit?.spacingBetween ? " " : "";
            if (accountData?.unit?.beforeValue) {
                cardDisplayValue = `${unitDisplay}${spacing}${formattedValue}`;
            } else if (!accountData?.unit?.beforeValue) {
                cardDisplayValue = `${formattedValue}${spacing}${unitDisplay}`;
            }
        }

        return {
            cardData,
            x,
            y,
            cardDisplayValue,
            accountName,
            monthlyOrCumulative,
            focused,
        };
    }, shallowEqual);

    const updatePosition = (x: number, y: number) => {
        const newCardData = {
            ...cardData,
            x,
            y,
        };
        dispatch(updateCard(newCardData));
    };

    const updateMonthlyOrCumulative = () => {
        const newCardData = {
            ...cardData,
            data: {
                ...cardData?.data,
                monthlyOrCumulative:
                    monthlyOrCumulative === "monthly"
                        ? "cumulative"
                        : "monthly",
            },
        };
        dispatch(updateCard(newCardData));
    };

    return (
        <AccountCardIdContext.Provider value={cardId}>
            <UpdatePositionContext.Provider value={updatePosition}>
                <CardDisplayValueContext.Provider value={cardDisplayValue}>
                    <AccountNameContext.Provider value={accountName}>
                        <UpdateMonthlyOrCumulative.Provider
                            value={updateMonthlyOrCumulative}
                        >
                            <MonthlyOrCumulativeContext.Provider
                                value={monthlyOrCumulative}
                            >
                                <AccountCardFocusContext.Provider
                                    value={focused}
                                >
                                    <AccountCardMain x={x} y={y} />
                                </AccountCardFocusContext.Provider>
                            </MonthlyOrCumulativeContext.Provider>
                        </UpdateMonthlyOrCumulative.Provider>
                    </AccountNameContext.Provider>
                </CardDisplayValueContext.Provider>
            </UpdatePositionContext.Provider>
        </AccountCardIdContext.Provider>
    );
};

export const CanvasAccountCardWrapper = memo(_CanvasAccountCardWrapper);

import React, { Component } from "react";
import {
    EmploymentIncome,
    SelfEmploymentIncome,
    EligibleDividends,
    IneligibleDividends,
    OtherIncome,
    RRSPDeduction,
    IncomeTaxesPaid,
    ON,
    QC,
    BC,
    AB,
    MB,
    SK,
    NS,
    NB,
    NL,
    PE,
    NT,
    YT,
    NU,
    Provinces,
} from "../../helpers/constants";
import {
    SelectDropDown,
    InputButtons,
    EventInputs,
    EventInputModal,
    EventHeader,
    ModalRow,
    RequiredStar,
    Description,
} from "./Components";
import { throwError } from "../../helpers/swalError";
import { getDefaultName } from "../../helpers";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { capitalGainsObject } from "Components/Registry/Capital Gains";


export default class IncomeTaxInput extends Component {
    constructor(props) {
        super(props);
        let manager;
        if (!this.props.baseline) {
            manager = this.props.manager;
        } else {
            manager = this.props.baselineManager;
        }
        const nodes = manager.nodes;
        const baselineNodes = manager.baselineNodes;
        let province = "";
        let people = {};
        if (nodes) {
            Object.values(nodes).forEach((node) => {
                // todo hide if bypassed
                if (node.type === "Person") {
                    people[node.metadata.name] = node.id;
                }
                if (node.type === "Me") {
                    people[node.metadata.name] = "me";
                    province = node.metadata.state ? node.metadata.state : "";
                }
            });
        }
        if (baselineNodes) {
            Object.values(baselineNodes).forEach((node) => {
                // todo hide if bypassed
                if (node.type === "Person" || node.type === "Me") {
                    people[node.metadata.name] = node.id;
                }
                if (node.type === "Me") {
                    people[node.metadata.name] = "me";
                }
            });
        }
        this.state = {
            type: incomeTaxObject.constant(),
            name: getDefaultName("Income Tax", this.props),
            tag: `@${incomeTaxObject.constant()}`,
            description: "",
            address: "",
            lat: null,
            lng: null,
            incomeArray: [
                {
                    name: EmploymentIncome,
                    value: null,
                },
                {
                    name: SelfEmploymentIncome,
                    value: null,
                },
                {
                    name: capitalGainsObject.constant(),
                    value: null,
                },
                {
                    name: EligibleDividends,
                    value: null,
                },
                {
                    name: IneligibleDividends,
                    value: null,
                },
                {
                    name: OtherIncome,
                    value: null,
                },
                {
                    name: RRSPDeduction,
                    value: null,
                },
                {
                    name: IncomeTaxesPaid,
                    value: null,
                },
            ],
            province: "",
            federal_tax: 0,
            provincial_tax: 0,
            total_income: 0,
            at_income: 0,
            avg_tax_rate: 0,
            marginal_tax_rate: 0,
            cpp_ei: 0,
            value: 0,
            total_tax: 0,
            inflation: false,
            baseline: false,
            people: people,
            personId: "",
            personName: "",
            nodeDetail: 0,
        };
        if (Provinces.includes(province) && this.state.province === "") {
            this.state.province = province;
        }
    }

    onChangeInput = (income, e) => {
        const newState = this.state.incomeArray.map((item) => {
            if (item.name === income.name) {
                const newItem = {
                    ...item,
                    value: parseInt(e.target.value),
                };
                return newItem;
            }
            return item;
        });

        this.setState({ incomeArray: newState }, () => {
            return this.calculateTax();
        });
    };

    onChangeDescription = (e) => {
        const value = e.target.value;
        this.setState({ description: value });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleProvince = (e) => {
        if (e.target.value === "Select Province") {
            return null;
        } else {
            this.setState({
                province: e.target.value,
            });
        }
    };

    onHandlePerson = (e) => {
        if (e.target.value === "Select Person") {
            this.setState({ personName: "" });
            this.setState({ personId: this.state.meId });
        } else {
            this.setState({ personName: e.target.value });
            if (this.state.people[this.state.personName]) {
                this.setState({
                    personId: this.state.people[this.state.personName],
                });
            }
        }
    };

    calculateTax = () => {
        const employment_income = this.state.incomeArray[0].value
            ? this.state.incomeArray[0].value
            : 0;
        const self_employment_income = this.state.incomeArray[1].value
            ? this.state.incomeArray[1].value
            : 0;
        const capital_gains = this.state.incomeArray[2].value
            ? this.state.incomeArray[2].value
            : 0;
        const eligible_dividends = this.state.incomeArray[3].value
            ? this.state.incomeArray[3].value
            : 0;
        const ineligible_dividends = this.state.incomeArray[4].value
            ? this.state.incomeArray[4].value
            : 0;
        const other_income = this.state.incomeArray[5].value
            ? this.state.incomeArray[5].value
            : 0;
        const rrsp_deduction = this.state.incomeArray[6].value
            ? this.state.incomeArray[6].value
            : 0;
        const income_taxes_paid = this.state.incomeArray[7].value
            ? this.state.incomeArray[7].value
            : 0;

        let deductions = rrsp_deduction + income_taxes_paid;

        let total_income =
            employment_income +
            self_employment_income +
            capital_gains +
            eligible_dividends +
            ineligible_dividends +
            other_income;

        this.setState({
            total_income: total_income,
        });

        // dividends
        let eligible_gross_up = 1.38;
        let ineligible_gross_up = 1.15;

        let federal_eligible_tax_credit_rate = 0.150198;
        let federal_ineligible_tax_credit_rate = 0.09301;

        let marginal_tax_rate, average_tax_rate;

        let provincial_eligible_tax_credit_rate,
            provincial_ineligible_tax_credit_rate;

        let taxable_income =
            employment_income +
            self_employment_income +
            capital_gains +
            eligible_dividends * eligible_gross_up +
            ineligible_dividends * ineligible_gross_up +
            other_income -
            deductions;

        // calculate ei / cpp
        ///// cpp rate = 5.10 % -> max employee 2748.90
        //// ei rate = 1.62 % -> max employee 860.22
        // cpp exemption: 3500
        let cpp = (employment_income + self_employment_income - 3500) * 0.051;
        if (cpp < 0) {
            cpp = 0;
        } else if (cpp > 2748.9) {
            cpp = 2748.9;
        }
        let ei = employment_income * 0.0162;
        if (ei > 860.22) {
            ei = 860.22;
        }

        //// qpp rate = 5.55 % -> max employee 2991.45
        ///  ei rate = 1.25 % -> max employee 663.75
        /// qpip rate = .526 % -> max amount 76,500
        let qpp = (employment_income + self_employment_income) * 0.0555;
        if (qpp > 2991.45) {
            qpp = 2991.45;
        }
        let qei = employment_income * 0.0125;
        if (qei > 663.73) {
            qei = 663.73;
        }
        let qpip = (employment_income + self_employment_income) * 0.00526;
        if (qpip > 76500) {
            qpip = 76500;
        }

        let cpp_ei_premiums = cpp + ei;
        if (this.state.province === QC) {
            cpp_ei_premiums = qpp + qei + qpip;
        }
        parseFloat(cpp_ei_premiums).toFixed(2);
        /// Federal income tax calculations
        /// 15% on first 48,535 +
        /// 20.5% on next 48,534 (income up to 97,069) +
        /// 26% on next 53,404 (income up to 150,473) +
        /// 29% on next 63,895 (income up to 214368) +
        /// 33% on income over 214,368
        let federal_personal_amount = 12069;
        let federal_tax_credits =
            (cpp_ei_premiums + federal_personal_amount) * 0.15;
        let federal_tax = 0;
        let to_calculate = taxable_income - federal_tax_credits;
        // B1
        if (to_calculate - 48535 > 0) {
            marginal_tax_rate = 0.15;
            federal_tax += 0.15 * 48535;
            to_calculate -= 48535;
        } else {
            marginal_tax_rate = 0.15;
            federal_tax += to_calculate * 0.15;
            to_calculate = 0;
        }
        // B2
        if (to_calculate - 48534 > 0) {
            marginal_tax_rate = 0.205;
            federal_tax += 0.205 * 48534;
            to_calculate -= 48534;
        } else if (to_calculate > 0) {
            marginal_tax_rate = 0.205;
            federal_tax += to_calculate * 0.205;
            to_calculate = 0;
        }
        // B3
        if (to_calculate - 53404 > 0) {
            marginal_tax_rate = 0.26;
            federal_tax += 0.26 * 53404;
            to_calculate -= 53404;
        } else if (to_calculate > 0) {
            marginal_tax_rate = 0.26;
            federal_tax += to_calculate * 0.26;
            to_calculate = 0;
        }
        // B4
        if (to_calculate - 63895 > 0) {
            marginal_tax_rate = 0.29;
            federal_tax += 0.29 * 63895;
            to_calculate -= 63895;
        } else if (to_calculate > 0) {
            marginal_tax_rate = 0.29;
            federal_tax += to_calculate * 0.29;
            to_calculate = 0;
        }
        // B5
        if (to_calculate > 0) {
            marginal_tax_rate = 0.33;
            federal_tax += 0.33 * to_calculate;
        }
        federal_tax =
            federal_tax -
            eligible_dividends *
                eligible_gross_up *
                federal_eligible_tax_credit_rate;
        federal_tax =
            federal_tax -
            ineligible_dividends *
                ineligible_gross_up *
                federal_ineligible_tax_credit_rate;

        federal_tax = federal_tax.toFixed(2);

        if (federal_tax < 0) {
            federal_tax = 0;
        }

        let provincial_tax = 0;
        let provincial_personal_amount, provincial_tax_credits;

        let t1, b1, t2, b2, t3, b3, t4, b4, t5, b5, t6, b6;
        let num_brackets;
        switch (this.state.province) {
            case AB:
                num_brackets = 5;
                provincial_personal_amount = 19369;
                provincial_eligible_tax_credit_rate = 0.1;
                provincial_ineligible_tax_credit_rate = 0.0218;
                provincial_tax_credits =
                    0.1 * (provincial_personal_amount + cpp_ei_premiums);
                /// 10% on first 131,220 + 12% on next 26,244 + 13% on next 52,488 + 14% on next 104,976 + 15% over 314,928
                t1 = 0.1;
                b1 = 131220;
                t2 = 0.12;
                b2 = 26244;
                t3 = 0.13;
                b3 = 52488;
                t4 = 0.14;
                b4 = 104976;
                t5 = 0.15;
                b5 = 314928;
                break;
            case BC:
                num_brackets = 6;
                provincial_personal_amount = 10682;
                provincial_eligible_tax_credit_rate = 0.12;
                provincial_ineligible_tax_credit_rate = 0.0196;
                provincial_tax_credits =
                    0.1 * (provincial_personal_amount + cpp_ei_premiums);
                /// 5.06% on first 41,725 + 7.7% on next 41,726 + 10.5% on next 12,361 + 12.29 on next 20,532 + 14.7 on next 41,404 + 16.8 over 157,748
                t1 = 0.0506;
                b1 = 41725;
                t2 = 0.077;
                b2 = 41726;
                t3 = 0.105;
                b3 = 12361;
                t4 = 0.1229;
                b4 = 20532;
                t5 = 0.147;
                b5 = 41404;
                t6 = 0.168;
                b6 = 157748;
                break;
            case MB:
                // todo
                num_brackets = 3;
                provincial_personal_amount = 0;
                provincial_eligible_tax_credit_rate = 0.08;
                provincial_ineligible_tax_credit_rate = 0.007835;
                provincial_tax_credits =
                    0.1 * (provincial_personal_amount + cpp_ei_premiums);
                /// 10.8% on first 33,389 + 12.5% on next 38,775 + 17.4% over 72,164
                t1 = 0.108;
                b1 = 33389;
                t2 = 0.125;
                b2 = 38775;
                t3 = 0.174;
                b3 = 72164;
                break;
            case NB:
                num_brackets = 5;
                provincial_personal_amount = 10264;
                provincial_eligible_tax_credit_rate = 0.14;
                provincial_ineligible_tax_credit_rate = 0.0275;
                provincial_tax_credits =
                    0.0968 * (provincial_personal_amount + cpp_ei_premiums);
                /// 9.68% on first 43,401 + 14.82% on next 43,402 + 16.52 on next 54,319 + 17.84% on next 54.172 + 20.3% over 160,776
                t1 = 0.0968;
                b1 = 43401;
                t2 = 0.1482;
                b2 = 43402;
                t3 = 0.1652;
                b3 = 54319;
                t4 = 0.1784;
                b4 = 54172;
                t5 = 0.203;
                b5 = 160776;
                break;
            case NL:
                num_brackets = 5;
                provincial_personal_amount = 9414;
                provincial_eligible_tax_credit_rate = 0.054;
                provincial_ineligible_tax_credit_rate = 0.035;
                provincial_tax_credits =
                    0.087 * (provincial_personal_amount + cpp_ei_premiums);
                /// 8.7% on first 37,929 + 14.5% on next 37,929 + 15.8% on next 59.574 + 17.3% on next 54.172 + 18.3% over 189.604
                t1 = 0.087;
                b1 = 37929;
                t2 = 0.145;
                b2 = 37929;
                t3 = 0.158;
                b3 = 59574;
                t4 = 0.173;
                b4 = 54172;
                t5 = 0.183;
                b5 = 189604;
                break;
            case NS:
                num_brackets = 5;
                provincial_personal_amount = 11481;
                provincial_eligible_tax_credit_rate = 0.0885;
                provincial_ineligible_tax_credit_rate = 0.0299;
                provincial_tax_credits =
                    0.0879 * (provincial_personal_amount + cpp_ei_premiums);
                /// 8.79% on first 29,590 + 14.95% on next 29,590 + 16.67% on next 33,820 + 17.5% on next 57,000 + 21% over 150,000
                t1 = 0.0879;
                b1 = 29590;
                t2 = 0.1495;
                b2 = 29590;
                t3 = 0.1667;
                b3 = 33820;
                t4 = 0.175;
                b4 = 57000;
                t5 = 0.21;
                b5 = 150000;
                break;
            case NT:
                num_brackets = 4;
                provincial_personal_amount = 14811;
                provincial_eligible_tax_credit_rate = 0.115;
                provincial_ineligible_tax_credit_rate = 0.06;
                provincial_tax_credits =
                    0.059 * (provincial_personal_amount + cpp_ei_premiums);
                /// 5.9% on first 43,957 + 8.6% on next 43,959 + 12.2% over 55,016 + 14.05% over 142,932
                t1 = 0.059;
                b1 = 43957;
                t2 = 0.086;
                b2 = 43959;
                t3 = 0.122;
                b3 = 55016;
                t4 = 0.1405;
                b4 = 142932;
                break;
            case NU:
                num_brackets = 4;
                provincial_personal_amount = 16000;
                provincial_eligible_tax_credit_rate = 0.0551;
                provincial_ineligible_tax_credit_rate = 0.0261;
                provincial_tax_credits =
                    0.04 * (provincial_personal_amount + cpp_ei_premiums);
                /// 4% on first 46,277 + 7% on next 46,278 + 9% on next 57,918 + 11.5% over 150,473
                t1 = 0.04;
                b1 = 46277;
                t2 = 0.07;
                b2 = 46278;
                t3 = 0.09;
                b3 = 57918;
                t3 = 0.115;
                b4 = 150473;
                break;
            case PE:
                num_brackets = 3;
                provincial_personal_amount = 9160;
                provincial_eligible_tax_credit_rate = 0.0274;
                provincial_ineligible_tax_credit_rate = 0.105;
                provincial_tax_credits =
                    0.098 * (provincial_personal_amount + cpp_ei_premiums);
                /// 9.8% on first 31,984 + 13.8% on next 31,985 + 16.7% over 63,969
                t1 = 0.098;
                b1 = 31984;
                t2 = 0.138;
                b2 = 31985;
                t3 = 0.167;
                b3 = 63969;
                break;
            case QC:
                num_brackets = 4;
                provincial_personal_amount = 15269;
                provincial_eligible_tax_credit_rate = 0.117;
                provincial_ineligible_tax_credit_rate = 0.0477;
                provincial_tax_credits =
                    0.15 * (provincial_personal_amount + cpp_ei_premiums);
                /// 15% on <=44,545 + 20% on 45,545-89,080 + 24% on 89,080-108,390 + 25.75% over 108,390
                t1 = 0.15;
                b1 = 44545;
                t2 = 0.2;
                b2 = 43535;
                t3 = 0.24;
                b3 = 19310;
                t4 = 0.2575;
                b4 = 108390;
                break;
            case SK:
                num_brackets = 3;
                provincial_personal_amount = 16065;
                provincial_eligible_tax_credit_rate = 0.11;
                provincial_ineligible_tax_credit_rate = 0.03362;
                provincial_tax_credits =
                    0.105 * (provincial_personal_amount + cpp_ei_premiums);
                /// 10.5% on first 45,225 + 12.5% on next 83,989 + 14.5% over 129,214
                t1 = 0.105;
                b1 = 45225;
                t2 = 0.125;
                b2 = 83989;
                t3 = 0.145;
                b3 = 129214;
                break;
            case YT:
                num_brackets = 5;
                provincial_personal_amount = 12069;
                provincial_eligible_tax_credit_rate = 0.1202;
                provincial_ineligible_tax_credit_rate = 0.023;
                provincial_tax_credits =
                    0.064 * (provincial_personal_amount + cpp_ei_premiums);
                /// 6.4% on first 48,535 + 9% on next 48,534 + 10.9% on next 54,404 + 12.8 on next 349,527 + 15 over 500,000
                t1 = 0.064;
                b1 = 48535;
                t2 = 0.09;
                b2 = 48534;
                t3 = 0.109;
                b3 = 54404;
                t4 = 0.128;
                b4 = 104976;
                t5 = 0.15;
                b5 = 500000;
                break;
            case ON:
            default:
                num_brackets = 5;
                provincial_personal_amount = 10582;
                provincial_eligible_tax_credit_rate = 0.1;
                provincial_ineligible_tax_credit_rate = 0.029863;
                provincial_tax_credits =
                    0.0505 * (provincial_personal_amount + cpp_ei_premiums);
                /// 5.05% on first 44,740 + 9.15% on next 44,742 + 11.16% on next 60,518 + 12.16 on next 70,000 + 13.16 over 220,000
                t1 = 0.0505;
                b1 = 44740;
                t2 = 0.0915;
                b2 = 44742;
                t3 = 0.1116;
                b3 = 60518;
                t4 = 0.1216;
                b4 = 70000;
                t5 = 0.1316;
                b5 = 220000;
                break;
        }

        let provincial_marginal_tax_rate;
        to_calculate = taxable_income - provincial_tax_credits;

        if (to_calculate - b1 > 0) {
            provincial_tax += t1 * b1;
            to_calculate -= b1;
        } else {
            provincial_marginal_tax_rate = t1;
            provincial_tax += t1 * to_calculate;
            to_calculate = 0;
        }

        if (b2) {
            if (to_calculate - b2 > 0) {
                provincial_tax += t2 * b2;
                to_calculate -= b2;
            } else if (to_calculate > 0) {
                provincial_marginal_tax_rate = t2;
                provincial_tax += to_calculate * t2;
                to_calculate = 0;
            }
        }

        if (b3) {
            if (num_brackets === 3) {
                provincial_marginal_tax_rate = t3;
                provincial_tax += to_calculate * t3;
                to_calculate = 0;
            } else if (to_calculate - b3 > 0) {
                provincial_tax += t3 * b3;
                to_calculate -= b3;
            } else if (to_calculate > 0) {
                provincial_marginal_tax_rate = t3;
                provincial_tax += to_calculate * t3;
                to_calculate = 0;
            }
        }

        if (b4) {
            if (num_brackets === 4) {
                provincial_marginal_tax_rate = t4;
                provincial_tax += to_calculate * t4;
                to_calculate = 0;
            } else if (to_calculate - b4 > 0) {
                provincial_tax += t4 * b4;
                to_calculate -= b4;
            } else if (to_calculate > 0) {
                provincial_marginal_tax_rate = t4;
                provincial_tax += to_calculate * t4;
                to_calculate = 0;
            }
        }

        if (b5) {
            if (num_brackets === 5) {
                provincial_marginal_tax_rate = t5;
                provincial_tax += to_calculate * t5;
                to_calculate = 0;
            } else if (to_calculate - b5 > 0) {
                provincial_tax += t5 * b5;
                to_calculate -= b5;
            } else if (to_calculate > 0) {
                provincial_marginal_tax_rate = t5;
                provincial_tax += to_calculate * t5;
                to_calculate = 0;
            }
        }

        if (b6) {
            if (num_brackets === 6) {
                provincial_marginal_tax_rate = t6;
                provincial_tax += to_calculate * t6;
                to_calculate = 0;
            } else if (to_calculate - b6 > 0) {
                provincial_marginal_tax_rate = t6;
                provincial_tax += t6 * b6;
                to_calculate -= b6;
            } else if (to_calculate > 0) {
                provincial_marginal_tax_rate = t6;
                provincial_tax += to_calculate * t6;
                to_calculate = 0;
            }
        }
        provincial_tax =
            provincial_tax -
            eligible_dividends *
                eligible_gross_up *
                provincial_eligible_tax_credit_rate;
        provincial_tax =
            provincial_tax -
            ineligible_dividends *
                ineligible_gross_up *
                provincial_ineligible_tax_credit_rate;

        provincial_tax = Math.round(provincial_tax);

        if (provincial_tax < 0) {
            provincial_tax = 0;
        }

        marginal_tax_rate = total_income
            ? (
                  (marginal_tax_rate + provincial_marginal_tax_rate) *
                  100
              ).toFixed(2)
            : 0;

        let total_tax =
            parseFloat(federal_tax) +
            parseFloat(provincial_tax) +
            cpp_ei_premiums;
        total_tax = Math.round(total_tax);

        let income = Math.round(total_income - total_tax);

        average_tax_rate = total_income
            ? ((total_tax / total_income) * 100).toFixed(2)
            : 0;

        this.setState({
            cpp_ei: cpp_ei_premiums,
            federal_tax: federal_tax,
            provincial_tax: provincial_tax,
            total_tax: total_tax,
            at_income: income,
            value: total_tax,
            marginal_tax_rate: marginal_tax_rate,
            avg_tax_rate: average_tax_rate,
        });
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const { name, province } = this.state;
        if (name && province) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    componentDidMount() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (this.props.edit) {
            this.setState({
                ...this.props.editData,
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }

        if (this.props.onboardingData === 6) {
            this.setState({
                province: BC,
                personId: this.state.people[loggedInUser.name.split(" ")[0]],
                personName: loggedInUser.name.split(" ")[0],
            });
        }
    }

    onChangeTaxName = (e) => {
        this.setState({ name: e.target.value });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        const { edit } = this.props;
        const { name, province, description } = this.state;
        const passedCheck = incomeTaxObject.checkInput(this.state);
        const onboarding = this.props.onboardingData === 6;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        image={incomeTaxObject.svg()}
                        onChangeInput={this.onChangeTaxName}
                        eventType={"Income Tax (Canada)"}
                    />
                    <ModalRow>
                        <Description
                            onChangeInput={this.onChangeDescription}
                            description={description}
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onHandlePerson}
                            value={this.state.personName}
                            options={Object.keys(this.state.people)}
                            className="select-dropdown"
                            id="person"
                            label="Select Person"
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onHandleProvince}
                            value={province}
                            options={Provinces}
                            className="select-dropdown"
                            id="province"
                            label="Select Province"
                        />
                    </ModalRow>
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    onboarding={onboarding}
                />
            </EventInputModal>
        );
    }
}

// import type { EventManager } from "Events";
import type Event from "Events/_event";
import type { AppThunk } from "store";
import {
    SELECT_LINE,
    POP_UP_OPTION,
    SELECT_LINE_FOR_DISCONNECTION,
} from "./types";
import { setShowPasteOptionModal } from "./modalActions";
import { getMaxEventsPerScenario } from "../helpers/userHelpers";
import { createEntityCopies } from "./nodeEntityActions";
import { setOnboardingData, updateBaseline } from "./scenario";
import { getNameFromType } from "helpers/getNameFromType";
import { EventActionIds, eventActionsMap } from "./canvasEventActions";
import { getManager } from "helpers/getManager";
import BaselineDataManager from "Events/baselineManager";
import * as uuid from "uuid";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { updateDependencyMap } from "./dependencyMapActions";
import { containerObject } from "Components/Registry/Container";

export enum LineActionIds {
    focusLine = "focusLine:c97c3bd0-4b28-45fe-a10a-dedec77f2bf0",
    addEvent = "addEvent:c3af2bb6-3754-44da-9bdf-815aad0c6dbd",
    pasteEvent = "pasteEvent:788cdb70-cd8b-40dd-a76e-5d0acaee983b",
    selectLineForDisconnection = "selectLineForDisconnection:50506042-e42b-4b5f-97d4-8263eaf2da8a",
    disconnectLine = "disconnectLine:ac571514-db71-4000-a014-18b00c9d9476",
}

export const lineActionsMap = {
    [LineActionIds?.focusLine]: focusLine,
    [LineActionIds?.addEvent]: addEvent,
    [LineActionIds?.pasteEvent]: pasteEvent,
    [LineActionIds?.selectLineForDisconnection]: selectLineForDisconnection,
    [LineActionIds?.disconnectLine]: disconnectLine,
};

/**
 * @param lineId
 * Finds the line selected via the passed lineId, clears the applications focus, and updates the line states in redux.
 */
export function focusLine(
    lineId: string | null,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const existingFocus = getState()?.scenario?.focus?.id;
        if (existingFocus) {
            dispatch(
                eventActionsMap?.[EventActionIds?.focusEvent](null, isBaseline)
            );
        }

        const existingLine = getState()?.scenario?.line?.lineId;
        if (lineId && lineId !== existingLine) {
            const manager = getManager(isBaseline, getState());
            const splitId = lineId?.split("_");
            const parentEventId = splitId?.[0];
            const childEventId = splitId?.[1]?.split("-background")?.[0];
            const line = {
                lineId,
                parentEvent: manager?._findEvent(parentEventId) as Event,
                childEvent: manager?._findEvent(childEventId) as Event,
                yPlacement: null,
            };
            dispatch({
                type: SELECT_LINE,
                line,
            });
        } else {
            dispatch({
                type: SELECT_LINE,
                line: null,
            });
        }
    };
}

/**
 * Opens the add event modal based off the following conditions.
 * @if the lineId is equal to the line states lineId we dispatch the POP_UP_ACTION with the current focus and line.
 * @else-if there is no focused line, we then need to set the focus line given the passed lineId before dispatching the POP_UP_ACTION with the new focus line.
 */
export function addEvent(lineId: string): AppThunk<void> {
    return (dispatch, getState) => {
        const currentFocus = getState()?.scenario?.focus;
        const currentLine = getState()?.scenario?.line;
        if (currentLine && currentLine?.lineId === lineId) {
            dispatch({
                type: POP_UP_OPTION,
                focus: currentFocus,
                line: currentLine,
            });
        } else if (lineId) {
            dispatch(focusLine(lineId));
            const newLine = getState()?.scenario?.line;
            dispatch({
                type: POP_UP_OPTION,
                focus: currentFocus,
                line: newLine,
            });
        }
    };
}

/**
 * Accepts either a lineId or null.
 *
 * @if a lineId is provided, find the line via the passed id and paste from there.
 *
 * @else if no lineId is provided, paste from the focued line if possible.
 */
export function pasteEvent(
    lineId: string | null,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const loadedScenario = getState()?.scenario?.loadedScenario;
        const copiedEvent: Event = getState()?.scenario?.copiedEvent;
        const onboardingData = getState()?.scenario?.onboardingData;
        const dependencyMap: DependencyMapSchema = {
            ...getState()?.scenario?.loadedScenario?.dependency_map,
        };

        if (!copiedEvent) {
            return;
        }

        if (lineId) {
            const splitId = lineId?.split("_");
            const parentEventId = splitId?.[0];
            const childEventId = splitId?.[1]?.split("-background")[0];
            const parentEvent: Event = manager?._findEvent(parentEventId);
            const childEvent: Event = manager?._findEvent(childEventId);
            if (
                parentEvent?.relevantContainerId ||
                childEvent?.relevantContainerId ||
                parentEvent?.type === containerObject?.constant() ||
                childEvent?.type === containerObject?.constant()
            )
                return;
        }

        const updateEvent = (newEventId?: string) => {
            const newEvent = manager?.createEvent(
                copiedEvent?.type,
                copiedEvent,
                // @ts-ignore
                newEventId ?? null
            );

            newEvent.name = getNameFromType(newEvent.type);

            if (newEvent?.relevantContainerId) {
                newEvent.relevantContainerId = "";
            }

            if (lineId) {
                const splitId = lineId?.split("_");
                const parentEventId = splitId?.[0];
                const childEventId = splitId?.[1]?.split("-background")[0];
                const parentEvent: Event = manager?._findEvent(parentEventId);
                const childEvent: Event = manager?._findEvent(childEventId);
                manager?.attachToNodes(newEvent, [parentEvent], [childEvent]);
                if (
                    manager?.isBaseline &&
                    manager instanceof BaselineDataManager
                ) {
                    const newScenario = manager.handleExport();
                    dispatch(updateBaseline(newScenario, manager));
                }
            } else {
                const currentFocusLine = getState()?.scenario?.line;
                if (currentFocusLine) {
                    const { _lineId, parentEvent, childEvent } =
                        currentFocusLine;
                    manager?.attachToNodes(
                        newEvent,
                        [parentEvent],
                        [childEvent]
                    );
                }
            }

            if (isBaseline) manager?._updateScenarioCanvas();
            manager?.calculate();

            dispatch(focusLine(null));
        };

        const pasteCallback = (action) => {
            if (copiedEvent != null) {
                const loadedScenarioNode = loadedScenario?.data?.nodes?.filter(
                    (node) => {
                        return node?.type !== "Decision";
                    }
                );

                const loadedScenarioNodeCount = loadedScenarioNode?.length - 1;

                const nodeCount = getMaxEventsPerScenario(null);

                if (loadedScenarioNodeCount <= nodeCount) {
                    if (action === "duplicate") {
                        const newEventId = uuid.v4();
                        const newEntityIdsMap = {};
                        // Here we create a mapping between the old entity Ids and the new entity Ids
                        copiedEvent?.entities.forEach((entity) => {
                            newEntityIdsMap[entity.id] = uuid.v4();
                        });
                        createEntityCopies(
                            copiedEvent?.entities,
                            newEntityIdsMap,
                            dependencyMap,
                            newEventId
                        ).then((response) => {
                            copiedEvent.entities = response?.data?.map(
                                (id) => ({
                                    id,
                                    active: true,
                                })
                            );
                            dispatch(updateDependencyMap(dependencyMap));
                            updateEvent(newEventId);
                        });
                    } else {
                        updateEvent();
                    }
                }

                if (onboardingData === 11) {
                    setOnboardingData(12);
                }
            }
        };

        setShowPasteOptionModal({
            show: true,
            callback: pasteCallback,
        });
    };
}

/**
 * @param lineId
 * Updates the line disconnection state with the provided lineId.
 */
export function selectLineForDisconnection(
    lineId: string | null,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, _getState) => {
        if (lineId) {
            dispatch({
                type: SELECT_LINE_FOR_DISCONNECTION,
                payload: { lineId, isBaseline },
            });
        } else {
            dispatch({
                type: SELECT_LINE_FOR_DISCONNECTION,
                payload: {},
            });
        }
    };
}

/**
 * @param lineId
 * Finds the line selected via the passed lineId and separates the lineId into the parent and child event ids.
 * Then it removes the line by separating the parent and child events.
 * Dispatches TRIGGER_UPDATE to force a re-render.
 */
export function disconnectLine(): AppThunk<void> {
    return (dispatch, getState) => {
        const disconnectionData = getState()?.scenario?.lineDisconnection;
        const manager = getManager(disconnectionData.isBaseline, getState());
        const splitId = disconnectionData.lineId?.split("_");
        const parentEventId = splitId?.[0];
        const childEventId = splitId?.[1]?.split("-background")?.[0];
        const parentEvent = manager?._findEvent(parentEventId) as Event;
        const childEvent = manager?._findEvent(childEventId) as Event;

        if (!(childEvent?.parents?.length > 1)) {
            return;
        }

        manager?.separateNodes(parentEvent, childEvent);
        dispatch(selectLineForDisconnection(null));
    };
}

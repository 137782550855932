import React from "react";
import styles from "./Options.module.css";
import backSvg from "../../Assets/_budgetIcons/back.svg";
import fullSvg from "../../Assets/_onboarding/full.svg";
import emptySvg from "../../Assets/_onboarding/empty.svg";
import rightArrow from "../../Assets/_optionDescriptionIcons/rightArrow.svg";
import leftArrow from "../../Assets/_optionDescriptionIcons/leftArrow.svg";
import tourPoint from "../../Assets/_onboarding/tourPoint.svg";
import { NodeTypesBlack } from "../../Events";
import { throwError } from "../../helpers/swalError";

import { events } from "Components/InputContainer/Master Registry";
import store from "store"

export default class OptionDescription extends React.Component {
    constructor() {
        super();
        this.scroller = React.createRef();
    }
    state = {
        step: 0,
        selected: null,
    };

    addEventType = () => {
        const { optionSelected } = this.props;
        this.props.addEventType(optionSelected);
        this.props.onHandleOptionSelected(null);
    };

    handleLeftClick = (_templates) => {
        if (this.state.step === 0) {
            return null;
        } else {
            this.setState({ step: this.state.step - 1 });
            return this.scroller ? (this.scroller.scrollLeft -= 280) : null;
        }
    };

    handleRightClick = (templates) => {
        if (this.state.step < templates.length - 1) {
            this.setState({ step: this.state.step + 1 });
            return this.scroller ? (this.scroller.scrollLeft += 280) : null;
        } else {
            return null;
        }
    };

    handleTemplateSelected = (template) => {
        this.setState({ selected: template });
    };

    onHandleBack = () => {
        this.setState({ selected: null });
    };

    handleLaunchTemplate = () => {
        if (this.state.selected.templateId === "RentVsBuy") {
            throwError(
                "info",
                "Use This Template?",
                "Click the New Scenario button and choose Templates."
            );
        } else {
            throwError("info", "Coming Soon", "Template is still in progress");
        }

        // this.props.close();
        // this.setState({ selected: null });
        // const {
        //     manager,
        //     loadScenario,
        //     getUserScenarios,
        //     createScenario,
        // } = this.props;

        // switch (this.state.selected.templateId) {
        //     case "RentVsBuy":
        //         return RentVsBuy(
        //             manager,
        //             loadScenario,
        //             getUserScenarios,
        //             createScenario,
        //             this.props.baseline
        //         );
        //     default:
        // }
    };

    render() {

        const { step, selected } = this.state;
        const { optionSelected, onHandleOptionSelected, onboardingData } =
            this.props;

        const typeImagesBlack = {};

        NodeTypesBlack.forEach((data) => {
            typeImagesBlack[data.type] = data.image;
        });

        let image = typeImagesBlack[optionSelected];

        let tags;
        let description;
        let usedForText;
        let connection;
        let connectionImage;
        let templates;
        let learnMoreLink;
        let type;
        let eventName;

        events.forEach(function (event) {
            if (optionSelected == event.constant()) {
                const eventDetails = store.getState()?.eventDetails?.[optionSelected]
                const eventValues = event.optionDescription();
                tags = eventValues.get("tags");
                type = eventValues.get("type");
                description = eventDetails?.descriptionText ?? eventValues.get("description");
                usedForText = eventDetails?.usageText ?? eventValues.get("usedForText");
                connection = eventDetails?.connectionsText ?? eventValues.get("connection");
                connectionImage = eventValues.get("connectionImage");
                learnMoreLink = eventDetails?.docsUrl;
                templates = eventValues.get("templates");
                eventName = eventDetails?.eventDisplayName ?? event.name();
            }
        });

        const showTourPoint =
            (onboardingData && onboardingData === 3) || onboardingData === 4;

        return (
            <div className={styles.optionDescriptionContainer}>
                {selected ? (
                    <div>
                        <div className={styles.inputRoot}>
                            <img
                                alt="alt"
                                className={styles.bigImage}
                                src={selected.bigImage}
                            />
                            <div className={styles.boldText}>
                                {selected.name}
                            </div>
                            <div className={styles.regularText}>
                                {selected.description}
                            </div>
                        </div>
                        <div className={styles.decriptionBtnContainer}>
                            <div
                                onClick={this.onHandleBack}
                                className={styles.textButton}
                            >
                                Back
                            </div>
                            <div
                                onClick={this.handleLaunchTemplate}
                                className={styles.proceedButton}
                            >
                                Launch Scenario
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div
                            onClick={() => onHandleOptionSelected(null)}
                            className={styles.backTextContainer}
                        >
                            <img
                                alt="alt"
                                className={styles.backSvg}
                                src={backSvg}
                            />
                            <div className={styles.backText}>
                                All Options/{eventName}
                            </div>
                        </div>
                        <div className={styles.inputRoot}>
                            <div className={styles.containerRow}>
                                <img
                                    alt="alt"
                                    className={styles.descriptionImage}
                                    src={image}
                                />
                                <div className={styles.nodeName}>{type}</div>
                            </div>
                            <div className={styles.containerRow}>
                                {tags.map((tag, i) => {
                                    return (
                                        <div key={i} className={styles.tag}>
                                            {tag}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={styles.regularText}>
                                {description}
                            </div>
                            {usedForText && (
                                <>
                                    <div className={styles.boldText}>
                                        Can be used for ...
                                    </div>
                                    <div className={styles.regularText}>
                                        {usedForText}
                                    </div>
                                </>
                            )}

                            <div className={styles.boldText}>
                                Common Connections
                            </div>
                            <div className={styles.regularText}>
                                {connection}
                            </div>
                            <div className={styles.connectionImageContainer}>
                                <img
                                    alt="alt"
                                    className={styles.connectionImage}
                                    src={connectionImage}
                                />
                            </div>
                            {templates.length > 0 && (
                                <>
                                    <div className={styles.boldText}>
                                        Related Scenarios & Templates
                                    </div>
                                    <div className={styles.regularText}>
                                        {step + 1} of {templates.length}
                                    </div>
                                    <div
                                        ref={this.scroller}
                                        className={styles.templateContainer}
                                    >
                                        {templates.map((template, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    onClick={() =>
                                                        this.handleTemplateSelected(
                                                            template
                                                        )
                                                    }
                                                    className={
                                                        styles.innerContainer
                                                    }
                                                >
                                                    <img
                                                        alt="alt"
                                                        src={template.image}
                                                    />
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.templateName
                                                            }
                                                        >
                                                            {template.name}
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.templateDescription
                                                            }
                                                        >
                                                            {
                                                                template.description
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.sliderContainer}>
                                        <img
                                            alt="alt"
                                            onClick={() =>
                                                this.handleLeftClick(templates)
                                            }
                                            className={styles.arrow}
                                            src={leftArrow}
                                        />
                                        {templates.map((dot, i) => {
                                            if (dot.id === step) {
                                                return (
                                                    <img
                                                        alt="alt"
                                                        className={styles.dots}
                                                        src={fullSvg}
                                                        key={i}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <img
                                                        alt="alt"
                                                        className={styles.dots}
                                                        src={emptySvg}
                                                        key={i}
                                                    />
                                                );
                                            }
                                        })}
                                        <img
                                            alt="alt"
                                            onClick={() =>
                                                this.handleRightClick(templates)
                                            }
                                            className={styles.arrow}
                                            src={rightArrow}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={styles.decriptionBtnContainer}>
                            <div
                                onClick={() =>
                                    learnMoreLink && window.open(learnMoreLink)
                                }
                                className={`${
                                    !learnMoreLink
                                        ? `${styles.textButton} ${styles.textButtonDisabled}`
                                        : `${styles.textButton}`
                                }`}
                            >
                                Learn More
                            </div>
                            <div
                                onClick={this.addEventType}
                                className={styles.proceedButton}
                            >
                                Proceed
                            </div>
                            {showTourPoint && (
                                <img
                                    alt="alt"
                                    className={styles.descriptionTour}
                                    src={tourPoint}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

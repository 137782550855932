import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import AccountData from "../../../helpers/ledgers/accountsAndLedgers.json";
import { genericIncomeExpenseInputsHandler } from "./debitCreditInputsHandler";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const unitInputsHandler = (
    value: string,
    id:
        | "value"
        | "entityName"
        | "bypassState"
        | "startDate"
        | "endDate"
        | "cadence"
        | "rating"
        | "increaseOrDecreaseType"
        | "accountName"
        | "contraAccountName"
        | "amortizedValue"
        | "amortizationPeriod"
        | "ongoingOrAmortized",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    let newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "increaseOrDecreaseType":
            data.increaseOrDecrease = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
            newEntitiesMap = genericIncomeExpenseInputsHandler(
                value,
                id,
                star,
                entitiesMap,
                currentEntity,
                account
            );
    }

    return newEntitiesMap;
};

export function getContraAccounts(account) {
    const accounts = Object.values(AccountData);
    const contraAccounts: string[] = [];

    if (!account || account.trim() == "") {
        return contraAccounts;
    }

    accounts.forEach((acc) => {
        if (acc.id === account) {
            contraAccounts.unshift(acc.id);
            let currentAcc = acc;
            while (currentAcc.parents.length > 0) {
                currentAcc = AccountData[currentAcc.parents[0]];
                contraAccounts.unshift(currentAcc.id);
            }
        }
    });

    return contraAccounts;
}

import ShareScenarioContainer from "./ShareScenarioContainer";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";

const mapStateToProps = (state) => {
    return {
        loadedScenario: state.scenario.loadedScenario,
        shareScenario: state.scenario.shareScenario,
        urlLink: state.scenario.urlLink,
        baseline: state.scenario.baseline,
        baselineDataManager: state.scenario.baselineDataManager,
        agencyClients: state.scenario.agencyClients,
    };
};

const mapDispatchToProps = {
    shareScenario: actions.shareScenario,
    cancelShareScenario: actions.cancelShareScenario,
    sendEmail: actions.sendEmail,
    shareByEmail: actions.shareByEmail,
    shareBaseline: actions.shareBaseline,
    updateScenario: actions.updateScenario,
    updateBaseline: actions.updateBaseline,
    performTemplateShare: actions.performTemplateShare,
    getClientData: actions.getClientData,
};

const ShareScenario = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareScenarioContainer);

export default ShareScenario;

import { useState, useEffect, useContext, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import InstanceInputView from "./InstanceInputView";
import { instanceObject } from "Components/Registry/Instance";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { updateEntityState } from "helpers/updateEntityState";
import { getEvent } from "actions/getNodeEntityActions";
import { getRelevantLedgers } from "helpers/ledgers/ledgerDependencies";
import useOverrides from "../CustomHooks/useOverrides";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function InstanceInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);
    const eventId = useContext(EventInputIDContext);
    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );
    const entitiesObject: EntitiesSchema = useAppSelector(
        (state) => state?.entities
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(instanceObject);
            _eventData.name = getDefaultName(
                instanceObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        onHandleDate,
    } = useEntities(entitiesObject, eventData, edit, instanceObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const {
        checkIfFieldIsOverridden,
        isOverrideValid,
        createOverride,
        updateOverride,
        getOverride,
    } = useOverrides(entitiesMap, setEntitiesMap, currentEntity)

    const { ledgerAccount } = useMemo(() => {
        const currentEntityData = entitiesMap[currentEntity];
        let dependantEvent: any = {};

        const dependantEventId =
            currentEntityData.data.instanceType === "groupInstance"
                ? currentEntityData?.data?.selectedGroupEntity?.eventId
                : currentEntityData?.data?.selectedEntity?.eventId;
        if (dependantEventId) dependantEvent = getEvent(dependantEventId);

        const ledgerAccount = getRelevantLedgers([dependantEvent])?.[0];
        // const unitData = getUnit(ledgerAccount?.Name);

        return { ledgerAccount };
    }, [entitiesMap, currentEntity]);

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;
        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    //returns an array[] of upstream event objects in order
    const getUpstreamEvents = () => {
        const upstreamEventIds: any[] = [];
        if (!propsObject.eventId && !propsObject.line) return upstreamEventIds;
        const allEventsIds = manager.getSortedEventIds();
        const currId = propsObject.eventId;
        for (const eventId in allEventsIds) {
            //current event is different if you're creating the node vs editing the node hence the two if statements
            if (allEventsIds[eventId] === currId && edit) {
                break;
            }
            upstreamEventIds.push(
                manager._findEvent(allEventsIds[eventId]).exportData()
            );
            if (allEventsIds[eventId] === currId && !edit) {
                break;
            }
        }
        return upstreamEventIds;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.event = getUpstreamEvents();
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const handleEventChange = (e) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const eventId = e.target.value;
        const selectedEvent = getEvent(eventId);

        if (data.instanceType == "recordInstance") {
            data.selectedEvent = selectedEvent?.id;
            data.entities = [selectedEvent?.id];
            data.selectedEntity = {};
        } else {
            data.selectedGroupEvent = selectedEvent?.id;
            data.groupEntities = [selectedEvent?.id];
            data.selectedGroupEntity = {};
        }
        data.isGroup = selectedEvent?.type == groupObject.constant();
        data.isProject = selectedEvent?.type == projectObject.constant();

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && instanceObject.checkInput(entitiesMap);

    return (
        <InstanceInputView
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            onHandleDate={onHandleDate}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleEventChange={handleEventChange}
            handleEntityStateChange={handleEntityStateChange}
            ledgerAccount={ledgerAccount?.Name ?? ""}
            checkIfFieldIsOverridden={checkIfFieldIsOverridden}
            isOverrideValid={isOverrideValid}
            createOverride={createOverride}
            getOverride={getOverride}
            updateOverride={updateOverride}
        />
    );
}

import React from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import "./LocationSearchInput.css";

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: "" };
    }

    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({ address: this.props.value });
        }
    }
    componentDidMount() {
        this.setState({ address: this.props.value });
    }

    handleChange = (address) => {
        this.setState({ address });
    };

    handleSelect = (address) => {
        this.setState({ address });
        let location;
        geocodeByAddress(address)
            .then((results) => {
                location = results[0].formatted_address;
                return getLatLng(results[0]);
            })
            .then((data) =>
                this.props.handleLocation(location, data.lat, data.lng)
            )
            .catch((error) => console.error("Error", error));
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <div className="searchInputRoot">
                        <input
                            {...getInputProps({
                                placeholder:
                                    "Type an address, neighborhood, city or postal/zip code",
                            })}
                            className="searchInput"
                        />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, i) => {
                                const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {
                                          backgroundColor: "#f8b46a",
                                          cursor: "pointer",
                                          fontFamily: "CeraPro-Regular",
                                          fontSize: "18px",
                                          color: "white",
                                          margin: "5px auto",
                                      }
                                    : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                          fontFamily: "CeraPro-Regular",
                                          fontSize: "18px",
                                          margin: "5px auto",
                                      };
                                return (
                                    <div
                                        key={i}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        className="suggestionContainer"
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default LocationSearchInput;

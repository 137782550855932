import { useState, useMemo } from "react";
import styles from "./index.module.css";
import { closeOnboardingModal } from "actions/onboardingModalActions";
import { useAppDispatch } from "store/useAppSelectorDispatch";

export const OnboardingModal = () => {
    const dispatch = useAppDispatch();
    const [currentStep, setCurrentStep] = useState(0);

    const currentDisplay = useMemo(() => {
        switch (currentStep) {
            case 0:
                return (
                    <div className={styles.primaryContainer}>
                        <div className={styles.leftContainer}>
                            <figure
                                className={`${styles.figure} ${styles.figure1}`}
                            ></figure>
                        </div>
                        <div className={styles.rightContainer}>
                            <article className={styles.article}>
                                <div className={styles.copyContainer}>
                                    <p className={styles.copy}>
                                        <span className={styles.copyHighlight}>
                                            Events
                                        </span>{" "}
                                        are the building blocks of Scenarios in
                                        whatifi.
                                    </p>
                                    <p className={styles.copy}>
                                        Calculations flow daily through all
                                        Events in a{" "}
                                        <span className={styles.copyHighlight}>
                                            Scenario Thread
                                        </span>
                                        .
                                    </p>
                                </div>
                                <div className={styles.buttonsContainer}>
                                    <button
                                        className={styles.primaryButton}
                                        onClick={() => setCurrentStep(1)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </article>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className={styles.primaryContainer}>
                        <div className={styles.leftContainer}>
                            <figure
                                className={`${styles.figure} ${styles.figure2}`}
                            ></figure>
                        </div>
                        <div className={styles.rightContainer}>
                            <article className={styles.article}>
                                <div className={styles.copyContainer}>
                                    <p className={styles.copy}>
                                        Add{" "}
                                        <span className={styles.copyHighlight}>
                                            Decision Events
                                        </span>{" "}
                                        to create multiple alternate Scenario
                                        Threads.
                                    </p>
                                    <p className={styles.copy}>
                                        Insert a{" "}
                                        <span className={styles.copyHighlight}>
                                            Goal
                                        </span>{" "}
                                        or{" "}
                                        <span className={styles.copyHighlight}>
                                            KPI Event
                                        </span>{" "}
                                        to measure financial outcomes.
                                    </p>
                                </div>
                                <div className={styles.buttonsContainer}>
                                    <button
                                        className={styles.primaryButton}
                                        onClick={() => setCurrentStep(2)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </article>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className={styles.primaryContainer}>
                        <div className={styles.leftContainer}>
                            <figure
                                className={`${styles.figure} ${styles.figure3}`}
                            ></figure>
                        </div>
                        <div className={styles.rightContainer}>
                            <article className={styles.article}>
                                <div className={styles.copyContainer}>
                                    <p className={styles.copy}>
                                        Or quickly compare Scenario Threads
                                        without breaking formulas or duplicating
                                        tabs in a spreadsheet.
                                    </p>
                                    <p className={styles.copy}>In seconds.</p>
                                </div>
                                <div className={styles.buttonsContainer}>
                                    <button
                                        className={styles.primaryButton}
                                        onClick={() => setCurrentStep(3)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </article>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className={styles.primaryContainer}>
                        <div className={styles.leftContainer}>
                            <figure
                                className={`${styles.figure} ${styles.figure4}`}
                            ></figure>
                        </div>
                        <div className={styles.rightContainer}>
                            <article className={styles.article}>
                                <div className={styles.copyContainer}>
                                    <p className={styles.copy}>
                                        A decision is more than just the bottom
                                        line.
                                    </p>
                                    <p className={styles.copy}>
                                        Filter and sort outcomes based on a wide
                                        range of Account types.
                                    </p>
                                    <p className={styles.copy}>
                                        Not just dollars and cents.
                                    </p>
                                </div>
                                <div className={styles.buttonsContainer}>
                                    <button
                                        className={styles.primaryButton}
                                        onClick={() =>
                                            dispatch(closeOnboardingModal())
                                        }
                                    >
                                        Lets Go!
                                    </button>
                                </div>
                            </article>
                        </div>
                    </div>
                );
            default:
                return <></>;
        }
    }, [currentStep, dispatch]);

    return currentDisplay;
};

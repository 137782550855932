import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";

export function useActionsData(actionId: string) {
    const action = useAppSelector(
        (state) => state.canvasActionsData?.actionsData?.[actionId],
        shallowEqual
    );

    return action;
}

import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import CustomerTransfer2InputView from "./CustomerTransfer2InputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import type { ChangeEvent } from "react";
import { customerTransfer2InputsHandler } from "../OnInputChangeHandlers/customerTransfer2InputsHandler";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { updateEntityState } from "helpers/updateEntityState";
import { customer2Object } from "Components/Registry/Customer2";
import { cloneDeep } from "lodash";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";

export default function CustomerTransfer2InputExperimental({
    fillPartialValues,
    line,
    edit,
    editData,
    baseline,
    confirmAction,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */

    const eventId = useContext(EventInputIDContext);
    const propsObject = useMemo(
        () => ({ baselineManager, manager, line, eventId, baseline }),
        [baseline, baselineManager, eventId, line, manager]
    );
    const defaultName = useMemo(
        () => getDefaultName(customerTransfer2Object.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(customerTransfer2Object);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entityIndex,
        entitiesMap,
        entityIds,
        setEntitiesMap,
        onHandleDate,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "entityName"
            | "sourceEntity"
            | "targetCustomer"
            | "maxTransferRate"
            | "constantTransferRate"
            | "transferRate"
            | "cadence",
        index?: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = customerTransfer2InputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            index
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeValue = (
        value: any,
        id:
            | "transferType"
            | "interpolate"
            | "startDate"
            | "period"
            | "revertOnEnd"
            | "customEffectPeriod",
        index?: number
    ) => {
        const newEntitiesMap = customerTransfer2InputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            index
        );
        setEntitiesMap(newEntitiesMap);
    };

    const getCustomers = () => {
        const customers = {};

        if (!propsObject.eventId && !propsObject.line) return customers;

        getPresentableDependencies(
            customers,
            customer2Object.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(customers).length) {
            throwError(
                "warning",
                "No Customer2 nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return customers;
    };

    const getWebsiteVisitors = () => {
        const websiteVisitors = {};

        getPresentableDependencies(
            websiteVisitors,
            websiteVisitorsObject.constant(),
            propsObject, // Temporary object
            true
        );

        return websiteVisitors;
    };

    const addNewSegment = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const transferSegments = cloneDeep(data.transferSegments);
        const newSegment = {
            startDate: "",
            monthlyRate: 0,
            yearlyRate: 0,
            rateDisplay: "",
            period: "monthly",
        };

        transferSegments.push(newSegment);

        data.transferSegments = transferSegments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const deleteSegment = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const transferSegments = cloneDeep(data.transferSegments);

        transferSegments.splice(index, 1);
        data.transferSegments = transferSegments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const customers = getCustomers();
            const websiteVisitors = getWebsiteVisitors();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.customers = customers;
            newEntitiesMap[currentEntity].data.websiteVisitors =
                websiteVisitors;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, baselineManager, setEntitiesMap, defaultName]);

    // THIS NEEDS A REWORK!
    const onHandleSubmitValues = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                confirmAction,
                fillPartialValues,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    // ALSO NEEDS A REWORK!
    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                confirmAction,
                fillPartialValues,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && customerTransfer2Object.checkInput(entitiesMap);
    return (
        <CustomerTransfer2InputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            onHandleDate={onHandleDate}
            passedCheck={passedCheck}
            onHandleSubmitValues={onHandleSubmitValues}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
            onChangeValue={onChangeValue}
            addNewSegment={addNewSegment}
            deleteSegment={deleteSegment}
        />
    );
}

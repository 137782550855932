import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import EntityStructureData from "exampleConfigJson/entityTypesStructure.json";
import ImportEventBase, {
    ImportEventProps,
    CsvMap,
    ManuallyMappedFields,
} from "./ImportEventLib";

import { cloneDeep } from "lodash";
import { resourceObject } from "Components/Registry/Resource";
import { FieldType } from "reducers/typesSchema/fieldMappingSchema";

const csvboxDynamicColumns = [
    {
        column_name: "projectCode",
        display_label: "Project Code",
        required: true,
    },
    { column_name: "bidId", display_label: "Bid ID", required: true },
    { column_name: "name", display_label: "Name", required: true },
    {
        column_name: "amount",
        display_label: "Amount of work (in days)",
        required: false,
    },
    {
        column_name: "startDate",
        display_label: "Start Date",
        type: "date",
        validators: { format: ["YYYY-MM-DD"] },
        required: false,
    },
    {
        column_name: "endDate",
        display_label: "End Date",
        type: "date",
        validators: { format: ["YYYY-MM-DD"] },
        required: false,
    },
];

const manuallyMappedFields: ManuallyMappedFields = [
    {
        columnName: "name",
        fieldType: FieldType.Account,
        newName: "MAP_account",
    },
];

const getCsvIdToEventName = (rows) => {
    const csvIdToEventName: { [name: string]: string } = {};

    rows.forEach((row) => {
        const dynamic = row._dynamic_data;
        const csvEventName = `${dynamic.projectCode}-${dynamic.bidId}`;
        const csvEntityId = `${csvEventName}-${dynamic.name}`;
        csvIdToEventName[csvEntityId] = csvEventName;
    });

    return csvIdToEventName;
};

/**
 * Given a csv row (from CsvBox) and csvMap, return an entitiesMap for Resource
 * entities
 * */
const getResourceEntitiesMap = (rows, csvMap: CsvMap) => {
    const resourceEntitiesMap: EntitiesSchema = {};

    rows.forEach((row) => {
        const dynamic = row._dynamic_data;
        const csvEventName = `${dynamic.projectCode}-${dynamic.bidId}`;
        const csvEntityId = `${csvEventName}-${dynamic.name}`;
        const entityId = csvMap[csvEntityId].entityId;
        resourceEntitiesMap[entityId] = cloneDeep(
            EntityStructureData?.[resourceObject.constant()]
        );

        resourceEntitiesMap[entityId] = {
            ...resourceEntitiesMap[entityId],
            id: entityId,
            name: csvEntityId,
            cadence: "monthly",
            startDate: dynamic.startDate,
            endDate: dynamic.endDate,
        };

        resourceEntitiesMap[entityId].data = {
            ...resourceEntitiesMap[entityId].data,
            accountIds: dynamic.MAP_account.ids,
            accountName: dynamic.MAP_account.name,
            inheritedStartDate: false,
            inheritedEndDate: false,
            bidId: dynamic.bidId,
            value: dynamic.amount,
            setOrDistributed: "set",
            fixedOrAmortized: "fixed",
        };
    });
    return resourceEntitiesMap;
};

export default function ImportResources(props: ImportEventProps) {
    return (
        <ImportEventBase
            {...props}
            getCsvIdToEventName={getCsvIdToEventName}
            getNewEntitiesMap={getResourceEntitiesMap}
            csvboxDynamicColumns={csvboxDynamicColumns}
            manuallyMappedFields={manuallyMappedFields}
            eventObject={resourceObject}
        />
    );
}

import type { ThunkAction } from "redux-thunk";
import type { RootState } from "store";
import type { AnyAction } from "redux";

import { ScenarioViewSelectedThreadsSchema } from "reducers/typesSchema/ScenarioViewSelectedThreads";

export const ADD_THREAD_TO_SELECTED_THREADS =
    "scenarioViewSelectedThreads/ADD_THREAD_TO_SELECTED_THREADS";
export const UPSERT_SCENARIO_VIEW_SELECTED_THREADS =
    "scenarioViewSelectedThreads/UPSERT_SCENARIO_VIEW_SELECTED_THREADS";
export const RESET_SCENARIO_VIEW_SELECTED_THREADS =
    "scenarioViewSelectedThreads/RESET_SCENARIO_VIEW_SELECTED_THREADS";
export const RESET_SCENARIO_VIEW_ALL_STATE =
    "scenarioViewSelectedThreads/RESET_SCENARIO_VIEW_ALL_STATE";

/**
 * Reset state.selectedThreads to empty string[]
 */
export const resetScenarioViewSelectedThreads = () => ({
    type: RESET_SCENARIO_VIEW_SELECTED_THREADS,
});

/**
 * Reset state to initialState
 */
export const resetScenarioViewAllState = () => ({
    type: RESET_SCENARIO_VIEW_ALL_STATE,
});

export const upsertScenarioViewSelectedThreads = (
    payload: Partial<ScenarioViewSelectedThreadsSchema>
) => ({
    type: UPSERT_SCENARIO_VIEW_SELECTED_THREADS,
    payload,
});

export const selectAllThreads =
    (): ThunkAction<void, RootState, any, AnyAction> =>
    (dispatch, getState) => {
        const allThreads = Object.keys(getState().calculatedThreads);

        dispatch(
            upsertScenarioViewSelectedThreads({
                selectedThreads: allThreads,
            })
        );
    };

export const addThreadsToSelectedThreads =
    (newThreadIds: string[]): ThunkAction<void, RootState, any, AnyAction> =>
    (dispatch, getState) => {
        const selectedThreads =
            getState().scenarioViewSelectedThreads?.selectedThreads;

        dispatch(
            upsertScenarioViewSelectedThreads({
                selectedThreads: [...selectedThreads, ...newThreadIds],
            })
        );
    };

export const removeThreadsFromSelectedThreads =
    (threadIds: string[]): ThunkAction<void, RootState, any, AnyAction> =>
    (dispatch, getState) => {
        const selectedThreads =
            getState().scenarioViewSelectedThreads?.selectedThreads.filter(
                (curSelectedThreadId) =>
                    !threadIds.includes(curSelectedThreadId)
            );

        dispatch(
            upsertScenarioViewSelectedThreads({
                selectedThreads,
            })
        );
    };

// import type {ChangeEvent} from "react";
import { loanObject } from "Components/Registry/Loan";
import moment from "moment"
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const loanInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "standard"
        | "revenueBased"
        | "interestVsPremium"
        | "loan"
        | "interestRate"
        | "loanStart"
        | "loanPremium"
        | "revenueAccount"
        | "revenuePercentage"
        | "paymentStart"
        | "cadence"
        | "termUnit"
        | "numTermUnits"
        | "interestPaymentsOnly",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "standard":
        case "revenueBased":
            data.loanType = id;

            data.loan = 0;
            data.interestRate = 0;
            data.loanPremium = 0;
            data.dependantAccountId = "";
            data.dependantAccount = "";
            data.revenuePercentage = 0;
            data.paymentStart = "";
            data.termUnit = "";
            data.numTermUnits = 0;
            data.interestPaymentsOnly = false;
            currentEntityObject.cadence = "";
            currentEntityObject.startDate = "";
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interestVsPremium": 
            data.interestVsPremium = value;

            data.loanPremium = 0;
            data.interestRate = 0;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break
        case "loan":
            const loan = parseInt(value) || 0;
            data.loan = loan;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = handleCalculation(
                currentEntityObject,
                data
            );
            break;
        case "interestRate":
            const rate = parseFloat(value) || 0;
            data.interestRate = rate;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = handleCalculation(
                currentEntityObject,
                data
            );
            break;
        case "loanStart":
            const loanStartDateString = moment(value).format("YYYY-MM-DD")
            currentEntityObject.startDate = loanStartDateString;
            newEntitiesMap[currentEntity] = handleCalculation(
                currentEntityObject,
                data
            );
            break
        case "loanPremium":
            const loanPremium = parseInt(value) || 0;
            data.loanPremium = loanPremium;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = handleCalculation(
                currentEntityObject,
                data
            );
            break;
        case "revenueAccount":
            const parsedAccount = JSON.parse(value || "{\"Name\": \"\", \"Id\": \"\"}")

            data.dependantAccount = parsedAccount.name
            data.dependantAccountId = parsedAccount.id
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break
        case "revenuePercentage": 
            const revenuePercentage = parseFloat(value) || 0;
            data.revenuePercentage = revenuePercentage
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break
        case "paymentStart":
            const paymentStartDateString = moment(value).format("YYYY-MM-DD")
            data.paymentStart = paymentStartDateString;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = handleCalculation(
                currentEntityObject,
                data
            );
            break;
        case "termUnit":
            let termUnitAmortization = data.numTermUnits ?? 1;
            if (
                data.termUnit?.toLowerCase() === "years" &&
                value.toLowerCase() === "months"
            ) {
                termUnitAmortization /= 12;
            }
            data.amortization = termUnitAmortization;
            data.termUnit = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = handleCalculation(
                currentEntityObject,
                data
            );
            break;
        case "numTermUnits":
            data.numTermUnits = parseInt(value) || 1;
            let numTermUnitsAmortization = data.numTermUnits ?? 1;
            if (data.termUnit?.toLowerCase() === "months") {
                numTermUnitsAmortization = data.numTermUnits / 12;
            }
            data.amortization = numTermUnitsAmortization;
            if (!isNaN(data.numTermUnits) && !isNaN(data.amortization)) {
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = handleCalculation(
                    currentEntityObject,
                    data
                );
            }
            break;
        case "interestPaymentsOnly": 
            data.interestPaymentsOnly = !data.interestPaymentsOnly;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break
        default:
        // Noop
    }

    return newEntitiesMap;
};

const handleCalculation = (currentEntityObject, data) => {
    if (currentEntityObject.type === loanObject.constant()) {
        if (
            currentEntityObject.cadence !== (undefined || null) &&
            data.amortization !== (undefined || null) &&
            data.loan !== (undefined || null) &&
            data.interestRate !== (undefined || null)
        ) {
            const paymentData = getLoanPayments(currentEntityObject, data);
            data.termInterest = paymentData.termInterest;
            data.numPayments = paymentData.numPayments;
            data.periodicInterestRate = paymentData.periodicInterestRate

            const payments = parseFloat(paymentData.payments).toFixed(2);
            const value = parseInt(payments);
            data.payments = value;
            data.value = parseFloat(paymentData.payments);
        }
    }
    currentEntityObject.data = data;
    return currentEntityObject;
};

const getLoanPayments = (currentEntityObject, data) => {
    const principal = data.loan;
    const rate = data.interestRate * 0.01;
    const amortization = data.amortization;

    let payments;
    let periodicInterestRate;
    let termInterest = 0;
    let numPayments = amortization;
    switch (currentEntityObject.cadence) {
        case "annually":
            numPayments *= 1;
            periodicInterestRate = rate;
            payments =
                principal /
                (((1 + periodicInterestRate) ** numPayments - 1) /
                    (periodicInterestRate *
                        (1 + periodicInterestRate) ** numPayments));
            termInterest = principal - payments * amortization;
            break;
        case "quarterly":
            numPayments *= 4;
            periodicInterestRate = rate / 4;
            payments =
                principal /
                (((1 + periodicInterestRate) ** numPayments - 1) /
                    (periodicInterestRate *
                        (1 + periodicInterestRate) ** numPayments));
            termInterest = principal - payments * amortization * 4;
            break;
        case "monthly":
            numPayments *= 12;
            periodicInterestRate = rate / 12;
            payments =
                principal /
                (((1 + periodicInterestRate) ** numPayments - 1) /
                    (periodicInterestRate *
                        (1 + periodicInterestRate) ** numPayments));
            termInterest = principal - payments * amortization * 12;
            break;
        case "semi-monthly":
            numPayments *= 24;
            periodicInterestRate = rate / 24;
            payments =
                principal /
                (((1 + periodicInterestRate) ** numPayments - 1) /
                    (periodicInterestRate *
                        (1 + periodicInterestRate) ** numPayments));
            termInterest = principal - payments * amortization * 24;
            break;
        case "bi-weekly":
            numPayments *= 26;
            periodicInterestRate = rate / 26;
            payments =
                principal /
                (((1 + periodicInterestRate) ** numPayments - 1) /
                    (periodicInterestRate *
                        (1 + periodicInterestRate) ** numPayments));
            termInterest = principal - payments * amortization * 26;
            break;
        case "weekly":
            numPayments *= 52;
            periodicInterestRate = rate / 52;
            payments =
                principal /
                (((1 + periodicInterestRate) ** numPayments - 1) /
                    (periodicInterestRate *
                        (1 + periodicInterestRate) ** numPayments));
            termInterest = principal - payments * amortization * 52;
            break;
        case "one-time":
            numPayments = 1;
            periodicInterestRate = rate;
            payments = principal * (rate + 1);
            termInterest = principal - payments * amortization;
            break;
        default:
    }
    return {
        payments: payments,
        termInterest: (-1 * termInterest).toFixed(0),
        numPayments,
        periodicInterestRate
    };
};

import { useSpring, animated } from "react-spring";
import EventMenuTabIcon from "../../Assets/tabsIcons/detail.svg";
import ScenarioViewTabIcon from "../../Assets/tabsIcons/decisionEngine.svg";
import KnowledgeBaseTabIcon from "../../Assets/tabsIcons/knowledgeBase.svg";

export default function RightDisplayPanel({
    showRightDisplay,
    eventMenuClass,
    scenarioViewClass,
    knowledgeBaseClass,
    ClickRightDisplayChangeTabs,
    rightDisplayComponent,
}) {
    const containerAnimation = useSpring({
        to: { width: showRightDisplay ? 375 : 0 },
    });

    return (
        <animated.div className="RightDisplay" style={containerAnimation}>
            <div className="TabControlsContainer">
                <div
                    className={`${eventMenuClass} UserGuiding-EventDetailsTab`}
                    title="Event Details"
                    onClick={() => {
                        ClickRightDisplayChangeTabs("EventMenu");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={EventMenuTabIcon}
                        alt="event menu tab"
                    />
                </div>
                <div
                    className={`${scenarioViewClass} UserGuiding-DecisionEngineTab`}
                    title="Decision Engine"
                    onClick={() => {
                        ClickRightDisplayChangeTabs("ScenarioView");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={ScenarioViewTabIcon}
                        alt="scenario view tab"
                    />
                </div>
                <div
                    className={`${knowledgeBaseClass} UserGuiding-KnowledgeBaseTab`}
                    title="Knowledge Base"
                    onClick={() => {
                        ClickRightDisplayChangeTabs("KnowledgeBase");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={KnowledgeBaseTabIcon}
                        alt="knowledge base tab"
                    />
                </div>
            </div>

            {/* this variable is rendering the component inside the right side panel */}
            {rightDisplayComponent}
        </animated.div>
    );
}

import { useState, useMemo, useContext, useRef, MutableRefObject } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import type { ChangeEvent } from "react";
import { contractInputsHandler } from "../OnInputChangeHandlers/contractInputsHandler";
import ContractInputView from "./ContractInputView";
import { contractObject } from "Components/Registry/Contract";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import moment from "moment";
import { updateEntityState } from "helpers/updateEntityState";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function ContractInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...useAppSelector(
            (state) => state?.scenario?.loadedScenario?.dependency_map
        ),
    });

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(contractObject);
            _eventData.name = getDefaultName(
                contractObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        updateInflation,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        contractObject,
        dependencyMap.current
    ); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "value"
            | "initialPayout"
            | "contractLength"
            | "url"
            | "entityName"
            | "netInitial"
            | "netFinal"
            | "rating"
            | "business"
            | "payoutPercentage"
            | "offset"
            | "net",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = contractInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = contractInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleAddDynamicValue = () => {
        // Adds a new milestone field
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const specificPercentages = data.specificPercentages;

        const newData = {
            id: specificPercentages.length,
            offsetDays: "",
            offsetMonths: "",
            value: "",
            net: "",
            date: "",
        };
        specificPercentages.push(newData);

        data.specificPercentages = specificPercentages;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDeleteDynamicValue = () => {
        // Removes a milestone field
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const specificPercentages = data.specificPercentages;

        const lastAdded = specificPercentages.length - 1;
        const filteredArray = specificPercentages.filter((data) => {
            return data.id !== lastAdded;
        });

        const initialPayout = data.initialPayout;
        let totalPercent = 0;
        if (filteredArray.length > 0) {
            filteredArray.forEach((currentVal) => {
                totalPercent += Number(currentVal.value);
            });
        }
        const finalPay = 100 - totalPercent - Number(initialPayout);
        if (finalPay >= 0) {
            data.finalPayout = finalPay;
        }

        data.specificPercentages = filteredArray;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const resetContractLength = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data.calculationMode == "days") {
            data.contractLengthDays = "";
        } else {
            data.contractLengthMonths = "";
        }
        currentEntityObject.endDate = "";

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const resetMilestoneOffset = (id) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.specificPercentages.forEach((milestone) => {
            if (milestone.id === id) {
                if (data.calculationMode == "days") {
                    milestone.offsetDays = "";
                } else {
                    milestone.offsetMonths = "";
                }
                milestone.date = "";
            }
        });

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeCalculationMode = (e: ChangeEvent<HTMLInputElement>) => {
        const mode = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.calculationMode = mode;

        const calculationMode = data.calculationMode;
        const startDate = currentEntityObject.startDate;
        if (startDate) {
            // update end date
            const contractLength =
                calculationMode == "days"
                    ? data.contractLengthDays
                    : data.contractLengthMonths;
            if (contractLength) {
                currentEntityObject.endDate = new Date(
                    moment(startDate)
                        .add(Number(contractLength), calculationMode)
                        .format("YYYY-MM-DD")
                )
                    .toISOString()
                    .split("T")[0];
            } else {
                currentEntityObject.endDate = "";
            }

            // update milestone dates
            data.specificPercentages.forEach((milestone) => {
                const offset =
                    calculationMode == "days"
                        ? milestone.offsetDays
                        : milestone.offsetMonths;

                if (!offset) {
                    milestone.date = "";
                } else {
                    milestone.date = new Date(
                        moment(startDate)
                            .add(Number(offset), calculationMode)
                            .format("YYYY-MM-DD")
                    )
                        .toISOString()
                        .split("T")[0];
                }
            });
        }

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const star = 0;
        const newEntitiesMap = contractInputsHandler(
            "",
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && contractObject.checkInput(entitiesMap);

    return (
        <ContractInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            updateInflation={updateInflation}
            handleOnChange={handleOnChange}
            onHandleSubmit={onHandleSubmit}
            handleAddDynamicValue={handleAddDynamicValue}
            handleDeleteDynamicValue={handleDeleteDynamicValue}
            passedCheck={passedCheck}
            eventData={eventData}
            edit={edit}
            handleChangeCalculationMode={handleChangeCalculationMode}
            resetContractLength={resetContractLength}
            resetMilestoneOffset={resetMilestoneOffset}
            handleEntityStateChange={handleEntityStateChange}
            handleDateSelection={handleDateSelection}
            updateAccount={updateAccount}
        />
    );
}

import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import ProjectInputView from "./ProjectInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { groupObject } from "Components/Registry/Group";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { incomeObject } from "Components/Registry/Income";
import { expenseObject } from "Components/Registry/Expense";
import { loanObject } from "Components/Registry/Loan";
import { debtObject } from "Components/Registry/Debt";
import { debtRepaymentObject } from "Components/Registry/Debt Repayment";
import { employeeObject } from "Components/Registry/Employee";
import { customerObject } from "Components/Registry/Customer";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { revenueObject } from "Components/Registry/Revenue";
import { contractObject } from "Components/Registry/Contract";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { projectInputsHandler } from "../OnInputChangeHandlers/projectInputsHandler";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import type { EntitySchema } from "../../../reducers/typesSchema/entitiesSchema";
import { projectObject } from "Components/Registry/Project";
import swal from "sweetalert";
import { cloneDeep } from "lodash";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

type Item = {
    itemType: string;
    eventType: string;
    offsetMode: "days" | "months";
    offset: string;
    selectedItem: string;
    events: any;
    entities: any;
    multiplier: string;
    duration: string;
    clip: boolean;
    editing?: boolean;
    itemId?: string;
};

export default function ProjectInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );

    const defaultName = useMemo(
        () => getDefaultName(projectObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(projectObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entityIndex,
        entitiesMap,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "select-event" | "select-entity" | "offset",
        mainId: string,
        index: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = projectInputsHandler(
            value,
            id,
            mainId,
            index,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleChangeItemType = (e: ChangeEvent<HTMLInputElement>, id, i) => {
        const index = Number(i);
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];
        const selectionItem = selectionItems[index];

        selectionItem.itemType =
            e.target.value === "Records" ? "entities" : "events";
        selectionItem.selectedItem = "";
        selectionItems[index] = selectionItem;
        data.selectionItems = selectionItems;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeClipping = (e: ChangeEvent<HTMLInputElement>, id, i) => {
        const index = Number(i);
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];
        const selectionItem = selectionItems[index];

        selectionItem.clip = e.target.value == "Clip";

        selectionItems[index] = selectionItem;
        data.selectionItems = selectionItems;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeEventType = (e: ChangeEvent<HTMLInputElement>, id, i) => {
        const index = Number(i);
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];
        const selectionItem = selectionItems[index];

        selectionItem.eventType = e.target.value;
        selectionItem.selectedItem = "";

        const events = getEvents(selectionItem.eventType) || [];
        selectionItem.events = events;
        selectionItem.entities = {};

        Object.values(events).forEach((event) => {
            const currEntities = getRelevantEntities(event.entities) || [];
            selectionItem.entities[event.id] = Object.values(currEntities);
        });

        selectionItem.selectedItem = "";
        selectionItems[index] = selectionItem;
        data.selectionItems = selectionItems;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeOffsetMode = (value, index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [
            ...newEntitiesMap[currentEntity].data.selectionItems,
        ];
        const selectionItem = selectionItems[index];

        selectionItem.offsetMode = value;
        selectionItems[index] = selectionItem;
        data.selectionItems = selectionItems;
        currentEntityObject.data = data;
        setEntitiesMap(newEntitiesMap);
    };

    const getEvents = (event) => {
        const events = {};
        if ((!propsObject.eventId && !propsObject.line) || !event) return [];

        getPresentableDependencies(
            events,
            getEventObject(event),
            propsObject // Temporary object
        );

        if (!Object.keys(events).length) {
            throwError(
                "warning",
                `No ${event} nodes found upstream or in baseline`
            );
        }

        const eventObjects: Event | EventStructure[] = [];
        Object.values(events).forEach((event) => {
            const eventObject = getEvent(String(event));
            if (eventObject) {
                eventObjects.push(eventObject);
            }
        });

        return eventObjects;
    };

    const handleAddSelectionItem = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];

        const newSelectionItem = {
            itemType: "entities",
            eventType: "",
            offsetMode: "days",
            offset: "",
            selectedItem: "",
            multiplier: "",
            duration: "",
            clip: false,
            events: [],
            entities: {},
        };

        selectionItems.push(newSelectionItem);
        data.selectionItems = selectionItems;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDeleteSelectionItem = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];

        selectionItems.splice(index, 1);

        data.selectionItems = selectionItems;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const addSelectedItem = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];
        const selectionItem = selectionItems[index];

        const items = cloneDeep(data.items);

        if (Object.keys(items).includes(selectionItem.selectedItem)) {
            swal({
                title: "Error",
                text: "Event or Record is already in group",
            });
            return;
        }

        items[selectionItem.selectedItem] = selectionItem;
        selectionItems.splice(index, 1);

        data.selectionItems = selectionItems;
        data.items = items;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDeleteItem = (id) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const items = cloneDeep(data.items);
        delete items[id];

        const selectionItems = [...data.selectionItems];
        for (let i = 0; i < selectionItems.length; i++) {
            if (selectionItems[i].itemId && id == selectionItems[i].itemId) {
                selectionItems.splice(i, 1);
                break;
            }
        }

        data.items = items;
        data.selectionItems = selectionItems;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleEditItem = (id) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];
        const itemData = data.items[id];

        if (
            selectionItems.some((selectionItem) => {
                selectionItem.itemId && selectionItem.itemId == id;
            })
        ) {
            return;
        }

        const newItemData = {
            editing: true,
            itemId: id,
        };

        for (const [key, data] of Object.entries(itemData)) {
            newItemData[key] = data;
        }

        const events = getEvents((newItemData as Item).eventType) || [];
        (newItemData as Item).events = events;
        (newItemData as Item).entities = {};

        Object.values(events).forEach((event) => {
            const currEntities = getRelevantEntities(event.entities) || [];
            (newItemData as Item).entities[event.id] =
                Object.values(currEntities);
        });

        selectionItems.push(newItemData);

        data.selectionItems = selectionItems;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleUpdateEditItem = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const selectionItems = [...data.selectionItems];
        const items = cloneDeep(data.items);
        const editItem = selectionItems[index];

        if (
            editItem.itemId !== editItem.selectedItem &&
            Object.keys(items).includes(editItem.selectedItem)
        ) {
            swal({
                title: "Error",
                text: "Event or Record is already in group",
            });
            return;
        } else {
            delete editItem.editing;

            const newItems = {};

            // preserve the order of items if the selected item changes
            for (const [key, data] of Object.entries(items)) {
                if (key == editItem.itemId) {
                    newItems[editItem.selectedItem] = editItem;
                    delete editItem.itemId;
                } else {
                    newItems[key] = data;
                }
            }

            selectionItems.splice(index, 1);
            data.items = newItems;
            data.selectionItems = selectionItems;
        }

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getEventObject = (event) => {
        switch (event) {
            case "Debit Credit":
                return debitCreditObject.constant();
            case "Income":
                return incomeObject.constant();
            case "Expense":
                return expenseObject.constant();
            case "Loan":
                return loanObject.constant();
            case "Debt":
                return debtObject.constant();
            case "Debt Repayment":
                return debtRepaymentObject.constant();
            case "Employee":
                return employeeObject.constant();
            case "Customer":
                return customerObject.constant();
            case "Unit Cost":
                return unitCostObject.constant();
            case "Revenue":
                return revenueObject.constant();
            case "Contract":
                return contractObject.constant();
            case "Group":
                return groupObject.constant();
        }
    };

    function getExportEntities(entities) {
        const exportEntities = { ...entities };
        for (const [key, entity] of Object.entries(exportEntities)) {
            const currentEntityObject = {
                ...((entity as EntitySchema) || {}),
            };
            const data = { ...(currentEntityObject?.data || {}) };

            const newItems = {};

            for (const [id, itemContent] of Object.entries(data.items)) {
                const itemData: Item = cloneDeep(itemContent) as Item;
                itemData.entities = {};
                itemData.events = [];

                newItems[id] = itemData;
            }

            data.items = newItems;
            data.selectionItems = [];
            currentEntityObject.data = data;
            exportEntities[key] = currentEntityObject;
        }

        return exportEntities;
    }

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap, defaultName]);

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                getExportEntities(entitiesMap),
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck =
        !!eventData.name && projectObject.checkInput(entitiesMap);
    return (
        <ProjectInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            entitiesLength={entityIds.length}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleChangeItemType={handleChangeItemType}
            handleChangeEventType={handleChangeEventType}
            handleAddSelectionItem={handleAddSelectionItem}
            handleDeleteSelectionItem={handleDeleteSelectionItem}
            addSelectedItem={addSelectedItem}
            handleDeleteItem={handleDeleteItem}
            handleEditItem={handleEditItem}
            handleUpdateEditItem={handleUpdateEditItem}
            handleChangeOffsetMode={handleChangeOffsetMode}
            handleChangeClipping={handleChangeClipping}
            handleEntityStateChange={handleEntityStateChange}
        />
    );
}

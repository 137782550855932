import type {
    BaseMenuFullBodyPositioningRectSchema,
    ButtonDimensionsRectSchema,
} from "reducers/typesSchema/menusTemplatesSchema";
import { useContext, useMemo } from "react";
import { Group } from "react-konva";
import {
    MenuLayerOne,
    MenuLayerTwo,
    MenuLayerThree,
} from "./partials/MenuLayers";
import { MenusContainer } from "./partials/MenusContainer";
import { MenuIconsContainer } from "./partials/MenuIconsContainer";
import { ToolTipTextContainer } from "./partials/ToolTipTextContainer";
import {
    EventNodeActiveContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeLockedContext,
} from "../../contexts/EventNodeStateContexts";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { MenuIdContext } from "./contexts/MenuIdContext";
import { CanvasStageScaleContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageScaleContext";
import { useMenuTemplate } from "./hooks/useMenuTemplate";
import { useEventTemplate } from "../../hooks/useEventTemplate";
import { EventNodeIDContext } from "../../contexts/EventNodeIDContext";
import { BaseMenuContainerWidthContext } from "./contexts/BaseMenuContainerWidthContext";
import type { MenuTemplate } from "reducers/typesSchema/menusTemplatesSchema";
import { CanvasBaselineContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasBaselineContext";

const CANVAS_STYLES_FIELDS_DIMENSIONS_AND_COLORS = [
    "baseMenuDimensions",
    "baseMenuColors",
];

const _CANVAS_STYLES_FIELDS_DIMENSIONS = ["baseMenuDimensions"];

/**
 * The menu when a user "focuses" on an event. Only displays icons and shows the command's name when user hovers.
 * Divided into layers:
 *   - {@link MenuLayerOne} - The shape of the menus container
 *   - {@link MenuLayerTwo} - The clickable icons - decides the width of the container
 *                            (Each icon == 40x40px)
 *   - {@link MenuLayerThree} - The tooptip text
 */
export function EventNodeBaseMenu() {
    const isBaseline = useContext(CanvasBaselineContext);
    const menuId = useContext(MenuIdContext);
    const stageScale = useContext(CanvasStageScaleContext);
    const menuTemplate = useMenuTemplate(menuId) as MenuTemplate;
    const eventId = useContext(EventNodeIDContext);
    const eventTemplate = useEventTemplate(eventId);
    const baseMenuActionsIds = useMemo(
        () =>
            (isBaseline
                ? eventTemplate?.eventTemplateObject?.baselineMenuActionsIds
                : eventTemplate?.eventTemplateObject?.baseMenuActionsIds) ?? [],
        [isBaseline, eventTemplate?.eventTemplateObject]
    );

    const active = useContext(EventNodeActiveContext);
    const focused = useContext(EventNodeFocusedContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const error = useContext(EventNodeErrorContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);

    const baseMenuFullBodyPositioning = useMemo(() => {
        const _styles =
            menuTemplate?.canvasStyles?.baseMenuFullBodyPositioning?.styles;
        const _default = _styles?.default;
        const _active = active ? _styles?.active : {};
        const _focused = focused ? _styles?.focused : {};
        const _locked = locked ? _styles?.locked : {};
        const _bypassed = bypassed ? _styles?.bypassed : {};
        const _error = error ? _styles?.error : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _finalStyles: BaseMenuFullBodyPositioningRectSchema = {
            ..._default,
            ..._active,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };

        return _finalStyles;
    }, [
        active,
        bypassed,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles?.baseMenuFullBodyPositioning?.styles,
        zoomCategory,
    ]);

    /**
     * THe full width of the container. Used to contain the icons perfectly. Could be a dynamic size.
     */
    const [containerWidth, containerLargestHeight] = useMemo(() => {
        let w = 0;
        let h = 0;
        baseMenuActionsIds.forEach((baseMenuActionId) => {
            const _styles =
                menuTemplate?.canvasStyles?.buttons?.[baseMenuActionId]
                    ?.buttonDimensions?.styles;
            const _default = _styles?.default;
            const _active = active ? _styles?.active : {};
            const _focused = focused ? _styles?.focused : {};
            const _locked = locked ? _styles?.locked : {};
            const _bypassed = bypassed ? _styles?.bypassed : {};
            const _error = error ? _styles?.error : {};
            const _zoomCategory = _styles?.[zoomCategory];

            const _finalStyles: ButtonDimensionsRectSchema = {
                ..._default,
                ..._active,
                ..._focused,
                ..._locked,
                ..._bypassed,
                ..._error,
                ..._zoomCategory,
            };

            w += _finalStyles?.width ?? 0;
            if ((_finalStyles?.height ?? 0) > h) h = _finalStyles?.height ?? 0;
        });

        return [w, h];
    }, [
        active,
        baseMenuActionsIds,
        bypassed,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    return (
        <BaseMenuContainerWidthContext.Provider value={containerWidth}>
            <Group scaleX={1 / stageScale} scaleY={1 / stageScale}>
                <Group {...baseMenuFullBodyPositioning}>
                    <MenuLayerOne listening={false}>
                        <MenusContainer
                            canvasStylesFields={
                                CANVAS_STYLES_FIELDS_DIMENSIONS_AND_COLORS
                            }
                        />
                    </MenuLayerOne>
                    <MenuLayerTwo>
                        <MenuIconsContainer />
                    </MenuLayerTwo>
                    <MenuLayerThree
                        listening={false}
                        y={containerLargestHeight}
                    >
                        <ToolTipTextContainer />
                    </MenuLayerThree>
                </Group>
            </Group>
        </BaseMenuContainerWidthContext.Provider>
    );
}

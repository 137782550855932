import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import AccountImportInputView from "./AccountImportInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent } from "react";
import { createNewEvent, extractEventIds } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { accountImportInputsHandler } from "../OnInputChangeHandlers/accountImportInputsHandler";
import { updateEntityState } from "helpers/updateEntityState";
import { accountImportObject } from "Components/Registry/AccountImport";
import axios from "axios";
import { cloneDeep, isEmpty } from "lodash";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { getTodayDateString } from "../OnInputChangeHandlers/initialBalanceInputsHandler";
import * as uuid from "uuid";
import { setShowAccountMappingModal } from "actions/accountMappingActions";
import moment from "moment";
import {
    AccountMappingType,
    AccountRowMapped,
} from "reducers/typesSchema/accountMappingsSchema";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { getAdjustedAccountMappingData } from "helpers/accountMapping";
import { inCurrentCsv } from "helpers/csvImport";
import swal from "sweetalert";
import { config } from "config";
import { requestHeader } from "actions/scenario";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { EventManager } from "Events";

export default function AccountImportInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const startEventCreation = (accountMappingData) => {
        const adjustedMappingData =
            getAdjustedAccountMappingData(accountMappingData);
        importAccounts(adjustedMappingData);
    };

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = useMemo(
        () => getDefaultName(accountImportObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(accountImportObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = accountImportInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onConnectService = (railzData) => {
        const businessName = railzData.businessName;
        const serviceName = railzData.serviceName;

        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.businessName = businessName;
        data.serviceName = serviceName;
        data.importState = "connected";

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getRailzAccessToken = () => {
        return axios.get(`${config.host}/railz`, requestHeader());
    };

    const getRailzConnection = async (
        accessToken,
        businessName,
        serviceName
    ) => {
        const accountsUrl = `https://api.railz.ai/accounts?businessName=${businessName}&serviceName=${serviceName}`;
        try {
            const response = await axios.get(accountsUrl, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            });

            if (response?.status === 202 || response?.status === 204) {
                swal({
                    title: "Error",
                    text: "Data is pending, or not available yet. Please try again later.",
                });
                return;
            }

            const accountMappingData = getAccountMappingDataRailz(
                response?.data?.data ?? []
            );

            setShowAccountMappingModal({
                show: true,
                entity: entitiesMap[currentEntity],
                accountMappingData: accountMappingData,
                accountMappingType: AccountMappingType.Railz,
                create: startEventCreation,
            });
        } catch (error: any) {
            if (error?.response?.status == 401) {
                try {
                    const response = await getRailzAccessToken();
                    const accessToken = response?.data?.accessToken;
                    if (accessToken)
                        getRailzConnection(
                            accessToken,
                            businessName,
                            serviceName
                        );
                } catch (error) {
                    console.log("get access token error: ", error);
                }
            }
        }
    };

    const refreshImportedAccounts = async () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const businessName = data.businessName;
        const serviceName = data.serviceName;
        data.lastUpdated = getTodayDateString();

        try {
            const response = await getRailzAccessToken();
            const accessToken = response?.data?.accessToken;
            if (accessToken) {
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
                setEntitiesMap(newEntitiesMap);

                await getRailzConnection(
                    accessToken,
                    businessName,
                    serviceName
                );
            }
        } catch (error) {
            console.log("Error obtaining access token ->", error);
        }
    };

    // TODO: create an interface for railz response data
    // known fields - {name: string, isActive: boolean, currentBalance: number}
    const getAccountMappingDataRailz = (
        chartOfAccounts: Record<any, any>[]
    ) => {
        const accountMappingData = {};

        for (const accountData of chartOfAccounts) {
            const accountName = accountData.name;

            if (
                !accountData.isActive ||
                !accountName ||
                !("currentBalance" in accountData)
            ) {
                continue;
            }

            const currentDate = moment(new Date()).format("MMM YYYY");

            accountMappingData[accountName] = {
                name: accountName,
                data: {
                    [currentDate]: accountData.currentBalance,
                },
            };
        }

        return accountMappingData;
    };

    const setImportData = (
        csvMap: {
            [accountName: string]: { entityId: string; eventId: string };
        },
        eventId: string
    ) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const newCsvMap = {};

        for (const [name, csvData] of Object.entries(csvMap)) {
            newCsvMap[name] = {
                ...csvData,
                eventId: eventId,
            };
        }

        data.csvMap = newCsvMap;
        data.eventId = eventId;
        data.importState = "imported";
        data.lastUpdated = getTodayDateString();
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
        onHandleSubmit(newEntitiesMap);
    };

    const importAccounts = async (mappingData) => {
        if (isEmpty(mappingData)) return;

        const currentCsvMap = entitiesMap[currentEntity].data.csvMap;
        const currentEventId = entitiesMap[currentEntity].data.eventId;

        const newEvent = createNewEvent(initialBalanceObject);
        newEvent.id = uuid.v4();
        newEvent.name = "Imported Railz Accounts";

        let originalEvent;

        const entitiesMapNew = {};
        const entityIdsNew: string[] = [];
        const entities: any[] = [];

        const csvMap = {};
        let editEvent = false;

        if (currentEventId) {
            editEvent = true;
            originalEvent = getEvent(currentEventId);

            newEvent.children = extractEventIds(originalEvent.children);
            newEvent.parents = extractEventIds(originalEvent.parents);

            newEvent.description = originalEvent.description;
            newEvent.id = originalEvent.id;
            newEvent.baseline = originalEvent.baseline;
            newEvent.bypassed = originalEvent.bypassed;
            newEvent.locked = originalEvent.locked;
            newEvent.version = originalEvent.version;
        }

        Object.values(mappingData).forEach((accountRow) => {
            const accountData: AccountRowMapped =
                accountRow as AccountRowMapped;
            const mappedAccountData = accountData.mappedAccountData;

            const newEntity = cloneDeep(
                EntityStructureData?.[initialBalanceObject.constant()]
            );

            let id;

            newEntity.name = accountData.name;

            if (accountData.name in currentCsvMap) {
                const entityData = currentCsvMap[accountData.name];

                if (originalEvent) {
                    newEntity.id = entityData.entityId;
                    id = entityData.entityId;

                    const originalEntity = getRelevantEntities([
                        entityData.entityId,
                    ])[entityData.entityId];

                    newEntity.bypassState = originalEntity.bypassState;
                    newEntity.calculateState = originalEntity.calculateState;
                }
            }

            if (!id) {
                id = uuid.v4();
                newEntity.id = id;
            }

            newEntity.data.accountName = mappedAccountData?.name;
            newEntity.data.accountIds = mappedAccountData?.ids;
            newEntity.data.value = String(Object.values(accountData.data)[0]);
            newEntity.data.lastUpdated = getTodayDateString();

            csvMap[accountData.name] = {
                entityId: id,
            };

            entityIdsNew.push(id);
            entitiesMapNew[id] = newEntity;
            entities.push({
                id: id,
                active: true,
            });
        });

        if (originalEvent) {
            newEvent.mostRecentEntity = newEvent.entities.length - 1;
        }

        // copy over extra entities
        if (
            originalEvent &&
            originalEvent.entities.length > entityIdsNew.length
        ) {
            originalEvent.entities.forEach((entity) => {
                if (
                    !entityIdsNew.includes(entity.id) &&
                    !inCurrentCsv(
                        entity,
                        entitiesMap[currentEntity].data.csvMap
                    )
                ) {
                    entityIdsNew.push(entity.id);
                    entities.push(entity);
                    const oldEntity = getRelevantEntities([entity.id])[
                        entity.id
                    ];
                    entitiesMapNew[entity.id] = oldEntity;
                }
            });
        }

        newEvent.entities = entities;

        const newEventExport = {
            ...newEvent,
            isFilled: originalEvent ? originalEvent.isFilled : true,
            valid: originalEvent ? originalEvent.valid : true,
        };

        const passedCheck = initialBalanceObject.checkInput(entitiesMapNew);

        const importData = {
            event: newEventExport,
            entitiesMap: entitiesMapNew,
            entityIds: entityIdsNew,
            passedCheck: passedCheck,
            csvMap: csvMap,
            editEvent: editEvent,
        };

        await submitEventCreateUpdate(importData);

        setImportData(importData.csvMap, importData.event.id);
    };

    const submitEventCreateUpdate = async (importData) => {
        const event = importData.event;
        const entitiesMap = importData.entitiesMap;
        const entityIds = importData.entityIds;
        const passedCheck = importData.passedCheck;
        const edit = importData.editEvent;

        await dispatch(
            handleSubmitNodesAndEntities(
                (event: EventStructure) =>
                    addNewEvent(
                        event,
                        [eventData.id],
                        eventData.children,
                        true
                    ),
                (event: EventStructure) => updateEvent(event, true),
                event,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
        await new Promise((resolve) => setTimeout(resolve, 200));
    };

    const onHandleSubmit = (newEntitiesMap?: EntitiesSchema) => {
        let finalEventData = { ...eventData };
        const eventNode = getEvent(eventData.id, true);
        if (eventNode)
            finalEventData = {
                ...finalEventData,
                parents: eventNode?.parents,
                children: eventNode?.children,
            };
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                finalEventData,
                newEntitiesMap ?? entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const importedEventStillExists = () => {
        const scenarioEvent = getEvent(
            entitiesMap[currentEntity]?.data?.eventId ?? ""
        );
        if (scenarioEvent) {
            return true;
        }
        return false;
    };

    // Check for deleted entities
    const getNewCsvMap = () => {
        const eventId = entitiesMap[currentEntity].data.eventId;
        const importedEvent = getEvent(eventId);
        const importedEntities = importedEvent?.entities;
        const importedEntityIds: string[] = [];

        importedEntities?.forEach((entity) => {
            importedEntityIds.push(entity.id);
        });

        const csvMap: {
            [accountName: string]: { entityId: string; eventId: string };
        } = entitiesMap[currentEntity].data.csvMap;

        const newCsvMap = {};

        for (const [name, entityData] of Object.entries(csvMap)) {
            if (importedEntityIds.includes(entityData.entityId)) {
                newCsvMap[name] = entityData;
            }
        }

        return newCsvMap;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };

            // check if created events are still there
            const foundImportedEvent = importedEventStillExists();
            const newCsvMap = foundImportedEvent ? getNewCsvMap() : {};
            newEntitiesMap[currentEntity].data.csvMap = newCsvMap;

            if (!foundImportedEvent) {
                newEntitiesMap[currentEntity].data.eventId = "";
            }

            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const passedCheck = accountImportObject.checkInput(entitiesMap);
    return (
        <AccountImportInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            onConnectService={onConnectService}
            refreshImportedAccounts={refreshImportedAccounts}
        />
    );
}

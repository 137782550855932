import type { AnimationConfig } from "reducers/typesSchema/eventsTemplatesSchema";
import { useContext, useMemo } from "react";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { InsertMenuIdContext } from "../../../../contexts/InsertMenuIdContext";
import { HoveredButtonIdContext } from "../../contexts/hoveredButtonIdContext";
import { FocusedLineContext } from "../../../../contexts/focusedLineContext";
import { useMenuTemplate } from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/partials/EventNodeBaseMenu/hooks/useMenuTemplate";

export function useInsertIconBackground(buttonId: string) {
    const templateId = useContext(InsertMenuIdContext);
    const insertMenuTemplate = useMenuTemplate(templateId);
    const focused = useContext(FocusedLineContext);
    const hoveredButtonId = useContext(HoveredButtonIdContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);

    const backgroundDimensions = useMemo(() => {
        const _styles =
            insertMenuTemplate?.canvasStyles?.buttons?.[buttonId]
                ?.buttonDimensions?.styles;
        const _default = _styles?.default;
        const _focused = focused ? _styles?.focused : {};
        const _hovered = hoveredButtonId === buttonId ? _styles?.hovered : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _dimensions = {
            ..._default,
            ..._focused,
            ..._hovered,
            ..._zoomCategory,
        };

        return _dimensions;
    }, [
        buttonId,
        focused,
        hoveredButtonId,
        insertMenuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const backgroundDimensionsAnimationConfig = useMemo(() => {
        const _animationOverride =
            insertMenuTemplate?.canvasStyles?.buttons?.[buttonId]
                ?.buttonDimensions?.animationOverride;
        const _default = _animationOverride?.default;
        const _focused = focused ? _animationOverride?.focused : {};
        const _hovered =
            hoveredButtonId === buttonId ? _animationOverride?.hovered : {};
        const _zoomCategory = _animationOverride?.[zoomCategory];

        const _animationConfig: Partial<AnimationConfig> = {
            ..._default,
            ..._focused,
            ..._hovered,
            ..._zoomCategory,
        };

        return _animationConfig;
    }, [
        buttonId,
        focused,
        hoveredButtonId,
        insertMenuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const backgroundStyles = useMemo(() => {
        const _styles =
            insertMenuTemplate?.canvasStyles?.buttons?.[buttonId]?.buttonColors
                ?.styles;
        const _default = _styles?.default;
        const _focused = focused ? _styles?.focused : {};
        const _hovered = hoveredButtonId === buttonId ? _styles?.hovered : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _finalStyles = {
            ..._default,
            ..._focused,
            ..._hovered,
            ..._zoomCategory,
        };

        return _finalStyles;
    }, [
        buttonId,
        focused,
        hoveredButtonId,
        insertMenuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const backgroundStylesAnimationConfig = useMemo(() => {
        const _animationOverride =
            insertMenuTemplate?.canvasStyles?.buttons?.[buttonId]?.buttonColors
                ?.animationOverride;
        const _default = _animationOverride?.default;
        const _focused = focused ? _animationOverride?.focused : {};
        const _hovered =
            hoveredButtonId === buttonId ? _animationOverride?.hovered : {};
        const _zoomCategory = _animationOverride?.[zoomCategory];

        const _animationConfig: Partial<AnimationConfig> = {
            ..._default,
            ..._focused,
            ..._hovered,
            ..._zoomCategory,
        };

        return _animationConfig;
    }, [
        buttonId,
        focused,
        hoveredButtonId,
        insertMenuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    const iconBackgroundStylesFull = useMemo(
        () => ({ ...backgroundDimensions, ...backgroundStyles }),
        [backgroundDimensions, backgroundStyles]
    );
    const iconBackgroundAnimationConfigFull = useMemo(
        () => ({
            ...backgroundDimensionsAnimationConfig,
            ...backgroundStylesAnimationConfig,
        }),
        [backgroundDimensionsAnimationConfig, backgroundStylesAnimationConfig]
    );

    return {
        iconBackgroundStylesFull,
        iconBackgroundAnimationConfigFull,
    };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { growthObject } from "Components/Registry/Growth";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    EntityName,
    SelectDropDown,
    SelectEntities,
    ModalRowHalf,
    ToggledContainer,
    ToggledContainerHalf,
    Rate,
    ModalRowComponent,
    RadioButton,
    PlusButton,
    DeleteButton,
    BasicHeading,
    MuiCalendar,
} from "../Components";
import Switch from "react-switch";

export const RateSegment = ({
    startDate,
    rate,
    period,
    index,
    handleOnChange,
    onChangeValue,
    deleteSegment,
    type,
}) => {
    let label;
    let labelId;

    switch (type) {
        case "growth":
            label = "Growth Rate";
            labelId = "growthRate";
            break;
        case "cost":
            label = "Estimated CAC";
            labelId = "cost";
            break;
    }

    return (
        <div key={index}>
            <ModalRow>
                <RequiredStar />
                <MuiCalendar
                    views={["year", "month"]}
                    id="rateStartDate"
                    inputFormat="YYYY-MM"
                    value={startDate}
                    onChangeInput={(date, id) => {
                        onChangeValue(id, date, index);
                    }}
                    label="Start Date"
                    required={true}
                />
            </ModalRow>
            <ModalRow twoInputs>
                <ModalRowHalf>
                    <RequiredStar />
                    <Rate
                        label={label}
                        onChangeInput={handleOnChange}
                        value={rate}
                        id={labelId}
                        mainId={index}
                        className="mlsInput"
                        sign={true}
                    />
                </ModalRowHalf>
                <div style={{ width: "10px" }}></div>
                <ModalRowComponent>
                    <RadioButton
                        onClick={() =>
                            onChangeValue("monthly", "period", index)
                        }
                        isActive={period == "monthly"}
                        label="Monthly"
                        value="monthly"
                        id="period"
                    />
                </ModalRowComponent>
                <ModalRowComponent>
                    <RadioButton
                        onClick={() => onChangeValue("yearly", "period", index)}
                        isActive={period == "yearly"}
                        label="Yearly"
                        value="yearly"
                        id="period"
                    />
                </ModalRowComponent>
                <DeleteButton onClick={() => deleteSegment(index)} />
            </ModalRow>
        </div>
    );
};

export default function GrowthInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    onChangeValue,
    addNewSegment,
    deleteSegment,
    toggleCustomEffectPeriod,
    handleDateSelection,
}) {
    const entityData = entitiesMap?.[currentEntity]?.data;

    const possibleCadences = ["annually", "quarterly", "monthly", "one-time"];

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={growthObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={growthObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[growthObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>

                <ToggledContainer>
                    <ToggledContainerHalf
                        id="constant"
                        isSelected={entityData.growthType == "constant"}
                        onClick={(_, id) => onChangeValue(id, "growthType")}
                        title="Constant Growth Rate"
                    />
                    <ToggledContainerHalf
                        id="dynamic"
                        isSelected={entityData.growthType == "dynamic"}
                        onClick={(_, id) => onChangeValue(id, "growthType")}
                        title="Dynamic Growth Rate"
                    />
                </ToggledContainer>

                <ModalRow>
                    <BasicHeading
                        copy="Select Event/Records to Modify"
                        margin="15px 0"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entityData?.selectedEventType ?? ""}
                            options={
                                Object.keys(entityData?.eventsMappedByType) ??
                                []
                            }
                            className="select-dropdown"
                            id="eventType"
                            label="Target Event Type"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            disabled={entityData?.selectedEventType === ""}
                            onChangeInput={handleOnChange}
                            value={entityData?.selectedEventId ?? ""}
                            options={entityData?.availableEvents ?? []}
                            className="select-dropdown"
                            id="event"
                            label="Target Event"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            disabled={entityData?.selectedEventId === ""}
                            onChangeInput={handleOnChange}
                            showAll={true}
                            value={entityData?.selectedEvent ?? {}}
                            nodes={[entityData?.selectedEventId]}
                            className="IncomeSelect"
                            id="entity"
                            label="Target Record"
                            required={true}
                            hideEventName={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            disabled={
                                entityData?.selectedEvent?.eventId !==
                                entityData?.selectedEventId
                            }
                            onChangeInput={handleOnChange}
                            value={entityData?.property ?? ""}
                            options={entityData?.properties ?? []}
                            className="select-dropdown"
                            id="property"
                            label="Target Property"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow>
                    <BasicHeading
                        copy="Select Growth Options"
                        margin="15px 0"
                    />
                </ModalRow>

                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.cadence}
                            options={possibleCadences}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Frequency"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        {entityData.growthType == "constant" && (
                            <>
                                <RequiredStar />
                                <Rate
                                    label="Growth Rate"
                                    onChangeInput={handleOnChange}
                                    value={entityData.constantGrowthRate}
                                    id="constantGrowthRate"
                                    className="mlsInput"
                                    sign={true}
                                />
                            </>
                        )}

                        {entityData.growthType == "dynamic" && (
                            <Rate
                                label="Max Growth"
                                onChangeInput={handleOnChange}
                                value={entityData.maxGrowthRate}
                                id="maxGrowthRate"
                                className="mlsInput"
                                sign={true}
                            />
                        )}
                    </ModalRowHalf>
                </ModalRow>
                {entityData.growthType == "dynamic" && (
                    <>
                        <ModalRow>
                            <div className="entity-section-label">
                                Date/Growth Rate Inputs
                            </div>
                        </ModalRow>
                        <ModalRow>
                            <ModalRowComponent>
                                <RadioButton
                                    onClick={onChangeValue}
                                    isActive={entityData.interpolate}
                                    label="Interpolate Growth Rate"
                                    value={true}
                                    id="interpolate"
                                />
                            </ModalRowComponent>
                            <ModalRowComponent>
                                <RadioButton
                                    onClick={onChangeValue}
                                    isActive={!entityData.interpolate}
                                    label="Stair Step Growth Rate"
                                    value={false}
                                    id="interpolate"
                                />
                            </ModalRowComponent>
                        </ModalRow>
                        {entityData.growthSegments.map((segment, i) => {
                            return (
                                <RateSegment
                                    key={i}
                                    startDate={segment.startDate}
                                    rate={segment.rateDisplay}
                                    period={segment.period}
                                    index={i}
                                    handleOnChange={handleOnChange}
                                    onChangeValue={onChangeValue}
                                    deleteSegment={deleteSegment}
                                    type="growth"
                                />
                            );
                        })}
                        <ModalRow>
                            <PlusButton
                                onClick={addNewSegment}
                                label="Add New Growth Rate Segment"
                            />
                        </ModalRow>
                    </>
                )}
                <ModalRow>
                    <ModalRowHalf>
                        <Switch
                            checked={entityData?.customEffectPeriod}
                            className="InflationToggle"
                            onChange={toggleCustomEffectPeriod}
                            height={20}
                            width={40}
                        />
                        <div className="InflationText">
                            Custom effect period
                        </div>
                    </ModalRowHalf>
                </ModalRow>
                {entityData?.customEffectPeriod && (
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <MuiCalendar
                                views={["year", "month"]}
                                inputFormat="YYYY-MM"
                                id="startDate"
                                value={
                                    entitiesMap?.[currentEntity]?.startDate ===
                                    ""
                                        ? null
                                        : entitiesMap?.[currentEntity]
                                              ?.startDate
                                }
                                required={entityData?.customEffectPeriod}
                                onChangeInput={handleDateSelection}
                                label="Start Date"
                            />
                        </ModalRowHalf>
                        <ModalRowHalf>
                            <MuiCalendar
                                views={["year", "month"]}
                                inputFormat="YYYY-MM"
                                id="endDate"
                                value={
                                    entitiesMap?.[currentEntity]?.endDate === ""
                                        ? null
                                        : entitiesMap?.[currentEntity]?.endDate
                                }
                                onChangeInput={handleDateSelection}
                                label="End Date"
                                required={false}
                                helperText={"An end date is optional"}
                            />
                        </ModalRowHalf>
                    </ModalRow>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import BudgetCardAlt from "../Card/budgetCardAlt";
import BudgetInputAlt from "../InputContainer/BudgetAlt";

// Import Connection Image (if exists)
import BudgetConnectionImage from "../../Assets/_optionDescriptionIcons/Budget, Home Maintenance, Short Term Rental.png";

// Import Coloured PNGs
import budgetBlack from "../../Assets/_eventNodeTypes/budget-black.png";
import budgetBlue from "../../Assets/_eventNodeTypes/budget-blue.png";
import budgetWhite from "../../Assets/_eventNodeTypes/budget-white.png";
import budgetGrey from "../../Assets/_eventNodeTypes/budget-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - budgetObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - budgetObject.checkInput.call(budgetObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const budgetObject = {
    constant: function () {
        return Budget_Constant();
    },

    checkInput: function (state) {
        return Budget_CheckInput(state);
    },

    svg: function () {
        return Budget_Svg();
    },

    eventNodeMenu: function (focus) {
        return Budget_EventNodeMenu(focus);
    },

    indexText: function () {
        return Budget_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Budget_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Budget_OptionDescription();
    },

    colouredPngs: function () {
        return Budget_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Budget_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Budget_CheckInput = (state) => {
    let passed = false;
    state.budgetList.map((budget) => {
        if (budget.selected) {
            passed = true;
        }
        return budget;
    });
    if (passed && state.name) {
        return true;
    } else {
        return false;
    }
};

// EFFECTS: Returns constant for event
const Budget_Constant = () => {
    return "Budget";
};

// EFFECTS: Returns black png / svg for event
const Budget_Svg = () => {
    return budgetBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Budget_EventNodeMenu = (focus) => {
    return <BudgetCardAlt data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Budget_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Budget calculations.";
};

// EFFECTS: Returns all icons for event ??
const Budget_Icons = () => {
    let pngs = new Map([
        ["black", budgetBlack],
        ["blue", budgetBlue],
        ["white", budgetWhite],
        ["grey", budgetGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Budget_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Budget_InputComponentInfoPassing = (minProps) => {
    return <BudgetInputAlt {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, budgets for event ??
const Budget_OptionDescription = () => {
    const tags = ["Option"];
    const type = budgetObject.constant();
    const description =
        "A Budget is a quintessential part of having a healthy financial future. Whether it be keeping track of your monthly subscriptions, or allocating a portion of your paycheck to do the things that you like, a Budget can capture it all.";
    const usedForText =
        "Use the Budget Node to track reoccuring expenses on a monthly basis and maintain a health cashflow.";
    const connection =
        "The Budget Node is a standalone event but pairs nicely with income to show your expected free cash flow.";
    const connectionImage = BudgetConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/b/budget.asp#personal-budgets";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Budget_SideModalImage = () => {
    return sideImage;
};

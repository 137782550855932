// Default Imports
import React from "react";

// Import DebtRepaymente Card and DebtRepaymente Input
import DebtRepaymentCard from "../Card/debtRepaymentCard";
import DebtRepaymentExperimental from "../InputContainer/DebtRepaymentExperimental/DebtRepaymentExperimental";

// Import Connection Image
import debtRepaymentConnectionImage from "Assets/_optionDescriptionIcons/Debt, Debt Repayment white.jpg";

// Import Coloured PNGs
import debtRepaymentBlack from "../../Assets/_eventNodeTypes/debt-repayment-black-updated.svg";
import debtRepaymentBlue from "../../Assets/_eventNodeTypes/debtRepayment-blue.png";
import debtRepaymentWhite from "../../Assets/_eventNodeTypes/debtRepayment-white.png";
import debtRepaymentGrey from "../../Assets/_eventNodeTypes/debtRepayment-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - debtRepaymentObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debtRepaymentObject.checkInput.call(debtRepaymentObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for DebtRepaymente
export const debtRepaymentObject = {
    constant: function () {
        return DebtRepayment_Constant();
    },

    name: function () {
        return "Debt Repayment";
    },

    checkInput: function (state) {
        return DebtRepayment_CheckInput(state);
    },

    svg: function () {
        return DebtRepayment_Svg();
    },

    eventNodeMenu: function (focus) {
        return DebtRepayment_EventNodeMenu(focus);
    },

    indexText: function () {
        return DebtRepayment_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return DebtRepayment_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return DebtRepayment_OptionDescription();
    },

    colouredPngs: function () {
        return DebtRepayment_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return DebtRepayment_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return DebtRepayment_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for DebtRepaymente

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const DebtRepayment_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (entity.data.fixedVsRemaining === "fixed") {
            if (!entity.data.value) return false;
            // if (!entity.data.debtEventId) return false;
            if (!entity.data.accountName) return false;
            if (!entity.cadence) return false;
            if (!entity.startDate) return false;
        } else if (entity.data.fixedVsRemaining === "remaining") {
            // if (!entity.data.debtEventId) return false;
            if (!entity.data.paymentDate) return false;
            if (!entity.data.accountName) return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const DebtRepayment_Constant = () => {
    return "db587bfa-03fc-4ce8-aaf1-4c79a196a1d7";
};

// EFFECTS: Returns svg for event
const DebtRepayment_Svg = () => {
    return debtRepaymentBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const DebtRepayment_EventNodeMenu = (focus) => {
    return <DebtRepaymentCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const DebtRepayment_IndexText = () => {
    return "Select an input field to learn more about your debt repayment calculations.";
};

// EFFECTS: Returns all icons for event ??
const DebtRepayment_Icons = () => {
    let pngs = new Map([
        ["black", debtRepaymentBlack],
        ["blue", debtRepaymentBlue],
        ["white", debtRepaymentWhite],
        ["grey", debtRepaymentGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const DebtRepayment_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const DebtRepayment_InputComponentInfoPassing = (minProps) => {
    return <DebtRepaymentExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const DebtRepayment_OptionDescription = () => {
    const tags = ["Option"];
    const type = debtRepaymentObject.name();
    const description =
        "Debt repayment fulfills an obligation to pay an existing or future projected creditor. It can be a recurring payment or lump sum payments. The Debt Repayment Event requires an upstream Debt Event for the payment to be tracked against.";
    const usedForText =
        "In combination with the Debt Event, this Event can be used to model common debt calculations for a Credit Card or a Line of Credit. For traditional loans, use the Loan Event.";
    const connection =
        "A Debt Repayment Event should be coupled with a Debt Event.";
    const connectionImage = debtRepaymentConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/r/repayment.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const DebtRepayment_SideModalImage = () => {
    return sideImage;
};

const DebtRepayment_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import React, { Component } from "react";
import "./Card.css";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";

class projectCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    getItemData = (id, itemType) => {
        if (itemType === "events") {
            const event = getEvent(id);
            return event ?? {};
        } else if (itemType === "entities") {
            const entity = getRelevantEntities([id])[id];
            return entity ?? {};
        }
    };

    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }

        const project = this.state.allEntities[this.state.currentIndex];
        const items = Object.values(project.data.items);
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Name:</div>
                    <div className="cardValue">{project.name}</div>
                </div>
                {items?.length > 0 && (
                    <div className="Row">
                        <div className="ExpenseLabel">Item Name:</div>
                        <div className="ExpenseLabel">Offset:</div>
                        <div className="ExpenseLabel">Multiplier:</div>
                    </div>
                )}
                {items?.length > 0 &&
                    items.map((item) => {
                        const additionalItemData = this.getItemData(
                            item.selectedItem,
                            item.itemType
                        );
                        return (
                            <div className="Row" key={item.selectedItem}>
                                <div className="ExpenseLabel">
                                    {additionalItemData.name}
                                </div>
                                <div className="ExpenseLabel">{`${item.offset} ${item.offsetMode}`}</div>
                                <div className="ExpenseLabel">{`${item.multiplier}x`}</div>
                            </div>
                        );
                    })}
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() => this.handleArrowClick("left")}
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentIndex + 1} of{" "}
                        {this.state.allEntities.length}
                    </span>
                    <div
                        className={
                            this.state.currentIndex ===
                            this.state.allEntities.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() => this.handleArrowClick("right")}
                    ></div>
                </div>
            </div>
        );
    }
}

export default projectCard;

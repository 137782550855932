import React, { Component } from "react";
import "./InputContainer.css";
import Calendar from "../Calendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import {
    Description,
    SelectDropDown,
    Url,
    InputButtons,
    Period,
    ModalRow,
    ModalRowHalf,
    EventInputModal,
    EventInputs,
    EventHeader,
    RequiredStar,
    ToggledContainer,
    ToggledContainerHalf,
    StarsRating,
    InflationInput,
    B,
    Value,
} from "./Components";
import { getDefaultName } from "../../helpers";
import SingleCalendar from "../Calendar/singleCalendar";
import { Text } from "react-konva";
import moment from "moment";
import { condoFeeObject } from "../Registry/CondoFee";

const options = ["annually", "monthly", "semi-monthly", "bi-weekly", "weekly"];
const amortizationOptions = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "daily",
];

class CondoFeeInput extends Component {
    constructor(props) {
        super(props);

        if (props.edit) {
            this.state = { ...props.editData };
        } else {
            this.state = CondoFeeInput.getDefaultState(
                props.inflationRate,
                getDefaultName(condoFeeObject.constant(), props),
                props.baseline
            );
        }
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            isFilled: false,
            valid: false,
            cost: "0",
            url: "",
            start: null,
            end: null,
            rating: 0,
            name: name,
            description: "",
            cadence: "",
            showSettings: false,
            type: condoFeeObject.constant(),
            value: null,
            tag: `@${condoFeeObject.constant()}`,
            onHoverStar: null,
            applyInflation: false,
            inflationRate: inflationRate,
            baseline: isInBaseline,
            expenseType: "ongoing",
        };
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;
        switch (id) {
            case "value":
                this.setState({
                    cost: value,
                    value: -parseInt(value),
                });
                break;
            case "amortizedValue":
                let paymentAmount = value / this.state.numPayments;
                this.setState({
                    cost: value,
                    value: -paymentAmount.toFixed(2),
                });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "cadence":
                this.onHandleCadence(value);
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "amortizationPeriod":
                this.onHandleAmortization(id, Math.round(value));
                break;
            case "inflationRate":
                this.setState({ inflationRate: value });
                break;
            default:
        }
    };

    toggleInflation = () => {
        this.setState({ applyInflation: !this.state.applyInflation });
    };

    getCadenceUnit = (cadence, numUnit = 0) => {
        const num = Number(numUnit);
        switch (cadence) {
            case "annually":
                return num !== 1 ? "years" : "year";
            case "monthly":
            case "semi-monthly":
                return num !== 1 ? "months" : "month";
            case "bi-weekly":
            case "weekly":
                return num !== 1 ? "weeks" : "week";
            case "daily":
                return num !== 1 ? "days" : "day";
            // case "one-time":
            default:
                return "";
        }
    };

    onHandleCadence = (newValue) => {
        this.setState({ cadence: newValue });
        if (this.state.lastChangedAmortizationField === "amortizationPeriod") {
            this.recalculateNumPayments(
                newValue,
                this.state.amortizationPeriod
            );
        } else if (this.state.lastChangedAmortizationField === "numPayments") {
            this.recalculateAmortizationPeriod(
                newValue,
                this.state.numPayments
            );
        }
    };

    onHandleAmortization = (id, value) => {
        const { cadence } = this.state;
        if (id === "amortizationPeriod") {
            this.setState({
                amortizationPeriod: value,
                lastChangedAmortizationField: id,
            });
            if (cadence) this.recalculateNumPayments(cadence, value);
        } else if (id === "numPayments") {
            this.setState({
                numPayments: value,
                lastChangedAmortizationField: id,
            });
            if (cadence) this.recalculateAmortizationPeriod(cadence, value);
        }
    };

    recalculateAmortizationPeriod = (cadence, numPayments) => {
        if (!cadence || !numPayments) {
            this.setState({ amortizationPeriod: "" });
        } else {
            let amortizationPeriod;
            switch (cadence) {
                case "annually":
                case "monthly":
                case "weekly":
                case "daily":
                    // case "one-time":
                    amortizationPeriod = numPayments;
                    break;
                case "semi-monthly":
                    amortizationPeriod = Math.round(numPayments * 2) / 4; //round to nearest 0.5
                    break;
                case "bi-weekly":
                    amortizationPeriod = 2 * numPayments;
                    break;
                default:
            }
            this.calculateAmortizedEndDate(
                this.state.start,
                amortizationPeriod,
                cadence
            );
        }
    };

    recalculateNumPayments = (cadence, amortizationPeriod) => {
        if (!cadence || !amortizationPeriod) {
            this.setState({ numPayments: "" });
        } else {
            let numPayments;
            switch (cadence) {
                case "annually":
                case "monthly":
                case "weekly":
                case "daily":
                    // case "one-time":
                    numPayments = amortizationPeriod;
                    break;
                case "semi-monthly":
                    numPayments = amortizationPeriod * 2;
                    break;
                case "bi-weekly":
                    numPayments = Math.floor(amortizationPeriod / 2);
                    break;
                default:
            }
            this.calculateAmortizedEndDate(
                this.state.start,
                amortizationPeriod,
                cadence
            );
            let paymentAmount = this.state.cost / numPayments;
            this.setState({ numPayments, value: -paymentAmount.toFixed(2) });
        }
    };

    calculateAmortizedEndDate = (start, amortizationPeriod, cadence) => {
        if (!start || !amortizationPeriod || !cadence) {
            this.setState({ amortizedEnd: null });
        } else {
            const startDate = moment(start);
            let endDate = moment(startDate);
            switch (cadence) {
                case "annually":
                    endDate = endDate.add(amortizationPeriod - 1, "years");
                    break;
                case "monthly":
                    endDate = endDate.add(amortizationPeriod - 1, "months");
                    break;
                case "semi-monthly":
                    endDate = endDate.add(amortizationPeriod - 1, "months");
                    endDate = endDate.add(16, "days");
                    break;
                case "weekly":
                    endDate = endDate.add(amortizationPeriod - 1, "weeks");
                    break;
                case "bi-weekly":
                    endDate = endDate.add(amortizationPeriod - 2, "weeks");
                    break;
                case "daily":
                    endDate = endDate.add(amortizationPeriod - 1, "days");
                    break;
                default:
                    endDate = null;
            }
            this.setState({ amortizedEnd: endDate.format("YYYY-MM-DD") });
        }
    };

    onHandleDate = (startDate, endDate) => {
        if (startDate && endDate && endDate < startDate) {
            this.setState({
                start: endDate,
                startDate: endDate,
                endDate: startDate,
                end: startDate,
            });
        } else {
            this.setState({
                start: startDate,
                end: endDate,
                startDate,
                endDate,
            });
        }
    };

    onHandleAmortizedDate = (startDate) => {
        this.setState({ start: startDate });
        this.calculateAmortizedEndDate(
            startDate,
            this.state.amortizationPeriod,
            this.state.cadence
        );
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = condoFeeObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    handleChangeExpenseType = (e) => {
        const newType = e.target.id;
        let newValue;
        if (newType === "ongoing") {
            newValue = Number(this.state.cost);
        } else {
            let paymentAmount =
                Number(this.state.cost) / this.state.numPayments;
            newValue = -paymentAmount.toFixed(2);
        }

        this.setState({ expenseType: newType, value: newValue });
    };

    getOngoingExpensePanel = () => {
        const { cadence, cost, url, start, end } = this.state;
        return (
            <>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={cadence}
                            options={options}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Payment Frequency"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Value
                            label="Cost"
                            onChangeInput={this.onChangeInput}
                            value={cost}
                            id="value"
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <Url
                        value={url}
                        className="UrlLink"
                        onChangeInput={this.onChangeInput}
                        id="url"
                        label="URL"
                    />
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <Calendar
                        onHandleDate={this.onHandleDate}
                        startDate={start}
                        endDate={end || null}
                        cadence={cadence}
                    />
                </ModalRow>
            </>
        );
    };

    infoBox = (numPayments, cadenceUnit, amortizationPeriod) => {
        let paymentAmount = this.state.value;
        if (!isFinite(paymentAmount) || isNaN(paymentAmount)) paymentAmount = 0;
        return (
            <ModalRow>
                <div className="loanPaymentsContainer">
                    <div className="paymentsAmountContainer">
                        <Text>
                            You will make <B>{` ${numPayments || "0"} `}</B>
                            payments of
                            <B>{` $${(-1 * paymentAmount).toFixed(2)} `}</B>
                            over
                            <B>
                                {` ${amortizationPeriod || "0"}`} {cadenceUnit}.
                            </B>
                        </Text>
                    </div>
                </div>
            </ModalRow>
        );
    };

    getAmortizedExpensePanel = () => {
        const { cadence, cost, numPayments, amortizationPeriod } = this.state;
        const cadenceUnit = this.getCadenceUnit(cadence, amortizationPeriod);

        return (
            <>
                <ModalRow>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Value
                            label="Cost"
                            onChangeInput={this.onChangeInput}
                            value={cost}
                            id="amortizedValue"
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <div className="ExpenseAmountContainer">
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={cadence}
                            options={amortizationOptions}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Payment Frequency"
                        />
                    </div>
                    <div className="ExpenseAmountContainer">
                        <RequiredStar />
                        <Period
                            label="Amortization Period"
                            onChangeInput={this.onChangeInput}
                            value={amortizationPeriod}
                            id="amortizationPeriod"
                            className="mlsInput"
                            cadenceUnit={cadenceUnit}
                        />
                    </div>
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <SingleCalendar
                        onHandleDate={this.onHandleAmortizedDate}
                        date={this.state.start}
                        title="Start Date"
                    />
                </ModalRow>
                {this.infoBox(numPayments, cadenceUnit, amortizationPeriod)}
            </>
        );
    };

    render() {
        const passedCheck = condoFeeObject.checkInput(this.state);
        const { edit } = this.props;

        const {
            name,
            description,
            rating,
            onHoverStar,
            expenseType,
            applyInflation,
            inflationRate,
        } = this.state;
        const isOngoing = expenseType === "ongoing";
        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={condoFeeObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={condoFeeObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ToggledContainer>
                        <ToggledContainerHalf
                            id="ongoing"
                            isSelected={isOngoing}
                            onClick={this.handleChangeExpenseType}
                            title="Ongoing Expense"
                        />
                        <ToggledContainerHalf
                            id="amortized"
                            isSelected={!isOngoing}
                            onClick={this.handleChangeExpenseType}
                            title="Amortized Expense"
                        />
                    </ToggledContainer>
                    {isOngoing
                        ? this.getOngoingExpensePanel()
                        : this.getAmortizedExpensePanel()}
                    <ModalRow>
                        <StarsRating
                            onHoverStar={onHoverStar}
                            rating={rating}
                            onChangeInput={this.onChangeInput}
                            onHandleStarHover={this.onHandleStarHover}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ModalRow>
                    {isOngoing && (
                        <ModalRow>
                            <InflationInput
                                applyInflation={applyInflation}
                                inflationRate={inflationRate}
                                onChangeInput={this.onChangeInput}
                                toggleInflation={this.toggleInflation}
                            />
                        </ModalRow>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { },
                            passedCheck
                        )
                    }
                    isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                    isLibraryEvent={this.state.isLibraryEvent}
                    addLibraryEventToScenario={() => {
                        this.props.addLibraryEventToScenario(this.state);
                    }}
                    divorceLibraryEvent={() => {
                        this.props.divorceFromLibraryEvent(
                            this.state,
                            this.props.updateValues
                        );
                    }}
                />
            </EventInputModal>
        );
    }
}

export default CondoFeeInput;

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import MeInput from "../InputContainer/MeInput";

// Import Connection Image (if exists)
import MeConnectionImage from "../../Assets/_optionDescriptionIcons/Me, Bank, Income.png";

// Import Coloured PNGs
import meBlack from "../../Assets/_eventNodeTypes/person-black.png";
import meBlue from "../../Assets/_eventNodeTypes/person-blue.png";
import meWhite from "../../Assets/_eventNodeTypes/person-white.png";
import meGrey from "../../Assets/_eventNodeTypes/person-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - meObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - meObject.checkInput.call(meObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const meObject = {
    constant: function () {
        return Me_Constant();
    },

    name: function () {
        return "Me";
    },

    checkInput: function (state) {
        return Me_CheckInput(state);
    },

    svg: function () {
        return Me_Svg();
    },

    eventNodeMenu: function (focus) {
        return Me_EventNodeMenu(focus);
    },

    indexText: function () {
        return Me_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Me_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Me_OptionDescription();
    },

    colouredPngs: function () {
        return Me_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Me_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Me_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.country) return false;
        if (!entity.data.state) return false;
        if (!entity.data.birthMonth) return false;
        if (!entity.data.birthYear) return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Me_Constant = () => {
    return "Me";
};

// EFFECTS: Returns black png / svg for event
const Me_Svg = () => {
    return meBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Me_EventNodeMenu = () => {
    return <></>;
};

// EFFECTS: Returns the Index Text for event
const Me_IndexText = () => {
    return "";
};

// EFFECTS: Returns all icons for event ??
const Me_Icons = () => {
    let pngs = new Map([
        ["black", meBlack],
        ["blue", meBlue],
        ["white", meWhite],
        ["grey", meGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Me_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Me_InputComponentInfoPassing = (minProps) => {
    return <MeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, mes for event ??
const Me_OptionDescription = () => {
    const tags = ["Option"];
    const type = meObject.constant();
    const description = "";
    const usedForText = "";
    const connection = "";
    const connectionImage = MeConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Me_SideModalImage = () => {
    return sideImage;
};

// *** TEMPORARY CODE - WILL BE DEPRECATED SOON ***
//
// -- This function returns a "context.Mortgage" object for each Mortgage Event in the thread (if any).
// -- The intention is that this object's structure is based on
// what "handleContextPassing" function expects (in MainContainer.js).
// -- Context object contains: price & startDate of associated House Event upstream,
// downpayment (number), isDownpayment (boolean) & downpaymentRate (number) of Mortgage Event in question,
// and {id: value} of the associated Renewal/Refinance Event downstream (if any)

interface Thread {
    name: string;
    nodes: string[];
    signature: string;
}

export const generateContextForMortgage = (thread: Thread, nodes: any[]) => {
    const nodesInThread: any[] = [];

    const newContext = {
        Mortgage: {},
    };

    // Grabbing only the Nodes/Events within the thread, ignoring the rest
    for (let i = 0; i < thread.nodes.length; i++) {
        if (nodes[thread.nodes[i]]) nodesInThread.push(nodes[thread.nodes[i]]);
    }

    // Everytime we encounter a "Mortgage" Event, we need to find
    // the associated House & Renewal Refinance Events.
    for (let i = 0; i < nodesInThread.length; i++) {
        if (nodesInThread[i].nodeType === "Mortgage") {
            let counter = 1;

            // First loop - searching for the closest House Event upstream.
            let associatedHouseEvent;
            do {
                if (nodesInThread[i - counter].nodeType !== "House") {
                    counter++;
                } else {
                    associatedHouseEvent = nodesInThread[i - counter];
                    counter = 1;
                }
            } while (!associatedHouseEvent && i - counter >= 0);

            // Second loop - searching for the closest Renewal Refinance Event downstream, grabbing just one.
            const associatedRenewalEvent = {};
            if (i + counter < nodesInThread.length) {
                do {
                    if (
                        nodesInThread[i + counter].nodeType !==
                        "Renewal Refinance"
                    ) {
                        if (i + counter < nodesInThread.length) counter++;
                    } else {
                        associatedRenewalEvent[nodesInThread[i + counter].id] =
                            nodesInThread[i + counter].value;
                    }
                } while (
                    Object.keys(associatedRenewalEvent).length < 1 &&
                    i + counter < nodesInThread.length
                );
            }

            // We only run this chunk of code IF we have an associatedHouseEvent
            // Otherwise we skip it all & return an empty 'context.Mortgage' object
            if (associatedHouseEvent) {
                const initialPayment = getMortgagePayments(
                    parseInt(associatedHouseEvent.price),
                    nodesInThread[i].downpaymentData.value,
                    nodesInThread[i].mortgageData.rate,
                    nodesInThread[i].mortgageData.amortizationPeriod,
                    nodesInThread[i].mortgageData.cadence
                );

                // Generating the "context" object the "handleContextPassing" function expects (in MainContainer.js).
                newContext.Mortgage = {
                    ...newContext.Mortgage,
                    [nodesInThread[i].id]: {
                        data: {
                            [thread.signature]: {
                                price: parseInt(associatedHouseEvent.price),
                                startDate:
                                    associatedHouseEvent["first payment"],
                                isDownpayment: nodesInThread[i].isDownpayment,
                                downpaymentRate:
                                    nodesInThread[i].downpaymentData
                                        .downpaymentRate,
                                downpayment:
                                    nodesInThread[i].downpaymentData.value,
                                initialPayment,
                                renewals: associatedRenewalEvent,
                            },
                        },
                    },
                };
            }
        }
    }

    return newContext;
};

// TEMPORARY CODE - Helper function for the above function to get dynamic
// Mortgage payments depending on the selected thread.
const getMortgagePayments = (
    price,
    downpayment,
    mortgageRate,
    amortizationPeriod,
    cadence
) => {
    // Canadian fixed rate mortgages compound semi-annually
    let payments;
    const principal = price - downpayment;
    const rate = mortgageRate * 0.01;
    const amortization = amortizationPeriod.toString().match(/\d+/)[0];
    const N = amortization * 12;
    const monthlyRate = (1 + rate / 2) ** (2 / 12) - 1;
    const monthlyPayment =
        (principal * monthlyRate * (1 + monthlyRate) ** N) /
        ((1 + monthlyRate) ** N - 1);
    switch (cadence) {
        case "annually":
            payments = monthlyPayment * 12;
            break;
        case "quarterly":
            payments = monthlyPayment * 3;
            break;
        case "monthly":
            payments = monthlyPayment;
            break;
        case "semi-monthly":
            payments = monthlyPayment / 2;
            break;
        case "bi-weekly":
            payments = (monthlyPayment * 12) / 26;
            break;
        case "weekly":
            payments = (monthlyPayment * 12) / 52;
            break;
        case "one-time":
            payments = monthlyPayment * 12 * amortization;
            break;
        default:
    }
    return payments.toFixed(2);
};

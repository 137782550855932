import { createContext } from "react";

export const EventNodeActiveContext = createContext<boolean>(false);
EventNodeActiveContext.displayName = "EventNodeActiveContext";

export const EventNodeInSelectedThreadContext = createContext<boolean>(false);
EventNodeInSelectedThreadContext.displayName =
    "EventNodeInSelectedThreadContext";

export const EventNodeFocusedContext = createContext<boolean>(false);
EventNodeFocusedContext.displayName = "EventNodeFocusedContext";

export const EventNodeSelectedContext = createContext<boolean>(false);
EventNodeSelectedContext.displayName = "EventNodeSelectedContext";

export const EventNodeLockedContext = createContext<boolean>(false);
EventNodeLockedContext.displayName = "EventNodeLockedContext";

export const EventNodeLinkedContext = createContext<boolean>(false);
EventNodeLinkedContext.displayName = "EventNodeLinkedContext";

export const EventNodeBypassedEntityContext = createContext<boolean>(false);
EventNodeBypassedEntityContext.displayName = "EventNodeBypassedEntityContext";

export const EventNodeBypassedContext = createContext<boolean>(false);
EventNodeBypassedContext.displayName = "EventNodeBypassedContext";

export const EventNodeErrorContext = createContext<boolean>(false);
EventNodeErrorContext.displayName = "EventNodeErrorContext";

export const EventNodeConnectableContext = createContext<boolean>(false);
EventNodeConnectableContext.displayName = "EventNodeConnectableContext";

export const HasCopiedEventContext = createContext<boolean>(false);
HasCopiedEventContext.displayName = "HasCopiedEventContext";

export const HasSelectedConnectionContext = createContext<boolean>(false);
HasSelectedConnectionContext.displayName = "HasSelectedConnectionContext";

export const HasNoChildrenContext = createContext<boolean>(false);
HasNoChildrenContext.displayName = "HasNoChildrenContext";

export const HasMultipleChildrenContext = createContext<boolean>(false);
HasMultipleChildrenContext.displayName = "HasMultipleChildrenContext";

export const EventTypeIsDecisionContext = createContext<boolean>(false);
EventTypeIsDecisionContext.displayName = "EventTypeIsDecisionContext";

export const EventTypeIsContainerContext = createContext<boolean>(false);
EventTypeIsContainerContext.displayName = "EventTypeIsContainerContext";

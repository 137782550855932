// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    SelectDropDown,
} from "../Components";
import { containerObject } from "Components/Registry/Container";
import { containerInputsHandler } from "../OnInputChangeHandlers/containerInputsHandler";
import Switch from "react-switch";
import { useMemo } from "react";

const ContainerInputView = ({
    eventData,
    entitiesMap,
    currentEntity,
    setEntitiesMap,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    selectedEvents,
}) => {
    const toggleExpanded = (toggleState) => {
        const newEntitiesMap = containerInputsHandler(
            toggleState,
            "expanded",
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleChange = (clickEvent, fieldKey) => {
        const value = clickEvent.target.value;
        const newEntitiesMap = containerInputsHandler(
            value,
            fieldKey,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const selectableEventOptions = useMemo(() => {
        const selectedEventsArray = Object.values(selectedEvents);
        if (selectedEventsArray?.length > 0) {
            const selectableEventOptions = [];
            selectedEventsArray?.forEach((event) => {
                selectableEventOptions.push({
                    value: event.id,
                    displayName: event.name,
                });
            });
            return selectableEventOptions;
        }
        return [];
    }, [selectedEvents]);

    const { headNode, tailNode, containedEvents } =
        entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={containerObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={containerObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
                {edit && (
                    <>
                        <ModalRow>
                            Selected head node:{" "}
                            {containedEvents?.[headNode]?.name ?? ""}
                        </ModalRow>
                        <ModalRow>
                            Selected tail node:{" "}
                            {containedEvents?.[tailNode]?.name ?? ""}
                        </ModalRow>
                    </>
                )}
                {!edit && selectableEventOptions.length > 0 && (
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <SelectDropDown
                                onChangeInput={(e) =>
                                    handleChange(e, "headNode")
                                }
                                value={headNode}
                                options={selectableEventOptions}
                                className="select-dropdown"
                                id="headNode"
                                label="Select Head Node"
                                required={true}
                                disabled={false}
                            />
                        </ModalRowHalf>
                        <ModalRowHalf>
                            <SelectDropDown
                                onChangeInput={(e) =>
                                    handleChange(e, "tailNode")
                                }
                                value={tailNode}
                                options={selectableEventOptions}
                                className="select-dropdown"
                                id="tailNode"
                                label="Select Tail Node"
                                required={true}
                                disabled={!headNode}
                            />
                        </ModalRowHalf>
                    </ModalRow>
                )}
                <ModalRow>
                    <Switch
                        id="expanded"
                        checked={
                            entitiesMap?.[currentEntity]?.data?.expanded ??
                            false
                        }
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={toggleExpanded}
                    />
                    <div className="InflationText">Expand Container</div>
                </ModalRow>
            </EventDetails>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default ContainerInputView;

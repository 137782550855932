// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { B, ModalRow } from "Components/InputContainer/Components";
import { Text } from "react-konva";

const PaymentText = ({
    numPayments,
    paymentAmount,
    amortizationPeriod,
    cadenceUnit,
}) => {
    return (
        <Text>
            You will make <B>{` ${numPayments || "0"} `}</B>
            payments of
            <B>{` $${(1 * paymentAmount).toFixed(2)} `}</B>
            over
            <B>
                {` ${amortizationPeriod || "0"}`} {cadenceUnit}.
            </B>
        </Text>
    );
};

const UnitlessText = ({
    numPayments,
    paymentAmount,
    amortizationPeriod,
    cadenceUnit,
    verb,
}) => {
    return (
        <Text>
            You will {` ${verb} `} by <B>{paymentAmount}</B>
            <B>{` ${numPayments}x `}</B> times over
            <B>
                {` ${amortizationPeriod || "0"} `} {cadenceUnit}.
            </B>
        </Text>
    );
};

export default function InfoBox({
    entitiesMap,
    currentEntity,
    numPayments,
    cadenceUnit,
    amortizationPeriod,
    unitless,
    increase,
}) {
    let paymentAmount =
        entitiesMap?.[currentEntity]?.data?.calulatedAmortizedValue;
    if (!isFinite(paymentAmount) || isNaN(paymentAmount)) paymentAmount = 0;
    return (
        <ModalRow>
            <div className="loanPaymentsContainer">
                <div className="paymentsAmountContainer">
                    {unitless ? (
                        <UnitlessText
                            numPayments={numPayments}
                            paymentAmount={paymentAmount}
                            amortizationPeriod={amortizationPeriod}
                            cadenceUnit={cadenceUnit}
                            verb={increase ? "increase" : "decrease"}
                        />
                    ) : (
                        <PaymentText
                            numPayments={numPayments}
                            paymentAmount={paymentAmount}
                            amortizationPeriod={amortizationPeriod}
                            cadenceUnit={cadenceUnit}
                        />
                    )}
                </div>
            </div>
        </ModalRow>
    );
}

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import DecisionInput from "../InputContainer/DecisionInput";

// Import Connection Image (if exists)
import DecisionConnectionImage from "Assets/_optionDescriptionIcons/Decision white.jpg";

// Import Coloured PNGs
import decisionBlack from "../../Assets/_eventNodeTypes/decision-black-updated.svg";
import decisionBlue from "../../Assets/_eventNodeTypes/decision-blue.png";
import decisionWhite from "../../Assets/_eventNodeTypes/decision-white.png";
import decisionGrey from "../../Assets/_eventNodeTypes/decision-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - decisionObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - decisionObject.checkInput.call(decisionObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const decisionObject = {
    constant: function () {
        return Decision_Constant();
    },

    name: function () {
        return "Decision";
    },

    checkInput: function (state) {
        return Decision_CheckInput(state);
    },

    svg: function () {
        return Decision_Svg();
    },

    eventNodeMenu: function (focus) {
        return Decision_EventNodeMenu(focus);
    },

    indexText: function () {
        return Decision_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Decision_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Decision_OptionDescription();
    },

    colouredPngs: function () {
        return Decision_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Decision_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Decision_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Decision_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Decision_Constant = () => {
    return "133e3246-6b72-495f-9ef1-c084efd75f13";
};

// EFFECTS: Returns black png / svg for event
const Decision_Svg = () => {
    return decisionBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Decision_EventNodeMenu = () => {
    return <></>;
};

// EFFECTS: Returns the Index Text for event
const Decision_IndexText = () => {
    return "Select an input field to learn more about your decision calculations.";
};

// EFFECTS: Returns all icons for event ??
const Decision_Icons = () => {
    let pngs = new Map([
        ["black", decisionBlack],
        ["blue", decisionBlue],
        ["white", decisionWhite],
        ["grey", decisionGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Decision_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Decision_InputComponentInfoPassing = (minProps) => {
    return <DecisionInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, decisions for event ??
const Decision_OptionDescription = () => {
    const tags = ["Option"];
    const type = decisionObject.name();
    const description =
        "The Decision Event is important for both organizing a Scenario and for interacting with Scenarios in the Scenario Canvas. When adding more than one option to the Canvas, it is best practice to insert a Decision Event before creating a branch. The name of the Decision Event is used when naming the Threads in the Thread List Manager. Additionally, when working with larger Scenarios, it is possible to bypass or lock an entire Decision in the Canvas, thereby reducing the number of active Scenario Threads.";
    const usedForText =
        "Anytime there is more than one option to consider, insert a Decision Event first before branching a new Event.";
    const connection = "";
    const connectionImage = DecisionConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Decision_SideModalImage = () => {
    return sideImage;
};

const Decision_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import { camelCase } from "lodash";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { handleDependencies } from "helpers/updateDependencyMap";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import moment from "moment";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
const RevenueInputsHandler = (
    value: string,
    id:
        | "revenueSource"
        | "customer"
        | "segment"
        | "unitCost"
        | "entityName"
        | "rating"
        | "startDate"
        | "endDate",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "revenueSource":
            data.revenueSource = value;

            const parseEmpty = JSON.parse('{"eventId": "", "entityIds": []}');
            if (value === "customer") {
                handleDependencies(
                    dependencyMap,
                    parseEmpty,
                    eventId,
                    currentEntityObject.id,
                    data.segmentEventId,
                    data.segmentEntityId ? [data.segmentEntityId] : []
                );
                data.selectedSegment = {};
                data.segmentEventId = "";
                data.segmentEntityId = "";
                data.segmentIds = [];
            }
            if (value === "segment") {
                handleDependencies(
                    dependencyMap,
                    parseEmpty,
                    eventId,
                    currentEntityObject.id,
                    data.customerEventId,
                    data.customerIds
                );
                data.selectedCustomer = {};
                data.customerEventId = "";
                data.customerIds = [];
            }

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "customer":
            const parsedCustomer = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedCustomer,
                eventId,
                currentEntityObject.id,
                data.customerEventId,
                data.customerIds
            );

            data.selectedCustomer = parsedCustomer;
            data.customerEventId = parsedCustomer?.eventId ?? "";
            data.customerIds = parsedCustomer?.entityIds ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "segment":
            const parsedSegment = JSON.parse(
                value || '{"eventId": "", "entityId": "", "segmentId": ""}'
            );
            const modSegment = {
                ...parsedSegment,
                entityIds: [parsedSegment?.entityId] ?? [],
            };

            handleDependencies(
                dependencyMap,
                modSegment,
                eventId,
                currentEntityObject.id,
                data.segmentEventId,
                data.segmentEntityId ? [data.segmentEntityId] : []
            );

            data.selectedSegment = parsedSegment;
            data.segmentEventId = parsedSegment?.eventId ?? "";
            data.segmentEntityId = parsedSegment?.entityId ?? "";
            data.segmentIds = [parsedSegment?.segmentId] ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "unitCost":
            const parsedUnitCost = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedUnitCost,
                eventId,
                currentEntityObject.id,
                data.unitCostEventId,
                data.unitCostIds
            );

            const entity = Object.values(
                getRelevantEntities(parsedUnitCost?.entityIds ?? [])
            )[0];
            if (entity) {
                if (entity?.type === averageOrderObject.constant()) {
                    data.delayRevenue = true;
                    data.disableDelay = true;
                } else {
                    data.disableDelay = false;
                }
            } else {
                data.disableDelay = false;
            }

            data.selectedUnitCost = parsedUnitCost;
            data.unitCostEventId = parsedUnitCost?.eventId ?? "";
            data.unitCostIds = parsedUnitCost?.entityIds ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            const finalString = camelCase(value);
            data.tag = `@${finalString}`;
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";

            // TODO: look at modifiers / overrides,
            // This snippet is probably important, but I don't know what it does
            // quite yet.
            /*
            if (currentEntityObject?.data?.modsCreated) {
                for (const mod of currentEntityObject?.data?.modsCreated) {
                    mod.startDate = startDateString;
                }
            }
            */
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            const endDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";

            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

export default RevenueInputsHandler;

export const remapRevenueDependencies = (
    entity: EntitySchema,
    newEntityIdsMap: { [key: string]: string }
) => {
    // Segments
    if (newEntityIdsMap?.[entity?.data?.segmentEntityId]) {
        entity.data.segmentEntityId =
            newEntityIdsMap[entity.data.segmentEntityId] ?? "";

        entity.data.selectedSegment = {
            eventId: entity.data.segmentEventId,
            entityId: entity.data.segmentEntityId,
            segmentId: entity.data.segmentIds?.[0],
        };
    }

    // Customers
    if (
        entity?.data?.customerIds?.length > 0 &&
        newEntityIdsMap?.[entity?.data?.customerIds?.[0]]
    ) {
        const oldCustomerIds: string[] = entity.data.customerIds;

        const newCustomerIds: string[] = [];
        for (const oldEntityId of oldCustomerIds) {
            const newId = newEntityIdsMap[oldEntityId];
            newCustomerIds.push(newId);
        }
        entity.data.customerIds = newCustomerIds;
        entity.data.selectedCustomer = {
            entityIds: entity.data.customerIds,
            eventId: entity.data.customerEventId,
        };
    }

    // UnitCost
    if (
        entity?.data?.unitCostIds?.length > 0 &&
        newEntityIdsMap?.[entity?.data?.unitCostIds?.[0]]
    ) {
        const oldUnitCostIds: string[] = entity.data.unitCostIds;

        const newUnitCostIds: string[] = [];
        for (const oldEntityId of oldUnitCostIds) {
            const newId = newEntityIdsMap[oldEntityId];
            newUnitCostIds.push(newId);
        }
        entity.data.unitCostIds = newUnitCostIds;
        entity.data.selectedUnitCost = {
            entityIds: entity.data.unitCostIds,
            eventId: entity.data.unitCostEventId,
        };
    }
};

import moment from "moment";
import { SetStateAction, useCallback } from "react";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import * as uuid from "uuid";

export interface Modifier {
    // Overrides are a special case of modifiers
    id: string;
    cadence: string;
    startDate: string; // Same as parent entities
    nodeType: string; // Parent entity type
    keyToModify: string; // Field being overriden
    value: { [date: string]: Number };
    action: string; // Always "override"
    entityId: string[]; // Parent entity ID
    revert: boolean; // Always false
    cadenceType: string; // Always "Recurring"
}

const useOverrides = (
    entitiesMap: EntitiesSchema,
    setEntitiesMap: (value: SetStateAction<EntitiesSchema>) => void,
    currentEntity: string
) => {
    // This is not really a customHook as of now, we still need to move some override logic from Override.tsx to this hook.
    // Ideally any states required for the override modal would exist in here, also we should make the override modal a child of the event/entity modal.

    const checkIfFieldIsOverridden = useCallback(
        (field: string) => {
            const existingOverrides =
                entitiesMap[currentEntity]?.data?.modsCreated ?? [];
            const fieldContainsOverride = !!existingOverrides?.find(
                (override) =>
                    override.keyToModify === field &&
                    Object.keys(override.value)?.length > 0
            );
            return fieldContainsOverride;
        },
        [currentEntity, entitiesMap]
    );

    const isOverrideValid = (
        entitiesMap: EntitiesSchema,
        currentEntity: string
    ) => {
        const entity = entitiesMap[currentEntity];
        return Boolean(
            ["daily", "monthly", "annually"].includes(entity?.cadence) &&
                (entity?.data?.income ||
                    entity?.data?.value ||
                    entity?.data?.cost ||
                    entity?.data?.income === 0 ||
                    entity?.data?.value === 0 ||
                    entity?.data?.cost === 0) &&
                entity?.startDate
        );
    };

    const createOverride = (
        modifiersData: { [date: string]: string },
        defaultValue: string,
        cadence: string,
        field: string
    ) => {
        const entity = entitiesMap[currentEntity];

        let targetStartDate = "";
        if (entity?.startDate) {
            const targetMoment = moment(entity?.startDate);
            targetStartDate = targetMoment.set("date", 1).format("YYYY-MM-DD");
        }

        const newModifier: Modifier = {
            id: uuid.v4(),
            cadence: cadence,
            startDate: targetStartDate,
            nodeType: entity.type,
            keyToModify: field,
            value: {},
            action: "override",
            entityId: [entity.id],
            revert: false,
            cadenceType: "Recurring",
        };

        Object.entries(modifiersData).forEach(([date, value]) => {
            if (value && value !== defaultValue) {
                newModifier.value[date] = parseFloat(value);
            }
        });

        const toDelete = Object.keys(newModifier.value)?.length === 0;

        if (entity?.data?.modsCreated) {
            const modArray = entity?.data?.modsCreated ?? [];
            let modExists = false;
            for (let index = 0; index < modArray.length; index++) {
                const modifier = modArray[index];
                if (modifier.keyToModify === newModifier.keyToModify) {
                    modExists = true;
                    toDelete
                        ? modArray.splice(index, 1)
                        : (modArray[index] = newModifier);
                    break;
                }
            }
            if (!modExists && !toDelete)
                entity.data.modsCreated.unshift(newModifier);
        } else {
            if (!toDelete) entity.data.modsCreated = [newModifier];
        }

        // We already directly mutated the modsCreated field of the entity so we force a re-render
        setEntitiesMap({ ...entitiesMap });
    };

    const getOverride = (field: string): Modifier | undefined => {
        if (entitiesMap[currentEntity]?.data?.modsCreated) {
            return entitiesMap[currentEntity]?.data?.modsCreated.find(
                (modifier: Modifier) => {
                    return (
                        modifier.action === "override" &&
                        modifier.keyToModify === field
                    );
                }
            );
        }
        return undefined;
    };

    // This function is only used in the override selector component to force a re-render, so when
    // we delete overrides it displays the removal on the modal.
    const updateOverride = () => {
        setEntitiesMap({ ...entitiesMap });
    };

    return {
        checkIfFieldIsOverridden,
        isOverrideValid,
        createOverride,
        updateOverride,
        getOverride,
    };
};

export default useOverrides;

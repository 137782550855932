import React, { Component } from "react";
import { getDefaultName } from "../../helpers";
import _ from "lodash";
import { throwError } from "../../helpers/swalError";
import {
    Description,
    InputButtons,
    Value,
    Rate,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    RequiredStar,
} from "./Components";
import "./InputContainer.css";
import { getClosestDependency } from "../../helpers/nodeDependencyDetectionHelpers";
import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";

class RealEstateFeeInput extends Component {
    constructor(props) {
        super(props);

        const data = this.getUpstreamResale();
        const foundData = data && data.curNode;
        const dependencyData = {
            feeAmount: foundData ? foundData.metadata.resalePrice * 0.05 : "",
            start: foundData ? foundData.metadata.start : null,
            resalePrice: foundData ? foundData.metadata.resalePrice : null,
            houseName: foundData ? foundData.metadata.house : null,
        };
        this.state = props.edit
            ? {
                  ...props.editData,
                  ...dependencyData,
              }
            : {
                  ...RealEstateFeeInput.getDefaultState(
                      props.inflationRate,
                      getDefaultName(realEstateFeeObject.constant(), props),
                      props.baseline
                  ),
                  ...dependencyData,
              };
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            isFilled: false,
            valid: false,
            name: name,
            type: realEstateFeeObject.constant(),
            applyInflation: false,
            description: "",
            value: "",
            tag: `@${realEstateFeeObject.constant()}`,
            rating: 0,
            cadence: "one-time",
            feePercent: 5,
            feeAmount: 0,
            resalePrice: 0,
            houseName: null,
            start: null,
            showInfoBox: true,
            lastChangedFeeProperty: "percent",
            inflationRate,
            inflation: false,
            baseline: isInBaseline,
        };
    }

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onChangeInput = (e, id) => {
        const value = e.target.value;
        e.persist();
        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "feePercent":
                this.feeCalculator(id, value);
                break;
            case "feeAmount":
                this.feeCalculator(id, value);
                break;
            default:
        }
    };

    feeCalculator = (id, value) => {
        if (this.state.resalePrice) {
            if (id === "feeAmount") {
                let feePercent =
                    (parseInt(value) / this.state.resalePrice) * 100;
                if (!Number.isInteger(feePercent)) {
                    feePercent = feePercent.toFixed(2);
                }
                this.setState({
                    feePercent,
                    feeAmount: value,
                    value,
                    lastChangedFeeProperty: "amount",
                });
            } else if (id === "feePercent") {
                let feeAmount = (value * this.state.resalePrice) / 100;
                if (!Number.isInteger(feeAmount)) {
                    feeAmount = feeAmount.toFixed(2);
                }
                this.setState({
                    feeAmount,
                    value: feeAmount,
                    feePercent: value,
                    lastChangedFeeProperty: "percent",
                });
            }
        }
    };

    getUpstreamResale = () => {
        const data = {
            curNode: null,
            isValid: false,
        };
        const dependencies = getClosestDependency(this.props, [
            "Property Resale",
        ]);
        this.processDependencies(dependencies, data, "Property Resale");
        if (!data.isValid) {
            throwError(
                "warning",
                "Failed to find an upstream PropertyResale node.",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
            return null;
        } else {
            return data;
        }
    };

    processDependencies = (dependencies, data, nodeType) => {
        if (dependencies && dependencies[nodeType]) {
            data.curNode = dependencies[nodeType];
            data.isValid = true;
        } else {
            data.isValid = false;
        }
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (realEstateFeeObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    updateValues = () => {
        const passedCheck = realEstateFeeObject.checkInput(this.state);
        this.props.fillPartialValues(
            this.state,
            {  },
            passedCheck
        );
    };

    toggleInfoBox = () => {
        this.setState({ showInfoBox: !this.state.showInfoBox });
    };

    render() {
        const passedCheck = realEstateFeeObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            name,
            description,
            feePercent,
            feeAmount,
            showInfoBox,
        } = this.state;
        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        image={realEstateFeeObject.svg()}
                        name={name}
                        eventType={realEstateFeeObject.constant()}
                        onChangeInput={this.onChangeInput}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Rate
                                value={feePercent}
                                label="Fee Percentage"
                                className="mlsInput"
                                id="feePercent"
                                onChangeInput={this.onChangeInput}
                                sign={true}
                            />
                        </ModalRowHalf>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Value
                                label="Fee Amount"
                                onChangeInput={this.onChangeInput}
                                value={feeAmount}
                                id="feeAmount"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    {showInfoBox && (
                        <ModalRow>
                            <div className="loanPaymentsContainer">
                                <div className="paymentsSubLabel">
                                    The fees charged by a real estate agent can
                                    be significant, and generally range from
                                    3-7%.
                                </div>
                            </div>
                        </ModalRow>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default RealEstateFeeInput;

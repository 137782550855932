// Default Imports
import React from "react";

// Import Rental WebsiteVisitors Card and Rental WebsiteVisitors Input
import WebsiteVisitorsCard from "../Card/websiteVisitorsCard";

// Import Connection Image (if exists)
import WebsiteVisitorsConnectionImage from "Assets/_optionDescriptionIcons/Website Visitors white.jpg";

// Import Coloured PNGs
import websiteVisitorsBlack from "../../Assets/_eventNodeTypes/websiteVisitors.svg";
import websiteVisitorsBlue from "../../Assets/_eventNodeTypes/websiteVisitors.svg";
import websiteVisitorsWhite from "../../Assets/_eventNodeTypes/websiteVisitors.svg";
import websiteVisitorsGrey from "../../Assets/_eventNodeTypes/websiteVisitors.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

import WebsiteVisitorsInputExperimental from "Components/InputContainer/WebsiteVisitorsExperimental/WebsiteVisitorsInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - websiteVisitorsObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - websiteVisitorsObject.checkInput.call(websiteVisitorsObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for WebsiteVisitors
export const websiteVisitorsObject = {
    constant: function () {
        return WebsiteVisitors_Constant();
    },

    name: function () {
        return "Website Visitors";
    },

    checkInput: function (state) {
        return WebsiteVisitors_CheckInput(state);
    },

    svg: function () {
        return WebsiteVisitors_Svg();
    },

    eventNodeMenu: function (focus) {
        return WebsiteVisitors_EventNodeMenu(focus);
    },

    indexText: function () {
        return WebsiteVisitors_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return WebsiteVisitors_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return WebsiteVisitors_OptionDescription();
    },

    colouredPngs: function () {
        return WebsiteVisitors_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return WebsiteVisitors_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return WebsiteVisitors_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for WebsiteVisitors

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const WebsiteVisitors_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.data.value) return false;
        if (!entity.startDate) return false;
        if (!entity.name) return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const WebsiteVisitors_Constant = () => {
    return "86d22aa4-aaf0-4e81-87a9-ab3070c27683";
};

// EFFECTS: Returns svg for event
const WebsiteVisitors_Svg = () => {
    return websiteVisitorsBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const WebsiteVisitors_EventNodeMenu = (focus) => {
    return <WebsiteVisitorsCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const WebsiteVisitors_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Website Visitor calculations.";
};

// EFFECTS: Returns all icons for event ??
const WebsiteVisitors_Icons = () => {
    let pngs = new Map([
        ["black", websiteVisitorsBlack],
        ["blue", websiteVisitorsBlue],
        ["white", websiteVisitorsWhite],
        ["grey", websiteVisitorsGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const WebsiteVisitors_InputComponentInfoPassing = (minProps) => {
    return <WebsiteVisitorsInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const WebsiteVisitors_OptionDescription = () => {
    const tags = ["Option"];
    const type = websiteVisitorsObject.name();
    const description =
        "The Website Visitors Event represents monthly organic visits to a website.";
    const usedForText =
        "Use the Website Visitors Event to keep track of website visitors.";
    const connection = "";
    const connectionImage = WebsiteVisitorsConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/i/income.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const WebsiteVisitors_SideModalImage = () => {
    return sideImage;
};

const WebsiteVisitors_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

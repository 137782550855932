// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    EntityCard,
    SelectEntities,
    SelectDropDown,
    Rate,
    ToggledContainer,
    ToggledContainerHalf,
    BasicHeading,
    MuiCalendar,
    EntitySummaryHighlightBlank,
} from "../Components";
import { modifierObject } from "Components/Registry/Modifier";
import Switch from "react-switch";
import { getScenarioStartDate } from "helpers/modifiers";

export default function ModifierInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    toggleRevertValue,
    toggleCustomEffectPeriod,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    loadedScenario,
    handleChangeCadenceType,
    handleDateSelection,
    getEntitySummary,
    availableEvents,
}) {
    const data = entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={modifierObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={modifierObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[modifierObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="One-time"
                        isSelected={data?.cadenceType === "One-time"}
                        onClick={handleChangeCadenceType}
                        title="Global Modifier"
                    />
                    <ToggledContainerHalf
                        id="Recurring"
                        isSelected={data?.cadenceType === "Recurring"}
                        onClick={handleChangeCadenceType}
                        title="Compounding Modifier"
                    />
                </ToggledContainer>
                <ModalRow>
                    <BasicHeading
                        copy="Select Event/Records to Modify"
                        margin="15px 0"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={data?.selectedEventType ?? ""}
                            options={
                                Object.keys(data?.eventsMappedByType) ?? []
                            }
                            className="select-dropdown"
                            id="eventType"
                            label="Target Event Type"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            disabled={data?.selectedEventType === ""}
                            onChangeInput={handleOnChange}
                            value={data?.selectedEventId ?? ""}
                            options={availableEvents ?? []}
                            className="select-dropdown"
                            id="event"
                            label="Target Event"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            disabled={data?.selectedEventId === ""}
                            onChangeInput={handleOnChange}
                            showAll={true}
                            value={data?.selectedEvent ?? {}}
                            nodes={[data?.selectedEventId]}
                            className="IncomeSelect"
                            id="entity"
                            label="Target Record"
                            required={true}
                            hideEventName={true}
                            groupEventType={"All"}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            disabled={
                                data?.selectedEvent?.eventId !==
                                data?.selectedEventId
                            }
                            onChangeInput={handleOnChange}
                            value={data?.property ?? ""}
                            options={data?.properties ?? []}
                            className="select-dropdown"
                            id="property"
                            label="Target Property"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>

                {data?.property && (
                    <>
                        <ModalRow>
                            <BasicHeading
                                copy="Apply Modification"
                                margin="15px 0"
                            />
                        </ModalRow>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={data?.feFuncitonDisplayName ?? ""}
                                    options={data?.functions ?? []}
                                    className="select-dropdown"
                                    id="function"
                                    label="Select Modifier Type"
                                    required={true}
                                />
                            </ModalRowHalf>
                            {data?.functions.length > 0 && (
                                <ModalRowHalf>
                                    {data?.modifierType === "date" &&
                                        (data?.function === "Set" ? (
                                            <MuiCalendar
                                                id="value"
                                                value={
                                                    entitiesMap?.[currentEntity]
                                                        ?.data?.value === ""
                                                        ? null
                                                        : entitiesMap?.[
                                                              currentEntity
                                                          ]?.data?.value
                                                }
                                                required={true}
                                                onChangeInput={
                                                    handleDateSelection
                                                }
                                                label="New Date"
                                                minDate={
                                                    entitiesMap?.[currentEntity]
                                                        ?.startDate ||
                                                    getScenarioStartDate(
                                                        loadedScenario
                                                    )
                                                }
                                            />
                                        ) : (
                                            <Rate
                                                label="Offset Months"
                                                onChangeInput={handleOnChange}
                                                value={
                                                    entitiesMap?.[currentEntity]
                                                        ?.data?.value ?? ""
                                                }
                                                id="value"
                                                className="mlsInput"
                                                sign={false}
                                                required={true}
                                            />
                                        ))}

                                    {data?.modifierType === "numerical" && (
                                        <Rate
                                            label="Modifier Amount"
                                            onChangeInput={handleOnChange}
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.data?.value ?? ""
                                            }
                                            id="value"
                                            className="mlsInput"
                                            sign={false}
                                            required={true}
                                        />
                                    )}
                                </ModalRowHalf>
                            )}
                        </ModalRow>
                    </>
                )}

                {data?.functions.length > 0 &&
                    data?.modifierType === "numerical" && (
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <Switch
                                    checked={data?.customEffectPeriod}
                                    className="InflationToggle"
                                    onChange={toggleCustomEffectPeriod}
                                    height={20}
                                    width={40}
                                />
                                <div className="InflationText">
                                    Custom effect period
                                </div>
                            </ModalRowHalf>

                            <ModalRowHalf>
                                <Switch
                                    checked={data?.revertValue}
                                    className="InflationToggle"
                                    onChange={toggleRevertValue}
                                    height={20}
                                    width={40}
                                />
                                <div className="InflationText">
                                    Revert value on end date
                                </div>
                            </ModalRowHalf>
                        </ModalRow>
                    )}

                <ModalRow twoInputs>
                    {data?.customEffectPeriod && (
                        <ModalRowHalf>
                            <MuiCalendar
                                views={["year", "month"]}
                                inputFormat="YYYY-MM"
                                id="startDate"
                                value={
                                    entitiesMap?.[currentEntity]?.startDate ===
                                    ""
                                        ? null
                                        : entitiesMap?.[currentEntity]
                                              ?.startDate
                                }
                                required={true}
                                onChangeInput={handleDateSelection}
                                label="Start Date"
                            />
                        </ModalRowHalf>
                    )}
                    {(data?.customEffectPeriod || data?.revertValue) && (
                        <ModalRowHalf>
                            <MuiCalendar
                                views={["year", "month"]}
                                inputFormat="YYYY-MM"
                                id="endDate"
                                value={
                                    entitiesMap?.[currentEntity]?.endDate === ""
                                        ? null
                                        : entitiesMap?.[currentEntity]?.endDate
                                }
                                onChangeInput={handleDateSelection}
                                label="End Date"
                                required={data?.revertValue}
                                helperText={
                                    data?.customEffectPeriod &&
                                    !data?.revertValue
                                        ? "An end date is optional"
                                        : ""
                                }
                            />
                        </ModalRowHalf>
                    )}
                </ModalRow>
                {passedCheck && (
                    <ModalRow>
                        <EntitySummaryHighlightBlank
                            copy={getEntitySummary(
                                entitiesMap?.[currentEntity]
                            )}
                        />
                    </ModalRow>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

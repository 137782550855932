import React, { Component } from "react";
import { processNodeValueForCanvasDisplay } from "../../helpers";

class ClosingCostsCard extends Component {
    render() {
        const {
            description,
            value,
            propertyEvaluationFee,
            landSurveyFee,
            titleInsurance,
            legalCosts,
            includePropertyEvalFee,
            includeLandSurveyFee,
            includeTitleInsurance,
            includeLegalCosts,
            version,
        } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="Amount">
                        {processNodeValueForCanvasDisplay(-1 * value)}
                    </div>
                    <div className="Frequency">{"Total Cost"}</div>
                </div>
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>

                {includePropertyEvalFee ? (
                    <div className="Row">
                        <div className="ExpenseLabel">
                            Property Evalution Fee
                        </div>
                        <div className="cardValue">
                            {includePropertyEvalFee
                                ? processNodeValueForCanvasDisplay(
                                      propertyEvaluationFee
                                  )
                                : "$0"}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {includeLandSurveyFee ? (
                    <div className="Row">
                        <div className="ExpenseLabel">Land Survey Fee</div>
                        <div className="cardValue">
                            {includeLandSurveyFee
                                ? processNodeValueForCanvasDisplay(
                                      landSurveyFee
                                  )
                                : "$0"}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {includeTitleInsurance ? (
                    <div className="Row">
                        <div className="ExpenseLabel">Title Insurance</div>
                        <div className="cardValue">
                            {includeTitleInsurance
                                ? processNodeValueForCanvasDisplay(
                                      titleInsurance
                                  )
                                : "$0"}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {includeLegalCosts ? (
                    <div className="Row">
                        <div className="ExpenseLabel">Legal Costs</div>
                        <div className="cardValue">
                            {includeLegalCosts
                                ? processNodeValueForCanvasDisplay(legalCosts)
                                : "$0"}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}

export default ClosingCostsCard;

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import ShortTermRentalCard from "../Card/shortTermRentalCard";
import ShortTermRentalInput from "../InputContainer/ShortTermRentalInput";

// Import Connection Image (if exists)
import ShortTermRentalConnectionImage from "../../Assets/_optionDescriptionIcons/Budget, Home Maintenance, Short Term Rental.png";

// Import Coloured PNGs
import shortTermRentalBlack from "../../Assets/_eventNodeTypes/shortTermRental-black.png";
import shortTermRentalBlue from "../../Assets/_eventNodeTypes/shortTermRental-blue.png";
import shortTermRentalWhite from "../../Assets/_eventNodeTypes/shortTermRental-white.png";
import shortTermRentalGrey from "../../Assets/_eventNodeTypes/shortTermRental-grey.png";
import { valueDoesNotExist } from "helpers";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - shortTermRentalObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - shortTermRentalObject.checkInput.call(shortTermRentalObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const shortTermRentalObject = {
    constant: function () {
        return ShortTermRental_Constant();
    },

    checkInput: function (state) {
        return ShortTermRental_CheckInput(state);
    },

    svg: function () {
        return ShortTermRental_Svg();
    },

    eventNodeMenu: function (focus) {
        return ShortTermRental_EventNodeMenu(focus);
    },

    indexText: function () {
        return ShortTermRental_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return ShortTermRental_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return ShortTermRental_OptionDescription();
    },

    colouredPngs: function () {
        return ShortTermRental_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return ShortTermRental_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const ShortTermRental_CheckInput = (state) => {
    if (!state.name) return false;
    if (valueDoesNotExist(state.upfrontCosts) || state.upfrontCosts < 0)
        return false;
    if (valueDoesNotExist(state.monthlyCosts) || state.monthlyCosts < 0)
        return false;
    if (
        valueDoesNotExist(state.averageMonthlyNights) ||
        state.averageMonthlyNights < 0
    )
        return false;
    if (valueDoesNotExist(state.dailyRate) || state.dailyRate < 0) return false;
    if (valueDoesNotExist(state.daysPerStay) || state.daysPerStay <= 0)
        return false;
    if (valueDoesNotExist(state.perStayCosts) || state.perStayCosts < 0)
        return false;
    if (valueDoesNotExist(state.otherFeesRate) || state.otherFeesRate < 0)
        return false;
    if (valueDoesNotExist(state.hotelTaxRate) || state.hotelTaxRate < 0)
        return false;
    if (valueDoesNotExist(state.startDate)) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const ShortTermRental_Constant = () => {
    return "Short-Term Rental";
};

// EFFECTS: Returns black png / svg for event
const ShortTermRental_Svg = () => {
    return shortTermRentalBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const ShortTermRental_EventNodeMenu = (focus) => {
    return <ShortTermRentalCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const ShortTermRental_IndexText = () => {
    return "Select an input field to learn more about your shortTermRental calculations.";
};

// EFFECTS: Returns all icons for event ??
const ShortTermRental_Icons = () => {
    let pngs = new Map([
        ["black", shortTermRentalBlack],
        ["blue", shortTermRentalBlue],
        ["white", shortTermRentalWhite],
        ["grey", shortTermRentalGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const ShortTermRental_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const ShortTermRental_InputComponentInfoPassing = (minProps) => {
    return <ShortTermRentalInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, shortTermRentals for event ??
const ShortTermRental_OptionDescription = () => {
    const tags = ["Option"];
    const type = shortTermRentalObject.constant();
    const description =
        "A Short Term Rental is a temporary property rental to a third party and generates Rental Income on a non-fixed basis. An AirBNB is an example of a Short Term Rental.";
    const usedForText =
        "The Short Term Rental Node uses average numbers to calculate generated income and expenses associated with a Short Term Rental.";
    const connection = "Connect a Short Term Rental Node after a House Node.";
    const connectionImage = ShortTermRentalConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/articles/mortgages-real-estate/10/renting-out-home.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const ShortTermRental_SideModalImage = () => {
    return sideImage;
};

import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { percentageObject } from "Components/Registry/Percentage";
import PercentageInputView from "./PercentageInputView";
import { percentageInputsHandler } from "../OnInputChangeHandlers/percentageInputsHandler";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import { recurseToTopAccount } from "helpers/accounts";
import { Ledgers } from "reducers/typesSchema/allAccountLedgersSchema";

export default function Percentage({ line, focus, edit, editData }) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(percentageObject);
            _eventData.name = getDefaultName(
                percentageObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, percentageObject);

    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "value" | "startDate" | "endDate" | "entityName"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = percentageInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = percentageInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const newEntitiesMap = percentageInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeDebitCredit = (e: ChangeEvent<HTMLInputElement>) => {
        const newType = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.debitOrCredit = newType;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const toggleCustomEffectPeriod = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data.customEffectPeriod === true) {
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";
        }

        data.customEffectPeriod = !data.customEffectPeriod;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && percentageObject.checkInput(entitiesMap);

    return (
        <PercentageInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            updateAccount={updateAccount}
            handleOnChange={handleOnChange}
            handleChangeDebitCredit={handleChangeDebitCredit}
            toggleCustomEffectPeriod={toggleCustomEffectPeriod}
            handleDateSelection={handleDateSelection}
        />
    );
}

export const remapPercentageAccounts = (
    entity: EntitySchema,
    customAccountMap: { [key: string]: string },
    accountsAndLedgers: Ledgers
) => {
    const oldSourceAccountId =
        entity.data["sourceAccountIds"][
            entity.data["sourceAccountIds"].length - 1
        ];
    const oldTargetAccountId =
        entity.data["targetAccountIds"][
            entity.data["targetAccountIds"].length - 1
        ];
    const oldTargetContraAccountId =
        entity.data["targetContraAccountIds"][
            entity.data["targetContraAccountIds"].length - 1
        ];

    if (customAccountMap[oldSourceAccountId]) {
        entity.data["sourceAccountIds"] = recurseToTopAccount(
            customAccountMap[oldSourceAccountId]
        );
        entity.data["sourceAccountName"] =
            accountsAndLedgers[customAccountMap[oldSourceAccountId]].name;
    }

    if (customAccountMap[oldTargetAccountId]) {
        entity.data["targetAccountIds"] = recurseToTopAccount(
            customAccountMap[oldTargetAccountId]
        );
        entity.data["targetAccountName"] =
            accountsAndLedgers[customAccountMap[oldTargetAccountId]].name;
    }

    if (customAccountMap[oldTargetContraAccountId]) {
        entity.data["targetContraAccountIds"] = recurseToTopAccount(
            customAccountMap[oldTargetContraAccountId]
        );
        entity.data["targetContraAccountName"] =
            accountsAndLedgers[customAccountMap[oldTargetContraAccountId]].name;
    }

    entity.data.accountIds = [
        ...entity.data.sourceAccountIds,
        ...entity.data.targetAccountIds,
        ...entity.data.targetContraAccountIds,
    ];
};

import { useContext, useMemo } from "react";
import { Group } from "react-konva";
import { EventNodeIDContext } from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/contexts/EventNodeIDContext";
import {
    EventNodeActiveContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeLockedContext,
} from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/contexts/EventNodeStateContexts";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { useEventTemplate } from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/hooks/useEventTemplate";
import { BaseMenuContainerWidthContext } from "../contexts/BaseMenuContainerWidthContext";
import { MenuIdContext } from "../contexts/MenuIdContext";
import { useMenuTemplate } from "../hooks/useMenuTemplate";
import { MenuIcon } from "./MenuIcon";
import type { MenuTemplate } from "reducers/typesSchema/menusTemplatesSchema";
import { CanvasBaselineContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasBaselineContext";

export function MenuIconsContainer() {
    const isBaseline = useContext(CanvasBaselineContext);
    const active = useContext(EventNodeActiveContext);
    const focused = useContext(EventNodeFocusedContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const error = useContext(EventNodeErrorContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);
    const baseContainerWidth = useContext(BaseMenuContainerWidthContext);
    const menuId = useContext(MenuIdContext);
    const menuTemplate = useMenuTemplate(menuId) as MenuTemplate;
    const eventId = useContext(EventNodeIDContext);
    const eventTemplate = useEventTemplate(eventId);
    const baseMenuActionsIds = useMemo(
        () =>
            (isBaseline
                ? eventTemplate?.eventTemplateObject?.baselineMenuActionsIds
                : eventTemplate?.eventTemplateObject?.baseMenuActionsIds) ?? [],
        [isBaseline, eventTemplate?.eventTemplateObject]
    );

    /**
     * Represents the x-center of each icon.
     *
     * Calculated by finding its width, its position in the array, and the full width of the container
     */
    const allIconsHorizontalCenters = useMemo(() => {
        const _allXPositions = {};
        let curPosition = 0 - baseContainerWidth / 2;

        baseMenuActionsIds.forEach((buttonId) => {
            const _styles =
                menuTemplate?.canvasStyles?.buttons?.[buttonId]
                    ?.buttonDimensions?.styles;
            const _default = _styles?.default;
            const _active = active ? _styles?.active : {};
            const _focused = focused ? _styles?.focused : {};
            const _locked = locked ? _styles?.locked : {};
            const _bypassed = bypassed ? _styles?.bypassed : {};
            const _error = error ? _styles?.error : {};
            const _zoomCategory = _styles?.[zoomCategory];

            const _finalStyles = {
                ..._default,
                ..._active,
                ..._focused,
                ..._locked,
                ..._bypassed,
                ..._error,
                ..._zoomCategory,
            };

            curPosition += _finalStyles?.width ?? 0;
            _allXPositions[buttonId] =
                curPosition - (_finalStyles?.width ?? 0) / 2;
        });

        return _allXPositions;
    }, [
        active,
        baseContainerWidth,
        baseMenuActionsIds,
        bypassed,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles?.buttons,
        zoomCategory,
    ]);

    return (
        <Group>
            {baseMenuActionsIds.map((buttonId, i) => {
                let descriptivePosition = "middle";
                if (i === 0) {
                    descriptivePosition = "left";
                } else if (i === baseMenuActionsIds?.length - 1) {
                    descriptivePosition = "right";
                }
                return (
                    <MenuIcon
                        key={buttonId}
                        descriptivePosition={descriptivePosition}
                        buttonId={buttonId}
                        horizontalCenter={allIconsHorizontalCenters[buttonId]}
                    />
                );
            })}
        </Group>
    );
}

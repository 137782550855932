import React, { Component } from "react";
import "./InputContainer.css";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import {
    InputButtons,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    Description,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { decisionObject } from "Components/Registry/Decision";

class DecisionInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: getDefaultName(decisionObject.name(), this.props),
            type: decisionObject.constant(),
            tag: `@${decisionObject.name()}`,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.onboardingData === 2) {
            this.setState({ name: "Rent or Buy", tag: "@RentOrBuy" });
        }
        if (this.props.onboardingData === 10) {
            this.setState({
                name: "Mortgage Decision",
                tag: "@MortgageDecision",
            });
        }
    }

    onChangeInput = (e, id, _star) => {
        const value = e.target.value;
        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        if (decisionObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            this.props.confirmAction(newState);
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        this.props.confirmAction(newState);
    };

    render() {
        const isPassed = decisionObject.checkInput(this.state);
        const { edit } = this.props;
        const { name, description } = this.state;
        const onboarding =
            this.props.onboardingData === 2 || this.props.onboardingData === 10;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={decisionObject.name()}
                        onChangeInput={this.onChangeInput}
                        image={decisionObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(this.state, {}, isPassed)
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={isPassed}
                    onboarding={onboarding}
                />
            </EventInputModal>
        );
    }
}

export default DecisionInput;

import store, { AppThunk } from "store";
import { SET_ACCOUNT_MAPPING_MODAL } from "./types";
import {
    AccountMappingData,
    AccountMappingDataMapped,
    AccountMappings,
    AccountMappingType,
} from "reducers/typesSchema/accountMappingsSchema";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";

export const setShowAccountMappingModal = (mappingData: {
    show: boolean;
    entity?: EntitySchema;
    accountMappingData?: AccountMappingData | AccountMappingDataMapped;
    mapped?: boolean;
    accountMappingType?: AccountMappingType;
    create?: any;
}) => {
    store.dispatch({ type: SET_ACCOUNT_MAPPING_MODAL, payload: mappingData });
};

export const closeAccountMappingModal = (): AppThunk<void> => {
    return (dispatch, _getState) => {
        dispatch({
            type: SET_ACCOUNT_MAPPING_MODAL,
            payload: { show: false },
        });
    };
};

export const UPSERT_ACCOUNT_MAPPINGS = "UPSERT_ACCOUNT_MAPPINGS";

export const upsertAccountMappings = (payload: Partial<AccountMappings>) => ({
    type: UPSERT_ACCOUNT_MAPPINGS,
    payload,
});

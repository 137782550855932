// import type {ChangeEvent} from "react";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

// import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";

// /**
//  *
//  * @param eventId
//  * @param entityIds
//  */
// const getDependantAccounts = (eventId: string, entityIds: string[]) => {
//     const eventData = getEvent(eventId);
//     const relevantAccounts: string[] = [];
//     if (entityIds?.length === 0 && eventData) {
//         const relevantEntities = getRelevantEntities(eventData?.entities);
//         Object.values(relevantEntities).map((entity) =>
//             relevantAccounts.push(entity.data.parentAccountId)
//         );
//     } else {
//         const relevantEntities = getRelevantEntities(entityIds);
//         Object.values(relevantEntities).map((entity) =>
//             relevantAccounts.push(entity.data.parentAccountId)
//         );
//     }

//     return relevantAccounts;
// };

/**
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const kpisInputsHandler = (
    value: string,
    id:
        | "value"
        | "entityName"
        | "filter"
        | "arpu"
        | "lifetimeValue"
        | "ltvToCACRatio"
        | "profitMargin"
        | "entity",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    switch (id) {
        case "value":
            data.income = value;
            data.value = parseInt(value) - data?.rentalExpenses;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const newDependencies: {
                [key: string]: { eventId: string; entityIds: string[] };
            } = { ...(currentEntityObject.dependencies ?? {}) };
            if (!value) {
                newDependencies[id] = {
                    eventId: "",
                    entityIds: [],
                };
            } else {
                newDependencies[id] = JSON.parse(value);
            }

            // console.log(
            //     "relevantAccounts",
            //     getDependantAccounts(
            //         newDependencies.entity.eventId,
            //         newDependencies.entity.entityIds
            //     )
            // );
            currentEntityObject.dependencies = newDependencies;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "filter":
            if (value === "all") {
                if (currentEntityObject.data.activeFilter) {
                    data.activeFilter = false;
                    data.filter = {
                        saas: false,
                        ecommerce: false,
                        general: false,
                    };
                    currentEntityObject.data = data;
                    newEntitiesMap[currentEntity] = currentEntityObject;
                }
                break;
            }

            data.filter[value] = !currentEntityObject.data.filter[value];

            for (const individualFilter in data.filter) {
                if (currentEntityObject.data.filter[individualFilter]) {
                    data.activeFilter = true;
                    break;
                } else {
                    data.activeFilter = false;
                }
            }
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "arpu":
            data.arpu = !currentEntityObject.data?.arpu;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "lifetimeValue":
            data.lifetimeValue = !currentEntityObject.data?.lifetimeValue;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "ltvToCACRatio":
            data.ltvToCACRatio = !currentEntityObject.data?.ltvToCACRatio;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "profitMargin":
            data.profitMargin = !currentEntityObject.data?.profitMargin;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

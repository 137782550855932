import { useState, ChangeEvent, useEffect, useMemo, useContext, useRef, MutableRefObject } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { getDefaultName } from "helpers";
import { salespersonObject } from "Components/Registry/Salesperson";
import salespersonInputsHandler from "../OnInputChangeHandlers/salespersonInputsHandler";
import SalespersonInputView from "./SalespersonInputView";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { throwError } from "helpers/swalError";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { employeeObject } from "Components/Registry/Employee";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { v4 as uuidv4 } from "uuid";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function SalespersonInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);

    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({ ...useAppSelector((state) => state?.scenario?.loadedScenario?.dependency_map) })

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(salespersonObject);
            _eventData.name = getDefaultName(
                salespersonObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, salespersonObject, dependencyMap.current); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );

    const getEmployees = () => {
        const employees = {};

        getPresentableDependencies(
            employees,
            employeeObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(employees).length) {
            throwError(
                "warning",
                "No Employee nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return employees;
    };

    const getOutboundSales = () => {
        const outboundSales = {};

        getPresentableDependencies(
            outboundSales,
            outboundSalesObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(outboundSales).length) {
            throwError(
                "warning",
                "No Outbound Sales nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return outboundSales;
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "employee"
            | "entityName"
            | "specific"
            | "placeholder"
            | "outboundSales"
            | "timePercentage"
            | "multiplier"
            | "totalSalespeople",
        index: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = salespersonInputsHandler(
            value,
            id,
            index,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const index = 0
        const newEntitiesMap = salespersonInputsHandler(
            value,
            id,
            index,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    }

    // const forceEntityChange = (
    //     value: any,
    //     id:
    //         | "employee"
    //         | "entityName"
    //         | "specific"
    //         | "placeholder"
    //         | "outboundSales"
    //         | "timePercentage"
    //         | "multiplier"
    //         | "totalSalespeople",
    //     index: number
    // ) => {
    //     const newEntitiesMap = salespersonInputsHandler(
    //         value,
    //         id,
    //         index,
    //         entitiesMap,
    //         currentEntity
    //     );
    //     setEntitiesMap(newEntitiesMap);
    // };

    const onChangeInput = (
        value: any,
        id: "inheritStartDate" | "inheritEndDate",
        index: number
    ) => {
        const newEntitiesMap = salespersonInputsHandler(
            value,
            id,
            index,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const getTimePercentage = (salesTargets) => {
        let totalTimePercentage = 0;
        salesTargets.forEach(
            (target) =>
            (totalTimePercentage =
                totalTimePercentage + Number(target?.timePercentage))
        );

        const availableTimePercentage = 100 - totalTimePercentage;

        return `${availableTimePercentage}`;
    };

    const addNewSalesTarget = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const newSegment = {
            id: uuidv4(),
            outboundSales: {
                eventId: "",
                entityIds: [],
            },
            customerAccountId: "",
            grownCustomerAccountId: "",
            cadence: "",
            contractsClosed: 0,
            leadTime: "",
            timePercentage: getTimePercentage(data.salesTargets),
            multiplier: "1",
        };

        data.salesTargets.push(newSegment);

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const deleteSalesTarget = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const salesTargets = [...data.salesTargets];

        salesTargets.splice(index, 1);
        data.salesTargets = salesTargets;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const employees = getEmployees();
            const outboundSales = getOutboundSales();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.employees = employees;
            newEntitiesMap[currentEntity].data.outboundSales = outboundSales;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const passedCheck =
        !!eventData.name && salespersonObject.checkInput(entitiesMap);

    return (
        <SalespersonInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            onHandleSubmit={onHandleSubmit}
            handleOnChange={handleOnChange}
            passedCheck={passedCheck}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            onChangeInput={onChangeInput}
            addNewSalesTarget={addNewSalesTarget}
            deleteSalesTarget={deleteSalesTarget}
            handleDateSelection={handleDateSelection}
        />
    );
}

// @ts-nocheck

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
} from "../Components";
import { startObject } from "Components/Registry/Start";

const StartInputView = ({
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    eventData,
}) => {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={startObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={startObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default StartInputView;

import { useState, useEffect, useMemo, useContext, useRef } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import GrowthInputView from "./GrowthInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent, MutableRefObject } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { growthInputsHandler } from "../OnInputChangeHandlers/growthInputsHandler";
import { growthObject } from "Components/Registry/Growth";
import { updateEntityState } from "helpers/updateEntityState";
import { cloneDeep } from "lodash";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import { throwError } from "helpers/swalError";
import { getPresentableDependenciesOfManyTypes } from "helpers/nodeDependencyDetectionHelpers";
import modifierEntityMap from "helpers/modifierEntityMap.json";
import { getEvent } from "actions/getNodeEntityActions";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";

export default function GrowthInputExperimental({
    line,
    edit,
    editData,
    focus,
}) {
    const dispatch = useAppDispatch();

    const loadedScenario: MutableRefObject<ScenarioSchema> = useRef({
        ...useAppSelector((state) => state?.scenario?.loadedScenario),
    });
    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...loadedScenario.current?.dependency_map,
    });

    const eventId = useContext(EventInputIDContext);

    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = getDefaultName(growthObject.name(), propsObject);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(growthObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entityIndex,
        entitiesMap,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "entityName"
            | "event"
            | "entity"
            | "property"
            | "cadence"
            | "maxGrowthRate"
            | "constantGrowthRate"
            | "growthRate",
        index?: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = growthInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current,
            index
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeValue = (
        value: any,
        id: "growthType" | "interpolate" | "rateStartDate" | "period",
        index?: number
    ) => {
        const newEntitiesMap = growthInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current,
            index
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const toggleCustomEffectPeriod = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data.customEffectPeriod === true) {
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";
        }

        data.customEffectPeriod = !data.customEffectPeriod;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = growthInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const addNewSegment = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const growthSegments = cloneDeep(data.growthSegments);

        const newSegment = {
            startDate: "",
            monthlyRate: 0,
            rateDisplay: "",
            period: "monthly",
        };

        growthSegments.push(newSegment);

        data.growthSegments = growthSegments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const deleteSegment = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const growthSegments = cloneDeep(data.growthSegments);

        growthSegments.splice(index, 1);
        data.growthSegments = growthSegments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getValidEvents = () => {
        const events = {};

        if (!propsObject.eventId && !propsObject.line) return events;

        const validEventType: string[] = Object.keys(modifierEntityMap);

        getPresentableDependenciesOfManyTypes(
            events,
            validEventType,
            propsObject,
            "Modifiable",
            true
        );

        if (!Object.keys(events).length) {
            throwError(
                "Warning",
                "No Modifiable nodes found upstream",
                "You can still add this event to this scenario as a placeholder for future calculations"
            );
        }
        //@ts-ignore
        const eventObjects = Object.values(events)?.map((eventId: string) =>
            getEvent(eventId)
        );

        const eventsMappedByType = {};
        eventObjects?.map((event) => {
            const eventTypeName = getObjectFromUUID(event?.type)?.name();
            if (!eventsMappedByType[eventTypeName]) {
                eventsMappedByType[eventTypeName] = [];
            }
            eventsMappedByType[eventTypeName].push(event?.id);
        });
        return eventsMappedByType;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const eventsMappedByType = getValidEvents();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.eventsMappedByType =
                eventsMappedByType;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = growthObject.checkInput(entitiesMap);
    return (
        <GrowthInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            entitiesLength={entityIds.length}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            onChangeValue={onChangeValue}
            addNewSegment={addNewSegment}
            deleteSegment={deleteSegment}
            toggleCustomEffectPeriod={toggleCustomEffectPeriod}
            handleDateSelection={handleDateSelection}
        />
    );
}

import { Component } from "react";
import styles from "./Scenario.module.css";
// import deleteSvg from "../../Assets/scenarioIcons/delete.svg";
// import editSvg from "../../Assets/scenarioIcons/edit.svg";
import swal from "sweetalert";
// import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
// import profilePlaceholder from "../../Assets/_headerIcons/small.png";
import "@webscopeio/react-textarea-autocomplete/style.css";

// import moment from "moment";
import * as uuid from "uuid";

export default class ScenarioComment extends Component {
    constructor(props) {
        super(props);
        // this.props.data is the current scenario |redux state -> scenario(object) -> userScenarios(array) -> currentScenario(an object in the prev array)|
        // this.state.data is an object containing comments and notifications data = {comments: [], notifications: []}

        this.state = {
            inputValue: null,
            data: null,
            focusId: null,
            toggledComments: [],
            showText: true,
            threadInput: null,
            editComment: null,
            editValue: null,
            editThreadId: null,
            threadEditValue: null,
            showNodeList: false,
            nodes: null,
            selected: [],
            deleteComment: null,
            deleteThreadParent: null,
            deleteThreadId: null,
        };
    }

    onHandleEditInput = (e) => {
        this.setState({
            editValue: e.target.value,
        });
    };

    onHandleInput = (e) => {
        this.setState({
            inputValue: e.target.value,
        });
    };

    componentDidMount() {
        if (this.props.focus) {
            const filteredComments =
                this.props.data.comments &&
                this.props.data.comments.comments.filter((comment) => {
                    let nodeData = [];
                    if (
                        comment.nodeId &&
                        comment.nodeId.includes(this.props.focus.id)
                    ) {
                        nodeData.push(comment);
                    } else {
                        return null;
                    }

                    return nodeData;
                });

            if (filteredComments && filteredComments.length > 0) {
                this.setState({
                    data: {
                        comments: filteredComments,
                        notification:
                            this.props.data.comments.notification || [],
                    },
                });
            } else {
                this.setState({
                    data: {
                        comments: [],
                        notification: [],
                    },
                });
            }
        } else {
            let nodeOptions = [];
            this.props.data.data.nodes.map((node) => {
                return nodeOptions.push(node.name);
            });
            this.setState({
                data: this.props.data.comments,
                nodes: this.props.data.data.nodes,
            });
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.data !== this.props.data) {
            let nodeOptions = [];
            this.props.data.data.nodes.map((node) => {
                return nodeOptions.push(node.name);
            });
            this.setState({
                data: this.props.data.comments,
                nodes: this.props.data.data.nodes,
            });
        }

        if (
            this.props.userScenarios &&
            prevProps.userScenarios !== this.props.userScenarios
        ) {
            this.setState({
                inputValue: "",
                threadInput: "",
                editComment: null,
                editValue: null,
                editThreadId: null,
                threadEditValue: null,
            });
        }

        if (
            this.props.sharedScenarios &&
            prevProps.sharedScenarios !== this.props.sharedScenarios
        ) {
            this.setState({
                inputValue: "",
                threadInput: "",
                editComment: null,
                editValue: null,
                editThreadId: null,
                threadEditValue: null,
            });
        }

        if (this.props.focus && prevProps.focus !== this.props.focus) {
            let filteredComments =
                this.props.data.comments &&
                this.props.data.comments.comments.filter((comment) => {
                    let nodeData = [];
                    if (
                        comment.nodeId &&
                        comment.nodeId.includes(this.props.focus.id)
                    ) {
                        nodeData.push(comment);
                    } else {
                        return null;
                    }

                    return nodeData;
                });
            if (!filteredComments) filteredComments = [];

            if (filteredComments.length > 0) {
                this.setState({
                    data: {
                        comments: filteredComments,
                        notification: [],
                    },
                });
            } else {
                this.setState({
                    data: {
                        comments: [],
                        notification: [],
                    },
                });
            }
        }
    }

    isSharedPassed = (loadedScenario) => {
        return (
            loadedScenario.type === "shared" &&
            (loadedScenario.access === "can comment" ||
                loadedScenario.access === "can edit")
        );
    };

    //add comment
    submitComment = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const loadedScenario = this.props.data;

        const str = this.state.inputValue;
        const tags = Array.from(str.matchAll("@[a-zA-Z0-9]+"), (m) => m[0]);

        if (loggedInUser && this.state.inputValue) {
            let nodeIds;
            let nodes;
            if (this.props.focus) {
                nodeIds = [this.props.focus.id];
            } else {
                const filteredNodes = this.state.nodes.filter((node) => {
                    // TODO: Tags are not a part of the event structure, replaced with name as temp fix
                    return tags.includes(node.name);
                });

                let ids = [];
                filteredNodes.map((node) => {
                    return ids.push(node.id);
                });
                nodes = filteredNodes;
                nodeIds = ids;
            }

            const comment = {
                comment: this.state.inputValue,
                image: null,
                time: new Date(),
                name: loggedInUser.name,
                scenarioId: loadedScenario.id,
                nodeId: nodeIds,
                threads: null,
                id: uuid.v4(),
            };

            let message;
            if (this.props.focus) {
                message = `Add Comment on `;
            } else {
                message = `Add Comment on `;
            }

            const notif = {
                message,
                commenterAccount: loggedInUser.account,
                commenterName: loggedInUser.name,
                commenterImage: null,
                scenarioId: loadedScenario.id,
                nodeId: nodeIds,
                nodes,
                scenario: loadedScenario,
                threads: null,
                seen: false,
                id: uuid.v4(),
                time: new Date(),
            };

            let notification;
            if (
                this.props.data &&
                this.props.data.comments &&
                this.props.data.comments.notification
            ) {
                notification = [
                    ...this.props.data.comments.notification,
                    notif,
                ];
            } else {
                notification = [notif];
            }

            let conversation;
            if (
                this.props.data &&
                this.props.data.comments &&
                this.props.data.comments.comments
            ) {
                conversation = [...this.props.data.comments.comments, comment];
            } else {
                conversation = [comment];
            }

            const commentArray = {
                comments: conversation,
                notification,
            };

            const scenario = {
                ...this.props.data,
                comments: commentArray,
            };

            const isPassed = true;
            const normalScenario = loadedScenario.type === "scenario";

            const isShared = loadedScenario.type === "shared";

            if (isPassed || normalScenario) {
                this.props.editScenarioComments(scenario, (err, data) => {
                    const commentsLength =
                        (this.state.data && this.state.data.comments) || [];
                    if (data) {
                        this.setState({
                            data: {
                                notification: [...notification],
                                comments: [...commentsLength, comment],
                            },
                        });
                    }
                });
            }
            if (isShared && loggedInUser) {
                this.props.fetchSharedScenarios(loggedInUser.account);
            }
        } else {
            if (!this.state.inputValue) {
                swal({
                    icon: "info",
                    text: "Please input your comment",
                });
            } else {
                swal({
                    icon: "info",
                    text: "Please login or create an account to comment in a scenario",
                });
            }
        }
    };

    cancelComment = () => {
        this.setState({ inputValue: "" });
    };

    onHandleToggle = (e) => {
        const id = e.target.id;
        const { toggledComments } = this.state;
        if (toggledComments.includes(id)) {
            const filtered = toggledComments.filter((toggledId) => {
                return toggledId !== id;
            });
            this.setState({ toggledComments: [...filtered] });
        } else {
            this.setState({ toggledComments: [...toggledComments, id] });
        }
    };

    onHandleThreadInput = (e) => {
        this.setState({ threadInput: e.target.value });
    };

    submitThread = (e) => {
        const id = e.target.id;
        const { data, threadInput } = this.state;

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const loadedScenario = this.props.data;

        if (loggedInUser && threadInput) {
            const str = this.state.threadInput;
            const tags = Array.from(str.matchAll("@[a-zA-Z0-9]+"), (m) => m[0]);

            const commentsArray = data.comments.map((comment) => {
                if (comment.id === id) {
                    const threads = {
                        comment: threadInput,
                        image: null,
                        time: new Date(),
                        name: loggedInUser.name,
                        scenarioId: loadedScenario.id,
                        commentId: comment.id,
                        id: uuid.v4(),
                    };

                    let commentThreads;
                    if (comment.threads) {
                        commentThreads = [...comment.threads, threads];
                    } else {
                        commentThreads = [threads];
                    }

                    const newComment = {
                        ...comment,
                        threads: commentThreads,
                    };
                    return newComment;
                }
                return comment;
            });

            let nodeIds;
            let nodes;

            if (this.props.focus) {
                nodeIds = [this.props.focus.id];
            } else {
                const filteredNodes = this.state.nodes.filter((node) => {
                    // TODO: Tags are not a part of the event structure, replaced with name as temp fix
                    return tags.includes(node.name);
                });

                let ids = [];
                filteredNodes.map((node) => {
                    return ids.push(node.id);
                });
                nodes = filteredNodes;
                nodeIds = ids;
            }

            let message;
            if (this.props.focus) {
                message = `Add Thread on `;
            } else {
                message = `Add Thread on `;
            }

            const notif = {
                message,
                commenterAccount: loggedInUser.account,
                commenterName: loggedInUser.name,
                commenterImage: null,
                scenarioId: loadedScenario.id,
                nodeId: nodeIds,
                nodes,
                scenario: loadedScenario,
                threads: null,
                seen: false,
                id: uuid.v4(),
                time: new Date(),
            };

            let notification;
            if (
                this.props.data &&
                this.props.data.comments &&
                this.props.data.comments.notification
            ) {
                notification = [
                    ...this.props.data.comments.notification,
                    notif,
                ];
            } else {
                notification = [notif];
            }

            const scenario = {
                ...this.props.data,
                comments: { comments: commentsArray, notification },
            };

            const isPassed = this.isSharedPassed(loadedScenario);
            const normalScenario = loadedScenario.type === "scenario";

            const isShared = loadedScenario.type === "shared";

            if (isPassed || normalScenario) {
                this.props.editScenarioComments(scenario, (err, data) => {
                    if (data) {
                        this.setState({
                            data: {
                                notification: [
                                    ...this.state.data.notification,
                                    notification,
                                ],
                                comments: commentsArray,
                            },
                        });
                    }
                });
            }
            if (isShared && loggedInUser) {
                this.props.fetchSharedScenarios(loggedInUser.account);
            }
        } else {
            if (!threadInput) {
                swal({
                    icon: "info",
                    text: "Please input your comment",
                });
            } else {
                swal({
                    icon: "info",
                    text: "Please login or create an account to comment in a scenario",
                });
            }
        }
    };

    editComment = (id) => {
        this.setState({ editComment: id });
    };

    cancelEditComment = () => {
        this.setState({ editComment: null, editValue: null });
    };

    cancelEditThread = () => {
        this.setState({
            editThreadId: null,
            threadEditValue: null,
        });
    };

    updateComment = (e) => {
        const id = e.target.id;
        const { data, editValue } = this.state;
        const loadedScenario = this.props.data;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        const str = this.state.editValue;
        const tags = Array.from(str.matchAll("@[a-zA-Z0-9]+"), (m) => m[0]);

        const commentsArray = data.comments.map((comment) => {
            if (comment.id === id) {
                const newComment = {
                    ...comment,
                    comment: editValue,
                };
                return newComment;
            }
            return comment;
        });

        let nodeIds;
        let nodes;

        if (this.props.focus) {
            nodeIds = [this.props.focus.id];
        } else {
            const filteredNodes = this.state.nodes.filter((node) => {
                // TODO: Tags are not a part of the event structure, replaced with name as temp fix
                return tags.includes(node.name);
            });

            let ids = [];
            filteredNodes.map((node) => {
                return ids.push(node.id);
            });
            nodes = filteredNodes;
            nodeIds = ids;
        }

        let message;
        if (this.props.focus) {
            message = `Update a Node Comment in`;
        } else {
            message = `Update a Scenario Comment in`;
        }

        const notif = {
            message,
            commenterAccount: loggedInUser.account,
            commenterName: loggedInUser.name,
            commenterImage: null,
            scenarioId: loadedScenario.id,
            nodeId: nodeIds,
            threads: null,
            nodes,
            scenario: loadedScenario,
            seen: false,
            id: uuid.v4(),
            time: new Date(),
        };

        let notification;
        if (
            this.props.data &&
            this.props.data.comments &&
            this.props.data.comments.notification
        ) {
            notification = [...this.props.data.comments.notification, notif];
        } else {
            notification = [notif];
        }

        const scenario = {
            ...this.props.data,
            comments: { comments: commentsArray, notification },
        };

        const isPassed = this.isSharedPassed(loadedScenario);
        const normalScenario = loadedScenario.type === "scenario";

        const isShared = loadedScenario.type === "shared";

        if ((isPassed || normalScenario) && editValue) {
            this.props.editScenarioComments(scenario, (err, data) => {
                if (data) {
                    this.setState({
                        data: {
                            notification: [...this.state.data.notification],
                            comments: commentsArray,
                        },
                    });
                }
            });

            // this.props.getUserScenarios();
        }
        if (isShared && loggedInUser) {
            this.props.fetchSharedScenarios(loggedInUser.account);
        }
    };

    updateThread = (commentData, threadData) => {
        const { data, threadEditValue } = this.state;
        const loadedScenario = this.props.data;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        const str = this.state.threadEditValue;
        const tags = Array.from(str.matchAll("@[a-zA-Z0-9]+"), (m) => m[0]);

        const commentsArray = data.comments.map((comment) => {
            if (comment.id === commentData.id) {
                const updatedThread = comment.threads.map((thread) => {
                    if (thread.id === threadData.id) {
                        const newThread = {
                            ...thread,
                            comment: threadEditValue,
                        };
                        return newThread;
                    }
                    return thread;
                });
                const newComment = {
                    ...comment,
                    threads: updatedThread,
                };
                return newComment;
            }
            return comment;
        });

        let nodeIds;
        let nodes;

        if (this.props.focus) {
            nodeIds = [this.props.focus.id];
        } else {
            const filteredNodes = this.state.nodes.filter((node) => {
                // TODO: Tags are not a part of the event structure, replaced with name as temp fix
                return tags.includes(node.name);
            });

            let ids = [];
            filteredNodes.map((node) => {
                return ids.push(node.id);
            });
            nodes = filteredNodes;
            nodeIds = ids;
        }

        let message;
        if (this.props.focus) {
            message = ` Update a Node Comment in`;
        } else {
            message = ` Update a Scenario Comment in`;
        }

        const notif = {
            message,
            commenterAccount: loggedInUser.account,
            commenterName: loggedInUser.name,
            commenterImage: null,
            scenarioId: loadedScenario.id,
            nodeId: nodeIds,
            nodes,
            scenario: loadedScenario,
            threads: null,
            seen: false,
            id: uuid.v4(),
            time: new Date(),
        };

        let notification;
        if (
            this.props.data &&
            this.props.data.comments &&
            this.props.data.comments.notification
        ) {
            notification = [...this.props.data.comments.notification, notif];
        } else {
            notification = [notif];
        }

        const scenario = {
            ...this.props.data,
            comments: { comments: commentsArray, notification },
        };

        const isPassed = this.isSharedPassed(loadedScenario);
        const normalScenario = loadedScenario.type === "scenario";

        const isShared = loadedScenario.type === "shared";

        if ((isPassed || normalScenario) && threadEditValue) {
            this.props.editScenarioComments(scenario, (err, data) => {
                if (data) {
                    this.setState({
                        data: {
                            notification: [...this.state.data.notification],
                            comments: commentsArray,
                        },
                    });
                }
            });
        }
        if (isShared && loggedInUser) {
            this.props.fetchSharedScenarios(loggedInUser.account);
        }
    };

    editThreadValue = (threadId) => {
        this.setState({ editThreadId: threadId });
    };

    onHandleEditThread = (e) => {
        this.setState({ threadEditValue: e.target.value });
    };

    deleteThread = (commentData, threadId) => {
        const { data } = this.state;
        const loadedScenario = this.props.data;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        const updatedData = data.comments.map((comment) => {
            if (comment.id === commentData.id) {
                const filteredThread = comment.threads.filter((thread) => {
                    return thread.id !== threadId;
                });
                const newComment = {
                    ...comment,
                    threads: filteredThread,
                };
                return newComment;
            }
            return comment;
        });

        const scenario = {
            ...this.props.data,
            comments: { comments: updatedData },
        };

        const isPassed = this.isSharedPassed(loadedScenario);
        const normalScenario = loadedScenario.type === "scenario";

        const isShared = loadedScenario.type === "shared";

        if (isPassed || normalScenario) {
            this.props.editScenarioComments(scenario, (err, data) => {
                if (data) {
                    this.setState({
                        data: {
                            notification: [...this.state.data.notification],
                            comments: updatedData,
                        },
                    });
                }
            });
        }
        if (isShared && loggedInUser) {
            this.props.fetchSharedScenarios(loggedInUser.account);
        }
    };

    deleteComment = (commentId) => {
        // const { data } = this.state;
        const loadedScenario = this.props.data;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        const updatedData = this.state.data.comments.filter((comment) => {
            return comment.id !== commentId;
        });

        const scenario = {
            ...this.props.data,
            comments: { comments: updatedData },
        };

        const isPassed = this.isSharedPassed(loadedScenario);
        const normalScenario = loadedScenario.type === "scenario";

        const isShared = loadedScenario.type === "shared";

        if (isPassed || normalScenario) {
            this.props.editScenarioComments(scenario, (err, data) => {
                if (data) {
                    this.setState({
                        data: {
                            notification: [...this.state.data.notification],
                            comments: updatedData,
                        },
                    });
                }
            });
        }
        if (isShared && loggedInUser) {
            this.props.fetchSharedScenarios(loggedInUser.account);
        }
    };

    deleteCommentTrigger = (id) => {
        this.setState({ deleteComment: id });
    };

    deleteThreadTrigger = (commentData, threadId) => {
        this.setState({
            deleteThreadParent: commentData,
            deleteThreadId: threadId,
        });
    };

    cancelCommentDelete = () => {
        this.setState({ deleteComment: null });
    };

    cancelThreadDelete = () => {
        this.setState({
            deleteThreadParent: null,
            deleteThreadId: null,
        });
    };

    showNodeList = () => {
        this.setState({ showNodeList: true });
    };

    onHandleTagSelected = (selectedNode) => {
        this.setState({
            selected: [...this.state.selected, selectedNode.item],
        });
    };

    render() {
        // const { inputValue } = this.state;

        // const { data } = this.state;
        // const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        // const commentButtonActive = inputValue;

        return (
            <h4 className={styles.commingSoonText}>Comments coming soon.</h4>
        )

        // return (
        //     <div className={styles.commentRoot}>
        //         <div className={styles.inputContainer}>
        //             <div>
        //                 {/* <textarea
        //                     value={inputValue}
        //                     onChange={this.onHandleInput}
        //                     className={styles.commentInput}
        //                     placeholder="Add Comment"
        //                 /> */}
        //                 <ReactTextareaAutocomplete
        //                     placeholder="you can tag a node by using @"
        //                     className={styles.commentInputContainer}
        //                     loadingComponent={() => <span>Loading</span>}
        //                     onChange={this.onHandleInput}
        //                     trigger={{
        //                         "@": {
        //                             dataProvider: (_token) => {
        //                                 return this.state.nodes;
        //                             },
        //                             component: (data) => {
        //                                 // TODO: Tags are not a part of the event structure, replaced with name as temp fix
        //                                 return (
        //                                     <div>
        //                                         {data.entity.name}
        //                                         {""}
        //                                     </div>
        //                                 );
        //                             },
        //                             output: (item, _trigger) => {
        //                                 return item.name;
        //                             },
        //                         },
        //                     }}
        //                     ref={(rta) => {
        //                         this.rta = rta;
        //                     }}
        //                     onItemSelected={(selected) =>
        //                         this.onHandleTagSelected(selected)
        //                     }
        //                     value={this.state.inputValue}
        //                 />
        //             </div>

        //             <div className={styles.buttonContainer}>
        //                 <div
        //                     onClick={this.cancelComment}
        //                     className={
        //                         commentButtonActive
        //                             ? styles.commentButton
        //                             : styles.commentButtonDisabled
        //                     }
        //                 >
        //                     Cancel
        //                 </div>
        //                 <div
        //                     onClick={this.submitComment}
        //                     className={
        //                         commentButtonActive
        //                             ? styles.commentButton
        //                             : styles.commentButtonDisabled
        //                     }
        //                 >
        //                     Add
        //                 </div>
        //             </div>
        //         </div>
        //         <div className={styles.messageContainer}>
        //             {data &&
        //                 data.comments &&
        //                 data.comments.map((comment, i) => {
        //                     const hasThread =
        //                         comment.threads && comment.threads.length > 0;
        //                     const showThread =
        //                         this.state.toggledComments.includes(comment.id);

        //                     const onEdit =
        //                         comment.id === this.state.editComment;

        //                     const hasAccess = !loggedInUser
        //                         ? false
        //                         : comment.name === loggedInUser.name;

        //                     const deleteItem =
        //                         this.state.deleteComment === comment.id;

        //                     return (
        //                         <div className={styles.comment} key={i}>
        //                             <div className={styles.commentDate}>
        //                                 {moment(comment.time).format(
        //                                     "h:mm a, MMM Do"
        //                                 )}
        //                             </div>
        //                             <div className={styles.commentInfo}>
        //                                 <img
        //                                     alt="alt"
        //                                     className={styles.profilePhoto}
        //                                     src={profilePlaceholder}
        //                                 />
        //                                 <div className={styles.name}>
        //                                     {comment.name}
        //                                 </div>
        //                                 {hasAccess && (
        //                                     <div
        //                                         className={
        //                                             styles.commentButtons
        //                                         }
        //                                     >
        //                                         <img
        //                                             alt="alt"
        //                                             src={editSvg}
        //                                             className={
        //                                                 styles.commentIcon
        //                                             }
        //                                             onClick={() =>
        //                                                 this.editComment(
        //                                                     comment.id
        //                                                 )
        //                                             }
        //                                         />
        //                                         <img
        //                                             alt="alt"
        //                                             onClick={() =>
        //                                                 this.deleteCommentTrigger(
        //                                                     comment.id
        //                                                 )
        //                                             }
        //                                             src={deleteSvg}
        //                                             className={
        //                                                 styles.commentIcon
        //                                             }
        //                                         />
        //                                     </div>
        //                                 )}
        //                             </div>

        //                             {onEdit ? (
        //                                 <div>
        //                                     <textarea
        //                                         value={this.state.editValue}
        //                                         onChange={
        //                                             this.onHandleEditInput
        //                                         }
        //                                         className={styles.threadText}
        //                                     />
        //                                     <div
        //                                         className={
        //                                             styles.buttonContainer
        //                                         }
        //                                     >
        //                                         <div
        //                                             onClick={
        //                                                 this.cancelEditComment
        //                                             }
        //                                             className={
        //                                                 styles.commentButton
        //                                             }
        //                                             id={comment.id}
        //                                         >
        //                                             Cancel
        //                                         </div>
        //                                         <div
        //                                             onClick={this.updateComment}
        //                                             className={
        //                                                 styles.commentButton
        //                                             }
        //                                             id={comment.id}
        //                                         >
        //                                             Update
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             ) : (
        //                                 <div className={styles.commentContent}>
        //                                     {comment.comment}
        //                                 </div>
        //                             )}

        //                             {!showThread && (
        //                                 <div className={styles.toggleContainer}>
        //                                     <div
        //                                         onClick={this.onHandleToggle}
        //                                         className={styles.toggleButton}
        //                                         id={comment.id}
        //                                     >
        //                                         {hasThread
        //                                             ? "See Thread"
        //                                             : "Reply"}
        //                                     </div>
        //                                 </div>
        //                             )}

        //                             {showThread && (
        //                                 <div className={styles.threadTextArea}>
        //                                     <div
        //                                         className={
        //                                             styles.threadContainer
        //                                         }
        //                                     >
        //                                         {comment.threads &&
        //                                             comment.threads.map(
        //                                                 (thread, i) => {
        //                                                     const threadAccess =
        //                                                         thread.name ===
        //                                                         (loggedInUser &&
        //                                                             loggedInUser.name);

        //                                                     const editThread =
        //                                                         thread.id ===
        //                                                         this.state
        //                                                             .editThreadId;

        //                                                     const deleteThread =
        //                                                         thread.id ===
        //                                                         this.state
        //                                                             .deleteThreadId;
        //                                                     return (
        //                                                         <div
        //                                                             className={
        //                                                                 styles.thread
        //                                                             }
        //                                                             key={i}
        //                                                         >
        //                                                             <div
        //                                                                 className={
        //                                                                     styles.threadDate
        //                                                                 }
        //                                                             >
        //                                                                 {moment(
        //                                                                     thread.time
        //                                                                 ).format(
        //                                                                     "h:mm a, MMM Do"
        //                                                                 )}
        //                                                             </div>
        //                                                             <div
        //                                                                 className={
        //                                                                     styles.threadInfo
        //                                                                 }
        //                                                             >
        //                                                                 <img
        //                                                                     alt="alt"
        //                                                                     className={
        //                                                                         styles.threadProfilePhoto
        //                                                                     }
        //                                                                     src={
        //                                                                         thread.image
        //                                                                     }
        //                                                                 />
        //                                                                 <div
        //                                                                     className={
        //                                                                         styles.threadName
        //                                                                     }
        //                                                                 >
        //                                                                     {
        //                                                                         thread.name
        //                                                                     }
        //                                                                 </div>
        //                                                                 {threadAccess && (
        //                                                                     <div
        //                                                                         className={
        //                                                                             styles.commentButtons
        //                                                                         }
        //                                                                     >
        //                                                                         <img
        //                                                                             alt="alt"
        //                                                                             src={
        //                                                                                 editSvg
        //                                                                             }
        //                                                                             className={
        //                                                                                 styles.commentIcon
        //                                                                             }
        //                                                                             onClick={() =>
        //                                                                                 this.editThreadValue(
        //                                                                                     thread.id
        //                                                                                 )
        //                                                                             }
        //                                                                         />
        //                                                                         <img
        //                                                                             alt="alt"
        //                                                                             onClick={() =>
        //                                                                                 this.deleteThreadTrigger(
        //                                                                                     comment,
        //                                                                                     thread.id
        //                                                                                 )
        //                                                                             }
        //                                                                             src={
        //                                                                                 deleteSvg
        //                                                                             }
        //                                                                             className={
        //                                                                                 styles.commentIcon
        //                                                                             }
        //                                                                         />
        //                                                                     </div>
        //                                                                 )}
        //                                                             </div>
        //                                                             {editThread ? (
        //                                                                 <div>
        //                                                                     <textarea
        //                                                                         className={
        //                                                                             styles.threadText
        //                                                                         }
        //                                                                         value={
        //                                                                             this
        //                                                                                 .state
        //                                                                                 .threadEditValue
        //                                                                         }
        //                                                                         onChange={
        //                                                                             this
        //                                                                                 .onHandleEditThread
        //                                                                         }
        //                                                                     />
        //                                                                     <div
        //                                                                         className={
        //                                                                             styles.buttonContainer
        //                                                                         }
        //                                                                     >
        //                                                                         <div
        //                                                                             onClick={
        //                                                                                 this
        //                                                                                     .cancelEditThread
        //                                                                             }
        //                                                                             className={
        //                                                                                 styles.commentButton
        //                                                                             }
        //                                                                         >
        //                                                                             Cancel
        //                                                                         </div>
        //                                                                         <div
        //                                                                             onClick={() =>
        //                                                                                 this.updateThread(
        //                                                                                     comment,
        //                                                                                     thread
        //                                                                                 )
        //                                                                             }
        //                                                                             className={
        //                                                                                 styles.commentButton
        //                                                                             }
        //                                                                             id={
        //                                                                                 thread.id
        //                                                                             }
        //                                                                         >
        //                                                                             Update
        //                                                                         </div>
        //                                                                     </div>
        //                                                                 </div>
        //                                                             ) : (
        //                                                                 <div
        //                                                                     className={
        //                                                                         styles.threadContent
        //                                                                     }
        //                                                                 >
        //                                                                     {
        //                                                                         thread.comment
        //                                                                     }
        //                                                                 </div>
        //                                                             )}
        //                                                             {deleteThread && (
        //                                                                 <div
        //                                                                     className={
        //                                                                         styles.buttonContainer
        //                                                                     }
        //                                                                 >
        //                                                                     <div
        //                                                                         className={
        //                                                                             styles.deleteButton
        //                                                                         }
        //                                                                         onClick={() =>
        //                                                                             this.deleteThread(
        //                                                                                 this
        //                                                                                     .state
        //                                                                                     .deleteThreadParent,
        //                                                                                 this
        //                                                                                     .state
        //                                                                                     .deleteThreadId
        //                                                                             )
        //                                                                         }
        //                                                                     >
        //                                                                         Yes,
        //                                                                         Delete
        //                                                                     </div>
        //                                                                     <div
        //                                                                         className={
        //                                                                             styles.deleteButtonActive
        //                                                                         }
        //                                                                         onClick={
        //                                                                             this
        //                                                                                 .cancelThreadDelete
        //                                                                         }
        //                                                                     >
        //                                                                         Cancel
        //                                                                     </div>
        //                                                                 </div>
        //                                                             )}
        //                                                         </div>
        //                                                     );
        //                                                 }
        //                                             )}
        //                                     </div>

        //                                     <textarea
        //                                         className={styles.threadText}
        //                                         placeholder={`reply to ${comment.name}`}
        //                                         value={this.state.threadInput}
        //                                         onChange={
        //                                             this.onHandleThreadInput
        //                                         }
        //                                     />
        //                                     <div
        //                                         className={
        //                                             styles.buttonContainer
        //                                         }
        //                                     >
        //                                         <div
        //                                             onClick={this.submitThread}
        //                                             className={
        //                                                 styles.commentButton
        //                                             }
        //                                             id={comment.id}
        //                                         >
        //                                             Send
        //                                         </div>
        //                                     </div>

        //                                     <div
        //                                         className={
        //                                             styles.showLessButton
        //                                         }
        //                                         onClick={this.onHandleToggle}
        //                                         id={comment.id}
        //                                     >
        //                                         Show less{" "}
        //                                     </div>
        //                                 </div>
        //                             )}
        //                             {deleteItem && (
        //                                 <div className={styles.buttonContainer}>
        //                                     <div
        //                                         className={styles.deleteButton}
        //                                         onClick={() =>
        //                                             this.deleteComment(
        //                                                 this.state.deleteComment
        //                                             )
        //                                         }
        //                                     >
        //                                         Yes, Delete
        //                                     </div>
        //                                     <div
        //                                         className={
        //                                             styles.deleteButtonActive
        //                                         }
        //                                         onClick={
        //                                             this.cancelCommentDelete
        //                                         }
        //                                     >
        //                                         Cancel
        //                                     </div>
        //                                 </div>
        //                             )}
        //                         </div>
        //                     );
        //                 })}
        //         </div>
        //     </div>
        // );
    }
}

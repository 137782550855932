// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import CustomerCard from "../Card/customerCard";

// Import Connection Image (if exists)
import CustomerConnection from "Assets/_optionDescriptionIcons/Customer, Growth, Churn white.jpg";

// Import Coloured PNGs

// TODO Grab pngs from Michelle or Jon
import customerBlack from "../../Assets/_eventNodeTypes/customers-black-updated.svg";
import customerBlue from "../../Assets/_eventNodeTypes/customers-blue.png";
import customerWhite from "../../Assets/_eventNodeTypes/customers-white.png";
import customerGrey from "../../Assets/_eventNodeTypes/customers-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import CustomerInputExperimental from "Components/InputContainer/CustomerInputExperimental/CustomerInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - customerObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - customerObject.checkInput.call(customerObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const customerObject = {
    constant: function () {
        return Customer_Constant();
    },

    name: function () {
        return "Customer";
    },

    checkInput: function (state) {
        return Customer_CheckInput(state);
    },

    svg: function () {
        return Customer_Svg();
    },

    eventNodeMenu: function (focus) {
        return Customer_EventNodeMenu(focus);
    },

    indexText: function () {
        return Customer_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Customer_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Customer_OptionDescription();
    },

    colouredPngs: function () {
        return Customer_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Customer_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Customer_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Customer_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name || !entity.data.customer || !entity.startDate)
            return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Customer_Constant = () => {
    return "2aa289fa-a0c6-4beb-8cec-234ebe938b9a";
};

// EFFECTS: Returns black png / svg for event
const Customer_Svg = () => {
    return customerBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Customer_EventNodeMenu = (focus) => {
    return <CustomerCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Customer_IndexText = () => {
    return "The Customer Event specifies an initial customer count. This event can be used with Customer Growth or Customer Churn to increase/decrease the customer count over time.";
};

// EFFECTS: Returns all icons for event ??
const Customer_Icons = () => {
    let pngs = new Map([
        ["black", customerBlack],
        ["blue", customerBlue],
        ["white", customerWhite],
        ["grey", customerGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Customer_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Customer_InputComponentInfoPassing = (minProps) => {
    return <CustomerInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const Customer_OptionDescription = () => {
    const tags = ["Option"];
    const type = customerObject.name();
    const description =
        "A Customer Event contains all of the information to track the number of customers, in a group or cohort, over time. A Customer Event can contain multiple cohorts.";
    const usedForText =
        "Use the Customer Event to create general cohorts of Users, Subscribers or Clients for downstream calculations. For example, any month-over-month Growth, Churn, or Software-as-a-Service type calculations will need an upstream Customer Event to calculate. To calculate or track Revenue or Expenses associated with a specific, individual client or user, we recommend using the Income, Debit/Credit or Contract Event.";
    const connection = "";
    const connectionImage = CustomerConnection;
    const learnMoreLink = "https://www.investopedia.com/terms/c/customer.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Customer_SideModalImage = () => {
    return sideImage;
};

const Customer_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { getTodayDateString } from "./initialBalanceInputsHandler";

export const importInputsHandler = (
    value: string,
    id: "entityName" | "importType",
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    data.lastUpdated = getTodayDateString();

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "importType":
            data.importType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import { debtRepaymentObject } from "Components/Registry/Debt Repayment";
import {
    Description,
    SelectDropDown,
    Value,
    // Url,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    // StarsRating,
    EntityName,
    BasicHeading,
    ModalRowHalf,
    MuiCalendar,
} from "../Components";
// import Calendar from "Components/Calendar";
import { debtObject } from "Components/Registry/Debt";

import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

const options = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

export default function DebtRepaymentInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    updateAccount,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleEntityStateChange,
    handleOnChange,
    handleDateSelection,
    checkIfFieldIsOverridden,
    isOverrideValid,
    createOverride,
    updateOverride,
    getOverride,
}) {
    const currentEntityObject = entitiesMap?.[currentEntity];

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={debtRepaymentObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={debtRepaymentObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[debtObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={currentEntityObject?.name}
                        onChangeInput={handleOnChange}
                        entityData={currentEntity}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ModalRow>
                    <BasicHeading copy="Debt Repayment Details" />
                </ModalRow>
                <ModalRow>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={currentEntityObject?.data?.fixedVsRemaining}
                        name="radio-buttons-group"
                        onChange={(e) =>
                            handleOnChange(e, "fixedVsRemaining", 1)
                        }
                    >
                        <FormControlLabel
                            value="fixed"
                            sx={{
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                            control={
                                <Radio
                                    sx={{
                                        "&.Mui-checked": {
                                            color: "#f7ab5f",
                                        },
                                    }}
                                />
                            }
                            label="Fixed Amount"
                        />
                        <FormControlLabel
                            value="remaining"
                            sx={{
                                color: "rgba(0, 0, 0, 0.87)",
                            }}
                            control={
                                <Radio
                                    sx={{
                                        "&.Mui-checked": {
                                            color: "#f7ab5f",
                                        },
                                    }}
                                />
                            }
                            label="Remaining Balance"
                        />
                    </RadioGroup>
                </ModalRow>
                {currentEntityObject?.data?.fixedVsRemaining === "fixed" && (
                    <>
                        <ModalRow>
                            <ModalRowHalf>
                                <Value
                                    label="Debt Repayment Amount"
                                    onChangeInput={handleOnChange}
                                    value={currentEntityObject?.data.value}
                                    id="value"
                                    unit="$"
                                    variant={"outlined"}
                                    required={true}
                                    override={isOverrideValid(
                                        entitiesMap,
                                        currentEntity
                                    )}
                                    entityData={currentEntity}
                                    overridden={checkIfFieldIsOverridden(
                                        "value"
                                    )}
                                    createOverride={createOverride}
                                    updateOverride={updateOverride}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        <ModalRow>
                            <InputDropDownButton
                                callback={updateAccount}
                                initialValue={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.accountName ?? ""
                                }
                                required={true}
                            />
                        </ModalRow>
                        <ModalRow>
                            <InputDropDownButton
                                callback={updateAccount}
                                initialValue={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.contraAccountName
                                }
                                contraAccount={true}
                                accountsSelectedForContra={
                                    entitiesMap?.[currentEntity]?.data
                                        .accountIds.length
                                        ? entitiesMap?.[currentEntity]?.data
                                              .accountIds[0]
                                        : ""
                                }
                            />
                        </ModalRow>
                        <ModalRow>
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={currentEntityObject?.cadence}
                                    options={options}
                                    className="select-dropdown"
                                    id="cadence"
                                    label="Select Frequency"
                                    required={true}
                                    disabled={
                                        getOverride("value") !== undefined
                                    }
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <MuiCalendar
                                    id="startDate"
                                    value={
                                        currentEntityObject?.startDate === ""
                                            ? null
                                            : currentEntityObject?.startDate
                                    }
                                    required={true}
                                    onChangeInput={handleDateSelection}
                                    label="Start Date"
                                />
                            </ModalRowHalf>
                            {entitiesMap?.[currentEntity]?.cadence !==
                                "one-time" && (
                                <ModalRowHalf>
                                    <MuiCalendar
                                        id="endDate"
                                        value={
                                            currentEntityObject?.endDate === ""
                                                ? null
                                                : currentEntityObject?.endDate
                                        }
                                        onChangeInput={handleDateSelection}
                                        label="End Date"
                                        helperText="An end date is optional"
                                    />
                                </ModalRowHalf>
                            )}
                        </ModalRow>
                    </>
                )}
                {currentEntityObject?.data?.fixedVsRemaining ===
                    "remaining" && (
                    <>
                        <ModalRow>
                            <InputDropDownButton
                                callback={updateAccount}
                                initialValue={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.accountName ?? ""
                                }
                                required={true}
                            />
                        </ModalRow>
                        <ModalRow>
                            <InputDropDownButton
                                callback={updateAccount}
                                initialValue={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.contraAccountName
                                }
                                contraAccount={true}
                                accountsSelectedForContra={
                                    entitiesMap?.[currentEntity]?.data
                                        .accountIds.length
                                        ? entitiesMap?.[currentEntity]?.data
                                              .accountIds[0]
                                        : ""
                                }
                            />
                        </ModalRow>
                        <ModalRow>
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={currentEntityObject?.cadence}
                                    options={options}
                                    className="select-dropdown"
                                    id="cadence"
                                    label="Select Frequency"
                                    disabled={true}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <MuiCalendar
                                    id="paymentDate"
                                    value={
                                        currentEntityObject?.data
                                            ?.paymentDate === ""
                                            ? null
                                            : currentEntityObject?.data
                                                  ?.paymentDate
                                    }
                                    onChangeInput={handleDateSelection}
                                    label="Payment Date"
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

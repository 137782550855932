import React, { Component } from "react";
import "./Card.css";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";

class BudgetCardAlt extends Component {
    state = {
        showOptions: false,
        budgetSelected: 0,
        toggleAccount: [],
        showSettings: false,
    };

    toggleAccount = (e) => {
        const id = parseInt(e.target.id);
        const { toggleAccount } = this.state;

        if (toggleAccount.includes(id)) {
            const removeId = toggleAccount.filter((item) => {
                return item !== id;
            });
            this.setState({ toggleAccount: removeId });
        } else {
            this.setState({ toggleAccount: [...toggleAccount, id] });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const { metadata } = this.props.data;
        const { toggleAccount } = this.state;
        const { version } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="BankLabel">
                        {metadata &&
                            metadata.selected &&
                            metadata.selected.length}{" "}
                        Budget Categories
                    </div>
                </div>
                <div className="Row">
                    <div
                        className={
                            this.props.data.metadata.description
                                ? "Description"
                                : "NoDescription"
                        }
                    >
                        {this.props.data.metadata.description}
                    </div>
                </div>
                <div className="selectedAccountsContainer">
                    {metadata &&
                        metadata.selected &&
                        metadata.selected.map((budget, i) => {
                            const isToggled = toggleAccount.includes(i);

                            return (
                                <div key={i}>
                                    <div key={i} className="selectedAccounts">
                                        <div className="nicknameContainer">
                                            <img
                                                alt="alt"
                                                src={
                                                    isToggled
                                                        ? dropdownSvg
                                                        : expandSvg
                                                }
                                                onClick={this.toggleAccount}
                                                id={i}
                                                className="selectedIcon"
                                            />
                                            <img
                                                alt="alt"
                                                className="cardIcon"
                                                src={budget.icon}
                                            />
                                            <div className="nickname">
                                                {budget.title}
                                            </div>
                                            <div>${budget.budget}</div>
                                        </div>

                                        {/* <div className="balanceContainer">
                                            <div>
                                                ${budget.budget}
                                                /mon
                                            </div>
                                            <div>
                                                ${balance}{" "}
                                                {balance >= 0 ? "left" : "over"}
                                            </div>
                                        </div> */}
                                        {/* <div className="sliderContainer">
                                            {" "}
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                value={percentage}
                                                id="range"
                                                className={
                                                    balance >= 0
                                                        ? "slider"
                                                        : "sliderOver"
                                                }
                                            />
                                        </div> */}
                                    </div>
                                    {isToggled && (
                                        <div className="popUpContainer">
                                            <div className="Row">
                                                <div className="key">
                                                    Inflation
                                                </div>
                                                <div className="value">
                                                    {`+${budget.inflationRate}%`}
                                                </div>
                                            </div>
                                            <div className="Row">
                                                <div className="key">
                                                    Historical Average
                                                </div>
                                                <div className="value">
                                                    ${budget.budget}
                                                </div>
                                            </div>

                                            {budget.subCategoriesList.length >
                                                0 && (
                                                <div>
                                                    <div className="Row">
                                                        <div className="line" />
                                                    </div>
                                                    {budget.subCategoriesList.map(
                                                        (subCategory) => {
                                                            return (
                                                                <div
                                                                    className="Row"
                                                                    key={
                                                                        subCategory
                                                                    }
                                                                >
                                                                    <div className="key">
                                                                        {
                                                                            subCategory.title
                                                                        }
                                                                    </div>
                                                                    <div className="value">
                                                                        $
                                                                        {
                                                                            subCategory.budget
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default BudgetCardAlt;

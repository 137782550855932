import React, { Component } from "react";
import styles from "./../index.module.css";

export default class PromptPopUp extends Component {
    state = {
        popUpMounted: false,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({ popUpMounted: true });
        }, 100);
    }

    closePopUpAnimation = () => {
        this.setState({ popUpMounted: false });

        setTimeout(() => {
            this.props.handleCloseClientArchivePrompt();
        }, 300);
    };

    render() {
        const { popUpMounted } = this.state;
        const { client, handleClientArchived, handleCloseClientArchivePrompt } =
            this.props;

        return (
            <div
                className={
                    popUpMounted
                        ? styles.promptPopUpBackgroundMounted
                        : styles.promptPopUpBackground
                }
            >
                <div
                    className={
                        popUpMounted
                            ? styles.promptPopUpMounted
                            : styles.promptPopUp
                    }
                >
                    <div className={styles.promptPopUpContentWrapper}>
                        <div className={styles.promptPopUpHeaderWrapper}>
                            <span className={styles.promptPopUpHeaderText}>
                                Archive client -{" "}
                            </span>
                            <span className={styles.promptPopUpHeaderName}>
                                {client.clientdata.firstName}
                            </span>
                            ?
                        </div>
                        <p className={styles.promptPopUpText}>
                            All the associated Scenarios will become
                            unavailable, but you can always reactivate this
                            client later.
                        </p>
                        <div className={styles.promptPopUpButtonsWrapper}>
                            <button
                                className={styles.promptPopUpCancelButton}
                                onClick={handleCloseClientArchivePrompt}
                            >
                                Cancel
                            </button>
                            <button
                                className={styles.promptPopUpArchiveButton}
                                onClick={() => handleClientArchived(client)}
                            >
                                Yes, Archive
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import accountUnitsData from "exampleConfigJson/allAccountLedgers.json";

/**
 * OUT DATED AND SHOULDNT BE USED
 * Takes the name of an account and returns the unit data for that account.
 */
export const getUnit = (allLedgerData, accountType) => {
    let pureAccount = accountType;
    if (pureAccount?.includes("Cumulative")) {
        const accountNameArray = accountType?.split(" ");
        accountNameArray.splice(0, 1);
        const accountNameString = accountNameArray.join(" ");
        pureAccount = accountNameString;
    }
    const foundAccount = allLedgerData?.find(
        (account) => account?.sourceName === pureAccount
    );

    return foundAccount ?? "";
};

export const formatValueWithLimitedUnits = (
    allLedgerData,
    accountId,
    value
) => {
    const foundAccount = allLedgerData?.[accountId] ?? {};
    if (foundAccount) {
        const valueWithDecimalPlaces = value?.toFixed(
            foundAccount?.unit?.nearestDecimal ?? 0
        );
        if (
            foundAccount?.unit?.display === "$" ||
            foundAccount?.unit?.display === "%"
        ) {
            if (foundAccount?.unit?.beforeValue) {
                return `${foundAccount?.unit?.display}${
                    foundAccount?.unit?.spacingBetween ? " " : ""
                }${valueWithDecimalPlaces}`;
            } else {
                return `${valueWithDecimalPlaces}${
                    foundAccount?.unit?.spacingBetween ? " " : ""
                }${foundAccount?.unit?.display}`;
            }
        } else {
            return `${valueWithDecimalPlaces}`;
        }
    } else {
        return `${value}`;
    }
};

/**
 * Takes the name of an account and a value. Returns the formatted value with the correct unit as a string.
 */
export const formatValueWithUnit = (allLedgerData, accountId, value) => {
    const pureValueString = value.replace(/,/g, "");
    const formatedValue = new Intl.NumberFormat().format(
        parseFloat(pureValueString).toFixed(2)
    );
    const foundAccount = allLedgerData?.[accountId] ?? {};
    if (foundAccount) {
        if (foundAccount?.unit?.beforeValue) {
            return `${foundAccount?.unit?.display}${
                foundAccount?.unit?.spacingBetween ? " " : ""
            }${formatedValue}`;
        } else {
            return `${formatedValue}${
                foundAccount?.unit?.spacingBetween ? " " : ""
            }${foundAccount?.unit?.display}`;
        }
    } else {
        return `${formatedValue}`;
    }
};

export const formatValueWithUnitOld = (accountType, value) => {
    let formattedValue = "";
    if (value === "" || value === "-") {
        formattedValue = " -";
    } else {
        formattedValue = new Intl.NumberFormat().format(
            parseFloat(value).toFixed(2)
        );
    }

    const accountUnitsDataArray = Object.values(
        accountUnitsData?.ledgersMetadata
    );
    let pureAccount = accountType;
    if (pureAccount?.includes("Cumulative")) {
        pureAccount = accountType?.split(" ")?.[1];
    }
    const foundAccount = accountUnitsDataArray?.find(
        (account) => account?.sourceName === pureAccount
    );
    if (foundAccount) {
        if (foundAccount?.unit?.beforeValue) {
            return `${foundAccount?.unit?.display}${
                foundAccount?.unit?.spacingBetween ? " " : ""
            }${formattedValue}`;
        } else {
            return `${formattedValue}${
                foundAccount?.unit?.spacingBetween ? " " : ""
            }${foundAccount?.unit?.display}`;
        }
    } else {
        return `${formattedValue}`;
    }
};

import React from "react";
import styles from "./index.module.css";
import backSvg from "../../Assets/_budgetIcons/back.svg";
import meSvg from "../../Assets/nodeIcons/me.png";
// import businessSvg from "../../Assets/nodeIcons/black.png";
import businessSvg from "../../Assets/_baseline/business_icon.svg";
// import EventsCanvas from "../../Components/EventsCanvas/Canvas";
// import userImage from "../../Assets/_signUpIcons/updated-business-plan.png";
import closeSvg from "../../Assets/close.svg";
import rentalSvg from "../../Assets/_baseline/rental.png";
import homeSvg from "../../Assets/_baseline/home.png";
import stepFourImage from "../../Assets/_baseline/stepFourImage.png";
import { Rent, Expense, Mortgage, House } from "../../helpers/baselineInputs";
import Options from "../../Components/Options";
// import personBlack from "../../Assets/_eventNodeTypes/person-black.png";
// import addOptionSvg from "../../Assets/_baseline/addOption.png";
import addOptionSvg from "../../Assets/_baseline/thread_icon.svg";
import { EventsModal } from "../../Components/EventsModal";
import { EventsPopUp } from "../../Components/EventsPopUp";
// import NodeMenuContainer from "../../Components/EventsMenu";
import { EventsToast } from "../../Components/EventsToast";
import { throwError } from "../../helpers/swalError";
import { AGENCY, Person } from "../../helpers/constants";
import profileSvg from "../../Assets/_home/profile.svg";
import { createBaselineScenario } from "../../helpers/baselineFunctions";
import ClientInput from "../../Components/InputContainer/ClientInput";
import { Description, Name } from "../../Components/InputContainer/Components";
import * as uuid from "uuid";
import { businessObject } from "Components/Registry/Business";
// import { meObject } from "Components/Registry/Me";
import { PasteOptionModal } from "Components/PasteOptionModal/PasteOptionModal";
import { CanvasStage } from "Components/EventsCanvas/Experimental/CanvasStage";
import BaselineRightDisplayPanel from "../../Components/RightDisplayPanel/baselineRightDisplay";
import Modal from "@mui/material/Modal";

export default class BaselineContainer extends React.Component {
    constructor(props) {
        super(props);

        this.manager = this.props.baselineDataManager;

        this.state = {
            showBaselineEditPopUp: false,
            step: 0,
            nodes: null,
            dimensions: {
                width: window.innerWidth * 1,
                height: window.innerHeight * 0.9,
            },
            steps: this.getOnboardingSteps(),
            showQuestions: false,
            selectedOption: null,
            showPanel: false,
            optionSelected: null,
            showPersonOption: false,
            baseline: null,
            baselineNode: null,
            baselineNodeSelected: null,
            baselineNodes: null,
            inputValue: null,
            toastData: null,
            deleteNode: null,
            userRole: window.localStorage.getItem("userRole"),
            isAddingLibraryEvent: false,
            clientQuery: "",
            showAddClient: false,
            loggedInUser: JSON.parse(localStorage.getItem("loggedInUser")),
            agencyClientsSelected: "active",
            showUserLandingPage: false,
        };
    }

    getAccountType = () => {
        const loadedScenario = this.props.loadedScenario || {};
        const client = this.props.agencyClients.find((cl) => {
            return (
                cl.baseline &&
                loadedScenario &&
                cl.baseline === loadedScenario.id
            );
        });
        const clientType =
            client?.clientdata?.clientType === "individual"
                ? "personal"
                : client?.clientdata?.clientType;
        return client ? clientType : this.state.userRole;
    };

    getOnboardingSteps = () => {
        return [
            {
                id: 0,
                title: "Let's start with your living arrangement!",
                subTitle:
                    "Are you currently renting an apartment or house, or living in a property you purchased?",
                options: [
                    {
                        id: 0,
                        optionId: "rental",
                        title: "Rental Apt / House",
                        image: rentalSvg,
                    },
                    {
                        id: 1,
                        optionId: "home",
                        title: "Purchased Property",
                        image: homeSvg,
                    },
                ],
            },
            {
                id: 1,
                title: "Do you have a mortgage with your property?",
                subTitle:
                    "Adding a mortgage to your house, we'll help you do the math!",
                options: [
                    {
                        id: 0,
                        optionId: "haveMortgage",
                        title: "Yes, add a mortgage to my house",
                    },
                    {
                        id: 1,
                        optionId: "noMortgage",
                        title: "No, I don't have a mortgage",
                    },
                ],
            },
            {
                id: 2,
                title: "Anything else you're paying for this property?",
                subTitle:
                    "Utilities, maintenance fee, condo fee.. you name it!",
                options: null,
            },
            {
                id: 3,
                title: "Add, connect, and calculate!",
                subTitle:
                    "Click on each node in the Baseline Canvas to fill in the blanks or add more events to your Baseline.",
                options: null,
            },
        ];
    };

    updateScenarioCanvas = (nodeData) => {
        const baselineDataManager = this.props.baselineDataManager;
        const { loadedScenario } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser) {
            if (loadedScenario && loadedScenario.data.root === nodeData.root) {
                const newScenario = {
                    ...loadedScenario,
                    data: nodeData,
                    type: "baseline",
                };
                const baselineToLoad = baselineDataManager._processForExport(
                    baselineDataManager.getActiveBaseline()
                );
                this.props.updateBaseline(newScenario, baselineDataManager);

                this.props.loadScenario(
                    newScenario,
                    this.manager,
                    baselineDataManager,
                    baselineToLoad.id,
                    this.props.eventLibrary
                );
            }
        } else {
            const newScenario = {
                ...loadedScenario,
                data: nodeData,
            };
            this.props.loadScenario(newScenario, this.manager);
        }
    };

    componentDidMount() {
        if (!this.manager) {
            // NOTE: Hack to force user to come back to baseline through another path first. There seem to be some initializations that happen outside this component.
            this.props.history.push("/");
        }

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (
            this.props.baselineDataManager &&
            !this.props.baselineDataManager.hasActiveBaseline() &&
            this.state.userRole !== "agency"
        ) {
            this.createInitalBaseline();
        } else {
            this.updateLoadedBaseline();
        }
        this.props.getUserScenarios();
        if (loggedInUser) {
            this.props.getUserData(loggedInUser).then((data) => {
                return data;
            });
        }
    }

    updateLoadedBaseline = async () => {
        if (!this.props.baselineDataManager) return;
        const baselineDataManager = this.props.baselineDataManager;
        if (!baselineDataManager.hasActiveBaseline())
            await this.props.getBaseline(this.props.baselineDataManager);

        const baselineToLoad = baselineDataManager._processForExport(
            baselineDataManager.getActiveBaseline()
        );

        this.props.loadScenario(
            baselineToLoad,
            this.manager,
            baselineDataManager,
            baselineToLoad.id,
            this.props.eventLibrary
        );

        const loadedBaseline = baselineDataManager.getActiveBaseline();
        const personNodes = loadedBaseline.data.nodes
            ? loadedBaseline.data.nodes.filter((node) => {
                  return node.type === "Me" || node.type === "Person";
              })
            : [];
        this.setState({
            baseline: loadedBaseline,
            selectedBaselineId: loadedBaseline.id,
            baselineNodes: this.manager.allNodes(),
            baselineNodeSelected: personNodes[0],
            showUserLandingPage: false,
            manager: this.manager,
        });
    };

    handleBack = () => {
        this.props.history.push("/");
    };

    onHandleNext = () => {
        const { step, selectedOption } = this.state;
        const manager = this.manager;
        const normalData = manager.exportData();
        if (!normalData.nodes || normalData.nodes.length < 4)
            throwError(
                "error",
                "Something Went Wrong",
                "We could not continue the onboarding process."
            );
        if (step === 0) {
            if (selectedOption === "rental") {
                manager.addOnboardingScenario(
                    "Rent",
                    Rent,
                    [manager.nodes[normalData.nodes[1].id]],
                    []
                );
                manager.calculate();
            } else {
                manager.addOnboardingScenario(
                    "House",
                    House,
                    [manager.nodes[normalData.nodes[1].id]],
                    []
                );
            }
            this.setState({ step: this.state.step + 1, selectedOption: null });
        } else if (step === 1) {
            if (selectedOption === "haveMortgage") {
                manager.addOnboardingScenario(
                    "Mortgage",
                    Mortgage,
                    [manager.nodes[normalData.nodes[2].id]],
                    []
                );
            }
            this.setState({ step: this.state.step + 1, selectedOption: true });
        } else if (step === 2) {
            if (this.state.inputValue) {
                const expenseData = {
                    ...Expense,
                    name: this.state.inputValue,
                };

                const value =
                    normalData.nodes.length === 4
                        ? manager.nodes[normalData.nodes[3].id]
                        : manager.nodes[normalData.nodes[2].id];

                manager.addOnboardingScenario(
                    "Expense",
                    expenseData,
                    [value],
                    []
                );
            }

            this.setState({ step: this.state.step + 1, selectedOption: true });
        } else {
            this.setState({
                showPanel: false,
                step: 0,
                selectedOption: false,
                showQuestions: false,
            });
        }
    };

    createInitalBaseline = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const manager = this.manager;

        const isPersonal = this.state.userRole === "personal";

        manager.addMeBusiness(
            true,
            isPersonal ? "Me" : businessObject.constant()
        );

        const userName = loggedInUser.name.split(" ")[0];
        const baselineScenario = {
            name: `${userName}'s Baseline`,
            description: "This is your baseline",
            data: this.manager.exportData(),
            range: 10,
            inflation: 0.019,
            type: "baseline",
        };

        this.props.createBaseline(baselineScenario, (err, data) => {
            if (data) {
                this.props.loadScenario(
                    baselineScenario,
                    this.manager,
                    this.props.baselineDataManager
                );
            } else {
                console.log(
                    "error on saving baseline from createInitalBaseline"
                );
            }
        });

        this.setState({
            showQuestions: isPersonal,
            showUserLandingPage: false,
            showPanel: isPersonal,
        });

        this.props.getBaseline(this.props.baselineDataManager).then((data) => {
            if (data && data.length > 0) {
                this.updateLoadedBaseline();
            }
        });
    };

    // handleShowQuestion = () => {
    //     const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    //     const manager = this.manager;
    //     manager.createBaseline();

    //     const isPersonal = this.state.userRole === "personal";

    //     manager.addMeBusiness(
    //         true,
    //         isPersonal ? "Me" : businessObject.constant()
    //     );

    //     const normalData = this.manager.exportData();

    //     const meNode = this.manager.nodes[normalData.nodes[1].id];
    //     manager.attachToNodes(
    //         meNode,
    //         [this.manager.nodes[normalData.nodes[0].id]],
    //         []
    //     );

    //     const normalData2 = this.manager.exportData();
    //     const userName = loggedInUser.name.split(" ")[0];
    //     const baselineScenario = {
    //         name: `${userName}'s Baseline`,
    //         description: "This is your baseline",
    //         data: normalData2,
    //         range: 10,
    //         inflation: 0.019,
    //         type: "baseline",
    //     };

    //     this.props.createBaseline(baselineScenario, (err, data) => {
    //         if (data) {
    //             this.props.loadScenario(baselineScenario, this.manager);
    //         } else {
    //             console.log("error on saving baseline");
    //         }
    //     });

    //     this.setState({
    //         showQuestions: isPersonal,
    //         showUserLandingPage: false,
    //         showPanel: isPersonal,
    //         // dimensions: {
    //         //     width: this.container.offsetWidth,
    //         //     height: this.container.offsetHeight,
    //         // },
    //     });

    //     this.props
    //         .getBaseline(this.props.baselineDataManager)
    //         .then((data) => {
    //             if (data && data.length > 0) {
    //                 this.updateLoadedBaseline();
    //             }
    //         })
    //         .catch(() => {
    //             this.setState({ showUserLandingPage: true });
    //         });
    // };

    closeSidePanel = () => {
        this.setState({ showPanel: false });
    };

    handleOptionInput = (e) => {
        const value = e.target.value;
        this.setState({ inputValue: value });
    };

    onHandleSelectedOption = (id) => {
        this.setState({
            selectedOption: id,
        });
    };

    updateEvents = () => {
        this.manager.calculate();
    };

    updateFocus = (focus) => {
        this.props.setFocus(this.manager._findEvent(focus));
    };

    onHandleShowBaselineOption = () => {
        this.setState({
            showPersonOption: !this.state.showPersonOption,
            showPanel: false,
            baselineNodeSelected: null,
        });
    };

    handleShowOptionPicker = () => {
        const { baselineNodeSelected, manager } = this.state;

        if (
            this.state.showQuestions ||
            this.props.baselineDataManager.getActiveBaseline()
        ) {
            this.props.showOptionPicker();
            this.setState({ showPanel: false });

            const baselineNode = this.manager.allNodes().find((node) => {
                return node.type === "Baseline";
            });

            if (baselineNodeSelected.id === "newPerson") {
                this.props.changeSelectedOption(Person);
                // find the baseline and create a person node then make this person node as a parent once they click create node
                this.props.setFocus(baselineNode);
            } else if (baselineNodeSelected.id === "newBusiness") {
                this.props.changeSelectedOption(businessObject.constant());
                this.props.setFocus(baselineNode);
            } else if (baselineNodeSelected.id === "me") {
                const node = manager.getRootNode();
                this.props.setFocus(node);
            } else if (baselineNodeSelected.id === "independent") {
                this.props.setFocus(baselineNode);
            } else {
                let parentNode = baselineNodeSelected;
                let node = manager._findEvent(parentNode.id);
                if (baselineNodeSelected.type === "Baseline") {
                    node = manager._findEvent(baselineNodeSelected.id);
                } else {
                    while (node && node.children.length > 0) {
                        node = manager._findEvent(node.children[0].id);
                    }
                }
                this.props.setFocus(node);
            }
        } else {
            return null;
        }
    };

    addEvent = (type = "Expense", parents = [], children = []) => {
        this.setState({ optionType: type, parents, children });
    };

    addEventType = (type) => {
        this.props.changeSelectedOption(type);
    };

    onHandleOptionSelected = (value) => {
        this.setState({ optionSelected: value });
    };

    onHandleCloseOption = () => {
        this.props.closeOption();
    };

    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.loadedScenario !== this.props.loadedScenario) {
            this.setState({});
        }
    }

    deleteEvent = (node) => {
        // just for now TODO FIX
        // if (node.type === "Me") {
        //     return;
        // }
        const manager = this.manager;
        const deleteNode = manager.deleteEvent(node);
        const toastData = {
            type: "warnings",
            text: `Delete "${node.name}"?`,
            upgrade: false,
        };
        this.setState({
            deleteNode,
            toastData,
        });
    };

    handleFullSelection = (node) => {
        this.setState({ baselineNodeSelected: node }, () => {
            this.handleShowOptionPicker();
            this.setState({ showPersonOption: false });
        });
    };

    handleChangeSelected = (node) => {
        this.setState({ baselineNodeSelected: node });
    };

    handleAddToBaseline = () => {
        this.handleShowOptionPicker();
        this.setState({ showPersonOption: false });
    };

    cancelAddToBaseline = () => {
        this.setState({ showPersonOption: false });
    };

    //todo: code duplication w/ maincontainer - refactor to a reusable toast component
    cancelToast = () => {
        this.cancelDeletePrompt();
        this.setState({ toastData: null });
    };

    cancelDeletePrompt = () => {
        this.setState({ deleteNode: null, toastData: null });
        this.manager.cancelDelete();
    };

    deletePrompt = () => {
        const { root, newRoot, toDelete } = this.state.deleteNode;
        this.manager.deleteNodes(root.id, newRoot, toDelete);
        this.manager.calculate();

        const toastData = {
            type: "success",
            text: `"${root.name}" Event was Deleted`,
            upgrade: false,
        };

        this.setState(
            {
                toastData,
                deleteNode: null,
            },
            () => {
                setTimeout(() => {
                    this.setState({ toastData: null });
                }, 7000);
            }
        );
    };

    onChangeBaseline = (e) => {
        const baselineId = e.target.value;
        this.props.baselineDataManager.setActiveBaseline(baselineId);
        this.updateLoadedBaseline();
    };

    onHandleEditBaseline = () => {
        const loadedBaseline = this.props.baselineDataManager.activeBaseline;
        this.setState({
            showBaselineEditPopUp: true,
            showPanel: false,
            newBaselineName: loadedBaseline.name,
            newBaselineDescription: loadedBaseline.description,
        });
    };

    cancelEditBaseline = () => {
        this.setState({ showBaselineEditPopUp: false });
    };

    // getAddPersonNodePopUp = (baselineNode, baselineNodeSelected) => {
    getAddPersonNodePopUp = () => {
        // const accountType = this.getAccountType();

        // const meNode = this.manager.allNodes().filter((node) => {
        //     return node.type === meObject.constant();
        // })[0] ?? { id: "me" };

        // const personNodes = this.manager.allNodes().filter((node) => {
        //     return node.type === Person;
        // });
        // const businessNodes = this.manager.allNodes().filter((node) => {
        //     return node.type === businessObject.constant();
        // });
        // console.log("account type", accountType);
        return (
            <EventsPopUp
                onHandleClose={this.cancelAddToBaseline}
                specificClass={"Baseline"}
            >
                <div className="baselinePopUpText">
                    New Baseline Thread Options
                </div>
                <div className="baselineNodeContainer">
                    {/* Me */}
                    {/* {accountType === "personal" && (
                        <div
                            onClick={() => this.handleChangeSelected(meNode)}
                            className={
                                baselineNodeSelected &&
                                baselineNodeSelected.id === meNode.id
                                    ? "baselineNodeActive"
                                    : "baselineNode"
                            }
                        >
                            <img
                                src={personBlack}
                                className="optionSvg"
                                alt="alt"
                            />
                            <div className="optionName">Me</div>
                        </div>
                    )} */}

                    {/* Business */}
                    {/* {accountType === "business" && (
                        <> */}
                    {/* {businessNodes.map((business) => {
                                return (
                                    <div
                                        key={business.id}
                                        onClick={() =>
                                            this.handleChangeSelected(business)
                                        }
                                        className={
                                            baselineNodeSelected &&
                                            baselineNodeSelected.id ===
                                                business.id
                                                ? "baselineNodeActive"
                                                : "baselineNode"
                                        }
                                    >
                                        <img
                                            src={businessSvg}
                                            className="optionSvg"
                                            alt="alt"
                                        />
                                        <div className="optionName">
                                            {business.name}
                                        </div>
                                    </div>
                                );
                            })} */}
                    <div className="baselineNodeSingle">
                        <div
                            onClick={() =>
                                this.handleFullSelection({
                                    id: "newBusiness",
                                })
                            }
                            className="baselineNode"
                        >
                            <img
                                src={businessSvg}
                                className="optionSvg"
                                alt="alt"
                            />
                        </div>
                        <p className="baselineNodeParagraph">
                            Use this option to create a new{" "}
                            <span className="baselineNodeParagraph--Highlight">
                                Business Thread
                            </span>{" "}
                            when planning for a seprate location or business
                            unit.
                        </p>
                        <button
                            className="baselineNodeButton"
                            onClick={() =>
                                this.handleFullSelection({
                                    id: "newBusiness",
                                })
                            }
                        >
                            Add Business Event
                        </button>
                    </div>
                    {/* </>
                    )} */}

                    {/* Person */}
                    {/* {accountType === "personal" && (
                        <>
                            {personNodes.map((person) => {
                                return (
                                    <div
                                        key={person.id}
                                        onClick={() =>
                                            this.handleChangeSelected(person)
                                        }
                                        className={
                                            baselineNodeSelected &&
                                            baselineNodeSelected.id ===
                                                person.id
                                                ? "baselineNodeActive"
                                                : "baselineNode"
                                        }
                                    >
                                        <img
                                            src={personBlack}
                                            className="optionSvg"
                                            alt="alt"
                                        />
                                        <div className="optionName">
                                            {person.name}
                                        </div>
                                    </div>
                                );
                            })}
                            <div
                                onClick={() =>
                                    this.handleChangeSelected({
                                        id: "newPerson",
                                    })
                                }
                                className={
                                    baselineNodeSelected &&
                                    baselineNodeSelected.id === "newPerson"
                                        ? "baselineNodeActive"
                                        : "baselineNode"
                                }
                            >
                                <img
                                    src={personBlack}
                                    className="optionSvg"
                                    alt="alt"
                                />
                                <div className="optionName">New Person</div>
                            </div>
                        </>
                    )} */}
                    <div className="baselineNodeSingle baselineNodeSingle--divider">
                        <div
                            onClick={() =>
                                this.handleFullSelection({ id: "independent" })
                            }
                            className="baselineNode"
                        >
                            <img
                                src={addOptionSvg}
                                className="optionSvg optionSvg--larger"
                                alt="alt"
                            />
                        </div>
                        <p className="baselineNodeParagraph">
                            Use this option to create a new{" "}
                            <span className="baselineNodeParagraph--Highlight">
                                Non-Business Thread
                            </span>{" "}
                            that is not accociated with a specific location or
                            business unit.
                        </p>
                        <button
                            className="baselineNodeButton"
                            onClick={() =>
                                this.handleFullSelection({ id: "independent" })
                            }
                        >
                            Add Standard Event
                        </button>
                    </div>
                </div>
                {/* <div className="baselineBtnContainer">
                    <div
                        onClick={
                            baselineNodeSelected
                                ? this.handleAddToBaseline
                                : () => {
                                      return;
                                  }
                        }
                        className={
                            baselineNodeSelected
                                ? "baselineSubmit"
                                : "baselineSubmitDisabled"
                        }
                    >
                        Continue
                    </div>
                </div> */}
            </EventsPopUp>
        );
    };

    renameActiveBaseline = () => {
        const { baselineDataManager, loadedScenario } = this.props;
        const { newBaselineName, newBaselineDescription } = this.state;

        if (!newBaselineName || !baselineDataManager.hasActiveBaseline()) {
            throwError("error", "Failed to Rename Baseline");
            return;
        }

        loadedScenario.name = newBaselineName;
        loadedScenario.description = newBaselineDescription;
        const newBaselineData = baselineDataManager.exportData();
        this.updateScenarioCanvas(newBaselineData);
        this.setState({ showBaselineEditPopUp: false });
    };

    deleteActiveBaseline = () => {
        const {
            loadedScenario,
            deleteBaseline,
            baselineDataManager,
            agencyClients,
        } = this.props;
        const baselineId = loadedScenario.id;
        deleteBaseline(baselineId, baselineDataManager, agencyClients)
            .then(() => {
                this.props.loadScenario(
                    null,
                    this.manager,
                    baselineDataManager,
                    null,
                    null,
                    true
                );
                this.setState({ showBaselineEditPopUp: false });
            })
            .catch((error) => {
                console.error("error", error);
                throwError("error", "Delete Baseline Failed");
                this.setState({ showBaselineEditPopUp: false });
            });
    };

    setClientQuery = (e) => {
        this.setState({ clientQuery: e.target.value });
    };

    queryClients = (agencyClients) => {
        const { clientQuery } = this.state;
        if (!clientQuery) return agencyClients;
        const upperCaseQuery = clientQuery.toUpperCase();

        return agencyClients.filter((client) => {
            let fullName = (
                client.clientdata.firstName +
                " " +
                client.clientdata.lastName
            ).toUpperCase();
            return (
                client.clientdata.name.toUpperCase().includes(upperCaseQuery) ||
                fullName.includes(upperCaseQuery)
            );
        });
    };

    getBaselineEditPopUp = () => {
        const { newBaselineName, newBaselineDescription } = this.state;

        return (
            <EventsPopUp
                onHandleClose={this.cancelEditBaseline}
                specificClass={"Baseline"}
            >
                <div className="baselinePopUpText">
                    Edit an existing baseline
                </div>
                <div className="editBaselinePanel">
                    <Name
                        onChangeInput={(e) => {
                            this.setState({
                                newBaselineName: e.target.value,
                            });
                        }}
                        name={newBaselineName}
                    />
                    <div style={{ height: "15px" }} />
                    <Description
                        description={newBaselineDescription}
                        onChangeInput={(e) => {
                            this.setState({
                                newBaselineDescription: e.target.value,
                            });
                        }}
                    />
                    <div className="baselineEditBtnContainer">
                        <div
                            onClick={() => {
                                this.deleteActiveBaseline();
                            }}
                            className="baselineDeleteBtn"
                        >
                            Delete Baseline
                        </div>
                        <div
                            onClick={
                                !newBaselineName
                                    ? () => {
                                          return;
                                      }
                                    : () => {
                                          this.renameActiveBaseline();
                                      }
                            }
                            className={
                                !newBaselineName
                                    ? "baselineUpdateBtnDisabled"
                                    : "baselineUpdateBtn"
                            }
                        >
                            Update
                        </div>
                    </div>
                </div>
            </EventsPopUp>
        );
    };

    toggleAddingLibraryEvent = (newValue) => {
        this.setState({ isAddingLibraryEvent: newValue });
    };

    handleClientArchived = (client) => {
        let archivedValue = true;
        if (client.clientdata.archived) {
            const toastData = {
                type: "success",
                text: `Successfully reactivated client - ${client.clientdata.firstName}.`,
                upgrade: false,
            };

            archivedValue = false;
            this.setState({ toastData: toastData });
        }
        const data = {
            ...client,
            clientdata: {
                ...client.clientdata,
                archived: archivedValue,
            },
        };
        this.props.updateClient(data);

        if (this.state.agencyClientsSelected === "archived") {
            setTimeout(() => {
                this.setState({ toastData: null });
            }, 3000);
        }
    };

    getClientCard = (client) => {
        if (!client) return <div />;

        const { clientdata } = client;
        const baselines = this.props.baselineDataManager.getBaselines();
        const { userScenarios } = this.props;
        const clientBaseline =
            client.baseline &&
            baselines.find((bl) => {
                return bl.id === client.baseline;
            });
        const fullName = `${clientdata.firstName} ${clientdata.lastName}`;

        let scenarioCount = client.scenarios
            .map((scenarioId) => {
                return userScenarios.find((scenario) => {
                    return scenario.id === scenarioId;
                });
            })
            .filter((scenario) => scenario).length;

        return (
            <div
                className={
                    this.state.agencyClientsSelected === "active"
                        ? styles.clientEntry
                        : styles.archivedClientEntry
                }
            >
                <div
                    className={styles.clientEntryRow}
                    style={{ marginBottom: "15px" }}
                >
                    <img
                        src={profileSvg}
                        alt="alt"
                        className={styles.clientImg}
                    />
                    <div className={styles.clientDetails}>
                        <div className={styles.clientName}>
                            {clientdata.name}
                        </div>
                        {fullName !== clientdata.name && (
                            <div className={styles.clientTextSmall}>
                                {fullName}
                            </div>
                        )}
                        <div
                            className={styles.clientTextSmall}
                        >{`${clientdata.state}, ${clientdata.country}`}</div>
                    </div>
                </div>
                <div className={styles.clientEntryRow}>
                    <div className={styles.clientDetailsText}>
                        Baseline:{" "}
                        {client.baseline && clientBaseline
                            ? `${clientBaseline.data.nodes.length} ${
                                  clientBaseline.data.nodes.length === 1
                                      ? "Event"
                                      : "Events"
                              }`
                            : "None"}
                    </div>
                </div>
                <div className={styles.clientEntryRow}>
                    <div className={styles.clientDetailsText}>
                        Scenarios: {client.scenarios ? scenarioCount : 0}
                    </div>
                </div>
                <div
                    className={styles.clientEntryRow}
                    style={{ marginTop: "auto" }}
                >
                    <div
                        onClick={
                            this.state.agencyClientsSelected === "active"
                                ? () => {
                                      this.accessClientBaseline(client);
                                  }
                                : () => this.handleClientArchived(client)
                        }
                        className={
                            this.props.areBaselinesLoaded
                                ? styles.editBaselineButton
                                : styles.editBaselineButtonDisabled
                        }
                    >
                        {client.baseline
                            ? this.state.agencyClientsSelected === "active"
                                ? "Edit Baseline"
                                : "Reactivate"
                            : "Create Baseline"}
                    </div>
                </div>
            </div>
        );
    };

    accessClientBaseline = (client) => {
        const {
            baselineDataManager,
            eventLibrary,
            createBaseline,
            loadScenario,
            areBaselinesLoaded,
        } = this.props;
        if (!areBaselinesLoaded) return;
        if (
            client.baseline &&
            baselineDataManager.existsBaselineWithId(client.baseline)
        ) {
            baselineDataManager.setActiveBaseline(client.baseline);
            this.manager._reset();
            loadScenario(
                baselineDataManager._processForExport(
                    baselineDataManager.getActiveBaseline()
                ),
                this.manager,
                baselineDataManager,
                null,
                eventLibrary
            );
            this.setState({ selectedBaselineId: client.baseline });
            this.props.toggleShowAgencyBaselineView(false);
        } else {
            createBaselineScenario(
                this.manager,
                createBaseline,
                loadScenario,
                null,
                "meNode",
                baselineDataManager,
                client
            ).then((result) => {
                client.baseline = result;
                this.props.updateClient(client);
                this.setState({ selectedBaselineId: client.baseline });
                this.props.toggleShowAgencyBaselineView(false);
            });
        }
    };

    addClient = (state) => {
        const {
            baselineDataManager,
            loadScenario,
            createBaseline,
            baselineManager,
        } = this.props;
        const id = uuid.v4();
        state["id"] = id;
        const client = {
            id,
            baseline: null,
            scenarios: [],
            clientdata: state,
        };
        //Call endpoint here
        this.props.addClient(client);
        createBaselineScenario(
            baselineManager,
            createBaseline,
            loadScenario,
            null,
            "meNode",
            baselineDataManager,
            client
        ).then((result) => {
            client.baseline = result;
            this.props.updateClient(client);
        });
        this.toggleAddClient();
    };

    toggleAddClient = () => {
        this.setState({ showAddClient: !this.state.showAddClient });
    };

    getBaselineButtons = (
        hasActiveBaseline,
        baselineDataManager,
        loggedInUser
    ) => {
        return (
            <>
                {/* <div className={styles.row}>
                    <div
                        onClick={
                            hasActiveBaseline
                                ? this.onHandleShowBaselineOption
                                : () => {
                                      return;
                                  }
                        }
                        className={
                            hasActiveBaseline
                                ? styles.activeButton
                                : styles.disabledButton
                        }
                    >
                        Add New Baseline Thread
                    </div>
                </div> */}
                <div className={styles.row}>
                    <div
                        className={
                            hasActiveBaseline &&
                            loggedInUser &&
                            baselineDataManager.getActiveBaseline().account ===
                                loggedInUser.account
                                ? styles.activeButton
                                : styles.disabledButton
                        }
                        onClick={
                            hasActiveBaseline &&
                            loggedInUser &&
                            baselineDataManager.getActiveBaseline().account ===
                                loggedInUser.account
                                ? this.onHandleEditBaseline
                                : () => {
                                      return;
                                  }
                        }
                    >
                        Edit Baseline Details
                    </div>
                </div>
            </>
        );
    };

    getStandardLeftContainer = () => {
        const { selectedBaselineId, loggedInUser } = this.state;
        const { baselineDataManager } = this.props;
        if (!baselineDataManager) return;
        const hasActiveBaseline = baselineDataManager.hasActiveBaseline();
        // "userType" and "userIcon" are the text/icon in Baseline left side panel, they depend on "userRole" in localStorage
        let userType = "Me";
        let userIcon = meSvg;
        if (
            this.state.userRole === "personal" ||
            this.state.userRole === "WHATIFI-GUEST"
        ) {
            userType = "Me";
            userIcon = meSvg;
        }
        if (this.state.userRole === "business") {
            userType = "Business";
            userIcon = businessSvg;
        }

        return (
            <div className={styles.leftContainer}>
                <div className={styles.sideRootContainer}>
                    <div onClick={this.handleBack} className={styles.row}>
                        <img alt="back" src={backSvg} />
                        <div className={styles.backButtonText}>
                            Back to Scenario Canvas
                        </div>
                    </div>
                    <div className={styles.row}>
                        <img
                            alt="alt"
                            className={styles.profileSvg}
                            src={userIcon}
                        />
                        <div className={styles.type}>{userType}</div>
                    </div>
                    <div className={styles.row}>
                        {" "}
                        <select
                            className={styles.selectBaseline}
                            onChange={this.onChangeBaseline}
                        >
                            {baselineDataManager.getBaselines().map((bl, i) => {
                                return (
                                    <option
                                        key={bl.id}
                                        value={bl.id}
                                        selected={
                                            bl.id === selectedBaselineId
                                                ? "selected"
                                                : null
                                        }
                                    >
                                        {`${bl.name}${
                                            bl.name === "Baseline"
                                                ? ` ${i + 1}`
                                                : ""
                                        } ${
                                            !loggedInUser ||
                                            bl.account !== loggedInUser.account
                                                ? "(shared)"
                                                : ""
                                        }`}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    {this.getBaselineButtons(
                        hasActiveBaseline,
                        baselineDataManager,
                        loggedInUser
                    )}

                    <div className={styles.row}>
                        <div className={styles.info}>Info</div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.plainText}>
                            Use the Baseline to capture all of the Events that
                            are real and current for an individual or a
                            business. Assets. Debts. Employees. Think of it like
                            a starting point or foundation upon which future
                            scenarios and projections can be built.
                        </div>
                        <div className={styles.plainText}>
                            By linking your accounts, the Events in the Baseline
                            can update automatically. Budgets. Bank account
                            balances. Investments.
                        </div>
                        <div className={styles.plainText}>
                            Any big changes, like a pay raise, or hiring an
                            employee are updated here. All of the Scenarios
                            inherit these updates as part of those calculations.
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    handleSelectAgencyClientsList = (selectedList) => {
        this.setState({ agencyClientsSelected: selectedList });
    };

    onHandleEventToast = (toastData) => {
        this.setState({ toastData });

        setTimeout(() => {
            this.setState({ toastData: null, upgradePlan: false });
        }, 7000);
    };

    getAgencyLeftContainer = () => {
        const { agencyClients, baselineDataManager } = this.props;
        const loadedScenario = this.props.loadedScenario || {};
        const { loggedInUser } = this.state;
        const client = agencyClients.find((cl) => {
            return (
                cl.baseline &&
                loadedScenario &&
                cl.baseline === loadedScenario.id
            );
        });
        const hasActiveBaseline = baselineDataManager.hasActiveBaseline();

        let userIcon = meSvg;
        if (client && client.clientdata.clientType === "business") {
            userIcon = businessSvg;
        }
        return (
            <div className={styles.leftContainer}>
                <div className={styles.sideRootContainer}>
                    <div
                        onClick={() => {
                            this.props.toggleShowAgencyBaselineView(true);
                        }}
                        className={styles.row}
                    >
                        <img alt="back" src={backSvg} />
                        <div className={styles.backButtonText}>
                            Back to All Baselines
                        </div>
                    </div>
                    <div className={styles.row}>
                        <img
                            alt="alt"
                            className={styles.profileSvg}
                            src={userIcon}
                        />
                        <div className={styles.type}>{loadedScenario.name}</div>
                    </div>
                    {this.getBaselineButtons(
                        hasActiveBaseline,
                        baselineDataManager,
                        loggedInUser
                    )}
                    <div className={styles.row}>
                        <div className={styles.info}>Description</div>
                    </div>
                    <div className={styles.column}>
                        {!loadedScenario.description ? (
                            <div />
                        ) : (
                            loadedScenario.description
                                .split("\n")
                                .map((paragraph, i) => {
                                    return (
                                        <div
                                            className={styles.plainText}
                                            key={i}
                                        >
                                            {paragraph}
                                        </div>
                                    );
                                })
                        )}
                    </div>
                </div>
            </div>
        );
    };

    getAgencyView = () => {
        const { agencyClients, activeAgencyClients, archivedAgencyClients } =
            this.props;
        const { showAddClient, agencyClientsSelected } = this.state;
        const filteredClients =
            agencyClientsSelected === "active"
                ? this.queryClients(activeAgencyClients)
                : this.queryClients(archivedAgencyClients);

        return (
            <div className={styles.root}>
                {/* <div ref={(el) => (this.container = el)} /> */}
                <div className={styles.agencyMenu}>
                    <div className={styles.headerContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerRow}>
                                <div className={styles.headerTitle}>
                                    {`All Baselines (${
                                        agencyClients.length || 0
                                    })`}
                                </div>
                            </div>
                            <div className={styles.headerRow}>
                                <div
                                    className={styles.clientsSubHeaderContainer}
                                >
                                    <div
                                        className={
                                            agencyClientsSelected === "active"
                                                ? styles.clientSubHeaderActive
                                                : styles.clientSubHeader
                                        }
                                        onClick={() =>
                                            this.handleSelectAgencyClientsList(
                                                "active"
                                            )
                                        }
                                    >
                                        {`Active `}
                                        <span className={styles.clientCount}>
                                            ({activeAgencyClients.length})
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            agencyClientsSelected === "archived"
                                                ? styles.clientSubHeaderActive
                                                : styles.clientSubHeader
                                        }
                                        onClick={() =>
                                            this.handleSelectAgencyClientsList(
                                                "archived"
                                            )
                                        }
                                    >
                                        {`Archived `}
                                        <span className={styles.clientCount}>
                                            {` (${archivedAgencyClients.length})`}
                                        </span>
                                    </div>
                                </div>
                                <input
                                    onChange={this.setClientQuery}
                                    className={styles.searchInput}
                                    placeHolder={"Search..."}
                                />
                                <div
                                    onClick={this.toggleAddClient}
                                    className={styles.addClientButton}
                                >
                                    + Add a New Client
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.clientsContainer}>
                        <div className={styles.clientTable}>
                            {filteredClients.map((client) => {
                                return this.getClientCard(client);
                            })}
                        </div>
                    </div>
                </div>
                {showAddClient && (
                    <EventsPopUp
                        onHandleClose={this.toggleAddClient}
                        specificClass={""}
                    >
                        <ClientInput
                            updateClient={() => {
                                return;
                            }}
                            addClient={this.addClient}
                            editData={null}
                            agencyClients={this.props.agencyClients}
                        />
                    </EventsPopUp>
                )}
                {this.state.toastData && (
                    <EventsToast
                        data={this.state.toastData}
                        history={this.props.history}
                    />
                )}
            </div>
        );
    };

    render() {
        const {
            step,
            steps,
            selectedOption,
            // showUserLandingPage,
            showPanel,
            showPersonOption,
            baselineNode,
            baselineNodeSelected,
            // baselineNodes,
            deleteNode,
            showBaselineEditPopUp,
        } = this.state;
        const {
            showOptions,
            selectedEvent,
            edit,
            focus,
            showAgencyBaselinePage,
        } = this.props;
        const displayMenu = focus != null;

        const userRole = localStorage.getItem("userRole");
        const isAgency = userRole === AGENCY;
        if (isAgency && showAgencyBaselinePage) {
            return this.getAgencyView();
        } else
            return (
                <div className={styles.root}>
                    <div id="MainContainerCanvasContainer" />
                    <div className="LeftDisplay">
                        <div className="CanvasContainerDefault">
                            {isAgency
                                ? this.getAgencyLeftContainer()
                                : this.getStandardLeftContainer()}
                            <CanvasStage isBaseline={true} />
                        </div>
                    </div>

                    {/* this wont work with the current state of the this file, however if it becomes needed again it already has some styling done to it. */}
                    {/* {showUserLandingPage && 
                            <div className={styles.userContainer}>
                                <img
                                    alt="alt"
                                    src={userImage}
                                    className={styles.bigUserImage}
                                />
                                <div className={styles.boldText}>
                                    Add your first node to your Profile
                                </div>
                                <div className={styles.column}>
                                    <div className={styles.plainText}>
                                        Your baseline is the foundation upon
                                        which all of your scenarios are built.
                                        If something changes in your baseline
                                        profile, like a raise or buying a house,
                                        all of your scenarios update with this
                                        new information.
                                    </div>
                                    <div className={styles.plainText}>
                                        The more you complete your baseline, the
                                        more accurate your calclations and
                                        scenarios will be in the future.
                                    </div>
                                    <div
                                        onClick={this.handleShowQuestion}
                                        className={styles.activeButton}
                                    >
                                        Start to build together
                                    </div>
                                </div>
                            </div>
                        } */}

                    <BaselineRightDisplayPanel
                        displayMenu={displayMenu}
                        history={this.props.history}
                        deleteEvent={this.deleteEvent}
                        baselineManager={this.manager}
                    />

                    <div
                        className={
                            showPanel
                                ? styles.stepsContainer
                                : styles.hideContainer
                        }
                    >
                        {showPanel && (
                            <img
                                alt="alt"
                                onClick={this.closeSidePanel}
                                src={closeSvg}
                                className={styles.closeSvg}
                            />
                        )}
                        <div className={styles.sideRootContainer}>
                            <div className={styles.questionContainer}>
                                <div className={styles.title}>
                                    {step + 1}. {steps[step].title}
                                </div>
                                <div className={styles.subTitle}>
                                    {" "}
                                    {steps[step].subTitle}
                                </div>
                                {steps[step].options ? (
                                    <div className={styles.optionContainer}>
                                        {steps[step].options.map(
                                            (option, i) => {
                                                const hasImage = option.image;
                                                const isSelected =
                                                    selectedOption &&
                                                    selectedOption ===
                                                        option.optionId;
                                                return (
                                                    <div
                                                        onClick={() =>
                                                            this.onHandleSelectedOption(
                                                                option.optionId
                                                            )
                                                        }
                                                        key={i}
                                                        className={
                                                            isSelected
                                                                ? styles.optionSelected
                                                                : styles.option
                                                        }
                                                    >
                                                        {hasImage && (
                                                            <img
                                                                alt="alt"
                                                                className={
                                                                    styles.optionImage
                                                                }
                                                                src={
                                                                    option.image
                                                                }
                                                            />
                                                        )}
                                                        <div
                                                            className={
                                                                styles.optionTitle
                                                            }
                                                        >
                                                            {option.title}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {step === 2 ? (
                                            <input
                                                className={styles.optionInput}
                                                onChange={
                                                    this.handleOptionInput
                                                }
                                                value={this.state.inputValue}
                                            />
                                        ) : (
                                            <img
                                                alt="alt"
                                                className={styles.stepFourImage}
                                                src={stepFourImage}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={styles.row}>
                                <div
                                    className={
                                        selectedOption
                                            ? styles.nextButton
                                            : styles.nextButtonDisabled
                                    }
                                    onClick={
                                        selectedOption
                                            ? this.onHandleNext
                                            : () => {
                                                  //noop
                                              }
                                    }
                                >
                                    {step < 3 ? "Next" : "Finish"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={showOptions || selectedEvent}
                        onClose={this.onHandleCloseOption}
                    >
                        <EventsModal
                            option={true}
                            onHandleClose={this.onHandleCloseOption}
                            edit={edit}
                            selectedEvent={selectedEvent}
                            optionSelected={this.state.optionSelected}
                            hoveredInput={this.props.hoveredInput}
                            clickAway={true}
                        >
                            <Options
                                history={this.props.history}
                                selectedOption={selectedOption}
                                selectedEvent={selectedEvent}
                                addEventType={this.addEventType}
                                optionSelected={this.state.optionSelected}
                                onHandleOptionSelected={
                                    this.onHandleOptionSelected
                                }
                                onboardingData={this.props.onboardingData}
                                isBaseline={true}
                                baselineManager={this.manager}
                                closeBaselineOption={this.onHandleCloseOption}
                                toggleAddingLibraryEvent={
                                    this.toggleAddingLibraryEvent
                                }
                                isAddingLibraryEvent={
                                    this.state.isAddingLibraryEvent
                                }
                            />
                        </EventsModal>
                    </Modal>
                    {this.state.toastData && (
                        <EventsToast
                            data={this.state.toastData}
                            close={this.cancelToast}
                            history={this.props.history}
                        >
                            {deleteNode && (
                                <div className="promptButtonContainer">
                                    <div
                                        onClick={this.cancelDeletePrompt}
                                        className="deletePromptButton"
                                    >
                                        Cancel
                                    </div>
                                    <div
                                        onClick={this.deletePrompt}
                                        className="deletePromptButton"
                                    >
                                        Yes
                                    </div>
                                </div>
                            )}
                        </EventsToast>
                    )}
                    <PasteOptionModal />
                    {showPersonOption &&
                        this.getAddPersonNodePopUp(
                            baselineNode,
                            baselineNodeSelected
                        )}
                    {showBaselineEditPopUp && this.getBaselineEditPopUp()}
                </div>
            );
    }
}

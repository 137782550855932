import React, { Component } from "react";
import styles from "./../index.module.css";
import UpgradePlanGraphic from "../../../Assets/_home/upgrade-plan-graphic.png";

export default class UpgradePlanPopUp extends Component {
    state = {
        popUpMounted: false,
        planDetails: undefined,
    };

    componentDidMount() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        this.setState({ planDetails: loggedInUser.plan.meta_data });

        setTimeout(() => {
            this.setState({ popUpMounted: true });
        }, 100);
    }

    handleGoToBillingPage = () => {
        this.props.history.push("/billing");
        this.props.handleCloseUpgradePlanPopUp();
    };

    render() {
        const { popUpMounted, planDetails } = this.state;

        const { handleCloseUpgradePlanPopUp } = this.props;

        return (
            <div
                className={
                    popUpMounted
                        ? styles.upgradePlanPopUpBackgroundMounted
                        : styles.upgradePlanPopUpBackground
                }
            >
                <div
                    className={
                        popUpMounted
                            ? styles.upgradePlanPopUpMounted
                            : styles.upgradePlanPopUp
                    }
                >
                    {planDetails && (
                        <div className={styles.upgradePlanPopUpContentWrapper}>
                            <div
                                className={styles.upgradePlanPopUpHeaderWrapper}
                            >
                                <span
                                    className={
                                        styles.upgradePlanPopUpHeaderText
                                    }
                                >
                                    You&apos;ve reached your limit of{" "}
                                    {planDetails.client_limit} clients.
                                </span>
                            </div>
                            <div
                                className={
                                    styles.upgradePlanPopUpGraphicWrapper
                                }
                            >
                                <img
                                    className={styles.upgradePlanPopUpGraphic}
                                    src={UpgradePlanGraphic}
                                    alt="upgrade plan graphic"
                                />
                            </div>
                            <p className={styles.upgradePlanPopUpText}>
                                Your{" "}
                                <span
                                    className={
                                        styles.upgradePlanPopUpTextHighlight
                                    }
                                >
                                    {planDetails.displayName}
                                </span>{" "}
                                has a{" "}
                                <span
                                    className={
                                        styles.upgradePlanPopUpTextHighlight
                                    }
                                >
                                    {planDetails.client_limit}-clients limit
                                </span>
                                . If you wish to add a new Client, one of your
                                existing Clients will have to be archived or
                                deleted.
                            </p>
                            <div
                                className={
                                    styles.upgradePlanPopUpButtonsWrapper
                                }
                            >
                                <button
                                    className={
                                        styles.upgradePlanPopUpCancelButton
                                    }
                                    onClick={handleCloseUpgradePlanPopUp}
                                >
                                    Maybe Later
                                </button>
                                <button
                                    className={
                                        styles.upgradePlanPopUpUpgradeButton
                                    }
                                    onClick={this.handleGoToBillingPage}
                                >
                                    Upgrade Plan
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

import { createContext } from "react";

export const AccountCardIdContext = createContext<string>("");
AccountCardIdContext.displayName = "AccountCardIdContext";

export const MonthlyOrCumulativeContext = createContext<string>("monthly");
MonthlyOrCumulativeContext.displayName = "MonthlyOrCumulativeContext";

export const CardDisplayValueContext = createContext<string>("");
CardDisplayValueContext.displayName = "CardDisplayValueContext";

export const AccountNameContext = createContext<string>("");
AccountNameContext.displayName = "AccountNameContext";

export const UpdatePositionContext = createContext<any>("");
UpdatePositionContext.displayName = "UpdatePositionContext";

export const UpdateMonthlyOrCumulative = createContext<any>("");
UpdateMonthlyOrCumulative.displayName = "UpdateMonthlyOrCumulative";

export const AccountCardFocusContext = createContext<boolean>(false);
AccountCardFocusContext.displayName = "AccountCardFocusContext";

import type { ThreadsListSchema } from "reducers/typesSchema/threadsListSchema";
import { UPSERT_THREADS_LIST } from "actions/threadsListActions";

const initialState: ThreadsListSchema = {
    accountLedgerName: "Cashflow",
    accountLedgerId: "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78",
    zoomedThreadId: "",
    monthlyCumulative: "monthly",
    preferenceRating: 50,
};

export default function threadsListReducer(
    state = initialState,
    action: { type: string; payload: Partial<ThreadsListSchema> }
) {
    switch (action.type) {
        case UPSERT_THREADS_LIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

import styles from "./accountWaterfall.module.css";
// import { useMemo } from "react";
import { AgGridSelectorButton } from "Components/AccountSelectionWaterfall/buttons/agGridSelectorButton";

export const AccountWaterfallCellRenderer = (props) => {
    const rowData = props?.node?.data;
    const columnDef = props?.colDef;
    const isContra = !!(columnDef?.field === "contraAccountName");

    const {
        // conditionalFieldName,
        // conditionalFieldConditionValue,
        cellRendererWaterfallTopLevelFilters,
    } = props;
    // const editable: boolean = useMemo(() => {
    //     if (!conditionalFieldName) return true;
    //     if (
    //         String(rowData[conditionalFieldName]) ===
    //         String(conditionalFieldConditionValue)
    //     ) {
    //         return true;
    //     }
    //     return false;
    // }, [conditionalFieldConditionValue, conditionalFieldName, rowData]);

    const handleChange = (accountType) => {
        if (isContra) {
            props.node.setDataValue("contraAccountIds", accountType.ids);
            props.node.setDataValue("contraAccountName", accountType.name);
        } else {
            props.node.setDataValue("accountIds", accountType.ids);
            props.node.setDataValue("accountName", accountType.name);
        }
    };

    return (
        <div className={styles?.container}>
            <AgGridSelectorButton
                callback={handleChange}
                initialValue={props?.value}
                topLevelFilters={cellRendererWaterfallTopLevelFilters}
                contraAccount={isContra}
                accountSelectedForContra={
                    isContra ? rowData?.accountIds?.[0] : ""
                }
            />
        </div>
    );
};

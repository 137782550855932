import React from "react";
import styles from "./Options.module.css";
import { NodeTypesBlack } from "../../Events";
import favoriteSvg from "../../Assets/favorite.svg";
import { decisionObject } from "Components/Registry/Decision";
import { getNameFromType } from "helpers/getNameFromType";

export const FavoriteTab = ({
    nodes,
    onHandleOptionSelected,
    addEventType,
    handleRemoveFavorite,
}) => {
    return (
        <div className={styles.optionNodeContainer}>
            <div className={styles.optionRootContainer}>
                {nodes.map((node) => {
                    const isDescriptionOpen =
                        node.type === "House" ||
                        node.type === "Mortgage" ||
                        node.type === "Person" ||
                        node.type === "Pension";

                    const isDecision = node.type === decisionObject.constant();

                    const typeImagesBlack = {};

                    NodeTypesBlack.forEach((data) => {
                        typeImagesBlack[data.type] = data.image;
                    });

                    let image = typeImagesBlack[node.type];

                    return (
                        <div key={node.id} className={styles.optionBoxWrap}>
                            <div
                                className={
                                    isDecision
                                        ? styles.decisionBox
                                        : styles.optionBox
                                }
                            >
                                <img
                                    onClick={() => handleRemoveFavorite(node)}
                                    className={styles.favorite}
                                    src={favoriteSvg}
                                    alt="favorite"
                                />
                                <img
                                    onClick={() =>
                                        isDescriptionOpen
                                            ? onHandleOptionSelected(node.type)
                                            : addEventType(node.type)
                                    }
                                    className={styles.optionSvg}
                                    src={image}
                                    alt="option"
                                />
                                <div
                                    onClick={() =>
                                        isDescriptionOpen
                                            ? onHandleOptionSelected(node.type)
                                            : addEventType(node.type)
                                    }
                                    className={styles.optionName}
                                >
                                    {getNameFromType(node.type)}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

import React, { Component } from "react";
import "./InputContainer.css";
import PropertyTransferTaxInput from "./PropertyTransferTaxInput";
import TaxDeductibleInput from "./TaxDeductibleInput";
import MortgageALT from "./MortgageALT";
import InvestmentInput from "./Investment";
import RRSPInput from "./RRSPInput";
import BankPlaidInput from "./BankPlaidInput";
import DownpaymentInput from "./DownpaymentInput";
import PropertyResaleInput from "./PropertyResaleInput";
import ModifierInputExperimental from "./ModifierInputExperimental/ModifierInputExperimental";
import Modifier2InputExperimental from "./Modifier2InputExperimental/Modifier2InputExperimental";
import PensionInput from "./PensionInput";
import RealEstateFeeInput from "./RealEstateFeeInput";
import MortgageInsuranceInput from "./MortgageInsuranceInput";
import CapitalGainsInput from "./CapitalGainsInput";
import ClosingCostsInput from "./ClosingCostsInput";
import HomeMaintenanceInput from "./HomeMaintenanceInput";
import RetirementInput from "./RetirementInput";

import { EventsToast } from "../../Components/EventsToast";
import backSvg from "../../Assets/_budgetIcons/back.svg";
import { throwError } from "../../helpers/swalError";
import LumpSumPaymentsInput from "./LumpSumPaymentsInput";
import RenewalRefinanceInput from "./RenewalRefinanceInput";
import {
    getMaxEventsPerScenario,
    isActiveUserAdmin,
} from "../../helpers/userHelpers";
import ShortTermRentalInput from "./ShortTermRentalInput";
import { Student_IncomeObject } from "../Registry/Student Income";
import { rentalIncomeObject } from "../Registry/Rental Income";
import { tuitionObject } from "../Registry/Tuition";
import { expenseObject } from "../Registry/Expense";
import { budgetObject } from "../Registry/Budget";
import { studentBudgetObject } from "../Registry/Student Budget";
import { retirementObject } from "../Registry/Retirement";
import { graduationObject } from "../Registry/Graduation";
import GraduationInput from "./GraduationInput";
import { loanObject } from "../Registry/Loan";
import { studentLoanObject } from "../Registry/Student Loan";
import { debtObject } from "../Registry/Debt";
import { debtRepaymentObject } from "../Registry/Debt Repayment";
import { customerObject } from "../Registry/Customer";
import { grantObject } from "../Registry/Grant";
import { customerGrowthObject } from "../Registry/CustomerGrowth";
import { customerChurnObject } from "../Registry/CustomerChurn";
import { unitCostObject } from "../Registry/Unit Cost";
import { revenueObject } from "../Registry/Revenue";
import { bankPlaidObject } from "../Registry/BankPlaid";
import { bankObject } from "../Registry/Bank";
import { rentObject } from "../Registry/Rent";
import { houseObject } from "../Registry/House";
import { incomeObject } from "../Registry/Income";
import IncomeInputExperimental from "./IncomeInputExperimental/IncomeInputExperimental";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { mortgageObject } from "Components/Registry/Mortgage";
import { rrspObject } from "Components/Registry/RRSP";
import { investmentObject } from "Components/Registry/Investment";
import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";
import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";
import { propertyResaleObject } from "Components/Registry/Property Resale";
import { propertyValueObject } from "Components/Registry/Property Value";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { employeeObject } from "Components/Registry/Employee";
import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
import { capitalGainsObject } from "Components/Registry/Capital Gains";
import { closingCostsObject } from "Components/Registry/Closing Costs";
import { pensionObject } from "Components/Registry/Pension";
import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";
import { shortTermRentalObject } from "Components/Registry/Short Term Rental";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { personObject } from "Components/Registry/Person";
import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
import { meObject } from "Components/Registry/Me";
import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
import { modifierObject } from "Components/Registry/Modifier";
import { goalObject } from "Components/Registry/Goal";
import { decisionObject } from "Components/Registry/Decision";
import { taxDeductibleObject } from "Components/Registry/Tax Deductible";
import { equityObject } from "Components/Registry/Equity";
import { maternityObject } from "Components/Registry/Maternity";
import { condoFeeObject } from "../Registry/CondoFee";
import { cacObject } from "Components/Registry/CAC";
import { nullObject } from "Components/Registry/Null";
import { containerObject } from "Components/Registry/Container";
import { contractObject } from "../Registry/Contract";
import HouseInputExperimental from "./HouseInputExperimental/HouseInputExperimental";
import EmployeeInputExperimental from "./EmployeeInputExperimental/EmployeeInputExperimental";
import IncomeTaxInputExperimental from "./IncomeTaxInputExperimental/IncomeTaxInputExperimental";
import LoanInputExperimental from "./LoanInputExperimental/LoanInputExperimental";
import BankInputExperimental from "./BankInputExperimental/BankInputExperimental";
import GoalInputExperimental from "./GoalInputExperimental/GoalInputExperimental";
import DecisionInputExperimental from "./DecisionInputExperimental/DecisionInputExperimental";
import PersonInputExperimental from "./PersonInputExperimental/PersonInputExperimental";
import { startObject } from "Components/Registry/Start";
import { businessObject } from "Components/Registry/Business";
import BusinessInputExperimental from "./BusinessInputExperimental/BusinessInputExperimental";
import StartInputExperimental from "./StartInputExperimental/StartInputExpiermental";
import MeInputExperimental from "./MeInputExperimental/MeInputExperimental";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import { EventInputIDContext } from "./Context/EventInputIDContext";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import CustomerTransferInputExperimental from "./CustomerTransferExperimental/CustomerTransferInputExperimental";
import { kpiObject } from "Components/Registry/KPI";
import KPIsInputExperimental from "./KPIInputExperimental/KPIsInputExperimental";
import { instanceObject } from "Components/Registry/Instance";
import InstanceInputExperimental from "./InstanceInputExperimental/InstanceInputExperimental";
import { modifier2Object } from "Components/Registry/Modifier2";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import ExpressionInputExperimental from "./ExpressionInputExperimental/ExpressionInputExperimental";
import { expressionObject } from "Components/Registry/Expression";
import { importObject } from "Components/Registry/Import";
import { getEvent } from "actions/getNodeEntityActions";
import { accountImportObject } from "Components/Registry/AccountImport";
import { growthObject } from "Components/Registry/Growth";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { salespersonObject } from "Components/Registry/Salesperson";
import { segmentObject } from "Components/Registry/Segment";
import { customer2Object } from "Components/Registry/Customer2";
import { customerGrowth2Object } from "Components/Registry/CustomerGrowth2";
import { customerChurn2Object } from "Components/Registry/CustomerChurn2";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { campaignObject } from "Components/Registry/Campaign";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { CAC2Object } from "Components/Registry/CAC2";
import { allocationObject } from "Components/Registry/Allocation";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { percentageObject } from "Components/Registry/Percentage";
import { resourceObject } from "Components/Registry/Resource";
import { capacityObject } from "Components/Registry/Capacity";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { unitObject } from "Components/Registry/Unit";
import { projectsObject } from "Components/Registry/Projects";
import { utilizationObject } from "Components/Registry/Utilization";

class Inputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toastData: null,
            upgradePlan: false,
            //Never changes
            eventId:
                (this?.props?.focus?.id
                    ? this?.props?.focus?.id
                    : this?.props?.line?.parentEvent?.id) ?? "",
        };
    }

    onCloseModal = () => {
        this.props.closeEventsModal();
        this.props.toggleAddingLibraryEvent(false);
    };

    libraryUpdateAction = (state) => {
        const { eventLibrary, loadedScenario, manager, baselineDataManager } =
            this.props;
        this.props.updateLibraryEvent(state, eventLibrary).then(() => {
            this.props.loadScenario(
                loadedScenario,
                manager,
                baselineDataManager,
                null,
                eventLibrary
            );
        });
        if (!this.props.isAddingLibraryEvent) this.updateNode(state);
        this.onCloseModal();
        this.props.toggleAddingLibraryEvent(false);
    };

    libraryConfirmAction = (state) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (!loggedInUser) {
            this.onCloseModal();
            this.props.toggleAddingLibraryEvent(false);
            const toastData = {
                type: "error",
                text: `Please log in to use the Events Library`,
                upgrade: false,
            };
            this.setState(
                {
                    toastData,
                    upgradePlan: true,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ toastData: null });
                    }, 7000);
                }
            );
            return;
        } else {
            this.props.addLibraryEvents(
                [state],
                state.type,
                this.props.eventLibrary
            );

            this.onCloseModal();
            this.props.toggleAddingLibraryEvent(false);
            const toastData = {
                type: "success",
                text: `Successfully added "${state.name}" to your Library`,
                upgrade: false,
            };
            this.setState(
                {
                    toastData,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ toastData: null });
                    }, 7000);
                }
            );

            this.updateRecentlyUsed(state.type);
        }
    };

    addLibraryEventToScenario = (state) => {
        state.baseline = Boolean(this.props.baselineManager);
        this.libraryUpdateAction(state);
        this.addEventToScenario(state);
        this.onCloseModal();
        this.updateRecentlyUsed(state.type, true, state.libraryEventId);
        this.props.closeOption();
    };

    getMaxNodeCount = () => {
        const { loggedInUser } = this.props;
        const nodeCount = getMaxEventsPerScenario(
            loggedInUser && loggedInUser.plan
        );
        return nodeCount;
    };

    getLastNode = () => {
        const isBaseline = Boolean(this.props.baselineManager);
        const manager = isBaseline
            ? this.props.baselineManager
            : this.props.manager;
        const lastNodeId =
            manager.sortedEventIds[manager.sortedEventIds.length - 1];
        const lastNode = getEvent(lastNodeId);
        return lastNode;
    };

    getLastCreatedEvents = (amount, type = null) => {
        const isBaseline = Boolean(this.props.baselineManager);
        const manager = isBaseline
            ? this.props.baselineManager
            : this.props.manager;

        const recentlyCreatedEvents = [];
        const actions = manager.actions;
        let counter = 0;
        for (let i = actions.length - 1; i >= 0; i--) {
            const action = actions[i];
            if (
                action.action == "createEvent" &&
                (!type || action.args?.type == type)
            ) {
                recentlyCreatedEvents.unshift(action.args.id);
                counter = counter + 1;
                if (amount && counter >= amount) {
                    break;
                }
            }
        }

        return recentlyCreatedEvents;
    };

    // also includes bypassed events
    getEvents = () => {
        const isBaseline = Boolean(this.props.baselineManager);
        const manager = isBaseline
            ? this.props.baselineManager
            : this.props.manager;
        return manager.sortedEventIds;
    };

    addEventsToScenario = (
        states,
        nextFunction = null,
        parentId = null,
        childId = null
    ) => {
        // state here is an eventState
        const { loadedScenario } = this.props;
        const maxNodeCount = this.getMaxNodeCount();
        const loadedScenarioNode = loadedScenario.data.nodes.filter((node) => {
            return node.type !== decisionObject.constant();
        });
        const loadedScenarioNodeCount =
            loadedScenarioNode.length - states.length;
        const isBaseline = Boolean(this.props.baselineManager);
        const manager = isBaseline
            ? this.props.baselineManager
            : this.props.manager;

        if (loadedScenarioNodeCount <= maxNodeCount) {
            let parent;
            let child;

            if (parentId) {
                parent = manager?._findEvent(parentId);
            }

            if (childId) {
                child = manager?._findEvent(childId);
            }

            const focus = this.props.focus || parent;

            if (focus) {
                let focusCurr = focus;

                for (let i = 0; i < states.length; i++) {
                    const state = states[i];
                    const data = state;
                    const type = state.type;
                    const node = manager.createEvent(type, data);

                    manager.attachToNodes(
                        node,
                        [focusCurr],
                        child ? [child] : []
                    );
                    focusCurr = node;
                }

                if (!isBaseline) this.updateEvents();
            }

            // toast success
            const toastData = {
                type: "success",
                text: `Successfully added states to the Scenario`,
                upgrade: false,
            };

            //added this cus the canvas don't show exact node count
            const normalData = manager.exportData();
            const normalScenario = {
                ...loadedScenario,
                data: normalData,
            };

            this.props.loadScenario(
                normalScenario,
                manager,
                this.props.baselineManager
            );

            this.setState(
                {
                    toastData,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ toastData: null });
                    }, 7000);
                }
            );

            if (nextFunction) {
                setTimeout(() => {
                    nextFunction();
                }, 500);
            }
        } else {
            // toast failure
            const toastData = {
                type: "error",
                text: `You've reached your event limit for this scenario.`,
                upgrade: false,
            };
            this.setState(
                {
                    toastData,
                    upgradePlan: true,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ toastData: null });
                    }, 7000);
                }
            );
        }
    };

    addEventToScenario = (state) => {
        // state here is an eventState
        const { loadedScenario } = this.props;
        const maxNodeCount = this.getMaxNodeCount();
        const loadedScenarioNode = loadedScenario.data.nodes.filter((node) => {
            return node.type !== decisionObject.constant();
        });
        const loadedScenarioNodeCount = loadedScenarioNode.length - 1;
        const isBaseline = Boolean(this.props.baselineManager);
        const manager = isBaseline
            ? this.props.baselineManager
            : this.props.manager;

        if (loadedScenarioNodeCount <= maxNodeCount) {
            // add event to scenario
            const data = state;
            const type = state.type;
            const node = manager.createEvent(type, data);

            if (this.props.focus) {
                manager.attachToNodes(node, [this.props.focus], []);
                if (!isBaseline) this.updateEvents();
            } else if (this.props.line) {
                const { parentEvent, childEvent } = this.props.line;
                manager.attachToNodes(node, [parentEvent], [childEvent]);
                if (!isBaseline) this.updateEvents();
            }

            // toast success
            const toastData = {
                type: "success",
                text: `Successfully added "${state.name}" to the Scenario`,
                upgrade: false,
            };
            //added this cus the canvas don't show exact node count
            const normalData = manager.exportData();
            const normalScenario = {
                ...loadedScenario,
                data: normalData,
            };

            this.props.loadScenario(
                normalScenario,
                manager,
                this.props.baselineManager
            );
            this.setState(
                {
                    toastData,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ toastData: null });
                    }, 7000);
                }
            );
        } else {
            // toast failure
            const toastData = {
                type: "error",
                text: `You've reached your event limit for this scenario.`,
                upgrade: false,
            };
            this.setState(
                {
                    toastData,
                    upgradePlan: true,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ toastData: null });
                    }, 7000);
                }
            );
        }
    };

    confirmAction = (state) => {
        const { loadedScenario, manager } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }

        this.addEventToScenario(state);
        this.onCloseModal();
        this.props.closeOption();

        //onBoarding code
        if (
            state.type === decisionObject.constant() &&
            this.props.onboardingData === 2
        ) {
            this.props.setOnboardingData(3);
        } else if (state.type === "House" && this.props.onboardingData === 3) {
            this.props.setOnboardingData(4);
        } else if (
            state.type === "Mortgage" &&
            this.props.onboardingData === 4
        ) {
            this.props.setOnboardingData(5);
        } else if (
            state.type === "IncomeTax" &&
            this.props.onboardingData === 6
        ) {
            this.props.setOnboardingData(7);
        } else if (
            state.type === decisionObject.constant() &&
            this.props.onboardingData === 10
        ) {
            this.props.setOnboardingData(11);
        }

        if (!loggedInUser) {
            const normalData = manager.exportData();
            const normalScenario = {
                ...loadedScenario,
                data: normalData,
            };

            this.props.onboardingScenario(normalScenario);
            this.props.loadScenario(
                normalScenario,
                manager,
                this.props.baselineManager
            );
        }

        this.updateRecentlyUsed(state.type);
    };

    bypassEvents = (eventIds) => {
        if (eventIds.length <= 0) {
            return;
        }

        const { loadedScenario, manager } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        eventIds.forEach((eventId) => {
            const selectedEvent = manager?._findEvent(eventId);
            if (selectedEvent) {
                selectedEvent.toggleBypassed();
            }
        });

        // toast success
        const toastData = {
            type: "success",
            text: `Successfully bypassed events in the Scenario`,
            upgrade: false,
        };
        //added this cus the canvas don't show exact node count
        const normalData = manager.exportData();
        const normalScenario = {
            ...loadedScenario,
            data: normalData,
        };

        this.props.loadScenario(
            normalScenario,
            manager,
            this.props.baselineManager
        );
        this.setState(
            {
                toastData,
            },
            () => {
                setTimeout(() => {
                    this.setState({ toastData: null });
                }, 7000);
            }
        );

        if (!loggedInUser) {
            const normalData = manager.exportData();
            const normalScenario = {
                ...loadedScenario,
                data: normalData,
            };

            this.props.onboardingScenario(normalScenario);
            this.props.loadScenario(
                normalScenario,
                manager,
                this.props.baselineManager
            );
        }
    };

    confirmActions = (
        states,
        nextFunction = null,
        parentId = null,
        childId = null
    ) => {
        const { loadedScenario, manager } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }

        this.addEventsToScenario(states, nextFunction, parentId, childId);

        if (!loggedInUser) {
            const normalData = manager.exportData();
            const normalScenario = {
                ...loadedScenario,
                data: normalData,
            };

            this.props.onboardingScenario(normalScenario);
            this.props.loadScenario(
                normalScenario,
                manager,
                this.props.baselineManager
            );
        }
    };

    confirmBaselineAction = (state) => {
        this.onCloseModal();
        this.props.closeOption();
        this.props.closeBaselineOption();
        const { baselineManager, baselineDataManager } = this.props;

        const data = state;
        const type = state.type;
        const node = baselineDataManager.createEvent(type, data);
        if (state.type === "Person") {
            //attached to baseline
            baselineDataManager.attachToNodes(
                node,
                [baselineDataManager.root],
                []
            );
        } else {
            if (this.props.focus) {
                baselineDataManager.attachToNodes(
                    node.id,
                    [this.props.focus.id],
                    []
                );
            } else if (this.props.line) {
                const { parentEvent, childEvent } = this.props.line;
                baselineDataManager.attachToNodes(
                    node,
                    [parentEvent],
                    [childEvent]
                );
            } else {
                const personNode = baselineDataManager.createEvent("Person", {
                    name: "Person",
                });
                baselineDataManager.attachToNodes(
                    personNode,
                    [baselineDataManager.root],
                    []
                );
                baselineDataManager.attachToNodes(node, [personNode], []);
            }
        }
        const newScenario = baselineDataManager.handleExport();
        this.props.updateBaseline(newScenario, baselineDataManager);

        baselineManager.calculate();
        baselineDataManager._updateScenarioCanvas();
        this.updateRecentlyUsed(state.type);
    };

    // adds new node type to head of 'recently used' tab. truncates list if it exceeds the limit
    updateRecentlyUsed = (
        newType,
        isLibraryEvent = false,
        libraryEventId = null
    ) => {
        const maxLength = 24; // configure this to adjust limit

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (!loggedInUser) {
            // throwError("error", "No Active Account");
            return;
        }
        let recentlyUsed = loggedInUser.recentlyusednodes || [];
        let newRecentlyUsed;
        // if recentlyUsed exceeds maxLength, remove the last entry
        if (recentlyUsed.length >= maxLength) {
            recentlyUsed = recentlyUsed.splice(0, maxLength - 1);
        }
        if (isLibraryEvent) {
            // filter out duplicates of this library event
            recentlyUsed = recentlyUsed.filter((entry) => {
                return (
                    !entry.isLibraryEvent ||
                    (entry.isLibraryEvent &&
                        entry.libraryEventId !== libraryEventId)
                );
            });
            newRecentlyUsed = [
                { type: newType, isLibraryEvent: true, libraryEventId },
                ...recentlyUsed,
            ];
        } else {
            // filter out duplicates of this event type
            recentlyUsed = recentlyUsed.filter((entry) => {
                return (
                    entry.isLibraryEvent ||
                    (!entry.isLibraryEvent && entry.type !== newType)
                );
            });
            newRecentlyUsed = [
                { type: newType, isLibraryEvent: false },
                ...recentlyUsed,
            ];
        }
        // update loggedInUser
        loggedInUser.recentlyusednodes = newRecentlyUsed;
        localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
        this.props.updateUserData(loggedInUser);
        localStorage.setItem(
            "recentlyusednodes",
            JSON.stringify(newRecentlyUsed)
        );
    };

    submitBankData = (state) => {
        if (this.props.focus) {
            // let parent = this.props.focus;
            let parent = this.props.focus;
            const data = state;
            const type = state.type;
            const node = this.props.manager.createEvent(type, data);
            this.props.manager.attachToNodes(node, [parent], []);
            this.updateEvents();
            // parent = node;
        } else if (this.props.line) {
            const { parentEvent, childEvent } = this.props.line;
            const data = state;
            const type = state.type;
            const node = this.props.manager.createEvent(type, data);
            this.props.manager.attachToNodes(node, [parentEvent], [childEvent]);
            this.updateEvents();
            // parent = node;
        }

        this.onCloseModal();
        this.props.closeOption();
    };

    updateNodes = (
        valuesList,
        overrideEditDataList = [],
        nextFunction = null,
        index = 0
    ) => {
        const { loadedScenario, manager, editData, isBaseline } = this.props;

        if (!isBaseline) {
            const isScenario = loadedScenario.type === "scenario";
            const isPassed = loadedScenario.type === "shared";

            if (loadedScenario && (isScenario || isPassed)) {
                const values = valuesList[index];
                const overrideEditData =
                    overrideEditDataList.length >= index + 1
                        ? overrideEditDataList[index]
                        : null;
                const customEditData = overrideEditData && { ...editData };
                if (overrideEditData) {
                    for (const [key, val] of Object.entries(overrideEditData)) {
                        customEditData[key] = val;
                    }
                }

                // NOTE: updateNode() is a redux action. See actions/scenario.js
                this.props
                    .updateNode(
                        values,
                        loadedScenario,
                        manager,
                        customEditData || editData,
                        this.props.baselineDataManager
                    )
                    .then((data) => {
                        if (data) {
                            if (index == valuesList.length - 1) {
                                const toastData = {
                                    type: "success",
                                    text: `Successfully Update Nodes`,
                                    upgrade: false,
                                };
                                this.setState(
                                    {
                                        toastData,
                                    },
                                    () => {
                                        setTimeout(() => {
                                            this.setState({ toastData: null });
                                        }, 7000);
                                    }
                                );
                                this.props.getUserScenarios();

                                if (nextFunction) {
                                    setTimeout(() => {
                                        nextFunction();
                                    }, 500);
                                }
                            } else {
                                this.updateNodes(
                                    valuesList,
                                    overrideEditDataList,
                                    nextFunction,
                                    index + 1
                                );
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err, "<--- err on saving node data");
                    });
            }
        }
    };

    updateNode = (values) => {
        const {
            loadedScenario,
            manager,
            editData,
            isBaseline,
            baselineManager,
            baselineDataManager,
        } = this.props;

        if (isBaseline) {
            const updatedValue = loadedScenario.data.nodes.map((data) => {
                if (data.id === editData.id) {
                    const newData = {
                        ...data,
                        ...values,
                    };
                    return newData;
                }
                return data;
            });
            const scenario = {
                account: loadedScenario.account,
                id: loadedScenario.id,
                name: loadedScenario.name,
                description: loadedScenario.description,
                data: {
                    version: loadedScenario.data.version,
                    nodes: [...updatedValue],
                    root: loadedScenario.data.root,
                },
                range: loadedScenario.range,
                inflation: loadedScenario.inflation,
            };
            this.props
                .updateBaseline(scenario, baselineDataManager)
                .then((data) => {
                    if (data) {
                        return this.props.loadScenario(
                            scenario,
                            baselineManager
                        );
                    }
                })
                .catch((err) => {
                    console.log(err, ",---- err");
                });
            this.onCloseModal();
        } else {
            const isScenario = loadedScenario.type === "scenario";
            const isPassed = loadedScenario.type === "shared";

            if (loadedScenario && (isScenario || isPassed)) {
                // NOTE: updateNode() is a redux action. See actions/scenario.js
                this.props
                    .updateNode(
                        values,
                        loadedScenario,
                        manager,
                        editData,
                        this.props.baselineDataManager
                    )
                    .then((data) => {
                        if (data) {
                            this.onCloseModal();
                            const toastData = {
                                type: "success",
                                text: `Successfully Update "${values.name}" Node`,
                                upgrade: false,
                            };
                            this.setState(
                                {
                                    toastData,
                                },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ toastData: null });
                                    }, 7000);
                                }
                            );
                            this.props.getUserScenarios();
                            // this.updateEvents();
                        }
                    })
                    .catch((err) => {
                        console.log(err, "<--- err on saving node data");
                    });
            } else {
                //this is where we update sample scenario for jon only
                const loggedInUser = JSON.parse(
                    localStorage.getItem("loggedInUser")
                );
                if (loggedInUser && isActiveUserAdmin()) {
                    const updatedValue = loadedScenario.data.nodes.map(
                        (data) => {
                            if (data.id === editData.id) {
                                const newData = {
                                    ...data,
                                    ...values,
                                };
                                return newData;
                            }
                            return data;
                        }
                    );

                    const scenario = {
                        ...loadedScenario,
                        data: {
                            version: loadedScenario.data.version,
                            nodes: [...updatedValue],
                            root: loadedScenario.data.root,
                        },
                    };

                    this.props.updateSampleScenario(scenario, () => {
                        this.props.getTemplates();
                        this.props.loadScenario(scenario, manager);
                    });
                    this.onCloseModal();
                } else {
                    this.onCloseModal();
                    const toastData = {
                        type: "error",
                        text: "Please copy the Sample Scenario if you want to modify it.",
                        upgrade: false,
                    };
                    this.setState(
                        {
                            toastData,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({ toastData: null });
                            }, 7000);
                        }
                    );
                }
            }
        }
    };

    updateEvents = () => {
        this.props.manager.calculate();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.bankData !== this.props.bankData) {
            this.setState({});
        }
    }

    cancelToast = () => {
        this.setState({ toastData: null });
    };

    onHandleUpgrade = () => {
        this.props.history.push("/billing");
    };

    onHandleInputEdit = () => {
        //pass this to call input edit
    };

    handleBack = () => {
        this.props.onHandleOptionSelected(null);
        this.props.onhandleFocusedInput(null);
        if (this.props.isAddingLibraryEvent) this.onCloseModal();
    };

    fillPartialValuesMultiple = (
        oldStateList,
        specificParamsList,
        passedCheckList,
        customEditDataList = [],
        nextFunction = null
    ) => {
        if (this.props.loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }

        const newStateList = [];

        for (let i = 0; i < oldStateList.length; i++) {
            const oldState = oldStateList[i];
            const specificParams = specificParamsList[i] || {};
            const passedCheck = passedCheckList[i] || false;

            let newState = {
                ...oldState,
                ...specificParams,
                isFilled: false,
                valid: false,
            };
            if (passedCheck) {
                newState = {
                    ...oldState,
                    ...specificParams,
                    isFilled: true,
                    valid: true,
                };
            } else {
                throwError(
                    "warning",
                    "Missing Inputs",
                    "The submitted information has been saved, but this event will not be included in calculations until all required fields are complete."
                );
            }

            newStateList.push(newState);
            if (newState.isLibraryEvent) {
                return;
            }
        }

        if (!this.props.isAddingLibraryEvent) {
            this.updateNodes(newStateList, customEditDataList, nextFunction);
        }
    };

    // This fillPartialValues function will be given as a prop to every eventInput below, it allows
    // saving partial information but will not include event node in calculations if required fields
    // are empty
    fillPartialValues = (oldState, specificParams = {}, passedCheck = true) => {
        if (this.props.loadedScenario.is_archived) {
            throwError(
                "error",
                "Archived Scenario",
                "Cannot edit events in archived scenarios"
            );
            return;
        }
        let newState = {
            ...oldState,
            ...specificParams,
            isFilled: passedCheck ? true : false,
            valid: passedCheck ? true : false,
        };
        if (!passedCheck) {
            throwError(
                "warning",
                "Missing Inputs",
                "The submitted information has been saved, but this event will not be included in calculations until all required fields are complete."
            );
        }
        if (this.props.isAddingLibraryEvent || newState.isLibraryEvent) {
            this.libraryUpdateAction(newState);
        } else {
            this.updateNode(newState);
        }
    };

    // for passing to event inputs
    // separates instance of a library event from the library
    divorceFromLibraryEvent = (state, updateValues) => {
        const newState = {
            ...state,
            isLibraryEvent: false,
            libraryEventId: null,
        };
        updateValues(newState);
    };

    render() {
        const { selectedOption, edit, editData, isAddingLibraryEvent } =
            this.props;
        const { upgradePlan } = this.state;
        let component;
        if (selectedOption || edit) {
            const minProps = {
                edit: edit,
                editData: editData,
                focus: this.props.focus,
                line: this.props.line,
            };

            const libraryProps = {
                divorceFromLibraryEvent: this.divorceFromLibraryEvent,
                isAddingLibraryEvent: isAddingLibraryEvent,
                addLibraryEventToScenario: this.addLibraryEventToScenario,
            };

            switch (selectedOption || editData.type) {
                case incomeObject.constant():
                    component = <IncomeInputExperimental {...minProps} />;
                    break;
                case taxDeductibleObject.constant():
                    component = (
                        <TaxDeductibleInput
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                        />
                    );
                    break;
                case expenseObject.constant():
                    component = expenseObject.inputInfoPassing(
                        minProps,
                        libraryProps
                    );
                    break;
                case tuitionObject.constant():
                    component = tuitionObject.inputInfoPassing(
                        minProps,
                        libraryProps
                    );
                    break;
                case mortgageObject.constant():
                    component = (
                        <MortgageALT
                            {...minProps}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            line={this.props.line}
                            highlightedThread={this.props.highlightedThread}
                            setHighlightedThread={
                                this.props.setHighlightedThread
                            }
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case loanObject.constant():
                    component = <LoanInputExperimental {...minProps} />;
                    break;
                case studentLoanObject.constant():
                    component = studentLoanObject.inputInfoPassing(
                        minProps,
                        libraryProps
                    );
                    break;
                case decisionObject.constant():
                    component = (
                        <DecisionInputExperimental
                            {...minProps}
                            onboardingData={this.props.onboardingData}
                        />
                    );
                    break;
                case budgetObject.constant():
                    component = budgetObject.inputInfoPassing(
                        minProps,
                        libraryProps
                    );
                    break;
                case studentBudgetObject.constant():
                    component = studentBudgetObject.inputInfoPassing(
                        minProps,
                        libraryProps
                    );
                    break;
                case goalObject.constant():
                    component = <GoalInputExperimental {...minProps} />;
                    break;
                case rentObject.constant():
                    component = rentObject.inputInfoPassing(
                        minProps,
                        libraryProps
                    );
                    break;
                case houseObject.constant():
                    component = (
                        <HouseInputExperimental
                            {...minProps}
                            {...libraryProps}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case modifierObject.constant():
                    component = (
                        <ModifierInputExperimental
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case modifier2Object.constant():
                    component = (
                        <Modifier2InputExperimental
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case expressionObject.constant():
                    component = (
                        <ExpressionInputExperimental
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case customerTransferObject.constant():
                    component = (
                        <CustomerTransferInputExperimental
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case propertyTransferTaxObject.constant():
                    component = (
                        <PropertyTransferTaxInput
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case incomeTaxObject.constant():
                    component = (
                        <IncomeTaxInputExperimental
                            {...minProps}
                            onboardingData={this.props.onboardingData}
                        />
                    );
                    break;
                case downpaymentObject.constant():
                    component = (
                        <DownpaymentInput
                            {...minProps}
                            focus={this.props.focus}
                            line={this.props.line}
                            highlightedThread={this.props.highlightedThread}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            onboardingData={this.props.onboardingData}
                        />
                    );
                    break;
                case investmentObject.constant():
                    component = (
                        <InvestmentInput
                            {...minProps}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case rrspObject.constant():
                    component = (
                        <RRSPInput
                            {...minProps}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case bankPlaidObject.constant():
                    component = (
                        <BankPlaidInput
                            saveFlinksBankData={this.props.saveFlinksBankData}
                            submitBankData={this.submitBankData}
                            getPlaidAccount={this.props.getPlaidAccount}
                            bankData={this.props.bankData}
                            {...minProps}
                            {...libraryProps}
                        />
                    );
                    break;
                case bankObject.constant():
                    component = (
                        <BankInputExperimental
                            saveFlinksBankData={this.props.saveFlinksBankData}
                            updateFlinksData={this.props.updateFlinksData}
                            bankData={this.props.bankData}
                            submitBankData={this.submitBankData}
                            getPlaidAccount={this.props.getPlaidAccount}
                            {...minProps}
                            {...libraryProps}
                        />
                    );
                    break;
                case equityObject.constant():
                    component = equityObject.inputInfoPassing(
                        minProps,
                        libraryProps
                    );
                    break;
                case propertyValueObject.constant():
                    component = propertyValueObject.inputInfoPassing(minProps);
                    break;
                case maternityObject.constant():
                    component = maternityObject.inputInfoPassing(minProps);
                    break;
                case propertyResaleObject.constant():
                    component = (
                        <PropertyResaleInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case personObject.constant():
                    component = (
                        <PersonInputExperimental
                            {...minProps}
                            {...libraryProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case employeeObject.constant():
                    component = (
                        <EmployeeInputExperimental
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case pensionObject.constant():
                    component = (
                        <PensionInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            baselineDataManager={this.props.baselineDataManager}
                            {...minProps}
                        />
                    );
                    break;
                case lumpSumPaymentObject.constant():
                    component = (
                        <LumpSumPaymentsInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case renewalRefinanceObject.constant():
                    component = (
                        <RenewalRefinanceInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case meObject.constant():
                    component = (
                        <MeInputExperimental
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            getUserData={this.props.getUserData}
                            {...minProps}
                        />
                    );
                    break;
                case businessObject.constant():
                    component = (
                        <BusinessInputExperimental
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            getUserData={this.props.getUserData}
                            {...minProps}
                        />
                    );
                    break;
                case startObject.constant():
                    component = (
                        <StartInputExperimental
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            getUserData={this.props.getUserData}
                            {...minProps}
                        />
                    );
                    break;
                case retirementObject.constant():
                    component = (
                        <RetirementInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            baselineDataManager={this.props.baselineDataManager}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case graduationObject.constant():
                    component = (
                        <GraduationInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            baselineDataManager={this.props.baselineDataManager}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case realEstateFeeObject.constant():
                    component = (
                        <RealEstateFeeInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case homeMaintenanceObject.constant():
                    component = (
                        <HomeMaintenanceInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case mortgageInsuranceObject.constant():
                    component = (
                        <MortgageInsuranceInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case capitalGainsObject.constant():
                    component = (
                        <CapitalGainsInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case closingCostsObject.constant():
                    component = (
                        <ClosingCostsInput
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                            {...minProps}
                        />
                    );
                    break;
                case shortTermRentalObject.constant():
                    component = (
                        <ShortTermRentalInput
                            {...minProps}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case kpiObject.constant():
                    component = (
                        <KPIsInputExperimental
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case instanceObject.constant():
                    component = (
                        <InstanceInputExperimental
                            {...minProps}
                            line={this.props.line}
                            focus={this.props.focus}
                            onboardingData={this.props.onboardingData}
                            onhandleFocusedInput={
                                this.props.onhandleFocusedInput
                            }
                        />
                    );
                    break;
                case rentalIncomeObject.constant():
                    component = rentalIncomeObject.inputInfoPassing(minProps);
                    break;
                case debitCreditObject.constant():
                    component = debitCreditObject.inputInfoPassing(minProps);
                    break;
                case debtObject.constant():
                    component = debtObject.inputInfoPassing(minProps);
                    break;
                case grantObject.constant():
                    component = grantObject.inputInfoPassing(minProps);
                    break;
                case debtRepaymentObject.constant():
                    component = debtRepaymentObject.inputInfoPassing(minProps);
                    break;
                case customerObject.constant():
                    component = customerObject.inputInfoPassing(minProps);
                    break;
                case projectsObject.constant():
                    component = projectsObject.inputInfoPassing(minProps);
                    break;
                case customer2Object.constant():
                    component = customer2Object.inputInfoPassing(minProps);
                    break;
                case customerGrowthObject.constant():
                    component = customerGrowthObject.inputInfoPassing(minProps);
                    break;
                case customerGrowth2Object.constant():
                    component =
                        customerGrowth2Object.inputInfoPassing(minProps);
                    break;
                case customerChurn2Object.constant():
                    component = customerChurn2Object.inputInfoPassing(minProps);
                    break;
                case customerTransfer2Object.constant():
                    component =
                        customerTransfer2Object.inputInfoPassing(minProps);
                    break;
                case nullObject.constant():
                    component = nullObject.inputInfoPassing(minProps);
                    break;
                case containerObject.constant():
                    component = containerObject.inputInfoPassing(minProps);
                    break;
                case customerChurnObject.constant():
                    component = customerChurnObject.inputInfoPassing(minProps);
                    break;
                case unitCostObject.constant():
                    component = unitCostObject.inputInfoPassing(minProps);
                    break;
                case revenueObject.constant():
                    component = revenueObject.inputInfoPassing(minProps);
                    break;
                case Student_IncomeObject.constant():
                    component = Student_IncomeObject.inputInfoPassing(minProps);
                    break;
                case condoFeeObject.constant():
                    component = condoFeeObject.inputInfoPassing(minProps);
                    break;
                case cacObject.constant():
                    component = cacObject.inputInfoPassing(minProps);
                    break;
                case contractObject.constant():
                    component = contractObject.inputInfoPassing({
                        ...minProps,
                        onhandleFocusedInput: this.props.onhandleFocusedInput,
                    });
                    break;
                case groupObject.constant():
                    component = groupObject.inputInfoPassing(minProps);
                    break;
                case projectObject.constant():
                    component = projectObject.inputInfoPassing(minProps);
                    break;
                case initialBalanceObject.constant():
                    component = initialBalanceObject.inputInfoPassing(minProps);
                    break;
                case importObject.constant():
                    component = importObject.inputInfoPassing(minProps);
                    break;
                case accountImportObject.constant():
                    component = accountImportObject.inputInfoPassing(minProps);
                    break;
                case growthObject.constant():
                    component = growthObject.inputInfoPassing(minProps);
                    break;
                case outboundSalesObject.constant():
                    component = outboundSalesObject.inputInfoPassing(minProps);
                    break;
                case salespersonObject.constant():
                    component = salespersonObject.inputInfoPassing(minProps);
                    break;
                case segmentObject.constant():
                    component = segmentObject.inputInfoPassing(minProps);
                    break;
                case allocationObject.constant():
                    component = allocationObject.inputInfoPassing(minProps);
                    break;
                case websiteVisitorsObject.constant():
                    component =
                        websiteVisitorsObject.inputInfoPassing(minProps);
                    break;
                case campaignObject.constant():
                    component = campaignObject.inputInfoPassing(minProps);
                    break;
                case CAC2Object.constant():
                    component = CAC2Object.inputInfoPassing(minProps);
                    break;
                case constraintObject.constant():
                    component = constraintObject.inputInfoPassing(minProps);
                    break;
                case accountModifierObject.constant():
                    component =
                        accountModifierObject.inputInfoPassing(minProps);
                    break;
                case percentageObject.constant():
                    component = percentageObject.inputInfoPassing(minProps);
                    break;
                case resourceObject.constant():
                    component = resourceObject.inputInfoPassing(minProps);
                    break;
                case utilizationObject.constant():
                    component = utilizationObject.inputInfoPassing(minProps);
                    break;
                case capacityObject.constant():
                    component = capacityObject.inputInfoPassing(minProps);
                    break;
                case averageOrderObject.constant():
                    component = averageOrderObject.inputInfoPassing(minProps);
                    break;
                case accountExpressionObject.constant():
                    component =
                        accountExpressionObject.inputInfoPassing(minProps);
                    break;
                case unitObject.constant():
                    component = unitObject.inputInfoPassing(minProps);
                    break;
                default:
            }
        }
        //retrieves name
        let eventName;
        try {
            eventName = getObjectFromUUID(selectedOption)?.name();
        } catch {
            eventName = "error";
        }

        return (
            <EventInputIDContext.Provider value={this.state.eventId}>
                <div className="modalContainer">
                    {!edit && (
                        <div
                            onClick={this.handleBack}
                            className="backTextContainer"
                        >
                            <img alt="alt" className="backSvg" src={backSvg} />
                            <div className="backText">
                                All Options/{eventName}
                            </div>
                        </div>
                    )}
                    <div className="modalContent">{component}</div>
                    {this.state.toastData && (
                        <EventsToast
                            data={this.state.toastData}
                            close={this.cancelToast}
                            history={null}
                        >
                            {upgradePlan && (
                                <div className="promptButtonContainer">
                                    <div
                                        onClick={this.cancelToast}
                                        className="deletePromptButton"
                                    >
                                        Close
                                    </div>
                                    <div
                                        onClick={this.onHandleUpgrade}
                                        className="deletePromptButton"
                                    >
                                        Upgrade
                                    </div>
                                </div>
                            )}
                        </EventsToast>
                    )}
                </div>
            </EventInputIDContext.Provider>
        );
    }
}

export default Inputs;

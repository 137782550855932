import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import GroupInputView from "./GroupInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { groupObject } from "Components/Registry/Group";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { incomeObject } from "Components/Registry/Income";
import { expenseObject } from "Components/Registry/Expense";
import { loanObject } from "Components/Registry/Loan";
import { debtObject } from "Components/Registry/Debt";
import { debtRepaymentObject } from "Components/Registry/Debt Repayment";
import { employeeObject } from "Components/Registry/Employee";
import { customer2Object } from "Components/Registry/Customer2";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { revenueObject } from "Components/Registry/Revenue";
import { contractObject } from "Components/Registry/Contract";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { groupInputsHandler } from "../OnInputChangeHandlers/groupInputsHandler";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import type { EntitySchema } from "../../../reducers/typesSchema/entitiesSchema";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { unitObject } from "Components/Registry/Unit";

export default function GroupInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = useMemo(
        () => getDefaultName(groupObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(groupObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entityIndex,
        entitiesMap,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "select-event" | "select-entity",
        mainId: string
    ) => {
        const value = e.target.value;
        const newEntitiesMap = groupInputsHandler(
            value,
            id,
            mainId,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleChangeEventType = (e: ChangeEvent<HTMLInputElement>) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.eventType = e.target.value;
        data.eventTypeId = getEventObject(data.eventType);
        const events = getEvents(data.eventType) || [];
        data.events = events;
        data.entities = {};

        Object.values(events).forEach((event) => {
            const currEntities = getRelevantEntities(event.entities) || [];
            data.entities[event.id] = Object.values(currEntities);
        });

        data.selectedEvents = [];
        data.selectedEntities = [];
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeGroupMode = (e: ChangeEvent<HTMLInputElement>) => {
        const newType = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.groupMode = newType;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getEvents = (event) => {
        const events = {};
        if ((!propsObject.eventId && !propsObject.line) || !event) return [];

        getPresentableDependencies(
            events,
            getEventObject(event),
            propsObject // Temporary object
        );

        console.log(events);

        if (!Object.keys(events).length) {
            throwError(
                "warning",
                `No ${event} nodes found upstream or in baseline`
            );
        }

        const eventObjects: Event | EventStructure[] = [];
        Object.values(events).forEach((event) => {
            const eventObject = getEvent(String(event));
            if (eventObject) {
                eventObjects.push(eventObject);
            }
        });

        return eventObjects;
    };

    // remove selected events that are not found upstream
    const updateSelectedEvents = (selectedEvents) => {
        if (!selectedEvents) {
            return [];
        }

        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const events = data.events;

        for (const selectedEvent of selectedEvents) {
            let foundEvent = false;
            for (const event of events) {
                if (event.id === selectedEvent) {
                    foundEvent = true;
                    break;
                }
            }
            if (!foundEvent) {
                selectedEvents.splice(selectedEvents.indexOf(selectedEvent), 1);
            }
        }

        return selectedEvents;
    };

    // removes selected entities whose events no longer exist
    const updateSelectedEntities = (entitiesList) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const selectedEntities = data.selectedEntities;

        if (!selectedEntities) {
            return [];
        }

        for (const selectedEntity of selectedEntities) {
            let foundEntity = false;
            selectedEntityLoop: for (const list of entitiesList) {
                for (const entity of list) {
                    if (entity.id === selectedEntity) {
                        foundEntity = true;
                        break selectedEntityLoop;
                    }
                }
            }

            if (!foundEntity) {
                selectedEntities.splice(
                    selectedEntities.indexOf(selectedEntity),
                    1
                );
            }
        }

        return selectedEntities;
    };

    const getEventObject = (event) => {
        switch (event) {
            case "Debit Credit":
                return debitCreditObject.constant();
            case "Income":
                return incomeObject.constant();
            case "Expense":
                return expenseObject.constant();
            case "Loan":
                return loanObject.constant();
            case "Debt":
                return debtObject.constant();
            case "Debt Repayment":
                return debtRepaymentObject.constant();
            case "Employee":
                return employeeObject.constant();
            case "Customer":
                return customer2Object.constant();
            case "Unit Cost":
                return unitCostObject.constant();
            case "Revenue":
                return revenueObject.constant();
            case "Contract":
                return contractObject.constant();
            case "Unit Adjustment":
                return unitObject.constant();
        }
    };

    // wipe data.events and data.entities since they cause an infinite loop
    function getExportEntities(entities) {
        const exportEntities = { ...entities };
        for (const [key, entity] of Object.entries(exportEntities)) {
            const currentEntityObject = {
                ...((entity as EntitySchema) || {}),
            };
            const data = { ...(currentEntityObject?.data || {}) };

            data.events = [];
            data.entities = {};
            currentEntityObject.data = data;
            exportEntities[key] = currentEntityObject;
        }

        return exportEntities;
    }

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            const events =
                getEvents(newEntitiesMap[currentEntity].data.eventType) || [];
            newEntitiesMap[currentEntity].data.events = events;
            const selectedEvents =
                newEntitiesMap[currentEntity].data.selectedEvents;
            const entities = {};

            Object.values(events).forEach((event) => {
                const currEntities = getRelevantEntities(event.entities) || [];
                entities[event.id] = Object.values(currEntities);
            });

            newEntitiesMap[currentEntity].data.entities = entities;
            newEntitiesMap[currentEntity].data.selectedEvents =
                updateSelectedEvents(selectedEvents);
            newEntitiesMap[currentEntity].data.selectedEntities =
                updateSelectedEntities(Object.values(entities));
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap, defaultName]);

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                getExportEntities(entitiesMap),
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = !!eventData.name && groupObject.checkInput(entitiesMap);
    return (
        <GroupInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            entitiesLength={entityIds.length}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleChangeGroupMode={handleChangeGroupMode}
            handleChangeEventType={handleChangeEventType}
            handleEntityStateChange={handleEntityStateChange}
        />
    );
}

import NotificationContainer from "./NotificationContainer";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";

const mapStateToProps = (state) => {
    return {
        loadedScenario: state.scenario.loadedScenario,
        userScenarios: state.scenario.userScenarios,
        sharedScenario: state.scenario.sharedScenario,
    };
};

const mapDispatchToProps = {
    showNotification: actions.showNotification,
};

const Notification = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationContainer);

export default Notification;

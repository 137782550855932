import { useState, useEffect, useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { accountModifierInputsHandler } from "../OnInputChangeHandlers/accountModifierInputsHandler";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import AccountModifierInputView from "./AccountModifierInputView";
import useOverrides from "../CustomHooks/useOverrides";

export default function AccountModifierInput({ line, focus, edit, editData }) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const eventId = useContext(EventInputIDContext);
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );
    const defaultName = useMemo(
        () => getDefaultName(accountModifierObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(accountModifierObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(entitiesObject, eventData, edit, accountModifierObject);

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                setEntitiesMap(
                    accountModifierInputsHandler(
                        value,
                        "entityName",
                        entitiesMap,
                        currentEntity
                    )
                );
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const newEntitiesMap = accountModifierInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, setEntitiesMap]);

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "action" | "value" | "maxValue" | "minValue"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = accountModifierInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = accountModifierInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const { createOverride, updateOverride } = useOverrides(
        entitiesMap,
        setEntitiesMap,
        currentEntity
    );

    const passedCheck =
        !!eventData.name && accountModifierObject.checkInput(entitiesMap);

    return (
        <AccountModifierInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
            updateAccount={updateAccount}
            createOverride={createOverride}
            updateOverride={updateOverride}
        />
    );
}

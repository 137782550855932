import React from "react";

import UtilizationCard from "../Card/utilizationCard";

import utilizationConnection from "Assets/_optionDescriptionIcons/Resource, Employee, Utilization, Capacity.png";

import utilizationBlack from "../../Assets/_eventNodeTypes/utilization.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import UtilizationInputExperimental from "Components/InputContainer/Utilization/Utilization";

export const utilizationObject = {
    constant: function () {
        return Utilization_Constant();
    },

    name: function () {
        return "Utilization";
    },

    checkInput: function (state) {
        return Utilization_CheckInput(state);
    },
    svg: function () {
        return Utilization_Svg();
    },

    eventNodeMenu: function (focus) {
        return Utilization_EventNodeMenu(focus);
    },

    indexText: function () {
        return Utilization_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Utilization_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Utilization_OptionDescription();
    },

    colouredPngs: function () {
        return Utilization_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Utilization_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Utilization_GenerateDisplayFieldData();
    },
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Utilization_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.value) return false;
        if (!entity.data.selectedEventId) return false;
        if (!entity.data.accountName) return false;
        if (!entity.data.contraAccountName) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Utilization_Constant = () => {
    return "7f51f857-5b91-4ab9-9875-4ea8b3b45b3d";
};

// EFFECTS: Returns black png / svg for event
const Utilization_Svg = () => {
    return utilizationBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Utilization_EventNodeMenu = (focus) => {
    return <UtilizationCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Utilization_IndexText = () => {
    return "The Project Event keeps track of project.";
};

// EFFECTS: Returns all icons for event ??
const Utilization_Icons = () => {
    let pngs = new Map([
        ["black", utilizationBlack],
        ["blue", utilizationBlack],
        ["white", utilizationBlack],
        ["grey", utilizationBlack],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Utilization_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Utilization_InputComponentInfoPassing = (minProps) => {
    return <UtilizationInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const Utilization_OptionDescription = () => {
    const tags = ["Option"];
    const type = utilizationObject.name();
    const description =
        "Input, import or augment what resources have already been used/utilized on a project to date. This Event can be integrated with a variety of third party platforms to automatically pull actuals per department or role (in development).";
    const usedForText =
        "This Event is commonly used in combination with Employees, Resources, Project and Capacity to help calculate the remaining resources required or available to complete a project.";
    const connection = "";
    const connectionImage = utilizationConnection;
    const learnMoreLink = "";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Utilization_SideModalImage = () => {
    return sideImage;
};

const Utilization_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

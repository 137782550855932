import React, { Component } from "react";
import "./Card.css";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";

class RevenueCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    getDependencyData = (eventId, entityIds) => {
        let dependantEntity = {};
        if (entityIds?.length > 0) {
            dependantEntity = getRelevantEntities(entityIds)[entityIds[0]];
        }
        const dependantEvent = getEvent(eventId);

        return { dependantEvent, dependantEntity };
    };

    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }

        const { description, name, data } =
            this.state.allEntities[this.state.currentIndex];
        const unitCostData = this.getDependencyData(
            data?.selectedUnitCost?.eventId,
            data?.selectedUnitCost?.entityIds,
        );

        const customerOrSegmentSection = () => {
            if (data?.revenueSource === "customer") {
                const customerData = this.getDependencyData(
                    data?.selectedCustomer?.eventId,
                    data?.selectedCustomer?.entityIds,
                );

                return (
                    <div className="Row">
                        <div className="ExpenseLabel">Selected Customer:</div>
                        <div className="cardValue">{`${
                            customerData.dependantEvent.name
                        } - ${customerData.dependantEntity.name ?? "All"}`}</div>
                    </div>
                )
            } else if (data?.revenueSource === "segment") {
                const segmentData = this.getDependencyData(
                    data?.selectedSegment?.eventId,
                    [data?.selectedSegment?.entityId],
                );

                const selectedSegment = segmentData?.dependantEntity?.data?.segments?.find((segment) => segment?.id === data?.selectedSegment?.segmentId)
                return (
                    <>
                        <div className="Row">
                            <div className="ExpenseLabel">Selected Segment:</div>
                            <div className="cardValue">{selectedSegment?.name}</div>
                        </div>
                        <div className="Row">
                            <div className="ExpenseLabel">Allocation:</div>
                            <div className="cardValue">{selectedSegment?.allocation}</div>
                        </div>
                    </>
                )
            }
        }
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Name:</div>
                    <div className="cardValue">{name ?? ""}</div>
                </div>
                {description && (
                    <div className="Row">
                        <div
                            className={
                                description ? "Description" : "NoDescription"
                            }
                        >
                            {description}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Revenue Source:</div>
                    <div className="cardValue">{data?.revenueSource ?? ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Selected UnitCost:</div>
                    <div className="cardValue">{`${
                        unitCostData.dependantEvent.name
                    } - ${unitCostData.dependantEntity.name ?? "All"}`}</div>
                </div>
                {customerOrSegmentSection()}
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() => this.handleArrowClick("left")}
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentIndex + 1} of{" "}
                        {this.state.allEntities.length}
                    </span>
                    <div
                        className={
                            this.state.currentIndex ===
                            this.state.allEntities.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() => this.handleArrowClick("right")}
                    ></div>
                </div>
            </div>
        );
    }
}

export default RevenueCard;

import type { CalculatedThreadsSchema } from "reducers/typesSchema/calculatedThreadsSchema";
import {
    RESET_CALCULATED_THREADS,
    UPSERT_CALCULATED_THREADS,
} from "actions/calculatedThreadsActions";

const initialState: CalculatedThreadsSchema = {};

export default function calculatedThreadsReducer(
    state = initialState,
    action: { type: string; payload?: CalculatedThreadsSchema }
) {
    switch (action.type) {
        case RESET_CALCULATED_THREADS:
            return { ...initialState };
        case UPSERT_CALCULATED_THREADS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

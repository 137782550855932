import React from "react";
import { Container } from "../../../helpers/constants";
import { getDefaultName } from "../../../helpers/index";
import "./_createContainerNodeMenu.css";

export default class CreateContainerNodeMenu extends React.Component {
    cancelContainerCreation = () => {
        this.props.resetContainerCreation();
    };

    createContainer = () => {
        if (!this.areBothNodesSelected()) return;
        const {
            manager,
            prospectiveHeadNode,
            prospectiveTailNode,
        } = this.props;
        const name = getDefaultName(Container, this.props);
        const type = Container;
        const metadata = {
            description: "",
            headNode: prospectiveHeadNode.id,
            tailNode: prospectiveTailNode.id,
            tag: `@${Container}`,
            isFilled: true,
            valid: true,
            containerIsExpanded: false,
            name,
            type,
        };

        const node = manager.createEvent(type, metadata);
        manager.attachToNodes(node, [], []);

        this.props.resetContainerCreation();
    };

    areBothNodesSelected = () => {
        const { prospectiveHeadNode, prospectiveTailNode } = this.props;
        const prospectiveHeadNodeName =
            prospectiveHeadNode &&
            prospectiveHeadNode.metadata &&
            prospectiveHeadNode.metadata.name;
        const prospectiveTailNodeName =
            prospectiveTailNode &&
            prospectiveTailNode.metadata &&
            prospectiveTailNode.metadata.name;
        return !!(prospectiveHeadNodeName && prospectiveTailNodeName);
    };

    render() {
        const { prospectiveHeadNode, prospectiveTailNode } = this.props;
        const prospectiveHeadNodeName =
            prospectiveHeadNode &&
            prospectiveHeadNode.metadata &&
            prospectiveHeadNode.metadata.name;
        const prospectiveTailNodeName =
            prospectiveTailNode &&
            prospectiveTailNode.metadata &&
            prospectiveTailNode.metadata.name;
        const bothNodesSelected = this.areBothNodesSelected();

        return (
            <div>
                <div>
                    <h1>You are now in creating container mode!</h1>
                </div>

                <div>
                    <h3>
                        Start Event:{" "}
                        {prospectiveHeadNodeName
                            ? prospectiveHeadNodeName
                            : "Not Selected Yet"}
                    </h3>
                    <h3>
                        End Event:{" "}
                        {prospectiveTailNodeName
                            ? prospectiveTailNodeName
                            : "Not Selected Yet"}
                    </h3>
                </div>

                <div className="create-container-node-menu__buttons-wrapper">
                    <div
                        className="textButton"
                        onClick={this.cancelContainerCreation}
                    >
                        Cancel
                    </div>
                    <div
                        className={
                            bothNodesSelected
                                ? "proceedButton"
                                : "proceedButtonDisabled"
                        }
                        onClick={this.createContainer}
                    >
                        Create Container
                    </div>
                </div>
            </div>
        );
    }
}

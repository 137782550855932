import type { ChartGraphGroupNodesModeSchema } from "reducers/typesSchema/chartGraphGroupNodesSchema";
import { useMemo, useState, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { AgGridReact } from "ag-grid-react";
import "./AgGridView.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { formatValueWithLimitedUnits } from "helpers/getUnit";
import { startObject } from "Components/Registry/Start";
import { getTopLevelAccount, recurseToTopAccount } from "helpers/accounts";

const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const getAccountFromIdString = (allLedgerData, accountId) => {
    const isCumulative = accountId.includes("Cumulative-");
    const pureAccountId = accountId.replace("Cumulative-", "");
    const accountData = {
        ...allLedgerData[pureAccountId],
        name: `${isCumulative ? "Cumulative " : ""}${
            allLedgerData[pureAccountId]?.name
        }`,
        cumulative: isCumulative,
    };
    return accountData;
};

export default function AgGridView() {
    // clean up following useAppSelectors to make into one destructured object useAppSelector - should make things easier to read

    const allLedgerData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata ?? {}
    );

    const nodesMode = useAppSelector(
        (state) =>
            state?.chartGraphGroupNodesMode as ChartGraphGroupNodesModeSchema
    );

    const focus = useAppSelector((state) => state?.scenario?.focus);

    const cumulativeNodes = useAppSelector(
        (state) => state?.chartGraph?.nodeIds
    );

    const selectedThreads = useAppSelector(
        (state) => state?.scenarioViewSelectedThreads?.selectedThreads
    );

    const allThreads = useAppSelector(
        (state) => state?.calculatedThreads,
        shallowEqual
    );

    const currentThreadId: string = useAppSelector(
        (state) => state?.scenario?.highlightedThread?.signature ?? ""
    );

    const [gridApi, setGridApi] = useState<any>();

    const onGridReady = (event) => {
        setGridApi(event?.api);
    };

    useEffect(() => {
        if (gridApi) {
            gridApi?.redrawRows();
        }
    }, [currentThreadId, gridApi]);

    const columnDefs = useMemo(() => {
        const allColumns: any[] = [];
        const threadsArray = Object.values(allThreads);
        const accountsArray = Object.values(
            threadsArray?.[0]?.accountData ?? {}
        );
        if (accountsArray?.length > 0) {
            const singleAccount = accountsArray?.[0];

            for (const date in singleAccount) {
                const columnDate: any = date?.split("-");
                const monthString: any = columnDate?.[1]?.split("");
                let columnFormatted = "";
                if (monthString[0] === "0") {
                    columnFormatted = `${MONTHS[monthString[1] - 1]}-${
                        columnDate[0]
                    }`;
                } else {
                    columnFormatted = `${MONTHS[columnDate[1] - 1]}-${
                        columnDate[0]
                    }`;
                }
                const columnObject = {
                    headerName: columnFormatted,
                    field: date,
                };
                allColumns.push(columnObject);
            }
        }
        return allColumns;
    }, [allThreads]);

    const rowData = useMemo(() => {
        const allRows: any[] = [];
        const activeThread = allThreads?.[currentThreadId];
        if (activeThread) {
            if (nodesMode === "singleNode" && focus) {
                const threadName = activeThread?.option
                    ? activeThread?.option
                    : "Thread is unnamed";

                for (const accountIdString in activeThread?.ledgersData) {
                    const accountData = getAccountFromIdString(
                        allLedgerData,
                        accountIdString
                    );

                    // this removes all cumulative accounts
                    if (accountData?.cumulative) return;

                    let accountIsRelated = false;

                    const recursedAccounts = recurseToTopAccount(
                        accountData.id
                    );

                    const row: any = {
                        accountType: accountData.name,
                        threadId: activeThread?.id,
                        nestingStructure: [
                            threadName,
                            allLedgerData[recursedAccounts?.[0]]?.name,
                        ],
                    };
                    if (allLedgerData[recursedAccounts?.[1]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[1]]?.name
                        );
                    }
                    if (allLedgerData[recursedAccounts?.[2]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[2]]?.name
                        );
                    }
                    if (allLedgerData[recursedAccounts?.[3]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[3]]?.name
                        );
                    }
                    if (allLedgerData[recursedAccounts?.[4]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[4]]?.name
                        );
                    }
                    const accountDateData =
                        activeThread?.ledgersData[accountIdString];

                    for (const date in accountDateData) {
                        const dateData = accountDateData[date];
                        let eventValue: any = null; //this is the value of a specific date

                        for (const event in dateData) {
                            const eventData = dateData[event];
                            if (focus?.id === event) {
                                eventValue = eventData;
                            }
                        }
                        if (eventValue !== null) {
                            accountIsRelated = true;
                            row[date] = formatValueWithLimitedUnits(
                                allLedgerData,
                                accountData.id,
                                eventValue
                            );
                        }
                    }
                    if (accountIsRelated) {
                        allRows.push(row);
                    }
                }
            } else if (
                nodesMode === "cumulativeNodes" &&
                focus &&
                focus?.type !== startObject?.constant()
            ) {
                const threadName = activeThread?.option
                    ? activeThread?.option
                    : "Thread is unnamed";
                for (const accountIdString in activeThread?.ledgersData) {
                    const accountData = getAccountFromIdString(
                        allLedgerData,
                        accountIdString
                    );

                    // this removes all cumulative accounts
                    if (accountData?.cumulative) return;

                    const recursedAccounts = recurseToTopAccount(
                        accountData.id
                    );

                    const row: any = {
                        accountType: accountData.name,
                        threadId: activeThread?.id,
                        nestingStructure: [
                            threadName,
                            allLedgerData[recursedAccounts?.[0]]?.name,
                        ],
                    };
                    if (allLedgerData[recursedAccounts?.[1]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[1]]?.name
                        );
                    }
                    if (allLedgerData[recursedAccounts?.[2]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[2]]?.name
                        );
                    }
                    if (allLedgerData[recursedAccounts?.[3]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[3]]?.name
                        );
                    }
                    if (allLedgerData[recursedAccounts?.[4]]?.name) {
                        row.nestingStructure.push(
                            allLedgerData[recursedAccounts?.[4]]?.name
                        );
                    }
                    const accountDateData =
                        activeThread?.ledgersData[accountIdString];
                    for (const date in accountDateData) {
                        const dateData = accountDateData[date];
                        let eventValue: any = 0;
                        for (const event in dateData) {
                            const eventData = dateData[event];

                            if (cumulativeNodes.includes(event)) {
                                eventValue = eventValue + eventData;
                            }
                        }
                        row[date] = formatValueWithLimitedUnits(
                            allLedgerData,
                            accountData.id,
                            eventValue
                        );
                    }
                    allRows.push(row);
                }
            } else if (allThreads[selectedThreads[0]]) {
                //single thread
                selectedThreads.forEach((selection) => {
                    const thread = allThreads[selection];
                    const threadName = thread.option
                        ? thread.option
                        : "Thread is unnamed";
                    Object.entries(thread?.accountData ?? {})?.forEach(
                        ([accountIdString, accountDates]) => {
                            const accountData = getAccountFromIdString(
                                allLedgerData,
                                accountIdString
                            );

                            // this removes all cumulative accounts
                            if (accountData?.cumulative) return;

                            const recursedAccounts = recurseToTopAccount(
                                accountData.id
                            );

                            const row: any = {
                                accountType: accountData.name,
                                threadId: activeThread?.id,
                                nestingStructure: [
                                    threadName,
                                    allLedgerData[recursedAccounts?.[0]]?.name,
                                ],
                            };
                            if (allLedgerData[recursedAccounts?.[1]]?.name) {
                                row.nestingStructure.push(
                                    allLedgerData[recursedAccounts?.[1]]?.name
                                );
                            }
                            if (allLedgerData[recursedAccounts?.[2]]?.name) {
                                row.nestingStructure.push(
                                    allLedgerData[recursedAccounts?.[2]]?.name
                                );
                            }
                            if (allLedgerData[recursedAccounts?.[3]]?.name) {
                                row.nestingStructure.push(
                                    allLedgerData[recursedAccounts?.[3]]?.name
                                );
                            }
                            if (allLedgerData[recursedAccounts?.[4]]?.name) {
                                row.nestingStructure.push(
                                    allLedgerData[recursedAccounts?.[4]]?.name
                                );
                            }
                            if (accountDates) {
                                for (const date in accountDates) {
                                    const value = accountDates[date];
                                    row[date] = formatValueWithLimitedUnits(
                                        allLedgerData,
                                        accountData.id,
                                        value
                                    );
                                }
                            }
                            allRows.push(row);
                        }
                    );
                });
            } else {
                //default
                const allSortedAccountsArrays: any = {};
                const allRowsMap: any = {};
                const threadName = activeThread?.option
                    ? activeThread?.option
                    : "Thread is unnamed";
                Object.entries(activeThread?.accountData ?? {}).forEach(
                    ([accountIdString, accountDates]) => {
                        const accountData = getAccountFromIdString(
                            allLedgerData,
                            accountIdString
                        );

                        // this removes all cumulative accounts
                        if (accountData?.cumulative) return;

                        const recursedAccounts = recurseToTopAccount(
                            accountData.id
                        );

                        const row: any = {
                            accountType: accountData.name,
                            threadId: activeThread?.id,
                            nestingStructure: [
                                threadName,
                                allLedgerData[recursedAccounts?.[0]]?.name,
                            ],
                        };
                        if (allLedgerData[recursedAccounts?.[1]]?.name) {
                            row.nestingStructure.push(
                                allLedgerData[recursedAccounts?.[1]]?.name
                            );
                        }
                        if (allLedgerData[recursedAccounts?.[2]]?.name) {
                            row.nestingStructure.push(
                                allLedgerData[recursedAccounts?.[2]]?.name
                            );
                        }
                        if (allLedgerData[recursedAccounts?.[3]]?.name) {
                            row.nestingStructure.push(
                                allLedgerData[recursedAccounts?.[3]]?.name
                            );
                        }
                        if (allLedgerData[recursedAccounts?.[4]]?.name) {
                            row.nestingStructure.push(
                                allLedgerData[recursedAccounts?.[4]]?.name
                            );
                        }
                        if (accountDates) {
                            for (const date in accountDates) {
                                const value = accountDates[date];
                                row[date] = formatValueWithLimitedUnits(
                                    allLedgerData,
                                    accountData.id,
                                    value
                                );
                            }
                        }
                        const topLevelAccount = getTopLevelAccount(
                            accountData?.id
                        );
                        if (topLevelAccount) {
                            if (
                                accountData?.parents?.length > 0 ||
                                accountData?.cumulative
                            ) {
                                if (
                                    topLevelAccount in allSortedAccountsArrays
                                ) {
                                    allSortedAccountsArrays[
                                        topLevelAccount
                                    ].push(accountIdString);
                                } else {
                                    allSortedAccountsArrays[topLevelAccount] = [
                                        accountIdString,
                                    ];
                                }
                            } else {
                                if (
                                    topLevelAccount in allSortedAccountsArrays
                                ) {
                                    // do nothing
                                } else {
                                    allSortedAccountsArrays[topLevelAccount] =
                                        [];
                                }
                            }
                        }
                        allRowsMap[accountIdString] = row;
                    }
                );
                const allSortedAccountsArraysEntries = Object.entries(
                    allSortedAccountsArrays
                );
                allSortedAccountsArraysEntries.forEach(
                    ([topLevelAccountId, relatedAccountIdsArray]: any) => {
                        if (allRowsMap[topLevelAccountId]) {
                            allRows.push(allRowsMap[topLevelAccountId]);
                        }
                        if (relatedAccountIdsArray?.length > 0) {
                            relatedAccountIdsArray?.sort((a, b) => {
                                a = a.includes("Cumulative-");
                                b = b.includes("Cumulative-");
                                if (a === b) return 0;
                                if (a && !b) return 1;
                                if (!a && b) return -1;
                            });
                            relatedAccountIdsArray.forEach(
                                (relatedAccountId) => {
                                    if (allRowsMap[relatedAccountId]) {
                                        allRows.push(
                                            allRowsMap[relatedAccountId]
                                        );
                                    }
                                }
                            );
                        }
                    }
                );
            }
        }
        return allRows;
    }, [
        allThreads,
        currentThreadId,
        focus,
        nodesMode,
        selectedThreads,
        cumulativeNodes,
        allLedgerData,
    ]);

    const defaultColDef = useMemo(() => {
        return {
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                debounceMs: 0,
            },
            sortable: true,
            resizable: true,
            width: 150,
        };
    }, []);

    const autoGroupColumnDef = {
        headerName: "Account Structure",
        width: 300,
        cellRendererParams: {
            suppressCount: true,
        },
        pinned: "left",
        lockPinned: true,
    };

    const getRowStyle = (params) => {
        const currentThreadId = params?.context?.currentThreadId;
        if (params?.data?.threadId === currentThreadId) {
            return { background: "#faeddd" };
        }
    };

    const getDataPath = (data) => {
        return data?.nestingStructure;
    };

    return (
        <div className="agGridContainer">
            <div className="ag-theme-balham">
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    groupDefaultExpanded={1}
                    getRowStyle={getRowStyle}
                    onGridReady={onGridReady}
                    context={{
                        currentThreadId,
                    }}
                    treeData={true}
                    getDataPath={getDataPath}
                />
            </div>
        </div>
    );
}

import type { ScenarioViewSelectedThreadsSchema } from "./typesSchema/ScenarioViewSelectedThreads";
import {
    RESET_SCENARIO_VIEW_ALL_STATE,
    RESET_SCENARIO_VIEW_SELECTED_THREADS,
    UPSERT_SCENARIO_VIEW_SELECTED_THREADS,
} from "actions/scenarioViewSelectedThreadsActions";

const initialState: ScenarioViewSelectedThreadsSchema = {
    selectedThreads: [],
    shouldFilterThreads: false,
};

export default function scenarioViewSelectedThreads(
    state = initialState,
    action: {
        type: string;
        payload: Partial<ScenarioViewSelectedThreadsSchema>;
    }
) {
    switch (action.type) {
        case RESET_SCENARIO_VIEW_ALL_STATE:
            return { ...initialState };
        case RESET_SCENARIO_VIEW_SELECTED_THREADS:
            return { ...initialState, selectedThreads: [] };
        case UPSERT_SCENARIO_VIEW_SELECTED_THREADS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

import { ChartGraphPointsData } from "./buildPointsData";
/**
 *
 * @param id The id of an entity or event
 * @param threadData The data for a givin thread
 * @param accountLedgerId The string name for a ledger name
 * @returns An array of the monthly data for the givin event/entity based off the passed id.
 */
export const buildPointsDataPerEntityGraphing = (
    id: string,
    threadData,
    accountLedgerId: string
): ChartGraphPointsData[] => {
    const selectedData: any = [];
    const ledgerData = threadData?.ledgersData?.[accountLedgerId];
    for (const date in ledgerData) {
        const dateData = ledgerData?.[date] ?? {};
        const value = dateData?.[id];
        selectedData.push({ date: date, value: value });
    }
    return selectedData;
};

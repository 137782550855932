import {
    useRef,
    useMemo,
    useState,
    useEffect,
    useCallback,
    KeyboardEvent,
} from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { AgGridReact } from "ag-grid-react";
import "./EntityTableView.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { RowNode, CellKeyDownEvent } from "ag-grid-community";
import {
    getEntityColumnData,
    getEntityRowData,
} from "./helpers/entityRowColumnData";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { CheckBoxCellRenderer } from "./cellRenderers/checkbox";
import { DateSelectorCellRenderer } from "./cellRenderers/dateSelector";
import { DropDownCellRenderer } from "./cellRenderers/dropDown";
import { AccountWaterfallCellRenderer } from "./cellRenderers/accountWaterfall";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import EntityStructureData from "exampleConfigJson/entityTypesStructure.json";
import { v4 as uuidv4 } from "uuid";

const StyledButton = styled(Button)<ButtonProps>(() => ({
    color: "#FFFFFF",
    textTransform: "capitalize",
    backgroundColor: "#F8B46A",
    "&:hover": {
        backgroundColor: "rgba(248, 179, 106, 0.75)",
    },
}));

export const EntityTableView = () => {
    const dispatch = useAppDispatch();

    const focus = useAppSelector(
        (state) => state?.scenario?.focus,
        shallowEqual
    );

    const gridRef: any = useRef(null);

    const [gridApi, setGridApi] = useState<any | null>(null);

    const onGridReady = (event) => {
        setGridApi(event?.api);
    };

    const frameworkComponents = useMemo(() => {
        return {
            checkBoxCellRenderer: CheckBoxCellRenderer,
            dateSelectorCellRenderer: DateSelectorCellRenderer,
            dropDownCellRenderer: DropDownCellRenderer,
            accountWaterfallCellRenderer: AccountWaterfallCellRenderer,
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                debounceMs: 0,
            },
            editable: true,
            sortable: true,
            resizable: true,
            width: 150,
        };
    }, []);

    const originalEntities = useMemo(() => {
        const originalEntitiesDataArray: any[] = [];
        if (focus?.entities?.length > 0) {
            Object.values(getRelevantEntities(focus?.entities) ?? {})?.forEach(
                (entity) => {
                    originalEntitiesDataArray.push(entity);
                }
            );
        }
        return originalEntitiesDataArray;
    }, [focus]);

    const [focusEntitiesMap, setFocusEntitiesMap] = useState<{}>({});

    const [cellUpdateEvent, setCellUpdateEvent] = useState<any>(null);

    const [selectedRows, setSelectedRows] = useState<RowNode[]>([]);

    const [hasChange, setHasChange] = useState<boolean>(false);

    const [rowData, setRowData] = useState<any[]>([]);

    const [columnDefs, setColumnDefs] = useState<any[]>([]);

    useEffect(() => {
        setHasChange(false);
        setCellUpdateEvent(null);
        if (focus) {
            const allEntityRowData = getEntityRowData(
                focus?.id,
                getRelevantEntities(focus?.entities)
            );
            setRowData(allEntityRowData);

            const entityTypeColumnDataKeys = getEntityColumnData(focus?.id);
            setColumnDefs(entityTypeColumnDataKeys);

            setFocusEntitiesMap({ ...getRelevantEntities(focus?.entities) });
        } else {
            setRowData([]);
            setColumnDefs([]);
            setFocusEntitiesMap({});
        }
    }, [focus]);

    useEffect(() => {
        if (gridApi) {
            gridApi?.refreshCells({ force: true });
            const rowDataMap = {};
            gridApi.forEachNode((rowNode) => {
                rowDataMap[rowNode?.data?.id] = rowNode.data;
            });
            compairEntityToRowData(originalEntities, rowDataMap);
        }
        // eslint-disable-next-line
    }, [rowData]);

    useEffect(() => {
        if (!cellUpdateEvent) return;

        let targetField = cellUpdateEvent.colDef.field;
        if (targetField === "name") {
            targetField = "entityName";
        }

        let account: any = null;
        if (cellUpdateEvent?.column?.colId === "accountName") {
            account = {
                name: cellUpdateEvent.newValue,
                ids: cellUpdateEvent?.data?.accountIds,
            };
        } else if (cellUpdateEvent?.column?.colId === "contraAccountName") {
            account = {
                name: cellUpdateEvent.newValue,
                ids: cellUpdateEvent?.data?.contraAccountIds,
            };
        }
        const focusEventObject = getObjectFromUUID(focus?.type);
        const hasDependenciesKey = !!(
            "dependencies" in focusEntitiesMap?.[cellUpdateEvent.data.id] &&
            focusEntitiesMap?.[cellUpdateEvent.data.id]?.dependencies
        );
        if (hasDependenciesKey) {
            const newEntitiesMap = focusEventObject.inputsHandler(
                cellUpdateEvent.newValue,
                targetField,
                focusEntitiesMap,
                cellUpdateEvent.data.id,
                focus.id,
                focusEntitiesMap?.[cellUpdateEvent.data.id]?.dependencies,
                account
            );
            setFocusEntitiesMap({ ...newEntitiesMap });
            const allEntityRowData = getEntityRowData(
                focus?.id,
                newEntitiesMap
            );
            setRowData(allEntityRowData);
        } else {
            const newEntitiesMap = focusEventObject.inputsHandler(
                cellUpdateEvent.newValue,
                targetField,
                0,
                focusEntitiesMap,
                cellUpdateEvent.data.id,
                account
            );
            setFocusEntitiesMap({ ...newEntitiesMap });
            const allEntityRowData = getEntityRowData(
                focus?.id,
                newEntitiesMap
            );
            setRowData(allEntityRowData);
        }
        // eslint-disable-next-line
    }, [cellUpdateEvent]);

    /**
     * @param originalEntities original entities for the focused event
     * @param currentRowData row entity data
     * @purpose takes the original entities for the focused event and compairs them with the row data and updates the hasChange state accordingly
     */
    const compairEntityToRowData = (originalEntities, currentRowData) => {
        let hasChangeCurrently = false;

        originalEntities.forEach((entity) => {
            const newEntity = {
                ...entity,
                data: {
                    ...entity.data,
                },
            };
            const correspondingRowData = currentRowData?.[newEntity?.id];
            for (const dataKey in correspondingRowData) {
                const newValue = String(correspondingRowData[dataKey]);
                if (dataKey in newEntity) {
                    if (String(newEntity?.[dataKey]) != newValue) {
                        hasChangeCurrently = true;
                    }
                } else if (dataKey in newEntity?.data) {
                    if (String(newEntity?.data?.[dataKey]) != newValue) {
                        hasChangeCurrently = true;
                    }
                }
            }
        });

        if (
            originalEntities?.length <
            Object.keys(focusEntitiesMap ?? {})?.length
        )
            hasChangeCurrently = true;

        setHasChange(hasChangeCurrently);
    };

    /**
     * creates a new entity, adds it to the focus entities map and updates the row data
     */
    const handleAddEntity = () => {
        const newEntityId = uuidv4();

        const newFocusEntitiesMap = {
            ...focusEntitiesMap,
            [newEntityId]: {
                ...EntityStructureData[focus?.type],
                id: newEntityId,
            },
        };

        setFocusEntitiesMap(newFocusEntitiesMap);
        const allEntityRowData = getEntityRowData(
            focus?.id,
            newFocusEntitiesMap
        );
        setRowData(allEntityRowData);
        setHasChange(true);
    };

    const handleDuplicateEntity = (selectedRows) => {
        const newFocusEntitiesMap = {
            ...focusEntitiesMap,
        };

        selectedRows?.forEach((row) => {
            const originalEntityId = row?.data?.id;
            const originalEntityData = focusEntitiesMap?.[originalEntityId];

            const newEntityId = uuidv4();

            newFocusEntitiesMap[newEntityId] = {
                ...originalEntityData,
                id: newEntityId,
                name: `${originalEntityData?.name} copy`,
            };
        });

        setFocusEntitiesMap(newFocusEntitiesMap);
        const allEntityRowData = getEntityRowData(
            focus?.id,
            newFocusEntitiesMap
        );
        setRowData(allEntityRowData);
        setHasChange(true);
        setSelectedRows([]);
        // WIP, trying to get rows to be reselected
        // const existingSelectionsMap = {};
        // selectedRows?.map((row) => {
        //     existingSelectionsMap[row?.data?.id] = true;
        // });

        // gridApi.forEachNode(function (node) {
        //     if (existingSelectionsMap?.[node.data.id]) {
        //         node.setSelected(true);
        //     }
        // });
    };

    const handleDeleteEntity = (selectedRows) => {
        const newFocusEntitiesMap = {
            ...focusEntitiesMap,
        };

        selectedRows?.forEach((row) => {
            const originalEntityId = row?.data?.id;
            delete newFocusEntitiesMap?.[originalEntityId];
        });

        setFocusEntitiesMap(newFocusEntitiesMap);
        const allEntityRowData = getEntityRowData(
            focus?.id,
            newFocusEntitiesMap
        );
        setRowData(allEntityRowData);
        setHasChange(true);
        setSelectedRows([]);
    };

    const handleUpdate = () => {
        const newNumberOfEntities = Object.keys(focusEntitiesMap)?.length;
        const currentMostRecentEntity = focus.mostRecentEntity + 1;

        const needsNewCurrentEntity =
            currentMostRecentEntity > newNumberOfEntities;

        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                {
                    ...focus.exportData(),
                    mostRecentEntity: needsNewCurrentEntity
                        ? 0
                        : focus.mostRecentEntity,
                },
                focusEntitiesMap,
                Object.keys(focusEntitiesMap),
                true,
                true,
                {}
            )
        );
    };

    const onCellValueChange = (event) => {
        if (event.newValue === undefined) return;
        setCellUpdateEvent(event);
    };

    interface CopiedEntity {
        copyId: string;
        entityData: any;
    }

    const [copiedEntity, setCopiedEntity] = useState<CopiedEntity>({
        copyId: "",
        entityData: {},
    });

    const onCellKeyDown = useCallback(
        (e: CellKeyDownEvent) => {
            const keyboardEvent: KeyboardEvent | undefined = e?.event as
                | KeyboardEvent
                | undefined;

            const rowData = e?.data;
            const rowNode: any = e?.node;
            if (!keyboardEvent) return;
            if (
                keyboardEvent?.key === "c" &&
                (keyboardEvent?.metaKey || keyboardEvent?.ctrlKey) &&
                rowNode?.selected
            ) {
                const copyId = uuidv4();
                setCopiedEntity({
                    copyId: copyId,
                    entityData: { ...focusEntitiesMap?.[rowData?.id] },
                });
                navigator.clipboard.writeText(copyId);
            }
        },
        [focusEntitiesMap]
    );

    const onPaste = (e) => {
        const clipboardValue = e?.clipboardData?.getData("text");
        const { rowIndex, column } = gridApi?.getFocusedCell();
        const rowData = gridApi?.getDisplayedRowAtIndex(rowIndex);
        const entityId = rowData?.data?.id;

        if (
            copiedEntity?.copyId === clipboardValue &&
            entityId !== copiedEntity?.entityData?.id
        ) {
            const newFocusEntitiesMap = {
                ...focusEntitiesMap,
            };

            newFocusEntitiesMap[entityId] = {
                ...copiedEntity?.entityData,
                id: entityId,
            };

            setFocusEntitiesMap(newFocusEntitiesMap);
            const allEntityRowData = getEntityRowData(
                focus?.id,
                newFocusEntitiesMap
            );
            setRowData(allEntityRowData);
            setHasChange(true);
            setSelectedRows([]);
            return;
        }

        const eventObject = {
            colDef: column?.colDef,
            column: column,
            newValue: clipboardValue,
            data: rowData?.data,
            api: gridApi,
        };

        onCellValueChange(eventObject);
    };

    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedNodes();
        setSelectedRows(selectedRows);
    };

    return (
        <div className="entityTableView">
            <div className="agGridContainer">
                <div className="ag-theme-balham" onPaste={onPaste}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        frameworkComponents={frameworkComponents}
                        rowSelection={"multiple"}
                        suppressRowClickSelection={true}
                        rowDragManaged={true}
                        onCellValueChanged={(e) => onCellValueChange(e)}
                        onSelectionChanged={onSelectionChanged}
                        onCellKeyDown={onCellKeyDown}
                        // suppressClipboardPaste={true}
                    />
                </div>
            </div>
            <div className="entityTableView__buttonsContainer">
                <span className="entityTableView__disclaimerText">
                    * this feature is in beta and requires user accuracy *
                </span>
                <div className="entityTableView__buttons">
                    <StyledButton
                        disabled={
                            !gridApi || !focus?.id || selectedRows?.length === 0
                        }
                        variant="contained"
                        onClick={() => handleDeleteEntity(selectedRows)}
                    >
                        {`Delete Record${selectedRows?.length > 1 ? "s" : ""}`}
                    </StyledButton>
                    <StyledButton
                        disabled={
                            !gridApi || !focus?.id || selectedRows?.length === 0
                        }
                        variant="contained"
                        onClick={() => handleDuplicateEntity(selectedRows)}
                    >
                        {`Duplicate Record${
                            selectedRows?.length > 1 ? "s" : ""
                        }`}
                    </StyledButton>
                    <StyledButton
                        disabled={!gridApi || !focus?.id}
                        variant="contained"
                        onClick={() => handleAddEntity()}
                    >
                        Add Record
                    </StyledButton>
                    <StyledButton
                        disabled={!gridApi || !focus?.id || !hasChange}
                        variant="contained"
                        onClick={() => handleUpdate()}
                    >
                        Update Records
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

// Default Imports
import React from "react";

// Import Event Card and Event Input
import DownpaymentCard from "../Card/downpaymentCard";
import DownpaymentInput from "../InputContainer/DownpaymentInput";

// Import Connection Image (if exists)
import DownpaymentConnectionImage from "../../Assets/_optionDescriptionIcons/House, Downpayment, Mortgage.png";

// Import Coloured PNGs
import downpaymentBlack from "../../Assets/_eventNodeTypes/downpayment-black.png";
import downpaymentBlue from "../../Assets/_eventNodeTypes/downpayment-blue.png";
import downpaymentWhite from "../../Assets/_eventNodeTypes/downpayment-white.png";
import downpaymentGrey from "../../Assets/_eventNodeTypes/downpayment-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

import { valueDoesNotExist } from "helpers";
import { DownpaymentAmount, DownpaymentRate } from "helpers/downpaymentHelpers";

import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";

// "Interface" object for Event
export const downpaymentObject = {
    constant: function () {
        return Downpayment_Constant();
    },

    checkInput: function (state) {
        return Downpayment_CheckInput(state);
    },

    svg: function () {
        return Downpayment_Svg();
    },

    eventNodeMenu: function (focus) {
        return Downpayment_EventNodeMenu(focus);
    },

    indexText: function () {
        return Downpayment_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Downpayment_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Downpayment_OptionDescription();
    },

    colouredPngs: function () {
        return Downpayment_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Downpayment_SideModalImage();
    },
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Downpayment_CheckInput = (state) => {
    if (!state.associatedType) return false;
    if (
        state.lastChangedDownpaymentProperty === DownpaymentAmount &&
        (valueDoesNotExist(state.downpayment) || state.downpayment < 0)
    )
        return false;
    if (
        state.lastChangedDownpaymentProperty === DownpaymentRate &&
        (valueDoesNotExist(state.downpaymentRate) ||
            state.downpaymentRate < 0 ||
            state.downpaymentRate > 100)
    )
        return false;
    if (state.rrspContributionOne > state.rrspMaxOne) return false;
    if (state.rrspContributionTwo > state.rrspMaxTwo) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const Downpayment_Constant = () => {
    return "Downpayment";
};

// EFFECTS: Returns black png / svg for event
const Downpayment_Svg = () => {
    return downpaymentBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Downpayment_EventNodeMenu = (focus) => {
    return <DownpaymentCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Downpayment_IndexText = () => {
    return "Select an input field to learn more about your downpayment calculations.";
};

// EFFECTS: Returns all icons for event ??
const Downpayment_Icons = () => {
    let pngs = new Map([
        ["black", downpaymentBlack],
        ["blue", downpaymentBlue],
        ["white", downpaymentWhite],
        ["grey", downpaymentGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Downpayment_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Downpayment_InputComponentInfoPassing = (minProps) => {
    return <DownpaymentInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, downpayments for event ??
const Downpayment_OptionDescription = () => {
    const tags = ["Option"];
    const type = downpaymentObject.constant();
    const description =
        "Use this event to separate out a Down Payment from the Mortgage Event for standalone calculations. Experiment with different Down Payment amounts and calculate the impact on your mortgage payments, interest paid, and long-term net worth.";
    const usedForText =
        "Set a Down Payment amount and pass that value to one or more Mortgage Events with different terms for comparison. Or explore multiple different Down Payment amounts on a single mortgage and calculate payments, mortgage insurance thresholds, and cash flow. Note - the Mortgage Event also has built in, optional, Down Payment functionality.";
    const connection =
        "Set a Down Payment amount and pass that value to one or more Mortgage Events with different terms for comparison. Or explore multiple different Down Payment amounts on a single mortgage and calculate payments, mortgage insurance thresholds, and cash flow. Note - the Mortgage Event also has built in, optional, Down Payment functionality.";
    const connectionImage = DownpaymentConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/d/down_payment.asp";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Downpayment_SideModalImage = () => {
    return sideImage;
};

import Konva from "konva";
// import type { AnimationConfig } from "reducers/typesSchema/eventsTemplatesSchema";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Group, Text } from "react-konva";
// import { animated, useSpring } from "@react-spring/konva";
import { MenuIdContext } from "../contexts/MenuIdContext";
import { HoverButtonIdContext } from "../../../contexts/HoverButtonIdContext";
import { useMenuTemplate } from "../hooks/useMenuTemplate";
import {
    EventNodeActiveContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeLockedContext,
    HasCopiedEventContext,
    HasSelectedConnectionContext,
    HasMultipleChildrenContext,
    HasNoChildrenContext,
    EventTypeIsDecisionContext,
    EventTypeIsContainerContext,
} from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/contexts/EventNodeStateContexts";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import type { MenuTemplate } from "reducers/typesSchema/menusTemplatesSchema";
import { useActionsData } from "Components/EventsCanvas/Experimental/CanvasStage/hooks/useActionsData";
import { getActionActive } from "./MenuIcon/helpers/getActionActive";
import { EventNodeIDContext } from "../../../contexts/EventNodeIDContext";
import { getEvent, getSingleEntity } from "actions/getNodeEntityActions";
// import { BaseMenuContainerWidthContext } from "../contexts/BaseMenuContainerWidthContext";

// const TEXT_DEBUG = false;
// const DEBUG_TIMEOUT = 300;

export function MenuToolTipText() {
    const textRef = useRef<Konva.TextConfig>(null);
    const menuId = useContext(MenuIdContext);
    const hoverButtonId = useContext(HoverButtonIdContext);
    const eventId = useContext(EventNodeIDContext);
    // was used down below to have dynamic max width for text based off the size of the container. Its currently being hard coded to allow for menu sizing to be independent from the number of actions.
    // const containerWidth = useContext(BaseMenuContainerWidthContext);
    const active = useContext(EventNodeActiveContext);
    const focused = useContext(EventNodeFocusedContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const error = useContext(EventNodeErrorContext);
    const hasCopiedEvent = useContext(HasCopiedEventContext);
    const hasSelectedConnection = useContext(HasSelectedConnectionContext);
    const hasMultipleChildren = useContext(HasMultipleChildrenContext);
    const hasNoChildren = useContext(HasNoChildrenContext);
    const typeIsDecision = useContext(EventTypeIsDecisionContext);
    const typeIsContainer = useContext(EventTypeIsContainerContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);
    const menuTemplate = useMenuTemplate(menuId) as MenuTemplate;
    const actionData = useActionsData(hoverButtonId);
    const [textRealWidth, setTextRealWidth] = useState(0);

    const getExpandedState = () => {
        if (eventId && typeIsContainer) {
            const containerNode = getEvent(eventId);
            const containerEntity = getSingleEntity(
                containerNode?.entities?.[0] ?? ""
            );
            const containerExpanded = containerEntity?.data?.expanded;

            return containerExpanded ?? false;
        }
        return false;
    };

    const hasRelevantContainer = () => {
        const event: any = getEvent(eventId);
        if (event && event?.relevantContainerId) return true;
        return false;
    };

    const textValue = useMemo(() => {
        if (!actionData) return "";

        const activeTooltip = getActionActive(
            actionData?.action,
            typeIsDecision,
            typeIsContainer,
            bypassed,
            locked,
            !hasRelevantContainer() && hasCopiedEvent,
            hasSelectedConnection,
            hasRelevantContainer() || hasMultipleChildren,
            hasNoChildren,
            getExpandedState()
        );

        if (activeTooltip) {
            return actionData?.activeTooltipText;
        } else {
            return actionData?.inactiveTooltipText;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        actionData,
        typeIsDecision,
        bypassed,
        locked,
        hasCopiedEvent,
        hasSelectedConnection,
        hasMultipleChildren,
        hasNoChildren,
        typeIsContainer,
    ]);

    /**
     * Need to use useEffect instead of useMemo because useEffect runs after the component has rendered.
     * Question: Any way to avoid this useEffect & setState loop?
     */
    useEffect(() => {
        if (textValue) {
            const _textRealWidth = textRef.current?.getTextWidth?.();
            setTextRealWidth(_textRealWidth);
        }
    }, [textValue]);

    const _textStyling = useMemo(() => {
        const _styles = menuTemplate?.canvasStyles?.tooltipText?.styles;

        const _default = _styles?.default;
        const _active = active ? _styles?.active : {};
        const _focused = focused ? _styles?.focused : {};
        const _locked = locked ? _styles?.locked : {};
        const _bypassed = bypassed ? _styles?.bypassed : {};
        const _error = error ? _styles?.error : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _stylesMid = {
            ..._default,
            ..._active,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };

        const x = -(textRealWidth || (_stylesMid?.width ?? 0)) / 2;
        const y = -(_stylesMid?.height ?? _stylesMid?.fontSize ?? 0) / 2;

        // const width = focused ? containerWidth - 20 : 0;

        const _finalStyles: Partial<Konva.TextConfig> = {
            ..._stylesMid,
            x,
            y,
            // width,
        };

        if (!textRealWidth) _finalStyles.x = 0;

        return _finalStyles;
    }, [
        active,
        bypassed,
        error,
        focused,
        locked,
        menuTemplate?.canvasStyles?.tooltipText?.styles,
        textRealWidth,
        zoomCategory,
    ]);

    /**
     * The animation of the text component.
     */
    // const animationConfig = useMemo(() => {
    //     const defaultAnimationConfig = menuTemplate?.defaultAnimationConfig;

    //     const _animationOverrides =
    //         menuTemplate?.canvasStyles?.tooltipText?.animationOverride;
    //     const _default = _animationOverrides?.default;
    //     const _active = active ? _animationOverrides?.active : {};
    //     const _focused = focused ? _animationOverrides?.focused : {};
    //     const _locked = locked ? _animationOverrides?.locked : {};
    //     const _bypassed = bypassed ? _animationOverrides?.bypassed : {};
    //     const _error = error ? _animationOverrides?.error : {};
    //     const _zoomCategory = _animationOverrides?.[zoomCategory];

    //     const _animationConfig: Partial<AnimationConfig> = {
    //         ...defaultAnimationConfig,
    //         ..._default,
    //         ..._active,
    //         ..._focused,
    //         ..._locked,
    //         ..._bypassed,
    //         ..._error,
    //         ..._zoomCategory,
    //     };

    //     return _animationConfig;
    // }, [
    //     active,
    //     bypassed,
    //     error,
    //     focused,
    //     locked,
    //     menuTemplate?.canvasStyles?.tooltipText?.animationOverride,
    //     menuTemplate?.defaultAnimationConfig,
    //     zoomCategory,
    // ]);

    // const textStyling = useSpring({
    //     to: _textStyling,
    //     config: animationConfig,
    // });

    /**
     * Currently used for debugging only.
     */
    /**
     * For debugging only. Draw border around the text component so dev can see the actual drawn size. See: https://konvajs.org/api/Konva.Text.html
     */
    // useEffect(() => {
    //     if (TEXT_DEBUG && textStyling) {
    //         setTimeout(() => {
    //             textRef?.current?.cache({ drawBorder: true });
    //         }, DEBUG_TIMEOUT);
    //     }
    // }, [textStyling]);

    return (
        <Group>
            {/* @ts-ignore - Seems to be a react spring type error */}
            <Text ref={textRef} {..._textStyling} text={textValue} />
        </Group>
    );
}

import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Calendar as ImportedCalendar } from "react-date-range";
import closeSvg from "../../Assets/close.svg";
import "./SingleCalendar.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { createDate } from "../../helpers"; // theme css file

const Calendar = ({
    onHandleDate,
    isValid,
    date,
    title,
    useFullWidth,
    minDate,
    width,
    entityIndex,
}) => {
    const convertPropsToState = (_date) => {
        return _date ? createDate(_date) : _date;
    };

    const [startDate, setStartDate] = useState(convertPropsToState(date));
    const [isShowCalendar, setShowCalendar] = useState(false);

    const componentEnd = useRef();

    const scrollToBottom = () => {
        componentEnd.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (!isShowCalendar) setStartDate(convertPropsToState(date));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        setShowCalendar(false);
    }, [entityIndex]);

    useEffect(() => {
        if (isShowCalendar) {
            scrollToBottom();
        }
    }, [isShowCalendar]);

    const onHandleCalendarSelection = (date) => {
        if (isValid ? isValid(date) : true) {
            setStartDate(convertPropsToState(date));
        }
        onHandleDate(moment(date).format("YYYY-MM-DD"), null);
        setShowCalendar(false);
    };

    return (
        <div ref={componentEnd} className="CalendarContainer">
            <div
                className={
                    isShowCalendar
                        ? "singleCalendarContainerActive"
                        : "singleCalendarContainer"
                }
            >
                {isShowCalendar ? (
                    <>
                        <div>
                            <div className="dateTitle">
                                {title || "Date"}{" "}
                                <img
                                    alt="alt"
                                    src={closeSvg}
                                    className="close"
                                    onClick={() => setShowCalendar(false)}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <ImportedCalendar
                                    format="YYYY/MM/DD"
                                    editableDateInputs={true}
                                    onChange={(date) =>
                                        onHandleCalendarSelection(date)
                                    }
                                    date={startDate}
                                    months={1}
                                    direction="horizontal"
                                    showSelectionPreview={true}
                                    scroll={{
                                        enabled: false,
                                    }}
                                    className="singleCalendar"
                                    calendarWidth={10}
                                    calendarHeight={10}
                                    monthHeight={10}
                                    monthWidth={10}
                                    maxDate={createDate("2150-01-01")}
                                    minDate={minDate && createDate(minDate)}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="calendarInputContainer">
                        <input
                            type="text"
                            readOnly
                            onClick={() => setShowCalendar(true)}
                            value={
                                startDate
                                    ? moment(startDate).format("MMM Do YYYY")
                                    : ""
                            }
                            className={
                                width === "full"
                                    ? "dateInputFullWidth"
                                    : "dateInput"
                            }
                            placeholder={title || "Start Date"}
                            style={useFullWidth ? { width: "89%" } : {}}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Calendar;

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import RentalIncomeCard from "../Card/rentalIncomeCard";
import RentalIncomeInput from "../InputContainer/RentalIncomeInput";

// Import Connection Image
import rentalIncomeConnectionImage from "../../Assets/_optionDescriptionIcons/rentalIncomeConnectionImage.png";

// Import Coloured PNGs
import rentalIncomeBlack from "../../Assets/_eventNodeTypes/rentalIncome-black.png";
import rentalIncomeBlue from "../../Assets/_eventNodeTypes/rentalIncome-blue.png";
import rentalIncomeWhite from "../../Assets/_eventNodeTypes/rentalIncome-white.png";
import rentalIncomeGrey from "../../Assets/_eventNodeTypes/rentalIncome-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - rentalIncomeObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - rentalIncomeObject.checkInput.call(rentalIncomeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Income
export const rentalIncomeObject = {
    constant: function () {
        return RentalIncome_Constant();
    },

    checkInput: function (state) {
        return RentalIncome_CheckInput(state);
    },

    svg: function () {
        return RentalIncome_Svg();
    },

    eventNodeMenu: function (focus) {
        return RentalIncome_EventNodeMenu(focus);
    },

    indexText: function () {
        return RentalIncome_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return RentalIncome_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return RentalIncome_OptionDescription();
    },

    colouredPngs: function () {
        return RentalIncome_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return RentalIncome_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const RentalIncome_CheckInput = (state) => {
    if (state.income === undefined || state.income === "") return false;
    if (!state.start) return false;
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (
        (!state.rentalExpenses && state.rentalExpenses !== 0) ||
        state.rentalExpenses < 0
    )
        return false;
    if (!state.personId) return false;
    if (state.income === null || state.income === undefined || state.income < 0)
        return false;
    else return true;
};

// EFFECTS: Returns constant for event
const RentalIncome_Constant = () => {
    return "Rental Income";
};

// EFFECTS: Returns svg for event
const RentalIncome_Svg = () => {
    return rentalIncomeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const RentalIncome_EventNodeMenu = (focus) => {
    return <RentalIncomeCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const RentalIncome_IndexText = () => {
    return "Select an input field to learn more about your rental income calculations.";
};

// EFFECTS: Returns all icons for event ??
const RentalIncome_Icons = () => {
    let pngs = new Map([
        ["black", rentalIncomeBlack],
        ["blue", rentalIncomeBlue],
        ["white", rentalIncomeWhite],
        ["grey", rentalIncomeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const RentalIncome_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const RentalIncome_InputComponentInfoPassing = (minProps) => {
    return <RentalIncomeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const RentalIncome_OptionDescription = () => {
    const tags = ["Option"];
    const type = rentalIncomeObject.constant();
    const description =
        "Rental Income is money received, typically on a regular basis, due to leasing out or subletting all or part of a property.";
    const usedForText =
        "The Rental Income Node can be used to model incoming cashflows and expenses generated by your properties.";
    const connection =
        "Connect a Mortgage Node after a House Node. This node will inherit values and properties from the House Node and will stop generating income when the House is sold.";
    const connectionImage = rentalIncomeConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/articles/mortgages-real-estate/08/buy-rental-property.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const RentalIncome_SideModalImage = () => {
    return sideImage;
};

// import type { ThunkAction } from "redux-thunk";
// import type { RootState } from "store";
// import type { AnyAction } from "redux";
import type { ChartGraphSchema } from "reducers/typesSchema/chartGraphSchema";
import { AppThunk } from "store";
// import separateSingleNodeKey from "Components/NodeGraph/helpers/separateSingleNodeKey";

export const UPSERT_CHART_GRAPH = "chartGraph/UPSERT_CHART_GRAPH";
export const RESET_CHART_GRAPH = "chartGraph/RESET_CHART_GRAPH";
export const UPDATE_SELECTED_ACCOUNTS = "chartGraph/UPDATE_SELECTED_ACCOUNTS";

export const resetChartGraph = () => ({
    type: RESET_CHART_GRAPH,
});

export const upsertChartGraph = (payload: Partial<ChartGraphSchema>) => ({
    type: UPSERT_CHART_GRAPH,
    payload,
});

export function selectChartAccount(
    e: React.MouseEvent<HTMLElement>,
    account: { name: string; id: string }
): AppThunk<void> {
    return (dispatch, getState) => {
        const { name, id } = account;
        if (e?.metaKey || e?.ctrlKey) {
            const newSelectedAccounts = {
                ...getState()?.chartGraph?.selectedAccounts,
            };
            if (id in newSelectedAccounts) {
                delete newSelectedAccounts[id];
            } else {
                newSelectedAccounts[id] = { name, id };
            }

            if (Object.keys(newSelectedAccounts)?.length === 0) {
                newSelectedAccounts["1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78"] = {
                    name: "Cashflow",
                    id: "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78",
                };
            }
            dispatch({
                type: UPDATE_SELECTED_ACCOUNTS,
                payload: newSelectedAccounts,
            });
        } else {
            const newSelectedAccounts = { [id]: { name, id } };
            dispatch({
                type: UPDATE_SELECTED_ACCOUNTS,
                payload: newSelectedAccounts,
            });
        }
    };
}

import React, { Component } from "react";
import "./InputContainer.css";
import SingleCalendar from "../Calendar/singleCalendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    SelectDropDown,
    InputButtons,
    Description,
    EventInputs,
    EventInputModal,
    EventHeader,
    ModalRow,
    RequiredStar,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { Person, Me, MMMMToMM } from "../../helpers/constants";
import { getPresentableDependenciesOfManyTypes } from "../../helpers/nodeDependencyDetectionHelpers";
import { retirementObject } from "../Registry/Retirement";

class RetirementInput extends Component {
    constructor(props) {
        super(props);

        let people = {};
        people = this.getPeople();

        this.state = {
            start: null,
            end: null,
            rating: 0,
            showOptions: false,
            name: getDefaultName(retirementObject.constant(), this.props),
            type: retirementObject.constant(),
            value: null,
            showSettings: false,
            tag: `@${retirementObject.constant()}`,
            onHoverStar: null,
            inflation: false,
            baseline: false,
            personId: "",
            people,
            age: "",
            person: "",
            birthMonth: null,
            birthYear: null,
            description: "",
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            const people = this.getPeople();
            this.setState({
                ...this.props.editData,
                people,
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }
    onChangeInput = (e, id, _star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "person":
                this.updatePerson(value);
                break;
            default:
        }
    };

    updatePerson = (person) => {
        if (!person) {
            this.setState({
                person,
                personId: null,
            });
        } else {
            const { loadedScenario, baselineDataManager } = this.props;
            const personId = this.state.people[person];
            let selfNode;
            if (personId === "me") {
                selfNode = loadedScenario.data.nodes.find((node) => {
                    return node.metadata.type === Me;
                });
            } else {
                selfNode = loadedScenario.data.nodes.find(
                    (node) => node.id === personId
                );
            }
            if (!selfNode && baselineDataManager.hasActiveBaseline()) {
                const baselineNodes =
                    baselineDataManager.getActiveBaseline().data.nodes;
                selfNode = baselineNodes.find(
                    (node) => "baseline-" + node.id === personId
                );
            }
            const birthMonth = selfNode.metadata.birthMonth;
            const birthYear = selfNode.metadata.birthYear;
            const age = this.getRetirementAge(
                birthMonth,
                birthYear,
                this.state.start
            );
            this.setState({
                person,
                personId,
                birthMonth,
                birthYear,
                age,
            });
        }
    };

    getRetirementAge = (birthMonth, birthYear, startDate) => {
        if (!birthMonth || !birthYear || !startDate) return "";
        const monthIndex = MMMMToMM(birthMonth) - 1;
        // we use 15 as date value, median of month, since we don't store birthday in Person node:
        const birthDate = new Date(birthYear, monthIndex, 15);
        const retirementDate = new Date(startDate);
        const rMonth = retirementDate.getMonth();
        const bMonth = birthDate.getMonth();
        let years = new Date(startDate).getFullYear() - birthDate.getFullYear();
        if (bMonth >= rMonth) {
            years -= 1;
        }

        return years;
    };

    onHandleDate = (startDate) => {
        const age = this.getRetirementAge(
            this.state.birthMonth,
            this.state.birthYear,
            startDate
        );
        this.setState({ start: startDate, age });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = retirementObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    getPeople = () => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            this.props,
            "Person"
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        return people;
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    render() {
        const passedCheck = retirementObject.checkInput(this.state);
        const { edit } = this.props;
        const { name, person, age, start, people, description } = this.state;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        image={retirementObject.svg()}
                        eventType={retirementObject.constant()}
                        onChangeInput={this.onChangeInput}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={person}
                            options={Object.keys(people) || []}
                            className="termsContainer"
                            id="person"
                            label="Person"
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SingleCalendar
                            onHandleDate={this.onHandleDate}
                            date={start}
                            title="Retirement Date"
                        />
                    </ModalRow>
                    <ModalRow>
                        <div className="loanPaymentsContainer">
                            <div className="paymentsAmountContainer">
                                <div className="paymentsLabel">
                                    Your Retirement Age will be
                                </div>
                                <div className="paymentsValue">{age}</div>
                            </div>
                        </div>
                    </ModalRow>
                    {this.state.showRequired && (
                        <ModalRow>
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </ModalRow>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default RetirementInput;

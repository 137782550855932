import React, { Component } from "react";
import "./InputContainer.css";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import Switch from "react-switch";
import SingleCalendar from "../Calendar/singleCalendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import {
    Name,
    Description,
    SelectDropDown,
    ValueOutlined,
    Rate,
    Stars,
    InputButtons,
} from "./Components";
import { getDefaultName } from "../../helpers";
import Calendar from "../Calendar";
import { investmentObject } from "Components/Registry/Investment";

const options = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

const subTypes = [
    "RRSP",
    "TFSA",
    "Capital Investment",
    "Non-Capital Investment",
];

class InvestmentInput extends Component {
    constructor(props) {
        super(props);
        /// get people in scenario
        const nodes = this.props.manager.nodes;
        const baselineNodes = this.props.manager.baselineNodes;
        let people = {};
        if (nodes) {
            Object.values(nodes).forEach((node) => {
                // todo hide if bypassed
                if (node.type === "Person") {
                    people[node.metadata.name] = node.id;
                }
                if (node.type === "Me") {
                    people[node.metadata.name] = "me";
                }
            });
        }
        if (baselineNodes) {
            Object.values(baselineNodes).forEach((node) => {
                // todo hide if bypassed
                if (node.type === "Person") {
                    people[node.metadata.name] = node.id;
                }
                if (node.type === "Me") {
                    people[node.metadata.name] = "me";
                }
            });
        }
        this.state = {
            start: null,
            rating: 0,
            showOptions: false,
            name: getDefaultName(investmentObject.constant(), this.props),
            description: "",
            cadence: null,
            type: investmentObject.constant(),
            showSettings: false,
            inflation: false,
            tag: `@${investmentObject.constant()}`,
            subType: null,
            frequency: 1,
            interestRate: 0,
            initialValue: 0,
            value: null,
            onHoverStar: null,
            baseline: false,
            people: people,
            personId: "",
            personName: "",
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "value":
                this.setState({
                    value: parseInt(value),
                });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "cadence":
                if (value === "Select Contribution Frequency") {
                    this.setState({ cadence: null });
                } else {
                    this.setState({ cadence: value });
                }
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "subType":
                if (value === "Select subType") {
                    this.setState({ subType: null });
                } else {
                    this.setState({ subType: value });
                }
                break;
            case "interestRate":
                console.log("value ", value);
                if (value !== "") {
                    this.setState({
                        interestRate: parseFloat(value),
                    });
                } else {
                    this.setState({
                        interestRate: null,
                    });
                }

                break;
            case "initialValue":
                this.setState({
                    initialValue: parseInt(value),
                });
                break;
            default:
        }
    };

    onHandlePerson = (e) => {
        const value = e.target.value;
        if (!value || e.target.value === "Select Person") {
            this.setState({ personName: "", personId: null });
        } else {
            this.setState({
                personName: value,
                personId: this.state.people[value],
            });
        }
    };

    onHandleDate = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
    };

    toggledisableOnRetirement = () => {
        if (this.state.disableOnRetirement) {
            this.setState({ disableOnRetirement: false });
        } else {
            this.setState({
                disableOnRetirement: true,
                end: null,
            });
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    onHandleSubmitValues = () => {
        const passedCheck = investmentObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "interestRate":
                data = {
                    title: "Appreciation",
                    content:
                        "The appreciation rate describes the percentage by which the balance of your investment increases each year.",
                    subContent: null,
                    link: null,
                };
                break;
            case "inflation":
                data = {
                    title: "Inflation",
                    content:
                        "Select this option if you would like your regular investment contributions to grow in proportion to the standard inflation rate.",
                    subContent: null,
                    link: null,
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        const passedCheck = investmentObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            name,
            cadence,
            description,
            rating,
            onHoverStar,
            subType,
            initialValue,
            value,
            interestRate,
            disableOnRetirement,
            start,
            end,
        } = this.state;

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={investmentObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">INVESTMENT</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onHandlePerson}
                            value={this.state.personName}
                            options={Object.keys(this.state.people)}
                            className="select-dropdown"
                            id="person"
                            label="Select Person"
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={subType}
                            options={subTypes}
                            className="select-dropdown"
                            id="subType"
                            label="Select Subtype"
                        />
                    </div>

                    <div className="ModalRowSpaceBetween MarginBottom">
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <ValueOutlined
                                label="Initial Balance"
                                onChangeInput={this.onChangeInput}
                                value={initialValue}
                                id="initialValue"
                            />
                        </div>
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <ValueOutlined
                                label="Contribution Amount"
                                onChangeInput={this.onChangeInput}
                                value={value}
                                id="value"
                            />
                        </div>
                    </div>

                    <div className="ModalRowSpaceBetween">
                        <div className="twoThird">
                            <div className="Required">*</div>
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={cadence}
                                options={options}
                                className="select-dropdown"
                                id="cadence"
                                label="Select Contribution Frequency"
                            />
                        </div>
                        <div
                            className="oneThird"
                            onMouseEnter={() => {
                                this.onHandleMouseEnter("interestRate");
                            }}
                            onMouseLeave={() => {
                                this.onHandleMouseEnter(null);
                            }}
                        >
                            <div className="Required">*</div>
                            <Rate
                                value={interestRate}
                                label="Appreciation Rate"
                                className="mlsInput"
                                id="interestRate"
                                onChangeInput={this.onChangeInput}
                                sign={true}
                            />
                        </div>
                    </div>
                    <div className="ModalRow">
                        <img
                            alt="checkbox"
                            src={disableOnRetirement ? checkboxOn : checkboxOff}
                            className="checkBox"
                            onClick={this.toggledisableOnRetirement}
                        />
                        <div>Cancel Upon Retirement</div>
                    </div>

                    <div className="ModalRow">
                        <div className="Required">*</div>
                        {disableOnRetirement ? (
                            <SingleCalendar
                                onHandleDate={this.onHandleDate}
                                date={start}
                                title="Start Date"
                            />
                        ) : (
                            <Calendar
                                onHandleDate={this.onHandleDate}
                                startDate={start}
                                endDate={end}
                                cadence={cadence}
                            />
                        )}
                    </div>
                    <div className="ModalRow">
                        <div className="Required" />
                        <div className="keyLabel">Rating</div>
                        <div className="StarsHolder">
                            {stars.map((star, i) => {
                                const id = i + 1;
                                const onHover = onHoverStar;
                                if (i < rating || i < onHover) {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starFilledSvg"
                                            src={starFilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeave}
                                        />
                                    );
                                } else {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starUnfilledSvg"
                                            src={starUnfilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeave}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>

                    {this.state.showRequired && (
                        <div className="ModalRow">
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </div>
                    )}

                    <div
                        className="ModalRow"
                        onMouseEnter={() => {
                            this.onHandleMouseEnter("inflation");
                        }}
                        onMouseLeave={() => {
                            this.onHandleMouseEnter(null);
                        }}
                    >
                        <Switch
                            id="inflation"
                            checked={this.state.inflation}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={this.updateInflation}
                        />
                        <div className="InflationText">
                            Apply inflation to ongoing contributions
                        </div>
                        {/* <div className="InflationRateContainer">
                        {(this.props.manager.inflation * 100).toFixed(2)} %
                    </div> */}
                    </div>
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </div>
        );
    }
}

export default InvestmentInput;

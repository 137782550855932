// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import PersonInput from "../InputContainer/PersonInput";

// Import Connection Image (if exists)
import personConnectionImage from "../../Assets/_optionDescriptionIcons/Me, Bank, Income.png";

import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";
import bigComingSoonImage from "../../Assets/_optionDescriptionIcons/bigComingSoon.png";
import comingSoonImage from "../../Assets/_optionDescriptionIcons/comingSoon.png";

// Import Coloured PNGs
import personBlack from "../../Assets/_eventNodeTypes/person-black.png";
import personBlue from "../../Assets/_eventNodeTypes/person-blue.png";
import personWhite from "../../Assets/_eventNodeTypes/person-white.png";
import personGrey from "../../Assets/_eventNodeTypes/person-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - personObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - personObject.checkInput.call(personObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const personObject = {
    constant: function () {
        return Person_Constant();
    },

    checkInput: function (state) {
        return Person_CheckInput(state);
    },

    svg: function () {
        return Person_Svg();
    },

    eventNodeMenu: function (focus) {
        return Person_EventNodeMenu(focus);
    },

    indexText: function () {
        return Person_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Person_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Person_OptionDescription();
    },

    colouredPngs: function () {
        return Person_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Person_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Person_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        // if (!entity.name) return false;
        if (!entity.data.relationship) return false;
        if (!entity.data.birthMonth) return false;
        if (!entity.data.birthYear) return false;
        if (!entity.data.country) return false;
        if (!entity.data.state) return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Person_Constant = () => {
    return "Person";
};

// EFFECTS: Returns black png / svg for event
const Person_Svg = () => {
    return personBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Person_EventNodeMenu = () => {
    return <></>;
};

// EFFECTS: Returns the Index Text for event
const Person_IndexText = () => {
    return "Choose on one input field to learn more about the detail for Person Node.";
};

// EFFECTS: Returns all icons for event ??
const Person_Icons = () => {
    let pngs = new Map([
        ["black", personBlack],
        ["blue", personBlue],
        ["white", personWhite],
        ["grey", personGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Person_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Person_InputComponentInfoPassing = (minProps) => {
    return <PersonInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, persons for event ??
const Person_OptionDescription = () => {
    const tags = ["Option"];
    const type = personObject.constant();
    const description =
        "The Person Node is used to add individuals to your Baseline or Scenarios. These can be a Spouse or a Dependent and can be associated with other nodes, like Income or Investments";
    const usedForText =
        "Add a Person to your Baseline; like a child or partner. For future considerations, like having a baby, add then to a Scenario instead.";
    const connection = "You can connect any option or decision after a Person.";
    const connectionImage = personConnectionImage;
    const learnMoreLink = null;
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
        {
            id: 1,
            name: "Refinance Your Home",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 2,
            name: "Purchasing an Income Property",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 3,
            name: "Relocating",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 4,
            name: "Adding a Rental Suite",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 5,
            name: "Listing on AirBnB",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Person_SideModalImage = () => {
    return sideImage;
};

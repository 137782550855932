// @ts-nocheck

import type { ChangeEvent } from "react";
import { goalObject } from "Components/Registry/Goal";
import Switch from "react-switch";
import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    ModalRowTwoThirds,
    ModalRowOneThird,
    EntityName,
    ToggledContainer,
    ToggledContainerHalf,
    MuiCalendar,
    BasicHeading,
    Value,
} from "../Components";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

export default function GoalInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    eventData,
    handleOnChange,
    updateAccount,
    handleDateSelection,
    handleDisplaySwitches,
    selectedAccountData,
    handleEntityStateChange,
}) {

    const accountUnit = selectedAccountData?.unit?.display ?? ""

    const handleToggle = (e: ChangeEvent<any>, value: string) => {
        e.target.value = value;
        handleOnChange(e, "monthlyCumulative");
    };


    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={goalObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={goalObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[goalObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        required={true}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ModalRow>
                    <ToggledContainer>
                        <ToggledContainerHalf
                            id="monthly"
                            isSelected={
                                entitiesMap?.[currentEntity]?.data
                                    ?.monthlyCumulative === "monthly"
                            }
                            onClick={handleToggle}
                            title="Monthly"
                        />
                        <ToggledContainerHalf
                            id="cumulative"
                            isSelected={
                                entitiesMap?.[currentEntity]?.data
                                    ?.monthlyCumulative === "cumulative"
                            }
                            onClick={handleToggle}
                            title="Cumulative"
                        />
                    </ToggledContainer>
                </ModalRow>
                <ModalRow>
                    <BasicHeading copy="Goal Details" />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowTwoThirds>
                    <InputDropDownButton
                        buttonLabel="Select Target Account"
                        callback={updateAccount}
                        initialValue={
                            entitiesMap?.[currentEntity]?.data?.accountName ??
                            ""
                        }
                        required={true}
                    />
                    </ModalRowTwoThirds>
                    <ModalRowOneThird>
                        <Value
                            label="Target Value"
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.data?.value}
                            id="value"
                            startAdornment={accountUnit === ("$" || "%") ? accountUnit : ""}
                            required={true}
                            variant="outlined"
                            showSettings={false}
                        />
                    </ModalRowOneThird>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Switch
                            id="displayMarker"
                            checked={
                                entitiesMap?.[currentEntity]?.data.displayMarker ?? false
                            }
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={() => handleDisplaySwitches("displayMarker")}
                        />
                        <div className="InflationText">
                            Display as a Marker
                        </div>
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <Switch
                            id="displayThreshold"
                            checked={
                                entitiesMap?.[currentEntity]?.data.displayThreshold ?? false
                            }
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={() => handleDisplaySwitches("displayThreshold")}
                        />
                        <div className="InflationText">
                            Display as a Threshold
                        </div>
                    </ModalRowHalf>
                </ModalRow>
                {entitiesMap?.[currentEntity]?.data?.displayMarker && <ModalRow>
                    <ModalRowHalf>
                        <MuiCalendar id="startDate" value={entitiesMap?.[currentEntity]?.startDate} required={true} onChangeInput={handleDateSelection} label="Target Date" />
                    </ModalRowHalf>
                </ModalRow>}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

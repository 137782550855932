import { MappedAccountData } from "./accountMappingsSchema";

export enum FieldType {
    Account = "account",
    String = "string",
}

export type FieldMappingColumn<T> = {
    [value: string]: T;
};

export type AccountFieldMappingColumn = FieldMappingColumn<MappedAccountData>;
export type StringFieldMappingColumn = FieldMappingColumn<string>;

export type FieldMappingData = {
    [fieldName: string]: {
        data: FieldMappingColumn<null>;
        type: FieldType;
    };
};

export type FieldMappingDataMapped = {
    [fieldName: string]: AccountFieldMappingColumn | StringFieldMappingColumn;
};

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import KpiCard from "../Card/kpiCard.js";
import IncomeInput from "../InputContainer/IncomeInput.js";

// Import Connection Image (if exists)
import KPIConnectionImage from "Assets/_optionDescriptionIcons/KPI white.jpg";

// Import Coloured PNGs
import incomeBlack from "../../Assets/_eventNodeTypes/kpiSvg.svg";
import incomeBlue from "../../Assets/_eventNodeTypes/kpiSvg.svg";
import incomeWhite from "../../Assets/_eventNodeTypes/kpiSvg.svg";
import incomeGrey from "../../Assets/_eventNodeTypes/kpiSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - incomeObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - incomeObject.checkInput.call(incomeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Income
export const kpiObject = {
    constant: function () {
        return KPI_Constant();
    },

    name: function () {
        return "KPIs";
    },

    checkInput: function (state) {
        return KPI_CheckInput(state);
    },

    svg: function () {
        return KPI_Svg();
    },

    eventNodeMenu: function (focus) {
        return KPI_EventNodeMenu(focus);
    },

    indexText: function () {
        return KPI_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return KPI_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return KPI_OptionDescription();
    },

    colouredPngs: function () {
        return KPI_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Income_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return KPI_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const KPI_CheckInput = (entitiesMap) => {
    let passedCheck = true;

    const checkEntity = (entity) => {
        if (!entity?.name) {
            return false;
        }
        return true;
    };

    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const KPI_Constant = () => {
    return "d44719b8-76f4-47a6-a92d-f9660a4fbd96";
};

// EFFECTS: Returns svg for event
const KPI_Svg = () => {
    return incomeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const KPI_EventNodeMenu = (focus) => {
    return <KpiCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const KPI_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Income calculations.";
};

// EFFECTS: Returns all icons for event ??
const KPI_Icons = () => {
    let pngs = new Map([
        ["black", incomeBlack],
        ["blue", incomeBlue],
        ["white", incomeWhite],
        ["grey", incomeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Income_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const KPI_InputComponentInfoPassing = (minProps) => {
    return <IncomeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const KPI_OptionDescription = () => {
    const tags = ["Option"];
    const type = kpiObject.name();
    const description =
        "The KPI event allows you to calculate and display many commonly used KPIs such as LTV and Profit Margin";
    // const usedForText =
    //     "The Income Node can be used to model incoming cashflows.";
    const connection = "";
    const connectionImage = KPIConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/i/income.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        // ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Income_SideModalImage = () => {
    return sideImage;
};

const KPI_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import React, { Component } from "react";
import "./Card.css";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";

class BankPlaidCard extends Component {
    state = {
        showOptions: false,
        bankSelected: 0,
        toggleAccount: [],
        showSettings: false,
    };

    toggleAccount = (e) => {
        const id = parseInt(e.target.id);
        const { toggleAccount } = this.state;

        if (toggleAccount.includes(id)) {
            const removeId = toggleAccount.filter((item) => {
                return item !== id;
            });
            this.setState({ toggleAccount: removeId });
        } else {
            this.setState({ toggleAccount: [...toggleAccount, id] });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const { metadata } = this.props.data;
        const { toggleAccount } = this.state;
        const { version } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="BankLabel">Type</div>
                    <div className="BankSubLabel">Bank Account</div>
                </div>
                <div className="Row">
                    <div className="accountLabel">
                        {metadata.bankData && metadata.bankData.length}{" "}
                        Accounts,{" "}
                        {metadata.selected && metadata.selected.length} Added
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                <div className="selectedAccountsContainer">
                    {metadata.selected &&
                        metadata.selected.map((account, i) => {
                            const isToggled = toggleAccount.includes(i);
                            return (
                                <div key={i}>
                                    <div key={i} className="selectedAccounts">
                                        <div className="nicknameContainer">
                                            <img
                                                alt="alt"
                                                src={
                                                    isToggled
                                                        ? dropdownSvg
                                                        : expandSvg
                                                }
                                                onClick={this.toggleAccount}
                                                id={i}
                                                className="selectedIcon"
                                            />
                                            <div className="nickname">
                                                {i + 1}. {account.name}
                                            </div>
                                        </div>
                                        <div className="balanceContainer">
                                            {account.Currency} ${" "}
                                            {account.balances.current}
                                        </div>
                                    </div>
                                    {isToggled && (
                                        <div className="popUpContainer">
                                            <div className="Row">
                                                <div className="key">
                                                    Account #
                                                </div>
                                                <div className="value">
                                                    {account.mask}
                                                </div>
                                            </div>
                                            <div className="Row">
                                                <div className="key">Type</div>
                                                <div className="value">
                                                    {account.type}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                {/* <div className="Row">
                    <img
                        alt="alt"
                        onClick={this.onHandleAdvanceSetting}
                        className="selectedIcon"
                        src={showSettings ? dropdownSvg : expandSvg}
                    />
                    <div className="advanceSettingLabel">Advance settings</div>
                </div> */}
            </div>
        );
    }
}

export default BankPlaidCard;

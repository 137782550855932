// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    SelectDropDown,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    SingleEntity,
    ModalRowHalf,
    EntityNumericalFieldDisplay,
    Rate,
    ToggledContainer,
    ToggledContainerHalf,
} from "../Components";
import { Autocomplete, TextField } from "@mui/material";
import Calendar from "Components/Calendar";
import { getEntityFields } from "helpers/expressionHelper";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { expressionObject } from "Components/Registry/Expression";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

const options = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

export default function ExpressionInputView({
    entitiesMap,
    currentEntity,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    updateAccount,
    onHandleDate,
    entityIndex,
    handleChangeDebitCredit,
}) {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={expressionObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={expressionObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="debit"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data
                                ?.debitOrCredit === "debit"
                        }
                        onClick={handleChangeDebitCredit}
                        title="Debit"
                    />
                    <ToggledContainerHalf
                        id="credit"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data
                                ?.debitOrCredit === "credit"
                        }
                        onClick={handleChangeDebitCredit}
                        title="Credit"
                    />
                </ToggledContainer>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectDropDown
                            id="selectEvent"
                            onChangeInput={handleOnChange}
                            options={
                                entitiesMap?.[currentEntity]?.data
                                    .upstreamEvents
                            }
                            value={
                                entitiesMap?.[currentEntity]?.data.selectedEvent
                            }
                            className="select-dropdown"
                            label="Select Event"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        {entitiesMap[currentEntity].data.selectedEvent && (
                            <SelectDropDown
                                id="selectEntity"
                                onChangeInput={handleOnChange}
                                options={
                                    entitiesMap?.[currentEntity]?.data
                                        .eventEntities
                                }
                                value={
                                    entitiesMap?.[currentEntity]?.data
                                        .selectedEntity
                                }
                                className="select-dropdown"
                                label="Select Entity"
                            />
                        )}
                    </ModalRowHalf>
                </ModalRow>
                {entitiesMap[currentEntity].data.selectedEvent &&
                    entitiesMap[currentEntity].data.selectedEntity && (
                        <>
                            <ModalRow>
                                <EntityNumericalFieldDisplay
                                    numericalFields={getEntityFields(
                                        Object.values(
                                            getRelevantEntities([
                                                entitiesMap[currentEntity]?.data
                                                    ?.selectedEntity,
                                            ])
                                        )[0].data
                                    )}
                                />
                            </ModalRow>
                            <ModalRow twoInputs>
                                <ModalRowHalf>
                                    <SelectDropDown
                                        onChangeInput={handleOnChange}
                                        value={
                                            entitiesMap?.[currentEntity]
                                                ?.cadence
                                        }
                                        options={options}
                                        className="select-dropdown"
                                        id="cadence"
                                        label="Select Frequency"
                                    />
                                </ModalRowHalf>
                                <ModalRowHalf>
                                    <Autocomplete
                                        options={getEntityFields(
                                            Object.values(
                                                getRelevantEntities([
                                                    entitiesMap[currentEntity]
                                                        ?.data?.selectedEntity,
                                                ])
                                            )[0].data
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Expression"
                                            />
                                        )}
                                        sx={{ width: 300 }}
                                        disablePortal
                                        autoComplete={true}
                                        freeSolo={true}
                                        fullWidth={true}
                                        inputValue={
                                            entitiesMap[currentEntity]?.data
                                                ?.expression
                                        }
                                        onInputChange={(_, value) =>
                                            handleOnChange(
                                                // Hacky fix: handleOnChange extracts value via `e.target.value`
                                                //  recreating that structure here.
                                                { target: { value: value } },
                                                "expression"
                                            )
                                        }
                                    />
                                </ModalRowHalf>
                            </ModalRow>
                            <ModalRow twoInputs>
                                <ModalRowHalf>
                                    <Rate
                                        label="Evaluated Expression"
                                        onChangeInput={handleOnChange}
                                        value={
                                            entitiesMap[currentEntity]?.data
                                                .value
                                        }
                                        id=""
                                        className="mlsInput"
                                        disabled={true}
                                    />
                                </ModalRowHalf>
                                <ModalRowHalf>
                                    <button
                                        onClick={() => {
                                            handleOnChange(
                                                {
                                                    target: {
                                                        value: entitiesMap[
                                                            currentEntity
                                                        ]?.data?.expression,
                                                    },
                                                },
                                                "value"
                                            );
                                        }}
                                    >
                                        Evaluate Expression
                                    </button>
                                </ModalRowHalf>
                            </ModalRow>
                            <ModalRow>
                                <InputDropDownButton
                                    callback={updateAccount}
                                    initialValue={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.accountName ?? ""
                                    }
                                />
                            </ModalRow>
                            <ModalRow>
                                <InputDropDownButton
                                    callback={updateAccount}
                                    initialValue={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.contraAccountName
                                    }
                                    contraAccount={true}
                                    // this is the top level account which is at position 0
                                    accountSelectedForContra={
                                        entitiesMap?.[currentEntity]?.data
                                            .accountIds.length
                                            ? entitiesMap?.[currentEntity]?.data
                                                .accountIds[0]
                                            : ""
                                    }
                                />
                            </ModalRow>
                            <ModalRow>
                                <Calendar
                                    onHandleDate={onHandleDate}
                                    startDate={
                                        entitiesMap?.[currentEntity]?.startDate
                                    }
                                    endDate={
                                        entitiesMap?.[currentEntity]?.endDate ||
                                        null
                                    }
                                    cadence={
                                        entitiesMap?.[currentEntity]?.cadence
                                    }
                                    entityIndex={entityIndex}
                                />
                            </ModalRow>
                        </>
                    )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import React, { Component } from "react";

class PensionCard extends Component {
    render() {
        const { description, person, startAge, contributionType, version } =
            this.props.data.metadata;
        let ageEntry;
        if (startAge) {
            ageEntry = (
                <div className="Row">
                    <div className="ExpenseLabel">Contribution Start Age</div>
                    <div className="cardNumber">
                        {startAge ? `${startAge}` : ""}
                    </div>
                </div>
            );
        } else {
            ageEntry = <div />;
        }
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Person</div>
                    <div className="cardNumber">
                        {person ? `${person}` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Contribution Type</div>
                    <div className="cardNumber">
                        {contributionType
                            ? contributionType === "Both"
                                ? `CPP & QPP`
                                : `${contributionType}`
                            : ""}
                    </div>
                </div>

                {ageEntry}
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default PensionCard;

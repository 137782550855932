import { getRelevantEntities } from "actions/getNodeEntityActions";
import React, { Component } from "react";
import "./Card.css";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";

class GoalCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };
    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }

        const { description, version, startDate, data, name } =
            this.state.allEntities[this.state.currentIndex];
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Name:</div>
                    <div className="cardValue">{name}</div>
                </div>
                {description && (
                    <div className="Row">
                        <div
                            className={
                                description ? "Description" : "NoDescription"
                            }
                        >
                            {description}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Account Type:</div>
                    <div className="cardValue">
                        {data.ledgerName ? data.ledgerName : "Net Worth"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Target Account Balance:</div>
                    <div className="cardValue">
                        {`
                        ${data.adornment === "$" ? data.adornment : ""}
                        ${
                            data.value
                                ? new Intl.NumberFormat().format(
                                      parseFloat(data.value).toFixed(2)
                                  )
                                : ""
                        } ${data.adornment !== "$" ? data.adornment : ""}
                            `}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Target Date:</div>
                    <div className="cardValue">{startDate}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Inflation:</div>
                    <div className="cardValue">1.5%</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() => this.handleArrowClick("left")}
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentIndex + 1} of{" "}
                        {this.state.allEntities.length}
                    </span>
                    <div
                        className={
                            this.state.currentIndex ===
                            this.state.allEntities.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() => this.handleArrowClick("right")}
                    ></div>
                </div>
            </div>
        );
    }
}

export default GoalCard;

import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { getPresentableDependenciesOfManyTypes } from "helpers/nodeDependencyDetectionHelpers";
import { capacityInputsHandler } from "../OnInputChangeHandlers/capacityInputsHandler";
import CapacityInputView from "./CapacityInputView";
import { capacityObject } from "Components/Registry/Capacity";

export default function CapacityInput({ line, edit, editData, focus }) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = getDefaultName(capacityObject.name(), propsObject);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(capacityObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIds,
        entityIndex,
        setEntitiesMap,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
    } = useEntities(entitiesObject, eventData, edit, capacityObject);

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "value" | "entityName" | "startDate" | "endDate" | "accountName"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = capacityInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeWorkDays = (value, id) => {
        const newEntitiesMap = capacityInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = capacityInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const newEntitiesMap = capacityInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const getValidEvents = () => {
        const events = {};
        const validEventType: string[] = [
            "00dd7b5a-c2f5-4e27-86eb-c744bb93c5a3",
        ]; // Contract

        getPresentableDependenciesOfManyTypes(
            events,
            validEventType,
            propsObject,
            "Target",
            true
        );

        return events;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            const currentEntityObject = {
                ...(newEntitiesMap[currentEntity] || {}),
            };
            const data = { ...(currentEntityObject?.data || {}) };

            data.events = getValidEvents();
            return newEntitiesMap;
        });
        // setEntitiesMap should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = capacityObject.checkInput(entitiesMap);

    return (
        <CapacityInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            eventData={eventData}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            updateAccount={updateAccount}
            handleChangeWorkDays={handleChangeWorkDays}
            handleDateSelection={handleDateSelection}
        />
    );
}

import type {
    DateAxis,
    ValueAxis,
    AxisRenderer,
} from "@amcharts/amcharts4/charts";
import type { LedgerMetadataUnit } from "reducers/typesSchema/allAccountLedgersSchema";
import { color, NumberFormatter, Tooltip } from "@amcharts/amcharts4/core";

export function updateDateAxis(
    dateAxis: DateAxis<AxisRenderer>,
    [start, end]: [start: number, end: number]
) {
    // This is not a pure function because dateAxis.renderer would be
    // undefined if not pushed directly into chart
    // We can make this customizable by passing in arguments
    // const dateAxis = new am4charts.DateAxis();
    dateAxis.renderer.minGridDistance = 55;
    dateAxis.tooltip = new Tooltip();
    dateAxis.tooltip.background.fill = color("#ffffff");
    dateAxis.tooltipDateFormat = "MMMM yyyy";
    dateAxis.baseInterval = {
        timeUnit: "month",
        count: 1,
    };
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.fontSize = 12;

    // Set the 'Zoom' of the X Axis, in percentage terms.
    // Source 1: https://www.amcharts.com/docs/v4/tutorials/pre-zooming-an-axis/
    // Source 2: https://www.amcharts.com/docs/v4/tutorials/zooming-axis-via-api-or-external-scrollbar/
    dateAxis.start = start;
    dateAxis.end = end;
    dateAxis.keepSelection = true;

    return dateAxis;
}

/**
 * Gets the unit of the current account ledger and displays it either behind or in front of the value.
 *
 * `numberFormatValue`: amcharts4 way of displaying numbers smartly. Documentation: https://www.amcharts.com/docs/v4/concepts/formatters/formatting-numbers/
 * `ledgerMetadataUnit`: Info about what to display for the unit. If it's displayed in front or behind the number value.
 *
 * Example returns:
 * "$#,###.##a"
 * "#,###.##a Customers"
 */
function getValueAxisNumberFormat(
    numberFormatValue: string,
    ledgerMetadataUnit: LedgerMetadataUnit
) {
    let unitWithSpacing = ledgerMetadataUnit.display;
    let numberFormat = numberFormatValue;
    if (ledgerMetadataUnit.beforeValue) {
        if (ledgerMetadataUnit.spacingBetween) {
            unitWithSpacing = `${unitWithSpacing} `; // Add space behind (ie. UNIT + SPACE + VALUE)
        }
        numberFormat = unitWithSpacing + numberFormat;
    } else {
        if (ledgerMetadataUnit.spacingBetween) {
            unitWithSpacing = ` ${unitWithSpacing}`; // Add space in front (ie. VALUE + SPACE + UNIT)
        }
        numberFormat = numberFormat + unitWithSpacing;
    }
    return numberFormat;
}

export function updateValueAxis(
    valueAxis: ValueAxis<AxisRenderer>,
    ledgerMetadataUnit: LedgerMetadataUnit,
    decimalPlaces: string
) {
    // This is not a pure function because valueAxis.renderer would be
    // undefined if not pushed directly into chart
    // We can make this customizable by passing in arguments
    valueAxis.numberFormatter = new NumberFormatter();
    const numberFormat = getValueAxisNumberFormat(
        `#,###.${decimalPlaces}a`,
        ledgerMetadataUnit
    );
    valueAxis.numberFormatter.smallNumberPrefixes = [];
    valueAxis.numberFormatter.numberFormat = numberFormat;
    valueAxis.tooltip = new Tooltip();
    valueAxis.tooltip.background.fill = color("#ffffff");
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.fontSize = 12;

    return valueAxis;
}

/**
 * Uses the provided action and context to return a boolean specific to the passed action.
 */
export function getInsertActionActive(
    action: string,
    pasteCondition: boolean,
    disconnectCondition: boolean
): boolean {
    switch (action) {
        case "paste":
            return pasteCondition;
        case "disconnect":
            return disconnectCondition;
        default:
            return false;
    }
}

import React, { Component } from "react";
import moment from "moment";
import { processNodeValueForCanvasDisplay } from "../../helpers";

class ShortTermRentalCard extends Component {
    render() {
        const {
            value,
            description,
            upfrontCosts,
            monthlyCosts,
            averageMonthlyNights,
            dailyRate,
            daysPerStay,
            perStayCosts,
            otherFeesRate,
            hotelTaxRate,
            startDate,
            endDate,
            version,
        } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="DollarSvg">$</div>
                    <div className="Amount">
                        {value ? parseFloat(value).toFixed(2) : ""}
                    </div>
                    <div className="Frequency">monthly /</div>
                </div>
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Upfront Expenses:</div>
                    <div className="cardValue">
                        {upfrontCosts
                            ? processNodeValueForCanvasDisplay(upfrontCosts)
                            : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Monthly Expenses:</div>
                    <div className="cardValue">
                        {monthlyCosts
                            ? processNodeValueForCanvasDisplay(monthlyCosts)
                            : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Expenses per Stay:</div>
                    <div className="cardValue">
                        {perStayCosts
                            ? processNodeValueForCanvasDisplay(perStayCosts)
                            : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">
                        Average Days Leased/Month
                    </div>
                    <div className="cardValue">
                        {averageMonthlyNights ? averageMonthlyNights : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Average Rate per Night</div>
                    <div className="cardValue">
                        {dailyRate
                            ? processNodeValueForCanvasDisplay(dailyRate)
                            : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Average Days/Stay:</div>
                    <div className="cardValue">
                        {daysPerStay ? daysPerStay : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Hotel Tax:</div>
                    <div className="cardValue">
                        {hotelTaxRate ? `${hotelTaxRate} %` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Other Fees:</div>
                    <div className="cardValue">
                        {otherFeesRate ? `${otherFeesRate} %` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Period:</div>
                    <div className="cardValue">
                        {startDate
                            ? moment(startDate).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {endDate
                            ? moment(endDate).format("MMM D YYYY")
                            : "No End Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default ShortTermRentalCard;

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import PropertyTransferTaxCard from "../Card/propertyTransferTaxCard";
import PropertyTransferTaxInput from "../InputContainer/PropertyTransferTaxInput";

// Import Connection Image (if exists)
// import PropertyTransferTaxConnectionImage from "../../Assets/_optionDescriptionIcons/propertyTransferTaxConnectionImage.png";
import landTransferConnectionImage from "../../Assets/_optionDescriptionIcons/House, Property Value, Property Transfer Tax.png";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";

// Import Coloured PNGs
import propertyTransferTaxBlack from "../../Assets/_eventNodeTypes/ptt-black.png";
import propertyTransferTaxBlue from "../../Assets/_eventNodeTypes/ptt-blue.png";
import propertyTransferTaxWhite from "../../Assets/_eventNodeTypes/ptt-white.png";
import propertyTransferTaxGrey from "../../Assets/_eventNodeTypes/ptt-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - propertyTransferTaxObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - propertyTransferTaxObject.checkInput.call(propertyTransferTaxObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const propertyTransferTaxObject = {
    constant: function () {
        return PropertyTransferTax_Constant();
    },

    checkInput: function (state) {
        return PropertyTransferTax_CheckInput(state);
    },

    svg: function () {
        return PropertyTransferTax_Svg();
    },

    eventNodeMenu: function (focus) {
        return PropertyTransferTax_EventNodeMenu(focus);
    },

    indexText: function () {
        return PropertyTransferTax_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return PropertyTransferTax_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return PropertyTransferTax_OptionDescription();
    },

    colouredPngs: function () {
        return PropertyTransferTax_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return PropertyTransferTax_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const PropertyTransferTax_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.firstTimeBuyerTrue && !state.firstTimeBuyerFalse) return false;
    if (!state.foreignBuyerTrue && !state.foreignBuyerFalse) return false;
    return true;
};

// EFFECTS: Returns constant for event
const PropertyTransferTax_Constant = () => {
    return "Land Transfer Tax";
};

// EFFECTS: Returns black png / svg for event
const PropertyTransferTax_Svg = () => {
    return propertyTransferTaxBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const PropertyTransferTax_EventNodeMenu = (focus) => {
    return <PropertyTransferTaxCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const PropertyTransferTax_IndexText = () => {
    return "Select an input field to learn more about your propertyTransferTax calculations.";
};

// EFFECTS: Returns all icons for event ??
const PropertyTransferTax_Icons = () => {
    let pngs = new Map([
        ["black", propertyTransferTaxBlack],
        ["blue", propertyTransferTaxBlue],
        ["white", propertyTransferTaxWhite],
        ["grey", propertyTransferTaxGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const PropertyTransferTax_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const PropertyTransferTax_InputComponentInfoPassing = (minProps) => {
    return <PropertyTransferTaxInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, propertyTransferTaxs for event ??
const PropertyTransferTax_OptionDescription = () => {
    const tags = ["Option"];
    const type = propertyTransferTaxObject.constant();
    const description =
        "In Canada, the buyer of a home may be required to pay a transfer tax based on the purchase price of the home and other factors like location of the home and nationality.";
    const usedForText =
        "The Land or Property Transfer Tax Event estimates the tax that a home buyer pays on a home purchase. This event accounts for location, first-time home owners, and other financial factors (including whether they are a resident of Canada).";
    const connection =
        "Add a Land Transfer Tax Event after a House and Mortgage thread to calculate the total tax owed";
    const connectionImage = landTransferConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/t/transfertax.asp#:~:text=A%20transfer%20tax%20is%20a,individual%20or%20entity%20to%20another.&text=It%20is%20usually%20not%20deductible,and%20investment%20property%20is%20calculated.";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const PropertyTransferTax_SideModalImage = () => {
    return sideImage;
};

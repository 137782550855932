import { useContext, useMemo, useRef } from "react";
import "./waterfallMenu.css";
import MenuItem from "@mui/material/MenuItem";
import {
    BaseContainerHeightContext,
    CurrentSelectionContext,
    SelectedTopLevelAccountContext,
    SelectedSubAccountContext,
    SelectedLeafAccountContext,
    SelectedLeafSubAccountContext,
} from "./WaterfallContext";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { openCustomAccountModal } from "actions/customAccountActions";
import addIcon from "Assets/actionsIcons/add_entity.svg";
import { WaterfallMenuItem } from "./WaterfallMenuItem";

const menuItemHeight = 39;

interface WaterfallSectionV2Props {
    accountLevel: string;
    clickAction: (data: any) => void;
    handleExpand: (accountLevel, accountId, anchorEl) => void;
    topLevelFilters: {};
    unselectableAccounts: {};
    contraAccount: boolean;
    defaultContraAcc: any;
}

export const WaterfallSectionV2 = ({
    accountLevel,
    clickAction,
    handleExpand,
    topLevelFilters = {},
    unselectableAccounts = {},
    contraAccount = false,
    defaultContraAcc,
}: WaterfallSectionV2Props) => {
    const dispatch = useAppDispatch();
    const allAccountData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );

    const containerRef = useRef<HTMLDivElement>(null);

    const baseContainerHeight = useContext(BaseContainerHeightContext);
    const currentSelection = useContext(CurrentSelectionContext);
    const selectedTopLevelAccount = useContext(SelectedTopLevelAccountContext);
    const selectedSubAccount = useContext(SelectedSubAccountContext);
    const selectedLeafAccount = useContext(SelectedLeafAccountContext);
    const selectedLeafSubAccount = useContext(SelectedLeafSubAccountContext);

    const maxHeight = useMemo(() => {
        if (baseContainerHeight < 375) {
            return 375;
        }
        return baseContainerHeight;
    }, [baseContainerHeight]);

    const relevantAccounts = useMemo(() => {
        switch (accountLevel) {
            case "topLevel":
                const topLevelAccounts =
                    Object.values(allAccountData)?.filter(
                        (account) =>
                            account.parents.length === 0 &&
                            account?.type !== "shared"
                    ) ?? [];
                if (Object.keys(topLevelFilters)?.length === 0) {
                    return topLevelAccounts;
                } else {
                    const filteredTopLevelAccounts: any = [];
                    topLevelAccounts?.map((account) => {
                        if (topLevelFilters[account.id]) {
                            filteredTopLevelAccounts.push(account);
                        }
                    });
                    return filteredTopLevelAccounts;
                }
            case "subAccount":
                const topLevelAccountData =
                    allAccountData?.[selectedTopLevelAccount];
                const subAccounts =
                    topLevelAccountData?.children?.map((childId) => {
                        const accountData = allAccountData?.[childId];
                        if (accountData?.type !== "shared") return accountData;
                    }) ?? [];
                return subAccounts;
            case "leafAccount":
                const subAccountData = allAccountData?.[selectedSubAccount];
                const leafAccounts =
                    subAccountData?.children?.map((childId) => {
                        const accountData = allAccountData?.[childId];
                        if (accountData?.type !== "shared") return accountData;
                    }) ?? [];
                return leafAccounts;
            case "leafSubAccount":
                const leafAccountData = allAccountData?.[selectedLeafAccount];
                const leafSubAccounts =
                    leafAccountData?.children?.map((childId) => {
                        const accountData = allAccountData?.[childId];
                        if (accountData?.type !== "shared") return accountData;
                    }) ?? [];
                return leafSubAccounts;
            case "fifthLevelAccount":
                const leafSubAccountData =
                    allAccountData?.[selectedLeafSubAccount];
                const fifthLevelAccounts =
                    leafSubAccountData?.children?.map((childId) => {
                        const accountData = allAccountData?.[childId];
                        if (accountData?.type !== "shared") return accountData;
                    }) ?? [];
                return fifthLevelAccounts;
            default:
                return [];
        }
    }, [
        accountLevel,
        allAccountData,
        selectedLeafAccount,
        selectedLeafSubAccount,
        selectedSubAccount,
        selectedTopLevelAccount,
        topLevelFilters,
    ]);

    const recurseToTopAccount = (id: string) => {
        if (id == "") {
            return [];
        }

        const accounts: string[] = [];
        let currentAcc = allAccountData[id];
        accounts.unshift(currentAcc.id);
        while (currentAcc.parents.length > 0) {
            currentAcc = allAccountData[currentAcc.parents[0]];
            accounts.unshift(currentAcc.id);
        }
        return accounts;
    };

    const handleCreateAccount = () => {
        const topLevel = {
            name: allAccountData?.[selectedTopLevelAccount]?.name ?? "",
            ids: recurseToTopAccount(selectedTopLevelAccount),
        };
        const subAccount = {
            name: allAccountData?.[selectedSubAccount]?.name ?? "",
            ids: recurseToTopAccount(selectedSubAccount),
        };
        const leafAccount = {
            name:
                accountLevel === "leafSubAccount"
                    ? allAccountData?.[selectedLeafAccount]?.name ?? ""
                    : "",
            ids:
                accountLevel === "leafSubAccount"
                    ? recurseToTopAccount(selectedLeafAccount)
                    : recurseToTopAccount(""),
        };
        dispatch(
            openCustomAccountModal(false, topLevel, subAccount, leafAccount)
        );
    };

    const { enforceAnchorContainer, sectionHeight } = useMemo(() => {
        const sectionHeight =
            accountLevel !== "topLevel"
                ? (relevantAccounts?.length + 1) * menuItemHeight
                : relevantAccounts?.length * menuItemHeight;
        const enforceAnchorContainer = sectionHeight > maxHeight;
        return {
            enforceAnchorContainer,
            sectionHeight,
        };
    }, [accountLevel, relevantAccounts?.length, maxHeight]);

    const getContainerEl = () => {
        return containerRef?.current;
    };

    const isUnselectable = !!unselectableAccounts[defaultContraAcc?.id];

    return (
        <section
            className="accountLevelContainer"
            ref={containerRef}
            style={{ maxHeight: maxHeight }}
        >
            {(accountLevel === "subAccount" ||
                accountLevel === "leafAccount" ||
                accountLevel === "leafSubAccount") && (
                <div className="section">
                    <MenuItem
                        id="textStylesOverride"
                        className="section__heading"
                        onClick={() => handleCreateAccount()}
                    >
                        <div className="section__createAccount">
                            <span className="section__createAccountText">
                                Create Account
                            </span>
                            <img
                                className="section__createAccountIcon"
                                src={addIcon}
                                alt="plus icon"
                            />
                        </div>
                    </MenuItem>
                </div>
            )}
            {contraAccount && accountLevel === "topLevel" && (
                <div className="section">
                    <MenuItem
                        id="textStylesOverride"
                        className="section__heading"
                        onClick={() => {
                            if (!isUnselectable) clickAction(defaultContraAcc);
                        }}
                        sx={
                            isUnselectable
                                ? { "&:hover": { backgroundColor: "#1976d2" } }
                                : {}
                        }
                    >
                        <span
                            className={
                                isUnselectable
                                    ? "section__heading section__heading--disabled"
                                    : currentSelection ===
                                      defaultContraAcc?.name
                                    ? "section__heading section__heading--active"
                                    : "section__heading"
                            }
                        >
                            {defaultContraAcc?.name ?? ""}
                        </span>
                    </MenuItem>
                </div>
            )}
            {relevantAccounts?.length > 0 &&
                relevantAccounts?.map((account) => {
                    if (account?.id)
                        return (
                            <WaterfallMenuItem
                                key={account?.id}
                                id={account?.id}
                                accountLevel={accountLevel}
                                clickAction={clickAction}
                                handleExpand={handleExpand}
                                getContainerEl={getContainerEl}
                                enforceAnchorContainer={enforceAnchorContainer}
                                parentSectionHeight={sectionHeight}
                                unselectableAccounts={unselectableAccounts}
                            />
                        );
                })}
        </section>
    );
};

import React, { Component } from "react";
import {
    getDefaultName,
    processNodeValueForCanvasDisplay,
} from "../../helpers";
import _ from "lodash";
import { throwError } from "../../helpers/swalError";
import { Description, InputButtons, Name, Value } from "./Components";
import { Switch } from "@material-ui/core";
import { getClosestDependency } from "../../helpers/nodeDependencyDetectionHelpers";
import { closingCostsObject } from "Components/Registry/Closing Costs";
import { houseObject } from "Components/Registry/House";

class ClosingCostsInput extends Component {
    constructor(props) {
        super(props);
        const doesParentHouseExit = this.isHouseInScenario();
        this.state = {
            name: getDefaultName(closingCostsObject.constant(), this.props),
            type: closingCostsObject.constant(),
            applyInflation: false,
            description: "",
            value: 0,
            tag: `@${closingCostsObject.constant()}`,
            rating: 0,
            cadence: "one-time",
            inflation: false,
            start: null,
            end: null,
            propertyEvaluationFee: 300,
            landSurveyFee: 1000,
            titleInsurance: 400,
            legalCosts: 500,
            includePropertyEvalFee: false,
            includeLandSurveyFee: false,
            includeTitleInsurance: false,
            includeLegalCosts: false,
            doesParentHouseExit,
        };
    }

    isHouseInScenario = () => {
        const dependencies = getClosestDependency(this.props, [houseObject.constant()]);
        if (
            !dependencies ||
            !dependencies[houseObject.constant()] ||
            dependencies[houseObject.constant()].length === 0
        ) {
            throwError(
                "warning",
                "Failed to find an upstream House node.",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
            return false;
        } else {
            return true;
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.editData,
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;
        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({
                    description: value,
                });
                break;
            case "propertyEvaluationFee":
                this.setState({
                    propertyEvaluationFee: value,
                    value: this.calculateTotalFee(id, value, this.state),
                });
                break;
            case "landSurveyFee":
                this.setState({
                    landSurveyFee: value,
                    value: this.calculateTotalFee(id, value, this.state),
                });
                break;
            case "titleInsurance":
                this.setState({
                    titleInsurance: value,
                    value: this.calculateTotalFee(id, value, this.state),
                });
                break;
            case "legalCosts":
                this.setState({
                    legalCosts: value,
                    value: this.calculateTotalFee(id, value, this.state),
                });
                break;
            default:
        }
    };

    calculateTotalFee = (id, value, state) => {
        const {
            includeLandSurveyFee,
            includeLegalCosts,
            includePropertyEvalFee,
            includeTitleInsurance,
        } = state;
        let propEvalFee = includePropertyEvalFee
            ? Math.max(Number(state.propertyEvaluationFee), 0)
            : 0;
        let landSurveyFee = includeLandSurveyFee
            ? Math.max(Number(state.landSurveyFee), 0)
            : 0;
        let titleInsurance = includeTitleInsurance
            ? Math.max(Number(state.titleInsurance), 0)
            : 0;
        let legalCosts = includeLegalCosts
            ? Math.max(Number(state.legalCosts), 0)
            : 0;

        switch (id) {
            case "propertyEvaluationFee":
                propEvalFee = includePropertyEvalFee
                    ? Math.max(Number(value), 0)
                    : 0;
                break;
            case "landSurveyFee":
                landSurveyFee = includeLandSurveyFee
                    ? Math.max(Number(value), 0)
                    : 0;
                break;
            case "titleInsurance":
                titleInsurance = includeTitleInsurance
                    ? Math.max(Number(value), 0)
                    : 0;
                break;
            case "legalCosts":
                legalCosts = includeLegalCosts ? Math.max(Number(value), 0) : 0;
                break;
            default:
        }

        return -1 * (propEvalFee + landSurveyFee + titleInsurance + legalCosts);
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (closingCostsObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onTogglePropertyEval = () => {
        this.setState(
            { includePropertyEvalFee: !this.state.includePropertyEvalFee },
            () => {
                this.setState({
                    value: this.calculateTotalFee(null, null, this.state),
                });
            }
        );
    };

    onToggleLandSurvey = () => {
        this.setState(
            { includeLandSurveyFee: !this.state.includeLandSurveyFee },
            () => {
                this.setState({
                    value: this.calculateTotalFee(null, null, this.state),
                });
            }
        );
    };

    onToggleTitleInsurance = () => {
        this.setState(
            { includeTitleInsurance: !this.state.includeTitleInsurance },
            () => {
                this.setState({
                    value: this.calculateTotalFee(null, null, this.state),
                });
            }
        );
    };

    onToggleLegalCosts = () => {
        this.setState(
            { includeLegalCosts: !this.state.includeLegalCosts },
            () => {
                this.setState({
                    value: this.calculateTotalFee(null, null, this.state),
                });
            }
        );
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "Property Evalution Fee":
                data = {
                    title: "Property Evaluation Fee",
                    content:
                        "A cost is payed to arrange for an appraisal to verify that the listed property value is in line with market norms.",
                    subContent:
                        "A property evaluation fee is generally at least $300.",
                    link:
                        "https://www.bmo.com/main/personal/mortgages/closing-costs/",
                };
                break;
            case "Land Survey Fee":
                data = {
                    title: "Land Survey Fee",
                    content:
                        "If the seller of the property does not provide an up-to-date survey of the property, you may have to provision one.",
                    subContent:
                        "A land survey is usually between $1000 and $2000, depending on the size and character of the property",
                    link:
                        "https://www.bmo.com/main/personal/mortgages/closing-costs/",
                };
                break;
            case "Title Insurance":
                data = {
                    title: "Title Insurance",
                    content:
                        "To protect yourself from title defects and fraud, you may procure title insurance.",
                    subContent: "The usual cost is about $400",
                    link:
                        "https://www.bmo.com/main/personal/mortgages/closing-costs/",
                };
                break;
            case "Legal Costs":
                data = {
                    title: "Legal Costs",
                    content:
                        "Services provided by your lawyer in the purchase of a property may amount to a significant cost",
                    subContent:
                        "Legal costs are generally at least $500, plus tax",
                    link:
                        "https://www.bmo.com/main/personal/mortgages/closing-costs/",
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    render() {
        const passedCheck = closingCostsObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            description,
            name,
            propertyEvaluationFee,
            landSurveyFee,
            titleInsurance,
            legalCosts,
            includePropertyEvalFee,
            includeLandSurveyFee,
            includeTitleInsurance,
            includeLegalCosts,
            value,
        } = this.state;

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div
                        onMouseEnter={() => {
                            this.onHandleMouseEnter(null);
                        }}
                        className="headerContainer"
                    >
                        <img
                            alt="alt"
                            src={closingCostsObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">
                                Closing Costs Node
                            </div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        onMouseEnter={() => {
                            this.onHandleMouseEnter(null);
                        }}
                        className="ModalRow"
                    >
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div
                        onMouseEnter={() => {
                            this.onHandleMouseEnter("Property Evalution Fee");
                        }}
                        className="ModalRow"
                    >
                        <div className="twoThird">
                            <Value
                                label="Property Evalution Fee"
                                onChangeInput={this.onChangeInput}
                                value={propertyEvaluationFee}
                                id="propertyEvaluationFee"
                            />
                        </div>
                        <Switch
                            id="includePropertyEvalFee"
                            checked={includePropertyEvalFee}
                            className="ToggleSelector"
                            height={20}
                            width={40}
                            onClick={this.onTogglePropertyEval}
                        />
                    </div>
                    <div
                        onMouseEnter={() => {
                            this.onHandleMouseEnter("Land Survey Fee");
                        }}
                        className="ModalRow"
                    >
                        <div className="twoThird">
                            <Value
                                label="Land Survey Fee"
                                onChangeInput={this.onChangeInput}
                                value={landSurveyFee}
                                id="landSurveyFee"
                            />
                        </div>
                        <Switch
                            id="includeLandSurveyFee"
                            checked={includeLandSurveyFee}
                            className="ToggleSelector"
                            height={20}
                            width={40}
                            onClick={this.onToggleLandSurvey}
                        />
                    </div>
                    <div
                        onMouseEnter={() => {
                            this.onHandleMouseEnter("Title Insurance");
                        }}
                        className="ModalRow"
                    >
                        <div className="twoThird">
                            <Value
                                label="Title Insurance"
                                onChangeInput={this.onChangeInput}
                                value={titleInsurance}
                                id="titleInsurance"
                            />
                        </div>
                        <Switch
                            id="includeTitleInsurance"
                            checked={includeTitleInsurance}
                            className="ToggleSelector"
                            height={20}
                            width={40}
                            onClick={this.onToggleTitleInsurance}
                        />
                    </div>
                    <div
                        onMouseEnter={() => {
                            this.onHandleMouseEnter("Legal Costs");
                        }}
                        className="ModalRow"
                    >
                        <div className="twoThird">
                            <Value
                                label="Legal Costs"
                                onChangeInput={this.onChangeInput}
                                value={legalCosts}
                                id="legalCosts"
                            />
                        </div>
                        <Switch
                            id="includeLegalCosts"
                            checked={includeLegalCosts}
                            className="ToggleSelector"
                            height={20}
                            width={40}
                            onClick={this.onToggleLegalCosts}
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="loanPaymentsContainer">
                            <div className="paymentsAmountContainer">
                                <div className="paymentsLabel">
                                    Total Closing Costs
                                </div>
                                <div className="paymentsValue">
                                    {processNodeValueForCanvasDisplay(
                                        -1 * value
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </div>
        );
    }
}

export default ClosingCostsInput;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import styles from "./accountMappingModal.module.css";
import { useState } from "react";
import { setShowAccountMappingModal } from "actions/accountMappingActions";
import {
    ConfirmButton,
    DeleteButton,
    MappingArrow,
    ModalRow,
    ModalRowComponent,
    Rate,
    RequiredStar,
    BasicTextInput,
} from "Components/InputContainer/Components";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import {
    AccountMappingDataMapped,
    AccountMappingType,
    MappedAccountData,
} from "reducers/typesSchema/accountMappingsSchema";
import { isEmpty } from "lodash";
import { Ledgers } from "reducers/typesSchema/allAccountLedgersSchema";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";

interface AccountMap {
    [account: string]: MappedAccountData;
}

const getInitialAccountMap = (
    accountMappingData,
    accountMap,
    allAccountLedgers
) => {
    const accountMapping = {};

    for (const account of Object.keys(accountMappingData)) {
        let accountData;

        const accountId = accountMap[account];

        // if the mapping is already stored
        if (accountId) {
            accountData = getDataFromAccountId(allAccountLedgers, accountId);
        } else {
            accountData = getDataFromAccount(allAccountLedgers, account);
        }

        if (!accountData) {
            accountMapping[account] = null;
            continue;
        }

        accountMapping[account] = {
            name: accountData.name,
            ids: accountData.ids,
            id: accountData.id,
        };
    }

    return accountMapping as AccountMap;
};

const getDataFromAccountId = (allAccountLedgers, accountId) => {
    const ledgersMetadata = allAccountLedgers.ledgersMetadata as Ledgers;

    let accountName: string | null = null;

    for (const ledgerData of Object.values(ledgersMetadata)) {
        if (ledgerData.id == accountId) {
            accountName = ledgerData.name;

            const accounts: string[] = [];
            let currentAcc = ledgerData;
            accounts.unshift(currentAcc.id);

            while (currentAcc.parents.length > 0) {
                currentAcc = ledgersMetadata[currentAcc.parents[0]];
                accounts.unshift(currentAcc.id);
            }

            return {
                name: accountName,
                ids: accounts,
                id: accountId,
            };
        }
    }

    return null;
};

const getDataFromAccount = (allAccountLedgers, accountName) => {
    const ledgersMetadata = allAccountLedgers.ledgersMetadata as Ledgers;

    for (const ledgerData of Object.values(ledgersMetadata)) {
        if (accountName == ledgerData.name) {
            const accountId = ledgerData.id;

            const accounts: string[] = [];
            let currentAcc = ledgerData;
            accounts.unshift(currentAcc.id);

            while (currentAcc.parents.length > 0) {
                currentAcc = ledgersMetadata[currentAcc.parents[0]];
                accounts.unshift(currentAcc.id);
            }

            return {
                name: accountName,
                ids: accounts,
                id: accountId,
            };
        }
    }

    return null;
};

export const AccountMappingModal = () => {
    const {
        accountMappingData,
        entity,
        accountMappingType,
        create,
    }: {
        accountMappingData: AccountMappingDataMapped;
        entity: EntitySchema;
        accountMappingType: AccountMappingType;
        create: any;
    } = useAppSelector((state) => {
        const showAccountMappingModal = state.scenario?.showAccountMappingModal;
        const accountMappingData = showAccountMappingModal?.accountMappingData;
        const entity = showAccountMappingModal?.entity;
        const accountMappingType = showAccountMappingModal?.accountMappingType;
        const create = showAccountMappingModal?.create;

        return { accountMappingData, entity, accountMappingType, create };
    });

    const allAccountLedgers = useAppSelector(
        (state) => state?.allAccountLedgers
    );

    const accountMapping = entity?.data?.accountMap ?? {};

    const emptyAccounts = isEmpty(accountMappingData);

    let dateHeaders = emptyAccounts
        ? []
        : Object.keys(Object.values(accountMappingData)[0].data);

    if (accountMappingType == AccountMappingType.Csvbox && !emptyAccounts) {
        dateHeaders = dateHeaders.slice(0, 3);
    }

    const [accountMap, setAccountMap] = useState(
        getInitialAccountMap(
            accountMappingData,
            accountMapping,
            allAccountLedgers
        )
    );

    const onConfirm = () => {
        const newAccountMappingData = {};

        for (const [account, mappedAccountData] of Object.entries(accountMap)) {
            const newAccountRow = {
                name: account,
                mappedAccountData: mappedAccountData,
                data: accountMappingData[account].data,
            };

            newAccountMappingData[account] = newAccountRow;
        }

        updateStoredAccountMapping();

        setShowAccountMappingModal({
            show: false,
            entity: entity,
            accountMappingData: newAccountMappingData,
            mapped: true,
        });
        create(newAccountMappingData);
    };

    const updateStoredAccountMapping = () => {
        if (isEmpty(accountMap)) return;

        const storedAccountMap = accountMapping;

        for (const [account, data] of Object.entries(accountMap)) {
            storedAccountMap[account] = data.id;
        }

        entity.data.accountMap = storedAccountMap;
    };

    const passedCheck = () => {
        for (const mappedAccount of Object.values(accountMap)) {
            if (!mappedAccount) {
                return false;
            }
        }

        return true;
    };

    const onChangeAccount = (accountData, id, accountName) => {
        const mappedAccountData = {
            name: accountData.name,
            ids: accountData.ids,
            id: accountData.id,
        };

        if (id == "accountName") {
            const newAccountMap = {};

            for (const [account, data] of Object.entries(accountMap)) {
                newAccountMap[account] = data;
            }

            newAccountMap[accountName] = mappedAccountData;
            setAccountMap(newAccountMap);
        }
    };

    const AccountMappingRow = ({ name, data }) => {
        let rowData = Object.values(data);

        const isCsvbox = accountMappingType == AccountMappingType.Csvbox;

        if (isCsvbox) {
            rowData = rowData.slice(0, 3);
        }

        const basicAccounts = {
            "211dd944-359f-4fbe-af1f-0c761afa1e67": true, //Assets
            "6c9640aa-abf5-4f19-b135-356e183bcce6": true, //Liabilities
            "001a1288-cefb-4bcb-97ce-510b8a668a19": true, //Equity
            "488dd61d-8697-4213-8978-cf91755365a4": true, //Income
            "7faf0285-78ca-411b-b875-d900929d7c94": true, //Expenses
        };

        return (
            <ModalRow twoInputs>
                <ModalRowComponent width={isCsvbox ? 21 : 30}>
                    <BasicTextInput
                        inputLabel="Source Name"
                        value={name}
                        className="mlsInput"
                        disabled={true}
                    />
                </ModalRowComponent>

                <MappingArrow />

                <ModalRowComponent width={isCsvbox ? 27 : 37}>
                    <RequiredStar />
                    <InputDropDownButton
                        callback={(accountData, id) =>
                            onChangeAccount(accountData, id, name)
                        }
                        initialValue={
                            accountMap[name] ? accountMap[name].name : ""
                        }
                        topLevelFilters={isCsvbox ? {} : basicAccounts}
                        unselectableAccounts={isCsvbox ? basicAccounts : {}}
                    />
                </ModalRowComponent>
                {rowData.map((val, i) => {
                    return (
                        <ModalRowComponent width={isCsvbox ? 12 : 19} key={i}>
                            <Rate
                                label="Sample Data"
                                value={val}
                                className="mlsInput"
                                edit={false}
                            />
                        </ModalRowComponent>
                    );
                })}
                <DeleteButton
                    onClick={() => {
                        const newAccountMap = {};

                        for (const [account, data] of Object.entries(
                            accountMap
                        )) {
                            newAccountMap[account] = data;
                        }

                        delete newAccountMap[name];
                        setAccountMap(newAccountMap);
                    }}
                />
            </ModalRow>
        );
    };

    const isCsv = accountMappingType == AccountMappingType.Csvbox;

    return (
        <div className="ModalContainer">
            <div
                className={styles.primaryContainer}
                style={{ width: isCsv ? "800px" : "650px" }}
            >
                <div
                    className={styles.closeIcon}
                    onClick={() => setShowAccountMappingModal({ show: false })}
                ></div>
                <div className={styles.secondaryContainer}>
                    <div className={styles.headerContainer}>
                        <aside className={styles.aside}>
                            <figure className={styles.figure}></figure>
                        </aside>
                        <div className={styles.headerCopyContainer}>
                            <h3 className={styles.headerCopy}></h3>
                            <h3 className={styles.headerCopy}>
                                Account Mappings
                            </h3>
                        </div>
                    </div>
                    <div className={styles.inputsContainer}>
                        <ModalRow>
                            <div
                                style={{
                                    marginLeft: isCsv ? "57%" : "78%",
                                }}
                            ></div>
                            {dateHeaders.map((date, i) => {
                                return (
                                    <ModalRowComponent
                                        key={i}
                                        width={isCsv ? 13 : 20}
                                    >
                                        <div className="HeaderText">{date}</div>
                                    </ModalRowComponent>
                                );
                            })}
                        </ModalRow>
                        {accountMap &&
                            Object.keys(accountMap).map((account, i) => {
                                return (
                                    <AccountMappingRow
                                        key={i}
                                        name={account}
                                        data={accountMappingData[account].data}
                                    />
                                );
                            })}
                        {emptyAccounts && (
                            <ModalRow>No accounts available.</ModalRow>
                        )}
                    </div>
                    <ConfirmButton
                        onClick={onConfirm}
                        passedCheck={passedCheck()}
                    />
                </div>
            </div>
        </div>
    );
};

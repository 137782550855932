import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";

/*
 * Helper function that handles Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */

export const accountModifierInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "startDate"
        | "endDate"
        | "action"
        | "value"
        | "maxValue"
        | "minValue"
        | "accountName",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            let startDateString = "";
            if (value) {
                const startMoment = moment(value);
                startDateString = startMoment
                    .set("date", 1)
                    .format("YYYY-MM-DD");
            }
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            let endDateString = "";
            if (value) {
                const endMoment = moment(value);
                endDateString = endMoment.set("date", 1).format("YYYY-MM-DD");
            }
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "action":
            data.action = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            if (data?.modifierType === "date") {
                data.value = value ? moment(value).format("YYYY-MM-DD") : "";
            } else {
                data.value = value;
            }

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "minValue":
            data.minValue = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "maxValue":
            data.maxValue = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name;
            data.accountIds = account.ids;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
};

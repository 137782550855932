import { createCustomer2AccountStructure } from "Components/InputContainer/Customer2InputExperimental/Customer2InputExperimental";
import { createCustomerAccountStructure } from "Components/InputContainer/CustomerInputExperimental/CustomerInputExperimental";
import { createLoanAccountStructure } from "Components/InputContainer/LoanInputExperimental/LoanInputExperimental";
import { createRevenueAccountStructure } from "Components/InputContainer/RevenueInputExperimental/RevenueInputExperimental";
import { createSegmentAccountStructure } from "Components/InputContainer/SegmentInputExperimental/SegmentInputExperimental";
import { createWebsiteVisitorAccountStructure } from "Components/InputContainer/WebsiteVisitorsExperimental/WebsiteVisitorsInputExperimental";
import { customerObject } from "Components/Registry/Customer";
import { customer2Object } from "Components/Registry/Customer2";
import { loanObject } from "Components/Registry/Loan";
import { revenueObject } from "Components/Registry/Revenue";
import { segmentObject } from "Components/Registry/Segment";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import * as uuid from "uuid";
import { deleteAndCreateCustomAccounts } from "./accounts";
import { projectsObject } from "Components/Registry/Projects";
import { createProjectsAccountStructure } from "Components/InputContainer/Projects/Projects";

export const getDuplicateCustomer2Entity = (entity: EntitySchema) => {
    // create the new custom customer accounts
    const parentAccountId = uuid.v4();
    const grownAccountId = uuid.v4();
    const churnedAccountId = uuid.v4();
    const transferredInAccountId = uuid.v4();
    const transferredOutAccountId = uuid.v4();

    const data = { ...entity.data };

    data.storedAccountName = entity.name;
    data.parentAccountId = parentAccountId;
    data.grownAccountId = grownAccountId;
    data.churnedAccountId = churnedAccountId;
    data.transferredInAccountId = transferredInAccountId;
    data.transferredOutAccountId = transferredOutAccountId;
    // accountIds is the field that's used to send accounts to de3
    data.accountIds = [
        "b2623ec6-3492-4fa0-9dcd-0150c3443cb0",
        "b93d043d-bf4c-423f-8a80-7702870a5ebb",
        "fe6d5929-4afd-4c03-91cf-121164ff212e",
        parentAccountId,
        grownAccountId,
        churnedAccountId,
        transferredInAccountId,
        transferredOutAccountId,
    ];

    entity.data = data;

    return entity;
};

export const getDuplicateWebsiteVisitorEntity = (entity: EntitySchema) => {
    const parentAccountId = uuid.v4();
    const organicAccountId = uuid.v4();
    const paidAccountId = uuid.v4();

    const data = { ...entity.data };

    data.storedAccountName = entity.name;
    data.parentAccountId = parentAccountId;
    data.organicAccountId = organicAccountId;
    data.paidAccountId = paidAccountId;
    // accountIds is the field that's used to send accounts to de3
    data.accountIds = [
        "db178274-8d95-4a6a-ae5c-46ae2fc356dd",
        "8a43fb3e-62f5-47d7-917f-3a042354cb11",
        "7eb29f4d-d0ec-402e-a743-19797ebbd22b",
        parentAccountId,
        organicAccountId,
        paidAccountId,
    ];

    entity.data = data;

    return entity;
};

export const createDuplicateAccountStructure = (
    entity: EntitySchema,
    customAccountMap: { [key: string]: string }
) => {
    entity.name = `${entity.name} - Duplicate`;
    entity = entityToAccountStructure(entity, customAccountMap);

    // handle events that create custom accounts
    deleteAndCreateCustomAccounts([entity], []);
    return entity;
};

const entityToAccountStructure = (entity: EntitySchema, customAccountMap) => {
    let modifiedEntity = entity;
    const currentEntityObject = { ...(entity ?? {}) };
    const prevData = { ...(currentEntityObject?.data || {}) };
    switch (entity.type) {
        // Backwards compatibility
        case customer2Object.constant():
            modifiedEntity = createCustomer2AccountStructure(entity);
            break;
        case customerObject.constant():
            modifiedEntity = createCustomerAccountStructure(entity);
            customAccountMap[prevData.parentAccountId] =
                modifiedEntity.data.parentAccountId;
            customAccountMap[prevData.grownAccountId] =
                modifiedEntity.data.grownAccountId;
            customAccountMap[prevData.churnedAccountId] =
                modifiedEntity.data.churnedAccountId;
            customAccountMap[prevData.transferredInAccountId] =
                modifiedEntity.data.transferredInAccountId;
            customAccountMap[prevData.transferredOutAccountId] =
                modifiedEntity.data.transferredOutAccountId;
            break;
        case revenueObject.constant():
            modifiedEntity = createRevenueAccountStructure(entity);
            customAccountMap[prevData.parentAccountId] =
                modifiedEntity.data.parentAccountId;
            break;
        case segmentObject.constant():
            modifiedEntity = createSegmentAccountStructure(entity);
            break;
        case loanObject.constant():
            modifiedEntity = createLoanAccountStructure(entity);
            customAccountMap[prevData.loanAccountId] =
                modifiedEntity.data.loanAccountId;
            customAccountMap[prevData.interestAccountId] =
                modifiedEntity.data.interestAccountId;
            customAccountMap[prevData.premiumAccountId] =
                modifiedEntity.data.premiumAccountId;
            break;
        case websiteVisitorsObject.constant():
            modifiedEntity = createWebsiteVisitorAccountStructure(entity);
            customAccountMap[prevData.parentAccountId] =
                modifiedEntity.data.parentAccountId;
            customAccountMap[prevData.organicAccountId] =
                modifiedEntity.data.organicAccountId;
            customAccountMap[prevData.paidAccountId] =
                modifiedEntity.data.paidAccountId;
            break;
        case projectsObject.constant():
            modifiedEntity = createProjectsAccountStructure(entity);
            customAccountMap[prevData.neededAccountId] =
                modifiedEntity.data.neededAccountId;
            customAccountMap[prevData.utilizedAccountId] =
                modifiedEntity.data.utilizedAccountId;
            customAccountMap[prevData.adjustedAccountId] =
                modifiedEntity.data.adjustedAccountId;
            break;
        default:
            break;
    }
    return modifiedEntity;
};

import Inputs from "./InputContainer";
import * as actions from "../../actions/scenario";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        selectedOption: state.scenario.selectedOption,
        focus: state.scenario.focus,
        line: state.scenario.line,
        manager: state.scenario.manager,
        baselineDataManager: state.scenario.baselineDataManager,
        editData: state.scenario.editData,
        edit: state.scenario.edit,
        loadedScenario: state.scenario.loadedScenario,
        bankData: state.scenario.bankData,
        onboardingData: state.scenario.onboardingData,
        userScenarios: state.scenario.userScenarios,
        baseline: state.scenario.baseline,
        highlightedThread: state.scenario.highlightedThread,
        eventLibrary: state.scenario.eventLibrary,
    };
};

const mapDispatchToProps = {
    getSampleScenario: actions.getSampleScenario,
    closeEventsModal: actions.closeEventsModal,
    closeOption: actions.closeOption,
    updateNode: actions.updateNode,
    getUserScenarios: actions.getUserScenarios,
    loadScenario: actions.loadScenario,
    saveFlinksBankData: actions.saveFlinksBankData,
    updateFlinksData: actions.updateFlinksData,
    getSubscription: actions.getSubscription,
    setOnboardingData: actions.setOnboardingData,
    onboardingScenario: actions.onboardingScenario,
    getPlaidAccount: actions.getPlaidAccount,
    updateBaseline: actions.updateBaseline,
    updateBaselineCanvas: actions.updateBaselineCanvas,
    onhandleFocusedInput: actions.onhandleFocusedInput,
    setHighlightedThread: actions.setHighlightedThread,
    getUserData: actions.getUserData,
    updateUserData: actions.updateUserData,
    // event library actions
    addLibraryEvents: actions.addLibraryEvents,
    updateLibraryEvent: actions.updateLibraryEvent,
    deleteLibraryEvents: actions.deleteLibraryEvents,
    updateSampleScenario: actions.updateSampleScenario,
    getTemplates: actions.getTemplates,
};

const InputContainer = connect(mapStateToProps, mapDispatchToProps)(Inputs);

export default InputContainer;

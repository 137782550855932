import React, { Component } from "react";
import LocationSearchInput from "../LocationSearchInput";
import "./InputContainer.css";
import radioButtonOff from "../../Assets/_nodeInputs/radioButtonOff.svg";
import radioButtonOn from "../../Assets/_nodeInputs/radioButtonOn.svg";
import SingleCalendar from "../Calendar/singleCalendar";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import { throwError } from "../../helpers/swalError";
import Switch from "react-switch";
import _ from "lodash";
import {
    Name,
    Description,
    Value,
    Url,
    Stars,
    Rate,
    InputButtons,
} from "./Components";
import {
    extractCityFromAddress,
    extractProvinceFromAddress,
    getDefaultName,
} from "../../helpers";
import { houseObject } from "../Registry/House";

class HouseInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: getDefaultName(houseObject.constant(), this.props),
            address: "",
            lat: null,
            lng: null,
            price: "",
            showSettings: false,
            description: "",
            mlsNumber: "",
            url: "",
            "first payment": null,
            applyAppreciation: false,
            rating: 0,
            type: houseObject.constant(),
            value: null,
            tag: `@${houseObject.constant()}`,
            onHoverStar: null,
            appreciationRate: 0,
            baseline: false,
            newlyBuiltHomeTrue: false,
            newlyBuiltHomeFalse: false,
            primaryResidenceTrue: false,
            primaryResidenceFalse: false,
            province: null,
            city: null,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }

        if (this.props.onboardingData === 3) {
            this.setState({
                name: "House 1",
                address: "4635 Kingsway Burnaby, BC, Canada",
                province: "BC",
                city: "Burnaby",
                lat: 49.229286,
                lng: -123.001795,
                price: 850000,
                showSettings: false,
                description: "This is a sample house for onboarding",
                mlsNumber: "YHR682H",
                url: null,
                "first payment": "2022-04-14",
                applyAppreciation: false,
                rating: 3,
                type: "House",
                value: 850000,
                tag: "@House1",
                onHoverStar: null,
                newlyBuiltHomeTrue: true,
                newlyBuiltHomeFalse: false,
                primaryResidenceTrue: false,
                primaryResidenceFalse: true,
            });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "address":
                this.setState({ address: value });
                this.processAddress(value);
                break;
            case "price":
                this.setState({ price: value, value });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "mlsNumber":
                this.setState({ mlsNumber: value });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "appreciationRate":
                this.setState({ appreciationRate: value });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleLocation = (address, lat, lng) => {
        this.setState({ address, lat, lng });
        this.processAddress(address);
    };

    onHandleSubmitValues = () => {
        if (this.state.province === null || this.state.city == null) {
            throwError(
                "warning",
                "Address not fully supported.",
                "Non-Canadian addresses or Canadian addresses with no city specified " +
                    "may lead to inaccurate calculations with other House-related Events."
            );
        }

        const passedCheck = houseObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleDate = (startDate) => {
        this.setState({ "first payment": startDate });
    };

    updateAppreciation = () => {
        this.setState({ applyAppreciation: !this.state.applyAppreciation });
    };

    onHandleRadioButton = (id, value) => {
        switch (id) {
            case "primaryResidence":
                if (!value) {
                    this.setState({
                        primaryResidenceFalse:
                            !this.state.primaryResidenceFalse,
                        primaryResidenceTrue: false,
                    });
                } else {
                    this.setState({
                        primaryResidenceTrue: !this.state.primaryResidenceTrue,
                        primaryResidenceFalse: false,
                    });
                }
                this.onHandleMouseEnter(id);
                break;
            case "newlyBuiltHome":
                if (!value) {
                    this.setState({
                        newlyBuiltHomeFalse: !this.state.newlyBuiltHomeFalse,
                        newlyBuiltHomeTrue: false,
                    });
                } else {
                    this.setState({
                        newlyBuiltHomeTrue: !this.state.newlyBuiltHomeTrue,
                        newlyBuiltHomeFalse: false,
                    });
                }
                this.onHandleMouseEnter(id);
                break;
            default:
        }
    };

    processAddress = (_address) => {
        const province = extractProvinceFromAddress(this.state.address);
        const city = extractCityFromAddress(this.state.address);
        this.setState({ province: province });
        this.setState({ city: city });
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "primaryResidence":
                data = {
                    title: "Primary Residence",
                    content:
                        "If this house is the property you currently living in, please choose “Yes”.",
                    subContent: "",
                    link: "https://www.investopedia.com/terms/p/principal-private-residence-canada.asp",
                };
                break;
            case "newlyBuiltHome":
                data = {
                    title: "Newly Built Home",
                    content:
                        "In general, a Newly Built Home is house constructed on vacant land and can include a new apartment in a recently built condominium development.",
                    subContent: "",
                    link: "https://www2.gov.bc.ca/gov/content/taxes/property-taxes/property-transfer-tax/exemptions/newly-built-home-exemption#:~:text=A%20newly%20built%20home%20includes,a%20parcel%20of%20vacant%20land",
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    onHandleMouseLeave = () => {
        this.props.onhandleFocusedInput(null);
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeaveStar = () => {
        this.setState({ onHoverStar: null });
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        const {
            primaryResidenceTrue,
            primaryResidenceFalse,
            newlyBuiltHomeTrue,
            newlyBuiltHomeFalse,
        } = this.state;
        const passedCheck = houseObject.checkInput(this.state);
        const { edit } = this.props;
        const onboarding = this.props.onboardingData === 3;
        const {
            name,
            description,
            url,
            rating,
            onHoverStar,
            mlsNumber,
            price,
            applyAppreciation,
            appreciationRate,
        } = this.state;
        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={houseObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">HOUSE</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        onMouseEnter={() =>
                            this.onHandleMouseEnter("primaryResidence")
                        }
                        onMouseLeave={this.onHandleMouseLeave}
                        className="ModalRow"
                    >
                        <div className="oneThird">
                            <div className="Required">*</div>
                            <div className="PTTLabel">Primary Residence</div>
                        </div>
                        <div className="oneThird">
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "primaryResidence",
                                        true
                                    )
                                }
                                src={
                                    primaryResidenceTrue
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">Yes</div>
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "primaryResidence",
                                        false
                                    )
                                }
                                id="primaryResidence"
                                src={
                                    primaryResidenceFalse
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">No</div>
                        </div>
                    </div>
                    <div
                        onMouseEnter={() =>
                            this.onHandleMouseEnter("newlyBuiltHome")
                        }
                        onMouseLeave={this.onHandleMouseLeave}
                        className="ModalRow"
                    >
                        <div className="oneThird">
                            <div className="Required">*</div>
                            <div className="PTTLabel">Newly Built Home</div>
                        </div>
                        <div className="oneThird">
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "newlyBuiltHome",
                                        true
                                    )
                                }
                                src={
                                    newlyBuiltHomeTrue
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">Yes</div>
                            <img
                                alt="alt"
                                onClick={() =>
                                    this.onHandleRadioButton(
                                        "newlyBuiltHome",
                                        false
                                    )
                                }
                                src={
                                    newlyBuiltHomeFalse
                                        ? radioButtonOn
                                        : radioButtonOff
                                }
                                className="pttRadioButton"
                            />
                            <div className="PTTValueLabel">No</div>
                        </div>
                    </div>
                    <div>
                        <div className="ModalRow">
                            <div className="Required">*</div>
                            <LocationSearchInput
                                id="address"
                                handleLocation={this.onHandleLocation}
                                value={this.state.address}
                                edit={this.props.edit}
                            />
                        </div>
                        <div className="ModalRow">
                            <Description
                                description={description}
                                onChangeInput={this.onChangeInput}
                            />
                        </div>
                        <div className="ModalRowSpaceBetween">
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <Value
                                    label="Purchase Price"
                                    onChangeInput={this.onChangeInput}
                                    value={price}
                                    id="price"
                                />
                            </div>
                            <div className="ExpenseAmountContainer">
                                <Rate
                                    value={mlsNumber}
                                    label="MLS #"
                                    className="mlsInput"
                                    id="mlsNumber"
                                    onChangeInput={this.onChangeInput}
                                    sign={false}
                                />
                            </div>
                        </div>
                        <div className="ModalRow">
                            <Url
                                value={url}
                                className="UrlLink"
                                onChangeInput={this.onChangeInput}
                                id="url"
                                label="URL"
                            />
                        </div>
                    </div>

                    <div className="ModalRowSpaceBetween">
                        <div className="ExpenseAmountContainer">
                            <div className="PropertyValueInflationText">
                                Appreciation Rate
                            </div>
                            <Switch
                                id="appreciation"
                                checked={applyAppreciation}
                                className="InflationToggle"
                                height={20}
                                width={40}
                                onChange={this.updateAppreciation}
                            />
                        </div>
                        <div className="ExpenseAmountContainer">
                            <div
                                className={
                                    applyAppreciation
                                        ? "PVRateActive"
                                        : "PVRate"
                                }
                            >
                                <input
                                    className={
                                        applyAppreciation
                                            ? "PVInput"
                                            : "PVInputDisabled"
                                    }
                                    value={appreciationRate}
                                    id="appreciationRate"
                                    onChange={(e) =>
                                        this.onChangeInput(
                                            e,
                                            "appreciationRate"
                                        )
                                    }
                                />{" "}
                                %
                            </div>
                        </div>
                    </div>

                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <div className="keyLabel">Purchase Date</div>
                        <SingleCalendar
                            onHandleDate={this.onHandleDate}
                            date={this.state["first payment"]}
                        />
                    </div>

                    <div className="ModalRow">
                        <div className="Required" />
                        <div className="keyLabel">Rating</div>
                        <div className="StarsHolder">
                            {stars.map((star, i) => {
                                const id = i + 1;
                                const onHover = onHoverStar;
                                if (i < rating || i < onHover) {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starFilledSvg"
                                            src={starFilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeaveStar}
                                        />
                                    );
                                } else {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starUnfilledSvg"
                                            src={starUnfilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeaveStar}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    onboarding={onboarding}
                    isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                    isLibraryEvent={this.state.isLibraryEvent}
                    addLibraryEventToScenario={() => {
                        this.props.addLibraryEventToScenario(this.state);
                    }}
                    divorceLibraryEvent={() => {
                        this.props.divorceFromLibraryEvent(
                            this.state,
                            this.props.updateValues
                        );
                    }}
                />
            </div>
        );
    }
}

export default HouseInput;

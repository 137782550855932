import { useState, ChangeEvent, useEffect } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import PropertyValueInputView from "./PropertyValueInputView";
import { propertyValueInputsHandler } from "../OnInputChangeHandlers/propertyValueInputsHandler";
import { propertyValueObject } from "Components/Registry/Property Value";
import { getDefaultName } from "helpers";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { throwError } from "helpers/swalError";
import { houseObject } from "Components/Registry/House";
import { addNewEvent } from "actions/eventHelpers";
import { updateNode } from "actions/scenario";
import { EventManager } from "Events";

export default function UnitCostInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);

    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(propertyValueObject);
            _eventData.name = getDefaultName(
                propertyValueObject.constant(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        onHandleDate,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = { manager, line, focus };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const houses = getHouses();
            const dateToday = _getDateToday();

            const newEntitiesMap = { ...prevEntitiesMap };
            const currentEntityObject = {
                ...(newEntitiesMap[currentEntity] || {}),
            };
            const data = { ...(currentEntityObject?.data || {}) };

            data.houses = houses;
            data.dateToday = dateToday;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const getHouses = () => {
        const houses = {};

        getPresentableDependencies(houses, houseObject.constant(), {
            manager,
            focus,
            line,
        });

        if (!Object.keys(houses).length) {
            throwError(
                "warning",
                `No House nodes found upstream or in baseline`
            );
        }
        return houses;
    };

    const _getDateToday = () => {
        const dateToday = new Date();
        const YYYY = dateToday.getFullYear();
        const m = dateToday.getMonth() + 1;
        const MM = m < 10 ? "0" + m.toString() : m.toString();
        const d = dateToday.getDate();
        const DD = d < 10 ? "0" + d.toString() : d.toString();
        return `${YYYY}-${MM}-${DD}`;
    };

    const updateAppreciation = (checked: boolean) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.appreciation = checked;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateNode,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "house" | "propertyValue" | "entityName" | "appreciationRate"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = propertyValueInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && propertyValueObject.checkInput(entitiesMap);

    return (
        <PropertyValueInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            onHandleSubmit={onHandleSubmit}
            handleOnChange={handleOnChange}
            onHandleDate={onHandleDate}
            updateAppreciation={updateAppreciation}
            passedCheck={passedCheck}
            edit={edit}
            eventData={eventData}
        />
    );
}

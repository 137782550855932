import {
    RESET_CHART_GRAPH,
    UPSERT_CHART_GRAPH,
    UPDATE_SELECTED_ACCOUNTS,
} from "actions/chartGraphActions";
import { ChartGraphSchema } from "reducers/typesSchema/chartGraphSchema";

const initialState: ChartGraphSchema = {
    selectedAccounts: {
        "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78": {
            name: "Cashflow",
            id: "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78",
        },
    },
    monthlyCumulative: "monthly",
    scenarioId: "",
    activeNodeId: "",
    nodeIds: [],
};

export default function chartGraphReducer(
    state = initialState,
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case RESET_CHART_GRAPH:
            return { ...initialState };
        case UPSERT_CHART_GRAPH:
            return { ...state, ...action.payload };
        case UPDATE_SELECTED_ACCOUNTS:
            return { ...state, selectedAccounts: action.payload };
        default:
            return state;
    }
}

import type { RefObject } from "react";
import { useEffect, useState } from "react";
import { DOMRectInitialState } from "../helpers/DOMRectInitialState";

const MAX_TIME = 10000;

export function useObserveWrapperSizeChange(
    containerRef: RefObject<HTMLDivElement>
) {
    const [dimensions, setDimensions] = useState<DOMRect>({
        ...DOMRectInitialState,
    });
    const [forceRerender, setForceRerender] = useState(0);

    /**
     * Ensure the ref has an element before listening
     */
    useEffect(() => {
        const start = Date.now();

        const intervalId = setInterval(() => {
            const container = containerRef.current;
            if (Date.now() - start > MAX_TIME) {
                console.error(
                    "uowsc - Time limit exceeded. Ensure you passed in a valid containerRef!"
                );
                clearInterval(intervalId);
                return;
            }
            if (!container) return;

            setForceRerender(Date.now());
            clearInterval(intervalId);
        }, 30);

        return () => {
            clearInterval(intervalId);
        };
    }, [containerRef]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            let timeout: NodeJS.Timeout | null = null;
            const observer = new ResizeObserver((_entries) => {
                if (timeout) clearTimeout(timeout);

                timeout = setTimeout(() => {
                    const rect = container?.getBoundingClientRect?.();
                    setDimensions(rect);
                }, 75);
            });

            observer.observe(container);

            return () => {
                if (timeout) clearTimeout(timeout);

                observer.unobserve(container);
            };
        }
    }, [
        /**
         * Because this is a ref, its reference will never change. Therefore, this is the same as an empty dep array.
         */
        containerRef,
        forceRerender,
    ]);

    return dimensions;
}

import type { OnboardingModalSchema } from "./typesSchema/onboardingModalSchema";
import { SHOW_ONBOARDING_MODAL } from "actions/onboardingModalActions";

const initialState: OnboardingModalSchema = {
    modalOpen: false,
};

export default function onboardingModal(
    state: OnboardingModalSchema = initialState,
    action: { type: string; payload?: any }
): OnboardingModalSchema {
    switch (action.type) {
        case SHOW_ONBOARDING_MODAL:
            return {
                ...state,
                modalOpen: action.payload,
            };
        default:
            return state;
    }
}

import React, { Component } from "react";

class CapitalGainsCard extends Component {
    render() {
        const { description, person, province, version } =
            this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Person</div>
                    <div className="cardNumber">
                        {person ? `% ${person}` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Province</div>
                    <div className="cardNumber">
                        {province ? `% ${province}` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default CapitalGainsCard;

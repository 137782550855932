import React, { Component } from "react";
import "./Card.css";

class IncomeTaxCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const id = this.props.data.id;
        const { version } = this.props.data.metadata;
        const threadsAlias = this.props.threadsAlias || {};
        const curYear = new Date().getFullYear();
        return (
            <div className="Container">
                {Object.values(threadsAlias).map((thread) => {
                    if (thread.context) {
                        if (thread.context["IncomeTax"]) {
                            if (thread.context["IncomeTax"][id]) {
                                return (
                                    <div className="threadDataContainer">
                                        <div className="Row">
                                            <div className="DollarSvg">$</div>
                                            <div className="Amount">
                                                {parseInt(
                                                    thread.context["IncomeTax"][
                                                        id
                                                    ].data.taxGraph[thread.id][
                                                        curYear
                                                    ].totalTax
                                                )}
                                            </div>
                                            annually /
                                        </div>
                                        <div className="Row">
                                            <div className="ExpenseLabel">
                                                Thread:
                                            </div>
                                            <div className="cardValue">
                                                {thread.option}
                                            </div>
                                        </div>
                                        <div className="Row">
                                            <div className="ExpenseLabel">
                                                Version:
                                            </div>
                                            <div className="cardValue">
                                                {version ? version : ""}
                                            </div>
                                        </div>
                                        {/* <div className="Row">
                                            <div className="ExpenseLabel">
                                                Province
                                            </div>
                                            <div className="cardValue">
                                                {province}
                                            </div>
                                        </div> */}
                                    </div>
                                );
                            }
                        }
                    }
                })}
            </div>
        );
    }
}

export default IncomeTaxCard;

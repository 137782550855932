import type { LoadingStatesSchema } from "reducers/typesSchema/loadingStatesSchema";

export const UPSERT_LOADING_STATES = "loadingStates/UPSERT_LOADING_STATES";

export const upsertLoadingStates = (
    upsertState: Partial<LoadingStatesSchema>
) => ({
    type: UPSERT_LOADING_STATES,
    payload: upsertState,
});

import { LOGIN } from "../actions/types";
import { AuthSchema } from "./typesSchema/authSchema";

const initialState: AuthSchema = {
    auth: null,
    token: null,
    error: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, ...action.payload } || null;
        default:
            return state;
    }
};

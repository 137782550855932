import Konva from "konva";
import { useRef, useState } from "react";
import CanvasStageStyles from "./CanvasStageStyles.module.css";
import { StageViewableAreaDimensionsContext } from "./contexts/StageViewableAreaDimensionsContext";
import { CanvasStageWrapperElementDimensions } from "./contexts/CanvasStageWrapperElementDimensions";
import { CanvasStageScaleContext } from "./contexts/CanvasStageScaleContext";
import { CanvasStageZoomCategoryContext } from "./contexts/CanvasStageZoomCategoryContext";
import {
    FocusedLineIdContext,
    HoveredLineIdContext,
    SetHoveredLineIdContext,
} from "./contexts/CanvasLinesStateContexts";
import {
    FocusedCardIdContext,
    HoveredCardIdContext,
    SetHoveredCardIdContext,
} from "./contexts/CanvasCardsStateContext";

import {
    EventDragActiveContext,
    SetEventDragActiveContext,
    DraggedEventTempXContext,
    SetDraggedEventTempXContext,
    DraggedEventTempYContext,
    SetDraggedEventTempYContext,
} from "./contexts/CanvasEventDragContext";

import { CanvasBaselineContext } from "./contexts/CanvasBaselineContext";
import { CanvasDashboardContext } from "./contexts/CanvasDashboardContext";
import { useObserveWrapperSizeChange } from "./hooks/useObserveWrapperSizeChange";
import { useZoomCategory } from "./hooks/useZoomCategory";
import { usePortalTarget } from "./hooks/usePortalTarget";
import { CanvasZoomButtons } from "./partials/CanvasZoomButtons";
import { CanvasStageMain } from "./CanvasStageMain";
import { createPortal } from "react-dom";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import { CanvasExplainerVideo } from "./partials/CanvasExplainerVideo";

interface CanvasStageProps {
    isBaseline: boolean;
    isDashboard: boolean;
}

export function CanvasStage({
    isBaseline = false,
    isDashboard = false,
}: CanvasStageProps) {
    const portalTarget = usePortalTarget();

    const stageViewableAreaRef = useRef<HTMLDivElement>(null);
    const stageWrapperRef = useRef<HTMLDivElement>(null);
    const stageRef = useRef<Konva.Stage>(null);
    const [stagePosition, setStagePosition] = useState<[number, number]>([
        0, 0,
    ]);

    const [stageScale, setStageScale] = useState(1);
    const [hoveredLineId, setHoveredLineId] = useState<string | null>(null);
    const [hoveredCardId, setHoveredCardId] = useState("");

    const [eventDragActive, setEventDragActive] = useState(false);
    const [draggedEventTempX, setDraggedEventTempX] = useState(0);
    const [draggedEventTempY, setDraggedEventTempY] = useState(0);

    const viewableAreaDimensions =
        useObserveWrapperSizeChange(stageViewableAreaRef);

    const { focusedLineId } = useAppSelector((state) => {
        const focusedLineId: string | null = state?.scenario?.line?.lineId;
        return {
            focusedLineId,
        };
    }, shallowEqual);

    const focusedCardId = useAppSelector(
        (state) => state?.scenario?.focusedCardId,
        shallowEqual
    );

    /**
     * stageWrapperDimensions will be much bigger than the viewableAreaDimensions
     *
     * This is so that there is a 'sliding window' effect with the panels.
     * See BUG: Panels should act like "Sliding Windows": https://www.notion.so/whatifi/aa247518cdb14b64a57356c7f98fa768?v=c6131908f9b64e43bde153f455f085c5&p=67f57114aa0547e38ceebd7cafe9c87b
     */
    const stageWrapperDimensions = useObserveWrapperSizeChange(stageWrapperRef);
    const zoomCategory = useZoomCategory(stageScale);
    return (
        <StageViewableAreaDimensionsContext.Provider
            value={viewableAreaDimensions}
        >
            <CanvasStageWrapperElementDimensions.Provider
                value={stageWrapperDimensions}
            >
                <CanvasStageScaleContext.Provider value={stageScale}>
                    <CanvasStageZoomCategoryContext.Provider
                        value={zoomCategory}
                    >
                        <CanvasBaselineContext.Provider value={isBaseline}>
                            <CanvasDashboardContext.Provider
                                value={isDashboard}
                            >
                                <SetHoveredLineIdContext.Provider
                                    value={setHoveredLineId}
                                >
                                    <HoveredLineIdContext.Provider
                                        value={hoveredLineId}
                                    >
                                        <SetHoveredCardIdContext.Provider
                                            value={setHoveredCardId}
                                        >
                                            <HoveredCardIdContext.Provider
                                                value={hoveredCardId}
                                            >
                                                <FocusedLineIdContext.Provider
                                                    value={focusedLineId}
                                                >
                                                    <EventDragActiveContext.Provider
                                                        value={eventDragActive}
                                                    >
                                                        <SetEventDragActiveContext.Provider
                                                            value={
                                                                setEventDragActive
                                                            }
                                                        >
                                                            <DraggedEventTempXContext.Provider
                                                                value={
                                                                    draggedEventTempX
                                                                }
                                                            >
                                                                <SetDraggedEventTempXContext.Provider
                                                                    value={
                                                                        setDraggedEventTempX
                                                                    }
                                                                >
                                                                    <DraggedEventTempYContext.Provider
                                                                        value={
                                                                            draggedEventTempY
                                                                        }
                                                                    >
                                                                        <SetDraggedEventTempYContext.Provider
                                                                            value={
                                                                                setDraggedEventTempY
                                                                            }
                                                                        >
                                                                            <FocusedCardIdContext.Provider
                                                                                value={
                                                                                    focusedCardId
                                                                                }
                                                                            >
                                                                                <div
                                                                                    ref={
                                                                                        stageViewableAreaRef
                                                                                    }
                                                                                    className={
                                                                                        CanvasStageStyles.CanvasViewableAreaWrapper
                                                                                    }
                                                                                >
                                                                                    <CanvasZoomButtons
                                                                                        stageRef={
                                                                                            stageRef
                                                                                        }
                                                                                        setStagePosition={
                                                                                            setStagePosition
                                                                                        }
                                                                                        setStageScale={
                                                                                            setStageScale
                                                                                        }
                                                                                    />
                                                                                    <CanvasExplainerVideo />
                                                                                    {portalTarget.current &&
                                                                                        createPortal(
                                                                                            <CanvasStageMain
                                                                                                ref={
                                                                                                    stageWrapperRef
                                                                                                }
                                                                                                stageRef={
                                                                                                    stageRef
                                                                                                }
                                                                                                stagePosition={
                                                                                                    stagePosition
                                                                                                }
                                                                                                setStagePosition={
                                                                                                    setStagePosition
                                                                                                }
                                                                                                setStageScale={
                                                                                                    setStageScale
                                                                                                }
                                                                                            />,
                                                                                            portalTarget.current
                                                                                        )}
                                                                                </div>
                                                                            </FocusedCardIdContext.Provider>
                                                                        </SetDraggedEventTempYContext.Provider>
                                                                    </DraggedEventTempYContext.Provider>
                                                                </SetDraggedEventTempXContext.Provider>
                                                            </DraggedEventTempXContext.Provider>
                                                        </SetEventDragActiveContext.Provider>
                                                    </EventDragActiveContext.Provider>
                                                </FocusedLineIdContext.Provider>
                                            </HoveredCardIdContext.Provider>
                                        </SetHoveredCardIdContext.Provider>
                                    </HoveredLineIdContext.Provider>
                                </SetHoveredLineIdContext.Provider>
                            </CanvasDashboardContext.Provider>
                        </CanvasBaselineContext.Provider>
                    </CanvasStageZoomCategoryContext.Provider>
                </CanvasStageScaleContext.Provider>
            </CanvasStageWrapperElementDimensions.Provider>
        </StageViewableAreaDimensionsContext.Provider>
    );
}

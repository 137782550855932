// Default Imports
import React from "react";

// Import Rental Start Card and Rental Start Input
// import StartCard from "../Card/startCard.js";
import StartInput from "../InputContainer/StartInput.js";

// Import Connection Image (if exists)
import StartConnectionImage from "../../Assets/logo.png";

// Import Coloured PNGs
import startBlack from "../../Assets/logo.png";
import startBlue from "../../Assets/logo.png";
import startWhite from "../../Assets/logo.png";
import startGrey from "../../Assets/logo.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - startObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - startObject.checkInput.call(startObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Start
export const a = "abc";
export const startObject = {
    constant: function () {
        return Start_Constant();
    },

    name: function () {
        return "Start";
    },

    checkInput: function (state) {
        return Start_CheckInput(state);
    },

    svg: function () {
        return Start_Svg();
    },

    eventNodeMenu: function (focus) {
        return Start_EventNodeMenu(focus);
    },

    indexText: function () {
        return Start_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Start_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Start_OptionDescription();
    },

    colouredPngs: function () {
        return Start_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Start_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Start_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Start

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Start_CheckInput = () => {
    return true;
};

// EFFECTS: Returns constant for event
const Start_Constant = () => {
    return "2aebc590-46f4-4b20-b700-c8fa38b9f0e0";
};

// EFFECTS: Returns svg for event
const Start_Svg = () => {
    return startBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Start_EventNodeMenu = () => {
    return <></>;
};

// EFFECTS: Returns the Index Text for event
const Start_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Start calculations.";
};

// EFFECTS: Returns all icons for event ??
const Start_Icons = () => {
    let pngs = new Map([
        ["black", startBlack],
        ["blue", startBlue],
        ["white", startWhite],
        ["grey", startGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Start_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Start_InputComponentInfoPassing = (minProps) => {
    return <StartInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Start_OptionDescription = () => {
    const tags = ["Option"];
    const type = startObject.name();
    const description =
        "Start is money received, typically on a regular basis, due to employment or investments.";
    const usedForText =
        "The Start Node can be used to model incoming cashflows.";
    const connection = "";
    const connectionImage = StartConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/i/start.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Start_SideModalImage = () => {
    return sideImage;
};

const Start_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

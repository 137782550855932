import {
    TreeNode,
    Me,
    Individual,
    Partner,
    Decision,
    Event,
    Income,
    Expense,
    Loan,
    Mortgage,
    Bank,
} from "./treeNode";
import * as d3 from "d3";

class TreeManager {
    constructor(
        tree,
        updateNodes = () => {
            // noop
        },
        resetFocus,
        renderEntities = null,
        positionEntities = null
    ) {
        this.rawData = tree;
        this.tree = {};
        this.buckets = [];
        this.nodes = [];
        this.links = [];
        this.actions = [];
        this.updateNodes = updateNodes;
        this._resetFocus = resetFocus;
        this._minified = false;
        this.inflation = null;

        const defaultRender = () => {
            //console.log("render function unavailable");
        };
        this._render = renderEntities || defaultRender;

        const defaultPosition = () => {
            //console.log("render function unavailable");
        };
        this._position = positionEntities || defaultPosition;
        this.resetCanvas = () => {
            //console.log("reset canvas function unavailable");
        };
        this._updateScenario = () => {
            // noop
        };
    }

    _resetData() {
        this.tree = {};
        this.buckets = [];
        this.nodes = [];
        this.links = [];
        this.actions = [];

        //this.initializeDisplay();
        this._renderNodes();
    }

    _renderNodes(delay = true) {
        this._updateNodeStatus();
        this._trimBuckets();
        const allNodes = [];
        this.nodes.forEach((node) => {
            if (node.metadata.start) {
                const data = Object.assign(node.metadata);
                data.node = node;
                allNodes.push(data);
            }
        });

        const root = allNodes.length > 0 ? this.buckets[0][0] : null;
        const branches = root ? this._findBranches(root) : [];

        const filteredBranches = this._removeDuplicateBranches(branches);
        this._removeMismatchedLocation(filteredBranches);
        this.updateNodes(branches);
        this._balanceDepth();
        this._centerBreadth();
        this._generateLinks();
        this._render(this);
        this._position(delay);
    }

    _addAction = (action, newData) => {
        this.actions.push(action);
        if (newData) {
            this._updateScenario(this.exportNodes());
            this.resetCanvas();
            this._renderNodes();
        }
    };

    createEmptyScenario = () => {
        this._resetData();
        this.addStart({});
    };

    exportNodes = () => {
        return {
            actions: this.actions,
            nodes: this.nodes.map((node) => {
                const { id, children, parents, metadata } = node;

                const removeData = [
                    "showOptions",
                    "showRequired",
                    "decisionLevel",
                    "addedTo",
                    "decisionContext",
                    "node",
                    "nextDate",
                    "rootEvent",
                ];
                removeData.forEach((key) => {
                    delete metadata[key];
                });
                return {
                    id,
                    metadata,
                    parents: parents.map((parentNode) => parentNode.id),
                    children: children.map((childNode) => childNode.id),
                };
            }),
        };
    };

    importNodes = (importDetails, inflation) => {
        if (inflation) {
            this.inflation = parseFloat(inflation);
        }

        this._resetData();
        importDetails.actions &&
            importDetails.actions.forEach((action) => {
                let node;
                if (action.action === "add-event") {
                    const target = this.tree[action.target];
                    const source = action.details;
                    source.id = action.source;
                    node = this.addEvent(target, source, false);
                } else if (action.action === "add-decision") {
                    const target = this.tree[action.target];
                    const source = action.details;
                    source.id = action.source;
                    node = this.addDecision(target, source, false);
                } else if (action.action === "add-option") {
                    const target = this.tree[action.target];
                    const source = action.details;
                    source.id = action.source;
                    node = this.addOption(target, source, false, action.type);
                } else if (action.action === "collect-decision") {
                    let targetIds = [];

                    const newDetails = {
                        decisionLevel: action.newDetails.decisionLevel,
                        id: action.newDetails.id,
                        name: action.newDetails.name,
                        rating: action.newDetails.rating,
                        type: action.newDetails.type,
                    };

                    action.target.map((id) => {
                        if (this.tree[id]) {
                            targetIds.push(this.tree[id]);
                        }
                        return id;
                    });
                    const target = targetIds;
                    const source = newDetails;
                    node = this.insertCollectionNode(target, source, false);
                } else if (action.action === "add-slide") {
                    const target = this.tree[action.target];
                    const source = action.details;
                    const type = action.type;
                    node = this.addSlide(target, source, type, false);
                } else if (action.action === "add-me") {
                    const source = action.details;
                    source.id = action.source;
                    node = this.addStart(source, false);
                } else if (action.action === "remove-node") {
                    this.removeNode(this.tree[action.source], false);
                } else if (action.action === "insert-decision") {
                    const target = this.tree[action.target];
                    const source = this.tree[action.source];

                    node = this.insertDecision(
                        source,
                        action.details,
                        false,
                        target
                    );
                } else if (action.action === "insert-event") {
                    const target = this.tree[action.target];
                    const source = this.tree[action.source];

                    node = this.insertEvent(
                        source,
                        action.details,
                        false,
                        target
                    );
                }

                if (node) {
                    this.tree[node.id] = node;
                }
            });
        this.resetCanvas();
        this._renderNodes();
    };

    initializeDisplay() {
        this._render(this);
        this._position(false);
    }

    obtainNode(nodeId) {
        return this.tree[nodeId];
    }

    obtainParentNode(_node) {
        const node = typeof _node == "string" ? this.obtainNode(_node) : _node;

        if (node && node.parents.length > 0) {
            const parentNode = node.parents[0];
            if (parentNode) {
                return parentNode;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    refreshNodes() {
        this._position();
    }

    _findRootShown(node) {
        return node.minimized && node.parents.length > 0
            ? this._findRootShown(node.parents[0])
            : node;
    }

    minimizeSubTree(_node, root = false, parentState) {
        const node = typeof _node == "string" ? this.obtainNode(_node) : _node;

        if (root && node.minimized) {
            return this.minimizeSubTree(
                this._findRootShown(node.parents[0]).id,
                true
            );
        }

        if (!root) {
            node.minimized =
                parentState != null ? parentState : !node.minimized;
        }

        (node.children || []).forEach((child) => {
            if (root) {
                this.minimizeSubTree(child);
            } else {
                this.minimizeSubTree(child, false, node.minimized);
            }
        });

        this._renderNodes();
    }

    minifyDisplay(minify) {
        this._minified = minify;
        this.nodes.forEach((node) => {
            node.minified = minify;
        });

        this._position();
    }

    toggleMinifyDisplay() {
        this.minifyDisplay(!this._minified);

        this._position();
    }

    highlightSubTree(_node, parentState) {
        const node = typeof _node == "string" ? this.obtainNode(_node) : _node;
        node.highlighted =
            parentState != null ? parentState : !node.highlighted;
        (node.children || []).forEach((_child) => {
            const child = this.obtainNode(_child);
            this.highlightSubTree(child, node.highlighted);
        });

        this._position();
    }

    _generateTree(nodes, edges) {
        console.log("Generate Tree", nodes, edges);
    }

    _createNode(node) {
        if (this.tree[node.id]) {
            return this.tree[node.id];
        }
        switch (node.type) {
            case "me":
                return new Me(node.name, node, this);

            case "individual":
                return new Individual(node.name, node, this);

            case "partner":
                return new Partner(node.name, node, this);

            case "decision":
                return new Decision(node.name, node, this);

            case "event":
                return new Event(node.name, node, this);

            case "income":
                return new Income(node.name, node, this);

            case "expense":
                return new Expense(node.name, node, this);

            case "loan":
                return new Loan(node.name, node, this);

            case "mortgage":
                return new Mortgage(node.name, node, this);

            case "bank":
                return new Bank(node.name, node, this);

            default:
                return new TreeNode(node.name, node, this);
        }
    }

    _processNodes(_nodes) {
        _nodes.forEach((node) => {
            /*
            const processedNode = new TreeNode(
                node.name,
                node,
                this,
                node
            );
*/
            const processedNode = this._createNode(node);
            processedNode.children = node.children;
            processedNode.parents = node.parents;

            this.tree[node.id] = processedNode;
            this.nodes.push(processedNode);
        });

        this.nodes.forEach((node) => {
            node.metadata.children.forEach(() => {
                const source = this.obtainNode(node.metadata.id);
                const target = node;

                this.links.push({ source: source, target: target });
            });
        });

        this.nodes.forEach((node) => {
            node.depth = this._generateDepth(node);

            this._createBucketsToDepth(node.depth);

            this.buckets[node.depth].push(node);
        });

        if (this.nodes.length > 0) {
            this._renderNodes();
        }
    }

    _generateDepth(node) {
        if (node.metadata.parents.length === 0) {
            return 0;
        } else {
            let parentDepth = 0;
            node.metadata.parents.forEach((parentNodeId) => {
                const parentNode = this.obtainNode(parentNodeId);
                const currentParentDepth = this._generateDepth(parentNode) + 1;
                parentDepth =
                    currentParentDepth > parentDepth
                        ? currentParentDepth
                        : parentDepth;
            });
            return parentDepth;
        }
    }

    _generateNodes(branches, parentId, treeDepth) {
        const depth = treeDepth || 0;
        const bucketDepth = depth;

        this._createBucketsToDepth(bucketDepth);
        branches.forEach((entity) => {
            const metadata = {
                type: entity.nodeType,
                nodeName: entity.nodeName,
                activities: entity.activities || [],
                value: entity.value || 0,
                cadence: entity.cadence || "one-time",
            };
            const node = new TreeNode(entity.nodeId, metadata, this);
            if (parentId) {
                node.parents.push(parentId);
            }

            node.depth = depth;
            node.children = this._generateNodes(
                (entity.outputActivity || {}).children || [],
                node.id,
                depth + 1
            );

            this.tree[node.id] = node;
            this.buckets[depth].push(node);
            this.nodes.push(node);
        });
    }

    inDecision = (node) => {
        if (node.type === "decision") {
            return true;
        } else if (node.parents.length === 0) {
            return false;
        }

        let inDecision = false;
        node.parents.forEach((parentNode) => {
            if (parentNode && this.inDecision(parentNode)) {
                inDecision = true;
            }
        });
        return inDecision;
    };

    addPartner(addTo, partnerDetails) {
        partnerDetails["type"] = "partner";
        const node = this._createNode(partnerDetails);
        node.depth = addTo.depth;
        const bucketDepth = node.depth;

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        node.breadth = this.buckets[bucketDepth].length - 1;

        node.parents = addTo.parents;
        addTo.partner = node;
        node.partner = addTo;

        this._renderNodes();

        return node;
    }

    insertEvent(source, details, newData = true, target) {
        let type;
        if (details.income) {
            type = "income";
        } else if (details.cost) {
            type = "expense";
        } else if (details.loan) {
            type = "loan";
        } else if (details.downpayment) {
            type = "mortgage";
        } else if (details.account) {
            type = "bank";
        }
        const copy = JSON.parse(JSON.stringify(details));
        let node;

        if (target) {
            if (source) {
                node = this.insertEventNode(source, copy, target, type);
                this._addAction(
                    {
                        action: "insert-event",
                        target: target.id,
                        source: source.id,
                        details,
                    },
                    newData
                );
            }
        } else {
            node = this.addShift(source, copy, type);
            this._addAction(
                {
                    action: "add-event",
                    target: source.id,
                    source: node.id,
                    details,
                },
                newData
            );
        }

        return node;
    }

    insertDecision(source, details, newData = true, target) {
        const copy = JSON.parse(JSON.stringify(details));
        let node;

        if (target) {
            node = this.insertDecisionNode(source, copy, target, "decision");
            this._addAction(
                {
                    action: "insert-decision",
                    target: target.id,
                    source: source.id,
                    details,
                },
                newData
            );
        } else {
            node = this.addShift(source, copy, "decision");
            this._addAction(
                {
                    action: "add-decision",
                    target: source.id,
                    source: node.id,
                    details,
                },
                newData
            );
        }

        return node;
    }

    addDecision(addTo, details, newData = true) {
        const copy = JSON.parse(JSON.stringify(details));
        let node;
        const hasChild = addTo.children.length > 0;
        if (addTo.type === "me" || hasChild) {
            node = this.addShift(addTo, copy, "decision");
        } else {
            node = this.addTail(addTo, copy, "decision");
        }

        this._addAction(
            {
                action: "add-decision",
                target: addTo.id,
                source: node.id,
                details,
            },
            newData
        );

        return node;
    }

    _shiftNode(node, shiftDate = new Date()) {
        this._forwardTraverse(
            node,
            (childNode, parentNode) => {
                if (childNode.depth <= parentNode.depth) {
                    const index =
                        this.buckets[childNode.depth].indexOf(childNode);
                    this.buckets[childNode.depth].splice(index, 1);

                    childNode.depth += 1;
                    childNode.dateShifted = shiftDate;
                    this._createBucketsToDepth(childNode.depth);
                    this.buckets[childNode.depth].push(childNode);
                }
            },
            () => {
                //where do we use this???
                // const stop = traverseNode.depth > traverseParent.depth;
            }
        );
    }

    insertEventNode(addTo, details, target, type) {
        if (type) {
            details["type"] = type;
        }

        details.decisionLevel = addTo.metadata.decisionLevel;
        details.addedTo = addTo;
        details.decisionLevel +=
            details.type === "decision" || addTo.type !== "decision" ? 1 : 0;
        details.decisionContext =
            addTo.type === "decision" || addTo.type === "me"
                ? addTo
                : addTo.metadata.decisionContext;
        if (details.type !== "decision") {
            this.formatDetails(details);
        }

        const node = this._createNode(details);
        node.depth = addTo.depth + 1;

        let toShift = false;

        node.children = addTo.children.filter((childNode) => {
            if (childNode.id === target.id) {
                const i = childNode.parents.indexOf(addTo);
                childNode.parents.splice(i, 1);
                childNode.parents.push(node);
                if (
                    node.type === "decision" &&
                    childNode.type !== "decision" &&
                    childNode.metadata.decisionLevel <=
                        node.metadata.decisionLevel
                ) {
                    childNode.metadata.decisionLevel += 1;
                }

                if (childNode.depth <= node.depth) {
                    toShift = true;
                }
                return childNode;
            }
            return childNode;
        });

        const bucketDepth = node.depth;

        node.parents = [addTo];
        addTo.children = addTo.children.map((childNode) => {
            if (childNode.id === target.id) {
                return (childNode = node);
            }
            return childNode;
        });

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        node.breadth = this.buckets[bucketDepth].length - 1;

        if (toShift || node.type === "decision") {
            this._shiftNode(node);
        }

        this.links.push({ source: addTo, target: node });
        this._renderNodes();

        return node;
    }

    insertCollectionNode(targets, details, newData = true) {
        const meNode = this.nodes[0];

        details.decisionLevel = meNode.metadata.decisionLevel;
        details.addedTo = meNode;
        details.type = "decision";
        details.decisionContext = meNode;

        const node = this._createNode(details);
        node.parents = targets;

        const targetIds = [];

        targets.forEach((target) => {
            if (node.depth == null || node.depth <= target.depth) {
                node.depth = target.depth + 1;
            }
            targetIds.push(target.id);
            target.children.push(node);
        });

        const bucketDepth = node.depth;

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        node.breadth = this.buckets[bucketDepth].length;

        this._renderNodes();

        const newDetails = {
            decisionLevel: details.decisionLevel,
            id: details.id,
            name: details.name,
            rating: details.rating,
            type: details.type,
        };

        this._addAction(
            {
                action: "collect-decision",
                target: targetIds,
                source: node.id,
                newDetails,
            },
            newData
        );

        return node;
    }

    insertDecisionNode(addTo, details, target, type) {
        if (type) {
            details["type"] = type;
        }

        details.decisionLevel = addTo.metadata.decisionLevel;
        details.addedTo = addTo;
        details.decisionLevel +=
            details.type === "decision" || addTo.type !== "decision" ? 1 : 0;
        details.decisionContext =
            addTo.type === "decision" || addTo.type === "me"
                ? addTo
                : addTo.metadata.decisionContext;
        if (details.type !== "decision") {
            this.formatDetails(details);
        }

        const node = this._createNode(details);
        node.depth = addTo.depth + 1;

        let toShift = false;

        node.children = addTo.children.filter((childNode) => {
            if (childNode.id === target.id) {
                const i = childNode.parents.indexOf(addTo);
                childNode.parents.splice(i, 1);
                childNode.parents.push(node);
                if (
                    node.type === "decision" &&
                    childNode.type !== "decision" &&
                    childNode.metadata.decisionLevel <=
                        node.metadata.decisionLevel
                ) {
                    childNode.metadata.decisionLevel += 1;
                }

                if (childNode.depth <= node.depth) {
                    toShift = true;
                }
                return childNode;
            }

            return childNode;
        });

        const bucketDepth = node.depth;

        node.parents = [addTo];
        addTo.children = addTo.children.map((childNode) => {
            if (childNode.id === target.id) {
                return (childNode = node);
            }
            return childNode;
        });

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        node.breadth = this.buckets[bucketDepth].length;

        if (toShift || node.type === "decision") {
            this._shiftNode(node);
        }

        //this.links.push({source:addTo, target:node});
        this._renderNodes();

        return node;
    }

    addShift(addTo, details, type) {
        if (type) {
            details["type"] = type;
        }

        details.decisionLevel = addTo.metadata.decisionLevel;
        details.addedTo = addTo;
        details.decisionLevel +=
            details.type === "decision" || addTo.type !== "decision" ? 0 : 1;
        details.decisionContext =
            addTo.type === "decision" || addTo.type === "me"
                ? addTo
                : addTo.metadata.decisionContext;
        if (details.type !== "decision") {
            this.formatDetails(details);
        }

        const node = this._createNode(details);
        node.depth = addTo.depth + 1;

        let toShift = false;
        node.children = addTo.children.map((childNode) => {
            const i = childNode.parents.indexOf(addTo);
            childNode.parents.splice(i, 1);
            childNode.parents.push(node);
            if (
                node.type === "decision" &&
                childNode.type !== "decision" &&
                childNode.metadata.decisionLevel <= node.metadata.decisionLevel
            ) {
                childNode.metadata.decisionLevel += 1;
            }

            if (childNode.depth <= node.depth) {
                toShift = true;
            }
            return childNode;
        });
        const bucketDepth = node.depth;

        node.parents = [addTo];
        addTo.children = [node];

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        node.breadth = 0; //this.buckets[bucketDepth].length-1;

        if (toShift || node.type === "decision") {
            this._shiftNode(node);
        }

        this.links.push({ source: addTo, target: node });
        this._renderNodes();

        return node;
    }

    connectNode(addTo, details, type) {
        if (type) {
            details["type"] = type;
        }

        addTo.map((node) => {
            if (node.type === "decision") {
                details.decisionLevel +=
                    details.type === "decision" || addTo.type !== "decision"
                        ? 0
                        : 1;
            }
            return node;
        });

        // addTo.map((node) => {
        //     if (node.type === "decision") {
        //         details.decisionLevel +=
        //     }
        // })
        // details.decisionLevel = addTo.metadata.decisionLevel;
        details.addedTo = addTo;
        // details.decisionLevel +=
        //     details.type === "decision" || addTo.type !== "decision" ? 0 : 1;
        details.decisionContext =
            addTo.type === "decision" || addTo.type === "me"
                ? addTo[0]
                : addTo[0].metadata.decisionContext;
        if (details.type !== "decision") {
            this.formatDetails(details);
        }

        const node = this._createNode(details);
        node.depth = addTo.depth + 1;

        let toShift = false;
        node.parents = addTo;

        const bucketDepth = node.depth;
        addTo.map((child) => {
            return (child.children = [node]);
        });

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        node.breadth = 0; //this.buckets[bucketDepth].length-1;

        if (toShift || node.type === "decision") {
            this._shiftNode(node);
        }

        this.links.push({ source: addTo, target: node });
        this._renderNodes();

        return node;
    }

    addSlide = (addTo, details, type, newData = true) => {
        const copy = JSON.parse(JSON.stringify(details));

        const node = this.connectNode(addTo, copy, type);

        const children = node.children.map((child) => {
            return child;
        });
        // const parents = node.parents.map((parent) => {
        //     return parent;
        // });
        const depth = node.depth;
        const breadth = node.breadth;

        node.metadata.addedTo = addTo;
        node.metadata.rootEvent = addTo[0].metadata.rootEvent;
        node.metadata.decisionLevel = addTo[0].metadata.decisionLevel;
        node.metadata.decisionContext = addTo[0].metadata.decisionContext;

        addTo[0].metadata.decisionContext = node;
        addTo[0].metadata.decisionLevel += 1;

        node.children = [addTo]; //.children.map((child) => {return child});;
        node.parents = addTo[0].parents.map((parent) => {
            return parent;
        });
        node.depth = addTo.depth;
        node.breadth = addTo.breadth;

        addTo.children = children;
        addTo.parents = [node];
        addTo.depth = depth;
        addTo.breadth = breadth;

        node.parents.forEach((parent) => {
            const i = parent.children.indexOf(addTo);
            parent.children.splice(i, 1, node);
        });

        addTo.children.forEach((child) => {
            const i = child.parents.indexOf(node);
            child.parents.splice(i, 1, addTo);
        });

        this._forwardTraverse(node, (traverseNode) => {
            if (
                traverseNode.metadata.decisionContext === addTo ||
                traverseNode.metadata.decisionContext ===
                    node.metadata.decisionContext
            ) {
                traverseNode.metadata.decisionContext = node;
            }
        });

        this._renderNodes();

        this._addAction(
            {
                action: "add-slide",
                target: addTo.id,
                source: node.id,
                details: details,
                type,
            },
            newData
        );

        return node;
    };

    addSplit = (addTo, details, type) => {
        if (type) {
            details["type"] = type;
        }

        details.addedTo = addTo;

        details.decisionLevel += details.type === "decision" ? 1 : 0;
        details.decisionContext =
            addTo.type === "decision" || addTo.type === "me"
                ? addTo
                : addTo.metadata.decisionContext;
        if (details.type !== "decision") {
            this.formatDetails(details);
        }

        const node = this._createNode(details);
        node.depth = addTo ? addTo.depth + 1 : 0;
        const bucketDepth = node.depth;

        addTo.children.forEach((child) => {
            child.metadata.rootEvent = false;
        });

        const endOfDecision = [];
        this._forwardTraverse(
            addTo,
            () => {
                // noop
            },
            (traverseNode, traverseParent) => {
                return this._endOfContext(
                    addTo,
                    node,
                    traverseNode,
                    traverseParent,
                    ["sibling"]
                );
            },
            (traverseNode) => {
                endOfDecision.push(traverseNode);
            }
        );

        endOfDecision.forEach((endNode) => {
            if (endNode.parents.indexOf(node) === -1) {
                endNode.parents.push(node);
            }
            if (node.children.indexOf(endNode) === -1) {
                node.children.push(endNode);
            }
        });

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        node.breadth = this.buckets[bucketDepth].length - 1;

        if (addTo) {
            node.parents.push(addTo);
            addTo.addChild(node);
        }

        this._renderNodes();

        return node;
    };

    _endOfContext(addTo, node, traverseNode, traverseParent, ignore) {
        const internalContext =
            addTo.metadata.decisionLevel >=
                traverseNode.metadata.decisionLevel &&
            addTo.metadata.decisionContext !==
                traverseParent.metadata.decisionContext;

        const externalContext =
            addTo.metadata.decisionLevel > traverseNode.metadata.decisionLevel;

        const siblingContext =
            ((addTo.type !== "decision" && traverseNode.type === "decision") ||
                (addTo.type === "decision" &&
                    traverseNode.type !== "decision")) &&
            addTo.metadata.decisionContext ===
                traverseNode.metadata.decisionContext &&
            ignore.indexOf("sibling") === -1;

        const decisionContext =
            addTo.type === "decision" &&
            traverseNode.type === "decision" &&
            addTo.decisionLevel >= traverseNode.decisionLevel;

        const joinContext =
            addTo === traverseNode.metadata.decisionContext &&
            node.type !== "decision" &&
            traverseNode.metadata.addedTo === addTo &&
            traverseNode.parents.indexOf(addTo) === -1 &&
            traverseNode.type !== "decision";

        return (
            internalContext ||
            externalContext ||
            joinContext ||
            siblingContext ||
            decisionContext
        );
    }

    addTail(addTo, details, type) {
        if (type) {
            details["type"] = type;
        }

        details.decisionLevel = addTo.metadata.decisionLevel;
        details.addedTo = addTo;
        details.rootEvent = addTo.type === "decision" || addTo.type === "me";
        details.decisionLevel += details.type === "decision" ? 0 : 1;
        details.decisionContext =
            addTo.type === "decision" || addTo.type === "me"
                ? addTo
                : addTo.metadata.decisionContext;
        if (details.type !== "decision") {
            this.formatDetails(details);
        }

        const node = this._createNode(details);

        let endDepth = 0;
        const leafNodes = [],
            stopNodes = [];
        if (addTo.children.length > 0) {
            this._forwardTraverse(
                addTo,
                () => {
                    // noop
                },
                (traverseNode, traverseParent) => {
                    return this._endOfContext(
                        addTo,
                        node,
                        traverseNode,
                        traverseParent
                    );
                },
                (traverseNode, traverseParent) => {
                    if (leafNodes.indexOf(traverseParent) === -1) {
                        leafNodes.push(traverseParent);
                    }
                    if (stopNodes.indexOf(traverseNode) === -1) {
                        stopNodes.push(traverseNode);
                    }
                }
            );
            if (leafNodes.length === 0 && stopNodes.length === 0) {
                this._forwardTraverse(
                    addTo,
                    () => {
                        // noop
                    },
                    (traverseNode) => {
                        return traverseNode.children.length === 0;
                    },
                    (traverseNode) => {
                        if (leafNodes.indexOf(traverseNode) === -1) {
                            leafNodes.push(traverseNode);
                        }
                    }
                );
            }
        } else {
            leafNodes.push(addTo);
        }

        node.breadth = addTo.breadth;
        // const shiftDate = new Date();
        if (leafNodes.length === 0) {
            node.parents = [addTo];
            addTo.children = [node];
            endDepth = addTo.depth;
        } else {
            leafNodes.forEach((leafNode) => {
                leafNode.children = [node];
                node.parents.push(leafNode);
                endDepth =
                    leafNode.depth >= endDepth ? leafNode.depth : endDepth;
            });
        }

        const bucketDepth = endDepth + 1;
        node.depth = bucketDepth;

        stopNodes.forEach((stopNode) => {
            stopNode.parents = [node];
            node.children.push(stopNode);
        });
        this._shiftNode(node);

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);

        this._renderNodes();

        return node;
    }

    _deleteNode(node) {
        node.metadata.selected = false;
        node.metadata.blocked = false;
        node.metadata.locked = false;

        node.children.forEach((childNode) => {
            const i = childNode.parents.indexOf(node);
            childNode.parents.splice(i, 1);
        });
        node.parents.forEach((parentNode) => {
            const i = parentNode.children.indexOf(node);
            parentNode.children.splice(i, 1);
        });
        const bucketIndex = this.buckets[node.depth].indexOf(node);
        this.buckets[node.depth].splice(bucketIndex, 1);

        const nodeIndex = this.nodes.indexOf(node);
        this.nodes.splice(nodeIndex, 1);

        d3.select(`g#entity-${node.id}`).remove();
        delete this.tree[node.id];
    }

    removeNode(node, newData = true) {
        if (!node) {
            return;
        }

        const lastChildOf = [];
        const subTreeLeaves = [];
        const toDelete = [];

        this._addAction(
            {
                action: "remove-node",
                source: node.id,
            },
            newData
        );

        node.parents.forEach((parentNode) => {
            if (
                parentNode.children.length === 1 &&
                parentNode.children[0] === node
            ) {
                lastChildOf.push(parentNode);
            }
        });

        this._forwardTraverse(
            node,
            (_node) => {
                toDelete.push(_node);
            },
            (_node) => {
                return (
                    _node.metadata.decisionLevel <=
                        node.metadata.decisionLevel ||
                    (_node.metadata.decisionLevel ===
                        node.metadata.decisionLevl &&
                        _node.metadata.type === "decision")
                );
            },
            (_node) => {
                if (subTreeLeaves.indexOf(_node) === -1) {
                    subTreeLeaves.push(_node);
                }
            }
        );

        toDelete.push(node);
        toDelete.forEach((_node) => {
            this._deleteNode(_node);
        });

        lastChildOf.forEach((parentNode) => {
            parentNode.children = subTreeLeaves;
        });

        if (this._resetFocus) {
            this._resetFocus();
        }
        this._renderNodes(false);
    }

    _createBucketsToDepth(depth) {
        while (this.buckets.length <= depth) {
            this.buckets.push([]);
        }
    }

    _forwardTraverse(
        node,
        action = () => {
            // noop
        },
        stop = () => {
            return false;
        },
        stopAction = null
    ) {
        node.children.forEach((childNode) => {
            if (stop(childNode, node)) {
                if (stopAction) {
                    stopAction(childNode, node);
                }
            } else {
                action(childNode, node);
                this._forwardTraverse(childNode, action, stop, stopAction);
            }
        });
    }

    _shiftSubTreeDepth(node) {
        node.children.forEach((childNode) => {
            this._shiftSubTreeDepth(childNode);
        });

        if (!node.shifted) {
            const oldIndex = this.buckets[node.depth].indexOf(node);
            this.buckets[node.depth].splice(oldIndex, 1);
            node.depth += 1;
            this.buckets[node.depth].push(node);
            node.shifted = true;
        }
    }

    _resetShift() {
        this.nodes.forEach((node) => {
            node.shifted = false;
        });
    }

    _removeMismatchedLocation(branches) {
        const branchesToRemove = [];
        branches.forEach((branch, branchIndex) => {
            let location,
                toRemove = false;
            branch.forEach((node) => {
                if (location == null && node.location && node.location !== "") {
                    location = node.location;
                }

                if (
                    location &&
                    node.location &&
                    node.location !== "" &&
                    location !== node.location
                ) {
                    toRemove = true;
                }
            });

            if (toRemove) {
                branchesToRemove.unshift(branchIndex);
            }
        });

        branchesToRemove.forEach((branchIndex) => {
            branches.splice(branchIndex, 1);
        });
    }

    _findBranches(node) {
        const allBranches = [];
        let lockedIn;
        node.children.forEach((childNode) => {
            if (childNode.metadata.locked) {
                lockedIn = childNode;
            }
        });
        node.children.forEach((childNode) => {
            if (!lockedIn || lockedIn === childNode) {
                const childBranches = this._findBranches(childNode);
                childBranches.forEach((branch) => {
                    allBranches.push(branch);
                });
            }
        });

        const formattedMetadata = Object.assign(node.metadata);
        formattedMetadata["node"] = node;

        if (allBranches.length === 0) {
            allBranches.push([formattedMetadata]);
        } else {
            allBranches.forEach((branch) => {
                branch.push(formattedMetadata);
            });
        }

        return allBranches;
    }

    _removeDuplicateBranches(branches) {
        const filteredBranches = branches;
        return filteredBranches;
    }

    formatDetails = (eventDetails) => {
        if (eventDetails.cadence) {
            eventDetails.cadence = eventDetails.cadence.toLowerCase();
        }
        if (eventDetails.income) {
            eventDetails.type = "income";
            eventDetails.value = Math.abs(parseFloat(eventDetails.income));
        } else if (eventDetails.cost) {
            eventDetails.type = "expense";
            eventDetails.value = Math.abs(parseFloat(eventDetails.cost)) * -1;
        } else if (eventDetails["mortgage term"]) {
            eventDetails.type = "mortgage";
            eventDetails.value =
                Math.abs(parseFloat(eventDetails.payments)) * -1;
        } else if (eventDetails.payments) {
            eventDetails.type = "loan";
            eventDetails.value =
                Math.abs(parseFloat(eventDetails.payments)) * -1;
        } else if (eventDetails.account) {
            eventDetails.type = "house";
            eventDetails.value = 0;
        }
    };

    addEvent(addTo, details, newData = true) {
        //add shift or tail pending if it's middle
        const copy = JSON.parse(JSON.stringify(details));
        let node;
        if (addTo.type === "decision") {
            node = this.addTail(addTo, copy);
        } else {
            node = this.addShift(addTo, copy);
        }

        this._addAction(
            {
                action: "add-event",
                target: addTo.id,
                source: node.id,
                details,
            },
            newData
        );

        return node;
    }

    addStart = (details, newData = true) => {
        const userDetails = JSON.parse(localStorage.getItem("loggedInUser"));
        const copy = JSON.parse(JSON.stringify(details));

        copy.type = "me";
        copy.name = userDetails
            ? userDetails.name.split(" ")[0]
            : "Whatifi-Guest";
        copy.children = [];
        copy.parents = [];
        copy.partner = [];
        copy.decisionLevel = 0;

        const node = this._createNode(copy);

        this._addAction(
            {
                action: "add-me",
                source: node.id,
                details,
            },
            newData
        );

        node.depth = 0;
        const bucketDepth = node.depth;

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);
        this._renderNodes();

        return node;
    };

    addOption = (addTo, details, newData = true, type) => {
        let newType;
        if (type === "decision") {
            newType = "decision";
        } else {
            if (details.income) {
                newType = "income";
            } else if (details.cost) {
                newType = "expense";
            } else if (details.loan) {
                newType = "loan";
            } else if (details.downpayment) {
                newType = "mortgage";
            } else if (details.account) {
                newType = "bank";
            }
        }

        const copy = JSON.parse(JSON.stringify(details));
        let containsDecisionChildren = false;
        if (addTo) {
            addTo.children.forEach((childNode) => {
                if (
                    childNode.type === "decision" &&
                    childNode.metadata.decisionContext !== addTo
                ) {
                    containsDecisionChildren = true;
                }
            });
        }

        let node;
        if (containsDecisionChildren) {
            node = this.addShift(addTo, copy);
        } else {
            node = this.addSplit(addTo, copy, newType);
        }

        this._addAction(
            {
                action: "add-option",
                target: addTo.id,
                source: node.id,
                type: type,
                details,
            },
            newData
        );

        return node;
    };

    _findDecisionAfter(node, start) {
        if (start == null) {
            start = node;
        }
        if (node.children.length === 0) {
            return null;
        }

        let decision;
        node.children.forEach((childNode) => {
            if (
                childNode.metadata.type === "decision" &&
                childNode.metadata.decisionLevel <= start.metadata.decisionLevel
            ) {
                decision = childNode;
            }
        });

        if (!decision) {
            const tree = node.children.map((child) => {
                return this._findDecisionAfter(child, start);
            });

            tree.forEach((child) => {
                if (child) {
                    decision = child;
                }
            });
        }

        return decision;
    }

    addNode(addTo) {
        const node = new TreeNode("randomEntity", { type: "income" }, this);
        node.depth = addTo.depth + 1;
        node.minimized = addTo.minimized;
        node.minified = addTo.minified;
        const bucketDepth = node.depth;

        this.nodes.push(node);
        this.tree[node.id] = node;

        this._createBucketsToDepth(bucketDepth);
        this.buckets[bucketDepth].push(node);

        node.parents.push(addTo);
        addTo.addChild(node);

        this.links.push({ source: addTo, target: node });

        this._renderNodes();

        return node;
    }

    selectNode(node) {
        this.nodes.forEach((_node) => {
            _node.metadata.selected = node ? _node === node : false;
        });

        this._renderNodes();
    }

    _updateNodeStatus() {
        console.warn(
            "treeManager._updateNodeStatus allows g rect nodes to be undefined"
        );
        d3.selectAll("g rect")
            .attr("ignored", (n) => {
                return n && n.metadata ? n.metadata.blocked === true : false;
            })
            .attr("selected", (n) => {
                return n && n.metadata ? n.metadata.selected === true : false;
            })
            .attr("locked", (n) => {
                return n && n.metadata ? n.metadata.locked === true : false;
            })
            .attr("grayed", (n) => {
                return n && n.metadata ? n.metadata.grayed === true : false;
            });
        d3.selectAll("g text").attr("locked", (n) => {
            return n && n.metadata ? n.metadata.locked === true : false;
        });
        d3.selectAll("g text").attr("grayed", (n) => {
            return n && n.metadata ? n.metadata.grayed === true : false;
        });
    }

    toggleLockedNode(node) {
        node.metadata.locked =
            node.metadata.locked == null ? true : !node.metadata.locked;

        // if (node.metadata.locked) {
        //     node.metadata.blocked = false;
        // }
        if (node.metadata.locked) {
            node.parents.forEach((parentNode) => {
                parentNode.children.forEach((childNode) => {
                    if (childNode !== node) {
                        childNode.metadata.locked = false;
                        childNode.metadata.grayed = true;
                    }
                });
            });
        } else {
            node.parents.forEach((parentNode) => {
                parentNode.children.forEach((childNode) => {
                    childNode.metadata.grayed = false;
                });
            });
        }

        this._renderNodes();
    }

    toggleBlockNode(node) {
        node.metadata.blocked =
            node.metadata.blocked == null ? true : !node.metadata.blocked;

        // if (node.metadata.blocked) {
        //     node.metadata.locked = false;
        // }

        this._renderNodes();
    }

    _obtainSubTreeBreadth(node, setDate) {
        let treeBreadth = 0;

        node.children.forEach((childNode) => {
            if (
                (childNode.breadth < node.breadth &&
                    childNode.parents.length > 1) ||
                childNode.decisionLevel < node.decisionLevel
            ) {
                return;
            }
            const childBreadth = this._balanceDepth(
                childNode.id,
                node.breadth,
                treeBreadth,
                setDate
            );
            treeBreadth +=
                childNode.metadata.decisionLevel !== 0 ? childBreadth : 0;
        });

        return treeBreadth;
    }

    _trimBuckets() {
        const toDelete = [];
        this.buckets.forEach((bucket, i) => {
            if (bucket.length === 0) {
                toDelete.push(i);
            }
        });
        toDelete.sort().reverse();
        toDelete.forEach((i) => {
            this.buckets.splice(i, 1);
        });
        this.buckets.forEach((bucket, i) => {
            bucket.forEach((node) => {
                node.depth = i;
            });
        });
    }

    _balanceDepth(nodeId, parentBreadth, siblingPlacement, setDate) {
        if (this.buckets.length === 0) {
            return;
        }

        if (!setDate) {
            setDate = new Date();
        }

        const node = nodeId ? this.obtainNode(nodeId) : this.buckets[0][0];

        if (!node) {
            return;
        }

        if (parentBreadth != null && siblingPlacement != null) {
            const breadth = parentBreadth + siblingPlacement;
            if (node.setDate && setDate === node.setDate) {
                if (breadth < node.breadth) {
                    node.breadth = breadth;
                }
            } else {
                node.breadth = breadth;
                node.setDate = setDate;
            }
        } else {
            node.breadth = 0;
            node.setDate = setDate;
        }

        let treeBreadth = this._obtainSubTreeBreadth(node, setDate);
        let breadth = treeBreadth > 0 ? treeBreadth : 1;
        return breadth;
    }

    _siblingBreadth(node) {
        const siblingBreadth = {};
        node.parents.forEach((parent) => {
            parent.children.forEach((child) => {
                if (siblingBreadth[child.depth] == null) {
                    siblingBreadth[child.depth] = 0;
                }

                siblingBreadth[child.depth] += 1;
            });
        });

        return siblingBreadth;
    }

    _centerBreadth() {
        const now = new Date();
        this.nodes.forEach((node) => {
            if (
                node.metadata.type === "me" ||
                node.metadata.decisionLevel === 0
            ) {
                const subTreeBreadth = this._obtainSubTreeBreadth(node, now);

                this._forwardTraverse(
                    node,
                    (childNode) => {
                        childNode.maxBreadth = childNode.metadata.rootEvent
                            ? childNode.metadata.decisionContext.maxBreadth
                            : subTreeBreadth;
                        //childNode.maxBreadth = subTreeBreadth;
                    },
                    (childNode) => {
                        return (
                            childNode.metadata.decisionLevel <=
                            node.metadata.decisionLevel
                        );
                    }
                );
            }
        });
    }

    _generateLinks() {
        this.links = [];
        this.nodes.forEach((node) => {
            node.children.forEach((child) => {
                this.links.push({ source: node, target: child });
            });
        });
    }

    obtainAbsoluteBreadth(node) {
        let absoluteBreadth = 0,
            siblingBreadth = 0;
        this.nodes.forEach((bucketNode) => {
            if (
                bucketNode.depth < node.depth &&
                bucketNode.breadth <= node.breadth
            ) {
                absoluteBreadth += 1;
            }
            if (
                bucketNode.depth === node.depth &&
                bucketNode.breadth < node.breadth
            ) {
                const _siblingBreadth = this._obtainSubTreeBreadth(
                    bucketNode,
                    true
                );
                siblingBreadth += _siblingBreadth;
            }
        });

        return absoluteBreadth + siblingBreadth;
    }
}

export default TreeManager;

import { getEvent } from "actions/getNodeEntityActions";
import { allocationObject } from "Components/Registry/Allocation";
import { cacObject } from "Components/Registry/CAC";
import { campaignObject } from "Components/Registry/Campaign";
import { constraintObject } from "Components/Registry/Constraint";
import { contractObject } from "Components/Registry/Contract";
import { customerChurnObject } from "Components/Registry/CustomerChurn";
import { customerGrowthObject } from "Components/Registry/CustomerGrowth";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import { debtRepaymentObject } from "Components/Registry/Debt Repayment";
import { growthObject } from "Components/Registry/Growth";
import { modifierObject } from "Components/Registry/Modifier";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { resourceObject } from "Components/Registry/Resource";
import { revenueObject } from "Components/Registry/Revenue";
import { salespersonObject } from "Components/Registry/Salesperson";
import { segmentObject } from "Components/Registry/Segment";
import { utilizationObject } from "Components/Registry/Utilization";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";

const removeAllOutgoingDependencies = (
    dependencyMap: DependencyMapSchema,
    sourceEntityId: string
) => {
    const outgoingDependencies = Object.keys(
        dependencyMap[sourceEntityId]?.outgoingDependencies ?? []
    );
    for (const outgoingDependencyId of outgoingDependencies) {
        const targetEntityDependencies = {
            ...dependencyMap[outgoingDependencyId],
        };
        const incomingDependencies = {
            ...targetEntityDependencies.incomingDependencies,
        };

        delete incomingDependencies[sourceEntityId];
        targetEntityDependencies.incomingDependencies = incomingDependencies;
        dependencyMap[outgoingDependencyId] = targetEntityDependencies;
    }
};

const removeAllIncomingDependencies = (
    dependencyMap: DependencyMapSchema,
    sourceEntityId: string
) => {
    const incomingDependencies = Object.keys(
        dependencyMap[sourceEntityId]?.incomingDependencies ?? []
    );
    for (const incomingDependencyId of incomingDependencies) {
        const targetEntityDependencies = {
            ...dependencyMap[incomingDependencyId],
        };
        const outgoingDependencies = {
            ...targetEntityDependencies.outgoingDependencies,
        };

        delete outgoingDependencies[sourceEntityId];
        targetEntityDependencies.outgoingDependencies = outgoingDependencies;
        dependencyMap[incomingDependencyId] = targetEntityDependencies;
    }

    delete dependencyMap[sourceEntityId];
};

const createOutgoingDependencies = (
    dependencyMap: DependencyMapSchema,
    eventId: string,
    entityId: string,
    depEventId: string,
    depEntityIds: string[]
) => {
    if (
        !dependencyMap[entityId]?.incomingDependencies &&
        !dependencyMap[entityId]?.outgoingDependencies
    ) {
        dependencyMap[entityId] = {
            incomingDependencies: {},
            outgoingDependencies: {},
        };
    }

    const sourceEntityDependencies = { ...dependencyMap[entityId] };
    const outgoingEntityDependencies = {
        ...sourceEntityDependencies.outgoingDependencies,
    };
    for (const depEntityId of depEntityIds) {
        outgoingEntityDependencies[depEntityId] = depEventId;

        if (!dependencyMap[depEntityId]) {
            dependencyMap[depEntityId] = {
                incomingDependencies: {},
                outgoingDependencies: {},
            };
        }

        const targetEntityDependencies = { ...dependencyMap[depEntityId] };
        const incomingEntityDependencies = {
            ...targetEntityDependencies.incomingDependencies,
        };
        incomingEntityDependencies[entityId] = eventId;
        targetEntityDependencies.incomingDependencies =
            incomingEntityDependencies;
        dependencyMap[depEntityId] = targetEntityDependencies;
    }
    sourceEntityDependencies.outgoingDependencies = outgoingEntityDependencies;
    dependencyMap[entityId] = sourceEntityDependencies;
};

const removeOutgoingDependencies = (
    dependencyMap: DependencyMapSchema,
    sourceEntity: string,
    targetEntityIds: string[]
) => {
    const sourceEntityDependencies = { ...dependencyMap[sourceEntity] };
    for (const targetEntityId of targetEntityIds) {
        const outgoingDependencies = {
            ...sourceEntityDependencies.outgoingDependencies,
        };
        if (outgoingDependencies[targetEntityId]) {
            delete outgoingDependencies[targetEntityId];
            sourceEntityDependencies.outgoingDependencies =
                outgoingDependencies;
        }

        const targetEntityDependencies = { ...dependencyMap[targetEntityId] };
        const incomingDependencies = {
            ...targetEntityDependencies.incomingDependencies,
        };
        if (incomingDependencies[sourceEntity]) {
            delete incomingDependencies[sourceEntity];
            targetEntityDependencies.incomingDependencies =
                incomingDependencies;
            dependencyMap[targetEntityId] = targetEntityDependencies;
        }
    }
    dependencyMap[sourceEntity] = sourceEntityDependencies;
};

export const deleteEntityFromMap = (
    dependencyMap: DependencyMapSchema,
    sourceEntityId: string
) => {
    removeAllOutgoingDependencies(dependencyMap, sourceEntityId);
    removeAllIncomingDependencies(dependencyMap, sourceEntityId);
};

export const handleDependencies = (
    dependencyMap: DependencyMapSchema,
    parsedCustomer: { eventId: string; entityIds: string[] },
    sourceEventId: string,
    sourceEntityId: string,
    oldTargetEventId: string,
    oldTargetEntityIds: string[]
) => {
    let newCustomerIds = parsedCustomer?.entityIds;
    let oldCustomerIds = oldTargetEntityIds;

    if (oldTargetEventId && oldCustomerIds.length === 0) {
        const oldEventData = getEvent(oldTargetEventId);
        oldEventData
            ? (oldCustomerIds = oldEventData?.entities?.map(
                  (entity: { id: string; active: boolean }) => entity?.id
              ))
            : (oldCustomerIds = []);
    }
    if (oldTargetEventId && oldCustomerIds?.length !== 0) {
        removeOutgoingDependencies(
            dependencyMap,
            sourceEntityId,
            oldCustomerIds
        );
    }

    if (parsedCustomer?.eventId && parsedCustomer?.entityIds?.length === 0) {
        const newEventData = getEvent(parsedCustomer?.eventId);
        newEventData
            ? (newCustomerIds = newEventData?.entities?.map(
                  (entity: { id: string; active: boolean }) => entity?.id
              ))
            : (newCustomerIds = []);
    }
    createOutgoingDependencies(
        dependencyMap,
        sourceEventId,
        sourceEntityId,
        parsedCustomer?.eventId,
        newCustomerIds
    );
};

// gross quick fix
const getEntityDependencyData = (
    entity: EntitySchema
): [string[], string[][]] => {
    const eventId: string[] = [];
    const targetEntityIds: string[][] = [];
    switch (entity.type) {
        case revenueObject.constant():
            // Create helper to organise this logic
            let revCustomerIds = entity.data.customerIds;
            if (
                entity.data.customerEventId &&
                entity.data.customerIds.length === 0
            ) {
                const customerEvent = getEvent(entity.data.customerEventId);
                customerEvent
                    ? (revCustomerIds = customerEvent.entities.map(
                          (entity) => entity.id
                      ))
                    : (revCustomerIds = []);
            }
            eventId.push(
                entity.data.customerEventId,
                entity.data.selectedUnitCost,
                entity.data.segmentEventId
            );
            targetEntityIds.push(revCustomerIds, entity.data.unitCostIds, [
                entity.data.segmentEntityId,
            ]);
            break;
        case outboundSalesObject.constant():
        case cacObject.constant():
        case segmentObject.constant():
        case customerChurnObject.constant():
        case customerGrowthObject.constant():
            let growthCustomerIds = entity.data.customerIds;
            if (
                entity.data.customerEventId &&
                entity.data.customerIds.length === 0
            ) {
                const customerEvent = getEvent(entity.data.customerEventId);
                customerEvent
                    ? (growthCustomerIds = customerEvent.entities.map(
                          (entity) => entity.id
                      ))
                    : (growthCustomerIds = []);
            }
            eventId.push(entity.data.customerEventId);
            targetEntityIds.push(growthCustomerIds);
            break;
        case customerTransferObject.constant():
            eventId.push(
                entity.data.targetCustomerEventId,
                entity.data.sourceCustomerEventId
            );
            targetEntityIds.push(
                entity.data.targetCustomerIds,
                entity.data.sourceCustomerIds
            );
            break;
        case contractObject.constant():
            eventId.push(entity.data.businessEventId);
            targetEntityIds.push(entity.data.businessIds);
            break;
        case debtRepaymentObject.constant():
            eventId.push(entity.data.debtEventId);
            targetEntityIds.push(entity.data.debtIds);
            break;
        case resourceObject.constant():
        case utilizationObject.constant():
        case constraintObject.constant():
        case growthObject.constant():
        case modifierObject.constant():
            eventId.push(entity.data.selectedEventId);
            targetEntityIds.push(entity.data.selectedIds);
            break;
        case allocationObject.constant():
            eventId.push(entity.data.segmentEventId);
            targetEntityIds.push(entity.data.segmentIds);
            break;
        case campaignObject.constant():
            eventId.push(entity.data.websiteVisEventId);
            targetEntityIds.push(entity.data.websiteVisIds);
            break;
        case salespersonObject.constant():
            eventId.push(entity.data.employeeEventId);
            targetEntityIds.push(entity.data.employeeIds);
            for (const salesTarget of entity.data.salesTargets) {
                eventId.push(salesTarget.outboundSales.eventId);
                targetEntityIds.push(salesTarget.outboundSales.entityIds);
            }
            break;
        default:
            break;
    }
    return [eventId, targetEntityIds];
};

export const handleDuplicatedEntity = (
    dependencyMap: DependencyMapSchema,
    eventId: string,
    entity: EntitySchema
) => {
    const [targetEventIds, targetEntityIdArrays] =
        getEntityDependencyData(entity);
    if (targetEventIds?.length > 0) {
        for (let i = 0; i < targetEventIds.length; i++) {
            createOutgoingDependencies(
                dependencyMap,
                eventId,
                entity.id,
                targetEventIds[i],
                targetEntityIdArrays[i]
            );
        }
    }
};

export const updateScenarioDependencyMap = (
    dependencyMap: DependencyMapSchema,
    oldEntityId: string,
    newEntityId: string
) => {
    if (dependencyMap[oldEntityId]) {
        const sourceEntityDependencies = { ...dependencyMap[oldEntityId] };

        const incomingDependencies = {
            ...sourceEntityDependencies.incomingDependencies,
        };
        for (const incomingDependencyId of Object.keys(incomingDependencies)) {
            const targetEntityDependencies = {
                ...dependencyMap[incomingDependencyId],
            };
            const targetOutgoingDependencies = {
                ...targetEntityDependencies.outgoingDependencies,
            };

            const eventId = targetOutgoingDependencies[oldEntityId];
            delete targetOutgoingDependencies[oldEntityId];
            targetOutgoingDependencies[newEntityId] = eventId;

            targetEntityDependencies.outgoingDependencies =
                targetOutgoingDependencies;
            dependencyMap[incomingDependencyId] = targetEntityDependencies;
        }

        const outgoingDependencies = {
            ...sourceEntityDependencies.outgoingDependencies,
        };
        for (const outgoingDependencyId of Object.keys(outgoingDependencies)) {
            const targetEntityDependencies = {
                ...dependencyMap[outgoingDependencyId],
            };
            const targetIncomingDependencies = {
                ...targetEntityDependencies.incomingDependencies,
            };

            const eventId = targetIncomingDependencies[oldEntityId];
            delete targetIncomingDependencies[oldEntityId];
            targetIncomingDependencies[newEntityId] = eventId;

            targetEntityDependencies.incomingDependencies =
                targetIncomingDependencies;
            dependencyMap[outgoingDependencyId] = targetEntityDependencies;
        }

        sourceEntityDependencies.incomingDependencies = incomingDependencies;
        sourceEntityDependencies.outgoingDependencies = outgoingDependencies;
        delete dependencyMap[oldEntityId];
        dependencyMap[newEntityId] = sourceEntityDependencies;
    }
};

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import AccountImportCard from "../Card/accountImportCard";

// Import Connection Image
import accountImportConnectionImage from "Assets/_optionDescriptionIcons/Account Import white.jpg";

// Import Coloured PNGs
import accountImportDarkGrey from "../../Assets/_eventNodeTypes/accountImportSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import AccountImportInputExperimental from "Components/InputContainer/AccountImportInputExperimental/AccountImportInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const accountImportObject = {
    constant: function () {
        return accountImportObject_Constant();
    },

    name: function () {
        return "Account Import";
    },

    checkInput: function (state) {
        return accountImportObject_CheckInput(state);
    },

    svg: function () {
        return accountImportObject_Svg();
    },

    eventNodeMenu: function (focus) {
        return accountImportObject_EventNodeMenu(focus);
    },

    indexText: function () {
        return accountImportObject_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return accountImportObject_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return accountImportObject_OptionDescription();
    },

    colouredPngs: function () {
        return accountImportObject_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return accountImportObject_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return AccountImport_GenerateDisplayFieldData();
    },
};

/**
 *
 * @param {*} entitiesMap
 * @returns Returns true if all necessary fields / dependancies are filled out
 */
const accountImportObject_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const accountImportObject_Constant = () => {
    return "88a05121-3f9e-48be-bfa5-b5521e6c736d";
};

// EFFECTS: Returns svg for event
const accountImportObject_Svg = () => {
    return accountImportDarkGrey;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const accountImportObject_EventNodeMenu = (focus) => {
    return <AccountImportCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const accountImportObject_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const accountImportObject_Icons = () => {
    let pngs = new Map([
        ["black", accountImportDarkGrey],
        ["blue", accountImportDarkGrey],
        ["white", accountImportDarkGrey],
        ["grey", accountImportDarkGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const accountImportObject_InputComponentInfoPassing = (minProps) => {
    return <AccountImportInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const accountImportObject_OptionDescription = () => {
    const tags = ["Option"];
    const type = accountImportObject.name();
    const description = "Import event description placeholder.";
    const usedForText = "Import event used for text placeholder";
    const connection = "";
    const connectionImage = accountImportConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const accountImportObject_SideModalImage = () => {
    return sideImage;
};

const AccountImport_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import * as React from "react";
import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { useMemo } from "react";
import styles from "./index.module.css";
import { LedgerMetadata } from "reducers/typesSchema/allAccountLedgersSchema";

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
    ({ theme }) => {
        return {
            "& .MuiPaper-root": {
                borderRadius: 6,
                marginTop: theme.spacing(1),
                minWidth: 180,
                overflow: "visible",
                color: "rgb(55, 65, 81)",
                boxShadow:
                    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                "& .MuiMenu-list": {
                    padding: "4px 0",
                },
            },
        };
    }
);

interface AccountSelectionMenuProps {
    parentAccountId: string;
    anchorEl: null | HTMLElement;
    handleClose: any;
    currentSelection: string;
    callback: any;
}

export const AccountSelectionMenu = ({
    parentAccountId = "",
    anchorEl,
    handleClose,
    currentSelection,
    callback,
}: AccountSelectionMenuProps) => {
    const allAccountData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );

    const open = Boolean(anchorEl);

    const recurseToTopAccount = (id: string) => {
        const accounts: string[] = [];
        let currentAcc = allAccountData[id];
        accounts.unshift(currentAcc.id);
        while (currentAcc.parents.length > 0) {
            currentAcc = allAccountData[currentAcc.parents[0]];
            accounts.unshift(currentAcc.id);
        }
        return accounts;
    };

    const updateSelection = (accountData: LedgerMetadata) => {
        const requiredData = {
            name: accountData.name,
            ids: recurseToTopAccount(accountData.id),
        };
        handleClose();
        callback(requiredData);
    };

    const filteredAccountData = useMemo(() => {
        if (parentAccountId) {
            const allChildAccounts = allAccountData?.[parentAccountId]?.children
                ?.map((accountId) => allAccountData?.[accountId])
                ?.filter(
                    (account) => account?.id && account?.type !== "shared"
                );
            return allChildAccounts;
        } else {
            const allTopLevelAccounts = Object.values(allAccountData)?.filter(
                (account) =>
                    account?.parents?.length === 0 && account?.type !== "shared"
            );
            return allTopLevelAccounts;
        }
    }, [allAccountData, parentAccountId]);

    return (
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                "aria-labelledby": "demo-customized-button",
            }}
            PaperProps={{
                style: {
                    maxHeight: "300px",
                    overflowY: "auto",
                },
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            open={open}
            onClose={handleClose}
        >
            {filteredAccountData?.length !== 0 &&
                filteredAccountData?.map((account, i) => (
                    <MenuItem
                        key={account?.id ?? i}
                        onClick={() => updateSelection(account)}
                        autoFocus={false}
                    >
                        <span
                            className={`${
                                currentSelection === account?.id
                                    ? `${styles.itemName} ${styles.itemName__active}`
                                    : styles.itemName
                            }`}
                        >
                            {account?.name ?? ""}
                        </span>
                    </MenuItem>
                ))}
            {filteredAccountData?.length === 0 && (
                <div className={styles.noAccountsContainer}>
                    <span className={styles.noAccounts}>
                        No Existing Accounts
                    </span>
                </div>
            )}
        </StyledMenu>
    );
};

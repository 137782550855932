import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

const houseInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "address"
        | "price"
        | "mlsNumber"
        | "url"
        | "rating"
        | "appreciationRate"
        | "primaryResidence"
        | "newlyBuiltHome"
        | "location"
        | "lat"
        | "lng",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "address":
            data.address = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "price":
            data.price = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "mlsNumber":
            data.mlsNumber = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "url":
            data.url = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "appreciationRate":
            data.appreciationRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "primaryResidence":
            data.primaryResidence = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "newlyBuiltHome":
            data.newlyBuiltHome = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
};

export default houseInputsHandler;

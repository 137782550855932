//import type { CancelTokenSource } from "axios";
import type { EventManager } from "Events";
import type { AppThunk } from "store";
import axios from "axios";
import { config } from "../config";
import * as Sentry from "@sentry/browser";
import {
    parseCalculatedThreadsResponse,
    resetCalculatedThreads,
} from "./calculatedThreadsActions";
import { upsertLoadingStates } from "./loadingStatesActions";
import { setHighlightedThread } from "./scenario";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { throwError } from "helpers/swalError";

const BATCH_COUNT = 5;

export const highlightThread = (threadId: string): AppThunk => {
    return (dispatch, getState) => {
        const manager = getState().scenario?.manager as EventManager;
        const threads = manager.scenarioThreads || [];
        const threadModel = threads.find((t) => t.signature === threadId);
        const curHighlightedThread = getState().scenario?.highlightedThread;

        if (!threadModel) {
            console.error(
                `Thread ID - ${threadId} - does not exist in manager.scenarioThreads`
            );
            return;
        } else if (curHighlightedThread === threadModel) {
            return;
        }

        // TODO: Redux should only contain the threadId for the highlighted thread, NOT the entire model; This way, the model is always kept in a singular location - the manager (or redux)
        dispatch(setHighlightedThread(threadModel));
    };
};

const determineNewHighlightThread = (): AppThunk => {
    return (dispatch, getState) => {
        const highlightedThread = getState().scenario?.highlightedThread;
        const calculatedThreads = getState().calculatedThreads;

        let threadId = "";
        if (
            highlightedThread &&
            calculatedThreads[highlightedThread.signature]
        ) {
            threadId = highlightedThread.signature;
        }
        if (!threadId) {
            threadId =
                Object.keys(calculatedThreads).sort((a, b) =>
                    a.localeCompare(b)
                )[0] || "";
        }

        dispatch(highlightThread(threadId));
    };
};

function _createGroups(arr, numGroups) {
    //const perGroup = Math.ceil(arr.length / numGroups);
    const perGroup = BATCH_COUNT;
    return new Array(numGroups)
        .fill("")
        .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
}

let currentCalculationScenario = "";
export const calculateScenario = (
    threads,
    entities: EntitiesSchema,
    ledgers,
    requestDate: string,
    duration: number,
    scenarioId: string,
    calculationStartDate: string,
    scenarioStartDate: string,
    overrides,
    instances,
    modifiers
): AppThunk<Promise<any[] | null>> => {
    return async (dispatch) => {
        // Step 1: Set the cancel token to a unique identifier
        currentCalculationScenario = `${scenarioId}_${requestDate}`;

        // Step 2: Clear out current calculatedThreads
        dispatch(upsertLoadingStates({ isCalculatedThreadsLoading: true }));
        dispatch(resetCalculatedThreads());

        // Step 3: Separate scenario into batches
        const _batches = _createGroups(
            threads,
            Math.ceil(threads.length / BATCH_COUNT)
        );

        // Step 3: Concurrently send out calculation requests
        const promises = _batches.map((batch) =>
            dispatch(
                calculateThread(
                    batch,
                    entities,
                    ledgers,
                    requestDate,
                    duration,
                    scenarioId,
                    calculationStartDate,
                    scenarioStartDate,
                    overrides,
                    instances,
                    modifiers
                )
            )
        );
        let response: any[] | null = null;
        try {
            response = await Promise.all(promises);
        } catch (error) {
            console.error("Error while calculating scenario:", error);
        } finally {
            // If not cancelled, set isCalculatedThreadsLoading to false
            // Step 4: Check if this calculation is still valid
            const _uniqueCalculationIdentifier = `${scenarioId}_${requestDate}`;
            const isCancelled =
                _uniqueCalculationIdentifier !== currentCalculationScenario;
            if (!isCancelled) {
                // Step 5: Calculation is valid, set loading state to false
                dispatch(
                    upsertLoadingStates({
                        isCalculatedThreadsLoading: false,
                    })
                );
                // Step 6: Highlight a single thread
                dispatch(determineNewHighlightThread());

                // Step 7: Reset the cancel token
                currentCalculationScenario = "";
            }
        }
        return response;
    };
};

function calculateThread(
    threads,
    entities: EntitiesSchema,
    ledgers,
    requestDate: string,
    duration: number,
    scenarioId: string,
    calculationStartDate: string,
    scenarioStartDate: string,
    overrides,
    instances,
    modifiers
): AppThunk<Promise<any>> {
    return async (dispatch) => {
        const requestHeaderDE3 = () => {
            const loggedInUserString = localStorage.getItem("loggedInUser");
            const loggedInUser =
                loggedInUserString !== null
                    ? JSON.parse(loggedInUserString)
                    : {};
            const requestHeader = {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${
                        loggedInUser.token || "WHATIFI-GUEST"
                    }`,
                    accept: "*/*",
                },
            };

            return requestHeader;
        };

        let response: any | null = null;
        try {
            response = await axios.post(
                `${config.decisionEngine}`,
                JSON.stringify({
                    threads,
                    entities,
                    ledgers,
                    duration,
                    scenarioId,
                    calculationStartDate,
                    scenarioStartDate,
                    overrides,
                    instances,
                    modifiers,
                }),
                {
                    ...requestHeaderDE3(),
                }
            );
            try {
                const calculatedThreads = { ...response.data };
                delete calculatedThreads.scenarioId;
                delete calculatedThreads.runtime;
                delete calculatedThreads.requestDate;
                const _uniqueCalculationIdentifier = `${scenarioId}_${requestDate}`;
                const isCancelled =
                    _uniqueCalculationIdentifier !== currentCalculationScenario;
                if (!isCancelled) {
                    dispatch(parseCalculatedThreadsResponse(calculatedThreads));
                }
            } catch (e) {
                // console.log("Expected errors?", e)
            }
        } catch (err: any) {
            Sentry.withScope((scope) => {
                scope.setExtras({ threads, entities, requestDate });
                Sentry.captureException(err);
            });
            throwError("warning", "Scenario Failed to Calculate.");
            console.error("Calculate Scenario Error", err?.response?.data);
        }
        return response;
    };
}

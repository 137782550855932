// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { setShowCellRowModal } from "actions/modalActions";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    EntityName,
    RequiredStar,
    ModalRowHalf,
    EntityCard,
    SelectDropDown,
    GenericButton,
} from "../Components";
import { averageOrderObject } from "Components/Registry/AverageOrder";

export default function AverageOrderInputView({
    entitiesMap,
    currentEntity,
    eventData,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    createSchedule,
}) {
    const entityData = entitiesMap?.[currentEntity]?.data;
    const cadences = ["daily", "monthly"];

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={averageOrderObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={averageOrderObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[averageOrderObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <GenericButton
                            label={entityData?.schedule ? "Update" : "Create"}
                            disabled={!entitiesMap?.[currentEntity]?.cadence}
                            onClick={() => {
                                setShowCellRowModal({
                                    show: true,
                                    type: "schedule",
                                    entityData: entitiesMap?.[currentEntity],
                                    createSchedule,
                                });
                            }}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.cadence}
                            options={cadences}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Frequency"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

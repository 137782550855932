import React, { Component } from "react";
import {
    Name,
    InputButtons,
    Description,
    SelectDropDown,
    Value,
} from "./Components";
import { throwError } from "../../helpers/swalError";
import { getDefaultName } from "../../helpers";
import Calendar from "../Calendar";
import { cadenceOptions } from "../../helpers/constants";
import { getPresentableDependencies } from "../../helpers/nodeDependencyDetectionHelpers";
import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
import { mortgageObject } from "Components/Registry/Mortgage";

export const LumpSumPaymentDependencies = [mortgageObject.constant()]; // todo: add loan later

export default class LumpSumPaymentsInput extends Component {
    constructor(props) {
        super(props);

        let isValid = false;
        const mortgages = {};
        if (!this.props.edit) {
            isValid = getPresentableDependencies(
                mortgages,
                mortgageObject.constant(),
                this.props
            );
        }

        this.state = {
            type: lumpSumPaymentObject.constant(),
            name: getDefaultName(lumpSumPaymentObject.constant(), this.props),
            tag: `@${lumpSumPaymentObject.constant()}`,
            description: "",
            value: undefined,
            start: null,
            end: null,
            mortgages: mortgages,
            mortgage: null,
            nodeType: "",
            cadence: "",
            valid: isValid,
            decisionEngineName: lumpSumPaymentObject.constant(),
            associatedIds: [],
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState(
                {
                    ...this.props.editData,
                },
                () => {
                    const mortgages = {};
                    getPresentableDependencies(
                        mortgages,
                        mortgageObject.constant(),
                        this.props
                    );
                    this.setState({ mortgages: mortgages });
                }
            );
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onHandleSubmitValues = () => {
        const passedCheck = lumpSumPaymentObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                curNodes: undefined,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }

        this.onHandleMouseLeave();
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;

        const newState = {
            ...this.state,
            isFilled: false,
            curNodes: undefined,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }

        this.onHandleMouseLeave();
        this.props.manager.calculate();
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    //This is how we do the onchange values just put it in one function so it would be easy to locate stuff when needed
    //all you need to pass is onChangeInput to all the components that we have e.g. Name, Description etc. and it should be good. if you are not sure then look into components and check what is the expected props :)
    onChangeInput = (e, id, _star) => {
        let value = !e ? null : e.target.value;
        switch (id) {
            case "name":
                this.setState({ name: value });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "nodeType":
                this.setState({ nodeType: value });
                this.setState({ associatedIds: this.state.nodeTypes[value] });
                break;
            case "mortgage":
                this.setState({ mortgage: value });
                if (value && this.state.mortgages[value]) {
                    this.setState({
                        associatedIds: [this.state.mortgages[value]],
                    });
                } else {
                    this.setState({ associatedIds: [] });
                }
                break;
            case "cadence":
                if (value === "one-time") {
                    this.setState({ end: this.state.start });
                }
                this.setState({ cadence: value });
                break;
            case "payment":
                this.setState({ value: value });
                break;
            default:
        }
    };

    onHandleMouseLeave = () => {
        this.props.onhandleFocusedInput(null);
    };

    onHandleDate = (startDate, endDate) => {
        if (this.state.cadence === "one-time") {
            endDate = startDate;
        }
        this.setState({ start: startDate, end: endDate });
    };

    render() {
        const { name, description, value, start, end, cadence } = this.state;
        const { edit } = this.props;

        let passedCheck = lumpSumPaymentObject.checkInput(this.state);

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={lumpSumPaymentObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">LUMP SUM PAYMENT</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>

                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={this.state.mortgage}
                            options={Object.keys(this.state.mortgages) || []}
                            className="termsContainer"
                            id="mortgage"
                            label="Choose a Mortgage"
                        />
                    </div>

                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <div className="CalendarContainer">
                            <Calendar
                                onHandleDate={this.onHandleDate}
                                startDate={start}
                                endDate={end}
                            />
                        </div>
                    </div>

                    <div className="ModalRowSpaceBetween">
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <Value
                                label="Payment"
                                onChangeInput={this.onChangeInput}
                                value={value}
                                id="payment"
                            />
                        </div>

                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={cadence}
                                options={cadenceOptions}
                                className="select-dropdown"
                                id="cadence"
                                label="Frequency"
                            />
                        </div>
                    </div>
                </div>

                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </div>
        );
    }
}

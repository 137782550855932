// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import { AccountExpressionCard } from "../Card/accountExpressionCard";

// Import Connection Image (if exists)
import CustomerConnection from "Assets/_optionDescriptionIcons/Customer, Growth, Churn white.jpg";

// Import Coloured PNGs

// TODO Grab pngs from Michelle or Jon
import accountExpressionIcon from "Assets/_eventNodeTypes/accountExpression.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import AccountExpressionInputExperimental from "Components/InputContainer/AccountExpressionInputExperimental/AccountExpressionInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - customerObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - customerObject.checkInput.call(customerObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const accountExpressionObject = {
    constant: function () {
        return AccountExpression_Constant();
    },

    name: function () {
        return "Account Expression";
    },

    checkInput: function (state) {
        return AccountExpression_CheckInput(state);
    },

    svg: function () {
        return AccountExpression_Svg();
    },

    eventNodeMenu: function (focus) {
        return AccountExpression_EventNodeMenu(focus);
    },

    indexText: function () {
        return AccountExpression_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return AccountExpression_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return AccountExpression_OptionDescription();
    },

    colouredPngs: function () {
        return AccountExpression_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return AccountExpression_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return AccountExpression_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const AccountExpression_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (entity?.data?.customDateRange && !entity.startDate) return false;
        if (!entity.data.targetAccountName) return false;
        if (!entity.data.expressionStepsIds.length) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const AccountExpression_Constant = () => {
    return "2cd4f0cd-1306-49a7-9bea-d9ba9e83b644";
};

// EFFECTS: Returns black png / svg for event
const AccountExpression_Svg = () => {
    return accountExpressionIcon;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const AccountExpression_EventNodeMenu = (focus) => {
    return <AccountExpressionCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const AccountExpression_IndexText = () => {
    return "Account Expession default copy";
};

// EFFECTS: Returns all icons for event ??
const AccountExpression_Icons = () => {
    let pngs = new Map([
        ["black", accountExpressionIcon],
        ["blue", accountExpressionIcon],
        ["white", accountExpressionIcon],
        ["grey", accountExpressionIcon],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const AccountExpression_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const AccountExpression_InputComponentInfoPassing = (minProps) => {
    return <AccountExpressionInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const AccountExpression_OptionDescription = () => {
    const tags = ["Option"];
    const type = accountExpressionObject.name();
    const description =
        "A Customer Event contains all of the information to track the number of customers, in a group or cohort, over time. A Customer Event can contain multiple cohorts.";
    const usedForText =
        "Use the Customer Event to create general cohorts of Users, Subscribers or Clients for downstream calculations. For example, any month-over-month Growth, Churn, or Software-as-a-Service type calculations will need an upstream Customer Event to calculate. To calculate or track Revenue or Expenses associated with a specific, individual client or user, we recommend using the Income, Debit/Credit or Contract Event.";
    const connection = "";
    const connectionImage = CustomerConnection;
    const learnMoreLink = "https://www.investopedia.com/terms/c/customer.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const AccountExpression_SideModalImage = () => {
    return sideImage;
};

const AccountExpression_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

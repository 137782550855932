import SettingsContainer from "./SettingsContainer";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";

const mapStateToProps = (state) => {
    return {
        loadedScenario: state.scenario.loadedScenario,
        userScenarios: state.scenario.userScenarios,
        sharedScenario: state.scenario.sharedScenario,
    };
};

const mapDispatchToProps = {
    showNotification: actions.showNotification,
    getUserScenarios: actions.getUserScenarios,
    fetchSharedScenarios: actions.fetchSharedScenarios,
    openPortal: actions.openPortal,
    getSubscription: actions.getSubscription,
    getUserData: actions.getUserData,
    saveUserDetails: actions.saveUserDetails,
    updateUserData: actions.updateUserData
};

const Settings = connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsContainer);

export default Settings;

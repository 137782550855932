import type {
    // EventsTemplatesInputSchema,
    MenusTemplatesSchema,
} from "./typesSchema/menusTemplatesSchema";
import {
    SET_MENUS_TEMPLATES,
    UPSERT_MENUS_TEMPLATES,
} from "../actions/menusTemplatesActions";

const initialState: MenusTemplatesSchema = {};

export default function menusTemplatesReducer(
    state: MenusTemplatesSchema = initialState,
    action: { type: string; payload?: any }
): MenusTemplatesSchema {
    switch (action.type) {
        case SET_MENUS_TEMPLATES:
            return { ...action.payload };
        case UPSERT_MENUS_TEMPLATES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

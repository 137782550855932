import { cloneDeep } from "lodash";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import { yearlyToMonthlyRate } from "./growthInputsHandler";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const customerTransfer2InputsHandler = (
    value: string,
    id:
        | "entityName"
        | "sourceEntity"
        | "targetCustomer"
        | "transferType"
        | "maxTransferRate"
        | "constantTransferRate"
        | "interpolate"
        | "startDate"
        | "transferRate"
        | "period"
        | "customEffectPeriod"
        | "revertOnEnd"
        | "modType"
        | "cadence",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    index?: number
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    switch (id) {
        case "targetCustomer":
            const newDependencies: {
                [key: string]: {
                    eventId: string;
                    entityIds: string[];
                };
            } = { ...(currentEntityObject.dependencies ?? {}) };
            if (!value || value === "Select Customer") {
                newDependencies[id] = {
                    eventId: "",
                    entityIds: [],
                };
            } else {
                newDependencies[id] = JSON.parse(value);
            }
            currentEntityObject.dependencies = newDependencies;
            newEntitiesMap[currentEntity] = currentEntityObject;

            break;
        case "sourceEntity":
            const newDependencies2: {
                [key: string]: {
                    eventId: string;
                    entityIds: string[];
                };
            } = { ...(currentEntityObject.dependencies ?? {}) };
            if (!value || value === "Select Customer") {
                newDependencies2[id] = {
                    eventId: "",
                    entityIds: [],
                };
            } else {
                newDependencies2[id] = JSON.parse(value);
            }

            currentEntityObject.dependencies = newDependencies2;
            newEntitiesMap[currentEntity] = currentEntityObject;

            break;
        case "constantTransferRate":
            if (Number(value) < 0) break;
            data.constantTransferRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "modType":
            data.modType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "interpolate":
            data.interpolate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            if (value === "") {
                currentEntityObject.cadence = "";
                newEntitiesMap[currentEntity] = currentEntityObject;
            } else {
                currentEntityObject.cadence = value;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "transferType":
            data.transferType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "customEffectPeriod":
            data.customEffectPeriod = !data.customEffectPeriod;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "revertOnEnd":
            data.revertOnEnd = !data.revertOnEnd;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "maxTransferRate":
            if (Number(value) < 0) break;
            data.maxTransferRate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
        case "period":
            if (!index && index !== 0) break;

            const transferSegs = cloneDeep(data.transferSegments);
            const seg = transferSegs[index];

            seg[id] = value;

            if (id == "period") {
                const currentTransferRate =
                    seg.period == "monthly" ? seg.monthlyRate : seg.yearlyRate;
                seg.rateDisplay = String(
                    Math.round(Number(currentTransferRate) * 100) / 100
                );
            }

            transferSegs[index] = seg;
            data.transferSegments = transferSegs;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "transferRate":
            if (!index && index !== 0) break;
            if (Number(value) < 0) break;

            const transferSegments = cloneDeep(data.transferSegments);
            const segment = transferSegments[index];

            segment.rateDisplay = value;

            if (segment.period == "monthly") {
                segment.monthlyRate = Number(value);
            } else if (segment.period == "yearly") {
                segment.yearlyRate = Number(value);
                segment.monthlyRate =
                    yearlyToMonthlyRate(Number(value) * 0.01) * 100;
            }

            transferSegments[index] = segment;
            data.transferSegments = transferSegments;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

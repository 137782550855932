import { createContext } from "react";

export const HoveredButtonIdContext = createContext("");
HoveredButtonIdContext.displayName = "HoveredButtonIdContext";

export const SetHoveredButtonIdContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetHoveredButtonIdContext.displayName = "SetHoveredButtonIdContext";

import { createContext } from "react";

export const CurrentSelectionContext = createContext<string>("");
CurrentSelectionContext.displayName = "CurrentSelectionContext";

export const BaseContainerHeightContext = createContext<number>(0);
BaseContainerHeightContext.displayName = "BaseContainerHeightContext";

export const SelectedTopLevelAccountContext = createContext<string>("");
SelectedTopLevelAccountContext.displayName = "SelectedTopLevelAccountContext";

export const SetSelectedTopLevelAccountContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetSelectedTopLevelAccountContext.displayName =
    "SetSelectedTopLevelAccountContext";

export const SelectedSubAccountContext = createContext<string>("");
SelectedSubAccountContext.displayName = "SelectedSubAccountContext";

export const SetSelectedSubAccountContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetSelectedSubAccountContext.displayName = "SetSelectedSubAccountContext";

export const SelectedLeafAccountContext = createContext<string>("");
SelectedLeafAccountContext.displayName = "SelectedLeafAccountContext";

export const SetSelectedLeafAccountContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetSelectedLeafAccountContext.displayName = "SetSelectedLeafAccountContext";

export const SelectedLeafSubAccountContext = createContext<string>("");
SelectedLeafSubAccountContext.displayName = "SelectedLeafSubAccountContext";

export const SetSelectedLeafSubAccountContext = createContext<
    React.Dispatch<React.SetStateAction<string>>
>(() => {
    //no op
});
SetSelectedLeafSubAccountContext.displayName =
    "SetSelectedLeafSubAccountContext";

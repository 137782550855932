import React from "react";

import SegmentCard from "../Card/SegmentCard";

import SegmentConnectionImage from "Assets/_optionDescriptionIcons/Segment, Allocation white.jpg";

import segmentSvg from "../../Assets/_eventNodeTypes/segmentSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import SegmentInputExperimental from "Components/InputContainer/SegmentInputExperimental/SegmentInputExperimental";

export const segmentObject = {
    constant: function () {
        return Segment_Constant();
    },

    name: function () {
        return "Segment";
    },

    checkInput: function (state) {
        return Segment_CheckInput(state);
    },

    svg: function () {
        return Segment_Svg();
    },

    eventNodeMenu: function (focus) {
        return Segment_EventNodeMenu(focus);
    },

    indexText: function () {
        return Segment_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Segment_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Segment_OptionDescription();
    },

    colouredPngs: function () {
        return Segment_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Segment_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Segment_GenerateDisplayFieldData();
    },
};

// Helper functions for Event

const Segment_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.customerEventId) return false;
        if (!entity.data.segments.length) return false;

        for (const segment of entity.data.segments) {
            if (!segment.allocation) return false;
            if (segment.name === "") return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

const Segment_Constant = () => {
    return "beff5239-6aac-45e8-96a3-5ff1b1fe8172";
};

const Segment_Svg = () => {
    return segmentSvg;
};

const Segment_EventNodeMenu = (focus) => {
    return <SegmentCard data={focus} />;
};

const Segment_IndexText = () => {
    return "Segment no copy text";
};

const Segment_Icons = () => {
    let pngs = new Map([
        ["black", segmentSvg],
        ["blue", segmentSvg],
        ["white", segmentSvg],
        ["grey", segmentSvg],
    ]);
    return pngs;
};

const Segment_InputComponentInfoPassing = (minProps) => {
    return <SegmentInputExperimental {...minProps} />;
};

const Segment_OptionDescription = () => {
    const tags = ["Option"];
    const type = segmentObject.name();
    const description = "Segment no copy text";
    const usedForText = "Segment no copy text";
    const connection = "";
    const connectionImage = SegmentConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/r/revenue.asp#:~:text=Revenue%20is%20the%20income%20generated,subtracted%20to%20determine%20net%20income.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Segment_SideModalImage = () => {
    return sideImage;
};

const Segment_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

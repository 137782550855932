// @ts-nocheck
import { utilizationObject } from "Components/Registry/Utilization";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    ModalRowHalf,
    SelectEntities,
    Value,
} from "../Components";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

export default function UtilizationInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    onHandleSubmit,
    handleOnChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    updateAccount,
}) {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={utilizationObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={utilizationObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[utilizationObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            id="resource"
                            onChangeInput={handleOnChange}
                            value={
                                entitiesMap?.[currentEntity]?.data
                                    ?.selectedEvent ?? {}
                            }
                            nodes={Object.values(
                                entitiesMap[currentEntity]?.data?.resources ??
                                    []
                            )}
                            label="Select Resource"
                            showAll={false}
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <Value
                            label={"Amount"}
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.data?.value}
                            id="value"
                            entityData={entitiesMap?.[currentEntity]}
                            startAdornment={""}
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={
                                entitiesMap?.[currentEntity]?.data
                                    ?.accountName ?? ""
                            }
                            topLevelFilters={{
                                "8762235d-676a-4ef5-be5e-9c5a27b301da": true,
                            }}
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <InputDropDownButton
                            callback={updateAccount}
                            initialValue={
                                entitiesMap?.[currentEntity]?.data
                                    ?.contraAccountName
                            }
                            topLevelFilters={{
                                "3ebf3eb4-6cdf-4889-8796-435cb9bf0939": true,
                            }}
                            contraAccount={true}
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                {entitiesMap?.[currentEntity]?.data?.lastUpdated && (
                    <div className="last-updated">
                        Last Updated:{" "}
                        {entitiesMap?.[currentEntity]?.data?.lastUpdated || ""}
                    </div>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import moment from "moment";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";

type YyyyMmDdDate = `${number}-${number}-${number}`;

type AmortizedCadence =
    | "annually"
    | "quarterly"
    | "monthly"
    | "semi-monthly"
    | "weekly"
    | "bi-weekly"
    | "daily";

export const stringToYyyyMmDdDate = (date: string): YyyyMmDdDate | null => {
    const momentDate = moment(date);

    if (!momentDate.isValid()) {
        return null;
    }

    return momentDate.format("YYYY-MM-DD") as YyyyMmDdDate;
};

export const stringToAmortizedCadence = (
    cadenceString: string
): AmortizedCadence | null => {
    switch (cadenceString) {
        case "annually":
        case "quarterly":
        case "monthly":
        case "semi-monthly":
        case "weekly":
        case "bi-weekly":
        case "daily":
            return cadenceString as AmortizedCadence;
        default:
            return null;
    }
};

export const getAmortizedEndDate = (
    start: YyyyMmDdDate,
    cadence: AmortizedCadence,
    amortizationPeriod: number
): YyyyMmDdDate => {
    const endDate = moment(start);

    switch (cadence) {
        case "annually":
            endDate.add(amortizationPeriod, "years");
            break;
        case "quarterly":
            endDate.add(amortizationPeriod, "quarters");
            break;
        case "monthly":
            endDate.add(amortizationPeriod, "months");
            break;
        case "semi-monthly":
            endDate.add(amortizationPeriod - 1, "months");
            endDate.add(16, "days");

            break;
        case "weekly":
            endDate.add(amortizationPeriod, "weeks");
            break;
        case "bi-weekly":
            endDate.add(amortizationPeriod, "weeks");
            break;
        case "daily":
            endDate.add(amortizationPeriod, "days");
            break;
    }

    return endDate.format("YYYY-MM-DD") as YyyyMmDdDate;
};

export const getNumPayments = (
    cadence: AmortizedCadence,
    amortizationPeriod: number
): number => {
    switch (cadence) {
        case "annually":
        case "quarterly":
        case "monthly":
        case "weekly":
        case "daily":
            return amortizationPeriod;
        case "semi-monthly":
            return amortizationPeriod * 2;
        case "bi-weekly":
            return Math.floor(amortizationPeriod / 2);
    }
};

export const getAmortizationPeriod = (
    start: YyyyMmDdDate,
    end: YyyyMmDdDate,
    cadence: AmortizedCadence
): number => {
    const startDate = moment(start);
    const endDate = moment(end);

    switch (cadence) {
        case "annually":
            return endDate.diff(startDate, "years", true);
        case "quarterly":
            return endDate.diff(startDate, "quarters", true);
        case "monthly":
            return endDate.diff(startDate, "months", true);
        case "semi-monthly":
            return endDate.diff(startDate, "months", true) * 2;
        case "weekly":
            return endDate.diff(startDate, "weeks", true);
        case "bi-weekly":
            return endDate.diff(startDate, "weeks", true) * 2;
        case "daily":
            return endDate.diff(startDate, "days", true);
    }
};

/* A generic handler for cadence changes for events that use amortized calculation.
 * If the cadence changes, then the behaviour should be that the start and end dates
 * remain.
 * This only works for events
 *
 */
export const handleCadenceChange = (
    entity: EntitySchema,
    newCadence: AmortizedCadence
): EntitySchema => {
    const newEntity = { ...entity };
    const entityData = { ...entity.data };

    const startDate = stringToYyyyMmDdDate(newEntity.startDate);
    const endDate = stringToYyyyMmDdDate(newEntity.endDate ?? ""); // TODO: this can be undefined. find a better way to handle

    if (startDate == null || endDate == null) {
        console.warn("handleCadenceChange: failed to parse start or end dates");

        newEntity.cadence = newCadence;
        newEntity.data = entityData;

        return newEntity;
    }

    entityData.amortizationPeriod = getAmortizationPeriod(
        startDate,
        endDate,
        newCadence
    );

    newEntity.cadence = newCadence;

    newEntity.data = entityData;
    return newEntity;
};

export const handleAmortizationPeriodChange = (
    entity: EntitySchema,
    newAmortizationPeriod: number
): EntitySchema => {
    const newEntity = { ...entity };
    const entityData = { ...entity.data };

    const startDate = stringToYyyyMmDdDate(newEntity.startDate);
    const cadence = stringToAmortizedCadence(newEntity.cadence);

    if (startDate == null || cadence == null) {
        console.warn(
            "handleAmortizationPeriodChange: failed to parse start date or cadence"
        );

        entityData.amortizationPeriod = newAmortizationPeriod;
        newEntity.data = entityData;

        return newEntity;
    }

    const newEndDate = getAmortizedEndDate(
        startDate,
        cadence,
        newAmortizationPeriod
    );

    entityData.amortizationPeriod = newAmortizationPeriod;
    entityData.amortizedEnd = newEndDate;
    entityData.numPayments = getNumPayments(cadence, newAmortizationPeriod);

    newEntity.endDate = newEndDate;

    newEntity.data = entityData;
    return newEntity;
};

export const handleAmortizedStartDateChange = (
    entity: EntitySchema,
    newStartDate: YyyyMmDdDate
): EntitySchema => {
    const newEntity = { ...entity };
    const entityData = { ...entity.data };

    const cadence = stringToAmortizedCadence(newEntity.cadence);
    const endDate = stringToYyyyMmDdDate(newEntity.endDate ?? ""); // TODO: this can be undefined. find a saner way to handle this.

    if (cadence == null || endDate == null) {
        console.warn(
            "handleAmortizedStartDateChange: failed to parse end date or cadence"
        );

        newEntity.startDate = newStartDate;
        newEntity.data = entityData;

        return newEntity;
    }

    const newAmortizationPeriod = getAmortizationPeriod(
        newStartDate,
        endDate,
        cadence
    );

    entityData.amortizationPeriod = newAmortizationPeriod;
    entityData.numPayments = getNumPayments(cadence, newAmortizationPeriod);

    newEntity.startDate = newStartDate;

    newEntity.data = entityData;
    return newEntity;
};

export const handleAmortizedEndDateChange = (
    entity: EntitySchema,
    newEndDate: YyyyMmDdDate
): EntitySchema => {
    const newEntity = { ...entity };
    const entityData = { ...entity.data };

    const cadence = stringToAmortizedCadence(newEntity.cadence);
    const startDate = stringToYyyyMmDdDate(newEntity.startDate);

    if (cadence == null || startDate == null) {
        console.warn(
            "handleAmortizedStartDateChange: failed to parse end date or cadence"
        );

        newEntity.endDate = newEndDate;
        entityData.amortizedEnd = newEndDate;
        newEntity.data = entityData;

        return newEntity;
    }

    const newAmortizationPeriod = getAmortizationPeriod(
        startDate,
        newEndDate,
        cadence
    );

    entityData.amortizationPeriod = newAmortizationPeriod;
    entityData.numPayments = getNumPayments(cadence, newAmortizationPeriod);
    entityData.amortizedEnd = newEndDate;

    newEntity.endDate = newEndDate;

    newEntity.data = entityData;
    return newEntity;
};

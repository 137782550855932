import React, { Component } from "react";
import "./Card.css";
// import starFilledSvg from "../../Assets/star_filled.svg";
// import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import moment from "moment";
import _ from "lodash";
import { getRelevantEntities } from "actions/getNodeEntityActions";
// import { areRangesOverlapping } from "date-fns";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";

class EmployeeCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }

        // const stars = [1, 2, 3, 4, 5];
        const { description, startDate, endDate, version, data, name } =
            this.state.allEntities[this.state.currentIndex];
        console.log(
            "this.state.allEntities[this.state.currentIndex]",
            this.state.allEntities[this.state.currentIndex]
        );
        // let cadenceString;
        // switch (cadence) {
        //     case "annually":
        //         cadenceString = "Annual";
        //         break;
        //     case "monthly":
        //         cadenceString = "Monthly";
        //         break;
        //     case "semi-monthly":
        //         cadenceString = "Semi-Monthly";
        //         break;
        //     case "bi-weekly":
        //         cadenceString = "Bi-Weekly";
        //         break;
        //     case "weekly":
        //         cadenceString = "Weekly";
        //         break;
        //     default:
        //         cadenceString = "";
        // }

        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">
                        {data.employeeState === "specific"
                            ? "Employee Name"
                            : "Placeholder Name"}
                        :
                    </div>
                    <div className="cardValue">{name}</div>
                </div>
                {description && (
                    <div className="Row">
                        <div
                            className={
                                description ? "Description" : "NoDescription"
                            }
                        >
                            {description}
                        </div>
                    </div>
                )}
                {data.employeeState === "specific" && (
                    <div className="Row">
                        <div className="ExpenseLabel">Employee Role:</div>
                        <div className="cardValue">
                            {data.role ? data.role : ""}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Location:</div>
                    <div className="cardValue">
                        {data.city} {data.province}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Pay Type:</div>
                    <div className="cardValue">
                        {data.payType ? _.startCase(data.payType) : ""}
                    </div>
                </div>
                {data.payType === "salary" ? (
                    <div className="Row">
                        <div className="ExpenseLabel">Annual Salary:</div>
                        <div className="cardValue">
                            $
                            {data.salary
                                ? new Intl.NumberFormat().format(
                                      parseFloat(data.salary).toFixed(2)
                                  )
                                : ""}
                        </div>
                    </div>
                ) : (
                    <div className="Row">
                        <div className="ExpenseLabel">Base Hourly Wage:</div>
                        <div className="cardValue">
                            $
                            {data.rate
                                ? new Intl.NumberFormat().format(
                                      parseFloat(data.rate).toFixed(2)
                                  )
                                : ""}
                        </div>
                    </div>
                )}
                {data.payType === "salary" ? (
                    <div />
                ) : (
                    <div className="Row">
                        <div className="ExpenseLabel">
                            Average Weekly Hours:
                        </div>
                        <div className="cardValue">
                            {data.averageHours ? `${data.averageHours}` : ""}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Date:</div>
                    <div className="cardValue">
                        {startDate
                            ? moment(startDate).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {endDate
                            ? moment(endDate).format("MMM D YYYY")
                            : "No End Date"}
                    </div>
                </div>
                {/* <div className="Row">
                    <div className="ExpenseLabel">Inflation Rate:</div>
                    <div className="cardValue">
                        {data.inflation
                            ? `% ${data.inflationRate * 100}`
                            : `% 0`}
                    </div>
                </div> */}
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                {/* <div className="Row">
                    <div className="ExpenseLabel"> Rating</div>
                    <div className="StarsContainer">
                        {stars.map((star, i) => {
                            if (i < data.rating) {
                                return (
                                    <img
                                        alt="alt"
                                        key={i}
                                        className="starFilledSvg"
                                        src={starFilledSvg}
                                        id="rating"
                                    />
                                );
                            } else {
                                return (
                                    <img
                                        alt="alt"
                                        className="starUnfilledSvg"
                                        src={starUnfilledSvg}
                                        key={i}
                                        id="rating"
                                    />
                                );
                            }
                        })}
                    </div>
                </div> */}
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() => this.handleArrowClick("left")}
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentIndex + 1} of{" "}
                        {this.state.allEntities.length}
                    </span>
                    <div
                        className={
                            this.state.currentIndex ===
                            this.state.allEntities.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() => this.handleArrowClick("right")}
                    ></div>
                </div>
            </div>
        );
    }
}

export default EmployeeCard;

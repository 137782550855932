import React from "react";

import CACConnectionImage from "../../Assets/_optionDescriptionIcons/Customers, Customer Growth, Customer Churn, CAC.png";

import cacBlack from "../../Assets/_eventNodeTypes/cac-black-updated.svg";
import cacBlue from "../../Assets/_eventNodeTypes/cac-blue.png";
import cacWhite from "../../Assets/_eventNodeTypes/cac-white.png";
import cacGrey from "../../Assets/_eventNodeTypes/cac-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import CAC2Card from "Components/Card/CustomerAcquisitionCost2Card";
import CAC2InputExperimental from "Components/InputContainer/CAC2InputExperimental/CAC2InputExperimental";

export const CAC2Object = {
    constant: function () {
        return CAC2_Constant();
    },

    name: function () {
        return "Customer Acquisition Cost 2";
    },

    checkInput: function (state) {
        return CAC2_CheckInput(state);
    },

    svg: function () {
        return CAC2_Svg();
    },

    eventNodeMenu: function (focus) {
        return CAC2_EventNodeMenu(focus);
    },

    indexText: function () {
        return CAC2_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return CAC2_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return CAC2_OptionDescription();
    },

    colouredPngs: function () {
        return CAC2_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return CAC2_SideModalImage();
    },
};

// Helper functions for Event

const CAC2_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (!entity.dependencies) return false;
        if (!entity.dependencies.entity) return false;
        if (!entity.dependencies.entity.eventId) return false;

        if (entity.data.costType == "constant") {
            if (!entity.data.constantCost) return false;
        } else if (entity.data.costType == "dynamic") {
            if (entity.data.costSegments.length <= 0) return false;

            for (const segment of entity.data.costSegments) {
                if (!segment.startDate || !segment.period) return false;
                if (!segment.monthlyRate && segment.monthlyRate !== 0) {
                    return false;
                }
            }
        }

        if (!entity.data.inheritStartDate) {
            if (!entity.startDate) return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

const CAC2_Constant = () => {
    return "75daa253-1da3-4a26-b538-2c72c2a47095";
};

const CAC2_Svg = () => {
    return cacBlack;
};

const CAC2_EventNodeMenu = (focus) => {
    return <CAC2Card data={focus} />;
};

const CAC2_IndexText = () => {
    return "The CAC Event produces Cashflow based on Customer Growth and the estimated cost to acquire a single customer.";
};

const CAC2_Icons = () => {
    let pngs = new Map([
        ["black", cacBlack],
        ["blue", cacBlue],
        ["white", cacWhite],
        ["grey", cacGrey],
    ]);
    return pngs;
};

const CAC2_InputComponentInfoPassing = (minProps) => {
    return <CAC2InputExperimental {...minProps} />;
};

const CAC2_OptionDescription = () => {
    const tags = ["Option"];
    const type = CAC2Object.name();
    const description =
        "CAC, or Customer Acquisition Cost, is a common KPI. and represents the cost of acquiring a new customer. This can include sales, marketing costs, overhead and the salaries of sales focussed employees. While CAC can be calculated, this Event instead use manually entered values that are based on industry estimates or a client’s historical CAC.";
    const usedForText =
        "Use the Customer Acquisition Event as part of a customer signup, growth, churn, and conversion funnel. CAC will be recognized as an Expense and offset the Revenue generated from these customers.";
    const connection =
        "The CAC Event is commonly used alongside the Customer, Customer Growth, Customer Churn and Unit Cost Events.";
    const connectionImage = CACConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/r/revenue.asp#:~:text=Revenue%20is%20the%20income%20generated,subtracted%20to%20determine%20net%20income.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const CAC2_SideModalImage = () => {
    return sideImage;
};

import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { useState, useEffect, useMemo } from "react";
import "./CustomAccountManager.css";
import { updateAccountsAPI } from "actions/accountsActions";
import { topLevelAccountIds } from "helpers/constants";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import { upsertAllAccountLedgers } from "actions/allAccountLedgersActions";
import { CheckBoxCellRenderer } from "./cellRenderers/checkbox";
import { DeleteButtonCellRenderer } from "./cellRenderers/deleteButton";
import { getColumnDefs } from "./helpers/getColumnDefs";
import { getRowData } from "./helpers/getRowData";
import { ConfirmChangesModal } from "./confirmChangesModal";

export const CustomAccountManager = () => {
    const dispatch = useAppDispatch();

    const { allUserLedgers, allAccountLedgers } = useAppSelector((state) => {
        const allAccountLedgers = state?.allAccountLedgers?.ledgersMetadata;
        const allLedgersArray: any = Object.values(allAccountLedgers ?? {});
        const allNoneSharedLedgers = allLedgersArray.filter(
            (ledger) => ledger?.type !== "shared"
        );
        return {
            allUserLedgers: allNoneSharedLedgers,
            allAccountLedgers: allAccountLedgers,
        };
    });

    const [showConfirmChangesModal, setShowConfirmChangesModal] =
        useState(false);

    const [localAccountLedgersMap, setLocalAccountLedgersMap] = useState({});

    const [accountsFlaggedForDeletionMap, setAccountsFlaggedForDeletionMap] =
        useState({});

    const [rowData, setRowData] = useState<any[]>([]);

    const [columnDefs, setColumnDefs] = useState<any[]>([]);

    const [gridApi, setGridApi] = useState<any | null>(null);

    const onGridReady = (event) => {
        setGridApi(event?.api);
    };

    useEffect(() => {
        if (gridApi) {
            gridApi?.redrawRows();
        }
        // eslint-disable-next-line
    }, [allAccountLedgers]);

    useEffect(() => {
        if (gridApi) {
            gridApi?.refreshCells({ force: true });
        }
        // eslint-disable-next-line
    }, [accountsFlaggedForDeletionMap]);

    useEffect(() => {
        if (allAccountLedgers && allUserLedgers) {
            setLocalAccountLedgersMap({ ...allAccountLedgers });
            setRowData(getRowData(allUserLedgers, allAccountLedgers));
            setColumnDefs(getColumnDefs());
        }
        // eslint-disable-next-line
    }, [allAccountLedgers]);

    const handleUpdate = async (newLedgers) => {
        const accountsFlaggedForDeletionArray = Object?.keys(
            accountsFlaggedForDeletionMap
        );

        const finalVersionOfLedgerMap = {
            ...newLedgers,
        };

        accountsFlaggedForDeletionArray?.forEach((accountId) => {
            // remove the deleted accounts id from its parent's children array
            const accountData = finalVersionOfLedgerMap?.[accountId];
            const parentAccount =
                finalVersionOfLedgerMap?.[accountData?.parents?.[0]];
            const indexOfAccountToBeDeleted =
                parentAccount?.children?.findIndex((id) => id === accountId);
            parentAccount?.children?.splice(indexOfAccountToBeDeleted, 1);

            // delete the account from the final ledgers map
            delete finalVersionOfLedgerMap?.[accountId];
        });

        await updateAccountsAPI(finalVersionOfLedgerMap);
        dispatch(
            upsertAllAccountLedgers({
                ledgersMetadata: finalVersionOfLedgerMap,
            })
        );
    };

    const flagEventsForDeletion = (
        accountId,
        allAccountLedgers,
        accountsFlaggedForDeletionMap
    ) => {
        const firstAccountData = allAccountLedgers?.[accountId];

        if (accountsFlaggedForDeletionMap?.[firstAccountData?.parents?.[0]])
            return;

        const newAccountsFlaggedForDeletionMap = {
            ...accountsFlaggedForDeletionMap,
        };

        const flagForDeletion = !newAccountsFlaggedForDeletionMap?.[accountId];

        const generateChildAccountIds = (accountId) => {
            const accountData = allAccountLedgers?.[accountId];

            if (accountData?.type === "shared") return;

            if (newAccountsFlaggedForDeletionMap?.[accountId]) {
                if (!flagForDeletion)
                    delete newAccountsFlaggedForDeletionMap?.[accountId];
            } else {
                if (flagForDeletion)
                    newAccountsFlaggedForDeletionMap[accountId] = accountData;
            }

            const childAccounts = accountData?.children;

            if (childAccounts?.length > 0) {
                childAccounts?.forEach((childAccountId) => {
                    generateChildAccountIds(childAccountId);
                });
            }
        };

        generateChildAccountIds(accountId);

        setAccountsFlaggedForDeletionMap(newAccountsFlaggedForDeletionMap);
    };

    const frameworkComponents = useMemo(() => {
        return {
            checkBoxCellRenderer: CheckBoxCellRenderer,
            deleteButtonCellRenderer: DeleteButtonCellRenderer,
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                debounceMs: 0,
            },
            sortable: true,
            resizable: true,
            minWidth: 150,
            flex: 1,
            editable: (params) => {
                const rowData = params?.data;
                if (topLevelAccountIds?.[rowData?.id]) return false;
                return true;
            },
            cellClassRules: {
                disableEdit: (params) => {
                    const columnDef = params?.colDef;
                    return !columnDef?.editable(params);
                },
                isEdited: (params) => {
                    const cellValue = params?.value;
                    const columnDef = params?.colDef;
                    const field = columnDef?.field;
                    const rowData = params.data;
                    const accountId = rowData?.id;
                    const allAccountLedgers =
                        params?.context?.allAccountLedgers;
                    const originalAccountData = allAccountLedgers?.[accountId];

                    if (!originalAccountData) return false;

                    if (
                        field in originalAccountData &&
                        originalAccountData?.[field] != cellValue
                    ) {
                        return true;
                    } else if (
                        field in originalAccountData?.unit &&
                        originalAccountData?.unit?.[field] != cellValue
                    ) {
                        return true;
                    }

                    return false;
                },
                flaggedForDeletion: (params) => {
                    const columnDef = params?.colDef;
                    const field = columnDef?.field;
                    if (field === "delete") return false;
                    const rowData = params.data;
                    const accountId = rowData?.id;
                    const accountsFlaggedForDeletionMap =
                        params?.context?.accountsFlaggedForDeletionMap;
                    if (accountsFlaggedForDeletionMap?.[accountId]) return true;
                    return false;
                },
            },
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerName: "Account Structure",
            width: 300,
            cellRendererParams: {
                suppressCount: true,
            },
            pinned: "left",
            lockPinned: true,
            editable: false,
            cellClassRules: {
                disableEdit: () => {
                    return true;
                },
            },
        };
    }, []);

    const getDataPath = (data) => {
        return data?.accountStructure;
    };

    const handleLocalLedgerMapUpdate = (cellChangeEvent) => {
        const cellValue = cellChangeEvent?.value;
        const columnDef = cellChangeEvent?.colDef;
        const field = columnDef?.field;
        const rowData = cellChangeEvent.data;
        const accountId = rowData?.id;
        const localAccountLedgersMap =
            cellChangeEvent?.context?.localAccountLedgersMap;
        const accountData = localAccountLedgersMap?.[accountId];

        if (field in accountData) {
            setLocalAccountLedgersMap({
                ...localAccountLedgersMap,
                [accountId]: {
                    ...accountData,
                    [field]: cellValue,
                },
            });
        } else if (field in accountData?.unit) {
            setLocalAccountLedgersMap({
                ...localAccountLedgersMap,
                [accountId]: {
                    ...accountData,
                    unit: {
                        ...accountData?.unit,
                        [field]: cellValue,
                    },
                },
            });
        }
    };

    const onCellValueChange = (event) => {
        if (event.newValue === undefined) return;
        const rowDataMap = {};
        event.api.forEachNode((rowNode) => {
            rowDataMap[rowNode?.data?.id] = rowNode.data;
        });

        if (event?.colDef?.field === "delete") {
            const accountId = event?.value;
            const allAccountLedgers = event?.context?.allAccountLedgers;
            const accountsFlaggedForDeletionMap =
                event?.context?.accountsFlaggedForDeletionMap;
            flagEventsForDeletion(
                accountId,
                allAccountLedgers,
                accountsFlaggedForDeletionMap
            );
            return;
        }

        handleLocalLedgerMapUpdate(event);
    };

    return (
        <div className="customAccountManager">
            <div className="customAccountManager__headerContainer">
                <h2 className="customAccountManager__primaryHeader">
                    Custom Account Manager
                </h2>
                <button
                    className="customAccountManager__updateButton"
                    onClick={() => setShowConfirmChangesModal(true)}
                >
                    Update
                </button>
            </div>
            <div className="agGridContainer">
                <div className="ag-theme-balham">
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        autoGroupColumnDef={autoGroupColumnDef}
                        treeData={true}
                        getDataPath={getDataPath}
                        // enableRangeSelection={true}
                        context={{
                            allAccountLedgers,
                            localAccountLedgersMap,
                            accountsFlaggedForDeletionMap,
                        }}
                        frameworkComponents={frameworkComponents}
                        // rowSelection={'multiple'}
                        onCellValueChanged={(e) => onCellValueChange(e)}
                    />
                </div>
            </div>
            {showConfirmChangesModal && (
                <ConfirmChangesModal
                    handleClose={() => setShowConfirmChangesModal(false)}
                    handleSubmit={() => handleUpdate(localAccountLedgersMap)}
                />
            )}
        </div>
    );
};

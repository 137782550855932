import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";
import { getLedger, recurseToTopAccount } from "helpers/accounts";
import store from "store";

/*
 * Helper function that handles Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */

export const capacityInputsHandler = (
    value: string,
    id:
        | "value"
        | "entityName"
        | "startDate"
        | "endDate"
        | "workDays"
        | "employee"
        | "accountName",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "value":
            let number = parseFloat(value);
            if (number < 0) number *= -1;

            data.value = number;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "workDays":
            data.workDays = parseInt(value);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            let startDateString = "";
            if (value) {
                const startMoment = moment(value);
                startDateString = startMoment.format("YYYY-MM-DD");
            }
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            let endDateString = "";
            if (value) {
                const endMoment = moment(value);
                endDateString = endMoment.format("YYYY-MM-DD");
            }
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "employee":
            // TODO: this is temporary code used for aggregating employee utilized
            // or adjusted accounts. A better method should be employed eventually.
            // If this code still exists (this comment is written as of Apr 30, 2024)
            // in the future, then this is not great!!!
            if (!account) break;

            const accountData = getLedger(account.ids[account.ids.length - 1]);
            if (!accountData || !accountData?.shadowIds) break;

            const adjustedName = "Adjusted - " + account.name;
            const utilizedName = "Utilized - " + account.name;
            const accountsAndLedgers =
                store.getState().allAccountLedgers.ledgersMetadata;

            const employeeAccounts = Object.values(accountsAndLedgers).filter(
                (account) =>
                    account.name === adjustedName ||
                    account.name === utilizedName
            );

            const employeeAccountIds = employeeAccounts.map(
                (account) => account.id
            );

            data.employeeName = account.name;
            data.employeeAccountIds = employeeAccountIds;
            data.employeePath = account.ids ?? [];
            data.capacityPath =
                recurseToTopAccount(accountData.shadowIds[1]) ?? [];
            data.accountIdsPaths = employeeAccountIds.map((id) =>
                recurseToTopAccount(id)
            );

            // Additionally export the employee and capacity accounts so
            // that there is no dependence on an employee entity.
            data.accountIdsPaths.push(account.ids);
            data.accountIdsPaths.push(data.capacityPath);

            data.accountIds = Array.from(
                new Set(data.accountIdsPaths.flat()).values()
            );

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;

            break;
        default:
    }

    return newEntitiesMap;
};

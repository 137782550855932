import React from "react";
import styles from "./index.module.css";
import tourImage from "../../Assets/_home/productTour.png";
import sampleScenarioImage from "../../Assets/_home/sampleScenario.png";
import templateImage from "../../Assets/_home/template.png";
import { EventManager } from "../../Events";
import getGeolocation from "countrycitystatejson";
import { EventsToast } from "../../Components/EventsToast";
import collapse from "../../Assets/_optionPicker/collapsed.svg";
import toggle from "../../Assets/_optionPicker/toggle.svg";
import profilePng from "../../Assets/_home/whatifi-logo-green.png";
import PersonalIcon from "../../Assets/_signUpIcons/personal.png";
import NoResultsImg from "../../Assets/_home/noClientSearch-1.png";
// import MoveCardsArrow from "../../Assets/_home/move-cards-arrow.svg";
import EmptyClientsList from "../../Assets/_home/empty-clients-list.png";
import deleteSvg from "../../Assets/scenarioIcons/delete.svg";
import editSvg from "../../Assets/scenarioIcons/edit.svg";
import ArchiveClientIcon from "../../Assets/scenarioIcons/archive.svg";
import ReactivateClientIcon from "../../Assets/scenarioIcons/reactivate.svg";
import { EventsPopUp } from "../../Components/EventsPopUp";
import ClientInput from "../../Components/InputContainer/ClientInput";
import addSvg from "../../Assets/_home/add.svg";
import rightArrow from "../../Assets/_optionDescriptionIcons/rightArrow.svg";
import leftArrow from "../../Assets/_optionDescriptionIcons/leftArrow.svg";
import { createBaselineScenario } from "../../helpers/baselineFunctions";
import CreateScenarioModal from "../../Components/CreateScenarioModal";
import PromptPopUp from "./partials/PromptPopUp";
import UpgradePlanPopUp from "./partials/UpgradePlanPopUp";
import Modal from "@mui/material/Modal";

import * as uuid from "uuid";
// eslint-disable-next-line
const _ = require("lodash");

export default class HomeAgency extends React.Component {
    constructor(props) {
        super(props);

        this.manager = new EventManager(
            this.updateCanvas,
            this.updateScenarioCanvas
        );

        this.state = {
            countries: getGeolocation.getCountries(),
            options: [
                {
                    id: 0,
                    title: "Take a Product Tour",
                    subTitle:
                        "Learn the basics of whatifi by interactively building a 'rent vs buy' example scenario.",
                    image: tourImage,
                    optionId: "tour",
                    banner: false,
                },
                {
                    id: 1,
                    title: "Explore Sample Scenarios",
                    subTitle:
                        "Click through some of our pre-built sample scenarios and get a better idea of what is possible in whatifi.",
                    image: sampleScenarioImage,
                    optionId: "sample",
                    banner: false,
                },
                {
                    id: 2,
                    title: "Build Your First Scenario From a Template",
                    subTitle:
                        "No need to start from scratch, we prepared templates for you!",
                    image: templateImage,
                    optionId: "template",
                    banner: true,
                },
                {
                    id: 3,
                    title: "Add Baseline Events",
                    subTitle:
                        "Your Baseline is your profile. Use it to capture all that is current and real in your Life.",
                    image: PersonalIcon,
                    optionId: "template",
                    banner: true,
                },
            ],
            showLanding: true,
            showClientModal: false,
            editData: null,
            clientSelected: null,
            clientSearchBarContent: null,
            filteredClientsList: [],
            showDeleteClientPrompt: false,
            toastData: null,
            showCardsArrows: window.innerWidth < 1875,
            cardsArrowCounter: 0,
            agencyClientsSelected: "active",
            showArchiveClientPrompt: false,
            showUpgradePlanPopUp: false,
            clientToArchive: null,
        };
        this.myDivToFocus = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.clientSearchBarContent !==
                this.state.clientSearchBarContent &&
            this.state.agencyClientsSelected === "active"
        ) {
            const clientsListArray = this.props.activeAgencyClients;
            let filteredClientsList = [];
            if (this.state.clientSearchBarContent === "")
                this.setState({ clientSearchBarContent: null });

            for (let i = 0; i < clientsListArray.length; i++) {
                if (
                    clientsListArray[i].clientdata.firstName
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent) ||
                    clientsListArray[i].clientdata.lastName
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent) ||
                    clientsListArray[i].clientdata.email
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent) ||
                    clientsListArray[i].clientdata.name
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent)
                )
                    filteredClientsList.push(clientsListArray[i]);
            }
            this.setState({ filteredClientsList: filteredClientsList });
        }
        if (
            prevState.clientSearchBarContent !==
                this.state.clientSearchBarContent &&
            this.state.agencyClientsSelected === "archived"
        ) {
            const clientsListArray = this.props.archivedAgencyClients;
            let filteredClientsList = [];
            if (this.state.clientSearchBarContent === "")
                this.setState({ clientSearchBarContent: null });

            for (let i = 0; i < clientsListArray.length; i++) {
                if (
                    clientsListArray[i].clientdata.firstName
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent) ||
                    clientsListArray[i].clientdata.lastName
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent) ||
                    clientsListArray[i].clientdata.email
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent) ||
                    clientsListArray[i].clientdata.name
                        .toLowerCase()
                        .includes(this.state.clientSearchBarContent)
                )
                    filteredClientsList.push(clientsListArray[i]);
            }
            this.setState({ filteredClientsList: filteredClientsList });
        }

        if (
            prevProps.activeAgencyClients !== this.props.activeAgencyClients ||
            prevProps.archivedAgencyClients !== this.props.archivedAgencyClients
        ) {
            if (this.state.agencyClientsSelected === "active") {
                const clientsListArray = this.props.activeAgencyClients;
                let filteredClientsList = [];
                if (this.state.clientSearchBarContent === "")
                    this.setState({ clientSearchBarContent: null });

                for (let i = 0; i < clientsListArray.length; i++) {
                    if (
                        clientsListArray[i].clientdata.firstName
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent) ||
                        clientsListArray[i].clientdata.lastName
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent) ||
                        clientsListArray[i].clientdata.email
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent) ||
                        clientsListArray[i].clientdata.name
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent)
                    )
                        filteredClientsList.push(clientsListArray[i]);
                }
                this.setState({ filteredClientsList: filteredClientsList });
            }
            if (this.state.agencyClientsSelected === "archived") {
                const clientsListArray = this.props.archivedAgencyClients;
                let filteredClientsList = [];
                if (this.state.clientSearchBarContent === "")
                    this.setState({ clientSearchBarContent: null });

                for (let i = 0; i < clientsListArray.length; i++) {
                    if (
                        clientsListArray[i].clientdata.firstName
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent) ||
                        clientsListArray[i].clientdata.lastName
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent) ||
                        clientsListArray[i].clientdata.email
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent) ||
                        clientsListArray[i].clientdata.name
                            .toLowerCase()
                            .includes(this.state.clientSearchBarContent)
                    )
                        filteredClientsList.push(clientsListArray[i]);
                }
                this.setState({ filteredClientsList: filteredClientsList });
            }
        }
    }

    handleSelectAgencyClientsList = (selectedList) => {
        this.setState({
            agencyClientsSelected: selectedList,
            clientSearchBarContent: null,
        });
        document.getElementById("client-searchbar").value = "";
    };

    handleOptionToggle = () => {
        this.setState({ showOptions: !this.state.showOptions });
    };

    toggleAddClient = () => {
        this.setState({ showClientModal: !this.state.showClientModal });
    };

    clientSearchBar = (e) => {
        let searchBarContent = e.target.value.toLowerCase().trim();

        this.setState({ clientSearchBarContent: searchBarContent });
    };

    handleAddClient = (state) => {
        const {
            baselineDataManager,
            loadScenario,
            createBaseline,
            baselineManager,
        } = this.props;
        const id = uuid.v4();
        state["id"] = id;
        const client = {
            id,
            baseline: null,
            scenarios: [],
            clientdata: state,
        };
        //Call endpoint here
        this.props.addClient(client);
        createBaselineScenario(
            baselineManager,
            createBaseline,
            loadScenario,
            null,
            "meNode",
            baselineDataManager,
            client
        ).then((result) => {
            client.baseline = result;
            this.props.updateClient(client);
        });
        this.toggleAddClient();
    };

    handleUpdateClient = (state) => {
        const { baselineDataManager } = this.props;
        const data = {
            ...this.state.editData,
            clientdata: state,
        };
        this.props.updateClient(data);
        const baselines = baselineDataManager.getBaselines(true);
        const clientBaseline = baselines.find((candidate) => {
            return data.baseline && candidate.id === data.baseline;
        });
        if (clientBaseline) {
            clientBaseline.name = `${data.clientdata.name}'s Baseline`;
            this.props.updateBaseline(clientBaseline, baselineDataManager);
        }
        this.setState({ showClientModal: !this.state.showClientModal });
    };

    handleShowDeleteClientPrompt = (client) => {
        const toastData = {
            type: "warnings",
            text: `Delete client ${client.clientdata.name} and all their Scenarios?`,
            upgrade: false,
        };
        this.setState({
            showDeleteClientPrompt: true,
            toastData: toastData,
            clientToDelete: client,
        });
    };

    handleClientArchived = (client) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const { agencyClients } = this.props;
        const filteredActiveClients =
            agencyClients &&
            agencyClients.filter((item) => !item.clientdata.archived);

        let archivedValue = true;

        if (client.clientdata.archived) {
            const toastData = {
                type: "success",
                text: `Successfully reactivated client - ${client.clientdata.firstName}.`,
                upgrade: false,
            };

            if (
                loggedInUser &&
                filteredActiveClients.length <
                    loggedInUser.plan.meta_data.client_limit
            ) {
                archivedValue = false;
                this.setState({ toastData: toastData });
            } else {
                console.log('Heeeeey!!! YOU DON"T HAVE ENOUGH CLIENT SPACE');
                this.setState({ showUpgradePlanPopUp: true });
            }
        }

        const data = {
            ...client,
            clientdata: {
                ...client.clientdata,
                archived: archivedValue,
            },
        };

        this.props.updateClient(data);

        if (this.state.showArchiveClientPrompt) {
            this.setState({ showArchiveClientPrompt: false });
        }

        if (this.state.agencyClientsSelected === "archived") {
            setTimeout(() => {
                this.setState({ toastData: null });
            }, 3000);
        }
    };

    handleCloseClientArchivePrompt = () => {
        if (this.state.showArchiveClientPrompt) {
            this.setState({ showArchiveClientPrompt: false });
        }
    };

    handleCloseUpgradePlanPopUp = () => {
        if (this.state.showUpgradePlanPopUp) {
            this.setState({ showUpgradePlanPopUp: false });
        }
    };

    cancelToast = () => {
        this.setState({ toastData: null });
    };

    handleDeleteClient = (clientData) => {
        const { manager, sampleScenarios } = this.props;
        const baselines = this.props.baselineDataManager.getBaselines();
        const client = this.props.agencyClients.find(
            (cl) => cl.id === clientData.id
        );
        baselines &&
            baselines.forEach((baseline) => {
                if (client.baseline === baseline.id) {
                    this.props.deleteBaseline(
                        client.baseline,
                        this.props.baselineDataManager,
                        []
                    );
                }
            });
        client.scenarios &&
            client.scenarios.forEach((scenarioId) => {
                this.props.deleteScenario(scenarioId, manager, sampleScenarios);
            });
        this.props.deleteClient(clientData.id);
        this.setState({ toastData: null, clientToDelete: null });
    };

    handleEditClient = (data) => {
        this.setState({
            showClientModal: !this.state.showClientModal,
            editData: data,
        });
    };

    handleClientSelected = (client) => {
        if (this.state.clientSelected) {
            if (this.state.clientSelected.id === client.id) {
                this.setState({ clientSelected: null });
            } else {
                this.handleScroll(client.id);
                this.setState({ clientSelected: client });
            }
        } else {
            this.handleScroll(client.id);
            this.setState({ clientSelected: client });
        }
    };

    handleScroll = (id) => {
        setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        }, 200);
    };

    handleArrowClick = (direction, id) => {
        const el = document.getElementById(id);
        if (el) {
            if (direction === "right") {
                el.scrollLeft += 100;
            } else {
                el.scrollLeft -= 100;
            }
        }
    };

    // handleRightClick = () => {
    //     if (this.myDivToFocus.current) {
    //         this.myDivToFocus.current.scrollLeft += 100;
    //     }
    // };

    // handleLeftClick = () => {
    //     if (this.myDivToFocus.current) {
    //         this.myDivToFocus.current.scrollLeft -= 100;
    //     }
    // };

    accessClientBaseline = (client) => {
        const {
            baselineDataManager,
            baselineManager,
            createBaseline,
            loadScenario,
            areBaselinesLoaded,
        } = this.props;
        if (!areBaselinesLoaded) return;
        if (
            client.baseline &&
            baselineDataManager.existsBaselineWithId(client.baseline)
        ) {
            // if baseline exists load it
            baselineDataManager.setActiveBaseline(client.baseline);
            this.props.toggleShowAgencyBaselineView(false);
            this.props.history.push("/baseline");
        } else {
            // otherwise must create one
            createBaselineScenario(
                baselineManager,
                createBaseline,
                loadScenario,
                null,
                "meNode",
                baselineDataManager,
                client
            ).then((result) => {
                client.baseline = result;
                this.props.updateClient(client);
                this.props.toggleShowAgencyBaselineView(false);
                this.props.history.push("/baseline");
            });
        }
    };

    getDemoScenarioData = () => {
        return [
            {
                name: "Scenario 1",
                description:
                    "This is an onboarding scenario. Whatifi buy a house in Vancouver, instead of renting the current ap…",
                range: 10,
                inflation: 1.9,
            },
            {
                name: "Scenario 2",
                description:
                    "This is an onboarding scenario. Whatifi buy a house in Vancouver, instead of renting the current ap…",
                range: 10,
                inflation: 1.9,
            },
            {
                name: "Scenario 3",
                description:
                    "This is an onboarding scenario. Whatifi buy a house in Vancouver, instead of renting the current ap…",
                range: 10,
                inflation: 1.9,
            },
            {
                name: "Scenario 4",
                description:
                    "This is an onboarding scenario. Whatifi buy a house in Vancouver, instead of renting the current ap…",
                range: 10,
                inflation: 1.9,
            },
            {
                name: "Scenario 5",
                description:
                    "This is an onboarding scenario. Whatifi buy a house in Vancouver, instead of renting the current ap…",
                range: 10,
                inflation: 1.9,
            },
            {
                name: "Scenario 6",
                description:
                    "This is an onboarding scenario. Whatifi buy a house in Vancouver, instead of renting the current ap…",
                range: 10,
                inflation: 1.9,
            },
        ];
    };

    loadClientScenario = (scenario) => {
        this.props.baselineDataManager.setIsFirstLoad(true);
        localStorage.setItem("loadedScenario", JSON.stringify(scenario));
        this.props.loadScenario(
            scenario,
            this.manager,
            this.props.baselineDataManager,
            null,
            this.props.eventLibrary
        );
        this.props.history.push("/");
    };

    closeScenarioCreation = () => {
        this.setState({ showAddScenario: false });
    };

    createScenario = (
        name,
        description,
        range,
        inflation,
        client,
        explainerVideoLink,
        subtitle,
        custom_start_date,
        start_date_preference
    ) => {
        this.manager.addStart();
        let startNode;

        if (client) {
            startNode = this.manager.getRootNode();
            startNode.name = client.clientdata.name;
            startNode.baseline = false;
        }
        const data = JSON.stringify({
            ...this.manager.exportData(),
        });

        const scenario = {
            name,
            description,
            data,
            range,
            inflation,
            baselineid: client.baseline,
            explainerVideoLink,
            subtitle,
            custom_start_date,
            start_date_preference,
            dependency_map: {}
        };
        this.props.createScenario(scenario, range, (data) => {
            if (data) {
                if (client) {
                    this.addScenarioToClient(client, data.data.id);
                }
                this.props.getUserScenarios();
            }
        });
    };

    addScenarioToClient = (client, scenarioId) => {
        if (!client.scenarios) client.scenarios = [];
        if (client.scenarios.includes(scenarioId)) {
            return;
        }
        client.scenarios.push(scenarioId);
        this.props.updateClient(client);
    };

    scrollToBottom = (id) => {
        let lastClient = document.getElementById(id);

        let topPos = lastClient.offsetTop;

        setTimeout(() => {
            document.getElementById("clientsList").scrollTop = topPos;
            console.log("times up");
        }, 210);
    };

    getScenarioCard = (item) => {
        return (
            <div
                className={styles.addCard}
                onClick={
                    this.state.agencyClientsSelected !== "archived"
                        ? () => {
                              this.loadClientScenario(item);
                          }
                        : null
                }
            >
                <div className={styles.scenarioCardTitle}>{item.name}</div>
                <div className={styles.scenarioCardDescription}>
                    {item.description || <i>no description</i>}
                </div>
                <div className={styles.scenarioCardBottom}>
                    <div
                        style={{
                            display: "flex",
                            marginLeft: "16px",
                        }}
                    >
                        <div
                            style={{
                                fontWeight: "600",
                            }}
                        >
                            Scenario Range:
                        </div>
                        <div> {item.range} yrs.</div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            marginLeft: "16px",
                        }}
                    >
                        <div
                            style={{
                                fontWeight: "600",
                            }}
                        >
                            Inflation:
                        </div>
                        <div>{item.inflation * 100} %</div>
                    </div>
                </div>
            </div>
        );
    };

    promptArchiveClientConfirmation = (client) => {
        this.setState({
            showArchiveClientPrompt: !this.state.showArchiveClientPrompt,
            clientToArchive: client,
        });
    };

    handleClickCardsArrowRight = () => {
        if (this.state.cardsArrowCounter < 5)
            this.setState({
                cardsArrowCounter: this.state.cardsArrowCounter + 1,
            });
    };

    handleClickCardsArrowLeft = () => {
        if (this.state.cardsArrowCounter > 0)
            this.setState({
                cardsArrowCounter: this.state.cardsArrowCounter - 1,
            });
    };

    handleClickAddClient = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const { agencyClients } = this.props;
        const filteredActiveClients =
            agencyClients &&
            agencyClients.filter((item) => !item.clientdata.archived);
        if (
            loggedInUser &&
            filteredActiveClients.length <
                loggedInUser.plan.meta_data.client_limit
        ) {
            this.setState({ editData: null });
            this.toggleAddClient();
        } else {
            console.log('Heeeeey!!! YOU DON"T HAVE ENOUGH CLIENT SPACE');
            this.setState({ showUpgradePlanPopUp: true });
        }
    };

    render() {
        const {
            // options,
            showClientModal,
            editData,
            clientSelected,
            showAddScenario,
            filteredClientsList,
            clientSearchBarContent,
            // cardsArrowCounter,
            // showCardsArrows,
            agencyClientsSelected,
            clientToArchive,
            showArchiveClientPrompt,
            showUpgradePlanPopUp,
        } = this.state;
        const {
            agencyClients,
            // showExploreWhatifiOptions,
            userScenarios,
            activeAgencyClients,
            archivedAgencyClients,
        } = this.props;
        // let cardsTranslate = {
        //     transform: `translateX(-${cardsArrowCounter * 10}%)`,
        // };

        let clientsListComponent;

        const unsortedScenarios = clientSelected
            ? clientSelected.scenarios
                  .map((scenarioId) => {
                      return userScenarios.find((scenario) => {
                          return scenario.id === scenarioId;
                      });
                  })
                  .filter((scenario) => scenario)
            : [];
        const scenarios = clientSelected
            ? _.sortBy(unsortedScenarios, [
                  function (o) {
                      return o.name;
                  },
              ])
            : [];

        switch (agencyClientsSelected) {
            case "active":
                clientsListComponent = activeAgencyClients.map((client, i) => {
                    const location =
                        this.state.countries.find(
                            (item) =>
                                item.shortName === client.clientdata.country
                        ) || {};
                    const selected =
                        clientSelected && clientSelected.id === client.id;

                    let scenarioCount = client.scenarios
                        .map((scenarioId) => {
                            return userScenarios.find((scenario) => {
                                return scenario.id === scenarioId;
                            });
                        })
                        .filter((scenario) => scenario).length;

                    let backGround = "#FFFFFF";
                    if (selected) backGround = "#FFE1B9";
                    if (client.clientdata["archived"]) backGround = "#EEEEEE";
                    return (
                        <div
                            style={{
                                backgroundColor: backGround,
                            }}
                            className={styles.clientOuterContainer}
                            id={`client${i}`}
                            key={`client${i}`}
                        >
                            <div
                                id={client.id}
                                onClick={
                                    i < agencyClients.length - 1
                                        ? () =>
                                              this.handleClientSelected(client)
                                        : () => {
                                              this.handleClientSelected(client);
                                              this.scrollToBottom(`client${i}`);
                                          }
                                }
                                style={{
                                    backgroundColor: backGround,
                                }}
                                className={styles.clientInnerContainer}
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        display: "flex",
                                    }}
                                >
                                    <div className={styles.clientNumber}>
                                        {i + 1}
                                    </div>
                                    <div className={styles.clientIconWrapper}>
                                        <img
                                            className={styles.clientIcon}
                                            src={profilePng}
                                            alt="profile icon"
                                        />
                                    </div>
                                    <div className={styles.clientNameContainer}>
                                        <div className={styles.clientName}>
                                            <div
                                                className={
                                                    styles.clientNameText
                                                }
                                            >
                                                {client.clientdata.name}{" "}
                                            </div>
                                            <span
                                                className={styles.accountType}
                                            >
                                                {client.clientdata.clientType}
                                            </span>
                                        </div>
                                        {client.clientdata.name !==
                                            `${client.clientdata.firstName} ${client.clientdata.lastName}` && (
                                            <div
                                                className={styles.businessText}
                                            >
                                                {`${client.clientdata.firstName} ${client.clientdata.lastName}`}
                                            </div>
                                        )}
                                        <div className={styles.businessText}>
                                            {client.clientdata.state},{" "}
                                            {location.name}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: "fit-content",
                                        display: "flex",
                                    }}
                                >
                                    {/* <div
                                        className={
                                            this.props.areBaselinesLoaded
                                                ? styles.clientEditButton
                                                : styles.clientEditButtonDisabled
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.accessClientBaseline(client);
                                        }}
                                    >
                                        {client.baseline
                                            ? "Edit Baseline"
                                            : "Create Baseline"}
                                    </div> */}
                                    <div className={styles.clientEditButton}>
                                        {scenarioCount} Scenarios{" "}
                                        <img
                                            style={{
                                                margin: "0px 10px",
                                            }}
                                            src={selected ? toggle : collapse}
                                            alt="alt"
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.clientSvgContainerEdit
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.handleEditClient(client);
                                        }}
                                    >
                                        <img
                                            src={editSvg}
                                            alt="alt"
                                            className={styles.clientSvgEdit}
                                        />
                                    </div>
                                    <div className={styles.clientSvgContainer}>
                                        <img
                                            className={styles.clientSvgEdit}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.handleShowDeleteClientPrompt(
                                                    client
                                                );
                                            }}
                                            src={deleteSvg}
                                            alt="alt"
                                        />
                                    </div>
                                    <div className={styles.clientSvgContainer}>
                                        <img
                                            className={styles.clientSvgEdit}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.promptArchiveClientConfirmation(
                                                    client
                                                );
                                            }}
                                            src={ArchiveClientIcon}
                                            alt="archived"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                className={
                                    selected
                                        ? styles.scenarioCardContainerOpen
                                        : styles.scenarioCardContainerClosed
                                }
                            >
                                <img
                                    alt="alt"
                                    onClick={() =>
                                        this.handleArrowClick(
                                            "left",
                                            `client${i}Scenarios`
                                        )
                                    }
                                    // onClick={() => this.handleLeftClick()}
                                    className={styles.arrowLeft}
                                    src={leftArrow}
                                />
                                <div
                                    id={`client${i}Scenarios`}
                                    // ref={this.myDivToFocus}
                                    className={styles.cardsOuterContainer}
                                >
                                    <div className={styles.cardsInnerContainer}>
                                        <div
                                            className={styles.addCard}
                                            onClick={() => {
                                                this.setState({
                                                    showAddScenario: true,
                                                });
                                            }}
                                        >
                                            <div
                                                style={{
                                                    margin: "auto auto",
                                                }}
                                            >
                                                <img
                                                    src={addSvg}
                                                    className={styles.addSvg}
                                                    alt="alt"
                                                />
                                                <div
                                                    style={{
                                                        margin: "5px auto",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Add New
                                                </div>
                                                <div>
                                                    Start a new scenario with
                                                    this client’s baseline
                                                    information
                                                </div>
                                            </div>
                                        </div>
                                        {scenarios &&
                                            scenarios.map((item) => {
                                                if (!item) return <div />;
                                                else
                                                    return this.getScenarioCard(
                                                        item
                                                    );
                                            })}
                                    </div>
                                </div>
                                <img
                                    alt="alt"
                                    onClick={() =>
                                        this.handleArrowClick(
                                            "right",
                                            `client${i}Scenarios`
                                        )
                                    }
                                    // onClick={() => this.handleRightClick()}
                                    className={styles.arrow}
                                    src={rightArrow}
                                />
                            </div>
                        </div>
                    );
                });

                break;
            case "archived":
                clientsListComponent = archivedAgencyClients.map(
                    (client, i) => {
                        const location =
                            this.state.countries.find(
                                (item) =>
                                    item.shortName === client.clientdata.country
                            ) || {};
                        const selected =
                            clientSelected && clientSelected.id === client.id;

                        let scenarioCount = client.scenarios
                            .map((scenarioId) => {
                                return userScenarios.find((scenario) => {
                                    return scenario.id === scenarioId;
                                });
                            })
                            .filter((scenario) => scenario).length;

                        let backGround = "#FFFFFF";
                        if (selected) backGround = "#FFE1B9";
                        if (client.clientdata["archived"])
                            backGround = "#EEEEEE";
                        return (
                            <div
                                style={{
                                    backgroundColor: backGround,
                                }}
                                className={styles.clientOuterContainer}
                                id={`client${i}`}
                                key={`client${i}`}
                            >
                                <div
                                    id={client.id}
                                    onClick={
                                        i < agencyClients.length - 1
                                            ? () =>
                                                  this.handleClientSelected(
                                                      client
                                                  )
                                            : () => {
                                                  this.handleClientSelected(
                                                      client
                                                  );
                                                  this.scrollToBottom(
                                                      `client${i}`
                                                  );
                                              }
                                    }
                                    style={{
                                        backgroundColor: backGround,
                                    }}
                                    className={styles.clientInnerContainer}
                                >
                                    <div
                                        style={{
                                            width: "50%",
                                            display: "flex",
                                        }}
                                    >
                                        <div className={styles.clientNumber}>
                                            {i + 1}
                                        </div>
                                        <div
                                            className={styles.clientIconWrapper}
                                        >
                                            <img
                                                className={styles.clientIcon}
                                                src={profilePng}
                                                alt="profile icon"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.clientNameContainer
                                            }
                                        >
                                            <div className={styles.clientName}>
                                                <div
                                                    className={
                                                        styles.clientNameText
                                                    }
                                                >
                                                    {client.clientdata.name}{" "}
                                                </div>
                                                <span
                                                    className={
                                                        styles.accountType
                                                    }
                                                >
                                                    {
                                                        client.clientdata
                                                            .clientType
                                                    }
                                                </span>
                                            </div>
                                            {client.clientdata.name !==
                                                `${client.clientdata.firstName} ${client.clientdata.lastName}` && (
                                                <div
                                                    className={
                                                        styles.businessText
                                                    }
                                                >
                                                    {`${client.clientdata.firstName} ${client.clientdata.lastName}`}
                                                </div>
                                            )}
                                            <div
                                                className={styles.businessText}
                                            >
                                                {client.clientdata.state},{" "}
                                                {location.name}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            width: "fit-content",
                                            display: "flex",
                                        }}
                                    >
                                        <div
                                            className={styles.clientEditButton}
                                        >
                                            {scenarioCount} Scenarios{" "}
                                            <img
                                                style={{
                                                    margin: "0px 10px",
                                                }}
                                                src={
                                                    selected ? toggle : collapse
                                                }
                                                alt="alt"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.clientSvgContainer
                                            }
                                        >
                                            <img
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleClientArchived(
                                                        client
                                                    );
                                                }}
                                                src={ReactivateClientIcon}
                                                alt="reactivate client"
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.clientSvgContainer
                                            }
                                        >
                                            <img
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleShowDeleteClientPrompt(
                                                        client
                                                    );
                                                }}
                                                src={deleteSvg}
                                                alt="alt"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        selected
                                            ? styles.scenarioCardContainerOpen
                                            : styles.scenarioCardContainerClosed
                                    }
                                >
                                    <img
                                        alt="alt"
                                        onClick={() =>
                                            this.handleArrowClick(
                                                "left",
                                                `client${i}Scenarios`
                                            )
                                        }
                                        // onClick={() => this.handleLeftClick()}
                                        className={styles.arrowLeft}
                                        src={leftArrow}
                                    />
                                    <div
                                        id={`client${i}Scenarios`}
                                        // ref={this.myDivToFocus}
                                        className={styles.cardsOuterContainer}
                                    >
                                        <div
                                            className={
                                                styles.cardsInnerContainer
                                            }
                                        >
                                            {/* <div
                                            className={
                                                styles.addCard
                                            }
                                            onClick={() => {
                                                this.setState({
                                                    showAddScenario: true,
                                                });
                                            }}
                                        >
                                            <div
                                                style={{
                                                    margin:
                                                        "auto auto",
                                                }}
                                            >
                                                <img
                                                    src={addSvg}
                                                    className={
                                                        styles.addSvg
                                                    }
                                                    alt="alt"
                                                />
                                                <div
                                                    style={{
                                                        margin:
                                                            "5px auto",
                                                        fontWeight:
                                                            "600",
                                                    }}
                                                >
                                                    Add New
                                                </div>
                                                <div>
                                                    Start a new
                                                    scenario with
                                                    this client’s
                                                    baseline
                                                    information
                                                </div>
                                            </div>
                                        </div> */}
                                            {scenarios &&
                                                scenarios.map((item) => {
                                                    if (!item) return <div />;
                                                    else
                                                        return this.getScenarioCard(
                                                            item
                                                        );
                                                })}
                                        </div>
                                    </div>
                                    <img
                                        alt="alt"
                                        onClick={() =>
                                            this.handleArrowClick(
                                                "right",
                                                `client${i}Scenarios`
                                            )
                                        }
                                        // onClick={() => this.handleRightClick()}
                                        className={styles.arrow}
                                        src={rightArrow}
                                    />
                                </div>
                            </div>
                        );
                    }
                );
                break;
            default:
                clientsListComponent = activeAgencyClients.map((client, i) => {
                    const location =
                        this.state.countries.find(
                            (item) =>
                                item.shortName === client.clientdata.country
                        ) || {};
                    const selected =
                        clientSelected && clientSelected.id === client.id;

                    let scenarioCount = client.scenarios
                        .map((scenarioId) => {
                            return userScenarios.find((scenario) => {
                                return scenario.id === scenarioId;
                            });
                        })
                        .filter((scenario) => scenario).length;

                    let backGround = "#FFFFFF";
                    if (selected) backGround = "#FFE1B9";
                    if (client.clientdata["archived"]) backGround = "#EEEEEE";
                    return (
                        <div
                            style={{
                                backgroundColor: backGround,
                            }}
                            className={styles.clientOuterContainer}
                            id={`client${i}`}
                            key={`client${i}`}
                        >
                            <div
                                id={client.id}
                                onClick={
                                    i < agencyClients.length - 1
                                        ? () =>
                                              this.handleClientSelected(client)
                                        : () => {
                                              this.handleClientSelected(client);
                                              this.scrollToBottom(`client${i}`);
                                          }
                                }
                                style={{
                                    backgroundColor: backGround,
                                }}
                                className={styles.clientInnerContainer}
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        display: "flex",
                                    }}
                                >
                                    <div className={styles.clientNumber}>
                                        {i + 1}
                                    </div>
                                    <div className={styles.clientIconWrapper}>
                                        <img
                                            className={styles.clientIcon}
                                            src={profilePng}
                                            alt="profile icon"
                                        />
                                    </div>
                                    <div className={styles.clientNameContainer}>
                                        <div className={styles.clientName}>
                                            <div
                                                className={
                                                    styles.clientNameText
                                                }
                                            >
                                                {client.clientdata.name}{" "}
                                            </div>
                                            <span
                                                className={styles.accountType}
                                            >
                                                {client.clientdata.clientType}
                                            </span>
                                        </div>
                                        {client.clientdata.name !==
                                            `${client.clientdata.firstName} ${client.clientdata.lastName}` && (
                                            <div
                                                className={styles.businessText}
                                            >
                                                {`${client.clientdata.firstName} ${client.clientdata.lastName}`}
                                            </div>
                                        )}
                                        <div className={styles.businessText}>
                                            {client.clientdata.state},{" "}
                                            {location.name}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        width: "fit-content",
                                        display: "flex",
                                    }}
                                >
                                    {/* <div
                                        className={
                                            this.props.areBaselinesLoaded
                                                ? styles.clientEditButton
                                                : styles.clientEditButtonDisabled
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.accessClientBaseline(client);
                                        }}
                                    >
                                        {client.baseline
                                            ? "Edit Baseline"
                                            : "Create Baseline"}
                                    </div> */}
                                    <div className={styles.clientEditButton}>
                                        {scenarioCount} Scenarios{" "}
                                        <img
                                            style={{
                                                margin: "0px 10px",
                                            }}
                                            src={selected ? toggle : collapse}
                                            alt="alt"
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.clientSvgContainerEdit
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.handleEditClient(client);
                                        }}
                                    >
                                        <img
                                            src={editSvg}
                                            alt="alt"
                                            className={styles.clientSvgEdit}
                                        />
                                    </div>
                                    <div className={styles.clientSvgContainer}>
                                        <img
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.handleShowDeleteClientPrompt(
                                                    client
                                                );
                                            }}
                                            src={deleteSvg}
                                            alt="alt"
                                        />
                                    </div>
                                    <div className={styles.clientSvgContainer}>
                                        <img
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.handleClientArchived(
                                                    client
                                                );
                                            }}
                                            src={ArchiveClientIcon}
                                            alt="archived"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                className={
                                    selected
                                        ? styles.scenarioCardContainerOpen
                                        : styles.scenarioCardContainerClosed
                                }
                            >
                                <img
                                    alt="alt"
                                    onClick={() =>
                                        this.handleArrowClick(
                                            "left",
                                            `client${i}Scenarios`
                                        )
                                    }
                                    // onClick={() => this.handleLeftClick()}
                                    className={styles.arrowLeft}
                                    src={leftArrow}
                                />
                                <div
                                    id={`client${i}Scenarios`}
                                    // ref={this.myDivToFocus}
                                    className={styles.cardsOuterContainer}
                                >
                                    <div className={styles.cardsInnerContainer}>
                                        <div
                                            className={styles.addCard}
                                            onClick={() => {
                                                this.setState({
                                                    showAddScenario: true,
                                                });
                                            }}
                                        >
                                            <div
                                                style={{
                                                    margin: "auto auto",
                                                }}
                                            >
                                                <img
                                                    src={addSvg}
                                                    className={styles.addSvg}
                                                    alt="alt"
                                                />
                                                <div
                                                    style={{
                                                        margin: "5px auto",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Add New
                                                </div>
                                                <div>
                                                    Start a new scenario with
                                                    this client’s baseline
                                                    information
                                                </div>
                                            </div>
                                        </div>
                                        {scenarios &&
                                            scenarios.map((item) => {
                                                if (!item) return <div />;
                                                else
                                                    return this.getScenarioCard(
                                                        item
                                                    );
                                            })}
                                    </div>
                                </div>
                                <img
                                    alt="alt"
                                    onClick={() =>
                                        this.handleArrowClick(
                                            "right",
                                            `client${i}Scenarios`
                                        )
                                    }
                                    // onClick={() => this.handleRightClick()}
                                    className={styles.arrow}
                                    src={rightArrow}
                                />
                            </div>
                        </div>
                    );
                });
        }

        return (
            <div className={styles.businessRoot}>
                <Modal
                    open={showAddScenario}
                    onClose={this.closeScenarioCreation}
                >
                    <div>
                        <CreateScenarioModal
                            title="Create Scenario"
                            open={showAddScenario}
                            close={this.closeScenarioCreation}
                            submit={this.createScenario}
                            loadScenario={this.props.loadScenario}
                            manager={this.manager}
                            onboardingScenario={this.props.onboardingScenario}
                            getUserScenarios={this.props.getUserScenarios}
                            createScenario={this.props.createScenario}
                            showCreateScenario={this.props.showCreateScenario}
                            getBaseline={this.props.getBaseline}
                            baseline={this.props.baseline}
                            getAdminValues={this.props.getAdminValues}
                            baselineManager={this.props.baselineManager}
                            baselineDataManager={this.props.baselineDataManager}
                            agencyClients={this.props.agencyClients}
                            forcedClientId={clientSelected?.id ?? ""}
                            userScenarios={this.props.userScenarios}
                            scenarioTemplates={this.props.scenarioTemplates}
                            addScenarioToClient={this.addScenarioToClient}
                        />
                    </div>
                </Modal> 
                {showArchiveClientPrompt && (
                    <PromptPopUp
                        client={clientToArchive}
                        handleClientArchived={this.handleClientArchived}
                        handleCloseClientArchivePrompt={
                            this.handleCloseClientArchivePrompt
                        }
                    />
                )}
                {showUpgradePlanPopUp && (
                    <UpgradePlanPopUp
                        handleCloseUpgradePlanPopUp={
                            this.handleCloseUpgradePlanPopUp
                        }
                        history={this.props.history}
                    />
                )}
                {/* <div className={styles.businessTopContainer}>
                    <div className={styles.business_header}>
                        Explore whatifi
                    </div>
                    <div className={styles.subHeaderContainer}>
                        <div className={styles.businessSubHeader}>
                            Let&apos;s start exploring whatifi with...
                        </div>
                        <div
                            className={styles.hideWrapper}
                            onClick={() => {
                                this.props.toggleExploreOptions(
                                    !showExploreWhatifiOptions
                                );
                            }}
                        >
                            <div className={styles.hideText}>Hide</div>
                            <img
                                src={
                                    showExploreWhatifiOptions
                                        ? toggle
                                        : collapse
                                }
                                alt="alt"
                            />
                        </div>
                    </div>
                    {showExploreWhatifiOptions && (
                        <div className={styles.businessCardContainerWrapper}>
                            {showCardsArrows && (
                                <div
                                    className={
                                        styles.businessCardArrowLeftWrapper
                                    }
                                    onClick={this.handleClickCardsArrowLeft}
                                >
                                    <img
                                        className={styles.businessCardArrowLeft}
                                        src={MoveCardsArrow}
                                    />
                                </div>
                            )}
                            <div className={styles.businessCardFadeIn} />
                            <div
                                className={styles.businessCardContainer}
                                style={cardsTranslate}
                            >
                                {options.map((option, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={
                                                i === 3
                                                    ? styles.businessCardLast
                                                    : styles.businessCard
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.businessCardImageWrapper
                                                }
                                            >
                                                <img
                                                    alt="alt"
                                                    className={
                                                        styles.businessCardImage
                                                    }
                                                    src={option.image}
                                                />
                                            </div>

                                            <div
                                                className={
                                                    styles.businessCardTextHolder
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.businessCardTitle
                                                    }
                                                >
                                                    {i + 1}. {option.title}
                                                </div>
                                                <div
                                                    className={
                                                        i === 3
                                                            ? styles.businessCardSubTitleSmall
                                                            : styles.businessCardSubTitle
                                                    }
                                                >
                                                    {option.subTitle}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={styles.businessCardFadeOut} />
                            {showCardsArrows && (
                                <div
                                    className={
                                        styles.businessCardArrowRightWrapper
                                    }
                                    onClick={this.handleClickCardsArrowRight}
                                >
                                    <img
                                        className={
                                            styles.businessCardArrowRight
                                        }
                                        src={MoveCardsArrow}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div> */}
                <div className={styles.clientsHeaderContainer}>
                    <div className={styles.clientHeader}>All Clients</div>
                    <div className={styles.clientCount}>
                        {agencyClients.length} Clients
                    </div>
                </div>
                <div className={styles.clientsSubHeaderContainer}>
                    <div
                        className={
                            agencyClientsSelected === "active"
                                ? styles.clientSubHeaderActive
                                : styles.clientSubHeader
                        }
                        onClick={() =>
                            this.handleSelectAgencyClientsList("active")
                        }
                    >
                        Active
                        <span className={styles.clientCount}>
                            ({activeAgencyClients.length})
                        </span>
                    </div>
                    <div
                        className={
                            agencyClientsSelected === "archived"
                                ? styles.clientSubHeaderActive
                                : styles.clientSubHeader
                        }
                        onClick={() =>
                            this.handleSelectAgencyClientsList("archived")
                        }
                    >
                        Archived
                        <span className={styles.clientCount}>
                            ({archivedAgencyClients.length})
                        </span>
                    </div>
                    <input
                        id="client-searchbar"
                        className={styles.clientSearch}
                        placeholder="Search Clients By Name or Email..."
                        onChange={(e) => this.clientSearchBar(e)}
                    />
                    <div
                        onClick={() => this.handleClickAddClient()}
                        className={styles.addClientBtn}
                    >
                        + Add a New Client
                    </div>
                </div>

                <div className={styles.clientsListWrapper} id="clientsList">
                    {!clientSearchBarContent ? (
                        agencyClients.length > 0 ? (
                            clientsListComponent
                        ) : (
                            <div className={styles.noSearchResultsWrapper}>
                                <div
                                    className={styles.noSearchResultsImgWrapper}
                                >
                                    <img
                                        className={styles.noSearchResultsImg}
                                        src={EmptyClientsList}
                                        alt="empty clients list"
                                    />
                                </div>
                            </div>
                        )
                    ) : filteredClientsList.length > 0 ? (
                        filteredClientsList.map((client, i) => {
                            const location =
                                this.state.countries.find(
                                    (item) =>
                                        item.shortName ===
                                        client.clientdata.country
                                ) || {};
                            const selected =
                                clientSelected &&
                                clientSelected.id === client.id;
                            return (
                                <div
                                    style={{
                                        backgroundColor: selected
                                            ? "#FFE1B9"
                                            : "#FFFFFF",
                                    }}
                                    className={styles.clientOuterContainer}
                                    key={client.id}
                                >
                                    <div
                                        id={client.id}
                                        onClick={() =>
                                            this.handleClientSelected(client)
                                        }
                                        style={{
                                            backgroundColor: selected
                                                ? "#FFE1B9"
                                                : "#FFFFFF",
                                        }}
                                        className={styles.clientInnerContainer}
                                    >
                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                            }}
                                        >
                                            <div
                                                className={styles.clientNumber}
                                            >
                                                {i + 1}
                                            </div>
                                            <div
                                                className={
                                                    styles.clientIconWrapper
                                                }
                                            >
                                                <img
                                                    className={
                                                        styles.clientIcon
                                                    }
                                                    src={profilePng}
                                                    alt="profile icon"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.clientNameContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.clientName
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.clientNameText
                                                        }
                                                    >
                                                        {client.clientdata.name}{" "}
                                                    </div>
                                                    <span
                                                        className={
                                                            styles.accountType
                                                        }
                                                    >
                                                        {
                                                            client.clientdata
                                                                .clientType
                                                        }
                                                    </span>
                                                </div>
                                                {client.clientdata.name !==
                                                    `${client.clientdata.firstName} ${client.clientdata.lastName}` && (
                                                    <div
                                                        className={
                                                            styles.businessText
                                                        }
                                                    >
                                                        {`${client.clientdata.firstName} ${client.clientdata.lastName}`}
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        styles.businessText
                                                    }
                                                >
                                                    {client.clientdata.state},{" "}
                                                    {location.name}
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                width: "fit-content",
                                                display: "flex",
                                            }}
                                        >
                                            {/* <div
                                                className={
                                                    styles.clientEditButton
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.accessClientBaseline(
                                                        client
                                                    );
                                                }}
                                            >
                                                {client.baseline
                                                    ? "Edit Baseline"
                                                    : "Create Baseline"}
                                            </div> */}
                                            <div
                                                className={
                                                    styles.clientEditButton
                                                }
                                            >
                                                {client.scenarios.length}{" "}
                                                Scenarios{" "}
                                                <img
                                                    style={{
                                                        margin: "0px 10px",
                                                    }}
                                                    src={
                                                        selected
                                                            ? toggle
                                                            : collapse
                                                    }
                                                    alt="alt"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.clientSvgContainer
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleEditClient(
                                                        client
                                                    );
                                                }}
                                            >
                                                <img src={editSvg} alt="alt" />
                                            </div>
                                            <div
                                                className={
                                                    styles.clientSvgContainer
                                                }
                                            >
                                                <img
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.handleShowDeleteClientPrompt(
                                                            client
                                                        );
                                                    }}
                                                    src={deleteSvg}
                                                    alt="alt"
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.clientSvgContainer
                                                }
                                            >
                                                <img
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.handleClientArchived(
                                                            client
                                                        );
                                                    }}
                                                    src={ArchiveClientIcon}
                                                    alt="archived"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {selected && (
                                        <div
                                            className={
                                                styles.scenarioCardContainer
                                            }
                                        >
                                            <img
                                                alt="alt"
                                                onClick={() =>
                                                    this.handleArrowClick(
                                                        "left",
                                                        `client${i}Scenarios`
                                                    )
                                                }
                                                // onClick={() =>
                                                //     this.handleLeftClick()
                                                // }
                                                className={styles.arrowLeft}
                                                src={leftArrow}
                                            />
                                            <div
                                                id={`client${i}Scenarios`}
                                                // ref={this.myDivToFocus}
                                                className={
                                                    styles.cardsOuterContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.cardsInnerContainer
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.addCard
                                                        }
                                                        onClick={() => {
                                                            this.setState({
                                                                showAddScenario: true,
                                                            });
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                margin: "auto auto",
                                                            }}
                                                        >
                                                            <img
                                                                src={addSvg}
                                                                className={
                                                                    styles.addSvg
                                                                }
                                                                alt="alt"
                                                            />
                                                            <div
                                                                style={{
                                                                    margin: "5px auto",
                                                                    fontWeight:
                                                                        "600",
                                                                }}
                                                            >
                                                                Add New
                                                            </div>
                                                            <div>
                                                                Start a new
                                                                scenario with
                                                                this client’s
                                                                baseline
                                                                information
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {scenarios &&
                                                        scenarios.map(
                                                            (item) => {
                                                                if (!item)
                                                                    return (
                                                                        <div />
                                                                    );
                                                                else
                                                                    return this.getScenarioCard(
                                                                        item
                                                                    );
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                            <img
                                                alt="alt"
                                                onClick={() =>
                                                    this.handleArrowClick(
                                                        "right",
                                                        `client${i}Scenarios`
                                                    )
                                                }
                                                // onClick={() =>
                                                //     this.handleRightClick()
                                                // }
                                                className={styles.arrow}
                                                src={rightArrow}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <div className={styles.noSearchResultsWrapper}>
                            <div className={styles.noSearchResultsImgWrapper}>
                                <img
                                    className={styles.noSearchResultsImg}
                                    src={NoResultsImg}
                                    alt="no results from search"
                                />
                            </div>
                        </div>
                    )}
                </div>
                {showClientModal && (
                    <EventsPopUp
                        onHandleClose={this.toggleAddClient}
                        specificClass={""}
                    >
                        <ClientInput
                            updateClient={this.handleUpdateClient}
                            addClient={this.handleAddClient}
                            editData={editData}
                            agencyClients={this.props.agencyClients}
                        />
                    </EventsPopUp>
                )}
                {this.state.toastData && (
                    <EventsToast
                        data={this.state.toastData}
                        close={this.cancelToast}
                        history={this.props.history}
                    >
                        {this.state.clientToDelete && (
                            <div className="promptButtonContainer">
                                <div
                                    onClick={this.cancelToast}
                                    className="deletePromptButton"
                                >
                                    Cancel
                                </div>
                                <div
                                    onClick={() =>
                                        this.handleDeleteClient(
                                            this.state.clientToDelete &&
                                                this.state.clientToDelete
                                                    .clientdata
                                        )
                                    }
                                    className="deletePromptButton"
                                >
                                    Yes
                                </div>
                            </div>
                        )}
                    </EventsToast>
                )}
            </div>
        );
    }
}

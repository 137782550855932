import React, { Component } from "react";
import "./Card.css";
import moment from "moment";
import { getRelevantEntities } from "actions/getNodeEntityActions";

class HouseCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];
        const { address, lat, lng } = firstEntity.data;
        if (address && lat && lng) {
            this.props.getWalkScore(address, lat, lng, (err, data) => {
                // console.log(err, "<--- err");
                // console.log(data, "<--- dataaa");
                if (err) {
                    console.log(err, "<-- walkscore");
                } else {
                    this.setState({ walkScoreData: data });
                }
            });
        }
    }

    // componentDidUpdate(prevProps) {
    //     const entities = getRelevantEntities(this.props.data.entities)
    //     const firstEntity = Object.values(entities)[0];
    //     if (prevProps.data.id !== this.props.data.id) {
    //         const { address, lat, lng } = this.props.data.metadata;
    //         if (address && lat && lng) {
    //             this.props.getWalkScore(address, lat, lng, (err, data) => {
    //                 if (err) {
    //                     console.log(err, "<-- walkscore");
    //                 } else {
    //                     this.setState({ walkScoreData: data });
    //                 }
    //             });
    //         }
    //     }
    // }
    render() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];
        const { walkScoreData } = this.state;
        const { address, actualHouse, price, mlsNumber, url } =
            firstEntity.data;
        const { version } = firstEntity;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="locationData">
                        <span className="highlighted">{address}</span>
                    </div>
                </div>
                {walkScoreData && (
                    <div className="Row">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={walkScoreData.ws_link}
                        >
                            <img alt="alt" src={walkScoreData.logo_url} />
                        </a>
                        <div className="walkScoreValue">
                            {walkScoreData.walkscore}
                        </div>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={walkScoreData.more_info_link}
                        >
                            <img alt="alt" src={walkScoreData.more_info_icon} />
                        </a>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Type</div>
                    <div className="cardValue">
                        {actualHouse ? "Actual House" : "Placeholder House"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Purchase Price</div>
                    <div className="cardNumber">
                        {price ? `$ ${price}` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">MLS</div>
                    <div className="cardValue">
                        {mlsNumber ? `# ${mlsNumber}` : ""}
                    </div>
                </div>

                <div className="Row">
                    <div className="ExpenseLabel">URL:</div>
                    <a
                        className="ExpenseLink"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {url}
                    </a>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Date</div>
                    <div className="cardValue">
                        {this.props.data.metadata["first payment"]
                            ? moment(
                                  this.props.data.metadata["first payment"]
                              ).format("MMM D YYYY")
                            : "No Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default HouseCard;

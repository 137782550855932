import type {
    EventsSchema,
    EventsInputSchema,
} from "reducers/typesSchema/eventsSchema";
import type { AppThunk } from "store";
import { eventInitialState } from "reducers/typesSchema/eventsSchema";

export const UPSERT_EVENTS = "events/UPSERT_EVENTS";
export const SET_EVENTS = "entities/SET_EVENTS";

export function upsertEvents(newEvents: EventsInputSchema): AppThunk<void> {
    return (dispatch, getState) => {
        const curEvents = getState().events;

        const newEntities: EventsSchema = {};
        Object.entries(newEvents).forEach(([eventId, event]) => {
            newEntities[eventId] = {
                ...eventInitialState,
                ...curEvents[eventId],
                ...event,
            };
        });

        dispatch({
            type: UPSERT_EVENTS,
            payload: newEntities,
        });
    };
}

export const setEvents = (newEvents: EventsSchema) => ({
    type: SET_EVENTS,
    payload: newEvents,
});

export function removeEvents(eventIds: string[]): AppThunk<void> {
    return (dispatch, getState) => {
        const eventsCopy: EventsSchema = { ...getState().events };
        eventIds.forEach((eventId) => {
            delete eventsCopy[eventId];
        });

        dispatch(setEvents(eventsCopy));
    };
}

export const getEvents = (): AppThunk<EventsSchema> => (_dispatch, getState) =>
    getState().events;

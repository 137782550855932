// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    EntityName,
    FillAreaTextBlock,
    BasicHeading,
    Rate,
    ModalColumn,
    MuiCalendar,
} from "../Components";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { useState } from "react";

const textBlockCopy =
    "The number of organic website visitors that come to the website each month via non-paid channels (such as SEO, content marketing, referrals). Use the Campaign Event to model cost-per-click paid traffic.";

const WebstieVisitorsInputView = ({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    edit,
    handleEntityStateChange,
    onHandleClickSubmitOrUpdate,
    handleOnChange,
    handleDateSelection,
}) => {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    const entityData = entitiesMap[currentEntity].data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={websiteVisitorsObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={websiteVisitorsObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[websiteVisitorsObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                {creatingCustomAccounts && (
                    <div>Creating custom accounts...</div>
                )}
                {!creatingCustomAccounts && (
                    <>
                        <ModalRow>
                            <BasicHeading copy="Organic Website Traffic" />
                        </ModalRow>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <ModalColumn>
                                    <ModalRow>
                                        <MuiCalendar
                                            id="startDate"
                                            value={
                                                entitiesMap?.[currentEntity]
                                                    ?.startDate
                                            }
                                            required={true}
                                            onChangeInput={handleDateSelection}
                                            label="Start Date"
                                        />
                                    </ModalRow>
                                    <ModalRow>
                                        <Rate
                                            label="Initial Monthly Visits"
                                            onChangeInput={handleOnChange}
                                            value={entityData.value}
                                            id="initialVisits"
                                            className="mlsInput"
                                            sign={false}
                                            required={true}
                                        />
                                    </ModalRow>
                                </ModalColumn>
                            </ModalRowHalf>

                            <ModalRowHalf>
                                <FillAreaTextBlock copy={textBlockCopy} />
                            </ModalRowHalf>
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmitValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmit={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                passedCheck={creatingCustomAccounts ? false : passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default WebstieVisitorsInputView;

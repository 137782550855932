import React, { Component } from "react";
import "./InputContainer.css";
import Switch from "react-switch";
import Calendar from "../Calendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    Description,
    SelectDropDown,
    SelectDropDownPerson,
    Value,
    Url,
    InputButtons,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    StarsRating,
    InflationSwitch,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { getPresentableDependenciesOfManyTypes } from "../../helpers/nodeDependencyDetectionHelpers";
import { Me, Person } from "../../helpers/constants";
import { incomeObject } from "../Registry/Income";

const options = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

const incomeTypes = [
    "Employment Income",
    "Self Employment Income",
    "Capital Gains",
    "Eligible Dividends",
    "Ineligible Dividends",
    "Rental Income",
    "Other Income",
];

class IncomeInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            income: "",
            url: "",
            start: null,
            end: null,
            rating: 0,
            showOptions: false,
            name: getDefaultName(incomeObject.name(), this.props),
            description: "",
            cadence: "",
            incomeType: "",
            type: incomeObject.constant(),
            value: null,
            showSettings: false,
            tag: `@${incomeObject.name()}`,
            onHoverStar: null,
            inflation: false,
            baseline: false,
            people: this.getPeople(props),
            personId: "",
            personName: "",
            curPersonNodes: "",
            disableOnRetirement: false,
            rentalExpenses: 0,
        };
    }

    getPeople = (props = this.props) => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            props,
            "Person"
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        return people;
    };

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData, people: this.getPeople() });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "value":
                this.setState({
                    income: value,
                    value: parseInt(value - this.state.rentalExpenses),
                });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "cadence":
                if (value === "") {
                    this.setState({ cadence: "" });
                } else {
                    this.setState({ cadence: value });
                }
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "incomeType":
                if (value === "Select Income Type") {
                    this.setState({ incomeType: "" });
                } else {
                    this.setState({ incomeType: value });
                }
                break;
            case "person":
                if (value === "Select Person" || !value) {
                    this.setState({ personName: "", personId: null });
                } else {
                    this.setState({
                        personName: value,
                        personId: this.state.people[value],
                    });
                }
                break;
            case "rentalExpenses":
                this.setState({
                    rentalExpenses: parseInt(value),
                    value: parseInt(this.state.income - value),
                });
                break;
            default:
        }
    };

    onHandleDate = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    onHandleSubmitValues = () => {
        const passedCheck = incomeObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onToggleCheckBox = () => {
        this.setState({ disableOnRetirement: !this.state.disableOnRetirement });
    };
    // scrollToBottom = () => {
    //     this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // };

    render() {
        const passedCheck = incomeObject.checkInput(this.state);
        const { edit } = this.props;

        const {
            name,
            cadence,
            description,
            income,
            incomeType,
            personName,
            url,
            rating,
            start,
            end,
            onHoverStar,
            disableOnRetirement,
            rentalExpenses,
        } = this.state;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={incomeObject.name()}
                        onChangeInput={this.onChangeInput}
                        image={incomeObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <RequiredStar />
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={cadence}
                                options={options}
                                className="select-dropdown"
                                id="cadence"
                                label="Select Frequency"
                            />
                        </ModalRowHalf>

                        <ModalRowHalf>
                            <RequiredStar />
                            <Value
                                label="Income Amount"
                                onChangeInput={this.onChangeInput}
                                value={income}
                                id="value"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    {incomeType === "Rental Income" ? (
                        <ModalRow twoInputs>
                            <RequiredStar />
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={incomeType}
                                    options={incomeTypes}
                                    className="IncomeSelect"
                                    id="incomeType"
                                    label="Select Income Type"
                                />
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <Value
                                    label="Rental Expenses"
                                    onChangeInput={this.onChangeInput}
                                    value={rentalExpenses}
                                    id="rentalExpenses"
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    ) : (
                        <ModalRow>
                            <RequiredStar />
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={incomeType}
                                options={incomeTypes}
                                className="IncomeSelect"
                                id="incomeType"
                                label="Select Income Type"
                            />
                        </ModalRow>
                    )}
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDownPerson
                            onChangeInput={this.onChangeInput}
                            value={personName}
                            options={Object.keys(this.state.people)}
                            className="IncomeSelect"
                            id="person"
                            label="Select Person"
                        />
                    </ModalRow>
                    <ModalRow>
                        <Url
                            value={url}
                            className="UrlLink"
                            onChangeInput={this.onChangeInput}
                            id="url"
                            label="URL"
                        />
                    </ModalRow>
                    <ModalRow>
                        <Switch
                            id="disableOnRetirement"
                            checked={disableOnRetirement}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={this.onToggleCheckBox}
                        />
                        <div className="incomeText">
                            Disable Income Upon Retirement
                        </div>
                    </ModalRow>

                    <ModalRow>
                        <RequiredStar />
                        <Calendar
                            onHandleDate={this.onHandleDate}
                            startDate={start}
                            endDate={end || null}
                            cadence={cadence}
                        />
                    </ModalRow>
                    <ModalRow>
                        <StarsRating
                            onHoverStar={onHoverStar}
                            onHandleStarHover={this.onHandleStarHover}
                            rating={rating}
                            onChangeInput={this.onChangeInput}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ModalRow>

                    <ModalRow>
                        <InflationSwitch
                            applyInflation={this.state.inflation}
                            toggleInflation={this.updateInflation}
                        />
                    </ModalRow>
                    {this.state.showRequired && (
                        <ModalRow>
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </ModalRow>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default IncomeInput;

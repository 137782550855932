import React, { Component } from "react";
import ".././SignUp.css";
import LinkIcon from "../../../Assets/_signUpIcons/link.svg";

export default class RightPanel extends Component {
    state = {};

    render() {
        const { planDetails } = this.props;
        return (
            <div className="sign-up__right-panel-wrapper">
                <div className="sign-up__right-panel-content-wrapper">
                    <div className="sign-up__right-panel-header-wrapper">
                        <h3 className="sign-up__right-panel-title">
                            {planDetails.accountTitle}
                        </h3>
                        <div className="sign-up__right-panel-header-text-wrapper">
                            <div className="sign-up__right-panel-header-text">
                                {planDetails.accountDescription.map((descriptionSegment, i) => (
                                    <span key={i}>
                                        {descriptionSegment}
                                    </span>))}
                            </div>
                            {/* <div className="sign-up__right-panel-header-cta-wrapper">
                                <span
                                    className="sign-up__right-panel-header-text-cta"
                                    onClick={this.props.toggleShowVideo}
                                >
                                    Want to know more? Here&apos;s the video!
                                </span>
                                <img
                                    className="sign-up__right-panel-header-text-cta-icon"
                                    src={LinkIcon}
                                    alt="leave current page"
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="sign-up__right-panel-main-content-wrapper">
                        <div className="sign-up__right-panel-left-side-content">
                            <div className="sign-up__right-panel-left-side-img-text-wrapper">
                                {/* <img
                                    className="sign-up__right-panel-left-side-img"
                                    src={planDetails.featureOneIcon}
                                    alt="guide boards"
                                /> */}
                                <div className="sign-up__right-panel-left-side-text-wrapper">
                                    <h4 className="sign-up__right-panel-left-side-text-title">
                                        {planDetails.featureOneTitle}
                                    </h4>
                                    {/* <p className="sign-up__right-panel-left-side-text">
                                        {planDetails.featureOneDescription}
                                    </p> */}
                                </div>
                            </div>
                            <div className="sign-up__right-panel-left-side-img-text-wrapper">
                                {/* <img
                                    className="sign-up__right-panel-left-side-img"
                                    src={planDetails.featureTwoIcon}
                                    alt="calculator"
                                /> */}
                                <div className="sign-up__right-panel-left-side-text-wrapper">
                                    <h4 className="sign-up__right-panel-left-side-text-title">
                                        {planDetails.featureTwoTitle}
                                    </h4>
                                    {/* <p className="sign-up__right-panel-left-side-text">
                                        {planDetails.featureTwoDescription}
                                    </p> */}
                                </div>
                            </div>
                            <div className="sign-up__right-panel-left-side-img-text-wrapper">
                                {/* <img
                                    className="sign-up__right-panel-left-side-img"
                                    src={planDetails.featureThreeIcon}
                                    alt="bulb"
                                /> */}
                                <div className="sign-up__right-panel-left-side-text-wrapper">
                                    <h4 className="sign-up__right-panel-left-side-text-title">
                                        {planDetails.featureThreeTitle}
                                    </h4>
                                    {/* <p className="sign-up__right-panel-left-side-text">
                                        {planDetails.featureThreeDescription}
                                    </p> */}
                                </div>
                            </div>
                            <div className="sign-up__right-panel-left-side-img-text-wrapper">
                                {/* <img
                                    className="sign-up__right-panel-left-side-img"
                                    src={planDetails.featureFourIcon}
                                    alt="hand with currency"
                                /> */}
                                <div className="sign-up__right-panel-left-side-text-wrapper">
                                    <h4 className="sign-up__right-panel-left-side-text-title">
                                        {planDetails.featureFourTitle}
                                    </h4>
                                    {/* <p className="sign-up__right-panel-left-side-text">
                                        {planDetails.featureFourDescription}
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className="sign-up__right-panel-right-side-content">
                            <div className="sign-up__right-panel-right-side-img-wrapper">
                                <img
                                    className="sign-up__right-panel-right-side-img"
                                    src={planDetails.planImg}
                                    alt="plan graphic"
                                />
                            </div>
                        </div>
                    </div>
                    {planDetails.buttonsEnabled ? (
                        <div className="sign-up__right-panel-buttons-wrapper">
                            <a
                                className="sign-up__right-panel-buttons-button1-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={planDetails.learnMoreLink}
                            >
                                <div className="sign-up__right-panel-buttons-button1">
                                    <span className="sign-up__right-panel-buttons-button1-text">
                                        {planDetails.buttonOneText}
                                    </span>
                                    <img
                                        className="sign-up__right-panel-button1-img"
                                        src={LinkIcon}
                                        alt="leave current page"
                                    />
                                </div>
                            </a>
                            <div
                                className="sign-up__right-panel-buttons-button2"
                                onClick={() =>
                                    this.props.onClickSignUp(
                                        planDetails.buttonType
                                    )
                                }
                            >
                                <span className="sign-up__right-panel-buttons-button2-text">
                                    {planDetails.buttonTwoText}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="sign-up__right-panel-buttons-wrapper">
                            <div className="sign-up__right-panel-buttons-button1-inactive">
                                <span className="sign-up__right-panel-buttons-button2-text">
                                    {planDetails.buttonOneText}
                                </span>
                            </div>
                            <div className="sign-up__right-panel-buttons-button1-inactive">
                                <span className="sign-up__right-panel-buttons-button2-text">
                                    {planDetails.buttonTwoText}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

import styles from "./index.module.css";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { useMemo, useState, useEffect } from "react";
import { shallowEqual } from "react-redux";
import { AvailableAccount } from "./availableAccount";
import { AccountLevelSection } from "./accountLevelSection";
import { upsertGraphAccountSelection } from "actions/graphAccountSelectionActions";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import { closeAccountPickerModal } from "actions/accountPickerActions";
import {
    SelectedTopLevelAccountContext,
    SetSelectedTopLevelAccountContext,
    SelectedSubAccountContext,
    SetSelectedSubAccountContext,
    SelectedLeafAccountContext,
    SetSelectedLeafAccountContext,
    SelectedLeafSubAccountContext,
    SetSelectedLeafSubAccountContext,
} from "./accountPickerContexts";

const mainLedgerAccounts = {
    "211dd944-359f-4fbe-af1f-0c761afa1e67": true,
    "6c9640aa-abf5-4f19-b135-356e183bcce6": true,
    "488dd61d-8697-4213-8978-cf91755365a4": true,
    "7faf0285-78ca-411b-b875-d900929d7c94": true,
    "001a1288-cefb-4bcb-97ce-510b8a668a19": true,
};

const mainCalculatedAccounts = {
    "5a508f09-cf61-4207-8950-28a32be3a0d8": true,
    "df3f629d-dfa9-40e9-b083-58119d82ec4b": true,
    "2b5b218c-345b-48f9-8b4f-2992978acfd5": true,
    "4597dc96-dff1-48e3-b992-89ecf8476274": true,
    "db178274-8d95-4a6a-ae5c-46ae2fc356dd": true,
    "b2623ec6-3492-4fa0-9dcd-0150c3443cb0": true,
    "e18faebd-5cb1-40ac-8c18-442943677d4a": true,
    "852b5a0b-2a32-4817-867c-cca92295141b": true,
    "8762235d-676a-4ef5-be5e-9c5a27b301da": true,
    "3ebf3eb4-6cdf-4889-8796-435cb9bf0939": true,
    "8175db4a-66e9-4cd6-866b-b8ae06bddd74": true,
};

const cashflowUUID = "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78";
const calcEquityUUID = "a4d432a8-26ab-41f0-9008-88ae2d171378";
const profitUUID = "4db53c56-db20-4798-9830-adbfcc977b26";

const StyledTextField = styled(TextField)<TextFieldProps>(() => ({
    "& label.Mui-focused": {
        color: "rgba(0, 0, 0, 0.6)",
    },
    "& .MuiOutlinedInput-root": {
        "& .MuiInputBase-inputAdornedStart": {
            padding: "9.5px 14px 9.5px 0px",
        },
        "&.Mui-focused fieldset": {
            borderColor: "rgba(0, 0, 0, 0.23)",
        },
    },
}));

export const AccountPickerModal = () => {
    const dispatch = useAppDispatch();

    const {
        allAccountData,
        relevantLedgers,
        listedAccountTypes,
        selectedAccountTypes,
        selectedAccountsHashMap,
        favouriteAccountsArray,
    } = useAppSelector((state) => {
        const allAccountData = state?.allAccountLedgers?.ledgersMetadata;
        const relevantLedgers =
            state?.allAccountLedgers?.relevantLedgers?.filter(
                (ledger) =>
                    !(
                        ledger.id === cashflowUUID ||
                        ledger.id === calcEquityUUID ||
                        ledger.id === profitUUID
                    )
            ) ?? [];
        const listedAccountTypes =
            state?.graphAccountSelection?.listedAccountTypes ?? [];
        const selectedAccountTypes: any[] =
            state?.graphAccountSelection?.selectedAccountTypes ?? [];

        const selectedAccountsHashMap = {};
        selectedAccountTypes?.map(
            (account) => (selectedAccountsHashMap[account?.id] = account)
        );

        const loadedScenario = state?.scenario?.loadedScenario;
        const favouriteAccountsArray = Object.keys(
            loadedScenario?.data?.favouriteAccounts ?? {}
        );

        return {
            allAccountData,
            relevantLedgers,
            listedAccountTypes,
            selectedAccountTypes,
            selectedAccountsHashMap,
            favouriteAccountsArray,
        };
    }, shallowEqual);

    const [searchValue, setSearchValue] = useState("");

    const { filteredLedgerAccounts, filteredCalculatedAccounts } =
        useMemo(() => {
            const filteredLedgerAccounts =
                relevantLedgers?.filter(
                    (ledger) => mainLedgerAccounts[ledger.id]
                ) ?? [];
            const filteredCalculatedAccounts =
                relevantLedgers?.filter(
                    (ledger) => mainCalculatedAccounts[ledger.id]
                ) ?? [];
            return { filteredLedgerAccounts, filteredCalculatedAccounts };
        }, [relevantLedgers]);

    const searchedAccounts = useMemo(
        () =>
            relevantLedgers?.filter((account) => {
                const accountName = account?.name?.toLowerCase();
                return accountName.includes(searchValue.toLowerCase());
            }),
        [relevantLedgers, searchValue]
    );

    const [selectedTopLevelAccount, setSelectedTopLevelAccount] =
        useState<string>(filteredLedgerAccounts?.[0]?.id ?? "");
    const [selectedSubAccount, setSelectedSubAccount] = useState<string>("");
    const [selectedLeafAccount, setSelectedLeafAccount] = useState<string>("");
    const [selectedLeafSubAccount, setSelectedLeafSubAccount] =
        useState<string>("");

    const handleExpansion = (accountLevel, accountId) => {
        switch (accountLevel) {
            case "topLevel":
                if (accountId === selectedTopLevelAccount) {
                    setSelectedTopLevelAccount("");
                    setSelectedSubAccount("");
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                } else {
                    setSelectedTopLevelAccount(accountId);
                    setSelectedSubAccount("");
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                }
                break;
            case "subAccount":
                if (accountId === selectedSubAccount) {
                    setSelectedSubAccount("");
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                } else {
                    setSelectedSubAccount(accountId);
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                }
                break;
            case "leafAccount":
                if (accountId === selectedLeafAccount) {
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                } else {
                    setSelectedLeafAccount(accountId);
                    setSelectedLeafSubAccount("");
                }
                break;
            case "leafSubAccount":
                if (accountId === selectedLeafSubAccount) {
                    setSelectedLeafSubAccount("");
                } else {
                    setSelectedLeafSubAccount(accountId);
                }
                break;
        }
    };

    const handleSelectAccount = (accountId) => {
        const accountData = allAccountData?.[accountId];
        if (selectedAccountsHashMap[accountData.id]) {
            delete selectedAccountsHashMap[accountData.id];
        } else {
            selectedAccountsHashMap[accountData.id] = accountData;
        }
        dispatch(
            upsertGraphAccountSelection({
                selectedAccountTypes: Object.values(selectedAccountsHashMap),
            })
        );
    };

    const handleAddAccounts = () => {
        const selectedAccounts = selectedAccountTypes?.filter(
            (account) => !listedAccountTypes?.includes(account)
        );

        const previouslyListedAcountTypes =
            listedAccountTypes?.concat(selectedAccounts);

        dispatch(
            upsertGraphAccountSelection({
                listedAccountTypes: previouslyListedAcountTypes,
            })
        );
        dispatch(closeAccountPickerModal());
    };

    const getFixedAccountInOrder = (): any[] => {
        const fixedAccountsMap = {};
        const displayAccountOrder = [
            { name: "Cashflow", id: cashflowUUID },
            {
                name: "Calculated Equity",
                id: calcEquityUUID,
            },
            { name: "Proft", id: profitUUID },
        ];

        relevantLedgers?.map((account) => {
            if (allAccountData?.[account.id]?.alwaysDisplayed) {
                fixedAccountsMap[account.id] = account;
            }
        });

        const fixedAccountTypes: any[] = [];
        displayAccountOrder.forEach((account) => {
            if (fixedAccountsMap[account.id]) {
                fixedAccountTypes.push(account);
                delete fixedAccountsMap[account.id];
            }
        });

        Object.values(fixedAccountsMap).forEach((account: any) => {
            fixedAccountTypes.push({ name: account.name, id: account.id });
        });

        return fixedAccountTypes;
    };

    const clearAccountSelection = () => {
        const fixedAccountTypes = getFixedAccountInOrder();

        dispatch(
            upsertGraphAccountSelection({
                listedAccountTypes: fixedAccountTypes,
                selectedAccountTypes: [],
            })
        );
    };

    const displayAccountLayout = useMemo(() => {
        if (searchValue !== "" && searchedAccounts) {
            return searchedAccounts?.map((account) => (
                <AvailableAccount
                    key={account.id}
                    selectAccount={handleSelectAccount}
                    id={account.id}
                    handleExpand={() => {
                        return;
                    }}
                    accountLevel="searched"
                />
            ));
        } else {
            return (
                <div className={styles.relatedAccountsNestContainer}>
                    <AccountLevelSection
                        accountLevel="subAccount"
                        handleExpand={handleExpansion}
                        selectAccount={handleSelectAccount}
                    />
                    <AccountLevelSection
                        accountLevel="leafAccount"
                        handleExpand={handleExpansion}
                        selectAccount={handleSelectAccount}
                    />
                    <AccountLevelSection
                        accountLevel="leafSubAccount"
                        handleExpand={handleExpansion}
                        selectAccount={handleSelectAccount}
                    />
                    <AccountLevelSection
                        accountLevel="fifthLevelAccount"
                        handleExpand={handleExpansion}
                        selectAccount={handleSelectAccount}
                    />
                </div>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, searchedAccounts]);

    const [widthState, setWidthState] = useState(244);
    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const ratio = window.devicePixelRatio;

            const pxChange = e.movementX / ratio;

            setWidthState(widthState + pxChange);
        };

        if (mouseDown) {
            window.addEventListener("mousemove", handleMouseMove);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [mouseDown, widthState]);

    useEffect(() => {
        const handleMouseUp = () => setMouseDown(false);

        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    return (
        <SelectedTopLevelAccountContext.Provider
            value={selectedTopLevelAccount}
        >
            <SetSelectedTopLevelAccountContext.Provider
                value={setSelectedTopLevelAccount}
            >
                <SelectedSubAccountContext.Provider value={selectedSubAccount}>
                    <SetSelectedSubAccountContext.Provider
                        value={setSelectedSubAccount}
                    >
                        <SelectedLeafAccountContext.Provider
                            value={selectedLeafAccount}
                        >
                            <SetSelectedLeafAccountContext.Provider
                                value={setSelectedLeafAccount}
                            >
                                <SelectedLeafSubAccountContext.Provider
                                    value={selectedLeafSubAccount}
                                >
                                    <SetSelectedLeafSubAccountContext.Provider
                                        value={setSelectedLeafSubAccount}
                                    >
                                        <div
                                            className={styles.primaryContainer}
                                        >
                                            <div className={styles.header}>
                                                <h1
                                                    className={
                                                        styles.headerTitle
                                                    }
                                                >
                                                    Account Selector
                                                </h1>
                                                <StyledTextField
                                                    label="Search for an Account"
                                                    value={searchValue}
                                                    onChange={(e) =>
                                                        setSearchValue(
                                                            e?.target?.value ??
                                                                ""
                                                        )
                                                    }
                                                    focused
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <VisibilityIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    styles.contentContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.leftContainer
                                                    }
                                                    style={{
                                                        width: widthState,
                                                    }}
                                                >
                                                    {filteredLedgerAccounts?.length >
                                                        0 && (
                                                        <div
                                                            className={
                                                                styles.accountGroup
                                                            }
                                                        >
                                                            <h3
                                                                className={
                                                                    styles.accountGroupTitle
                                                                }
                                                            >
                                                                Ledger Accounts
                                                            </h3>
                                                            {filteredLedgerAccounts.map(
                                                                (
                                                                    ledgerAccount
                                                                ) => (
                                                                    <AvailableAccount
                                                                        key={
                                                                            ledgerAccount?.id
                                                                        }
                                                                        selectAccount={
                                                                            handleSelectAccount
                                                                        }
                                                                        id={
                                                                            ledgerAccount.id
                                                                        }
                                                                        handleExpand={
                                                                            handleExpansion
                                                                        }
                                                                        accountLevel="topLevel"
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                    {filteredCalculatedAccounts?.length >
                                                        0 && (
                                                        <div
                                                            className={`${
                                                                filteredLedgerAccounts?.length >
                                                                0
                                                                    ? `${styles.accountGroup} ${styles.accountGroup_divider}`
                                                                    : `${styles.accountGroup}`
                                                            }`}
                                                        >
                                                            <h3
                                                                className={
                                                                    styles.accountGroupTitle
                                                                }
                                                            >
                                                                Calculated
                                                                Accounts
                                                            </h3>
                                                            {filteredCalculatedAccounts.map(
                                                                (
                                                                    calculatedAccount
                                                                ) => (
                                                                    <AvailableAccount
                                                                        key={
                                                                            calculatedAccount?.id
                                                                        }
                                                                        selectAccount={
                                                                            handleSelectAccount
                                                                        }
                                                                        id={
                                                                            calculatedAccount.id
                                                                        }
                                                                        handleExpand={
                                                                            handleExpansion
                                                                        }
                                                                        accountLevel="topLevel"
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                    {favouriteAccountsArray?.length >
                                                        0 && (
                                                        <div
                                                            className={`${
                                                                favouriteAccountsArray?.length >
                                                                0
                                                                    ? `${styles.accountGroup} ${styles.accountGroup_divider}`
                                                                    : `${styles.accountGroup}`
                                                            }`}
                                                        >
                                                            <h3
                                                                className={
                                                                    styles.accountGroupTitle
                                                                }
                                                            >
                                                                Favourite
                                                                Accounts
                                                            </h3>
                                                            {favouriteAccountsArray.map(
                                                                (
                                                                    favouriteAccountId
                                                                ) => (
                                                                    <AvailableAccount
                                                                        key={
                                                                            favouriteAccountId
                                                                        }
                                                                        selectAccount={
                                                                            handleSelectAccount
                                                                        }
                                                                        id={
                                                                            favouriteAccountId
                                                                        }
                                                                        handleExpand={
                                                                            handleExpansion
                                                                        }
                                                                        accountLevel="topLevel"
                                                                    />
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            styles.verDragBar
                                                        }
                                                        onMouseDown={() =>
                                                            setMouseDown(true)
                                                        }
                                                    ></div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.rightContainer
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.relatedAccountsContainer
                                                        }
                                                    >
                                                        {displayAccountLayout}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    styles.lowerContainer
                                                }
                                            >
                                                <button
                                                    className={
                                                        styles.clearButton
                                                    }
                                                    onClick={
                                                        clearAccountSelection
                                                    }
                                                >
                                                    clear selection
                                                </button>
                                                <button
                                                    className={styles.addButton}
                                                    onClick={handleAddAccounts}
                                                >{`add ${
                                                    selectedAccountTypes?.length ??
                                                    0
                                                }`}</button>
                                            </div>
                                        </div>
                                    </SetSelectedLeafSubAccountContext.Provider>
                                </SelectedLeafSubAccountContext.Provider>
                            </SetSelectedLeafAccountContext.Provider>
                        </SelectedLeafAccountContext.Provider>
                    </SetSelectedSubAccountContext.Provider>
                </SelectedSubAccountContext.Provider>
            </SetSelectedTopLevelAccountContext.Provider>
        </SelectedTopLevelAccountContext.Provider>
    );
};

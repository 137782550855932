import styles from "./homeRefactor.module.css";
import oldStyles from "./index.module.css";
import { useState } from "react";
import CreateScenarioModal from "../../Components/CreateScenarioModal";
import addSvg from "../../Assets/_home/add.svg";
import expandSvg from "../../Assets/scenarioIcons/expand.svg";
import collapseSvg from "../../Assets/scenarioIcons/collapse.svg";
import Modal from "@mui/material/Modal";

export default function HomeBusiness(props) {
    const [gettingStartedOpen, setGettingStartedOpen] = useState(true);
    const [userScenariosOpen, setUserScenariosOpen] = useState(false);
    const [sharedScenariosOpen, setSharedScenariosOpen] = useState(false);
    const [showAddScenario, setShowAddScenario] = useState(false);

    const closeScenarioCreation = () => {
        setShowAddScenario(false);
    };

    const createScenario = (
        name,
        description,
        range,
        inflation,
        client,
        explainerVideoLink,
        subtitle,
        custom_start_date,
        start_date_preference
    ) => {
        props.manager.addStart();
        const normalData = props.manager.exportData();
        const normalScenario = {
            name,
            description,
            data: normalData,
            range,
            inflation,
            explainerVideoLink,
            subtitle,
            custom_start_date,
            start_date_preference,
            dependency_map: {},
        };

        props.createScenario(normalScenario, range, (data) => {
            if (data) {
                props.getUserScenarios();
            }
        });
        // loadNewScenario(normalScenario);
        // onHandleScenarioCountToast();
        // setState({ scenarioCreatedFromHome: true });
    };

    const handleClickSampleScenarioCard = (scenarioData) => {
        props.history.push("/");
        props.selectTab("/");
        props.handleClickSampleScenarioCard(scenarioData);
    };

    const loadClientScenario = (scenario) => {
        props.baselineDataManager.setIsFirstLoad(true);
        localStorage.setItem("loadedScenario", JSON.stringify(scenario));
        props.loadScenario(
            scenario,
            props.manager,
            props.baselineDataManager,
            null,
            props.eventLibrary
        );
        props.history.push("/");
    };

    const handleScroll = (id) => {
        setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        }, 200);
    };

    const getScenarioCard = (item) => {
        return (
            <div
                key={item.id}
                className={styles.scenarioCard}
                onClick={() => loadClientScenario(item)}
            >
                <div className={styles.scenarioCardUpper}>
                    <div className={styles.scenarioCardHeader}>
                        <h5 className={styles.scenarioCardHeaderText}>
                            {item.name}
                        </h5>
                    </div>
                    <p className={styles.scenarioCardDescription}>
                        {item.description || <i>no description</i>}
                    </p>
                </div>
                <div className={styles.scenarioCardLower}>
                    <div className={styles.scenarioCardDetails}>
                        <h5 className={styles.scenarioCardDetailsText}>
                            Scenario Range: {item.range} yrs.
                        </h5>
                        <h5 className={styles.scenarioCardDetailsText}>
                            Inflation: {item.inflation * 100} %
                        </h5>
                    </div>
                </div>
            </div>
        );
    };

    const scenarioCards = props.sampleScenarios
        .filter((scenario) => !scenario?.is_tutorial)
        .map((sampleScenario) => {
            return (
                <div
                    key={sampleScenario.id}
                    className={oldStyles.sampleScenarioCardWrapper}
                    onClick={() =>
                        handleClickSampleScenarioCard(sampleScenario)
                    }
                >
                    <div className={oldStyles.sampleScenarioCardTopWrapper}>
                        <h3 className={oldStyles.sampleScenarioCardTopHeader}>
                            Sample Scenario
                        </h3>
                    </div>
                    <div className={oldStyles.sampleScenarioCardBottomWrapper}>
                        <div
                            className={
                                oldStyles.sampleScenarioCardBottomTextWrapper
                            }
                        >
                            <span
                                className={
                                    oldStyles.sampleScenarioCardBottomTextOne
                                }
                            >
                                {sampleScenario.name}
                            </span>
                            <span
                                className={
                                    oldStyles.sampleScenarioCardBottomTextTwo
                                }
                            >
                                {sampleScenario.subtitle}
                            </span>
                            <div
                                className={
                                    oldStyles.sampleScenarioCategoriesWrapper
                                }
                            >
                                {/* {categories.map((category, i) => {
                                    return (
                                        <div
                                            className={
                                                oldStyles.sampleScenarioCardBottomTextThreeWrapper
                                            }
                                            style={{
                                                backgroundColor:
                                                    category.categoryColor,
                                            }}
                                            key={i}
                                        >
                                            <span
                                                className={
                                                    oldStyles.sampleScenarioCardBottomTextThree
                                                }
                                            >
                                                {category.categoryName}
                                            </span>
                                        </div>
                                    );
                                })} */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

    return (
        <section className={styles.root}>
            <Modal open={showAddScenario} onClose={closeScenarioCreation}>
                <div>
                    <CreateScenarioModal
                        title="Create Scenario"
                        open={showAddScenario}
                        close={closeScenarioCreation}
                        submit={createScenario}
                        loadScenario={props.loadScenario}
                        manager={props.manager}
                        onboardingScenario={props.onboardingScenario}
                        getUserScenarios={props.getUserScenarios}
                        createScenario={props.createScenario}
                        showCreateScenario={props.showCreateScenario}
                        getBaseline={props.getBaseline}
                        baseline={props.baseline}
                        getAdminValues={props.getAdminValues}
                        baselineManager={props.baselineManager}
                        baselineDataManager={props.baselineDataManager}
                        userScenarios={props.userScenarios}
                        scenarioTemplates={props.scenarioTemplates}
                        agencyClients={props.agencyClients}
                        // addScenarioToClient={addScenarioToClient}
                        tabSelected={props.tabSelected}
                        history={props.history}
                    />
                </div>
            </Modal>
            <div className={styles.header}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerCopy}>
                        <h1 className={styles.headerText}>
                            Build Answers. Create Clarity.
                        </h1>
                        <p className={styles.subheader}>
                            We&apos;re here to help you make bigger business
                            decisions. Better.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.header}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerCopy}>
                        <h1 className={styles.headerText}>Getting Started</h1>
                        <p className={styles.subheader}>
                            Start exploring whatifi by visiting one of our
                            sample scenarios.
                        </p>
                    </div>
                    <div className={styles.headerActions}>
                        <div
                            className={styles.headerActionOpenCloseContainer}
                            onClick={() =>
                                setGettingStartedOpen(!gettingStartedOpen)
                            }
                        >
                            <span className={styles.headerActionSupportingText}>
                                {!gettingStartedOpen ? "Show" : "Hide"}
                            </span>
                            <img
                                className={styles.headerActionsImage}
                                src={
                                    !gettingStartedOpen
                                        ? expandSvg
                                        : collapseSvg
                                }
                                alt="Arrow Icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${
                    !gettingStartedOpen
                        ? styles.scenarioList
                        : `${styles.scenarioList} ${styles.sampleScenariosOpen}`
                }`}
            >
                <div className={styles.sampleScenariosContainer}>
                    {scenarioCards}
                </div>
            </div>
            <div
                className={`${
                    !userScenariosOpen
                        ? `${styles.header} ${styles.headerClickable}`
                        : `${styles.header} ${styles.headerClickable} ${styles.headerOpen}`
                }`}
            >
                <div
                    className={`${styles.headerContainer} ${styles.headerContainerClickable}`}
                    onClick={() => {
                        setUserScenariosOpen(!userScenariosOpen);
                        handleScroll("userScenariosContainer");
                    }}
                >
                    <div className={styles.headerCopy}>
                        <h1 className={styles.headerText}>Your Scenarios</h1>
                        <p className={styles.subheader}>
                            Create, view or edit all of your Scenarios here.
                        </p>
                    </div>
                    <div className={styles.headerActions}>
                        <div className={styles.headerActionOpenCloseContainer}>
                            <div
                                className={
                                    styles.headerActionSupportingTextContainer
                                }
                            >
                                {/* <span
                                    className={
                                        styles.headerActionSupportingText
                                    }
                                    onClick={() =>
                                        props.history.push("/baseline")
                                    }
                                >
                                    Edit Baseline
                                </span> */}
                                <span
                                    className={
                                        styles.headerActionSupportingText
                                    }
                                >
                                    {props.userScenarios.length}{" "}
                                    {props.userScenarios.length === 1
                                        ? "Scenario"
                                        : "Scenarios"}
                                </span>
                            </div>
                            <img
                                className={styles.headerActionsImage}
                                src={
                                    !userScenariosOpen ? expandSvg : collapseSvg
                                }
                                alt="Arrow Icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${
                    !userScenariosOpen
                        ? styles.scenarioList
                        : `${styles.scenarioList} ${styles.scenarioListOpen}`
                }`}
            >
                <div
                    className={styles.scenarioListContainer}
                    id="userScenariosContainer"
                >
                    <div className={styles.scenarioListInnerWrapper}>
                        <div
                            className={styles.scenarioCard}
                            onClick={() => setShowAddScenario(true)}
                        >
                            <div className={styles.scenarioCardAddContainer}>
                                <figure
                                    className={styles.scenarioCardAddFigure}
                                >
                                    <img
                                        className={styles.scenarioCardAddImage}
                                        src={addSvg}
                                        alt="plus icon"
                                    />
                                </figure>
                                <h5 className={styles.scenarioCardAddTitle}>
                                    Add New
                                </h5>
                                <p className={styles.scenarioCardAddCopy}>
                                    Create a new scenario
                                </p>
                            </div>
                        </div>
                        {props.userScenarios.map((scenario) => {
                            return getScenarioCard(scenario);
                        })}
                    </div>
                </div>
            </div>
            <div
                className={`${
                    !sharedScenariosOpen
                        ? `${styles.header} ${styles.headerClickable} ${styles.headerNoTopSpacing}`
                        : `${styles.header} ${styles.headerClickable} ${styles.headerNoTopSpacing} ${styles.headerOpen}`
                }`}
            >
                <div
                    className={`${
                        props.sharedScenario.length > 0
                            ? `${styles.headerContainer} ${styles.headerContainerClickable}`
                            : `${styles.headerContainer}`
                    }`}
                    onClick={
                        props.sharedScenario.length > 0
                            ? () => {
                                  setSharedScenariosOpen(!sharedScenariosOpen);
                                  handleScroll("sharedScenariosContainer");
                              }
                            : () => {
                                  return;
                              }
                    }
                >
                    <div className={styles.headerCopy}>
                        <h1 className={styles.headerText}>
                            Scenarios Shared with You
                        </h1>
                        <p className={styles.subheader}>
                            View Scenarios that have been shared with you by
                            other whatifi users.
                        </p>
                    </div>
                    <div className={styles.headerActions}>
                        <div className={styles.headerActionOpenCloseContainer}>
                            <div
                                className={
                                    styles.headerActionSupportingTextContainer
                                }
                            >
                                <span
                                    className={
                                        styles.headerActionSupportingText
                                    }
                                >
                                    {props?.sharedScenario?.length}{" "}
                                    {props?.sharedScenario?.length === 1
                                        ? "Scenario"
                                        : "Scenarios"}
                                </span>
                            </div>
                            <img
                                className={styles.headerActionsImage}
                                src={
                                    !sharedScenariosOpen
                                        ? expandSvg
                                        : collapseSvg
                                }
                                alt="Arrow Icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {props.sharedScenario.length > 0 && (
                <div
                    className={`${
                        !sharedScenariosOpen
                            ? styles.scenarioList
                            : `${styles.scenarioList} ${styles.scenarioListOpen}`
                    }`}
                >
                    <div
                        className={styles.scenarioListContainer}
                        id="sharedScenariosContainer"
                    >
                        <div className={styles.scenarioListInnerWrapper}>
                            {props.sharedScenario.map((scenario) => {
                                return getScenarioCard(scenario);
                            })}
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}

let environment;

switch (process.env.REACT_APP_ENV) {
    case "production":
    case "staging":
    case "development":
        environment = process.env.REACT_APP_ENV;
        break;
    default:
}

// DEFAULT PORTS - FOR DEVELOPMENT OF APPLICATION & DECISION ENGINE
let host = "https://server.whatifi.io:8082",
    decisionEngine =
        "https://k71lplyh5d.execute-api.us-west-2.amazonaws.com/default/DecisionEngine_Calculate",
    shareScenarioUrl = "http://localhost:3000";

// LOCAL PORTS - FOR DEVELOPMENT OF APPLICATION & BACK-END & DECISION ENGINE
// let host = "http://localhost:8080",
//     decisionEngine = "http://localhost:8081",
//     shareScenarioUrl = "http://localhost:3000";

// LOCAL PORTS - FOR DEVELOPMENT OF APPLICATION & BACK-END
// let host = "http://localhost:8080",
//     decisionEngine = "https://server.whatifi.io:9002",
//     shareScenarioUrl = "http://localhost:3000";

if (environment != null) {
    // TODO: Separate decisionEngine's environments
    switch (environment) {
        case "production":
            host = "https://server.whatifi.io:8080";
            decisionEngine =
                "https://hom7zadlg6.execute-api.us-west-2.amazonaws.com/default/DecisionEngine_Calculate";
            shareScenarioUrl = "https://app.whatifi.io";
            break;
        case "staging":
            host = "https://server.whatifi.io:8081";
            decisionEngine =
                "https://v0nate5q4d.execute-api.us-west-2.amazonaws.com/default/DecisionEngine_Calculate";
            shareScenarioUrl = "https://staging.whatifi.io";
            break;
        case "development":
            host = "https://server.whatifi.io:8082";
            // decisionEngine = "https://server.whatifi.io:9002"; // NOTE: Temporarily using DE endpoint.
            decisionEngine =
                "https://rljk6c6nfj.execute-api.us-west-2.amazonaws.com/default/DecisionEngine_Calculate";
            shareScenarioUrl = "https://dev.whatifi.io";
            break;
        default:
            break;
    }
}
// host = "http://localhost:8080";
// decisionEngine = "http://localhost:10000/decisionEngine";
export const config = {
    host,
    decisionEngine,
    shareScenarioUrl,
};

import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";

export function useMenuTemplate(menuId: string) {
    const menuTemplate = useAppSelector(
        (state) => state.menusTemplates?.[menuId] ?? {},
        shallowEqual
    );

    return menuTemplate;
}

import type { LoadingStatesSchema } from "./typesSchema/loadingStatesSchema";
import { UPSERT_LOADING_STATES } from "actions/loadingStatesActions";

const initialState: LoadingStatesSchema = {
    isCalculatedThreadsLoading: false,
};

export default function loadingStatesReducer(
    state = initialState,
    action: { type: string; payload?: Partial<LoadingStatesSchema> }
) {
    switch (action.type) {
        case UPSERT_LOADING_STATES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

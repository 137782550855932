// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import EquityCard from "../Card/equityCard";
import EquityInput from "../InputContainer/EquityInput";

// Import Connection Image (if exists)
import EquityConnectionImage from "../../Assets/_optionDescriptionIcons/RRSP, Equity, Capital Gains.png";

// Import Coloured PNGs
import equityBlack from "../../Assets/_eventNodeTypes/investment-black.png";
import equityBlue from "../../Assets/_eventNodeTypes/investment-blue.png";
import equityWhite from "../../Assets/_eventNodeTypes/investment-white.png";
import equityGrey from "../../Assets/_eventNodeTypes/investment-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/WealthManagement2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - equityObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - equityObject.checkInput.call(equityObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const equityObject = {
    constant: function () {
        return Equity_Constant();
    },

    checkInput: function (state) {
        return Equity_CheckInput(state);
    },

    svg: function () {
        return Equity_Svg();
    },

    eventNodeMenu: function (focus) {
        return Equity_EventNodeMenu(focus);
    },

    indexText: function () {
        return Equity_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Equity_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Equity_OptionDescription();
    },

    colouredPngs: function () {
        return Equity_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Equity_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Equity_CheckInput = () => {
    return true;
};

// EFFECTS: Returns constant for event
const Equity_Constant = () => {
    return "Equity";
};

// EFFECTS: Returns black png / svg for event
const Equity_Svg = () => {
    return equityBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Equity_EventNodeMenu = (focus) => {
    return <EquityCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Equity_IndexText = () => {
    return "Select an input field to learn more about your equity calculations.";
};

// EFFECTS: Returns all icons for event ??
const Equity_Icons = () => {
    let pngs = new Map([
        ["black", equityBlack],
        ["blue", equityBlue],
        ["white", equityWhite],
        ["grey", equityGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Equity_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Equity_InputComponentInfoPassing = (minProps) => {
    return <EquityInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, equitys for event ??
const Equity_OptionDescription = () => {
    const tags = ["Option"];
    const type = equityObject.constant();
    const description =
        "Equity is the ownership of an asset generally as an investment. The most common form of equity available to purchase is common stock on the international trading markets. Most commonly known as stocks or shares.";
    const usedForText =
        "Use the Equity event to track your equity investment assets both personal and company issued.";
    const connection = "";
    const connectionImage = EquityConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/s/stockmarket.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Equity_SideModalImage = () => {
    return sideImage;
};

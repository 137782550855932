import React from "react";
import closeSvg from "../../Assets/close.svg";
import styles from "./index.module.css";
import fullSvg from "../../Assets/_onboarding/full.svg";
import emptySvg from "../../Assets/_onboarding/empty.svg";
import doneSvg from "../../Assets/_onboarding/doneBig.png";
import nineGif from "../../Assets/_onboarding/9.gif";
import elevenGif from "../../Assets/_onboarding/11.gif";
import thirteenGif from "../../Assets/_onboarding/13.gif";

const steps = [
    {
        step: 1,
        title: "1. It's all about you",
        text:
            "Should I buy or should I rent? Every scenario starts with a 'Me'! If I have a monthly income of $2,875, and I am renting a $1,800 apartment, what if I ... ",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/1.gif",
    },
    {
        step: 2,
        title: "2. Add a Decision",
        text:
            "Should I keep renting or consider buying a property? Click on the connection joint and select 'Add Option' to open the Option Picker. From this menu, choose add a 'Decision'.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/2.gif",
    },
    {
        step: 3,
        title: "3. Branch out an option",
        text:
            "whatifi want to buy a property that costs $850,000? Branch out from the Decision Node to choose a 'House' and add that to your Canvas!",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/3.gif",
    },
    {
        step: 4,
        title: "4. Context Passing",
        text:
            "Next, let's add a Mortgage after your House Node. In whatifi, the order of the nodes is important. For example, we pass information through each step to calculate your mortgage payment.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/4.gif",
    },
    {
        step: 5,
        title: "5. Decision Engine",
        text:
            "The decisions we make in life are not purely financial. In each node, you can assign a star rating. Adjust the Decision Engine slider to sort your decision path by profitabilty or ranks and reviews.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/5.gif",
    },
    {
        step: 6,
        title: "6. Add an in-line option",
        text:
            "We can't escape taxes... Let's append an Income Tax Node to the end of our House and Mortgage Scenario Thread. A Thread is a unique path through your network of nodes.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/6.gif",
    },
    {
        step: 7,
        title: "7. See your scenario threads in the Graph",
        text:
            "A good decsion today can be a bad decision tomorrow. Click on the 'Graph' button  to expose the graph. You can then toggle between Scenario Threads to see how your financial options calculate over time.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/7.gif",
    },
    {
        step: 8,
        title: "8. Expand the graph for the more detail",
        text:
            "For some options, there are a lot more financial calculations to consider. Open the Expanded Details to better understand the numbers behind the numbers.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/8.gif",
    },
    {
        step: 9,
        title: "9. Passing multiple threads into a node",
        text:
            "We still need to factor Income Tax into our Rent Thread. Use the Connect function to connect the Rent Node to the Income Tax node.",
        // icon:
        //     "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/9.gif",
        icon: nineGif,
    },
    {
        step: 10,
        title: "10. Add a Mortgage Decision",
        text:
            "Buying a house is a big decision and you may want to consider different mortgages. Let's add a Mortgage Decision to help you decide.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/10.gif",
    },
    {
        step: 11,
        title: "11. Copy and Paste",
        text:
            "To save time, you can make a copy of your current Mortgage. Paste it as a 'branch' off your Mortgage Decision. Edit the details to create a second, unique Mortgage option.",
        // icon:
        //     "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/11.gif",
        icon: elevenGif,
    },
    {
        step: 12,
        title: "12. Lock options and nested decisions",
        text:
            "Time to make a decision! By 'locking' a node, you can decide which Mortgage to promote in your Mortgage Decision branch.",
        icon:
            "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/12.gif",
    },
    {
        step: 13,
        title: "13. Lock a decision, a-ha!",
        text:
            "Down to renting or buying a house with Mortgage 2. By 'locking' the Mortgage Decision, the Rent Scenario Thread is greyed out and no longer calculates. You are done!!",
        // icon:
        //     "https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/onboarding-gifs/13.gif",
        icon: thirteenGif,
    },
    {
        step: 14,
        title: "You're all done!",
        text:
            "Congratulations! You can now build your own scenario from scratch, explore our Sample Scenarios or use one of our custom templates to get started. Time to make some bigger life decisions. Better.",
        icon: doneSvg,
    },
];

export default class OnboardingModal extends React.Component {
    render() {
        const { step, close, onHandleNext, onhandleBack } = this.props;

        const filtered = steps.filter((item) => {
            return item.step === step;
        });
        const isBackTrue = step && step > 1;
        const stepSelected = filtered && filtered[0];

        const isHorizontal = step === 8 || step === 7;

        return (
            <div
                className={
                    isHorizontal ? styles.horizontal : styles.ModalContainer
                }
            >
                <img
                    alt="alt"
                    src={closeSvg}
                    className={styles.CloseModal}
                    onClick={close}
                />

                <div
                    className={
                        isHorizontal
                            ? styles.horizontalContainer
                            : styles.container
                    }
                >
                    {/* <div className={styles.gif}>IMAGE</div> */}
                    <img
                        alt="alt"
                        src={stepSelected && stepSelected.icon}
                        className={
                            isHorizontal ? styles.gifHorizontal : styles.gif
                        }
                    />
                    {isHorizontal ? (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <div className={styles.step}>
                                {stepSelected && stepSelected.title}
                            </div>
                            <div className={styles.text}>
                                {stepSelected && stepSelected.text}
                            </div>
                            <div className={styles.buttonsContainer}>
                                <div className={styles.horizontalDots}>
                                    {steps.map((dot, i) => {
                                        if (dot.step === step) {
                                            return (
                                                <img
                                                    alt="alt"
                                                    className={styles.dots}
                                                    src={fullSvg}
                                                    key={i}
                                                />
                                            );
                                        } else {
                                            return (
                                                <img
                                                    alt="alt"
                                                    className={styles.dots}
                                                    src={emptySvg}
                                                    key={i}
                                                />
                                            );
                                        }
                                    })}
                                </div>
                                <div className={styles.horizontalButton}>
                                    {isBackTrue && (
                                        <div
                                            onClick={onhandleBack}
                                            className={styles.backButton}
                                        >
                                            Back
                                        </div>
                                    )}
                                    <div
                                        className={styles.nextButton}
                                        onClick={onHandleNext}
                                    >
                                        {step === 14 ? "Finish" : "Next"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                {steps.map((dot, i) => {
                                    if (dot.step === step) {
                                        return (
                                            <img
                                                alt="alt"
                                                className={styles.dots}
                                                src={fullSvg}
                                                key={i}
                                            />
                                        );
                                    } else {
                                        return (
                                            <img
                                                alt="alt"
                                                className={styles.dots}
                                                src={emptySvg}
                                                key={i}
                                            />
                                        );
                                    }
                                })}
                            </div>
                            <div className={styles.step}>
                                {stepSelected && stepSelected.title}
                            </div>
                            <div className={styles.text}>
                                {stepSelected && stepSelected.text}
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                {isBackTrue && (
                                    <div
                                        onClick={onhandleBack}
                                        className={styles.backButton}
                                    >
                                        Back
                                    </div>
                                )}
                                <div
                                    className={styles.nextButton}
                                    onClick={onHandleNext}
                                >
                                    {step === 14 ? "Finish" : "Next"}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* <div className={styles.children}>{children}</div> */}
            </div>
        );
    }
}

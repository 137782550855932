import React, { Component } from "react";
import { valueDoesNotExist } from "../../helpers";
import "./Card.css";
import {
    CardContainer,
    DollarValue,
    RowLabel,
    Row,
    TextValue,
    Description,
} from "./Components";

class PropertyResaleCard extends Component {
    render() {
        const {
            description,
            house,
            resalePrice,
            closingCost,
            start,
            profits,
            version,
        } = this.props.data.metadata;
        const showProfits =
            !valueDoesNotExist(profits) && profits !== resalePrice;

        return (
            <CardContainer>
                <Row>
                    <Description text={description} />
                </Row>
                <Row>
                    <RowLabel text="Resale Price" />
                    <DollarValue value={resalePrice} />
                </Row>
                <Row>
                    <RowLabel text="Closing Cost" />
                    <DollarValue value={closingCost} />
                </Row>
                {showProfits && (
                    <Row>
                        <RowLabel text="Profits" />
                        <DollarValue value={profits} />
                    </Row>
                )}
                <Row>
                    <RowLabel text="House" />
                    <TextValue text={house} />
                </Row>
                <Row>
                    <RowLabel text="Resale Date" />
                    <TextValue text={start} />
                </Row>
                <Row>
                    <RowLabel text="Version" />
                    <TextValue text={version} />
                </Row>
            </CardContainer>
        );
    }
}

export default PropertyResaleCard;

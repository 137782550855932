import { upsertChartGraph } from "actions/chartGraphActions";
import { Component } from "react";
import { connect } from "react-redux";
import { appMapDispatch, appMapState } from "store/appMapStateDispatch";
import "./MonthlyCumulativeSelection.css";

class MonthlyCumulativeSelection extends Component {
    setMonthly = () => {
        this.props.dispatch(
            upsertChartGraph({
                monthlyCumulative: "monthly",
            })
        );
    };

    setCumulative = () => {
        this.props.dispatch(
            upsertChartGraph({
                monthlyCumulative: "cumulative",
            })
        );
    };

    render() {
        return (
            <div className="DisplaySelection">
                <div className="DisplayToggleContainer">
                    <div
                        className={
                            this.props.monthlyCumulative === "monthly"
                                ? "DisplayToggleSelected"
                                : "DisplayToggle"
                        }
                        onClick={this.setMonthly}
                    >
                        <span>Monthly</span>
                    </div>
                    <div
                        className={
                            this.props.monthlyCumulative === "cumulative"
                                ? "DisplayToggleSelected"
                                : "DisplayToggle"
                        }
                        onClick={this.setCumulative}
                    >
                        <span>Cumulative</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = appMapState((state, _ownProps) => ({
    monthlyCumulative: state.chartGraph.monthlyCumulative,
}));

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlyCumulativeSelection);

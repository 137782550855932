import type { EventManager } from "Events";
import store, { AppThunk } from "store";
import { upsertThreadsList } from "./threadsListActions";

export function zoomThread(threadId: string): AppThunk {
    return (dispatch, getState) => {
        const manager = getState().scenario?.manager as EventManager;
        // Manager reorganizes zoomThreads, setting nodes as hideInCanvas = true as necessary
        manager?.hideNodesNotInThread(threadId);

        dispatch(upsertThreadsList({ zoomedThreadId: threadId }));

        manager?.updateCanvas(false);
    };
}

export function unzoomThread(): AppThunk {
    return (dispatch, getState) => {
        const manager = getState().scenario?.manager as EventManager;
        dispatch(upsertThreadsList({ zoomedThreadId: "" }));
        // For now, just run the all-encompassing calculate() method because containers are also built into calculate().
        // Later, we want to modularize containers and calculate and hiding nodes, etc. from each other
        manager?.calculate(false);

        // TODO: Remove this dispatch and replace with the dummy "re-render" state that Atom's work is using from development once that is merged to development
        dispatch(upsertThreadsList({ zoomedThreadId: "" }));
    };
}

export function ultraZoomThreadExperimental(): AppThunk {
    return (_dispatch, getState) => {
        // Experimental flag
        if (process.env?.REACT_APP_ENV !== "development") return;

        // Temporary: Just get the current zoomed thread and ultra zoom it
        const manager = getState().scenario?.manager as EventManager;
        const threadId = getState().threadsList.zoomedThreadId;
        manager?.ultraZoomThread(threadId);

        manager?.updateCanvas(false);
    };
}

/**
 * This function is a hack to get the current zoomed thread id from inside manager. It is used to prevent having events reappear while in zoomed thread mode and user sends a calculation.
 *
 * This is not an ideal solution. Instead of forcing manager.hideNodesNotInThread(), we should have each line and event manage itself and hide themselves as necessary.
 *
 * TODO: Remove this function once we use the more ideal method.
 */
export function getZoomThread() {
    return store.getState?.()?.threadsList?.zoomedThreadId ?? "";
}

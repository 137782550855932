import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import Swal from "sweetalert2";
import AccountData from "../../../helpers/ledgers/accountsAndLedgers.json";
import { Modifier } from "../CustomHooks/useOverrides";
import {
    stringToYyyyMmDdDate,
    stringToAmortizedCadence,
    handleCadenceChange,
    handleAmortizationPeriodChange,
    handleAmortizedStartDateChange,
    handleAmortizedEndDateChange,
} from "./helpers/amortizedHelpers";

/*
 * A generic input handler for events that use the standardized set of properties
 * {
 *   value: number
 *   startDate: Date
 *   endDate: Date
 *   cadence: Cadence
 *   account: Account
 *   contraAccount: Account
 *   ongoingOrAmortized: "ongoing" | "amortized"
 * }
 */
export const genericIncomeExpenseInputsHandler = (
    value: string,
    id:
        | "value"
        | "entityName"
        | "bypassState"
        | "startDate"
        | "endDate"
        | "cadence"
        | "rating"
        | "accountName"
        | "contraAccountName"
        | "amortizedValue"
        | "amortizationPeriod"
        | "ongoingOrAmortized",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
): EntitiesSchema => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...newEntitiesMap[currentEntity] };
    const data = { ...currentEntityObject.data };

    switch (id) {
        case "value":
            data.value = parseFloat(value);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "amortizedValue":
            data.value = parseFloat(value);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "bypassState":
            currentEntityObject.bypassState = !!value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDate = stringToYyyyMmDdDate(value);

            if (startDate == null) {
                console.warn("failed to parse start date");
                newEntitiesMap[currentEntity] = currentEntityObject;
                break;
            }

            if (data.ongoingOrAmortized === "amortized") {
                newEntitiesMap[currentEntity] = handleAmortizedStartDateChange(
                    currentEntityObject,
                    startDate
                );
            } else {
                currentEntityObject.startDate = startDate;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }

            // Update all modifier/override startDates
            if (currentEntityObject?.data?.modsCreated) {
                for (const mod of currentEntityObject.data.modsCreated) {
                    mod.startDate = startDate;
                }
            }

            break;
        case "endDate":
            const endDate = stringToYyyyMmDdDate(value);

            if (endDate == null) {
                console.warn("failed to parse end date");
                newEntitiesMap[currentEntity] = currentEntityObject;
                break;
            }

            if (data.ongoingOrAmortized === "amortized") {
                newEntitiesMap[currentEntity] = handleAmortizedEndDateChange(
                    currentEntityObject,
                    endDate
                );
            } else {
                currentEntityObject.endDate = endDate;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "cadence":
            if (data.ongoingOrAmortized === "amortized") {
                const cadence = stringToAmortizedCadence(value);

                if (cadence == null) {
                    console.warn("failed to parse cadence");
                    newEntitiesMap[currentEntity] = currentEntityObject;
                    break;
                }

                newEntitiesMap[currentEntity] = handleCadenceChange(
                    currentEntityObject,
                    cadence
                );
            } else {
                currentEntityObject.cadence = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name ?? "";
            data.accountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.contraAccountName = account.name ?? "None (default)";
            data.contraAccountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "ongoingOrAmortized":
            // If you change ongoingOrAmortized to amortized, remove any overrides
            // on the value. If ongoingOrAmortized is changed to ongoing, do nothing
            // special.

            if (value === "ongoing") {
                data.ongoingOrAmortized = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
                break;
            }

            if (currentEntityObject.data.modsCreated) {
                const valueOverrideIndex =
                    currentEntityObject.data.modsCreated.findIndex(
                        (modifier: Modifier) => {
                            return (
                                modifier.action === "override" &&
                                modifier.keyToModify === "value"
                            );
                        }
                    );

                if (valueOverrideIndex >= 0) {
                    Swal.fire({
                        title: "Amortization will clear existing overrides.",
                        showCancelButton: true,
                        showConfirmButton: true,
                        cancelButtonText: "Cancel",
                        confirmButtonText: "Confirm",
                    }).then((result) => {
                        if (result?.value) {
                            currentEntityObject.data.modsCreated.splice(
                                valueOverrideIndex,
                                1
                            );

                            data.ongoingOrAmortized = value;
                            currentEntityObject.data = data;
                            newEntitiesMap[currentEntity] = currentEntityObject;
                        }
                    });
                } else {
                    // No overrides, don't need to worry about resetting them.
                    data.ongoingOrAmortized = value;
                    currentEntityObject.data = data;
                    newEntitiesMap[currentEntity] = currentEntityObject;
                }
            } else {
                // No modifiers, don't need to worry about resetting them.
                data.ongoingOrAmortized = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }

            break;
        case "amortizationPeriod":
            newEntitiesMap[currentEntity] = handleAmortizationPeriodChange(
                currentEntityObject,
                parseFloat(value)
            );

            break;
        default:
            console.warn("Unknown id", id);
    }

    return newEntitiesMap;
};

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const debitCreditInputsHandler = (
    value: string,
    id:
        | "value"
        | "entityName"
        | "bypassState"
        | "startDate"
        | "endDate"
        | "cadence"
        | "rating"
        | "debitOrCreditType"
        | "accountName"
        | "contraAccountName"
        | "amortizedValue"
        | "amortizationPeriod"
        | "ongoingOrAmortized",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    let newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "debitOrCreditType":
            data.debitOrCredit = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
            newEntitiesMap = genericIncomeExpenseInputsHandler(
                value,
                id,
                star,
                entitiesMap,
                currentEntity,
                account
            );
    }

    return newEntitiesMap;
};

export function getContraAccounts(account) {
    const accounts = Object.values(AccountData);
    const contraAccounts: string[] = [];

    if (!account || account.trim() == "") {
        return contraAccounts;
    }

    accounts.forEach((acc) => {
        if (acc.id === account) {
            contraAccounts.unshift(acc.id);
            let currentAcc = acc;
            while (currentAcc.parents.length > 0) {
                currentAcc = AccountData[currentAcc.parents[0]];
                contraAccounts.unshift(currentAcc.id);
            }
        }
    });

    return contraAccounts;
}

import { getEvent } from "actions/getNodeEntityActions";

export const getContainerDimensions = (containedEventsIdArray) => {
    const xPositionArray: number[] = [];
    const yPositionArray: number[] = [];

    containedEventsIdArray.forEach((containedEventId) => {
        const containedEvent: any = getEvent(containedEventId);
        const x = containedEvent?.x();
        const y = containedEvent?.y();

        xPositionArray.push(x);
        yPositionArray.push(y);
    });

    const sortedXPositions = xPositionArray.sort((a, b) => a - b);
    const sortedYPositions = yPositionArray.sort((a, b) => a - b);
    const containerSizeValues = {
        smallestX: sortedXPositions[0],
        largestX: sortedXPositions[sortedXPositions.length - 1],
        smallestY: sortedYPositions[0],
        largestY: sortedYPositions[sortedYPositions.length - 1],
    };

    const differenceX =
        containerSizeValues.largestX - containerSizeValues.smallestX;
    const differenceY =
        containerSizeValues.largestY - containerSizeValues.smallestY;

    const xCenterPosition = differenceX / 2 + containerSizeValues.smallestX;
    const yCenterPosition = differenceY / 2 + containerSizeValues.smallestY;

    const width = differenceX + 200;
    const height = differenceY + 200;
    return { width, height, xCenterPosition, yCenterPosition };
};

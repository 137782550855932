import axios, { AxiosResponse } from "axios";
import { config } from "config";
import { requestHeader } from "./scenario";

export interface AssistantMessageResponse {
    assistantResponseType: "message";
    id: string;
    content: string;
    role: "user" | "assistant";
}

export interface AssistantNodesResponse {
    assistantResponseType: "nodes";
    output: { entities: any[]; accounts: any[] };
    id: string;
}

export interface AssistantError {
    assistantResponseType: "error";
    id: string;
    reason: string;
}

export type AssistantResponse =
    | AssistantMessageResponse
    | AssistantNodesResponse
    | AssistantError;

export function isAssistantResponse(
    value: unknown
): value is AssistantResponse {
    if (value === null || value === undefined) {
        return false;
    }

    if (typeof value !== "object") {
        return false;
    }

    if (Array.isArray(value)) {
        return false;
    }

    if (!("assistantResponseType" in value)) {
        return false;
    }

    return true;
}

export function isAssistantMessageResponse(
    value: unknown
): value is AssistantMessageResponse {
    return (
        isAssistantResponse(value) && value.assistantResponseType === "message"
    );
}

export function isAssistantNodesResponse(
    value: unknown
): value is AssistantNodesResponse {
    return (
        isAssistantResponse(value) && value.assistantResponseType === "nodes"
    );
}

export function isAssistantErrorResponse(
    value: unknown
): value is AssistantError {
    return (
        isAssistantResponse(value) && value.assistantResponseType === "error"
    );
}

function parseAssistantResponse(value: unknown): AssistantResponse {
    return isAssistantResponse(value)
        ? value
        : {
              assistantResponseType: "error",
              id: "error",
              reason: "parseAssistantResponse: Bad parse",
          };
}

export function getConversationId(scenario): Promise<string> {
    const data = {
        ...requestHeader(),
    };

    return axios
        .get(`${config.host}/ai/assistant/${scenario}`, data)
        .then((thread: AxiosResponse<unknown>) => {
            return thread.data as any;
        });
}

export async function getMessages(
    conversationId
): Promise<AssistantResponse[]> {
    const data = {
        ...requestHeader(),
    };

    while (true) {
        const resp = await axios.get(
            `${config.host}/ai/messages/${conversationId}`,
            data
        );
        if (resp.status >= 200 && resp.status < 300) {
            return resp.data.map((message) => {
                if ((message as any).content.length === 0) {
                    return {
                        assistantResponseType: "error",
                        id: (message as any).id,
                        reason: "No message contents",
                    } as AssistantError;
                }

                const messageContents: string = (
                    message as any
                ).content[0].text.value.trim();

                if (messageContents.startsWith("CREATE_NODES")) {
                    const codeBlockRegex = /```(?:json)?(.*?)```/s;
                    const match = messageContents.match(codeBlockRegex);

                    if (match !== null) {
                        try {
                            const output = JSON.parse(match[1]);
                            return {
                                assistantResponseType: "nodes",
                                id: (message as any).id,
                                output: output,
                            } as AssistantNodesResponse;
                        } catch (e) {
                            console.log("Bad parse: " + (e as any).toString());
                            return {
                                assistantResponseType: "error",
                                id: (message as any).id,
                                reason: "Bad CREATE_NODES JSON parse",
                            } as AssistantError;
                        }
                    } else {
                        return {
                            assistantResponseType: "error",
                            id: (message as any).id,
                            reason: "Bad CREATE_NODES parse",
                        } as AssistantError;
                    }
                } else {
                    return {
                        assistantResponseType: "message",
                        content: messageContents,
                        id: (message as any).id,
                        role: (message as any).role,
                    } as AssistantMessageResponse;
                }
            });
        }
    }
}

export function postResponse(
    conversationId,
    message: string
): Promise<AssistantResponse> {
    // TODO:

    const data = {
        data: {
            messageContent: message,
        },
        ...requestHeader(),
    };

    return axios
        .post(`${config.host}/ai/messages/${conversationId}`, data)
        .then((response: AxiosResponse<unknown>) => {
            return undefined as any as AssistantResponse; // TODO
        });
}

import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { Group } from "react-konva";
import { CanvasLine } from "../../../CanvasLines/CanvasLine";
import { containerObject } from "Components/Registry/Container";

export const generateCanvasLine = (parentEventId, childEventId) => {
    const parentEvent: any = getEvent(parentEventId);
    const childEvent: any = getEvent(childEventId);
    if (parentEvent?.type === containerObject?.constant()) {
        const parentContainerEntity = Object.values(
            getRelevantEntities(parentEvent?.entities ?? []) ?? {}
        )?.[0];
        if (parentContainerEntity?.data?.expanded) {
            if (parentContainerEntity?.data?.containedEvents?.[childEventId]) {
                return <Group key={childEventId}></Group>;
            }
            return (
                <CanvasLine
                    key={childEventId}
                    lineId={`${parentContainerEntity?.data?.tailNode}_${childEventId}`}
                />
            );
        } else {
            const tailNode = getEvent(parentContainerEntity?.data?.tailNode);
            const tailNodeChild = tailNode?.children?.[0]?.id ?? "";
            if (tailNodeChild) {
                return (
                    <CanvasLine
                        key={tailNodeChild}
                        lineId={`${parentEventId}_${tailNodeChild}`}
                    />
                );
            } else {
                return <Group key={childEventId}></Group>;
            }
        }
    } else if (childEvent?.type === containerObject?.constant()) {
        const childContainerEntity = Object.values(
            getRelevantEntities(childEvent?.entities ?? []) ?? {}
        )?.[0];
        if (childContainerEntity?.data?.expanded) {
            return (
                <CanvasLine
                    key={childEventId}
                    lineId={`${parentEventId}_${childContainerEntity?.data?.headNode}`}
                />
            );
        }
    }

    return (
        <CanvasLine
            key={childEventId}
            lineId={`${parentEventId}_${childEventId}`}
        />
    );
};

import styles from "./deleteButton.module.css";
import DeleteIcon from "Assets/_nodeIcons/delete.png";
import { topLevelAccountIds } from "helpers/constants";

export const DeleteButtonCellRenderer = (props) => {
    const accountId = props?.value;
    const disabled = topLevelAccountIds?.[accountId];
    const isSelectedForDeletion =
        !!props?.context?.accountsFlaggedForDeletionMap?.[accountId];

    const handleClick = () => {
        if (disabled) return;
        const colId = props.column.colId;
        props.node.setDataValue(colId, accountId);
    };

    return (
        <div className={styles?.deleteButtonCellContainer}>
            <img
                className={
                    disabled
                        ? `${styles?.deleteButton} ${styles?.deleteButtonDisabled}`
                        : isSelectedForDeletion
                        ? `${styles?.deleteButton} ${styles?.deleteButtonActive}`
                        : styles?.deleteButton
                }
                onClick={handleClick}
                src={DeleteIcon}
                alt="Delete"
            />
        </div>
    );
};

import type { XYChart } from "@amcharts/amcharts4/charts";
import type { LedgerMetadataUnit } from "reducers/typesSchema/allAccountLedgersSchema";
import type { ChartGraphSchema } from "reducers/typesSchema/chartGraphSchema";
import type { CalculatedThreadsSchema } from "reducers/typesSchema/calculatedThreadsSchema";
import type { DrawGraphOptions } from "./drawGraph";
import { drawGraph } from "./drawGraph";

/**
 * This class is used so we have a mutable object that updates its states.
 * This technique of cancelling promises is adapted from this source: https://javascript.plainenglish.io/canceling-promises-in-javascript-31f4b8524dcd
 */
export class CancellableDrawGraphPromise {
    finished: boolean;
    cancelled: boolean;
    cancel: () => void;
    promise?: Promise<XYChart | null> | null;

    constructor() {
        this.finished = false;
        this.cancelled = false;
        this.cancel = () => {
            this.finished = true;
            this.cancelled = true;
        };
        this.promise = null;
    }
}

/**
 * A cancellable Async Function that calls the main renderGraph() function.
 * If a user clicks through threads, etc. too quickly, then this Promise will reject with an error.name === "Cancelled".
 * The caller of this function can then determine what to do if promise.catch((e) => e.name === "Cancelled").
 *
 *
 * To use, this function, take the return object, CancellableDrawGraphPromise.
 * You can call CancellableDrawGraphPromise.promise.then().catch().finally().
 * You can call CancellableDrawGraphPromise.cancel() at any time and it will set CancellableDrawGraphPromise.cancelled to true.
 * You can check CancellableDrawGraphPromise.cancelled to see if it is true or false inside .then() or .catch().
 * You must check CancellableDrawGraphPromise.cancelled inside .then() or .catch() because the underlying function still goes through completely and returns an XYChart.
 */
export function drawGraphAsync(
    chartGraphState: ChartGraphSchema & { activeThreadId: string },
    selectedThreads: string[],
    calculatedThreads: CalculatedThreadsSchema,
    ledgerMetadataUnit: LedgerMetadataUnit,
    filteredEventAndEntityData: any[],
    options?: Partial<DrawGraphOptions> // TODO: Pass in options like tooltiptext, colors, width, height, etc. so it's customizeable by the caller.
): CancellableDrawGraphPromise {
    const cancellablePromise = new CancellableDrawGraphPromise();

    cancellablePromise.promise = new Promise((resolve, reject) => {
        cancellablePromise.cancel = () => {
            if (cancellablePromise.finished) {
                return;
            }

            cancellablePromise.cancelled = true;
        };

        try {
            // Set an interval. Normally the graph will finish way before the timeout and cancel the interval (therefore not running this timeout code).
            // However, just in case, we should check if this promise is taking too long to finish. If it is, reject the promise.
            const timeStart = performance.now();
            const intervalId = setInterval(() => {
                const timeNow = performance.now();
                if (timeNow - timeStart > 60000) {
                    clearInterval(intervalId);
                    reject(new Error("Timed out"));
                }
            }, 30000);

            // setTimeout(() => { // NOTE: Use setTimeout to test loading states.
            const xyChart = drawGraph(
                chartGraphState,
                selectedThreads,
                calculatedThreads,
                ledgerMetadataUnit,
                filteredEventAndEntityData,
                options
            );
            clearInterval(intervalId); // clear the interval so the timeout callback is not run
            resolve(xyChart);
            // }, 2500)
        } catch (e) {
            console.log("Will be rejecting!", e);
            reject(e);
        }
    });

    return cancellablePromise;
}

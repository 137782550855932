import React from "react";
import styles from "./index.module.css";
import { EventsModal } from "../EventsModal";
// import planSvg from "../../Assets/_onboarding/plan.png";
import businessPlanSvg from "../../Assets/_onboarding/business-plan.png";
import agencyPlanSvg from "../../Assets/_onboarding/agency-plan.png";
import checkMarkSvg from "../../Assets/checkMark.svg";
import exSvg from "../../Assets/ex.svg";
import { DEFAULT } from "../../actions/types";

// const allPlans = [
//     // this is for solo business plan, however the business plan is becoming the forever free for launch
//     // {
//     //     planType: "business",
//     //     planName: "business",
//     //     paymentStartText: "FREE 14 day trial",
//     //     planImage: businessPlanSvg,
//     //     checkContent: [
//     //         "Access to all Business Events and Templates",
//     //         "One User Account for a Single Business",
//     //         "Unlimited Scenario Threads",
//     //         "Advanced Scenario Sharing with 3 other Users per Scenario",
//     //         "Access to all Integration services",
//     //         "Scenario Alerts for $1 /mon per Scenario",
//     //         "PRO Level Customer Support",
//     //     ],
//     //     exContent: [],
//     // },
//     {
//         planType: "business",
//         planName: "basic",
//         paymentStartText: "FOREVER FREE",
//         planImage: businessPlanSvg,
//         checkContent: [
//             "Access to Basic Events and Templates",
//             "One User Account for a Single Business",
//             "3 Scenario Limit",
//             "1 Year Forecast Limit",
//             "Basic Scenario Sharing",
//             "25 Events Limit per Scenario",
//             "Upgrade to Startup, Agency or Solo Business to Access PRO Features",
//         ],
//         exContent: [],
//     },
//     {
//         planType: "agency",
//         planName: "agency",
//         paymentStartText: "FREE 14 day trial",
//         planImage: agencyPlanSvg,
//         checkContent: [
//             "Access to all Business Events and Templates",
//             "Includes 5 Client Accounts",
//             "Unlimited Scenario Threads",
//             "Advanced Scenario Sharing with 3 other Users per Scenario",
//             "Access to all Integration services",
//             "Add additional Client Accounts for $35/mon",
//             "Scenario Alerts for $1 /mon per Scenario",
//             "PRO Level Customer Support",
//         ],
//         exContent: [],
//     },
// ];

export default class PlanCard extends React.Component {
    state = {
        planType: "",
        planName: "",
        paymentStartText: "",
        planImage: "",
        checkContent: [],
        exContent: [],
    };

    componentDidMount() {
        if (this.props.userPlanData?.id === ("free-plan" || "small-business")) {
            this.setState({ planImage: businessPlanSvg });
        } else if (this.props.userPlanData?.id === "agency_01") {
            this.setState({ planImage: agencyPlanSvg });
        } else {
            this.setState({ planImage: businessPlanSvg });
        }

        this.setState({
            planType: this.props.userPlanData?.id ?? "",
            planName: this.props.userPlanData?.meta_data?.displayName ?? "",
            paymentStartText:
                this.props.userPlanData?.meta_data?.billingString ?? "",
            checkContent: this.props.userPlanData?.meta_data?.content ?? [],
            exContent: this.props.userPlanData?.meta_data?.exContent ?? [],
        });
    }

    onHandleClose = () => {
        const loggedInUser = localStorage.getItem("loggedInUser");
        window.userGuiding.previewGuide(44272);
        if (loggedInUser) {
            this.props.setOnboardingState(DEFAULT);
        } else {
            this.props.setOnboardingState(DEFAULT);
        }
    };

    upgrade = () => {
        this.props.history.push("/billing");
        this.props.setOnboardingState(DEFAULT);
    };

    render() {
        const {
            planName,
            paymentStartText,
            planImage,
            checkContent,
            exContent,
        } = this.state;
        return (
            <EventsModal
                plan={true}
                edit={true}
                onHandleClose={this.onHandleClose}
            >
                <div className={styles.rowColumn}>
                    <div className={styles.row}>
                        <div className={styles.headersContainer}>
                            <div className={styles.planName}>
                                Your Plan -{" "}
                                <span
                                    className={`${styles.highlighted} ${styles.allCaps}`}
                                >
                                    {planName}
                                </span>
                            </div>
                            <p className={styles.subheader}>
                                {paymentStartText}
                            </p>
                        </div>
                        <img alt="alt" src={planImage} />
                    </div>
                    <div className={styles.rowColumn}>
                        {checkContent.map((content, i) => {
                            return (
                                <div className={styles.content} key={i}>
                                    <img
                                        alt="alt"
                                        className={styles.svg}
                                        src={checkMarkSvg}
                                    />
                                    {content}
                                </div>
                            );
                        })}
                        {exContent.map((content, i) => {
                            return (
                                <div className={styles.content} key={i}>
                                    <img
                                        alt="alt"
                                        className={styles.svg}
                                        src={exSvg}
                                    />
                                    {content}
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.getStartedButtonContainer}>
                        <button
                            className={styles.getStartedButton}
                            onClick={this.onHandleClose}
                        >
                            Got it. Let’s get started!
                        </button>
                    </div>
                    {/* <div className={styles.row}>
                        <div
                            onClick={this.onHandleClose}
                            className={styles.coloredText}
                        >
                            Continue with this Plan
                        </div>
                        <div
                            onClick={this.upgrade}
                            className={styles.upgradeButton}
                        >
                            Upgrade Now
                        </div>
                    </div> */}
                </div>
            </EventsModal>
        );
    }
}

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import BankCard from "../Card/bankCard";
import BankInput from "../InputContainer/BankInput";

// Import Connection Image (if exists)
import BankConnectionImage from "Assets/_optionDescriptionIcons/Bank white.jpg";

// Import Coloured PNGs
import bankBlack from "../../Assets/_eventNodeTypes/bank-black-updated.svg";
import bankBlue from "../../Assets/_eventNodeTypes/bank-blue.png";
import bankWhite from "../../Assets/_eventNodeTypes/bank-white.png";
import bankGrey from "../../Assets/_eventNodeTypes/bank-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/bankEvent.png";

// Notes
// To call an object with no parameters, can simply use
//  - bankObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - bankObject.checkInput.call(bankObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const bankObject = {
    constant: function () {
        return Bank_Constant();
    },

    name: function () {
        return "Bank";
    },

    checkInput: function (state) {
        return Bank_CheckInput(state);
    },

    svg: function () {
        return Bank_Svg();
    },

    eventNodeMenu: function (focus) {
        return Bank_EventNodeMenu(focus);
    },

    indexText: function () {
        return Bank_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Bank_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Bank_OptionDescription();
    },

    colouredPngs: function () {
        return Bank_Icons();
    },

    version: function () {
        return "2.0.0";
    },

    sideModal: function () {
        return Bank_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Bank_GenerateDisplayFieldData();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Bank_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        // if (!entity.name) return false;

        if (entity.data.bankSelected === "manual") {
            let passed = true;
            for (let i = 0; i < entity.data.accounts.length; i++) {
                if (
                    !entity.data.accounts[i].accountName ||
                    !entity.data.accounts[i].accountBalance ||
                    !entity.data.accounts[i].accountType
                ) {
                    passed = false;
                }
            }

            if (passed) {
                return true;
            } else {
                return false;
            }
        }

        if (entity.data.bankSelected !== "manual") {
            if (
                entity.data.selected.length === 0 &&
                entity.data.bankSelected !== "manual"
            )
                return false;
            else return true;
        }
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Bank_Constant = () => {
    return "25a5ae7f-f0ce-4f77-94c2-1319bbbb7427";
};

// EFFECTS: Returns black png / svg for event
const Bank_Svg = () => {
    return bankBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Bank_EventNodeMenu = (focus) => {
    return <BankCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Bank_IndexText = () => {
    return "Use our Bank Event to manually or automatically add and update daily any balances associated with your bank account. We use industry leading, secure, 3rd party platforms and never access or store any of your banking credentials.";
};

// EFFECTS: Returns all icons for event ??
const Bank_Icons = () => {
    let pngs = new Map([
        ["black", bankBlack],
        ["blue", bankBlue],
        ["white", bankWhite],
        ["grey", bankGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Bank_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Bank_InputComponentInfoPassing = (minProps) => {
    return <BankInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, banks for event ??
const Bank_OptionDescription = () => {
    const tags = ["Option"];
    const type = bankObject.name();
    const description =
        "The Bank Event is used to set initial, or ongoing balances, for all of a company’s institutional-related Accounts. This can include traditional bank accounts, credit cards, loans, and any other Account associated with that account. Balances can be manually entered or linked directly to actual accounts for daily real-time updates.";
    const usedForText =
        "Tracking both transactions and account balances is fundamental to maintaining and projecting future cash flow. Use the Bank Event to reconcile available funds, debts and account balances.";
    const connection =
        "It is common to place the Bank Event inside of the Baseline.";
    const connectionImage = BankConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/b/bank.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Bank_SideModalImage = () => {
    return sideImage;
};

const Bank_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";
import { getRelevantEntities } from "actions/getNodeEntityActions";

export const debtRepaymentInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "fixedVsRemaining"
        | "value"
        | "debtOrLoan"
        | "cadence"
        | "startDate"
        | "endDate"
        | "paymentDate"
        | "accountName"
        | "contraAccountName",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "fixedVsRemaining":
            data.fixedVsRemaining = value;
            if (value === "remaining") {
                currentEntityObject.cadence = "one-time";
                currentEntityObject.startDate = "";
                currentEntityObject.endDate = "";
                data.value = 0;
            } else {
                data.paymentDate = "";
            }

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            data.value = parseInt(value);
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "debtOrLoan":
            const parsedDebt = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedDebt,
                eventId,
                currentEntityObject.id,
                data.debtEventId,
                data.debtIds
            );

            const entity = Object.values(
                getRelevantEntities(parsedDebt.entityIds)
            )?.[0];

            console.log("debtOrLoan changed: " + value);
            console.log(entity);

            data.selectedDebtName = entity.name;
            data.selectedDebt = parsedDebt;
            data.debtEventId = parsedDebt?.eventId ?? "";
            data.debtIds = parsedDebt?.entityIds ?? [];
            data.loanAccountId = entity.data.loanAccountId;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;

        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";

            // Update all modifier/override startDates
            if (currentEntityObject?.data?.modsCreated) {
                for (const mod of currentEntityObject?.data?.modsCreated) {
                    mod.startDate = startDateString;
                }
            }

            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            const endDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "paymentDate":
            const paymentDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            data.paymentDate = paymentDateString;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name ?? "";
            data.accountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "contraAccountName":
            if (!account) break;
            data.contraAccountName = account.name ?? "None (default)";
            data.contraAccountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

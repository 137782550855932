import type { ModifiersSchema } from "./typesSchema/modifiersSchema";
import { SET_MODIFIERS, UPSERT_MODIFIERS } from "../actions/modifiersActions";

const initialState: ModifiersSchema = {};

export default function modifiersReducer(
    state: ModifiersSchema = initialState,
    action: { type: string; payload?: any }
): ModifiersSchema {
    switch (action.type) {
        case SET_MODIFIERS:
            return { ...action.payload };
        case UPSERT_MODIFIERS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

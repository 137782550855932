// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    ModalRowHalf,
    SelectEntities,
    Rate,
    SelectDropDown,
    EntitySummaryHighlight
} from "../Components";
import { outboundSalesObject } from "Components/Registry/OutboundSales";

const cadences = [
    "annually",
    "quarterly",
    "monthly",
    "weekly",
    "one-time",
];

const cadenceDurations = [
    "years",
    "quarters",
    "months",
    "weeks",
    "days",
];

export default function OutboundSalesInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    onHandleSubmit,
    handleOnChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    selectedCustomerName,
}) {
    const entityData = entitiesMap[currentEntity].data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={outboundSalesObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={outboundSalesObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[outboundSalesObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <div style={{ height: "10px" }} />
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.data?.selectedCustomer ?? {}
                            }
                            nodes={Object.values(entityData.customers)}
                            className="mlsInput"
                            id="customer"
                            label="Target Customer Cohort"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <div className="entity-subsection-label">
                        Sales Pipeline Details
                    </div>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Rate
                            label="Deals Closed Per Salesperson"
                            onChangeInput={handleOnChange}
                            value={entityData.value}
                            id="value"
                            className="mlsInput"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.cadence}
                            options={cadences}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Frequency"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <div className="entity-subsection-label">
                        Sales Lead Time Details
                    </div>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Rate
                            label="Lead time per Deal"
                            onChangeInput={handleOnChange}
                            value={entityData.leadTime}
                            id="leadTime"
                            className="mlsInput"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={entityData.leadTimeFrequency}
                            options={cadenceDurations}
                            className="select-dropdown"
                            id="leadTimeFrequency"
                            label="Select Frequency"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <div style={{ height: "10px" }} />
                <ModalRow>
                    <EntitySummaryHighlight mainText={`For Outbound Sales, the average salesperson can close ${new Intl.NumberFormat().format(parseFloat(entityData.value).toFixed(2)) || 0} ${selectedCustomerName ?? "customers"} deals ${entitiesMap[currentEntity]?.cadence} with a lead time of ${entityData?.leadTime || 0} ${entityData?.leadTimeFrequency} per deal.`} highlightedData={new Intl.NumberFormat().format(parseFloat(entityData?.value).toFixed(2)) || 0} />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                passedCheck={passedCheck}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
            />
        </EventEntityWrapper>
    );
}

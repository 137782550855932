// @ts-nocheck

import {
    SingleEntity,
    Description,
    InputButtons,
    EventDetails,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    BasicTextInput,
    ModalRowHalf,
    SelectEntities,
    ModalRowComponent,
    Rate,
    CssTextField,
} from "../Components";
import { allocationObject } from "Components/Registry/Allocation";

const Segment = ({
    index,
    handleOnChange,
    segmentName,
    allocation,
}) => {
    return (
        <ModalRow>
            <div style={{ display: "flex", flex: 1, gap: "8px" }}>
                <ModalRowComponent width={60}>
                    <BasicTextInput
                        inputLabel="Segment Name"
                        onChangeInput={handleOnChange}
                        value={segmentName}
                        id="segmentName"
                        className="mlsInput"
                        mainId={index}
                        disabled={true}
                        required={true}
                    />
                </ModalRowComponent>
                <ModalRowComponent width={18}>
                    <Rate
                        label="Allocation"
                        onChangeInput={handleOnChange}
                        value={allocation}
                        id="allocation"
                        className="mlsInput"
                        mainId={index}
                        sign={true}
                        required={true}
                    />
                </ModalRowComponent>
            </div>
        </ModalRow>
    );
};

export default function AllocationInputView({
    entitiesMap,
    currentEntity,
    onChangeNameDescription,
    handleOnChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    onHandleSubmit,
}) {

    const entityData = entitiesMap[currentEntity].data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={allocationObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={allocationObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.data?.selectedSegment ?? {}
                            }
                            nodes={Object.values(
                                entitiesMap?.[currentEntity]?.data?.segmentEvents ||
                                []
                            )}
                            className="mlsInput"
                            id="segment"
                            label="Source Segment"
                            groupEventType="Customer"
                            required={true}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        {entityData?.customerIds.length > 0 && (
                            <CssTextField
                                value={entityData?.customerName}
                                disabled={true}
                                label="Source Customer"
                            />
                        )}
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    {entityData?.segments.length > 0 && (<div className="entity-section-label">Segments</div>)}
                </ModalRow>
                {entityData?.segments.map((segment, i) => {
                    return (
                        <Segment
                            key={i}
                            index={i}
                            handleOnChange={handleOnChange}
                            segmentName={segment.name}
                            allocation={segment.allocation}
                        />
                    );
                })}
                <div style={{ display: "flex", flex: 1, marginTop: "50px" }}>
                </div>
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

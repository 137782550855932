import React, { Component } from "react";
import "./Card.css";
import moment from "moment";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";
// import { customerTransferObject } from "Components/Registry/CustomerTransfer";

class CustomerCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    getDependencyData = (eventId, entityIds) => {
        let dependantEntity = {};
        if (entityIds.length > 0) {
            dependantEntity = getRelevantEntities(entityIds)[entityIds[0]];
        }
        const dependantEvent = getEvent(eventId);

        return { dependantEvent, dependantEntity };
    };

    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }

        const { startDate, endDate, cadence, data } =
        this.state.allEntities[this.state.currentIndex];

        const { 
            value,
            modType,
            selectedSourceCustomer,
            selectedTargetCustomer,
            subType,
            specificPercentages,
            interpolateSubType,
            maxGrowth,
        } = data;

        const sourceCustomerData = this.getDependencyData(
            selectedSourceCustomer.eventId,
            selectedSourceCustomer.entityIds
        );

        const targetCustomerData = this.getDependencyData(
            selectedTargetCustomer.eventId,
            selectedTargetCustomer.entityIds
        );
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Source Customer:</div>
                    <div className="cardValue">{`${
                        sourceCustomerData.dependantEvent.name
                    } - ${
                        sourceCustomerData.dependantEntity.name ?? "All"
                    }`}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Target Customer:</div>
                    <div className="cardValue">{`${
                        targetCustomerData.dependantEvent.name
                    } - ${
                        targetCustomerData.dependantEntity.name ?? "All"
                    }`}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Date:</div>
                    <div className="cardValue">
                        {startDate
                            ? moment(startDate).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {endDate
                            ? moment(endDate).format("MMM D YYYY")
                            : "No End Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Modifier Type:</div>
                    <div className="cardValue">{modType ?? ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Transfer Type:</div>
                    <div className="cardValue">{subType ?? ""}</div>
                </div>
                {subType === "constant" && (
                    <div className="Row">
                        <div className="ExpenseLabel">Transfer Amount:</div>
                        <div className="cardValue">
                                {`
                            ${
                                value
                                    ? new Intl.NumberFormat().format(
                                        parseFloat(value).toFixed(2)
                                    )
                                    : ""
                            } ${cadence ? `/ ${cadence}` : ""}
                                `}
                            </div>
                    </div>
                )}
                {subType === "dynamic" && (
                    <>
                        <div className="Row">
                            <div className="ExpenseLabel">
                                Dynamic Growth Type:
                            </div>
                            <div className="cardValue">
                                {interpolateSubType}
                            </div>
                        </div>
                        {maxGrowth && (
                            <div className="Row">
                                <div className="ExpenseLabel">
                                    Max Growth Rate:
                                </div>
                                <div className="cardValue">{maxGrowth}%</div>
                            </div>
                        )}
                    </>
                )}
                {subType === "dynamic" && specificPercentages?.length > 0 && specificPercentages.map((specificValue) => (
                    <div className="Row" key={specificValue?.id}>
                        <div className="ExpenseLabel">{`${specificValue?.month}, ${specificValue?.year}:`}</div>
                        <div className="cardValue">
                            {`${
                                specificValue.value
                                    ? new Intl.NumberFormat().format(
                                        parseFloat(specificValue.value).toFixed(2)
                                    )
                                    : ""
                            }%`}
                        </div>
                    </div>
                ))}

            </div>
        );
    }
}

export default CustomerCard;

import type { Action } from "redux";
import type { ThunkAction } from "redux-thunk";
import { applyMiddleware, createStore, compose } from "redux";
import reduxThunk from "redux-thunk";
import { reducers } from "reducers";

const composeEnhancers =
    process.env.NODE_ENV === "development"
        ? (
           window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] && 
           window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({ trace: true, traceLimit: 25 }) ||
           compose
           )
        : compose;

const store = createStore(
    reducers,
    { auth: null },
    composeEnhancers(applyMiddleware(reduxThunk))
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;

import { getRelevantEntities } from "actions/getNodeEntityActions";
import React, { Component } from "react";
import "./Card.css";
import {
    CardContainer,
    Description,
    DollarValue,
    PercentValue,
    Row,
    RowLabel,
    TextValue,
} from "./Components";

class PropertyValueCard extends Component {
    render() {
        // As of right now we are just grabbing and displaying the first entity
        // TODO: Add the ability to scroll through all entities, similar to the input container
        const entity = Object.values(
            getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            )
        )[0];
        const { description, startDate, version, data } = entity;

        return (
            <CardContainer>
                <Row>
                    <Description text={description} />
                </Row>
                <Row>
                    <RowLabel text="Updated Value" />
                    <DollarValue value={data.propertyValue} />
                </Row>
                <Row>
                    <RowLabel text="Present Value" />
                    <DollarValue value={data.presentValue} />
                </Row>
                <Row>
                    <RowLabel text="Equity" />
                    <DollarValue value={data.equity} />
                </Row>
                <Row>
                    <RowLabel text="Remaining Principal" />
                    <DollarValue value={data.remainingPrincipal} />
                </Row>
                <Row>
                    <RowLabel text="House" />
                    <TextValue text={data.house} />
                </Row>
                <Row>
                    <RowLabel text="Date" />
                    <TextValue text={startDate} />
                </Row>
                {data.appreciation && (
                    <Row>
                        <RowLabel text="Updated Appreciation Rate" />
                        <PercentValue value={data.appreciationRate} />
                    </Row>
                )}
                <Row>
                    <RowLabel text="Version" />
                    <TextValue text={version} />
                </Row>
            </CardContainer>
        );
    }
}

export default PropertyValueCard;

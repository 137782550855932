// @ts-nocheck

import { debtObject } from "Components/Registry/Debt";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import {
    Description,
    EntityCard,
    EntityName,
    EventDetails,
    EventEntityWrapper,
    EventHeader,
    InputButtons,
    ModalRow,
    ModalRowHalf,
    Value,
    SelectDropDown,
    Rate,
    MuiCalendar,
} from "../Components";
import { useState } from "react";

const options = ["Credit Card Interest" /*"Lines of Credit"*/];

export default function DebtInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleEntityStateChange,
    handleOnChange,
    handleDateSelection,
}) {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    const onUpdate = () => {
        setCreatingCustomAccounts(true);
        onHandleSubmit();
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={debtObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={debtObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[debtObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>

                {creatingCustomAccounts ? (
                    <div>Creating custom accounts...</div>
                ) : (
                    <>
                        <ModalRow>
                            <Value
                                label="Debt Amount"
                                onChangeInput={handleOnChange}
                                value={entitiesMap?.[currentEntity]?.data?.debt}
                                id="value"
                                unit="$"
                                required={true}
                            />
                        </ModalRow>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <Rate
                                    useFullWidth={true}
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.interestRate
                                    }
                                    id="interestRate"
                                    label="Interest"
                                    required={true}
                                    inputAdornmentOverride="%"
                                />
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.interestType
                                    }
                                    options={options}
                                    id="interestType"
                                    label="Interest Type"
                                    required={true}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        <ModalRow>
                            <ModalRowHalf>
                                <MuiCalendar
                                    id="startDate"
                                    value={
                                        entitiesMap?.[currentEntity]?.startDate
                                    }
                                    required={true}
                                    onChangeInput={handleDateSelection}
                                    label="Start Date"
                                />
                            </ModalRowHalf>
                            {/* <SingleCalendar
                        onHandleDate={onHandleDate}
                        date={entitiesMap?.[currentEntity]?.startDate}
                        entityIndex={entityIndex}
                    /> */}
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onUpdate}
                onHandleSubmitValues={onUpdate}
                onHandleSubmit={onUpdate}
                passedCheck={!creatingCustomAccounts && passedCheck}
            />
        </EventEntityWrapper>
    );
}

import { Component } from "react";
import "./Card.css";
// import starFilledSvg from "../../Assets/star_filled.svg";
// import starUnfilledSvg from "../../Assets/star_unfilled.svg";
// import { customerGrowthObject } from "../Registry/CustomerGrowth";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";

class CustomerGrowthCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };
    /**
     * Takes an array of customer entities and returns the total amount of customers.
     */
    getTotalCustomers = (customerEntities) => {
        let totalCustomers = 0;
        customerEntities.forEach((customerEntity) => {
            const numberOfCustomers = parseFloat(customerEntity.data.customer);
            totalCustomers = totalCustomers + numberOfCustomers;
        });
        return totalCustomers;
    };

    render() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );
        const firstEntity = Object.values(entities)[0];

        const {
            startGrowth,
            subType,
            interpolateSubType,
            maxGrowth,
            constantValue,
            inheritCustomer,
        } = firstEntity.data;
        const { data } = firstEntity;

        const dependantCustomerEntityIds = data?.selectedCustomer?.entityIds ?? []
        let dependantCustomerEntity = {};
        if (dependantCustomerEntityIds.length > 0) {
            dependantCustomerEntity =
                getRelevantEntities(dependantCustomerEntityIds)[
                    dependantCustomerEntityIds?.[0]
                ];
        }
        const dependantCustomerEventId = data?.selectedCustomer?.eventId
        const dependantEvent = getEvent(dependantCustomerEventId);

        const allDependantEventEntities = Object.values(
            getRelevantEntities(dependantEvent.entities)
        );
        const dependantEventTotalCustomers = this.getTotalCustomers(
            allDependantEventEntities
        );
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Selected Customer:</div>
                    <div className="cardValue">{`${dependantEvent.name} - ${
                        dependantCustomerEntity.name ?? "All"
                    }`}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Initial Customers/Month:</div>
                    <div className="cardValue">{`${
                        inheritCustomer
                            ? `${
                                dependantCustomerEntityIds.length > 0
                                      ? `${dependantCustomerEntity.data.customer}`
                                      : `${dependantEventTotalCustomers}`
                              }`
                            : `${startGrowth}`
                    }`}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Growth Type:</div>
                    <div className="cardValue">{subType}</div>
                </div>
                {subType === "dynamic" && (
                    <>
                        <div className="Row">
                            <div className="ExpenseLabel">
                                Dynamic Growth Type:
                            </div>
                            <div className="cardValue">
                                {interpolateSubType}
                            </div>
                        </div>
                        {maxGrowth && (
                            <div className="Row">
                                <div className="ExpenseLabel">
                                    Max Growth Rate:
                                </div>
                                <div className="cardValue">{maxGrowth}%</div>
                            </div>
                        )}
                    </>
                )}
                 {data.subType === "dynamic" && data.specificPercentages?.length > 0 && data.specificPercentages.map((specificValue) => (
                    <div className="Row" key={specificValue?.id}>
                        <div className="ExpenseLabel">{`${specificValue?.month}, ${specificValue?.year}:`}</div>
                        <div className="cardValue">
                            {`${
                                specificValue.value
                                    ? new Intl.NumberFormat().format(
                                        parseFloat(specificValue.value).toFixed(2)
                                    )
                                    : ""
                            }%`}
                        </div>
                    </div>
                ))}
                {subType === "constant" && (
                    <div className="Row">
                        <div className="ExpenseLabel">
                            Constant Growth Rate:
                        </div>
                        <div className="cardValue">{constantValue}%</div>
                    </div>
                )}
            </div>
        );
    }
}

export default CustomerGrowthCard;

import {
    useState,
    ChangeEvent,
    useEffect,
    useMemo,
    useContext,
    useRef,
    MutableRefObject,
} from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { getDefaultName } from "helpers";
import { revenueObject } from "Components/Registry/Revenue";
import RevenueInputsHandler from "../OnInputChangeHandlers/revenueInputsHandler";
import RevenueInputView from "./RevenueInputView";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import {
    getPresentableDependencies,
    getPresentableDependenciesOfManyTypes,
} from "helpers/nodeDependencyDetectionHelpers";
import { throwError } from "helpers/swalError";
import { customerObject } from "Components/Registry/Customer";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import {
    EntityCustomAccount,
    getAccountsToDelete,
    getPaths,
    handleCustomAccountStructures,
} from "helpers/accounts";
import * as uuid from "uuid";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { segmentObject } from "Components/Registry/Segment";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { averageOrderObject } from "Components/Registry/AverageOrder";

export default function RevenueInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...useAppSelector(
            (state) => state?.scenario?.loadedScenario?.dependency_map
        ),
    });

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(revenueObject);
            _eventData.name = getDefaultName(revenueObject.name(), propsObject);
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        revenueObject,
        dependencyMap.current
    ); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );

    const revenueSource =
        entitiesMap[currentEntity]?.data?.revenueSource ?? "customer";

    const getCustomers = () => {
        const customers = {};

        getPresentableDependencies(
            customers,
            customerObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (
            !Object.keys(customers).length &&
            entitiesMap[currentEntity].data.revenueSource === "customer"
        ) {
            throwError(
                "warning",
                "No Customer or Group nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return customers;
    };

    const getSegments = () => {
        const segments = {};

        getPresentableDependencies(
            segments,
            segmentObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (
            !Object.keys(segments).length &&
            entitiesMap[currentEntity].data.revenueSource === "segment"
        ) {
            throwError(
                "warning",
                "No Segment nodes found upstream",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }
        return segments;
    };

    const getUnitCosts = () => {
        const unitCosts = {};

        getPresentableDependenciesOfManyTypes(
            unitCosts,
            [unitCostObject.constant(), averageOrderObject.constant()],
            propsObject, // Temporary object
            "Unit Cost",
            true
        );

        if (!Object.keys(unitCosts).length) {
            throwError(
                "warning",
                `No unit cost nodes found upstream or in baseline`
            );
        }
        return unitCosts;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const unitCosts = getUnitCosts();

            const newEntitiesMap = { ...prevEntitiesMap };
            const newCurrentEntity = { ...newEntitiesMap[currentEntity] };
            const newData = { ...newCurrentEntity.data };

            if (newData.revenueSource === "customer") {
                const customers = getCustomers();
                newData.customers = customers;
            } else {
                const segments = getSegments();
                newData.segments = segments;
            }

            newData.unitCosts = unitCosts;
            newCurrentEntity.data = newData;
            newEntitiesMap[currentEntity] = newCurrentEntity;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap, revenueSource]);

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "revenueSource"
            | "customer"
            | "segment"
            | "unitCost"
            | "entityName"
            | "rating",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = RevenueInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = RevenueInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnInheritedDateChanged = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.overrideInheritedDate = !data.overrideInheritedDate;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    /**
     *
     * @param value value you wish to provide to the input handler
     * @param id the case you are targeting in the input handler
     * @param star optional
     */
    const forceEntityChange = (
        value: any,
        id:
            | "revenueSource"
            | "customer"
            | "segment"
            | "unitCost"
            | "entityName"
            | "rating",
        star: number
    ) => {
        const newEntitiesMap = RevenueInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const toggleDelayRevenue = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.delayRevenue = !data.delayRevenue;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmitValuesCustom = (newEntitiesMap, passedCheck) => {
        dispatch(
            // TODO: look into solution for all undefined statements
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const onHandleClickSubmitOrUpdate = () => {
        const accountsToDelete = getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            passedCheck,
            entitiesMap,
            createRevenueAccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const passedCheck =
        !!eventData.name && revenueObject.checkInput(entitiesMap);

    return (
        <RevenueInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
            handleOnInheritedDateChange={handleOnInheritedDateChanged}
            forceEntityChange={forceEntityChange}
            passedCheck={passedCheck}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            onHandleClickSubmitOrUpdate={onHandleClickSubmitOrUpdate}
            toggleDelayRevenue={toggleDelayRevenue}
        />
    );
}

export const createRevenueAccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const parentAccountId = uuid.v4();

    const accountStructures: EntityCustomAccount[] = [
        {
            Name: entity.name,
            Type: "ledger",
            Display: "$",
            Root: {
                topLevel: "488dd61d-8697-4213-8978-cf91755365a4",
                topLevelChildren: [parentAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Revenue - ", parentAccountId],
                    topLevelChildren: [],
                },
            ],
        },
    ];

    data.accountStructure = accountStructures;
    data.accountIdsPaths = getPaths(accountStructures);

    // Record accountIds in state (Maybe we can retrieve them from above struct?)
    data.parentAccountId = parentAccountId;

    data.accountIds = [
        "61f75c52-c089-4137-b424-e2f7bfc4d340", // Cash (child of current assets and assets)
        "63d6884b-7792-49b7-8fca-a0d1c0d025a2", // COGS (child of expenses)
        // "43863d4a-b569-44c8-a6d6-6e85e5e8b0c6", // ARPU (child of kpi)
        parentAccountId, // Revenue custom account
    ];

    currentEntityObject.data = data;

    return currentEntityObject;
};

import React, { Component } from "react";
import "./InputContainer.css";
import Calendar from "../Calendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import {
    Description,
    SelectDropDown,
    Url,
    InputButtons,
    ModalRow,
    ModalRowHalf,
    Rate,
    EventInputModal,
    EventInputs,
    EventHeader,
    RequiredStar,
    ToggledContainer,
    ToggledContainerHalf,
    StarsRating,
    InflationInput,
    B,
    Value,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { Text } from "react-konva";
import { tuitionObject } from "../Registry/Tuition";

const options = ["annually", "per-term", "one-time"];

class TuitionInput extends Component {
    constructor(props) {
        super(props);

        if (props.edit) {
            this.state = { ...props.editData };
        } else {
            this.state = TuitionInput.getDefaultState(
                props.inflationRate,
                getDefaultName(tuitionObject.constant(), props),
                props.baseline
            );
        }
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            isFilled: false,
            valid: false,
            url: "",
            start: null,
            end: null,
            rating: 0,
            cost: null,
            quantity: 0,
            name: name,
            description: "",
            cadence: "",
            showSettings: false,
            type: tuitionObject.constant(),
            value: null,
            tag: `@${tuitionObject.constant()}`,
            onHoverStar: null,
            applyInflation: false,
            inflationRate: inflationRate,
            baseline: isInBaseline,
            tuitionType: "fixed",
        };
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;
        switch (id) {
            case "value":
                this.setState({
                    cost: value,
                    value: -parseInt(value),
                });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "cost":
                if (this.state.tuitionType === "variable") {
                    this.setState({
                        cost: value,
                        value: this.state.quantity * value,
                    });
                } else {
                    this.setState({
                        cost: value,
                    });
                }
                break;
            case "quantity":
                if (this.state.tuitionType === "variable") {
                    this.setState({
                        quantity: value,
                        value: this.state.cost * value,
                    });
                } else {
                    this.setState({
                        quantity: value,
                    });
                }
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "cadence":
                if (value === "") {
                    this.setState({ cadence: "" });
                } else {
                    this.setState({ cadence: value });
                }
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "inflationRate":
                this.setState({ inflationRate: value });
                break;
            default:
        }
    };

    toggleInflation = () => {
        this.setState({ applyInflation: !this.state.applyInflation });
    };

    getCadenceUnit = (cadence, numUnit = 0) => {
        const num = Number(numUnit);
        switch (cadence) {
            case "annually":
                return num !== 1 ? "years" : "year";
            case "monthly":
            case "semi-monthly":
                return num !== 1 ? "months" : "month";
            case "bi-weekly":
            case "weekly":
                return num !== 1 ? "weeks" : "week";
            case "daily":
                return num !== 1 ? "days" : "day";
            case "one-time":
            default:
                return "";
        }
    };

    onHandleDate = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = tuitionObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    handleChangeTuitionType = (e) => {
        const newType = e.target.id;
        let newValue;
        if (newType === "ongoing") {
            newValue = Number(this.state.cost);
        } else {
            let paymentAmount =
                Number(this.state.cost) / this.state.numPayments;
            newValue = -paymentAmount.toFixed(2);
        }

        this.setState({ tuitionType: newType, value: newValue });
    };

    getFixedTuitionPanel = () => {
        const { cadence, cost, url } = this.state;
        return (
            <>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={cadence}
                            options={options}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Payment Frequency"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Value
                            label="Cost"
                            onChangeInput={this.onChangeInput}
                            value={cost}
                            id="value"
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <Url
                        value={url}
                        className="UrlLink"
                        onChangeInput={this.onChangeInput}
                        id="url"
                        label="URL"
                    />
                </ModalRow>
            </>
        );
    };

    infoBox = () => {
        let paymentAmount = this.state.value;
        if (!isFinite(paymentAmount) || isNaN(paymentAmount)) paymentAmount = 0;
        return (
            <ModalRow>
                <div className="loanPaymentsContainer">
                    <div className="paymentsAmountContainer">
                        <Text>
                            Your current tuition is{" "}
                            <B>${this.state.value || "0"}</B> .
                        </Text>
                    </div>
                </div>
            </ModalRow>
        );
    };

    getVariableTuitionPanel = () => {
        const { cadence, cost, url, quantity } = this.state;
        return (
            <>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={cadence}
                        options={options}
                        className="select-dropdown"
                        id="cadence"
                        label="Select Payment Frequency"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Rate
                            label="Credits/Courses"
                            onChangeInput={this.onChangeInput}
                            value={quantity}
                            className="mlsInput"
                            id="quantity"
                            sign={false}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Value
                            label="Cost per Credit/Course"
                            onChangeInput={this.onChangeInput}
                            value={cost}
                            id="cost"
                        />
                    </ModalRowHalf>
                </ModalRow>
                {this.infoBox()}
                <ModalRow>
                    <Url
                        value={url}
                        className="UrlLink"
                        onChangeInput={this.onChangeInput}
                        id="url"
                        label="URL"
                    />
                </ModalRow>
            </>
        );
    };

    render() {
        const passedCheck = tuitionObject.checkInput(this.state);
        const { edit } = this.props;

        const {
            name,
            description,
            rating,
            onHoverStar,
            tuitionType,
            applyInflation,
            inflationRate,
            start,
            end,
            cadence,
        } = this.state;
        const ixFixed = tuitionType === "fixed";
        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={tuitionObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={tuitionObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ToggledContainer>
                        <ToggledContainerHalf
                            id="fixed"
                            isSelected={ixFixed}
                            onClick={this.handleChangeTuitionType}
                            title="Fixed Tuition"
                        />
                        <ToggledContainerHalf
                            id="variable"
                            isSelected={!ixFixed}
                            onClick={this.handleChangeTuitionType}
                            title="Variable Tuition"
                        />
                    </ToggledContainer>
                    {ixFixed
                        ? this.getFixedTuitionPanel()
                        : this.getVariableTuitionPanel()}
                    <ModalRow>
                        <RequiredStar />
                        <Calendar
                            onHandleDate={this.onHandleDate}
                            startDate={start}
                            endDate={end || null}
                            cadence={cadence}
                        />
                    </ModalRow>
                    <ModalRow>
                        <StarsRating
                            onHoverStar={onHoverStar}
                            rating={rating}
                            onChangeInput={this.onChangeInput}
                            onHandleStarHover={this.onHandleStarHover}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ModalRow>
                    <ModalRow>
                        <InflationInput
                            applyInflation={applyInflation}
                            inflationRate={inflationRate}
                            onChangeInput={this.onChangeInput}
                            toggleInflation={this.toggleInflation}
                        />
                    </ModalRow>
                </EventInputs>
                <InputButtons
                    edit={edit}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {  },
                            passedCheck
                        )
                    }
                    isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                    isLibraryEvent={this.state.isLibraryEvent}
                    addLibraryEventToScenario={() => {
                        this.props.addLibraryEventToScenario(this.state);
                    }}
                    divorceLibraryEvent={() => {
                        this.props.divorceFromLibraryEvent(
                            this.state,
                            this.props.updateValues
                        );
                    }}
                />
            </EventInputModal>
        );
    }
}

export default TuitionInput;

import React, { Component } from "react";
import "./ShareScenario.css";
import { EventsModal } from "../EventsModal";
import * as EmailValidator from "email-validator";
import closeSvg from "../../Assets/close.svg";
import profileSvg from "../../Assets/_home/profile.svg";
import { throwError } from "../../helpers/swalError";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import { config } from "../../config";
import { isActiveUserAdmin } from "../../helpers/userHelpers";
import { getDefaultScenarioPermissions } from "../../helpers";
import { Mixpanel } from "../../helpers/mixpanel";

class ShareScenarioContainer extends Component {
    constructor(props) {
        super(props);

        const agencyClients = this.props.agencyClients || [];
        const searchableClients = agencyClients.map((client) => {
            return client.clientdata.email;
        });
        this.state = {
            recipients: this.generateRecipients(),
            copySuccess: false,
            copyAccess: null,
            notAddingUser: true,
            currentPanel: "userList",
            editUser: {},
            doTemplateShare: false,
            searchableClients,
            baselineIsPublic:
                props.baselineDataManager.hasActiveBaseline() &&
                props.baselineDataManager.getActiveBaseline().permissions
                    ?.default?.view,
        };
    }

    generateRecipients = () => {
        const { loadedScenario } = this.props;
        let recipients = [];

        if (loadedScenario.permissions) {
            const whitelist = this.props.loadedScenario.permissions.whitelist;
            for (const entry of Object.keys(whitelist)) {
                let access = null;
                if (whitelist[entry].edit) {
                    access = "Can Edit";
                } else if (whitelist[entry].comment) {
                    access = "Can Comment";
                } else if (whitelist[entry].view) {
                    access = "Can View";
                }
                recipients.push({
                    view: whitelist[entry].view,
                    edit: whitelist[entry].edit,
                    comment: whitelist[entry].comment,
                    share: whitelist[entry].share,
                    clone: whitelist[entry].clone,
                    changePermissions: whitelist[entry].changePermissions,
                    email: entry,
                    access,
                    includeBaseline: whitelist[entry].includeBaseline || false,
                });
            }
        } else {
            this.props.updateScenario({
                ...loadedScenario,
                permissions: getDefaultScenarioPermissions(),
            });
        }
        return recipients;
    };
    onHandleMessage = (e) => {
        const value = e.target.value;

        const { editUser } = this.state;
        editUser.message = value;
    };

    // determines permissions of user based on entered 'access' type
    computePermissions = (person) => {
        switch (person.access) {
            case "Can View":
                person.view = true;
                person.edit = false;
                person.comment = false;
                person.changePermissions = false;
                person.clone = false;
                person.share = true;
                break;
            case "Can Comment":
                person.view = true;
                person.edit = false;
                person.comment = true;
                person.changePermissions = false;
                person.clone = false;
                person.share = true;
                break;
            case "Can Edit":
                person.view = true;
                person.edit = true;
                person.comment = true;
                person.changePermissions = false;
                person.clone = false;
                person.share = true;
                break;
            default:
                person.view = false;
                person.edit = false;
                person.comment = false;
                person.changePermissions = false;
                person.clone = false;
                person.share = true;
        }
    };

    isUserDetailsValid = (userDetails) => {
        if (!EmailValidator.validate(userDetails.email)) {
            throwError(
                "error",
                "Invalid Email",
                "Please double check entered value"
            );
            return false;
        } else {
            return true;
        }
    };

    cancelAndReset = () => {
        this.setState({
            currentPanel: "userList",
            notAddingUser: true,
            editUser: {},
            doTemplateShare: false,
        });
    };

    onHandleEmailAdd = (e) => {
        const notAddingUser = !e.target.value;
        let editUser = notAddingUser
            ? {}
            : { ...this.state.editUser, email: e.target.value };
        this.setState({ notAddingUser, editUser });
    };

    getEmails = (recipients = []) => {
        return recipients.map((recip) => {
            return recip.email;
        });
    };

    // share scenario with user, notify if appropriate
    submitUserAdd = () => {
        const { editUser } = this.state;
        if (!editUser.access) editUser.access = "Can View";
        if (!editUser.includeBaseline) editUser.includeBaseline = false;
        const { loadedScenario } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const recipients = this.state.recipients.concat([editUser]);

        if (!this.isUserDetailsValid(editUser)) {
            return;
        } else {
            if (this.state.doTemplateShare) {
                this.performTemplateShare(
                    loadedScenario,
                    editUser,
                    loggedInUser,
                    recipients
                );
            } else {
                this.setState({ recipients });
                this.performScenarioShare(
                    loadedScenario,
                    editUser,
                    recipients,
                    loggedInUser
                );
            }

            this.cancelAndReset();
        }
    };

    // edit share permissions for a specified user, notify if appropriate
    submitUserEdit = () => {
        const { editUser, recipients } = this.state;
        const { loadedScenario } = this.props;
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (!this.isUserDetailsValid(editUser)) {
            return;
        } else {
            this.performScenarioShare(
                loadedScenario,
                editUser,
                recipients,
                loggedInUser
            );

            let index = 0;
            for (let i = 0; i < recipients.length; i++) {
                if (recipients[i].email === editUser.email) index = i;
            }
            recipients[index] = editUser;
            this.setState({ recipients });

            this.cancelAndReset();
        }
    };

    // For Whatifi Agency - shares loaded scenario (as a template) with the logged-in user's Agent
    shareWithAgent = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const { recipients } = this.state;
        const { loadedScenario } = this.props;
        try {
            const userData = this.props.getClientData(loggedInUser.id);
            const agentEmail =
                userData &&
                userData.clientdata &&
                userData.clientdata.agentEmail;
            if (!agentEmail) throw new Error();
            if (
                recipients.filter((recip) => {
                    return recip.email === agentEmail;
                }).length > 0
            ) {
                // list already contains agent
                throw new Error();
            } else {
                this.props.performTemplateShare(agentEmail, loadedScenario);

                this.prepareAndSendEmail(
                    loggedInUser,
                    { email: agentEmail, message: "", access: "" },
                    loadedScenario
                );
                throwError(
                    "success",
                    "Shared Scenario",
                    "This scenario has been shared as a template with your agent"
                );
            }
        } catch (error) {
            throwError(
                "error",
                "Share Failed",
                "Unable to share scenario with associated Agency"
            );
        }
    };

    performTemplateShare = (
        loadedScenario,
        editUser,
        loggedInUser,
        recipients
    ) => {
        this.performBaselineShare(loadedScenario, editUser, recipients);

        this.props.performTemplateShare(editUser.email, loadedScenario);

        this.prepareAndSendEmail(loggedInUser, editUser, loadedScenario);
    };

    performScenarioShare = (
        loadedScenario,
        editUser,
        recipients,
        loggedInUser
    ) => {
        const permissions = loadedScenario.permissions || {};
        const whitelist = permissions.whitelist || {};

        this.computePermissions(editUser);

        whitelist[editUser.email] = {
            view: editUser.view,
            edit: editUser.edit,
            comment: editUser.comment,
            share: editUser.share,
            changePermissions: editUser.changePermissions,
            clone: editUser.clone,
            includeBaseline: editUser.includeBaseline,
        };
        // update scenario permissions as well as the userDetails.sharedscenarios field
        this.props.shareByEmail(
            this.getEmails(recipients),
            loadedScenario.id,
            whitelist
        );

        loadedScenario.permissions.whitelist = whitelist;

        this.performBaselineShare(loadedScenario, editUser, recipients);

        this.prepareAndSendEmail(loggedInUser, editUser, loadedScenario);
    };

    performBaselineShare = (loadedScenario, editUser, recipients) => {
        // set default baseline for scenario, if none exists
        const defaultBaseline =
            this.props.baselineDataManager.getDefaultBaseline(loadedScenario) ||
            {};
        const defaultBaselineId = defaultBaseline.id;
        if (!loadedScenario.baselineid && defaultBaselineId) {
            loadedScenario.baselineid = defaultBaselineId;
            this.props.updateScenario(loadedScenario);
        }

        // share baseline, if applicable
        if (editUser.includeBaseline && defaultBaselineId) {
            const toShareBaseline = recipients
                .filter((recip) => {
                    return recip.includeBaseline;
                })
                .map((recip) => {
                    return recip.email;
                });

            this.props.shareBaseline(toShareBaseline, defaultBaselineId);
        }
    };

    prepareAndSendEmail = (loggedInUser, editUser, loadedScenario) => {
        // send email
        const urlWithAccess = `${this.props.urlLink}/${editUser.access}`; // TODO: audit... is access needed in URL
        const emailData = {
            recipient: editUser.email,
            sender: loggedInUser.email,
            message: editUser.message || "",
            name: loggedInUser.name,
            scenarioName: loadedScenario.name,
            url: urlWithAccess,
        };
        this.props.sendEmail([emailData]);
    };

    // unshare scenario with the specified user
    submitUserUnshare = (e) => {
        const { loadedScenario } = this.props;
        const userIndex = e.target.id;
        const toRemove = this.state.recipients[userIndex];
        const recipients = this.state.recipients.filter((recip) => {
            return recip.email !== toRemove.email;
        });
        this.setState({ recipients });
        const whitelist = loadedScenario.permissions
            ? loadedScenario.permissions.whitelist
            : {};
        delete whitelist[toRemove.email];

        this.props.shareByEmail(
            this.getEmails(recipients),
            this.props.loadedScenario.id,
            whitelist
        );

        //FIXME: baseline unshare
        throwError(
            "warning",
            "Scenario Unshare Still in Progress",
            "If a baseline was shared with the user, it will not be unshared."
        );
    };

    getUserListPanel = () => {
        if (this.state.currentPanel !== "userList")
            this.setState({ currentPanel: "userList" });
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        const { loadedScenario } = this.props;
        const { recipients } = this.state;
        return (
            <>
                <div className="numUserShareText">
                    Shared with {recipients.length || 0}{" "}
                    {recipients.length === 1 ? "person" : "people"}
                </div>
                <div className="userList">
                    {loadedScenario.type !== "shared" && (
                        <div className="userListEntry">
                            <img
                                alt="alt"
                                src={profileSvg}
                                className="userProfileImage"
                            />
                            <div className="userListEntryDescriptor">
                                <div>
                                    <b>Me ({loggedInUser.email})</b>
                                </div>
                                Access to all the nodes
                            </div>
                            <div className="userListEntryRole">
                                <b>Owner</b>
                            </div>
                        </div>
                    )}
                    {recipients.map((recip, i) => {
                        return (
                            <div key={i} className="userListEntry">
                                <img
                                    alt="alt"
                                    src={profileSvg}
                                    className="userProfileImage"
                                />
                                <div className="userListEntryDescriptor">
                                    <div>
                                        <b>{`${recip.email}`}</b>
                                    </div>
                                    {recip.includeBaseline
                                        ? "Access to scenario and baseline events"
                                        : `Access to scenario events only`}
                                </div>
                                <div className="userListEntryRole">
                                    <b>
                                        {recip.access}
                                        <div
                                            className="userListChangePermission"
                                            onClick={() => {
                                                this.setState({
                                                    currentPanel: "userEdit",
                                                    editUser: recip,
                                                });
                                            }}
                                        >
                                            Change
                                        </div>
                                    </b>
                                </div>
                                <img
                                    alt="alt"
                                    src={closeSvg}
                                    className="userUnhare"
                                    id={i}
                                    onClick={this.submitUserUnshare}
                                />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    getUserEditPanel = () => {
        if (this.state.currentPanel !== "userEdit")
            this.setState({ currentPanel: "userEdit" });
        const { editUser } = this.state;

        return (
            <div className="shareContainerRoot">
                <div className="shareContainer">
                    <div className="sharePanelRow">
                        <input
                            className="addUserEmailInput"
                            placeholder="Enter email address.."
                            onChange={this.onHandleEmailAdd}
                            value={editUser.email}
                            disabled="disabled"
                            list="searchable"
                        />
                        <datalist id="searchable">
                            {this.state.searchableClients.map((client, key) => {
                                return <option key={key} value={client} />;
                            })}
                        </datalist>
                    </div>
                    <div className="sharePanelRow">
                        <select
                            onChange={(e) => {
                                this.setState({
                                    editUser: {
                                        ...editUser,
                                        includeBaseline:
                                            e.target.value !==
                                            "Scenario Events Only",
                                    },
                                });
                            }}
                            className="baselineSelect"
                        >
                            <option
                                selected={
                                    !editUser.includeBaseline && "selected"
                                }
                            >
                                Scenario Events Only
                            </option>
                            <option
                                selected={
                                    editUser.includeBaseline && "selected"
                                }
                            >
                                Scenario and Baseline Events
                            </option>
                        </select>
                        <select
                            onChange={(e) => {
                                this.setState({
                                    editUser: {
                                        ...editUser,
                                        access: e.target.value,
                                    },
                                });
                            }}
                            className="shareSelect"
                            selected={editUser.access}
                        >
                            <option
                                selected={
                                    editUser.access === "Can View"
                                        ? "selected"
                                        : ""
                                }
                            >
                                Can View
                            </option>
                            <option
                                selected={
                                    editUser.access === "Can Comment"
                                        ? "selected"
                                        : ""
                                }
                            >
                                Can Comment
                            </option>
                        </select>
                    </div>
                    <div className="sharePanelRow">
                        {!editUser.includeBaseline
                            ? "Events in your baseline won't be shared"
                            : "Access to all events in this scenario, including your baseline"}
                    </div>
                    <div className="sharePanelRow">
                        <textarea
                            className="shareTextArea"
                            onChange={this.onHandleMessage}
                            placeholder="Add a note..."
                            value={editUser.message}
                        />
                    </div>
                </div>
            </div>
        );
    };

    getUserAddPanel = () => {
        if (this.state.currentPanel !== "userAdd")
            this.setState({ currentPanel: "userAdd" });
        const { editUser, doTemplateShare } = this.state;

        return (
            <div className="userAddPanel">
                <div className="sharePanelRow">
                    <select
                        onChange={(e) => {
                            this.setState({
                                editUser: {
                                    ...editUser,
                                    includeBaseline:
                                        e.target.value !==
                                        "Scenario Events Only",
                                },
                            });
                        }}
                        className="baselineSelect"
                        selected={editUser.baselineAccess}
                    >
                        <option>Scenario Events Only</option>
                        <option>Scenario and Baseline Events</option>
                    </select>
                    <select
                        onChange={(e) => {
                            this.setState({
                                editUser: {
                                    ...editUser,
                                    access: e.target.value,
                                },
                            });
                        }}
                        className="shareSelect"
                        selected={editUser.access}
                    >
                        <option
                            selected={
                                editUser.access === "Can View" ? "selected" : ""
                            }
                        >
                            Can View
                        </option>
                        <option
                            selected={
                                editUser.access === "Can Comment"
                                    ? "selected"
                                    : ""
                            }
                        >
                            Can Comment
                        </option>
                    </select>
                </div>
                <div className="sharePanelRow">
                    {!editUser.includeBaseline
                        ? "Events in your baseline won't be shared"
                        : "Access to all events in this scenario, including your baseline"}
                </div>
                <div className="sharePanelRow">
                    <img
                        alt="checkbox"
                        src={doTemplateShare ? checkboxOn : checkboxOff}
                        className="baselineCheckBox"
                        onClick={() => {
                            this.setState({
                                doTemplateShare: !doTemplateShare,
                            });
                        }}
                    />
                    <div className="templateShareText">
                        <div>
                            <b>Share as Template</b>
                        </div>
                        Create a copy of the scenario, and transfer it to the
                        recipient
                    </div>
                </div>
                <div className="sharePanelRow">
                    <textarea
                        className="shareTextArea"
                        onChange={this.onHandleMessage}
                        placeholder="Add a note..."
                        value={editUser.message}
                    />
                </div>
            </div>
        );
    };

    toggleScenarioIsPublic = () => {
        const { loadedScenario, baselineDataManager } = this.props;
        const { baselineIsPublic } = this.state;
        const scenarioPermissions = loadedScenario.permissions || {
            whitelist: [],
        };
        const isPublic =
            scenarioPermissions &&
            scenarioPermissions.default &&
            scenarioPermissions.default.view;
        const defaultPerms = {
            view: !isPublic,
            edit: false,
            comment: false,
            share: false,
            changePermissions: false,
            clone: false,
            includeBaseline: false,
        };
        scenarioPermissions.default = defaultPerms;
        loadedScenario.permissions = scenarioPermissions;
        const newScenario = { ...loadedScenario, scenarioPermissions };
        this.props.updateScenario(newScenario);
        if (baselineDataManager.hasActiveBaseline()) {
            const baseline = baselineDataManager.getActiveBaseline(true);
            if (!baseline.permissions) baseline.permissions = { whitelist: [] };
            const baselinePerms = {
                view: !isPublic && baselineIsPublic,
                edit: false,
                comment: false,
                share: false,
                changePermissions: false,
                clone: false,
                includeBaseline: false,
            };
            baseline.permissions.default = baselinePerms;
            this.props.updateBaseline(baseline, baselineDataManager);
        }
    };

    getLinkShare = () => {
        const { loadedScenario } = this.props;
        const { baselineIsPublic } = this.state;
        const isPublic =
            loadedScenario &&
            loadedScenario.permissions &&
            loadedScenario.permissions.default &&
            loadedScenario.permissions.default.view;

        const scenarioName = this.props.loadedScenario?.name?.toLowerCase();
        const stringArray = scenarioName?.split(" ");
        const cleanArray = stringArray
            ?.map((string) => string?.replaceAll(/[^a-z0-9]/gi, ""))
            ?.filter((string) => string !== "");
        const cleanScenarioName = cleanArray?.join("_");

        const linkText = `${config.shareScenarioUrl}/shareScenario/${this.props.loadedScenario.id}?scenarioName=${cleanScenarioName}`;
        return (
            <>
                <div className="shareLine" />
                <div className="linkShareContainer">
                    THIS OPTION IS VISIBLE FOR ADMINISTRATORS ONLY <br />
                    <button onClick={this.toggleScenarioIsPublic}>
                        <b>
                            {isPublic
                                ? "MAKE THIS SCENARIO PRIVATE"
                                : "MAKE THIS SCENARIO PUBLIC"}
                        </b>
                    </button>
                    {isPublic ? (
                        "If baseline is public, it will be unshared as well"
                    ) : (
                        <>
                            <br />
                            Include Baseline?
                            <img
                                alt="checkbox"
                                src={
                                    baselineIsPublic ? checkboxOn : checkboxOff
                                }
                                className="baselineCheckBox"
                                onClick={() => {
                                    this.setState({
                                        baselineIsPublic: !baselineIsPublic,
                                    });
                                }}
                            />
                        </>
                    )}
                    <br />
                    <br />
                    {linkText}
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(linkText);
                            Mixpanel.track("Shared Scenario via Clipboard", {
                                scenarioName: loadedScenario.name,
                                scenarioId: loadedScenario.id,
                            });
                        }}
                    >
                        COPY LINK TO CLIPBOARD
                    </button>
                </div>
            </>
        );
    };

    render() {
        const { notAddingUser, currentPanel, editUser } = this.state;
        return (
            <EventsModal
                onHandleClose={this.props.cancelShareScenario}
                edit={true}
            >
                <div className="shareLabel">
                    Share{" "}
                    <span className="shareSelected">
                        {this.props.loadedScenario.name}
                    </span>{" "}
                    with others
                </div>
                <div className="shareSubLabel">
                    Share this scenario with other whatifi users. Sign-in
                    required. You can add up to 3 users per scenario.
                </div>
                {currentPanel === "userEdit" ? (
                    this.getUserEditPanel()
                ) : (
                    <div className="shareContainerRoot">
                        <div className="shareContainer">
                            <input
                                value={(editUser && editUser.email) || ""}
                                className="addUserEmailInput"
                                placeholder="Enter email address.."
                                onChange={this.onHandleEmailAdd}
                                list="searchable"
                            />
                            <datalist
                                id="searchable"
                                className="recipientDataList"
                            >
                                {this.state.searchableClients.map(
                                    (client, key) => {
                                        return (
                                            <option key={key} value={client} />
                                        );
                                    }
                                )}
                            </datalist>
                            {notAddingUser
                                ? this.getUserListPanel()
                                : this.getUserAddPanel()}
                        </div>
                        {isActiveUserAdmin() &&
                            notAddingUser &&
                            this.getLinkShare()}
                    </div>
                )}

                {currentPanel !== "userList" && (
                    <>
                        <div className="shareLine" />
                        <div className="shareContainerRow">
                            <div
                                onClick={this.cancelAndReset}
                                className="cancelButton"
                            >
                                Cancel
                            </div>
                            <div
                                onClick={
                                    currentPanel === "userEdit"
                                        ? this.submitUserEdit
                                        : this.submitUserAdd
                                }
                                className="shareButton"
                            >
                                {currentPanel === "userEdit"
                                    ? "Save Changes"
                                    : "Share"}
                            </div>
                        </div>
                    </>
                )}
                {currentPanel === "userList" && this.state.isClient && (
                    <>
                        <div className="shareLine" />
                        <div className="shareContainerRow">
                            <div
                                className="shareButton"
                                onClick={this.shareWithAgent}
                            >
                                Send to My Agent
                            </div>
                        </div>
                    </>
                )}
            </EventsModal>
        );
    }
}

export default ShareScenarioContainer;

import React, { Component } from "react";
import "./Card.css";
import moment from "moment";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import { updateNodeKeepFocus } from "actions/scenario";
import store from "store";

class ContractCard extends Component {
    constructor(props) {
        super(props);
        this.cancelTimeoutRef = React.createRef();
    }

    state = {
        showSettings: false,
        allEntities: null,
        currentIndex: 0,
    };

    componentDidMount() {
        const entities = getRelevantEntities(
            this.props.data.entities.map((entity) => entity.id)
        );

        if (!this.state.allEntities) {
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id !== this.props.data.id) {
            const entities = getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            );
            this.setState({
                allEntities: Object.values(entities),
                currentIndex: this.props.data.mostRecentEntity,
            });
        }

        if (
            prevState.currentIndex !== this.state.currentIndex &&
            this.state.currentIndex !== this.props.data.mostRecentEntity
        ) {
            if (this.cancelTimeoutRef.current) {
                clearTimeout(this.cancelTimeoutRef.current);
            }

            this.cancelTimeoutRef.current = setTimeout(() => {
                this.props.data.mostRecentEntity = this.state.currentIndex;
                const { loadedScenario } = store.getState().scenario;
                store.dispatch(
                    updateNodeKeepFocus(
                        this.props.data.exportData(),
                        loadedScenario,
                        this.props.data
                    )
                );
            }, 1000);
        }
    }

    handleArrowClick = (direction) => {
        const currentNumber = this.state.currentIndex + 1;
        const numberOfEntities = this.state.allEntities.length;
        if (direction === "right" && currentNumber != numberOfEntities) {
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({ currentIndex: this.state.currentIndex - 1 });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    // Helper function to render milestones
    renderMilestones = (arr, income) => {
        var i = 0;
        return (
            <>
                {arr &&
                    arr.map((data) => {
                        i += 1;
                        return (
                            <>
                                <div className="Row">
                                    <div className="ExpenseLabel">
                                        {" "}
                                        {"Milestone " + String(i) + ":"}{" "}
                                    </div>
                                    <div className="ExpenseLabel">
                                        {data.date}
                                    </div>
                                    <div className="ExpenseLabel">
                                        {`$${income * (data.value / 100)} (${
                                            data.value
                                        }%)`}
                                    </div>
                                </div>
                            </>
                        );
                    })}
            </>
        );
    };

    render() {
        if (!this.state.allEntities) {
            return <div></div>;
        }
        const {
            cadence,
            description,
            startDate,
            endDate,
            data,
            name,
        } = this.state.allEntities[this.state.currentIndex];
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Name:</div>
                    <div className="cardValue">{name}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Amount:</div>
                    <div className="cardValue">
                        {`
                        $
                        ${
                            data.value
                                ? new Intl.NumberFormat().format(
                                      parseFloat(data.value).toFixed(2)
                                  )
                                : ""
                        } ${cadence ? `${cadence} /` : ""}
                            `}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Milestones</div>
                    <div className="ExpenseLabel">Days Out</div>
                    <div className="ExpenseLabel">Payout</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Initial:</div>
                    <div className="ExpenseLabel">{startDate}</div>
                    <div className="ExpenseLabel">{`$${new Intl.NumberFormat().format(
                        parseFloat(
                            data.value * (parseFloat(data.initialPayout) / 100)
                        ).toFixed(2)
                    )} (${data.initialPayout}%)`}</div>
                </div>
                {data?.specificPercentages?.length > 0 &&
                    this.renderMilestones(data.specificPercentages, data.value)}
                <div className="Row">
                    <div className="ExpenseLabel">Completion:</div>
                    <div className="ExpenseLabel">{endDate}</div>
                    <div className="ExpenseLabel">{`$${new Intl.NumberFormat().format(
                        parseFloat(
                            data.value * (data.finalPayout / 100)
                        ).toFixed(2)
                    )} (${data.finalPayout}%)`}</div>
                </div>
                {description && (
                    <div className="Row">
                        <div
                            className={
                                description ? "Description" : "NoDescription"
                            }
                        >
                            {description}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Date:</div>
                    <div className="cardValue">
                        {startDate
                            ? moment(startDate).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {endDate
                            ? moment(endDate).format("MMM D YYYY")
                            : "No End Date"}
                    </div>
                </div>
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() => this.handleArrowClick("left")}
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentIndex + 1} of{" "}
                        {this.state.allEntities.length}
                    </span>
                    <div
                        className={
                            this.state.currentIndex ===
                            this.state.allEntities.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() => this.handleArrowClick("right")}
                    ></div>
                </div>
            </div>
        );
    }
}

export default ContractCard;

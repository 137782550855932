import { Component } from "react";
import GraphTabIcon from "../../Assets/tabsIcons/graph.svg";
import TableChartTabIcon from "../../Assets/tabsIcons/table.svg";
import LeaderboardTabIcon from "../../Assets/tabsIcons/chart.svg";
import MapTabIcon from "../../Assets/tabsIcons/map.svg";
import RecordsTabIcon from "Assets/tabsIcons/recordsTab.svg";
import FilterTabIcon from "Assets/tabsIcons/filterTabIcon.svg";
import "./NodeGraph.css";
import { appMapState } from "store/appMapStateDispatch";
import { connect } from "react-redux";

class BottomPanelTabControls extends Component {
    state = {
        bottomPanelTabControlsClasses: ["BottomPanelTabControlsContainer"],
    };

    componentDidMount() {
        this.openAnimation();
    }

    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.showRightDisplay !== this.props.showRightDisplay) {
            if (this.props.showRightDisplay) {
                let responsiveBottomPanelTabControlsClasses =
                    "BottomPanelTabControlsContainer BottomPanelTabsShowing BottomPanelTabControlsContainerOpen";
                if (window.innerWidth < 2000) {
                    this.setState({
                        bottomPanelTabControlsClasses:
                            responsiveBottomPanelTabControlsClasses,
                    });
                } else {
                    let responsiveBottomPanelTabControlsClasses =
                        "BottomPanelTabControlsContainer BottomPanelTabsShowing BottomPanelTabControlsContainerOpenMaxWidth";
                    this.setState({
                        bottomPanelTabControlsClasses:
                            responsiveBottomPanelTabControlsClasses,
                    });
                }
            } else {
                let responsiveBottomPanelTabControlsClasses =
                    "BottomPanelTabControlsContainer BottomPanelTabsShowing BottomPanelTabControlsContainerClosed";
                this.setState({
                    bottomPanelTabControlsClasses:
                        responsiveBottomPanelTabControlsClasses,
                });
            }
        }
    }

    openAnimation = () => {
        let joinedClasses = [
            this.state.bottomPanelTabControlsClasses,
            "BottomPanelTabsShowing",
        ];
        joinedClasses = joinedClasses.join(" ");

        setTimeout(() => {
            this.setState({ bottomPanelTabControlsClasses: joinedClasses });
        }, 300);
    };

    render() {
        const { bottomPanelTabControlsClasses } = this.state;

        let eventGraphClass = "BottomPanelTabIconWrapper";
        let tableChartClass = "BottomPanelTabIconWrapper";
        let leaderboardClass = "BottomPanelTabIconWrapper";
        let mapClass = "BottomPanelTabIconWrapper";
        let entityTableViewClass = "BottomPanelTabIconWrapper";
        let accountDelveViewClass = "BottomPanelTabIconWrapper";
        // if (this.props.selectedThreads.length > 0)
        //     eventGraphClass = "BottomPanelTabIconWrapperActive";

        if (this.props.graphPanelTabSelected === "EventGraph")
            eventGraphClass = "BottomPanelTabIconWrapperActive";
        else if (this.props.graphPanelTabSelected === "TableChart")
            tableChartClass = "BottomPanelTabIconWrapperActive";
        else if (this.props.graphPanelTabSelected === "Leaderboard")
            leaderboardClass = "BottomPanelTabIconWrapperActive";
        else if (this.props.graphPanelTabSelected === "Map")
            mapClass = "BottomPanelTabIconWrapperActive";
        else if (this.props.graphPanelTabSelected === "entityTable")
            entityTableViewClass = "BottomPanelTabIconWrapperActive";
        else if (this.props.graphPanelTabSelected === "accountDelve")
            accountDelveViewClass = "BottomPanelTabIconWrapperActive";

        return (
            <div className={bottomPanelTabControlsClasses}>
                <div
                    className={`${eventGraphClass} UserGuiding-EventsGraphTab`}
                    title="Events Graph"
                    onClick={() => {
                        this.props.ClickGraphPanelChangeTabs("EventGraph");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={GraphTabIcon}
                        alt="event menu tab"
                    />
                </div>
                <div
                    className={`${tableChartClass} UserGuiding-SpreadsheetTab`}
                    title="Spreadsheet"
                    onClick={() => {
                        this.props.ClickGraphPanelChangeTabs("TableChart");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={TableChartTabIcon}
                        alt="table chart tab"
                    />
                </div>
                <div
                    className={`${entityTableViewClass} UserGuiding-EntityViewTab`}
                    title="Record View"
                    onClick={() => {
                        this.props.ClickGraphPanelChangeTabs("entityTable");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={RecordsTabIcon}
                        alt="records tab"
                    />
                </div>
                <div
                    className={`${accountDelveViewClass} UserGuiding-AccountDelveViewTab`}
                    title="Account Delve View"
                    onClick={() => {
                        this.props.ClickGraphPanelChangeTabs("accountDelve");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={FilterTabIcon}
                        alt="filter tab"
                    />
                </div>
                <div
                    className={`${leaderboardClass} UserGuiding-ChartsTab`}
                    title="Charts"
                    onClick={() => {
                        this.props.ClickGraphPanelChangeTabs("Leaderboard");
                    }}
                >
                    <img
                        className="TabIcon"
                        src={LeaderboardTabIcon}
                        alt="leaderboard tab"
                    />
                </div>
                <div
                    className={`${mapClass} MapTab`}
                    title="Map"
                    onClick={() => {
                        this.props.ClickGraphPanelChangeTabs("Map");
                    }}
                >
                    <img className="TabIcon" src={MapTabIcon} alt="map tab" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = appMapState((state) => ({
    selectedThreads: state?.scenarioViewSelectedThreads?.selectedThreads,
}));

export default connect(mapStateToProps, null)(BottomPanelTabControls);

import React, { Component } from "react";
import { connect } from "react-redux";
import { appMapDispatch, appMapState } from "store/appMapStateDispatch";
import "../NodeGraph.css";
import MoveCardsArrow from "Assets/_home/move-cards-arrow.svg";
import OtherAccountsPanel from "./OtherAccountsPanel";
import SelectedAccountsList from "./SelectedAccountsList";
import { upsertChartGraph } from "actions/chartGraphActions";
import { upsertGraphAccountSelection } from "actions/graphAccountSelectionActions";
import { openAccountPickerModal } from "actions/accountPickerActions";

class GraphAccountsSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAccountsPanel: false,
            listedAccountsLength: null,
            // accountTypesScrollCounter: 0,
            panelCooldown: false,
            accountsAreScrollable: false,
        };
        this.selectedAccountsContainerRef = React.createRef(null);
    }

    componentDidMount() {
        const newListedAccountsLength = this.getListedAccountsLength();

        this.setState({
            listedAccountsLength: newListedAccountsLength,
        });

        this.getListedRelevantAccounts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.listedAccountTypes !== this.props.listedAccountTypes ||
            prevState.listedAccountsLength !== this.state.listedAccountsLength
        ) {
            const newListedAccountsLength = this.getListedAccountsLength();
            this.setState({ listedAccountsLength: newListedAccountsLength });
            if (
                this.selectedAccountsContainerRef.current.scrollWidth >
                newListedAccountsLength
            ) {
                if (
                    this.selectedAccountsContainerRef.current.scrollWidth >
                    this.selectedAccountsContainerRef.current.offsetWidth
                ) {
                    this.setState({ accountsAreScrollable: true });
                } else {
                    this.setState({ accountsAreScrollable: false });
                }
            }
        }
    }

    getListedRelevantAccounts = () => {
        const fixedAccountsSourceNames = this.getFixedAccountsInOrder();

        const listedNonFixedAccounts = this.props.listedAccountTypes.filter(
            (account) =>
                !this.props.allAccountLedgers.ledgersMetadata[account.id]
                    .alwaysDisplayed
        );

        const listedNonFixedRelevantAccounts = listedNonFixedAccounts.filter(
            (account) =>
                this.props.relevantLedgers
                    .map((relevantLedger) => relevantLedger.id)
                    .includes(account.id)
        );

        const newListedAccountsArray = fixedAccountsSourceNames.concat(
            listedNonFixedRelevantAccounts
        );

        this.props.dispatch(
            upsertGraphAccountSelection({
                listedAccountTypes: newListedAccountsArray,
                selectedAccountTypes: listedNonFixedRelevantAccounts,
            })
        );
    };

    handleClickAccountsPanel = () => {
        if (!this.state.panelCooldown) {
            this.setState({
                showAccountsPanel: !this.state.showAccountsPanel,
                panelCooldown: true,
            });
            setTimeout(() => {
                this.setState({ panelCooldown: false });
            }, 200);
        }
    };

    selectDeselectAccountTypes = (accountType) => {
        let newArray = [...this.props.selectedAccountTypes];

        if (newArray.includes(accountType)) {
            newArray = newArray.filter((account) => account !== accountType);
        } else newArray.push(accountType);

        this.props.dispatch(
            upsertGraphAccountSelection({
                selectedAccountTypes: newArray,
            })
        );
    };

    handleClickAddAccounts = () => {
        // This makes sure accounts that are already "listed" in the left side are not added again as a duplicate
        const selectedAccountTypes = this.props.selectedAccountTypes.filter(
            (account) => !this.props.listedAccountTypes.includes(account)
        );
        const previouslyListedAcountTypes =
            this.props.listedAccountTypes.concat(selectedAccountTypes);

        this.props.dispatch(
            upsertGraphAccountSelection({
                listedAccountTypes: previouslyListedAcountTypes,
            })
        );
    };

    getListedAccountsLength = () => {
        let listedAccounts = this.props.listedAccountTypes ?? [];
        let totalLength = 0;

        for (let i = 0; i < listedAccounts.length; i++) {
            totalLength = totalLength + listedAccounts[i].name.length * 7 + 20;
        }
        return totalLength;
    };

    clearAccountTypeSelection = () => {
        const fixedAccountTypes = this.getFixedAccountsInOrder();

        // this.setState({
        //     accountTypesScrollCounter: 0,
        // });

        this.props.dispatch(
            upsertGraphAccountSelection({
                listedAccountTypes: fixedAccountTypes,
                selectedAccountTypes: [],
            })
        );
    };

    // handleClickScrollLeft = () => {
    //     if (this.state.accountTypesScrollCounter > 0) {
    //         this.setState({
    //             accountTypesScrollCounter:
    //                 this.state.accountTypesScrollCounter - 1,
    //         });
    //     }
    // };

    // handleClickScrollRight = () => {
    //     let scrollingLimit = this.state.listedAccountsLength * 0.1;
    //     let processedCounter = this.state.accountTypesScrollCounter * 12;
    //     if (
    //         processedCounter < scrollingLimit &&
    //         this.props.listedAccountTypes.length > 3
    //     ) {
    //         this.setState({
    //             accountTypesScrollCounter:
    //                 this.state.accountTypesScrollCounter + 1,
    //         });
    //     }
    // };

    handleArrowClick = (direction) => {
        const el = document.querySelector(".graph-accounts__selected-list");
        if (el) {
            if (direction === "right") {
                el.scrollLeft += 100;
            } else {
                el.scrollLeft -= 100;
            }
        }
    };

    handleClickRemoveSingleListedLedger = (ledger) => {
        const newListedLedgersArray = this.props.listedAccountTypes.filter(
            (accountLedger) => accountLedger.id !== ledger.id
        );
        const newSelectedLedgersArray = this.props.selectedAccountTypes.filter(
            (accountLedger) => accountLedger.id !== ledger.id
        );

        this.props.dispatch(
            upsertGraphAccountSelection({
                listedAccountTypes: newListedLedgersArray,
                selectedAccountTypes: newSelectedLedgersArray,
            })
        );

        if (this.props.accountLedgerName === ledger.name) {
            this.props.dispatch(
                upsertChartGraph({
                    accountLedgerName: newListedLedgersArray?.[0].name || "",
                    accountLedgerId: newListedLedgersArray?.[0].id || "",
                })
            );
        }
    };

    getLedgerDisplayName = (ledger) => {
        let displayName = ledger.name;

        return displayName;
    };

    getFixedAccountsInOrder = () => {
        const fixedAccountsMap = {};
        const displayAccountOrder = [
            { name: "Cashflow", id: "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78" },
            {
                name: "Calculated Equity",
                id: "a4d432a8-26ab-41f0-9008-88ae2d171378",
            },
            { name: "Profit", id: "4db53c56-db20-4798-9830-adbfcc977b26" },
        ];
        Object.entries(this.props.allAccountLedgers.ledgersMetadata).forEach(
            ([id, ledgerMetadata]) => {
                if (ledgerMetadata.alwaysDisplayed)
                    fixedAccountsMap[id] = ledgerMetadata;
            }
        );

        // Reorder based on displayedAccountsOrder
        const fixedAccountTypes = [];
        displayAccountOrder.forEach((ledger) => {
            if (fixedAccountsMap[ledger.id]) {
                fixedAccountTypes.push(ledger);
                delete fixedAccountsMap[ledger.id];
            }
        });

        // Push accounts whose order is not specified in displayedAccountsOrder
        Object.values(fixedAccountsMap).forEach((ledger) => {
            fixedAccountTypes.push({ name: ledger.name, id: ledger.id });
        });

        return fixedAccountTypes;
    };

    render() {
        const { showAccountsPanel } = this.state;

        const allAccountTypes =
            this.props.allAccountLedgers.relevantLedgers.filter(
                (ledger) =>
                    !this.props.allAccountLedgers.ledgersMetadata[ledger.id]
                        .alwaysDisplayed
            );

        // let accountsScrollTranslate = {
        //     transform: `translateX(-${accountTypesScrollCounter * 10}%)`,
        // };

        return (
            <div className="graph-accounts__container">
                <div className="graph-accounts__selected-other-wrapper">
                    <div className="graph-accounts__account-selector-wrapper">
                        {this.state.accountsAreScrollable && (
                            <div
                                className="graph-accounts__selected-list-left-arrow-wrapper"
                                // onClick={this.handleClickScrollLeft}
                                onClick={() => this.handleArrowClick("left")}
                            >
                                <img
                                    className="graph-accounts__selected-list-left-arrow"
                                    src={MoveCardsArrow}
                                />
                            </div>
                        )}
                        <SelectedAccountsList
                            // accountsScrollTranslate={accountsScrollTranslate}
                            accountsContainerRef={
                                this.selectedAccountsContainerRef
                            }
                            getLedgerDisplayName={this.getLedgerDisplayName}
                            handleClickRemoveSingleListedLedger={
                                this.handleClickRemoveSingleListedLedger
                            }
                        />
                        {this.state.accountsAreScrollable && (
                            <div
                                className="graph-accounts__selected-list-right-arrow-wrapper"
                                // onClick={this.handleClickScrollRight}
                                onClick={() => this.handleArrowClick("right")}
                            >
                                <img
                                    className="graph-accounts__selected-list-right-arrow"
                                    src={MoveCardsArrow}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className={
                            showAccountsPanel
                                ? "graph-accounts__other-accounts-wrapper--active"
                                : "graph-accounts__other-accounts-wrapper"
                        }
                        // onClick={this.handleClickAccountsPanel}
                        onClick={() => {
                            if (allAccountTypes.length > 0) {
                                this.props.dispatch(openAccountPickerModal());
                            } else {
                                return;
                            }
                        }}
                    >
                        <span className="graph-accounts__other-accounts-text">
                            Other Accounts
                        </span>
                        {!showAccountsPanel && (
                            <div className="graph-accounts__other-accounts-counter-wrapper">
                                <span className="graph-accounts__other-accounts-counter">
                                    {allAccountTypes.length}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                {showAccountsPanel && (
                    <OtherAccountsPanel
                        selectDeselectAccountTypes={
                            this.selectDeselectAccountTypes
                        }
                        clearAccountTypeSelection={
                            this.clearAccountTypeSelection
                        }
                        handleClickAddAccounts={this.handleClickAddAccounts}
                        allAccountTypes={allAccountTypes}
                        getLedgerDisplayName={this.getLedgerDisplayName}
                        handleClickAccountsPanel={this.handleClickAccountsPanel}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = appMapState((state) => {
    const allAccountLedgers = state?.allAccountLedgers;
    const accountLedgerName = state?.chartGraph?.accountLedgerName;
    const listedAccountTypes = state?.graphAccountSelection?.listedAccountTypes;
    const selectedAccountTypes =
        state?.graphAccountSelection?.selectedAccountTypes;
    const relevantLedgers = state?.allAccountLedgers.relevantLedgers;

    return {
        allAccountLedgers,
        accountLedgerName,
        listedAccountTypes,
        selectedAccountTypes,
        relevantLedgers,
    };
});

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GraphAccountsSelection);

import type {
    LedgerMetadata,
    LedgerMetadataUnit,
} from "reducers/typesSchema/allAccountLedgersSchema";

/**
 * Same as getUnitDisplayPattern(), but uses an actual value, instead of a pattern. Also accounts for singular values.
 *
 * Example outputs:
 * "$231,498"
 * "198 customers"
 */
export const getDisplayValue = (
    ledgerMetadataUnit: LedgerMetadataUnit,
    selectedLedgerAverage: number
) => {
    const ledgerValueNum = Number(
        selectedLedgerAverage.toFixed(ledgerMetadataUnit?.nearestDecimal)
    );
    const ledgerValue = ledgerValueNum.toLocaleString();
    const unitDisplay =
        ledgerValueNum > 1
            ? ledgerMetadataUnit.display
            : ledgerMetadataUnit.singular;
    const spacing = ledgerMetadataUnit.spacingBetween ? " " : "";

    if (ledgerMetadataUnit.beforeValue) {
        const valueDisplay = `${unitDisplay}${spacing}${ledgerValue}`;
        return valueDisplay;
    } else if (!ledgerMetadataUnit.beforeValue) {
        const valueDisplay = `${ledgerValue}${spacing}${unitDisplay}`;
        return valueDisplay;
    }
};

/**
 * Same as getDisplayValue but outputs {valueY} instead of the actual value.
 * Used for ChartGraph component.
 *
 * Example outputs:
 * "${valueY}"
 * "{valueY} customers"
 */
export const getDisplayPattern = (ledgerMetadataUnit: LedgerMetadataUnit) => {
    const unitDisplay = ledgerMetadataUnit.display;
    const spacing = ledgerMetadataUnit.spacingBetween ? " " : "";
    return ledgerMetadataUnit.beforeValue
        ? `${unitDisplay}${spacing}{valueY}`
        : `{valueY}${spacing}${unitDisplay}`;
};

export const getMontlyOrCumulativeText = (
    ledgerMetadata: LedgerMetadata,
    monthlyCumulative
) => {
    const text =
        monthlyCumulative === "monthly"
            ? ledgerMetadata.monthlyCumulativeText.monthly
            : ledgerMetadata.monthlyCumulativeText.cumulative;

    return text;
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import GraduationCard from "../Card/graduationCard";
import GraduationInput from "../InputContainer/GraduationInput";

// Import Connection Image (if exists)
import GraduationConnectionImage from "../../Assets/_optionDescriptionIcons/Student Budget, Student Loan, Graduation.png";

// Import Coloured PNGs
// TODO Graduation pngs
import graduationBlack from "../../Assets/_eventNodeTypes/graduation-black.png";
import graduationBlue from "../../Assets/_eventNodeTypes/graduation-blue.png";
import graduationWhite from "../../Assets/_eventNodeTypes/graduation-white.png";
import graduationGrey from "../../Assets/_eventNodeTypes/graduation-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - graduationObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - graduationObject.checkInput.call(graduationObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const graduationObject = {
    constant: function () {
        return Graduation_Constant();
    },

    checkInput: function (state) {
        return Graduation_CheckInput(state);
    },

    svg: function () {
        return Graduation_Svg();
    },

    eventNodeMenu: function (focus) {
        return Graduation_EventNodeMenu(focus);
    },

    indexText: function () {
        return Graduation_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Graduation_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Graduation_OptionDescription();
    },

    colouredPngs: function () {
        return Graduation_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Graduation_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Graduation_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.person) return false;
    if (!state.start) return false;
    return true;
};

// EFFECTS: Returns constant for event
const Graduation_Constant = () => {
    return "Graduation";
};

// EFFECTS: Returns black png / svg for event
const Graduation_Svg = () => {
    return graduationBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Graduation_EventNodeMenu = (focus) => {
    return <GraduationCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Graduation_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Graduation calculations.";
};

// EFFECTS: Returns all icons for event ??
const Graduation_Icons = () => {
    let pngs = new Map([
        ["black", graduationBlack],
        ["blue", graduationBlue],
        ["white", graduationWhite],
        ["grey", graduationGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Graduation_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Graduation_InputComponentInfoPassing = (minProps) => {
    return <GraduationInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, graduations for event ??
const Graduation_OptionDescription = () => {
    const tags = ["Option"];
    const type = graduationObject.constant();
    const description =
        "Graduation is a huge milestone, congratulations! Unfortunately, this also means that you need to start repaying your government full-time student loans quite soon.";
    const usedForText =
        "The Graduation Node acts as a trigger for some Student Loans to start repayment.";
    const connection =
        "Connect a Graduation Node after a Student Loan Node. This node will inherit values and properties from the Student Loan Node to calculate when Student Loan should be repayed.";
    const connectionImage = GraduationConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/financial-consumer-agency/services/pay-down-student-debt.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Graduation_SideModalImage = () => {
    return sideImage;
};

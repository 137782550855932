// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";
import { v4 as uuidv4 } from "uuid";
import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    EntityName,
    MuiCalendar,
    ModalColumn,
    BasicHeading,
    ExpressionStep,
} from "../Components";
import { accountExpressionInputsHandler } from "../OnInputChangeHandlers/accountExpressionInputsHandler";
import type { ChangeEvent } from "react";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import Switch from "react-switch";

const AccountExpressionInputView = ({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleEntityStateChange,
    updateAccount,
}) => {
    const allLedgersData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata,
        shallowEqual
    );

    const entityData = entitiesMap?.[currentEntity]?.data;
    const handleDateSelection = (id, value) => {
        const newEntitiesMap = accountExpressionInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "startDate" | "endDate"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = accountExpressionInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateRangeToggle = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.customDateRange = !data.customDateRange;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleStepsUpdate = (step) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const newStepsMap = { ...(data?.expressionStepsMap || {}) };
        const newStepIds = [...(data?.expressionStepsIds || [])];

        if (newStepsMap?.[step?.id]) {
            newStepsMap[step?.id] = step;
        } else {
            newStepsMap[step?.id] = step;
            newStepIds.push(step?.id);
        }

        data.expressionStepsMap = newStepsMap;
        data.expressionStepsIds = newStepIds;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        setEntitiesMap(newEntitiesMap);
    };

    const renderSteps = (entityData, stepsIdsArray) => {
        const expressionStepsArray = stepsIdsArray?.map(
            (stepId) => entityData?.expressionStepsMap?.[stepId]
        );
        const creationArray = [
            ...expressionStepsArray,
            {
                id: uuidv4(),
                leftValue: "",
                rightValue: "",
                operator: "+",
            },
        ];

        return creationArray?.map((step, i) => (
            // This weird key is required. Duplicating an entity copies
            // everything (whether that be deep or shallow, doesn't matter),
            // including string ids. This means that if you were to duplicate
            // an expression entity, its steps array will still keep the same ids
            // even if it's a distinct object. Since the keys are the same, React
            // doesn't know that it changes, so appending on the current entity's
            // id is a nice easy way to differentiate between them (though having
            // proper duplication behaviour is much better, where ids are actually
            // remade).
            <ModalRow key={step?.id + currentEntity}>
                <ExpressionStep
                    stepNumber={i + 1}
                    stepObject={step}
                    allLedgersData={allLedgersData}
                    callback={handleStepsUpdate}
                />
            </ModalRow>
        ));
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={accountExpressionObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={accountExpressionObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[accountExpressionObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ModalColumn cssOverrides={{ marginBottom: "2rem" }}>
                    {renderSteps(
                        entityData,
                        entityData?.expressionStepsIds ?? []
                    )}
                </ModalColumn>
                <BasicHeading copy="Select Target Account" />
                <ModalRow cssOverrides={{ marginBottom: "2rem" }}>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={entityData?.targetAccountName ?? ""}
                        required={true}
                    />
                </ModalRow>
                <BasicHeading copy="Select Contra Account" />
                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={entityData?.targetContraAccountName ?? ""}
                        required={true}
                        contraAccount={true}
                    />
                </ModalRow>
                <ModalRow cssOverrides={{ marginBottom: "1rem" }}>
                    <Switch
                        checked={entityData?.customDateRange}
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={handleDateRangeToggle}
                        onColor="#3B8524"
                    />
                    <span
                        style={{
                            color: "rgba(151, 151, 151, 1)",
                            marginLeft: "1rem",
                        }}
                    >
                        Custom Date Range
                    </span>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="startDate"
                            value={
                                entitiesMap?.[currentEntity]?.startDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.startDate
                            }
                            required={entityData?.customDateRange}
                            onChangeInput={handleDateSelection}
                            label="Start Date"
                            disabled={!entityData?.customDateRange}
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="endDate"
                            value={
                                entitiesMap?.[currentEntity]?.endDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.endDate
                            }
                            onChangeInput={handleDateSelection}
                            label="End Date"
                            helperText="An end date is optional"
                            disabled={!entityData?.customDateRange}
                        />
                    </ModalRowHalf>
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default AccountExpressionInputView;

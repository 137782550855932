//Scenario
export const SAMPLE_SCENARIO = "SAMPLE_SCENARIO";
export const USER_SCENARIOS = "USER_SCENARIOS";
export const SET_MANAGER = "SET_MANAGER";
export const SET_BACKGROUND_MANAGER = "SET_BACKGROUND_MANAGER";
export const EDIT_SCENARIO = "EDIT_SCENARIO";
export const CREATE_SCENARIO = "CREATE_SCENARIO";
export const LOAD_SCENARIO = "LOAD_SCENARIO";
export const PAUSE_DECISION_ENGINE = "PAUSE_DECISION_ENGINE";
export const DELETE_SCENARIO = "DELETE_SCENARIO";
export const SET_FOCUS = "SET_FOCUS";
export const RESET_FOCUS = "RESET_FOCUS";
export const UPDATE_FOCUSED_CARD_ID = "UPDATE_FOCUSED_CARD_ID";
export const UPDATE_NODE = "UPDATE_NODE";
export const SET_ZOOMED_EVENT_ID = "SET_ZOOMED_EVENT_ID";
export const RESET_ZOOMED_EVENT_ID = "RESET_ZOOMED_EVENT_ID";
export const SELECT_FILTERED_EVENTS_AND_ENTITIES =
    "SELECT_FILTERED_EVENTS_AND_ENTITIES";
export const UPDATE_DEPENDENCY_MAP = "UPDATE_DEPENDENCY_MAP";
// UPDATE_NODE_CLEAR_FOCUS does not do anything
export const UPDATE_NODE_CLEAR_FOCUS = "UPDATE_NODE_CLEAR_FOCUS";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const COPY_SCENARIO = "COPY_SCENARIO";
export const SET_LINE_FOCUS = "SET_LINE_FOCUS";
export const RESET_SOURCE = "RESET_SOURCE";
export const SET_SOURCE = "SET_SOURCE";
export const CLEAR_SELECTED_EVENTS = "CLEAR_SELECTED_EVENTS";
export const SELECT_EVENT = "SELECT_EVENT";
export const SELECT_ENTITY = "SELECT_ENTITY";

export const COLLECT_NODE = "COLLECT_NODE";
export const DISABLE_COLLECT_NODE = "DISABLE_COLLECT_NODE";
export const LOAD_CREATED_SCENARIO = "LOAD_CREATED_SCENARIO";
export const REMOVE_COLLECTED_NODE = "REMOVE_COLLECTED_NODE";
export const CANCEL_COLLECT_MODE = "CANCEL_COLLECT_MODE";
export const CANCEL_ACTIONS = "CANCEL_ACTIONS";

export const POP_UP_OPTION = "POP_UP_OPTION";
export const CLOSE_POP_UP_OPTION = "CLOSE_POP_UP_OPTION";
export const SELECTED_OPTION = "SELECTED_OPTION";
export const CLOSE_EVENTS_MODAL = "CLOSE_EVENTS_MODAL";
export const COPY_EVENT = "COPY_EVENT";
export const TOGGLE_COLLECTION_MODE = "TOGGLE_COLLECTION_MODE";
export const UPDATE_CONNECTIONS_SELECTED = "UPDATE_CONNECTIONS_SELECTED";
export const TOGGLE_DELETION_MODE = "TOGGLE_DELETION_MODE";
export const UPDATE_SCENARIO_CANVAS = "UPDATE_SCENARIO_CANVAS";

//Share scenario
export const SHARE_SCENARIO = "SHARE_SCENARIO";
export const CANCEL_SHARE_SCENARIO = "CANCEL_SHARE_SCENARIO";
export const SEND_EMAIL = "SEND_EMAIL";
export const SHARED_SCENARIO_PER_ACCOUNT = "SHARED_SCENARIO_PER_ACCOUNT";
export const GET_SHARED_SCENARIO = "GET_SHARED_SCENARIO";
export const SHARE_BY_EMAIL = "SHARE_BY_EMAIL";
export const SHARE_TEMPLATE = "SHARE_TEMPLATE";

export const EDIT_NODE = "EDIT_NODE";
export const SELECT_LINE = "SELECT_LINE";
export const SELECT_LINE_FOR_DISCONNECTION = "SELECT_LINE_FOR_DISCONNECTION";
export const UPDATE_RATING = "UPDATE_RATING";
export const HIGHLIGHTED_THREAD = "HIGHLIGHTED_THREAD";
export const NODEGRAPH_DISPLAY = "NODEGRAPH_DISPLAY";
export const NODEGRAPH_STATE = "NODEGRAPH_STATE";
export const FLINK_BANK_DATA = "FLINK_BANK_DATA";
export const SIGNUP = "SIGNUP";
export const LOGIN = "LOGIN";
export const GET_USER_DATA = "GET_USER_DATA";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const GET_WALKSCORE = "GET_WALKSCORE";
export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const PREVENT_EVENT_MODAL_ACTIONS = "PREVENT_EVENT_MODAL_ACTIONS";

//notif
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const UPDATE_SHARED_SCENARIO = "UPDATE_SHARED_SCENARIO";
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

//onboarding
export const ONBOARDING_STATE = "ONBOARDING_STATE";
export const ONBOARDING_DATA = "ONBOARDING_DATA";
export const ONBOARDING_SCENARIO = "ONBOARDING_SCENARIO";

//onboardingState
export const DEFAULT = "DEFAULT";
export const PLAN = "PLAN";
export const ONBOARDING = "ONBOARDING";
export const BASELINE = "BASELINE";
export const TOUR = "TOUR";
export const WELCOME = "WELCOME";
export const ONBOARDING_OPTIONS = "ONBOARDING_OPTIONS";
export const SHOW_EXPLORE_OPTIONS = "SHOW_EXPLORE_OPTIONS";

//onboarding options
export const SHOW_CREATE_SCENARIO = "SHOW_CREATE_SCENARIO";

//login
export const SHOW_LOGIN = "SHOW_LOGIN";

//header selected
export const TAB_SELECTED = "TAB_SELECTED";

//option picker
export const SHOW_OPTION_PICKER = "SHOW_OPTION_PICKER";

//baseline
export const GET_BASELINE = "GET_BASELINE";
export const BASELINE_MANAGER = "BASELINE_MANAGER";
export const BASELINE_DATA_MANAGER = "BASELINE_DATA_MANAGER";
export const DELETE_BASELINE = "DELETE_BASELINE";
export const ARE_BASELINES_LOADED = "ARE_BASELINES_LOADED";

//ptt Hover input
export const HOVERED_INPUT = "HOVERED_INPUT";

//focus for ptt
export const FOCUS_VALUE = "FOCUS_VALUE";

//close expired node modal
export const CLOSE_EXPIRED_NODE = "CLOSE_EXPIRED_NODE";

//close outdated node modal
export const CLOSE_OUTDATED_NODE = "CLOSE_OUTDATED_NODE";

//open / close paste modal
export const SET_SHOW_PASTE_MODAL = "SET_SHOW_PASTE_MODAL";

// open / close duplicate scenario modal
export const SET_DUPLICATE_SCENARIO_MODAL = "SET_DUPLICATE_SCENARIO_MODAL";

//determines whether we show the override modal or not
export const SET_CELL_ROW_MODAL = "SET_CELL_ROW_MODAL";

//determines whether we show the chart modal or not
export const SET_CHART_MODAL = "SET_CHART_MODAL";

//toggle account types
export const TOGGLE_ACCOUNT_TYPE = "TOGGLE_ACCOUNT_TYPE";

//agency
export const GET_AGENCY_CLIENTS = "GET_AGENCY_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const SHOW_AGENCY_BASELINE_PAGE = "SHOW_AGENCY_BASELINE_PAGE";

// event library
export const GET_LIBRARY = "GET_LIBRARY";
export const ADDING_LIBRARY_EVENT = "ADDING_LIBRARY_EVENT";

// options picker
export const SELECT_OPTIONS_TAB = "SELECT_OPTIONS_TAB";

// scenario archiving
export const ARCHIVE_SCENARIO = "ARCHIVE_SCENARIO";
export const REACTIVATE_ARCHIVED_SCENARIO = "REACTIVATE_ARCHIVED_SCENARIO";

export const UPDATE_ACCOUNT_DATA = "UPDATE_ACCOUNT_DATA";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";

// templates
export const GET_TEMPLATES = "GET_TEMPLATES";

// only purpose is to update redux state
export const TRIGGER_UPDATE = "TRIGGER_UPDATE";

// determines whether we show the account mapping modal or not
export const SET_ACCOUNT_MAPPING_MODAL = "SET_ACCOUNT_MAPPING_MODAL";
export const SET_ACCOUNT_FIELD_MAPPING_MODAL =
    "SET_ACCOUNT_FIELD_MAPPING_MODAL";

import { useState, useEffect, useContext, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import KPIInputView from "./KPIsInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { updateEntityState } from "helpers/updateEntityState";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { kpiObject } from "Components/Registry/KPI";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { revenueObject } from "Components/Registry/Revenue";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { customerChurnObject } from "Components/Registry/CustomerChurn";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { groupObject } from "Components/Registry/Group";
import { customer2Object } from "Components/Registry/Customer2";
import { kpisInputsHandler } from "../OnInputChangeHandlers/kpiInputsHandler";
//import { isOverrideValid } from "helpers/modifiers";

const kpiData = {
    arpu: {
        id: "609b4a93-93b9-470e-bab8-afcc8bc28754",
        displayName: "arpu",
        activeKPIField: "arpu",
        description: "Average Revenue Per User",
        additionalInfo: "im an arpu additional info",
        categorys: ["saas", "pro"],
    },
    ltv: {
        id: "b2d2799f-1e96-4c81-8c26-2faf406ef04f",
        displayName: "ltv",
        activeKPIField: "lifetimeValue",
        description: "Lifetime Value",
        additionalInfo: "",
        categorys: ["saas"],
    },
    ltvToCACRatio: {
        id: "858bbd98-5f24-42d9-976c-5127bac2697d",
        displayName: "CAC to LTV Ratio",
        activeKPIField: "ltvToCACRatio",
        description: "Customer Acquisition to Lifetime Value ratio",
        additionalInfo: "",
        categorys: ["saas"],
    },
    profitMargin: {
        id: "38cd28d3-8591-43d1-9784-aefeb25a1c08",
        displayName: "Profit Margin",
        activeKPIField: "profitMargin",
        description: "Profit Margin",
        additionalInfo: "",
        categorys: ["saas"],
    },
    // mrr: {
    //     id: "f56961f3-2725-46ff-a3ce-60022a6a68be",
    //     displayName: "mrr",
    //     activeKPIField: "mrr",
    //     description: "Monthly Recurring Revenue",
    //     additionalInfo:
    //         "Monthly Recurring Revenue (MRR) is the predictable total revenue generated by your business from all the active subscriptions in a particular month. It includes recurring charges from discounts, coupons, and recurring add-ons, but excludes one-time fees.",
    //     categorys: ["saas"],
    // },
};

//TODO - Create interface for _eventData
export default function KPIsInputExperimental({ line, focus, edit, editData }) {
    const dispatch = useAppDispatch();
    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(kpiObject);
            _eventData.name = getDefaultName(kpiObject.name(), propsObject);
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, kpiObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = {
        manager,
        line,
        focus,
        eventId,
    };

    const getRevenues = () => {
        const revenues = {};

        getPresentableDependencies(
            revenues,
            revenueObject.constant(),
            propsObject, // Temporary object
            true
        );

        return revenues;
    };

    const getCustomerChurns = () => {
        const churns = {};
        getPresentableDependencies(
            churns,
            customerChurnObject.constant(),
            propsObject, // Temporary object
            true
        );

        return churns;
    };

    /**
     * @param kpi - uses the activeKPIField from the kpi data to determine if the kpi is able to be selected
     * @returns - returns a boolean "kpiValid" if the kpi is able to be selected and a "warningMessage" if it isnt valid
     */
    const handleKPIWarning = (kpi) => {
        const revenueNodes = entitiesMap[currentEntity].data.revenues;
        const churnNodes = entitiesMap[currentEntity].data.churns;

        const missingNodes: string[] = [];

        if (
            Object.keys(revenueNodes).length == 0 &&
            (kpi === "arpu" || kpi === "lifetimeValue")
        ) {
            missingNodes.push("Revenue");
        }

        if (Object.keys(churnNodes).length == 0 && kpi === "lifetimeValue") {
            missingNodes.push("Customer Churn");
        }

        return {
            kpiValid: !!(missingNodes.length === 0),
            warningMessage:
                missingNodes.length === 0
                    ? ""
                    : `No ${missingNodes.join(
                          ", "
                      )} nodes found upstream or in baseline`,
        };
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const revenues = getRevenues();
            const churns = getCustomerChurns();
            const customers = getCustomers();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.revenues = revenues;
            newEntitiesMap[currentEntity].data.churns = churns;
            newEntitiesMap[currentEntity].data.customers = customers;
            return newEntitiesMap;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const forceEntityChange = (value: any, id: "filter", index: number) => {
        const newEntitiesMap = kpisInputsHandler(
            value,
            id,
            index,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const relevantKPIs = useMemo(() => {
        if (!entitiesMap[currentEntity].data.activeFilter) return kpiData;
        const filters = entitiesMap[currentEntity].data.filter;
        const relevantKPIs: any = {};
        Object.values(kpiData).map((kpi) => {
            kpi.categorys.map((category) => {
                if (filters[category] && !relevantKPIs[kpi.id]) {
                    relevantKPIs[kpi.id] = kpi;
                }
            });
        });
        return relevantKPIs;
    }, [entitiesMap, currentEntity]);

    const getCustomers = () => {
        const customers = {};
        const groups = {};

        if (!propsObject.eventId && !propsObject.line) return customers;

        getPresentableDependencies(
            customers,
            customer2Object.constant(),
            propsObject, // Temporary object
            true
        );

        getPresentableDependencies(
            groups,
            groupObject.constant(),
            propsObject, // Temporary object
            true
        );

        return { ...customers, ...groups };
    };

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };
    const passedCheck = !!eventData.name && kpiObject.checkInput(entitiesMap);

    return (
        <KPIInputView
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            entitiesLength={entityIds.length}
            handleEntityStateChange={handleEntityStateChange}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            forceEntityChange={forceEntityChange}
            relevantKPIs={relevantKPIs}
            handleKPIWarning={handleKPIWarning}
        />
    );
}

import { getEvent } from "actions/getNodeEntityActions";
import styles from "./CanvasContextMenu.module.css";
import { clearSelectedEvents } from "actions/scenario";
import { shallowEqual } from "react-redux";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { POP_UP_OPTION } from "actions/types";
import { containerObject } from "Components/Registry/Container";
import { changeSelectedOption } from "actions/scenario";
import MenuItem from "@mui/material/MenuItem";

export const CanvasContextMenu = ({ onClose }) => {
    const { selectedEvents, selectedEventIdsArray } = useAppSelector(
        (state) => {
            const selectedEvents = state?.scenario?.selectedEvents;
            const selectedEventIdsArray = Object.keys(selectedEvents ?? {});
            return {
                selectedEvents,
                selectedEventIdsArray,
            };
        },
        shallowEqual
    );

    const dispatch = useAppDispatch();

    const handleClearSelectedEvents = () => {
        dispatch(clearSelectedEvents());
        onClose(null);
    };

    const openContainerCreationModal = () => {
        const canvasSelectedEventIds = selectedEventIdsArray?.filter(
            (eventId) => selectedEvents?.[eventId] === "canvas"
        );

        const eventDataMap: any[] = canvasSelectedEventIds?.map((eventId) => {
            const eventData = getEvent(eventId);
            return eventData;
        });

        if (eventDataMap && eventDataMap?.length > 0) {
            eventDataMap?.sort((a, b) => {
                if (a && b) {
                    if (a.breadth < b.breadth) return 1;
                    if (a.breadth > b.breadth) return -1;
                }
                return 0;
            });
        }

        const containerStartEvent = eventDataMap?.[0];
        const containerStartEventParent = eventDataMap?.[0]?.parents?.[0];
        const insertionLine = {
            lineId: `${containerStartEventParent?.id}_${containerStartEvent?.id}`,
            parentEvent: containerStartEventParent,
            childEvent: containerStartEvent,
            yPlacement: null,
        };
        dispatch({
            type: POP_UP_OPTION,
            focus: null,
            line: insertionLine,
        });

        dispatch(changeSelectedOption(containerObject.constant()));

        onClose(null);
    };

    return (
        <div className={styles.container}>
            <MenuItem
                onClick={handleClearSelectedEvents}
                className={styles.menuItem}
                disabled={selectedEventIdsArray?.length === 0}
            >
                <span className={styles.itemText}>Clear Selected Events</span>
            </MenuItem>
            <MenuItem
                onClick={openContainerCreationModal}
                className={styles.menuItem}
                disabled={selectedEventIdsArray?.length === 0}
            >
                <span className={styles.itemText}>Create Container</span>
            </MenuItem>
        </div>
    );
};

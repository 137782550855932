import { BaselinePrefix } from "./constants";
import { mDate, mDollar, mPercent } from "./modifierHelpers";
import * as moment from "moment";

// Intended for setting "nodeDetail" the variable used to set the node text
// as seen on the canvas. Used in fe context passing too when we want to forcefully
// override the value displayed.
// Automatically rounds number.
// Use this function to avoid semantic coupling!
export const processNodeValueForCanvasDisplay = (value) => {
    if (value === null || value === undefined) {
        return "";
    }
    if (value === "" || isNaN(value)) {
        return value;
    }
    const signed = Math.sign(value) === -1;
    let processedValue = Math.round(Math.abs(value)).toLocaleString();
    processedValue = signed ? `-$${processedValue}` : `$${processedValue}`;
    return processedValue;
};

export const capitalizeFirstHelper = (str) => {
    try {
        return str.charAt(0).toUpperCase() + str.substr(1);
    } catch {
        return "";
    }
};

// Currently only supports Canadian addresses
// Returns province from an address string in the format "...Vancouver, BC ... , Canada"
// Returns null if no province was found
export const extractProvinceFromAddress = (address) => {
    if (!address) return null;
    let splitAddress = address.split(",");
    let province = splitAddress[splitAddress.length - 2];
    if (!province) return null;
    let supposedProvince = province.substr(1, 2);

    // to refactor to an external set (more efficient and readable)
    switch (supposedProvince) {
        case "BC":
            break;
        case "AB":
            break;
        case "SK":
            break;
        case "MB":
            break;
        case "QC":
            break;
        case "ON":
            break;
        case "NL":
            break;
        case "PE":
            break;
        case "NS":
            break;
        case "NB":
            break;
        case "YT":
            break;
        case "NU":
            break;
        case "NT":
            break;
        default:
            return "";
    }
    return supposedProvince.trim();
};

// we can move these two helpers out of this class (I made them not coupled with state)
// returns city from an address string in the format "...Vancouver, BC ... , Canada"
// returns null if no city was found
export const extractCityFromAddress = (address) => {
    if (!address) return null;
    let splitAddress = address.split(",");
    let potentialCity = splitAddress[splitAddress.length - 3];
    if (!potentialCity) return null;
    return potentialCity.trim();
};

// walks up to me node from a given node
export const findRootNode = (node) => {
    while (node.parents.length > 0) {
        node = node.parents[0];
    }
    return node;
};

// If name already exists, adds a number next to it, pass in node event manager
// Note: Intended for default names, not user defined names, as it will always
//      add a number to the end of a name
// Input conventional node props that contains a baseline or reg manager; or input
// manager directly
// (Intended to remove code duplication as much as possible/ enforce single
// point of control)
export const getDefaultName = (name, props, manager) => {
    if (!manager) {
        // checks for manager in props
        if (!props) return name;
        manager = props.manager;
        if (props.baseline && props.baselineManager)
            manager = props.baselineManager;
        if (!manager) return name;
    }
    let count = manager.countNodesWithSameName(name) + 1;
    return `${name} ${count}`;
};

// Returns value surrounded by appropriate unit
// - currently supports mDate, mDollar, mPercent units
export const getStringValueWithUnit = (val, unit) => {
    if (unit === mDate) return val;
    let dollar = unit === mDollar ? "$" : "";
    let percent = unit === mPercent ? "%" : "";
    const valSign = Math.sign(val);
    if (valSign === -1) {
        return `-${dollar}${Math.abs(val)}${percent}`;
    } else {
        return `${dollar}${val}${percent}`;
    }
};

// Safer way of creating native date objects
// dateStr is expected in format YYYY-MM-DD
export const createDate = (dateStr) => {
    if (typeof dateStr !== "string") {
        dateStr = moment(dateStr).format("YYYY-MM-DD");
    }
    if (!dateStr) return null;
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(5, 7);
    const date = dateStr.substring(8, 10);
    return new Date(+year, +month - 1, +date);
};

// Baseline nodes have a baseline prefix. This method ensures we can control this
// aspect from a single point
export const prependBaselinePrefix = (id) => {
    return `${BaselinePrefix}${id}`;
};

// Returns true if value is a valid number.
// This was created because boolean checks, such as !this.value,
// will return true if value is undefined or 0, when they really
// intend on checking if it's undefined/null/"" and returning false when
// it's an existing number
export const valueDoesNotExist = (value) => {
    return !value && value !== 0;
};

// Utility function for recursive scenario traversals starting from an upstream node
// intending to reach a downstream target node
export const getViableChildrenToSearch = (node, target) => {
    let nodesToSearch = []; // filters out nodes the at are bypassed/ locked
    for (let child of node.children) {
        if (!child) continue;
        if (child.isLocked() || child === target) {
            // only path we want to go down, so we clear other nodes
            nodesToSearch = [];
            nodesToSearch.push(child);
            break;
        }
        nodesToSearch.push(child);
    }
    return nodesToSearch;
};

// rounds number to (up to) two decimal points, as appropriate, for example:
// (with maxDecimalPoints === 2)
// - 1.005 ==> 1.01
// - 1.001 ==> 1
// - 1.1   ==> 1.1
export const roundNumberForDisplay = (value, maxDecimalPoints = 2) => {
    return (
        Math.round((Number(value) + Number.EPSILON) * 10 ** maxDecimalPoints) /
        10 ** maxDecimalPoints
    );
};

export const getDefaultScenarioPermissions = () => {
    return {
        default: {
            view: false,
            comment: false,
            edit: false,
            share: false,
            clone: false,
            changePermissions: false,
            includeBaseline: false,
        },
        whitelist: {},
    };
};

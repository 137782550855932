import type { XYChart } from "@amcharts/amcharts4/charts";
import { useEffect, useRef } from "react";
import { useDimension } from "helpers/useDimension";
import GraphLoading from "../GraphLoading";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

interface GraphLineViewProps {
    xyChart: XYChart | null;
    graphLoading: boolean;
}

export default function GraphLineView({
    xyChart,
    graphLoading,
}: GraphLineViewProps) {
    const graphElementRef = useRef<HTMLDivElement>(null);
    const [width, height] = useDimension(graphElementRef.current);

    useEffect(() => {
        if (xyChart) {
            xyChart.width = width;
            xyChart.height = height;
        }
    }, [xyChart, width, height]);

    const graphElemClassName = graphLoading
        ? "Whatifi-Line-Graph2--hidden"
        : "";

    return (
        <div className="Whatifi-Line-Graph-Container">
            <GraphLoading isLoading={graphLoading} />
            <div
                ref={graphElementRef}
                id="Whatifi-Line-Graph2"
                className={graphElemClassName}
            />
        </div>
    );
}

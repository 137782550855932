// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    ModalRowHalf,
    Value,
    MuiCalendar,
} from "../Components";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { initialBalanceInputsHandler } from "../OnInputChangeHandlers/initialBalanceInputsHandler";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

export default function InitialBalanceInputView({
    entitiesMap,
    setEntitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    selectedAccountData,
    handleDateSelection,
}) {
    const updateAccount = (accountType, id) => {
        const value = accountType;
        const newEntitiesMap = initialBalanceInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const accountUnit = selectedAccountData?.unit?.display ?? "";

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={initialBalanceObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={initialBalanceObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[initialBalanceObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>

                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={
                            entitiesMap?.[currentEntity]?.data?.accountName
                        }
                        required={true}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Value
                            label="Initial Balance"
                            onChangeInput={handleOnChange}
                            value={entitiesMap?.[currentEntity]?.data?.value}
                            id="value"
                            startAdornment={
                                accountUnit === ("$" || "%") ? accountUnit : ""
                            }
                            required={true}
                        />
                    </ModalRowHalf>
                    {entitiesMap?.[currentEntity]?.data?.lastUpdated && (
                        <div className="last-updated">
                            Last Updated:{" "}
                            {entitiesMap?.[currentEntity]?.data?.lastUpdated ||
                                ""}
                        </div>
                    )}
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="startDate"
                            value={
                                entitiesMap?.[currentEntity]?.startDate === ""
                                    ? null
                                    : entitiesMap?.[currentEntity]?.startDate
                            }
                            required={true}
                            onChangeInput={handleDateSelection}
                            label="Bank Balance Date"
                        />
                    </ModalRowHalf>
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import React, { useState } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import {
    extractCityFromAddress,
    extractProvinceFromAddress,
    getDefaultName,
} from "../../../helpers";
import { employeeObject } from "../../Registry/Employee";
import EmployeeInputView from "./EmployeeInputView";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { employeeInputsHandler } from "../OnInputChangeHandlers/employeeInputsHandler";

export default function EmployeeInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const entitiesObject = useAppSelector((state) => state?.entities);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(employeeObject);
            _eventData.name = getDefaultName(
                employeeObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entityIds,
        entitiesMap,
        entityIndex,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        // onHandleDate,
        // onHandleStarHover,
        // onMouseLeave,
        updateInflation,
    } = useEntities(entitiesObject, eventData, edit, employeeObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "personName"
            | "rate"
            | "entityName"
            | "cadence"
            | "rating"
            | "averageHours"
            | "salary"
            | "noEmployees"
            | "expenseType",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = employeeInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDisplayFinancialDataToggle = (checked) => {
        const newEntitiesMap = employeeInputsHandler(
            checked,
            "displayFinancialData",
            0,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = employeeInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const star = 0;
        const newEntitiesMap = employeeInputsHandler(
            "",
            id,
            star,
            entitiesMap,
            currentEntity,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const toggleEmployeeState = (e) => {
        const id = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.employeeState = id;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const togglePayType = (e) => {
        const id = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.payType = id;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const processAddress = (address, updatedEntitiesMap) => {
        const province = extractProvinceFromAddress(address);
        const city = extractCityFromAddress(address);

        const newEntitiesMap = { ...updatedEntitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (address) {
            const arr = address.split(", ");
            const country = arr[arr.length - 1];
            data.country = country;
        }

        data.province = province;
        data.city = city;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        setEntitiesMap(newEntitiesMap);
    };

    const onHandleLocation = (address, lat, lng) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.address = address;
        data.lat = lat;
        data.lng = lng;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;

        processAddress(address, newEntitiesMap);
    };

    // const onToggleCheckBox = (id) => {
    //     const newEntitiesMap = { ...entitiesMap };
    //     const currentEntityObject = {
    //         ...(newEntitiesMap[currentEntity] || {}),
    //     };
    //     const data = { ...(currentEntityObject?.data || {}) };

    //     switch (id) {
    //         case "overtime":
    //             data.overtime = !data.overtime;
    //             break;
    //         case "pension":
    //             data.includePension = !data.includePension;
    //             break;
    //         case "EI":
    //             data.includeEI = !data.includeEI;
    //             break;
    //         default:
    //         //noop
    //     }

    //     currentEntityObject.data = data;
    //     newEntitiesMap[currentEntity] = currentEntityObject;
    //     setEntitiesMap(newEntitiesMap);
    // };

    // const checkBox = [
    //     {
    //         id: "overtime",
    //         text: "Overtime eligible",
    //         state: entitiesMap[currentEntity].data.overtime,
    //     },
    //     {
    //         id: "EI",
    //         text: "Include EI Estimate",
    //         state: entitiesMap[currentEntity].data.includeEI,
    //     },
    //     {
    //         id: "pension",
    //         text: "Include CPP/QPP Estimate",
    //         state: entitiesMap[currentEntity].data.includePension,
    //     },
    // ];

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && employeeObject.checkInput(entitiesMap);

    return (
        <EmployeeInputView
            currentEntity={currentEntity}
            entitiesLength={entityIds.length}
            entitiesMap={entitiesMap}
            entityIndex={entityIndex}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            handleClickAddEntityCard={handleClickAddEntityCard}
            toggleEmployeeState={toggleEmployeeState}
            togglePayType={togglePayType}
            onHandleLocation={onHandleLocation}
            onChangeNameDescription={onChangeNameDescription}
            updateInflation={updateInflation}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            passedCheck={passedCheck}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
            handleDisplayFinancialDataToggle={handleDisplayFinancialDataToggle}
            handleDateSelection={handleDateSelection}
            updateAccount={updateAccount}
        />
    );
}

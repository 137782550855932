import React from "react";
import styles from "./index.module.css";

export default class Billing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personal: [
                {
                    name: "Free",
                    description: null,
                    cost: "$0",
                    frequency: "No Cost - Ever",
                    content: [
                        "Access to all Basic Events and Templates",
                        "5 Scenario limit",
                        "Up to 40 Events per Scenario",
                        "Scenario Sharing with 1 other user per Scenario",
                    ],
                    id: "free-plan",
                    frequencyDisplay: false,
                    personal: true,
                },
                {
                    name: "Starter",
                    description: null,
                    cost: "$15",
                    frequency: "One Time Lifetime Payment",
                    content: [
                        "Access to all Starter Events and Templates",
                        "Up to 15 Scenarios; One Baseline Account",
                        "Up to 75 Events per Scenario",
                        "Real-time notifications and Scenario Sharing with 2 other users",
                        "Add-on Pro Events for $1/month",
                        "Scenario Alerts for $1/month per Scenario",
                    ],
                    id: "product-hunt",
                    frequencyDisplay: true,
                    personal: true,
                },
                {
                    name: "Personal Pro",
                    description: null,
                    cost: "$20",
                    frequency: "Price per Month",
                    content: [
                        "Access to all Pro Events including some of our Small Business Events",
                        "Unlimited Scenarios",
                        "Unlimited Threads; One Baseline Account",
                        "Unlimited Scenario Sharing",
                        "Support Access",
                    ],
                    id: "personal-pro",
                    frequencyDisplay: true,
                    personal: true,
                },
            ],
            business: [
                {
                    name: "Small Business",
                    description:
                        "Designed for SMB owners to model revenue and cash flow, plan for multiple Scenarios and share their Scenarios with their advisors",
                    cost: "$95",
                    frequency: "Price per Month",
                    content: [
                        "Access to all Small Business Events and Templates",
                        "Quickbooks Integration",
                        "Up to 10 Users",
                        "Up to 50 Scenarios",
                        "Unlimited Threads; One Baseline Account",
                        "Advanced Scenario Sharing",
                        "Basic Support",
                    ],
                    id: "small-business",
                    frequencyDisplay: true,
                    personal: false,
                },
                {
                    name: "Agency",
                    description:
                        "Empowering individual consultants and professionals to build, share and monitor custom Scenarios with their clients.",
                    cost: "$395",
                    frequency: "Price per Month",
                    content: [
                        "Access to all Personal and Business Events",
                        "Up to 10 Users; $25 per additional User Account",
                        "Ability to create Custom Events",
                        "Multi-Client support - Unlimited Scenarios; Unlimited Baselines",
                        "Unlimited Threads",
                        "Advanced Reports and Scenario Sharing Dashboard",
                        "Pro Support",
                    ],
                    id: "agency",
                    frequencyDisplay: true,
                    personal: false,
                },
                {
                    name: "Enterprise",
                    description:
                        "Our group plan. For organization with multiple stakeholders, large scale applications and customized implementations.",
                    cost: "Contact Us",
                    frequency: null,
                    content: [
                        "Access to all Features",
                        "Support for custom integration",
                        "Per seat pricing",
                        "Contact us for pricing",
                    ],
                    id: "enterprise",
                    frequencyDisplay: false,
                    personal: false,
                },
            ],
        };
    }
    render() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        return (
            <div className={styles.settingContainer}>
                <div className={styles.headerOne}>Billing </div>
                <div style={{ display: "flex" }}>
                    <div className={styles.leftSetting}>
                        <div className={styles.containers}>
                            <div className={styles.headerTwo}>Subscription</div>
                            <div className={styles.headerThree}>Plan</div>
                            <div className={styles.profileText}>
                                {loggedInUser.plan
                                    ? loggedInUser.plan.name
                                    : "free-plan"}
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSetting}>
                        <div
                            className={styles.manageSub}
                            onClick={this.props.openPortal}
                        >
                            Manage Subscription
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

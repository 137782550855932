import React, { Component } from "react";
import "./Card.css";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
// import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
// import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import moment from "moment";

class StudentLoanCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        const {
            cadence,
            borrowingReason,
            description,
            url,
            rating,
            rate,
            loan,
            numTermUnits,
            termUnit,
            payments,
            version,
        } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">URL:</div>
                    <a
                        className="ExpenseLink"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {url}
                    </a>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Reason:</div>
                    <div className="cardValue">{borrowingReason}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Loan amount:</div>
                    <div className="cardNumber">{loan ? `$ ${loan}` : ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Interest rate:</div>
                    <div className="cardValue">{rate ? `${rate} %` : ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Payment term:</div>
                    <div className="cardValue">
                        {numTermUnits ? `${numTermUnits} ${termUnit}` : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Frequency:</div>
                    <div className="cardValue">{cadence}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Each payment:</div>
                    <div className="cardValue">{`$ ${payments}`}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Date</div>
                    <div className="cardValue">
                        {this.props.data.metadata.start
                            ? moment(this.props.data.metadata.start).format(
                                  "MMM D YYYY"
                              )
                            : "No Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel"> Rating</div>
                    <div className="StarsContainer">
                        {stars.map((star, i) => {
                            if (i < rating) {
                                return (
                                    <img
                                        alt="alt"
                                        key={i}
                                        className="starFilledSvg"
                                        src={starFilledSvg}
                                        id="rating"
                                    />
                                );
                            } else {
                                return (
                                    <img
                                        alt="alt"
                                        className="starUnfilledSvg"
                                        src={starUnfilledSvg}
                                        key={i}
                                        id="rating"
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default StudentLoanCard;

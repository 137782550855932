import { useState, useEffect, useMemo, useContext } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import CustomerChurn2InputView from "./CustomerChurn2InputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { customerChurn2InputsHandler } from "../OnInputChangeHandlers/customerChurn2InputsHandler";
import { updateEntityState } from "helpers/updateEntityState";
import { customerChurn2Object } from "Components/Registry/CustomerChurn2";
import { customer2Object } from "Components/Registry/Customer2";
import { throwError } from "helpers/swalError";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { groupObject } from "Components/Registry/Group";
import { cloneDeep } from "lodash";

export default function CustomerChurn2InputExperimental({
    fillPartialValues,
    line,
    edit,
    editData,
    baseline,
    confirmAction,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);
    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const propsObject = useMemo(
        () => ({ baselineManager, manager, line, eventId, baseline }),
        [baseline, baselineManager, eventId, line, manager]
    );

    const defaultName = useMemo(
        () => getDefaultName(customerChurn2Object.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(customerChurn2Object);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entityIndex,
        entitiesMap,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        onHandleDate,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "entityName"
            | "entity"
            | "maxChurnRate"
            | "constantChurnRate"
            | "churnRate"
            | "startChurn"
            | "cadence",
        index?: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = customerChurn2InputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            index
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeValue = (
        value: any,
        id:
            | "churnType"
            | "interpolate"
            | "startDate"
            | "period"
            | "inheritStartDate"
            | "inheritCustomer"
            | "constantChurnPeriod",
        index?: number
    ) => {
        const newEntitiesMap = customerChurn2InputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            index
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const getCustomers = () => {
        const customers = {};
        const groups = {};

        if (!propsObject.eventId && !propsObject.line) return customers;

        getPresentableDependencies(
            customers,
            customer2Object.constant(),
            propsObject, // Temporary object
            true
        );

        getPresentableDependencies(
            groups,
            groupObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(customers).length && !Object.keys(groups).length) {
            throwError(
                "warning",
                "No Customer2 or Group nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return { ...customers, ...groups };
    };

    const addNewSegment = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const churnSegments = cloneDeep(data.churnSegments);

        const newSegment = {
            startDate: "",
            monthlyRate: 0,
            yearlyRate: 0,
            rateDisplay: "",
            period: "monthly",
        };

        churnSegments.push(newSegment);

        data.churnSegments = churnSegments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const deleteSegment = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const churnSegments = cloneDeep(data.churnSegments);

        churnSegments.splice(index, 1);
        data.churnSegments = churnSegments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const customers = getCustomers();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.customers = customers;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, baselineManager, setEntitiesMap, defaultName]);

    // have the option of passing in a customEntitiesMap since this function is called after entitiesMap is set,
    // so it isn't updated immediately
    const onHandleSubmitValues = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                confirmAction,
                fillPartialValues,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    // ALSO NEEDS A REWORK!
    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                confirmAction,
                fillPartialValues,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = customerChurn2Object.checkInput(entitiesMap);
    return (
        <CustomerChurn2InputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            entitiesLength={entityIds.length}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmitValues={onHandleSubmitValues}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleEntityStateChange={handleEntityStateChange}
            onChangeValue={onChangeValue}
            addNewSegment={addNewSegment}
            deleteSegment={deleteSegment}
            onHandleDate={onHandleDate}
        />
    );
}

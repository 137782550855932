import type { XYChart } from "@amcharts/amcharts4/charts";
import type { CalculatedThreadsSchema } from "reducers/typesSchema/calculatedThreadsSchema";
import type { LedgerMetadataUnit } from "reducers/typesSchema/allAccountLedgersSchema";
import type { ChartGraphSchema } from "reducers/typesSchema/chartGraphSchema";
import { useEffect, useRef, useState } from "react";
import {
    CancellableDrawGraphPromise,
    drawGraphAsync,
} from "Components/ChartGraph/helpers/drawGraph/drawGraphAsync";
import { getUniqueGraphId } from "Components/ChartGraph/helpers/getUniqueGraphId";

interface UseDrawGraphProps {
    activeThreadId: string;
    calculatedThreads: CalculatedThreadsSchema;
    chartGraphState: ChartGraphSchema;
    ledgerMetadataUnit: LedgerMetadataUnit;
    selectedThreads: string[];
    filteredEventAndEntityData: any[];
}

export function useDrawGraph({
    activeThreadId,
    calculatedThreads,
    chartGraphState,
    ledgerMetadataUnit,
    selectedThreads,
    filteredEventAndEntityData,
}: UseDrawGraphProps) {
    const xAxisZoomRef = useRef<[start: number, end: number]>([0, 100]);
    const xyChart = useRef<XYChart | null>(null);
    const [graphLoading, setGraphLoading] = useState<boolean>(false);
    const prevGraphPromise = useRef<CancellableDrawGraphPromise | null>(null);

    useEffect(() => {
        const _chartGraphState = {
            ...chartGraphState,
            activeThreadId,
            selectedThreads,
        };
        if (!_chartGraphState.nodeIds.length) {
            _chartGraphState.nodeIds = ["_All"]; // Default to show entire thread
        }

        // Step 1: Find the unique id for the graph
        const _uniqueGraphId = getUniqueGraphId(_chartGraphState);

        // TODO: Step 2: Find out if this graph has been cached

        // TODO: Step 2a: If graph is cached, place it in the graph-line-container

        // Step 2b: If graph is not cached, render graph. This is the main function which will draw the graph and determine if it should be put into the UI (depending if it was cancelled).
        if (_chartGraphState.activeThreadId || selectedThreads.length) {
            // console.count(`Drawing new graph for ${uniqueGraphId}`); // NOTE: Used to ensure graph redraws are deterministic. ie. Based on a user's SINGLE action, there should not be multiple redraws. This was an issue we had before.
            // console.time(`${uniqueGraphId} - GraphRender Time`) // Only used to test timing
            if (xyChart.current) {
                xyChart.current.dispose(); // Temporary. Dispose should not be required if we are caching.
            }
            setGraphLoading(true);

            // Step 2bi: If a previous graph render is still loading, cancel it so that we don't load it up in the UI when it does finish.
            // This is because JavaScript does not have a true "Cancel" for Promises. The code still runs through and resolves.
            if (prevGraphPromise.current) {
                prevGraphPromise.current.cancel();
            }

            // Step 2bii: Call the render graph async function, and assign its CancellableRenderGraphPromise object to prevGraphPromise.current
            // This allows us to cancel if another call comes in before this render graph had a chance to finish
            const renderGraphPromise = drawGraphAsync(
                _chartGraphState,
                selectedThreads,
                calculatedThreads,
                ledgerMetadataUnit,
                filteredEventAndEntityData,
                { xAxisZoom: xAxisZoomRef.current }
            );
            prevGraphPromise.current = renderGraphPromise;

            renderGraphPromise.promise
                ?.then((newXyChart) => {
                    // Step 2biii: If we received a new chart object. If nothing was given back, don't do anything.
                    if (newXyChart) {
                        // If this promise was not cancelled, go ahead and put it into the UI. Else do nothing.
                        if (!renderGraphPromise.cancelled) {
                            // setXyChart forces the view to rerender with the correct width/height for the newly created chart
                            // Ideally, we only deal with a singleton chart object because currently it "flashes" when rendering the new chart, but need more Research + Discovery time for that
                            // setXyChart(newXyCHart);
                            xyChart.current = newXyChart;
                            setGraphLoading(false);
                            prevGraphPromise.current = null;
                        } else {
                            // TODO: If graph was cancelled, go ahead and cache it so we don't need to draw it again.
                            newXyChart.dispose(); // Temporary. Dispose should not be required if we are caching.
                            // console.warn("I got a new xyChart, but not rendering because I was cancelled!", newXyCHart)
                        }
                    }
                })
                .catch((e: Error) => {
                    if (!renderGraphPromise.cancelled) {
                        // Step 2biv: If this call was not cancelled (there's no other calls in the future happening), log the error and set loading to false
                        console.error("Could not render graph!", e);
                        setGraphLoading(false);
                        prevGraphPromise.current = null;
                    }
                })
                .finally(() => {
                    // console.timeEnd(`${uniqueGraphId} - GraphRender Time`) // Only used to test timing
                });
        }
    }, [
        activeThreadId,
        calculatedThreads,
        chartGraphState,
        selectedThreads,
        ledgerMetadataUnit,
        filteredEventAndEntityData,
    ]);

    useEffect(() => {
        if (!graphLoading && xyChart.current) {
            try {
                // A chart has loaded, listen to the X Axis Scrollbar zoom.
                // Source: https://www.amcharts.com/docs/v4/concepts/event-listeners/
                const xAxis = xyChart.current.xAxes.values?.[0];
                const eventDisposer = xAxis?.events?.on?.(
                    "startendchanged",
                    ({ target }) => {
                        xAxisZoomRef.current = [target.start, target.end];
                    }
                );
                return () => {
                    eventDisposer.dispose();
                };
            } catch (e) {
                if (
                    e instanceof Error &&
                    e.message.includes("EventDispatcher is disposed")
                ) {
                    // This if statement only happens in dev mode when hot reloading. Doesn't happen in prod.
                    console.log(
                        "Expected Error when hot reloading. Should not see this in production"
                    );
                } else {
                    console.error("Unexpected X-Axis EventDispatch Error:", e);
                }
            }
        }
    }, [graphLoading]);

    return {
        xyChart: xyChart.current,
        graphLoading,
    };
}

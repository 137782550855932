//images for the categories

// import residentialImg from "../Assets/_optionPicker/RealE.png";
// import commercialImg from "../Assets/_optionPicker/CommercialRealEstate.png";
// import retirementImg from "../Assets/_optionPicker/RetirementPlanning.png";
// import taxesImg from "../Assets/_optionPicker/taxes.png";
// import canadaImg from "../Assets/_optionPicker/CanadaSpecific.png";
// import investmentImg from "../Assets/_optionPicker/Investments.png";
// import childcareImg from "../Assets/_optionPicker/ChildcarePlanning.png";
// import usaImg from "../Assets/_optionPicker/USASpecific.png";
// import { propertyValueObject } from "Components/Registry/Property Value";
// import { maternityObject } from "Components/Registry/Maternity";
// import { investmentObject } from "Components/Registry/Investment";
// import { rrspObject } from "Components/Registry/RRSP";
// import { renewalRefinanceObject } from "Components/Registry/Renewal Refinance";
// import { mortgageObject } from "Components/Registry/Mortgage";
// import { propertyResaleObject } from "Components/Registry/Property Resale";
// import { propertyTransferTaxObject } from "Components/Registry/Property Transfer Tax";
// import { downpaymentObject } from "Components/Registry/Downpayment";
// import { closingCostsObject } from "Components/Registry/Closing Costs";
// import { homeMaintenanceObject } from "Components/Registry/Home Maintenance";
// import { capitalGainsObject } from "Components/Registry/Capital Gains";
// import { pensionObject } from "Components/Registry/Pension";
// import { realEstateFeeObject } from "Components/Registry/Real Estate Fees";
// import { rentalIncomeObject } from "../Components/Registry/Rental Income";
// import { tuitionObject } from "../Components/Registry/Tuition";
// import { studentBudgetObject } from "../Components/Registry/Student Budget";
// import { retirementObject } from "../Components/Registry/Retirement";
// import { graduationObject } from "../Components/Registry/Graduation";
// import { studentLoanObject } from "../Components/Registry/Student Loan";
// import { grantObject } from "../Components/Registry/Grant";
// import { lumpSumPaymentObject } from "Components/Registry/Lump Sum Payment";
// import { shortTermRentalObject } from "Components/Registry/Short Term Rental";
// import { mortgageInsuranceObject } from "Components/Registry/Mortgage Insurance";
// import { equityObject } from "Components/Registry/Equity";
// import { condoFeeObject } from "../Components/Registry/CondoFee";

// import bankingImg from "../Assets/_optionPicker/Banking.png";
// import whatifiBasicsImg from "../Assets/_optionPicker/whatifiBasics.png";
// import smbModuleImg from "../Assets/_optionPicker/SMBBasicModule.png";
// import employeeImg from "../Assets/_optionPicker/EmployeeStaffing.png";
import bankingImg from "../Assets/_optionPicker/updated-banking.png";
import whatifiBasicsImg from "../Assets/_optionPicker/updated-whatifiBasics.png";
import smbModuleImg from "../Assets/_optionPicker/updated-SMBBasicModule.png";
import employeeImg from "../Assets/_optionPicker/updated-employeeStaffing.png";
import { expenseObject } from "../Components/Registry/Expense";
import { budgetObject } from "../Components/Registry/Budget";
import { loanObject } from "../Components/Registry/Loan";
import { debtObject } from "../Components/Registry/Debt";
import { debtRepaymentObject } from "../Components/Registry/Debt Repayment";
import { customerObject } from "../Components/Registry/Customer";
import { customerGrowthObject } from "../Components/Registry/CustomerGrowth";
import { customerChurnObject } from "../Components/Registry/CustomerChurn";
import { customerTransferObject } from "../Components/Registry/CustomerTransfer";
import { unitCostObject } from "../Components/Registry/Unit Cost";
import { revenueObject } from "../Components/Registry/Revenue";
import { incomeObject } from "../Components/Registry/Income";
import { rentObject } from "../Components/Registry/Rent";
import { houseObject } from "../Components/Registry/House";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { bankObject } from "Components/Registry/Bank";
import { employeeObject } from "Components/Registry/Employee";
import { personObject } from "Components/Registry/Person";
import { cacObject } from "Components/Registry/CAC";
import { contractObject } from "../Components/Registry/Contract";
import { instanceObject } from "Components/Registry/Instance";
import { initialBalanceObject } from "Components/Registry/InitialBalance";
import { expressionObject } from "Components/Registry/Expression";
import { importObject } from "Components/Registry/Import";
import { accountImportObject } from "Components/Registry/AccountImport";
import { outboundSalesObject } from "Components/Registry/OutboundSales";
import { salespersonObject } from "Components/Registry/Salesperson";
import { segmentObject } from "Components/Registry/Segment";
import { campaignObject } from "Components/Registry/Campaign";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { allocationObject } from "Components/Registry/Allocation";
import { percentageObject } from "Components/Registry/Percentage";
import { resourceObject } from "Components/Registry/Resource";
import { capacityObject } from "Components/Registry/Capacity";
import { averageOrderObject } from "Components/Registry/AverageOrder";
import { unitObject } from "Components/Registry/Unit";

//utility events
import { decisionObject } from "Components/Registry/Decision";
import { nullObject } from "Components/Registry/Null";
import { kpiObject } from "Components/Registry/KPI";
import { groupObject } from "Components/Registry/Group";
import { projectObject } from "Components/Registry/Project";
import { modifierObject } from "Components/Registry/Modifier";
import { growthObject } from "Components/Registry/Growth";
import { constraintObject } from "Components/Registry/Constraint";
import { accountModifierObject } from "Components/Registry/AccountModifier";
import { accountExpressionObject } from "Components/Registry/AccountExpression";
import { goalObject } from "Components/Registry/Goal";
import { projectsObject } from "Components/Registry/Projects";
import { utilizationObject } from "Components/Registry/Utilization";

//types
export const Me = "Me";
export const Income = "Income";
export const Expense = "Expense";
export const Mortgage = "Mortgage";
export const StudentIncome = "Part Time Job";
export const StudentExpense = "Tuition";
export const StudentBudget = "Student Budget";
// export const Bank = "Bank";
export const Loan = "Loan";
export const Container = "Container";
export const Decision = "Decision";
export const Budget = "Budget";
export const Goal = "Goal";
export const Rent = "Rent";
export const House = "House";
export const PropertyTransferTax = "Land Transfer Tax";
export const IncomeTax = "IncomeTax";
export const BankBeta = "BankBeta";
export const BudgetBeta = "BudgetBeta";
export const Investment = "Investment";
export const BankPlaid = "Bank Plaid";
export const Downpayment = "Downpayment";
export const Bank = "Bank";
export const PropertyValue = "Property Value";
export const PropertyResale = "Property Resale";
export const Modifier = "Modifier";
export const Person = "Person";
export const Employee = "Employee";
export const Pension = "Pension";
export const Retirement = "Retirement";
export const LumpSumPayments = "Lump Sum Payments";
export const RenewalRefinance = "Renewal/ Refinance";
export const RealEstateFee = "Real Estate Fee";
export const MortgageInsurance = "Mortgage Insurance";
export const CapitalGains = "Capital Gains";
export const ClosingCosts = "Closing Costs";
export const HomeMaintenance = "Home Maintenance";
export const ShortTermRental = "Short-Term Rental";
export const CustomerTransfer = "Customer Transfer";
export const Instance = "Instance";

//types coming soon
export const RRSP = "RRSP";
export const Commute = "Commute";
export const Daycare = "Daycare";
export const fourZeroOneK = "401K";
export const Nanny = "Nanny";
export const HouseMaintenance = "House Maintenance";
export const Childcare = "Childcare";
export const ChildcareTax = "Childcare Benefit";
export const ChildNode = "Child";
export const MortgageUSA = "MortgageUSA";
export const IncomeTaxUSA = "Income Tax USA";
export const Parental = "Parental Leave";
export const Lease = "Lease";
export const OldAge = "Old Age Security";
export const TFSA = "TFSA";
export const Drawdowns = "Drawdowns";
export const Product = "COGS Product";
export const Service = "COGS Service";
export const Revenue = "Revenue";
export const Inventory = "Inventory";
export const Customers = "Customers";
export const Growth = "Growth";
export const Churn = "Churn";

//income tax constant
export const EmploymentIncome = "Employment income";
export const SelfEmploymentIncome = "Self-employment income";
export const EligibleDividends = "Eligible dividends";
export const IneligibleDividends = "Ineligible dividends";
export const OtherIncome = "Other income";
export const RRSPDeduction = "RRSP deduction";
export const IncomeTaxesPaid = "Income taxes paid";

//province of canada
export const ON = "Ontario";
export const QC = "Quebec";
export const BC = "British Columbia";
export const AB = "Alberta";
export const MB = "Manitoba";
export const SK = "Saskatchewan";
export const NS = "Nova Scotia";
export const NB = "New Brunswick";
export const NL = "Newfoundland and Labrador";
export const PE = "Prince Edward Island";
export const NT = "Northwest Territories";
export const YT = "Yukon";
export const NU = "Nunavut";
export const Provinces = [AB, BC, MB, NB, NL, NS, NT, NU, ON, PE, QC, SK, YT];

export const BaselinePrefix = "baseline-";

// standard cadence options, as used in mortgage or lumpSumPaymentsNode
// todo: refactor other nodes to use this too
export const cadenceOptions = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

//Helper Function For Date
//Accepts a MMMM date (eg. April, May, June, etc.)
//Converts to MM format (April => 04)
export const MMMMToMM = (month) => {
    switch (month) {
        case "January":
            return "01";
        case "February":
            return "02";
        case "March":
            return "03";
        case "April":
            return "04";
        case "May":
            return "05";
        case "June":
            return "06";
        case "July":
            return "07";
        case "August":
            return "08";
        case "September":
            return "09";
        case "October":
            return "10";
        case "November":
            return "11";
        default:
            return "12";
    }
};

//Categories
export const ResidentialRealEstate = "Residential Real Estate";
export const CommercialRealEstate = "Commercial Real Estate";
export const WhatifiBasics = "Basic Events";
export const RetirementPlanning = "Retirement Planning";
export const Banking = "Banking Basics";
export const UtilityEvents = "Utility Events";
export const Taxes = "Personal Taxes";
export const Canada = "Canada Specific";
export const InvestmentPlanning = "Wealth Management";
export const ChildcarePlanning = "Childcare Planning";
export const USA = "USA Specific";
export const SMBBasicModule = "SMB Basic Module";
export const CapacityModule = "Capacity Module";
export const Student = "Student Module";

export const optionNodes = [
    {
        type: WhatifiBasics,
        description:
            "These are basic Events common to most scenarios and can be used for basic calculations and cash flow projections.",
        activeNodes: [
            {
                type: unitObject.constant(),
                name: unitObject.name(),
            },
            {
                type: debitCreditObject.constant(),
                name: debitCreditObject.name(),
            },
            { type: incomeObject.constant(), name: incomeObject.name() },
            { type: expenseObject.constant(), name: expenseObject.name() },
            { type: bankObject.constant(), name: bankObject.name() },
            { type: loanObject.constant(), name: loanObject.name() },
            { type: debtObject.constant(), name: debtObject.name() },
            {
                type: debtRepaymentObject.constant(),
                name: debtRepaymentObject.name(),
            },
            {
                type: initialBalanceObject.constant(),
                name: initialBalanceObject.name(),
                pro: true,
            },
            {
                type: percentageObject.constant(),
                name: percentageObject.name(),
            },
            {
                type: contractObject.constant(),
                pro: true,
                name: contractObject.name(),
            },
        ],
        inProgressNodes: [
            {
                type: accountImportObject.constant(),
                name: accountImportObject.name(),
                pro: true,
            },
            {
                type: expressionObject.constant(),
                name: expressionObject.name(),
                pro: true,
            },
        ],
        deprecatedNodes: [],
        isOpen: false,
        svg: whatifiBasicsImg,
    },
    {
        type: Banking,
        description:
            "For all of your bank, budget, and finance tracking needs, including integrations with your bank accounts.",
        activeNodes: [
            { type: bankObject.constant(), name: bankObject.name() },
            { type: loanObject.constant(), name: loanObject.name() },
            { type: debtObject.constant(), name: debtObject.name() },
            {
                type: debtRepaymentObject.constant(),
                name: debtRepaymentObject.name(),
            },
            {
                type: initialBalanceObject.constant(),
                name: initialBalanceObject.name(),
                pro: true,
            },
        ],
        inProgressNodes: [
            // Hiding coming soon options
            // { type: budgetObject.constant() },
            // { type: taxDeductibleObject.constant() },
        ],
        deprecatedNodes: [],
        isOpen: false,
        svg: bankingImg,
    },
    {
        type: SMBBasicModule,
        description:
            "PRO - Our suite of Events to model the most common scenarios and financial actions for Small Business owners.",
        activeNodes: [
            {
                type: customerObject.constant(),
                pro: true,
                name: customerObject.name(),
            },
            {
                type: customerGrowthObject.constant(),
                pro: true,
                name: customerGrowthObject.name(),
            },
            {
                type: customerChurnObject.constant(),
                pro: true,
                name: customerChurnObject.name(),
            },
            {
                type: customerTransferObject.constant(),
                pro: true,
                name: customerTransferObject.name(),
            },
            {
                type: unitCostObject.constant(),
                pro: true,
                name: unitCostObject.name(),
            },
            {
                type: averageOrderObject.constant(),
                pro: true,
                name: averageOrderObject.name(),
            },
            {
                type: revenueObject.constant(),
                pro: true,
                name: revenueObject.name(),
            },
            {
                type: contractObject.constant(),
                pro: true,
                name: contractObject.name(),
            },
            {
                type: websiteVisitorsObject.constant(),
                pro: true,
                name: websiteVisitorsObject.name(),
            },
            {
                type: campaignObject.constant(),
                pro: true,
                name: campaignObject.name(),
            },
            {
                type: segmentObject.constant(),
                pro: true,
                name: segmentObject.name(),
            },
            {
                type: allocationObject.constant(),
                pro: true,
                name: allocationObject.name(),
            },
            {
                type: salespersonObject.constant(),
                pro: true,
                name: salespersonObject.name(),
            },
            {
                type: outboundSalesObject.constant(),
                pro: true,
                name: outboundSalesObject.name(),
            },
        ],

        inProgressNodes: [
            { type: Product, name: Product, pro: true },
            { type: Service, name: Service, pro: true },
            { type: Inventory, name: Inventory, pro: true },
            {
                type: instanceObject.constant(),
                pro: true,
                name: instanceObject.name(),
            },
            { type: cacObject.constant(), pro: true, name: cacObject.name() },

            {
                type: growthObject.constant(),
                pro: true,
                name: growthObject.name(),
            },
        ],
        deprecatedNodes: [],
        isOpen: false,
        svg: smbModuleImg,
    },
    {
        type: CapacityModule,
        description:
            "PRO - for all staffing, resource forecasting, utilization and capacity calculations.",
        activeNodes: [
            {
                type: employeeObject.constant(),
                pro: true,
                name: employeeObject.name(),
            },
            {
                type: resourceObject.constant(),
                pro: true,
                name: resourceObject.name(),
            },
            {
                type: importObject.constant(),
                name: importObject.name(),
                pro: true,
            },
            {
                type: projectsObject.constant(),
                pro: true,
                name: projectsObject.name(),
            },
            {
                type: utilizationObject.constant(),
                pro: true,
                name: utilizationObject.name(),
            },
            {
                type: capacityObject.constant(),
                pro: true,
                name: capacityObject.name(),
            },
        ],
        inProgressNodes: [],
        deprecatedNodes: [],
        isOpen: false,
        svg: employeeImg,
    },
    {
        type: UtilityEvents,
        description:
            "Commonly used Events for modifying Accounts, Events, Importing, or adding Decisions into your Scenarios",
        activeNodes: [
            {
                type: decisionObject.constant(),
                name: decisionObject.name(),
                pro: false,
            },
            {
                type: importObject.constant(),
                name: importObject.name(),
                pro: true,
            },
            {
                type: accountExpressionObject.constant(),
                name: accountExpressionObject.name(),
                pro: false,
            },
            {
                type: accountModifierObject.constant(),
                name: accountModifierObject.name(),
                pro: false,
            },
            {
                type: modifierObject.constant(),
                name: modifierObject.name(),
                pro: false,
            },
            {
                type: growthObject.constant(),
                name: growthObject.name(),
                pro: false,
            },
            {
                type: constraintObject.constant(),
                name: constraintObject.name(),
                pro: false,
            },
            {
                type: nullObject.constant(),
                name: nullObject.name(),
                pro: false,
            },
            {
                type: goalObject.constant(),
                name: goalObject.name(),
                pro: false,
            },
            {
                type: groupObject.constant(),
                name: groupObject.name(),
                pro: false,
            },
        ],
        inProgressNodes: [
            {
                type: kpiObject.constant(),
                name: kpiObject.name(),
                pro: false,
            },
            {
                type: projectObject.constant(),
                name: projectObject.name(),
                pro: false,
            },
        ],
        deprecatedNodes: [],
        isOpen: false,
        svg: bankingImg,
    },
];

export const supportedLibraryEvents = [
    { type: budgetObject.constant() },
    { type: expenseObject.constant(), name: expenseObject.name() },
    { type: houseObject.constant() },
    { type: goalObject.constant(), name: goalObject.name() },
    { type: loanObject.constant(), name: loanObject.name() },
    { type: personObject.constant() },
    { type: rentObject.constant() },
];

//auth types
export const SIGN_UP = "signup";
export const LOG_IN = "login";

//account types
export const INDIVIDUAL = "personal";
export const BUSINESS = "business";
export const AGENCY = "agency";
export const STUDENT = "student";
export const GUEST = "guest";

export const freePlan = {
    scenario_count: 5,
    events_count: 60,
    share_scenario_count: 2,
    pro_events_count: 0,
    name: "Free Plan",
    billingString: "No Cost - Ever",
};

export const adminPlanIds = ["secret_rockstar_01", "agency_rockstar_01"];

export const InputErrors = {
    NumberBelowZero: "Value must be greater than zero.",
    NumberAboveLimit: "Value must be less than ",
    NotEmpty: "Value must not be empty",
    NotZero: "Value must not be zero",
};

export const topLevelAccountIds = {
    "211dd944-359f-4fbe-af1f-0c761afa1e67": true, // Assets
    "6c9640aa-abf5-4f19-b135-356e183bcce6": true, // Liabilities
    "488dd61d-8697-4213-8978-cf91755365a4": true, // Income
    "7faf0285-78ca-411b-b875-d900929d7c94": true, // Expenses
    "001a1288-cefb-4bcb-97ce-510b8a668a19": true, // Equity
    "5a508f09-cf61-4207-8950-28a32be3a0d8": true, // Display Accounts
    "df3f629d-dfa9-40e9-b083-58119d82ec4b": true, // Cashflow
    "2b5b218c-345b-48f9-8b4f-2992978acfd5": true, // ARPU
    "4597dc96-dff1-48e3-b992-89ecf8476274": true, // Segment Accounts
    "db178274-8d95-4a6a-ae5c-46ae2fc356dd": true, // Website Visitor
    "b2623ec6-3492-4fa0-9dcd-0150c3443cb0": true, // Customer
    "e18faebd-5cb1-40ac-8c18-442943677d4a": true, // Employees
    "852b5a0b-2a32-4817-867c-cca92295141b": true, // Employees Needed
    "8175db4a-66e9-4cd6-866b-b8ae06bddd74": true, // Capacity
    "8762235d-676a-4ef5-be5e-9c5a27b301da": true, // Employees Utilized
    "3ebf3eb4-6cdf-4889-8796-435cb9bf0939": true, // Employees Adjusted
};

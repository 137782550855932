import React from "react";

import AllocationCard from "../Card/allocationCard";

import AllocationConnectionImage from "Assets/_optionDescriptionIcons/Segment, Allocation white.jpg";

import allocationSvg from "../../Assets/_eventNodeTypes/allocationSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import AllocationInputExperimental from "Components/InputContainer/AllocationInputExperimental/AllocationInputExperimental";

export const allocationObject = {
    constant: function () {
        return Allocation_Constant();
    },

    name: function () {
        return "Allocation";
    },

    checkInput: function (state) {
        return Allocation_CheckInput(state);
    },

    svg: function () {
        return Allocation_Svg();
    },

    eventNodeMenu: function (focus) {
        return Allocation_EventNodeMenu(focus);
    },

    indexText: function () {
        return Allocation_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Allocation_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Allocation_OptionDescription();
    },

    colouredPngs: function () {
        return Allocation_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Allocation_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Allocation_GenerateDisplayFieldData();
    },
};

// Helper functions for Event

const Allocation_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.segmentEventId) return false;
        if (!entity.data.segments.length) return false;

        for (const segment of entity.data.segments) {
            if (!segment.allocation) return false;
            if (segment.name === "") return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

const Allocation_Constant = () => {
    return "a87c9f53-a2e7-41eb-9969-1460fcff2b8d";
};

const Allocation_Svg = () => {
    return allocationSvg;
};

const Allocation_EventNodeMenu = (focus) => {
    return <AllocationCard data={focus} />;
};

const Allocation_IndexText = () => {
    return "Allocation no copy text";
};

const Allocation_Icons = () => {
    const pngs = new Map([
        ["black", allocationSvg],
        ["blue", allocationSvg],
        ["white", allocationSvg],
        ["grey", allocationSvg],
    ]);
    return pngs;
};

const Allocation_InputComponentInfoPassing = (minProps) => {
    return <AllocationInputExperimental {...minProps} />;
};

const Allocation_OptionDescription = () => {
    const tags = ["Option"];
    const type = allocationObject.name();
    const description = "Allocation no copy text";
    const usedForText = "Allocation no copy text";
    const connection = "";
    const connectionImage = AllocationConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/r/revenue.asp#:~:text=Revenue%20is%20the%20income%20generated,subtracted%20to%20determine%20net%20income.";
    const templates = [];
    const descMap = new Map<string, any>([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Allocation_SideModalImage = () => {
    return sideImage;
};

const Allocation_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import EmployeeCard from "../Card/employeeCard";
import EmployeeInput from "../InputContainer/EmployeeInput";

// Import Connection Image (if exists)
import EmployeeConnectionImage from "Assets/_optionDescriptionIcons/Salesperson, Employee, Outbound Sales white .jpg";

// Import Coloured PNGs
import employeeBlack from "../../Assets/_eventNodeTypes/employee-black-updated.svg";
import employeeBlue from "../../Assets/_eventNodeTypes/employee-blue.png";
import employeeWhite from "../../Assets/_eventNodeTypes/employee-white.png";
import employeeGrey from "../../Assets/_eventNodeTypes/employee-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/employeeAndStaffing.png";

import { employeeInputsHandler } from "Components/InputContainer/OnInputChangeHandlers/employeeInputsHandler";

// Notes
// To call an object with no parameters, can simply use
//  - employeeObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - employeeObject.checkInput.call(employeeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const employeeObject = {
    constant: function () {
        return Employee_Constant();
    },

    name: function () {
        return "Employee";
    },

    checkInput: function (state) {
        return Employee_CheckInput(state);
    },

    svg: function () {
        return Employee_Svg();
    },

    eventNodeMenu: function (focus) {
        return Employee_EventNodeMenu(focus);
    },

    indexText: function () {
        return Employee_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Employee_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Employee_OptionDescription();
    },

    colouredPngs: function () {
        return Employee_Icons();
    },

    version: function () {
        return "1.0.1";
    },

    sideModal: function () {
        return Employee_SideModalImage();
    },

    inputsHandler: function (
        value,
        id,
        star,
        entitiesMap,
        currentEntity,
        account
    ) {
        return employeeInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            account
        );
    },

    generateDisplayFieldData: function () {
        return Employee_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Employee_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (entity.data.employeeState === "specific")
            if (!entity.data.accountName) return false;
        if (entity.data.employeeState === "placeholder")
            if (!entity.data.noEmployees) return false;
        if (!entity.data.expenseType) return false;
        if (!entity.name) return false;
        if (!entity.data.address) return false;
        if (entity.data.payType === "hourly") {
            if (!entity.data.rate) return false;
            if (!entity.data.averageHours) return false;
        } else {
            if (!entity.data.salary) return false;
        }
        if (!entity.cadence) return false;
        if (!entity.startDate || entity.startDate === "Invalid date")
            return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Employee_Constant = () => {
    return "89d1bc58-96ec-4dba-a256-55541dabb4dc";
};

// EFFECTS: Returns black png / svg for event
const Employee_Svg = () => {
    return employeeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Employee_EventNodeMenu = (focus) => {
    return <EmployeeCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Employee_IndexText = () => {
    return "The Employee Event can be used to model the expenses generated from a specific employee. Overtime, Employment Insurance, and Pension calculations are currently only supported for Canadian addresses 🇨🇦.";
};

// EFFECTS: Returns all icons for event ??
const Employee_Icons = () => {
    let pngs = new Map([
        ["black", employeeBlack],
        ["blue", employeeBlue],
        ["white", employeeWhite],
        ["grey", employeeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Employee_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Employee_InputComponentInfoPassing = (minProps) => {
    return <EmployeeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, employees for event ??
const Employee_OptionDescription = () => {
    const tags = ["Option"];
    const type = employeeObject.name();
    const description =
        "Wages, payroll and contractors can be the primary expense for a business. The Employee Event supports a range of calculations from overtime, hourly vs salary, individual and group calculations, and in some regions, advanced payroll calculations like Employment Insurance and Pension contributions.";
    const usedForText =
        "Use the Employee Event to model current staffing, plan for future hires, or build placeholder Scenarios with entire segments of Employee types and roles.";
    const connection = "";
    const connectionImage = EmployeeConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/p/payroll.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Employee_SideModalImage = () => {
    return sideImage;
};

const Employee_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: true,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        endDate: {
            displayName: "End Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        cadence: {
            displayName: "Payment Frequency",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: [
                "annually",
                "quarterly",
                "monthly",
                "semi-monthly",
                "bi-weekly",
                "weekly",
                "daily",
                "one-time",
            ],
        },
        bypassState: {
            displayName: "Bypassed",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
    };

    const entityDataFieldsMap = {
        employeeState: {
            displayName: "Employee Type",
            editable: false,
            conditionalTarget: true,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["specific", "placeholder"],
        },
        expenseType: {
            displayName: "Expense Type",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: [
                "Employment Expenses",
                "Cost of Labor",
            ],
        },
        accountName: {
            displayName: "Employee Account",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
            cellRendererWaterfallTopLevelFilters: {
                "e18faebd-5cb1-40ac-8c18-442943677d4a": true,
            },
        },
        accountIds: {
            hide: true,
        },
        payType: {
            displayName: "Payment Type",
            editable: false,
            conditionalTarget: true,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["hourly", "salary"],
        },
        rate: {
            displayName: "Hourly Rate",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "payType",
            conditionalFieldConditionValue: "hourly",
        },
        salary: {
            displayName: "Annual Salary",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "payType",
            conditionalFieldConditionValue: "salary",
        },
        averageHours: {
            displayName: "Average Weekly Hours",
            editable: false,
            conditionalFieldName: "payType",
            conditionalFieldConditionValue: "hourly",
        },
        noEmployees: {
            displayName: "Number of Employees",
            editable: false,
            conditionalFieldName: "employeeState",
            conditionalFieldConditionValue: "placeholder",
        },
    };

    return { entityFieldsMap, entityDataFieldsMap };
};

import React, { Component } from "react";
import "./Card.css";
import { getRelevantEntities } from "actions/getNodeEntityActions";

class KpiCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const entity = Object.values(
            getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            )
        )[0];

        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Name:</div>
                    <div className="cardValue">{entity.name ?? ""}</div>
                </div>
            </div>
        );
    }
}

export default KpiCard;

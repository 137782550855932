import React, { Component } from "react";
import "./InputContainer.css";
import { throwError } from "../../helpers/swalError";
import getGeolocation from "countrycitystatejson";
import radioButtonOff from "../../Assets/_nodeInputs/radioButtonOff.svg";
import radioButtonOn from "../../Assets/_nodeInputs/radioButtonOn.svg";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import _ from "lodash";

import {
    InputButtons,
    SelectDropDown,
    Description,
    Rate,
    EventInputs,
    EventInputModal,
    EventHeader,
    ModalRow,
    ToggledContainerHalf,
    ToggledContainer,
    RequiredStar,
    ModalRowTwoThirds,
    ModalRowOneThird,
    ModalRowHalf,
} from "./Components";
// import Switch from "react-switch";
import { Person, Me } from "../../helpers/constants";

import { getDefaultName } from "../../helpers";
import { getPresentableDependenciesOfManyTypes } from "../../helpers/nodeDependencyDetectionHelpers";
import { pensionObject } from "Components/Registry/Pension";

class PensionInput extends Component {
    constructor(props) {
        super(props);

        let people = {};
        people = this.getPeople();

        this.state = {
            name: getDefaultName(pensionObject.constant(), this.props),
            type: pensionObject.constant(),
            applyInflation: false,
            value: "",
            tag: `@${pensionObject.constant()}`,
            rating: 0,
            inflation: false,
            baseline: false,
            description: "",
            pensionCalculationType: "estimates",
            contributionType: null,
            startAge: null,
            pensionableRate: null,
            cppRate: null,
            qppRate: null,
            actualsData: null,
            showSettings: false,
            showSettingsData: [{ id: 0, title: "", start: "", end: "" }],
            personId: null,
            person: null,
            country: null,
            state: null,
            birthYear: new Date().getFullYear(),
            birthMonth: null,
            people,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            const people = this.getPeople();
            const countries = getGeolocation.getCountries();
            this.setState({ ...this.props.editData, people, countries });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "contributoryTime:":
                data = {
                    title: "Has a Disability",
                    content:
                        "According to government, people with disabilties can be eligible for the disability tax credit. We’ll optimize your tax caculation if the person has one or more disabilities.",
                    subContent: "",
                    link: null,
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    onChangeInput = (e, id, mainId) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "person":
                this.updatePerson(value);
                break;
            case "startAge":
                this.setState({ startAge: value });
                break;
            case "pensionableRate":
                this.setState({ pensionableRate: value });
                break;
            case "cppRate":
                this.setState({ cppRate: value });
                break;
            case "qppRate":
                this.setState({ qppRate: value });
                break;
            case "month":
                this.onChangeContributoryTitle(e, mainId, "start");
                break;
            case "year":
                this.onChangeContributoryTitle(e, mainId, "end");
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (pensionObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    toggleInflation = () => {
        this.setState({ applyInflation: !this.state.applyInflation });
    };

    onHandleDate = (value, type) => {
        if (type === "start") {
            this.setState({ birthday: value });
        }
    };

    toggleDisability = () => {
        this.setState({ hasDisability: !this.state.hasDisability });
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "contributoryTime":
                data = {
                    title: "Non-Contributory Time:",
                    content: `Add here for the period of time when if you are working outside Canada, unemployment, etc, and not contributing to either CPP or QPP.

We’ll make sure to take that into account when calculating.`,
                    subContent: "",
                    link: null,
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    getGenericData = (startYear, thisYear, age) => {
        const data = [];
        for (let x = startYear; x <= thisYear; x++) {
            data.push({ year: startYear, age, actualIncome: null });
            age++;
            startYear++;
        }
        return data;
    };
    handlePensionToggle = (e) => {
        const id = e.target.id;
        if (id === "actuals") {
            let thisYear = new Date().getFullYear();
            let birthYear = Number(this.state.birthYear) + 18;
            let age = 18;
            const data = this.getGenericData(birthYear, thisYear, age);
            this.setState({ pensionCalculationType: id, actualsData: data });
        } else {
            this.setState({ pensionCalculationType: id });
        }
    };

    handleContribution = (id) => {
        this.setState({ contributionType: id });
    };

    handleActualIncome = (e, i, propsData) => {
        e.preventDefault();
        const value = e.target.value;
        const { actualsData } = this.state;
        const editedData = actualsData.map((data) => {
            if (data.year === propsData.year) {
                const newData = { ...data, actualIncome: value };
                return newData;
            }
            return data;
        });
        this.setState({ actualsData: editedData });
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onChangeContributoryTitle = (e, id, title) => {
        const { showSettingsData } = this.state;
        const value = e.target.value;
        const newData = showSettingsData.map((settingsData) => {
            if (settingsData.id === id) {
                const newTitle = { ...settingsData, [`${title}`]: value };
                return newTitle;
            }
            return settingsData;
        });
        this.setState({ showSettingsData: newData });
    };

    handeAddContribution = () => {
        const { showSettingsData } = this.state;
        const newData = {
            id: showSettingsData.length,
            title: "",
            start: "",
            end: "",
        };
        this.setState({ showSettingsData: [...showSettingsData, newData] });
    };

    handeDeleteContribution = () => {
        const { showSettingsData } = this.state;
        const lastAdded = showSettingsData.length - 1;
        const filteredArray = showSettingsData.filter((data) => {
            return data.id !== lastAdded;
        });

        this.setState({ showSettingsData: filteredArray });
    };

    getPeople = () => {
        const people = {};

        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            this.props,
            "Person"
        );

        return people;
    };

    updatePerson = (person) => {
        if (!person) {
            this.setState({
                person,
                personId: null,
            });
        } else {
            const { loadedScenario, baselineDataManager } = this.props;
            const personId = this.state.people[person];
            let selfNode;
            if (personId === "me") {
                selfNode = loadedScenario.data.nodes.find((node) => {
                    return node.metadata.type === Me;
                });
            } else {
                selfNode = loadedScenario.data.nodes.find(
                    (node) => node.id === personId
                );
            }
            if (!selfNode && baselineDataManager.hasActiveBaseline()) {
                const baselineNodes =
                    baselineDataManager.getActiveBaseline().data.nodes;
                selfNode = baselineNodes.find(
                    (node) => "baseline-" + node.id === personId
                );
            }
            const birthMonth = selfNode.metadata.birthMonth;
            const birthYear = selfNode.metadata.birthYear;
            const data = this.getGenericData(
                Number(birthYear) + 18,
                new Date().getFullYear(),
                18
            );

            this.setState({
                person,
                personId,
                birthMonth,
                birthYear,
                actualsData: data,
            });
        }
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        const passedCheck = pensionObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            name,
            person,
            description,
            pensionCalculationType,
            contributionType,
            startAge,
            pensionableRate,
            actualsData,
            showSettings,
            showSettingsData,
            people,
        } = this.state;

        const isCpp = contributionType === "CPP";
        const isQpp = contributionType === "QPP";

        let maxOffset = 100;
        let thisYear = new Date().getFullYear();
        let allYears = [];
        for (let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x);
        }

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={pensionObject.constant()}
                        image={pensionObject.svg()}
                        onChangeInput={this.onChangeInput}
                    />
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={person}
                            options={Object.keys(people) || []}
                            className="termsContainer"
                            id="person"
                            label="Person"
                        />
                    </ModalRow>
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <div className="PTTLabel">Contribution Type:</div>
                    </ModalRow>
                    <ModalRow>
                        <ModalRowHalf>
                            <img
                                alt="alt"
                                onClick={() => this.handleContribution("CPP")}
                                src={isCpp ? radioButtonOn : radioButtonOff}
                                className="checkBox"
                            />
                            CPP
                        </ModalRowHalf>
                        <ModalRowHalf>
                            <img
                                alt="alt"
                                onClick={() => this.handleContribution("QPP")}
                                src={isQpp ? radioButtonOn : radioButtonOff}
                                className="checkBox"
                            />
                            QPP
                        </ModalRowHalf>
                    </ModalRow>
                    <ToggledContainer>
                        <ToggledContainerHalf
                            id="estimates"
                            isSelected={pensionCalculationType === "estimates"}
                            onClick={this.handlePensionToggle}
                            title="Estimates to Date"
                        />
                        <ToggledContainerHalf
                            id="actuals"
                            isSelected={pensionCalculationType === "actuals"}
                            onClick={this.handlePensionToggle}
                            title="Actuals to Date"
                        />
                    </ToggledContainer>
                    {pensionCalculationType === "estimates" && (
                        <div>
                            <ModalRow twoInputs>
                                <ModalRowTwoThirds>
                                    <div className="pensionText">
                                        Contribution Start Age
                                    </div>
                                </ModalRowTwoThirds>
                                <ModalRowOneThird>
                                    <Rate
                                        label="Age"
                                        onChangeInput={this.onChangeInput}
                                        value={startAge}
                                        id="startAge"
                                        sign={false}
                                        className="pensionRate"
                                    />
                                </ModalRowOneThird>
                            </ModalRow>
                            <ModalRow twoInputs>
                                <ModalRowTwoThirds>
                                    <div className="pensionText">
                                        Estimated % of max. Pensionable Amount
                                    </div>
                                </ModalRowTwoThirds>

                                <ModalRowOneThird>
                                    <Rate
                                        label="Pensionable Rate"
                                        onChangeInput={this.onChangeInput}
                                        value={pensionableRate}
                                        id="pensionableRate"
                                        sign={true}
                                        className="pensionRate"
                                    />
                                </ModalRowOneThird>
                            </ModalRow>
                        </div>
                    )}

                    {pensionCalculationType === "actuals" && (
                        <div>
                            <div className="tableHeader">
                                <div>Year</div>
                                <div>Age</div>
                                <div>Actual Contribution</div>
                            </div>
                            {actualsData &&
                                actualsData.map((data, i) => {
                                    return (
                                        <div className="table" key={i}>
                                            <div>{data.year}</div>
                                            <div>{data.age}</div>
                                            <div>
                                                $
                                                <input
                                                    type="number"
                                                    placeholder="CAD.."
                                                    className="tableInput2"
                                                    value={data.actualIncome}
                                                    onChange={(e) =>
                                                        this.handleActualIncome(
                                                            e,
                                                            i,
                                                            data
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}

                    <ModalRow>
                        <ModalRowHalf onClick={this.onHandleAdvanceSetting}>
                            <img
                                alt="alt"
                                className="selectedIcon"
                                src={showSettings ? dropdownSvg : expandSvg}
                            />
                            <div className="advanceSettingLabel">
                                Advance settings
                            </div>
                        </ModalRowHalf>
                    </ModalRow>
                    {showSettings && (
                        <div>
                            <ModalRow
                                onMouseEnter={() =>
                                    this.onHandleMouseEnter("contributoryTime")
                                }
                            >
                                <div className="PTTLabel">
                                    Non-Contributory Time:
                                </div>
                            </ModalRow>
                            {showSettingsData &&
                                showSettingsData.map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <div>
                                                {i + 1}.{" "}
                                                <input
                                                    onChange={(e) =>
                                                        this.onChangeContributoryTitle(
                                                            e,
                                                            data.id,
                                                            "title"
                                                        )
                                                    }
                                                    className="contriburyInput"
                                                    value={data.title}
                                                />
                                            </div>
                                            <ModalRow twoInputs>
                                                <ModalRowHalf>
                                                    <SelectDropDown
                                                        onChangeInput={
                                                            this.onChangeInput
                                                        }
                                                        value={data.start}
                                                        options={allYears}
                                                        className="termsContainer"
                                                        mainId={data.id}
                                                        label="Start Date"
                                                        id="month"
                                                    />
                                                </ModalRowHalf>
                                                <ModalRowHalf>
                                                    <SelectDropDown
                                                        onChangeInput={
                                                            this.onChangeInput
                                                        }
                                                        value={data.end}
                                                        options={allYears}
                                                        className="termsContainer"
                                                        mainId={data.id}
                                                        label="End Date"
                                                        id="year"
                                                    />
                                                </ModalRowHalf>
                                            </ModalRow>
                                        </div>
                                    );
                                })}
                            <ModalRow>
                                <div
                                    onClick={this.handeAddContribution}
                                    className="textButton"
                                >
                                    Add Another
                                </div>
                                {showSettingsData.length > 1 && (
                                    <div
                                        onClick={this.handeDeleteContribution}
                                        className="addButton"
                                    >
                                        Delete Recently added
                                    </div>
                                )}
                            </ModalRow>
                        </div>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default PensionInput;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    SelectDropDown,
    Rate,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    SingleEntity,
    ToggledContainer,
    ToggledContainerHalf,
    EntityName,
    SelectEntities,
    ModalRowComponent,
    RadioButton,
    PlusButton,
} from "../Components";
import Switch from "react-switch";
import Calendar from "../../Calendar";
import { customerTransfer2Object } from "Components/Registry/CustomerTransfer2";
import { RateSegment } from "../GrowthInputExperimental/GrowthInputView";
import { groupObject } from "Components/Registry/Group";
import { getEvent } from "actions/getNodeEntityActions";

const modifierTypes = ["Percent change", "Add to value"];

export default function CustomerTransfer2InputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    eventData,
    onChangeNameDescription,
    onHandleDate,
    passedCheck,
    onHandleSubmitValues,
    onHandleSubmit,
    edit,
    handleEntityStateChange,
    handleOnChange,
    onChangeValue,
    addNewSegment,
    deleteSegment,
}) {
    const isGroup = (id) => {
        const node = id ? getEvent(id) : null;
        return node ? node.type === groupObject.constant() : null;
    };

    const entityData = entitiesMap?.[currentEntity]?.data;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={customerTransfer2Object.name()}
                    onChangeInput={onChangeNameDescription}
                    image={customerTransfer2Object.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRow>
                        <Switch
                            id="customEffectPeriod"
                            checked={entityData.customEffectPeriod}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={() =>
                                onChangeValue(
                                    !entityData.customEffectPeriod,
                                    "customEffectPeriod"
                                )
                            }
                        />
                        <div className="InflationText">
                            Custom effect period
                        </div>
                    </ModalRow>

                    <ModalRow>
                        <Switch
                            id="revertOnEnd"
                            checked={entityData.revertOnEnd}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={() =>
                                onChangeValue(
                                    !entityData.revertOnEnd,
                                    "revertOnEnd"
                                )
                            }
                        />
                        <div className="InflationText">
                            Revert value on end date
                        </div>
                    </ModalRow>
                </ModalRow>

                {entityData.customEffectPeriod && (
                    <ModalRow>
                        <RequiredStar />
                        <Calendar
                            onHandleDate={onHandleDate}
                            startDate={entitiesMap?.[currentEntity]?.startDate}
                            endDate={entitiesMap?.[currentEntity]?.endDate}
                            cadence={entitiesMap?.[currentEntity]?.cadence}
                            entityIndex={entityIndex}
                        />
                    </ModalRow>
                )}

                <ModalRow>
                    <ModalRowHalf>
                        <div className="HeaderText">Source Entity</div>
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <div className="HeaderText ">Target Customer</div>
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.dependencies
                                    ?.sourceEntity
                            }
                            nodes={Object.values({
                                ...entityData.customers,
                                ...entityData.websiteVisitors,
                            })}
                            className="IncomeSelect"
                            id="sourceEntity"
                            label="Select Customer"
                            groupEventType={
                                isGroup(entityData.selectedEventId) && "All"
                            }
                        />
                    </ModalRowHalf>

                    <ModalRowHalf>
                        <RequiredStar />
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.dependencies
                                    ?.targetCustomer
                            }
                            nodes={Object.values(entityData.customers)}
                            className="IncomeSelect"
                            id="targetCustomer"
                            label="Select Customer"
                            groupEventType={
                                isGroup(entityData.selectedEventId) && "All"
                            }
                        />
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow>
                    <RequiredStar />
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entityData.modType}
                        options={modifierTypes}
                        className="select-dropdown"
                        id="modType"
                        label="Modifier Type"
                    />
                </ModalRow>

                <ToggledContainer>
                    <ToggledContainerHalf
                        id="constant"
                        isSelected={entityData.transferType == "constant"}
                        onClick={(_, id) => onChangeValue(id, "transferType")}
                        title="Constant Transfer"
                    />
                    <ToggledContainerHalf
                        id="dynamic"
                        isSelected={entityData.transferType == "dynamic"}
                        onClick={(_, id) => onChangeValue(id, "transferType")}
                        title="Dynamic Transfer"
                    />
                </ToggledContainer>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        {entityData.transferType == "constant" && (
                            <>
                                <RequiredStar />
                                <Rate
                                    label="Monthly Transfer Rate"
                                    onChangeInput={handleOnChange}
                                    value={entityData.constantTransferRate}
                                    id="constantTransferRate"
                                    className="mlsInput"
                                    sign={true}
                                />
                            </>
                        )}

                        {entityData.transferType == "dynamic" && (
                            <Rate
                                label="Max Transfer"
                                onChangeInput={handleOnChange}
                                value={entityData.maxTransferRate}
                                id="maxTransferRate"
                                className="mlsInput"
                                sign={true}
                            />
                        )}
                    </ModalRowHalf>
                </ModalRow>
                {entityData.transferType == "dynamic" && (
                    <>
                        <ModalRow>
                            <div className="entity-section-label">
                                Date/Transfer Rate Inputs
                            </div>
                        </ModalRow>
                        <ModalRow>
                            <ModalRowComponent>
                                <RadioButton
                                    onClick={onChangeValue}
                                    isActive={entityData.interpolate}
                                    label="Interpolate Transfer Rate"
                                    value={true}
                                    id="interpolate"
                                />
                            </ModalRowComponent>
                            <ModalRowComponent>
                                <RadioButton
                                    onClick={onChangeValue}
                                    isActive={!entityData.interpolate}
                                    label="Stair Step Transfer Rate"
                                    value={false}
                                    id="interpolate"
                                />
                            </ModalRowComponent>
                        </ModalRow>
                        {entityData.transferSegments.map((segment, i) => {
                            return (
                                <RateSegment
                                    key={i}
                                    startDate={segment.startDate}
                                    rate={segment.rateDisplay}
                                    period={segment.period}
                                    index={i}
                                    handleOnChange={handleOnChange}
                                    onChangeValue={onChangeValue}
                                    deleteSegment={deleteSegment}
                                    entityIndex={entityIndex}
                                    type="transfer"
                                />
                            );
                        })}
                        <ModalRow>
                            <PlusButton
                                onClick={addNewSegment}
                                label="Add New Transfer Rate Segment"
                            />
                        </ModalRow>
                    </>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmitValues}
                onHandleSubmitValues={onHandleSubmitValues}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

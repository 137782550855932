import React from "react";
import styles from "./index.module.css";

export default class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sharedScenario: { email: false, pushNotification: true },
            watchList: { email: false, pushNotification: false },
        };
    }

    handleSharedScenario = (id) => {
        if (id === "email") {
            this.setState({
                sharedScenario: {
                    ...this.state.sharedScenario,
                    email: !this.state.sharedScenario.email,
                },
            });
        } else {
            this.setState({
                sharedScenario: {
                    ...this.state.sharedScenario,
                    pushNotification: !this.state.sharedScenario
                        .pushNotification,
                },
            });
        }
    };

    onHandleWatchList = (id) => {
        if (id === "email") {
            this.setState({
                watchList: {
                    ...this.state.watchList,
                    email: !this.state.watchList.email,
                },
            });
        } else {
            this.setState({
                watchList: {
                    ...this.state.watchList,
                    pushNotification: !this.state.watchList.pushNotification,
                },
            });
        }
    };

    render() {
        return (
            <div className={styles.settingContainer}>
                <div className={styles.leftSetting}>
                    <div className={styles.headerOne}>Settings</div>
                    <div className={styles.subHeader}>
                        Notification Settings Coming soon!
                    </div>
                    {/* <div className={styles.containers}>
                        <div className={styles.headerTwo}>Shared Scenarios</div>
                        <div className={styles.plainText}>
                            All scenarios you own or shared with others
                        </div>
                        <div className={styles.checkboxContainerRoot}>
                            <div className={styles.checkboxContainer}>
                                <img
                                    src={
                                        sharedScenario.email
                                            ? checkboxOn
                                            : checkboxOff
                                    }
                                    onClick={() =>
                                        this.handleSharedScenario("email")
                                    }
                                />
                                <div className={styles.text}>Email</div>
                            </div>
                            <div className={styles.checkboxContainer}>
                                <img
                                    src={
                                        sharedScenario.pushNotification
                                            ? checkboxOn
                                            : checkboxOff
                                    }
                                    onClick={() =>
                                        this.handleSharedScenario(
                                            "Push Notification"
                                        )
                                    }
                                />
                                <div className={styles.text}>
                                    Push Notification
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.containers}>
                        <div className={styles.headerTwo}>Watched List</div>
                        <div className={styles.plainText}>
                            The scenarios that you're watching
                        </div>
                        <div className={styles.checkboxContainerRoot}>
                            <div className={styles.checkboxContainer}>
                                <img
                                    src={
                                        watchList.email
                                            ? checkboxOn
                                            : checkboxOff
                                    }
                                    onClick={() =>
                                        this.onHandleWatchList("email")
                                    }
                                />
                                <div className={styles.text}>Email</div>
                            </div>
                            <div className={styles.checkboxContainer}>
                                <img
                                    src={
                                        watchList.pushNotification
                                            ? checkboxOn
                                            : checkboxOff
                                    }
                                    onClick={() =>
                                        this.onHandleWatchList(
                                            "Push Notification"
                                        )
                                    }
                                />
                                <div className={styles.text}>
                                    Push Notification
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className={styles.rightSetting}>
                    <div className={styles.headerTwo}>
                        Default Notification Email
                    </div>

                    <div className={styles.email}>
                        {loggedInUser && loggedInUser.email}
                    </div>

                    <div className={styles.containers}>
                        <div className={styles.headerTwo}>
                            Email Notifications
                        </div>
                        <div className={styles.plainText}>
                            Get Email notifications when there's an update
                        </div>
                        <div className={styles.checkboxColumnContainer}>
                            <div className={styles.checkboxColumn}>
                                <img src={checkboxOn} />
                                <div className={styles.text}>
                                    New comments on scenarios
                                </div>
                            </div>
                            <div className={styles.checkboxColumn}>
                                <img src={checkboxOn} />
                                <div className={styles.text}>
                                    New changes on scenarios (when an option is
                                    added or deleted)
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.containers}>
                        <div className={styles.headerTwo}>
                            Push Notifications
                        </div>
                        <div className={styles.plainText}>
                            Receive in-product push notifications when using
                            whatifi when there's an update
                        </div>
                        <div className={styles.checkboxColumnContainer}>
                            <div className={styles.checkboxColumn}>
                                <img src={checkboxOn} />
                                <div className={styles.text}>
                                    New comments on scenarios
                                </div>
                            </div>
                            <div className={styles.checkboxColumn}>
                                <img src={checkboxOn} />
                                <div className={styles.text}>
                                    New changes on scenarios (when an option is
                                    added or deleted)
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

import React from "react";
import styles from "./Options.module.css";
import { NodeTypesBlack } from "../../Events";
import { decisionObject } from "Components/Registry/Decision";

export const LibraryOptionsTab = ({
    nodes,
    addEventType,
    onHandleOptionSelected,
}) => {
    return (
        <div className={styles.optionNodeContainer}>
            <div className={styles.optionRootContainer}>
                {nodes.map((node) => {
                    const isDescriptionOpen =
                        node.type === "House" ||
                        node.type === "Mortgage" ||
                        node.type === "Person" ||
                        node.type === "Pension";

                    const isDecision = node.type === decisionObject.constant();

                    const typeImagesBlack = {};

                    NodeTypesBlack.forEach((data) => {
                        typeImagesBlack[data.type] = data.image;
                    });

                    const image = typeImagesBlack[node.type];

                    return (
                        <div
                            key={node.type}
                            className={styles.optionBoxWrap}
                            onClick={() =>
                                isDescriptionOpen
                                    ? onHandleOptionSelected(node.type)
                                    : addEventType(node.type)
                            }
                        >
                            <div
                                className={
                                    isDecision
                                        ? styles.decisionBox
                                        : styles.optionBox
                                }
                            >
                                <img className={styles.optionSvg} src={image} />
                                <div className={styles.optionName}>
                                    {node.type}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

import axios from "axios";
import { config } from "config";
import {
    EntitiesInputSchema,
    EntitiesSchema,
} from "reducers/typesSchema/entitiesSchema";
import { requestHeader } from "./scenario";

interface EntityProperty {
    name?: string;
    id?: string | number;
    version?: string;
    account?: string;
    type?: string;
    //Any additional fields shared by all entities that can be queried go here
}

export function queryEntitiesAPI(entityProperty: EntityProperty) {
    return axios.get(`${config.host}/entities/query`, {
        headers: requestHeader().headers,
        params: { name: entityProperty },
    });
}

export function createEntitiesAPI(newEntities: EntitiesSchema) {
    if (Object.keys(newEntities).length === 0)
        return Promise.resolve<string[]>([]);
    const reqHeader = requestHeader();
    const entities = Object.values(newEntities).map((entity) => ({
        ...entity,
        account: reqHeader.headers.account,
    }));
    return axios.post<string[]>(
        `${config.host}/entities`,
        { entities },
        reqHeader
    );
}

export function updateEntitiesAPI(entityChanges: EntitiesInputSchema) {
    if (Object.keys(entityChanges).length === 0)
        return Promise.resolve<string[]>([]);
    return axios.put(
        `${config.host}/entities`,
        { entities: entityChanges },
        requestHeader()
    );
}

export function deleteEntitiesAPI(toDelete: string[]) {
    if (toDelete.length === 0) return Promise.resolve<string[]>([]);
    return axios.delete(`${config.host}/entities`, {
        headers: requestHeader().headers,
        data: { entities: toDelete },
    });
}

export function submitEntitiesAPI(
    createObjs: EntitiesSchema,
    updateObjs: EntitiesSchema
    // deleteIds: string[]
) {
    return Promise.allSettled([
        createEntitiesAPI(createObjs),
        updateEntitiesAPI(updateObjs),
        // deleteEntitiesAPI(deleteIds),
    ]);
}

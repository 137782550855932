import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { Group } from "react-konva";
import { useContext, useMemo } from "react";
import { CanvasDashboardContext } from "../../contexts/CanvasDashboardContext";
import { CanvasAccountCardWrapper } from "./CanvasAccountCard";
import type { CanvasCardSchema } from "reducers/typesSchema/canvasCardSchema";
import { accountCardType } from "helpers/canvasCardTypeUUIDtoName";

export function CanvasAccountCards() {
    const isDashboard = useContext(CanvasDashboardContext);
    const allCardIds: string[] = useAppSelector(
        (state) => state?.scenario?.loadedScenario?.data?.canvasCardIds ?? [],
        shallowEqual
    );
    const canvasCards = useAppSelector(
        (state) => state?.canvasCards,
        shallowEqual
    );

    const relevantVisibleCardIds: string[] = useMemo(() => {
        const visibleCardIds: string[] = [];

        Object.values(allCardIds).map((cardId) => {
            const cardData: CanvasCardSchema = canvasCards?.[cardId];
            if (cardData?.cardType !== accountCardType) return;

            if (isDashboard && cardData.dashboardVisible) {
                visibleCardIds.push(cardId);
            } else if (!isDashboard && cardData?.scenarioCanvasVisible) {
                visibleCardIds.push(cardId);
            }
        });
        return visibleCardIds;
    }, [allCardIds, isDashboard, canvasCards]);

    return (
        <Group>
            {relevantVisibleCardIds?.map((cardId) => (
                <CanvasAccountCardWrapper key={cardId} cardId={cardId} />
            ))}
        </Group>
    );
}

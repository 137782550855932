import {
    EDIT_CHART_GRAPH_GROUP_NODES_MODE,
    RESET_CHART_GRAPH_GROUP_NODES_MODE,
} from "actions/chartGraphGroupNodesModeActions";
import { ChartGraphGroupNodesModeSchema } from "./typesSchema/chartGraphGroupNodesSchema";

const initialState: ChartGraphGroupNodesModeSchema = "default";

export default function chartGraphGroupNodesMode(
    state = initialState,
    action: { type: string; payload?: ChartGraphGroupNodesModeSchema }
) {
    switch (action.type) {
        case RESET_CHART_GRAPH_GROUP_NODES_MODE:
            return initialState;
        case EDIT_CHART_GRAPH_GROUP_NODES_MODE:
            return action.payload;
        default:
            return state;
    }
}

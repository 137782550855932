import "./CopyScenarioModal.css";
import "../EventsModal/EventsModal.css";
import closeSvg from "Assets/close.svg";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { setShowDuplicateScenarioModal } from "actions/modalActions";

export const CopyScenarioModal = () => {
    const showDuplicateScenarioModal = useAppSelector(
        (state) => state.scenario?.showDuplicateScenarioModal
    );
    return showDuplicateScenarioModal?.show ? (
        <div className="ModalContainer">
            <div className="copy-scenario-modal">
                <img
                    alt="alt"
                    src={closeSvg}
                    className="CloseModal"
                    onClick={() => {
                        setShowDuplicateScenarioModal({ show: false });
                    }}
                />
                <div className="copy-scenario-modal-title">
                    Duplicate Scenario Options
                </div>
                <div className="copy-scenario-modal-box">
                    <div className="copy-scenario-modal-option">
                        <div className="copy-scenario-modal-option-text">
                            The new Scenario will <b>NOT</b> be linked to the
                            same Events and Records as the source Scenario.
                            <br />
                            <br />
                            Any edits to this new Scenario will <b>NOT</b>{" "}
                            change any values in the source Scenario.
                        </div>
                    </div>
                    <div className="copy-scenario-modal-separator" />
                    <div className="copy-scenario-modal-option">
                        <div className="copy-scenario-modal-option-text">
                            The new Scenario will <b>LINK</b> to the same
                            Records as the source Scenario.
                            <br />
                            <br />
                            Any edits to common Records in this Scenario will
                            change values in the source Scenario.
                        </div>
                    </div>
                </div>
                <div className="copy-scenario-modal-box">
                    <div className="copy-scenario-modal-option">
                        <div
                            className="copy-scenario-modal-button"
                            onClick={() => {
                                if (showDuplicateScenarioModal?.callback)
                                    showDuplicateScenarioModal.callback(
                                        "duplicate"
                                    );
                                setShowDuplicateScenarioModal({ show: false });
                            }}
                        >
                            Create Duplicate Scenario
                        </div>
                        (recommended)
                    </div>
                    <div className="copy-scenario-modal-option">
                        <div
                            className="copy-scenario-modal-button"
                            onClick={() => {
                                if (showDuplicateScenarioModal?.callback)
                                    showDuplicateScenarioModal.callback("link");
                                setShowDuplicateScenarioModal({ show: false });
                            }}
                        >
                            Create Linked Scenario
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

import type { CanvasCardModalsSchema } from "./typesSchema/canvasCardModalsSchema";
import { SHOW_ACCOUNT_CARD_MODAL } from "actions/canvasCardModalsActions";

const initialState: CanvasCardModalsSchema = {
    createAccountCardModalOpen: false,
    cardId: null,
};

export default function canvasCardModals(
    state: CanvasCardModalsSchema = initialState,
    action: { type: string; payload: any }
): CanvasCardModalsSchema {
    switch (action.type) {
        case SHOW_ACCOUNT_CARD_MODAL:
            return {
                ...state,
                createAccountCardModalOpen:
                    action.payload.createAccountCardModalOpen,
                cardId: action.payload.cardId,
            };
        default:
            return state;
    }
}

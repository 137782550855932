// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    ModalRowHalf,
    SelectEntities,
    ToggledContainer,
    ToggledContainerHalf,
    SelectSegments,
    MuiCalendar,
} from "../Components";
import Switch from "react-switch";
import { revenueObject } from "Components/Registry/Revenue";
import { useState } from "react";

export default function RevenueInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    handleOnChange,
    handleDateSelection,
    handleOnInheritedDateChange,
    forceEntityChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    onHandleClickSubmitOrUpdate,
    toggleDelayRevenue,
}) {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    if (entitiesMap?.[currentEntity]?.data?.revenueSource === "") {
        forceEntityChange("customer", "revenueSource");
    }

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={revenueObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={revenueObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[revenueObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                {creatingCustomAccounts ? (
                    <div>Creating custom accounts...</div>
                ) : (
                    <>
                        <ModalRow>
                            <EntityName
                                name={entitiesMap?.[currentEntity]?.name}
                                onChangeInput={handleOnChange}
                                entityData={entitiesMap?.[currentEntity]}
                                handleEntityStateChange={
                                    handleEntityStateChange
                                }
                                required={true}
                            />
                        </ModalRow>
                        <ToggledContainer>
                            <ToggledContainerHalf
                                isSelected={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.revenueSource === "customer"
                                }
                                onClick={() =>
                                    forceEntityChange(
                                        "customer",
                                        "revenueSource"
                                    )
                                }
                                title="Customer"
                            />
                            <ToggledContainerHalf
                                isSelected={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.revenueSource === "segment"
                                }
                                onClick={() =>
                                    forceEntityChange(
                                        "segment",
                                        "revenueSource"
                                    )
                                }
                                title="Segment"
                            />
                        </ToggledContainer>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                {entitiesMap?.[currentEntity]?.data
                                    ?.revenueSource === "customer" && (
                                    <SelectEntities
                                        onChangeInput={handleOnChange}
                                        value={
                                            entitiesMap?.[currentEntity]?.data
                                                ?.selectedCustomer ?? {}
                                        }
                                        nodes={Object.values(
                                            entitiesMap[currentEntity]?.data
                                                ?.customers ?? []
                                        )}
                                        className="mlsInput"
                                        id="customer"
                                        label="Select Customer"
                                        showAll={false}
                                        required={true}
                                    />
                                )}
                                {entitiesMap?.[currentEntity]?.data
                                    ?.revenueSource === "segment" && (
                                    <SelectSegments
                                        onChangeInput={handleOnChange}
                                        value={
                                            entitiesMap?.[currentEntity]?.data
                                                ?.selectedSegment ?? {}
                                        }
                                        segments={Object.values(
                                            entitiesMap[currentEntity]?.data
                                                ?.segments ?? []
                                        )}
                                        className="mlsInput"
                                        id="segment"
                                        label="Select Segment"
                                        required={true}
                                    />
                                )}
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <SelectEntities
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedUnitCost ?? ""
                                    }
                                    nodes={Object.values(
                                        entitiesMap[currentEntity]?.data
                                            ?.unitCosts ?? []
                                    )}
                                    className="mlsInput"
                                    id="unitCost"
                                    label="Select Unit Cost"
                                    required={true}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        <ModalRow>
                            <Switch
                                id="overrideInheritedDate"
                                checked={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.overrideInheritedDate ?? false
                                }
                                className="InflationToggle"
                                height={20}
                                width={40}
                                onChange={handleOnInheritedDateChange}
                                disabled={false}
                            />
                            <div className="InflationText">
                                Override inherited start and end date
                            </div>
                        </ModalRow>
                        {entitiesMap?.[currentEntity]?.data
                            ?.overrideInheritedDate ? (
                            <ModalRow twoInputs>
                                <ModalRowHalf>
                                    <MuiCalendar
                                        id="startDate"
                                        value={
                                            entitiesMap?.[currentEntity]
                                                ?.startDate === ""
                                                ? null
                                                : entitiesMap?.[currentEntity]
                                                      ?.startDate
                                        }
                                        required={false}
                                        onChangeInput={handleDateSelection}
                                        label="Start Date"
                                    />
                                </ModalRowHalf>
                                <ModalRowHalf>
                                    <MuiCalendar
                                        id="endDate"
                                        value={
                                            entitiesMap?.[currentEntity]
                                                ?.endDate === ""
                                                ? null
                                                : entitiesMap?.[currentEntity]
                                                      ?.endDate
                                        }
                                        required={false}
                                        onChangeInput={handleDateSelection}
                                        label="End Date"
                                        helperText="An end date is optional"
                                    />
                                </ModalRowHalf>
                            </ModalRow>
                        ) : null}
                        <ModalRow>
                            <Switch
                                id="delayRevenue"
                                checked={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.delayRevenue ?? false
                                }
                                className="InflationToggle"
                                height={20}
                                width={40}
                                onChange={toggleDelayRevenue}
                                disabled={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.disableDelay
                                }
                            />
                            <div className="InflationText">Delay Revenue</div>
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmitValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmit={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                passedCheck={creatingCustomAccounts ? false : passedCheck}
            />
        </EventEntityWrapper>
    );
}

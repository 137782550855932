import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { instanceObject } from "Components/Registry/Instance";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";

//takes in an instance entity that's pointing at a group and returns an array of entity ids targeted by the group
export function formatGroupInstance(entity: EntitySchema) {
    const data = entity.data;
    const groupEntityId = data.selectedGroupEntity.entityIds[0];
    const groupEntity = getRelevantEntities([groupEntityId])[groupEntityId];
    const groupMode = groupEntity.data.groupMode;

    if (groupMode == "entities") {
        return groupEntity.data.selectedEntities;
    } else if (groupMode == "events") {
        const entities: string[] = [];

        groupEntity.data.selectedEvents.forEach((eventId) => {
            const event = getEvent(eventId);
            const eventEntities = event?.entities;
            eventEntities?.forEach((entityObj) => {
                entities.push(entityObj.id);
            });
        });

        return entities;
    }

    return [];
}

//takes in an instance entity that's pointing at a Project  and returns an array of entity ids targeted by the group
//returns an object with fields result (array of entity ids) and offsetMultipliers (map of entitiy ids to offset and multiplier data)
export function formatProjectInstance(entity: EntitySchema) {
    const data = entity.data;
    const projectEntityId = data.selectedGroupEntity.entityIds[0];
    const projectEntity = getRelevantEntities([projectEntityId])[
        projectEntityId
    ];

    const projectItems = projectEntity.data.items;

    const idList = Object.keys(projectItems);
    const metaData = new Map();

    for (const key in projectItems) {
        const monthConvert = projectItems[key].offsetMode == "days" ? 1 : 30;
        if (projectItems[key].offset && projectItems[key].offset >= 0) {
            metaData[projectItems[key].selectedItem] = {
                Offset: Number(projectItems[key].offset * monthConvert),
                Multiplier: Number(projectItems[key].multiplier ?? 1),
                Clip:
                    data.groupClipType == "Inherit"
                        ? Boolean(projectItems[key].clip ?? true)
                        : Boolean(data.groupClipType == "Clip"),
                Duration: Number(projectItems[key].duration * monthConvert),
            };
        }
    }

    return { idList, metaData };
}

export function getEntityIdsFromEventIds(eventId: string) {
    const result: string[] = [];

    const entities = getEvent(eventId)?.entities;

    entities?.forEach((entity) => result.push(entity.id));

    return result;
}

//takes list of entity ids and creates the structure for DE3
//meta data is an optional input that contains data for multipliers and offsets that are provided in a project event, same with clip
export function idsToExport(
    entityArray: string[],
    exportEntities,
    exportInstances,
    targetEntities,
    metaData = {},
    clip = null
) {
    let returnData: any[] = [];
    let clipType = clip != null ? clip === "Clip" : null;
    entityArray?.forEach((entityId) => {
        {
            clipType = clip === "Inherit" ? metaData[entityId]?.Clip : clipType;
            if (exportEntities[entityId] != null) {
                if (
                    exportEntities[entityId].type === instanceObject.constant()
                ) {
                    returnData = returnData.concat(
                        instanceInception(
                            exportEntities[entityId],
                            exportInstances,
                            exportEntities,
                            targetEntities
                        )
                    );
                } else {
                    returnData.push({
                        Data: exportEntities[entityId],
                        Offset: metaData[entityId]?.Offset ?? 0,
                        Multiplier: metaData[entityId]?.Multiplier ?? 1,
                        Clip: clipType ?? metaData[entityId]?.Clip ?? true,
                        Duration: metaData[entityId]?.Duration ?? -1,
                    });
                }
            }
        }
    });
    return returnData;
}

//takes in an instance object and returns a list of all entity ids it points to
//used when instancing an instance
function instanceInception(
    instanceData,
    exportInstances,
    exportEntities,
    _targetEntities
): any {
    const obj = getRelevantEntities([instanceData.id]);
    const entity = obj[Object.keys(obj)[0]];

    const newTargetEntities =
        entity.data.instanceType == "recordInstance"
            ? entity.data.selectedEntity
            : entity.data.selectedGroupEntity;

    return findIdsAndFormat(
        entity,
        exportInstances,
        exportEntities,
        newTargetEntities
    );
}

function getIds(entity, exportInstances, exportEntities, targetEntities) {
    let idList: string[] = [];
    let optionalInputs = new Map();
    //Instancing a Group Event
    if (entity.data.isGroup && entity.data.instanceType == "groupInstance") {
        idList = formatGroupInstance(entity);
        //Instancing a Project Event
    } else if (
        entity.data.isProject &&
        entity.data.instanceType == "groupInstance"
    ) {
        const proj = formatProjectInstance(entity);
        idList = proj.idList;
        optionalInputs = proj.metaData;
    } else {
        if (targetEntities.entityIds.length > 0) {
            //instancing a single record
            idList = targetEntities.entityIds;
        } else {
            //instancing an event
            idList = getEntityIdsFromEventIds(targetEntities.eventId);
        }
    }
    return { idList, optionalInputs };
}

//returns new export instances
export function formatData(
    entity,
    exportInstances,
    exportEntities,
    targetEntities
) {
    exportInstances[entity.id] = findIdsAndFormat(
        entity,
        exportInstances,
        exportEntities,
        targetEntities
    );
    return exportInstances;
}
function findIdsAndFormat(
    entity: any,
    exportInstances: any,
    exportEntities: any,
    targetEntities: any
) {
    const data = entity.data;
    const ids = getIds(entity, exportInstances, exportEntities, targetEntities);
    const clip =
        data.isGroup || data.isProject
            ? entity.data.groupClipType
            : entity.data.recordClipType;
    return idsToExport(
        ids.idList,
        exportEntities,
        exportInstances,
        targetEntities,
        ids.optionalInputs,
        clip
    );
}

// import type {ChangeEvent} from "react";
import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment"

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const websiteVisitorsInputsHandler = (
    value: string,
    id: "entityName" | "startDate" | "initialVisits",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate": 
            const startDateString = value ? moment(value).format("YYYY-MM-DD") : ""
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "initialVisits":
            data.value = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    
    return newEntitiesMap;
};

// @ts-nocheck

import {
    SingleEntity,
    Description,
    InputButtons,
    EventDetails,
    EventHeader,
    ModalRow,
    EntityName,
    EventEntityWrapper,
    BasicTextInput,
    PlusButton,
    DeleteButton,
    ModalRowHalf,
    SelectEntities,
    ModalRowComponent,
} from "../Components";
import { segmentObject } from "Components/Registry/Segment";
import { useState } from "react";

const Segment = ({
    index,
    handleOnChange,
    deleteSegment,
    segmentName,
    disabled,
}) => {
    return (
        <ModalRow>
            <div style={{ display: "flex", flex: 1, gap: "8px" }}>
                <ModalRowComponent width={60}>
                    <BasicTextInput
                        inputLabel="Segment Name"
                        onChangeInput={handleOnChange}
                        value={segmentName}
                        id="segmentName"
                        className="mlsInput"
                        mainId={index}
                        disabled={disabled}
                        required={true}
                    />
                </ModalRowComponent>
                {!disabled && (
                    <DeleteButton onClick={() => deleteSegment(index)} />
                )}
            </div>
        </ModalRow>
    );
};

export default function SegmentInputView({
    entitiesMap,
    currentEntity,
    onChangeNameDescription,
    handleOnChange,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    onHandleClickSubmitOrUpdate,
    addNewSegment,
    deleteSegment,
}) {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    const entityData = entitiesMap[currentEntity].data;
    const created = entitiesMap[currentEntity].data.created;

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={segmentObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={segmentObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                {creatingCustomAccounts ? (
                    <div>Creating custom accounts...</div>
                ) : (
                    <>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <SelectEntities
                                    onChangeInput={handleOnChange}
                                    showAll={false}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.selectedCustomer ?? {}
                                    }
                                    nodes={Object.values(
                                        entitiesMap?.[currentEntity]?.data
                                            ?.customers || []
                                    )}
                                    className="mlsInput"
                                    id="customer"
                                    label="Source Customer or Group"
                                    groupEventType="Customer"
                                    disabled={created}
                                    required={true}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        <ModalRow>
                            <div className="entity-section-label">Segments</div>
                        </ModalRow>
                        {entityData.segments.map((segment, i) => {
                            return (
                                <Segment
                                    key={i}
                                    index={i}
                                    handleOnChange={handleOnChange}
                                    deleteSegment={deleteSegment}
                                    segmentName={segment.name}
                                    disabled={created}
                                />
                            );
                        })}
                        <ModalRow>
                            {!created && (
                                <PlusButton
                                    onClick={addNewSegment}
                                    label="Add Segments"
                                />
                            )}
                        </ModalRow>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                marginTop: "50px",
                            }}
                        ></div>
                    </>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={() => {
                    entitiesMap[currentEntity].data.created = true;
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmitValues={() => {
                    entitiesMap[currentEntity].data.created = true;
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                onHandleSubmit={() => {
                    entitiesMap[currentEntity].data.created = true;
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate();
                }}
                passedCheck={creatingCustomAccounts ? false : passedCheck}
            />
        </EventEntityWrapper>
    );
}

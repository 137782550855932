import React, { Component } from "react";
import "./InputContainer.css";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import LocationSearchInput from "../LocationSearchInput";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import Calendar from "../Calendar";
import Switch from "react-switch";
import {
    Name,
    InputButtons,
    Description,
    Value,
    Rate,
    Stars,
    NameDesc,
    SelectDropDown,
} from "./Components";
import {
    extractCityFromAddress,
    extractProvinceFromAddress,
    getDefaultName,
} from "../../helpers";
import { employeeObject } from "Components/Registry/Employee";

class EmployeeInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            name: getDefaultName(employeeObject.name(), this.props),
            type: employeeObject.constant(),
            tag: `@${employeeObject.name()}`,
            employeeState: "specific",
            role: "",
            description: "",
            province: null,
            city: null,
            payType: "hourly",
            rate: null,
            averageHours: null,
            noEmployees: null,
            start: null,
            end: null,
            overtime: false,
            onHoverStar: null,
            rating: 0,
            inflation: false,
            salary: null,
            basePayCheck: 0,
            baseline: false,
            includePension: false,
            includeEI: false,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            const inflationRate = this.props.loadedScenario.inflation;
            this.setState({ ...this.props.editData, inflationRate });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "personName": // "Role" field - subject to change
                this.setState({ role: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "rate":
                this.setState({ rate: value });
                break;
            case "averageHours":
                this.setState({ averageHours: value });
                break;
            case "salary":
                this.setState({ salary: value });
                break;
            case "noEmployees":
                this.setState({ noEmployees: value });
                break;
            case "cadence":
                this.setState({ cadence: value });
                break;
            default:
        }
    };
    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;

        if (employeeObject.checkInput(this.state)) {
            let basePayCheck;
            if (this.state.payType === "salary") {
                basePayCheck = this.state.salary / 12;
            } else {
                basePayCheck = this.state.averageHours * this.state.rate;
            }
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
                basePayCheck,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;

        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    // Nov 18 2020 (Daniel V)
    // Updated updateValues fn below to make use of "fillPartialValues" prop coming from inputContainer.js.
    // "fillPartialValues" allows to partially fill an event node but avoids considering it "valid" until
    // all required fields are complete.

    updateValues = () => {
        let basePayCheck;
        let passedCheck = employeeObject.checkInput(this.state);
        if (this.state.payType === "salary") {
            basePayCheck = this.state.salary / 12;
        } else {
            basePayCheck = this.state.averageHours * this.state.rate * 2;
        }

        this.props.fillPartialValues(this.state, { basePayCheck }, passedCheck);
    };

    toggleEmployeeState = (e) => {
        const id = e.target.id;
        this.setState({ employeeState: id });
    };

    togglePayType = (e) => {
        const id = e.target.id;
        this.setState({ payType: id });
    };

    onHandleLocation = (address, lat, lng) => {
        this.setState({ address, lat, lng });
        this.processAddress(address);
    };

    processAddress = (address) => {
        const province = extractProvinceFromAddress(address);
        const city = extractCityFromAddress(address);
        if (address) {
            const arr = address.split(", ");
            const country = arr[arr.length - 1];
            this.setState({ country: country });
        }
        this.setState({ province: province });
        this.setState({ city: city });
    };

    onHandleDate = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
    };

    onToggleCheckBox = (id) => {
        switch (id) {
            case "overtime":
                this.setState({ overtime: !this.state.overtime });
                break;
            case "pension":
                this.setState({ includePension: !this.state.includePension });
                break;
            case "EI":
                this.setState({ includeEI: !this.state.includeEI });
                break;
            default:
        }
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        const isPassed = employeeObject.checkInput(this.state);
        const { edit } = this.props;
        const {
            employeeState,
            description,
            role,
            rate,
            averageHours,
            start,
            end,
            overtime,
            payType,
            onHoverStar,
            rating,
            salary,
            noEmployees,
            cadence,
            includeEI,
            includePension,
        } = this.state;
        const cadenceOptions = [
            "annually",
            "monthly",
            "semi-monthly",
            "bi-weekly",
            "weekly",
        ];
        // can add additional checkboxes (e.g. "Receives Commission", "Contractor") for future functionality
        const checkBox = [
            { id: "overtime", text: "Overtime eligible", state: overtime },
            { id: "EI", text: "Include EI Estimate", state: includeEI },
            {
                id: "pension",
                text: "Include CPP/QPP Estimate",
                state: includePension,
            },
        ];

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={employeeObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">EMPLOYEE</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={this.state.name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="toggledContainer">
                        <div
                            id="specific"
                            onClick={this.toggleEmployeeState}
                            className={
                                employeeState === "specific"
                                    ? "toggleBoxActive"
                                    : "toggleBox"
                            }
                        >
                            Specific
                        </div>
                        <div
                            id="placeholder"
                            onClick={this.toggleEmployeeState}
                            className={
                                employeeState === "placeholder"
                                    ? "toggleBoxActive"
                                    : "toggleBox"
                            }
                        >
                            Placeholder
                        </div>
                    </div>
                    {employeeState === "specific" && (
                        <div className="ModalRow">
                            <div className="Required">*</div>
                            <NameDesc
                                description={role}
                                onChangeInput={this.onChangeInput}
                            />
                        </div>
                    )}
                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <LocationSearchInput
                            id="address"
                            handleLocation={this.onHandleLocation}
                            value={this.state.address}
                            edit={edit}
                        />
                    </div>
                    <div className="toggledContainer">
                        <div
                            id="hourly"
                            onClick={this.togglePayType}
                            className={
                                payType === "hourly"
                                    ? "toggleBoxActive"
                                    : "toggleBox"
                            }
                        >
                            Hourly
                        </div>
                        <div
                            id="salary"
                            onClick={this.togglePayType}
                            className={
                                payType === "salary"
                                    ? "toggleBoxActive"
                                    : "toggleBox"
                            }
                        >
                            Salary
                        </div>
                    </div>
                    {employeeState === "placeholder" && (
                        <div className="ModalRowSpaceBetween MarginBottom">
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <Rate
                                    value={noEmployees}
                                    label="No. of Employees"
                                    id="noEmployees"
                                    className="mlsInput"
                                    onChangeInput={this.onChangeInput}
                                    sign={false}
                                />
                            </div>
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={cadence}
                                    options={cadenceOptions}
                                    className="select-dropdown"
                                    id="cadence"
                                    label="Payment Frequency"
                                />
                            </div>
                        </div>
                    )}
                    {payType === "hourly" && (
                        <div className="ModalRowSpaceBetween">
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <Value
                                    label="Hourly Rate"
                                    onChangeInput={this.onChangeInput}
                                    value={rate}
                                    id="rate"
                                />
                            </div>
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <Rate
                                    value={averageHours}
                                    label="Average Hrs/week"
                                    className="mlsInput"
                                    id="averageHours"
                                    onChangeInput={this.onChangeInput}
                                    sign={true}
                                />
                            </div>
                        </div>
                    )}

                    {payType === "salary" && (
                        <div className="ModalRow">
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <Value
                                    label="Annual Salary"
                                    onChangeInput={this.onChangeInput}
                                    value={salary}
                                    id="salary"
                                />
                            </div>
                            {employeeState === "specific" && (
                                <div className="ExpenseAmountContainer">
                                    <div className="Required">*</div>
                                    <SelectDropDown
                                        onChangeInput={this.onChangeInput}
                                        value={cadence}
                                        options={cadenceOptions}
                                        className="select-dropdown"
                                        id="cadence"
                                        label="Payment Frequency"
                                    />
                                </div>
                            )}
                            {employeeState !== "specific" && (
                                <div className="ExpenseAmountContainer" />
                            )}
                        </div>
                    )}
                    {employeeState === "specific" && payType === "hourly" && (
                        <div className="ModalRow">
                            <div className="ExpenseAmountContainer">
                                <div className="Required">*</div>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={cadence}
                                    options={cadenceOptions}
                                    className="select-dropdown"
                                    id="cadence"
                                    label="Payment Frequency"
                                />
                            </div>
                            <div className="ExpenseAmountContainer" />
                        </div>
                    )}

                    {checkBox.map((item) => {
                        return (
                            this.state.country === "Canada" && (
                                <div>
                                    <div className="ModalRow">
                                        <img
                                            alt="alt"
                                            onClick={() =>
                                                this.onToggleCheckBox(item.id)
                                            }
                                            className="checkBox"
                                            src={
                                                item.state
                                                    ? checkboxOn
                                                    : checkboxOff
                                            }
                                        />
                                        <div className="employeeText">
                                            {item.text}
                                        </div>
                                    </div>
                                    {item.id === "overtime" && item.state}
                                </div>
                            )
                        );
                    })}

                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <div className="keyLabel">Date</div>
                        <Calendar
                            onHandleDate={this.onHandleDate}
                            startDate={start}
                            endDate={end || null}
                            cadence={null}
                        />
                    </div>

                    <div className="ModalRow">
                        <div className="Required" />
                        <div className="keyLabel">Rating</div>
                        <div className="StarsHolder">
                            {stars.map((star, i) => {
                                const id = i + 1;
                                const onHover = onHoverStar;
                                if (i < rating || i < onHover) {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starFilledSvg"
                                            src={starFilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeave}
                                        />
                                    );
                                } else {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starUnfilledSvg"
                                            src={starUnfilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeave}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Switch
                            id="inflation"
                            checked={this.state.inflation}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={this.updateInflation}
                            onColor="#F8B46A"
                        />
                        <div className="InflationText">Apply inflation</div>
                    </div>
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={isPassed}
                />
            </div>
        );
    }
}

export default EmployeeInput;

import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import type { ChangeEvent } from "react";
import BankInputView from "./BankInputView";
import { bankObject } from "Components/Registry/Bank";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { EventManager } from "Events";
import { addNewEvent, updateEvent } from "actions/eventHelpers";

export default function BankInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);

    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(bankObject);
            _eventData.name = getDefaultName(bankObject.name(), propsObject);
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }

        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onSuccess = (_token, _metadata) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const stateData = { ...(currentEntityObject?.data || {}) };

        // getPlaidAccount(token, (_err, data) => {
        //     if (data) {
        //         stateData.bankData = data.data;
        //         stateData.bankSelected = "plaid";
        //         stateData.bankState = "accounts";
        //     } else {
        //         swal({
        //             icon: "error",
        //             title: "Cannot get bank account details",
        //         });
        //     }
        // });

        currentEntityObject.data = stateData;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleManual = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        data.bankSelected = "manual";
        data.bankState = "accounts";

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck = !!eventData.name && bankObject.checkInput(entitiesMap);

    return (
        <BankInputView
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            onChangeNameDescription={onChangeNameDescription}
            onSuccess={onSuccess}
            handleManual={handleManual}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            passedCheck={passedCheck}
            eventData={eventData}
        />
    );
}

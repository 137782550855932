import type {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import modifierEntityMap from "helpers/modifierEntityMap.json";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import moment from "moment";
import { groupObject } from "Components/Registry/Group";

/*
 * Helper function that handles Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */

export const modifierInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "startDate"
        | "endDate"
        | "eventType"
        | "event"
        | "entity"
        | "property"
        | "function"
        | "value",
    _star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            let startDateString = "";
            if (value) {
                const startMoment = moment(value);
                startDateString = startMoment
                    .set("date", 1)
                    .format("YYYY-MM-DD");
            }
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            let endDateString = "";
            if (value) {
                const endMoment = moment(value);
                endDateString = endMoment.set("date", 1).format("YYYY-MM-DD");
            }
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const parsedEvent = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedEvent,
                eventId,
                currentEntityObject.id,
                data.selectedEventId,
                data.selectedIds
            );

            data.selectedEvent = parsedEvent;
            data.selectedIds = parsedEvent?.entityIds ?? [];

            if (parsedEvent?.eventId && parsedEvent?.entityIds?.length === 0) {
                const event = getEvent(parsedEvent?.eventId);
                if (event) {
                    const entities = event.entities.map((entity) => entity.id);
                    const entity =
                        getRelevantEntities(entities)?.[entities[0]] ?? {};

                    if (entity.type === groupObject.constant()) {
                        data.entityType = entity.data.eventTypeId ?? "";
                    } else {
                        data.entityType = entity?.type ?? "";
                    }

                    data.entityType
                        ? (data.properties = Object.keys(
                              modifierEntityMap?.[data.entityType]
                          ))
                        : (data.properties = []);
                }
            } else {
                const entity =
                    getRelevantEntities(parsedEvent.entityIds)?.[
                        parsedEvent.entityIds[0]
                    ] ?? {};

                if (entity.type === groupObject.constant()) {
                    data.entityType = entity.data.eventTypeId ?? "";
                } else {
                    data.entityType = entity?.type ?? "";
                }

                const allProperties: string[] = [];
                if (data.entityType) {
                    for (const property in modifierEntityMap?.[
                        data.entityType
                    ]) {
                        const propertyType =
                            modifierEntityMap?.[data.entityType]?.[property]
                                ?.feName;

                        const unit =
                            modifierEntityMap?.[data.entityType]?.[property]
                                ?.unit;

                        const value =
                            entity?.[propertyType] ??
                            entity?.data?.[propertyType];

                        // TODO: probably extract this to a helper, since it looks like
                        // growth event also uses the modifierEntityMap to display
                        // properties

                        if (value) {
                            switch (unit) {
                                case "$":
                                    const numberValue = Number(value);
                                    allProperties.push(
                                        `${property} - ${new Intl.NumberFormat(
                                            undefined,
                                            {
                                                currency: "USD",
                                                style: "currency",
                                            }
                                        ).format(numberValue)}`
                                    );
                                    break;
                                case "Day":
                                case "":
                                default:
                                    allProperties.push(
                                        `${property} - ${value}`
                                    );
                                    break;
                            }
                        } else {
                            allProperties.push(property);
                        }
                    }
                }
                data.entityType
                    ? (data.properties = allProperties)
                    : (data.properties = []);
            }

            // If entity is changed we reset property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;

        case "eventType":
            data.selectedEventType = value ?? "";
            const availableEvents: {}[] = [];
            data.eventsMappedByType[value]?.forEach((eventId) => {
                const event = getEvent(eventId);
                if (event) {
                    availableEvents.push({
                        value: event.id,
                        displayName: event.name,
                    });
                }
            });

            // If eventType is changed we reset event, entity, property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            data.availableEvents = availableEvents;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "event":
            const selectedEventId = value ?? "";
            data.selectedEventId = selectedEventId;
            const event = getEvent(selectedEventId);
            if (event) {
                data.selectedEventName = event?.name;
            } else {
                data.selectedEventName = "";
            }

            // If event is changed we reset entity, property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "property":
            data.property = value;
            const valueKey = value?.split(" - ")?.[0];

            const propertyType =
                modifierEntityMap?.[data.entityType]?.[valueKey]?.["type"] ??
                "";
            data.modifierType = propertyType ?? "";
            propertyType
                ? (data.functions = [
                      ...modifierEntityMap?.["type"]?.[propertyType],
                  ])
                : (data.functions = []);

            // Remove set function from recurring options
            if (
                propertyType === "numerical" &&
                data.cadenceType === "Recurring"
            ) {
                data.functions.splice(1, 1);
            }

            const decisionEngineName =
                modifierEntityMap?.[data.entityType]?.[valueKey]?.["deName"] ??
                "";
            decisionEngineName
                ? (data.deName = decisionEngineName)
                : (data.deName = "");

            // If property is changed we reset function and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "function":
            data.function =
                modifierEntityMap?.["type"]?.feToDeNameMap?.[
                    data.modifierType
                ]?.[value];
            data.feFuncitonDisplayName = value;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            if (data?.modifierType === "date" && data?.function === "Set") {
                data.value = value ? moment(value).format("YYYY-MM-DD") : "";
            } else {
                data.value = value;
            }

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
};

export const remapModifierDependencies = (
    entity: EntitySchema,
    newEntityIdsMap: { [key: string]: string }
) => {
    if (
        entity?.data?.selectedIds?.length > 0 &&
        newEntityIdsMap?.[entity?.data?.selectedIds?.[0]]
    ) {
        const selectedEvent = { ...entity.data.selectedEvent };
        entity.data.selectedIds =
            [newEntityIdsMap?.[entity?.data?.selectedIds?.[0]]] ?? [];
        selectedEvent.entityIds = entity?.data?.selectedIds;
        entity.data.selectedEvent = selectedEvent;
    }
};

const resetFields = (changedField: string, data: { [x: string]: any }) => {
    if (
        changedField === "property" ||
        changedField === "entity" ||
        changedField === "event" ||
        changedField === "eventType"
    ) {
        data.function = "";
        data.feFuncitonDisplayName = "";
        data.value = "";
        data.customEffectPeriod = false;
        data.revertValue = false;
    }

    if (
        changedField === "entity" ||
        changedField === "event" ||
        changedField === "eventType"
    ) {
        data.property = "";
        data.modifierType = "";
        data.deName = "";
    }

    if (changedField === "event" || changedField === "eventType") {
        data.selectedEvent = {};
        data.selectedIds = [];
        data.entityType = "";
    }

    if (changedField === "eventType") {
        data.selectedEventId = "";
        data.selectedEventName = "";
    }
};

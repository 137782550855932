import type { ThunkAction } from "redux-thunk";
import type { RootState } from "store";
import type { AnyAction } from "redux";
import type { ChartGraphGroupNodesModeSchema } from "reducers/typesSchema/chartGraphGroupNodesSchema";
import type { EventManager } from "Events";
import type Event from "Events/_event";
import { upsertChartGraph } from "./chartGraphActions";

export const EDIT_CHART_GRAPH_GROUP_NODES_MODE =
    "chartGraphGroupNodesMode/EDIT_CHART_GRAPH_GROUP_NODES_MODE";
export const RESET_CHART_GRAPH_GROUP_NODES_MODE =
    "chartGraphGroupNodesMode/RESET_CHART_GRAPH_GROUP_NODES_MODE";

export const resetChartGraphGroupNodesModeSimple = () => ({
    type: RESET_CHART_GRAPH_GROUP_NODES_MODE,
});

/**
 * If there are any nodes specified in chartGraph.nodeIds, then wipe it out. Set chartGraphGroupNodesMode to "default";
 */
export const resetChartGraphGroupNodesMode = (): ThunkAction<
    void,
    RootState,
    any,
    AnyAction
> => {
    return (dispatch, getState) => {
        if (getState().chartGraph.nodeIds.length) {
            dispatch(
                upsertChartGraph({
                    nodeIds: [],
                })
            );
        }

        dispatch(resetChartGraphGroupNodesModeSimple());
    };
};

export const editChartGraphGroupNodesMode = (
    payload: ChartGraphGroupNodesModeSchema
) => ({
    type: EDIT_CHART_GRAPH_GROUP_NODES_MODE,
    payload,
});

/**
 * Thunk action which will update the chartGraphGroupNodesMode, but also check if there is a focused node / pinned node.
 * If there is a focused node or pinned node, then we will change chartGraph.nodeIds accordingly to a single node id or to a group of node ids.
 * If there is no focused node or pinned node, then we will set chartGraph.nodeIds to an empty array (meaning chart graph the entire thread).
 */
export const selectChartGraphGroupNodesMode =
    (
        newModeValue: ChartGraphGroupNodesModeSchema
    ): ThunkAction<void, RootState, any, AnyAction> =>
    (dispatch, getState) => {
        const focusedNode: Event = getState()?.scenario?.focus;
        if (
            newModeValue === "default"
            // && getState().chartGraph.nodeIds.length
        ) {
            // Set chartGraph.nodeIds = []
            dispatch(
                upsertChartGraph({
                    nodeIds: [],
                })
            );
        } else if (
            focusedNode
            // || hasPinnedNode
        ) {
            // if (has pinned node) { // TODO
            //     focusedNode = pinned node
            // }
            // Step 1: TODO: Get nodeIds based on cumulativeNodes/singleNode & focusedNode's value; may need to pass manager to the pure function.
            const curThread = getState().scenario?.highlightedThread;
            const manager = getState().scenario?.manager as EventManager;
            const allNodesInThread: Event[] = manager.getNodesFromThread(
                curThread.signature
            );
            if (newModeValue === "singleNode") {
                if (manager.isNodeInThread(focusedNode, allNodesInThread)) {
                    // Dispatch ["focusedNode.id"] to chartGraph.nodeIds
                    dispatch(
                        upsertChartGraph({
                            nodeIds: [focusedNode.id],
                        })
                    );
                }
            } else if (newModeValue === "cumulativeNodes") {
                // Dispatch ["focusedNode.id"] to chartGraph.nodeIds
                const newNodeIds = manager.getNodesFromThreadUpToNode(
                    curThread.signature,
                    focusedNode.id
                );
                // DISCUSSION: Do we need to sort nodeIds?

                dispatch(
                    upsertChartGraph({
                        nodeIds: newNodeIds,
                    })
                );
            }
        }

        dispatch(editChartGraphGroupNodesMode(newModeValue));
    };

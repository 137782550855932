import React from "react";

import CampaignCard from "../Card/CampaignCard";

import CampaignConnectionImage from "Assets/_optionDescriptionIcons/Campaign Customer Transfer white.jpg";
import campaignSvg from "../../Assets/_eventNodeTypes/campaignSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import CampaignInputExperimental from "Components/InputContainer/CampaignInputExperimental/CampaignInputExperimental";

export const campaignObject = {
    constant: function () {
        return Campaign_Constant();
    },

    name: function () {
        return "Campaign";
    },

    checkInput: function (state) {
        return Campaign_CheckInput(state);
    },

    svg: function () {
        return Campaign_Svg();
    },

    eventNodeMenu: function (focus) {
        return Campaign_EventNodeMenu(focus);
    },

    indexText: function () {
        return Campaign_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Campaign_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Campaign_OptionDescription();
    },

    colouredPngs: function () {
        return Campaign_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Campaign_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Campaign_GenerateDisplayFieldData();
    },
};

// Helper functions for Event

const Campaign_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.startDate) return false;

        // if (!entity.dependencies?.websiteVisitor?.eventId) return false;
        if (!entity.data.value) return false;
        if (!entity.cadence) return false;

        if (!entity.data.costPerVisit) return false;
        if (!entity.data.accountIds.length) return false;

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

const Campaign_Constant = () => {
    return "9072221a-94c2-441d-9d14-4eaa267158c6";
};

const Campaign_Svg = () => {
    return campaignSvg;
};

const Campaign_EventNodeMenu = (focus) => {
    return <CampaignCard data={focus} />;
};

const Campaign_IndexText = () => {
    return "The Campaign Event takes in a Website Visitors Event and adds to its value based on the cost per click rate and ad spend.";
};

const Campaign_Icons = () => {
    let pngs = new Map([
        ["black", campaignSvg],
        ["blue", campaignSvg],
        ["white", campaignSvg],
        ["grey", campaignSvg],
    ]);
    return pngs;
};

const Campaign_InputComponentInfoPassing = (minProps) => {
    return <CampaignInputExperimental {...minProps} />;
};

const Campaign_OptionDescription = () => {
    const tags = ["Option"];
    const type = campaignObject.name();
    const description =
        "The Campaign Event represents an advertisement campaign used to acquire website visitors.";
    const usedForText =
        "Use the Campaign Event to add website visitors to a website visitor cohort.";
    const connection = "";
    const connectionImage = CampaignConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/r/revenue.asp#:~:text=Revenue%20is%20the%20income%20generated,subtracted%20to%20determine%20net%20income.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Campaign_SideModalImage = () => {
    return sideImage;
};

const Campaign_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

// Default Imports
import React from "react";

// Import Debt  Card and Debt  Input
import DebtCard from "../Card/debtCard";
import DebtInputExperimental from "../InputContainer/DebtInputExperimental/DebtInputExperimental";

// Import Connection Image
import debtConnectionImage from "Assets/_optionDescriptionIcons/Debt, Debt Repayment white.jpg";

// Import Coloured PNGs
import debtBlack from "../../Assets/_eventNodeTypes/debt-black.svg";
import debtBlue from "../../Assets/_eventNodeTypes/expense-blue.png";
import debtWhite from "../../Assets/_eventNodeTypes/expense-white.png";
import debtGrey from "../../Assets/_eventNodeTypes/expense-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - debtObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debtObject.checkInput.call(debtObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Debt
export const debtObject = {
    constant: function () {
        return Debt_Constant();
    },

    name: function () {
        return "Debt";
    },

    checkInput: function (state) {
        return Debt_CheckInput(state);
    },

    svg: function () {
        return Debt_Svg();
    },

    eventNodeMenu: function (focus) {
        return Debt_EventNodeMenu(focus);
    },

    indexText: function () {
        return Debt_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Debt_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Debt_OptionDescription();
    },

    colouredPngs: function () {
        return Debt_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Debt_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Debt_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Debt

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Debt_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name || !entity.startDate) return false;
        if (!entity.data.debt || !entity.data.value) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Debt_Constant = () => {
    return "0e18244b-0ead-42da-acba-3a7dc3b89a1c";
};

// EFFECTS: Returns svg for event
const Debt_Svg = () => {
    return debtBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Debt_EventNodeMenu = (focus) => {
    return <DebtCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Debt_IndexText = () => {
    return "Select an input field to learn more about your debt calculations.";
};

// EFFECTS: Returns all icons for event ??
const Debt_Icons = () => {
    let pngs = new Map([
        ["black", debtBlack],
        ["blue", debtBlue],
        ["white", debtWhite],
        ["grey", debtGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Debt_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Debt_InputComponentInfoPassing = (minProps) => {
    return <DebtInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Debt_OptionDescription = () => {
    const tags = ["Option"];
    const type = debtObject.name();
    const description =
        "The Debt Event can be used to track an existing or future debt obligation that does not have structured payment terms, like a loan. This Event also calculates the interest on that debt.Used in combination with the Debt Repayment Event, it is easy to project long-term interest and principal balances and the effect of various payment schedules.";
    const usedForText =
        "This event is ideal for non-structured debt like Credit Cards or a Line of Credit.";
    const connection =
        "A Debt Event is best coupled with a Debt Repayment Event.";
    const connectionImage = debtConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/d/debt.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Debt_SideModalImage = () => {
    return sideImage;
};

const Debt_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

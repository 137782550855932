import { connect } from "react-redux";
import { appMapDispatch, appMapState } from "store/appMapStateDispatch";
import { selectChartAccount } from "actions/chartGraphActions";
import CloseIcon from "../../../Assets/close_black.svg";

const SelectedAccountsList = ({
    accountsContainerRef,
    listedAccountTypes,
    // accountsScrollTranslate,
    dispatch,
    getLedgerDisplayName,
    allAccountLedgers,
    handleClickRemoveSingleListedLedger,
    selectedAccounts,
}) => {
    const setAccountLedger = (e, account) => {
        dispatch(selectChartAccount(e, account));
    };

    return (
        <div className="graph-accounts__selected-list-wrapper">
            {/* <div className="graph-accounts__selected-list-fade-in" /> */}
            <ul
                className="graph-accounts__selected-list"
                id="graph-accounts-available"
                ref={accountsContainerRef}
                // style={accountsScrollTranslate}
            >
                {listedAccountTypes.map((accountType) => {
                    const accountIsSelected =
                        !!selectedAccounts?.[accountType.id];
                    const allowDeletion =
                        !allAccountLedgers?.ledgersMetadata?.[accountType.id]
                            ?.alwaysDisplayed;

                    return (
                        <li
                            key={accountType.id}
                            className={
                                accountIsSelected
                                    ? "graph-accounts__selected-list-item--active"
                                    : "graph-accounts__selected-list-item"
                            }
                        >
                            <span
                                className="graph-accounts__selected-list-item-text"
                                onClick={(e) =>
                                    setAccountLedger(e, accountType)
                                }
                            >
                                {getLedgerDisplayName(accountType)}
                            </span>
                            {allowDeletion && (
                                <img
                                    className="graph-accounts__remove-icon"
                                    src={CloseIcon}
                                    title="Remove"
                                    alt="remove ledger"
                                    onClick={() =>
                                        handleClickRemoveSingleListedLedger(
                                            accountType
                                        )
                                    }
                                />
                            )}
                        </li>
                    );
                })}
            </ul>
            {/* <div className="graph-accounts__selected-list-fade-out" /> */}
        </div>
    );
};

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

const mapStateToProps = appMapState((state) => {
    const allAccountLedgers = state?.allAccountLedgers;
    const selectedAccounts = state?.chartGraph.selectedAccounts;
    const listedAccountTypes = state?.graphAccountSelection?.listedAccountTypes;

    return {
        allAccountLedgers,
        selectedAccounts,
        listedAccountTypes,
    };
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectedAccountsList);

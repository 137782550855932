import { recurseToTopAccount } from "helpers/accounts";

export const getRowData = (ledgersArray, allLedgerData) => {
    const allRowsData: any = [];

    ledgersArray?.forEach((ledger) => {
        const recursedAccounts = recurseToTopAccount(ledger.id);

        // this is to fix display account issues. Because of this, the user must have submit a non empty string for the name value.
        if (!ledger?.name) return;

        const rowData = {
            accountStructure: [allLedgerData[recursedAccounts?.[0]]?.name],
            id: ledger?.id,
            name: ledger?.name,
            display: ledger?.unit?.display,
            singular: ledger?.unit?.singular,
            nearestDecimal: ledger?.unit?.nearestDecimal,
            beforeValue: ledger?.unit?.beforeValue,
            spacingBetween: ledger?.unit?.spacingBetween,
            delete: ledger?.id,
        };

        if (allLedgerData[recursedAccounts?.[1]]?.name) {
            rowData.accountStructure.push(
                allLedgerData[recursedAccounts?.[1]]?.name
            );
        }
        if (allLedgerData[recursedAccounts?.[2]]?.name) {
            rowData.accountStructure.push(
                allLedgerData[recursedAccounts?.[2]]?.name
            );
        }
        if (allLedgerData[recursedAccounts?.[3]]?.name) {
            rowData.accountStructure.push(
                allLedgerData[recursedAccounts?.[3]]?.name
            );
        }
        if (allLedgerData[recursedAccounts?.[4]]?.name) {
            rowData.accountStructure.push(
                allLedgerData[recursedAccounts?.[4]]?.name
            );
        }

        allRowsData?.push(rowData);
    });

    return allRowsData;
};

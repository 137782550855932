import { useEffect, useRef, useState } from "react";

export function usePortalTarget() {
    const portalTarget = useRef<HTMLDivElement | null>(null);
    const [_, setDummyState] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const _portalTarget = document.getElementById(
                "MainContainerCanvasContainer"
            ) as HTMLDivElement;
            if (!_portalTarget) return;

            portalTarget.current = _portalTarget;
            clearInterval(intervalId);
            try {
                setDummyState(Date.now());
            } catch {
                // noop
            }
        }, 30);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return portalTarget;
}

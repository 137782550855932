import store, { AppThunk } from "store";
import { SET_ACCOUNT_FIELD_MAPPING_MODAL } from "./types";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import {
    FieldMappingData,
    FieldMappingDataMapped,
} from "reducers/typesSchema/fieldMappingSchema";

export const setShowAccountFieldMappingModal = (mappingData: {
    show: boolean;
    entity?: EntitySchema;
    fieldMappingData?: FieldMappingData | FieldMappingDataMapped;
    mapped?: boolean;
    create?: any;
}) => {
    store.dispatch({
        type: SET_ACCOUNT_FIELD_MAPPING_MODAL,
        payload: mappingData,
    });
};

export const closeAccountFieldMappingModal = (): AppThunk<void> => {
    return (dispatch, _getState) => {
        dispatch({
            type: SET_ACCOUNT_FIELD_MAPPING_MODAL,
            payload: { show: false },
        });
    };
};

export const UPSERT_ACCOUNT_FIELD_MAPPINGS = "UPSERT_ACCOUNT_FIELD_MAPPINGS";

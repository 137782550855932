import React, { Component } from "react";
import styles from "./Options.module.css";
import { NodeTypesBlack } from "../../Events";
import collapse from "../../Assets/_optionPicker/collapsed.svg";
import toggle from "../../Assets/_optionPicker/toggle.svg";
import tourPoint from "../../Assets/_onboarding/tourPoint.svg";
import favoriteSvg from "../../Assets/favorite.svg";
import unFavoriteSvg from "../../Assets/unfavorite.svg";
import proIcon from "../../Assets/_optionPicker/proIcon.svg";
import {
    Decision,
    IncomeTax,
    Taxes,
    ResidentialRealEstate,
} from "../../helpers/constants";
import { events } from "Components/InputContainer/Master Registry";
import { houseObject } from "Components/Registry/House";
import { mortgageObject } from "Components/Registry/Mortgage";
import { incomeTaxObject } from "Components/Registry/Income Tax";
import { decisionObject } from "Components/Registry/Decision";

export default class OptionTab extends Component {
    componentDidMount() {
        if (
            this.props.onboardingData &&
            (this.props.onboardingData === 3 || this.props.onboardingData === 4)
        ) {
            const Residential = this.props.nodes.find(
                (node) => node.type === ResidentialRealEstate
            );
            this.onHandleToggleOpen(Residential);
        } else if (
            this.props.onboardingData &&
            this.props.onboardingData === 6
        ) {
            const Tax = this.props.nodes.find((node) => node.type === Taxes);
            this.onHandleToggleOpen(Tax);
        }
    }

    onHandleToggleOpen = (node) => {
        const { nodes } = this.props;
        const newNodes = nodes.map((item) => {
            if (item.type === node.type) {
                const newItem = {
                    ...item,
                    isOpen: !item.isOpen,
                };
                return newItem;
            }
            return item;
        });

        this.props.onHandleOptionNodes(newNodes);

        this.handleScroll(node.type);
    };

    handleScroll = (id) => {
        setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        }, 200);
    };

    onHandleFavorite = (node) => {
        const favoriteNodes =
            JSON.parse(localStorage.getItem("favoriteNodes")) || [];
        const isPresent = favoriteNodes.find((fave) => fave.type === node.type);
        if (isPresent) {
            const newFave = favoriteNodes.filter((fave) => {
                return fave.type !== node.type;
            });
            localStorage.setItem("favoriteNodes", JSON.stringify(newFave));
        } else {
            const newFavoriteNodes = [...favoriteNodes, { type: node.type }];
            localStorage.setItem(
                "favoriteNodes",
                JSON.stringify(newFavoriteNodes)
            );
        }
        this.props.changeFavorite();
    };

    render() {
        const {
            onboardingData,
            isBaseline,
            incomeTax,
            onHandleOptionSelected,
            addEventType,
            nodes,
        } = this.props;
        const { favoriteNodes } = this.props;
        return (
            <div className={styles.utilityContainer}>
                {nodes &&
                    nodes.map((node, i) => {
                        const typeImagesBlack = {};

                        NodeTypesBlack.forEach((data) => {
                            typeImagesBlack[data.type] = data.image;
                        });

                        //let image = typeImagesBlack[node.type];
                        return (
                            <div key={i}>
                                <div
                                    id={node.type}
                                    onClick={() =>
                                        this.onHandleToggleOpen(node)
                                    }
                                    className={styles.utilityDiv}
                                >
                                    <img
                                        className={styles.utilitySvg}
                                        src={node.svg}
                                        alt={""}
                                    />
                                    <div
                                        className={
                                            styles.optionsDescriptionContainer
                                        }
                                    >
                                        <div className={styles.utilityType}>
                                            {node.type}{" "}
                                            <span
                                                className={styles.activeCount}
                                            >
                                                {node.activeNodes.length}{" "}
                                                Active,{" "}
                                                {node.inProgressNodes.length} in
                                                Development
                                            </span>
                                        </div>
                                        <div>{node.description}</div>
                                    </div>

                                    <img
                                        onClick={() =>
                                            this.onHandleToggleOpen(node)
                                        }
                                        src={node.isOpen ? toggle : collapse}
                                        className={styles.collapse}
                                        alt={""}
                                    />
                                </div>
                                {node.isOpen && (
                                    <div>
                                        <div
                                            className={
                                                styles.optionNodeContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.optionRootContainer
                                                }
                                            >
                                                {node.activeNodes.map(
                                                    (node, i) => {
                                                        if (
                                                            incomeTax &&
                                                            incomeTax.length >
                                                                0 &&
                                                            node.type ===
                                                                IncomeTax
                                                        ) {
                                                            return null;
                                                        }

                                                        const isFavorite =
                                                            favoriteNodes.find(
                                                                (fave) =>
                                                                    fave.type ===
                                                                    node.type
                                                            );

                                                        const isDecision =
                                                            node.type ===
                                                            Decision;

                                                        if (
                                                            isBaseline &&
                                                            isDecision
                                                        ) {
                                                            return null;
                                                        }

                                                        const stepTwo =
                                                            onboardingData &&
                                                            isDecision &&
                                                            onboardingData ===
                                                                2;

                                                        const stepThree =
                                                            onboardingData &&
                                                            onboardingData ===
                                                                3 &&
                                                            node.type ===
                                                                houseObject.constant();

                                                        const stepFour =
                                                            onboardingData &&
                                                            onboardingData ===
                                                                4 &&
                                                            node.type ===
                                                                mortgageObject.constant();

                                                        const stepSix =
                                                            onboardingData &&
                                                            onboardingData ===
                                                                6 &&
                                                            node.type ===
                                                                incomeTaxObject.constant();

                                                        const stepTen =
                                                            onboardingData &&
                                                            onboardingData ===
                                                                10 &&
                                                            node.type ===
                                                                decisionObject.constant();

                                                        let showTourPoint;
                                                        if (
                                                            stepTwo ||
                                                            stepThree ||
                                                            stepFour ||
                                                            stepSix ||
                                                            stepTen
                                                        ) {
                                                            showTourPoint = true;
                                                        } else {
                                                            showTourPoint = false;
                                                        }

                                                        const typeImagesBlack =
                                                            {};

                                                        NodeTypesBlack.forEach(
                                                            (data) => {
                                                                typeImagesBlack[
                                                                    data.type
                                                                ] = data.image;
                                                            }
                                                        );

                                                        let image =
                                                            typeImagesBlack[
                                                                node.type
                                                            ];

                                                        //this is where you toggle if node will show description
                                                        let isDescriptionOpen = false;
                                                        events.forEach(
                                                            (event) => {
                                                                if (
                                                                    event.constant() ===
                                                                    node.type
                                                                )
                                                                    isDescriptionOpen = true;
                                                            }
                                                        );

                                                        return (
                                                            <div
                                                                key={node.type}
                                                                className={
                                                                    styles.optionBoxWrap
                                                                }
                                                            >
                                                                <div
                                                                    key={i}
                                                                    className={
                                                                        isDecision
                                                                            ? styles.decisionBox
                                                                            : styles.optionBox
                                                                    }
                                                                    onClick={() =>
                                                                        isDescriptionOpen
                                                                            ? onHandleOptionSelected(
                                                                                  node.type
                                                                              )
                                                                            : addEventType(
                                                                                  node.type
                                                                              )
                                                                    }
                                                                >
                                                                    <img
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            this.onHandleFavorite(
                                                                                node
                                                                            );
                                                                        }}
                                                                        className={
                                                                            styles.favorite
                                                                        }
                                                                        src={
                                                                            isFavorite
                                                                                ? favoriteSvg
                                                                                : unFavoriteSvg
                                                                        }
                                                                        alt={""}
                                                                    />
                                                                    <img
                                                                        src={
                                                                            image
                                                                        }
                                                                        id={
                                                                            node.type
                                                                        }
                                                                        className={
                                                                            styles.optionSvg
                                                                        }
                                                                        alt="alt"
                                                                    />

                                                                    <div
                                                                        onClick={() =>
                                                                            isDescriptionOpen
                                                                                ? onHandleOptionSelected(
                                                                                      node.type
                                                                                  )
                                                                                : addEventType(
                                                                                      node.type
                                                                                  )
                                                                        }
                                                                        className={
                                                                            styles.optionName
                                                                        }
                                                                    >
                                                                        {
                                                                            // event names inside the options tab
                                                                            node.name
                                                                                ? node.name
                                                                                : node.type
                                                                        }
                                                                    </div>
                                                                    {showTourPoint && (
                                                                        <img
                                                                            alt="alt"
                                                                            className={
                                                                                styles.tourPoint
                                                                            }
                                                                            src={
                                                                                tourPoint
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>

                                            {node.inProgressNodes?.length >
                                                0 && (
                                                <>
                                                    <div
                                                        className={
                                                            styles.ModalRow
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.optionsLabel
                                                            }
                                                        >
                                                            Coming soon
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.optionRootContainer
                                                        }
                                                    >
                                                        {node.inProgressNodes.map(
                                                            (node, i) => {
                                                                const typeImagesBlack =
                                                                    {};

                                                                NodeTypesBlack.forEach(
                                                                    (data) => {
                                                                        typeImagesBlack[
                                                                            data.type
                                                                        ] =
                                                                            data.image;
                                                                    }
                                                                );

                                                                let image =
                                                                    typeImagesBlack[
                                                                        node
                                                                            .type
                                                                    ];

                                                                let isDescriptionOpen = false;
                                                                events.forEach(
                                                                    (event) => {
                                                                        if (
                                                                            event.constant() ===
                                                                            node.type
                                                                        )
                                                                            isDescriptionOpen = true;
                                                                    }
                                                                );

                                                                return (
                                                                    <div
                                                                        key={
                                                                            node.type
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                process
                                                                                    .env
                                                                                    .REACT_APP_ENV !==
                                                                                "development"
                                                                            )
                                                                                return;
                                                                            isDescriptionOpen
                                                                                ? onHandleOptionSelected(
                                                                                      node.type
                                                                                  )
                                                                                : addEventType(
                                                                                      node.type
                                                                                  );
                                                                        }}
                                                                        className={
                                                                            styles.optionBoxWrap
                                                                        }
                                                                    >
                                                                        <div
                                                                            // onClick={() =>
                                                                            //     isBeta &&
                                                                            //     onHandleOptionSelected(node.type)
                                                                            // }
                                                                            key={
                                                                                i
                                                                            }
                                                                            className={
                                                                                styles.optionBox
                                                                            }
                                                                        >
                                                                            {node.pro && (
                                                                                <img
                                                                                    src={
                                                                                        proIcon
                                                                                    }
                                                                                    className={
                                                                                        styles.proIcon
                                                                                    }
                                                                                    alt={
                                                                                        ""
                                                                                    }
                                                                                />
                                                                            )}
                                                                            <img
                                                                                src={
                                                                                    image
                                                                                }
                                                                                className={
                                                                                    styles.optionSvg
                                                                                }
                                                                                alt="alt"
                                                                            />
                                                                            <div
                                                                                className={
                                                                                    styles.optionName
                                                                                }
                                                                            >
                                                                                {node.name ??
                                                                                    ""}
                                                                            </div>
                                                                            <div
                                                                                className={
                                                                                    styles.banner
                                                                                }
                                                                            >
                                                                                coming
                                                                                soon
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {node.deprecatedNodes?.length >
                                                0 && (
                                                <>
                                                    <div
                                                        className={
                                                            styles.ModalRow
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.optionsLabel
                                                            }
                                                        >
                                                            Deprecated
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.optionRootContainer
                                                        }
                                                    >
                                                        {node.deprecatedNodes.map(
                                                            (node, i) => {
                                                                const typeImagesBlack =
                                                                    {};

                                                                NodeTypesBlack.forEach(
                                                                    (data) => {
                                                                        typeImagesBlack[
                                                                            data.type
                                                                        ] =
                                                                            data.image;
                                                                    }
                                                                );

                                                                let image =
                                                                    typeImagesBlack[
                                                                        node
                                                                            .type
                                                                    ];

                                                                let isDescriptionOpen = false;
                                                                events.forEach(
                                                                    (event) => {
                                                                        if (
                                                                            event.constant() ===
                                                                            node.type
                                                                        )
                                                                            isDescriptionOpen = true;
                                                                    }
                                                                );

                                                                return (
                                                                    <div
                                                                        key={
                                                                            node.type
                                                                        }
                                                                        onClick={() =>
                                                                            isDescriptionOpen
                                                                                ? onHandleOptionSelected(
                                                                                      node.type
                                                                                  )
                                                                                : addEventType(
                                                                                      node.type
                                                                                  )
                                                                        }
                                                                        className={
                                                                            styles.optionBoxWrap
                                                                        }
                                                                    >
                                                                        <div
                                                                            // onClick={() =>
                                                                            //     isBeta &&
                                                                            //     onHandleOptionSelected(node.type)
                                                                            // }
                                                                            key={
                                                                                i
                                                                            }
                                                                            className={
                                                                                styles.optionBox
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    image
                                                                                }
                                                                                className={
                                                                                    styles.optionSvg
                                                                                }
                                                                                alt="alt"
                                                                            />
                                                                            <div
                                                                                className={
                                                                                    styles.optionName
                                                                                }
                                                                            >
                                                                                {node.name ??
                                                                                    ""}
                                                                            </div>
                                                                            <div
                                                                                className={
                                                                                    styles.bannerRed
                                                                                }
                                                                            >
                                                                                deprecated
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        );
    }
}

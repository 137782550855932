/**
 * Adds up all the value in ledgerValues if in nodeIds.
 *
 * Example:
 * ledgerValues - {
 *     "n1": 2
 *     "n2": 5
 *     "n3": 12
 *     "_All": 19
 * }
 * nodeIds - ["n1", "n3"]
 *
 * returns - 2 + 12 = 14
 */
export function addAllAverages(
    ledgerValues: { [nodeId: string]: number },
    nodeIds: string[]
) {
    let allAveragesValue = 0;
    const _nodeIds = [...nodeIds];

    if (!_nodeIds.length) {
        _nodeIds.push("_All");
    }

    _nodeIds.forEach((nodeId) => {
        const nodeAverageValue = ledgerValues?.[nodeId] || 0;
        allAveragesValue += nodeAverageValue;
    });

    return allAveragesValue;
}

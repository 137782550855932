import * as React from "react";
import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { WaterfallSectionV2 } from "./WaterfallSectionV2";
import Popper from "@mui/material/Popper";
import "./waterfallMenu.css";
import {
    BaseContainerHeightContext,
    CurrentSelectionContext,
    SelectedTopLevelAccountContext,
    SetSelectedTopLevelAccountContext,
    SelectedSubAccountContext,
    SetSelectedSubAccountContext,
    SelectedLeafAccountContext,
    SetSelectedLeafAccountContext,
    SelectedLeafSubAccountContext,
    SetSelectedLeafSubAccountContext,
} from "./WaterfallContext";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { useMemo, useEffect, useRef } from "react";
import { useDimension } from "helpers/useDimension";
import MenuItem from "@mui/material/MenuItem";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => {
    return {
        "& .MuiPaper-root": {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            overflow: "visible",
            color: "rgb(55, 65, 81)",
            boxShadow:
                "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
                padding: "0",
            },
        },
    };
});

interface WaterfallMenuProps {
    specificCallbackId: string;
    anchorEl: null | HTMLElement;
    handleClose: any;
    currentSelection: string;
    setCurrentSelection: any;
    callback: any;
    contraAccount: boolean;
    accountSelectedForContra: string;
    topLevelFilters: {};
    unselectableAccounts?: {};
    quickSelectAccounts?: {};
    disableContraReset: boolean;
}

export const WaterfallMenu = ({
    specificCallbackId,
    anchorEl,
    handleClose,
    currentSelection,
    setCurrentSelection,
    callback,
    contraAccount = false,
    accountSelectedForContra = "",
    topLevelFilters = {},
    unselectableAccounts = {},
    quickSelectAccounts = {},
    disableContraReset = false,
}: WaterfallMenuProps) => {
    const baseContainerRef = useRef<HTMLDivElement>(null);
    const [_width, height] = useDimension(baseContainerRef.current);

    const allAccountData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );
    const recurseToTopAccount = (id: string) => {
        if (id == "") {
            return [];
        }

        const accounts: string[] = [];
        let currentAcc = allAccountData[id];
        accounts.unshift(currentAcc.id);
        while (currentAcc.parents.length > 0) {
            currentAcc = allAccountData[currentAcc.parents[0]];
            accounts.unshift(currentAcc.id);
        }
        return accounts;
    };

    const [selectedTopLevelAccount, setSelectedTopLevelAccount] =
        React.useState<string>("");
    const [selectedSubAccount, setSelectedSubAccount] =
        React.useState<string>("");
    const [selectedLeafAccount, setSelectedLeafAccount] =
        React.useState<string>("");
    const [selectedLeafSubAccount, setSelectedLeafSubAccount] =
        React.useState<string>("");

    const open = Boolean(anchorEl);

    const updateSelection = (accountData) => {
        const requiredData = {
            name: accountData.name,
            ids: recurseToTopAccount(accountData.id),
            id: accountData.id,
        };
        handleClose(handleReset);
        setCurrentSelection(accountData.name ?? "Select Account");
        callback(
            requiredData,
            specificCallbackId !== ""
                ? specificCallbackId
                : contraAccount
                ? "contraAccountName"
                : "accountName"
        );
    };

    const hasContraAccount = (accountId) => {
        // only expense and income accounts have a contra account
        return (
            accountId == "7faf0285-78ca-411b-b875-d900929d7c94" ||
            accountId == "488dd61d-8697-4213-8978-cf91755365a4"
        );
    };

    const defaultContraAcc = useMemo(() => {
        const contraAccount = hasContraAccount(accountSelectedForContra)
            ? {
                  name: "Cash (default)",
                  id: "61f75c52-c089-4137-b424-e2f7bfc4d340",
              }
            : { name: "None (default)", id: "" };
        return contraAccount;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountSelectedForContra]);

    useEffect(() => {
        if (
            !disableContraReset &&
            contraAccount &&
            currentSelection?.includes("default")
        )
            updateSelection(defaultContraAcc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultContraAcc]);

    const [subAccountPopperAnchor, setSubAccountPopperAnchor] =
        React.useState<null | HTMLElement>(null);
    const [leafAccountPopperAnchor, setLeafAccountPopperAnchor] =
        React.useState<null | HTMLElement>(null);
    const [subLeafAccountPopperAnchor, setSubLeafAccountPopperAnchor] =
        React.useState<null | HTMLElement>(null);
    const [fifthLevelAccountPopperAnchor, setFifthLevelAccountPopperAnchor] =
        React.useState<null | HTMLElement>(null);

    const subAccountPopperOpen = Boolean(subAccountPopperAnchor);
    const subAccountPopperId = subAccountPopperOpen
        ? "simple-popper"
        : undefined;

    const leafAccountPopperOpen = Boolean(leafAccountPopperAnchor);
    const leafAccountPopperId = leafAccountPopperOpen
        ? "simple-popper"
        : undefined;

    const subLeafAccountPopperOpen = Boolean(subLeafAccountPopperAnchor);
    const subLeafAccountPopperId = subLeafAccountPopperOpen
        ? "simple-popper"
        : undefined;

    const fifthLevelAccountPopperOpen = Boolean(fifthLevelAccountPopperAnchor);
    const fifthLevelAccountPopperId = fifthLevelAccountPopperOpen
        ? "simple-popper"
        : undefined;

    const handleUpdatePoppers = (accountLevel, accountId, anchorEl) => {
        switch (accountLevel) {
            case "topLevel":
                if (
                    subAccountPopperOpen &&
                    accountId === selectedTopLevelAccount
                ) {
                    setSelectedTopLevelAccount("");
                    setSelectedSubAccount("");
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                    setSubAccountPopperAnchor(null);
                    setLeafAccountPopperAnchor(null);
                    setSubLeafAccountPopperAnchor(null);
                    setFifthLevelAccountPopperAnchor(null);
                } else {
                    setSelectedTopLevelAccount(accountId);
                    setSelectedSubAccount("");
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                    setSubAccountPopperAnchor(anchorEl);
                    setLeafAccountPopperAnchor(null);
                    setSubLeafAccountPopperAnchor(null);
                    setFifthLevelAccountPopperAnchor(null);
                }
                break;
            case "subAccount":
                if (leafAccountPopperOpen && accountId === selectedSubAccount) {
                    setSelectedSubAccount("");
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                    setLeafAccountPopperAnchor(null);
                    setSubLeafAccountPopperAnchor(null);
                    setFifthLevelAccountPopperAnchor(null);
                } else {
                    setSelectedSubAccount(accountId);
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                    setLeafAccountPopperAnchor(anchorEl);
                    setSubLeafAccountPopperAnchor(null);
                    setFifthLevelAccountPopperAnchor(null);
                }
                break;
            case "leafAccount":
                if (
                    subLeafAccountPopperOpen &&
                    accountId === selectedLeafAccount
                ) {
                    setSelectedLeafAccount("");
                    setSelectedLeafSubAccount("");
                    setSubLeafAccountPopperAnchor(null);
                    setFifthLevelAccountPopperAnchor(null);
                } else {
                    setSelectedLeafAccount(accountId);
                    setSelectedLeafSubAccount("");
                    setSubLeafAccountPopperAnchor(anchorEl);
                    setFifthLevelAccountPopperAnchor(null);
                }
                break;
            case "leafSubAccount":
                if (
                    subLeafAccountPopperOpen &&
                    accountId === selectedLeafSubAccount
                ) {
                    setSelectedLeafSubAccount("");
                    setFifthLevelAccountPopperAnchor(null);
                } else {
                    setSelectedLeafSubAccount(accountId);
                    setFifthLevelAccountPopperAnchor(anchorEl);
                }
                break;
        }
    };

    const handleReset = () => {
        setSelectedTopLevelAccount("");
        setSelectedSubAccount("");
        setSelectedLeafAccount("");
        setSubAccountPopperAnchor(null);
        setLeafAccountPopperAnchor(null);
        setSubLeafAccountPopperAnchor(null);
        setFifthLevelAccountPopperAnchor(null);
    };

    return (
        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
                "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            elevation={0}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            open={open}
            onClose={() => handleClose(handleReset)}
        >
            <BaseContainerHeightContext.Provider value={height}>
                <CurrentSelectionContext.Provider value={currentSelection}>
                    <SelectedTopLevelAccountContext.Provider
                        value={selectedTopLevelAccount}
                    >
                        <SetSelectedTopLevelAccountContext.Provider
                            value={setSelectedTopLevelAccount}
                        >
                            <SelectedSubAccountContext.Provider
                                value={selectedSubAccount}
                            >
                                <SetSelectedSubAccountContext.Provider
                                    value={setSelectedSubAccount}
                                >
                                    <SelectedLeafAccountContext.Provider
                                        value={selectedLeafAccount}
                                    >
                                        <SetSelectedLeafAccountContext.Provider
                                            value={setSelectedLeafAccount}
                                        >
                                            <SelectedLeafSubAccountContext.Provider
                                                value={selectedLeafSubAccount}
                                            >
                                                <SetSelectedLeafSubAccountContext.Provider
                                                    value={
                                                        setSelectedLeafSubAccount
                                                    }
                                                >
                                                    <div
                                                        className="basecontainer"
                                                        ref={baseContainerRef}
                                                    >
                                                        {Object.keys(
                                                            quickSelectAccounts
                                                        )?.length > 0 && (
                                                            <div className="quickSelectAccounts">
                                                                {Object.keys(
                                                                    quickSelectAccounts
                                                                ).map(
                                                                    (
                                                                        quickSelectAccountId
                                                                    ) => {
                                                                        const accountData =
                                                                            allAccountData?.[
                                                                                quickSelectAccountId
                                                                            ];
                                                                        return (
                                                                            <MenuItem
                                                                                key={
                                                                                    quickSelectAccountId
                                                                                }
                                                                                id="textStylesOverride"
                                                                                className="section__heading"
                                                                                onClick={() => {
                                                                                    updateSelection(
                                                                                        accountData
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    className={
                                                                                        currentSelection ===
                                                                                        accountData.name
                                                                                            ? "section__heading section__heading--active"
                                                                                            : "section__heading"
                                                                                    }
                                                                                >
                                                                                    {accountData.name ??
                                                                                        ""}
                                                                                </span>
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                        <WaterfallSectionV2
                                                            accountLevel="topLevel"
                                                            clickAction={
                                                                updateSelection
                                                            }
                                                            handleExpand={
                                                                handleUpdatePoppers
                                                            }
                                                            topLevelFilters={
                                                                topLevelFilters
                                                            }
                                                            unselectableAccounts={
                                                                unselectableAccounts
                                                            }
                                                            contraAccount={
                                                                contraAccount
                                                            }
                                                            defaultContraAcc={
                                                                defaultContraAcc
                                                            }
                                                        />
                                                        <Popper
                                                            id={
                                                                subAccountPopperId
                                                            }
                                                            open={
                                                                subAccountPopperOpen
                                                            }
                                                            anchorEl={
                                                                subAccountPopperAnchor
                                                            }
                                                            disablePortal={true}
                                                            placement="right-start"
                                                        >
                                                            <WaterfallSectionV2
                                                                accountLevel="subAccount"
                                                                clickAction={
                                                                    updateSelection
                                                                }
                                                                handleExpand={
                                                                    handleUpdatePoppers
                                                                }
                                                                topLevelFilters={
                                                                    topLevelFilters
                                                                }
                                                                unselectableAccounts={
                                                                    unselectableAccounts
                                                                }
                                                                contraAccount={
                                                                    contraAccount
                                                                }
                                                                defaultContraAcc={
                                                                    defaultContraAcc
                                                                }
                                                            />
                                                        </Popper>
                                                        <Popper
                                                            id={
                                                                leafAccountPopperId
                                                            }
                                                            open={
                                                                leafAccountPopperOpen
                                                            }
                                                            anchorEl={
                                                                leafAccountPopperAnchor
                                                            }
                                                            disablePortal={true}
                                                            placement="right-start"
                                                        >
                                                            <WaterfallSectionV2
                                                                accountLevel="leafAccount"
                                                                clickAction={
                                                                    updateSelection
                                                                }
                                                                handleExpand={
                                                                    handleUpdatePoppers
                                                                }
                                                                topLevelFilters={
                                                                    topLevelFilters
                                                                }
                                                                unselectableAccounts={
                                                                    unselectableAccounts
                                                                }
                                                                contraAccount={
                                                                    contraAccount
                                                                }
                                                                defaultContraAcc={
                                                                    defaultContraAcc
                                                                }
                                                            />
                                                        </Popper>
                                                        <Popper
                                                            id={
                                                                subLeafAccountPopperId
                                                            }
                                                            open={
                                                                subLeafAccountPopperOpen
                                                            }
                                                            anchorEl={
                                                                subLeafAccountPopperAnchor
                                                            }
                                                            disablePortal={true}
                                                            placement="right-start"
                                                        >
                                                            <WaterfallSectionV2
                                                                accountLevel="leafSubAccount"
                                                                clickAction={
                                                                    updateSelection
                                                                }
                                                                handleExpand={
                                                                    handleUpdatePoppers
                                                                }
                                                                topLevelFilters={
                                                                    topLevelFilters
                                                                }
                                                                unselectableAccounts={
                                                                    unselectableAccounts
                                                                }
                                                                contraAccount={
                                                                    contraAccount
                                                                }
                                                                defaultContraAcc={
                                                                    defaultContraAcc
                                                                }
                                                            />
                                                        </Popper>
                                                        <Popper
                                                            id={
                                                                fifthLevelAccountPopperId
                                                            }
                                                            open={
                                                                fifthLevelAccountPopperOpen
                                                            }
                                                            anchorEl={
                                                                fifthLevelAccountPopperAnchor
                                                            }
                                                            disablePortal={true}
                                                            placement="left-start"
                                                        >
                                                            <WaterfallSectionV2
                                                                accountLevel="fifthLevelAccount"
                                                                clickAction={
                                                                    updateSelection
                                                                }
                                                                handleExpand={
                                                                    handleUpdatePoppers
                                                                }
                                                                topLevelFilters={
                                                                    topLevelFilters
                                                                }
                                                                unselectableAccounts={
                                                                    unselectableAccounts
                                                                }
                                                                contraAccount={
                                                                    contraAccount
                                                                }
                                                                defaultContraAcc={
                                                                    defaultContraAcc
                                                                }
                                                            />
                                                        </Popper>
                                                    </div>
                                                </SetSelectedLeafSubAccountContext.Provider>
                                            </SelectedLeafSubAccountContext.Provider>
                                        </SetSelectedLeafAccountContext.Provider>
                                    </SelectedLeafAccountContext.Provider>
                                </SetSelectedSubAccountContext.Provider>
                            </SelectedSubAccountContext.Provider>
                        </SetSelectedTopLevelAccountContext.Provider>
                    </SelectedTopLevelAccountContext.Provider>
                </CurrentSelectionContext.Provider>
            </BaseContainerHeightContext.Provider>
        </StyledMenu>
    );
};

import React, { Component } from "react";
import "./InputContainer.css";
import SingleCalendar from "../Calendar/singleCalendar";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import {
    Name,
    Description,
    SelectDropDown,
    Value,
    Url,
    Stars,
    Rate,
    InputButtons,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { loanObject } from "../Registry/Loan";

const borrowingReasons = [
    "Buying a car",
    "Home renovation",
    "Debt consolidation",
    "Education",
    "RRSP",
    "Other",
];

const options = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];

class LoanInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: getDefaultName(loanObject.name(), this.props),
            description: "",
            url: "",
            borrowingReason: "",
            loan: "",
            repaymentTerm: null,
            cadence: "",
            amortization: 0,
            rate: "",
            payments: null,
            type: loanObject.name(),
            value: null,
            showSettings: false,
            tag: `@${loanObject.name()}`,
            onHoverStar: null,
            inflation: false,
            termInterest: null,
            baseline: false,
            start: null,
            termUnit: "Years",
            numTermUnits: "",
        };
    }
    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }
    onChangeInput = (e, id, star) => {
        const value = e.target.value;
        let newAmortization;
        switch (id) {
            case "url":
                this.setState({ url: value });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "cadence":
                if (value === "Payment Frequency") {
                    this.setState({ cadence: "" }, () => {
                        this.handleCalculation();
                    });
                } else {
                    this.setState({ cadence: value }, () => {
                        this.handleCalculation();
                    });
                }

                break;
            case "borrowingReason":
                if (value === "Borrowing Reason") {
                    this.setState({ borrowingReason: "" });
                } else {
                    this.setState({ borrowingReason: value });
                }

                break;
            case "loan":
                const loan = parseInt(value);
                this.setState({ loan: loan }, () => {
                    this.handleCalculation();
                });
                break;
            case "interestRate":
                const rate = parseFloat(value);
                this.setState({ rate: rate }, () => {
                    this.handleCalculation();
                });
                break;
            case "termUnit":
                newAmortization = this.state.amortization;
                if (this.state.termUnit === "Years" && value === "Months")
                    newAmortization /= 12;
                if (this.state.termUnit === "Months" && value === "Years")
                    newAmortization *= 12;
                this.setState(
                    { termUnit: value, amortization: newAmortization },
                    () => {
                        this.handleCalculation();
                    }
                );
                break;
            case "numTermUnits":
                newAmortization = value;
                if (this.state.termUnit === "Months") {
                    newAmortization /= 12;
                }
                this.setState(
                    { numTermUnits: value, amortization: newAmortization },
                    () => {
                        this.handleCalculation();
                    }
                );
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = loanObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleDate = (startDate) => {
        this.setState({ start: startDate });
    };

    //assumes annual compounding
    static getLoanPayments(stateObject) {
        const principal = stateObject["loan"];
        const rate = stateObject["rate"] * 0.01;
        const amortization = stateObject["amortization"];

        let payments;
        let periodicInterestRate;
        let termInterest = 0;
        let numPayments = amortization;
        switch (stateObject["cadence"]) {
            case "annually":
                numPayments *= 1;
                periodicInterestRate = rate;
                payments =
                    principal /
                    (((1 + periodicInterestRate) ** numPayments - 1) /
                        (periodicInterestRate *
                            (1 + periodicInterestRate) ** numPayments));
                termInterest = principal - payments * amortization;
                break;
            case "monthly":
                numPayments *= 12;
                periodicInterestRate = rate / 12;
                payments =
                    principal /
                    (((1 + periodicInterestRate) ** numPayments - 1) /
                        (periodicInterestRate *
                            (1 + periodicInterestRate) ** numPayments));
                termInterest = principal - payments * amortization * 12;
                break;
            case "semi-monthly":
                numPayments *= 24;
                periodicInterestRate = rate / 24;
                payments =
                    principal /
                    (((1 + periodicInterestRate) ** numPayments - 1) /
                        (periodicInterestRate *
                            (1 + periodicInterestRate) ** numPayments));
                termInterest = principal - payments * amortization * 24;
                break;
            case "bi-weekly":
                numPayments *= 26;
                periodicInterestRate = rate / 26;
                payments =
                    principal /
                    (((1 + periodicInterestRate) ** numPayments - 1) /
                        (periodicInterestRate *
                            (1 + periodicInterestRate) ** numPayments));
                termInterest = principal - payments * amortization * 26;
                break;
            case "weekly":
                numPayments *= 52;
                periodicInterestRate = rate / 52;
                payments =
                    principal /
                    (((1 + periodicInterestRate) ** numPayments - 1) /
                        (periodicInterestRate *
                            (1 + periodicInterestRate) ** numPayments));
                termInterest = principal - payments * amortization * 52;
                break;
            case "one-time":
                numPayments = 1;
                periodicInterestRate = rate;
                payments = principal * (rate + 1);
                termInterest = principal - payments * amortization;
                break;
            default:
        }
        return {
            payments: payments,
            termInterest: (-1 * termInterest).toFixed(0),
            numPayments,
        };
    }

    updateValue = (payments) => {
        const value = parseInt(payments);
        this.setState({ payments: value, value: parseInt(value) });
    };

    handleCalculation = () => {
        if (this.state.type === "Loan") {
            if (
                this.state["cadence"] !== (undefined || null) &&
                this.state["amortization"] !== (undefined || null) &&
                this.state["loan"] !== (undefined || null) &&
                this.state["rate"] !== (undefined || null)
            ) {
                const data = LoanInput.getLoanPayments(this.state);
                this.setState({
                    termInterest: data.termInterest,
                    numPayments: data.numPayments,
                });
                let payments = parseFloat(data.payments).toFixed(2);

                this.updateValue(payments);
            }
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        const stars = [1, 2, 3, 4, 5];
        const { edit } = this.props;
        const passedCheck = loanObject.checkInput(this.state);
        const {
            name,
            description,
            url,
            borrowingReason,
            loan,
            rate,
            cadence,
            onHoverStar,
            rating,
            termUnit,
            numTermUnits,
        } = this.state;

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={loanObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">LOAN</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div className="ModalRow">
                        <Url
                            value={url}
                            className="UrlLink"
                            onChangeInput={this.onChangeInput}
                            id="url"
                            label="URL"
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={borrowingReason}
                            options={borrowingReasons}
                            className="IncomeSelect"
                            id="borrowingReason"
                            label="Borrowing Reason"
                        />
                    </div>
                    <div className="ModalRowSpaceBetween">
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <Value
                                label="Loan amount"
                                onChangeInput={this.onChangeInput}
                                value={loan}
                                id="loan"
                            />
                        </div>
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <Rate
                                value={rate}
                                label="Interest Rate"
                                className="mlsInput"
                                id="interestRate"
                                onChangeInput={this.onChangeInput}
                                sign={true}
                            />
                        </div>
                    </div>
                    <div className="ModalRowSpaceBetween">
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={termUnit}
                                options={["Years", "Months"]}
                                className="select-dropdown"
                                id="termUnit"
                                label="Select Term Unit"
                            />
                        </div>
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <Rate
                                value={numTermUnits}
                                label={`Term Length in ${termUnit}`}
                                id="numTermUnits"
                                className="mlsInput"
                                onChangeInput={this.onChangeInput}
                                sign={false}
                            />
                        </div>
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={cadence}
                            options={options}
                            className="select-dropdown"
                            id="cadence"
                            label="Select Frequency"
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="loanPaymentsContainer">
                            <div className="paymentsAmountContainer">
                                <div className="paymentsLabel">
                                    Your approximate payment <br />
                                    <span className="cadenceHolder">
                                        {this.state.cadence
                                            ? this.state.cadence
                                            : "frequency payment"}
                                    </span>{" "}
                                    will be
                                </div>
                                <div className="paymentsValue">
                                    ${" "}
                                    {this.state.payments
                                        ? this.state.payments
                                        : "0.00"}
                                </div>
                            </div>

                            <div className="paymentsSubLabel">
                                You&apos;ll pay{" "}
                                {this.state.termInterest
                                    ? "$" + this.state.termInterest + " "
                                    : "$0.00 "}
                                in interest after{" "}
                                {this.state.amortization
                                    ? this.state.amortization + " "
                                    : "0 "}
                                years. <br /> This is the total interest paid
                                throughout the duration of the loan.
                            </div>
                        </div>
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <div className="keyLabel">Date</div>
                        <SingleCalendar
                            onHandleDate={this.onHandleDate}
                            date={this.state.start}
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="Required" />
                        <div className="keyLabel">Rating</div>
                        <div className="StarsHolder">
                            {stars.map((star, i) => {
                                const id = i + 1;
                                const onHover = onHoverStar;
                                if (i < rating || i < onHover) {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starFilledSvg"
                                            src={starFilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeave}
                                        />
                                    );
                                } else {
                                    return (
                                        <Stars
                                            key={i}
                                            className="starUnfilledSvg"
                                            src={starUnfilledSvg}
                                            id={id}
                                            onChangeInput={this.onChangeInput}
                                            onHandleStarHover={
                                                this.onHandleStarHover
                                            }
                                            onMouseLeave={this.onMouseLeave}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className="ModalRow">
                        <div className="RequiredField">* Required Field </div>
                    </div>
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                    isLibraryEvent={this.state.isLibraryEvent}
                    addLibraryEventToScenario={() => {
                        this.props.addLibraryEventToScenario(this.state);
                    }}
                    divorceLibraryEvent={() => {
                        this.props.divorceFromLibraryEvent(
                            this.state,
                            this.props.updateValues
                        );
                    }}
                />
            </div>
        );
    }
}
export default LoanInput;

import tourPoint from "Assets/_onboarding/tourPoint.svg";
import MonthlyCumulativeSelection from "Components/ChartGraph/partials/MonthlyCumulativeSelection";
import GraphAccountsSelection from "./GraphAccountsSelection";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { AverageValue } from "./AverageValue";

export default function GraphTopBar() {
    const { onboardingData } = useAppSelector((state) => {
        const onboardingData = state.scenario?.onboardingData;

        return { onboardingData };
    });

    return (
        <div className="NodeGraph__TopBarContainer">
            {/*
            FLAG: Ask Daniel: What are these overlays?
            <div
                className={
                    !showPerNodeGraphingOverlay
                        ? "NodeGraph__PerEventGraphingMessage-container"
                        : "NodeGraph__PerEventGraphingMessage-container NodeGraph__PerEventGraphingMessage-container--show"
                }
            >
                <div className="NodeGraph__PerEventGraphingMessage-wrapper">
                    <span
                        className={
                            !showPerNodeGraphingOverlay
                                ? "NodeGraph__PerEventGraphingMessage--hide"
                                : "NodeGraph__PerEventGraphingMessage--show"
                        }
                    >
                        Please select an Event to view Cumulative or Individual
                        graphing details.
                    </span>
                </div>
            </div> */}

            <div style={{ height: "0px" }}>
                {onboardingData === 8 && (
                    <img
                        alt="tourPoint"
                        src={tourPoint}
                        className="nodeGraphTourPoint"
                    />
                )}
            </div>
            <div className="AmchartsData">
                <div className="AmchartsDisplay">
                    <div className="NodeGraph-DisplaySelection-wrapper">
                        <MonthlyCumulativeSelection />
                    </div>
                    <GraphAccountsSelection />
                </div>
                <AverageValue />
            </div>
        </div>
    );
}

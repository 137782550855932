import { useState } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import TextField from "@material-ui/core/TextField";
import { cloneDeep } from "lodash";
import EntityStructureData from "../../exampleConfigJson/entityTypesStructure.json";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { getManager } from "actions/getNodeEntityActions";
import store from "store";
import { useAppDispatch } from "store/useAppSelectorDispatch";
import { upsertAllAccountLedgers } from "actions/allAccountLedgersActions";
import { updateAccountsAPI } from "actions/accountsActions";

export async function importFromAi(
    imported: {
        entities: any[];
        accounts: any[];
    },
    dispatch: any
) {
    for (const importedAccount of imported.accounts) {
        const parent =
            importedAccount.path[importedAccount.path.length - 2] ??
            "5a508f09-cf61-4207-8950-28a32be3a0d8";
        const display = "$";
        const singular = "$";
        const spacingBetween = false;
        const nearestDecimal = 2;
        const beforeValue = true;

        const newAccount = {
            id: importedAccount.id,
            name: importedAccount.name,
            parents: [parent],
            children: [],
            alwaysDisplayed: false,
            type: "ledger",
            monthlyCumulativeText: {
                monthly: "per month",
                cumulative: "total",
            },
            unit: {
                display,
                spacingBetween,
                singular,
                beforeValue,
                nearestDecimal,
            },
        };

        const allAccountLedgers = {
            ...store.getState().allAccountLedgers.ledgersMetadata,
        };

        const updatedParent = { ...allAccountLedgers[parent] };
        updatedParent.children.push(importedAccount.id);

        allAccountLedgers[parent] = updatedParent;
        allAccountLedgers[importedAccount.id] = newAccount;

        dispatch(
            upsertAllAccountLedgers({
                ledgersMetadata: allAccountLedgers,
            })
        );

        await updateAccountsAPI(allAccountLedgers);
    }

    const root = getManager()?.root;
    let attachToRoot = true;

    for (const importedEntity of imported.entities) {
        const importedEntityCopy = { ...importedEntity };

        if (importedEntity == null || importedEntity.type == null) {
            attachToRoot = true;
            continue;
        }

        if (importedEntity.parents == undefined || importedEntity.parents.length === 0) {
            attachToRoot = true;
        }

        const eventObject = getObjectFromUUID(importedEntity.type);
        const newEvent = createNewEvent(eventObject);
        const newEntity = cloneDeep(EntityStructureData?.[importedEntity.type]);

        if (newEntity) {
            newEvent.name = importedEntity.name;
            newEvent.entities = [{ id: newEntity.id, active: true }];
            newEvent.mostRecentEntity = 0;
            newEvent.children = importedEntity.children;
            newEvent.id = importedEntity.id;
            newEvent.baseline = false;
            newEvent.bypassed = false;
            newEvent.locked = false;

            if (attachToRoot) {
                newEvent.parents = [root.id];
                attachToRoot = false;
            } else {
                newEvent.parents = importedEntity.parents;
            }

            delete importedEntityCopy.children;
            delete importedEntityCopy.parents;

            for (const key in importedEntityCopy) {
                newEntity[key] = importedEntity[key];
            }

            for (const dataKey in importedEntity.data) {
                newEntity.data[dataKey] = importedEntity.data[dataKey];
            }

            const newEventEntitiesMap = {
                [importedEntity.id]: newEntity,
            };

            await dispatch(
                handleSubmitNodesAndEntities(
                    (event) => addNewEvent(event, newEvent.parents, [], false),
                    updateEvent,
                    newEvent,
                    newEventEntitiesMap,
                    Object.keys(newEventEntitiesMap),
                    eventObject.checkInput(newEventEntitiesMap),
                    false,
                    {}
                )
            );

            await new Promise((resolve) => setTimeout(resolve, 200));
        } else {
            console.log("Bad type:", importedEntity.type);
            break;
        }
    }
}

export function ImportFromAiExperimental() {
    const [text, setText] = useState("");
    const dispatch = useAppDispatch();

    const importEntities = async () => {
        try {
            await importFromAi(JSON.parse(text), dispatch);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div
            style={{
                width: "200px",
                height: "400px",
                position: "absolute",
                bottom: "30px",
                right: "10px",
                zIndex: 99,
            }}
        >
            <TextField
                style={{ width: "100%", height: "100%" }}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <div onClick={importEntities} style={{ width: "156px" }}>
                Import
            </div>
        </div>
    );
}

// @ts-nocheck

import React from "react";
import { employeeObject } from "Components/Registry/Employee";
import {
    InputButtons,
    Description,
    Value,
    Rate,
    SelectDropDown,
    EventEntityWrapper,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    EntityCard,
    EventDetails,
    EntityName,
    ToggledContainer,
    ToggledContainerHalf,
    MuiCalendar,
    BasicHeading,
} from "./../Components";
import Switch from "react-switch";
import LocationSearchInput from "../../LocationSearchInput";
import "./../InputContainer.css";
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { InputDropDownButton } from "Components/AccountSelectionWaterfall/buttons/InputDropDownButton";

const cadenceOptions = [
    "annually",
    "quarterly",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
];

const expenseTypes = ["Employment Expenses", "Cost of Labor"];

export default function EmployeeInputView({
    currentEntity,
    entitiesLength,
    entitiesMap,
    entityIndex,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    handleClickAddEntityCard,
    toggleEmployeeState,
    togglePayType,
    onHandleLocation,
    onChangeNameDescription,
    updateInflation,
    onHandleSubmit,
    edit,
    passedCheck,
    eventData,
    handleEntityStateChange,
    handleOnChange,
    handleDisplayFinancialDataToggle,
    handleDateSelection,
    updateAccount,
}) {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={employeeObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={employeeObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[employeeObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ToggledContainer>
                    <ToggledContainerHalf
                        id="specific"
                        isSelected={
                            entitiesMap[currentEntity].data.employeeState ===
                            "specific"
                        }
                        onClick={toggleEmployeeState}
                        title="Specific"
                    />
                    <ToggledContainerHalf
                        id="placeholder"
                        isSelected={
                            entitiesMap[currentEntity].data.employeeState ===
                            "placeholder"
                        }
                        onClick={toggleEmployeeState}
                        title="Placeholder"
                    />
                </ToggledContainer>
                <ModalRow>
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity].data.expenseType}
                        options={expenseTypes}
                        // className="mlsInput"
                        id="expenseType"
                        label="Expense Type"
                        required={true}
                    />
                </ModalRow>
                <ModalRow>
                    <InputDropDownButton
                        callback={updateAccount}
                        initialValue={
                            entitiesMap?.[currentEntity]?.data?.accountName ??
                            ""
                        }
                        topLevelFilters={{
                            "e18faebd-5cb1-40ac-8c18-442943677d4a": true,
                        }}
                        buttonLabel="Select Employee Account"
                        required={true}
                    />
                </ModalRow>
                <ModalRow>
                    <LocationSearchInput
                        id="address"
                        handleLocation={onHandleLocation}
                        value={entitiesMap[currentEntity].data.address}
                        edit={edit}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Rate
                            value={entitiesMap[currentEntity].data.averageHours}
                            label="Average Hrs/week"
                            className="mlsInput"
                            id="averageHours"
                            onChangeInput={handleOnChange}
                            sign={true}
                            required={true}
                        />
                    </ModalRowHalf>
                    {entitiesMap[currentEntity].data.employeeState ===
                        "placeholder" && (
                        <ModalRowHalf>
                            <Rate
                                value={
                                    entitiesMap[currentEntity].data.noEmployees
                                }
                                label="No. of Employees"
                                id="noEmployees"
                                className="mlsInput"
                                onChangeInput={handleOnChange}
                                sign={false}
                                required={true}
                            />
                        </ModalRowHalf>
                    )}
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="startDate"
                            value={entitiesMap?.[currentEntity]?.startDate}
                            required={true}
                            onChangeInput={handleDateSelection}
                            label="Start Date"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <MuiCalendar
                            id="endDate"
                            value={entitiesMap?.[currentEntity]?.endDate}
                            onChangeInput={handleDateSelection}
                            label="End Date"
                            helperText="An end date is optional"
                        />
                    </ModalRowHalf>
                </ModalRow>
                <BasicHeading copy="Financial Data" margin="2rem 0 1rem" />
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <Switch
                            id="displayFinancialData"
                            checked={
                                entitiesMap?.[currentEntity]?.data
                                    ?.displayFinancialData
                            }
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={handleDisplayFinancialDataToggle}
                            onColor="#F8B46A"
                        />
                        <div className="InflationText">
                            Display Financial Data
                        </div>
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <Switch
                            id="inflation"
                            checked={entitiesMap[currentEntity].data.inflation}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={updateInflation}
                            onColor="#F8B46A"
                        />
                        <div className="InflationText">Apply inflation</div>
                    </ModalRowHalf>
                </ModalRow>
                {entitiesMap?.[currentEntity]?.data?.displayFinancialData && (
                    <>
                        <ToggledContainer>
                            <ToggledContainerHalf
                                id="hourly"
                                isSelected={
                                    entitiesMap[currentEntity].data.payType ===
                                    "hourly"
                                }
                                onClick={togglePayType}
                                title="Hourly"
                            />
                            <ToggledContainerHalf
                                id="salary"
                                isSelected={
                                    entitiesMap[currentEntity].data.payType ===
                                    "salary"
                                }
                                onClick={togglePayType}
                                title="Salary"
                            />
                        </ToggledContainer>

                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                {entitiesMap[currentEntity].data.payType ===
                                "hourly" ? (
                                    <Value
                                        label="Hourly Rate"
                                        onChangeInput={handleOnChange}
                                        value={
                                            entitiesMap[currentEntity].data.rate
                                        }
                                        id="rate"
                                        unit="$"
                                        required={true}
                                    />
                                ) : (
                                    <Value
                                        label="Annual Salary"
                                        onChangeInput={handleOnChange}
                                        value={
                                            entitiesMap[currentEntity].data
                                                .salary
                                        }
                                        id="salary"
                                        unit="$"
                                        required={true}
                                    />
                                )}
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={entitiesMap[currentEntity].cadence}
                                    options={cadenceOptions}
                                    // className="mlsInput"
                                    id="cadence"
                                    label="Payment Frequency"
                                    required={true}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

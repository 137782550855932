export const DOMRectInitialState = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    toJSON: () => {
        // noop
    },
    top: 0,
    width: 0,
    x: 0,
    y: 0,
};

import React, { Component } from "react";
import "./Card.css";
import moment from "moment";
import {
    CadenceValue,
    CardContainer,
    Description,
    DollarValue,
    PercentValue,
    TextValue,
    Row,
    RowLabel,
} from "./Components";

class MortgageCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const {
            rate,
            downpayment,
            downpaymentRate,
            price,
            payments,
            description,
            isNew,
            cadence,
            version,
        } = this.props.data.metadata;
        return (
            <CardContainer>
                <Row>
                    <Description text={description} />
                </Row>
                <Row>
                    <RowLabel text={isNew ? "Purchase Price" : "Principal"} />
                    <DollarValue value={price} />
                </Row>
                <Row>
                    <RowLabel text="Payments" />
                    <DollarValue value={payments} />
                </Row>
                <Row>
                    <RowLabel text="Payment Frequency" />
                    <CadenceValue cadence={cadence} />
                </Row>
                <Row>
                    <RowLabel text="Down Payment" />
                    <DollarValue value={downpayment} />
                </Row>
                <Row>
                    <RowLabel text="Down Payment %" />
                    <PercentValue value={downpaymentRate} />
                </Row>
                <Row>
                    <RowLabel text="Term" />
                    <TextValue
                        text={this.props.data.metadata["mortgage term"]}
                    />
                </Row>
                <Row>
                    <RowLabel text="Mortgage Rate" />
                    <PercentValue value={rate} />
                </Row>
                <Row>
                    <RowLabel text="Date" />
                    <TextValue
                        text={
                            this.props.data.metadata["first payment"]
                                ? moment(
                                      this.props.data.metadata["first payment"]
                                  ).format("MMM D YYYY")
                                : "No Date"
                        }
                    />
                </Row>
                <Row>
                    <RowLabel text="Amortization Period" />
                    <TextValue
                        text={this.props.data.metadata["amortization period"]}
                    />
                </Row>
                <Row>
                    <RowLabel text="Version" />
                    <TextValue text={version} />
                </Row>
            </CardContainer>
        );
    }
}

export default MortgageCard;

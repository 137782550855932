import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import {
    getAccountColumnData,
    getAccountRowData,
} from "./helpers/accountRowColumnData";
import "./accountDelveView.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import Switch from "react-switch";
import MenuItem from "@mui/material/MenuItem";
import { topLevelAccountIds } from "helpers/constants";

export const AccountDelveView = () => {
    const [cumulative, setCumulative] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<any>(null);

    const [rowData, setRowData] = useState<any[]>([]);

    const [columnDefs, setColumnDefs] = useState<any[]>([]);

    const currentThreadLedgerData = useAppSelector((state) => {
        const allThreads = state?.calculatedThreads;
        const currentThread = state?.scenario?.highlightedThread?.signature;
        const currentThreadLedgerData =
            allThreads?.[currentThread]?.ledgersData ?? {};

        return currentThreadLedgerData;
    }, shallowEqual);

    const { ledgersMetadata, relevantLedgers, relevantLedgersMap } =
        useAppSelector((state) => {
            const ledgersMetadata = state?.allAccountLedgers?.ledgersMetadata;
            const relevantLedgers: any =
                state?.allAccountLedgers?.relevantLedgers;

            const relevantLedgersMap: any = {};

            relevantLedgers.forEach(({ _name, id }) => {
                relevantLedgersMap[id] = ledgersMetadata?.[id] ?? false;
            });
            return {
                ledgersMetadata,
                relevantLedgers,
                relevantLedgersMap,
            };
        }, shallowEqual);

    const availableTopLevelAccounts = useMemo(() => {
        const availableTopLevelAccounts: string[] = [];
        relevantLedgers.forEach((ledger) => {
            if (topLevelAccountIds[ledger.id]) {
                availableTopLevelAccounts.push(ledger.id);
            }
        });
        return availableTopLevelAccounts;
    }, [relevantLedgers]);

    const availableChildAccounts = useMemo(() => {
        const availableChildAccounts: any = [];
        if (selectedAccount?.children?.length > 0) {
            selectedAccount?.children?.forEach((childAccountId) => {
                const relevantChildAccount =
                    relevantLedgersMap?.[childAccountId];
                if (relevantChildAccount)
                    availableChildAccounts.push(relevantChildAccount);
            });
        }

        return availableChildAccounts;
    }, [relevantLedgersMap, selectedAccount?.children]);

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            filter: true,
            resizable: true,
            width: 125,
        };
    }, []);

    useEffect(() => {
        if (selectedAccount) {
            const selecedAccountId = cumulative
                ? `Cumulative-${selectedAccount?.id}`
                : selectedAccount?.id;
            const currentThreadSelectedLedgerData =
                currentThreadLedgerData?.[selecedAccountId];
            const columnData = getAccountColumnData(
                selectedAccount,
                currentThreadSelectedLedgerData
            );
            setColumnDefs(columnData);

            const rowData = getAccountRowData(
                availableChildAccounts,
                currentThreadLedgerData,
                cumulative
            );
            setRowData(rowData);
        } else {
            setRowData([]);
            setColumnDefs([]);
        }
        // eslint-disable-next-line
    }, [currentThreadLedgerData, selectedAccount, cumulative]);

    const handleAccountSelection = (accountId) => {
        const selectedAccount = ledgersMetadata?.[accountId] ?? null;
        setSelectedAccount(selectedAccount);
    };

    return (
        <div className="table-chart-tab__container">
            <div className="accountDelveView">
                <div className="accountDelveView__accountSelector">
                    <div className="cumulativeToggleContainer">
                        <Switch
                            checked={cumulative}
                            height={20}
                            width={40}
                            onChange={() => setCumulative(!cumulative)}
                            onColor="#F8B46A"
                        />
                        <span className="cumulativeToggleContainer__text">
                            Cumulative
                        </span>
                    </div>
                    {selectedAccount && (
                        <div className="selectionContainer">
                            <div className="selectionContainer__header">
                                <div
                                    className="selectionContainer__icon"
                                    onClick={() =>
                                        handleAccountSelection(
                                            selectedAccount?.parents?.[0] ??
                                                null
                                        )
                                    }
                                ></div>
                                <span className="selectionContainer__text">
                                    {selectedAccount?.name ?? ""}
                                </span>
                            </div>
                            <div className="optionsList">
                                {availableChildAccounts?.length > 0 ? (
                                    availableChildAccounts?.map(
                                        (childAccount) => (
                                            <MenuItem
                                                key={childAccount?.id}
                                                onClick={() =>
                                                    handleAccountSelection(
                                                        childAccount?.id
                                                    )
                                                }
                                            >
                                                <span className="optionsList__text">
                                                    {childAccount?.name ?? ""}
                                                </span>
                                            </MenuItem>
                                        )
                                    )
                                ) : (
                                    <span className="optionsList__noOptionsText">
                                        No Child Accounts
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    {!selectedAccount && (
                        <div className="optionsList">
                            {availableTopLevelAccounts?.map(
                                (availableTopLevelAccountId) => (
                                    <MenuItem
                                        key={availableTopLevelAccountId}
                                        onClick={() =>
                                            handleAccountSelection(
                                                availableTopLevelAccountId
                                            )
                                        }
                                    >
                                        <span className="optionsList__text">
                                            {ledgersMetadata?.[
                                                availableTopLevelAccountId
                                            ]?.name ?? ""}
                                        </span>
                                    </MenuItem>
                                )
                            )}
                        </div>
                    )}
                </div>
                <div className="accountDelveView__gridContainer">
                    <div className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                            defaultColDef={defaultColDef}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

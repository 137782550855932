//black
import incomeTaxBlack from "../Assets/_eventNodeTypes/incomeTax-black.png";
import daycareBlack from "../Assets/_eventNodeTypes/dayCare-black.png";
import nannyBlack from "../Assets/_eventNodeTypes/nanny-black.png";
import commuteBlack from "../Assets/_eventNodeTypes/commute-black.png";
import childCareBlack from "../Assets/_eventNodeTypes/childCare-black.png";
import childCareTaxBlack from "../Assets/_eventNodeTypes/childCareTax-black.png";
import childNodeBlack from "../Assets/_eventNodeTypes/childNode-black.png";
import containerBlack from "../Assets/_eventNodeTypes/container-black.png";
import parentalBlack from "../Assets/_eventNodeTypes/parental-black.png";
import leaseBlack from "../Assets/_eventNodeTypes/lease-black.png";
import oldAgeBlack from "../Assets/_eventNodeTypes/oldAge-black.png";
import drawdownsBlack from "../Assets/_eventNodeTypes/drawdowns-black.png";
import tfsaBlack from "../Assets/_eventNodeTypes/tfsa-black.png";
import customersBlack from "../Assets/_eventNodeTypes/customers-black.png";
import serviceBlack from "../Assets/_eventNodeTypes/service-black.png";
import growthBlack from "../Assets/_eventNodeTypes/growth-black.png";
import productBlack from "../Assets/_eventNodeTypes/product-black.png";
import inventoryBlack from "../Assets/_eventNodeTypes/inventory-black.png";
import churnBlack from "../Assets/_eventNodeTypes/churn-black.png";

//blue
import containerBlue from "../Assets/_eventNodeTypes/container-blue.png";
import parentalBlue from "../Assets/_eventNodeTypes/parental-blue.png";
import leaseBlue from "../Assets/_eventNodeTypes/lease-blue.png";
import oldAgeBlue from "../Assets/_eventNodeTypes/oldAge-blue.png";
import drawdownsBlue from "../Assets/_eventNodeTypes/drawdowns-blue.png";
import tfsaBlue from "../Assets/_eventNodeTypes/tfsa-blue.png";
import customersBlue from "../Assets/_eventNodeTypes/customers-blue.png";
import serviceBlue from "../Assets/_eventNodeTypes/service-blue.png";
import growthBlue from "../Assets/_eventNodeTypes/growth-blue.png";
import productBlue from "../Assets/_eventNodeTypes/product-blue.png";
import inventoryBlue from "../Assets/_eventNodeTypes/inventory-blue.png";
import churnBlue from "../Assets/_eventNodeTypes/churn-blue.png";

//white
import containerWhite from "../Assets/_eventNodeTypes/container-white.png";
import parentalWhite from "../Assets/_eventNodeTypes/parental-white.png";
import leaseWhite from "../Assets/_eventNodeTypes/lease-white.png";
import oldAgeWhite from "../Assets/_eventNodeTypes/oldAge-white.png";
import drawdownsWhite from "../Assets/_eventNodeTypes/drawdowns-white.png";
import tfsaWhite from "../Assets/_eventNodeTypes/tfsa-white.png";
import customersWhite from "../Assets/_eventNodeTypes/customers-white.png";
import serviceWhite from "../Assets/_eventNodeTypes/service-white.png";
import growthWhite from "../Assets/_eventNodeTypes/growth-white.png";
import productWhite from "../Assets/_eventNodeTypes/product-white.png";
import inventoryWhite from "../Assets/_eventNodeTypes/inventory-white.png";
import churnWhite from "../Assets/_eventNodeTypes/churn-white.png";

//grey
import containerGrey from "../Assets/_eventNodeTypes/container-grey.png";
import parentalGrey from "../Assets/_eventNodeTypes/parental-grey.png";
import leaseGrey from "../Assets/_eventNodeTypes/lease-grey.png";
import oldAgeGrey from "../Assets/_eventNodeTypes/oldAge-grey.png";
import drawdownsGrey from "../Assets/_eventNodeTypes/drawdowns-grey.png";
import tfsaGrey from "../Assets/_eventNodeTypes/tfsa-grey.png";
import customersGrey from "../Assets/_eventNodeTypes/customers-grey.png";
import serviceGrey from "../Assets/_eventNodeTypes/service-grey.png";
import growthGrey from "../Assets/_eventNodeTypes/growth-grey.png";
import productGrey from "../Assets/_eventNodeTypes/product-grey.png";
import inventoryGrey from "../Assets/_eventNodeTypes/inventory-grey.png";
import churnGrey from "../Assets/_eventNodeTypes/churn-grey.png";

import {
    Container,
    Commute,
    Daycare,
    fourZeroOneK,
    Nanny,
    Childcare,
    ChildcareTax,
    ChildNode,
    MortgageUSA,
    IncomeTaxUSA,
    Parental,
    Lease,
    OldAge,
    TFSA,
    Drawdowns,
    Customers,
    Service,
    Growth,
    Product,
    Inventory,
    Churn,
} from "../helpers/constants";
import { rrspObject } from "Components/Registry/RRSP";
import { mortgageObject } from "Components/Registry/Mortgage";
import { events } from "Components/InputContainer/Master Registry";

// Todo, add into events registry when created
const NodeTypes = [
    //grey
    {
        type: Container,
        image: containerGrey,
    },
    {
        type: Parental,
        image: parentalGrey,
    },
    {
        type: Lease,
        image: leaseGrey,
    },
    {
        type: OldAge,
        image: oldAgeGrey,
    },
    {
        type: TFSA,
        image: tfsaGrey,
    },
    {
        type: Drawdowns,
        image: drawdownsGrey,
    },
    {
        type: Customers,
        image: customersGrey,
    },
    {
        type: Service,
        image: serviceGrey,
    },
    {
        type: Growth,
        image: growthGrey,
    },
    {
        type: Product,
        image: productGrey,
    },
    {
        type: Inventory,
        image: inventoryGrey,
    },
    {
        type: Churn,
        image: churnGrey,
    },
];

const NodeTypesBlue = [
    {
        type: Container,
        image: containerBlue,
    },
    {
        type: Parental,
        image: parentalBlue,
    },
    {
        type: Lease,
        image: leaseBlue,
    },
    {
        type: OldAge,
        image: oldAgeBlue,
    },
    {
        type: TFSA,
        image: tfsaBlue,
    },
    {
        type: Drawdowns,
        image: drawdownsBlue,
    },
    {
        type: Customers,
        image: customersBlue,
    },
    {
        type: Service,
        image: serviceBlue,
    },
    {
        type: Growth,
        image: growthBlue,
    },
    {
        type: Product,
        image: productBlue,
    },
    {
        type: Inventory,
        image: inventoryBlue,
    },
    {
        type: Churn,
        image: churnBlue,
    },
];

const NodeTypesWhite = [
    {
        type: Container,
        image: containerWhite,
    },
    {
        type: Parental,
        image: parentalWhite,
    },
    {
        type: Lease,
        image: leaseWhite,
    },
    {
        type: OldAge,
        image: oldAgeWhite,
    },
    {
        type: TFSA,
        image: tfsaWhite,
    },
    {
        type: Drawdowns,
        image: drawdownsWhite,
    },
    {
        type: Customers,
        image: customersWhite,
    },
    {
        type: Service,
        image: serviceWhite,
    },
    {
        type: Growth,
        image: growthWhite,
    },
    {
        type: Product,
        image: productWhite,
    },
    {
        type: Inventory,
        image: inventoryWhite,
    },
    {
        type: Churn,
        image: churnWhite,
    },
];

const NodeTypesBlack = [
    {
        type: Container,
        image: containerBlack,
    },
    {
        type: Commute,
        image: commuteBlack,
    },
    {
        type: fourZeroOneK,
        image: rrspObject.colouredPngs().get("black"),
    },
    {
        type: Nanny,
        image: nannyBlack,
    },
    {
        type: Daycare,
        image: daycareBlack,
    },
    {
        type: Childcare,
        image: childCareBlack,
    },
    {
        type: ChildcareTax,
        image: childCareTaxBlack,
    },
    {
        type: ChildNode,
        image: childNodeBlack,
    },
    {
        type: MortgageUSA,
        image: mortgageObject.colouredPngs().get("black"),
    },
    {
        type: IncomeTaxUSA,
        image: incomeTaxBlack,
    },
    {
        type: Parental,
        image: parentalBlack,
    },
    {
        type: Lease,
        image: leaseBlack,
    },
    {
        type: OldAge,
        image: oldAgeBlack,
    },
    {
        type: TFSA,
        image: tfsaBlack,
    },
    {
        type: Drawdowns,
        image: drawdownsBlack,
    },
    {
        type: Customers,
        image: customersBlack,
    },
    {
        type: Service,
        image: serviceBlack,
    },
    {
        type: Growth,
        image: growthBlack,
    },
    {
        type: Product,
        image: productBlack,
    },
    {
        type: Inventory,
        image: inventoryBlack,
    },
    {
        type: Churn,
        image: churnBlack,
    },
];
// Grabbing from data
events.forEach(function (event) {
    NodeTypes.push({
        type: event.constant(),
        image: event.colouredPngs().get("grey"),
    });
    NodeTypesBlue.push({
        type: event.constant(),
        image: event.colouredPngs().get("blue"),
    });
    NodeTypesWhite.push({
        type: event.constant(),
        image: event.colouredPngs().get("white"),
    });
    NodeTypesBlack.push({
        type: event.constant(),
        image: event.colouredPngs().get("black"),
    });
});

export { NodeTypes, NodeTypesBlue, NodeTypesWhite, NodeTypesBlack };

import React, { Component } from "react";
import "./Card.css";
import moment from "moment";
import { capitalizeFirstHelper } from "../../helpers";

class LumpSumPaymentsCard extends Component {
    render() {
        const { description, nodeType, cadence, value, version } =
            this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description || ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Payment Amount</div>
                    <div className="cardNumber">
                        {value === null || value === undefined
                            ? ""
                            : `$ ${value}`}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Cadence</div>
                    <div className="cardValue">
                        {capitalizeFirstHelper(cadence)}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Node Type Affected</div>
                    <div className="cardValue">{nodeType}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Start</div>
                    <div className="cardValue">
                        {this.props.data.metadata["start"]
                            ? moment(this.props.data.metadata["start"]).format(
                                  "MMM D YYYY"
                              )
                            : "No Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">End</div>
                    <div className="cardValue">
                        {this.props.data.metadata["end"]
                            ? moment(this.props.data.metadata["end"]).format(
                                  "MMM D YYYY"
                              )
                            : "No Date"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
            </div>
        );
    }
}

export default LumpSumPaymentsCard;

import { CSVBoxButton } from "@csvbox/react";
import { CsvImportButton } from "../../Components";
import { accountId } from "actions/scenario";
import {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";

import * as uuid from "uuid";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { createNewEvent, EventObject } from "helpers/createNewEvent";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import { getTodayDateString } from "../../OnInputChangeHandlers/initialBalanceInputsHandler";
import { setShowAccountFieldMappingModal } from "actions/accountFieldMappingActions";
import {
    FieldMappingData,
    FieldMappingDataMapped,
    FieldType,
} from "reducers/typesSchema/fieldMappingSchema";

// === TYPES ===
export type CsvMap = {
    [csvEntityId: string]: {
        entityId: string;
        eventId: string;
    };
};

export type ManuallyMappedFields = {
    columnName: string;
    fieldType: FieldType;
    newName: string;
}[];

type ImportEventAdapter = {
    getCsvIdToEventName: (rows: {}) => { [name: string]: string };
    getNewEntitiesMap: (rows: {}, csvMap: CsvMap) => EntitiesSchema;
    csvboxDynamicColumns: {}[];
    eventObject: EventObject;
};

export type ImportEventProps = {
    entitiesMap: EntitiesSchema;
    currentEntity: string;
    eventId: string;
    manuallyMappedFields: ManuallyMappedFields;
    setEntitiesMap: Function;
    onHandleSubmit: Function;
    dispatch: Function;
};

type ImportEventBaseProps = ImportEventAdapter & ImportEventProps;

const getStartEventCreation = (props: ImportEventBaseProps, data) => {
    const {
        getCsvIdToEventName,
        getNewEntitiesMap,
        eventObject,
        entitiesMap,
        currentEntity,
        manuallyMappedFields,
        eventId,
        setEntitiesMap,
        onHandleSubmit,
        dispatch,
    } = props;
    return async (fieldMappingData: FieldMappingDataMapped) => {
        const filteredRows = data.rows.filter((row: {}) => {
            return "_unmapped_data" in row && "_dynamic_data" in row;
        });

        for (const [newName, mappingData] of Object.entries(fieldMappingData)) {
            const manuallyMappedField = manuallyMappedFields.find(
                (e) => e.newName === newName
            );
            if (!manuallyMappedField) {
                continue;
            }
            for (const row of filteredRows) {
                const value = row._dynamic_data[manuallyMappedField.columnName];
                if (value in mappingData) {
                    row._dynamic_data[manuallyMappedField.newName] =
                        mappingData[value];
                }
            }
        }

        const csvIdToEventName = getCsvIdToEventName(filteredRows);
        const { csvMap, eventIds, csvEventMap } = createImportEntityData(
            csvIdToEventName,
            entitiesMap[currentEntity]
        );
        const resourceEntitiesMap = getNewEntitiesMap(filteredRows, csvMap);

        // TODO: As we add more events, we'll have a better idea as to whether we want
        // to bypass unmapped events, override them completely, etc. Perhaps this will
        // involve a toggle for the user (or a flag per event) to determine this
        // behaviour.
        // bypassUnmappedEvents(eventIds, csvEventMap);
        await createUpdateCsvEvents(
            resourceEntitiesMap,
            entitiesMap[currentEntity]?.data?.csvMap ?? {},
            csvMap,
            eventIds,
            csvEventMap,
            eventId,
            dispatch,
            eventObject
        );
        saveImportEntity(
            entitiesMap,
            currentEntity,
            csvMap,
            eventIds,
            csvEventMap,
            setEntitiesMap,
            onHandleSubmit
        );
    };
};

// === MAIN COMPONENT ===
export default function ImportEventBase(props: ImportEventBaseProps) {
    const {
        csvboxDynamicColumns,
        entitiesMap,
        currentEntity,
        manuallyMappedFields,
    } = props;
    return (
        <CSVBoxButton
            licenseKey={process.env.REACT_APP_CSVBOX_LICENSE_KEY ?? ""}
            user={{
                user_id: accountId(),
            }}
            dynamicColumns={csvboxDynamicColumns}
            onImport={async (result, data) => {
                if (!result) return;

                const filteredRows = data.rows.filter((row: {}) => {
                    return "_unmapped_data" in row && "_dynamic_data" in row;
                });

                if (manuallyMappedFields.length !== 0) {
                    const fieldMappingData: FieldMappingData = {};

                    for (const {
                        columnName,
                        fieldType,
                        newName,
                    } of manuallyMappedFields) {
                        fieldMappingData[newName] = {
                            type: fieldType,
                            data: {},
                        };
                        for (const row of filteredRows) {
                            const data = row._dynamic_data[columnName];
                            if (
                                typeof data === "number" ||
                                typeof data === "string"
                            ) {
                                fieldMappingData[newName].data[data] = null;
                            }
                        }
                    }

                    setShowAccountFieldMappingModal({
                        show: true,
                        entity: entitiesMap[currentEntity],
                        fieldMappingData: fieldMappingData,
                        create: getStartEventCreation(props, data),
                    });
                } else {
                    getStartEventCreation(props, data)({});
                }
            }}
            render={(launch, isLoading) => {
                return (
                    <>
                        {!isLoading && <CsvImportButton onClick={launch} />}
                        {isLoading && <div>Readying Importer...</div>}
                    </>
                );
            }}
        >
            Import
        </CSVBoxButton>
    );
}

// === HELPERS ===
const getEventIdToName = (csvEventMap: { [eventName: string]: string }) => {
    const eventIdToName = {};
    for (const [eventName, eventId] of Object.entries(csvEventMap)) {
        eventIdToName[eventId] = eventName;
    }
    return eventIdToName;
};

/**
 * Creates (csvMap, eventIds, csvEventMap) and saves the Import metadata.
 * */
const createImportEntityData = (
    csvIdToEventName: { [csvId: string]: string },
    currentEntity: EntitySchema
) => {
    const oldEntityData = currentEntity?.data;

    const csvMap: CsvMap = {};
    const eventIds: string[] = [];
    const csvEventMap: { [eventName: string]: string } = {};

    // Keep event id of old mappings if they exist in the scenario and are still
    // being used by the new mapping
    if (
        "csvMap" in oldEntityData &&
        "eventIds" in oldEntityData &&
        "csvEventMap" in oldEntityData
    ) {
        const newEventNames = new Set(Object.values(csvIdToEventName));
        const oldEventIdToName = getEventIdToName(oldEntityData.csvEventMap);
        for (const eventId of oldEntityData.eventIds) {
            if (
                !(eventId in oldEventIdToName) ||
                getEvent(eventId) === undefined
            )
                continue;
            if (newEventNames.has(oldEventIdToName[eventId])) {
                eventIds.push(eventId);
            }
            // Preserve eventName -> eventId mapping even if the new import does not use it
            csvEventMap[oldEventIdToName[eventId]] = eventId;
        }
    }

    // Populate entities into csvMap and add new events if they don't already exist
    for (const [csvId, eventName] of Object.entries(csvIdToEventName)) {
        if (csvId in oldEntityData.csvMap) {
            const eventId = oldEntityData.csvMap[csvId].eventId;
            const entityId = oldEntityData.csvMap[csvId].entityId;
            const event = getEvent(eventId);
            // Use existing ids if both event and entity already exist in the scenario
            if (
                event !== undefined &&
                event.entities.some((entry) => entry.id === entityId)
            ) {
                csvMap[csvId] = { eventId, entityId };
                continue;
            }
        }

        if (eventName in csvEventMap) {
            csvMap[csvId] = {
                entityId: uuid.v4(),
                eventId: csvEventMap[eventName],
            };
            continue;
        }

        const eventId = uuid.v4();
        csvMap[csvId] = { entityId: uuid.v4(), eventId };
        if (!(eventName in csvEventMap)) {
            eventIds.push(eventId);
        }
        csvEventMap[eventName] = eventId;
    }

    return { csvMap, eventIds, csvEventMap };
};

/**
 * Save metadata associated with current import into the Import Event.
 */
const saveImportEntity = (
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    csvMap: CsvMap,
    eventIds: string[],
    csvEventMap: { [eventName: string]: string },
    setEntitiesMap: Function,
    onHandleSubmit: Function
) => {
    const newEntitiesMap = { ...entitiesMap };
    const newEntityObject = { ...(entitiesMap[currentEntity] || {}) };
    const data = { ...(newEntityObject.data || {}) };

    data.csvMap = csvMap;
    data.eventIds = eventIds;
    data.csvEventMap = csvEventMap;
    data.lastUpdated = getTodayDateString();

    newEntityObject.data = data;
    newEntitiesMap[currentEntity] = newEntityObject;
    setEntitiesMap(newEntitiesMap);
    onHandleSubmit(newEntitiesMap);
};

// Bypass events that are no longer being imported
//const bypassUnmappedEvents = (
//    eventIds: string[],
//    csvEventMap: { [eventName: string]: string }
//) => {
//    const mappedEventsSet = new Set(eventIds);
//    const eventIdsToBypass: string[] = [];
//    for (const eventId of Object.values(csvEventMap)) {
//        if (!mappedEventsSet.has(eventId)) {
//            eventIdsToBypass.push(eventId);
//        }
//    }
//    bypassEvents(eventIdsToBypass);
//};

// The purpose of this function is to provide the correct parents and children events for any new events
// that we are creating. If the event is the first event, we always attach it to the parent import event. Otherwise
// we find the most recent import generated event and it becomes our new events parent, and its children become our new
// events children.
const createAddEvent = (eventId: string, index: number, eventIds: string[]) => {
    let parents: string[] = [];
    let children: string[] = [];
    if (index === 0) {
        const eventNode = getEvent(eventId, true);
        if (eventNode) {
            parents = [eventNode.id];
            children = eventNode.children;
        }
    } else {
        const eventNode = getEvent(eventIds[index - 1], true);
        if (eventNode) {
            parents = [eventNode.id];
            children = eventNode.children;
        }
    }

    return (event: EventStructure) => {
        addNewEvent(event, parents ?? [], children, true);
    };
};

/**
 * Create events in scenario canvas placed following the Import Event. Also upload their
 * associated entities.
 * */
const createUpdateCsvEvents = async (
    importEntitiesMap: EntitiesSchema,
    oldCsvMap: CsvMap,
    csvMap: CsvMap,
    eventIds: string[],
    csvEventMap: { [eventName: string]: string },
    importEventId: string,
    dispatch: Function,
    eventObject: EventObject
) => {
    const oldMappedEntityIds = new Set(
        Object.values(oldCsvMap).map((entry) => entry.entityId)
    );
    const eventIdToName = getEventIdToName(csvEventMap);
    const eventIdToEntityIds: { [id: string]: Set<string> } = {};
    for (const { entityId, eventId } of Object.values(csvMap)) {
        if (!(eventId in eventIdToEntityIds)) {
            eventIdToEntityIds[eventId] = new Set();
        }
        eventIdToEntityIds[eventId].add(entityId);
    }

    for (let i = 0; i < eventIds.length; i++) {
        const eventId = eventIds[i];
        let event: EventStructure = createNewEvent(eventObject);
        event.entities = [];
        event.id = eventId;
        event.name = eventIdToName[eventId];

        const originalEvent = getEvent(eventId, true);
        const unmappedOriginalEventEntities = new Set<string>();
        if (originalEvent) {
            event = {
                ...event,
                name: originalEvent.name,
                parents: originalEvent.parents,
                children: originalEvent.children,
                description: originalEvent.description,
                baseline: originalEvent.baseline,
                bypassed: originalEvent.bypassed,
                locked: originalEvent.locked,
                version: originalEvent.version,
                entities: originalEvent.entities.filter(({ id }) => {
                    const keep = eventIdToEntityIds[eventId].delete(id);
                    if (!keep) unmappedOriginalEventEntities.add(id);
                    return keep;
                }),
            };
        }

        // New entities not in originalEvent entities list
        eventIdToEntityIds[eventId].forEach((id) => {
            event.entities.push({ id, active: true });
        });

        // Copy custom-created entities from original event
        unmappedOriginalEventEntities.forEach((id) => {
            if (!oldMappedEntityIds.has(id)) {
                event.entities.push({ id, active: true });
            }
        });

        const oldEntityData = getRelevantEntities(event.entities);
        const eventEntitiesMap: EntitiesSchema = {};
        for (const { id } of event.entities) {
            if (id in importEntitiesMap) {
                eventEntitiesMap[id] = importEntitiesMap[id];

                // Carry over some entity fields if old entity exists
                if (id in oldEntityData) {
                    eventEntitiesMap[id].name = oldEntityData[id].name;
                    eventEntitiesMap[id].bypassState =
                        oldEntityData[id].bypassState;
                }
            }
        }

        const customAddEvent = createAddEvent(importEventId, i, eventIds);
        await dispatch(
            handleSubmitNodesAndEntities(
                customAddEvent,
                (e: EventStructure) => updateEvent(e, true),
                event,
                eventEntitiesMap,
                event.entities.map((e) => e.id),
                eventObject.checkInput(eventEntitiesMap),
                !!originalEvent,
                {}
            )
        );

        await new Promise((resolve) => setTimeout(resolve, 200));
    }
};

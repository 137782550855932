import {
    useState,
    useEffect,
    useContext,
    useMemo,
    useRef,
    MutableRefObject,
} from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import ModifierInputView from "./ModifierInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import { getDefaultName } from "../../../helpers";
import { modifierObject } from "Components/Registry/Modifier";
import type { ChangeEvent } from "react";
import { modifierInputsHandler } from "../OnInputChangeHandlers/modifierInputsHandler";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import {
    getPresentableDependencies,
    getPresentableDependenciesOfManyTypes,
} from "helpers/nodeDependencyDetectionHelpers";
import modifierEntityMap from "helpers/modifierEntityMap.json";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { getObjectFromUUID } from "helpers/getObjectFromUUID";
import moment from "moment";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import { groupObject } from "Components/Registry/Group";

export default function ModifierInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const loadedScenario: MutableRefObject<ScenarioSchema> = useRef({
        ...useAppSelector((state) => state?.scenario?.loadedScenario),
    });
    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...loadedScenario.current?.dependency_map,
    });

    const eventId = useContext(EventInputIDContext);
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );
    const defaultName = useMemo(
        () => getDefaultName(modifierObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(modifierObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        modifierObject,
        dependencyMap.current
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                setEntitiesMap(
                    modifierInputsHandler(
                        value,
                        "entityName",
                        0,
                        entitiesMap,
                        currentEntity,
                        eventId,
                        dependencyMap.current
                    )
                );
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const getValidEvents = () => {
        const events = {};

        if (!propsObject.eventId && !propsObject.line) return events;

        const validEventType: string[] = Object.keys(modifierEntityMap);

        getPresentableDependenciesOfManyTypes(
            events,
            validEventType,
            propsObject,
            "Modifiable",
            true
        );

        getPresentableDependencies(
            events,
            groupObject.constant(),
            propsObject,
            true
        );

        if (!Object.keys(events).length) {
            throwError(
                "Warning",
                "No Modifiable nodes found upstream",
                "You can still add this event to this scenario as a placeholder for future calculations"
            );
        }
        //@ts-ignore
        const eventObjects = Object.values(events)?.map((eventId: string) =>
            getEvent(eventId)
        );

        const eventsMappedByType = {};
        eventObjects?.map((event) => {
            const eventTypeName = getObjectFromUUID(event?.type)?.name();
            if (!eventsMappedByType[eventTypeName]) {
                eventsMappedByType[eventTypeName] = [];
            }
            eventsMappedByType[eventTypeName].push(event?.id);
        });
        return eventsMappedByType;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const eventsMappedByType = getValidEvents();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.eventsMappedByType =
                eventsMappedByType;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const toggleRevertValue = (checked) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        if (checked === false) {
            currentEntityObject.endDate = "";
        }
        data.revertValue = checked;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const toggleCustomEffectPeriod = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data.customEffectPeriod === true) {
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";
        }

        data.customEffectPeriod = !data.customEffectPeriod;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, setEntitiesMap]);

    const eventsMappedByType = getValidEvents();
    const availableEvents: { value: any; displayName: string }[] = [];

    for (const eventId of eventsMappedByType?.[
        entitiesMap[currentEntity]?.data.selectedEventType ?? ""
    ] ?? []) {
        const event = getEvent(eventId);
        if (event) {
            availableEvents.push({
                value: event.id,
                displayName: event.name,
            });
        }
    }

    const getEntitySummary = (currentEntity) => {
        const targetEntityData = Object.values(
            getRelevantEntities(currentEntity?.data?.selectedIds ?? [])
        )?.[0];
        if (currentEntity?.data?.modifierType === "numerical") {
            switch (currentEntity?.data?.function) {
                case "Add":
                    return `Add ${currentEntity?.data?.value ?? "Value Error"}${
                        currentEntity?.data?.cadenceType === "Recurring"
                            ? ", compounding each month,"
                            : ""
                    } to the existing ${
                        currentEntity?.data?.property ?? "Property Error"
                    } for ${
                        targetEntityData
                            ? targetEntityData?.name
                            : `${currentEntity?.data?.selectedEventName} - All`
                    }${
                        currentEntity?.data?.customEffectPeriod
                            ? ` from ${moment(currentEntity?.startDate).format(
                                  "MMM DD, YYYY"
                              )}${
                                  currentEntity?.endDate !== ""
                                      ? ` to ${moment(
                                            currentEntity?.endDate
                                        ).format("MMM DD, YYYY")}`
                                      : ""
                              }`
                            : ""
                    }. ${
                        currentEntity?.data?.revertValue
                            ? `After ${
                                  moment(currentEntity?.endDate).format(
                                      "MMM DD, YYYY"
                                  ) ?? ""
                              }, the ${
                                  currentEntity?.data?.property ??
                                  "Property Error"
                              } will revert back to its default values.`
                            : ""
                    }`;
                case "Set":
                    return `Change the existing ${
                        currentEntity?.data?.property ?? "Property Error"
                    } for ${
                        targetEntityData
                            ? targetEntityData?.name
                            : `${currentEntity?.data?.selectedEventName} - All`
                    } to ${currentEntity?.data?.value ?? "Value Error"}${
                        currentEntity?.data?.cadenceType === "Recurring"
                            ? currentEntity?.data?.customEffectPeriod
                                ? ", compounding each month,"
                                : ", compounding each month"
                            : ""
                    }${
                        currentEntity?.data?.customEffectPeriod
                            ? ` from ${moment(currentEntity?.startDate).format(
                                  "MMM DD, YYYY"
                              )}${
                                  currentEntity?.endDate !== ""
                                      ? ` to ${moment(
                                            currentEntity?.endDate
                                        ).format("MMM DD, YYYY")}`
                                      : ""
                              }`
                            : ""
                    }. ${
                        currentEntity?.data?.revertValue
                            ? `After ${
                                  moment(currentEntity?.endDate).format(
                                      "MMM DD, YYYY"
                                  ) ?? ""
                              }, the ${
                                  currentEntity?.data?.property ??
                                  "Property Error"
                              } will revert back to its default values.`
                            : ""
                    }`;
                case "Multiply":
                    return `Multiply the existing ${
                        currentEntity?.data?.property ?? "Property Error"
                    } for ${
                        targetEntityData
                            ? targetEntityData?.name
                            : `${currentEntity?.data?.selectedEventName} - All`
                    } by ${currentEntity?.data?.value ?? "Value Error"}${
                        currentEntity?.data?.cadenceType === "Recurring"
                            ? currentEntity?.data?.customEffectPeriod
                                ? ", compounding each month,"
                                : ", compounding each month"
                            : ""
                    }${
                        currentEntity?.data?.customEffectPeriod
                            ? ` from ${moment(currentEntity?.startDate).format(
                                  "MMM DD, YYYY"
                              )}${
                                  currentEntity?.endDate !== ""
                                      ? ` to ${moment(
                                            currentEntity?.endDate
                                        ).format("MMM DD, YYYY")}`
                                      : ""
                              }`
                            : ""
                    }. ${
                        currentEntity?.data?.revertValue
                            ? `After ${
                                  moment(currentEntity?.endDate).format(
                                      "MMM DD, YYYY"
                                  ) ?? ""
                              }, the ${
                                  currentEntity?.data?.property ??
                                  "Property Error"
                              } will revert back to its default values.`
                            : ""
                    }`;
                default:
                    return "Nothing to display";
            }
        } else if (currentEntity?.data?.modifierType === "date") {
            switch (currentEntity?.data?.function) {
                case "Set":
                    return `Change the existing ${
                        currentEntity?.data?.property ?? "Property Error"
                    } for ${
                        targetEntityData
                            ? targetEntityData?.name
                            : `${currentEntity?.data?.selectedEventName} - All`
                    } from ${moment(targetEntityData?.startDate).format(
                        "MMM DD, YYYY"
                    )} to ${moment(currentEntity?.data?.value).format(
                        "MMM DD, YYYY"
                    )}. Note that this does NOT offset or shift any cadenced values in this Record. Refer to our documentation for more details on how the Change Date Modifier impacts values.`;
                case "Offset":
                    return `Offset both the start and end date for
                    ${
                        targetEntityData
                            ? targetEntityData?.name
                            : `${currentEntity?.data?.selectedEventName} - All`
                    } by ${currentEntity?.data?.value ?? "Value Error"} months${
                        currentEntity?.data?.customEffectPeriod
                            ? ` from ${moment(currentEntity?.startDate).format(
                                  "MMM DD, YYYY"
                              )}${
                                  currentEntity?.endDate !== ""
                                      ? ` to ${moment(
                                            currentEntity?.endDate
                                        ).format("MMM DD, YYYY")}`
                                      : ""
                              }`
                            : ""
                    }. ${
                        currentEntity?.data?.revertValue
                            ? `After ${
                                  moment(currentEntity?.endDate).format(
                                      "MMM DD, YYYY"
                                  ) ?? ""
                              }, the ${
                                  currentEntity?.data?.property ??
                                  "Property Error"
                              } will revert back to its default values.`
                            : ""
                    }`;
                default:
                    return "Nothing to display";
            }
        }
    };

    const handleChangeCadenceType = (e: ChangeEvent<HTMLInputElement>) => {
        const newType = e.target.id;
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data?.modifierType === "numerical") {
            if (newType === "Recurring") {
                if (data?.functions?.length) data.functions.splice(1, 1);
            } else {
                data.functions = [
                    ...modifierEntityMap?.["type"]?.[data?.modifierType],
                ];
            }
            data.function = "";
            // TODO: This is misspelt everywhere, run a migration and fix
            data.feFuncitonDisplayName = "";
        }

        data.cadenceType = newType;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "entity" | "property" | "function" | "value",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = modifierInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = modifierInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const passedCheck =
        !!eventData.name && modifierObject.checkInput(entitiesMap);

    return (
        <ModifierInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            toggleRevertValue={toggleRevertValue}
            toggleCustomEffectPeriod={toggleCustomEffectPeriod}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            loadedScenario={loadedScenario}
            handleChangeCadenceType={handleChangeCadenceType}
            handleDateSelection={handleDateSelection}
            getEntitySummary={getEntitySummary}
            availableEvents={availableEvents}
        />
    );
}

export const getModifierCustomStartDate = (
    modifierStartDate: string,
    targetStartDate: string
) => {
    const modDate = moment(modifierStartDate);
    const targetDate = moment(targetStartDate);

    if (modDate.isBefore(targetDate)) return targetDate.format("YYYY-MM-DD");

    const [modY, modM, _modD] = modifierStartDate.split("-");
    const [_y, _m, d] = targetStartDate.split("-");
    if (parseInt(d) >= 29) {
        let smallestDate = parseInt(d);
        const monthsDifference = modDate.diff(targetDate, "months") + 1;
        for (let index = 0; index < monthsDifference; index++) {
            const lastDay = targetDate
                .clone()
                .add(index, "M")
                .endOf("month")
                .date();
            if (lastDay < smallestDate) smallestDate = lastDay;
        }

        return `${modY}-${modM}-${smallestDate}`;
    }
    return `${modY}-${modM}-${d}`;
};

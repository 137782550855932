import React, { Component } from "react";
import "./InputContainer.css";
import starFilledSvg from "../../Assets/star_filled.svg";
import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import {
    Name,
    Description,
    Rate,
    Value,
    Stars,
    InputButtons,
    SelectDropDown,
} from "./Components";
import { getDefaultName } from "../../helpers";
import {
    DownpaymentAmount,
    DownpaymentDependencyTypes,
    DownpaymentRate,
} from "../../helpers/downpaymentHelpers";
import {
    getClosestDependency,
    getDependencyCandidates,
    getPresentableDependenciesOfManyTypes,
} from "../../helpers/nodeDependencyDetectionHelpers";
import {
    Me,
    Person,
} from "../../helpers/constants";
import moment from "moment";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import { downpaymentObject } from "Components/Registry/Downpayment";
import { houseObject } from "Components/Registry/House";
import { rrspObject } from "Components/Registry/RRSP";
import { investmentObject } from "Components/Registry/Investment";

class DownpaymentInput extends Component {
    constructor(props) {
        super(props);

        const rrspDependencyData = this.getPeopleAndRRSPs();
        const people = rrspDependencyData.peopleWithRRSP;
        const rrspNodes = rrspDependencyData.allRRSPNodes;

        this.state = {
            name: getDefaultName(downpaymentObject.constant(), this.props),
            cadence: "one-time",
            downpayment: "",
            price: 0,
            downpaymentRate: "",
            rating: 0,
            description: "",
            showRequired: false,
            mlsNumber: null,
            type: downpaymentObject.constant(),
            start: null,
            showSettings: false,
            tag: `@${downpaymentObject.constant()}`,
            onHoverStar: null,
            valid: false,
            value: "",
            associatedType: houseObject.constant(),
            nodeTypes: DownpaymentDependencyTypes,
            baseline: false,
            lastInputChanged: DownpaymentAmount,
            justEdited: false,
            // data for RRSP as down payment:
            showRRSPSettings: false,
            people,
            rrspNodes,
            personOne: null,
            personTwo: null,
            rrspContributionOne: 0,
            rrspContributionTwo: 0,
            rrspMaxOne: 0,
            rrspMaxTwo: 0,
            personOneIsFTB: "No",
            personTwoIsFTB: "No",
            lastChangedDownpaymentProperty: DownpaymentAmount,
        };
    }

    handleDependencyDetection = (nodeType) => {
        const data = {
            curNode: null,
            isValid: false,
        };
        const dependencies = getClosestDependency(this.props, [nodeType]);
        this.processDependencies(dependencies, data, nodeType);
        if (!data.isValid) {
            throwError(
                "warning",
                `Failed to find an upstream ${nodeType} node.`,
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
            return null;
        }
        return data;
    };

    // retrieves all Person and Me nodes with corresponding RRSP & Investment ( w/ subtype RRSP) nodes
    getPeopleAndRRSPs = () => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            this.props,
            "Person"
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        const modifiedRRSPIds = [];
        const peopleWithRRSP = {};
        const { rrspNodes, rrspInvestmentNodes } = this.getRRSPCandidates(
            this.props
        );

        for (const person of Object.keys(people)) {
            for (const rrsp of rrspNodes) {
                if (rrsp.metadata.personId === people[person]) {
                    peopleWithRRSP[person] = people[person];
                    modifiedRRSPIds.push(rrsp.id);
                    break;
                }
            }
            for (const rrspInvestment of rrspInvestmentNodes) {
                if (
                    rrspInvestment.metadata.personId === people[person] &&
                    !peopleWithRRSP[person]
                ) {
                    peopleWithRRSP[person] = people[person];
                    modifiedRRSPIds.push(rrspInvestment.id);
                    break;
                }
            }
        }

        const allRRSPNodes = [];
        for (const rrsp of rrspNodes.concat(rrspInvestmentNodes)) {
            allRRSPNodes.push({
                id: rrsp.id,
                personId: rrsp.metadata.personId,
            });
        }

        return { peopleWithRRSP, allRRSPNodes };
    };

    getRRSPCandidates = (props) => {
        let rrspInvestmentNodes = [],
            rrspNodes = [];
        const dependencies = getDependencyCandidates(
            props,
            [rrspObject.constant(), investmentObject.constant()],
            null,
            false
        );
        const rrspCandidates = dependencies[rrspObject.constant()];
        const investmentCandidates = dependencies[investmentObject.constant()];

        if (dependencies && investmentCandidates) {
            for (const investment of investmentCandidates) {
                if (investment.metadata.subType === rrspObject.constant()) {
                    rrspInvestmentNodes.push(investment);
                }
            }
        }
        if (dependencies && rrspCandidates) {
            rrspNodes = rrspCandidates;
        }

        return { rrspNodes, rrspInvestmentNodes };
    };

    processDependencies = (dependencies, data, nodeType) => {
        if (dependencies && dependencies[nodeType]) {
            data.curNode = dependencies[nodeType];
            data.isValid = true;
        } else {
            data.isValid = false;
        }
    };

    onHandleDate = (startDate) => {
        this.setState({ start: startDate });
    };

    componentDidMount() {
        // TODO: support for different dependency types
        // let currentDependency = null,
        //     housePrice = 0;
        // const dependencyData = this.handleDependencyDetection(
        //     this.state.associatedType
        // );
        // if (dependencyData && dependencyData.isValid) {
        //     currentDependency = dependencyData.curNode;
        //     housePrice = Number(dependencyData.curNode.metadata.price);
        // }
        // this.setState({ currentDependency, housePrice });
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
            this.setState({ justEdited: true });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "downpayment":
                this.setState({ downpayment: value, value: value });
                break;
            case "downpaymentRate":
                this.setState({ downpaymentRate: value, value: 0 });
                this.nodeDetail = `${value || 0} %`;
                break;
            case "associatedType":
                this.setState({ associatedType: value || null });
                // TODO: support for different dependency types
                // const data = this.handleDependencyDetection(value);
                // if (!data || !data.curNode) {
                //     break;
                // }
                // this.setState({
                //     valid: data.isValid,
                // });

                // this.setState({ associatedType: value });

                // if (value === Loan) {
                //     this.setState({ price: data.curNode.metadata.loan });
                //     this.setState({
                //         start: data.curNode.metadata["first payment"],
                //     });
                // } else if (value === House) {
                //     this.setState({ price: data.curNode.metadata.price });
                //     this.setState({
                //         start: data.curNode.metadata["first payment"],
                //     });
                // }
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "personOne":
            case "personTwo":
                this.setPerson(id, value);
                break;
            case "rrspContributionOne":
                this.setState({ rrspContributionOne: Math.max(value, 0) });
                break;
            case "rrspContributionTwo":
                this.setState({ rrspContributionTwo: Math.max(value, 0) });
                break;
            case "personOneIsFTB":
            case "personTwoIsFTB":
                this.setPersonIsFTB(id, value);
                break;
            default:
        }
    };

    setPerson(id, value) {
        if (id === "personOne") {
            const rrspMax =
                this.state.personOneIsFTB === "Yes" && value ? 35000 : 0;
            this.setState({
                rrspMaxOne: rrspMax,
                personOne: value,
            });
        } else if (id === "personTwo") {
            const rrspMax =
                this.state.personTwoIsFTB === "Yes" && value ? 35000 : 0;
            this.setState({
                rrspMaxTwo: rrspMax,
                personTwo: value,
            });
        }
    }

    setPersonIsFTB(id, value) {
        if (id === "personOneIsFTB") {
            const rrspMax = value === "Yes" && this.state.personOne ? 35000 : 0;
            this.setState({
                personOneIsFTB: value,
                rrspMaxOne: rrspMax,
            });
        } else if (id === "personTwoIsFTB") {
            const rrspMax = value === "Yes" && this.state.personTwo ? 35000 : 0;

            this.setState({
                personTwoIsFTB: value,
                rrspMaxTwo: rrspMax,
            });
        }
    }

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = downpaymentObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onCancelOptional = () => {
        this.setState({ ...this.state, showSettings: false });
    };

    _getAdvancedSettingsMenu() {
        const { showRRSPSettings } = this.state;
        return (
            <div className="inputRoot">
                <button
                    onClick={this.onCancelOptional}
                    className="BackToRequired"
                >
                    Back
                </button>
                <div className="ModalRow">
                    <div className="OptionalLabel">Optional settings</div>
                </div>
                <div className="ModalRow">
                    <div
                        className="RentLabel"
                        onClick={this._onHandleRRSPSettings}
                        id="showRrspAsDP"
                    >
                        <img
                            alt="alt"
                            className="selectedIcon"
                            src={showRRSPSettings ? dropdownSvg : expandSvg}
                            onClick={this._onHandleRRSPSettings}
                            id="showRrspAsDP"
                        />
                        Use RRSP as Down Payment
                    </div>
                </div>
                {showRRSPSettings ? this._getRRSPSettingsMenu() : <></>}
            </div>
        );
    }
    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    _onHandleRRSPSettings = () => {
        this.setState({ showRRSPSettings: !this.state.showRRSPSettings });
    };

    onHandleMouseEnter = (id) => {
        let data;
        switch (id) {
            case "Person":
                data = {
                    title: "RRSPs and You",
                    content:
                        "Up to two partners may contribute to the down payment on a home with their RRSP savings.",
                    subContent:
                        "Your 'Person' and 'Me' nodes will only show up here if they have a corrresponding RRSP event",
                    link: null,
                };
                break;
            case "First Time Buyer":
                data = {
                    title: "First Time Buyers",
                    content:
                        "You can only use your RRSP savings as a down payment if you are buying a home for the first time.",
                    subContent:
                        "First time buyers can contribute up to $35,000 each.",
                    link: null,
                };
                break;
            case "Contribution":
                data = {
                    title: "Contribution Amounts",
                    content:
                        "Enter the amount you would like to contribute to the down payment from your RRSP savings.",
                    subContent:
                        "If your RRSP balance is less than the entered amount at time of purchase, only an amount equal to the balance will be put towards your down payment.",
                    link: null,
                };
                break;
            default:
        }
        this.props.onhandleFocusedInput(data);
    };

    _getRRSPSettingsMenu() {
        const {
            people,
            personOne,
            personTwo,
            personOneIsFTB,
            personTwoIsFTB,
            rrspMaxOne,
            rrspMaxTwo,
        } = this.state;
        const peopleListOne = Object.keys(people).filter((person) => {
            return person !== personTwo;
        });
        const peopleListTwo = Object.keys(people).filter((person) => {
            return person !== personOne;
        });

        const personOneMax = rrspMaxOne > 0 ? "35,000" : "0";
        const personTwoMax = rrspMaxTwo > 0 ? "35,000" : "0";

        return (
            <div className="modalBox">
                <div className="modalBoxLabel">RRSP as Down Payment</div>

                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Person");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">Person 1:</div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personOne}
                        options={peopleListOne}
                        className="SharedSelect"
                        id="personOne"
                        label="Person 1"
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Person");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">Person 2:</div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personTwo}
                        options={peopleListTwo}
                        className="SharedSelect"
                        id="personTwo"
                        label="Person 2"
                    />
                </div>

                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("First Time Buyer");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personOne ? personOne : "Person 1"} Is First Time
                        Buyer:
                    </div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personOneIsFTB}
                        options={["Yes", "No"]}
                        className="SharedSelect"
                        id="personOneIsFTB"
                        label="Person 1"
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("First Time Buyer");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personTwo ? personTwo : "Person 2"} Is First Time
                        Buyer:
                    </div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={personTwoIsFTB}
                        options={["Yes", "No"]}
                        className="SharedSelect"
                        id="personTwoIsFTB"
                        label="Person 2"
                    />
                </div>

                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Contribution");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personOne ? personOne : "Person 1"} Max Contribution:
                    </div>
                    <div className="ExpenseAmountContainerLeft">
                        <Value
                            label={`No more than ${personOneMax}`}
                            onChangeInput={this.onChangeInput}
                            value={this.state.rrspContributionOne || 0}
                            id="rrspContributionOne"
                        />
                    </div>
                </div>
                <div
                    onMouseEnter={() => {
                        this.onHandleMouseEnter("Contribution");
                    }}
                    className="boxRow"
                >
                    <div className="modalBoxLabel">
                        {personTwo ? personTwo : "Person 2"} Max Contribution:
                    </div>
                    <div className="ExpenseAmountContainerLeft">
                        <Value
                            label={`No more than ${personTwoMax}`}
                            onChangeInput={this.onChangeInput}
                            value={this.state.rrspContributionTwo || 0}
                            id="rrspContributionTwo"
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const stars = [1, 2, 3, 4, 5];
        const passedCheck = downpaymentObject.checkInput(this.state);
        const { edit } = this.props;
        const onboarding = this.props.onboardingData === 3;
        const {
            name,
            description,
            downpayment,
            downpaymentRate,
            rating,
            onHoverStar,
            associatedType,
            nodeTypes,
            start,
            showSettings,
            lastChangedDownpaymentProperty,
        } = this.state;

        const useAmount = lastChangedDownpaymentProperty === DownpaymentAmount;
        return (
            <div className="mainRootContainer">
                {showSettings ? (
                    this._getAdvancedSettingsMenu()
                ) : (
                    <div className="inputRoot">
                        <div className="headerContainer">
                            <img
                                alt="alt"
                                src={downpaymentObject.svg()}
                                className="flinksLogo"
                            />
                            <div className="headerInput">
                                <div className="headerLabel">
                                    {_.toUpper(downpaymentObject.constant())}
                                </div>
                                <div className="inputContainer">
                                    <div className="Required">*</div>
                                    <Name
                                        name={name}
                                        onChangeInput={this.onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                onMouseEnter={() => {
                                    this.onHandleMouseEnter(null);
                                }}
                                className="ModalRow"
                            >
                                <Description
                                    description={description}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                            <div
                                onMouseEnter={() => {
                                    this.onHandleMouseEnter(null);
                                }}
                                className="ModalRow"
                            >
                                <div className="Required">*</div>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={associatedType}
                                    options={nodeTypes}
                                    className="termsContainer"
                                    id="associatedType"
                                    label="Select Purchase Type"
                                    disabled={nodeTypes.length <= 1}
                                />
                            </div>
                            <div
                                onMouseEnter={() => {
                                    this.onHandleMouseEnter(null);
                                }}
                                className="toggledContainer"
                            >
                                <div
                                    id={DownpaymentRate}
                                    onClick={() => {
                                        this.setState({
                                            lastChangedDownpaymentProperty: DownpaymentRate,
                                        });
                                    }}
                                    className={
                                        !useAmount
                                            ? "toggleBoxActive"
                                            : "toggleBox"
                                    }
                                >
                                    Percent Downpayment
                                </div>
                                <div
                                    id={DownpaymentAmount}
                                    onClick={() => {
                                        this.setState({
                                            lastChangedDownpaymentProperty: DownpaymentAmount,
                                        });
                                    }}
                                    className={
                                        useAmount
                                            ? "toggleBoxActive"
                                            : "toggleBox"
                                    }
                                >
                                    Amount Downpayment
                                </div>
                            </div>
                            <div className="ModalRowSpaceBetween">
                                <div
                                    onMouseEnter={() => {
                                        this.onHandleMouseEnter(null);
                                    }}
                                    className="ExpenseAmountContainer"
                                >
                                    <div className="Required">*</div>
                                    <Rate
                                        value={
                                            !useAmount ? downpaymentRate : ""
                                        }
                                        label="Downpayment Rate"
                                        className="mlsInput"
                                        id="downpaymentRate"
                                        onChangeInput={this.onChangeInput}
                                        sign={true}
                                        disabled={useAmount}
                                    />
                                </div>

                                <div
                                    onMouseEnter={() => {
                                        this.onHandleMouseEnter(null);
                                    }}
                                    className="ExpenseAmountContainer"
                                >
                                    <div className="Required">*</div>
                                    <Value
                                        label="Downpayment Amount"
                                        onChangeInput={this.onChangeInput}
                                        value={useAmount ? downpayment : ""}
                                        id="downpayment"
                                        disabled={!useAmount}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            onMouseEnter={() => {
                                this.onHandleMouseEnter(null);
                            }}
                            className="ModalRow"
                        >
                            <div className="Required">*</div>
                            <div className="keyLabel">Date</div>
                            {start && moment(start).format("MM-DD-YYYY")}
                        </div>

                        <div
                            onMouseEnter={() => {
                                this.onHandleMouseEnter(null);
                            }}
                            className="ModalRow"
                        >
                            <div className="Required" />
                            <div className="keyLabel">Rating</div>
                            <div className="StarsHolder">
                                {stars.map((star, i) => {
                                    const id = i + 1;
                                    const onHover = onHoverStar;
                                    if (i < rating || i < onHover) {
                                        return (
                                            <Stars
                                                key={i}
                                                className="starFilledSvg"
                                                src={starFilledSvg}
                                                id={id}
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                onHandleStarHover={
                                                    this.onHandleStarHover
                                                }
                                                onMouseLeave={this.onMouseLeave}
                                            />
                                        );
                                    } else {
                                        return (
                                            <Stars
                                                key={i}
                                                className="starUnfilledSvg"
                                                src={starUnfilledSvg}
                                                id={id}
                                                onChangeInput={
                                                    this.onChangeInput
                                                }
                                                onHandleStarHover={
                                                    this.onHandleStarHover
                                                }
                                                onMouseLeave={this.onMouseLeave}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        <div
                            onMouseEnter={() => {
                                this.onHandleMouseEnter(null);
                            }}
                            className="ModalRow"
                        >
                            <div className="halfDiv">
                                <img
                                    alt="alt"
                                    onClick={this.onHandleAdvanceSetting}
                                    className="selectedIcon"
                                    src={
                                        this.state.showSettings
                                            ? dropdownSvg
                                            : expandSvg
                                    }
                                />
                                <div
                                    className="advanceSettingLabel"
                                    onClick={this.onHandleAdvanceSetting}
                                >
                                    Advance settings
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    onboarding={onboarding}
                />
            </div>
        );
    }
}

export default DownpaymentInput;

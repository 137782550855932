import { incomeMetadata, rentMetadata } from "./onboardingInputs";

import * as uuid from "uuid";

export const LaunchOnboarding = (
    manager,
    onboardingScenario,
    loadScenario,
    loggedInUser,
    createScenario,
    getUserScenarios
) => {
    manager._reset();
    manager.addStart();
    const normalData = manager.exportData();

    manager.addOnboardingScenario(
        "Income",
        incomeMetadata,
        [manager.nodes[normalData.nodes[0].id]],
        []
    );

    const rentData = manager.exportData();

    manager.addOnboardingScenario(
        "Rent",
        rentMetadata,
        [manager.nodes[rentData.nodes[1].id]],
        []
    );

    const data = JSON.stringify(manager.exportData());
    const normalData2 = manager.exportData();

    const scenario = {
        name: "Onboarding Scenario",
        description: "This is an onboarding scenario",
        data,
        range: 10,
        inflation: 0.019,
        dependency_map: {}
    };

    const normalScenario = {
        id: uuid.v4(),
        name: "Onboarding Scenario",
        description: "This is an onboarding scenario",
        data: normalData2,
        range: 10,
        inflation: 0.019,
        type: "scenario",
        dependency_map: {}
    };

    if (loggedInUser) {
        createScenario(scenario, 10, (data) => {
            if (data) {
                getUserScenarios();
            }
        });
        loadScenario(normalScenario, manager);
    } else {
        onboardingScenario(normalScenario);
        loadScenario(normalScenario, manager);
    }
};

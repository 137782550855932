import styles from "./customAccountModal.module.css";
import { useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { closeCustomAccountModal } from "actions/customAccountActions";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { submitLedgerAccount, submitDisplayAccount } from "helpers/accounts";
import { DropDownButton } from "Components/AccountSelectionMenu/buttons/DropDownButton";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "react-switch";

interface SelectedAccount {
    name: string;
    ids: string[];
}

const StyledTextField = styled(TextField)<TextFieldProps>(() => ({
    "& label.Mui-focused": {
        color: "#F8B46A",
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#F8B46A",
        },
    },
}));

const StyledButton = styled(Button)<ButtonProps>(() => ({
    color: "#FFFFFF",
    textTransform: "capitalize",
    backgroundColor: "#F8B46A",
    "&:hover": {
        backgroundColor: "rgba(248, 179, 106, 0.75)",
    },
}));

/**
 * The component responsible for the custom account modal.
 */
export const CustomAccountModal = () => {
    const dispatch = useAppDispatch();

    const {
        sticky,
        initialTopLevelAccount,
        initialSubAccount,
        initialLeafAccount,
    } = useAppSelector((state) => {
        const sticky = state?.customAccounts?.sticky;
        const initialTopLevelAccount =
            state?.customAccounts?.initialTopLevelAccount;
        const initialSubAccount = state?.customAccounts?.initialSubAccount;
        const initialLeafAccount = state?.customAccounts?.initialLeafAccount;
        return {
            sticky,
            initialTopLevelAccount,
            initialSubAccount,
            initialLeafAccount,
        };
    }, shallowEqual);

    const [activeAccountType, setActiveAccountType] = useState(
        initialLeafAccount?.name
            ? "leafSubAccount"
            : initialSubAccount?.name
            ? "leafAccount"
            : initialTopLevelAccount?.name
            ? "subAccount"
            : "displayAccount"
    );

    // States for the drop downs
    const [selectedTopLevelAccount, setSelectedTopLevelAccount] =
        useState<SelectedAccount>(initialTopLevelAccount);
    const [selectedSubAccount, setSelectedSubAccount] =
        useState<SelectedAccount>(initialSubAccount);
    const [selectedLeafAccount, setSelectedLeafAccount] =
        useState<SelectedAccount>(initialLeafAccount);

    // States for the text fields
    const [newDisplayAccount, setNewDisplayAccount] = useState("");
    const [newSubAccount, setNewSubAccount] = useState("");
    const [newLeafAccount, setNewLeafAccount] = useState("");
    const [newSubLeafAccount, setNewSubLeafAccount] = useState("");

    // States for unit creation
    const [displayUnit, setDisplayUnit] = useState("$");
    const [decimalPlaces, setDecimalPlaces] = useState(2);
    const [displayPosition, setDisplayPosition] = useState("before");
    const [spaceBetween, setSpaceBetween] = useState(false);

    const handleTextFieldChange = (e, accountType) => {
        const value = e.target.value;
        switch (accountType) {
            case "subAccount":
                setNewSubAccount(value);
                break;
            case "leafAccount":
                setNewLeafAccount(value);
                break;
            case "leafSubAccount":
                setNewSubLeafAccount(value);
                break;
            case "displayAccount":
                setNewDisplayAccount(value);
                break;
            default:
                break;
        }
    };

    const handleTopLevelAccountUpdate = (accountInfo: {
        name: string;
        ids: string[];
    }) => {
        setSelectedSubAccount({ name: "", ids: [] });
        setSelectedLeafAccount({ name: "", ids: [] });
        setSelectedTopLevelAccount(accountInfo);
    };

    const handleSubAccountUpdate = (accountInfo) => {
        setSelectedLeafAccount({ name: "", ids: [] });
        setSelectedSubAccount(accountInfo);
    };

    useEffect(() => {
        setDisplayUnit("$");
        setDecimalPlaces(2);
        setDisplayPosition("before");
        setSpaceBetween(false);
    }, [activeAccountType]);

    const handleAccountCreation = () => {
        const unitData = {
            type: activeAccountType === "displayAccount" ? "display" : "ledger",
            display: displayUnit,
            singular: displayUnit,
            spacingBetween: spaceBetween,
            beforeValue: displayPosition === "before",
            nearestDecimal: decimalPlaces,
        };

        switch (activeAccountType) {
            case "subAccount":
                submitLedgerAccount(
                    selectedTopLevelAccount,
                    newSubAccount,
                    unitData
                );
                break;
            case "leafAccount":
                submitLedgerAccount(
                    selectedSubAccount,
                    newLeafAccount,
                    unitData
                );
                break;
            case "leafSubAccount":
                submitLedgerAccount(
                    selectedLeafAccount,
                    newSubLeafAccount,
                    unitData
                );
                break;
            case "displayAccount":
                submitDisplayAccount(newDisplayAccount, unitData);
                break;
        }
        handlePostSubmitCleanUp();
    };

    const handlePostSubmitCleanUp = () => {
        setNewDisplayAccount("");
        setNewSubAccount("");
        setNewLeafAccount("");
        setNewSubLeafAccount("");

        if (!sticky) {
            dispatch(closeCustomAccountModal());
        }
    };

    const getAccountUnitInputs = () => {
        return (
            <div className={styles.unitInputsContainer}>
                <div className={styles.unitInputsRow}>
                    <StyledTextField
                        id="outlined-basic"
                        label="Display Label"
                        variant="outlined"
                        value={displayUnit}
                        onChange={(e) => setDisplayUnit(e.target.value)}
                        helperText="$, %, Customers, etc"
                    />
                    <StyledTextField
                        id="outlined-basic"
                        label="Decimal Places"
                        variant="outlined"
                        value={decimalPlaces}
                        onChange={(e) => {
                            const newValue = Number(e.target.value);
                            if (newValue < 5 && newValue >= 0)
                                setDecimalPlaces(newValue);
                        }}
                        type="number"
                    />
                </div>
                <RadioGroup
                    row
                    value={displayPosition}
                    name="radio-buttons-group"
                    onChange={(e) => setDisplayPosition(e.target.value)}
                >
                    <FormControlLabel
                        value="before"
                        control={
                            <Radio
                                sx={{
                                    "&.Mui-checked": {
                                        color: "#f7ab5f",
                                    },
                                }}
                            />
                        }
                        sx={{
                            ".MuiFormControlLabel-label": {
                                color: "rgba(0, 0, 0, 0.87)",
                                fontFamily: "CeraPro-Regular",
                            },
                        }}
                        label="Display in front of values"
                    />
                    <FormControlLabel
                        value="after"
                        control={
                            <Radio
                                sx={{
                                    "&.Mui-checked": {
                                        color: "#f7ab5f",
                                    },
                                }}
                            />
                        }
                        sx={{
                            ".MuiFormControlLabel-label": {
                                color: "rgba(0, 0, 0, 0.87)",
                                fontFamily: "CeraPro-Regular",
                            },
                        }}
                        label="Display after values"
                    />
                </RadioGroup>
                <div className={styles.switchContainer}>
                    <Switch
                        checked={spaceBetween}
                        className="InflationToggle"
                        height={20}
                        width={40}
                        onChange={(e) => setSpaceBetween(e)}
                        onColor="#f7ab5f"
                    />
                    <span className={styles.switchLabel}>
                        Include space between
                    </span>
                </div>
            </div>
        );
    };

    /**
     * Takes in the activeAccountType as a string and returns the related discription jsx.
     */
    const getActiveDiscription = (activeAccountType: string) => {
        switch (activeAccountType) {
            case "subAccount":
                return (
                    <article className={styles.discription}>
                        <h5 className={styles.discriptionHeading}>
                            We support up to{" "}
                            <span
                                className={styles.discriptionHeading_highlight}
                            >
                                four
                            </span>{" "}
                            layers of Accounts.
                        </h5>
                        <span className={styles.discriptionSubtext}>
                            Account &gt; Sub Account &gt; Leaf Account &gt; Leaf
                            Sub Account
                        </span>
                        <a
                            className={styles.discriptionLink}
                            target="_blank"
                            rel="noreferrer"
                            href="https://docs.whatifi.io/b1bcb5e688ac4fa1a755e369dd228494"
                        >
                            Learn More
                        </a>
                    </article>
                );
            case "leafAccount":
                return (
                    <article className={styles.discription}>
                        <h5 className={styles.discriptionHeading}>
                            We support up to{" "}
                            <span
                                className={styles.discriptionHeading_highlight}
                            >
                                four
                            </span>{" "}
                            layers of Accounts.
                        </h5>
                        <span className={styles.discriptionSubtext}>
                            Account &gt; Sub Account &gt; Leaf Account &gt; Leaf
                            Sub Account
                        </span>
                        <a
                            className={styles.discriptionLink}
                            target="_blank"
                            rel="noreferrer"
                            href="https://docs.whatifi.io/b1bcb5e688ac4fa1a755e369dd228494"
                        >
                            Learn More
                        </a>
                    </article>
                );
            case "leafSubAccount":
                return (
                    <article className={styles.discription}>
                        <h5 className={styles.discriptionHeading}>
                            We support up to{" "}
                            <span
                                className={styles.discriptionHeading_highlight}
                            >
                                four
                            </span>{" "}
                            layers of Accounts.
                        </h5>
                        <span className={styles.discriptionSubtext}>
                            Account &gt; Sub Account &gt; Leaf Account &gt; Leaf
                            Sub Account
                        </span>
                        <a
                            className={styles.discriptionLink}
                            target="_blank"
                            rel="noreferrer"
                            href="#"
                        >
                            Learn More
                        </a>
                    </article>
                );
            case "displayAccount":
                return (
                    <article className={styles.discription}>
                        <p className={styles.discriptionCopy}>
                            Display Accounts are NON Ledger Accounts. They can
                            contain any type of data like number of Employees,
                            Customers or calculated KPI values.
                        </p>
                        <a
                            className={styles.discriptionLink}
                            target="_blank"
                            rel="noreferrer"
                            href="#"
                        >
                            Learn More
                        </a>
                    </article>
                );
            default:
                return;
        }
    };

    /**
     * Takes in the activeAccountType as a string and returns the related input fields.
     */
    const getActiveInputs = (activeAccountType: string) => {
        switch (activeAccountType) {
            case "subAccount":
                return (
                    <div className={styles.inputsOverflowContainer}>
                        <div className={styles.inputsContainer}>
                            <DropDownButton
                                callback={handleTopLevelAccountUpdate}
                                selectedAccountId={
                                    selectedTopLevelAccount?.ids?.[0]
                                }
                                buttonLabel={"Select Top Level Account"}
                            />
                            <StyledTextField
                                id="outlined-basic"
                                label="Sub Account"
                                variant="outlined"
                                value={newSubAccount}
                                onChange={(e) =>
                                    handleTextFieldChange(e, "subAccount")
                                }
                            />
                            {getAccountUnitInputs()}
                        </div>
                    </div>
                );
            case "leafAccount":
                return (
                    <div className={styles.inputsOverflowContainer}>
                        <div className={styles.inputsContainer}>
                            <DropDownButton
                                callback={handleTopLevelAccountUpdate}
                                selectedAccountId={
                                    selectedTopLevelAccount?.ids?.[0]
                                }
                                buttonLabel={"Select Top Level Account"}
                            />
                            <DropDownButton
                                callback={handleSubAccountUpdate}
                                selectedAccountId={selectedSubAccount?.ids?.[1]}
                                buttonLabel={"Select Sub Account"}
                                parentAccountId={
                                    selectedTopLevelAccount?.ids?.[0]
                                }
                                disabled={
                                    !!(selectedTopLevelAccount?.name === "")
                                }
                            />
                            <StyledTextField
                                id="outlined-basic"
                                label="Leaf Account"
                                variant="outlined"
                                value={newLeafAccount}
                                onChange={(e) =>
                                    handleTextFieldChange(e, "leafAccount")
                                }
                            />
                            {getAccountUnitInputs()}
                        </div>
                    </div>
                );
            case "leafSubAccount":
                return (
                    <div className={styles.inputsOverflowContainer}>
                        <div className={styles.inputsContainer}>
                            <DropDownButton
                                callback={handleTopLevelAccountUpdate}
                                selectedAccountId={
                                    selectedTopLevelAccount?.ids?.[0]
                                }
                                buttonLabel={"Select Top Level Account"}
                            />
                            <DropDownButton
                                callback={handleSubAccountUpdate}
                                selectedAccountId={selectedSubAccount?.ids?.[1]}
                                buttonLabel={"Select Sub Account"}
                                parentAccountId={
                                    selectedTopLevelAccount?.ids?.[0]
                                }
                                disabled={
                                    !!(selectedTopLevelAccount?.name === "")
                                }
                            />
                            <DropDownButton
                                callback={setSelectedLeafAccount}
                                selectedAccountId={
                                    selectedLeafAccount?.ids?.[2]
                                }
                                buttonLabel={"Select Leaf Account"}
                                parentAccountId={selectedSubAccount?.ids?.[1]}
                                disabled={!!(selectedSubAccount?.name === "")}
                            />
                            <StyledTextField
                                id="outlined-basic"
                                label="Leaf Sub Account"
                                variant="outlined"
                                value={newSubLeafAccount}
                                onChange={(e) =>
                                    handleTextFieldChange(e, "leafSubAccount")
                                }
                            />
                            {getAccountUnitInputs()}
                        </div>
                    </div>
                );
            case "displayAccount":
                return (
                    <div className={styles.inputsOverflowContainer}>
                        <div className={styles.inputsContainer}>
                            <StyledTextField
                                id="outlined-basic"
                                label="Display Account"
                                variant="outlined"
                                value={newDisplayAccount}
                                onChange={(e) =>
                                    handleTextFieldChange(e, "displayAccount")
                                }
                            />
                            {getAccountUnitInputs()}
                        </div>
                    </div>
                );
            default:
                return;
        }
    };

    const accountExample = useMemo(() => {
        let accountName = "";

        switch (activeAccountType) {
            case "subAccount":
                accountName = newSubAccount;
                break;
            case "leafAccount":
                accountName = newLeafAccount;
                break;
            case "leafSubAccount":
                accountName = newSubLeafAccount;
                break;
            case "displayAccount":
                accountName = newDisplayAccount;
                break;
        }

        const valueArray = ["123"];

        let count = 0;

        while (count < decimalPlaces) {
            if (count === 0) {
                valueArray.push(".");
            }
            valueArray.push("0");
            count++;
        }

        const valueWithDecimals = valueArray.join("");

        const accountExample =
            displayPosition === "before"
                ? `${displayUnit}${spaceBetween ? " " : ""}${valueWithDecimals}`
                : `${valueWithDecimals}${
                      spaceBetween ? " " : ""
                  }${displayUnit}`;

        const finalString = `${accountName} ${
            accountName && "-"
        } ${accountExample}`;

        return finalString;
    }, [
        activeAccountType,
        decimalPlaces,
        displayPosition,
        displayUnit,
        newDisplayAccount,
        newLeafAccount,
        newSubAccount,
        newSubLeafAccount,
        spaceBetween,
    ]);

    return (
        <div className={styles.primaryContainer}>
            <div
                className={styles.closeIcon}
                onClick={() => dispatch(closeCustomAccountModal())}
            ></div>
            <div
                className={`${styles.secondaryContainer} ${styles.secondaryContainerUpper}`}
            >
                <div className={styles.headerContainer}>
                    <aside className={styles.aside}>
                        <figure className={styles.figure}></figure>
                    </aside>
                    <div className={styles.headerCopyContainer}>
                        <h3 className={styles.headerCopy}>Add New</h3>
                        <h3 className={styles.headerCopy}>Custom Account</h3>
                    </div>
                </div>
                <div className={styles.selectionButtonContainer}>
                    <button
                        className={`${
                            activeAccountType === "subAccount"
                                ? `${styles.selectionButton} ${styles.selectionButton_active}`
                                : `${styles.selectionButton}`
                        }`}
                        onClick={() => setActiveAccountType("subAccount")}
                    >
                        Sub Account
                    </button>
                    <button
                        className={`${
                            activeAccountType === "leafAccount"
                                ? `${styles.selectionButton} ${styles.selectionButton_active}`
                                : `${styles.selectionButton}`
                        }`}
                        onClick={() => setActiveAccountType("leafAccount")}
                    >
                        Leaf Account
                    </button>
                    <button
                        className={`${
                            activeAccountType === "leafSubAccount"
                                ? `${styles.selectionButton} ${styles.selectionButton_active}`
                                : `${styles.selectionButton}`
                        }`}
                        onClick={() => setActiveAccountType("leafSubAccount")}
                    >
                        Leaf Sub Account
                    </button>
                    <button
                        className={`${
                            activeAccountType === "displayAccount"
                                ? `${styles.selectionButton} ${styles.selectionButton_active}`
                                : `${styles.selectionButton}`
                        }`}
                        onClick={() => setActiveAccountType("displayAccount")}
                    >
                        Display Account
                    </button>
                </div>
                {getActiveInputs(activeAccountType)}
            </div>
            <div
                className={`${styles.secondaryContainer} ${styles.secondaryContainerLower}`}
            >
                {getActiveDiscription(activeAccountType)}
                <div className={styles.submissionButtonsContainer}>
                    <span className={styles.accountExample}>
                        {accountExample}
                    </span>
                    <StyledButton
                        variant="contained"
                        onClick={() => handleAccountCreation()}
                    >
                        Create Account
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

import { useSpring, animated } from "react-spring";
import NodeMenuContainer from "../EventsMenu";

export default function BaselineRightDisplayPanel({
    displayMenu,
    history,
    deleteEvent,
    baselineManager,
}) {
    const containerAnimation = useSpring({
        to: { width: displayMenu ? 375 : 0 },
    });

    return (
        <animated.div className="RightDisplay" style={containerAnimation}>
            {displayMenu && (
                <NodeMenuContainer
                    history={history}
                    deleteEvent={deleteEvent}
                    inBaseline={true}
                    reload={false}
                    baselineManager={baselineManager}
                />
            )}
        </animated.div>
    );
}

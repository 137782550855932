import { Group, Rect, Image, Text } from "react-konva";
import useImage from "use-image";
import containerMiniIdentifierIconPath from "Assets/_canvasIcons/containerMiniIdentifier.svg";
import { useMemo, useState, useEffect, useRef } from "react";

export const ContainerMiniIdentifier = ({ x, y, containerName, expanded }) => {
    const baseWidth = 40;
    const textRef = useRef<any>(null);
    const [textRealWidth, setTextRealWidth] = useState(0);

    useEffect(() => {
        if (containerName && expanded) {
            const _textRealWidth = textRef.current?.getTextWidth?.();
            setTextRealWidth(_textRealWidth);
        }
    }, [containerName, expanded]);

    const { width, height } = useMemo(() => {
        let width = 40;
        const height = 35;

        if (expanded && textRealWidth > 0) {
            width = textRealWidth + 60;
        }

        return { width, height };
    }, [expanded, textRealWidth]);

    const [containerMiniIcon] = useImage(containerMiniIdentifierIconPath);

    return (
        <Group x={x - baseWidth / 2} y={y - height / 2}>
            <Rect
                width={width}
                height={height}
                fill="#FFF"
                stroke={"rgba(244, 213, 93, 1)"}
                strokeWidth={5}
                cornerRadius={[10, 10, 10, 10]}
            />
            <Image
                image={containerMiniIcon}
                width={20}
                height={20}
                x={10}
                y={7.5}
            />
            {expanded && (
                <Text
                    x={40}
                    y={11}
                    ref={textRef}
                    fontFamily={"CeraPro-Regular"}
                    fontSize={16}
                    fill={"#4C4C4C"}
                    text={containerName ?? ""}
                />
            )}
        </Group>
    );
};

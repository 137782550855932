// Takes an object and returns modifiable properties based on modifier submode provided
// Maps property's external display name to internal property name for both front end and
// decision engine, the property's unit if numerical
import { House, Investment, Mortgage, Pension, Rent } from "./constants";
import _ from "lodash";
import { getViableChildrenToSearch } from "./index";
import { meObject } from "Components/Registry/Me";
import { modifierObject } from "Components/Registry/Modifier";
import { loanObject } from "../Components/Registry/Loan";
import { studentLoanObject } from "../Components/Registry/Student Loan";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { debitCreditObject } from "Components/Registry/Debit Credit";
import { customerObject } from "Components/Registry/Customer";
import { groupObject } from "Components/Registry/Group";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { incomeObject } from "Components/Registry/Income";
import { expenseObject } from "Components/Registry/Expense";
import { employeeObject } from "Components/Registry/Employee";
import { instanceObject } from "Components/Registry/Instance";
import { customer2Object } from "Components/Registry/Customer2";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";

export const extractModifiableProperties = (entity) => {
    if (!entity) return [];
    let properties;
    switch (entity.type) {
        case House: {
            properties = {
                "House Price": {
                    deName: "value",
                    feName: "value",
                    unit: mDollar,
                    inDataSubfield: true,
                },
            };
            // TODO: Change to entity data
            if (entity.applyAppreciation) {
                properties["House Appreciation Rate"] = {
                    deName: "interestRate",
                    feName: "appreciationRate",
                    unit: mPercent,
                    inDataSubfield: true,
                };
            }
            properties["House Purchase Date"] = {
                deName: "startDate",
                feName: "first payment",
                unit: mDate,
                inDataSubfield: true,
            };
            break;
        }
        case Rent: {
            // all located in node's internal rentdata - same with inflation
            properties = {
                "Rent Amount": {
                    deName: "value",
                    feName: "value",
                    unit: mDollar,
                    intendedTobeNegative: true,
                    inDataSubfield: true,
                },
            };
            properties["Rent Start"] = {
                deName: "startDate",
                feName: "startDate",
                unit: mDate,
            };
            properties["Rent End"] = {
                deName: "endDate",
                feName: "endDate",
                unit: mDate,
            };
            break;
        }
        case loanObject.constant(): {
            properties = {
                "Loan Interest Rate": {
                    deName: "interestRate",
                    feName: "rate",
                    unit: mPercent,
                }, // inside internal loanData
                "Loan Amount": {
                    deName: "value",
                    feName: "loan",
                    unit: mDollar,
                    nonTemporal: true,
                }, // also inside internal loanData
            };
            // needs to be changed inside all node data
            properties["Loan Start"] = {
                deName: "startDate",
                feName: "first payment",
                unit: mDate,
            };
            break;
        }
        case studentLoanObject.constant(): {
            properties = {
                "Loan Interest Rate": {
                    deName: "interestRate",
                    feName: "rate",
                    unit: mPercent,
                }, // inside internal loanData
                "Loan Amount": {
                    deName: "value",
                    feName: "loan",
                    unit: mDollar,
                    nonTemporal: true,
                }, // also inside internal loanData
            };
            // needs to be changed inside all node data
            properties["Loan Start"] = {
                deName: "startDate",
                feName: "first payment",
                unit: mDate,
            };
            break;
        }
        case incomeObject.constant(): {
            properties = {
                "Income Amount": {
                    deName: "value",
                    feName: "value",
                    unit: mDollar,
                    inDataSubfield: true,
                },
            };

            properties["Income Start"] = {
                deName: "startDate",
                feName: "startDate",
                unit: mDate,
            };
            if (entity.cadence !== "one-time") {
                properties["Income End"] = {
                    deName: "endDate",
                    feName: "endDate",
                    unit: mDate,
                };
            }
            break;
        }
        case expenseObject.constant(): {
            properties = {
                "Expense Amount": {
                    deName: "value",
                    feName: "value",
                    unit: mDollar,
                    inDataSubfield: true,
                },
            };
            properties["Expense Start"] = {
                deName: "startDate",
                feName: "startDate",
                unit: mDate,
            };

            // TODO: Change to entity data
            if (entity.cadence !== "one-time") {
                properties["Expense End"] = {
                    deName: "endDate",
                    feName: "endDate",
                    unit: mDate,
                };
            }
            break;
        }
        // Mortgage is a special case where the DE names aren't consistent and used in
        // multiple sub-nodes - the denames don't actually correspond to field names
        case Mortgage: {
            properties = {
                "Mortgage Downpayment": {
                    deName: "downpayment",
                    feName: "downpayment",
                    unit: mDollar,
                    nonTemporal: true,
                }, // inside downPaymentData and loanData
                "Mortgage Rate": {
                    deName: "rate",
                    feName: "rate",
                    unit: mPercent,
                    nonTemporal: true,
                },
            };
            break;
        }
        case Investment: {
            properties = {
                "Investment Contribution Amount": {
                    deName: "value",
                    feName: "value",
                    unit: mDollar,
                    intendedTobeNegative: true,
                    inDataSubfield: true,
                },
                "Investment Interest Rate": {
                    deName: "interestRate",
                    feName: "interestRate",
                    unit: mPercent,
                    inDataSubfield: true,
                },
                "Investment Initial Value": {
                    deName: "initialValue",
                    feName: "initialValue",
                    unit: mDollar,
                    nonTemporal: true,
                    inDataSubfield: true,
                },
            };
            properties["Investment Start"] = {
                deName: "startDate",
                feName: "startDate",
                unit: mDate,
            };
            break;
        }
        case Pension: {
            properties = {
                "Retirement Date": {
                    deName: "retirementDate",
                    feName: "retirementDate",
                    unit: mDate,
                    nonTemporal: true,
                    inDataSubfield: true,
                },
            };
            break;
        }
        case unitCostObject.constant(): {
            properties = {
                "Unit Cost Value": {
                    deName: "value",
                    feName: "value",
                    unit: mDollar,
                    inDataSubfield: true,
                },
            };
            break;
        }
        case debitCreditObject.constant(): {
            properties = {
                "Debit Credit Value": {
                    deName: "value",
                    feName: "amount",
                    unit: mDollar,
                    inDataSubfield: true,
                },
            };
            properties["Debit Credit Start"] = {
                deName: "startDate",
                feName: "startDate",
                unit: mDate,
            };
            if (entity.cadence !== "one-time") {
                properties["Debit Credit End"] = {
                    deName: "endDate",
                    feName: "endDate",
                    unit: mDate,
                };
            }
            break;
        }
        case customerObject.constant(): {
            properties = {
                Customers: {
                    deName: "value",
                    feName: "customer",
                    unit: "",
                    inDataSubfield: true,
                },
            };
            break;
        }
        case customer2Object.constant(): {
            properties = {
                Customers: {
                    deName: "value",
                    feName: "customer",
                    unit: "",
                    inDataSubfield: true,
                },
            };
            break;
        }
        case websiteVisitorsObject.constant(): {
            properties = {
                "Monthly Visitors": {
                    deName: "value",
                    feName: "value",
                    unit: "",
                    inDataSubfield: true,
                },
            };
            break;
        }
        case employeeObject.constant(): {
            properties = {
                "Employee Start": {
                    deName: "startDate",
                    feName: "startDate",
                    unit: mDate,
                },
            };

            if (entity.endDate) {
                properties["Employee End"] = {
                    deName: "endDate",
                    feName: "endDate",
                    unit: mDate,
                };
            }

            if (entity.data.payType == "hourly") {
                properties["Hourly Rate"] = {
                    deName: "value",
                    feName: "rate",
                    unit: mDollar,
                    inDataSubfield: true,
                };
            } else if (entity.data.payType == "salary") {
                properties["Salary"] = {
                    deName: "value",
                    feName: "salary",
                    unit: mDollar,
                    inDataSubfield: true,
                };
            }

            break;
        }
        case groupObject.constant(): {
            if (entity.data.groupMode === "events") {
                const selectedEvents = entity.data.selectedEvents;
                let allEntities = [];
                selectedEvents.forEach((id) => {
                    const event = getEvent(id);
                    if (event) {
                        const entities = Object.values(
                            getRelevantEntities(event.entities)
                        );
                        allEntities = allEntities.concat(entities);
                    }
                });
                properties = extractModifiablePropertiesEntities(allEntities);
            } else if (entity.data.groupMode === "entities") {
                const selectedEntities = Object.values(
                    getRelevantEntities(entity.data.selectedEntities)
                );
                properties =
                    extractModifiablePropertiesEntities(selectedEntities);
            }
            break;
        }
        case instanceObject.constant(): {
            properties = {
                "Instance Start": {
                    deName: "startDate",
                    feName: "startDate",
                    unit: mDate,
                },
                "Instance Amount": {
                    deName: "value",
                    feName: "value",
                    unit: "",
                    inDataSubfield: true,
                },
            };

            if (entity.cadence !== "one-time") {
                properties["Instance End"] = {
                    deName: "endDate",
                    feName: "endDate",
                    unit: mDate,
                };
            }
            break;
        }
        // add extra nodes here
        default:
            properties = {};
    }
    // TODO: Change to entity data
    if (entity.inflation)
        // may need to accommodate on a per-node basis
        properties["Inflation Rate"] = {
            deName: "inflationRate",
            feName: null,
            unit: mPercent,
            nonTemporal: true,
        };
    return properties;
};

// same as extractModifiableProperties except it takes in multiple entities in an array
export const extractModifiablePropertiesEntities = (entities) => {
    if (entities.length < 1) {
        return [];
    }

    const properties = extractModifiableProperties(entities[0]);

    entities.slice(1).forEach((entity) => {
        const entityProperties = extractModifiableProperties(entity);
        Object.keys(properties).forEach((key) => {
            if (!(key in entityProperties)) {
                delete properties[key];
            }
        });
    });

    return properties;
};

// Used alongside recurseToModifier for the anyNode modifier mode
// NOTE: These helpers are also used in maincontainer, in case the graph changes
//        since the modifier was added
export const getBaselineProperties = (properties, manager) => {
    if (!manager) return;
    if (!properties) return;
    for (const node of manager.baseline) {
        const modifiableProperties = extractModifiableProperties(node);
        for (let [key, content] of Object.entries(modifiableProperties)) {
            if (!properties[key]) {
                properties[key] = Object.assign({}, content);
                properties[key].nodeIds = new Set();
            }
            properties[key].nodeIds.add(node.id);
        }
    }
};

// Returns an object containing each property name corresponding to a list of
// ancestor nodes and the decision engine name of the property; intended use for modifiers
// NOTE: we store modifiable properties in a temp obj, as we're not sure
//      if the current node is actually in the current thread; it's necessary to
//      start from Me node, as it allows us to ensure we're going down locked path
export const recurseToModifier = (node, target, tempProperties, properties) => {
    if (!node) return;

    let localTempProperties = _.cloneDeep(tempProperties);

    if (node.type !== groupObject.constant()) {
        // check modifiable properties and store them
        const entities = Object.values(getRelevantEntities(node.entities));
        let modifiableProperties =
            extractModifiablePropertiesEntities(entities);
        if (!node.isBypassed()) {
            for (let [key, content] of Object.entries(modifiableProperties)) {
                if (!localTempProperties[key]) {
                    localTempProperties[key] = Object.assign({}, content);
                    localTempProperties[key].nodeIds = new Set();
                }
                node.entities.forEach((entity) => {
                    localTempProperties[key].nodeIds.add(entity.id);
                });
            }
        }
    }

    if (node === target) {
        // merge tempProperties w/ properties - it's a modifier's ancestor, so we keep it
        for (let key of Object.keys(localTempProperties)) {
            if (!properties[key]) {
                properties[key] = Object.assign({}, localTempProperties[key]);
                properties[key].nodeIds = new Set();
            }
            localTempProperties[key].nodeIds.forEach(
                properties[key].nodeIds.add,
                properties[key].nodeIds
            );
        }
        return;
    }

    let nodesToSearch = getViableChildrenToSearch(node, target);

    for (let child of nodesToSearch) {
        recurseToModifier(child, target, localTempProperties, properties);
    }
};

// fetches all ancestor nodes to target
export const fetchAllNodes = (node, target, tempNodes, nodes, visited) => {
    let localTempNodes = _.cloneDeep(tempNodes);
    if (!(!node || (visited.has(node) && node !== target))) {
        visited.add(node);
        if (
            node.type !== meObject.constant() &&
            node.type !== modifierObject.constant() &&
            node.type !== "Baseline"
        ) {
            let i = 1;
            let name = node.name;
            const nameCopy = name;
            while (nodes[name] || localTempNodes[name]) {
                // name exists
                name = `${nameCopy} (${i})`;
                i++;
            }
            localTempNodes[name] = node;
        }

        if (node === target) {
            // merge w/ result nodes
            for (let key of Object.keys(localTempNodes)) {
                nodes[key] = localTempNodes[key];
            }
            return;
        }
    }
    let nodesToSearch = getViableChildrenToSearch(node, target);

    for (let child of nodesToSearch) {
        fetchAllNodes(child, target, localTempNodes, nodes, visited);
    }
};

// modifier node modes
export const SpecificNode = "Specific event";
export const AnyNode = "Any event with property";
// modifier node sub-modes
export const PercentChange = "Percent change";
export const AddToValue = "Add to value";
export const ValueReplacer = "Value replacement";
export const CompoundGrowth = "Compounding Growth";
export const Cancel = "Cancel";
// modifier units
export const mDate = "Date";
export const mPercent = "%";
export const mDollar = "$";

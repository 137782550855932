import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import * as uuid from "uuid";
import { camelCase } from "lodash";
/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const bankManualInputsHandler = (
    // accNum should be provided as 0 or a non-zero number in the cases where it's required
    value: string,
    id: "accName" | "accType" | "entityName" | "value" | "rating",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    accNum = 0
) => {
    const today = new Date();
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const dateStr = `${
        months[today.getMonth()]
    } ${today.getDate()}, ${today.getFullYear()}`;
    data.lastUpdated = dateStr;
    let accounts = data.accounts.map((acc) => ({ ...acc }));

    switch (id) {
        case "value":
            accounts[accNum].accountBalance = value;

            let totalBalance: number | string = 0;
            if (accounts.length > 0) {
                totalBalance = accounts
                    .map((account) => {
                        if (account.accountType === "Credit Card") {
                            return -parseInt(account.accountBalance);
                        } else {
                            return parseInt(account.accountBalance);
                        }
                    })
                    .reduce((total, accountBalance) => {
                        if (!total) total = 0;
                        if (!accountBalance) accountBalance = 0;
                        if (isNaN(total + accountBalance)) return "0";
                        else return total + accountBalance;
                    })
                    .toString();
                // using 'as' here as a quick fix, need to re-think logic to not use isNaN
                if (isNaN(totalBalance as any)) totalBalance = "0";
                data.accounts = accounts;
            } else {
                accounts = [
                    {
                        accountName: "",
                        accountType: "",
                        accountBalance: "",
                        id: uuid.v4(),
                    },
                ];

                data.accounts = accounts;
                totalBalance = "0";
            }
            data.value = totalBalance;

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            const finalString = camelCase(value);
            data.tag = `@${finalString}`;
            currentEntityObject.name = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accName":
            accounts[accNum].accountName = value;
            data.accounts = accounts;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accType":
            accounts[accNum].accountType = value;
            data.accounts = accounts;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

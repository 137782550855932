import { EventManager } from "Events";
import Event from "Events/_event";

interface PropsObject {
    manager: EventManager;
    line: any;
    eventId: string;
    focus: any;
}

/**
 * Returns all upstream events that are not utility events
 * @param propsObject
 */
export const getUpstreamNodes = (
    propsObject: PropsObject,
    filterNode?: (node: Event) => boolean
): string[] => {
    const { manager, eventId } = propsObject;
    const endNode: Event = manager._findEvent(eventId);
    const startingNode = manager.getRootNode();
    const results = nodesDepthFirstSearch(startingNode, endNode, manager);
    if (filterNode) {
        const filteredResults: string[] = results.filter((eventId) => {
            const event = manager._findEvent(eventId);
            return filterNode(event);
        });
        return filteredResults;
    }
    return results;
};

const nodesDepthFirstSearch = (
    root: Event,
    endNode: Event,
    manager: any,
    parentMap: Map<string, string> = new Map<string, string>()
) => {
    let results: string[] = [];
    if (root.id === endNode.id) {
        results.unshift(root.id);
        let parent = parentMap.get(root.id);
        while (parent) {
            results.unshift(parent);
            parent = parentMap.get(parent);
        }
        return results;
    }

    const children = root.children;
    for (const index in children) {
        const child = children[index];
        if (child && !child.isBypassed()) {
            parentMap.set(child.id, root.id);
            const path = nodesDepthFirstSearch(
                child,
                endNode,
                manager,
                parentMap
            );
            if (path.length > 0) {
                results = path;
                break;
            }
        }
    }

    return results;
};

import { useContext } from "react";
import { Group } from "react-konva";
import { MenusContainer } from "./MenusContainer";
import { MenuToolTipText } from "./MenuToolTipText";
import { HoverButtonIdContext } from "../../../contexts/HoverButtonIdContext";

const CANVAS_STYLES_FIELDS_DIMENSIONS_AND_COLORS = [
    "toolTipContainerDimensions",
    "toolTipContainerColors",
];

export function ToolTipTextContainer() {
    const hoverButtonId = useContext(HoverButtonIdContext);

    if (!hoverButtonId) return null;

    return (
        <Group>
            <Group>
                <MenusContainer
                    canvasStylesFields={
                        CANVAS_STYLES_FIELDS_DIMENSIONS_AND_COLORS
                    }
                />
            </Group>
            <Group>
                <MenuToolTipText />
            </Group>
        </Group>
    );
}

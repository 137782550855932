import type { EntitiesSchema } from "./typesSchema/entitiesSchema";
import { SET_ENTITIES, UPSERT_ENTITIES } from "../actions/entitiesActions";

const initialState: EntitiesSchema = {};

export default function entitiesReducer(
    state: EntitiesSchema = initialState,
    action: { type: string; payload?: any }
): EntitiesSchema {
    switch (action.type) {
        case SET_ENTITIES:
            return { ...action.payload };
        case UPSERT_ENTITIES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

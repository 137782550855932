import type { EventManager } from "Events";
import { shallowEqual } from "react-redux";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { NodeTypesBlack } from "../../../Events";
import {
    selectEvent,
    setFocus,
    setZoomedEvent,
} from "../../../actions/scenario";

// Image assets
import CheckboxOffIcon from "Assets/_budgetIcons/checkbox-off.svg";
import CheckboxOnIcon from "Assets/_budgetIcons/checkbox-on.svg";
import graphIcon from "Assets/graphIcon.svg";
import { useMemo } from "react";
import { getNameFromType } from "helpers/getNameFromType";
import ScenarioArrowIcon from "Assets/scenarioIcons/collapse_icon.svg";

interface ScenarioViewEventProps {
    eventId: string;
    threadId: string;
    threadColor: string;
    eventIds: string[];
}

export function ScenarioViewEvent({
    eventId,
    threadId,
    threadColor,
    eventIds,
}: ScenarioViewEventProps) {
    const dispatch = useAppDispatch();

    const {
        nodesMode,
        manager,
        isSelectedEvent,
        name,
        nodeDetail,
        type,
        focus,
        numberOfEntities,
    } = useAppSelector((state) => {
        const nodesMode = state?.chartGraphGroupNodesMode;
        const manager = state?.scenario?.manager as EventManager;
        const eventInstance = manager
            ?.getNodesFromThread(threadId)
            ?.find((n) => n.id === eventId);
        const focus = state?.scenario?.focus;
        const isSelectedEvent = !!state?.scenario?.selectedEvents?.[eventId];
        const name = eventInstance?.name ?? "noname";
        const type = eventInstance?.type ?? "notype";
        const numberOfEntities = eventInstance?.entities?.length;

        return {
            nodesMode,
            manager,
            isSelectedEvent,
            name,
            nodeDetail,
            type,
            focus,
            numberOfEntities,
        };
    }, shallowEqual);

    const image = useMemo(
        () => NodeTypesBlack.find((n) => n.type === type)?.image || "",
        [type]
    );

    const handleEventClick = () => {
        if (nodesMode === "singleNode" || nodesMode === "cumulativeNodes")
            dispatch(setFocus(manager._findEvent(eventId)));
    };

    const calculationIcon = useMemo(() => {
        const eventPosition = eventIds.indexOf(eventId);
        const focusPosition = eventIds.indexOf(focus?.id);

        if (nodesMode === "default") {
            if (eventPosition === eventIds?.length - 1) {
                return "ZoomedThreadEventGraphIconWrapper ZoomedThreadEventGraphIconWrapper--Highlighted";
            } else {
                return "ZoomedThreadEventGraphIconWrapper";
            }
        } else if (
            (nodesMode === "singleNode" || nodesMode === "cumulativeNodes") &&
            eventId === focus?.id
        ) {
            return "ZoomedThreadEventGraphIconWrapper ZoomedThreadEventGraphIconWrapper--Highlighted";
        } else if (
            nodesMode === "cumulativeNodes" &&
            eventPosition < focusPosition
        ) {
            return "ZoomedThreadEventGraphIconWrapper";
        }
        return "ZoomedThreadEventGraphIconWrapper--Hidden";
    }, [eventIds, focus, eventId, nodesMode]);

    return (
        <li className="ZoomedThreadEventContainer">
            <div
                className="ZoomedThreadColorBar"
                style={{ backgroundColor: threadColor }}
            />
            <div
                className={`${
                    nodesMode === "singleNode" ||
                    nodesMode === "cumulativeNodes"
                        ? "ZoomedThreadEventContentWrapper ZoomedThreadEventContentWrapper--clickable"
                        : "ZoomedThreadEventContentWrapper"
                }`}
                onClick={handleEventClick}
            >
                <div className="ZoomedThreadEventCheckboxWrapper">
                    <img
                        className="ZoomedThreadEventCheckbox"
                        src={isSelectedEvent ? CheckboxOnIcon : CheckboxOffIcon}
                        onClick={() =>
                            dispatch(selectEvent(eventId, "dePanel"))
                        }
                        alt="checkbox"
                    />
                </div>
                <div className="ZoomedThreadEventIconInfoWrapper">
                    <div className="ZoomedThreadEventIconWrapper">
                        <img
                            className="ZoomedThreadEventIcon"
                            src={image}
                            alt="event icon"
                        />
                    </div>
                    <div className="ZoomedThreadEventInfoWrapper">
                        <span className="ZoomedThreadEventTitle">{name}</span>
                        <span className="ZoomedThreadEventType">
                            {getNameFromType(type)}
                        </span>
                        <span className="ZoomedThreadEventValue">
                            {nodeDetail}
                        </span>
                        <span className="ZoomedThreadEventValue">
                            {numberOfEntities}{" "}
                            {numberOfEntities === 1 ? "Record" : "Records"}
                        </span>
                    </div>
                    <div className={calculationIcon}>
                        <img
                            className="ZoomedThreadEventGraphIcon"
                            src={graphIcon}
                            alt="graph icon"
                        />
                    </div>
                </div>
            </div>
            <div
                className="ZoomThreadEventArrowContainer"
                onClick={() => dispatch(setZoomedEvent(eventId))}
            >
                <img
                    className="ScenarioArrowImg"
                    src={ScenarioArrowIcon}
                    alt="Focus Thread"
                />
            </div>
        </li>
    );
}

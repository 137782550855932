import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import Modifier2InputView from "./Modifier2InputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import {
    findRootNode,
    getDefaultName,
    getStringValueWithUnit,
} from "../../../helpers";
import { modifier2Object } from "Components/Registry/Modifier2";
import type { ChangeEvent } from "react";
import {
    AnyNode,
    extractModifiableProperties,
    extractModifiablePropertiesEntities,
    fetchAllNodes,
    getBaselineProperties,
    mDate,
    mPercent,
    recurseToModifier,
    SpecificNode,
} from "helpers/modifierHelpers";
import { meObject } from "Components/Registry/Me";
import { modifier2InputsHandler } from "../OnInputChangeHandlers/modifier2InputsHandler";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";
import Event from "Events/_event";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
export default function Modifier2InputExperimental({
    fillPartialValues,
    line,
    focus,
    edit,
    editData,
    baseline,
    loadedScenario,
    confirmAction,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(modifier2Object);
            _eventData.name = getDefaultName(
                modifier2Object.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const [submitted, setSubmitted] = useState(false);

    const {
        currentEntity,
        entitiesMap,
        entityIds,
        entityIndex,
        setEntitiesMap,
        onHandleDate,
    } = useEntities(entitiesObject, eventData, edit); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = {
        baselineManager,
        manager,
        line,
        focus,
        baseline,
        loadedScenario,
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                setEntitiesMap(
                    modifier2InputsHandler(
                        value,
                        "entityName",
                        0,
                        entitiesMap,
                        currentEntity,
                        null,
                        setEventData
                    )
                );
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const toggleRevertValue = (checked) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        if (checked === false) {
            currentEntityObject.endDate = "";
        }
        data.revertValue = checked;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const toggleCustomEffectPeriod = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data.customEffectPeriod === true) {
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";
        }
        if (data.curProperties) {
            const curProperty = data.curProperties[data.propertySelected];
            if (
                curProperty &&
                (curProperty.unit === mDate || curProperty.nonTemporal)
            ) {
                data.subMode = null;
            }
        }

        data.customEffectPeriod = !data.customEffectPeriod;

        // update the properties list
        if (data.mode === AnyNode) {
            extractModifiablePropertiesAnyNode(data.customEffectPeriod);
        } else if (data.mode === SpecificNode && data.curNodeId) {
            data.curProperties =
                extractAndProcessPropertiesSpecificEntity(currentEntityObject);
        }

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleDateProperty = (startDate) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        if (data.unit === "Date") {
            const curNode = manager._findEvent(data.curNodeId);
            if (curNode?.metadata?.end) {
                const curPropertyName =
                    data.curProperties[data.propertySelected].feName;
                if (
                    curPropertyName === "end" &&
                    curNode.metadata.start > startDate
                ) {
                    throwError(
                        "warning",
                        "Property end date is before start date."
                    );
                } else if (
                    curPropertyName === "start" &&
                    curNode.metadata.end < startDate
                ) {
                    throwError(
                        "warning",
                        "Property start date is after end date."
                    );
                }
            }
        }
        data.value = startDate;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const extractModifiablePropertiesAnyNode = (customEffectPeriod) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        let properties = searchForProperties();
        const filteredEntries = Object.entries(properties).filter(
            ([_key, d]) => {
                if (customEffectPeriod && d.nonTemporal) return false;
                return data.unit !== mDate;
            }
        );
        properties = Object.fromEntries(filteredEntries);
        data.curProperties = properties;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
        return properties;
    };

    const getCurNode = () => {
        const mgr = baseline ? baselineManager : manager;
        if (!mgr) return null;
        // Line is used for inserting events when clicking "Add Event" between two nodes
        const parentId = focus ? focus.id : line.parentEvent.id;
        // const parentId = editData
        //     ? editData.id
        //     : focus
        //     ? focus.id
        //     : line.parentEvent.id;
        return mgr._findEvent(parentId);
    };

    const searchForProperties = () => {
        const curNode = getCurNode();
        const root = findRootNode(curNode);
        if (!root) return {};
        const properties: { [key: string]: any } = {};
        recurseToModifier(root, curNode, {}, properties);
        getBaselineProperties(properties, manager);
        return properties;
    };

    // Searches for upstream nodes
    const searchForNodes = () => {
        const curNode = getCurNode();
        const root = manager.getRootNode();
        if (!root) return {};
        const events: { [key: string]: Event | EventStructure } = {};
        const visited = new Set();
        fetchAllNodes(root, curNode, {}, events, visited);
        fetchBaselineNodes(events);
        for (const nodeName in events) {
            // TODO: Fix typescript type error
            // @ts-ignore
            events[nodeName] = events[nodeName].exportData();
        }

        const nodesReturned = Object.entries(events).map(([name, node]) => ({
            value: node.id,
            displayName: name,
        }));

        return nodesReturned;
    };

    const fetchBaselineNodes = (nodes) => {
        if (!manager) return;
        for (const node of manager.baseline) {
            // todo: refactor to a common method w/ fetchAllNodes
            if (
                node.type !== meObject.constant() &&
                node.type !== modifier2Object.constant() &&
                node.type !== "Baseline"
            ) {
                let i = 1;
                let name = `${node.name} - baseline`;
                const nameCopy = name;
                while (nodes[name]) {
                    // name exists
                    name = `${nameCopy} (${i})`;
                    i++;
                }
                nodes[name] = node;
            }
        }
    };

    const updateAndPropagateStates = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const curEvent = getEvent(data.curNodeId);
        if (!curEvent) {
            data.curNodeId = "";
        } else {
            if (data.mode === SpecificNode) {
                data.curProperties =
                    extractAndProcessPropertiesSpecificEntity(
                        currentEntityObject
                    );
            }
        }
        if (
            !curEvent ||
            (currentEntityObject.dependencies?.entity.entityIds.length !== 0 &&
                !curEvent.entities
                    .map((entity) => entity.id)
                    .includes(
                        currentEntityObject.dependencies?.entity.entityIds[0]
                    ))
        ) {
            currentEntityObject.dependencies = undefined;
        }
        if (!currentEntityObject.dependencies?.entity?.eventId) {
            data.propertySelected = "";
        }

        if (!data.propertySelected) {
            data.subMode = undefined;
        }
        if (!data.subMode) {
            data.value = "";
        }

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    // On mount
    useEffect(() => {
        if (entitiesMap[currentEntity].data.mode === SpecificNode)
            updateAndPropagateStates();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, baselineManager, setEntitiesMap]);

    // THIS NEEDS A REWORK!
    const onHandleSubmitValues = () => {
        setSubmitted(true);
        dispatch(
            handleSubmitNodesAndEntities(
                confirmAction,
                fillPartialValues,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    // ALSO NEEDS A REWORK!
    const onHandleSubmit = () => {
        setSubmitted(true);
        dispatch(
            handleSubmitNodesAndEntities(
                confirmAction,
                fillPartialValues,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck =
        !!eventData.name && modifier2Object.checkInput(entitiesMap);

    return (
        <Modifier2InputView
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            onHandleDate={onHandleDate}
            onHandleDateProperty={onHandleDateProperty}
            extractModifiablePropertiesAnyNode={
                extractModifiablePropertiesAnyNode
            }
            searchForNodes={searchForNodes}
            toggleRevertValue={toggleRevertValue}
            toggleCustomEffectPeriod={toggleCustomEffectPeriod}
            passedCheck={passedCheck}
            onHandleSubmitValues={onHandleSubmitValues}
            onHandleSubmit={onHandleSubmit}
            propsObject={propsObject}
            setEventData={setEventData}
            edit={edit}
            submitted={submitted}
        />
    );
}
export const extractAndProcessPropertiesSpecificEntity = (
    entity: EntitySchema
) => {
    const dependencyNodeId = entity.dependencies?.["entity"]?.eventId;
    if (!dependencyNodeId) return;
    const dependencyIds = entity.dependencies?.["entity"]?.entityIds;
    if (!dependencyIds) return;
    const data = entity.data;

    let extractedProperties;

    if (dependencyIds.length > 0) {
        const selectedEntity = Object.values(
            getRelevantEntities([dependencyIds[0]])
        )[0];
        extractedProperties = extractModifiableProperties(selectedEntity);
    } else {
        const selectedEvent = getEvent(dependencyNodeId);
        if (selectedEvent) {
            const entities = Object.values(
                getRelevantEntities(selectedEvent.entities)
            );
            extractedProperties = extractModifiablePropertiesEntities(entities);
        }
    }

    const properties = {};

    // process properties to include value alongside name
    for (const property in extractedProperties) {
        if (
            data.customEffectPeriod &&
            (extractedProperties[property].nonTemporal ||
                extractedProperties[property].unit === mDate)
        ) {
            continue;
        }
        properties[property] = extractedProperties[property];
        properties[property].name = property;
    }
    return properties;
};

const propertyToString = (
    property: string,
    val: string | number,
    unit: string
) => {
    return `${property}: ${getStringValueWithUnit(
        val ? val : "Not Specified",
        val ? unit : ""
    )}`;
};

export const propertyDisplayOptions = (
    entity: EntitySchema,
    loadedScenario
) => {
    let curEntity: EntitySchema | undefined = undefined;
    if (entity.dependencies) {
        const entityIds = entity?.dependencies?.entity?.entityIds || [];
        curEntity = getRelevantEntities(entityIds)[entityIds[0]];
    }
    const properties: {
        [key: string]: {
            deName: string;
            feName: string;
            unit: string;
            name: string;
            inDataSubfield?: boolean;
        };
    } = entity.data.curProperties;
    return Object.values(properties).map((property) => {
        if (property.name === "Inflation Rate") {
            return {
                value: property.name,
                displayName: propertyToString(
                    property.name,
                    loadedScenario?.inflation * 100 ?? "",
                    mPercent
                ),
            };
        } else {
            if (!curEntity) {
                return {
                    value: property.name,
                    displayName: propertyToString(
                        property.name,
                        "",
                        property.unit
                    ),
                };
            }
            const val = property.inDataSubfield
                ? curEntity.data[property.feName]
                : curEntity[property.feName];
            return {
                value: property.name,
                displayName: propertyToString(
                    property.name,
                    val,
                    property.unit
                ),
            };
        }
    });
};

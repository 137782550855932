import type {
    EntitiesSchema,
    EntitiesInputSchema,
} from "reducers/typesSchema/entitiesSchema";
import type { AppThunk } from "store";
import store from "store";

export const UPSERT_ENTITIES = "entities/UPSERT_ENTITIES";
export const SET_ENTITIES = "entities/SET_ENTITIES";

export function upsertEntities(
    newEntities: EntitiesInputSchema
): AppThunk<void> {
    return (dispatch, getState) => {
        const curEntities = getState().entities;

        const _newEntities: EntitiesSchema = {};
        Object.entries(newEntities).forEach(([entity_id, entity]) => {
            _newEntities[entity_id] = {
                ...curEntities[entity_id],
                ...entity,
            };
        });

        dispatch({
            type: UPSERT_ENTITIES,
            payload: _newEntities,
        });
    };
}

/**
 *
 * @param newEntities
 * dispatches upsertEntities so the event manager doesnt need access to the store.
 */
export function upsertEntitiesRaw(newEntities: EntitiesInputSchema) {
    return store.dispatch(upsertEntities(newEntities));
}

export function removeEntities(entity_ids: string[]): AppThunk<void> {
    return (dispatch, getState) => {
        const entitiesCopy: EntitiesSchema = { ...getState().entities };
        entity_ids.forEach((entity_id) => {
            delete entitiesCopy[entity_id];
        });

        dispatch({
            type: SET_ENTITIES,
            payload: entitiesCopy,
        });
    };
}

export const getEntities = () => {
    return (_dispatch, getState) => {
        return () => {
            return getState().entities;
        };
    };
};

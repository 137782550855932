// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import HomeMaintenanceCard from "../Card/homeMaintenanceCard";
import HomeMaintenanceInput from "../InputContainer/HomeMaintenanceInput";

// Import Connection Image (if exists)
import HomeMaintenanceConnectionImage from "../../Assets/_optionDescriptionIcons/Budget, Home Maintenance, Short Term Rental.png";

// Import Coloured PNGs
import homeMaintenanceBlack from "../../Assets/_eventNodeTypes/homeMaintenance-black.png";
import homeMaintenanceBlue from "../../Assets/_eventNodeTypes/homeMaintenance-blue.png";
import homeMaintenanceWhite from "../../Assets/_eventNodeTypes/homeMaintenance-white.png";
import homeMaintenanceGrey from "../../Assets/_eventNodeTypes/homeMaintenance-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - homeMaintenanceObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - homeMaintenanceObject.checkInput.call(homeMaintenanceObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const homeMaintenanceObject = {
    constant: function () {
        return HomeMaintenance_Constant();
    },

    checkInput: function (state) {
        return HomeMaintenance_CheckInput(state);
    },

    svg: function () {
        return HomeMaintenance_Svg();
    },

    eventNodeMenu: function (focus) {
        return HomeMaintenance_EventNodeMenu(focus);
    },

    indexText: function () {
        return HomeMaintenance_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return HomeMaintenance_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return HomeMaintenance_OptionDescription();
    },

    colouredPngs: function () {
        return HomeMaintenance_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return HomeMaintenance_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const HomeMaintenance_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.house || !state.houseId) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const HomeMaintenance_Constant = () => {
    return "Home Maintenance";
};

// EFFECTS: Returns black png / svg for event
const HomeMaintenance_Svg = () => {
    return homeMaintenanceBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const HomeMaintenance_EventNodeMenu = (focus) => {
    return <HomeMaintenanceCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const HomeMaintenance_IndexText = () => {
    return "Select an input field to learn more about your homeMaintenance calculations.";
};

// EFFECTS: Returns all icons for event ??
const HomeMaintenance_Icons = () => {
    let pngs = new Map([
        ["black", homeMaintenanceBlack],
        ["blue", homeMaintenanceBlue],
        ["white", homeMaintenanceWhite],
        ["grey", homeMaintenanceGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const HomeMaintenance_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const HomeMaintenance_InputComponentInfoPassing = (minProps) => {
    return <HomeMaintenanceInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, homeMaintenances for event ??
const HomeMaintenance_OptionDescription = () => {
    const tags = ["Option"];
    const type = homeMaintenanceObject.constant();
    const description =
        "A monthly home budget is a quintessential part of having a healthy financial future. Whether it be keeping track of strata fees, or allocating a portion of your paycheck to do start that DIY project you've always invisioned, a Home maintenance Node can capture it all.";
    const usedForText =
        "Use the Home maintenance Node to track reoccuring home related expenses on a monthly basis and maintain a health cashflow.";
    const connection = "Connect a Home Maintenance Node after a House Node.";
    const connectionImage = HomeMaintenanceConnectionImage;
    const learnMoreLink =
        "https://www.thebalance.com/home-maintenance-budget-453820";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const HomeMaintenance_SideModalImage = () => {
    return sideImage;
};

import React, { Component } from "react";
import "./InputContainer.css";
import moment from "moment";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import { InputButtons } from "./Components";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import { getDefaultName } from "../../helpers";
import flinksSvg from "../../Assets/_flinksIcons/flinks.svg";
import plaidSvg from "../../Assets/_flinksIcons/plaid.svg";
import { PlaidLink } from "react-plaid-link";
import swal from "sweetalert";
import BankPlaidInput from "./BankPlaidInput";
import BankAlt from "./BankAlt";
import { bankObject } from "../Registry/Bank";

import * as uuid from "uuid";
// TODO RENAME TO BANK FLINKS
const options = [
    "Chequing",
    "Saving",
    "RRSP",
    "RESP",
    "RDSP",
    "TFSA",
    "Investing",
    "Credit Card",
    "Other",
];

class BankInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...BankInput.getDefaultState(
                props.inflationRate,
                getDefaultName(bankObject.constant(), props),
                props.baseline
            ),
            ...props.editData,
        };
        this.handleFlinks = this.handleFlinks.bind(this);
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            isFilled: false,
            valid: false,
            name: name,
            flinksState: "default",
            identifier: "",
            proceed: false,
            selected: [],
            ids: [],
            type: bankObject.constant(),
            bankData: null,
            description: "",
            rating: 0,
            bank: null,
            tag: `@${bankObject.constant()}`,
            beta: true,
            onHoverStar: null,
            inflation: false,
            inflationRate: inflationRate,
            baseline: isInBaseline,
            accounts: [
                {
                    accountName: "",
                    accountType: "",
                    accountBalance: "",
                    id: uuid.v4(),
                },
            ],
            value: "",
            accountName: "",
            accountType: "",
            lastUpdated: "",
            acctNicknames: {},
            acctCategories: {},
        };
    }

    handlePlaidSelectedAccount = (selected, ids) => {
        this.setState({ selected, ids });
    };

    passedCheck = false;

    componentDidUpdate(prevProps) {
        if (prevProps.bankData !== this.props.bankData && this.props.bankData) {
            const newBankData = {
                Accounts: (this.props.bankData.Accounts || []).map(
                    (account) => {
                        const {
                            TransitNumber,
                            InstitutionNumber,
                            Title,
                            AccountNumber,
                            Balance,
                            Type,
                            Currency,
                            Id,
                        } = account;
                        const acct = {
                            TransitNumber,
                            InstitutionNumber,
                            Title,
                            AccountNumber,
                            Balance,
                            Type,
                            Currency,
                            Id,
                        };
                        return acct;
                    }
                ),
            };
            this.setState({
                proceed: true,
                bankData: newBankData,
                loading: false,
            });
        }

        if (this.state.flinksState === "choose bank") {
            window.addEventListener("message", (e) => {
                if (e.data.step === "REDIRECT") {
                    this.handleFlinks(e);
                }
            });
            this.flinks.addEventListener("event", this.handleFlinks);
        }

        if (prevProps.editData !== this.props.editData) {
            if (this.props.editData.accounts.length > 0)
                this.setState({ ...this.props.editData });
            else {
                this.setState({
                    accounts: [
                        {
                            accountName: "",
                            accountType: "",
                            accountBalance: "",
                            id: uuid.v4(),
                        },
                    ],
                });
            }
        }
    }

    deleteAccount = (id) => {
        const accounts = this.state.accounts;
        const newAccountsArray = [];

        for (let i = 0; i < accounts.length; i++) {
            if (accounts[i].id !== id) newAccountsArray.push(accounts[i]);
        }

        this.setState({ accounts: newAccountsArray });
    };

    handleFlinks = (e) => {
        if (this.state.hasCalledFlinks) return;
        this.setState({ hasCalledFlinks: true });
        const identifier = uuid.v4();
        this.setState({ identifier: identifier });
        this.props.saveFlinksBankData(e.data, identifier);
        this.setState({ bank: e.data.institution, loading: true });
        //this is where you get your bank account data
    };

    onChangeInput = (e, star) => {
        const value = e.target.value;
        const id = e.target.id;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);

                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onClickLink = () => {
        this.setState({ flinksState: "choose bank", bankSelected: "flinks" });
    };

    onHandleProceed = () => {
        if (this.state.proceed) {
            this.setState({ flinksState: "accounts" });
            this.setState({ lastUpdated: new Date().toDateString() });
        } else {
            return null;
        }
    };

    handleSelectAccount = (id, account) => {
        const { selected, ids } = this.state;

        if (ids.includes(id)) {
            const filteredArray = selected.filter((item) => {
                return item.Id !== account.Id;
            });

            const filterIds = ids.filter((id) => {
                return id !== account.Id;
            });
            this.setState({
                selected: filteredArray,
                ids: filterIds,
            });
        } else {
            this.setState({
                selected: [...this.state.selected, account],
                ids: [...this.state.ids, account.Id],
            });
        }
    };

    onHandleNickname = (e) => {
        const value = e.target.value;
        const id = e.target.id;
        // const { selected } = this.state;
        const acctNicknames = { ...this.state.acctNickNames };
        acctNicknames[id] = value;
        this.setState({ acctNicknames });
        // const updatedSelected = selected.map((item) => {
        //     if (item.Id === id) {
        //         const newItem = {
        //             ...item,
        //             Nickname: value,
        //         };
        //         return newItem;
        //     }
        //     return item;
        // });

        // this.setState({ selected: updatedSelected });
    };

    onHandleCategory = (e) => {
        const value = e.target.value;
        const id = e.target.id;
        const acctCategories = { ...this.state.acctCategories };

        acctCategories[id] = value;
        this.setState({ acctCategories });
    };

    submitBankDataValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const { bankSelected } = this.state;

        let newState;
        if (bankSelected === "plaid") {
            let value = 0;
            this.state.selected.map((account) => {
                return (value += account.balances.current);
            });

            newState = {
                ...this.state,
                value: Math.floor(value),
                start: moment(new Date()).format("YYYY-MM-DD"),
                isFilled: true,
                valid: true,
            };
        } else if (bankSelected === "flinks") {
            let value = 0;
            this.state.selected.map((account) => {
                if (account.accountType === "Credit Card") {
                    value -= account.Balance.Current;
                } else {
                    value += account.Balance.Current;
                }
                return value;
            });
            this.setState({ value: value });
            newState = {
                ...this.state,
                value: Math.floor(value),
                start: moment(new Date()).format("YYYY-MM-DD"),
                lastUpdated: moment(new Date()).format("YYYY-MM-DD"),
                isFilled: true,
                valid: true,
            };
        } else {
            newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
        }

        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            if (bankSelected === "manual") {
                if (bankObject.checkInput(this.state)) {
                    this.props.confirmAction(newState);
                } else {
                    throwError(
                        "error",
                        "Missing Input",
                        "Please fill up bank name and select accounts"
                    );
                }
            } else {
                this.props.submitBankData(newState);
            }
        }
    };

    submitBankData = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            value: 0,
            start: moment(new Date()).format("YYYY-MM-DD"),
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.submitBankData(newState);
        }
    };

    updateValues = () => {
        const { bankSelected } = this.state;
        let newState;
        if (bankSelected === "plaid") {
            let value = 0;
            this.state.selected.map((account) => {
                return (value += account.balances.current);
            });

            newState = {
                ...this.state,
                value: Math.floor(value),
                start: moment(new Date()).format("YYYY-MM-DD"),
                isFilled: true,
                valid: true,
            };
        } else if (bankSelected === "flinks") {
            let value = 0;
            this.state.selected.map((account) => {
                if (account.Type === "CreditCard") {
                    value -= account.Balance.Current;
                } else {
                    value += account.Balance.Current;
                }
                return value;
            });
            newState = {
                ...this.state,
                value: Math.floor(value),
                start: moment(new Date()).format("YYYY-MM-DD"),
                isFilled: true,
                valid: true,
            };
        } else {
            newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
        }
        if (bankObject.checkInput(this.state)) {
            this.props.updateValues(newState);
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up bank name and select accounts"
            );
        }
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onHandleFlinksState = (state) => {
        this.setState({ flinksState: state });
    };

    onSuccess = (token, _metadata) => {
        this.props.getPlaidAccount(token, (_err, data) => {
            if (data) {
                this.setState({ bankData: data.data, bankSelected: "plaid" });
                this.onHandleFlinksState("accounts");
            } else {
                swal({
                    icon: "error",
                    title: "Cannot get bank account details",
                });
            }
        });
    };

    updateFlinksData = () => {
        const loginId = localStorage.getItem(this.state.identifier);
        this.props
            .updateFlinksData({
                loginId,
                update: true,
            })
            .then((value) => {
                const accounts = value.data.Accounts;
                if (accounts) {
                    const bankData = {
                        ...this.state.bankData,
                        Accounts: accounts,
                    };
                    //update selected accounts
                    let count = 0;
                    const updatedSelected = [...this.state.selected];
                    this.state.selected.forEach((selectedAcc) => {
                        accounts.forEach((updatedAcc) => {
                            if (
                                selectedAcc.AccountNumber ===
                                updatedAcc.AccountNumber
                            ) {
                                updatedSelected[count] = updatedAcc;
                            }
                        });
                        count++;
                    });
                    this.setState({ bankData, selected: updatedSelected });
                    throwError("success", "Flinks Updated");
                }
            })
            .catch((err) => {
                console.log(err);
                throwError(
                    "error",
                    "Something Went Wrong",
                    "We were unable to retrieve your flinks data"
                );
            });
    };

    handleManual = () => {
        this.setState({ bankSelected: "manual", flinksState: "accounts" });
        // this.onHandleFlinksState("accounts");
    };

    handleManualBank = (accounts, dateStr, totalBalance = 0) => {
        if (totalBalance) {
            this.setState({ value: totalBalance });
        }
        this.setState({ accounts: accounts });
        this.setState({ lastUpdated: dateStr });
    };

    render() {
        const isPassed = bankObject.checkInput(this.state);
        const { edit } = this.props;
        const { bankSelected } = this.state;

        return (
            <div className="mainRootContainer">
                <div
                    className={
                        this.state.flinksState === "default"
                            ? "inputFull"
                            : "inputRoot"
                    }
                >
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={bankObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">BANK ACCOUNT</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <input
                                    className="ExpenseInputLabel"
                                    placeholder="Bank Account Name"
                                    id="name"
                                    onChange={this.onChangeInput}
                                    value={this.state.name}
                                />
                            </div>
                        </div>
                    </div>

                    {this.state.flinksState === "default" && (
                        <div className="pageOneContainer">
                            <div className="LinkContainer">
                                <div className="flinksContextContainer">
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img
                                            src={flinksSvg}
                                            alt="flinks logo"
                                        />
                                        <div className="labelBold">
                                            Connect Your Bank Account
                                        </div>
                                    </div>

                                    <div className="subLabel">
                                        Link to your bank accounts using a
                                        secure, 3rd party integration. We do not
                                        store or have access to any of your
                                        banking credentials.
                                    </div>
                                </div>
                                <div className="flinksButtonContainer">
                                    <div
                                        className="manualBankButton"
                                        onClick={this.onClickLink}
                                    >
                                        Connect
                                    </div>
                                </div>
                            </div>

                            <div className="LinkContainer">
                                <div className="flinksContextContainer">
                                    <div
                                        style={{
                                            display: "flex",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img src={plaidSvg} alt="plaid logo" />
                                        <div className="labelBold">
                                            Add via Plaid (US)
                                        </div>
                                    </div>
                                    <div className="subLabel">
                                        Link to your bank accounts (US or
                                        Canada) using Plaid&apos;s secure, 3rd
                                        party integration. whatifi does not
                                        store or have access to any of your
                                        banking credentials.
                                    </div>
                                </div>
                                <div className="flinksButtonContainer">
                                    <PlaidLink
                                        clientName="whatifi"
                                        // env="development"
                                        env="sandbox"
                                        product={["auth", "transactions"]}
                                        publicKey="64848e34a7b3f0fcb899d1ab8222e8"
                                        onSuccess={this.onSuccess}
                                        className="connectBankButton"
                                    >
                                        Coming Soon
                                    </PlaidLink>
                                </div>
                            </div>

                            <div className="LinkContainer">
                                <div className="flinksContextContainer">
                                    <div
                                        style={{
                                            display: "flex",
                                            margin: "15px 0px",
                                        }}
                                    >
                                        <div className="labelBold">
                                            Add Your Account Manually
                                        </div>
                                    </div>
                                    <div className="subLabel">
                                        Enter your bank account totals manually.
                                        This option does not require any banking
                                        information but will not update
                                        automatically as a result.
                                    </div>
                                </div>
                                <div className="flinksButtonContainer">
                                    <div
                                        className="manualBankButton"
                                        onClick={this.handleManual}
                                    >
                                        Manual Add
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {this.state.flinksState === "choose bank" && (
                        <div
                            className="inputRootBank"
                            ref={(elem) => (this.flinks = elem)}
                        >
                            {this.state.loading ? (
                                <img
                                    alt="alt"
                                    className="loadingGif"
                                    src="https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/assets/loading.gif"
                                />
                            ) : (
                                <div className="flinksModal">
                                    <iframe
                                        // TODO: ESLINT FIX THIS
                                        // eslint-disable-next-line react/no-unknown-property
                                        class="flinksconnect"
                                        height="760"
                                        src="https://whatifi-iframe.private.fin.ag/v2/?institutionsLocation=ca"
                                    ></iframe>
                                    <div className="flinksContentContainer">
                                        <div className="flinksContent">
                                            <div className="flinksLabelBold">
                                                1. Choose Your Bank
                                            </div>
                                            <div className="flinksSubLabel">
                                                Follow the instructions to add
                                                your bank.
                                            </div>
                                        </div>
                                        <div className="flinksContent">
                                            <div className="flinksLabelBold">
                                                2. Connect Your Account with Us
                                            </div>
                                            <div className="flinksSubLabel">
                                                Click the &quot;Proceed&quot;
                                                button when you see the
                                                confirmation that you&apos;re
                                                successfully connected!
                                            </div>
                                        </div>
                                        <div className="flinksButtonsWrapper">
                                            <div
                                                onClick={this.onHandleProceed}
                                                className={
                                                    this.state.proceed
                                                        ? "proceedBtnActive"
                                                        : "proceedBtn"
                                                }
                                            >
                                                Proceed
                                            </div>
                                            <div
                                                onClick={() =>
                                                    this.onHandleFlinksState(
                                                        "default"
                                                    )
                                                }
                                                className="cancelBtn"
                                            >
                                                Cancel
                                            </div>
                                            <div className="tagLine">
                                                POWERED BY FLINKS.IO
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {this.state.flinksState === "accounts" && (
                        <div>
                            {bankSelected === "plaid" &&
                                this.state.bankData && (
                                    <BankPlaidInput
                                        editData={this.state}
                                        bankData={this.state.bankData}
                                        handlePlaidSelectedAccount={
                                            this.handlePlaidSelectedAccount
                                        }
                                    />
                                )}
                            {bankSelected === "manual" && (
                                <BankAlt
                                    value={this.state.value}
                                    accountName={this.state.accountName}
                                    accountType={this.state.accountType}
                                    lastUpdated={this.state.lastUpdated}
                                    accounts={this.state.accounts}
                                    handleManualBank={this.handleManualBank}
                                    deleteAccount={this.deleteAccount}
                                />
                            )}
                            {bankSelected === "flinks" &&
                                this.getFlinksAccountList()}
                        </div>
                    )}
                </div>
                {this.state.flinksState === "accounts" && (
                    <InputButtons
                        edit={edit}
                        updateValues={this.updateValues}
                        onHandleSubmit={this.submitBankData}
                        onHandleSubmitValues={this.submitBankDataValues}
                        passedCheck={isPassed}
                        withSelected={bankSelected === "manual" ? false : true}
                        selected={this.state.selected.length}
                        isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                        isLibraryEvent={this.state.isLibraryEvent}
                        addLibraryEventToScenario={() => {
                            this.props.addLibraryEventToScenario(this.state);
                        }}
                        divorceLibraryEvent={() => {
                            this.props.divorceFromLibraryEvent(
                                this.state,
                                this.props.updateValues
                            );
                        }}
                    />
                )}
            </div>
        );
    }

    getFlinksAccountList = () => {
        const { edit } = this.props;
        const { lastUpdated, ids, bankData } = this.state;
        return (
            <div className="inputRootBank">
                <div className="accountsHeader">
                    Select the accounts that you want whatifi to track and keep
                    up-to-date.
                </div>
                <div className="accountsHeaderFlex">
                    Last Updated: {lastUpdated}
                    {edit && (
                        <div
                            className="proceedButton"
                            onClick={this.updateFlinksData}
                        >
                            Update Flinks Data
                        </div>
                    )}
                </div>
                <div className="accountsContainerRoot">
                    <div className="titleContainer">
                        <div className="titleHeader">#Title</div>
                        <div className="balanceHeader">Balance</div>
                        <div className="accountHeader">Account #</div>
                        <div className="typeHeader">Type</div>
                    </div>
                    <div className="bankAccountsContainer">
                        {bankData &&
                            bankData.Accounts.map((account, i) => {
                                const wholeNum = account.Balance.Current;

                                if (account.Type === "CreditCard") {
                                    return this.getFlinksAccountEntry(
                                        account,
                                        i,
                                        ids.includes(account.Id),
                                        -wholeNum
                                    );
                                }

                                const isSelected = ids.includes(account.Id);

                                return this.getFlinksAccountEntry(
                                    account,
                                    i,
                                    isSelected,
                                    wholeNum
                                );
                            })}
                    </div>
                </div>
                <div className="tagLine">POWERED BY FLINKS.IO</div>
            </div>
        );
    };

    getFlinksAccountEntry = (account, i, isSelected, wholeNum) => {
        const { acctCategories, acctNicknames } = this.state;

        return (
            <div key={i} className="account">
                <div className="accountInfo">
                    <img
                        alt="alt"
                        className="bankCheckBox"
                        src={isSelected ? checkboxOn : checkboxOff}
                        onClick={() =>
                            this.handleSelectAccount(account.Id, account)
                        }
                    />

                    <div className="number">{i + 1}</div>
                    <div className="title">{account.Title}</div>
                    <div className="balance">
                        {account.Currency}
                        {wholeNum}
                    </div>
                    <div className="accountNumber">{account.AccountNumber}</div>
                    <div className="accountType">{account.Type}</div>
                </div>
                <div className="accountInputContainer">
                    <input
                        type="text"
                        className="accountNickname"
                        placeholder="Acct Nickname"
                        id={account.Id}
                        onChange={this.onHandleNickname}
                        value={acctNicknames[account.Id]}
                    />
                    <select
                        placeholder="Account Category”"
                        className="accountSelect"
                        onChange={this.onHandleCategory}
                        id={account.Id}
                    >
                        <option value={null}>Account Category</option>
                        {options.map((option, i) => {
                            return (
                                <option
                                    key={i}
                                    value={option}
                                    selected={
                                        acctCategories[account.Id] === option
                                    }
                                >
                                    {option}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );
    };
}

export default BankInput;

import type { CanvasCardsSchema } from "./typesSchema/canvasCardSchema";

import { SET_CANVAS_CARDS } from "actions/canvasCardActions";

const initialState: CanvasCardsSchema = {};

export default function canvasCards(
    state: CanvasCardsSchema = initialState,
    action: { type: string; payload: any }
): CanvasCardsSchema {
    switch (action.type) {
        case SET_CANVAS_CARDS: {
            return { ...action.payload.cards };
        }
        default:
            return state;
    }
}

import { useActionsData } from "Components/EventsCanvas/Experimental/CanvasStage/hooks/useActionsData";
import { useContext } from "react";
import { HasCopiedEventContext } from "../../../../contexts/HasCopiedEventContext";
import { HasSiblingEventContext } from "../../../../contexts/HasSiblingEventContext";
import { getInsertActionActive } from "./helpers/getInsertActionActive";
import { LineIdContext } from "../../../../contexts/lineIdContext";
import { getEvent } from "actions/getNodeEntityActions";
import { containerObject } from "Components/Registry/Container";

/**
 * Finds the active image for a menuIcon given the passed buttonId.
 */
export function useInsertIconImage(buttonId: string): string {
    const actionData = useActionsData(buttonId);

    const lineId = useContext(LineIdContext);
    const hasCopiedEvent = useContext(HasCopiedEventContext);
    const hasSiblingEvent = useContext(HasSiblingEventContext);

    const lineReferencesContainer = () => {
        const splitId = lineId?.split("_");
        const parentEventId = splitId?.[0];
        const childEventId = splitId?.[1]?.split("-background")[0];
        const parentEvent: any = getEvent(parentEventId);
        const childEvent: any = getEvent(childEventId);
        if (
            parentEvent?.relevantContainerId ||
            childEvent?.relevantContainerId ||
            parentEvent?.type === containerObject?.constant() ||
            childEvent?.type === containerObject?.constant()
        ) {
            return true;
        }
        return false;
    };

    const active = getInsertActionActive(
        actionData?.action,
        !lineReferencesContainer() && hasCopiedEvent,
        hasSiblingEvent
    );

    if (active) {
        return actionData?.activeIconUrl;
    } else {
        return actionData?.inactiveIconUrl;
    }
}

import React, { Component } from "react";
import "./InputContainer.css";
import Calendar from "../Calendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    Description,
    SelectDropDownPerson,
    Value,
    Url,
    InputButtons,
    EventInputModal,
    SelectDropDown,
    EventInputs,
    EventHeader,
    ModalRow,
    RequiredStar,
    StarsRating,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { getPresentableDependenciesOfManyTypes } from "../../helpers/nodeDependencyDetectionHelpers";
import { Me, Person } from "../../helpers/constants";
import { grantObject } from "../Registry/Grant";

const options = [
    "annually",
    "per-term",
    "one-time",
];

class DebtInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            income: 0,
            url: "",
            start: null,
            rating: 0,
            cadence: "",
            name: getDefaultName(grantObject.constant(), this.props),
            description: "",
            type: grantObject.constant(),
            value: null,
            showSettings: false,
            tag: `@${grantObject.constant()}`,
            onHoverStar: null,
            baseline: false,
            people: this.getPeople(props),
            personId: "",
            personName: "",
        };
    }

    getPeople = (props = this.props) => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            props,
            "Person"
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        return people;
    };

    // Double check this checks for if the baseline is altered
    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.editData,
                people: this.getPeople(),
            });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "value":
                this.setState({
                    income: value,
                    value: parseInt(value),
                });
                break;
            case "url":
                this.setState({ url: value });
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "person":
                if (value === "Select Person" || !value) {
                    this.setState({ personName: "", personId: null });
                } else {
                    this.setState({
                        personName: value,
                        personId: this.state.people[value],
                    });
                }
                break;
            case "cadence":
                if (value === "") {
                    this.setState({ cadence: "" });
                } else {
                    this.setState({ cadence: value });
                }
                break;
            default:
        }
    };

    onHandleDate = (startDate) => {
        this.setState({ start: startDate});
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = grantObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onToggleCheckBox = () => {
        this.setState({ disableOnRetirement: !this.state.disableOnRetirement });
    };

    render() {
        const passedCheck = grantObject.checkInput(this.state);
        const { edit } = this.props;

        const {
            name,
            description,
            income,
            cadence,
            personName,
            url,
            rating,
            start,
            onHoverStar,
        } = this.state;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={grantObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={grantObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                            <Value
                                label="Monetary Amount"
                                onChangeInput={this.onChangeInput}
                                value={income}
                                id="value"
                            />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={cadence}
                                options={options}
                                className="select-dropdown"
                                id="cadence"
                                label="Award Frequency"
                            />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDownPerson
                            onChangeInput={this.onChangeInput}
                            value={personName}
                            options={Object.keys(this.state.people)}
                            className="IncomeSelect"
                            id="person"
                            label="Select Person"
                        />
                    </ModalRow>

                    <ModalRow>
                        <Url
                            value={url}
                            className="UrlLink"
                            onChangeInput={this.onChangeInput}
                            id="url"
                            label="URL"
                        />
                    </ModalRow>

                    <ModalRow>
                        <RequiredStar />
                        <Calendar
                            onHandleDate={this.onHandleDate}
                            startDate={start}
                        />
                    </ModalRow>
                    <ModalRow>
                        <StarsRating
                            onHoverStar={onHoverStar}
                            onHandleStarHover={this.onHandleStarHover}
                            rating={rating}
                            onChangeInput={this.onChangeInput}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ModalRow>

                    {this.state.showRequired && (
                        <ModalRow>
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </ModalRow>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {  },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default DebtInput;

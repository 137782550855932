import { Group } from "react-konva";
import { useState, useEffect, useContext } from "react";
import Konva from "konva";
import { useAppDispatch } from "store/useAppSelectorDispatch";
import { openAccountCardModal } from "actions/canvasCardModalsActions";
import { updatedFocusedCardId } from "actions/canvasCardActions";

import { useMemo } from "react";
import { animated, useSpring } from "@react-spring/konva";
import {
    AccountCardIdContext,
    UpdatePositionContext,
    CardDisplayValueContext,
    AccountNameContext,
    UpdateMonthlyOrCumulative,
    MonthlyOrCumulativeContext,
    AccountCardFocusContext,
} from "./contexts/AccountCardContexts";

import {
    HoveredCardIdContext,
    SetHoveredCardIdContext,
} from "../../../contexts/CanvasCardsStateContext";

import { CanvasStageScaleContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageScaleContext";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";

const defaultDimensions = {
    width: 250,
    height: 100,
};

export const AccountCardMain = ({ x, y }) => {
    const dispatch = useAppDispatch();

    const stageScale = useContext(CanvasStageScaleContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);

    const cardId = useContext(AccountCardIdContext);
    const updatePosition = useContext(UpdatePositionContext);
    const cardDisplayValue = useContext(CardDisplayValueContext);
    const accountName = useContext(AccountNameContext);
    const updateMonthlyOrCumulative = useContext(UpdateMonthlyOrCumulative);
    const monthlyOrCumulative = useContext(MonthlyOrCumulativeContext);
    const focused = useContext(AccountCardFocusContext);
    const hoveredCardId = useContext(HoveredCardIdContext);
    const setHoveredCardId = useContext(SetHoveredCardIdContext);

    const [canDrag, setCanDrag] = useState(false);
    const [canFocus, setCanFocus] = useState(true);

    useEffect(() => {
        if (hoveredCardId === cardId) {
            setCanDrag(true);
        } else {
            setCanDrag(false);
        }
    }, [hoveredCardId, cardId]);

    const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
        updatePosition(e.target.attrs.x, e.target.attrs.y);
    };

    const bodyStyles = useMemo(() => {
        let _bodyStyles = {
            width: defaultDimensions.width,
            height: defaultDimensions.height,
            cornerRadius: [20, 20, 20, 20],
            fill: "#FFFFFF",
            shadowBlur: 4,
            shadowColor: "#000000",
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowOpacity: 0.5,
            strokeWidth: 0,
            stroke: "#f7ab5f",
        };
        if (focused) {
            _bodyStyles = {
                ..._bodyStyles,
                strokeWidth: 5,
                stroke: "#f7ab5f",
            };
        }
        return _bodyStyles;
    }, [focused]);

    const bodyAnimation = useMemo(() => {
        const _bodyAnimation = {};
        return _bodyAnimation;
    }, []);

    const animatedBodyStyles = useSpring<Konva.RectConfig>({
        to: bodyStyles,
        config: bodyAnimation,
    });

    const accountNameTextStyles = useMemo(() => {
        const _accountNameTextStyles = {
            y: -30,
            width: 144,
            height: 23,
            verticalAlign: "center",
            lineHeight: 2,
            align: "right",
            ellipsis: true,
            fontFamily: "CeraPro-Regular",
            fontSize: 14,
            fill: "#1B2559",
        };

        return _accountNameTextStyles;
    }, []);

    const accountNameTextAnimation = useMemo(() => {
        const _accountNameTextAnimation = {};
        return _accountNameTextAnimation;
    }, []);

    const animatedAccountNameTextStyles = useSpring<Konva.RectConfig>({
        to: accountNameTextStyles,
        config: accountNameTextAnimation,
    });

    const accountValueTextStyles = useMemo(() => {
        const _accountValueTextStyles = {
            y: -7,
            width: 144,
            height: 31,
            verticalAlign: "center",
            lineHeight: 1,
            align: "right",
            ellipsis: true,
            fontFamily: "CeraPro-Regular",
            fontSize: 24,
            fill: "#1B2559",
            fontStyle: "700",
        };

        return _accountValueTextStyles;
    }, []);

    const accountValueTextAnimation = useMemo(() => {
        const _accountValueTextAnimation = {};
        return _accountValueTextAnimation;
    }, []);

    const animatedAccountValueTextStyles = useSpring<Konva.RectConfig>({
        to: accountValueTextStyles,
        config: accountValueTextAnimation,
    });

    const monthlyOrCumulativeCircleStyles = useMemo(() => {
        const _monthlyOrCumulativeCircleStyles = {
            // x: -50,
            // y: 0,
            radius: 28,
            fill: "#F4F7FE",
            stroke: "#9BC0FA",
            strokeWidth: 5,
        };

        return _monthlyOrCumulativeCircleStyles;
    }, []);

    const monthlyOrCumulativeCircleAnimation = useMemo(() => {
        const _monthlyOrCumulativeCircleAnimation = {};
        return _monthlyOrCumulativeCircleAnimation;
    }, []);

    const animatedMonthlyOrCumulativeCircleStyles = useSpring<Konva.RectConfig>(
        {
            to: monthlyOrCumulativeCircleStyles,
            config: monthlyOrCumulativeCircleAnimation,
        }
    );

    const monthlyOrCumulativeTextStyles = useMemo(() => {
        const _monthlyOrCumulativeTextStyles = {
            x: -12.5,
            y: -10,
            width: 25,
            height: 35,
            // verticalAlign: "center",
            // lineHeight: 1,
            align: "center",
            // ellipsis: true,
            fontFamily: "CeraPro-Regular",
            fontSize: 24,
            fill: "#4C5C72",
            fontStyle: "700",
        };

        return _monthlyOrCumulativeTextStyles;
    }, []);

    const monthlyOrCumulativeTextAnimation = useMemo(() => {
        const _monthlyOrCumulativeTextAnimation = {};
        return _monthlyOrCumulativeTextAnimation;
    }, []);

    const animatedMonthlyOrCumulativeTextStyles = useSpring<Konva.RectConfig>({
        to: monthlyOrCumulativeTextStyles,
        config: monthlyOrCumulativeTextAnimation,
    });

    const handleMoustEnterAccountCard = (
        e: Konva.KonvaEventObject<MouseEvent>
    ) => {
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = "pointer";
        setHoveredCardId(cardId);
    };

    const handleMoustLeaveAccountCard = (
        e: Konva.KonvaEventObject<MouseEvent>
    ) => {
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = "default";
        setHoveredCardId("");
    };

    const handleMoustEnterMonthlyOrCumulative = (
        e: Konva.KonvaEventObject<MouseEvent>
    ) => {
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = "pointer";
        setCanFocus(false);
    };

    const handleMoustLeaveMonthlyOrCumulative = (
        e: Konva.KonvaEventObject<MouseEvent>
    ) => {
        const container = e.target.getStage()?.container();
        if (container) container.style.cursor = "default";
        setCanFocus(true);
    };
    const handleDoubleClickAccountCard = (
        _e: Konva.KonvaEventObject<MouseEvent>
    ) => {
        dispatch(openAccountCardModal(cardId));
    };

    const handleClickAccountCard = (_e: Konva.KonvaEventObject<MouseEvent>) => {
        if (canFocus) {
            dispatch(updatedFocusedCardId(focused ? null : cardId));
        }
    };

    return (
        <Group
            x={x}
            y={y}
            draggable={canDrag}
            onDragEnd={onDragEnd}
            onDblClick={handleDoubleClickAccountCard}
            onClick={handleClickAccountCard}
            onMouseEnter={handleMoustEnterAccountCard}
            onMouseLeave={handleMoustLeaveAccountCard}
            scaleX={
                zoomCategory === "zoom-s" || zoomCategory === "zoom-xs"
                    ? (1 / stageScale) * 0.75
                    : 1
            }
            scaleY={
                zoomCategory === "zoom-s" || zoomCategory === "zoom-xs"
                    ? (1 / stageScale) * 0.75
                    : 1
            }
        >
            {/* @ts-ignore - Seems to be a react spring type error */}
            <animated.Rect {...animatedBodyStyles} />
            <Group
                x={defaultDimensions.width / 2}
                y={defaultDimensions.height / 2}
            >
                <Group>
                    <Group x={-39} y={0}>
                        {/* <Rect width={144} height={23} y={-30} fill="red" />  */}
                        <animated.Text
                            text={accountName ?? ""}
                            {...animatedAccountNameTextStyles}
                        />
                        {/* <Rect fill="green" y={-7}  width={144} height={31} />  */}
                        <animated.Text
                            text={cardDisplayValue ?? ""}
                            {...animatedAccountValueTextStyles}
                        />
                    </Group>
                    <Group
                        x={-77}
                        y={0}
                        onClick={() => updateMonthlyOrCumulative()}
                        onMouseEnter={handleMoustEnterMonthlyOrCumulative}
                        onMouseLeave={handleMoustLeaveMonthlyOrCumulative}
                    >
                        <animated.Circle
                            {...animatedMonthlyOrCumulativeCircleStyles}
                        />
                        <animated.Text
                            text={monthlyOrCumulative === "monthly" ? "M" : "C"}
                            {...animatedMonthlyOrCumulativeTextStyles}
                        />
                    </Group>
                </Group>
            </Group>
        </Group>
    );
};

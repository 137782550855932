import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

/*
 * Helper function that handles Income Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */
export const incomeTaxInputsHandler = (
    value: string,
    id: "person" | "province" | "entityName",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "person":
            if (!value || value === "Select Person") {
                data.personName = "";
                data.personId = "";
            } else {
                data.personName = value;
                data.personId = data.people[value];
            }
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "province":
            if (value === "Select Province") {
                data.province = null;
            } else {
                data.province = value;
            }
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

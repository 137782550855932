// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import CustomerChurn2Card from "../Card/customerChurn2Card";

// Import Connection Image (if exists)
import CustomerChurnConnection from "../../Assets/_optionDescriptionIcons/Customer, Customer Growth, Customer Churn.png";

// Import Coloured PNGs

// TODO Grab pngs from Michelle or Jon
import customerChurnBlack from "../../Assets/_eventNodeTypes/customer-churn-black.svg";
import customerChurnBlue from "../../Assets/_eventNodeTypes/churn-blue.png";
import customerChurnWhite from "../../Assets/_eventNodeTypes/churn-white.png";
import customerChurnGrey from "../../Assets/_eventNodeTypes/churn-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import CustomerChurn2InputExperimental from "Components/InputContainer/CustomerChurn2InputExperimental/CustomerChurn2InputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - customerObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - customerObject.checkInput.call(customerObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const customerChurn2Object = {
    constant: function () {
        return CustomerChurn2_Constant();
    },

    name: function () {
        return "Customer Churn 2";
    },

    checkInput: function (state) {
        return CustomerChurn2_CheckInput(state);
    },

    svg: function () {
        return CustomerChurn2_Svg();
    },

    eventNodeMenu: function (focus) {
        return CustomerChurn2_EventNodeMenu(focus);
    },

    indexText: function () {
        return CustomerChurn2_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return CustomerChurn2_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return CustomerChurn2_OptionDescription();
    },

    colouredPngs: function () {
        return CustomerChurn2_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return CustomerChurn2_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const CustomerChurn2_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (!entity.dependencies) return false;
        if (!entity.dependencies.entity) return false;
        if (!entity.dependencies.entity.eventId) return false;

        if (entity.data.churnType == "constant") {
            if (
                entity.data.constantChurnPeriod == "monthly" &&
                !entity.data.constantMonthlyChurnRate
            )
                return false;
            if (
                entity.data.constantChurnPeriod == "yearly" &&
                !entity.data.constantYearlyChurnRate
            )
                return false;
        } else if (entity.data.churnType == "dynamic") {
            if (entity.data.churnSegments.length <= 0) return false;

            for (const segment of entity.data.churnSegments) {
                if (!segment.startDate || !segment.period) return false;
                if (segment.period == "monthly") {
                    if (!segment.monthlyRate && segment.monthlyRate !== 0) {
                        return false;
                    }
                } else if (segment.period == "yearly") {
                    if (!segment.yearlyRate && segment.yearlyRate !== 0) {
                        return false;
                    }
                }
            }
        }

        if (!entity.data.inheritStartDate) {
            if (!entity.startDate) return false;
        }

        if (!entity.data.inheritCustomer) {
            if (!entity.data.startChurn) return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const CustomerChurn2_Constant = () => {
    return "8f312c18-9de3-41ca-9452-619f5581344f";
};

// EFFECTS: Returns black png / svg for event
const CustomerChurn2_Svg = () => {
    return customerChurnBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const CustomerChurn2_EventNodeMenu = (focus) => {
    return <CustomerChurn2Card data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const CustomerChurn2_IndexText = () => {
    return "The Customer Churn Event alters an existing Customer Event. We apply the percentage churn rates to the total Customer count each month.";
};

// EFFECTS: Returns all icons for event ??
const CustomerChurn2_Icons = () => {
    let pngs = new Map([
        ["black", customerChurnBlack],
        ["blue", customerChurnBlue],
        ["white", customerChurnWhite],
        ["grey", customerChurnGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const CustomerChurn_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const CustomerChurn2_InputComponentInfoPassing = (minProps) => {
    return <CustomerChurn2InputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const CustomerChurn2_OptionDescription = () => {
    const tags = ["Option"];
    const type = customerChurn2Object.name();
    const description =
        "The Churn or rate of Customer attrition is the month-over-month percentage of Users or Subscribers that stop using a product or service. Customer Churn can have a dramatic impact on the profitability and financial runway of a company.";
    const usedForText =
        "Use the Customer Churn Event, along with the Customer, Growth, Unit Cost and Revenue Events, to model out various subscription and SaaS models.";
    const connection = "";
    const connectionImage = CustomerChurnConnection;
    const learnMoreLink = "https://www.investopedia.com/terms/c/churnrate.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const CustomerChurn2_SideModalImage = () => {
    return sideImage;
};

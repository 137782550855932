// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";
import { ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const FilterButtonStyled = styled(ToggleButton)({
    width: "fit-content",
    fontSize: 16,
    lineHeight: "10px",
    padding: 8,
    border: "1px solid #f7ab5f",
    borderRadius: 16,
    backgroundColor: "#FFF",
    color: "#f7ab5f",
    fontFamily: "CeraPro-Regular",
    "&.Mui-selected": {
        backgroundColor: "#f7ab5f",
        color: "#FFF",
    },
    "&.Mui-selected:hover": {
        backgroundColor: "rgba(247, 171, 95, 0.75)",
        color: "#FFF",
    },
});

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    EntityName,
    KPIListItem,
    ScrollableFlexColumn,
    // SelectEntities,
    EntityCard,
} from "../Components";
import type { ChangeEvent } from "react";
import { kpiObject } from "Components/Registry/KPI";
import { kpisInputsHandler } from "../OnInputChangeHandlers/kpiInputsHandler";

const KPIsInputView = ({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    entitiesLength,
    handleEntityStateChange,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    forceEntityChange,
    relevantKPIs,
    handleKPIWarning,
}) => {
    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "value"
            | "entityName"
            | "filter"
            | "arpu"
            | "lifetimeValue"
            | "ltvToCACRatio"
            | "profitMargin"
            | "entity",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = kpisInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={kpiObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={kpiObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData?.[kpiObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                {/* if you add more filter buttons, just make sure to update the filter object in entityTypesStructure.json with the new filter key */}
                <div style={{ display: "flex", gap: "1rem", margin: "1rem 0" }}>
                    <FilterButtonStyled
                        variant="text"
                        selected={
                            !entitiesMap?.[currentEntity]?.data?.activeFilter
                        }
                        onClick={() => forceEntityChange("all", "filter")}
                    >
                        All
                    </FilterButtonStyled>
                    <FilterButtonStyled
                        variant="text"
                        selected={
                            entitiesMap?.[currentEntity]?.data?.filter?.saas
                        }
                        onClick={() => forceEntityChange("saas", "filter")}
                    >
                        Saas
                    </FilterButtonStyled>
                    <FilterButtonStyled
                        variant="text"
                        selected={
                            entitiesMap?.[currentEntity]?.data?.filter
                                ?.ecommerce
                        }
                        onClick={() => forceEntityChange("ecommerce", "filter")}
                    >
                        ECommerce
                    </FilterButtonStyled>
                    <FilterButtonStyled
                        variant="text"
                        selected={
                            entitiesMap?.[currentEntity]?.data?.filter?.general
                        }
                        onClick={() => forceEntityChange("general", "filter")}
                    >
                        General
                    </FilterButtonStyled>
                </div>
                <ScrollableFlexColumn>
                    {Object.values(relevantKPIs)?.length > 0 ? (
                        Object.values(relevantKPIs)?.map((kpi, i) => (
                            <KPIListItem
                                key={i}
                                displayName={kpi.displayName}
                                activeKPIField={kpi.activeKPIField}
                                description={kpi.description}
                                additionalInfo={kpi.additionalInfo}
                                categorys={kpi.categorys}
                                isActive={
                                    entitiesMap[currentEntity]?.data?.[
                                        kpi.activeKPIField
                                    ]
                                }
                                clickAction={handleOnChange}
                                isValid={handleKPIWarning(kpi.activeKPIField)}
                            />
                        ))
                    ) : (
                        <div>No KPIs of the selected type.</div>
                    )}
                </ScrollableFlexColumn>
                {/* <ModalRow>
                    <SelectEntities
                        onChangeInput={handleOnChange}
                        value={
                            entitiesMap[currentEntity].dependencies?.entity ??
                            ""
                        }
                        nodes={Object.values(
                            entitiesMap[currentEntity]?.data?.customers || []
                        )}
                        showAll={true}
                        className="IncomeSelect"
                        id="entity"
                        label="Select Record"
                        groupEventType={
                            isGroup(
                                entitiesMap[currentEntity]?.data
                                    ?.selectedEventId
                            ) && "All"
                        }
                    />
                </ModalRow> */}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
};

export default KPIsInputView;

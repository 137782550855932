import CheckboxChecked from "Assets/_nodeGraphIcons/checkbox-checked.svg";
import CheckboxUnchecked from "Assets/_nodeGraphIcons/checkbox-unchecked.svg";
import styles from "./availableAccount.module.css";
import expandArrow from "Assets/scenarioIcons/expand.svg";
import expandArrowOrange from "Assets/scenarioIcons/expandOrange.svg";
import { editScenario, getUserScenarios } from "actions/scenario.js";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import { shallowEqual } from "react-redux";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
// import Tooltip from "@mui/material/Tooltip";
import {
    SelectedTopLevelAccountContext,
    SelectedSubAccountContext,
    SelectedLeafAccountContext,
    SelectedLeafSubAccountContext,
} from "./accountPickerContexts";
import { useContext, useMemo } from "react";

export const AvailableAccount = ({
    selectAccount,
    handleExpand,
    id,
    accountLevel,
}) => {
    const dispatch = useAppDispatch();

    const selectedTopLevelAccount = useContext(SelectedTopLevelAccountContext);
    const selectedSubAccount = useContext(SelectedSubAccountContext);
    const selectedLeafAccount = useContext(SelectedLeafAccountContext);
    const selectedLeafSubAccount = useContext(SelectedLeafSubAccountContext);

    const allAccountData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );

    const accountData = useMemo(
        () => allAccountData?.[id] ?? {},
        [allAccountData, id]
    );

    const selectedAccountsHashMap = useAppSelector((state) => {
        const selectedAccountTypes: any[] =
            state?.graphAccountSelection?.selectedAccountTypes ?? [];

        const selectedAccountsHashMap = {};
        selectedAccountTypes?.map(
            (account) => (selectedAccountsHashMap[account?.id] = account)
        );
        return selectedAccountsHashMap;
    }, shallowEqual);

    const isSelected: boolean = useMemo(() => {
        return !!selectedAccountsHashMap?.[id];
    }, [id, selectedAccountsHashMap]);

    const isExpanded = useMemo(() => {
        switch (accountLevel) {
            case "topLevel":
                return id === selectedTopLevelAccount;
            case "subAccount":
                return id === selectedSubAccount;
            case "leafAccount":
                return id === selectedLeafAccount;
            case "leafSubAccount":
                return id === selectedLeafSubAccount;
            default:
                return false;
        }
    }, [
        accountLevel,
        id,
        selectedLeafSubAccount,
        selectedLeafAccount,
        selectedSubAccount,
        selectedTopLevelAccount,
    ]);

    const { loadedScenario, isFavouriteAccount } = useAppSelector((state) => {
        const loadedScenario = state?.scenario?.loadedScenario;
        const isFavouriteAccount =
            !!loadedScenario?.data?.favouriteAccounts?.[id];

        return {
            loadedScenario,
            isFavouriteAccount,
        };
    }, shallowEqual);

    const handleFavourite = () => {
        if (
            loadedScenario?.type === "sample" ||
            loadedScenario?.type === "shared"
        )
            return;
        let newScenario = loadedScenario;
        if (!loadedScenario?.data?.favouriteAccounts) {
            newScenario = {
                ...loadedScenario,
                data: {
                    ...loadedScenario.data,
                    favouriteAccounts: {
                        ...loadedScenario?.data?.favouriteAccounts,
                        [id]: true,
                    },
                },
            };
        } else if (!loadedScenario?.data?.favouriteAccounts?.[id]) {
            loadedScenario.data.favouriteAccounts[id] = true;
            newScenario = loadedScenario;
        } else if (loadedScenario?.data?.favouriteAccounts?.[id]) {
            delete loadedScenario?.data?.favouriteAccounts?.[id];
            newScenario = loadedScenario;
        }

        dispatch(
            editScenario(newScenario, (err, data) => {
                if (data) {
                    dispatch(getUserScenarios());
                } else if (err) {
                    console.log("error favouriting an account", err);
                }
            })
        );
    };

    const canExpand = useMemo(() => {
        if (accountLevel === "searched") {
            return false;
        } else if (accountLevel === "fifthLevel") {
            return false;
        } else {
            return true;
        }
    }, [accountLevel]);

    return (
        <div
            className={styles.container}
            style={{
                maxWidth: `${accountLevel === "searched" ? "224px" : "unset"}`,
            }}
        >
            <img
                className={styles.icon}
                onClick={() => selectAccount(id)}
                src={isSelected ? CheckboxChecked : CheckboxUnchecked}
                alt="checkbox"
            />
            {/* <Tooltip title={accountData?.name ?? ""} arrow> */}
            <span
                className={styles.text}
                onClick={() => handleExpand(accountLevel, id)}
            >
                {accountData?.name ?? ""}
            </span>
            {/* </Tooltip> */}
            {isFavouriteAccount ? (
                <StarIcon
                    sx={{ cursor: "pointer", color: "#f7ab5f" }}
                    onClick={() => handleFavourite()}
                />
            ) : (
                <StarBorderIcon
                    sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.54)" }}
                    onClick={() => handleFavourite()}
                />
            )}
            {canExpand && (
                <img
                    className={`${styles.icon} ${styles.iconChevron}`}
                    onClick={() => handleExpand(accountLevel, id)}
                    src={isExpanded ? expandArrowOrange : expandArrow}
                    alt="chevron"
                />
            )}
        </div>
    );
};

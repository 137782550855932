import React, { Component } from "react";
import "./InputContainer.css";
import checkboxOn from "../../Assets/_budgetIcons/checkbox-on.svg";
import checkboxOff from "../../Assets/_budgetIcons/checkbox-off.svg";
import dollarSvg from "../../Assets/_budgetIcons/dollar.svg";
import { roundNumberForDisplay } from "../../helpers";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";
import autoTransportSvg from "../../Assets/_budgetIcons/autoTransport.svg";
import billUtilitiesSvg from "../../Assets/_budgetIcons/billUtilities.svg";
import businessServicesSvg from "../../Assets/_budgetIcons/businessServices.svg";
import educationSvg from "../../Assets/_budgetIcons/education.svg";
import foodSvg from "../../Assets/_budgetIcons/food.svg";
import shoppingSvg from "../../Assets/_budgetIcons/shopping.svg";
import expenseSvg from "../../Assets/_budgetIcons/expense.svg";
import feeChargesSvg from "../../Assets/_budgetIcons/feeCharges.svg";
import giftDonationSvg from "../../Assets/_budgetIcons/giftDonation.svg";
import healthFitnessSvg from "../../Assets/_budgetIcons/healthFitness.svg";
import investmentSvg from "../../Assets/_budgetIcons/investment.svg";
import kidsSvg from "../../Assets/_budgetIcons/kids.svg";
import personalCareSvg from "../../Assets/_budgetIcons/personalCare.svg";
import taxSvg from "../../Assets/_budgetIcons/tax.svg";
import travelSvg from "../../Assets/_budgetIcons/travel.svg";
import addSvg from "../../Assets/_budgetIcons/add.svg";
import editSvg from "../../Assets/_budgetIcons/edit.svg";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import inflationSvg from "../../Assets/_budgetIcons/inflation.svg";
import backSvg from "../../Assets/_budgetIcons/back.svg";
import subCategorySvg from "../../Assets/_budgetIcons/subCategory.svg";
import customizeSvg from "../../Assets/_budgetIcons/customize.svg";
import { Name, Description, InputButtons } from "./Components";
import { getDefaultName } from "../../helpers";
import { budgetObject } from "../Registry/Budget";

import * as uuid from "uuid";

class BudgetAltInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: getDefaultName(budgetObject.constant(), this.props),
            tag: `@${budgetObject.constant()}`,
            ids: [],
            type: budgetObject.constant(),
            description: "",
            budgetList: [
                {
                    title: "Expense",
                    icon: expenseSvg,
                    id: 1,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Education",
                    icon: educationSvg,
                    id: 2,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Shopping",
                    icon: shoppingSvg,
                    id: 3,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Personal Care",
                    icon: personalCareSvg,
                    id: 4,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Health & Fitness",
                    icon: healthFitnessSvg,
                    id: 5,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Kids",
                    icon: kidsSvg,
                    id: 6,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Food & Dining",
                    icon: foodSvg,
                    id: 7,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Gifts & Donations",
                    icon: giftDonationSvg,
                    id: 8,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Investments",
                    icon: investmentSvg,
                    id: 9,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Bills & Utilities",
                    icon: billUtilitiesSvg,
                    id: 10,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Auto & Transport",
                    icon: autoTransportSvg,
                    id: 11,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Travel",
                    icon: travelSvg,
                    id: 12,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Fees & Charges",
                    icon: feeChargesSvg,
                    id: 13,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Business Services",
                    icon: businessServicesSvg,
                    id: 14,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
                {
                    title: "Taxes",
                    icon: taxSvg,
                    id: 15,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                    showSubCategory: false,
                    selected: false,
                },
            ],
            addNewCategories: false,
            subCategoriesList: [],
            newCategory: {
                title: "",
                icon: customizeSvg,
                id: null,
                budget: 0,
                inflation: false,
                inflationRate:
                    Number(this.props.loadedScenario.inflation) * 100,
                subCategoriesList: [],
                showSubCategory: false,
                selected: false,
            },
            value: null,
            budgetOnHover: null,
            inflation: false,
            baseline: false,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id, _star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;

            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { budgetList } = this.state;
        const { baseline, confirmBaselineAction } = this.props;
        if (budgetObject.checkInput(this.state)) {
            const selected = budgetList.filter((item) => {
                return item.selected;
            });

            let isPassed = true;
            selected.map((item) => {
                if (item.budget === 0) {
                    isPassed = false;
                }
                return item;
            });

            let value = 0;
            selected.map((item) => {
                return (value += item.budget);
            });

            if (isPassed) {
                const newState = {
                    ...this.state,
                    selected,
                    value: value,
                    isFilled: true,
                    valid: true,
                };

                if (baseline) {
                    confirmBaselineAction(newState);
                } else {
                    this.props.confirmAction(newState);
                }
            } else {
                throwError(
                    "error",
                    "Missing Input",
                    "Please fill up required budget."
                );
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };

        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    // Nov 16 2020 (Daniel V)
    // Updated updateValues fn below to make use of "fillPartialValues" prop coming from inputContainer.js.
    // "fillPartialValues" allows to partially fill an event node but avoids considering it "valid" until
    // all required fields are complete.

    updateValues = () => {
        const { budgetList } = this.state;
        let isPassed = budgetObject.checkInput(this.state);

        const selected = budgetList.filter((item) => {
            return item.selected;
        });

        selected.map((item) => {
            if (item.budget === 0) {
                isPassed = false;
            }
            return item;
        });

        let value = 0;
        selected.map((item) => {
            return (value += item.budget);
        });

        this.props.fillPartialValues(
            this.state,
            { selected, value: value },
            isPassed
        );
    };

    handleSelectAccount = (budget) => {
        const { budgetList } = this.state;
        const newBudget = budgetList.map((bud) => {
            if (bud.id === budget.id) {
                const newBud = {
                    ...bud,
                    selected: !bud.selected,
                };
                return newBud;
            }
            return bud;
        });

        newBudget.sort((a, b) => {
            return b.selected - a.selected;
        });

        this.setState({ budgetList: newBudget });
    };

    handleSelectAccount = (budget) => {
        const { budgetList } = this.state;
        const newBudget = budgetList.map((bud) => {
            if (bud.id === budget.id) {
                const newBud = {
                    ...bud,
                    selected: !bud.selected,
                };
                return newBud;
            }
            return bud;
        });

        newBudget.sort((a, b) => {
            return b.selected - a.selected;
        });

        this.setState({ budgetList: newBudget });
    };

    handleInflation = (budget) => {
        const { budgetList } = this.state;
        const newList = budgetList.map((item) => {
            if (item.Id === budget.Id) {
                const newItem = {
                    ...item,
                    inflation: !item.inflation,
                };
                return newItem;
            }
            return item;
        });
        this.setState({ budgetList: newList });
    };

    setBudget = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        const { budgetList } = this.state;

        const newBudgetList = budgetList.map((item) => {
            if (item.Id === parseInt(id)) {
                const newItem = {
                    ...item,
                    budget: parseInt(value),
                };
                return newItem;
            }
            return item;
        });

        this.setState({ budgetList: newBudgetList });
    };

    onHandleSubCategories = (budget) => {
        const newList = this.state.budgetList.map((bud) => {
            if (bud.id === budget.id) {
                const newBudget = {
                    ...bud,
                    showSubCategory: !bud.showSubCategory,
                };
                return newBudget;
            }
            return bud;
        });
        this.setState({ budgetList: newList });
    };

    onHandleAddCategory = () => {
        this.setState({ addNewCategories: true });
    };

    backToBudget = () => {
        this.setState({
            addNewCategories: false,
            newCategory: {
                title: "",
                icon: customizeSvg,
                id: null,
                budget: 0,
                inflation: false,
                inflationRate:
                    Number(this.props.loadedScenario.inflation) * 100,
                subCategoriesList: [],
            },
            subCategoriesList: [],
        });
    };

    onHandleAddSubCategory = () => {
        const subCategories = {
            title: "",
            icon: travelSvg,
            id: uuid.v4(),
            budget: 0,
        };

        this.setState({
            subCategoriesList: [...this.state.subCategoriesList, subCategories],
        });
    };

    onChangeSubCategoryValue = (e, id, title) => {
        const value =
            title === "budget" ? Number(e.target.value) : e.target.value;
        const { subCategoriesList } = this.state;
        const newSubCategories = subCategoriesList.map((sub) => {
            if (sub.id === id) {
                const newSub = {
                    ...sub,
                    [`${title}`]: value,
                };
                return newSub;
            }
            return sub;
        });
        this.setState({
            subCategoriesList: newSubCategories,
        });
    };

    onHandleNewCategory = (e, title) => {
        let value;
        switch (title) {
            case "budget":
                value = Number(e.target.value);
                const category = {
                    ...this.state.newCategory,
                    [`budget`]: value,
                    originalValue: value,
                };
                this.setState({ newCategory: category });
                break;
            case "title":
                value = e.target.value;
                break;
            case "inflation":
                value = !this.state.newCategory.inflation;
                break;
            default:
        }
        if (title !== "budget") {
            const category = {
                ...this.state.newCategory,
                [`${title}`]: value,
            };
            this.setState({ newCategory: category });
        }
    };

    deleteSubCategory = (id) => {
        const { subCategoriesList } = this.state;
        const filtered = subCategoriesList.filter((sub) => {
            return sub.id !== id;
        });
        this.setState({ subCategoriesList: filtered });
    };

    addCategory = () => {
        const { newCategory } = this.state;
        let totalBudget = 0;
        let budget = newCategory.budget;
        let subBudget = 0;
        let originalValue = newCategory.originalValue;
        this.state.subCategoriesList.map((sub) => {
            return (subBudget += sub.budget);
        });

        if (budget >= 0 && budget < subBudget) {
            totalBudget = subBudget;
        } else if (subBudget < originalValue) {
            totalBudget = originalValue;
        } else if (subBudget > originalValue) {
            totalBudget = subBudget;
        } else {
            totalBudget = subBudget;
        }

        if (this.state.newCategory.id) {
            const newBudgetList = this.state.budgetList.map((budgetList) => {
                if (budgetList.id === this.state.newCategory.id) {
                    const newBudget = {
                        ...this.state.newCategory,
                        subCategoriesList: this.state.subCategoriesList,
                        budget: totalBudget,
                    };
                    return newBudget;
                }
                return budgetList;
            });
            this.setState({
                budgetList: newBudgetList,
                newCategory: {
                    title: "",
                    icon: customizeSvg,
                    id: null,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                },
                subCategoriesList: [],
                addNewCategories: false,
            });
        } else {
            const newCategory = {
                ...this.state.newCategory,
                subCategoriesList: this.state.subCategoriesList,
                budget: totalBudget,
                id: this.state.budgetList.length + 1,
            };
            this.setState({
                budgetList: [newCategory, ...this.state.budgetList],
                addNewCategories: false,
                newCategory: {
                    title: "",
                    icon: customizeSvg,
                    id: null,
                    budget: 0,
                    inflation: false,
                    inflationRate:
                        Number(this.props.loadedScenario.inflation) * 100,
                    subCategoriesList: [],
                },
                subCategoriesList: [],
            });
        }
    };

    onHandleHover = (id) => {
        this.setState({ budgetOnHover: id });
    };

    onHandleHoverOut = () => {
        this.setState({ budgetOnHover: null });
    };

    handleEditBudget = (budget) => {
        const newBudget = {
            ...budget,
            selected: true,
        };
        this.setState({
            addNewCategories: true,
            newCategory: newBudget,
            subCategoriesList: budget.subCategoriesList,
        });
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        const { budgetList, newCategory } = this.state;
        const { loadedScenario, edit } = this.props;
        const passedCheck = budgetObject.checkInput(this.state);

        const inflationRate = Number(loadedScenario.inflation) * 100;

        const { name, description } = this.state;
        let count = 0;
        this.state.budgetList.map((item) => {
            if (item.selected) {
                count++;
            }
            return item;
        });

        return (
            <div className="mainRootContainer">
                {this.state.addNewCategories ? (
                    <div className="inputRoot">
                        <div onClick={this.backToBudget} className="backButton">
                            <img
                                alt="alt"
                                style={{
                                    height: "12px",
                                    width: "12px",
                                }}
                                src={backSvg}
                            />{" "}
                            Back to my Budget
                        </div>
                        <div className="categoryTitle">Add a New Category</div>
                        <div className="budgetContainer">
                            <div className="cardHolder">
                                <div style={{ display: "flex" }}>
                                    <img
                                        alt="alt"
                                        className="budgetIcon"
                                        src={newCategory.icon}
                                    />
                                    <input
                                        placeholder="New Category Name"
                                        className="newCategoryName"
                                        value={
                                            this.state.newCategory
                                                ? this.state.newCategory.title
                                                : ""
                                        }
                                        onChange={(e) =>
                                            this.onHandleNewCategory(e, "title")
                                        }
                                    />
                                </div>
                                <div className="budgetAmountContainer">
                                    <div className="twoThirds">
                                        <div className="Required">*</div>
                                        <img
                                            alt="alt"
                                            src={dollarSvg}
                                            className="budgetDollarSvg"
                                        />
                                        <input
                                            className="budgetInput"
                                            placeholder="Set a Budget"
                                            onChange={(e) =>
                                                this.onHandleNewCategory(
                                                    e,
                                                    "budget"
                                                )
                                            }
                                            type="number"
                                            value={
                                                this.state.newCategory.budget
                                                    ? this.state.newCategory
                                                          .budget
                                                    : ""
                                            }
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        <div className="budgetFrequency">
                                            / mon
                                        </div>
                                    </div>

                                    <div className="halfContainer">
                                        <img
                                            alt="alt"
                                            src={
                                                this.state.newCategory.inflation
                                                    ? checkboxOn
                                                    : checkboxOff
                                            }
                                            onClick={(e) =>
                                                this.onHandleNewCategory(
                                                    e,
                                                    "inflation"
                                                )
                                            }
                                            className="inflationCheckbox"
                                        />
                                        <div className="inflationText">
                                            Apply Inflation:
                                        </div>
                                        <div className="inflationHolder">
                                            {inflationRate} %
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="addSubCategory"
                                    onClick={this.onHandleAddSubCategory}
                                >
                                    <img
                                        alt="alt"
                                        style={{
                                            height: "12px",
                                            width: "12px",
                                        }}
                                        src={subCategorySvg}
                                    />
                                    {"  "}
                                    Add a sub-category
                                </div>
                            </div>
                        </div>
                        <div className="subCategoryContainer">
                            {this.state.subCategoriesList &&
                                this.state.subCategoriesList.map(
                                    (subCategory, i) => {
                                        return (
                                            <div key={i} className="cardHolder">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div className="subCategoryCount">
                                                        {i + 1}.
                                                    </div>
                                                    <input
                                                        placeholder="New Sub Category"
                                                        className="newCategoryName"
                                                        value={
                                                            subCategory.title
                                                        }
                                                        onChange={(e) =>
                                                            this.onChangeSubCategoryValue(
                                                                e,
                                                                subCategory.id,
                                                                "title"
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div className="budgetAmountContainer">
                                                    <div className="twoThirds">
                                                        <div className="Required">
                                                            *
                                                        </div>
                                                        <img
                                                            alt="alt"
                                                            src={dollarSvg}
                                                            className="budgetDollarSvg"
                                                        />

                                                        <input
                                                            className="budgetInput"
                                                            placeholder="Set a Budget"
                                                            onChange={(e) =>
                                                                this.onChangeSubCategoryValue(
                                                                    e,
                                                                    subCategory.id,
                                                                    "budget"
                                                                )
                                                            }
                                                            type="number"
                                                            value={
                                                                subCategory.budget
                                                                    ? subCategory.budget
                                                                    : ""
                                                            }
                                                        />
                                                        <div className="budgetFrequency">
                                                            / mon
                                                        </div>
                                                    </div>
                                                    <div className="halfContainer" />
                                                </div>
                                                <div className="halfContainer">
                                                    <div
                                                        onClick={() =>
                                                            this.deleteSubCategory(
                                                                subCategory.id
                                                            )
                                                        }
                                                        className="deleteSubCategory"
                                                    >
                                                        Delete
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                        <div className="ConfirmContainer">
                            <div
                                onClick={this.addCategory}
                                className={"addButton"}
                            >
                                {this.props.edit ? "Update" : "Add & Save"}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="inputRoot">
                        <div className="headerContainer">
                            <img
                                alt="alt"
                                src={budgetObject.svg()}
                                className="flinksLogo"
                            />
                            <div className="headerInput">
                                <div className="headerLabel">
                                    MONTHLY BUDGET
                                </div>
                                <div className="inputContainer">
                                    <div className="Required">*</div>
                                    <Name
                                        name={name}
                                        onChangeInput={this.onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ModalRow">
                            <Description
                                description={description}
                                onChangeInput={this.onChangeInput}
                            />
                        </div>

                        <div className="budgetLabel">
                            Set your monthly budget and select the ones that you
                            want whatifi to track and keep up-to-date.
                        </div>
                        <div className="cardContainer">
                            <div className="budgetAddButton">
                                <img
                                    alt="alt"
                                    className="budgetAddButtonSvg"
                                    src={addSvg}
                                />
                                <div
                                    onClick={this.onHandleAddCategory}
                                    className="budgetAddButtonText"
                                >
                                    Add a New Category
                                </div>
                            </div>
                            {budgetList.map((budget, i) => {
                                const onHoverBudget =
                                    this.state.budgetOnHover &&
                                    this.state.budgetOnHover === budget.id;
                                return (
                                    <div
                                        key={i}
                                        className="cardHolder"
                                        onMouseEnter={() =>
                                            this.onHandleHover(budget.id)
                                        }
                                        onMouseLeave={this.onHandleHoverOut}
                                    >
                                        <div className="cardHeader">
                                            <img
                                                alt="alt"
                                                className="cardIcon"
                                                src={budget.icon}
                                            />
                                            <div className="cardLabel">
                                                {budget.title}
                                            </div>

                                            <div className="editContainer">
                                                {onHoverBudget && (
                                                    <div>
                                                        <img
                                                            alt="alt"
                                                            className="editIcons"
                                                            src={editSvg}
                                                            onClick={() =>
                                                                this.handleEditBudget(
                                                                    budget
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <img
                                                alt="alt"
                                                src={
                                                    budget.selected
                                                        ? checkboxOn
                                                        : checkboxOff
                                                }
                                                onClick={(_e) =>
                                                    this.handleSelectAccount(
                                                        budget
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="cardRow">
                                            <div className="subCategoriesContainer">
                                                <img
                                                    alt="alt"
                                                    className="subCategoriesContainerIcon"
                                                    src={
                                                        budget.showSubCategory
                                                            ? dropdownSvg
                                                            : expandSvg
                                                    }
                                                    onClick={() =>
                                                        this.onHandleSubCategories(
                                                            budget
                                                        )
                                                    }
                                                />
                                                <div>
                                                    {budget.subCategoriesList &&
                                                        budget.subCategoriesList
                                                            .length}{" "}
                                                    sub categories
                                                </div>
                                            </div>

                                            <div className="budgetInflation">
                                                <img
                                                    alt="alt"
                                                    src={inflationSvg}
                                                    className="inflationCheckbox"
                                                />
                                                <div className="budgetInflationText">
                                                    Inflation:
                                                </div>
                                                <div className="inflationHolder">
                                                    {inflationRate} %
                                                </div>
                                            </div>
                                            <div className="amountValue">
                                                ${" "}
                                                {roundNumberForDisplay(
                                                    budget.budget
                                                )}
                                            </div>
                                        </div>
                                        {budget.showSubCategory &&
                                            budget.subCategoriesList.length >
                                                0 && (
                                                <div className="showSubCategoryContainer">
                                                    {budget.subCategoriesList.map(
                                                        (subCategory, i) => {
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    className="subCategory"
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            alt="alt"
                                                                            src={
                                                                                checkboxOn
                                                                            }
                                                                            className="checkBoxSvg"
                                                                        />
                                                                        <div>
                                                                            {
                                                                                subCategory.title
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="amountValue">
                                                                        ${" "}
                                                                        {roundNumberForDisplay(
                                                                            subCategory.budget
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                    withSelected={true}
                    selected={count}
                    totalNumber={this.state.budgetList.length}
                    isAddingLibraryEvent={this.props.isAddingLibraryEvent}
                    isLibraryEvent={this.state.isLibraryEvent}
                    addLibraryEventToScenario={() => {
                        this.props.addLibraryEventToScenario(this.state);
                    }}
                    divorceLibraryEvent={() => {
                        this.props.divorceFromLibraryEvent(
                            this.state,
                            this.props.updateValues
                        );
                    }}
                />
            </div>
        );
    }
}

export default BudgetAltInput;

import React, { Component } from "react";
import "./Card.css";
import moment from "moment";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";

class ExpressionCard extends Component {
    render() {
        const entity = Object.values(
            getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            )
        )[0];

        const { data, cadence, startDate, endDate } = entity;

        const dependantEntityId = data.selectedEntity ?? "";
        const dependantEntity = getRelevantEntities([dependantEntityId])?.[
            dependantEntityId
        ];

        const dependantEventId = data.selectedEvent ?? "";
        const dependantEvent = getEvent(dependantEventId);
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Selected Entity:</div>
                    <div className="cardValue">
                        {dependantEvent?.name} - {dependantEntity?.name}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Selected Account:</div>
                    <div className="cardValue">{data.accountName ?? ""}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Expression:</div>
                    <div className="cardValue">{`${data.expression ?? ""} ${
                        cadence ? `${cadence} /` : ""
                    }`}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Evaluated Expression:</div>
                    <div className="cardValue">{data.value}</div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Date</div>
                    <div className="cardValue">
                        {startDate
                            ? moment(startDate).format("MMM D YYYY")
                            : "No Start Date"}
                        -
                        {endDate
                            ? moment(endDate).format("MMM D YYYY")
                            : "No End Date"}
                    </div>
                </div>
            </div>
        );
    }
}

export default ExpressionCard;

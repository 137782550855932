import type { AllAccountLedgersSchema } from "reducers/typesSchema/allAccountLedgersSchema";

export const UPSERT_ALL_ACCOUNT_LEDGERS =
    "allAccountLedgers/UPSERT_ALL_ACCOUNT_LEDGERS";

export const upsertAllAccountLedgers = (
    payload: Partial<AllAccountLedgersSchema>
) => ({
    type: UPSERT_ALL_ACCOUNT_LEDGERS,
    payload,
});

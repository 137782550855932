// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import IncomeCard from "../Card/incomeCard.js";
import IncomeInput from "../InputContainer/IncomeInput.js";

// Import Connection Image (if exists)
// import IncomeConnectionImage from "../../Assets/_optionDescriptionIcons/Income, Income Tax, Expense.png";
import IncomeConnectionImage from "Assets/_optionDescriptionIcons/Income, Expense white.jpg";

// Import Coloured PNGs
import incomeBlack from "../../Assets/_eventNodeTypes/income-black-updated.svg";
import incomeBlue from "../../Assets/_eventNodeTypes/income-blue.png";
import incomeWhite from "../../Assets/_eventNodeTypes/income-white.png";
import incomeGrey from "../../Assets/_eventNodeTypes/income-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

import incomeInputsHandler from "Components/InputContainer/OnInputChangeHandlers/incomeInputsHandler";

// Notes
// To call an object with no parameters, can simply use
//  - incomeObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - incomeObject.checkInput.call(incomeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Income
export const incomeObject = {
    constant: function () {
        return Income_Constant();
    },

    name: function () {
        return "Income";
    },

    checkInput: function (state) {
        return Income_CheckInput(state);
    },

    svg: function () {
        return Income_Svg();
    },

    eventNodeMenu: function (focus) {
        return Income_EventNodeMenu(focus);
    },

    indexText: function () {
        return Income_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Income_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Income_OptionDescription();
    },

    colouredPngs: function () {
        return Income_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Income_SideModalImage();
    },

    inputsHandler: function (
        value,
        id,
        star,
        entitiesMap,
        currentEntity,
        account
    ) {
        return incomeInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            account
        );
    },

    generateDisplayFieldData: function () {
        return Income_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Income_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (entity.data.income === undefined || entity.data.income === "")
            return false;
        if (!entity.startDate) return false;
        if (!entity.name) return false;
        if (!entity.cadence) return false;
        if (entity.data.income === null || entity.data.income === undefined)
            return false;
        else return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Income_Constant = () => {
    return "80f9a89f-9103-41a0-bbe4-b5952cc488bf";
};

// EFFECTS: Returns svg for event
const Income_Svg = () => {
    return incomeBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Income_EventNodeMenu = (focus) => {
    return <IncomeCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Income_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Income calculations.";
};

// EFFECTS: Returns all icons for event ??
const Income_Icons = () => {
    let pngs = new Map([
        ["black", incomeBlack],
        ["blue", incomeBlue],
        ["white", incomeWhite],
        ["grey", incomeGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Income_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Income_InputComponentInfoPassing = (minProps) => {
    return <IncomeInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Income_OptionDescription = () => {
    const tags = ["Option"];
    const type = incomeObject.name();
    const description =
        "Income is a generic Event that can be used to recognize monies received. Income will be assigned to the top-level Income Account. For more customized calculations, consider using the Debit/Credit Event, the Contract Event or the Revenue Event.";
    // const usedForText =
    //     "The Income Node can be used to model incoming cashflows.";
    const connection = "";
    const connectionImage = IncomeConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/i/income.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        // ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Income_SideModalImage = () => {
    return sideImage;
};

const Income_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: true,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        endDate: {
            displayName: "End Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
            conditionalFieldName: "expenseType",
            conditionalFieldConditionValue: "ongoing",
        },
        cadence: {
            displayName: "Frequency",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: [
                "annually",
                "quarterly",
                "monthly",
                "semi-monthly",
                "bi-weekly",
                "weekly",
                "daily",
                "one-time",
            ],
        },
        bypassState: {
            displayName: "Bypassed",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
    };

    const entityDataFieldsMap = {
        expenseType: {
            displayName: "Type",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["ongoing", "amortized"],
        },
        value: {
            displayName: "Income",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: true,
        },
        income: {
            hide: true,
        },
        amortizationPeriod: {
            displayName: "Amortization Period",
            editable: false,
            conditionalFieldName: "expenseType",
            conditionalFieldConditionValue: "amortized",
        },
        accountName: {
            displayName: "Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
            cellRendererWaterfallTopLevelFilters: {
                "488dd61d-8697-4213-8978-cf91755365a4": true,
            },
        },
        accountIds: {
            hide: true,
        },
        contraAccountName: {
            displayName: "Contra Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        contraAccountIds: {
            hide: true,
        },
    };

    return { entityFieldsMap, entityDataFieldsMap };
};

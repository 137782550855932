import { UPSERT_CARD_TEMPLATES } from "actions/canvasCardTemplatesActions";

const initialState: any = {};

export default function cardTemplateReducer(
    state: any = initialState,
    action: { type: string; payload?: any }
): any {
    switch (action.type) {
        case UPSERT_CARD_TEMPLATES:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

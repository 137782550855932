import { useMemo } from "react";
import { Group } from "react-konva";

import { LinkedIndicator } from "./Indicators/LinkedIndicator";
import { BypassedEntityIndicator } from "./Indicators/BypassedEntityIndicator";

export const StateIndicators = () => {
    const containerStyles = useMemo(() => {
        const _containerStyles = {
            x: -67.5,
            y: -50,
        };
        return _containerStyles;
    }, []);

    return (
        <Group {...containerStyles}>
            <LinkedIndicator />
            <BypassedEntityIndicator />
        </Group>
    );
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import MortgageInsuranceCard from "../Card/MortgageInsuranceCard";
import MortgageInsuranceInput from "../InputContainer/MortgageInsuranceInput";

// Import Connection Image (if exists)
import mortgageInsuranceConnectionImage from "../../Assets/_optionDescriptionIcons/Mortgage, Renewal Refinance, Mortgage Insurance.png";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";

// Import Coloured PNGs
import mortgageInsuranceBlack from "../../Assets/_eventNodeTypes/mortgageinsurance-black.png";
import mortgageInsuranceBlue from "../../Assets/_eventNodeTypes/mortgageinsurance-blue.png";
import mortgageInsuranceWhite from "../../Assets/_eventNodeTypes/mortgageinsurance-white.png";
import mortgageInsuranceGrey from "../../Assets/_eventNodeTypes/mortgageinsurance-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - mortgageInsuranceObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - mortgageInsuranceObject.checkInput.call(mortgageInsuranceObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const mortgageInsuranceObject = {
    constant: function () {
        return MortgageInsurance_Constant();
    },

    checkInput: function (state) {
        return MortgageInsurance_CheckInput(state);
    },

    svg: function () {
        return MortgageInsurance_Svg();
    },

    eventNodeMenu: function (focus) {
        return MortgageInsurance_EventNodeMenu(focus);
    },

    indexText: function () {
        return MortgageInsurance_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return MortgageInsurance_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return MortgageInsurance_OptionDescription();
    },

    colouredPngs: function () {
        return MortgageInsurance_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return MortgageInsurance_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const MortgageInsurance_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.mortgageId) return false;
    if (
        (state.premiumPercent === null ||
            state.premiumPercent === undefined ||
            isNaN(state.premiumPercent) ||
            state.premiumPercent === "0.00" ||
            state.premiumPercent === "") &&
        !state.useAutoValue
    )
        return false;
    if (
        (state.premiumAmount === null ||
            state.premiumAmount === undefined ||
            isNaN(state.premiumAmount) ||
            state.premiumAmount === "0" ||
            state.premiumAmount === "") &&
        !state.useAutoValue
    )
        return false;
    if (!state.lastChangedPremiumProperty && !state.useAutoValue) return false;
    if (!state.start) return false;
    if (!state.province) return false;
    if (
        state.mortgagePrincipal === null ||
        state.mortgagePrincipal === undefined
    )
        return false;

    return true;
};

// EFFECTS: Returns constant for event
const MortgageInsurance_Constant = () => {
    return "Mortgage Insurance";
};

// EFFECTS: Returns black png / svg for event
const MortgageInsurance_Svg = () => {
    return mortgageInsuranceBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const MortgageInsurance_EventNodeMenu = (focus) => {
    return <MortgageInsuranceCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const MortgageInsurance_IndexText = () => {
    return "Select an input field to learn more about your mortgageInsurance calculations.";
};

// EFFECTS: Returns all icons for event ??
const MortgageInsurance_Icons = () => {
    let pngs = new Map([
        ["black", mortgageInsuranceBlack],
        ["blue", mortgageInsuranceBlue],
        ["white", mortgageInsuranceWhite],
        ["grey", mortgageInsuranceGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const MortgageInsurance_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const MortgageInsurance_InputComponentInfoPassing = (minProps) => {
    return <MortgageInsuranceInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, mortgageInsurances for event ??
const MortgageInsurance_OptionDescription = () => {
    const tags = ["Option"];
    const type = mortgageInsuranceObject.constant();
    const description =
        "In Canada, the buyer of a home may be required to pay mortgage insurance if their down payment does not pass a certain threshold; typically 20% of the purchase price of the home.";
    const usedForText =
        "The Mortgage Insurance Event (also known as CMHC) estimates the insurance premium that a home buyer pays on a home purchase.";
    const connection =
        "Add a Mortgage Insurance Event after a Mortgage Event and/or a Down Payment Event";
    const connectionImage = mortgageInsuranceConnectionImage;
    const learnMoreLink =
        "https://www.cmhc-schl.gc.ca/en/consumers/home-buying/mortgage-loan-insurance-for-consumers/what-is-mortgage-loan-insurance";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const MortgageInsurance_SideModalImage = () => {
    return sideImage;
};

// Default Imports
import React from "react";

// Import Event Card and Event Input
import BudgetCardAlt from "../Card/budgetCardAlt";
import StudentBudgetInput from "../InputContainer/StudentBudgetInput";

// Import Connection Image (if exists)
import studentBudgetConnection from "../../Assets/_optionDescriptionIcons/Student Budget, Student Loan, Graduation.png";

// Import Coloured PNGs
import budgetBlack from "../../Assets/_eventNodeTypes/budget-black.png";
import budgetBlue from "../../Assets/_eventNodeTypes/budget-blue.png";
import budgetWhite from "../../Assets/_eventNodeTypes/budget-white.png";
import budgetGrey from "../../Assets/_eventNodeTypes/budget-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

// "Interface" object for Event
export const studentBudgetObject = {
    constant: function () {
        return Budget_Constant();
    },

    checkInput: function (state) {
        return Budget_CheckInput(state);
    },

    svg: function () {
        return Budget_Svg();
    },

    eventNodeMenu: function (focus) {
        return Budget_EventNodeMenu(focus);
    },

    indexText: function () {
        return Budget_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Budget_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Budget_OptionDescription();
    },

    colouredPngs: function () {
        return Budget_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Budget_SideModalImage();
    },
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Budget_CheckInput = (state) => {
    let passed = false;
    state.budgetList.map((budget) => {
        if (budget.selected) {
            passed = true;
        }
        return budget;
    });
    if (!state.cadence) {
        return false;
    }
    if (passed && state.name) {
        return true;
    } else {
        return false;
    }
};

// EFFECTS: Returns constant for event
const Budget_Constant = () => {
    return "Student Budget";
};

// EFFECTS: Returns black png / svg for event
const Budget_Svg = () => {
    return budgetBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Budget_EventNodeMenu = (focus) => {
    return <BudgetCardAlt data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Budget_IndexText = () => {
    return "Budgeting is quintessential to any student. Small expenses here and there can soon become hundreds or thousands over your academic years. Maintaining a budget can help you navigate your academic and financial career as you manage different sources of funding and expenses.";
};

// EFFECTS: Returns all icons for event ??
const Budget_Icons = () => {
    let pngs = new Map([
        ["black", budgetBlack],
        ["blue", budgetBlue],
        ["white", budgetWhite],
        ["grey", budgetGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Budget_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Budget_InputComponentInfoPassing = (minProps) => {
    return <StudentBudgetInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, budgets for event ??
const Budget_OptionDescription = () => {
    const tags = ["Option"];
    const type = studentBudgetObject.constant();
    const description =
        "A Student Budget Event can be used to track ongoing expenses throughout the school term.";
    const usedForText =
        "This includes textbooks, transportation, groceries, etc.";
    const connection = "";
    const connectionImage = studentBudgetConnection;
    const learnMoreLink =
        "https://www.canada.ca/en/financial-consumer-agency/services/budget-student-life/student-budget-worksheet.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Budget_SideModalImage = () => {
    return sideImage;
};

import type { Dispatch } from "react";
import { useEffect, useRef, useState, createContext } from "react";
import { shallowEqual } from "react-redux";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { upsertThreadsList } from "actions/threadsListActions";
import { ThreadsControls } from "./partials/ThreadsControls";
import { ThreadsListController } from "./partials/ThreadsListController";

// Styling
import "./ScenarioView.css";
import "./partials/scenarioViewThread.css";

export const RatingContext = createContext(50);
export const SetRatingContext = createContext<Dispatch<number>>(() => {
    return;
});

export function ScenarioView() {
    const { isCalculatedThreadsLoading, preferenceRatings } = useAppSelector(
        (state) => {
            const isCalculatedThreadsLoading =
                state.loadingStates.isCalculatedThreadsLoading;

            const preferenceRatings = state.threadsList.preferenceRating;

            return {
                isCalculatedThreadsLoading,
                preferenceRatings,
            };
        },
        shallowEqual
    );

    const dispatch = useAppDispatch();

    /**
     * The useState, timeoutRef, and useEffect go hand in hand with each other.
     * The idea is we do not want to crowd the Redux with hundreds of dispatches when the user is sliding.
     * This architecture ensures that the Redux only updates when the user stops sliding the slider.
     * `preferenceRatings` is a way to "save" the rating between mounts.
     * local `rating` is to display and sort the threads as necessary.
     * We separate them because running dispatch too many times causes the user's computer to go on overdrive (too many re-renders throughout the entire app)
     *
     * Additional Notes:
     * Context is used for the local ratings so we don't need to prop drill.
     *
     */
    const [rating, setRating] = useState(preferenceRatings);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            dispatch(upsertThreadsList({ preferenceRating: rating }));
        }, 300);
    }, [dispatch, rating]);
    useEffect(() => {
        return () => {
            try {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            } catch {
                // Noop
            }
        };
    }, []);

    return (
        <SetRatingContext.Provider value={setRating}>
            <RatingContext.Provider value={rating}>
                <div className="ScenarioView">
                    <ThreadsControls />
                    {isCalculatedThreadsLoading ? (
                        <img
                            alt="alt"
                            className="loadingGif"
                            src="https://whatifi-dashboard-assets.s3-us-west-2.amazonaws.com/assets/loading.gif"
                        />
                    ) : (
                        <ThreadsListController />
                    )}
                </div>
            </RatingContext.Provider>
        </SetRatingContext.Provider>
    );
}

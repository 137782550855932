import React from "react";
import { roundNumberForDisplay } from "../../helpers";
import "./Card.css";

// contains reusable components for Event Cards

export const CardContainer = ({ children }) => {
    return <div className="Container">{children}</div>;
};

export const Row = ({ children }) => {
    return <div className="Row">{children}</div>;
};

export const Description = ({ text }) => {
    return <div className={text ? "Description" : "NoDescription"}>{text}</div>;
};

/**
 * @param  {string} text - to be displayed
 */
export const RowLabel = ({ text }) => {
    let displayText = String(text);
    if (
        displayText.length > 0 &&
        displayText.charAt(displayText.length - 1) === ":"
    ) {
        displayText = displayText.substr(0, displayText.length - 1);
    }
    return <div className="ExpenseLabel">{displayText}</div>;
};

/**
 * @param  {string} text - to be displayed
 */
export const TextValue = ({ text }) => {
    const displayText = !text ? "" : text;
    return <div className="cardNumber">{displayText}</div>;
};

/**
 * @param  {number} value - if null/undefined, will display as empty string,
 * if value < 1000, it will be displayed to two decimal points (e.g. $ XX.XX)
 */
export const DollarValue = ({ value }) => {
    let text = "";
    if (value || value === 0) {
        const temp =
            value < 1000 && value > -1000
                ? roundNumberForDisplay(value)
                : Math.round(value);
        text = `$ ${temp.toLocaleString("en")}`;
    }
    return <div className="cardNumber">{text}</div>;
};

/**
 * @param  {number} value - if null/undefined, will display as empty string
 */
export const PercentValue = ({ value }) => {
    const text = `${roundNumberForDisplay(value)} %`;

    return <div className="cardNumber">{text}</div>;
};
/**
 * @param  {string} cadence - one of standard cadence options
 */
export const CadenceValue = ({ cadence }) => {
    let text = "";
    switch (cadence) {
        case "annually":
            text = "Annually";
            break;
        case "monthly":
            text = "Monthly";
            break;
        case "semi-monthly":
            text = "Semi-Monthly";
            break;
        case "bi-weekly":
            text = "Bi-Weekly";
            break;
        case "weekly":
            text = "Weekly";
            break;
        case "daily":
            text = "Daily";
            break;
        case "one-time":
            text = "One-Time";
            break;
        default:
            text = cadence;
    }
    return <div className="cardNumber">{text}</div>;
};

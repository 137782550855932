// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import BankAltCard from "../Card/bankCardAlt";
import BankAltInput from "../InputContainer/BankAlt";

// Import Connection Image (if exists)
import BankAltConnectionImage from "../../Assets/_optionDescriptionIcons/Me, Bank, Income.png";

// Import Coloured PNGs
import bankAltBlack from "../../Assets/_eventNodeTypes/bank-black.png";
import bankAltBlue from "../../Assets/_eventNodeTypes/bank-blue.png";
import bankAltWhite from "../../Assets/_eventNodeTypes/bank-white.png";
import bankAltGrey from "../../Assets/_eventNodeTypes/bank-grey.png";
import { valueDoesNotExist } from "../../helpers";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - bankAltObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - bankAltObject.checkInput.call(bankAltObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const bankAltObject = {
    constant: function () {
        return BankAlt_Constant();
    },

    name: function () {
        return "Bank Alt";
    },

    checkInput: function (state) {
        return BankAlt_CheckInput(state);
    },

    svg: function () {
        return BankAlt_Svg();
    },

    eventNodeMenu: function (focus) {
        return BankAlt_EventNodeMenu(focus);
    },

    indexText: function () {
        return BankAlt_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return BankAlt_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return BankAlt_OptionDescription();
    },

    colouredPngs: function () {
        return BankAlt_Icons();
    },

    version: function () {
        return "2.0.0";
    },

    sideModal: function () {
        return BankAlt_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const BankAlt_CheckInput = (state) => {
    if (!state.name) return false;
    if (valueDoesNotExist(state.value)) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const BankAlt_Constant = () => {
    return "b5c4c179-020f-47c8-991f-0f10463fd06f";
};

// EFFECTS: Returns black png / svg for event
const BankAlt_Svg = () => {
    return bankAltBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const BankAlt_EventNodeMenu = (focus) => {
    return <BankAltCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const BankAlt_IndexText = () => {
    return "Use our Bank Event to manually or automatically add and update daily any balances associated with your bank account. We use industry leading, secure, 3rd party platforms and never access or store any of your banking credentials.";
};

// EFFECTS: Returns all icons for event ??
const BankAlt_Icons = () => {
    let pngs = new Map([
        ["black", bankAltBlack],
        ["blue", bankAltBlue],
        ["white", bankAltWhite],
        ["grey", bankAltGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const BankAlt_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const BankAlt_InputComponentInfoPassing = (minProps) => {
    return <BankAltInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, bankAlts for event ??
const BankAlt_OptionDescription = () => {
    const tags = ["Option"];
    const type = bankAltObject.constant();
    const description =
        "Import your various bank accounts and set up automatic updates via Flinks.";
    const usedForText =
        "Keeping track of your balances and if tracking your goals.";
    const connection =
        "It is common to place this event inside of your baseline.";
    const connectionImage = BankAltConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/b/bank.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const BankAlt_SideModalImage = () => {
    return sideImage;
};

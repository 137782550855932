import React, { Component } from "react";
import moment from "moment";
import { CustomDate } from "../../helpers/refinanceRenewalConstants";
import {
    CadenceValue,
    CardContainer,
    Description,
    PercentValue,
    Row,
    RowLabel,
    TextValue,
} from "./Components";

class RenewalRefinanceCard extends Component {
    render() {
        const metadata = this.props.data.metadata;
        const { rate, startOption, start, description, cadence, version } =
            metadata;
        const startText = start
            ? moment(start).format("MMM D YYYY")
            : "No Date";

        return (
            <CardContainer>
                <Row>
                    <Description text={description} />
                </Row>
                <Row>
                    <RowLabel text="Term" />
                    <TextValue text={metadata["mortgage term"]} />
                </Row>
                <Row>
                    <RowLabel text="Mortgage Rate" />
                    <PercentValue value={rate} />
                </Row>
                <Row>
                    <RowLabel text="Payment Frequency" />
                    <CadenceValue cadence={cadence} />
                </Row>
                <Row>
                    <RowLabel text="Amortization Period" />
                    <TextValue text={metadata["amortization period"]} />
                </Row>
                <Row>
                    <RowLabel text="Start Option" />
                    <TextValue text={startOption} />
                </Row>
                {startOption === CustomDate && (
                    <Row>
                        <RowLabel text="Start Date" />
                        <TextValue text={startText} />
                    </Row>
                )}
                <Row>
                    <RowLabel text="Version" />
                    <TextValue text={version} />
                </Row>
            </CardContainer>
        );
    }
}

export default RenewalRefinanceCard;

import * as uuid from "uuid";
import {
    ModifierSchema,
    ModifiersSchema,
} from "reducers/typesSchema/modifiersSchema";
import {
    createModifiersAPI,
    deleteModifiersAPI,
    updateModifiersAPI,
} from "actions/modifierAPIActions";
import { removeModifiers, upsertModifiers } from "actions/modifiersActions";
import store from "store";
import { getRelevantEntities } from "actions/getNodeEntityActions";
import moment from "moment";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import { cloneDeep } from "lodash";
import {
    PercentChange,
    AddToValue,
    ValueReplacer,
    CompoundGrowth,
} from "./modifierHelpers";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";

/**
 *
 * @returns {string} Date string formatted as "YYYY-MM-DD"
 */
export const getScenarioStartDate = (loadedScenario: ScenarioSchema) => {
    // Figuring out what to send as start date for the scenario
    // defaults to today's date if no preference
    if (loadedScenario?.start_date_preference === "custom")
        return loadedScenario.custom_start_date;
    else if (
        loadedScenario?.start_date_preference === "monthStart"
    )
        return moment().startOf("month").format("YYYY-MM-DD");
    else return moment().format("YYYY-MM-DD");
}

const sortSegments = (segments) => {
    if (segments.length < 2) return segments;

    const half = Math.floor(segments.length / 2);

    const left = segments.splice(0, half);

    const sortedLeft = sortSegments(left);
    const sortedRight = sortSegments(segments);

    return mergeSegments(sortedLeft, sortedRight);
};

const mergeSegments = (seg1, seg2) => {
    const mergedSegments: any = [];

    while (seg1.length && seg2.length) {
        const firstDate = moment(seg1[0].startDate);
        const secondDate = moment(seg2[0].startDate);
        if (dateIsBeforeOrEqual(firstDate, secondDate)) {
            mergedSegments.push(seg1.shift());
        } else {
            mergedSegments.push(seg2.shift());
        }
    }

    return [...mergedSegments, ...seg1, ...seg2];
};

export const dateIsBeforeOrEqual = (date1, date2) => {
    return moment(date1).isBefore(date2) || moment(date1).isSame(date2);
};

// checks if date1 should be recorded on date2 given the cadence
export const dateRoundsUpTo = (date1, date2, cadence) => {
    const isBeforeOrOn = dateIsBeforeOrEqual(date1, date2);

    if (isBeforeOrOn) {
        const date1NextCadence = moment(date1);

        switch (cadence) {
            case "annually":
                date1NextCadence.add(1, "y");
                break;
            case "quarterly":
                date1NextCadence.add(4, "M");
                break;
            case "monthly":
                date1NextCadence.add(1, "M");
                break;
            case "semi-monthly":
                date1NextCadence.add(14, "d");
                break;
            case "bi-weekly":
                date1NextCadence.add(14, "d");
                break;
            case "weekly":
                date1NextCadence.add(7, "d");
                break;
            case "daily":
                date1NextCadence.add(1, "d");
                break;
        }

        // if date1 is before/equal date2 but date1 + cadence is not before/equal date 2 then date1 should be recorded on date2
        return !dateIsBeforeOrEqual(date1NextCadence, date2);
    }

    return false;
};

const getDateStringFromCadence = (date, cadence) => {
    switch (cadence) {
        case "annually":
            return date.toISOString().replace(/-/g, "").slice(0, 4);
        case "quarterly":
        case "monthly":
            return date.toISOString().replace(/-/g, "").slice(0, 6);
        case "semi-monthly":
        case "bi-weekly":
        case "weekly":
        case "daily":
        case "one-time":
            return date.toISOString().replace(/-/g, "").slice(0, 8);
        default:
            return;
    }
};

const convertYearlyRate = (rate, cadence, date) => {
    let convertedRate = rate;

    const numWeeks = moment(date).isoWeeksInYear();

    switch (cadence) {
        case "annually":
            break;
        case "quarterly":
            convertedRate = Math.pow(1 + rate, 1 / 4) - 1;
            break;
        case "monthly":
            convertedRate = Math.pow(1 + rate, 1 / 12) - 1;
            break;
        case "semi-monthly":
            convertedRate = Math.pow(1 + rate, 1 / 24) - 1;
            break;
        case "bi-weekly":
            convertedRate = Math.pow(1 + rate, 1 / (numWeeks / 2)) - 1;
            break;
        case "weekly":
            convertedRate = Math.pow(1 + rate, 1 / numWeeks) - 1;
            break;
        case "daily":
            convertedRate = Math.pow(1 + rate, 1 / 365) - 1;
            break;
        default:
            return rate;
    }

    return Number(convertedRate);
};

// a helper for processing the RateSegment
export const getSimplifiedSegmentMap = (
    cadence,
    startDate,
    endDate,
    length,
    segments,
    interpolate,
    maxRate?,
    churnCadence? // should be either monthly or annually (unless we add more later)
) => {
    if (!segments || segments.length == 0) {
        return {};
    }

    const segmentsClone = cloneDeep(segments);
    const sortedSegments = sortSegments(segmentsClone);
    let segmentIndex = 0;

    const segmentMap = {};

    let currentSegmentValue = 0;

    let currentSegmentKeys: string[] = [];

    const start = moment(startDate);

    let end;
    if (endDate) {
        end = moment(endDate);
    } else {
        end = moment(startDate);
        end.add(length, "y");
    }

    while (dateIsBeforeOrEqual(start, end)) {
        const date = getDateStringFromCadence(start, cadence);

        let keepLastValue = true;

        // manage segments
        while (
            segmentIndex < sortedSegments.length &&
            dateRoundsUpTo(
                sortedSegments[segmentIndex].startDate,
                date,
                cadence
            )
        ) {
            // if this segment is going to be overwritten by the next one, just skip it
            // this is necessary so interpolation works
            if (
                sortedSegments.length > segmentIndex + 1 &&
                dateRoundsUpTo(
                    sortedSegments[segmentIndex + 1].startDate,
                    date,
                    cadence
                )
            ) {
                segmentIndex = segmentIndex + 1;
                continue;
            }

            const segment = sortedSegments[segmentIndex];

            const monthlyRate = segment.monthlyRate;
            const yearlyRate = segment.yearlyRate;

            // because of how floats work, using the monthly value directly is more accurate
            let segmentValue;

            if (!churnCadence) {
                segmentValue =
                    segment.period == "monthly"
                        ? Number(monthlyRate)
                        : 100 *
                          convertYearlyRate(
                              Number(yearlyRate) * 0.01,
                              cadence,
                              date
                          );
            } else {
                segmentValue =
                    churnCadence == "monthly"
                        ? Number(monthlyRate)
                        : Number(yearlyRate);
            }

            if (maxRate) {
                if (segmentValue > Number(maxRate)) {
                    segmentValue = Number(maxRate);
                }
            }

            segmentMap[date] = segmentValue;
            segmentIndex = segmentIndex + 1;
            keepLastValue = false;

            // when we reach a new segment and it's interpolate, we go back and add to the values for the previous segment
            if (interpolate && currentSegmentKeys.length > 1) {
                const toAdd =
                    (segmentValue - currentSegmentValue) /
                    currentSegmentKeys.length;
                for (let i = 0; i < currentSegmentKeys.length; i++) {
                    const key = currentSegmentKeys[i];
                    let newValue = currentSegmentValue + i * toAdd;
                    if (maxRate) {
                        if (newValue > Number(maxRate)) {
                            newValue = Number(maxRate);
                        }
                    }
                    segmentMap[key] = newValue;
                }
            }

            currentSegmentValue = segmentValue;
            currentSegmentKeys = [date];
        }

        // if current date key doesn't have a corresponding segment, repeat the previous value
        if (keepLastValue) {
            segmentMap[date] = currentSegmentValue;

            currentSegmentKeys.push(date);
        }

        switch (cadence) {
            case "annually":
                start.add(1, "y");
                break;
            case "quarterly":
                start.add(4, "M");
                break;
            case "monthly":
                start.add(1, "M");
                break;
            case "semi-monthly":
                start.add(14, "d");
                break;
            case "bi-weekly":
                start.add(14, "d");
                break;
            case "weekly":
                start.add(7, "d");
                break;
            case "daily":
                start.add(1, "d");
                break;
            default:
                break;
        }
    }

    return segmentMap;
};

export const createCadenceArray = (
    cadence,
    startDate,
    endDate,
    length,
    data
) => {
    const overrideMap = new Map();
    const start = moment(startDate);

    let end;
    if (endDate) {
        end = moment(endDate);
    } else {
        end = moment(startDate);
        end.add(length, "y");
    }
    switch (cadence) {
        case "annually":
            while (dateIsBeforeOrEqual(start, end)) {
                overrideMap[start.toISOString().replace(/-/g, "").slice(0, 4)] =
                    data;
                start.add(1, "y");
            }
            break;
        case "quarterly":
            while (dateIsBeforeOrEqual(start, end)) {
                overrideMap[start.toISOString().replace(/-/g, "").slice(0, 6)] =
                    data;
                start.add(4, "M");
            }
            break;
        case "monthly":
            while (dateIsBeforeOrEqual(start, end)) {
                overrideMap[start.toISOString().replace(/-/g, "").slice(0, 6)] =
                    data;
                start.add(1, "M");
            }
            break;
        case "semi-monthly":
            while (dateIsBeforeOrEqual(start, end)) {
                overrideMap[start.toISOString().replace(/-/g, "").slice(0, 8)] =
                    data;
                start.add(14, "d");
            }
            break;
        case "bi-weekly":
            while (dateIsBeforeOrEqual(start, end)) {
                overrideMap[start.toISOString().replace(/-/g, "").slice(0, 8)] =
                    data;
                start.add(14, "d");
            }
            break;
        case "weekly":
            while (dateIsBeforeOrEqual(start, end)) {
                overrideMap[start.toISOString().replace(/-/g, "").slice(0, 8)] =
                    data;
                start.add(7, "d");
            }
            break;
        case "daily":
            while (dateIsBeforeOrEqual(start, end)) {
                overrideMap[start.toISOString().replace(/-/g, "").slice(0, 8)] =
                    data;
                start.add(1, "d");
            }
            break;
        case "one-time":
            overrideMap[start.toISOString().replace(/-/g, "").slice(0, 8)] =
                data;
            break;
        default:
            break;
    }
    return overrideMap;
};

export const submitModifier = (
    modifiersData: { [date: string]: string },
    defaultValue: string,
    cadence: string,
    entityId: string,
    field: string
) => {
    const modifiers = store.getState().modifiers;
    const newModifier: ModifierSchema = {
        id: uuid.v4(),
        field: field,
        defaultValue: defaultValue,
        cadence: cadence,
        values: {},
        functions: {},
        associatedEntity: entityId,
    };
    Object.entries(modifiersData).forEach(([date, value]) => {
        if (value === defaultValue) {
            // do nothing
        } else {
            const modDate = date.replaceAll("-", "");
            newModifier.values[modDate] = parseFloat(value);
            newModifier.functions[modDate] = "replace";
        }
    });
    if (entityId in modifiers) {
        const [modifierExists, existingModifier, __] = modifierAlreadyExists(
            modifiers[entityId],
            entityId,
            newModifier.field
        );
        if (modifierExists) {
            newModifier.id = existingModifier.id;
            store.dispatch(upsertModifiers({ [entityId]: [newModifier] }));
            if (store.getState().scenario?.loadedScenario.type !== "shared") {
                updateModifiersAPI({ [entityId]: [newModifier] });
            }
            return newModifier;
        }
    }
    store.dispatch(upsertModifiers({ [entityId]: [newModifier] }));
    if (store.getState().scenario?.loadedScenario.type !== "shared") {
        createModifiersAPI([newModifier]);
    }
    return newModifier;
};

export const createModifiersCopy = (
    modifiers: ModifierSchema[],
    entityId: string
) => {
    if (!modifiers || modifiers.length === 0) {
        return;
    }
    const newModifiers: ModifierSchema[] = [];
    modifiers.forEach((modifier) => {
        const newModifier = {
            ...modifier,
            id: uuid.v4(),
            associatedEntity: entityId,
        };
        newModifiers.push(newModifier);
    });
    store.dispatch(upsertModifiers({ [entityId]: newModifiers }));
    return createModifiersAPI(newModifiers);
};

export const modifierAlreadyExists = (
    modifiersArray: ModifierSchema[],
    id: string,
    field: string
) => {
    let doesExist = false;
    let existingModifier;
    let index;
    modifiersArray.every((modifier, i) => {
        if (modifier.associatedEntity === id && modifier.field === field) {
            doesExist = true;
            existingModifier = modifier;
            index = i;
            return false;
        }
        return true;
    });
    return [doesExist, existingModifier, index];
};

// This function assumes that the specified entity ID already has existing modifiers associated with it.
// This function will grab the most up to date values from its parent entity, and is called in MainContainer
// before calculations are shipped off to the decision engine.
export const updateModifiers = async (
    currentModifiers: ModifiersSchema,
    entityId: string
): Promise<ModifierSchema[]> => {
    const entity = Object.values(getRelevantEntities([entityId]))[0];
    // if (entity.type === "ace1c1e0-4d70-4901-a95e-72ba41992e3a")
    //     return currentModifiers[entityId];
    const updatedModifiersArray: ModifierSchema[] = [];
    currentModifiers[entityId].forEach((modifier) => {
        const field: string = modifier.field;
        modifier.defaultValue = entity.data[field].toString();
        updatedModifiersArray.push(modifier);
    });
    store.dispatch(upsertModifiers({ [entityId]: updatedModifiersArray }));
    await updateModifiersAPI({ [entityId]: updatedModifiersArray });
    return updatedModifiersArray;
};

export const isOverrideValid = (entitiesMap, currentEntity) => {
    const entity = entitiesMap[currentEntity];
    return Boolean(
        ["daily", "monthly", "annually"].includes(entity.cadence) &&
            (entity.data.income ||
                entity.data.value ||
                entity.data.cost ||
                entity.data.income === 0 ||
                entity.data.value === 0 ||
                entity.data.cost === 0) &&
            entity.startDate
    );
};

export const getDefaultValue = (
    modifiedEntityData: EntitySchema,
    key: string
): string => {
    if (modifiedEntityData[key]) {
        return typeof modifiedEntityData[key] === "string"
            ? modifiedEntityData[key]
            : modifiedEntityData[key].toString();
    } else {
        return typeof modifiedEntityData.data[key] === "string"
            ? modifiedEntityData.data[key]
            : modifiedEntityData.data[key].toString();
    }
};

export const getOverrides = (
    entityId: string
): ModifierSchema[] | undefined => {
    if (store.getState().modifiers[entityId]) {
        return store.getState().modifiers[entityId];
    }
    return undefined;
};

export const getOverrideByField = (
    modifiersArray: ModifierSchema[],
    field: string
): [ModifierSchema | undefined, number | undefined] => {
    if (modifiersArray == null || modifiersArray.length === 0)
        return [undefined, undefined];
    const index = modifiersArray
        .map((override) => {
            return override.field;
        })
        .indexOf(field);
    if (modifiersArray[index]) return [modifiersArray[index], index];
    return [undefined, undefined];
};

export const deleteModifiers = async (
    idMaps: { entity_id: string; modifier_id: string }[]
) => {
    store.dispatch(removeModifiers(idMaps));
    const modifierIds = idMaps.map((idMap) => {
        return idMap.modifier_id;
    });
    await deleteModifiersAPI(modifierIds);
};

export const dateStringToOverrideDateString = (date, cadence) => {
    switch (cadence) {
        case "annually":
            return date.replace(/-/g, "").slice(0, 4);
        case "quarterly":
        case "monthly":
            return date.replace(/-/g, "").slice(0, 6);
        case "semi-monthly":
        case "bi-weekly":
        case "weekly":
        case "daily":
        case "one-time":
            return date.replace(/-/g, "").slice(0, 8);
        default:
            return;
    }
};

// For example, ("202204", "monthly") => "202205"
export const getNextOverrideDateString = (dateString, cadence) => {
    const date = moment(dateString);

    switch (cadence) {
        case "annually":
            date.add(1, "y");
            break;
        case "quarterly":
            date.add(4, "M");
            break;
        case "monthly":
            date.add(1, "M");
            break;
        case "semi-monthly":
            date.add(14, "d");
            break;
        case "bi-weekly":
            date.add(14, "d");
            break;
        case "weekly":
            date.add(7, "d");
            break;
        case "daily":
            date.add(1, "d");
            break;
        case "one-time":
            // one time cadence only happens once so there is no next date
            return null;
        default:
            break;
    }

    let cadenceFormat = "monthly";
    if (dateString.length == 4) {
        cadenceFormat = "annually";
    } else if (dateString.length == 8) {
        cadenceFormat = "one-time";
    }

    return getDateStringFromCadence(date, cadenceFormat);
};

// customer 2 is a special case since it returns an override instead of a modifier (since it technically has a one-time cadence)
export const getModifierForCustomer2 = (
    modFunction,
    modVal,
    modEntityOverrides
) => {
    if (!modEntityOverrides || modEntityOverrides.length == 0) null;

    const modValue = Number(modVal);

    const modEntityOverride = modEntityOverrides[0];

    // This is not allowed since compounding is for entities that don't have a one-time cadence. Compounding customers should use Customer Growth 2 or Customer Churn 2. I just added this check here since it's not worth it to add it in modifiers when the function is selected
    if (modFunction == CompoundGrowth) return null;

    // the actual function doesn't matter because it should always be 'replace'
    const overrideDateString = Object.keys(modEntityOverride.functions)[0];
    const originalOverrideValue = modEntityOverride.values[overrideDateString];

    let newOverrideValue = originalOverrideValue;

    if (modFunction == PercentChange) {
        newOverrideValue = originalOverrideValue * (1 + modValue / 100);
    } else if (modFunction == AddToValue) {
        newOverrideValue = originalOverrideValue + modValue;
    } else if (modFunction == ValueReplacer) {
        newOverrideValue = modValue;
    }

    const override = {
        cadence: modEntityOverride.cadence,
        defaultValue: modEntityOverride.defaultValue,
        field: modEntityOverride.field,
        functions: { [overrideDateString]: "replace" },
        values: {
            [overrideDateString]: Number(newOverrideValue),
        },
    };

    return override;
};

// Updates all overrides startDates to the specified startDate. Currently only targets
// overrides but can be modified to work on all modifiers as well. We use the spread operator to make
// new copies of the modifier/override, all fields we change are shallow so it works as expected.
// If we need to change more nested fields like an overrides value, then a shallow copy will not work.
export const updateOverrideStartDate = (modifiers: {action: string, startDate: string}[], startDate: string) => {
    for (const modifier of modifiers) {
        if (modifier.action === "override") {
            modifier.startDate = startDate
        }
    }
}
export const getColumnDefs = () => {
    const allColumnDefs = [
        {
            headerName: "Id",
            field: "id",
            hide: true,
        },
        {
            headerName: "Account Name",
            field: "name",
        },
        {
            headerName: "Display Label",
            field: "display",
        },
        {
            headerName: "Singular Label",
            field: "singular",
        },
        {
            headerName: "Decimal Places",
            field: "nearestDecimal",
        },
        {
            headerName: "Before Value",
            field: "beforeValue",
            cellRenderer: "checkBoxCellRenderer",
        },
        {
            headerName: "Space Between",
            field: "spacingBetween",
            cellRenderer: "checkBoxCellRenderer",
        },
        {
            headerName: "",
            field: "delete",
            minWidth: 30,
            maxWidth: 40,
            cellRenderer: "deleteButtonCellRenderer",
            pinned: "right",
            lockPinned: true,
            editable: () => false,
            filter: false,
        },
    ];

    return allColumnDefs;
};

import React from "react";

import OutboundSalesCard from "../Card/OutboundSalesCard";

import OutboundSalesConnectionImage from "Assets/_optionDescriptionIcons/Salesperson, Employee, Outbound Sales white .jpg";

import outboundSalesSvg from "../../Assets/_eventNodeTypes/outboundSalesSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import OutboundSalesInputExperimental from "Components/InputContainer/OutboundSalesInputExperimental/OutboundSalesInputExperimental";

export const outboundSalesObject = {
    constant: function () {
        return OutboundSales_Constant();
    },

    name: function () {
        return "Outbound Sales";
    },

    checkInput: function (state) {
        return OutboundSales_CheckInput(state);
    },

    svg: function () {
        return OutboundSales_Svg();
    },

    eventNodeMenu: function (focus) {
        return OutboundSales_EventNodeMenu(focus);
    },

    indexText: function () {
        return OutboundSales_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return OutboundSales_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return OutboundSales_OptionDescription();
    },

    colouredPngs: function () {
        return OutboundSales_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return OutboundSales_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return OutboundSales_GenerateDisplayFieldData();
    },
};

// Helper functions for Event

const OutboundSales_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        if (!entity.data.customerEventId) return false;
        if (!entity.data.value) return false;
        if (!entity.cadence) return false;

        if (!entity.data.leadTime) return false;
        if (!entity.data.leadTimeFrequency) return false;

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

const OutboundSales_Constant = () => {
    return "6ff00c3f-861e-4ad3-8071-500358c8e4ce";
};

const OutboundSales_Svg = () => {
    return outboundSalesSvg;
};

const OutboundSales_EventNodeMenu = (focus) => {
    return <OutboundSalesCard data={focus} />;
};

const OutboundSales_IndexText = () => {
    return "The Revenue Event takes in an upstream Customer Event and Unit Cost event, and produces cashflow based on the information.";
};

const OutboundSales_Icons = () => {
    let pngs = new Map([
        ["black", outboundSalesSvg],
        ["blue", outboundSalesSvg],
        ["white", outboundSalesSvg],
        ["grey", outboundSalesSvg],
    ]);
    return pngs;
};

const OutboundSales_InputComponentInfoPassing = (minProps) => {
    return <OutboundSalesInputExperimental {...minProps} />;
};

const OutboundSales_OptionDescription = () => {
    const tags = ["Option"];
    const type = outboundSalesObject.name();
    const description =
        "There are many ways to calculate Revenue. This Revenue Event best supports products and services with a fixed price and multiplies that by the number of Users or Customers in a given month. Other Revenue generating Events include the Contract, Income and Debit/Credit Events.";
    const usedForText =
        "Use the Revenue Event to model SaaS, E-commerce and traditional product sales. It requires an upstream Unit Cost Event and a Customer Event to calculate.";
    const connection = "";
    const connectionImage = OutboundSalesConnectionImage;
    const learnMoreLink =
        "https://www.investopedia.com/terms/r/revenue.asp#:~:text=Revenue%20is%20the%20income%20generated,subtracted%20to%20determine%20net%20income.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const OutboundSales_SideModalImage = () => {
    return sideImage;
};

const OutboundSales_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import { useState, useEffect, useMemo, useContext, useRef, MutableRefObject } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import CustomerTransferInputView from "./CustomerTransferInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import { getPresentableDependenciesOfManyTypes } from "helpers/nodeDependencyDetectionHelpers";
import { customerObject } from "Components/Registry/Customer";
import type { ChangeEvent } from "react";
import { customerTransferInputsHandler } from "../OnInputChangeHandlers/customerTransferInputsHandler";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";
import { updateEntityState } from "helpers/updateEntityState";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import moment from "moment";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function CustomerTransferInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({ ...useAppSelector((state) => state?.scenario?.loadedScenario?.dependency_map) })

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */

    const eventId = useContext(EventInputIDContext);
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );
    const defaultName = useMemo(
        () => getDefaultName(customerTransferObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(customerTransferObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIds,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, customerTransferObject, dependencyMap.current); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                setEntitiesMap(
                    customerTransferInputsHandler(
                        value,
                        "entityName",
                        0,
                        entitiesMap,
                        currentEntity,
                        eventId,
                        dependencyMap.current
                    )
                );
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const getDependancies = () => {
        const websiteVisitors = {};
        const customers = {};
        if (!propsObject.eventId && !propsObject.line) return {customers, websiteVisitors};

        getPresentableDependenciesOfManyTypes(
            websiteVisitors,
            [websiteVisitorsObject.constant()],
            propsObject, // Temporary object
            "Website Visitor",
            true
        )

        getPresentableDependenciesOfManyTypes(
            customers,
            [customerObject.constant()],
            propsObject, // Temporary object
            "Customer",
            true
        );

        if (Object.keys(customers).length === 0 && Object.keys(websiteVisitors).length === 0) {
            throwError(
                "warning",
                `No customer or website visitor nodes found upstream`
            );
        }

        return {customers, websiteVisitors}
    }

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const allDependancies = getDependancies()
            const customers = allDependancies.customers;
            const websiteVisitors = allDependancies.websiteVisitors;
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.customers = customers;
            newEntitiesMap[currentEntity].data.websiteVisitors = websiteVisitors;
            newEntitiesMap[currentEntity].name = defaultName;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, baselineManager, setEntitiesMap, defaultName]);

    const handleChangeInterpolationType = (e) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.subType = e.target.id;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeDynamicValues = (e, key, id) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.specificPercentages = data.specificPercentages.map((data) => {
            if (data.id === id) {
                return { ...data, [key]: e.target.value };
            }
            return { ...data };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDynamicDate = (key, value, dataId) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.specificPercentages = data.specificPercentages.map((data) => {
            if (data.id === dataId) {
                return { ...data, [key]: moment(value).format("YYYY") };
            }
            return { ...data };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    }

    const handleAddDynamicValue = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const newData = {
            id: data.specificPercentages.length,
            year: "",
            month: "",
            value: "",
        };
        data.specificPercentages = [
            ...data.specificPercentages.map((set) => {
                return { ...set };
            }),
            newData,
        ];
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDeleteDynamicValue = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const lastAdded = data.specificPercentages.length - 1;
        const filteredArray = data.specificPercentages.filter((data) => {
            return data.id != lastAdded;
        });
        data.specificPercentages = filteredArray.map((set) => {
            return { ...set };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "constantValue"
            | "entityName"
            | "interpolateSubType"
            | "rating"
            | "targetCustomer"
            | "sourceCustomer"
            | "cadence"
            | "maxGrowth",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = customerTransferInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = customerTransferInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    }

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && customerTransferObject.checkInput(entitiesMap);
    return (
        <CustomerTransferInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            onChangeDynamicValues={onChangeDynamicValues}
            handleAddDynamicValue={handleAddDynamicValue}
            handleDeleteDynamicValue={handleDeleteDynamicValue}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            handleDynamicDate={handleDynamicDate}
            handleChangeInterpolationType={handleChangeInterpolationType}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
        />
    );
}

import type { LedgerData } from "reducers/typesSchema/ledgersDataSchema";

export interface ChartGraphPointsData {
    date: string;
    value: number;
}

/**
 * Given a LedgerData, add up all the nodeIds' values that are inside each date of the ledger data.
 * This applies even for an entire thread, because nodeIds = ["_All"].
 * This applies even for a single node, because nodeIds = ["some-node-id"].
 */
export function buildPointsData(
    ledgerData: LedgerData,
    nodeIds: string[]
): ChartGraphPointsData[] {
    const pointsData = Object.entries(ledgerData).map(
        ([date, nodeContributionValues]) => {
            let value = 0;

            nodeIds.forEach((nodeId) => {
                const nodeContributionValue =
                    nodeContributionValues?.[nodeId] || 0;
                value += nodeContributionValue;
            });

            return { date, value };
        }
    );
    pointsData.sort((a, b) => a.date.localeCompare(b.date));

    return pointsData;
}

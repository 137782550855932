import { containerObject } from "Components/Registry/Container";
import { getEvent, getSingleEntity } from "actions/getNodeEntityActions";

/**
 * Determines if a thread is within a highlighted thread or not.
 * Both parent and child must be in the highlighted thread to return true.
 */
export function isLineInHighlightedThread(
    parentId: string,
    childId: string,
    highlightedThreadEvents: string[]
) {
    // Step 1: Build hashmap so we only need to loop once through highlightedThreadEvents, instead of twice
    const eventIdsHashmap: { [eventId: string]: number } = {};
    highlightedThreadEvents.forEach((eventId, index) => {
        eventIdsHashmap[eventId] = index;
    });

    // Step 2: Find if parentId and childId exist within highlightedThreadEvents
    let parentIndex = eventIdsHashmap[parentId] ?? -1;
    let childIndex = eventIdsHashmap[childId] ?? -1;

    // Step 3: Check if parent or child are the head or tail nodes of a container and update the related index to the index of relevant container
    const parentNode: any = getEvent(parentId);
    const childNode: any = getEvent(childId);

    if (
        !(
            typeof parentNode?.relevantContainerId === "string" &&
            typeof childNode?.relevantContainerId === "string"
        )
    ) {
        if (parentNode?.type === containerObject.constant()) {
            const containerEntity = getSingleEntity(parentNode?.entities?.[0]);
            const tailNodeId = containerEntity?.data?.tailNode;
            if (tailNodeId) {
                parentIndex = eventIdsHashmap[tailNodeId];
            }
        }

        if (childNode?.relevantContainerId) {
            const containerEvent = getEvent(childNode?.relevantContainerId);
            const containerEntity = getSingleEntity(
                containerEvent?.entities?.[0]
            );

            if (containerEntity?.data?.headNode === childNode?.id) {
                childIndex = eventIdsHashmap[childNode?.relevantContainerId];
            }
        }
    }

    // Step 4: Ask: "Are both parent and child in the thread?"
    if (childIndex <= parentIndex) return false;
    else if (childIndex > parentIndex + 1) return false;

    return true;
}

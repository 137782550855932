// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import GroupCard from "../Card/groupCard";

// Import Connection Image
import debitCreditConnectionImage from "../../Assets/_optionDescriptionIcons/Bank, Debit Credit, Debit Credit.png";

// Import Coloured PNGs
import groupDarkGrey from "../../Assets/_eventNodeTypes/groupSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import GroupInputExperimental from "Components/InputContainer/GroupInputExperimental/GroupInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const groupObject = {
    constant: function () {
        return group_Constant();
    },

    name: function () {
        return "Group";
    },

    checkInput: function (state) {
        return group_CheckInput(state);
    },

    svg: function () {
        return group_Svg();
    },

    eventNodeMenu: function (focus) {
        return group_EventNodeMenu(focus);
    },

    indexText: function () {
        return group_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return group_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return group_OptionDescription();
    },

    colouredPngs: function () {
        return group_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return group_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Group_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const group_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.eventType) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const group_Constant = () => {
    return "21d5a522-d6e0-11ec-9d64-0242ac120002";
};

// EFFECTS: Returns svg for event
const group_Svg = () => {
    return groupDarkGrey;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const group_EventNodeMenu = (focus) => {
    return <GroupCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const group_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const group_Icons = () => {
    let pngs = new Map([
        ["black", groupDarkGrey],
        ["blue", groupDarkGrey],
        ["white", groupDarkGrey],
        ["grey", groupDarkGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const group_InputComponentInfoPassing = (minProps) => {
    return <GroupInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const group_OptionDescription = () => {
    const tags = ["Option"];
    const type = groupObject.name();
    const description =
        "The Group Event is an organizational Event that can be used to group together entities from different Events.";
    const usedForText =
        "Use this Event to group entities across multiple Events. Modifications can then be performed on the group entity.";
    const connection = "";
    const connectionImage = debitCreditConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const group_SideModalImage = () => {
    return sideImage;
};

const Group_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import BankPlaidCard from "../Card/bankPlaidCard";
import BankPlaidInput from "../InputContainer/BankPlaidInput";

// Import Connection Image (if exists)
import BankPlaidConnectionImage from "../../Assets/_optionDescriptionIcons/Me, Bank, Income.png";

// Import Coloured PNGs
import bankPlaidBlack from "../../Assets/_eventNodeTypes/bank-black.png";
import bankPlaidBlue from "../../Assets/_eventNodeTypes/bank-blue.png";
import bankPlaidWhite from "../../Assets/_eventNodeTypes/bank-white.png";
import bankPlaidGrey from "../../Assets/_eventNodeTypes/bank-grey.png";
import { bankObject } from "./Bank";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - bankPlaidObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - bankPlaidObject.checkInput.call(bankPlaidObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const bankPlaidObject = {
    constant: function () {
        return BankPlaid_Constant();
    },

    checkInput: function (state) {
        return BankPlaid_CheckInput(state);
    },

    svg: function () {
        return BankPlaid_Svg();
    },

    eventNodeMenu: function (focus) {
        return BankPlaid_EventNodeMenu(focus);
    },

    indexText: function () {
        return BankPlaid_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return BankPlaid_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return BankPlaid_OptionDescription();
    },

    colouredPngs: function () {
        return BankPlaid_Icons();
    },

    // Add Front/Back end data exports
    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return BankPlaid_SideModalImage();
    },
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const BankPlaid_CheckInput = (state) => {
    return bankObject.checkInput(state);
};

// EFFECTS: Returns constant for event
const BankPlaid_Constant = () => {
    return "Bank Plaid";
};

// EFFECTS: Returns black png / svg for event
const BankPlaid_Svg = () => {
    return bankPlaidBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const BankPlaid_EventNodeMenu = (focus) => {
    return <BankPlaidCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const BankPlaid_IndexText = () => {
    return "Use our Bank Event to manually or automatically add and update daily any balances associated with your bank account. We use industry leading, secure, 3rd party platforms and never access or store any of your banking credentials.";
};

// EFFECTS: Returns all icons for event ??
const BankPlaid_Icons = () => {
    let pngs = new Map([
        ["black", bankPlaidBlack],
        ["blue", bankPlaidBlue],
        ["white", bankPlaidWhite],
        ["grey", bankPlaidGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const BankPlaid_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const BankPlaid_InputComponentInfoPassing = (minProps) => {
    return <BankPlaidInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, bankPlaids for event ??
const BankPlaid_OptionDescription = () => {
    const tags = ["Option"];
    const type = bankPlaidObject.constant();
    const description =
        "Import your various bank accounts and set up automatic updates via Flinks.";
    const usedForText =
        "Keeping track of your balances and if tracking your goals.";
    const connection =
        "It is common to place this event inside of your baseline.";
    const connectionImage = BankPlaidConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/b/bank.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const BankPlaid_SideModalImage = () => {
    return sideImage;
};

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { setShowCellRowModal } from "actions/modalActions";
import { AgGridReact } from "ag-grid-react";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { ScheduleData } from "reducers/typesSchema/cellRowModalSchemas";

export const Schedule = ({ entityData, createSchedule }: ScheduleData) => {
    const stepSize = 31;

    const firstRender = useRef(true);

    const getPrefix = useCallback(() => {
        switch (entityData?.cadence) {
            case "annually":
                return "Year";
            case "monthly":
                return "Month";
            case "weekly":
                return "Week";
            case "daily":
                return "Day";
            default:
                return "Month";
        }
    }, [entityData?.cadence]);

    const prevSchedule = useMemo<number[]>(
        () => entityData?.data?.schedule ?? [],
        [entityData?.data?.schedule]
    );

    const [dataPoints, setDataPoints] = useState<number>(
        prevSchedule?.length > stepSize ? prevSchedule.length : stepSize
    );

    const [rowData, setRowData] = useState(() => {
        const data = {};
        for (let i = 1; i < dataPoints + 1; i++) {
            data[i] = prevSchedule?.[i - 1]
                ? prevSchedule[i - 1].toString()
                : "-";
        }
        return [data];
    });

    const [columnDefs, setColumnDefs] = useState(() => {
        const columns: {}[] = [];
        const prefix = getPrefix();
        for (let i = 1; i < dataPoints + 1; i++) {
            columns.push({
                headerName: `${prefix} ${i}`,
                field: i.toString(),
                width: 100,
                cellClassRules: {
                    overriddenCellClass: (params) => {
                        return !isNaN(params?.value);
                    },
                },
            });
        }
        return columns;
    });

    useEffect(() => {
        // Don't want to double up size on first render
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setRowData(() => {
            const newData = { ...rowData[0] };
            const size = Object.keys(newData).length;
            if (size > dataPoints) {
                for (let i = dataPoints + 1; i < size + 1; i++) {
                    delete newData[i];
                }
                return [newData];
            }

            for (let i = size + 1; i < dataPoints + 1; i++) {
                newData[i] = "-";
            }
            return [newData];
        });

        setColumnDefs(() => {
            const prefix = getPrefix();
            const newColumns: {}[] = [...columnDefs];
            const size = newColumns.length;
            if (size > dataPoints) {
                newColumns.length = dataPoints;
                return newColumns;
            }

            for (let i = size + 1; i < dataPoints + 1; i++) {
                newColumns.push({
                    headerName: `${prefix} ${i}`,
                    field: i.toString(),
                    width: 100,
                    cellClassRules: {
                        overriddenCellClass: (params) => {
                            return !isNaN(params?.value);
                        },
                    },
                });
            }
            return newColumns;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPoints]);

    const [gridApi, setGridApi] = useState<any>(null);

    const defaultColDef = {
        editable: true,
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const increaseDataPoints = () => {
        // Arbitrary limit of 744 (# of days in ~2 years) data points
        if (dataPoints >= 744) return;

        setDataPoints(dataPoints + stepSize);
    };

    const decreaseDataPoints = () => {
        if (dataPoints === stepSize) return;

        setDataPoints(dataPoints - stepSize);
    };

    const clearRowData = () => {
        const data = {};
        for (let i = 1; i < dataPoints + 1; i++) {
            data[i] = "-";
        }
        gridApi.setRowData([data]);
    };

    const handleSubmitSchedule = () => {
        const finalRowData = gridApi.getRowNode("0").data;
        createSchedule(finalRowData);
        setShowCellRowModal({ show: false });
    };

    return (
        <>
            <div className="gridContainer">
                <div className="ag-theme-balham" style={{ height: 80 }}>
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                    ></AgGridReact>
                </div>
            </div>
            <div className="overrideLowerContainer">
                <div className="overrideButtonsContainer">
                    <button
                        className="overrideButton"
                        onClick={() => increaseDataPoints()}
                    >
                        {`Add ${getPrefix()}s`}
                    </button>
                    <button
                        className="overrideButton"
                        onClick={() => decreaseDataPoints()}
                    >
                        {`Remove ${getPrefix()}s`}
                    </button>
                </div>
                <div className="overrideButtonsContainer">
                    <button
                        className="overrideButton"
                        onClick={() => clearRowData()}
                    >
                        Clear Schedule
                    </button>
                    <button
                        className="overrideButton"
                        onClick={() => handleSubmitSchedule()}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </>
    );
};

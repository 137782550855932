import { useEffect } from "react";
import "./SortAccountType.css";
import expandImg from "../../../Assets/expand_square.svg";
import { connect } from "react-redux";
import { appMapDispatch, appMapState } from "store/appMapStateDispatch";
import { upsertThreadsList } from "actions/threadsListActions";

function SortAccountType({
    accountLedgerName,
    accountLedgerId,
    zoomedThreadId,
    monthlyCumulative,
    relevantLedgers,
    dispatch,
}) {
    useEffect(() => {
        if (!relevantLedgers.some((ledger) => ledger.id === accountLedgerId)) {
            dispatch(
                upsertThreadsList({
                    accountLedgerName: relevantLedgers[0]?.name ?? "",
                    accountLedgerId: relevantLedgers[0]?.id ?? "",
                })
            );
        }
    }, [accountLedgerId, dispatch, relevantLedgers]);

    const handleOnSelectChange = (e) => {
        e.preventDefault();
        const newDisplayAccountType =
            relevantLedgers.find((ledger) => ledger.name === e.target.value) ||
            relevantLedgers[0];
        dispatch(
            upsertThreadsList({
                accountLedgerName: newDisplayAccountType.name,
                accountLedgerId: newDisplayAccountType.id,
            })
        );
    };

    return (
        <div
            className={
                zoomedThreadId
                    ? "scenario-view__dropdown-container-hidden"
                    : "scenario-view__dropdown-container"
            }
        >
            <div className="scenario-view__dropdown-label">
                Sort Threads By...
            </div>
            <div className="scenario-view__display-selection-wrapper">
                <div className="DisplaySelection">
                    <div className="DisplayToggleContainer">
                        <div
                            className={
                                monthlyCumulative === "monthly"
                                    ? "DisplayToggleSelected"
                                    : "DisplayToggle"
                            }
                            onClick={() =>
                                dispatch(
                                    upsertThreadsList({
                                        monthlyCumulative: "monthly",
                                    })
                                )
                            }
                        >
                            <span>Monthly</span>
                        </div>
                        <div
                            className={
                                monthlyCumulative === "cumulative"
                                    ? "DisplayToggleSelected"
                                    : "DisplayToggle"
                            }
                            onClick={() =>
                                dispatch(
                                    upsertThreadsList({
                                        monthlyCumulative: "cumulative",
                                    })
                                )
                            }
                        >
                            <span>Cumulative</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scenario-view__dropdown-wrapper">
                <select
                    className="scenario-view__dropdown-menu"
                    value={accountLedgerName}
                    onChange={handleOnSelectChange}
                >
                    {relevantLedgers.map((ledger) => (
                        <option key={ledger.id} value={ledger.name}>
                            {ledger.name}
                        </option>
                    ))}
                </select>
                <img
                    className="scenario-view__dropdown-arrow"
                    src={expandImg}
                />
            </div>
        </div>
    );
}

const mapStateToProps = appMapState((state) => {
    const accountLedgerName = state?.threadsList?.accountLedgerName;
    const accountLedgerId = state?.threadsList?.accountLedgerId;
    const zoomedThreadId = state?.threadsList?.zoomedThreadId;
    const monthlyCumulative = state?.threadsList?.monthlyCumulative;
    const relevantLedgers = state?.allAccountLedgers?.relevantLedgers;

    return {
        accountLedgerName,
        accountLedgerId,
        zoomedThreadId,
        monthlyCumulative,
        relevantLedgers,
    };
});

const mapDispatchToProps = appMapDispatch((dispatch) => ({ dispatch }));

export default connect(mapStateToProps, mapDispatchToProps)(SortAccountType);

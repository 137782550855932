import React, { Component } from "react";
import "./InputContainer.css";
import { throwError } from "../../helpers/swalError";
import getGeolocation from "countrycitystatejson";
import _ from "lodash";

import {
    InputButtons,
    SelectDropDownCountry,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    RequiredStar,
} from "./Components";
import { businessObject } from "Components/Registry/Business";

const Countries = getGeolocation.getCountries();

class BusinessInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: businessObject.name(),
            type: businessObject.constant(),
            applyInflation: false,
            value: "",
            tag: `@${businessObject.name()}`,
            rating: 0,
            inflation: false,
            baseline: false,
            description: "",
            country: null,
            stateList: null,
            state: null,
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    onChangeInput = (e, id) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "country":
                const shortName = e.target.value;
                const state = getGeolocation.getStatesByShort(shortName);
                this.setState({
                    stateList: state,
                    state: null,
                    country: shortName,
                });
                break;
            case "state":
                this.setState({ state: e.target.value });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const { baseline, confirmBaselineAction } = this.props;
        if (businessObject.checkInput(this.state)) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    updateValues = () => {
        const passedCheck = businessObject.checkInput(this.state);
        this.props.fillPartialValues(this.state, {}, passedCheck);
    };

    toggleInflation = () => {
        this.setState({ applyInflation: !this.state.applyInflation });
    };

    onHandleDate = (value, type) => {
        if (type === "start") {
            this.setState({ birthday: value });
        }
    };

    render() {
        const passedCheck = businessObject.checkInput(this.state);
        const { edit } = this.props;
        const { name } = this.state;
        console.log("STATE", this.state);
        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        eventType={businessObject.constant()}
                        name={name}
                        onChangeInput={this.onChangeInput}
                        image={businessObject.svg()}
                    />
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDownCountry
                            onChangeInput={this.onChangeInput}
                            value={this.state.country ? this.state.country : ""}
                            options={Countries}
                            className="termsContainer"
                            id="country"
                            label="Select Country"
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDownCountry
                            onChangeInput={this.onChangeInput}
                            value={this.state.state}
                            options={this.state.stateList}
                            className="termsContainer"
                            id="state"
                            label="Select State"
                        />
                    </ModalRow>
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={this.updateValues}
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default BusinessInput;

import Konva from "konva";
import type {
    AnimationConfig,
    EventNodeDetailDescription,
    EventNodeDetailStyles,
} from "reducers/typesSchema/eventsTemplatesSchema";
import { useContext, useEffect, useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { Group } from "react-konva";
import { animated, useSpring } from "@react-spring/konva";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { EventNodeIDContext } from "../contexts/EventNodeIDContext";
import {
    EventNodeActiveContext,
    EventNodeBypassedContext,
    EventNodeErrorContext,
    EventNodeFocusedContext,
    EventNodeLockedContext,
} from "../contexts/EventNodeStateContexts";
import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { useEventTemplate } from "../hooks/useEventTemplate";
import { CanvasBaselineContext } from "../../../../contexts/CanvasBaselineContext";
import { getManager } from "helpers/getManager";

interface EventNodeDetailImageProps {
    eventNodeDetailDescription: EventNodeDetailDescription;
}

/**
 */
export function EventNodeDetailImage({
    eventNodeDetailDescription,
}: EventNodeDetailImageProps) {
    const _isBaseline = useContext(CanvasBaselineContext);

    const eventId = useContext(EventNodeIDContext);
    const active = useContext(EventNodeActiveContext);
    const focused = useContext(EventNodeFocusedContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const error = useContext(EventNodeErrorContext);
    const zoomCategory = useContext(CanvasStageZoomCategoryContext);

    const { eventTemplateObject } = useEventTemplate(eventId);
    const eventObject = useAppSelector(
        (state) => getManager(_isBaseline, state)?.nodes?.[eventId] ?? {},
        shallowEqual
    );

    /**
     * The final URL to the image to be displayed.
     *
     * Get this value by using `eventNodeDetail.valuePath` and the `event` object combined.
     *
     * For example, if `valuePath == ["foo", "bar"]`, then this value will return: `event.foo.bar` as the string value.
     * If `event.foo.bar` is not found, then `""` is returned, ie. no image.
     */
    const imageUrl = useMemo(() => {
        let _imageUrl: any = eventObject;
        if (eventNodeDetailDescription?.valueDerivesFrom === "template")
            _imageUrl = eventTemplateObject;
        eventNodeDetailDescription?.valuePath.forEach((fieldName) => {
            _imageUrl = _imageUrl?.[fieldName] ?? {};
        });

        if (typeof _imageUrl === "object" && !Object.keys(_imageUrl).length) {
            _imageUrl = "";
        }

        let _finalImageUrl = "";
        if (typeof _imageUrl !== "string") {
            _imageUrl = String(_imageUrl);
        }
        _finalImageUrl = _imageUrl as string;

        return _finalImageUrl;
    }, [
        eventNodeDetailDescription?.valueDerivesFrom,
        eventNodeDetailDescription?.valuePath,
        eventObject,
        eventTemplateObject,
    ]);

    /**
     * The image element loaded onto the Konva image.
     *
     * See:
     *   - https://konvajs.org/api/Konva.Image.html
     *   - https://konvajs.org/docs/shapes/Image.html
     */
    const [myImage, setMyImage] = useState<HTMLImageElement | null>(null);
    useEffect(() => {
        if (imageUrl) {
            const imageElement = new Image();
            imageElement.src = imageUrl;
            imageElement.addEventListener("load", (_e) => {
                setMyImage(imageElement);
            });
            return () => {
                imageElement.removeEventListener("load", (_e) => {
                    // noop
                });
            };
        }
    }, [imageUrl]);

    /**
     * The styling of the image component.
     */
    const imageConfig = useMemo(() => {
        const _styles =
            eventTemplateObject?.canvasStyles?.eventNodeDetails?.[
                eventNodeDetailDescription?.id
            ]?.styles;
        const _default = _styles?.default;
        const _active = active ? _styles?.active : {};
        const _focused = focused ? _styles?.focused : {};
        const _locked = locked ? _styles?.locked : {};
        const _bypassed = bypassed ? _styles?.bypassed : {};
        const _error = error ? _styles?.error : {};
        const _zoomCategory = _styles?.[zoomCategory];

        const _finalStyles: Partial<EventNodeDetailStyles> = {
            ..._default,
            ..._active,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };

        return _finalStyles;
    }, [
        active,
        bypassed,
        error,
        eventNodeDetailDescription?.id,
        eventTemplateObject?.canvasStyles?.eventNodeDetails,
        focused,
        locked,
        zoomCategory,
    ]);

    /**
     * The animation of the image component.
     */
    const animationConfig = useMemo(() => {
        const _animationOverrides =
            eventTemplateObject?.canvasStyles?.eventNodeDetails?.[
                eventNodeDetailDescription?.id
            ]?.animationOverride;
        const _default = _animationOverrides?.default;
        const _active = active ? _animationOverrides?.active : {};
        const _focused = focused ? _animationOverrides?.focused : {};
        const _locked = locked ? _animationOverrides?.locked : {};
        const _bypassed = bypassed ? _animationOverrides?.bypassed : {};
        const _error = error ? _animationOverrides?.error : {};
        const _zoomCategory = _animationOverrides?.[zoomCategory];

        const _animationConfig: Partial<AnimationConfig> = {
            ..._default,
            ..._active,
            ..._focused,
            ..._locked,
            ..._bypassed,
            ..._error,
            ..._zoomCategory,
        };

        return _animationConfig;
    }, [
        active,
        bypassed,
        error,
        eventNodeDetailDescription?.id,
        eventTemplateObject?.canvasStyles?.eventNodeDetails,
        focused,
        locked,
        zoomCategory,
    ]);

    const imageStyling = useSpring<Omit<Konva.Image, "image">>({
        to: imageConfig,
        config: animationConfig,
    });

    return (
        <Group>
            {/* @ts-ignore - seems to be a react-spring typing error*/}
            <animated.Image {...imageStyling} image={myImage} />
        </Group>
    );
}

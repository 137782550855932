import "./Card.css";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";
import {useMemo} from "react"

const CustomerChurnCard = ({focus}) => {

    const churnEntity = Object.values(getRelevantEntities(focus?.entities))?.[0]

    const dependantEvent = useMemo(() => getEvent(churnEntity?.data?.customerEventId), [churnEntity?.data?.customerEventId])

    const dependantEntity = useMemo(() => {
        const dependantEntityIds = churnEntity?.data?.customerIds;
        let dependantEntity = {};
        if (dependantEntityIds?.length > 0) {
            dependantEntity =
                getRelevantEntities(dependantEntityIds)[
                    dependantEntityIds?.[0]
                ];
        }
        return dependantEntity
    }, [churnEntity?.data?.customerIds])

    const dependantEventTotalCustomers = useMemo(() => {
        let totalCustomers = 0;
        if (dependantEvent?.entities?.length === 0) return totalCustomers
        const allDependantEventEntities = Object.values(
            getRelevantEntities(dependantEvent?.entities)
        );
        allDependantEventEntities.forEach((customerEntity) => {
            const numberOfCustomers = parseFloat(customerEntity?.data?.customer);
            totalCustomers = totalCustomers + numberOfCustomers;
        });
        return totalCustomers
    }, [dependantEvent])

    const { subType, interpolateSubType, maxChurn, constantValue, specificPercentages } = churnEntity?.data
    return (
        <div className="Container">
            <div className="Row">
                <div className="ExpenseLabel">Selected Customer:</div>
                <div className="cardValue">{`${dependantEvent.name} - ${
                    dependantEntity.name ?? "All"
                }`}</div>
            </div>
            <div className="Row">
                <div className="ExpenseLabel">Initial Customers:</div>
                <div className="cardValue">{`${
                    churnEntity?.customerIds?.length > 0
                        ? `${dependantEntity?.data?.customer}`
                        : `${dependantEventTotalCustomers}`
                }`}</div>
            </div>
            <div className="Row">
                <div className="ExpenseLabel">Churn Type:</div>
                <div className="cardValue">{subType}</div>
            </div>
            {subType === "dynamic" && (
                <>
                    <div className="Row">
                        <div className="ExpenseLabel">
                            Dynamic Churn Type:
                        </div>
                        <div className="cardValue">
                            {interpolateSubType}
                        </div>
                    </div>
                    {maxChurn && (
                        <div className="Row">
                            <div className="ExpenseLabel">
                                Max Churn Rate:
                            </div>
                            <div className="cardValue">{maxChurn}%</div>
                        </div>
                    )}
                </>
            )}
            {subType === "constant" && (
                <div className="Row">
                    <div className="ExpenseLabel">Constant Churn Rate:</div>
                    <div className="cardValue">{constantValue}%</div>
                </div>
            )}
            {subType === "dynamic" && specificPercentages?.length > 0 && specificPercentages.map((specificValue) => (
                    <div className="Row" key={specificValue?.id}>
                        <div className="ExpenseLabel">{`${specificValue?.month}, ${specificValue?.year}:`}</div>
                        <div className="cardValue">
                            {`${
                                specificValue.value
                                    ? new Intl.NumberFormat().format(
                                        parseFloat(specificValue.value).toFixed(2)
                                    )
                                    : ""
                            }%`}
                        </div>
                    </div>
                ))}
        </div>
    );
}

export default CustomerChurnCard;

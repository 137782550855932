export const missingInputs = (state, required) => {
    let missing = false,
        missingInputs = [];
    required.forEach((requiredInput) => {
        if (state[requiredInput.label] == null) {
            missing = true;
            missingInputs.push(requiredInput.label);
        }
    });
    return { isMissing: missing, details: missingInputs };
};

export const clientCheck = (state) => {
    if (!state.name.trim()) return false;
    if (!state.firstName.trim()) return false;
    if (!state.lastName.trim()) return false;
    if (!state.country) return false;
    if (!state.state) return false;
    if (state.clientType === "individual") {
        if (!state.birthMonth) return false;
        if (!state.birthYear) return false;
    } else if (state.clientType === "business") {
        if (!state.businessName) return false;
    }
    if (!state.email.trim()) return false;
    else return true;
};

export const mortgageCheck = (state) => {
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (!state["amortization period"]) return false;
    if (!state["first payment"]) return false;
    if (!state.rate) return false;
    if (!state.payments) return false;
    if (state.price === undefined || state.price === null) return false;
    if (
        state.downpayment === undefined ||
        state.downpayment === null ||
        isNaN(state.downpayment)
    )
        return false;
    if (!state["mortgage term"]) return false;
    if (!state.downpaymentRate) return false;
    else return true;
};

export const signupCheck = (state) => {
    if (!state.name) return false;
    if (!state.email) return false;
    if (!state.password) return false;
    if (!state.termsAccepted) return false;
    else return true;
};

export const loginCheck = (state) => {
    if (!state.email) return false;
    if (!state.password) return false;
    else return true;
};

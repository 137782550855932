import React, { Component } from "react";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";
import customizeSvg from "../../Assets/_budgetIcons/customize.svg";

class HomeMaintenanceCard extends Component {
    state = {
        showOptions: false,
        budgetSelected: 0,
        toggleAccount: [],
        showSettings: false,
    };
    toggleAccount = (e) => {
        const id = parseInt(e.target.id);
        const { toggleAccount } = this.state;

        if (toggleAccount.includes(id)) {
            const removeId = toggleAccount.filter((item) => {
                return item !== id;
            });
            this.setState({ toggleAccount: removeId });
        } else {
            this.setState({ toggleAccount: [...toggleAccount, id] });
        }
    };
    render() {
        const { value, metadata } = this.props.data;
        const { toggleAccount } = this.state;
        const { version } = this.props.data.metadata;

        const selected = metadata.budgetList.filter((category) => {
            return category.selected;
        });
        return (
            <div className="Container">
                <div className="Row">
                    <div className="DollarSvg">$</div>
                    <div className="Amount">
                        {value ? parseFloat(value).toFixed(2) : "0"}
                    </div>
                    <div className="Frequency">monthly</div>
                    <div className="Row">
                        <div className="ExpenseLabel">Version:</div>
                        <div className="cardValue">
                            {version ? version : ""}
                        </div>
                    </div>
                </div>
                <div className="selectedAccountsContainer">
                    {selected &&
                        selected.map((budget, i) => {
                            const isToggled = toggleAccount.includes(i);

                            return (
                                <div key={i}>
                                    <div key={i} className="selectedAccounts">
                                        <div className="nicknameContainer">
                                            <img
                                                alt="alt"
                                                src={
                                                    isToggled
                                                        ? dropdownSvg
                                                        : expandSvg
                                                }
                                                onClick={this.toggleAccount}
                                                id={i}
                                                className="selectedIcon"
                                            />
                                            <img
                                                alt="alt"
                                                className="cardIcon"
                                                src={customizeSvg}
                                            />
                                            <div className="nickname">
                                                {budget.title}
                                            </div>
                                            <div>
                                                ${budget.budget}{" "}
                                                {budget.cadence}
                                            </div>
                                        </div>
                                    </div>
                                    {isToggled && (
                                        <div className="popUpContainer">
                                            <div className="Row">
                                                <div className="key">
                                                    Inflation
                                                </div>
                                                <div className="value">
                                                    {`+${
                                                        budget.inflation
                                                            ? budget.inflationRate
                                                            : 0
                                                    }%`}
                                                </div>
                                            </div>

                                            {budget.subCategoriesList.length >
                                                0 && (
                                                <div>
                                                    <div className="Row">
                                                        <div className="line" />
                                                    </div>
                                                    {budget.subCategoriesList.map(
                                                        (subCategory) => {
                                                            return (
                                                                <div
                                                                    className="Row"
                                                                    key={
                                                                        subCategory
                                                                    }
                                                                >
                                                                    <div className="key">
                                                                        {
                                                                            subCategory.title
                                                                        }
                                                                    </div>
                                                                    <div className="value">
                                                                        $
                                                                        {
                                                                            subCategory.budget
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

export default HomeMaintenanceCard;

import { isEqual, isObject, transform } from "lodash";

/**
 * Finds the difference between two objects.
 *
 * Source: https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
export function deepDiff(
    object: Record<string, unknown> | Array<any>,
    base: Record<string, unknown> | Array<any>
) {
    function changes(
        object: Record<string, unknown> | Array<any>,
        base: Record<string, unknown> | Array<any>
    ): Record<string, unknown> | Array<any> {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                // @ts-ignore TODO: Fix typescript error
                result[key] =
                    isObject(value) && isObject(base[key])
                        ? // @ts-ignore TODO: Fix typescript error
                          changes(value, base[key])
                        : value;
            }
        });
    }
    return changes(object, base);
}

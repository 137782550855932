import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import WebsiteVisitorsInputView from "./WebsiteVisitorsInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import type { ChangeEvent } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { updateEntityState } from "helpers/updateEntityState";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import {
    EntityCustomAccount,
    getAccountsToDelete,
    getPaths,
    handleCustomAccountStructures,
} from "helpers/accounts";
import * as uuid from "uuid";
import { websiteVisitorsInputsHandler } from "../OnInputChangeHandlers/websiteVisitorsInputsHandler";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

//TODO - Create interface for _eventData
export default function WebsiteVisitorsInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(websiteVisitorsObject);
            _eventData.name = getDefaultName(
                websiteVisitorsObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, websiteVisitorsObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { manager, line, focus };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "initialVisits",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = websiteVisitorsInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = websiteVisitorsInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmitValuesCustom = (newEntitiesMap, passedCheck) => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const onHandleClickSubmitOrUpdate = () => {
        const accountsToDelete = getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            passedCheck,
            entitiesMap,
            createWebsiteVisitorAccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && websiteVisitorsObject.checkInput(entitiesMap);

    return (
        <WebsiteVisitorsInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleClickSubmitOrUpdate={onHandleClickSubmitOrUpdate}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
        />
    );
}

export const createWebsiteVisitorAccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const parentAccountId = uuid.v4();
    const organicAccountId = uuid.v4();
    const paidAccountId = uuid.v4();

    const accountStructures: EntityCustomAccount[] = [
        {
            Name: entity.name,
            Type: "display",
            Display: "Website Visitor",
            Root: {
                topLevel: "db178274-8d95-4a6a-ae5c-46ae2fc356dd",
                topLevelChildren: [parentAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Website Visitor - ", parentAccountId],
                    topLevelChildren: [
                        ["Organic ", organicAccountId],
                        ["Paid ", paidAccountId],
                    ],
                },
            ],
        },
    ];

    data.accountStructure = accountStructures;
    data.accountIdsPaths = getPaths(accountStructures);

    // Record accountIds in state (Maybe we can retrieve them from above struct?)
    data.parentAccountId = parentAccountId;
    data.organicAccountId = organicAccountId;
    data.paidAccountId = paidAccountId;

    data.accountIds = [
        "db178274-8d95-4a6a-ae5c-46ae2fc356dd",
        "8a43fb3e-62f5-47d7-917f-3a042354cb11",
        "7eb29f4d-d0ec-402e-a743-19797ebbd22b",
        parentAccountId,
        organicAccountId,
        paidAccountId,
    ];

    currentEntityObject.data = data;

    return currentEntityObject;
};

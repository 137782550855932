import React, { Component } from "react";
import styles from "./index.module.css";
import { EventsModal } from "../EventsModal";
import whatifiLogo from "../../Assets/_onboarding/whatifiLogo.svg";
import {
    SelectDropDownCountry,
    AgencyOnboardingTextField,
    SelectDropDown,
    Description,
    AgencyGoal,
} from "../InputContainer/Components";
import getGeolocation from "countrycitystatejson";
import checkMark from "../../Assets/_onboarding/checkMark.svg";
import { DEFAULT } from "../../actions/types";
import { throwError } from "../../helpers/swalError";

const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

class OnboardingContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [
                "Childcare",
                "Education",
                "Career",
                "Health",
                "Career Change",
                "Retirement",
                "Family",
                "Debt Management",
                "Real Estate",
                "Investing",
                "Relocation",
                "Travel",
                "Transportation",
                "Relationship Change",
            ],
            selectedTags: [],
            status: null,
            age: null,
            whatifiInput: null,
            steps: [
                { id: 0, step: "01", title: "Location", active: true },
                { id: 1, step: "02", title: "Basic Info", active: false },
                { id: 2, step: "03", title: "Choose Topics", active: false },
                {
                    id: 3,
                    step: "04",
                    title: "Your Biggest whatifi",
                    active: false,
                },
            ],
            step: 0,
            country: "",
            stateList: null,
            state: "",
            countries: null,
            birthMonth: "",
            birthYear: "",
            hasDisability: false,
            agencyName: null,
            businessName: null,
            contactName: null,
            teamSize: "",
            website: null,
            numberOfClients: "",
            revenueModal: "",
            serviceType: null,
            industry: "",
            agencyGoal: null,
        };
    }

    onHandleTag = (tags) => {
        const { selectedTags } = this.state;
        if (selectedTags.includes(tags)) {
            const filteredTags = selectedTags.filter((tag) => {
                return tag !== tags;
            });
            this.setState({ selectedTags: filteredTags });
        } else {
            this.setState({ selectedTags: [...selectedTags, tags] });
        }
    };

    handleInput = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        if (value === "Status" || value === "Age Range") {
            switch (id) {
                case "status":
                    this.setState({ status: null });
                    break;
                case "age":
                    this.setState({ age: null });
                    break;
                default:
            }
        } else {
            switch (id) {
                case "status":
                    this.setState({ status: value });
                    break;
                case "age":
                    this.setState({ age: value });
                    break;
                case "whatifiInput":
                    this.setState({ whatifiInput: value });
                    break;
                default:
            }
        }
    };

    onHandleOnboarding = () => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        // console.log(
        //     this.props.accountData.userRole,
        //     "<===== this.props.accountData.userRole"
        // );
        // console.log(loggedInUser, "<===== loggedInUser");
        let data = {};
        const {
            selectedTags,
            birthMonth,
            birthYear,
            hasDisability,
            whatifiInput,
            country,
            state,
            agencyName,
            businessName,
            contactName,
            teamSize,
            website,
            numberOfClients,
            revenueModal,
            serviceType,
            industry,
        } = this.state;

        if (this.props.accountData.userRole === "business") {
            data = {
                businessName,
                contactName,
                teamSize,
                website,
                revenueModal,
                industry,
                whatifiInput,
                state,
                country,
                planId: "free-plan",
                subscriptionId: loggedInUser.account,
                newUser: true,
                birthMonth: "January",
                birthYear: 1970,
            };
        } else if (this.props.accountData.userRole === "agency") {
            data = {
                agencyName,
                contactName,
                teamSize,
                website,
                numberOfClients,
                serviceType,
                whatifiInput,
                state,
                country,
                planId: "free-plan",
                subscriptionId: loggedInUser.account,
                newUser: true,
                birthMonth: "January",
                birthYear: 1970,
            };
        } else if (this.props.accountData.userRole === "student") {
            data = {
                selectedTags,
                birthMonth,
                birthYear,
                hasDisability,
                whatifiInput,
                state,
                country,
                planId: "student_02",
                subscriptionId: loggedInUser.account,
                newUser: true,
            };
        } else {
            data = {
                selectedTags,
                birthMonth,
                birthYear,
                hasDisability,
                whatifiInput,
                state,
                country,
                planId: "free-plan",
                subscriptionId: loggedInUser.account,
                newUser: true,
            };
        }

        this.props.saveUserDetails(loggedInUser, data).then(() => {
            this.props.getUserData(loggedInUser);
        });
        if (this.props.accountData.userRole === "student") {
            this.props.createStudentPlan(loggedInUser);
        } else {
            // Question: Why do we create a free plan here, but also create one in App.js???
            this.props.createFreePlan(loggedInUser);
        }

        // this.props.history.push("/billing");
    };

    onChangeInput = (e, id) => {
        switch (id) {
            case "country":
                const shortName = e.target.value;
                const state = getGeolocation.getStatesByShort(shortName);
                this.setState({
                    stateList: state,
                    state: null,
                    country: shortName,
                });
                break;
            case "state":
                this.setState({ state: e.target.value });
                break;
            case "birthMonth":
                this.setState({ birthMonth: e.target.value });
                break;
            case "birthYear":
                this.setState({ birthYear: e.target.value });
                break;
            case "description":
                this.setState({ whatifiInput: e.target.value });
                break;
            case "agencyName":
                this.setState({ agencyName: e.target.value });
                break;
            case "businessName":
                this.setState({ businessName: e.target.value });
                break;
            case "contactName":
                this.setState({ contactName: e.target.value });
                break;
            case "teamSize":
                this.setState({ teamSize: e.target.value });
                break;
            case "website":
                this.setState({ website: e.target.value });
                break;
            case "numberOfClients":
                this.setState({ numberOfClients: e.target.value });
                break;
            case "revenueModal":
                this.setState({ revenueModal: e.target.value });
                break;
            case "serviceType":
                this.setState({ serviceType: e.target.value });
                break;
            case "industry":
                this.setState({ industry: e.target.value });
                break;
            case "agencyGoal":
                this.setState({ agencyGoal: e.target.value });
                break;
            default:
        }
    };

    componentDidMount() {
        const countries = getGeolocation.getCountries();
        this.setState({ countries });
        if (this.props.accountData.userRole === "business") {
            this.setState({
                steps: [
                    { id: 0, step: "01", title: "Location", active: true },
                    { id: 1, step: "02", title: "Company Info", active: false },
                    {
                        id: 2,
                        step: "03",
                        title: "Business Model",
                        active: false,
                    },
                    {
                        id: 3,
                        step: "04",
                        title: "Your whatifi Goal",
                        active: false,
                    },
                ],
            });
        } else if (this.props.accountData.userRole === "agency")
            this.setState({
                steps: [
                    { id: 0, step: "01", title: "Location", active: true },
                    { id: 1, step: "02", title: "Company Info", active: false },
                    { id: 2, step: "03", title: "Client Info", active: false },
                    {
                        id: 3,
                        step: "04",
                        title: "Your whatifi Goal",
                        active: false,
                    },
                ],
            });
    }

    onHandleNext = () => {
        const { step } = this.state;
        if (step === 3) {
            this.onHandleOnboarding();
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    };

    onHandleBack = () => {
        this.setState({ step: this.state.step - 1 });
    };

    toggleDisability = () => {
        this.setState({ hasDisability: !this.state.hasDisability });
    };

    hasRequiredData = () => {
        const { country, state, birthMonth, birthYear } = this.state;
        return country && state && birthMonth && birthYear;
    };

    onHandleClose = () => {
        if (!this.hasRequiredData()) {
            throwError(
                "warning",
                "Missing Required Information",
                "Please complete the information form."
            );
        } else {
            this.onHandleOnboarding();
            this.props.setOnboardingState(DEFAULT);
        }
    };

    render() {
        const {
            selectedTags,
            steps,
            step,
            state,
            country,
            birthMonth,
            birthYear,
            agencyName,
            businessName,
            contactName,
            teamSize,
            website,
            numberOfClients,
            revenueModal,
            serviceType,
            industry,
            agencyGoal,
        } = this.state;

        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        let maxOffset = 100;
        let thisYear = new Date().getFullYear();
        let allYears = [];
        for (let x = 0; x <= maxOffset; x++) {
            allYears.push(thisYear - x);
        }

        let loadComponent;
        let isPassed = false;
        switch (step) {
            case 0:
                if (this.props.accountData.userRole === "business") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                Hi{" "}
                                <span>{loggedInUser && loggedInUser.name}</span>
                                ! <br /> Welcome to whatifi. Where are you
                                located?
                            </div>
                            <div className={styles.plainText}>
                                We use this information to update calculations
                                and settings based on your local country.
                            </div>
                            <div className={styles.dropdownContainer}>
                                <SelectDropDownCountry
                                    onChangeInput={this.onChangeInput}
                                    value={this.state.country}
                                    options={this.state.countries}
                                    className={styles.country}
                                    id="country"
                                    label="Select Country"
                                />
                                <SelectDropDownCountry
                                    onChangeInput={this.onChangeInput}
                                    value={this.state.state}
                                    options={this.state.stateList}
                                    className={styles.country}
                                    id="state"
                                    label="Select Province/State"
                                />
                            </div>
                        </div>
                    );
                    isPassed = country && state;
                } else if (this.props.accountData.userRole === "agency") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                Hi{" "}
                                <span>{loggedInUser && loggedInUser.name}</span>
                                ! <br /> Welcome to whatifi! Where are you
                                located?
                            </div>
                            <div className={styles.plainText}>
                                We use this information to update calculations
                                and settings based on your local country.
                            </div>
                            <div className={styles.dropdownContainer}>
                                <SelectDropDownCountry
                                    onChangeInput={this.onChangeInput}
                                    value={this.state.country}
                                    options={this.state.countries}
                                    className={styles.country}
                                    id="country"
                                    label="Select Country"
                                />
                                <SelectDropDownCountry
                                    onChangeInput={this.onChangeInput}
                                    value={this.state.state}
                                    options={this.state.stateList}
                                    className={styles.country}
                                    id="state"
                                    label="Select Province/State"
                                />
                            </div>
                        </div>
                    );
                    isPassed = country && state;
                } else {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                Hi{" "}
                                <span>{loggedInUser && loggedInUser.name}</span>
                                ! <br /> Welcome to whatifi! Where do you live?
                            </div>
                            <div className={styles.plainText}>
                                We&apos;ll optimize our formulas for more
                                accurate calculations based on your location!
                            </div>
                            <div className={styles.dropdownContainer}>
                                <SelectDropDownCountry
                                    onChangeInput={this.onChangeInput}
                                    value={this.state.country}
                                    options={this.state.countries}
                                    className={styles.country}
                                    id="country"
                                    label="Select Country"
                                />
                                <SelectDropDownCountry
                                    onChangeInput={this.onChangeInput}
                                    value={this.state.state}
                                    options={this.state.stateList}
                                    className={styles.country}
                                    id="state"
                                    label="Select Province"
                                />
                            </div>
                        </div>
                    );
                    isPassed = country && state;
                }
                break;
            case 1:
                if (this.props.accountData.userRole === "business") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                A little bit more about your company before we
                                get started
                            </div>
                            <div className={styles.plainText}>
                                We&apos;d love to learn more about your company
                                and your needs.
                            </div>
                            <div className={styles.agencyDropdownContainer}>
                                <AgencyOnboardingTextField
                                    onChangeInput={this.onChangeInput}
                                    value={businessName}
                                    className={styles.agencyInput}
                                    id="businessName"
                                    label="Business Name"
                                />
                                <AgencyOnboardingTextField
                                    onChangeInput={this.onChangeInput}
                                    value={contactName}
                                    className={styles.agencyInput}
                                    id="contactName"
                                    label="Contact Name (e.g. Jon)"
                                />
                            </div>
                            <div className={styles.agencyDropdownContainer}>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={teamSize}
                                    options={[
                                        "0-5",
                                        "6-10",
                                        "11-50",
                                        "51-100",
                                        "100+",
                                    ]}
                                    className={styles.country}
                                    id="teamSize"
                                    label="Team Size"
                                />
                                <AgencyOnboardingTextField
                                    onChangeInput={this.onChangeInput}
                                    value={website}
                                    className={styles.agencyInput}
                                    id="website"
                                    label="Website (Optional)"
                                />
                            </div>
                        </div>
                    );
                    isPassed = businessName && contactName && teamSize;
                } else if (this.props.accountData.userRole === "agency") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                A little bit more about your company before we
                                get started
                            </div>
                            <div className={styles.plainText}>
                                We&apos;d love to learn more about your company
                                and your needs.
                            </div>
                            <div className={styles.agencyDropdownContainer}>
                                <AgencyOnboardingTextField
                                    onChangeInput={this.onChangeInput}
                                    value={agencyName}
                                    className={styles.agencyInput}
                                    id="agencyName"
                                    label="Agency Name"
                                />
                                <AgencyOnboardingTextField
                                    onChangeInput={this.onChangeInput}
                                    value={contactName}
                                    className={styles.agencyInput}
                                    id="contactName"
                                    label="Contact Name (e.g. Jon)"
                                />
                            </div>
                            <div className={styles.agencyDropdownContainer}>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={teamSize}
                                    options={[
                                        "0-10",
                                        "11-50",
                                        "51-100",
                                        "101-500",
                                        "500+",
                                    ]}
                                    className={styles.country}
                                    id="teamSize"
                                    label="Team Size"
                                />
                                <AgencyOnboardingTextField
                                    onChangeInput={this.onChangeInput}
                                    value={website}
                                    className={styles.agencyInput}
                                    id="website"
                                    label="Website (Optional)"
                                />
                            </div>
                        </div>
                    );
                    isPassed = agencyName && contactName && teamSize;
                } else {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                A little bit more about you before we get
                                started
                            </div>
                            <div className={styles.plainText}>
                                We will use your answers to customize your
                                templates, tax calculations, and settings in the
                                app.
                            </div>
                            <div className={styles.dropdownContainer}>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={birthMonth}
                                    options={monthList}
                                    className={styles.country}
                                    id="birthMonth"
                                    label="Your Birth Month"
                                />
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={birthYear}
                                    options={allYears}
                                    className={styles.country}
                                    id="birthYear"
                                    label="Your Birth Year"
                                />
                            </div>
                        </div>
                    );
                    isPassed = monthList && birthYear;
                }
                break;
            case 2:
                if (this.props.accountData.userRole == "business") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                Now tell us more about your business and revenue
                                model.
                            </div>
                            <div className={styles.plainText}>
                                We support calculations for many different types
                                of revenue models and industries.
                            </div>
                            <div className={styles.agencyDropdownContainer}>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={revenueModal}
                                    options={[
                                        "Subscription",
                                        "Markup",
                                        "Licensing",
                                        "Advertising",
                                        "Pay-Per-User",
                                        "Donation",
                                        "Affiliate",
                                        "Arbitrage",
                                        "Commission",
                                        "Data Sales",
                                        "Web/Direct Sales",
                                        "Other",
                                    ]}
                                    className={styles.country}
                                    id="revenueModal"
                                    label="Revenue Model"
                                />
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={industry}
                                    options={[
                                        "Accommodation and Food Services",
                                        "Administration, Business Support & Waste Management Services",
                                        "Agriculture, Forestry, Fishing and Hunting",
                                        "Arts, Entertainment and Recreation",
                                        "Construction",
                                        "Educational Services",
                                        "Finance and Insurance",
                                        "Healthcare and Social Assistance",
                                        "Information",
                                        "Manufacturing",
                                        "Mining",
                                        "Other Services (except Public Administration)",
                                        "Professional, Scientific and Technical Services",
                                        "Real Estate and Rental and Leasing",
                                        "Retail Trade",
                                        "Transportation and Warehousing",
                                        "Utilities",
                                        "Wholesale Trade",
                                        "Other",
                                    ]}
                                    className={styles.agencyInput}
                                    id="industry"
                                    label="Industry"
                                />
                            </div>
                        </div>
                    );
                    isPassed = revenueModal && industry;
                } else if (this.props.accountData.userRole === "agency") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                Now tell us more about your clients!
                            </div>
                            <div className={styles.plainText}>
                                How many clients do you have and what type of
                                financial service you provide to them?
                            </div>
                            <div className={styles.agencyDropdownContainer}>
                                <SelectDropDown
                                    onChangeInput={this.onChangeInput}
                                    value={numberOfClients}
                                    options={["1-3", "4-10", "11-20", "20+"]}
                                    className={styles.country}
                                    id="numberOfClients"
                                    label="Number of Clients"
                                />
                                <AgencyOnboardingTextField
                                    onChangeInput={this.onChangeInput}
                                    value={serviceType ? serviceType : ""}
                                    className={styles.agencyInput}
                                    id="serviceType"
                                    label="Type (e.g. Financial Planning)"
                                />
                            </div>
                        </div>
                    );
                    isPassed = numberOfClients && serviceType;
                } else {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                What kind of life questions are you trying to
                                answer?
                            </div>
                            <div className={styles.plainText}>
                                This helps us to better understand the kinds of
                                problems and scenarios you are trying to solve.
                            </div>
                            <div className={styles.tagsContainer}>
                                {this.state.tags.map((tag, i) => {
                                    const selected = selectedTags.includes(tag);
                                    return (
                                        <div
                                            onClick={() =>
                                                this.onHandleTag(tag)
                                            }
                                            className={
                                                selected
                                                    ? styles.tagSelected
                                                    : styles.tag
                                            }
                                            key={i}
                                        >
                                            {tag}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                    isPassed = selectedTags.length > 0;
                }
                break;
            case 3:
                if (this.props.accountData.userRole === "business") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                How can we help you with your business?
                            </div>
                            <div className={styles.plainText}>
                                What kinds of questions are you trying to
                                answer? This helps us to better understand the
                                kinds of problems and scenarios you are trying
                                to solve.
                            </div>
                            <div className={styles.dropdownContainer}>
                                <AgencyGoal
                                    value={agencyGoal}
                                    onChangeInput={this.onChangeInput}
                                    placeholder="Build cash flow forecasts, model SaaS pricing models, etc"
                                    id="agencyGoal"
                                />
                            </div>
                        </div>
                    );
                    isPassed = agencyGoal;
                } else if (this.props.accountData.userRole === "agency") {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                How can we help you with your clients?
                            </div>
                            <div className={styles.plainText}>
                                What kinds of questions are you trying to
                                answer? This helps us to better understand the
                                kinds of problems and scenarios you are trying
                                to solve.
                            </div>
                            <div className={styles.dropdownContainer}>
                                <AgencyGoal
                                    value={agencyGoal}
                                    onChangeInput={this.onChangeInput}
                                    placeholder="Build cash flow forecasts, model SaaS pricing models, etc"
                                    id="agencyGoal"
                                />
                            </div>
                        </div>
                    );
                    isPassed = agencyGoal;
                } else {
                    loadComponent = (
                        <div>
                            <div className={styles.boldText}>
                                Besides that, what is your biggest whatifi?
                            </div>
                            <div className={styles.plainText}>
                                What is your single biggest whatifi that we can
                                help you gain clarity on?
                            </div>
                            <div className={styles.dropdownContainer}>
                                <Description
                                    description={this.state.whatifiInput}
                                    onChangeInput={this.onChangeInput}
                                    placeholder="Biggest whatifi"
                                />
                            </div>
                        </div>
                    );
                    isPassed = this.state.whatifiInput;
                }
                break;
            default:
        }

        return (
            <div className={styles.LoginModal}>
                <EventsModal
                    login={true}
                    onHandleClose={this.onHandleClose}
                    preventClose={true}
                >
                    <div className={styles.signupContainer}>
                        <div className={styles.colored}>
                            <div className="whatifiLogo">
                                <img
                                    alt="alt"
                                    src={whatifiLogo}
                                    className="logoSvg"
                                />
                                <div className="logoText">
                                    make bigger business decisions. better.
                                </div>
                            </div>
                            <div className={styles.stepsContainer}>
                                {steps.map((step, i) => {
                                    const isActive =
                                        step.id === this.state.step;
                                    return (
                                        <div
                                            key={i}
                                            className={
                                                step.id <= this.state.step
                                                    ? styles.step
                                                    : styles.stepDisabled
                                            }
                                        >
                                            <div>
                                                {step.step}{" "}
                                                {step.id < this.state.step && (
                                                    <img
                                                        alt="alt"
                                                        src={checkMark}
                                                    />
                                                )}
                                            </div>
                                            {isActive && (
                                                <div
                                                    className={styles.underline}
                                                />
                                            )}
                                            <div>{step.title}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={styles.plain}>
                            <div className={styles.centerContainer}>
                                {loadComponent}
                                <div className={styles.buttonContainer}>
                                    {step > 0 && (
                                        <div
                                            onClick={this.onHandleBack}
                                            className={styles.backButton}
                                        >
                                            Back
                                        </div>
                                    )}
                                    <div
                                        className={
                                            isPassed
                                                ? styles.nextButton
                                                : styles.nextButtonDisabled
                                        }
                                        onClick={
                                            isPassed ? this.onHandleNext : null
                                        }
                                    >
                                        {step === 3 ? "Finish" : "Next"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </EventsModal>
            </div>
        );
    }
}

export default OnboardingContainer;

// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import ContractCard from "../Card/contractCard.js";

// Import Connection Image (if exists)
// *TODO* need a new connection image for contract event
import ContractConnectionImage from "Assets/_optionDescriptionIcons/Contract white.jpg";

// Import Coloured PNGs
// *TODO* need new assets for contract event
import contractBlack from "../../Assets/_eventNodeTypes/contract-black-updated.svg";
import contractBlue from "../../Assets/_eventNodeTypes/contract-blue.png";
import contractWhite from "../../Assets/_eventNodeTypes/contract-white.png";
import contractGrey from "../../Assets/_eventNodeTypes/contract-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import ContractInputExperimental from "Components/InputContainer/ContractInputExperimental/ContractInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - incomeObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - incomeObject.checkInput.call(incomeObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Income
export const contractObject = {
    constant: function () {
        return Contract_Constant();
    },

    name: function () {
        return "Contract";
    },

    checkInput: function (state) {
        return Contract_CheckInput(state);
    },

    svg: function () {
        return Contract_Svg();
    },

    eventNodeMenu: function (focus) {
        return Contract_EventNodeMenu(focus);
    },

    indexText: function () {
        return Contract_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Contract_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Contract_OptionDescription();
    },

    colouredPngs: function () {
        return Contract_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Contract_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Contract_GenerateDisplayFieldData();
    },

    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Contract_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        const calculationMode = entity?.data?.calculationMode ?? "days";
        if (!entity || !entity?.data) return false;
        if (!entity?.data?.income || entity?.data?.income < 0) return false;
        if (!entity?.startDate) return false;
        if (!entity?.name) return false;
        if (!entity?.endDate) return false;
        const contractLength =
            calculationMode == "days"
                ? entity?.data?.contractLengthDays
                : entity?.data?.contractLengthMonths;
        if (!contractLength) return false;
        if (!entity?.data?.netInitial) return false;
        if (!entity?.data?.netFinal) return false;
        if (!entity?.data?.initialPayout) return false;
        if (!entity.data.accountName) return false;

        if (
            entity?.data?.specificPercentages?.length > 0 &&
            !entity?.data?.specificPercentages?.reduce(function (acc, obj) {
                return (
                    acc &&
                    (calculationMode == "days"
                        ? obj.offsetDays
                        : obj.offsetMonths) &&
                    obj?.value &&
                    obj?.net &&
                    obj?.date
                );
            }, true)
        ) {
            return false;
        }

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Contract_Constant = () => {
    return "00dd7b5a-c2f5-4e27-86eb-c744bb93c5a3";
};

// EFFECTS: Returns svg for event
const Contract_Svg = () => {
    return contractBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Contract_EventNodeMenu = (focus) => {
    return <ContractCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Contract_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Income calculations.";
};

// EFFECTS: Returns all icons for event ??
const Contract_Icons = () => {
    let pngs = new Map([
        ["black", contractBlack],
        ["blue", contractBlue],
        ["white", contractWhite],
        ["grey", contractGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Contract_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Contract_InputComponentInfoPassing = (minProps) => {
    return <ContractInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Contract_OptionDescription = () => {
    const tags = ["Option"];
    const type = contractObject.name();
    const description =
        "The Contract Event generates Revenue using milestones and payment terms. It is best used for Scenarios where the amount and frequency of the payments terms does not follow a repeatable pattern. This Event supports multiple milestones and varying payment percentages over time. This Event currently supports only Revenue but will be updated for Expense-like use cases shortly.";
    const usedForText =
        "Use the Contract Event for any one-off Revenue generating contract or project with a known start and end date. Examples might include a construction project, service contract or short term gig-like contract.";
    const connection = "";
    const connectionImage = ContractConnectionImage;
    const learnMoreLink = "";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};
// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Contract_SideModalImage = () => {
    return sideImage;
};

const Contract_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

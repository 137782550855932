import {
    useState,
    ChangeEvent,
    useEffect,
    useMemo,
    useContext,
    useRef,
    MutableRefObject,
} from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { getDefaultName } from "helpers";
import segmentInputsHandler from "../OnInputChangeHandlers/segmentInputsHandler";
import SegmentInputView from "./SegmentInputView";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { throwError } from "helpers/swalError";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { groupObject } from "Components/Registry/Group";
import { segmentObject } from "Components/Registry/Segment";
import { v4 as uuidv4 } from "uuid";
import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import {
    EntityCustomAccount,
    getAccountsToDelete,
    getPaths,
    handleCustomAccountStructures,
} from "helpers/accounts";
import { customerObject } from "Components/Registry/Customer";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function SegmentInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...useAppSelector(
            (state) => state?.scenario?.loadedScenario?.dependency_map
        ),
    });

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(segmentObject);
            _eventData.name = getDefaultName(segmentObject.name(), propsObject);
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        // entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        setEntitiesMap,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        segmentObject,
        dependencyMap.current
    ); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "customer" | "entityName" | "segmentName" | "allocation",
        index: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = segmentInputsHandler(
            value,
            id,
            index,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const addNewSegment = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const segments = [...data.segments];

        const newSegment = {
            id: uuidv4(),
            name: "",
            allocation: "0",
        };

        segments.push(newSegment);

        data.segments = segments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const deleteSegment = (index) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const segments = [...data.segments];

        segments.splice(index, 1);
        data.segments = segments;

        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getCustomers = () => {
        const customers = {};
        const groups = {};

        if (!propsObject.eventId && !propsObject.line) return customers;

        getPresentableDependencies(
            customers,
            customerObject.constant(),
            propsObject, // Temporary object
            true
        );

        getPresentableDependencies(
            groups,
            groupObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(customers).length && !Object.keys(groups).length) {
            throwError(
                "warning",
                "No Customer or Group nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return { ...customers, ...groups };
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const customers = getCustomers();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.customers = customers;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const onHandleSubmitValuesCustom = (newEntitiesMap, passedCheck) => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const onHandleClickSubmitOrUpdate = () => {
        const accountsToDelete = getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            passedCheck,
            entitiesMap,
            createSegmentAccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const passedCheck =
        !!eventData.name && segmentObject.checkInput(entitiesMap);

    return (
        <SegmentInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            onChangeNameDescription={onChangeNameDescription}
            handleOnChange={handleOnChange}
            passedCheck={passedCheck}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            onHandleClickSubmitOrUpdate={onHandleClickSubmitOrUpdate}
            addNewSegment={addNewSegment}
            deleteSegment={deleteSegment}
        />
    );
}

export const createSegmentAccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const segmentCustomRoots = data.segments.map((segment) => {
        return {
            topLevel: [`${segment.name} - `, segment.id],
            topLevelChildren: [],
        };
    });
    const segmentIds: string[] = data.segments.map((segment) => segment.id);

    const accountStructures: EntityCustomAccount[] = [
        {
            Name: entity.name,
            Type: "display",
            Display: "Customer",
            Root: {
                topLevel: "4597dc96-dff1-48e3-b992-89ecf8476274",
                topLevelChildren: segmentIds,
            },
            customRoots: segmentCustomRoots,
        },
    ];

    data.accountStructure = accountStructures;
    data.accountIdsPaths = getPaths(accountStructures);

    data.accountIds = ["4597dc96-dff1-48e3-b992-89ecf8476274", ...segmentIds];

    currentEntityObject.data = data;
    return currentEntityObject;
};

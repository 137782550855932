import { getRelevantEntities } from "actions/getNodeEntityActions";
import moment from "moment";

export const parseDate = (date) => {
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM");
    }

    return null;
};

export const parseValue = (val) => {
    if (!isNaN(val)) {
        return val;
    }

    let value = val;

    if (value.includes("(")) {
        value = value.replace(/\(/g, "");
        value = value.replace(/\)/g, "");
        value = "-".concat(value);
    }

    if (value.includes("$")) {
        value = value.replace(/\$/g, "");
    }

    if (value.includes(",")) {
        value = value.replace(/,/g, "");
    }

    if (isNaN(value)) {
        return null;
    }

    return value;
};

// checks if an entity was created in the existing csv import
export const inCurrentCsv = (entity, csvMap) => {
    if (!csvMap) return false;

    if (entity.name) {
        return entity.name in csvMap;
    }

    const entityObj = getRelevantEntities([entity.id])[entity.id];
    return entityObj.name in csvMap;
};

import moment from "moment";

/**
 * This function takes in an arbitrary number of date strings as arguments and returns
 * an array of date object corresponding to the given strings. To grab all of the strings
 * returned simply use the javascript array destructuring assignment.
 *
 * Eg. `const [d1, d2, d3] = stringToDate(s1, s2, s3)`
 * **Array destructuring is still required if only one argument is passed in**
 *
 * The norm for string representation of dates in this app is "YYYY-MM-DD"
 */
export const stringsToDates = (...strings: string[]) => {
    return strings.map((str) => {
        return moment(str);
    });
};

/**
 * This function takes in two dates strings, and converts them into date objects.
 * Then returns true if the first date is before the second date, and false otherwise
 */
export const isBefore = (first: string, second: string) => {
    const [firstDate, secondDate] = stringsToDates(first, second);
    return firstDate.isBefore(secondDate);
};

import {
    SAMPLE_SCENARIO,
    SET_MANAGER,
    SET_BACKGROUND_MANAGER,
    USER_SCENARIOS,
    EDIT_SCENARIO,
    CREATE_SCENARIO,
    LOAD_SCENARIO,
    PAUSE_DECISION_ENGINE,
    DELETE_SCENARIO,
    SET_FOCUS,
    RESET_FOCUS,
    UPDATE_FOCUSED_CARD_ID,
    UPDATE_SUCCESS,
    CLOSE_MODAL,
    UPDATE_NODE,
    UPDATE_NODE_CLEAR_FOCUS,
    SET_LINE_FOCUS,
    RESET_SOURCE,
    SET_SOURCE,
    COLLECT_NODE,
    DISABLE_COLLECT_NODE,
    REMOVE_COLLECTED_NODE,
    CANCEL_COLLECT_MODE,
    CANCEL_ACTIONS,
    POP_UP_OPTION,
    CLOSE_POP_UP_OPTION,
    SELECTED_OPTION,
    CLOSE_EVENTS_MODAL,
    COPY_EVENT,
    SHARE_SCENARIO,
    CANCEL_SHARE_SCENARIO,
    SEND_EMAIL,
    SHARED_SCENARIO_PER_ACCOUNT,
    UPDATE_SCENARIO_CANVAS,
    EDIT_NODE,
    SELECT_LINE,
    HIGHLIGHTED_THREAD,
    NODEGRAPH_DISPLAY,
    NODEGRAPH_STATE,
    FLINK_BANK_DATA,
    GET_SHARED_SCENARIO,
    SIGNUP,
    GET_USER_DATA,
    SAVE_USER_DETAILS,
    UPDATE_COMMENTS,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    ONBOARDING_STATE,
    ONBOARDING_DATA,
    ONBOARDING_SCENARIO,
    PLAN,
    DEFAULT,
    ONBOARDING,
    SHOW_CREATE_SCENARIO,
    SHOW_LOGIN,
    TAB_SELECTED,
    SHOW_OPTION_PICKER,
    GET_BASELINE,
    HOVERED_INPUT,
    FOCUS_VALUE,
    BASELINE_MANAGER,
    CLOSE_EXPIRED_NODE,
    CLOSE_OUTDATED_NODE,
    SET_SHOW_PASTE_MODAL,
    BASELINE_DATA_MANAGER,
    TOGGLE_ACCOUNT_TYPE,
    GET_AGENCY_CLIENTS,
    ADD_CLIENT,
    DELETE_CLIENT,
    GET_LIBRARY,
    UPDATE_CLIENT,
    ADDING_LIBRARY_EVENT,
    SELECT_OPTIONS_TAB,
    SHOW_EXPLORE_OPTIONS,
    SHOW_AGENCY_BASELINE_PAGE,
    ARCHIVE_SCENARIO,
    REACTIVATE_ARCHIVED_SCENARIO,
    UPDATE_ACCOUNT_DATA,
    GET_SUBSCRIPTION,
    GET_TEMPLATES,
    ARE_BASELINES_LOADED,
    UPDATE_RATING,
    SET_DUPLICATE_SCENARIO_MODAL,
    SET_CELL_ROW_MODAL,
    TRIGGER_UPDATE,
    TOGGLE_COLLECTION_MODE,
    UPDATE_CONNECTIONS_SELECTED,
    TOGGLE_DELETION_MODE,
    CLEAR_SELECTED_EVENTS,
    SELECT_EVENT,
    SELECT_ENTITY,
    SET_ZOOMED_EVENT_ID,
    RESET_ZOOMED_EVENT_ID,
    SELECT_FILTERED_EVENTS_AND_ENTITIES,
    SELECT_LINE_FOR_DISCONNECTION,
    SET_ACCOUNT_MAPPING_MODAL,
    UPDATE_DEPENDENCY_MAP,
    PREVENT_EVENT_MODAL_ACTIONS,
    SET_CHART_MODAL,
    SET_ACCOUNT_FIELD_MAPPING_MODAL,
} from "../actions/types";
import { SIGN_UP, BUSINESS } from "../helpers/constants";
import { ScenarioState } from "./typesSchema/scenarioStateSchema";

const initialState: ScenarioState = {
    accountData: null,
    accountSelected: BUSINESS,
    activeAgencyClients: [],
    agencyClients: [],
    archivedAgencyClients: [],
    archivedScenarios: [],
    areBaselinesLoaded: false,
    backgroundManager: null,
    bankData: null,
    baseline: null,
    baselineDataManager: null,
    baselineManager: null,
    canvas: null,
    collectedNode: [],
    collectNode: false,
    connectionHead: null,
    connectionMode: false,
    connectionsSelected: [],
    connectableEvents: {},
    copiedEvent: null,
    deletionData: null,
    edit: false,
    editData: null,
    eventLibrary: {},
    expiredNodes: null,
    outdatedNodes: null,
    focus: null,
    focusedCardId: "",
    filteredEventAndEntityData: {},
    highlightedThread: null,
    hoveredInput: null,
    line: null,
    lineAddButtonClick: null,
    lineDisconnection: {},
    loadedScenario: null,
    loadedScenarioId: null,
    loadedUserScenarios: false,
    loginPopUp: false,
    loginPopUpValue: SIGN_UP,
    manager: null,
    nodeGraphDisplay: "selectedThreads",
    nodeGraphState: "default",
    onboardingData: null,
    pauseDecisionEngine: false,
    preventEventModalActions: false,
    range: 10,
    sampleScenarios: [],
    sampleScenariosLoading: true,
    scenarioTemplates: [],
    selectedOption: null,
    selectedEntities: {},
    selectedEvents: {},
    sharedScenario: [],
    shareScenario: false,
    showAgencyBaselinePage: true,
    showCreateScenario: false,
    showExpiredModal: false,
    showOutdatedModal: false,
    showPasteModal: {
        show: false,
    },
    showDuplicateScenarioModal: {
        show: false,
    },
    showExploreWhatifiOptions: true,
    showModal: false,
    showNotification: false,
    showOptions: false,
    showChartModal: {
        show: false,
        numDataPoints: 0,
        addUpdateDistribution: (_distData: number[]) => {
            // no-op
        },
        yData: [],
    },
    showCellRowModal: {
        show: false,
        type: "schedule",
        entityData: {
            id: "",
            cadence: "",
            data: {},
            name: "",
            startDate: "",
            type: "",
            version: "",
            calculateState: false,
            bypassState: false,
        },
        createSchedule: () => {
            return;
        },
    },
    signupState: DEFAULT,
    source: null,
    tabSelected: null,
    target: null,
    targetNode: null,
    toggleBlock: false,
    urlLink: null,
    userScenarios: [],
    userScenariosLoading: true,
    zoomedEventId: "",
    showAccountMappingModal: {
        show: false,
    },
    showAccountFieldMappingModal: {
        show: false,
    },
};

export default function scenarioReducer(state = initialState, action) {
    let scenario;
    switch (action.type) {
        case UPDATE_DEPENDENCY_MAP:
            if (action.payload) {
                const updatedLoadedScenario = {
                    ...state.loadedScenario,
                    dependency_map: action.payload,
                };
                return {
                    ...state,
                    loadedScenario: updatedLoadedScenario,
                };
            }
            break;
        case SAMPLE_SCENARIO:
            return {
                ...state,
                sampleScenarios: action.payload,
            };
        case SET_MANAGER:
            if (action.payload) {
                return {
                    ...state,
                    manager: action.payload,
                };
            }
            break;
        case SET_BACKGROUND_MANAGER:
            if (action.payload) {
                return {
                    ...state,
                    backgroundManager: action.payload,
                };
            }
            break;
        case USER_SCENARIOS:
            let { userScenarios, archivedScenarios } = action.payload;
            const loadedScenario = userScenarios.filter((scenario) => {
                return scenario.id === state.loadedScenarioId;
            });

            return {
                ...state,
                userScenarios,
                archivedScenarios,
                loadedUserScenarios: true,
                loadedScenario: loadedScenario[0] || state.loadedScenario,
                calculatedScenarios: action.payload,
            };
        case EDIT_SCENARIO:
            const updatedScenario = state.userScenarios.map((scenario) => {
                if (scenario.id === action.payload.id) {
                    scenario.name = action.payload.name;
                    scenario.baselineid = action.payload.baselineid;
                }
                return scenario;
            });

            return {
                ...state,
                userScenarios: updatedScenario,
            };
        case UPDATE_COMMENTS:
            const updatedScenarioComments = state.userScenarios.map(
                (scenario) => {
                    if (scenario.id === action.payload.id) {
                        scenario.name = action.payload.name;
                        return action.payload;
                    }
                    return scenario;
                }
            );

            return {
                ...state,
                userScenarios: updatedScenarioComments,
            };

        case CREATE_SCENARIO:
            return {
                ...state,
                loadedScenarioId: action.payload,
                range: parseInt(action.range),
            };
        case LOAD_SCENARIO:
            return {
                ...state,
                loadedScenario: action.payload,
                loadedScenarioId: action.payload.id,
                focus: null,
                source: null,
                expiredNodes: action.expiredNode,
                outdatedNodes: action.outdatedNode,
                showExpiredModal: action.expiredNode.length > 0 ? true : false,
                showOutdatedModal:
                    action.outdatedNode.length > 0 ? true : false,
                selectedEntities: {},
                selectedEvents: {},
                filteredEventAndEntityData: {},
            };
        case PAUSE_DECISION_ENGINE:
            return {
                ...state,
                pauseDecisionEngine: action.payload,
            };
        case UPDATE_NODE:
            return {
                ...state,
                loadedScenario: action.payload,
            };
        case DELETE_SCENARIO:
            const filteredScenario = state.userScenarios.filter((scenario) => {
                return scenario.id !== action.payload;
            });

            return {
                ...state,
                userScenarios: filteredScenario,
                loadedScenario: state.sampleScenarios[0],
            };
        case SET_FOCUS:
            let newFocus = action.payload;
            if (state.collectNode) {
                let doesExist = false;
                if (state.collectedNode.length > 0) {
                    state.collectedNode.forEach((collected) => {
                        if (collected.id === action.payload.id) {
                            doesExist = true;
                        }
                    });
                }

                if (!doesExist) {
                    state.collectedNode.push(action.payload);
                }
            } else {
                const { focus } = state;
                if (focus === newFocus) {
                    newFocus = null;
                }
            }

            return {
                ...state,
                focus: newFocus,
                lineAddButtonClick: action.lineAddButtonClick,
                targetNode: action.target,
                source: null,
                target: null,
                toggleBlock: !state.toggleBlock,
            };
        case FOCUS_VALUE:
            return {
                ...state,
                focus: action.payload,
            };
        case SET_SOURCE:
            return {
                ...state,
                source: action.payload,
            };
        case RESET_SOURCE:
            return {
                ...state,
                focus: null,
                source: null,
                target: null,
                targetNode: null,
            };

        case SET_LINE_FOCUS:
            return {
                ...state,
                source: action.source,
                target: action.target,
                focus: null,
            };
        case RESET_FOCUS:
            return {
                ...state,
                focus: action.payload,
            };
        case UPDATE_NODE_CLEAR_FOCUS:
            return {
                ...state,
                focus: null,
            };
        case UPDATE_RATING:
            return {
                ...state,
                focus: null,
            };
        case UPDATE_SUCCESS:
            return {
                ...state,
                showModal: true,
            };
        case CLOSE_MODAL:
            return {
                ...state,
                showModal: false,
            };
        case COLLECT_NODE:
            let showCollect = false;
            action.manager.buckets.forEach((bucket) => {
                if (bucket.length > 1) {
                    showCollect = true;
                }
            });

            if (showCollect) {
                return {
                    ...state,
                    collectNode: showCollect,
                    canvas: action.canvas,
                    focus: true,
                };
            } else {
                return {
                    ...state,
                    collectNode: showCollect,
                    canvas: action.canvas,
                };
            }

        case DISABLE_COLLECT_NODE:
            return {
                ...state,
                collectNode: false,
                collectedNode: [],
            };

        case REMOVE_COLLECTED_NODE:
            const newCollectedNode = state.collectedNode.filter((node) => {
                return node.id !== action.payload;
            });

            return {
                ...state,
                collectedNode: newCollectedNode,
            };
        case CANCEL_COLLECT_MODE:
            return {
                ...state,
                focus: null,
                collectedNode: [],
                collectNode: false,
            };
        case CANCEL_ACTIONS:
            return {
                ...state,
                source: null,
                focus: null,
            };
        case POP_UP_OPTION:
            return {
                ...state,
                showOptions: true,
                focus: action.focus,
                line: action.line,
            };
        case SHOW_OPTION_PICKER:
            return {
                ...state,
                showOptions: true,
            };
        case CLOSE_POP_UP_OPTION:
            return {
                ...state,
                showOptions: false,
                selectedOption: null,
                focus: null,
                line: null,
                edit: false,
                editData: null,
                isAddingLibraryEvent: false,
                currentOptionsTab: null,
            };
        case SELECT_OPTIONS_TAB:
            return {
                ...state,
                currentOptionsTab: action.payload,
            };
        case SELECTED_OPTION:
            return {
                ...state,
                selectedOption: action.payload,
            };
        case CLOSE_EVENTS_MODAL:
            return {
                ...state,
                selectedOption: null,
                edit: false,
                editData: null,
            };
        case COPY_EVENT:
            return {
                ...state,
                copiedEvent: action.payload,
            };
        case SHARE_SCENARIO:
            return {
                ...state,
                shareScenario: true,
                urlLink: action.link,
            };
        case CANCEL_SHARE_SCENARIO:
            return {
                ...state,
                shareScenario: false,
            };
        case SEND_EMAIL:
            return {
                ...state,
            };
        case SHARED_SCENARIO_PER_ACCOUNT:
            return {
                ...state,
                sharedScenario: action.data,
            };
        case GET_SHARED_SCENARIO:
            return {
                ...state,
                sharedScenario: action.data || [],
            };
        case UPDATE_SCENARIO_CANVAS:
            return {
                ...state,
            };
        case EDIT_NODE:
            return {
                ...state,
                edit: true,
                editData: action.payload,
                selectedOption: action.payload.type,
            };
        case SELECT_LINE:
            return {
                ...state,
                line: action.line,
            };
        case HIGHLIGHTED_THREAD:
            return {
                ...state,
                highlightedThread: action.payload,
            };
        case NODEGRAPH_DISPLAY:
            return {
                ...state,
                nodeGraphDisplay: action.payload,
            };
        case NODEGRAPH_STATE:
            return {
                ...state,
                nodeGraphState: action.payload,
            };
        case FLINK_BANK_DATA:
            return {
                ...state,
                bankData: action.payload,
            };
        case SIGNUP:
            return {
                ...state,
                accountData: { ...state.accountData, ...action.payload },
                signupState: ONBOARDING,
            };
        case GET_USER_DATA:
            return {
                ...state,
                accountData: { ...state.accountData, ...action.payload },
            };
        case SAVE_USER_DETAILS:
            return {
                ...state,
                signupState: PLAN,
            };
        case SHOW_NOTIFICATION:
            return {
                ...state,
                showNotification: !state.showNotification,
            };
        case HIDE_NOTIFICATION:
            return {
                ...state,
                showNotification: false,
            };
        case ONBOARDING_STATE:
            return {
                ...state,
                signupState: action.payload,
            };
        case ONBOARDING_DATA:
            return {
                ...state,
                onboardingData: action.payload,
            };
        case ONBOARDING_SCENARIO:
            return {
                ...state,
                userScenarios: [action.payload],
            };
        case SHOW_CREATE_SCENARIO:
            return {
                ...state,
                showCreateScenario: action.payload,
            };
        case SHOW_LOGIN:
            return {
                ...state,
                loginPopUp: action.payload.bool,
                loginPopUpValue: action.payload.data,
                accountSelected: action.payload.type
                    ? action.payload.type
                    : BUSINESS,
            };
        case TAB_SELECTED:
            return {
                ...state,
                tabSelected: action.payload,
            };
        case GET_BASELINE:
            return {
                ...state,
                baselines: action.payload,
                baseline: action.payload[0],
            };
        case ARE_BASELINES_LOADED:
            return {
                ...state,
                areBaselinesLoaded: action.payload,
            };
        case BASELINE_MANAGER:
            return {
                ...state,
                baselineManager: action.payload,
            };
        case BASELINE_DATA_MANAGER:
            return {
                ...state,
                baselineDataManager: action.payload,
            };
        case HOVERED_INPUT:
            return {
                ...state,
                hoveredInput: action.payload,
            };
        case CLOSE_EXPIRED_NODE:
            return {
                ...state,
                showExpiredModal: false,
            };
        case CLOSE_OUTDATED_NODE:
            return {
                ...state,
                showOutdatedModal: false,
            };
        case SET_SHOW_PASTE_MODAL:
            const paste_modal_payload: { show: boolean; callback?: Function } =
                {
                    show: action.payload.show as boolean,
                };
            if (action.payload.callback)
                paste_modal_payload.callback = action.payload
                    .callback as Function;
            return {
                ...state,
                showPasteModal: paste_modal_payload,
            };
        case SET_DUPLICATE_SCENARIO_MODAL:
            const dup_scenario_payload: { show: boolean; callback?: Function } =
                {
                    show: action.payload.show as boolean,
                };
            if (action.payload.callback)
                dup_scenario_payload.callback = action.payload
                    .callback as Function;
            return {
                ...state,
                showDuplicateScenarioModal: dup_scenario_payload,
            };
        case SET_CHART_MODAL:
            return {
                ...state,
                showChartModal: action.payload,
            };
        case SET_CELL_ROW_MODAL:
            const updatedShowCellRowModal = {
                ...state.showCellRowModal,
                ...action.payload,
            };
            return {
                ...state,
                showCellRowModal: updatedShowCellRowModal,
            };
        case TOGGLE_ACCOUNT_TYPE:
            return {
                ...state,
                accountSelected: action.payload,
            };
        case GET_AGENCY_CLIENTS:
            return {
                ...state,
                agencyClients: action.payload,
                activeAgencyClients: action.payload.filter(
                    (client) => !client.clientdata.archived
                ),
                archivedAgencyClients: action.payload.filter(
                    (client) => client.clientdata.archived
                ),
            };
        case ADD_CLIENT: {
            return {
                ...state,
                agencyClients: [...state.agencyClients, action.payload],
            };
        }
        case DELETE_CLIENT: {
            const filteredArray = state.agencyClients.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                agencyClients: filteredArray,
                activeAgencyClients: filteredArray.filter(
                    (client) => !client.clientdata.archived
                ),
                archivedAgencyClients: filteredArray.filter(
                    (client) => client.clientdata.archived
                ),
            };
        }
        case GET_LIBRARY: {
            return {
                ...state,
                eventLibrary: action.payload,
            };
        }
        case ADDING_LIBRARY_EVENT: {
            return {
                ...state,
                isAddingLibraryEvent: action.payload,
            };
        }
        case UPDATE_CLIENT: {
            const updatedArray = state.agencyClients.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
            return {
                ...state,
                agencyClients: updatedArray,
                activeAgencyClients: updatedArray.filter(
                    (client) => !client.clientdata.archived
                ),
                archivedAgencyClients: updatedArray.filter(
                    (client) => client.clientdata.archived
                ),
            };
        }
        case SHOW_EXPLORE_OPTIONS: {
            return {
                ...state,
                showExploreWhatifiOptions: action.payload,
            };
        }
        case SHOW_AGENCY_BASELINE_PAGE: {
            return {
                ...state,
                showAgencyBaselinePage: action.payload,
            };
        }
        case ARCHIVE_SCENARIO:
            scenario = action.payload;
            userScenarios = state.userScenarios.filter((entry) => {
                return entry.id !== scenario.id;
            });
            archivedScenarios = state.archivedScenarios;
            archivedScenarios.push(scenario);
            return {
                ...state,
                archivedScenarios,
                userScenarios,
            };
        case REACTIVATE_ARCHIVED_SCENARIO:
            scenario = action.payload;
            archivedScenarios = state.archivedScenarios.filter((entry) => {
                return entry.id !== scenario.id;
            });
            userScenarios = state.userScenarios;
            userScenarios.push(scenario);
            return {
                ...state,
                userScenarios,
                archivedScenarios,
            };
        case UPDATE_ACCOUNT_DATA:
            return {
                ...state,
                accountData: { ...state.accountData, ...action.payload },
            };
        case GET_SUBSCRIPTION:
            return {
                ...state,
                accountData: { ...state.accountData, plan: action.payload },
            };
        case GET_TEMPLATES: {
            return {
                ...state,
                scenarioTemplates: action.payload,
                sampleScenarios: action.payload.filter((template) => {
                    return (
                        template.categories.includes("sample") &&
                        template.is_sample === true
                    );
                }),
            };
        }
        case TRIGGER_UPDATE:
            return {
                ...state,
                updateState: action.payload,
            };
        case TOGGLE_COLLECTION_MODE:
            return {
                ...state,
                connectionMode: action.connectionMode,
                connectionHead: action.connectionHead,
                connectableEvents: action.connectableEvents,
            };
        case UPDATE_CONNECTIONS_SELECTED:
            const selectedEventId: string = action.payload;
            const connectionsSelected = state.connectionsSelected;
            if (selectedEventId === null) {
                return {
                    ...state,
                    connectionsSelected: [],
                };
            } else if (connectionsSelected?.includes(selectedEventId)) {
                const eventToRemove =
                    connectionsSelected.indexOf(selectedEventId);
                const newConnectionsSelected = [...connectionsSelected];
                newConnectionsSelected.splice(eventToRemove, 1);
                return {
                    ...state,
                    connectionsSelected: newConnectionsSelected,
                };
            } else {
                return {
                    ...state,
                    connectionsSelected: [
                        ...connectionsSelected,
                        selectedEventId,
                    ],
                };
            }
        case TOGGLE_DELETION_MODE:
            return {
                ...state,
                deletionData: action.payload,
            };
        case SELECT_LINE_FOR_DISCONNECTION:
            return {
                ...state,
                lineDisconnection: action.payload,
            };
        case CLEAR_SELECTED_EVENTS:
            return {
                ...state,
                selectedEvents: {},
            };
        case SELECT_EVENT:
            const newSelectedEvents = state.selectedEvents;
            const { eventId, location } = action.payload;
            if (newSelectedEvents[eventId]) {
                delete newSelectedEvents[eventId];
            } else {
                newSelectedEvents[eventId] = location;
            }
            return {
                ...state,
                selectedEvents: newSelectedEvents,
            };
        case SELECT_ENTITY:
            const newSelectedEntities = state.selectedEntities;
            if (newSelectedEntities[action.payload]) {
                delete newSelectedEntities[action.payload];
            } else {
                newSelectedEntities[action.payload] = true;
            }
            return {
                ...state,
                selectedEntities: newSelectedEntities,
            };
        case SET_ZOOMED_EVENT_ID:
            return {
                ...state,
                zoomedEventId: action.payload,
            };
        case RESET_ZOOMED_EVENT_ID:
            return {
                ...state,
                zoomedEventId: "",
            };
        case SELECT_FILTERED_EVENTS_AND_ENTITIES:
            const newFilteredEventAndEntityData =
                state.filteredEventAndEntityData;
            if (newFilteredEventAndEntityData[action.payload.id]) {
                delete newFilteredEventAndEntityData[action.payload.id];
            } else {
                newFilteredEventAndEntityData[action.payload.id] =
                    action.payload;
            }
            return {
                ...state,
                filteredEventAndEntityData: newFilteredEventAndEntityData,
            };
        case SET_ACCOUNT_MAPPING_MODAL:
            return {
                ...state,
                showAccountMappingModal: action.payload,
            };
        case SET_ACCOUNT_FIELD_MAPPING_MODAL:
            return {
                ...state,
                showAccountFieldMappingModal: action.payload,
            };
        case PREVENT_EVENT_MODAL_ACTIONS:
            return {
                ...state,
                preventEventModalActions: action.payload,
            };
        case UPDATE_FOCUSED_CARD_ID:
            return {
                ...state,
                focusedCardId: action.payload,
            };
        default:
            return state;
    }
}

import React from "react";

import UnitCostCard from "../Card/unitCostCard";
// import UnitCostInput from "../InputContainer/UnitCostInput";

import unitCostConnection from "Assets/_optionDescriptionIcons/Unit Cost, Revenue white .jpg";

import unitCostBlack from "../../Assets/_eventNodeTypes/unitCost-black-updated.svg";
import unitCostBlue from "../../Assets/_eventNodeTypes/unitCost-blue.png";
import unitCostWhite from "../../Assets/_eventNodeTypes/unitCost-white.png";
import unitCostGrey from "../../Assets/_eventNodeTypes/unitCost-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import UnitCostInputExperimental from "Components/InputContainer/UnitCostInputExperimental/UnitCostInputExperimental";

export const unitCostObject = {
    constant: function () {
        return UnitCost_Constant();
    },

    name: function () {
        return "Unit Cost";
    },

    checkInput: function (state) {
        return UnitCost_CheckInput(state);
    },
    svg: function () {
        return UnitCost_Svg();
    },

    eventNodeMenu: function (focus) {
        return UnitCost_EventNodeMenu(focus);
    },

    indexText: function () {
        return UnitCost_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return UnitCost_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return UnitCost_OptionDescription();
    },

    colouredPngs: function () {
        return UnitCost_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return UnitCost_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return UnitCost_GenerateDisplayFieldData();
    },
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const UnitCost_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.cadence || !entity.data.cost || !entity.data.cost < 0)
            return false;
        if (!entity.name) return false;
        if (
            entity.data.unitCostType === "Revenue" &&
            entity.data.includeCostOfGoodsSold &&
            !entity.data.costOfGoodsSold
        )
            return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const UnitCost_Constant = () => {
    return "c617b4df-26e6-40ed-82fa-0c12a6412e4c";
};

// EFFECTS: Returns black png / svg for event
const UnitCost_Svg = () => {
    return unitCostBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const UnitCost_EventNodeMenu = (focus) => {
    return <UnitCostCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const UnitCost_IndexText = () => {
    return "The Unit Cost Event keeps track of a Cost/unit for use by other events. As an example, it can be used in conjuction with Customer and Revenue to create Cashflow.";
};

// EFFECTS: Returns all icons for event ??
const UnitCost_Icons = () => {
    let pngs = new Map([
        ["black", unitCostBlack],
        ["blue", unitCostBlue],
        ["white", unitCostWhite],
        ["grey", unitCostGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const UnitCost_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const UnitCost_InputComponentInfoPassing = (minProps) => {
    return <UnitCostInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const UnitCost_OptionDescription = () => {
    const tags = ["Option"];
    const type = unitCostObject.name();
    const description =
        "Unit Cost is a versatile Event that can be used to model any product or service, whether income or an expense, that has a fixed price per unit sold or consumed. When combined with a Customer or Sales Event, and a Revenue Event, you can quickly calculate Revenue, Expenses and associated costs like Costs of Goods Sold.";
    const usedForText =
        "The Unit Cost is commonly used in SaaS (Software-as-a-Service) and E-Commerce models, but can also be used in more traditional physical goods and revenue forecasts.";
    const connection = "";
    const connectionImage = unitCostConnection;
    const learnMoreLink = "https://www.investopedia.com/terms/u/unitcost.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const UnitCost_SideModalImage = () => {
    return sideImage;
};

const UnitCost_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import { useState, ChangeEvent } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import UnitCostInputView from "./UnitCostInputView";
import UnitCostInputsHandler from "../OnInputChangeHandlers/unitCostInputsHandler";
import { getDefaultName } from "helpers";
import { unitCostObject } from "Components/Registry/Unit Cost";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { updateEntityState } from "helpers/updateEntityState";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function UnitCostInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);

    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(unitCostObject);
            _eventData.name = getDefaultName(
                unitCostObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
        updateInflation,
    } = useEntities(entitiesObject, eventData, edit, unitCostObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = { manager, line, focus };

    const handleChangeUnitCostType = (e) => {
        const multiplier = e.target.id === "Expense" ? -1 : 1;

        const newEntitiesMap = { ...entitiesMap };
        const newCurrentEntity = { ...newEntitiesMap[currentEntity] };
        const data = { ...newCurrentEntity.data };

        data.unitCostType = e.target.id;
        data.value = multiplier * Math.abs(data.value);

        newCurrentEntity.data = data;
        newEntitiesMap[currentEntity] = newCurrentEntity;
        setEntitiesMap(newEntitiesMap);
    };

    const toggleIncludeCostOfGoodsSold = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = { ...newEntitiesMap[currentEntity] };
        const data = { ...currentEntityObject.data };
        data.includeCostOfGoodsSold = !data.includeCostOfGoodsSold;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const isExpense =
        entitiesMap[currentEntity].data.unitCostType === "Expense";

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "value"
            | "url"
            | "entityName"
            | "cadence"
            | "rating"
            | "inflationRate",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = UnitCostInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && unitCostObject.checkInput(entitiesMap);

    return (
        <UnitCostInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            updateInflation={updateInflation}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            handleOnChange={handleOnChange}
            handleChangeUnitCostType={handleChangeUnitCostType}
            isExpense={isExpense}
            edit={edit}
            eventData={eventData}
            toggleIncludeCostOfGoodsSold={toggleIncludeCostOfGoodsSold}
            handleEntityStateChange={handleEntityStateChange}
        />
    );
}

import type {
    MenusTemplatesSchema,
    MenusTemplatesInputSchema,
} from "reducers/typesSchema/menusTemplatesSchema";
import type { AppThunk } from "store";

export const UPSERT_MENUS_TEMPLATES = "menusTemplates/UPSERT_MENUS_TEMPLATES";
export const SET_MENUS_TEMPLATES = "menusTemplates/SET_MENUS_TEMPLATES";

export function upsertMenusTemplates(
    newMenusTemplates: MenusTemplatesInputSchema
): AppThunk<void> {
    return (dispatch, getState) => {
        const curEventsTemplates: MenusTemplatesSchema = {
            ...getState().menusTemplates,
        };

        const newEventsTemplates: MenusTemplatesSchema = {};
        Object.entries(newMenusTemplates).forEach(
            ([eventTemplateTypeId, eventTemplate]) => {
                newEventsTemplates[eventTemplateTypeId] = {
                    ...curEventsTemplates[eventTemplateTypeId],
                    ...eventTemplate,
                };
            }
        );

        dispatch({
            type: UPSERT_MENUS_TEMPLATES,
            payload: newEventsTemplates,
        });
    };
}

export function removeMenusTemplates(menusTypesIds: string[]): AppThunk<void> {
    return (dispatch, getState) => {
        const menusTemplatesCopy: MenusTemplatesSchema = {
            ...getState().menusTemplates,
        };
        menusTypesIds.forEach((menuTypeId) => {
            delete menusTemplatesCopy[menuTypeId];
        });

        dispatch({
            type: SET_MENUS_TEMPLATES,
            payload: menusTemplatesCopy,
        });
    };
}

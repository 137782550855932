import styles from "./index.module.css";
import { useContext, useMemo, useState, useEffect } from "react";
import { AvailableAccount } from "./availableAccount";
import { useAppSelector } from "store/useAppSelectorDispatch";
import {
    SelectedTopLevelAccountContext,
    SelectedSubAccountContext,
    SelectedLeafAccountContext,
    SelectedLeafSubAccountContext,
} from "./accountPickerContexts";

const cashflowUUID = "1a0e28ee-b62a-4c17-bb7d-73a79fcbcb78";
const calcEquityUUID = "a4d432a8-26ab-41f0-9008-88ae2d171378";
const profitUUID = "4db53c56-db20-4798-9830-adbfcc977b26";
interface AccountLevelSectionProps {
    accountLevel:
        | "subAccount"
        | "leafAccount"
        | "leafSubAccount"
        | "fifthLevelAccount";
    handleExpand: (accountLevel, accountId) => void;
    selectAccount: (accountId) => void;
}

export const AccountLevelSection = ({
    accountLevel,
    handleExpand,
    selectAccount,
}: AccountLevelSectionProps) => {
    const allAccountData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );

    const selectedTopLevelAccount = useContext(SelectedTopLevelAccountContext);
    const selectedSubAccount = useContext(SelectedSubAccountContext);
    const selectedLeafAccount = useContext(SelectedLeafAccountContext);
    const selectedLeafSubAccount = useContext(SelectedLeafSubAccountContext);

    const isVisible: boolean = useMemo(() => {
        switch (accountLevel) {
            case "subAccount":
                return !!selectedTopLevelAccount;
            case "leafAccount":
                return !!selectedSubAccount;
            case "leafSubAccount":
                return !!selectedLeafAccount;
            case "fifthLevelAccount":
                return !!selectedLeafSubAccount;
            default:
                return false;
        }
    }, [
        accountLevel,
        selectedLeafSubAccount,
        selectedLeafAccount,
        selectedSubAccount,
        selectedTopLevelAccount,
    ]);

    const relevantLedgers = useAppSelector((state) => {
        const relevantLedgersHashMap = {};
        const relevantLedgers =
            state?.allAccountLedgers?.relevantLedgers?.filter(
                (ledger) =>
                    !(
                        ledger.id === cashflowUUID ||
                        ledger.id === calcEquityUUID ||
                        ledger.id === profitUUID
                    )
            ) ?? [];
        relevantLedgers?.map(
            (ledger) => (relevantLedgersHashMap[ledger.id] = ledger)
        );
        return relevantLedgersHashMap;
    });

    const relevantAccounts = useMemo(() => {
        switch (accountLevel) {
            case "subAccount":
                const topLevelAccountData =
                    allAccountData?.[selectedTopLevelAccount];
                const subAccounts =
                    topLevelAccountData?.children?.map(
                        (childId) => allAccountData?.[childId]
                    ) ?? [];
                return subAccounts;
            case "leafAccount":
                const subAccountData = allAccountData?.[selectedSubAccount];
                const leafAccounts =
                    subAccountData?.children?.map(
                        (childId) => allAccountData?.[childId]
                    ) ?? [];
                return leafAccounts;
            case "leafSubAccount":
                const leafAccountData = allAccountData?.[selectedLeafAccount];
                const leafSubAccounts =
                    leafAccountData?.children?.map(
                        (childId) => allAccountData?.[childId]
                    ) ?? [];
                return leafSubAccounts;
            case "fifthLevelAccount":
                const leafSubAccountData =
                    allAccountData?.[selectedLeafSubAccount];
                const fifthLevelAccounts =
                    leafSubAccountData?.children?.map(
                        (childId) => allAccountData?.[childId]
                    ) ?? [];
                return fifthLevelAccounts;
            default:
                return [];
        }
    }, [
        accountLevel,
        allAccountData,
        selectedLeafSubAccount,
        selectedLeafAccount,
        selectedSubAccount,
        selectedTopLevelAccount,
    ]);

    const allAccountsToDisplay = useMemo(() => {
        const realRelevantAccounts = relevantAccounts?.filter(
            (account) => relevantLedgers[account?.id]
        );
        return realRelevantAccounts;
    }, [relevantAccounts, relevantLedgers]);

    const [widthState, setWidthState] = useState(244);
    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        const handleMouseMove = (e) => {
            const ratio = window.devicePixelRatio;

            const pxChange = e.movementX / ratio;

            setWidthState(widthState + pxChange);
        };

        if (mouseDown) {
            window.addEventListener("mousemove", handleMouseMove);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, [mouseDown, widthState]);

    useEffect(() => {
        const handleMouseUp = () => setMouseDown(false);

        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    if (!isVisible) {
        return <></>;
    }

    if (allAccountsToDisplay?.length === 0) {
        return (
            <div className={styles.noDisplayText}>no accounts to display.</div>
        );
    }

    return (
        <div
            className={styles.accountLevelContainer}
            style={{ width: widthState }}
        >
            {allAccountsToDisplay?.length > 0 &&
                allAccountsToDisplay.map((account) => (
                    <AvailableAccount
                        key={account.id}
                        id={account.id}
                        accountLevel={accountLevel}
                        selectAccount={selectAccount}
                        handleExpand={handleExpand}
                    />
                ))}
            <div
                className={styles.verDragBar}
                onMouseDown={() => setMouseDown(true)}
            ></div>
        </div>
    );
};

import type { GoalNodeDataObjectSchema } from "reducers/typesSchema/calculatedThreadsSchema";
import { Image } from "@amcharts/amcharts4/core";
import { Bullet, LineSeries } from "@amcharts/amcharts4/charts";
import goalIcon from "Assets/_nodeGraphIcons/goal-icon.svg";
import { color } from "@amcharts/amcharts4/core";

function createGoalBulletPoint(imageHref: string) {
    const bullet = new Bullet();

    const image = bullet.createChild(Image);
    image.href = imageHref;
    image.width = 30;
    image.height = 30;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";

    // Make bullet grow on hover
    const bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;

    return bullet;
}

/**
 * Creates an LineSeries, with a SINGLE data point for the goal node.
 * Cannot have multiple data points or a line between the two goal nodes would be created.
 * Moreover, even if we hide the line between the two goal nodes, we cannot add the different goal nodes' names to
 * series.tooltipText within the same series. Thus, we create a single LineSeries for each goal node.
 */
export function createGoalSeries(goalNode: GoalNodeDataObjectSchema) {
    const series = new LineSeries();
    series.id = goalNode.identifier;
    series.dataFields.valueY = "monthly";
    series.dataFields.dateX = "date";
    // eslint-disable-next-line no-template-curly-in-string
    series.tooltipText =
        "[bold]" +
        goalNode.option +
        ":[/] {valueY}\n[bold]Target Day:[/] {dateX}";

    /**
     * None of the tooltip, stroke, propertyFields, fillOpacity, etc. are necessary for a Goal series.
     * This is because a Goal Series consists only for a single bullet point.
     */
    // series.tooltip = new Tooltip();
    // series.tooltip.pointerOrientation = "vertical";
    // series.tooltip.fontSize = "15px"; // Does this need to be dynamic?
    // series.stroke = color("#ffffff")
    // series.strokeWidth = 3; // Does this need to be dynamic?
    // series.minBulletDistance = 15; // Does this need to be dynamic?

    // series.propertyFields.stroke = `color${"#ffffff"}`;
    // series.propertyFields.fill = `color${"#ffffff"}`;
    // series.fillOpacity = 0.75;

    const { monthly, date } = goalNode;

    // This entire series only has one data point. This is so
    series.data = [{ monthly, date }];

    const bullet = createGoalBulletPoint(goalIcon);
    series.bullets.push(bullet);

    return series;
}

export const createGoalRange = (goalData, valueAxis) => {
    const range = valueAxis.axisRanges.create();
    range.value = goalData?.monthly;
    range.endValue = undefined;
    // range.grid.above = true;
    range.grid.stroke = color("#396478");
    range.grid.strokeWidth = 2;
    range.grid.strokeOpacity = 1;
    range.grid.strokeDasharray = "2,4";
    range.label.inside = true;
    range.label.text = goalData?.option;
    range.label.fill = range.grid.stroke;
    range.label.verticalCenter = "bottom";

    return range
}
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

const outboundSalesInputsHandler = (
    value: string,
    id:
        | "customer"
        | "entityName"
        | "value"
        | "cadence"
        | "leadTimeFrequency"
        | "leadTime",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "customer":
            const parsedCustomer = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedCustomer,
                eventId,
                currentEntityObject.id,
                data.customerEventId,
                data.customerIds
            );

            data.selectedCustomer = parsedCustomer;
            data.customerEventId = parsedCustomer?.eventId ?? "";
            data.customerIds = parsedCustomer?.entityIds ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "value":
            if (!isNaN(Number(value))) {
                data.value = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "cadence":
            currentEntityObject.cadence = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "leadTime":
            if (!isNaN(Number(value))) {
                data.leadTime = value;
                currentEntityObject.data = data;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "leadTimeFrequency":
            data.leadTimeFrequency = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        // Noop
    }

    return newEntitiesMap;
};

export default outboundSalesInputsHandler;

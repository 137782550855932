// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import UnitCard from "../Card/unitCard";

// Import Connection Image
// TODO: get connection image
import unitConnectionImage from "Assets/_optionDescriptionIcons/Debit Credit white.jpg";

// Import Coloured PNGs
import unitBlack from "../../Assets/_eventNodeTypes/unit-black.svg";
import unitBlue from "../../Assets/_eventNodeTypes/income-blue.png";
import unitWhite from "../../Assets/_eventNodeTypes/income-white.png";
import unitGrey from "../../Assets/_eventNodeTypes/income-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import UnitInputExperimental from "Components/InputContainer/UnitInputExperimental/UnitInputExperimental";

import { unitInputsHandler } from "Components/InputContainer/OnInputChangeHandlers/unitInputsHandler";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const unitObject = {
    constant: function () {
        return unit_Constant();
    },

    name: function () {
        return "Unit Adjustment";
    },

    checkInput: function (state) {
        return unit_CheckInput(state);
    },

    svg: function () {
        return unit_Svg();
    },

    eventNodeMenu: function (focus) {
        return unit_EventNodeMenu(focus);
    },

    indexText: function () {
        return unit_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return unit_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return unit_OptionDescription();
    },

    colouredPngs: function () {
        return unit_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return unit_SideModalImage();
    },

    inputsHandler: function (
        value,
        id,
        star,
        entitiesMap,
        currentEntity,
        account
    ) {
        return unitInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            account
        );
    },

    generateDisplayFieldData: function () {
        return Unit_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const unit_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.cadence) return false;
        if (!entity.startDate) return false;
        if (!entity.name) return false;
        if (!entity.data.amount) return false;
        if (!entity.data.accountName) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const unit_Constant = () => {
    return "35e82011-86a5-4d92-b824-44e0c0361ada";
};

// EFFECTS: Returns svg for event
const unit_Svg = () => {
    // TODO: get image for unit event
    return unitBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const unit_EventNodeMenu = (focus) => {
    // TODO: implement (component in sidebar showing info)
    return <UnitCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const unit_IndexText = () => {
    // TODO: find where this is being used
    return "Select an input field to learn more about your unit calculations.";
};

// EFFECTS: Returns all icons for event ??
const unit_Icons = () => {
    let pngs = new Map([
        ["black", unitBlack],
        ["blue", unitBlue],
        ["white", unitWhite],
        ["grey", unitGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const debitCredit_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const unit_InputComponentInfoPassing = (minProps) => {
    // TODO: actually do this component
    return <UnitInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const unit_OptionDescription = () => {
    const tags = ["Option"];
    const type = unitObject.name();
    const description =
        "This Event is for adjusting values in non-financial transactions.";
    const usedForText =
        "Use the Unit Adjustment Event to increase or decrease values for non-financial accounts. This could include Employees, Utilization, Inventory, Customer or any non-currency based account. It functions similarly to the Debit/Credit Event.";
    const connection = "";
    const connectionImage = unitConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const unit_SideModalImage = () => {
    // TODO: get image
    return sideImage;
};

const Unit_GenerateDisplayFieldData = () => {
    // TODO: check if this is being used?
    const entityFieldsMap = {
        name: {
            displayName: "Name",
            editable: true,
            rowDrag: true,
            checkboxSelection: true,
        },
        startDate: {
            displayName: "Start Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        endDate: {
            displayName: "End Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
        },
        cadence: {
            displayName: "Frequency",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: [
                "annually",
                "quarterly",
                "monthly",
                "semi-monthly",
                "bi-weekly",
                "weekly",
                "daily",
                "one-time",
            ],
        },
        bypassState: {
            displayName: "Bypassed",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
    };

    const entityDataFieldsMap = {
        increaseOrDecrease: {
            displayName: "Type",
            editable: false,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["increase", "decrease"],
        },
        ongoingOrAmortized: {
            displayName: "Expense Type",
            editable: false,
            conditionalTarget: true,
            cellRenderer: "dropDownCellRenderer",
            cellRendererDropDownOptions: ["amortized", "ongoing"],
        },
        value: {
            displayName: "Amount",
            formatting: {
                type: "numerical",
                displaySign: true,
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "ongoingOrAmortized",
            conditionalFieldConditionValue: "ongoing",
        },
        amount: {
            hide: true,
        },
        accountName: {
            displayName: "Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        accountIds: {
            hide: true,
        },
        contraAccountName: {
            displayName: "Contra Account Name",
            editable: false,
            cellRenderer: "accountWaterfallCellRenderer",
        },
        contraAccountIds: {
            hide: true,
        },
        amortizedValue: {
            displayName: "Amortization Value",
            formatting: {
                type: "numerical",
                displaySign: true,
                sign: "$",
                signLocation: "before",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "ongoingOrAmortized",
            conditionalFieldConditionValue: "amortized",
        },
        amortizationPeriod: {
            displayName: "Amortization Period",
            formatting: {
                type: "numerical",
                displaySign: false,
                sign: "",
                signLocation: "",
                incudeSignSpace: false,
            },
            editable: false,
            conditionalFieldName: "ongoingOrAmortized",
            conditionalFieldConditionValue: "amortized",
        },
    };

    return { entityFieldsMap, entityDataFieldsMap };
};

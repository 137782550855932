import type { AllEventDetailsSchema } from "reducers/typesSchema/eventDetailsSchema";
export const UPSERT_EVENT_DETAILS = "UPSERT_EVENT_DETAILS"
import type { AppThunk } from "store";

export const upsertEventDetails = (
    allEventDetails: AllEventDetailsSchema
): AppThunk<void>  => {
    return (dispatch, getState) => {
        const curEventDetails = getState().eventDetails;

        const newEventDetails: AllEventDetailsSchema = {};
        Object.entries(allEventDetails).forEach(
            ([eventId, eventDetails]) => {
                newEventDetails[eventId] = {
                    ...curEventDetails[eventId],
                    ...eventDetails,
                };
            }
        );

        dispatch({
            type: UPSERT_EVENT_DETAILS,
            payload: newEventDetails,
        });
    }
}
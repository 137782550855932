import type {
    CalculatedThreadsResponseSchema,
    CalculatedThreadsSchema,
} from "reducers/typesSchema/calculatedThreadsSchema";
import type { AppThunk } from "store";
import type { EventManager } from "Events";
import { getThreadsAlias } from "helpers/getThreadsAlias";
import { getRelevantEntities } from "./getNodeEntityActions";

export const RESET_CALCULATED_THREADS =
    "calculatedThreads/RESET_CALCULATED_THREADS";
export const UPSERT_CALCULATED_THREADS =
    "calculatedThreads/UPSERT_CALCULATED_THREADS";

export const resetCalculatedThreads = () => ({
    type: RESET_CALCULATED_THREADS,
});

export function upsertCalculatedThreads(
    upsertThreads: CalculatedThreadsSchema
): AppThunk<void> {
    return (dispatch, getState) => {
        const curCalcThreads = getState().calculatedThreads;

        const newCalcThreads: CalculatedThreadsSchema = {};
        Object.entries(upsertThreads).forEach(([threadId, calcThread]) => {
            newCalcThreads[threadId] = {
                ...curCalcThreads[threadId],
                ...calcThread,
            };
        });

        dispatch({
            type: UPSERT_CALCULATED_THREADS,
            payload: newCalcThreads,
        });
    };
}

export function updateThreadRatings(): AppThunk<void> {
    return (dispatch, getState) => {
        const manager: EventManager = getState().scenario?.manager;

        if (!manager) {
            console.error("cta utr - No manager found");
            return;
        }

        const upsertThreads = {};
        const calculatedThreads = getState().calculatedThreads;

        // Loop through each thread
        Object.keys(calculatedThreads).forEach((threadId) => {
            // Find all nodes in thread
            const nodes = manager.getNodesFromThread(threadId);

            // Find average of all nodes' ratings in thread
            let totalRating = 0;
            let nodeCount = 0;
            nodes.forEach((node) => {
                const entities = getRelevantEntities(node.entities);
                Object.values(entities).forEach((entity) => {
                    if (typeof entity.data.rating === "number") {
                        totalRating += entity.data.rating;
                        nodeCount++;
                    }
                });
            });
            const threadAvgRating = totalRating / nodeCount || 0;

            // Attach to new object (used for upserting)
            upsertThreads[threadId] = {
                rating: threadAvgRating,
            };
        });

        dispatch(upsertCalculatedThreads(upsertThreads));
    };
}

export function parseCalculatedThreadsResponse(
    calculatedThreadsResponse: CalculatedThreadsResponseSchema
): AppThunk<void> {
    return (dispatch) => {
        const calculatedThreads = getThreadsAlias(calculatedThreadsResponse);
        dispatch(upsertCalculatedThreads(calculatedThreads));
        dispatch(updateThreadRatings());
    };
}

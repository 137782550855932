import { ActionsDataSchema } from "reducers/typesSchema/menusTemplatesSchema";
import { AppThunk } from "store";

export const UPSERT_ACTIONS_DATA = "actionsData/UPSERT_ACTIONS_DATA";

export function upsertActionsData(
    actionsData: ActionsDataSchema
): AppThunk<void> {
    return (dispatch, getState) => {
        const curCanvasActionsData: ActionsDataSchema = {
            ...getState().canvasActionsData,
        };

        const newActionsData: ActionsDataSchema = {};
        Object.entries(actionsData).forEach(([actionId, actionData]) => {
            newActionsData[actionId] = {
                ...curCanvasActionsData[actionId],
                ...actionData,
            };
        });

        dispatch({
            type: UPSERT_ACTIONS_DATA,
            payload: newActionsData,
        });
    };
}

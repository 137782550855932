// @ts-nocheck
import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    ToggledRate,
    EventEntityWrapper,
    ModalRowHalf,
    RequiredStar,
    SelectDropDown,
    Value,
    EntityName,
} from "../Components";
import SingleCalendar from "../../Calendar/singleCalendar";
import { propertyValueObject } from "Components/Registry/Property Value";

export default function PropertyValueInputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    onChangeNameDescription,
    onHandleSubmit,
    handleOnChange,
    onHandleDate,
    updateAppreciation,
    passedCheck,
    edit,
    eventData,
}) {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    image={propertyValueObject.svg()}
                    eventType={propertyValueObject.constant()}
                    onChangeInput={onChangeNameDescription}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[propertyValueObject.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                    />
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap[currentEntity].data.house}
                        options={Object.keys(
                            entitiesMap[currentEntity].data.houses
                        )}
                        className="termsContainer"
                        id="house"
                        label="Associated House"
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Value
                            label="Updated Property Value"
                            onChangeInput={handleOnChange}
                            value={
                                entitiesMap[currentEntity].data.propertyValue
                            }
                            id="propertyValue"
                            unit="$"
                        />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <RequiredStar />
                        <ToggledRate
                            rateId="appreciationRate"
                            label="Appreciation Rate"
                            doApply={
                                entitiesMap[currentEntity].data.appreciation
                            }
                            toggleDoApply={updateAppreciation}
                            rate={
                                entitiesMap[currentEntity].data.appreciationRate
                            }
                            onChangeInput={handleOnChange}
                        />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow>
                    <RequiredStar />
                    <SingleCalendar
                        onHandleDate={onHandleDate}
                        date={entitiesMap[currentEntity].startDate}
                        title={"Date"}
                        entityIndex={entityIndex}
                    />
                </ModalRow>
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import React from "react";
import styles from "./index.module.css";

export default class SplashScreen extends React.Component {
    handleLearnMore = () => {
        const url = "https://whatifi.io/";
        window.open(url, "_blank");
    };

    render() {
        return (
            <div className={styles.ModalContainer}>
                <article className={styles.article}>
                    <h2 className={styles.heading}>
                        what<span className={styles.headingHighlight}>if</span>
                        i.io
                    </h2>
                    <p className={styles.copy}>
                        At this time, the{" "}
                        <span className={styles.copyHighlight}>whatifi</span>{" "}
                        Scenario Builder is only available on desktop.
                    </p>
                    <a
                        className={styles.link}
                        href="https://www.whatifi.io/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Main Website
                    </a>
                </article>
            </div>
        );
    }
}

import type { RefObject } from "react";
import Konva from "konva";
import { forwardRef, useContext, useRef, useState } from "react";
import { ReactReduxContext, Provider } from "react-redux";
import { Stage, Layer } from "react-konva";
import CanvasStageStyles from "./CanvasStageStyles.module.css";
import { CanvasStageScaleContext } from "./contexts/CanvasStageScaleContext";
import { CanvasStageZoomCategoryContext } from "./contexts/CanvasStageZoomCategoryContext";
import { CanvasStageWrapperElementDimensions } from "./contexts/CanvasStageWrapperElementDimensions";
import { CanvasLines } from "./partials/CanvasLines";
import { CanvasEvents } from "./partials/CanvasEvents";
import { CanvasAccountCards } from "./partials/CanvasAccountCards";
import { CanvasContextMenu } from "./partials/CanvasContextMenu";
import Menu from "@mui/material/Menu";
// import { CanvasLine } from "./partials/CanvasLines/CanvasLine";

import { updatedFocusedCardId } from "actions/canvasCardActions";
import { focusEvent } from "actions/canvasEventActions";
import { clearSelectedEvents } from "actions/scenario";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";

import {
    FocusedLineIdContext,
    HoveredLineIdContext,
    SetHoveredLineIdContext,
} from "./contexts/CanvasLinesStateContexts";
import {
    FocusedCardIdContext,
    HoveredCardIdContext,
    SetHoveredCardIdContext,
} from "./contexts/CanvasCardsStateContext";
import {
    EventDragActiveContext,
    SetEventDragActiveContext,
    DraggedEventTempXContext,
    SetDraggedEventTempXContext,
    DraggedEventTempYContext,
    SetDraggedEventTempYContext,
} from "./contexts/CanvasEventDragContext";
import { CanvasBaselineContext } from "./contexts/CanvasBaselineContext";
import { CanvasDashboardContext } from "./contexts/CanvasDashboardContext";
import { EventActionIds, eventActionsMap } from "actions/canvasEventActions";
import { StageViewableAreaDimensionsContext } from "./contexts/StageViewableAreaDimensionsContext";
import { getStageNewCenter } from "./helpers/getStageNewCenter";

interface CanvasStageMainProps {
    stageRef: RefObject<Konva.Stage>;
    stagePosition: [number, number];
    setStagePosition: React.Dispatch<React.SetStateAction<[number, number]>>;
    setStageScale: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * Notes:
 *   * We provide context providers here again because react-konva does not receive any new contexts' states outside of the Stage component.
 *   * Same as with redux's provider.
 */
export const CanvasStageMain = forwardRef<HTMLDivElement, CanvasStageMainProps>(
    (
        {
            stageRef,
            stagePosition: [stageX, stageY],
            setStagePosition,
            setStageScale,
        },
        stageWrapperRef
    ) => {
        const dispatch = useAppDispatch();

        const focus = useAppSelector((state) => state?.scenario?.focus);

        const stageContainerCursorRef = useRef("");
        const stageScale = useContext(CanvasStageScaleContext);
        const zoomCategory = useContext(CanvasStageZoomCategoryContext);
        const { width, height } = useContext(
            CanvasStageWrapperElementDimensions
        );
        const viewableAreaDomRect = useContext(
            StageViewableAreaDimensionsContext
        );
        const canvasStageWrapperDomRect = useContext(
            CanvasStageWrapperElementDimensions
        );

        const isBaseline = useContext(CanvasBaselineContext);
        const isDashboard = useContext(CanvasDashboardContext);
        const focusedLine = useContext(FocusedLineIdContext);
        const hoveredLine = useContext(HoveredLineIdContext);
        const setHoveredLine = useContext(SetHoveredLineIdContext);

        const eventDragActive = useContext(EventDragActiveContext);
        const setEventDragActive = useContext(SetEventDragActiveContext);
        const draggedEventTempX = useContext(DraggedEventTempXContext);
        const setDraggedEventTempX = useContext(SetDraggedEventTempXContext);
        const draggedEventTempY = useContext(DraggedEventTempYContext);
        const setDraggedEventTempY = useContext(SetDraggedEventTempYContext);

        const focusedCardId = useContext(FocusedCardIdContext);
        const hoveredCardId = useContext(HoveredCardIdContext);
        const setHoveredCardId = useContext(SetHoveredCardIdContext);

        const [contextMenu, setContextMenu] = useState<{
            mouseX: number;
            mouseY: number;
        } | null>(null);

        const [isDragging, setIsDragging] = useState(false);

        const onDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
            const container = e.target.getStage()?.container();

            if (pushedMouseButton !== "middle" && pushedKey !== "Space") {
                if (container) {
                    container.style.cursor = stageContainerCursorRef.current;
                    stageContainerCursorRef.current = "";
                }
                e.target.getStage()?.stopDrag();
                return;
            }
            setIsDragging(true);
            if (container) {
                stageContainerCursorRef.current = container.style.cursor;
                container.style.cursor = "grabbing";
            }
        };

        const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
            if (isDragging) {
                const x = e?.target?._lastPos?.x;
                const y = e?.target?._lastPos?.y;
                setStagePosition([x, y]);
            }
            setIsDragging(false);
            const container = e.target.getStage()?.container();
            if (container) {
                container.style.cursor = stageContainerCursorRef.current;
                stageContainerCursorRef.current = "";
            }
        };

        const [pushedKey, setPushedKey] = useState("");
        const [pushedMouseButton, setPushedMouseButton] = useState("");

        const ableToDrag = () => {
            if (hoveredCardId) {
                return false;
            } else {
                return true;
            }
        };

        const onStageClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
            const emptySpace = e.target === e.target.getStage();
            if (emptySpace) {
                dispatch(updatedFocusedCardId(null));
                dispatch(focusEvent(null));
                dispatch(clearSelectedEvents());
            }
        };

        const onContextMenu = (e: Konva.KonvaEventObject<MouseEvent>) => {
            e.evt.preventDefault();
            const emptySpace = e.target === e.target.getStage();
            if (emptySpace) {
                setContextMenu(
                    contextMenu === null
                        ? {
                              mouseX: e.evt.clientX + 2,
                              mouseY: e.evt.clientY - 6,
                          }
                        : null
                );
            }
        };

        const onKeyDown = (e) => {
            if (e.code === "Space") {
                if (ableToDrag()) setPushedKey("Space");
            } else if (e.code === "KeyC" && (e.metaKey || e.ctrlKey) && focus) {
                dispatch(
                    eventActionsMap?.[EventActionIds?.copyEvent](
                        focus?.id,
                        isBaseline
                    )
                );
            } else if (e.code === "KeyV" && (e.metaKey || e.ctrlKey) && focus) {
                dispatch(
                    eventActionsMap?.[EventActionIds?.pasteEvent](
                        focus?.id,
                        isBaseline
                    )
                );
            }
        };

        const onKeyUp = (e) => {
            if (e.code === "Space") setPushedKey("");
        };

        const onMouseDown = (e) => {
            if (e.evt.button === 1) {
                if (ableToDrag()) setPushedMouseButton("middle");
            }
        };

        const onMouseUp = (e) => {
            if (e.evt.button === 1) setPushedMouseButton("");
        };

        const onWheel = (e) => {
            e.evt.preventDefault();

            if (isDragging) return;

            const dx = e.evt.deltaX;
            const dy = e.evt.deltaY;

            if (e?.evt?.metaKey || e?.evt?.ctrlKey) {
                const SCALE_BY_FACTOR = 1.1;
                const MAX_SCALE = 1.5;
                const MIN_SCALE = 0.25;
                const stage = stageRef.current as Konva.Stage;

                let newScale = 0;

                //zoom in
                if (dy > 0) {
                    newScale = Math.max(
                        MIN_SCALE,
                        stageScale / SCALE_BY_FACTOR
                    );
                }
                //zoom out
                if (dy < 0) {
                    newScale = Math.min(
                        MAX_SCALE,
                        stageScale * SCALE_BY_FACTOR
                    );
                }

                /**
                 * Represents how far from the left we need to offset.
                 * While canvasStageWrapperDomRect.left is 0 all the time currently, that is not guaranteed in the future.
                 */
                const leftOffset =
                    (viewableAreaDomRect?.left ?? 0) -
                    (canvasStageWrapperDomRect?.left ?? 0);
                /**
                 * Represents how far from the top we need to offset.
                 * While the resulting value is always 0 currently, that is not guaranteed in the future.
                 */
                const topOffset =
                    (viewableAreaDomRect?.top ?? 0) -
                    (canvasStageWrapperDomRect?.top ?? 0);
                const newPos = getStageNewCenter(
                    viewableAreaDomRect?.width,
                    viewableAreaDomRect?.height,
                    stage?.x?.(),
                    stage?.y?.(),
                    stageScale,
                    newScale,
                    leftOffset,
                    topOffset
                );

                setStageScale(newScale);
                if (!isNaN(newPos.x) && !isNaN(newPos.y))
                    setStagePosition([newPos.x, newPos.y]);
                return;
            }

            setStagePosition([stageX - dx, stageY - dy]);
        };

        Konva.dragButtons = [0, 1];

        return (
            <div
                ref={stageWrapperRef}
                className={
                    pushedKey === "Space" || pushedMouseButton === "middle"
                        ? `${CanvasStageStyles.StageWrapper} ${CanvasStageStyles.StageWrapperDrag}`
                        : CanvasStageStyles.StageWrapper
                }
                onKeyDown={(e) => onKeyDown(e)}
                onKeyUp={(e) => onKeyUp(e)}
                tabIndex={0}
            >
                <Menu
                    open={contextMenu !== null}
                    onClose={() => setContextMenu(null)}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? {
                                  top: contextMenu.mouseY,
                                  left: contextMenu.mouseX,
                              }
                            : undefined
                    }
                >
                    <CanvasContextMenu onClose={setContextMenu} />
                </Menu>
                {width && height && (
                    <ReactReduxContext.Consumer>
                        {({ store }) => (
                            <Stage
                                ref={stageRef}
                                width={width}
                                height={height}
                                draggable={true}
                                x={stageX}
                                y={stageY}
                                scaleX={stageScale}
                                scaleY={stageScale}
                                onDragStart={onDragStart}
                                onDragEnd={onDragEnd}
                                onClick={onStageClick}
                                onContextMenu={onContextMenu}
                                onMouseDown={onMouseDown}
                                onMouseUp={onMouseUp}
                                onWheel={onWheel}
                            >
                                <Provider store={store}>
                                    <CanvasBaselineContext.Provider
                                        value={isBaseline}
                                    >
                                        <CanvasDashboardContext.Provider
                                            value={isDashboard}
                                        >
                                            <SetHoveredLineIdContext.Provider
                                                value={setHoveredLine}
                                            >
                                                <HoveredLineIdContext.Provider
                                                    value={hoveredLine}
                                                >
                                                    <SetHoveredCardIdContext.Provider
                                                        value={setHoveredCardId}
                                                    >
                                                        <HoveredCardIdContext.Provider
                                                            value={
                                                                hoveredCardId
                                                            }
                                                        >
                                                            <FocusedLineIdContext.Provider
                                                                value={
                                                                    focusedLine
                                                                }
                                                            >
                                                                <FocusedCardIdContext.Provider
                                                                    value={
                                                                        focusedCardId
                                                                    }
                                                                >
                                                                    <EventDragActiveContext.Provider
                                                                        value={
                                                                            eventDragActive
                                                                        }
                                                                    >
                                                                        <SetEventDragActiveContext.Provider
                                                                            value={
                                                                                setEventDragActive
                                                                            }
                                                                        >
                                                                            <DraggedEventTempXContext.Provider
                                                                                value={
                                                                                    draggedEventTempX
                                                                                }
                                                                            >
                                                                                <SetDraggedEventTempXContext.Provider
                                                                                    value={
                                                                                        setDraggedEventTempX
                                                                                    }
                                                                                >
                                                                                    <DraggedEventTempYContext.Provider
                                                                                        value={
                                                                                            draggedEventTempY
                                                                                        }
                                                                                    >
                                                                                        <SetDraggedEventTempYContext.Provider
                                                                                            value={
                                                                                                setDraggedEventTempY
                                                                                            }
                                                                                        >
                                                                                            <CanvasStageScaleContext.Provider
                                                                                                value={
                                                                                                    stageScale
                                                                                                }
                                                                                            >
                                                                                                <CanvasStageZoomCategoryContext.Provider
                                                                                                    value={
                                                                                                        zoomCategory
                                                                                                    }
                                                                                                >
                                                                                                    <Layer name="container-layer" />
                                                                                                    <Layer name="bottom-layer" />
                                                                                                    <Layer>
                                                                                                        <CanvasLines />
                                                                                                        <CanvasEvents />
                                                                                                    </Layer>
                                                                                                    <Layer>
                                                                                                        <CanvasAccountCards />
                                                                                                    </Layer>
                                                                                                    <Layer name="top-layer" />
                                                                                                </CanvasStageZoomCategoryContext.Provider>
                                                                                            </CanvasStageScaleContext.Provider>
                                                                                        </SetDraggedEventTempYContext.Provider>
                                                                                    </DraggedEventTempYContext.Provider>
                                                                                </SetDraggedEventTempXContext.Provider>
                                                                            </DraggedEventTempXContext.Provider>
                                                                        </SetEventDragActiveContext.Provider>
                                                                    </EventDragActiveContext.Provider>
                                                                </FocusedCardIdContext.Provider>
                                                            </FocusedLineIdContext.Provider>
                                                        </HoveredCardIdContext.Provider>
                                                    </SetHoveredCardIdContext.Provider>
                                                </HoveredLineIdContext.Provider>
                                            </SetHoveredLineIdContext.Provider>
                                        </CanvasDashboardContext.Provider>
                                    </CanvasBaselineContext.Provider>
                                </Provider>
                            </Stage>
                        )}
                    </ReactReduxContext.Consumer>
                )}
            </div>
        );
    }
);
CanvasStageMain.displayName = "CanvasStageMain";

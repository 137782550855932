import { combineReducers } from "redux";
import accountFieldMapping from "./accountFieldMappingReducer";
import accountMapping from "./accountMappingReducer";
import allAccountLedgers from "./allAccountLedgersReducer";
import auth from "./auth";
import calculatedThreads from "./calculatedThreadsReducer";
import chartGraph from "./chartGraphReducer";
import chartGraphGroupNodesMode from "./chartGraphGroupNodesModeReducer";
import containerCreation from "./containerCreationReducers";
import entities from "./entitiesReducer";
import events from "./eventsReducer";
import eventsTemplates from "./eventsTemplatesReducer";
import graphAccountSelection from "./graphAccountSelectionReducer";
import loadingStates from "./loadingStatesReducer";
import menusTemplates from "./menusTemplatesReducer";
import canvasActionsData from "./canvasActionsDataReducer";
import modifiers from "./modifiersReducer";
import scenario from "./scenario";
import scenarioViewSelectedThreads from "./scenarioViewSelectedThreadsReducer";
import threadsList from "./threadsListReducer";
import onboardingModal from "./onboardingModalReducer";
import customAccounts from "./customAccountsReducer";
import accountPicker from "./accountPickerReducer";
import canvasCardModals from "./canvasCardModalsReducer";
import cardTemplates from "./canvasCardTemplateReducer";
import canvasCards from "./canvasCardReducer";
import eventDetails from "./eventDetailsReducer";

export const reducers = combineReducers({
    accountFieldMapping,
    accountMapping,
    accountPicker,
    allAccountLedgers,
    auth,
    calculatedThreads,
    canvasCards,
    canvasCardModals,
    cardTemplates,
    chartGraph,
    chartGraphGroupNodesMode,
    containerCreation,
    customAccounts,
    entities,
    events,
    eventDetails,
    eventsTemplates,
    graphAccountSelection,
    loadingStates,
    menusTemplates,
    canvasActionsData,
    modifiers,
    scenario,
    scenarioViewSelectedThreads,
    threadsList,
    onboardingModal,
});

// Default Imports
import React from "react";

// Import Grant  Card and Grant  Input
import GrantCard from "../Card/grantCard";
import GrantInput from "../InputContainer/GrantInput";

// Import Connection Image
import grantConnectionImage from "../../Assets/_optionDescriptionIcons/Grant, Tuition, Rent.png";

// Import Coloured PNGs
import grantBlack from "../../Assets/_eventNodeTypes/grant-black.png";
import grantBlue from "../../Assets/_eventNodeTypes/grant-blue.png";
import grantWhite from "../../Assets/_eventNodeTypes/grant-white.png";
import grantGrey from "../../Assets/_eventNodeTypes/grant-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - grantObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - grantObject.checkInput.call(grantObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Grant
export const grantObject = {
    constant: function () {
        return Grant_Constant();
    },

    checkInput: function (state) {
        return Grant_CheckInput(state);
    },

    svg: function () {
        return Grant_Svg();
    },

    eventNodeMenu: function (focus) {
        return Grant_EventNodeMenu(focus);
    },

    indexText: function () {
        return Grant_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Grant_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Grant_OptionDescription();
    },

    colouredPngs: function () {
        return Grant_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Grant_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Grant

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Grant_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.value) return false;
    if (!state.cadence) return false;
    if (!state.start) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const Grant_Constant = () => {
    return "Grant";
};

// EFFECTS: Returns svg for event
const Grant_Svg = () => {
    return grantBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Grant_EventNodeMenu = (focus) => {
    return <GrantCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Grant_IndexText = () => {
    return "Many students are eligible for non-repayable grants, bursaries, scholarships, and other awards. These monetary contributinos are non-taxable and can help any student throughout their undergraduate career.";
};

// EFFECTS: Returns all icons for event ??
const Grant_Icons = () => {
    let pngs = new Map([
        ["black", grantBlack],
        ["blue", grantBlue],
        ["white", grantWhite],
        ["grey", grantGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Grant_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Grant_InputComponentInfoPassing = (minProps) => {
    return <GrantInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Grant_OptionDescription = () => {
    const tags = ["Option"];
    const type = grantObject.constant();
    const description =
        "Often times, students are eligible for scholarships, awards, and bursaries. Students can apply to these through their schools, through financial aid, or throughout the community.";
    const usedForText =
        "The Grant Event can be used to recognize a non-taxable income for students.";
    const connection = "";
    const connectionImage = grantConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/services/benefits/education/student-aid/grants-loans.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Grant_SideModalImage = () => {
    return sideImage;
};

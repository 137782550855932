// we're setting these to empty because when the user updates the event, it checks each entity if it's empty and creates the custom accounts for the empty ones
export const duplicateCustomer2Entity = (duplicatedEntity) => {
    duplicatedEntity.data.accountIds = [];
    duplicatedEntity.data.storedAccountName = "";
    duplicatedEntity.data.parentAccountId = "";
    duplicatedEntity.data.grownAccountId = "";
    duplicatedEntity.data.churnedAccountId = "";
    duplicatedEntity.data.transferredInAccountId = "";
    duplicatedEntity.data.transferredOutAccountId = "";
};

export const duplicateWebsiteVisitorEntity = (duplicatedEntity) => {
    duplicatedEntity.data.accountIds = [];
    duplicatedEntity.data.storedAccountName = "";
    duplicatedEntity.data.parentAccountId = "";
    duplicatedEntity.data.organicAccountId = "";
    duplicatedEntity.data.paidAccountId = "";
};

export const duplicateEntityAccount = (duplicatedEntity) => {
    duplicatedEntity.data.accountStructure = null;
};

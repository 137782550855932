// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    RequiredStar,
    EntityName,
    GenericButton,
    ModalRowHalf,
    SingleEntity,
} from "../Components";
import { accountImportObject } from "Components/Registry/AccountImport";
import { useEffect, useRef } from "react";
import RailzConnect from "@railzai/railz-connect";

export default function AccountImportInputView({
    entitiesMap,
    currentEntity,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    onConnectService,
    refreshImportedAccounts,
}) {
    const railzWidgetRef = useRef();

    useEffect(() => {
        const widget = new RailzConnect();
        widget.mount({
            parentElement: railzWidgetRef.current,
            widgetId: process.env.REACT_APP_RAILZ_WIDGET_ID,
            onSuccess: (event, data) => {
                if (event == "authorization") {
                    onConnectService(data);
                }
            },
            options: {
                services: {
                    exclude: [
                        "shopify",
                        "square",
                        "plaid",
                        "wave",
                        "xero",
                        "sageIntacct",
                        "sageBusinessCloud",
                        "oracleNetsuite",
                        "dynamicsBusinessCentral",
                        "freshbooks",
                    ],
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const importState = entitiesMap[currentEntity].data.importState;

    const serviceName = entitiesMap[currentEntity].data.serviceName;
    const serviceNameDisplay =
        serviceName.charAt(0).toUpperCase() + serviceName.slice(1);

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={accountImportObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={accountImportObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>

                {eventData.id && importState == "connect" && (
                    <>
                        <div id="railz-connect" ref={railzWidgetRef}></div>
                    </>
                )}

                {!eventData.id && (
                    <ModalRow>
                        <RequiredStar />
                        <div>{`This event is in beta. Please click "Fill & Add" to access account import`}</div>
                    </ModalRow>
                )}

                {importState == "connected" && (
                    <>
                        <ModalRow>
                            {`Connected Accounting Service: ${serviceNameDisplay}`}
                        </ModalRow>
                        <ModalRow>
                            <ModalRowHalf>
                                <GenericButton
                                    label="Import Accounts"
                                    onClick={refreshImportedAccounts}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                    </>
                )}

                {importState == "imported" && (
                    <>
                        <ModalRow>
                            <GenericButton
                                label="Refresh Imported Accounts"
                                onClick={refreshImportedAccounts}
                            />
                            {entitiesMap?.[currentEntity]?.data
                                ?.lastUpdated && (
                                <ModalRow>
                                    Last Updated:{" "}
                                    {entitiesMap?.[currentEntity]?.data
                                        ?.lastUpdated || ""}
                                </ModalRow>
                            )}
                        </ModalRow>
                    </>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

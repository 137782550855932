import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { ThreadsList } from "./ThreadsList";
import { ZoomedThread } from "./ZoomedThread";

export function ThreadsListController() {
    const { scenarioIsArchived, zoomedThreadId } = useAppSelector((state) => {
        const zoomedThreadId = state.threadsList.zoomedThreadId;
        const scenarioIsArchived = state?.scenario?.loadedScenario?.is_archived;

        return {
            scenarioIsArchived,
            zoomedThreadId,
        };
    }, shallowEqual);

    if (scenarioIsArchived) {
        return <div>This scenario is archived</div>;
    }

    if (zoomedThreadId) {
        return <ZoomedThread threadId={zoomedThreadId} />;
    }

    return <ThreadsList />;
}

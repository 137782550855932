import React, { Component } from "react";
import "./InputContainer.css";
import Switch from "react-switch";
import Calendar from "../Calendar";
import { throwError } from "../../helpers/swalError";
import _ from "lodash";

import {
    Description,
    SelectDropDown,
    SelectDropDownPerson,
    Value,
    Url,
    InputButtons,
    EventInputModal,
    EventInputs,
    EventHeader,
    ModalRow,
    Rate,
    RequiredStar,
    ModalRowHalf,
    StarsRating,
    InflationSwitch,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { getPresentableDependenciesOfManyTypes } from "../../helpers/nodeDependencyDetectionHelpers";
import { Me, Person } from "../../helpers/constants";
import { Student_IncomeObject } from "../Registry/Student Income";

const options = [
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "per-term",
    "weekly",
    "one-time",
];

class Student_IncomeInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            url: "",
            start: null,
            end: null,
            hours: null,
            wage: null,
            rating: 0,
            income: 0,
            showOptions: false,
            name: getDefaultName(Student_IncomeObject.constant(), this.props),
            description: "",
            cadence: "",
            type: Student_IncomeObject.constant(),
            value: null,
            showSettings: false,
            tag: `@${Student_IncomeObject.constant()}`,
            onHoverStar: null,
            inflation: false,
            baseline: false,
            people: this.getPeople(props),
            personId: "",
            personName: "",
            disableUponGraduation: false,
        };
    }

    getPeople = (props = this.props) => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            props,
            "Person"
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        return people;
    };

    componentDidMount() {
        if (this.props.edit) {
            this.setState({ ...this.props.editData, people: this.getPeople() });
        }

        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    updateCadencedValue = (string) => {
        this.setState({ value: Number(this.getCadencedIncome(string)) });
    };

    onToggleCheckBox = () => {
        this.setState({
            disableUponGraduation:
                !this.state.disableOndisableUponGraduationRetirement,
        });
    };

    onChangeInput = (e, id, star) => {
        const value = e.target.value;

        switch (id) {
            case "url":
                this.setState({ url: value });
                break;
            case "hours":
                if (value === "") {
                    this.setState({
                        hours: "",
                        income: 0,
                    });
                } else {
                    this.setState({
                        hours: Number(value),
                        income: Number(value) * this.state.wage,
                        value:
                            Number(value) *
                            this.state.wage *
                            this.getMultiplier(this.state.cadence),
                    });
                }
                break;
            case "wage":
                if (value === "") {
                    this.setState({
                        wage: "",
                        income: 0,
                    });
                } else {
                    this.setState({
                        wage: Number(value),
                        income: Number(value) * this.state.hours,
                        value:
                            Number(value) *
                            this.state.hours *
                            this.getMultiplier(this.state.cadence),
                    });
                }
                break;
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "cadence":
                if (value === "") {
                    this.setState({ cadence: "" });
                } else {
                    this.setState({ cadence: value });
                }
                this.updateCadencedValue(value);
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "person":
                if (value === "Select Person" || !value) {
                    this.setState({ personName: "", personId: null });
                } else {
                    this.setState({
                        personName: value,
                        personId: this.state.people[value],
                    });
                }
                break;
            default:
        }
    };

    onHandleDate = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    onHandleSubmitValues = () => {
        const passedCheck = Student_IncomeObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    onHandleStarHover = (i) => {
        this.setState({ onHoverStar: i });
    };

    onMouseLeave = () => {
        this.setState({ onHoverStar: null });
    };

    onToggleCheckBox = () => {
        this.setState({
            disableUponGraduation: !this.state.disableUponGraduation,
        });
    };
    // scrollToBottom = () => {
    //     this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // };

    getCadencedIncome = (value) => {
        switch (value) {
            case "annually":
                return this.state.income * 52;
            case "monthly":
                return Math.round(this.state.income * 4.28);
            case "semi-monthly":
                return Math.round(this.state.income * 2.14);
            case "bi-weekly":
                return this.state.income * 2;
            case "weekly":
                return this.state.income;
            default:
                return this.state.income;
        }
    };

    getMultiplier = (value) => {
        switch (value) {
            case "annually":
                return 52;
            case "monthly":
                return 4.28;
            case "semi-monthly":
                return 2.14;
            case "bi-weekly":
                return 2;
            default:
                return 1;
        }
    };

    render() {
        const passedCheck = Student_IncomeObject.checkInput(this.state);
        const { edit } = this.props;

        const {
            name,
            cadence,
            description,
            personName,
            url,
            disableUponGraduation,
            rating,
            start,
            end,
            onHoverStar,
            hours,
            wage,
        } = this.state;

        return (
            <EventInputModal>
                <EventInputs>
                    <EventHeader
                        name={name}
                        eventType={Student_IncomeObject.constant()}
                        onChangeInput={this.onChangeInput}
                        image={Student_IncomeObject.svg()}
                    />
                    <ModalRow>
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={cadence}
                            options={options}
                            className="select-dropdown"
                            id="cadence"
                            label="Payroll Frequency"
                        />
                    </ModalRow>
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Rate
                                label={`weekly hours`}
                                onChangeInput={this.onChangeInput}
                                value={hours}
                                className="mlsInput"
                                id="hours"
                                sign={false}
                            />
                        </ModalRowHalf>

                        <ModalRowHalf>
                            <RequiredStar />
                            <Value
                                label="Hourly Wage"
                                onChangeInput={this.onChangeInput}
                                value={wage}
                                id="wage"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                    <ModalRow>
                        Your {this.state.cadence} income will be{" "}
                        {this.state.value}
                    </ModalRow>
                    <ModalRow>
                        <RequiredStar />
                        <SelectDropDownPerson
                            onChangeInput={this.onChangeInput}
                            value={personName}
                            options={Object.keys(this.state.people)}
                            className="IncomeSelect"
                            id="person"
                            label="Select Person"
                        />
                    </ModalRow>
                    <ModalRow>
                        <Url
                            value={url}
                            className="UrlLink"
                            onChangeInput={this.onChangeInput}
                            id="url"
                            label="URL"
                        />
                    </ModalRow>
                    <ModalRow>
                        <Switch
                            id="disableUponGraduation"
                            checked={disableUponGraduation}
                            className="InflationToggle"
                            height={20}
                            width={40}
                            onChange={this.onToggleCheckBox}
                        />
                        <div className="incomeText">
                            Disable Hourly Income Upon Graduation
                        </div>
                    </ModalRow>

                    <ModalRow>
                        <RequiredStar />
                        <Calendar
                            onHandleDate={this.onHandleDate}
                            startDate={start}
                            endDate={end || null}
                            cadence={cadence}
                        />
                    </ModalRow>
                    <ModalRow>
                        <StarsRating
                            onHoverStar={onHoverStar}
                            onHandleStarHover={this.onHandleStarHover}
                            rating={rating}
                            onChangeInput={this.onChangeInput}
                            onMouseLeave={this.onMouseLeave}
                        />
                    </ModalRow>

                    <ModalRow>
                        <InflationSwitch
                            applyInflation={this.state.inflation}
                            toggleInflation={this.updateInflation}
                        />
                    </ModalRow>
                    {this.state.showRequired && (
                        <ModalRow>
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </ModalRow>
                    )}
                </EventInputs>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </EventInputModal>
        );
    }
}

export default Student_IncomeInput;

import type { ZoomStateEnum } from "reducers/typesSchema/eventsTemplatesSchema";
import { useMemo } from "react";

export function useZoomCategory(stageScale: number) {
    const zoomCategory = useMemo<ZoomStateEnum>(
        function getZoomCategory() {
            let _zoomCategory: ZoomStateEnum = "zoom-xs";
            if (stageScale >= 1.25) _zoomCategory = "zoom-xl";
            else if (stageScale >= 1) _zoomCategory = "zoom-l";
            else if (stageScale >= 0.75) _zoomCategory = "zoom-m";
            else if (stageScale >= 0.5) _zoomCategory = "zoom-s";

            return _zoomCategory;
        },
        [stageScale]
    );

    return zoomCategory;
}

// Default Imports
import React from "react";

// Import Rental Investment Card and Rental Investment Input
import InvestmentCard from "../Card/investmentCard.js";
import InvestmentInput from "../InputContainer/Investment.js";

// Import Connection Image (if exists)
import InvestmentConnectionImage from "../../Assets/_optionDescriptionIcons/RRSP, Investments, Capital Gains.png";

// Import Coloured PNGs
import investmentBlack from "../../Assets/_eventNodeTypes/investment-black.png";
import investmentBlue from "../../Assets/_eventNodeTypes/investment-blue.png";
import investmentWhite from "../../Assets/_eventNodeTypes/investment-white.png";
import investmentGrey from "../../Assets/_eventNodeTypes/investment-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/WealthManagement2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - investmentObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - investmentObject.checkInput.call(investmentObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Investment
export const investmentObject = {
    constant: function () {
        return Investment_Constant();
    },

    checkInput: function (state) {
        return Investment_CheckInput(state);
    },

    svg: function () {
        return Investment_Svg();
    },

    eventNodeMenu: function (focus) {
        return Investment_EventNodeMenu(focus);
    },

    indexText: function () {
        return Investment_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Investment_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Investment_OptionDescription();
    },

    colouredPngs: function () {
        return Investment_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Investment_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Investment

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Investment_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.subType) return false;
    if (!state.cadence) return false;
    if (
        state.value === undefined ||
        state.value === "" ||
        state.value < 0 ||
        state.value === null ||
        isNaN(state.value)
    )
        return false;
    if (!state.personName || !state.personId) return false;
    if (
        state.initialValue === undefined ||
        state.initialValue === "" ||
        state.initialValue < 0 ||
        state.initialValue === null ||
        isNaN(state.initialValue)
    )
        return false;
    if (!state.start) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const Investment_Constant = () => {
    return "Investment";
};

// EFFECTS: Returns svg for event
const Investment_Svg = () => {
    return investmentBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Investment_EventNodeMenu = (focus) => {
    return <InvestmentCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Investment_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Investment calculations.";
};

// EFFECTS: Returns all icons for event ??
const Investment_Icons = () => {
    let pngs = new Map([
        ["black", investmentBlack],
        ["blue", investmentBlue],
        ["white", investmentWhite],
        ["grey", investmentGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Investment_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Investment_InputComponentInfoPassing = (minProps) => {
    return <InvestmentInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Investment_OptionDescription = () => {
    const tags = ["Option"];
    const type = investmentObject.constant();
    const description =
        "Investments are assets obtained with the intent of generating a positive income or appreciating in value over time. Investments can take on many forms from Properties to Equities to Savings.";
    const usedForText =
        "Use the Investment Event to capture your investment related assets into your scenario and project future appreciation and growth.";
    const connection = "";
    const connectionImage = InvestmentConnectionImage;
    const learnMoreLink = "https://www.investopedia.com/terms/i/investment.asp";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Investment_SideModalImage = () => {
    return sideImage;
};

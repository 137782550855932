// Default Imports
import React from "react";

// Import Rental Income Card and Rental Income Input
import ProjectCard from "../Card/projectCard";

// Import Connection Image
import ProjectConnectionImage from "Assets/_optionDescriptionIcons/Instance, Project white.jpg";

// Import Coloured PNGs
import projectDarkGrey from "../../Assets/_eventNodeTypes/projectSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import ProjectInputExperimental from "Components/InputContainer/ProjectInputExperimental/ProjectInputExperimental";

// Notes
// To call an object with no parameters, can simply use
//  - debitCreditObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - debitCreditObject.checkInput.call(debitCreditObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for devbit credit
export const projectObject = {
    constant: function () {
        return project_Constant();
    },

    name: function () {
        return "Project";
    },

    checkInput: function (state) {
        return project_CheckInput(state);
    },

    svg: function () {
        return project_Svg();
    },

    eventNodeMenu: function (focus) {
        return project_EventNodeMenu(focus);
    },

    indexText: function () {
        return project_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return project_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return project_OptionDescription();
    },

    colouredPngs: function () {
        return project_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return project_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Project_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Rental Income

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const project_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;

        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const project_Constant = () => {
    return "4df84c50-e2ba-11ec-8fea-0242ac120002";
};

// EFFECTS: Returns svg for event
const project_Svg = () => {
    return projectDarkGrey;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const project_EventNodeMenu = (focus) => {
    return <ProjectCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const project_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const project_Icons = () => {
    let pngs = new Map([
        ["black", projectDarkGrey],
        ["blue", projectDarkGrey],
        ["white", projectDarkGrey],
        ["grey", projectDarkGrey],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const project_InputComponentInfoPassing = (minProps) => {
    return <ProjectInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const project_OptionDescription = () => {
    const tags = ["Option"];
    const type = projectObject.name();
    const description =
        "The Project Event is an organizational Event that can be used to group together records and events.";
    const usedForText =
        "Use this Event to group records and events. A date offset can be set for each record or event.";
    const connection = "";
    const connectionImage = ProjectConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const project_SideModalImage = () => {
    return sideImage;
};

const Project_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import type {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import { handleDependencies } from "helpers/updateDependencyMap";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import modifierEntityMap from "helpers/modifierEntityMap.json";
import moment from "moment";

/*
 * Helper function that handles Entity inputs. The expected caller is the onChange fn for each input.
 *
 * @params
 * id - input field name
 * star - rating
 * entitiesMap - object of entities for the event
 * currentEntity - id of current entity card
 */

export const constraintInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "eventType"
        | "event"
        | "entity"
        | "property"
        | "minValue"
        | "maxValue"
        | "startDate"
        | "endDate",
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    eventId: string,
    dependencyMap: DependencyMapSchema
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            let startDateString = "";
            if (value) {
                const startMoment = moment(value);
                startDateString = startMoment
                    .set("date", 1)
                    .format("YYYY-MM-DD");
            }
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            let endDateString = "";
            if (value) {
                const endMoment = moment(value);
                endDateString = endMoment.set("date", 1).format("YYYY-MM-DD");
            }
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "entity":
            const parsedEvent = JSON.parse(
                value || '{"eventId": "", "entityIds": []}'
            );

            handleDependencies(
                dependencyMap,
                parsedEvent,
                eventId,
                currentEntityObject.id,
                data.selectedEventId,
                data.selectedIds
            );

            data.selectedEvent = parsedEvent;
            data.selectedIds = parsedEvent?.entityIds ?? [];

            const properties: string[] = [];
            if (parsedEvent?.eventId && parsedEvent?.entityIds?.length === 0) {
                const event = getEvent(parsedEvent?.eventId);
                if (event) {
                    const entities = event.entities.map((entity) => entity.id);
                    const entity =
                        getRelevantEntities(entities)?.[entities[0]] ?? {};

                    data.entityType = entity?.type ?? "";
                    getProperties(properties, entity, data.entityType);
                }
            } else {
                const entity =
                    getRelevantEntities(parsedEvent.entityIds)?.[
                        parsedEvent.entityIds[0]
                    ] ?? {};

                data.entityType = entity?.type ?? "";
                getProperties(properties, entity, data.entityType);
            }
            data.properties = properties;

            // If entity is changed we reset property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "eventType":
            data.selectedEventType = value ?? "";
            const availableEvents: {}[] = [];
            data.eventsMappedByType[value]?.forEach((eventId) => {
                const event = getEvent(eventId);
                if (event) {
                    availableEvents.push({
                        value: event.id,
                        displayName: event.name,
                    });
                }
            });

            // If eventType is changed we reset event, entity, property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            data.availableEvents = availableEvents;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "event":
            const selectedEventId = value ?? "";
            data.selectedEventId = selectedEventId;
            const event = getEvent(selectedEventId);
            if (event) {
                data.selectedEventName = event?.name;
            } else {
                data.selectedEventName = "";
            }

            // If event is changed we reset entity, property, function, and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "property":
            data.property = value;
            const valueKey = value?.split(" - ")?.[0];

            const decisionEngineName =
                modifierEntityMap?.[data.entityType]?.[valueKey]?.["deName"] ??
                "";
            data.deName = decisionEngineName;

            // If property is changed we reset function and value
            resetFields(id, data);
            currentEntityObject.startDate = "";
            currentEntityObject.endDate = "";

            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "minValue":
            data.minValue = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "maxValue":
            data.maxValue = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }

    return newEntitiesMap;
};

const resetFields = (changedField: string, data: { [x: string]: any }) => {
    if (
        changedField === "property" ||
        changedField === "entity" ||
        changedField === "event" ||
        changedField === "eventType"
    ) {
        data.function = "";
        data.feFunctionDisplayName = "";
        data.minValue = "";
        data.maxValue = "";
        data.customEffectPeriod = false;
        data.revertValue = false;
    }

    if (
        changedField === "entity" ||
        changedField === "event" ||
        changedField === "eventType"
    ) {
        data.property = "";
        data.modifierType = "";
        data.deName = "";
    }

    if (changedField === "event" || changedField === "eventType") {
        data.selectedEvent = {};
        data.selectedIds = [];
        data.entityType = "";
    }

    if (changedField === "eventType") {
        data.selectedEventId = "";
        data.selectedEventName = "";
    }
};

const formatNumericType = (value: number, unit: string): string => {
    switch (unit) {
        case "$":
            return new Intl.NumberFormat(undefined, {
                currency: "USD",
                style: "currency",
            }).format(value);
        default:
            return value.toString();
    }
};

const getProperties = (
    properties: string[],
    targetEntity: EntitySchema,
    entityType: string
) => {
    for (const property in modifierEntityMap?.[entityType]) {
        const propertyObject =
            modifierEntityMap?.[entityType]?.[property] ?? {};

        if (propertyObject?.type !== "numerical") continue;

        const propertyType = propertyObject?.feName;

        const numberValue = Number(targetEntity?.data?.[propertyType]) ?? 0;

        const formatted = formatNumericType(numberValue, propertyObject.unit);

        properties?.push(`${property} - ${formatted}`);
    }
};

import { upsertAccountMappings } from "actions/accountMappingActions";
import {
    createAccountMappingsAPI,
    updateAccountMappingsAPI,
} from "actions/accountMappingAPIActions";
import { cloneDeep } from "lodash";
import {
    AccountMap,
    AccountMappingDataMapped,
    AccountMappings,
    AccountMappingType,
} from "reducers/typesSchema/accountMappingsSchema";
import store from "store";
import { getLedger, getTopLevelAccount } from "./accounts";

export const submitAccountMapping = async (
    accountMapping: AccountMap,
    type: AccountMappingType
) => {
    const accountMappings: AccountMappings = cloneDeep(
        store.getState().accountMapping || {
            Csvbox: {},
            Railz: {},
        }
    );

    if (type == AccountMappingType.Csvbox) {
        accountMappings.Csvbox = accountMapping;
    } else if (AccountMappingType.Railz) {
        accountMappings.Railz = accountMapping;
    }

    store.dispatch(upsertAccountMappings(accountMappings));
    await updateAccountMappingsAPI(accountMappings);
};

// called when a user creates a whatifi account
export const createEmptyAccountMapping = async () => {
    await createAccountMappingsAPI();
};

/**
 * @returns Mapping from csv row name to event account id
 */
export const regroupedMapping = (
    accountMappingData: AccountMappingDataMapped
): { [csvRowName: string]: string } => {
    const newAccountMapping: { [csvRowName: string]: string } = {};

    for (const mappingData of Object.values(accountMappingData)) {
        const basicAccounts = {
            "211dd944-359f-4fbe-af1f-0c761afa1e67": true, //Assets
            "6c9640aa-abf5-4f19-b135-356e183bcce6": true, //Liabilities
            "001a1288-cefb-4bcb-97ce-510b8a668a19": true, //Equity
            "488dd61d-8697-4213-8978-cf91755365a4": true, //Income
            "7faf0285-78ca-411b-b875-d900929d7c94": true, //Expenses
        };
        let id = mappingData.mappedAccountData.id;
        const ids = mappingData.mappedAccountData.ids;

        // Basic account : group by sub-account
        // Other accounts: group by top-level account
        const regroupLevel = ids[0] in basicAccounts ? 1 : 0;
        if (ids.length > regroupLevel + 1) {
            const ledger = getLedger(ids[regroupLevel]);
            id = ledger.id;
        }
        newAccountMapping[mappingData.name] = id;
    }

    return newAccountMapping;
};

/**
 *
 * @param accountMappingData
 * @returns Checks from the first encountered naturally credit account to see it it's negative. If it is then flip all signs for naturally credit accounts and return the adjusted accountMappingData
 */
export const getAdjustedAccountMappingData = (
    accountMappingData: AccountMappingDataMapped
) => {
    let flipSignForCreditAccounts;

    for (const mappingData of Object.values(accountMappingData)) {
        const mappedAccount = mappingData.mappedAccountData.id;

        if (isCreditAccount(mappedAccount)) {
            // the first credt account encountered
            if (!flipSignForCreditAccounts) {
                if (isMostValuesNegative(mappingData.data)) {
                    flipSignForCreditAccounts = true;
                } else {
                    return accountMappingData;
                }
            }

            // flip the sign
            const data = { ...mappingData.data };
            for (const [date, val] of Object.entries(data)) {
                data[date] = (Number(val) * -1) as typeof val;
            }
            mappingData.data = data;
        } else {
            continue;
        }
    }

    return accountMappingData;
};

export const isMostValuesNegative = (valuesMap) => {
    let negativeCount = 0;
    let positiveCount = 0;

    for (const value of Object.values(valuesMap)) {
        if (Number(value) < 0) {
            negativeCount = negativeCount + 1;
        } else {
            positiveCount = positiveCount + 1;
        }
    }

    return negativeCount > positiveCount;
};

// returns whether the account has a natural credit balance
export const isCreditAccount = (accountId) => {
    if (!accountId) return false;

    const topLevelAccount = getTopLevelAccount(accountId);

    if (!topLevelAccount) return false;

    // Liabilities, Income, Equity
    const naturalCreditAccounts = [
        "6c9640aa-abf5-4f19-b135-356e183bcce6",
        "488dd61d-8697-4213-8978-cf91755365a4",
        "001a1288-cefb-4bcb-97ce-510b8a668a19",
    ];

    return naturalCreditAccounts.includes(topLevelAccount);
};

import { useState, useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import DebtInputView from "./DebtInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";

import { EntitySchema } from "reducers/typesSchema/entitiesSchema";
import {
    EntityCustomAccount,
    getAccountsToDelete,
    getPaths,
    handleCustomAccountStructures,
} from "helpers/accounts";
import * as uuid from "uuid";
import { debtObject } from "Components/Registry/Debt";
import { getDefaultName } from "helpers";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { updateEntityState } from "helpers/updateEntityState";
import { debtInputsHandler } from "../OnInputChangeHandlers/debtInputsHandler";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function DebtInputExperimental({ line, focus, edit, editData }) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);
    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = { manager, line, focus };

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(debtObject);
            _eventData.name = getDefaultName(debtObject.name(), propsObject);
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, debtObject); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, baselineManager, setEntitiesMap]);

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "value" | "url" | "entityName" | "rating",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = debtInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = debtInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmitValuesCustom = (newEntitiesMap, passedCheck) => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;

        // use submit account instead??
        const accountsToDelete = getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            passedCheck,
            entitiesMap,
            createDebtAccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck = !!eventData.name && debtObject.checkInput(entitiesMap);

    return (
        <DebtInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
        />
    );
}

export const createDebtAccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const debtAccountId = uuid.v4();

    // figure this out lol
    const accountStructures: EntityCustomAccount[] = [
        {
            Name: entity.name,
            Type: "display",
            Display: "$",
            Root: {
                topLevel: "6c9640aa-abf5-4f19-b135-356e183bcce6", // Liabilities
                topLevelChildren: [debtAccountId], // Current Liabilities
            },
            customRoots: [
                {
                    topLevel: ["Debt - ", debtAccountId],
                    topLevelChildren: [],
                },
            ],
        },
    ];

    data.accountStructure = accountStructures;
    data.accountIdsPaths = getPaths(accountStructures);
    data.accountIds = data.accountIdsPaths[0];

    currentEntityObject.data = data;

    return currentEntityObject;
};

// Default Imports
import React from "react";

// Import Event Card and Event Input
import { AccountModifierCard } from "../Card/accountModifierCard.tsx";
import AccountModifierInput from "Components/InputContainer/AccountModifier/AccountModifierInput.tsx";

// Import Connection Image (if exists)
import ModifierConnectionImage from "../../Assets/_optionDescriptionIcons/Modifiers white.jpg";

// Import Coloured PNGs
import accountModifierBlack from "../../Assets/_eventNodeTypes/accountModifiersSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// "Interface" object for Event
export const accountModifierObject = {
    constant: function () {
        return AccountModifier_Constant();
    },

    name: function () {
        return "Account Modifier";
    },

    checkInput: function (state) {
        return AccountModifier_CheckInput(state);
    },

    svg: function () {
        return AccountModifier_Svg();
    },

    eventNodeMenu: function (focus) {
        return AccountModifier_EventNodeMenu(focus);
    },

    indexText: function () {
        return AccountModifier_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return AccountModifier_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return AccountModifier_OptionDescription();
    },

    colouredPngs: function () {
        return AccountModifier_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return AccountModifier_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return AccountModifier_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependencies are filled out
const AccountModifier_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.startDate) return false;
        if (!entity.data.action) return false;
        if (entity.data.action === "constrain") {
            if (!entity.data.minValue && !entity.data.maxValue) return false;
        } else if (entity.data.action === "override") {
            if (!entity.data?.modsCreated?.length) return false;
        } else {
            if (!entity.data.value) return false;
        }
        if (!entity.data.accountName) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const AccountModifier_Constant = () => {
    return "2aedf0f3-2c64-4d31-b255-da2714dfd424";
};

// EFFECTS: Returns black png / svg for event
const AccountModifier_Svg = () => {
    return accountModifierBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const AccountModifier_EventNodeMenu = (focus) => {
    return <AccountModifierCard eventData={focus} />;
};

// EFFECTS: Returns the Index Text for event
const AccountModifier_IndexText = () => {
    return "Select an input field to learn more about your account modifier calculations.";
};

// EFFECTS: Returns all icons for event ??
const AccountModifier_Icons = () => {
    let pngs = new Map([
        ["black", accountModifierBlack],
        ["blue", accountModifierBlack],
        ["white", accountModifierBlack],
        ["grey", accountModifierBlack],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const AccountModifier_InputComponentInfoPassing = (minProps) => {
    return <AccountModifierInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, modifiers for event ??
const AccountModifier_OptionDescription = () => {
    const tags = ["Option"];
    const type = accountModifierObject.name();
    const description =
        "The Account Modifier Event is our most powerful Event. It allows a User to modify almost any value on any Event, or types of Events, upstream of itself. With a Account Modifier, you can change start dates, end dates, or any field or value. Values can be replaced, incremented or have a percent change - and using start and end dates, you can control when this modification occurs. A specific Event can be targeted. Or any type of Event (like all Expense Events) can all be modified at once.";
    const usedForText =
        "This Event is best used when modeling a “what if” question. For example, if a company was considering a price increase in a year’s time, they would use the Account Modifier Event to modify a Unit Cost Event upstream. Using multiple Account Modifiers, they could also quickly test various prices, various start dates, all without having to manually edit or duplicate the upstream Unit Cost Event. ";
    const connection =
        "Account Modifiers only need to be downstream of the Event(s) that are being modified.";
    const connectionImage = ModifierConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const AccountModifier_SideModalImage = () => {
    return sideImage;
};

const AccountModifier_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {
        startDate: {
            displayName: "Start Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
            conditionalFieldName: "customEffectPeriod",
            conditionalFieldConditionValue: "true",
        },
        endDate: {
            displayName: "End Date",
            editable: false,
            cellRenderer: "dateSelectorCellRenderer",
            conditionalFieldName: "customEffectPeriod",
            conditionalFieldConditionValue: "true",
        },
    };

    const entityDataFieldsMap = {
        customEffectPeriod: {
            displayName: "Custom Effect Period",
            editable: false,
            cellRenderer: "checkBoxCellRenderer",
        },
        value: {
            displayName: "Value",
            editable: true,
        },
    };

    return { entityFieldsMap, entityDataFieldsMap };
};

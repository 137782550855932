// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import PropertyResaleCard from "../Card/propertyResaleCard";
import PropertyResaleInput from "../InputContainer/PropertyResaleInput";

// Import Connection Image (if exists)
import propertyResaleConnectionImage from "../../Assets/_optionDescriptionIcons/Property Resale, Closing Costs, Real Estate Fees.png";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";

// Import Coloured PNGs
import propertyResaleBlack from "../../Assets/_eventNodeTypes/propertyResale-black.png";
import propertyResaleBlue from "../../Assets/_eventNodeTypes/propertyResale-blue.png";
import propertyResaleWhite from "../../Assets/_eventNodeTypes/propertyResale-white.png";
import propertyResaleGrey from "../../Assets/_eventNodeTypes/propertyResale-grey.png";
import { valueDoesNotExist } from "helpers";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - propertyResaleObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - propertyResaleObject.checkInput.call(propertyResaleObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const propertyResaleObject = {
    constant: function () {
        return PropertyResale_Constant();
    },

    checkInput: function (state) {
        return PropertyResale_CheckInput(state);
    },

    svg: function () {
        return PropertyResale_Svg();
    },

    eventNodeMenu: function (focus) {
        return PropertyResale_EventNodeMenu(focus);
    },

    indexText: function () {
        return PropertyResale_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return PropertyResale_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return PropertyResale_OptionDescription();
    },

    colouredPngs: function () {
        return PropertyResale_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return PropertyResale_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const PropertyResale_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.house) return false;
    if (valueDoesNotExist(state.resalePrice)) return false;
    if (!state.start) return false;
    if (valueDoesNotExist(state.closingCost)) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const PropertyResale_Constant = () => {
    return "Property Resale";
};

// EFFECTS: Returns black png / svg for event
const PropertyResale_Svg = () => {
    return propertyResaleBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const PropertyResale_EventNodeMenu = (focus) => {
    return <PropertyResaleCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const PropertyResale_IndexText = () => {
    return "Select an input field to learn more about your propertyResale calculations.";
};

// EFFECTS: Returns all icons for event ??
const PropertyResale_Icons = () => {
    let pngs = new Map([
        ["black", propertyResaleBlack],
        ["blue", propertyResaleBlue],
        ["white", propertyResaleWhite],
        ["grey", propertyResaleGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const PropertyResale_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const PropertyResale_InputComponentInfoPassing = (minProps) => {
    return <PropertyResaleInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, propertyResales for event ??
const PropertyResale_OptionDescription = () => {
    const tags = ["Option"];
    const type = propertyResaleObject.constant();
    const description =
        "The sale of a home triggers many different calculations, tax implications, and cash flow adjustments. Use the Property Resale Event to capture all of these stages when selling a home.";
    const usedForText =
        "The sale of a home is just one transaction. This Event also updates pre-existing Mortgages, adjusts realized Net Worth, feeds potential Capital Gains, and updates any corresponding Income Tax results.";
    const connection = "";
    const connectionImage = propertyResaleConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/financial-consumer-agency/services/selling-home.html";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const PropertyResale_SideModalImage = () => {
    return sideImage;
};

export enum AccountMappingType {
    Csvbox = "Csvbox",
    Railz = "Railz",
}

export type AccountMappings = {
    [type in AccountMappingType]: AccountMap;
};

export interface AccountMap {
    [account: string]: string;
}

export interface MappedAccountData {
    name: string;
    ids: string[];
    id: string;
}

export interface AccountRow {
    name: string;
    data: {
        [date: string]: any;
    };
}

export interface AccountRowMapped {
    name: string;
    data: {
        [date: string]: any;
    };
    mappedAccountData: MappedAccountData;
}
export interface AccountMappingData {
    [account: string]: AccountRow;
}

export interface AccountMappingDataMapped {
    [account: string]: AccountRowMapped;
}

import { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { getDefaultName } from "../../../helpers";
import type { ChangeEvent } from "react";
import { businessInputsHandler } from "../OnInputChangeHandlers/businessInputsHandler";
import BusinessInputView from "./BusinessInputView";
import { businessObject } from "Components/Registry/Business";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { EventManager } from "Events";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
export default function BusinessInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);
    const entitiesObject = useAppSelector((state) => state?.entities);

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(businessObject);
            _eventData.name = getDefaultName(
                businessObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(entitiesObject, eventData, edit);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = useMemo(
        () => ({ manager, line, focus }),
        [focus, line, manager]
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                setEntitiesMap(
                    businessInputsHandler(
                        value,
                        "entityName",
                        0,
                        entitiesMap,
                        currentEntity
                    )
                );
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].name = getDefaultName(
                businessObject.name(),
                propsObject
            );
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
    }, [currentEntity, baselineManager, setEntitiesMap, propsObject]);

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const passedCheck =
        !!eventData.name && businessObject.checkInput(entitiesMap);

    return (
        <BusinessInputView
            setEntitiesMap={setEntitiesMap}
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            onHandleSubmit={onHandleSubmit}
            passedCheck={passedCheck}
            edit={edit}
        />
    );
}

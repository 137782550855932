import { useContext, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
// import expandArrow from "Assets/scenarioIcons/expand.svg";
import expandArrowOrange from "Assets/scenarioIcons/expandOrange.svg";
import "./waterfallMenu.css";
import {
    BaseContainerHeightContext,
    CurrentSelectionContext,
    SelectedTopLevelAccountContext,
    SelectedSubAccountContext,
    SelectedLeafAccountContext,
    SelectedLeafSubAccountContext,
} from "./WaterfallContext";
import { useAppSelector } from "store/useAppSelectorDispatch";

const menuItemHeight = 39;

interface WaterfallMenuItemProps {
    id: string;
    accountLevel: string;
    clickAction: (data: any) => void;
    handleExpand: (accountLevel, accountId, anchorEl) => void;
    getContainerEl: any;
    enforceAnchorContainer: boolean;
    parentSectionHeight: number;
    unselectableAccounts: {};
}

export const WaterfallMenuItem = ({
    id,
    accountLevel,
    clickAction,
    handleExpand,
    getContainerEl,
    enforceAnchorContainer,
    parentSectionHeight,
    unselectableAccounts,
}: WaterfallMenuItemProps) => {
    const baseContainerHeight = useContext(BaseContainerHeightContext);
    const currentSelection = useContext(CurrentSelectionContext);
    const selectedTopLevelAccount = useContext(SelectedTopLevelAccountContext);
    const selectedSubAccount = useContext(SelectedSubAccountContext);
    const selectedLeafAccount = useContext(SelectedLeafAccountContext);
    const selectedLeafSubAccount = useContext(SelectedLeafSubAccountContext);

    const allAccountData = useAppSelector(
        (state) => state?.allAccountLedgers?.ledgersMetadata
    );

    const accountData = useMemo(
        () => allAccountData?.[id] ?? {},
        [allAccountData, id]
    );

    const isMenuOpen = useMemo(() => {
        switch (accountLevel) {
            case "topLevel":
                return id === selectedTopLevelAccount;
            case "subAccount":
                return id === selectedSubAccount;
            case "leafAccount":
                return id === selectedLeafAccount;
            case "leafSubAccount":
                return id === selectedLeafSubAccount;
            default:
                return false;
        }
    }, [
        accountLevel,
        id,
        selectedLeafSubAccount,
        selectedLeafAccount,
        selectedSubAccount,
        selectedTopLevelAccount,
    ]);

    const maxHeight = useMemo(() => {
        if (baseContainerHeight < 375) {
            return 375;
        }
        return baseContainerHeight;
    }, [baseContainerHeight]);

    const shouldAnchorFromContainer: boolean = useMemo(() => {
        if (enforceAnchorContainer) {
            return true;
        }
        const heightOfChildMenu =
            accountLevel === "subAccount" || accountLevel === "leafAccount"
                ? (accountData?.children?.length + 1) * menuItemHeight
                : accountData?.children?.length * menuItemHeight;
        if (heightOfChildMenu > parentSectionHeight) {
            return true;
        }
        return heightOfChildMenu > maxHeight;
    }, [
        enforceAnchorContainer,
        accountLevel,
        accountData?.children?.length,
        parentSectionHeight,
        maxHeight,
    ]);

    const isUnselectable = !!unselectableAccounts[accountData?.id];

    const canExpand = useMemo(() => {
        if (
            accountLevel !== "leafSubAccount" &&
            accountLevel !== "fifthLevelAccount"
        )
            return true;
        if (
            accountLevel === "leafSubAccount" &&
            accountData?.children?.length > 0
        )
            return true;

        return false;
    }, [accountData?.children?.length, accountLevel]);

    return (
        <div className="section" style={{ height: menuItemHeight }}>
            <Tooltip title={accountData.name ?? ""} enterDelay={500}>
                <MenuItem
                    id="textStylesOverride"
                    className="section__heading"
                    onClick={() => {
                        if (!isUnselectable) clickAction(accountData);
                    }}
                    sx={
                        isUnselectable
                            ? { "&:hover": { bgcolor: "transparent" } }
                            : {}
                    }
                >
                    <span
                        className={
                            isUnselectable
                                ? "section__heading section__heading--disabled"
                                : currentSelection === accountData.name
                                ? "section__heading section__heading--active"
                                : "section__heading"
                        }
                    >
                        {accountData.name ?? ""}
                    </span>
                </MenuItem>
            </Tooltip>
            {canExpand && (
                <img
                    className={`${
                        // accountData.children?.length > 0 ||
                        // (accountData.children?.length === 0 &&
                        //     accountLevel !== "topLevel")
                        //     ?
                        isMenuOpen
                            ? "section__expandImage section__expandImage--clickable section__expandImage--expanded"
                            : "section__expandImage section__expandImage--clickable"
                        // : "section__expandImage"
                    }`}
                    src={
                        // accountData.children?.length > 0 ||
                        // (accountData.children?.length === 0 &&
                        //     accountLevel !== "topLevel")
                        // ?
                        expandArrowOrange
                        // : expandArrow
                    }
                    alt="expand arrow"
                    onClick={(event) =>
                        handleExpand(
                            accountLevel,
                            id,
                            shouldAnchorFromContainer
                                ? getContainerEl()
                                : event?.currentTarget
                        )
                    }
                />
            )}
        </div>
    );
};

import { UPSERT_ACCOUNT_FIELD_MAPPINGS } from "actions/accountFieldMappingActions";
import { AccountMappings } from "./typesSchema/accountMappingsSchema";

const initialState: AccountMappings = {
    Csvbox: {},
    Railz: {},
};

export default function accountFieldMappingReducer(
    state = initialState,
    action: { type: string; payload: Partial<AccountMappings> }
) {
    switch (action.type) {
        case UPSERT_ACCOUNT_FIELD_MAPPINGS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

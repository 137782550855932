import React, { Component } from "react";
import "./Card.css";
import expandSvg from "../../Assets/_flinksIcons/expanded.svg";
import dropdownSvg from "../../Assets/_flinksIcons/dropdown.svg";

class BudgetCard extends Component {
    state = {
        showOptions: false,
        budgetSelected: 0,
        toggleAccount: [],
        showSettings: false,
    };

    toggleAccount = (e) => {
        const id = parseInt(e.target.id);
        const { toggleAccount } = this.state;

        if (toggleAccount.includes(id)) {
            const removeId = toggleAccount.filter((item) => {
                return item !== id;
            });
            this.setState({ toggleAccount: removeId });
        } else {
            this.setState({ toggleAccount: [...toggleAccount, id] });
        }
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        const { metadata } = this.props.data;
        const { toggleAccount } = this.state;
        const { version } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div className="BankLabel">
                        {metadata &&
                            metadata.selected &&
                            metadata.selected.length}{" "}
                        Budget Categories
                    </div>
                </div>
                <div className="selectedAccountsContainer">
                    {metadata &&
                        metadata.selected &&
                        metadata.selected.map((budget, i) => {
                            const isToggled = toggleAccount.includes(i);
                            const balance = budget.budget - budget.spent;
                            const percentage = Math.floor(
                                (budget.spent / budget.budget) * 100
                            );
                            return (
                                <div key={i}>
                                    <div key={i} className="selectedAccounts">
                                        <div className="nicknameContainer">
                                            <img
                                                alt="alt"
                                                src={
                                                    isToggled
                                                        ? dropdownSvg
                                                        : expandSvg
                                                }
                                                onClick={this.toggleAccount}
                                                id={i}
                                                className="selectedIcon"
                                            />
                                            <img
                                                alt="alt"
                                                className="cardIcon"
                                                src={budget.icon}
                                            />
                                            <div className="nickname">
                                                {budget.title}
                                            </div>
                                            <div>{percentage}%</div>
                                        </div>
                                        <div className="balanceContainer">
                                            <div>
                                                ${budget.budget}
                                                /mon
                                            </div>
                                            <div>
                                                ${balance}{" "}
                                                {balance >= 0 ? "left" : "over"}
                                            </div>
                                        </div>
                                        <div className="sliderContainer">
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                defaultValue={percentage}
                                                id="range"
                                                className={
                                                    balance >= 0
                                                        ? "slider"
                                                        : "sliderOver"
                                                }
                                            />
                                        </div>
                                    </div>
                                    {isToggled && (
                                        <div className="popUpContainer">
                                            <div className="Row">
                                                <div className="key">
                                                    Monthly Goal
                                                </div>
                                                <div className="value">
                                                    {budget.budget}
                                                </div>
                                            </div>
                                            <div className="Row">
                                                <div className="key">
                                                    Historical Average
                                                </div>
                                                <div className="value">
                                                    {budget.budget}
                                                </div>
                                            </div>
                                            <div className="Row">
                                                <div className="key">
                                                    Infaltion
                                                </div>
                                                <div className="value">
                                                    +1.5%
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                {/* <div className="Row">
                    <img
                        alt="alt"
                        onClick={this.onHandleAdvanceSetting}
                        className="selectedIcon"
                        src={showSettings ? dropdownSvg : expandSvg}
                    />
                    <div className="advanceSettingLabel">Advance settings</div>
                </div> */}
            </div>
        );
    }
}

export default BudgetCard;

import { currentMortgageAmortization } from "./mortgageHelpers";

export const AnyMortgage = "Any upstream and baseline mortgage";
export const SpecificMortgage = "Specific Mortgage";
export const RenewalRefinanceModes = [AnyMortgage, SpecificMortgage];
export const DefaultSetting = "Same as last term";
export const ExtraAmortizationPeriods = [...currentMortgageAmortization];
export const ExtraCadenceOptions = [
    DefaultSetting,
    "annually",
    "monthly",
    "semi-monthly",
    "bi-weekly",
    "weekly",
    "one-time",
];
export const AutoStart = "End of last term (auto-detect)";
export const CustomDate = "Custom date";
export const StartDateOptions = [AutoStart, CustomDate];

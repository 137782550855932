import { CanvasEventWrapper } from "./CanvasEvent";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { Group } from "react-konva";
import { useContext } from "react";
import { CanvasBaselineContext } from "../../contexts/CanvasBaselineContext";
import { getManager } from "helpers/getManager";
import { CanvasDashboardContext } from "../../contexts/CanvasDashboardContext";

export function CanvasEvents() {
    const isBaseline = useContext(CanvasBaselineContext);
    const isDashboard = useContext(CanvasDashboardContext);

    const sortedEventIds = useAppSelector((state) => {
        const manager = getManager(isBaseline, state);
        const _sortedEventIds: string[] = manager?.sortedEventIds ?? [];
        return _sortedEventIds;
    }, shallowEqual);

    if (isDashboard) return <Group></Group>;

    return (
        <Group>
            {sortedEventIds?.map((eventId) => {
                return <CanvasEventWrapper key={eventId} eventId={eventId} />;
            })}
        </Group>
    );
}

import styles from "./CanvasExplainerVideoStyles.module.css";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { useSpring, animated } from "react-spring";
import { useState } from "react";
import CollapseIcon from "Assets/actionsIcons/collapse.svg";
import ExpandIcon from "Assets/actionsIcons/plus-expand.svg";

export function CanvasExplainerVideo() {
    const scenarioVideoSrc: string = useAppSelector(
        (state) => state?.scenario?.loadedScenario?.video_link
    );

    const [expanded, setExpanded] = useState<boolean>(true);

    const containerAnimation = useSpring({
        to: { height: expanded ? 150 : 24 },
    });

    if (!scenarioVideoSrc) return <></>;

    return (
        <animated.div
            className={styles.CanvasExplainerVideo}
            style={containerAnimation}
        >
            <div className={styles.CanvasExplainerVideo__header}>
                <h5 className={styles.CanvasExplainerVideo__title}>
                    Explainer Video
                </h5>
                <figure
                    className={styles.CanvasExplainerVideo__figure}
                    onClick={() => setExpanded(!expanded)}
                >
                    <img
                        className={styles.CanvasExplainerVideo__expandIcon}
                        src={expanded ? CollapseIcon : ExpandIcon}
                        alt="Expand Icon"
                    />
                </figure>
            </div>
            <div className={styles.CanvasExplainerVideo__videoContainer}>
                <iframe
                    className={styles.CanvasExplainerVideo__iframe}
                    src={scenarioVideoSrc}
                    frameBorder="0"
                    //@ts-ignore
                    allowFullScreen
                />
            </div>
        </animated.div>
    );
}

import * as React from "react";
// import Box from '@mui/material/Box';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
// import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
// import SaveIcon from '@mui/icons-material/Save';
// import PrintIcon from '@mui/icons-material/Print';
// import ShareIcon from "@mui/icons-material/Share";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import styles from "./DashboardSpeedDial.module.css";

import { withStyles } from "@material-ui/core/styles";

import { openAccountCardModal } from "actions/canvasCardModalsActions";

import { useAppDispatch } from "store/useAppSelectorDispatch";

const CssSpeedDial = withStyles({
    root: {
        "& .MuiSpeedDial-fab": {
            backgroundColor: "#f7ab5f",
        },
        "& .MuiSpeedDial-fab:hover": {
            backgroundColor: "#f7ab5f",
        },
    },
})(SpeedDial);

const actions = [
    // { icon: <FileCopyIcon />, name: 'Copy' },
    // { icon: <SaveIcon />, name: 'Save' },
    // { icon: <PrintIcon />, name: 'Print' },
    {
        icon: <DashboardCustomizeIcon />,
        name: "Account Card",
        clickAction: openAccountCardModal(),
    },
];

export const DashboardSpeedDial = () => {
    const dispatch = useAppDispatch();

    return (
        <div className={styles.container}>
            <CssSpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: "absolute", bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => dispatch(action.clickAction)}
                    />
                ))}
            </CssSpeedDial>
        </div>
    );
};

import React, { Component } from "react";
import "./Card.css";
// import { cacObject } from "../Registry/CAC";
import { getRelevantEntities, getEvent } from "actions/getNodeEntityActions";

class CACCard extends Component {
    state = {
        showSettings: false,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    render() {
        // As of right now we are just grabbing and displaying the first entity
        // TODO: Add the ability to scroll through all entities, similar to the input container
        const entity = Object.values(
            getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            )
        )[0];

        const { description, data, startDate } = entity;
        const dependantCustomerEntityIds = data?.selectedCustomer?.entityIds ?? []
        let dependantCustomerEntity = {};
        if (dependantCustomerEntityIds.length > 0) {
            dependantCustomerEntity =
                getRelevantEntities(dependantCustomerEntityIds)[
                    dependantCustomerEntityIds?.[0]
                ];
        }
        const dependantCustomerEventId = data?.selectedCustomer?.eventId
        const dependantEvent = getEvent(dependantCustomerEventId);
        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Selected Customer:</div>
                    <div className="cardValue">{`${dependantEvent?.name} - ${
                        dependantCustomerEntity?.name ?? "All"
                    }`}</div>
                </div>
                {description && (
                    <div className="Row">
                        <div
                            className={
                                description ? "Description" : "NoDescription"
                            }
                        >
                            {description}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Date Type:</div>
                    <div className="cardValue">
                        {data.inheritStartDate ? "Inherit" : "Custom"}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Date:</div>
                    <div className="cardValue">
                        {data.inheritStartDate
                            ? dependantCustomerEntity?.startDate ?? "Date Varies"
                            : startDate}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Type:</div>
                    <div className="cardValue">
                        {data.subType ?? ""}
                    </div>
                </div>
                {data.subType === "constant" && (
                    <div className="Row">
                        <div className="ExpenseLabel">Estimated CAC:</div>
                        <div className="cardValue">{`$${
                            data.constantValue
                                ? new Intl.NumberFormat().format(
                                    parseFloat(data.constantValue).toFixed(2)
                                )
                                : ""
                        }`}</div>
                    </div>
                )}
                {data.subType === "dynamic" && (
                    <div className="Row">
                        <div className="ExpenseLabel">Interpolate SubType:</div>
                        <div className="cardValue">
                            {data.interpolateSubType ?? ""}
                        </div>
                    </div>
                )}
                {data.subType === "dynamic" && data.specificPercentages?.length > 0 && data.specificPercentages.map((specificValue) => (
                    <div className="Row" key={specificValue?.id}>
                        <div className="ExpenseLabel">{`${specificValue?.month}, ${specificValue?.year}:`}</div>
                        <div className="cardValue">
                            {`$${
                                specificValue.value
                                    ? new Intl.NumberFormat().format(
                                        parseFloat(specificValue.value).toFixed(2)
                                    )
                                    : ""
                            }`}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default CACCard;

import React from "react";

import ProjectsCard from "../Card/projectsCard";
// import ProjectsInput from "../InputContainer/ProjectsInput";

import projectsConnection from "Assets/_optionDescriptionIcons/Project, Employee, Resource.png";

import projectDarkGrey from "../../Assets/_eventNodeTypes/projectSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";
import ProjectsInputExperimental from "Components/InputContainer/Projects/Projects";

export const projectsObject = {
    constant: function () {
        return Projects_Constant();
    },

    name: function () {
        return "Projects";
    },

    checkInput: function (state) {
        return Projects_CheckInput(state);
    },
    svg: function () {
        return Projects_Svg();
    },

    eventNodeMenu: function (focus) {
        return Projects_EventNodeMenu(focus);
    },

    indexText: function () {
        return Projects_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Projects_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Projects_OptionDescription();
    },

    colouredPngs: function () {
        return Projects_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Projects_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return Projects_GenerateDisplayFieldData();
    },
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Projects_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.data.state) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        const entityValid = checkEntity(entity);
        passedCheck = entityValid;
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Projects_Constant = () => {
    return "30b507ac-53b8-4c0e-aaf6-f47f8152d4d9";
};

// EFFECTS: Returns black png / svg for event
const Projects_Svg = () => {
    return projectDarkGrey;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Projects_EventNodeMenu = (focus) => {
    return <ProjectsCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Projects_IndexText = () => {
    return "The Project Event keeps track of project.";
};

// EFFECTS: Returns all icons for event ??
const Projects_Icons = () => {
    let pngs = new Map([
        ["black", projectDarkGrey],
        ["blue", projectDarkGrey],
        ["white", projectDarkGrey],
        ["grey", projectDarkGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Projects_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Projects_InputComponentInfoPassing = (minProps) => {
    return <ProjectsInputExperimental {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, customers for event ??
const Projects_OptionDescription = () => {
    const tags = ["Option"];
    const type = projectsObject.name();
    const description =
        "This Event automatically creates custom Accounts in the Account hierarchy that can be referenced by downstream Events like Resources or Utilization. Projects can be linked to external sources like Excel, Shotgrid, or other third party platforms.";
    const usedForText =
        "Use this Event to track the status and account totals for all of your projects or initiatives.";
    const connection = "";
    const connectionImage = projectsConnection;
    const learnMoreLink = "";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Projects_SideModalImage = () => {
    return sideImage;
};

const Projects_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import React from "react";

// Import Event Card and Event Input
import TuitionCard from "../Card/tuitionCard";
import TuitionInput from "../InputContainer/TuitionInput";

// Import Coloured PNGs
import tuitionBlack from "../../Assets/_eventNodeTypes/tuition-black.png";
import tuitionBlue from "../../Assets/_eventNodeTypes/tuition-blue.png";
import tuitionWhite from "../../Assets/_eventNodeTypes/tuition-white.png";
import tuitionGrey from "../../Assets/_eventNodeTypes/tuition-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/Banking2x.png";

import tuitionConnection from "../../Assets/_optionDescriptionIcons/Grant, Tuition, Rent.png";

import { valueDoesNotExist } from "../../helpers/index";

export const tuitionObject = {
    constant: function () {
        return Tuition_Constant();
    },

    checkInput: function (state) {
        return Tuition_CheckInput(state);
    },

    svg: function () {
        return Tuition_Svg();
    },

    eventNodeMenu: function (focus) {
        return Tuition_EventNodeMenu(focus);
    },

    indexText: function () {
        return Tuition_IndexText();
    },

    inputInfoPassing: function (minProps, libraryProps) {
        return Tuition_InputComponentInfoPassing(minProps, libraryProps);
    },

    optionDescription: function () {
        return Tuition_OptionDescription();
    },

    colouredPngs: function () {
        return Tuition_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Tuition_SideModalImage();
    },
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Tuition_CheckInput = (state) => {
    if (state.tuitionType === "variable") {
        if (!state.quantity) return false;
        if (!state.start) return false;
        if (!state.cadence) return false;
        if (!state.cost) return false;
        if (state.applyInflation && valueDoesNotExist(state.inflationRate))
            return false;
    } else {
        if (!state.start) return false;
        if (!state.cadence) return false;
        if (!state.cost) return false;
        if (state.applyInflation && valueDoesNotExist(state.inflationRate))
            return false;
    }
    return true;
};

// EFFECTS: Returns constant for event
const Tuition_Constant = () => {
    return "Tuition";
};

// EFFECTS: Returns black png / svg for event
const Tuition_Svg = () => {
    return tuitionBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Tuition_EventNodeMenu = (focus, inflation) => {
    return <TuitionCard data={focus} inflation={inflation} />;
};

// EFFECTS: Returns the Index Text for event
const Tuition_IndexText = () => {
    return "Calculate your tuition based on a variety of factors. Weigh the outcomes between taking more courses and perhaps juggling something on the side.";
};

// EFFECTS: Returns all icons for event ??
const Tuition_Icons = () => {
    let pngs = new Map([
        ["black", tuitionBlack],
        ["blue", tuitionBlue],
        ["white", tuitionWhite],
        ["grey", tuitionGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Tuition_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Tuition_InputComponentInfoPassing = (minProps, libraryProps) => {
    return <TuitionInput {...minProps} {...libraryProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, tuitions for event ??
const Tuition_OptionDescription = () => {
    const tags = ["Option"];
    const type = tuitionObject.constant();
    const description =
        "Pursuing a post-secondary degree is a large decision. Calculate expenses and manage your finances while in school. ";
    const usedForText =
        "The Tuition Event can be used to calculate costs associated with full-time and part-time enrollment on an annual, per-term, or one-time basis.";
    const connection = "";
    const connectionImage = tuitionConnection;
    const learnMoreLink =
        "https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-32300-your-tuition-education-textbook-amounts/eligible-tuition-fees.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Tuition_SideModalImage = () => {
    return sideImage;
};

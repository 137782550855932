import {
    KeyboardEvent,
    ChangeEvent,
    useEffect,
    useRef,
    useState,
    ElementRef,
} from "react";
import styles from "./index.module.css";
import { v4 as uuidv4 } from "uuid";
import whatifiIcon from "Assets/scenarioIcons/whatifiGreen.svg";
import openAiIcon from "Assets/scenarioIcons/openAI.svg";
import paperclipIcon from "Assets/scenarioIcons/paperclip.svg";
import arrowIcon from "Assets/scenarioIcons/upArrow.svg";
import startsIcon from "Assets/scenarioIcons/conversation_tab_icon.svg";
import {
    AssistantMessageResponse,
    AssistantNodesResponse,
    getConversationId,
    getMessages,
    postResponse,
} from "actions/aiAssistantActions";
import { importFromAi } from "Components/ImportFromAiExperimental/ImportFromAiExperimental";
import { useAppDispatch } from "store/useAppSelectorDispatch";

interface MessageProps {
    sentByUser: boolean;
    content: string;
}

const Message = ({ sentByUser, content }: MessageProps) => {
    return (
        <div className={styles.message}>
            <span className={styles.message__highlightText}>
                {sentByUser ? "You" : "whatifi"}
            </span>
            <p className={styles.message__content}>{content}</p>
        </div>
    );
};

const NodesMessage = ({ output }: any) => {
    const dispatch = useAppDispatch();
    const allNamesString = output?.entities
        ?.map((entity: any) => entity?.name)
        ?.join(", ");

    return (
        <div className={styles.message}>
            <span className={styles.message__highlightText}>whatifi</span>
            <p className={styles.message__content}>
                {
                    "We're ready to build your model with the following entities. Can we proceed?"
                }
            </p>
            <span className={styles.message__highlightText}>
                {allNamesString}
            </span>
            <img
                className={styles.message__submitIcon}
                src={arrowIcon}
                alt="arrow"
                onClick={() => importFromAi(output, dispatch)}
            />
        </div>
    );
};

const ScenarioConversation = ({ scenario }) => {
    const conversationId = useRef<string>();
    const [conversation, setConversation] = useState<
        (AssistantMessageResponse | AssistantNodesResponse)[]
    >([]);
    const [userMessage, setUserMessage] = useState<string>("");
    const containerRef = useRef<ElementRef<"div">>(null);
    const textAreaRef = useRef<ElementRef<"textarea">>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getConversationId(scenario).then((id) => {
            conversationId.current = id;
            getMessages(conversationId.current).then((messages) => {
                const messagesWithoutErrors = messages.filter(
                    (
                        message
                    ): message is
                        | AssistantMessageResponse
                        | AssistantNodesResponse => {
                        return message.assistantResponseType !== "error";
                    }
                );

                setConversation(messagesWithoutErrors);
                setIsLoading(false);
            });
        });
    }, [scenario]);

    useEffect(() => {
        const element = containerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }, [conversation]);

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.code === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (textAreaRef.current != undefined) {
            textAreaRef.current.style.height = "inherit";
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
        setUserMessage(e?.target?.value);
    };

    const updateMessages = () => {
        setIsLoading(true);
        getMessages(conversationId.current).then((messages) => {
            const messagesWithoutErrors = messages.filter(
                (
                    message
                ): message is
                    | AssistantMessageResponse
                    | AssistantNodesResponse => {
                    return message.assistantResponseType !== "error";
                }
            );

            setConversation(messagesWithoutErrors);
            setIsLoading(false);
        });
    };

    const handleSubmit = () => {
        if (userMessage) {
            // Make the message appear on the client immediately. This gets overwritten
            // once the AI responds back.
            const newMessage: AssistantMessageResponse = {
                assistantResponseType: "message",
                id: uuidv4(),
                content: userMessage,
                role: "user",
            };

            setConversation([...conversation, newMessage]);

            new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
                // TODO: handle the case where the response comes sooner than this,
                // and prevent it from overwriting.
                const aiResponse: AssistantMessageResponse = {
                    assistantResponseType: "message",
                    id: uuidv4(),
                    content: "...",
                    role: "assistant",
                };

                setConversation([...conversation, newMessage, aiResponse]);
            });

            setIsLoading(true);
            postResponse(conversationId.current, userMessage).then(() => {
                updateMessages();
            });
        }

        setUserMessage("");
        if (textAreaRef.current != undefined) {
            textAreaRef.current.style.height = "inherit";
        }
    };

    return (
        <div className={styles.conversationTab}>
            <div className={styles.conversationTab__heading}>
                <div className={styles.conversationTab__imagesWrapper}>
                    <img
                        className={styles.conversationTab__image}
                        src={whatifiIcon}
                        alt="whatifi"
                        draggable={false}
                    />
                    <img
                        className={`${styles.conversationTab__image} ${styles["conversationTab__image--small"]}`}
                        src={startsIcon}
                        alt="starts"
                        draggable={false}
                    />
                    <img
                        className={styles.conversationTab__image}
                        src={openAiIcon}
                        alt="openai"
                        draggable={false}
                    />
                </div>
                <h2 className={styles.conversationTab__subText}>
                    <a
                        href={
                            "https://platform.openai.com/playground?assistant=asst_39q3iQKoGpZelrm5tZqnUjoO&mode=assistant&thread=" +
                            conversationId.current
                        }
                        style={{
                            color: "inherit",
                            textDecoration: "inherit",
                            cursor: "inherit",
                        }}
                    >
                        ask whatifi
                    </a>
                </h2>
            </div>
            <div className={styles.conversation} ref={containerRef}>
                {conversation?.map((response) => {
                    if (response?.assistantResponseType === "message") {
                        return (
                            <Message
                                key={response?.id}
                                sentByUser={response?.role === "user"}
                                content={response?.content}
                            />
                        );
                    }
                    if (response?.assistantResponseType === "nodes") {
                        return (
                            <NodesMessage
                                key={response?.id}
                                output={response?.output}
                            />
                        );
                    }
                })}
            </div>
            <form className={styles.conversationTab__form}>
                <img
                    className={`${styles.conversationTab__inputIcon} ${styles["conversationTab__inputIcon--left"]}`}
                    src={paperclipIcon}
                    alt="paperclip"
                    draggable={false}
                />
                <div className={styles?.conversationTab__textareaWrapper}>
                    <textarea
                        className={styles.conversationTab__textarea}
                        placeholder="Hey whatifi..."
                        value={userMessage}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        ref={textAreaRef}
                        disabled={isLoading}
                    />
                </div>
                <img
                    className={
                        userMessage
                            ? `${styles.conversationTab__inputIcon} ${styles["conversationTab__inputIcon--right"]} ${styles["conversationTab__inputIcon--submit"]}`
                            : `${styles.conversationTab__inputIcon} ${styles["conversationTab__inputIcon--right"]} ${styles["conversationTab__inputIcon--submit"]} ${styles["conversationTab__inputIcon--disabled"]}`
                    }
                    src={arrowIcon}
                    alt="arrow"
                    draggable={false}
                    onClick={() => handleSubmit()}
                />
            </form>
        </div>
    );
};

export default ScenarioConversation;

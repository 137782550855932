import { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import CustomerInputView from "./CustomerInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { customerObject } from "Components/Registry/Customer";
import type { ChangeEvent, MutableRefObject } from "react";
import { createNewEvent } from "helpers/createNewEvent";
import {
    EntitiesSchema,
    EntitySchema,
} from "reducers/typesSchema/entitiesSchema";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { updateEntityState } from "helpers/updateEntityState";
import {
    EntityCustomAccount,
    getAccountsToDelete,
    getCustomerSubAccountPaths,
    getPaths,
    getTopLevelGrownChurn,
    handleCustomAccountStructures,
} from "helpers/accounts";
import * as uuid from "uuid";
import { EventManager } from "Events";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { customerInputsHandler } from "../OnInputChangeHandlers/customerInputsHandler";
import { EventInputIDContext } from "../Context/EventInputIDContext";

export default function CustomerInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...useAppSelector(
            (state) => state?.scenario?.loadedScenario?.dependency_map
        ),
    });

    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );

    const entitiesObject: EntitiesSchema = useAppSelector(
        (state) => state?.entities
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(customerObject);
            _eventData.name = getDefaultName(
                customerObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        customerObject,
        dependencyMap.current
    );

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const getCustomers = () => {
        const customers = {};

        getPresentableDependencies(
            customers,
            customerObject.constant(),
            propsObject,
            true
        );

        return customers;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const newEntitiesMap = { ...prevEntitiesMap };
            const newCurrentEntity = { ...newEntitiesMap[currentEntity] };
            const newData = { ...newCurrentEntity.data };

            const customers = getCustomers();
            newData.customers = customers;

            newCurrentEntity.data = newData;
            newEntitiesMap[currentEntity] = newCurrentEntity;
            return newEntitiesMap;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, setEntitiesMap]);

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = customerInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "entityName" | "customer" | "startDate"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = customerInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmitValuesCustom = (newEntitiesMap, passedCheck) => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                newEntitiesMap,
                entityIds,
                passedCheck,
                edit,
                {}
            )
        );
    };

    const onHandleClickSubmitOrUpdate = () => {
        const accountsToDelete = getAccountsToDelete(entitiesMap, eventData);

        handleCustomAccountStructures(
            passedCheck,
            entitiesMap,
            createCustomerAccountStructure,
            onHandleSubmitValuesCustom,
            accountsToDelete
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && customerObject.checkInput(entitiesMap);

    const isSubmitted = useMemo(() => {
        if (eventData?.id)
            return eventData.entities.some(
                (e) => e.id === entitiesMap?.[currentEntity]?.id
            );
        return false;
    }, [currentEntity, entitiesMap, eventData.entities, eventData?.id]);

    return (
        <CustomerInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleClickSubmitOrUpdate={onHandleClickSubmitOrUpdate}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            handleDateSelection={handleDateSelection}
            handleOnChange={handleOnChange}
            isSubmitted={isSubmitted}
        />
    );
}

export const createCustomerAccountStructure = (entity: EntitySchema) => {
    const currentEntityObject = { ...(entity ?? {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    const parentAccountId = uuid.v4();
    const grownAccountId = uuid.v4();
    const churnedAccountId = uuid.v4();
    const transferredInAccountId = uuid.v4();
    const transferredOutAccountId = uuid.v4();

    const accountStructures: EntityCustomAccount[] = [
        {
            Name: entity.name,
            Type: "display",
            Display: "Customer",
            Root: {
                topLevel:
                    data?.accountId ?? "b2623ec6-3492-4fa0-9dcd-0150c3443cb0",
                topLevelChildren: [parentAccountId],
            },
            customRoots: [
                {
                    topLevel: ["Customer - ", parentAccountId],
                    topLevelChildren: [
                        ["Grown ", grownAccountId],
                        ["Churned ", churnedAccountId],
                        ["Transferred In ", transferredInAccountId],
                        ["Transferred Out ", transferredOutAccountId],
                    ],
                },
            ],
        },
    ];

    data.accountStructure = accountStructures;
    data.accountIdsPaths = getPaths(accountStructures);

    const [topLevelGrown, topLevelChurn] = getTopLevelGrownChurn(
        data?.accountId ?? "b2623ec6-3492-4fa0-9dcd-0150c3443cb0"
    );

    const [grownPath, churnedPath, transInPath, transOutPath] =
        getCustomerSubAccountPaths(
            data?.accountId ?? "b2623ec6-3492-4fa0-9dcd-0150c3443cb0",
            parentAccountId,
            grownAccountId,
            churnedAccountId,
            transferredInAccountId,
            transferredOutAccountId
        );

    data.grownPath = grownPath;
    data.churnedPath = churnedPath;
    data.transInPath = transInPath;
    data.transOutPath = transOutPath;

    // Record accountIds in state (Maybe we can retrieve them from above struct?)
    data.parentAccountId = parentAccountId;
    data.grownAccountId = grownAccountId;
    data.churnedAccountId = churnedAccountId;
    data.transferredInAccountId = transferredInAccountId;
    data.transferredOutAccountId = transferredOutAccountId;

    data.accountIds = [
        data?.accountId ?? "b2623ec6-3492-4fa0-9dcd-0150c3443cb0",
        topLevelGrown,
        topLevelChurn,
        parentAccountId,
        grownAccountId,
        churnedAccountId,
        transferredInAccountId,
        transferredOutAccountId,
    ];

    currentEntityObject.data = data;

    return currentEntityObject;
};

import { EventStructure } from "Components/InputContainer/CustomHooks/useEntities";
import { decisionObject } from "Components/Registry/Decision";
import { containerObject } from "Components/Registry/Container";
import { getMaxEventsPerScenario } from "helpers/userHelpers";
import Event from "../Events/_event";
import EventManager from "../Events/eventManager";
import store from "store";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";
import {
    getRelevantEntities,
    getEvent,
    getSingleEntity,
} from "./getNodeEntityActions";
import {
    handleCloseModal,
    loadScenario,
    updateEventAndScenario,
} from "./scenarioHelpers";
import { handleSubmitNodesAndEntities } from "./nodeEntityActions";
// import { BaselineDataManager } from "Events";

export const getMaxNodeCount = (loggedInUser) => {
    const nodeCount = getMaxEventsPerScenario(
        loggedInUser &&
            Object.keys(loggedInUser).length === 0 &&
            loggedInUser.plan
    );
    return nodeCount;
};

/**
 *
 * @param event The new event to be created (required)
 * @param parents Parents to attach the new event to (optional)
 * @param children Children to attach the new event to (optional)
 * @param keepModalOpen Keeps modal open (optional) - Used when automatically creating multiple events
 */
export const addNewEvent = (
    event: EventStructure,
    parents?: string[],
    children?: string[],
    keepModalOpen?: boolean
) => {
    const loadedScenario: ScenarioSchema =
        store.getState().scenario?.loadedScenario;
    const manager: EventManager = store.getState().scenario?.manager;
    const line: { parentEvent: Event; childEvent: Event } | null =
        store.getState().scenario?.line;
    const focus: {
        id: string;
        type: string;
        version: string;
        relevantContainerId: string | null;
        entities: any;
    } | null = store.getState().scenario?.focus;

    // Is there a better default value for the null case than '{}'?
    const loggedInUser =
        JSON.parse(localStorage.getItem("loggedInUser") ?? "{}") ?? {};

    const maxNodeCount = getMaxNodeCount(loggedInUser);
    const loadedScenarioNodes = loadedScenario.data.nodes.filter((node) => {
        const eventNode = manager._findEvent(node);
        return eventNode?.type !== decisionObject.constant();
    });
    const loadedScenarioNodeCount = loadedScenarioNodes.length - 1;

    if (loadedScenarioNodeCount <= maxNodeCount) {
        const node: Event = manager.createEvent(event.type, event, event.id);
        if (node?.type === containerObject.constant()) {
            const containerEntityObject = Object.values(
                getRelevantEntities(event?.entities ?? {})
            )?.[0];
            const containedEventIds = Object.keys(
                containerEntityObject?.data?.containedEvents ?? {}
            );

            containedEventIds?.map((containedEventId) => {
                const containedNode = manager.nodes[containedEventId];
                containedNode.relevantContainerId = node.id;
            });
        }
        // If we're adding an event while inside another event (eg. the import event)
        if (parents || children) {
            manager.attachToNodes(node, parents ?? [], children ?? []);

            // If we're adding an event to the end of another event (using the '+' button)
        } else if (focus) {
            if (focus?.type === containerObject?.constant()) {
                const containerEntity = getSingleEntity(
                    focus?.entities?.[0] ?? ""
                );
                manager.attachToNodes(
                    node,
                    [containerEntity?.data?.tailNode],
                    []
                );
            } else if (focus?.relevantContainerId) {
                node.relevantContainerId = focus?.relevantContainerId;
                const containerEvent =
                    manager.nodes[focus?.relevantContainerId];
                const containerEntity: any = getSingleEntity(
                    containerEvent?.entities?.[0]
                );
                if (containerEntity) {
                    const updatedContainerEntity = {
                        ...containerEntity,
                        data: {
                            ...containerEntity?.data,
                            containedEvents: {
                                ...containerEntity?.data?.containedEvents,
                                [node.id]: node.exportData(),
                            },
                            tailNode: node.id,
                        },
                    };
                    const newContainerEntitiesMap = {
                        [containerEntity?.id]: updatedContainerEntity,
                    };
                    store.dispatch(
                        handleSubmitNodesAndEntities(
                            addNewEvent,
                            updateEvent,
                            containerEvent.exportData(),
                            newContainerEntitiesMap,
                            Object.keys(newContainerEntitiesMap),
                            true,
                            true,
                            {}
                        )
                    );
                }
                manager.attachToNodes(node, [focus.id], []);
            } else {
                // If you can access the the '+' button directly from an event then it has no children
                manager.attachToNodes(node, [focus.id], []);
            }

            // If we're adding an event between to existing events (using the line and '...' button)
        } else if (line) {
            // If you can access the '...' button from the line then it must have one parent and one child
            const parentEvent: any = getEvent(line.parentEvent.id);
            const childEvent: any = getEvent(line.childEvent.id);
            if (parentEvent?.type === containerObject?.constant()) {
                const containerEntity: any = getSingleEntity(
                    parentEvent?.entities?.[0]
                );

                manager.attachToNodes(
                    node,
                    [containerEntity?.data?.tailNode],
                    [line.childEvent.id]
                );
            } else if (
                parentEvent?.relevantContainerId &&
                node?.type === containerObject.constant()
            ) {
                const containerEvent =
                    manager.nodes[parentEvent?.relevantContainerId];
                const containerEntity: any = getSingleEntity(
                    containerEvent?.entities?.[0]
                );

                manager.attachToNodes(
                    node,
                    [containerEntity?.data?.tailNode],
                    [line.childEvent.id]
                );
            } else if (parentEvent?.relevantContainerId) {
                const containerEvent =
                    manager.nodes[parentEvent?.relevantContainerId];
                const containerEntity: any = getSingleEntity(
                    containerEvent?.entities?.[0]
                );

                const replaceTailNode =
                    containerEntity?.data?.tailNode === parentEvent.id;

                node.relevantContainerId = containerEvent?.id;
                if (containerEntity) {
                    const updatedContainerEntity = {
                        ...containerEntity,
                        data: {
                            ...containerEntity?.data,
                            containedEvents: {
                                ...containerEntity?.data?.containedEvents,
                                [node.id]: node.exportData(),
                            },
                            tailNode: replaceTailNode
                                ? node.id
                                : containerEntity?.data?.tailNode,
                        },
                    };
                    const newContainerEntitiesMap = {
                        [containerEntity?.id]: updatedContainerEntity,
                    };
                    store.dispatch(
                        handleSubmitNodesAndEntities(
                            addNewEvent,
                            updateEvent,
                            containerEvent.exportData(),
                            newContainerEntitiesMap,
                            Object.keys(newContainerEntitiesMap),
                            true,
                            true,
                            {}
                        )
                    );
                }

                manager.attachToNodes(
                    node,
                    [line.parentEvent.id],
                    [line.childEvent.id]
                );
            } else if (
                childEvent?.relevantContainerId &&
                node?.type !== containerObject.constant()
            ) {
                manager.attachToNodes(
                    node,
                    [line.parentEvent.id],
                    [childEvent?.relevantContainerId]
                );
            } else {
                manager.attachToNodes(
                    node,
                    [line.parentEvent.id],
                    [line.childEvent.id]
                );
            }
        }

        if (!keepModalOpen) handleCloseModal();

        const scenarioData = manager.exportData();
        const updatedScenario: ScenarioSchema = {
            ...loadedScenario,
            data: {
                ...scenarioData,
                favouriteAccounts: { ...loadedScenario.data.favouriteAccounts },
                canvasCardIds: [...loadedScenario?.data?.canvasCardIds],
            },
        };

        loadScenario(updatedScenario, manager);
    }
};

// export const addNewBaselineEvent = (event: EventStructure) => {
//     const baselineDataManager: BaselineDataManager = store.getState().scenario?.baselineDataManager
//     const line: {"parentEvent": Event, "childEvent": Event} | null = store.getState().scenario?.line
//     const focus : {"id": string, "type": string, version: string} | null = store.getState().scenario?.focus

//     const node = baselineDataManager.createEvent(event.type, event, event.id, true);
//     if (event.type === "Person") {
//         //attached to baseline
//         baselineDataManager.attachToNodes(
//             node,
//             [baselineDataManager.root],
//             []
//         );
//     } else {
//         if (focus) {
//             baselineDataManager.attachToNodes(
//                 node.id,
//                 [focus.id],
//                 []
//             );
//         } else if (line) {
//             const { parentEvent, childEvent } = this.props.line;
//             baselineDataManager.attachToNodes(
//                 node,
//                 [parentEvent],
//                 [childEvent]
//             );
//         } else {
//             const personNode = baselineDataManager.createEvent("Person", {
//                 name: "Person",
//             });
//             baselineDataManager.attachToNodes(
//                 personNode,
//                 [baselineDataManager.root],
//                 []
//             );
//             baselineDataManager.attachToNodes(node, [personNode], []);
//         }
//     }

//     const newScenario = baselineDataManager.handleExport();
//     this.props.updateBaseline(newScenario, baselineDataManager);

//     baselineDataManager._updateScenarioCanvas();
// };

export const updateEvent = (event: EventStructure, keepModalOpen?: boolean) => {
    const loadedScenario: ScenarioSchema =
        store.getState().scenario?.loadedScenario;
    const manager: EventManager = store.getState().scenario?.manager;

    const isScenario = loadedScenario.type === "scenario";

    if (loadedScenario && isScenario) {
        updateEventAndScenario(event, loadedScenario, manager);
        if (!keepModalOpen) handleCloseModal();
    }
};

export const bypassEvents = (eventIds: string[]) => {
    if (eventIds.length <= 0) {
        return;
    }

    const loadedScenario: ScenarioSchema =
        store.getState().scenario?.loadedScenario;
    const manager: EventManager = store.getState().scenario?.manager;

    eventIds.forEach((eventId) => {
        const selectedEvent = manager?._findEvent(eventId);
        if (selectedEvent) {
            selectedEvent.toggleBypassed();
        }
    });

    const normalData = manager.exportData();
    const normalScenario = {
        ...loadedScenario,
        data: {
            ...normalData,
            favouriteAccounts: { ...loadedScenario.data.favouriteAccounts },
            canvasCardIds: [...loadedScenario?.data?.canvasCardIds],
        },
    };

    loadScenario(normalScenario, manager);
};

export const checkEventForBypassedEntity = (eventId) => {
    const eventData = getEvent(eventId);
    const allEntitiesDataArray = Object.values(
        getRelevantEntities(eventData?.entities ?? []) ?? {}
    );

    let containsBypassedEntity = false;

    allEntitiesDataArray.forEach((entity) => {
        if (entity?.bypassState) containsBypassedEntity = true;
    });
    return containsBypassedEntity;
};

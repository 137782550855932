import React, { Component } from "react";
import {
    Name,
    InputButtons,
    Description,
    SelectDropDown,
    Value,
    Rate,
    ModalRowHalf,
} from "./Components";
import {
    SpecificNode,
    AnyNode,
    PercentChange,
    ValueReplacer,
    mDate,
    mPercent,
    mDollar,
} from "../../helpers/modifierHelpers";
import { throwError } from "../../helpers/swalError";
import {
    findRootNode,
    getDefaultName,
    getStringValueWithUnit,
    getViableChildrenToSearch,
} from "../../helpers";
import _ from "lodash";
import SingleCalendar from "../Calendar/singleCalendar";
import Calendar from "../Calendar";
import Switch from "react-switch";
import {
    extractModifiableProperties,
    getBaselineProperties,
    recurseToModifier,
} from "../../helpers/modifierHelpers";
import { modifierObject } from "Components/Registry/Modifier";
import { meObject } from "Components/Registry/Me";
import { startObject } from "Components/Registry/Start";

export default class ModifierInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: modifierObject.name(),
            name: getDefaultName(modifierObject.name(), this.props),
            tag: `@${modifierObject.name()}`,
            description: "",
            value: undefined,
            curProperties: undefined, // all the properties gathered upon opening panel; resets on open
            propertySelected: "",
            associatedIds: undefined,
            decisionEngineName: "",
            frontEndName: "",
            mode: AnyNode,
            customEffectPeriod: false,
            revertValue: false,
            start: null,
            end: null,
            // state used for specific node mode
            curNodes: undefined,
            subMode: undefined, // allows user to choose between multiplier or replace specific value
            curNodeName: undefined,
            nameChanged: false,
            unit: undefined,
            misc: {},
        };
    }

    searchForProperties() {
        let curNode = this.getCurNode();
        let root = findRootNode(curNode);
        if (!root) return {};
        let properties = {};
        recurseToModifier(root, curNode, {}, properties);
        getBaselineProperties(properties, this.props.manager);
        return properties;
    }

    searchForNodes() {
        let curNode = this.getCurNode();
        let root = findRootNode(curNode);
        if (!root) return {};
        let nodes = {};
        let visited = new Set();
        this.fetchAllNodes(root, curNode, {}, nodes, visited);
        this.fetchBaseLineNodes(nodes);
        return nodes;
    }

    fetchBaseLineNodes(nodes) {
        if (!this.props.manager) return;
        for (const node of this.props.manager.baseline) {
            // todo: refactor to a common method w/ fetchAllNodes
            if (
                node.type !== meObject.constant() &&
                node.type !== modifierObject.constant() &&
                node.type !== "Baseline"
            ) {
                let i = 1;
                let name = `${node.metadata.name} - baseline`;
                const nameCopy = name;
                while (nodes[name]) {
                    // name exists
                    name = `${nameCopy} (${i})`;
                    i++;
                }
                nodes[name] = node;
            }
        }
    }

    // fetches all ancestor nodes to target
    fetchAllNodes(node, target, tempNodes, nodes, visited) {
        let localTempNodes = _.cloneDeep(tempNodes);
        if (!(!node || (visited.has(node) && node !== target))) {
            visited.add(node);
            if (
                node.type !== meObject.constant() &&
                node.type !== modifierObject.constant() &&
                node.type !== startObject.constant() &&
                node.type !== "Baseline"
            ) {
                let i = 1;
                let name = node.metadata.name;
                const nameCopy = name;
                while (nodes[name] || localTempNodes[name]) {
                    // name exists
                    name = `${nameCopy} (${i})`;
                    i++;
                }
                localTempNodes[name] = node;
            }

            if (node === target) {
                // merge w/ result nodes
                for (let key of Object.keys(localTempNodes)) {
                    nodes[key] = localTempNodes[key];
                }
                return;
            }
        }
        let nodesToSearch = getViableChildrenToSearch(node, target);

        for (let child of nodesToSearch) {
            this.fetchAllNodes(child, target, localTempNodes, nodes, visited);
        }
    }

    getCurNode() {
        let manager = this.props.baseline
            ? this.props.baselineManager
            : this.props.manager;
        if (!manager) return null;
        const parentId = this.props.focus
            ? this.props.focus.id
            : this.props.line.parentEvent.id;
        return manager._findEvent(parentId);
    }

    componentDidUpdate(prevProps, prevState) {
        // we want to scroll down if some
        if (this.newSelectAvailable(prevState, this.state))
            this.scrollToBottom();
    }

    newSelectAvailable(prevState, curState) {
        if (!prevState.mode && curState.mode) return true;
        if (!prevState.curNodeName && curState.curNodeName) return true;
        if (!prevState.propertySelected && curState.propertySelected)
            return true;
        if (!prevState.subMode && curState.subMode) return true;
        return false;
    }

    componentDidMount() {
        if (this.props.edit) {
            this.setState({
                ...this.props.editData,
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
        // note: curProperties/ curNodes is reset in case user changed upstream graph
        this.setState({ curProperties: undefined });
        this.setState({ curNodes: undefined });
        //todo support target node deletion(s)
    }

    onHandleSubmitValues = () => {
        const passedCheck = modifierObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                curNodes: undefined,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }

        this.onHandleMouseLeave();
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;

        const newState = {
            ...this.state,
            isFilled: false,
            curNodes: undefined,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }

        this.onHandleMouseLeave();
        this.props.manager.calculate();
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    //This is how we do the onchange values just put it in one function so it would be easy to locate stuff when needed
    //all you need to pass is onChangeInput to all the components that we have e.g. Name, Description etc. and it should be good. if you are not sure then look into components and check what is the expected props :)
    onChangeInput = (e, id, _star) => {
        let value = !e ? null : e.target.value;
        switch (id) {
            case "name":
                this.setState({ name: value });
                if (!this.state.nameChanged) {
                    this.setState({ nameChanged: true });
                }
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "node":
                this.setState({ curNodeName: value });
                if (value && this.state.curNodes) {
                    // always only 1 id
                    this.setState({
                        associatedIds: [this.state.curNodes[value].id],
                    });
                }
                this.setState({ subMode: undefined });
                this.setState({ propertySelected: "" });
                this.setState({ curProperties: undefined });
                break;
            case "newValue":
                this.setState({ value: value });
                break;
            case "mode":
                this.setState({ mode: value });
                // reset state
                this.setState({ curProperties: undefined });
                this.setState({ propertySelected: "" });
                this.setState({ subMode: undefined });
                this.setState({ curNodeName: undefined });
                this.setState({ value: undefined });
                this.setState({ unit: undefined });
                break;
            case "subMode":
                this.setState({ subMode: value });
                if (value === PercentChange && this.state.unit !== mPercent) {
                    this.setState({ unit: mPercent });
                }

                this.setState({ value: "" });
                this.resetUnits(this.state.propertySelected, value);
                break;
            case "property":
                this.setState({ value: "" });
                this.setState({ propertySelected: value });
                this.setState({ subMode: undefined });
                if (!value) break;
                // set the unit
                this.resetUnits(value, null);

                // change name to property selected, unless user changed name
                if (!this.state.nameChanged) {
                    let name = value;
                    let dashIndex = value.indexOf(":");
                    if (dashIndex >= 0) name = value.substring(0, dashIndex);
                    this.setState({
                        name: getDefaultName(name, this.props),
                    });
                }

                if (
                    !this.state.curProperties ||
                    !this.state.curProperties[value]
                ) {
                    break;
                }

                this.setState({
                    decisionEngineName: this.state.curProperties[value].deName,
                });

                this.setState({
                    frontEndName: this.state.curProperties[value].feName,
                });

                // accommodates how different modes store property/ associated node data differently
                if (this.state.mode === AnyNode) {
                    this.setState({
                        associatedIds: Array.from(
                            this.state.curProperties[value].nodeIds
                        ),
                    });
                }
                break;
            default:
        }
    };

    resetUnits = (curProperty, subMode) => {
        if (this.state.curProperties && this.state.curProperties[curProperty]) {
            let desiredUnit = this.state.curProperties[curProperty].unit;
            if (!subMode && this.state.unit !== desiredUnit) {
                this.setState({ unit: desiredUnit });
            } else if (
                this.state.unit !== desiredUnit &&
                subMode === ValueReplacer
            ) {
                this.setState({ unit: desiredUnit });
            } else if (
                this.state.unit !== mPercent &&
                subMode === PercentChange
            ) {
                this.setState({ unit: mPercent });
            }
        }
    };

    updateCustomEffectPeriodToggle = () => {
        if (this.state.customEffectPeriod === true) {
            this.setState({ start: null });
            this.setState({ end: null });
        }
        if (this.state.curProperties) {
            const curProperty =
                this.state.curProperties[this.state.propertySelected];
            if (
                curProperty &&
                (curProperty.unit === mDate || curProperty.nonTemporal)
            ) {
                this.onChangeInput(null);
                this.setState({ subMode: null });
            }
        }
        // update the properties list
        if (this.state.mode === AnyNode) {
            this.extractModifiablePropertiesAnyNode(
                !this.state.customEffectPeriod
            );
        } else if (this.state.mode === SpecificNode && this.state.curNodeName) {
            this.extractAndProcessPropertiesSpecificEntity(
                this.state.curNodes,
                !this.state.customEffectPeriod
            );
        }
        this.setState({ customEffectPeriod: !this.state.customEffectPeriod });
    };

    updateRevertValue = (checked) => {
        this.setState({ revertValue: checked });
    };

    onHandleMouseLeave = () => {
        this.props.onhandleFocusedInput(null);
    };

    createBodyPanelSpecificNode() {
        // find all nodes available - display in list; dynamically hide the below fields until each req is met
        let curNodes = this.state.curNodes;
        if (!curNodes) {
            let fetchedNodes = this.searchForNodes();
            this.setState({ curNodes: fetchedNodes });
            curNodes = fetchedNodes;
        }

        let properties = this.state.curProperties;

        if (this.state.curNodeName && !properties) {
            this.extractAndProcessPropertiesSpecificEntity(
                curNodes,
                this.state.customEffectPeriod
            );
        }

        // process specific sub modes
        let specificNodeSubModes = [PercentChange, ValueReplacer];

        if (this.state.unit === mDate) {
            specificNodeSubModes = [ValueReplacer];
        }

        let valueField = this.buildSpecificNodeValueField();
        return (
            <>
                <div className="ModalRow">
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={this.state.curNodeName || ""}
                        options={Object.keys(curNodes)}
                        className="termsContainer"
                        id="node"
                        label="Node"
                    />
                </div>
                {this.state.curNodeName && properties && (
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={this.state.propertySelected || ""}
                            options={Object.keys(properties)}
                            className="termsContainer"
                            id="property"
                            label="Property"
                        />
                    </div>
                )}
                {this.state.curNodeName &&
                    properties &&
                    this.state.propertySelected && (
                        <div className="ModalRow">
                            <SelectDropDown
                                onChangeInput={this.onChangeInput}
                                value={this.state.subMode || ""}
                                options={specificNodeSubModes}
                                className="termsContainer"
                                id="subMode"
                                label="Modifier type"
                            />
                        </div>
                    )}
                {valueField}
            </>
        );
    }

    extractAndProcessPropertiesSpecificEntity(curNodes, customEffectPeriod) {
        if (!curNodes) return;
        let curNode = curNodes[this.state.curNodeName];
        let properties = {};
        let extractedProperties = extractModifiableProperties(curNode);
        // process properties to include value alongside name
        for (let property in extractedProperties) {
            if (
                customEffectPeriod &&
                (extractedProperties[property].nonTemporal ||
                    extractedProperties[property].unit === mDate)
            ) {
                continue;
            }
            let internalName = extractedProperties[property].feName;
            let unit = extractedProperties[property].unit;

            let keyName;
            if (property === "Inflation Rate") {
                let loadedScenario = this.props.loadedScenario;
                if (loadedScenario) {
                    keyName = this.propertyToString(
                        property,
                        loadedScenario.inflation * 100,
                        mPercent
                    );
                }
            } else {
                let val = curNode.metadata[internalName];
                // if (extractedProperties[property].intendedTobeNegative) {
                //     val *= -1;
                // }
                keyName = this.propertyToString(property, val, unit);
            }

            properties[keyName] = extractedProperties[property];
        }
        this.setState({ curProperties: properties });
    }

    propertyToString(property, val, unit) {
        return `${property}: ${getStringValueWithUnit(
            val ? val : "Not Specified",
            unit
        )}`;
    }

    buildSpecificNodeValueField() {
        let valueField;
        if (
            this.state.curNodeName &&
            this.state.subMode &&
            this.state.propertySelected &&
            this.state.curProperties
        ) {
            switch (this.state.subMode) {
                case PercentChange: {
                    valueField = (
                        <div className="ExpenseAmountContainer">
                            <div className="Required">*</div>
                            <Rate
                                label="Percent Change"
                                className="mlsInput"
                                onChangeInput={this.onChangeInput}
                                value={this.state.value || ""}
                                sign={true}
                                id="newValue"
                            />
                        </div>
                    );
                    break;
                }
                case ValueReplacer: {
                    if (!this.state.curProperties[this.state.propertySelected])
                        break;
                    switch (this.state.unit) {
                        case mDate: {
                            let curNode =
                                this.state.curNodes[this.state.curNodeName];
                            let curDate = this.state.value;
                            if (!curDate && curNode) {
                                let feName =
                                    this.state.curProperties[
                                        this.state.propertySelected
                                    ].feName;
                                curDate = curNode.metadata[feName];
                                this.setState({ value: curDate });
                            }

                            valueField = (
                                <div className="ModalRow">
                                    <div className="Required">*</div>
                                    <div className="keyLabel">Date</div>
                                    <SingleCalendar
                                        onHandleDate={this.onHandleDateProperty}
                                        date={curDate}
                                    />
                                </div>
                            );
                            break;
                        }
                        case mPercent: {
                            valueField = (
                                <div className="ExpenseAmountContainer">
                                    <div className="Required">*</div>
                                    <Rate
                                        label="New Value"
                                        className="mlsInput"
                                        onChangeInput={this.onChangeInput}
                                        value={this.state.value || ""}
                                        sign={true}
                                        id="newValue"
                                    />
                                </div>
                            );
                            break;
                        }
                        case mDollar: {
                            valueField = (
                                <div className="ExpenseAmountContainer">
                                    <div className="Required">*</div>
                                    <Value
                                        label="New Value"
                                        className="mlsInput"
                                        onChangeInput={this.onChangeInput}
                                        value={this.state.value || ""}
                                        sign={true}
                                        id="newValue"
                                    />
                                </div>
                            );
                            break;
                        }
                        default:
                    }
                    break;
                }
                default:
            }
        }
        return valueField;
    }

    createBodyPanelAnyNode() {
        let properties = this.state.curProperties;
        if (!this.state.curProperties) {
            properties = this.extractModifiablePropertiesAnyNode(
                this.state.customEffectPeriod
            );
        }

        if (this.state.subMode !== PercentChange) {
            this.setState({ subMode: PercentChange });
        }
        if (this.state.unit !== mPercent) {
            this.setState({ unit: mPercent });
        }

        return (
            <>
                <div className="ModalRow">
                    <div className="Required">*</div>
                    <SelectDropDown
                        onChangeInput={this.onChangeInput}
                        value={this.state.propertySelected}
                        options={Object.keys(properties)}
                        className="termsContainer"
                        id="property"
                        label="Property"
                    />
                </div>
                <div className="ExpenseAmountContainer">
                    <div className="Required">*</div>
                    <Rate
                        label="Percent Change"
                        className="mlsInput"
                        onChangeInput={this.onChangeInput}
                        value={this.state.value}
                        sign={true}
                        id="newValue"
                    />
                </div>
            </>
        );
    }

    extractModifiablePropertiesAnyNode(customEffectPeriod) {
        let properties = this.searchForProperties();
        let filteredEntries = Object.entries(properties).filter(
            ([_key, data]) => {
                if (customEffectPeriod && data.nonTemporal) return false;
                return data.unit !== mDate;
            }
        );
        properties = Object.fromEntries(filteredEntries);
        this.setState({ curProperties: properties });
        return properties;
    }

    verifyDateBounds = (value) => {
        if (this.state.unit === "Date") {
            let curNode = this.state.curNodes[this.state.curNodeName];
            if (curNode && curNode.metadata.end) {
                let curPropertyName =
                    this.state.curProperties[this.state.propertySelected]
                        .feName;
                if (
                    curPropertyName === "end" &&
                    curNode.metadata.start > value
                ) {
                    throwError(
                        "warning",
                        "Property end date is before start date."
                    );
                    return false;
                } else if (
                    curPropertyName === "start" &&
                    curNode.metadata.end < value
                ) {
                    throwError(
                        "warning",
                        "Property start date is after end date."
                    );
                    return false;
                }
            }
        }
        return true;
    };

    onHandleDateProperty = (startDate) => {
        this.verifyDateBounds(startDate);
        this.setState({ value: startDate });
    };

    onHandleEffectPeriod = (startDate, endDate) => {
        this.setState({ start: startDate, end: endDate });
    };

    scrollToBottom = () => {
        setTimeout(() => {
            this.inputEnd.scrollIntoView({
                behavior: "smooth",
            });
        });
    };

    render() {
        const {
            name,
            description,
            start,
            end,
            customEffectPeriod,
            mode,
            revertValue,
        } = this.state;
        const { edit } = this.props;

        const modes = [SpecificNode, AnyNode];

        let bodyPanel;
        switch (this.state.mode) {
            case SpecificNode: {
                bodyPanel = this.createBodyPanelSpecificNode();
                break;
            }
            case AnyNode: {
                bodyPanel = this.createBodyPanelAnyNode();
                break;
            }
            default:
                bodyPanel = null;
        }

        let passedCheck = modifierObject.checkInput(this.state);

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={modifierObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">MODIFIER</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>
                    <div className="ModalRow">
                        <ModalRowHalf>
                            <Switch
                                id="customEffectPeriod"
                                checked={customEffectPeriod}
                                className="InflationToggle"
                                onChange={this.updateCustomEffectPeriodToggle}
                                height={20}
                                width={40}
                                onColor="#F8B46A"
                            />
                            <div className="InflationText">
                                Custom effect period
                            </div>
                        </ModalRowHalf>
                        {customEffectPeriod && (
                            <ModalRowHalf>
                                <Switch
                                    className="InflationToggle"
                                    height={20}
                                    width={40}
                                    onColor="#F8B46A"
                                    checked={revertValue}
                                    onChange={this.updateRevertValue}
                                />
                                <div className="InflationText">
                                    Revert value on end date
                                </div>
                            </ModalRowHalf>
                        )}
                    </div>
                    {customEffectPeriod && (
                        <div className="ModalRow">
                            <div className="CalendarContainer">
                                <Calendar
                                    onHandleDate={this.onHandleEffectPeriod}
                                    startDate={start}
                                    endDate={end}
                                />
                            </div>
                        </div>
                    )}
                    <div className="ModalRow">
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={mode}
                            options={modes}
                            className="termsContainer"
                            id="mode"
                            label="Choose Mode"
                        />
                    </div>

                    {bodyPanel}

                    {/* dummy div to enable autoscroll when adding extra fields */}
                    <div
                        ref={(el) => {
                            this.inputEnd = el;
                        }}
                    />
                </div>

                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            { curNodes: undefined },
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </div>
        );
    }
}

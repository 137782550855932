import { useEffect, useRef, useState } from "react";

interface useDimensionOptions {
    cancelTime: number;
}

const defaultOption: useDimensionOptions = {
    cancelTime: 50,
};

/**
 * Listens to change in size of an element. Useful if you need to keep track of an element's size and set width/height of something through JavaScript.
 */
export function useDimension(
    element: HTMLElement | null,
    { cancelTime }: useDimensionOptions = defaultOption
) {
    const cancelTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [dimensions, setDimensions] = useState<[number, number]>([
        element?.offsetWidth || 0,
        element?.offsetHeight || 0,
    ]);

    useEffect(() => {
        if (element) {
            new ResizeObserver((resizeObserverEntries, _resizeObserver) => {
                if (cancelTimeoutRef.current) {
                    // console.count("Canceled timeout");
                    clearTimeout(cancelTimeoutRef.current);
                }

                cancelTimeoutRef.current = setTimeout(() => {
                    // console.count("Resize observer running!");
                    resizeObserverEntries.forEach((entry) => {
                        const resizedElement = entry.target as HTMLElement;
                        setDimensions([
                            resizedElement.offsetWidth,
                            resizedElement.offsetHeight,
                        ]);
                    });
                }, cancelTime);
            }).observe(element);
        }
    }, [element, cancelTime]);

    return dimensions;
}

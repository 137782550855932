import { useActionsData } from "Components/EventsCanvas/Experimental/CanvasStage/hooks/useActionsData";
import { useContext } from "react";
import {
    EventNodeBypassedContext,
    EventNodeLockedContext,
    HasCopiedEventContext,
    HasSelectedConnectionContext,
    HasMultipleChildrenContext,
    HasNoChildrenContext,
    EventTypeIsDecisionContext,
    EventTypeIsContainerContext,
} from "../../../../contexts/EventNodeStateContexts";
import { getActionActive } from "./helpers/getActionActive";
import { EventNodeIDContext } from "../../../../contexts/EventNodeIDContext";
import { getEvent, getSingleEntity } from "actions/getNodeEntityActions";

/**
 * Finds the active image for a menuIcon given the passed buttonId.
 */
export function useIconImage(buttonId: string): string {
    const actionData = useActionsData(buttonId);
    const eventId = useContext(EventNodeIDContext);
    const locked = useContext(EventNodeLockedContext);
    const bypassed = useContext(EventNodeBypassedContext);
    const hasCopiedEvent = useContext(HasCopiedEventContext);
    const hasSelectedConnection = useContext(HasSelectedConnectionContext);
    const hasMultipleChildren = useContext(HasMultipleChildrenContext);
    const hasNoChildren = useContext(HasNoChildrenContext);
    const typeIsDecision = useContext(EventTypeIsDecisionContext);
    const typeIsContainer = useContext(EventTypeIsContainerContext);

    const getExpandedState = () => {
        if (eventId && typeIsContainer) {
            const containerNode = getEvent(eventId);
            const containerEntity = getSingleEntity(
                containerNode?.entities?.[0] ?? ""
            );
            const containerExpanded = containerEntity?.data?.expanded;

            return containerExpanded ?? false;
        }
        return false;
    };

    const hasRelevantContainer = () => {
        const event: any = getEvent(eventId);
        if (event && event?.relevantContainerId) return true;
        return false;
    };

    const active = getActionActive(
        actionData?.action,
        typeIsDecision,
        typeIsContainer,
        bypassed,
        locked,
        !hasRelevantContainer() && hasCopiedEvent,
        hasSelectedConnection,
        hasRelevantContainer() || hasMultipleChildren,
        hasNoChildren,
        getExpandedState()
    );

    if (active) {
        return actionData?.activeIconUrl;
    } else {
        return actionData?.inactiveIconUrl;
    }
}

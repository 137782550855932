import { createContext } from "react";

export const EventDragActiveContext = createContext<boolean>(false);
EventDragActiveContext.displayName = "EventDragActiveContext";

export const SetEventDragActiveContext = createContext<
    React.Dispatch<React.SetStateAction<any>>
>(() => {
    //no op
});
SetEventDragActiveContext.displayName = "SetEventDragActiveContext";

export const DraggedEventTempXContext = createContext<number>(0);
DraggedEventTempXContext.displayName = "DraggedEventTempXContext";

export const SetDraggedEventTempXContext = createContext<
    React.Dispatch<React.SetStateAction<any>>
>(() => {
    //no op
});
SetDraggedEventTempXContext.displayName = "SetDraggedEventTempXContext";

export const DraggedEventTempYContext = createContext<number>(0);
DraggedEventTempYContext.displayName = "DraggedEventTempYContext";

export const SetDraggedEventTempYContext = createContext<
    React.Dispatch<React.SetStateAction<any>>
>(() => {
    //no op
});
SetDraggedEventTempYContext.displayName = "SetDraggedEventTempYContext";

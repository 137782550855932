import React from "react";
import styles from "./index.module.css";
import expandSvg from "../../Assets/scenarioIcons/expand.svg";
import collapseSvg from "../../Assets/scenarioIcons/collapse.svg";
// import emptySvg from "../../Assets/_onboarding/empty.svg";
import rightArrow from "../../Assets/_optionDescriptionIcons/rightArrow.svg";
import leftArrow from "../../Assets/_optionDescriptionIcons/leftArrow.svg";
// import fullSvg from "../../Assets/_onboarding/full.svg";
// import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
// import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";
// import bigComingSoonImage from "../../Assets/_optionDescriptionIcons/bigComingSoon.png";
// import comingSoonImage from "../../Assets/_optionDescriptionIcons/comingSoon.png";
import templateAdd from "../../Assets/_optionDescriptionIcons/templateAdd.svg";

export const templateCategories = [
    // { name: "Real Estate", id: "realEstate" },
    // { name: "Childcare", id: "childcare" },
    // { name: "Travel", id: "travel" },
    // { name: "Family", id: "family" },
    // { name: "Education", id: "education" },
    // { name: "Career", id: "career" },
    // { name: "Health", id: "health" },
    // { name: "Career Change", id: "careerChange" },
    // { name: "Retirement", id: "retirement" },
    // { name: "Debt Management", id: "debtManagement" },
    // { name: "Investing", id: "investing" },
    // { name: "Relocation", id: "relocation" },
    // { name: "Transportation", id: "transportation" },
    // { name: "Relationship Change", id: "relationshipChange" },
    // { name: "Student", id: "student" },
    { name: "Tutorial", id: "tutorial" },
    { name: "Business", id: "business" },
    { name: "SasS", id: "sass" },
    { name: "E-Commerce", id: "eCommerce" },
    { name: "Staffing", id: "staffing" },
    { name: "Debt Management", id: "debtManagement" },
    { name: "Pricing", id: "pricing" },
];
export default class Template extends React.Component {
    constructor(props) {
        super(props);
        this.scroller = React.createRef();
        this.state = {
            step: 0,
            templateList: this.getTemplateList(this.props.scenarioTemplates),
            selectedTemplate: null,
            selected: null,
        };
    }

    getTemplateList = (templates) => {
        return templateCategories.map((category) => {
            const cards = this.getTemplateCards(category.id, templates);
            return {
                ...category,
                templates: cards,
            };
        });
    };
    getTemplateCards = (categoryId, templates) => {
        return templates
            .filter((template) => {
                return (
                    template &&
                    template.categories.includes(categoryId) &&
                    template.is_template === true
                );
            })
            .map((template) => {
                return {
                    id: template.id,
                    name: template.name,
                    image: this.getCardImage(template.id),
                    bigImage: this.getBigCardImage(template.id),
                    templateId: template.id,
                    description: template.description,
                };
            });
    };

    getCardImage = (_id) => {
        return templateAdd;
        //outdated code
        // switch (id) {
        //     // RENT VS BUY
        //     case "dc6823d0-5b51-484b-b4db-9d88c423b56e":
        //         return rentVsBuyImage;
        //     default:
        //         return comingSoonImage;
        // }
    };

    getBigCardImage = (_id) => {
        return templateAdd;
        //outdated code
        // switch (id) {
        //     // RENT VS BUY
        //     case "d59df101-5623-4d75-9f59-331dd44df1ec":
        //         return bigRentVsBuyImage;
        //     default:
        //         return bigComingSoonImage;
        // }
    };

    handleArrowClick = (direction, id) => {
        const el = document.getElementById(id);
        if (el) {
            if (direction === "right") {
                el.scrollLeft += 275;
            } else {
                el.scrollLeft -= 275;
            }
        }
    };

    // handleLeftClick = (_templates) => {
    //     if (this.state.step === 0) {
    //         return null;
    //     } else {
    //         this.setState({ step: this.state.step - 1 });
    //         return this.scroller ? (this.scroller.scrollLeft -= 280) : null;
    //     }
    // };

    // handleRightClick = (templates) => {
    //     if (this.state.step < templates.length - 1) {
    //         this.setState({ step: this.state.step + 1 });
    //         return this.scroller ? (this.scroller.scrollLeft += 280) : null;
    //     } else {
    //         return null;
    //     }
    // };

    onHandleSelectTemplate = (id) => {
        if (this.props.selectedTemplateData) {
            this.props.updateSelectedTemplate(this.props.selectedTemplateData)
        }

        if (this.state.selectedTemplate && this.state.selectedTemplate === id) {
            this.setState({
                selectedTemplate: null,
            });
        } else {
            this.setState({
                selectedTemplate: id,
            });
        }
    };

    render() {
        const {selectedTemplateData} = this.props
        const { templateList, _step, selectedTemplate} =
            this.state;
        return (
            <div className={styles.customContainer}>
                <div className={styles.templateHeader}>Select Template</div>
                <div className={styles.inputRoot}>
                    {templateList &&
                        templateList.map((list, i) => {
                            const selected =
                                selectedTemplate &&
                                selectedTemplate === list.id;
                            return (
                                <div className={styles.templateItem} key={i}>
                                    <div
                                        onClick={() =>
                                            this.onHandleSelectTemplate(list.id)
                                        }
                                        className={styles.templateList}
                                    >
                                        {list.name} ({list.templates.length})
                                        <img
                                            alt="alt"
                                            className={styles.templateToggle}
                                            src={
                                                selected
                                                    ? collapseSvg
                                                    : expandSvg
                                            }
                                        />
                                    </div>
                                    {selected && list.templates.length > 0 && (
                                        <div
                                            className={
                                                styles.templateSelectedContainer
                                            }
                                        >
                                            {/* <div className={styles.regularText}>
                                                {step + 1} of{" "}
                                                {list.templates.length}
                                            </div> */}
                                            <img
                                                className={styles.arrow}
                                                onClick={() =>
                                                    this.handleArrowClick(
                                                        "left",
                                                        list.id
                                                    )
                                                }
                                                src={leftArrow}
                                                alt="alt"
                                            />
                                            <div
                                                id={list.id}
                                                ref={this.scroller}
                                                className={
                                                    styles.templateContainer
                                                }
                                            >
                                                {list.templates.length > 0 &&
                                                    list.templates.map(
                                                        (template, i) => {
                                                            const selectedTemplate =
                                                                selectedTemplateData &&
                                                                selectedTemplateData.name ===
                                                                    template.name;
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    onClick={() =>
                                                                        this.props.updateSelectedTemplate(
                                                                            template
                                                                        )
                                                                    }
                                                                    className={`${
                                                                        selectedTemplate
                                                                            ? `${styles.innerContainer} ${styles.innerContainerActive}`
                                                                            : `${styles.innerContainer}`
                                                                    }`}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.templateHeaderContainer
                                                                        }
                                                                    >
                                                                        <h3
                                                                            className={
                                                                                styles.templateTitle
                                                                            }
                                                                        >
                                                                            {
                                                                                template.name
                                                                            }
                                                                        </h3>
                                                                    </div>
                                                                    <aside
                                                                        className={
                                                                            styles.templateAside
                                                                        }
                                                                    >
                                                                        <figure
                                                                            className={
                                                                                styles.templateFigure
                                                                            }
                                                                        >
                                                                            <img
                                                                                className={
                                                                                    styles.templateImage
                                                                                }
                                                                                alt="Plus inside dotted circle"
                                                                                src={
                                                                                    template.image
                                                                                }
                                                                            />
                                                                        </figure>
                                                                    </aside>
                                                                    <div
                                                                        className={
                                                                            styles.templateDescriptionContainer
                                                                        }
                                                                    >
                                                                        <p
                                                                            className={
                                                                                styles.templateDescription
                                                                            }
                                                                        >
                                                                            {
                                                                                template.description
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                            <img
                                                className={styles.arrow}
                                                onClick={() =>
                                                    this.handleArrowClick(
                                                        "right",
                                                        list.id
                                                    )
                                                }
                                                src={rightArrow}
                                                alt="alt"
                                            />
                                            {/* <div className={styles.sliderContainer}>
                                                <img
                                                    alt="alt"
                                                    onClick={() => this.handleArrowClick("left", list.id)}
                                                    // onClick={() =>
                                                    //     this.handleLeftClick(
                                                    //         list.templates
                                                    //     )
                                                    // }
                                                    className={styles.arrow}
                                                    src={leftArrow}
                                                /> */}
                                            {/* {list.templates.map(
                                                    (dot, i) => {
                                                        if (dot.id === step) {
                                                            return (
                                                                <img
                                                                    alt="alt"
                                                                    className={
                                                                        styles.dots
                                                                    }
                                                                    src={
                                                                        fullSvg
                                                                    }
                                                                    key={i}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <img
                                                                    alt="alt"
                                                                    className={
                                                                        styles.dots
                                                                    }
                                                                    src={
                                                                        emptySvg
                                                                    }
                                                                    key={i}
                                                                />
                                                            );
                                                        }
                                                    }
                                                )} */}
                                            {/* <img
                                                    alt="alt"
                                                    onClick={() => this.handleArrowClick("right", list.id)}
                                                    // onClick={() =>
                                                    //     this.handleRightClick(
                                                    //         list.templates
                                                    //     )
                                                    // }
                                                    className={styles.arrow}
                                                    src={rightArrow}
                                                />
                                            </div> */}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

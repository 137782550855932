import React, { Component } from "react";
import "./Card.css";
import {
    DownpaymentAmount,
    DownpaymentRate,
} from "../../helpers/downpaymentHelpers";

class DownpaymentCard extends Component {
    state = {
        showSettings: false,
    };

    render() {
        const {
            description,
            downpayment,
            downpaymentRate,
            lastChangedDownpaymentProperty,
            version,
        } = this.props.data.metadata;
        return (
            <div className="Container">
                <div className="Row">
                    <div
                        className={
                            description ? "Description" : "NoDescription"
                        }
                    >
                        {description}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                {lastChangedDownpaymentProperty === DownpaymentAmount && (
                    <div className="Row">
                        <div className="ExpenseLabel">Downpayment:</div>
                        <div className="cardNumber">
                            {downpayment
                                ? `$ ${Math.round(
                                      Number(downpayment)
                                  ).toLocaleString("en")}`
                                : ""}
                        </div>
                    </div>
                )}
                {lastChangedDownpaymentProperty === DownpaymentRate && (
                    <div className="Row">
                        <div className="ExpenseLabel">Downpayment %:</div>
                        <div className="cardValue">
                            {downpaymentRate
                                ? `${Number(downpaymentRate).toFixed(2)} %`
                                : ""}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default DownpaymentCard;

// Default Imports
import React from "react";

// Import Rental Mortgage Card and Rental Mortgage Input
import MortgageCard from "../Card/mortgageCard.js";
import MortgageInput from "../InputContainer/MortgageInput.js";

// Import Coloured PNGs
import mortgageBlack from "../../Assets/_eventNodeTypes/mortgage-black.png";
import mortgageBlue from "../../Assets/_eventNodeTypes/mortgage-blue.png";
import mortgageWhite from "../../Assets/_eventNodeTypes/mortgage-white.png";
import mortgageGrey from "../../Assets/_eventNodeTypes/mortgage-grey.png";
import { valueDoesNotExist } from "helpers/index.js";
import rentVsBuyImage from "../../Assets/_optionDescriptionIcons/rentOrBuy.png";
import bigRentVsBuyImage from "../../Assets/_optionDescriptionIcons/bigRentVsBuy.png";
import mortgageConnectionImg from "../../Assets/_optionDescriptionIcons/Mortgage, Renewal Refinance, Mortgage Insurance.png";
import bigComingSoonImage from "../../Assets/_optionDescriptionIcons/bigComingSoon.png";
import comingSoonImage from "../../Assets/_optionDescriptionIcons/comingSoon.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/CommercialRealEstate2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - mortgageObject.constant()

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - mortgageObject.checkInput.call(mortgageObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Rental Mortgage
// THIS IS FOR MORTGAGE ALT
export const mortgageObject = {
    constant: function () {
        return Mortgage_Constant();
    },

    checkInput: function (state) {
        return Mortgage_CheckInput(state);
    },

    svg: function () {
        return Mortgage_Svg();
    },

    eventNodeMenu: function (focus) {
        return Mortgage_EventNodeMenu(focus);
    },

    indexText: function () {
        return Mortgage_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Mortgage_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Mortgage_OptionDescription();
    },

    colouredPngs: function () {
        return Mortgage_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Mortgage_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Rental Mortgage

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Mortgage_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (!state["amortization period"]) return false;
    if (valueDoesNotExist(state.rate)) return false;
    if (!state.isNew && valueDoesNotExist(state.price)) return false;
    if (!state["mortgage term"]) return false;
    if (!state.isNew && valueDoesNotExist(state["first payment"])) return false;
    if (state.isNew && valueDoesNotExist(state.downpayment)) return false;
    if (isNaN(state.payments)) return false;

    if (state.rrspContributionOne > state.rrspMaxOne) return false;
    if (state.rrspContributionTwo > state.rrspMaxTwo) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const Mortgage_Constant = () => {
    return "Mortgage";
};

// EFFECTS: Returns svg for event
const Mortgage_Svg = () => {
    return mortgageBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Mortgage_EventNodeMenu = (focus) => {
    return <MortgageCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Mortgage_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Mortgage calculations.";
};

// EFFECTS: Returns all icons for event ??
const Mortgage_Icons = () => {
    let pngs = new Map([
        ["black", mortgageBlack],
        ["blue", mortgageBlue],
        ["white", mortgageWhite],
        ["grey", mortgageGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Mortgage_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
// NOT USED, NEED TO FIGURE OUT HOW TO ACCEPT MULTIPLE FIELDS
const Mortgage_InputComponentInfoPassing = (minProps) => {
    return <MortgageInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const Mortgage_OptionDescription = () => {
    const tags = ["Option"];
    const type = mortgageObject.constant();
    const description =
        "A home is one of the largest decisions we will ever make. Calculate mortgage payments, principal and interest amounts on a potential or current home or property. This node is specific to Canada.";
    const usedForText =
        "Estimating the main cost of purchasing a home in Canada, refinancing your current home, rent vs buy estimates, income properties, relocation, insurance, and calculating long-term net worth.";
    const connection =
        "Connect a Mortgage Node after a House Node. This node will inherit values and properties from the House Node to calculate costs.";
    const connectionImage = mortgageConnectionImg;
    const learnMoreLink =
        "https://www.canada.ca/en/financial-consumer-agency/services/mortgages.html";
    const templates = [
        {
            id: 0,
            name: "Rent vs Buy",
            image: rentVsBuyImage,
            bigImage: bigRentVsBuyImage,
            description: "This is an onboarding scenario.",
            templateId: "RentVsBuy",
        },
        {
            id: 1,
            name: "Refinance Your Home",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 2,
            name: "Purchasing an Income Property",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 3,
            name: "Relocating",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 4,
            name: "Adding a Rental Suite",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
        {
            id: 5,
            name: "Listing on AirBnB",
            image: comingSoonImage,
            bigImage: bigComingSoonImage,
            description: "Coming soon.",
            templateId: "comingSoon",
        },
    ];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Mortgage_SideModalImage = () => {
    return sideImage;
};

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

export const projectInputsHandler = (
    value: string,
    id:
        | "entityName"
        | "select-event"
        | "select-entity"
        | "offset"
        | "multiplier"
        | "duration",
    mainId: string,
    index: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };
    const selectionItems = [
        ...newEntitiesMap[currentEntity].data.selectionItems,
    ];
    const item = index === undefined ? undefined : selectionItems[index];

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "select-event":
        case "select-entity":
            item.selectedItem = mainId;
            selectionItems[index] = item;
            data.selectionItems = selectionItems;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "offset":
            item.offset = value;
            selectionItems[index] = item;
            data.selectionItems = selectionItems;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "multiplier":
            item.multiplier = value;
            selectionItems[index] = item;
            data.selectionItems = selectionItems;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "duration":
            item.duration = value;
            selectionItems[index] = item;
            data.selectionItems = selectionItems;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
};

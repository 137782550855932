// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import EntityStructureData from "../../../exampleConfigJson/entityTypesStructure.json";

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EntityCard,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    EntityName,
    Rate,
} from "../Components";

import { customer2Object } from "../../Registry/Customer2";
import SingleCalendar from "../../Calendar/singleCalendar";
import { useState } from "react";

export default function Customer2InputView({
    entitiesMap,
    currentEntity,
    entityIndex,
    handleClickAddEntityCard,
    handleClickChangeEntity,
    handleClickDeleteEntity,
    handleClickDuplicateEntity,
    entitiesLength,
    eventData,
    onChangeNameDescription,
    onHandleDate,
    passedCheck,
    onHandleClickSubmitOrUpdate,
    edit,
    handleEntityStateChange,
    handleOnChange,
}) {
    const [creatingCustomAccounts, setCreatingCustomAccounts] = useState(false);

    const entityData = entitiesMap[currentEntity]?.data ?? {};

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={customer2Object.name()}
                    onChangeInput={onChangeNameDescription}
                    image={customer2Object.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <EntityCard
                handleClickChangeEntity={handleClickChangeEntity}
                handleClickDeleteEntity={handleClickDeleteEntity}
                handleClickDuplicateEntity={handleClickDuplicateEntity}
                handleClickAddEntityCard={() =>
                    handleClickAddEntityCard(
                        EntityStructureData[customer2Object.constant()]
                    )
                }
                entityIndex={entityIndex}
                entitiesLength={entitiesLength}
                passedCheck={passedCheck}
            >
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                {creatingCustomAccounts && (
                    <div>Creating custom accounts...</div>
                )}
                {!creatingCustomAccounts && (
                    <>
                        <ModalRow>
                            <ModalRowHalf>
                                <Rate
                                    label="Initial Customer Count"
                                    onChangeInput={handleOnChange}
                                    value={entityData.customer}
                                    id="customer"
                                    className="mlsInput"
                                    sign={false}
                                />
                            </ModalRowHalf>
                        </ModalRow>
                        <ModalRow>
                            <RequiredStar />
                            <SingleCalendar
                                onHandleDate={onHandleDate}
                                date={entitiesMap?.[currentEntity]?.startDate}
                                entityIndex={entityIndex}
                            />
                        </ModalRow>
                    </>
                )}
            </EntityCard>
            <InputButtons
                edit={edit}
                updateValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate(false);
                }}
                onHandleSubmitValues={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate(false);
                }}
                onHandleSubmit={() => {
                    setCreatingCustomAccounts(true);
                    onHandleClickSubmitOrUpdate(true);
                }}
                passedCheck={creatingCustomAccounts ? false : passedCheck}
            />
        </EventEntityWrapper>
    );
}

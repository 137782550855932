import Konva from "konva";
import { useContext, useMemo, useRef } from "react";
import { Group } from "react-konva";
import { animated, useSpring } from "@react-spring/konva";
import {
    // EventNodeActiveContext,
    // EventNodeBypassedContext,
    // EventNodeErrorContext,
    EventNodeFocusedContext,
    EventTypeIsContainerContext,
    // EventNodeLockedContext,
    EventTypeIsDecisionContext,
    HasNoChildrenContext,
} from "Components/EventsCanvas/Experimental/CanvasStage/partials/CanvasEvents/CanvasEvent/contexts/EventNodeStateContexts";
// import { CanvasStageZoomCategoryContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageZoomCategoryContext";
import { CanvasStageScaleContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasStageScaleContext";
import useImage from "use-image";
import plusIconPath from "Assets/_nodeIcons/plus.svg";
// import plusIconGreyPath from "Assets/_nodeIcons/plusGrey.svg";
import branchIconPath from "Assets/_nodeIcons/branch.svg";
import { useAppDispatch } from "store/useAppSelectorDispatch";
import { EventActionIds, eventActionsMap } from "actions/canvasEventActions";
import { EventNodeIDContext } from "../../contexts/EventNodeIDContext";
import { SetHoverButtonIdContext } from "../../contexts/HoverButtonIdContext";
import { CanvasBaselineContext } from "Components/EventsCanvas/Experimental/CanvasStage/contexts/CanvasBaselineContext";

const addActionId = "addEvent:18e1e922-1129-4989-83e4-4c33b6215f46";
const branchActionId = "branchEvent:2c1585bf-801e-4e20-88ec-653a2d1b4f8f";

export function PlusButton() {
    const isBaseline = useContext(CanvasBaselineContext);
    const eventId = useContext(EventNodeIDContext);
    const setHoverButtonId = useContext(SetHoverButtonIdContext);
    const typeIsDecision = useContext(EventTypeIsDecisionContext);
    const typeIsContainer = useContext(EventTypeIsContainerContext);
    const hasNoChildren = useContext(HasNoChildrenContext);
    // const active = useContext(EventNodeActiveContext);
    const focused = useContext(EventNodeFocusedContext);
    // const locked = useContext(EventNodeLockedContext);
    // const bypassed = useContext(EventNodeBypassedContext);
    // const error = useContext(EventNodeErrorContext);
    // const zoomCategory = useContext(CanvasStageZoomCategoryContext); will need this for the container styles
    const stageScale = useContext(CanvasStageScaleContext);

    const dispatch = useAppDispatch();

    const imagePath = useMemo(() => {
        if (!hasNoChildren && typeIsDecision) {
            return branchIconPath;
        } else if (hasNoChildren) {
            return plusIconPath;
        } else {
            return plusIconPath;
        }
    }, [hasNoChildren, typeIsDecision]);

    const [plusIcon] = useImage(imagePath);

    const containerStyles = useMemo(() => {
        const _containerStyles = {
            x: 50,
            y: -50,
        };
        return _containerStyles;
    }, []);

    const backgroundStyles = useMemo(() => {
        let _backgroundStyles = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            cornerRadius: [50, 50, 50, 50],
            fill: "#f7ab5f",
        };
        if (focused) {
            _backgroundStyles = {
                ..._backgroundStyles,
                x: -15,
                y: -15,
                width: 30,
                height: 30,
            };
        }
        if (!hasNoChildren && !typeIsDecision && !typeIsContainer) {
            _backgroundStyles = {
                ..._backgroundStyles,
                fill: "#AEAEAE",
            };
        }
        return _backgroundStyles;
    }, [focused, hasNoChildren, typeIsDecision, typeIsContainer]);

    const backgroundAnimation = useMemo(() => {
        const _backgroundAnimation = {};
        return _backgroundAnimation;
    }, []);

    const animatedBackgroundStyles = useSpring<Konva.RectConfig>({
        to: backgroundStyles,
        config: backgroundAnimation,
    });

    const iconStyles = useMemo(() => {
        let _iconStyles = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
        if (focused) {
            _iconStyles = {
                x: -15,
                y: -15,
                width: 30,
                height: 30,
            };
        }
        return _iconStyles;
    }, [focused]);

    const iconAnimation = useMemo(() => {
        const _iconAnimation = {};
        return _iconAnimation;
    }, []);

    const animatedIconStyles = useSpring<Konva.RectConfig>({
        to: iconStyles,
        config: iconAnimation,
    });

    const prevCursorStyle = useRef("");
    const handleMouseEnter = (e) => {
        const container = e?.target?.getStage()?.container();
        if (container) {
            prevCursorStyle.current = container?.style?.cursor;
            container.style.cursor = "pointer";
        }
        if (!hasNoChildren && typeIsDecision) {
            setHoverButtonId(branchActionId);
        } else {
            setHoverButtonId(addActionId);
        }
    };

    const handleMouseLeave = (e) => {
        const container = e?.target?.getStage()?.container();
        if (container) container.style.cursor = prevCursorStyle?.current;
        setHoverButtonId("");
    };

    const handleClickPlusButton = () => {
        dispatch(
            eventActionsMap?.[EventActionIds?.addEvent](eventId, isBaseline)
        );
    };

    return (
        <Group
            {...containerStyles}
            scaleX={1 / stageScale}
            scaleY={1 / stageScale}
            onMouseEnter={(e) => handleMouseEnter(e)}
            onMouseLeave={(e) => handleMouseLeave(e)}
            onClick={() => handleClickPlusButton()}
        >
            {/* @ts-ignore - Seems to be a react spring type error */}
            <animated.Rect {...animatedBackgroundStyles} />
            <animated.Image {...animatedIconStyles} image={plusIcon} />
        </Group>
    );
}

import BillingContainer from "./billingContainer";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";

const mapStateToProps = (state) => {
    return {
        loadedScenario: state.scenario.loadedScenario,
        accountData: state.scenario.accountData,
        userScenarios: state.scenario.userScenarios,
        archivedScenarios: state.scenario.archivedScenarios,
    };
};

const mapDispatchToProps = {
    shareScenario: actions.shareScenario,
    createSubscription: actions.createSubscription,
    getUserData: actions.getUserData,
    saveUserDetails: actions.saveUserDetails,
    archiveScenario: actions.archiveScenario,
    updateScenario: actions.updateScenario,
    reactivateArchivedScenario: actions.reactivateArchivedScenario,
    shareByEmail: actions.shareByEmail,
    updateAccountData: actions.updateAccountData,
    getAllPlans: actions.getAllPlans,
    getSubscription: actions.getSubscription,
    launchAddCard: actions.launchAddCard,
    openPortal: actions.openPortal,
};

const Billing = connect(mapStateToProps, mapDispatchToProps)(BillingContainer);

export default Billing;

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";
import moment from "moment";

export const employeeInputsHandler = (
    value: string,
    id:
        | "personName"
        | "rate"
        | "entityName"
        | "bypassState"
        | "startDate"
        | "endDate"
        | "accountName"
        | "cadence"
        | "rating"
        | "averageHours"
        | "salary"
        | "noEmployees"
        | "payType"
        | "employeeState"
        | "displayFinancialData"
        | "expenseType",
    star: number,
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    account?: { name: string; ids: string[] }
) => {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    const data = { ...(currentEntityObject?.data || {}) };

    switch (id) {
        case "entityName":
            currentEntityObject.name = value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "bypassState":
            currentEntityObject.bypassState = !!value;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "personName":
            data.role = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "startDate":
            const startDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.startDate = startDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "endDate":
            const endDateString = value
                ? moment(value).format("YYYY-MM-DD")
                : "";
            currentEntityObject.endDate = endDateString;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "accountName":
            if (!account) break;
            data.accountName = account.name ?? "";
            data.accountIds = account.ids ?? [];
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rating":
            data.rating = star;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "employeeState":
            data.employeeState = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "payType":
            data.payType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "rate":
            data.rate = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "averageHours":
            data.averageHours = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "salary":
            data.salary = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "noEmployees":
            data.noEmployees = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "cadence":
            if (value === "") {
                currentEntityObject.cadence = "";
                newEntitiesMap[currentEntity] = currentEntityObject;
            } else {
                currentEntityObject.cadence = value;
                newEntitiesMap[currentEntity] = currentEntityObject;
            }
            break;
        case "expenseType":
            data.expenseType = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "displayFinancialData":
            data.displayFinancialData = value;
            currentEntityObject.data = data;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
        //noop
    }
    return newEntitiesMap;
};

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/scenario";
import { Typography, Container, Box, Button, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StudentPic from "Assets/_home/student.jpg";
import { LOG_IN, SIGN_UP, STUDENT } from "../../helpers/constants";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: `url(${StudentPic})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100vw",
        maxWidth: "none",
        margin: "0",
        padding: "6rem",
        [theme.breakpoints.down("sm")]: {
            backgroundPosition: "89% 10%",
            backgroundSize: "auto 120%",
            padding: "40px",
        },
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginRight: "30vw",
        [theme.breakpoints.down("sm")]: {
            margin: "0 0 300px 0",
            alignItems: "center",
        },
        position: "relative",
    },
    heroTextWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        [theme.breakpoints.down("sm")]: {
            alignItems: "center",
        },
    },
    heroTitle: {
        fontSize: "64px",
        marginBottom: "12px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "48px",
            marginBottom: "24px",
        },
    },
    heroSubtitle: {
        fontSize: "32px",
        marginBottom: "2.875rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "28px",
            marginBottom: "2rem",
        },
    },
    ctaButton: {
        background: "linear-gradient(45deg, #fff1da 30%, #f8eedc 90%)",
        border: 0,
        borderRadius: 3,
        color: "black",
        height: "3rem",
        padding: "0 1.875rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            width: "11rem",
            padding: "0 1rem",
            height: "2.5rem",
        },
    },
    ctaButtonsWrapper: {
        display: "flex",
        alignItems: "center",
    },
    loginTextCtaWrapper: {
        display: "flex",
        marginLeft: "16px",
    },
    loginCtaButton: {
        marginLeft: "16px",
        textDecoration: "underline",
        cursor: "pointer",
    },
    mobileBannerWrapper: {
        backgroundColor: "rgba(248, 238, 220, .95)",
        borderRadius: "6px",
        width: "85%",
        padding: ".5rem",
        position: "absolute",
        bottom: "-52%",
        boxSizing: "border-box",
    },
}));

const StudentsLanding = ({ loggedInUser, history, showLogin }) => {
    const classes = useStyles();

    const [showBanner, setShowBanner] = useState(false);

    const handleClick = (e) => {
        const isMobileView = window.innerWidth < 813;
        if (isMobileView) {
            setShowBanner(true);
            setTimeout(() => {
                setShowBanner(false);
            }, 7000);
        } else {
            if (e.target.id === "sign-up") {
                showLogin(true, SIGN_UP, STUDENT);
            } else if (e.target.id === "log-in") {
                showLogin(true, LOG_IN);
            }
        }
    };

    useEffect(() => {
        if (loggedInUser) history.push("/home");
    }, [loggedInUser, history]);

    return (
        <Container className={classes.container}>
            <Box className={classes.mainWrapper}>
                <Box className={classes.heroTextWrapper}>
                    <Typography className={classes.heroTitle} variant="h1">
                        whatifi for Students
                    </Typography>
                    <Typography className={classes.heroSubtitle} variant="h3">
                        Start your financial journey with a plan.
                    </Typography>
                </Box>
                <Box className={classes.ctaButtonsWrapper}>
                    <Button
                        id="sign-up"
                        className={classes.ctaButton}
                        onClick={handleClick}
                    >
                        <Typography id="sign-up" variant="span">
                            Create an Account
                        </Typography>
                    </Button>
                    <Box className={classes.loginTextCtaWrapper}>
                        <Typography>or</Typography>
                        <Typography
                            id="log-in"
                            className={classes.loginCtaButton}
                            onClick={handleClick}
                        >
                            Log In
                        </Typography>
                    </Box>
                </Box>
                <Fade in={showBanner}>
                    <Box className={classes.mobileBannerWrapper}>
                        <Typography variant="h6">Hi there!</Typography>
                        <Typography variant="p">
                            Currently, whatifi is available for Desktop and
                            Laptop. Please visit app.whatifi.io/students on your
                            computer to get started!
                        </Typography>
                    </Box>
                </Fade>
            </Box>
        </Container>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    showLogin: actions.showLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsLanding);

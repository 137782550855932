//@ts-nocheck

import { useState, useEffect, useMemo, useContext, useRef, MutableRefObject } from "react";
import CustomerAcquisitionCostInputView from "./CustomerAcquisitionCostInputView";
import { throwError } from "../../../helpers/swalError";
import { cacObject } from "../../Registry/CAC";
import { customerObject } from "../../Registry/Customer";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import type { ChangeEvent } from "react";
import {
    ModalRow,
    SelectDropDown,
    ModalRowHalf,
    Value,
    MuiCalendar
} from "../Components";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { groupObject } from "Components/Registry/Group";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { customerAcquisitionCostInputsHandler } from "../OnInputChangeHandlers/customerAcquisitionCostInputsHandler";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";
import moment from "moment"

export const CustomerAcquisitionCostInputExperimental = ({
    line,
    focus,
    edit,
    editData,
}) => {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);
    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);

    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({ ...useAppSelector((state) => state?.scenario?.loadedScenario?.dependency_map) })

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );

    const defaultName = useMemo(
        () => getDefaultName(cacObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(cacObject);

            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        // entityIndex,
        entityIds,
        setEntitiesMap,
        // onHandleDate,
    } = useEntities(entitiesObject, eventData, edit, cacObject, dependencyMap.current); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                const newEntitiesMap = { ...entitiesMap };
                const currentEntityObject = {
                    ...(newEntitiesMap[currentEntity] || {}),
                };
                currentEntityObject.name = value;
                newEntitiesMap[currentEntity] = currentEntityObject;
                setEntitiesMap(newEntitiesMap);
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const getCustomers = () => {
        const customers = {};
        const groups = {};

        if (!propsObject.eventId && !propsObject.line) return customers;

        getPresentableDependencies(
            customers,
            customerObject.constant(),
            propsObject, // Temporary object
            true
        );

        getPresentableDependencies(
            groups,
            groupObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(customers).length && !Object.keys(groups).length) {
            throwError(
                "warning",
                "No Customer or Group nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return { ...customers, ...groups };
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const customers = getCustomers();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.customers = customers;
            newEntitiesMap[currentEntity].name = defaultName;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, baselineManager, setEntitiesMap, defaultName]);

    const toggleInheritStartDate = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.inheritStartDate = !data.inheritStartDate;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeInterpolationType = (e) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.subType = e.target.id;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeDynamicValues = (
        e: ChangeEvent<HTMLInputElement>,
        id,
        key
    ) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.specificPercentages = data.specificPercentages.map((data) => {
            if (data.id === id) {
                return { ...data, [key]: e.target.value };
            }
            return { ...data };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleAddDynamicValue = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const newData = {
            id: data.specificPercentages.length,
            year: "",
            month: "",
            value: "",
        };
        data.specificPercentages = [
            ...data.specificPercentages.map((set) => {
                return { ...set };
            }),
            newData,
        ];
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDeleteDynamicValue = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const lastAdded = data.specificPercentages.length - 1;
        const filteredArray = data.specificPercentages.filter((data) => {
            return data.id != lastAdded;
        });
        data.specificPercentages = filteredArray.map((set) => {
            return { ...set };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const getDynamicPanel = () => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "November",
            "December",
        ];
        const { specificPercentages } = entitiesMap[currentEntity].data;
        return (
            <>
                {specificPercentages &&
                    specificPercentages.map((data) => {
                        return (
                            <>
                                <ModalRow twoInputs>
                                    <ModalRowHalf>
                                        <SelectDropDown
                                            onChangeInput={(e) =>
                                                onChangeDynamicValues(
                                                    e,
                                                    data.id,
                                                    "month"
                                                )
                                            }
                                            value={data.month}
                                            options={months}
                                            className="mlsInput"
                                            mainId={data.id}
                                            label="Select a Month"
                                            id="month"
                                            required={true}
                                        />
                                    </ModalRowHalf>
                                    <ModalRowHalf>
                                        <MuiCalendar id="year" value={data.year} dynamicId={data.id} onChangeInput={handleDynamicDate} label="Year" inputFormat="YYYY" disableOpenPicker={true} />
                                    </ModalRowHalf>
                                </ModalRow>
                                <ModalRow>
                                    <ModalRowHalf>
                                        <Value
                                            label="Estimated CAC"
                                            onChangeInput={(e) =>
                                                onChangeDynamicValues(
                                                    e,
                                                    data.id,
                                                    "value"
                                                )
                                            }
                                            value={data.value}
                                            id="dynamicCost"
                                            mainId={data.id}
                                            className="mlsInput"
                                            sign={false}
                                            unit="$"
                                            required={true}
                                        />
                                    </ModalRowHalf>
                                </ModalRow>
                            </>
                        );
                    })}
                <ModalRow>
                    <div onClick={handleAddDynamicValue} className="textButton">
                        Add Another
                    </div>
                    {specificPercentages.length > 1 && (
                        <div
                            onClick={handleDeleteDynamicValue}
                            className="addButton"
                        >
                            Delete Recently added
                        </div>
                    )}
                </ModalRow>
            </>
        );
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id: "customer" | "constantValue" | "entityName" | "interpolateSubType",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = customerAcquisitionCostInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = customerAcquisitionCostInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    }

    const handleDynamicDate = (key, value, dataId) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.specificPercentages = data.specificPercentages.map((data) => {
            if (data.id === dataId) {
                return { ...data, [key]: moment(value).format("YYYY") };
            }
            return { ...data };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    }

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const passedCheck = !!eventData.name && cacObject.checkInput(entitiesMap);

    return (
        <CustomerAcquisitionCostInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            onChangeNameDescription={onChangeNameDescription}
            onHandleSubmit={onHandleSubmit}
            toggleInheritStartDate={toggleInheritStartDate}
            handleChangeInterpolationType={handleChangeInterpolationType}
            getDynamicPanel={getDynamicPanel}
            edit={edit}
            passedCheck={passedCheck}
            eventData={eventData}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
        />
    );
};

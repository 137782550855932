/**
 * Uses the provided action and context to return a boolean specific to the passed action.
 */
export function getActionActive(
    action: string,
    typeIsDecision: boolean,
    typeIsContainer: boolean,
    bypassedCondition: boolean,
    lockedCondition: boolean,
    pasteCondition: boolean,
    connectCondition: boolean,
    deleteCondition: boolean,
    addCondition: boolean,
    collapseExpand: boolean
): boolean {
    switch (action) {
        case "bypass":
            return bypassedCondition;
        case "lock":
            return lockedCondition;
        case "paste":
            return pasteCondition && (addCondition || typeIsDecision);
        case "connect":
            return connectCondition;
        case "delete":
            return deleteCondition;
        case "add":
            return addCondition || typeIsContainer;
        case "collapseExpand":
            return collapseExpand;
        default:
            return false;
    }
}

import {
    useState,
    useEffect,
    useMemo,
    useContext,
    MutableRefObject,
    useRef,
} from "react";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import DebtRepaymentInputView from "./DebtRepaymentInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import { getDefaultName } from "../../../helpers";
import { getPresentableDependenciesOfManyTypes } from "helpers/nodeDependencyDetectionHelpers";
import type { ChangeEvent } from "react";
import { debtRepaymentObject } from "../../Registry/Debt Repayment";
import { debtObject } from "Components/Registry/Debt";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { debtRepaymentInputsHandler } from "../OnInputChangeHandlers/debtRepaymentInputsHandler";
import { loanObject } from "Components/Registry/Loan";
import useOverrides from "../CustomHooks/useOverrides";
import { EventManager } from "Events";
import { addNewEvent, updateEvent } from "actions/eventHelpers";

export default function DebtRepaymentInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...useAppSelector(
            (state) => state?.scenario?.loadedScenario?.dependency_map
        ),
    });
    const eventId = useContext(EventInputIDContext);
    const propsObject = useMemo(
        () => ({ manager, eventId, line, focus }),
        [eventId, line, manager, focus]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(debtRepaymentObject);
            _eventData.name = getDefaultName(
                debtRepaymentObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity,
        entitiesMap,
        entityIndex,
        entityIds,
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(
        entitiesObject,
        eventData,
        edit,
        debtRepaymentObject,
        dependencyMap.current
    ); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const {
        checkIfFieldIsOverridden,
        isOverrideValid,
        createOverride,
        updateOverride,
        getOverride,
    } = useOverrides(entitiesMap, setEntitiesMap, currentEntity);

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const getDebtsAndLoans = () => {
        const debtsAndLoans = {};
        if (!propsObject.eventId && !propsObject.line) return debtsAndLoans;

        getPresentableDependenciesOfManyTypes(
            debtsAndLoans,
            [debtObject.constant(), loanObject.constant()],
            propsObject, // Temporary object
            debtObject.name()
        );

        if (!Object.keys(debtsAndLoans).length) {
            throwError("warning", `No Debt or Loan events found upstream.`);
        }
        return debtsAndLoans;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const debtsAndLoans = getDebtsAndLoans();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.debtsAndLoans = debtsAndLoans;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, baselineManager, setEntitiesMap]);

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const star = 0;
        const newEntitiesMap = debtRepaymentInputsHandler(
            "",
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "entityName"
            | "fixedVsRemaining"
            | "value"
            | "debtOrLoan"
            | "cadence"
            | "startDate"
            | "endDate"
            | "paymentDate",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = debtRepaymentInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = debtRepaymentInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && debtRepaymentObject.checkInput(entitiesMap);

    return (
        <DebtRepaymentInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            eventData={eventData}
            updateAccount={updateAccount}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleEntityStateChange={handleEntityStateChange}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
            checkIfFieldIsOverridden={checkIfFieldIsOverridden}
            isOverrideValid={isOverrideValid}
            createOverride={createOverride}
            updateOverride={updateOverride}
            getOverride={getOverride}
        />
    );
}

import {
    useState,
    useEffect,
    useMemo,
    useContext,
    useRef,
    MutableRefObject,
} from "react";
import { useAppSelector, useAppDispatch } from "store/useAppSelectorDispatch";
import CustomerGrowthInputView from "./CustomerGrowthInputView";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { throwError } from "helpers/swalError";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { customerObject } from "Components/Registry/Customer";
import { customerGrowthObject } from "Components/Registry/CustomerGrowth";
import type { ChangeEvent } from "react";
import { customerGrowthInputsHandler } from "../OnInputChangeHandlers/customerGrowthInputsHandler";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getDefaultName } from "helpers";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { groupObject } from "Components/Registry/Group";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import moment from "moment";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { EventManager } from "Events";

export default function CustomerGrowthInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager: EventManager = useAppSelector(
        (state) => state?.scenario?.manager
    );
    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({
        ...useAppSelector(
            (state) => state?.scenario?.loadedScenario?.dependency_map
        ),
    });

    /**
     * Created propsObject because getDefaultName & getPresentableDependenciesOfManyTypes
     * functions expects a props obj with baselineManager and manager.
     * Temporary solution, re-work of this logic is outside of current scope.
     */

    const eventId = useContext(EventInputIDContext);
    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [eventId, line, manager, focus]
    );
    const defaultName = useMemo(
        () => getDefaultName(customerGrowthObject.name(), propsObject),
        [propsObject]
    );

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(customerGrowthObject);
            _eventData.name = defaultName;
        }

        return _eventData;
    });

    const { currentEntity, entitiesMap, entityIds, setEntitiesMap } =
        useEntities(
            entitiesObject,
            eventData,
            edit,
            customerGrowthObject,
            dependencyMap.current
        ); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                setEntitiesMap(
                    customerGrowthInputsHandler(
                        value,
                        "entityName",
                        0,
                        entitiesMap,
                        currentEntity,
                        eventId,
                        dependencyMap.current
                    )
                );
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const getCustomers = () => {
        const customers = {};
        const groups = {};

        if (!propsObject.eventId && !propsObject.line) return customers;

        getPresentableDependencies(
            customers,
            customerObject.constant(),
            propsObject, // Temporary object
            true
        );

        getPresentableDependencies(
            groups,
            groupObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(customers).length && !Object.keys(groups).length) {
            throwError(
                "warning",
                "No Customer or Group nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return { ...customers, ...groups };
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const customers = getCustomers();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.customers = customers;
            newEntitiesMap[currentEntity].name = defaultName;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, baselineManager, setEntitiesMap, defaultName]);

    const toggleInheritStartDate = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.inheritStartDate = !data.inheritStartDate;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleChangeInterpolationType = (e) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.subType = e.target.id;
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const onChangeDynamicValues = (e, key, id) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.specificPercentages = data.specificPercentages.map((data) => {
            if (data.id === id) {
                return { ...data, [key]: e.target.value };
            }
            return { ...data };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDynamicDate = (key, value, dataId) => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        data.specificPercentages = data.specificPercentages.map((data) => {
            if (data.id === dataId) {
                let formattedDate = moment(value).format("YYYY");
                if (formattedDate === "Invalid date") formattedDate = "";
                return { ...data, [key]: formattedDate };
            }
            return { ...data };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleAddDynamicValue = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };
        const newData = {
            id: data.specificPercentages.length,
            year: "",
            month: "",
            value: "",
        };
        data.specificPercentages = [
            ...data.specificPercentages.map((set) => {
                return { ...set };
            }),
            newData,
        ];
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleDeleteDynamicValue = () => {
        const newEntitiesMap = { ...entitiesMap };
        const currentEntityObject = {
            ...(newEntitiesMap[currentEntity] || {}),
        };
        const data = { ...(currentEntityObject?.data || {}) };

        const lastAdded = data.specificPercentages.length - 1;
        const filteredArray = data.specificPercentages.filter((data) => {
            return data.id != lastAdded;
        });
        data.specificPercentages = filteredArray.map((set) => {
            return { ...set };
        });
        currentEntityObject.data = data;
        newEntitiesMap[currentEntity] = currentEntityObject;
        setEntitiesMap(newEntitiesMap);
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "customer"
            | "constantValue"
            | "entityName"
            | "interpolateSubType"
            | "maxGrowth"
            | "rating"
            | "cadence",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = customerGrowthInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const star = 0;
        const newEntitiesMap = customerGrowthInputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const onHandleSubmit = () => {
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const passedCheck =
        !!eventData.name && customerGrowthObject.checkInput(entitiesMap);
    return (
        <CustomerGrowthInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            onChangeDynamicValues={onChangeDynamicValues}
            handleDynamicDate={handleDynamicDate}
            handleAddDynamicValue={handleAddDynamicValue}
            handleDeleteDynamicValue={handleDeleteDynamicValue}
            eventData={eventData}
            onChangeNameDescription={onChangeNameDescription}
            handleChangeInterpolationType={handleChangeInterpolationType}
            toggleInheritStartDate={toggleInheritStartDate}
            passedCheck={passedCheck}
            onHandleSubmit={onHandleSubmit}
            edit={edit}
            handleOnChange={handleOnChange}
            handleDateSelection={handleDateSelection}
        />
    );
}

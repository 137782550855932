// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    SelectDropDown,
    Rate,
    Value,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    RequiredStar,
    ModalRowHalf,
    SingleEntity,
    SelectEntities,
} from "../Components";
import { ChangeEvent, useEffect } from "react";
import { useRef } from "react";
import Calendar from "../../Calendar";
import SingleCalendar from "../../Calendar/singleCalendar";
import { modifier2InputsHandler } from "../OnInputChangeHandlers/modifier2InputsHandler";
import { modifier2Object } from "Components/Registry/Modifier2";
import Switch from "react-switch";
import {
    AddToValue,
    AnyNode,
    CompoundGrowth,
    mDate,
    mDollar,
    mPercent,
    PercentChange,
    SpecificNode,
    ValueReplacer,
} from "helpers/modifierHelpers";
import { getEvent, getRelevantEntities } from "actions/getNodeEntityActions";
import { propertyDisplayOptions } from "./Modifier2InputExperimental";
import { groupObject } from "Components/Registry/Group";

const modes = [SpecificNode, AnyNode];

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function Modifier2InputView({
    setEntitiesMap,
    entitiesMap,
    currentEntity,
    entityIndex,
    eventData,
    onChangeNameDescription,
    onHandleDate,
    onHandleDateProperty,
    extractModifiablePropertiesAnyNode,
    searchForNodes,
    toggleRevertValue,
    toggleCustomEffectPeriod,
    passedCheck,
    onHandleSubmitValues,
    onHandleSubmit,
    propsObject,
    setEventData,
    edit,
    submitted,
}) {
    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "newValue"
            | "entityName"
            | "node"
            | "mode"
            | "subMode"
            | "property",
        star: number
    ) => {
        const value = e.target.value;
        const newEntitiesMap = modifier2InputsHandler(
            value,
            id,
            star,
            entitiesMap,
            currentEntity,
            propsObject,
            setEventData
        );
        setEntitiesMap(newEntitiesMap);
    };

    // // Reference to previous state
    // const prevStateRef = useRef<any>();
    // useEffect(() => {
    //     prevStateRef.current = entitiesMap;
    // });

    const prevState = usePrevious(entitiesMap);
    // Reference to div at end of container
    const inputEnd = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const curEntData = entitiesMap?.[currentEntity]?.data;
        // const prevState = prevStateRef.current;
        const preEntData = prevState?.[currentEntity]?.data;
        if (
            (!preEntData?.mode && curEntData?.mode) ||
            (!preEntData?.curNodeId && curEntData?.curNodeId) ||
            (!preEntData?.propertySelected && curEntData?.propertySelected) ||
            (!preEntData?.subMode && curEntData?.subMode)
        ) {
            setTimeout(() => {
                if (inputEnd.current != null)
                    inputEnd.current.scrollIntoView({
                        behavior: "smooth",
                    });
            });
        }
    }, [currentEntity, entitiesMap, prevState]);

    // Specific Node body panel
    const createBodyPanelSpecificNode = () => {
        const currentEntityObject = entitiesMap?.[currentEntity];
        const currentEntityData = entitiesMap?.[currentEntity]?.data;
        const curNodes = searchForNodes();

        const properties = currentEntityData?.curProperties;

        // process specific sub modes
        let specificNodeSubModes = [];
        // development flag because compounding functionality is still in testing phase
        // process.env.REACT_APP_ENV === "development"
        //     ? (specificNodeSubModes = [
        //           PercentChange,
        //           ValueReplacer,
        //           AddToValue,
        //           CompoundGrowth,
        //       ])
        //     : (specificNodeSubModes = [
        //           PercentChange,
        //           ValueReplacer,
        //           AddToValue,
        //       ]);
        specificNodeSubModes = [
            PercentChange,
            ValueReplacer,
            AddToValue,
            CompoundGrowth,
        ];
        if (currentEntityData?.unit === mDate) {
            specificNodeSubModes = [ValueReplacer];
        }

        const valueField = buildSpecificNodeValueField();

        const isGroup = (id) => {
            const node = id ? getEvent(id) : null;
            return node ? node.type === groupObject.constant() : null;
        };

        return (
            <>
                <ModalRow>
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={currentEntityData?.curNodeId || ""}
                        options={curNodes}
                        className="termsContainer"
                        id="node"
                        label="Select Event"
                    />
                </ModalRow>
                {currentEntityData?.curNodeId && (
                    <ModalRow>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            value={
                                entitiesMap[currentEntity].dependencies
                                    ?.entity ?? ""
                            }
                            nodes={[currentEntityData?.curNodeId]}
                            showAll={true}
                            className="IncomeSelect"
                            id="entity"
                            label="Select Record"
                            groupEventType={
                                isGroup(currentEntityData?.curNodeId) && "All"
                            }
                        />
                    </ModalRow>
                )}
                {currentEntityData?.curNodeId &&
                    entitiesMap?.[currentEntity]?.dependencies?.entity
                        ?.eventId &&
                    properties && (
                        <ModalRow>
                            <RequiredStar />
                            <SelectDropDown
                                onChangeInput={handleOnChange}
                                value={
                                    currentEntityData?.propertySelected || ""
                                }
                                options={propertyDisplayOptions(
                                    currentEntityObject,
                                    propsObject.loadedScenario
                                )}
                                className="termsContainer"
                                id="property"
                                label="Property"
                            />
                        </ModalRow>
                    )}
                {currentEntityData?.curNodeId &&
                    properties &&
                    currentEntityData?.propertySelected && (
                        <ModalRow>
                            <SelectDropDown
                                onChangeInput={handleOnChange}
                                value={currentEntityData.subMode || ""}
                                options={specificNodeSubModes}
                                className="termsContainer"
                                id="subMode"
                                label="Modifier type"
                            />
                        </ModalRow>
                    )}
                {valueField}
            </>
        );
    };

    // Any Node body panel
    const createBodyPanelAnyNode = () => {
        const curEntData = entitiesMap?.[currentEntity]?.data;
        if (!curEntData) return <></>;
        let properties = curEntData.curProperties;
        if (!properties)
            properties = extractModifiablePropertiesAnyNode(
                curEntData.customEffectPeriod
            );
        return (
            <>
                <ModalRow>
                    <RequiredStar />
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={curEntData.propertySelected}
                        options={Object.keys(properties)}
                        className="termsContainer"
                        id="property"
                        label="Property"
                    />
                </ModalRow>
                <ModalRow>
                    <ModalRowHalf>
                        <RequiredStar />
                        <Rate
                            label="Percent Change"
                            className="mlsInput"
                            onChangeInput={handleOnChange}
                            value={curEntData.value}
                            sign={true}
                            id="newValue"
                        />
                    </ModalRowHalf>
                </ModalRow>
            </>
        );
    };

    // Build specific node value fields
    const buildSpecificNodeValueField = () => {
        const curEntData = entitiesMap?.[currentEntity]?.data;
        if (
            !curEntData ||
            !curEntData.curNodeId ||
            !curEntData.subMode ||
            !curEntData.propertySelected ||
            !curEntData.curProperties ||
            !entitiesMap?.[currentEntity]?.dependencies?.entity?.eventId
        )
            return <></>;

        // selectedEntity is undefined if "All" is selected.
        const selectedEntityId =
            entitiesMap?.[currentEntity]?.dependencies?.entity?.entityIds;
        const selectedEntity =
            getRelevantEntities(selectedEntityId)[selectedEntityId[0]];

        let valueField = <></>;
        switch (curEntData.subMode) {
            case AddToValue: {
                valueField = (
                    <ModalRow>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Rate
                                label="Add to Value"
                                className="mlsInput"
                                onChangeInput={handleOnChange}
                                value={curEntData?.value || ""}
                                sign={false}
                                id="newValue"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                );
                break;
            }
            case CompoundGrowth: {
                valueField = (
                    <ModalRow>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Rate
                                label="Percent Compounding Growth"
                                className="mlsInput"
                                onChangeInput={handleOnChange}
                                value={curEntData?.value || ""}
                                sign={true}
                                id="newValue"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                );
                break;
            }
            case PercentChange: {
                valueField = (
                    <ModalRow>
                        <ModalRowHalf>
                            <RequiredStar />
                            <Rate
                                label="Percent Change"
                                className="mlsInput"
                                onChangeInput={handleOnChange}
                                value={curEntData?.value || ""}
                                sign={true}
                                id="newValue"
                            />
                        </ModalRowHalf>
                    </ModalRow>
                );
                break;
            }
            case ValueReplacer:
                {
                    switch (curEntData.unit) {
                        case mDate: {
                            const curNode = propsObject.manager._findEvent(
                                curEntData.curNodeId
                            );
                            let curDate = curEntData.value;
                            if (!curDate && curNode) {
                                const { feName, inDataSubfield } =
                                    curEntData.curProperties[
                                        curEntData.propertySelected
                                    ];
                                curDate = inDataSubfield
                                    ? selectedEntity?.data?.[feName]
                                    : selectedEntity?.[feName];
                            }
                            valueField = (
                                <ModalRow>
                                    <RequiredStar />
                                    <SingleCalendar
                                        onHandleDate={onHandleDateProperty}
                                        date={curDate}
                                        entityIndex={entityIndex}
                                    />
                                </ModalRow>
                            );
                            break;
                        }
                        case mPercent: {
                            valueField = (
                                <ModalRow>
                                    <ModalRowHalf>
                                        <RequiredStar />
                                        <Rate
                                            label="New Value"
                                            className="mlsInput"
                                            onChangeInput={handleOnChange}
                                            value={curEntData.value || ""}
                                            sign={true}
                                            id="newValue"
                                        />
                                    </ModalRowHalf>
                                </ModalRow>
                            );
                            break;
                        }
                        case mDollar: {
                            valueField = (
                                <ModalRow>
                                    <ModalRowHalf>
                                        <RequiredStar />
                                        <Value
                                            label="New Value"
                                            // className="mlsInput"
                                            onChangeInput={handleOnChange}
                                            value={curEntData.value || ""}
                                            sign={true}
                                            id="newValue"
                                            unit="$"
                                        />
                                    </ModalRowHalf>
                                </ModalRow>
                            );
                            break;
                        }
                        default:
                            valueField = (
                                <ModalRow>
                                    <ModalRowHalf>
                                        <RequiredStar />
                                        <Rate
                                            label="New Value"
                                            className="mlsInput"
                                            onChangeInput={handleOnChange}
                                            value={curEntData.value || ""}
                                            sign={false}
                                            id="newValue"
                                        />
                                    </ModalRowHalf>
                                </ModalRow>
                            );
                    }
                }
                break;
            default:
        }
        return valueField;
    };

    // Body Panel
    let bodyPanel = <></>;

    if (!submitted) {
        switch (entitiesMap?.[currentEntity]?.data?.mode) {
            case SpecificNode:
                bodyPanel = createBodyPanelSpecificNode();
                break;
            case AnyNode:
                bodyPanel = createBodyPanelAnyNode();
                break;
            default:
                bodyPanel = <></>;
        }
    }

    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={modifier2Object.name()}
                    onChangeInput={onChangeNameDescription}
                    image={modifier2Object.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <ModalRowHalf>
                        <Switch
                            checked={
                                entitiesMap?.[currentEntity]?.data
                                    ?.customEffectPeriod
                            }
                            className="InflationToggle"
                            onChange={toggleCustomEffectPeriod}
                            height={20}
                            width={40}
                        />
                        <div className="InflationText">
                            Custom effect period
                        </div>
                    </ModalRowHalf>
                    {entitiesMap?.[currentEntity]?.data?.customEffectPeriod && (
                        <ModalRowHalf>
                            <Switch
                                checked={
                                    entitiesMap?.[currentEntity]?.data
                                        ?.revertValue
                                }
                                className="InflationToggle"
                                onChange={toggleRevertValue}
                                height={20}
                                width={40}
                            />
                            <div className="InflationText">
                                Revert value on end date
                            </div>
                        </ModalRowHalf>
                    )}
                </ModalRow>
                {entitiesMap?.[currentEntity]?.data?.customEffectPeriod && (
                    <ModalRow>
                        <Calendar
                            onHandleDate={onHandleDate}
                            startDate={entitiesMap?.[currentEntity]?.startDate}
                            endDate={
                                entitiesMap?.[currentEntity]?.endDate || null
                            }
                            cadence={
                                !entitiesMap?.[currentEntity]?.data
                                    .revertValue &&
                                entitiesMap?.[currentEntity]?.cadence
                            }
                            entityIndex={entityIndex}
                        />
                    </ModalRow>
                )}
                <ModalRow>
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap?.[currentEntity]?.data?.mode}
                        options={modes}
                        className="termsContainer"
                        id="mode"
                        label="Choose Mode"
                    />
                </ModalRow>

                {bodyPanel}

                {/* dummy div to enable autoscroll when adding extra fields */}
                <div ref={inputEnd} />
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmitValues}
                onHandleSubmitValues={onHandleSubmitValues}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import React, { Component } from "react";
import {
    CardContainer,
    Description,
    DollarValue,
    PercentValue,
    Row,
    RowLabel,
    TextValue,
} from "./Components";

class RealEstateFeeCard extends Component {
    render() {
        const { description, feePercent, feeAmount, houseName, version } =
            this.props.data.metadata;
        return (
            <CardContainer>
                <Row>
                    <Description text={description} />
                </Row>
                <Row>
                    <RowLabel text="Fee Percentage" />
                    <PercentValue value={feePercent} />
                </Row>
                <Row>
                    <RowLabel text="Fee Amount" />
                    <DollarValue value={feeAmount} />
                </Row>

                <Row>
                    <RowLabel text="House" />
                    <TextValue text={houseName} />
                </Row>
                <Row>
                    <RowLabel text="Version" />
                    <TextValue text={version} />
                </Row>
            </CardContainer>
        );
    }
}

export default RealEstateFeeCard;

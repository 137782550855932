// import type Event from "Events/_event";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store/useAppSelectorDispatch";
import { Group, Line } from "react-konva";
// import { CanvasLine } from "./CanvasLine";
import { useContext } from "react";
import { CanvasBaselineContext } from "../../contexts/CanvasBaselineContext";
import { getManager } from "helpers/getManager";
import { CanvasDashboardContext } from "../../contexts/CanvasDashboardContext";

export function CanvasLines() {
    const _isBaseline = useContext(CanvasBaselineContext);
    const isDashboard = useContext(CanvasDashboardContext);

    // const lineIds = useAppSelector((state) => {
    //     const manager = getManager(_isBaseline, state);
    //     const _lineIds: string[] = [];
    //     const uniqueLineIds: { [lineId: string]: boolean } = {};
    //     if (!manager) return _lineIds;

    //     const eventIdsArr: string[] = manager?.sortedEventIds;

    //     eventIdsArr?.forEach?.((eventId) => {
    //         const event = manager._findEvent(eventId);
    //         const parentId = event?.id ?? "";
    //         event?.children?.forEach?.((child: Event) => {
    //             const childId = child.id ?? "";
    //             const lineId = `${parentId}_${childId}`;
    //             if (!uniqueLineIds[lineId]) {
    //                 _lineIds.push(lineId);
    //                 uniqueLineIds[lineId] = true;
    //             }
    //         });
    //     });

    //     return _lineIds;
    // }, shallowEqual);

    const { connectionHead, manager } = useAppSelector((state) => {
        const connectionHead = state?.scenario?.connectionHead;
        const manager = getManager(_isBaseline, state);
        return {
            connectionHead,
            manager,
        };
    }, shallowEqual);

    const connectionsSelected = useAppSelector((state) => {
        return state?.scenario?.connectionsSelected;
    }, shallowEqual);

    const connectionParent = manager?._findEvent(connectionHead);

    if (isDashboard) return <Group></Group>;

    return (
        <Group>
            {/* {lineIds.map((lineId) => (
                <CanvasLine key={lineId} lineId={lineId} />
            ))} */}
            {connectionsSelected &&
                connectionsSelected.length > 0 &&
                connectionsSelected.map((childId) => {
                    const parentX: number = connectionParent?.x?.();
                    const parentY: number = connectionParent?.y?.();
                    const childEvent = manager._findEvent(childId);
                    const childX: number = childEvent?.x?.();
                    const childY: number = childEvent?.y?.();
                    const points = [parentX, parentY, childX, childY];
                    return (
                        <Line
                            points={points}
                            strokeWidth={5}
                            stroke="#c0c0c0"
                            key={childId}
                            dash={[14, 20]}
                        />
                    );
                })}
        </Group>
    );
}

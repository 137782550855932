// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    RequiredStar,
    EntityName,
    SingleEntity,
    SelectDropDown,
} from "../Components";
import { importObject } from "Components/Registry/Import";
import { resourceObject } from "../../Registry/Resource";
import ImportAccount from "./ImportAccount";
import ImportResources from "./ImportEvent/ImportResources";

const importAccountValuesId = "_account";
const importTypeOptions = {
    [importAccountValuesId]: {
        value: importAccountValuesId,
        displayName: "Account",
    },
    [resourceObject.constant()]: {
        value: resourceObject.constant(),
        displayName: `${resourceObject.name()}`,
    },
};

export default function ImportInputView({
    entitiesMap,
    currentEntity,
    setEntitiesMap,
    eventData,
    onChangeNameDescription,
    passedCheck,
    onHandleSubmit,
    edit,
    handleOnChange,
    handleEntityStateChange,
    containsData,
    startEventCreation,
    dispatch,
}) {
    const importType = entitiesMap?.[currentEntity]?.data.importType;
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData?.name}
                    eventType={importObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={importObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData?.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <RequiredStar />
                    <EntityName
                        name={entitiesMap[currentEntity].name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                    />
                </ModalRow>
                {eventData.id && importType ? (
                    `Import ${importTypeOptions[importType].displayName}:`
                ) : (
                    <ModalRow>
                        <SelectDropDown
                            onChangeInput={handleOnChange}
                            value={importType}
                            options={Object.values(importTypeOptions)}
                            className="select-dropdown"
                            id="importType"
                            label="Select Import Type"
                            required={true}
                        />
                    </ModalRow>
                )}
                {eventData.id &&
                    (importType === importAccountValuesId ? (
                        <ImportAccount
                            entitiesMap={entitiesMap}
                            currentEntity={currentEntity}
                            containsData={containsData}
                            startEventCreation={startEventCreation}
                        />
                    ) : importType === resourceObject.constant() ? (
                        <ImportResources
                            entitiesMap={entitiesMap}
                            currentEntity={currentEntity}
                            eventId={eventData.id}
                            setEntitiesMap={setEntitiesMap}
                            onHandleSubmit={onHandleSubmit}
                            dispatch={dispatch}
                        />
                    ) : (
                        <></>
                    ))}
                {entitiesMap?.[currentEntity]?.data?.lastUpdated && (
                    <ModalRow>
                        Last Updated:{" "}
                        {entitiesMap?.[currentEntity]?.data?.lastUpdated || ""}
                    </ModalRow>
                )}
                {!eventData.id && (
                    <ModalRow>
                        <RequiredStar />
                        <div>{`This event is in beta. Please click "Fill & Add" to access CSV import`}</div>
                    </ModalRow>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

import React, { Component } from "react";
import { getDefaultName } from "../../helpers";
import { Me, Person, Provinces } from "../../helpers/constants";
import { getPresentableDependenciesOfManyTypes } from "../../helpers/nodeDependencyDetectionHelpers";
import { throwError } from "../../helpers/swalError";
import { Description, InputButtons, Name, SelectDropDown } from "./Components";
import _ from "lodash";
import { capitalGainsObject } from "Components/Registry/Capital Gains";

class CapitalGainsInput extends Component {
    constructor(props) {
        super(props);

        const people = this.getPeople();

        this.state = {
            start: null,
            end: null,
            rating: 0,
            showOptions: false,
            name: getDefaultName(capitalGainsObject.constant(), this.props),
            type: capitalGainsObject.constant(),
            value: null,
            showSettings: false,
            tag: `@${capitalGainsObject.constant()}`,
            inflation: false,
            baseline: false,
            people,
            personId: "",
            person: null,
            description: "",
            province: "",
        };
    }

    componentDidMount() {
        if (this.props.edit) {
            const people = this.getPeople();
            this.setState({
                ...this.props.editData,
                people,
            });
        }
        if (this.props.baseline) {
            this.setState({ baseline: true });
        }
    }

    getPeople = () => {
        const people = {};
        getPresentableDependenciesOfManyTypes(
            people,
            [Person, Me],
            this.props,
            "Person"
        );

        if (Object.keys(people).length === 0) {
            throwError(
                "warning",
                `No people nodes found upstream or in baseline`
            );
        }
        return people;
    };

    updatePerson = (person) => {
        if (!person) {
            this.setState({
                person,
                personId: null,
            });
        } else {
            this.setState({
                person,
                personId: this.state.people[person],
            });
        }
    };

    onChangeInput = (e, id, _star) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "person":
                this.updatePerson(value);
                break;
            case "province":
                this.setState({ province: value });
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    onHandleSubmitValues = () => {
        const passedCheck = capitalGainsObject.checkInput(this.state);
        const { baseline, confirmBaselineAction } = this.props;
        if (passedCheck) {
            const newState = {
                ...this.state,
                isFilled: true,
                valid: true,
            };
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const { baseline, confirmBaselineAction } = this.props;
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    render() {
        const passedCheck = capitalGainsObject.checkInput(this.state);
        const { edit } = this.props;

        const { name, person, people, description, province } = this.state;

        return (
            <div className="mainRootContainer">
                <div className="inputRoot">
                    <div className="headerContainer">
                        <img
                            alt="alt"
                            src={capitalGainsObject.svg()}
                            className="flinksLogo"
                        />
                        <div className="headerInput">
                            <div className="headerLabel">RETIREMENT</div>
                            <div className="inputContainer">
                                <div className="Required">*</div>
                                <Name
                                    name={name}
                                    onChangeInput={this.onChangeInput}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="ModalRow">
                        <Description
                            description={description}
                            onChangeInput={this.onChangeInput}
                        />
                    </div>

                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={person}
                            options={Object.keys(people) || []}
                            className="termsContainer"
                            id="person"
                            label="Person"
                        />
                    </div>
                    <div className="ModalRow">
                        <div className="Required">*</div>
                        <SelectDropDown
                            onChangeInput={this.onChangeInput}
                            value={province}
                            options={Provinces}
                            className="termsContainer"
                            id="province"
                            label="Province"
                        />
                    </div>
                    {this.state.showRequired && (
                        <div className="ModalRow">
                            <div className="RequiredField">
                                * Required Field{" "}
                            </div>
                        </div>
                    )}
                </div>
                <InputButtons
                    edit={edit}
                    updateValues={() =>
                        this.props.fillPartialValues(
                            this.state,
                            {},
                            passedCheck
                        )
                    }
                    onHandleSubmitValues={this.onHandleSubmitValues}
                    onHandleSubmit={this.onHandleSubmit}
                    passedCheck={passedCheck}
                />
            </div>
        );
    }
}

export default CapitalGainsInput;

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "store";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import { CookiesProvider } from "react-cookie";
// un-comment the following line when updating event templates.
// import "stories/Events/Income/UpdateTemplates"
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_KEY);

Sentry.init({
    dsn: "https://486e1851df7b49809faea920a481a07e@sentry.io/4396766",
});

window.app_version = "4.0.0-rc1-canvas-refactor";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <CookiesProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </CookiesProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from "react";
import "./Card.css";
// import starFilledSvg from "../../Assets/star_filled.svg";
// import starUnfilledSvg from "../../Assets/star_unfilled.svg";
import { getRelevantEntities } from "actions/getNodeEntityActions";

class BankCardAlt extends Component {
    state = {
        showSettings: false,
        currentAccountIndex: 0,
    };

    onHandleAdvanceSetting = () => {
        this.setState({ showSettings: !this.state.showSettings });
    };

    handleArrowClick = (direction, accountsLength) => {
        const currentNumber = this.state.currentAccountIndex + 1;
        if (direction === "right" && currentNumber != accountsLength) {
            this.setState({
                currentAccountIndex: this.state.currentAccountIndex + 1,
            });
        } else if (direction === "left" && currentNumber != 1) {
            this.setState({
                currentAccountIndex: this.state.currentAccountIndex - 1,
            });
        }
    };

    render() {
        // As of right now we are just grabbing and displaying the first entity
        // TODO: Add the ability to scroll through all entities, similar to the input container
        const entity = Object.values(
            getRelevantEntities(
                this.props.data.entities.map((entity) => entity.id)
            )
        )[0];
        // const stars = [1, 2, 3, 4, 5];
        const { description, version, data } = entity;
        const { accounts } = data;
        const currentAccountData = accounts[this.state.currentAccountIndex];

        return (
            <div className="Container">
                <div className="Row">
                    <div className="ExpenseLabel">Account Name:</div>
                    <div className="cardValue">
                        {currentAccountData.accountName ?? ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Account Balance:</div>
                    <div className="cardValue">
                        $
                        {currentAccountData.accountBalance
                            ? new Intl.NumberFormat().format(
                                  parseFloat(
                                      currentAccountData.accountBalance
                                  ).toFixed(2)
                              )
                            : ""}
                    </div>
                </div>
                {description && (
                    <div className="Row">
                        <div
                            className={
                                description ? "Description" : "NoDescription"
                            }
                        >
                            {description}
                        </div>
                    </div>
                )}
                <div className="Row">
                    <div className="ExpenseLabel">Account Type:</div>
                    <div className="cardValue">
                        {currentAccountData.accountType
                            ? currentAccountData.accountType
                            : ""}
                    </div>
                </div>
                <div className="Row">
                    <div className="ExpenseLabel">Version:</div>
                    <div className="cardValue">{version ? version : ""}</div>
                </div>
                {/* <div className="Row">
                    <div className="ExpenseLabel"> Rating</div>
                    <div className="StarsContainer">
                        {stars.map((star, i) => {
                            if (i < data.rating) {
                                return (
                                    <img
                                        alt="alt"
                                        key={i}
                                        className="starFilledSvg"
                                        src={starFilledSvg}
                                        id="rating"
                                    />
                                );
                            } else {
                                return (
                                    <img
                                        alt="alt"
                                        className="starUnfilledSvg"
                                        src={starUnfilledSvg}
                                        key={i}
                                        id="rating"
                                    />
                                );
                            }
                        })}
                    </div>
                </div> */}
                <div className="arrowsWrapper">
                    <div
                        className={
                            this.state.currentAccountIndex === 0
                                ? "arrowsLeftDisabled"
                                : "arrowsLeft"
                        }
                        onClick={() =>
                            this.handleArrowClick("left", accounts.length)
                        }
                    ></div>
                    <span className="arrowsText">
                        {this.state.currentAccountIndex + 1} of{" "}
                        {accounts.length}
                    </span>
                    <div
                        className={
                            this.state.currentAccountIndex ===
                            accounts.length - 1
                                ? "arrowsRightDisabled"
                                : "arrowsRight"
                        }
                        onClick={() =>
                            this.handleArrowClick("right", accounts.length)
                        }
                    ></div>
                </div>
            </div>
        );
    }
}

export default BankCardAlt;

import { toast } from "react-toastify";
import bypassSvg from "../../Assets/nodeIcons/bypass.png";
import moment from "moment";
import logo from "../../Assets/logo.png";
import { v4 as uuid } from "uuid";

const capitalize = (str) => {
    return str.length > 1 ? str.slice(0, 1).toUpperCase() + str.slice(1) : str;
};

class TreeNode {
    constructor(name, metadata, manager) {
        this._manager = manager;
        this.type = "node";
        this.id = metadata.id || uuid();
        this.name = name;
        this.depth = null;
        this.breadth = null;
        this.minimized = false;
        this.parents = [];
        this.children = [];
        this.minified = false;

        metadata.rating = metadata.rating == null ? 0 : metadata.rating;
        metadata.id = this.id;
        this.metadata = metadata;

        this._base = 125;

        this._nodeFullWidth = this._base;
        this._nodeCompactWidth = this._nodeFullWidth;
        this._nodeFullHeight = this._base;
        this._nodeCompactHeight = 40;

        this._nodeFullXSpacing = 100;
        this._nodeCompactXSpacing = 60;
        this._nodeFullYSpacing = 50;
        this._nodeCompactYSpacing = 10;
        this._nodeFullXLineSpacing = 60;
        this._nodeCompactXLineSpacing = 0;

        this.displayIndex = 0;
        this.displayKeys = ["type"]; //,"value"];
        this.displayAlias = {
            type: "Node",
            value: "Value",
            "date of birth": "Born",
        };

        //this.actionsOrder = ["Add Decision - Start", "Add Decision - Split", "Add Decision - End", "Add Option"];
        this.actionsOrder = ["Add Decision"];
        this.actions = {
            "Insert Decision": {
                title: "Insert Decision",
                action: (decisionDetails, target, _source) => {
                    //this is source
                    //details is new node
                    //target is target
                    this._manager.insertDecision(
                        this,
                        decisionDetails,
                        true,
                        target
                    );
                    // this._manager.insertDecision(this, decisionDetails, target);
                },
                description: "insert-decision",
                nodeType: "Decision",
                reference: Decision,
            },
            "Insert Option": {
                title: "Insert Option",
                action: (eventDetails, target, _source) => {
                    this._manager.insertEvent(this, eventDetails, true, target);
                },
                description: "insert-event",
                reference: Event,
            },
            "Branch Option": {
                title: "Branch Option",
                action: (eventDetails) => {
                    const type = "event";
                    this._manager.addOption(this, eventDetails, true, type);
                },
                description: "add-node",
                reference: Event,
            },
            "Branch Decision": {
                title: "Branch Decision",
                action: (eventDetails) => {
                    const type = "decision";
                    this._manager.addOption(this, eventDetails, true, type);
                },
                description: "add-decision",
                nodeType: "Decision",
                reference: Decision,
            },
            "Connect Decision": {
                title: "Connect Decision",
                action: (decisionDetails, collectedNode) => {
                    this._manager.insertCollectionNode(
                        collectedNode,
                        decisionDetails,
                        true
                    );
                },
                description: "collect-decision",
                nodeType: "Decision",
                reference: Decision,
            },
            "Delete Node": {
                title: "Confirm Delete",
                action: () => {
                    this._manager.removeNode(this);
                },
                description: "delete",
            },
            "Add Decision": {
                title: "Add Decision",
                action: (decisionDetails) => {
                    this._manager.addDecision(this, decisionDetails);
                },
                description: "add-decision",
                nodeType: "Decision",
                reference: Decision,
            },
            "Add Decision - End": {
                title: "Decision Details",
                action: (decisionDetails) => {
                    this._manager.addDecision(this, decisionDetails, "leaf");
                },
                description: "add-decision",
                nodeType: "Decision",
                reference: Decision,
            },
            "Add Decision - Start": {
                title: "Decision Details",
                action: (decisionDetails) => {
                    this._manager.addDecision(this, decisionDetails, "start");
                },
                description: "add-decision",
                nodeType: "Decision",
                reference: Decision,
            },
            "Add Decision - Split": {
                title: "Decision Details",
                action: (decisionDetails) => {
                    this._manager.addDecision(this, decisionDetails, "split");
                },
                description: "add-decision",
                nodeType: "Decision",
                reference: Decision,
            },
            "Add Option": {
                title: "Add Option",
                action: (eventDetails) => {
                    this._manager.addEvent(this, eventDetails);
                },
                description: "add-node",
                reference: Event,
            },
            // "Start Decision": {
            //     title: "Start Decision",
            //     action: (decisionDetails) => {
            //         this._manager.addSlide(this, decisionDetails, "decision");
            //     },
            //     description: "add-decision",
            //     nodeType: "Decision",
            //     reference: Decision,
            // },
            "Toggle Lock": {
                title: "Toggle Lock",
                action: () => {
                    this._manager.toggleLockedNode(this);
                },
                description: "lock",
                reference: TreeNode,
            },
            "Toggle Ignore": {
                title: "Toggle Ignore",
                action: () => {
                    this._manager.toggleBlockNode(this);
                },
                description: "ignore",
                reference: TreeNode,
            },
        };
    }

    json = () => {
        const details = {
            id: this.id,
            name: this.name,
            type: this.type,
            children: [],
            parent: [],
            metadata: {},
        };

        this.children.forEach((child) => {
            details.children.push(child.id);
        });
        this.parents.forEach((parent) => {
            details.parent.push(parent.id);
        });
        this.displayKeys.forEach((key) => {
            details.metadata[key] = this.metadata[key];
        });
        return details;
    };

    availableActions() {
        return this.actionsOrder;
    }

    static availableTypes() {
        return {
            Partner: Partner,
            Decision: Decision,
            Event: Event,
        };
    }

    static requiredInputs() {
        const required = [{ label: "name", type: "text" }];
        return required;
    }

    static optionalInputs() {
        return [];
    }

    quickActions = () => {
        return [];
    };

    actionDisplay() {
        const actions = [];
        this.quickActions().forEach((action, i) => {
            const image = this.actionImage(action);
            if (image && i < 4) {
                actions.push({
                    action,
                    image,
                });
            }
        });
        return actions;
    }

    actionImage(actionIdentifier) {
        switch (actionIdentifier) {
            case "Add Option":
                return "https://image.flaticon.com/icons/svg/189/189674.svg";
            case "Branch Option":
                return "https://image.flaticon.com/icons/svg/189/189676.svg";
            case "Add Decision":
                return "https://image.flaticon.com/icons/svg/189/189665.svg";
            case "Toggle Lock":
                return "https://image.flaticon.com/icons/svg/345/345635.svg";
            case "Toggle Ignore":
                return "https://i.imgur.com/13Dnhbh.png";
            case "Delete Node":
                return "https://image.flaticon.com/icons/svg/190/190406.svg";
            default:
                return null;
        }
    }

    reset = () => {
        return;
    };

    value = () => {
        return this.metadata.value;
    };

    tick = () => {
        return;
    };

    expired = () => {
        return false;
    };

    menuDisplay = () => {
        return {
            keys: [],
            data: {},
        };
    };

    menuActions = () => {
        return;
    };

    addChild(nodeId) {
        this.children.push(nodeId);
    }

    canAddPartner() {
        return false;
    }

    width() {
        return this.minimized
            ? 0
            : this.minified
            ? this._nodeCompactWidth
            : this._nodeFullWidth;
    }

    height() {
        return this.minimized
            ? 0
            : this.minified
            ? this._nodeCompactHeight
            : this._nodeFullHeight;
    }

    display() {
        let display = this.displayIndex % this.displayKeys.length,
            displayKey = this.displayKeys[display],
            displayValue = this.metadata[displayKey];

        const key = this.displayAlias[displayKey] || capitalize(displayKey);

        const value =
            typeof displayValue === "string"
                ? capitalize(displayValue)
                : displayValue;

        let newValue = value;
        switch (key) {
            case "Start":
                newValue = moment(value).format("MMM Do YYYY");
                break;
            case "End":
                newValue = moment(value).format("MMM Do YYYY");
                break;
            case "Expense":
                newValue = `$ ${parseInt(Math.abs(value))}`;
                break;
            case "First Payment":
                newValue = moment(value).format("MMM Do YYYY");
                break;
            case "Principal":
                newValue = `$ ${parseInt(Math.abs(value))}`;
                break;
            case "Price":
                newValue = `$ ${parseInt(Math.abs(value))}`;
                break;
            case "Payments":
                newValue = `$ ${parseInt(Math.abs(value))}`;
                break;
            case "Rating":
                newValue = `${key} : ${value}`;
                break;
            case "Income":
                newValue = `$ ${parseInt(Math.abs(value))}`;
                break;
            default:
        }

        return key === "Node" || key === "Born" ? value : `${newValue}`;
    }

    nextDisplay() {
        this.displayIndex += 1;
        if (this.displayIndex >= this.displayKeys.length) {
            this.displayIndex = 0;
        }

        const displayValue = this.metadata[this.displayKeys[this.displayIndex]];
        if (displayValue == null) {
            this.nextDisplay();
        }

        this._manager._position();
    }

    rx() {
        return this.type === "decision"
            ? 2 *
                  (this.metadata.decisionLevel + 1) *
                  (this.metadata.decisionLevel + 1)
            : this._base / 1;
        //return this.minified ? 10 : this.width();
    }

    ry() {
        return this.type === "decision"
            ? 5 * (this.metadata.decisionLevel + 1)
            : this._base / 1;
        //return 75;
        //return this.minified ? 10 : this.height();
    }

    x(line = false) {
        const visibleNode = this._manager._findRootShown(this);
        return (
            visibleNode.depth *
                (this.minified
                    ? this._nodeCompactXSpacing
                    : this._nodeFullWidth + this._nodeFullXSpacing) +
            (line && this.minified
                ? this._nodeCompactXLineSpacing
                : this._nodeFullXLineSpacing *
                  (this.metadata.selected && this.quickActions().length > 0
                      ? 0.5
                      : 1))
        );
    }

    _relativeBreadth(breadth, maxBreadth) {
        if (maxBreadth % 2 === 0) {
            const mid = maxBreadth / 2;
            return maxBreadth === 1 ? 0 : breadth - mid - 0.5;
        } else {
            const mid = Math.ceil(maxBreadth / 2);
            return maxBreadth === 1 ? 0 : breadth - mid;
        }
    }

    y(_line = false) {
        const relativeBreadth = this._relativeBreadth(
            this.breadth + 1,
            this.maxBreadth || 1
        );
        /*
        if (this.name.split(" ")[0] === "Expense" || this.name === "Disney") {
            console.log(this);
            console.log(
                "\nREL BR",
                this.name,
                "\n\t> breadth:",
                this.breadth + 1,
                "\n\t> max:",
                this.maxBreadth,
                "\n\tdepth:",
                this.depth,
                "\n\trel:",
                relativeBreadth,
                "\n\tdecision:",
                this.metadata.decisionLevel
            );
        }
*/
        return (
            relativeBreadth * (this._nodeFullHeight + this._nodeFullYSpacing) +
            125
        );
    }

    textAnchor(type = "default") {
        if (type === "header") {
            return "middle";
        } else if (type === "header-box") {
            return "start";
        } else {
            return "middle";
        }
    }

    displayImageTranslate() {
        return "translate(90,-5)";
    }

    displayImage() {
        return null;
    }

    statusImage() {
        if (this.metadata.locked && this.metadata.blocked) {
            return bypassSvg;
        } else if (this.metadata.locked) {
            return null;
        } else if (this.metadata.blocked) {
            return bypassSvg;
        } else {
            return null;
        }
    }

    textTranslate(type = "default") {
        const baseHeight = this._base / 2 - 10;
        if (type === "header") {
            return this.minified
                ? this.centerTranslate()
                : `translate(${this.width() / 2},${baseHeight})`;
        } else if (type === "header-box") {
            return this.minified
                ? `translate(0,${this.height() / 2})`
                : `translate(0,${baseHeight - 10})`;
        } else {
            return this.minified
                ? `translate(20,${this.height() / 2})`
                : `translate(${this.width() / 2},${baseHeight + 20})`;
        }
    }

    centerTranslate() {
        return `translate(${this.width() / 2},${this.height() / 2})`;
    }

    xLine() {
        return this.x(true);
    }

    yLine(_min) {
        return this.y(true);
    }

    _status() {
        const activities = this.metadata.activities || [];
        const currentActivity =
            activities.length > 0 ? activities[activities.length - 1] : {};
        return {
            type: currentActivity.activityType,
            state: currentActivity.activityStatus,
        };
    }

    statusColour() {
        const state = this._status().state;

        switch (state) {
            case "Pass":
            case "Passed":
            case "Delivered":
                return "#85C100";
            case "In Transit":
                return "rgb(200,200,0)";
            case "Delayed":
                //return "#FE0504";
                return "rgb(240,40,60)";
            case "Recalled":
                return "rgb(70,70,70)";
            case "":
            case null:
            default:
                return "rgb(90, 105, 220)";
        }
    }

    statusText() {
        const currentStatus = this._status();
        if (currentStatus.type && currentStatus.state) {
            return `${currentStatus.type}: ${currentStatus.state}`;
        } else if (currentStatus.type || currentStatus.state) {
            return currentStatus.type
                ? currentStatus.type
                : currentStatus.state;
        } else {
            return "";
        }
    }
}

class Event extends TreeNode {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "event";

        this.actionsOrder.push("Add Option");
        this.actionsOrder.push("Insert Decision");
        this.actionsOrder.push("Insert Option");
        this.actionsOrder.push("Branch Option");
        // this.actionsOrder.push("Connect Decision");
        //this.actionsOrder.push("Toggle Lock");
        //this.actionsOrder.push("Toggle Ignore");
        //this.actionsOrder.push("Delete Node");

        this.displayKeys.push("start");
        this.displayKeys.push("end");
        this.displayKeys.push("frequency");
        this.displayKeys.push("value");
        this.displayKeys.push("location");
        this.displayKeys.push("rating");
    }

    inDecision = () => {
        return this._manager.inDecision(this);
    };

    quickActions = () => {
        const actions = [];

        actions.push("Toggle Lock");
        actions.push("Toggle Ignore");
        actions.push("Delete Node");

        return actions;
    };

    static requiredInputs() {
        const required = super.requiredInputs();

        required.push({ label: "start", type: "date" });
        required.push({
            label: "cadence",
            type: "string",
            // selection: [
            //     "Annually",
            //     "Monthly",
            //     "Semi-Monthly",
            //     "Bi-Weekly",
            //     "Weekly",
            //     "One-Time",
            // ],
            // defaultSelection: "Select Frequency",
        });

        return required;
    }

    static optionalInputs() {
        const optional = super.optionalInputs();

        optional.push({ label: "end", type: "date" });
        optional.push({ label: "location", type: "text" });
        optional.push({ label: "rating", type: "rating" });

        return optional;
    }

    static availableTypes() {
        return {
            Income: Income,
            Expense: Expense,
            Loan: Loan,
            Mortgage: Mortgage,
            Bank: Bank,
        };
    }
}

class Loan extends Event {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "loan";
        this.displayAlias["value"] = "Payments";
        this.displayAlias["start"] = "First Payment";
        this.displayAlias["closing"] = "End";
        this.displayAlias["interest"] = "Interest Paid";
        this.displayAlias["principal"] = "Principal";
        this.displayKeys.push("loan");
        this.displayKeys.push("principal");
        this.displayKeys.push("interest");
        this.displayKeys.push("closing");

        let loan = this.metadata.loan;
        this.metadata.principal = loan;
        this.metadata.loan = -(
            this.metadata.value * this._numPayments()
        ).toFixed(2);
        this.metadata.interest = (this.metadata.loan - loan).toFixed(2);

        this._currentValue = this.metadata.loan;

        this._calculateArrears();
    }

    value = (paymentMultiplier = 1) => {
        const owed = Math.abs(this._currentValue),
            payments = Math.abs(this.metadata.value) * paymentMultiplier;
        if (owed > payments) {
            return -1 * payments;
        } else {
            return -1 * owed;
        }
    };

    _numPayments = () => {
        let amortization = this.metadata.amortization
            ? this.metadata.amortization
            : 25;
        let numPayments;
        switch (this.metadata.cadence) {
            case "anually":
                numPayments = 1 * amortization;
                break;
            case "monthly":
                numPayments = 12 * amortization;
                break;
            case "semi-monthly":
                numPayments = 24 * amortization;
                break;
            case "bi-weekly":
                numPayments = 26 * amortization;
                break;
            case "weekly":
                numPayments = 52 * amortization;
                break;
            case "one-time":
                numPayments = 1;
                break;
            default:
        }
        return numPayments;
    };

    _calculateArrears = () => {
        const firstPayment = new Date(this.metadata["first payment"]);
        // const amortization = parseInt(
        //     this.metadata["amortization"].match(/\d+/)[0],
        //     10
        // );
        const amortization = this.metadata.amortization
            ? parseInt(this.metadata.amortization)
            : 10;
        const closing = new Date(
            parseInt(firstPayment.getFullYear()) + amortization,
            firstPayment.getMonth(),
            firstPayment.getDay(),
            firstPayment.getHours()
        );

        let closingMonth = closing.getMonth();
        if (closingMonth < 10) {
            closingMonth = "" + 0 + closingMonth;
        }
        let closingDate = closing.getDate();
        if (closingDate < 10) {
            closingDate = "" + 0 + closingDate;
        }

        this.metadata["start"] = this.metadata["first payment"];
        this.metadata["end"] =
            closing.getFullYear() + "-" + closingMonth + "-" + closingDate;
    };

    static requiredInputs() {
        const required = super.requiredInputs();

        required.forEach((req) => {
            if (req.label === "start") {
                req.label = "first payment";
            }
        });

        required.push({
            label: "amortization",
            type: "number",
        });
        required.push({ label: "loan", type: "number" });
        required.push({ label: "rate", type: "number" });
        required.push({ label: "payments", type: "number" });

        return required;
    }

    static optionalInputs() {
        return [];
    }

    reset = () => {
        this._currentValue = this.metadata.loan;
    };

    tick = (payment = 0) => {
        if (payment) {
            this._currentValue -= payment;
        }
    };

    expired = () => {
        return this._currentValue <= 0;
    };

    displayImage() {
        return "https://image.flaticon.com/icons/svg/505/505003.svg";
    }
}

// todo: mortgage extends loan
class Mortgage extends Event {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "mortgage";
        this.displayAlias["value"] = "Payments";
        this.displayAlias["start"] = "First Payment";
        this.displayAlias["closing"] = "End";
        this.displayAlias["interest"] = "Interest Paid";
        this.displayAlias["principal"] = "Principal";
        this.displayKeys.push("price");
        this.displayKeys.push("loan");
        this.displayKeys.push("principal");
        this.displayKeys.push("interest");
        this.displayKeys.push("closing");

        this.metadata.principal =
            this.metadata.price - this.metadata.downpayment;
        this.metadata.loan = -(
            this.metadata.value * this._numPayments()
        ).toFixed(2);
        this.metadata.interest = (
            this.metadata.loan - this.metadata.principal
        ).toFixed(2);

        this._currentValue = this.metadata.loan;

        this._calculateArrears();
    }

    value = (paymentMultiplier = 1) => {
        const owed = Math.abs(this._currentValue),
            payments = Math.abs(this.metadata.value) * paymentMultiplier;
        if (owed > payments) {
            return -1 * payments;
        } else {
            return -1 * owed;
        }
    };

    _numPayments = () => {
        let amortization = this.metadata["amortization period"].match(/\d+/)[0];
        let numPayments;
        switch (this.metadata.cadence) {
            case "annually":
                numPayments = 1 * amortization;
                break;
            case "monthly":
                numPayments = 12 * amortization;
                break;
            case "semi-monthly":
                numPayments = 24 * amortization;
                break;
            case "bi-weekly":
                numPayments = 26 * amortization;
                break;
            case "weekly":
                numPayments = 52 * amortization;
                break;
            case "one-time":
                numPayments = 1;
                break;
            default:
        }
        return numPayments;
    };

    _calculateArrears = () => {
        const firstPayment = new Date(this.metadata["first payment"]);
        const amortization = parseInt(
            this.metadata["amortization period"].match(/\d+/)[0],
            10
        );
        const closing = new Date(
            parseInt(firstPayment.getFullYear()) + amortization,
            firstPayment.getMonth(),
            firstPayment.getDay(),
            firstPayment.getHours()
        );

        let closingMonth = closing.getMonth();
        if (closingMonth < 10) {
            closingMonth = "" + 0 + closingMonth;
        }
        let closingDate = closing.getDate();
        if (closingDate < 10) {
            closingDate = "" + 0 + closingDate;
        }

        this.metadata["start"] = this.metadata["first payment"];
        this.metadata["end"] =
            closing.getFullYear() + "-" + closingMonth + "-" + closingDate;
    };

    static requiredInputs() {
        const required = super.requiredInputs();

        required.forEach((req) => {
            if (req.label === "start") {
                req.label = "first payment";
            }
        });

        required.push({ label: "price", type: "number" });
        required.push({ label: "downpayment", type: "number" });
        required.push({
            label: "mortgage term",
            type: "select",
            selection: [
                "1 year fixed closed",
                "2 year fixed closed",
                "3 year fixed closed",
                "4 year fixed closed",
                "5 year fixed closed",
                "7 year fixed closed",
                "10 year fixed closed",
                "5 year variable closed",
            ],
            defaultSelection: "select term",
        });
        required.push({ label: "rate", type: "number" });
        required.push({
            label: "amortization period",
            type: "select",
            selection: [
                "5 years",
                "10 years",
                "15 years",
                "20 years",
                "25 years",
                "30 years",
            ],
            defaultSelection: "select period",
        });
        required.push({ label: "payments", type: "number" });

        return required;
    }

    static optionalInputs() {
        return [];
    }

    reset = () => {
        this._currentValue = this.metadata.loan;
    };

    tick = (payment = 0) => {
        if (payment) {
            this._currentValue -= payment;
        }
    };

    expired = () => {
        return this._currentValue <= 0;
    };

    displayImage() {
        return "https://image.flaticon.com/icons/svg/505/505003.svg";
    }
}

class Bank extends Event {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "income";
        this.displayAlias["value"] = "Accounts";
    }

    static requiredInputs() {
        const required = super.requiredInputs();

        required.push({ label: "income", type: "number" });

        return required;
    }

    displayImage() {
        return "https://image.flaticon.com/icons/svg/189/189721.svg";
    }
}

class Income extends Event {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "income";
        this.displayAlias["value"] = "Income";
    }

    static requiredInputs() {
        const required = super.requiredInputs();

        required.push({ label: "income", type: "number" });

        return required;
    }

    displayImage() {
        return "https://image.flaticon.com/icons/svg/189/189721.svg";
    }
}

class Expense extends Event {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "expense";
        this.displayAlias["value"] = "Expense";
        this.inflationValue = metadata.value;
        this.inflationRate = manager.inflation;
    }

    static requiredInputs() {
        const required = super.requiredInputs();
        required.push({ label: "cost", type: "number" });
        return required;
    }

    value = () => {
        if (this.metadata.inflation) {
            return this.inflationValue;
        } else {
            return this.metadata.value;
        }
    };

    tick = () => {
        const rate = this.inflationRate ? this.inflationRate : 0.019;
        this.inflationValue = this.inflationValue * (1 + rate) ** (1 / 365);
    };

    reset = () => {
        this.inflationValue = this.metadata.value;
    };

    displayImage() {
        return "https://image.flaticon.com/icons/svg/189/189722.svg";
    }
}

class Decision extends TreeNode {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "decision";

        // this.actionsOrder.push("Add Option");
        this.actionsOrder.push("Add Option");
        this.actionsOrder.push("Insert Decision");
        this.actionsOrder.push("Insert Option");
        this.actionsOrder.push("Branch Option");
        this.actionsOrder.push("Branch Decision");

        //this.actionsOrder.push("Delete Node");
    }

    displayImage() {
        return ""; //"https://image.flaticon.com/icons/svg/1189/1189050.svg";
    }

    quickActions = () => {
        return ["Delete Node"];
    };
}

class Individual extends TreeNode {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "individual";

        this.partner = null;

        this.displayIndex = 0;
        this.displayKeys = ["type", "date of birth", "province"];
    }

    static requiredInputs() {
        const required = super.requiredInputs();

        required.push({ label: "date of birth", type: "date" });
        required.push({ label: "province", type: "text" });

        return required;
    }
}

class Partner extends Individual {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "partner";

        this.displayKeys.push("income");
        this.displayKeys.push("cadence");
        //this.displayKeys.push("disability");

        delete this.actions["Add Option"];

        this.actions["Toggle Ignore"] = {
            title: "Toggle Ignore",
            action: () => {
                this._manager.toggleBlockNode(this);
            },
            description: "ignore",
            reference: Partner,
        };

        this.actionsOrder = ["Toggle Ignore"];
    }

    static availableTypes() {
        return {
            Partner: Partner,
        };
    }

    static requiredInputs() {
        const required = super.requiredInputs();

        required.push({ label: "income", type: "number" });
        required.push({
            label: "cadence",
            type: "select",
            selection: [
                "Annually",
                "Monthly",
                "Semi-Monthly",
                "Bi-Weekly",
                "Weekly",
            ],
            defaultSelection: "Select Income Cadence",
        });
        //required.push({label:"disability",type:"checkbox"});

        return required;
    }
}

class Root extends TreeNode {
    static availableTypes() {
        return {
            Me: Me,
        };
    }
}

class Me extends Individual {
    constructor(name, metadata, manager) {
        super(name, metadata, manager);
        this.type = "me";

        //this.actionsOrder.unshift("Add Partner");
        this.actionsOrder.push("Add Option");
        this.actionsOrder.push("Insert Option");
        this.actionsOrder.push("Insert Decision");

        this.actions["Add Partner"] = {
            action: (partnerDetails) => {
                if (this.canAddPartner()) {
                    this._manager.addPartner(this, partnerDetails);
                } else {
                    toast("Partner already added!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                    });
                }
            },
            description: "add-node",
            nodeType: "Partner",
            reference: Partner,
        };
    }

    canAddPartner() {
        return this.partner === null;
    }

    displayImage() {
        const session = localStorage.getItem("loggedInUser");
        const image = session ? JSON.parse(session).image : null;

        return image || logo;
    }
}

export {
    TreeNode,
    Me,
    Individual,
    Partner,
    Decision,
    Event,
    Income,
    Expense,
    Loan,
    Mortgage,
    Bank,
    Root,
};

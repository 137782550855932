import { useState, ChangeEvent, useEffect, useMemo, useContext, MutableRefObject, useRef } from "react";
import useEntities, { EventStructure } from "../CustomHooks/useEntities";
import { useAppDispatch, useAppSelector } from "store/useAppSelectorDispatch";
import { getDefaultName } from "helpers";
import { createNewEvent } from "helpers/createNewEvent";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import { getPresentableDependencies } from "helpers/nodeDependencyDetectionHelpers";
import { throwError } from "helpers/swalError";
import { EventInputIDContext } from "../Context/EventInputIDContext";
import { updateEntityState } from "helpers/updateEntityState";
import { campaignObject } from "Components/Registry/Campaign";
import CampaignInputView from "./CampaignInputView";
import campaignInputsHandler from "../OnInputChangeHandlers/campaignInputsHandler";
import { websiteVisitorsObject } from "Components/Registry/WebsiteVisitors";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { EventManager } from "Events";
import { addNewEvent, updateEvent } from "actions/eventHelpers";

export default function CampaignInputExperimental({
    line,
    focus,
    edit,
    editData,
}) {
    const dispatch = useAppDispatch();

    const eventId = useContext(EventInputIDContext);
    const baselineManager = useAppSelector(
        (state) => state?.scenario?.baselineManager
    );
    const manager: EventManager = useAppSelector((state) => state?.scenario?.manager);

    const entitiesObject = useAppSelector((state) => state?.entities);

    const dependencyMap: MutableRefObject<DependencyMapSchema> = useRef({ ...useAppSelector((state) => state?.scenario?.loadedScenario?.dependency_map) })

    const [eventData, setEventData] = useState(() => {
        let _eventData: EventStructure;

        if (edit) {
            //load original data
            _eventData = { ...editData.exportData() };
        } else {
            // create a new event with default data
            _eventData = createNewEvent(campaignObject);
            _eventData.name = getDefaultName(
                campaignObject.name(),
                propsObject
            );
        }

        return _eventData;
    });

    const {
        currentEntity, // string - the ID of the current entity
        entitiesMap, // master hashmap containing all entities for that event
        entityIndex, // Index of the current entity in the array of entityIds
        entityIds, // Array - Entity Ids
        handleClickChangeEntity,
        handleClickDeleteEntity,
        handleClickDuplicateEntity,
        handleClickAddEntityCard,
        setEntitiesMap,
    } = useEntities(entitiesObject, eventData, edit, campaignObject, dependencyMap.current); // "mockEvent" represents the eventObject, which will be provided by the backend later.

    const propsObject = useMemo(
        () => ({ manager, line, eventId, focus }),
        [focus, eventId, line, manager]
    );

    const getWebsiteVisitors = () => {
        const websiteVisitors = {};

        getPresentableDependencies(
            websiteVisitors,
            websiteVisitorsObject.constant(),
            propsObject, // Temporary object
            true
        );

        if (!Object.keys(websiteVisitors).length) {
            throwError(
                "warning",
                "No Website Visitor nodes found upstream or in baseline",
                "You can still add this Event to this Scenario as a placeholder for future calculations"
            );
        }

        return websiteVisitors;
    };

    useEffect(() => {
        setEntitiesMap((prevEntitiesMap) => {
            const websiteVisitors = getWebsiteVisitors();
            const newEntitiesMap = { ...prevEntitiesMap };
            newEntitiesMap[currentEntity].data.websiteVisitors =
                websiteVisitors;
            return newEntitiesMap;
        });
        // setEntitiesMap and baselineManager should never change so only if currentEntity changes, does this useEffect get run;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, baselineManager, setEntitiesMap]);

    const onHandleSubmit = () => {
        eventData.mostRecentEntity = entityIndex ?? 0;
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                eventData,
                entitiesMap,
                entityIds,
                passedCheck,
                edit,
                { dependencyMap: dependencyMap.current }
            )
        );
    };

    const onChangeNameDescription = (
        e: ChangeEvent<HTMLInputElement>,
        id: "name" | "description"
    ) => {
        const value = e.target.value;

        switch (id) {
            case "name":
                setEventData((prevState) => ({
                    ...prevState,
                    name: value,
                }));
                break;
            case "description":
                setEventData((prevState) => ({
                    ...prevState,
                    description: value,
                }));
                break;
            default:
            // noop
        }
    };

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement>,
        id:
            | "websiteVisitor"
            | "entityName"
            | "cadence"
            | "value"
            | "costPerVisit"
    ) => {
        const value = e.target.value;
        const newEntitiesMap = campaignInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleDateSelection = (id, value) => {
        const newEntitiesMap = campaignInputsHandler(
            value,
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current
        );
        setEntitiesMap(newEntitiesMap);
    }

    const updateAccount = (accountType, id) => {
        const value = accountType;
        const newEntitiesMap = campaignInputsHandler(
            "",
            id,
            entitiesMap,
            currentEntity,
            eventId,
            dependencyMap.current,
            value
        );
        setEntitiesMap(newEntitiesMap);
    };

    const handleEntityStateChange = (id, _value) => {
        const newEntitiesMap = updateEntityState(
            entitiesMap,
            currentEntity,
            id
        );
        setEntitiesMap(newEntitiesMap);
    };

    const passedCheck =
        !!eventData.name && campaignObject.checkInput(entitiesMap);

    return (
        <CampaignInputView
            entitiesMap={entitiesMap}
            currentEntity={currentEntity}
            entityIndex={entityIndex}
            handleClickAddEntityCard={handleClickAddEntityCard}
            handleClickChangeEntity={handleClickChangeEntity}
            handleClickDeleteEntity={handleClickDeleteEntity}
            handleClickDuplicateEntity={handleClickDuplicateEntity}
            entitiesLength={entityIds.length}
            onChangeNameDescription={onChangeNameDescription}
            onHandleSubmit={onHandleSubmit}
            handleOnChange={handleOnChange}
            passedCheck={passedCheck}
            edit={edit}
            eventData={eventData}
            handleEntityStateChange={handleEntityStateChange}
            updateAccount={updateAccount}
            handleDateSelection={handleDateSelection}
        />
    );
}

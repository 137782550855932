import React from "react";
import styles from "./index.module.css";
import moment from "moment";

export default class NotificationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: [],
        };
    }
    componentDidMount() {
        const { notification } = this.props.data;
        this.setState({ notification });
        this.props.onHandleNotificationSeen();
    }

    readAllNotification = () => {
        this.props.history.push("/settings/notification");
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    <div className={styles.header}>Notifications</div>
                    <div
                        onClick={this.readAllNotification}
                        className={styles.readAll}
                    >
                        Read All
                    </div>
                </div>
                {this.state.notification &&
                    this.state.notification.map((notif, i) => {
                        return (
                            <div
                                className={
                                    !notif.seen
                                        ? styles.notification
                                        : styles.notificationSeen
                                }
                                key={i}
                            >
                                <div className={styles.commentDate}>
                                    {moment(notif.time).format(
                                        "h:mm a, MMM Do"
                                    )}
                                </div>
                                <div style={{ display: "flex" }}>
                                    <img
                                        alt="alt"
                                        className={styles.image}
                                        src={notif.commenterImage}
                                    />
                                    <div className={styles.text}>
                                        <span className={styles.notifName}>
                                            @{notif.commenterName}
                                        </span>{" "}
                                        {notif.message}
                                        <span
                                            className={
                                                styles.scenarioHighlighted
                                            }
                                        >
                                            {notif.scenario.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                {this.state.notification.length <= 0 && (
                    <div className={styles.notification}>
                        <div className={styles.text}>No notification</div>
                    </div>
                )}
            </div>
        );
    }
}

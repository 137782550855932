import { getRelevantEntities } from "actions/getNodeEntityActions";
import { ScenarioSchema } from "reducers/typesSchema/ScenarioSchema";
import { stringsToDates } from "./stringToDateHelpers";
import moment from "moment";

/**
    This function takes in a scenario and its startDate as input and iterates through every node in the scenario
    and every entity within every node to find the earliest start date. This function returns the earliest date 
    (which is either an entity startDate or the scenario startDate itself) as a string formatted as "YYYY-MM-DD".
 */
export const getEarliestStartDate = (
    scenario: ScenarioSchema,
    scenarioStartDate: string
) => {
    // Chose the year 3000, hopefully no one needs to calculate further ahead than that
    let [startDate] = stringsToDates("3001-01-01");
    scenario.data.nodes.forEach((node) => {
        Object.values(getRelevantEntities(node.entities)).forEach((entity) => {
            // Compare the startDate of each entity with our current earliest date
            const [entityDate] = stringsToDates(entity.startDate);
            if (entityDate.isBefore(startDate)) {
                // Update our earliest startDate
                startDate = entityDate;
            }
        });
    });
    // Lastly we check to see if the scenarioStartDate is earlier than our current
    // earliest date, and update our startDate if it is
    const scenarioStart = moment(scenarioStartDate);
    if (scenarioStart.isBefore(startDate)) {
        startDate = scenarioStart;
    }
    // Return the date as a string formatted as "YYYY-MM-DD"
    return startDate.format("YYYY-MM-DD");
};

import type { EntitiesSchema } from "reducers/typesSchema/entitiesSchema";

export function updateEntityState(
    entitiesMap: EntitiesSchema,
    currentEntity: string,
    id: string
) {
    const newEntitiesMap = { ...entitiesMap };
    const currentEntityObject = { ...(newEntitiesMap[currentEntity] || {}) };
    switch (id) {
        case "calculateState":
            if (!currentEntityObject.bypassState) {
                currentEntityObject.calculateState = true;
            }
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "nonCalculateState":
            if (!currentEntityObject.bypassState) {
                currentEntityObject.calculateState = false;
            }
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        case "bypassState":
            currentEntityObject.bypassState = !currentEntityObject.bypassState;
            newEntitiesMap[currentEntity] = currentEntityObject;
            break;
        default:
    }
    return newEntitiesMap;
}

import { editScenario, getUserScenarios } from "actions/scenario.js";
import type { EventManager } from "Events";
import type Event from "Events/_event";
import type { AppThunk } from "store";
import {
    TRIGGER_UPDATE,
    SET_FOCUS,
    EDIT_NODE,
    COPY_EVENT,
    POP_UP_OPTION,
    TOGGLE_COLLECTION_MODE,
    UPDATE_CONNECTIONS_SELECTED,
    TOGGLE_DELETION_MODE,
} from "./types";
import { setShowPasteOptionModal } from "../actions/modalActions";
import { getMaxEventsPerScenario } from "../helpers/userHelpers";
import { createEntityCopies } from "../actions/nodeEntityActions";
import { setOnboardingData, updateBaseline } from "../actions/scenario";
import { upsertChartGraph } from "actions/chartGraphActions";
import { v4 as uuidv4 } from "uuid";
import { LineActionIds, lineActionsMap } from "./canvasLineActions";
import _ from "lodash";
import { getManager } from "helpers/getManager";
import BaselineDataManager from "Events/baselineManager";
import * as uuid from "uuid";
import { decisionObject } from "Components/Registry/Decision";
import { containerObject } from "Components/Registry/Container";
import { DependencyMapSchema } from "reducers/typesSchema/dependencyMapSchema";
import { updateDependencyMap } from "./dependencyMapActions";
import { deleteEntityFromMap } from "helpers/updateDependencyMap";
import { loadScenario } from "./scenarioHelpers";
import { addNewEvent, updateEvent } from "actions/eventHelpers";
import { handleSubmitNodesAndEntities } from "actions/nodeEntityActions";
import {
    getRelevantEntities,
    getSingleEntity,
} from "actions/getNodeEntityActions";

export enum EventActionIds {
    bypassEvent = "bypassEvent:9f28b430-9dab-4df7-9e72-52d6acaa96be",
    lockEvent = "lockEvent:c0d9c4bd-fa0b-4c06-b90a-e9b74e447718",
    focusEvent = "focusEvent:fd447014-b9da-454b-bb90-66ae463070fb",
    editEvent = "editEvent:7a3fe373-1440-4424-8628-9ea07df75512",
    addEvent = "addEvent:18e1e922-1129-4989-83e4-4c33b6215f46",
    copyEvent = "copyEvent:358d04c8-3815-45ef-b019-43e5d838c170",
    pasteEvent = "pasteEvent:79196186-b8cc-46bd-bea5-be3588f65815",
    addEventForDeletion = "addEventForDeletion:b50ada29-47a7-44d8-a6f4-05641e3c0756",
    deleteEvents = "deleteEvents:9bc9a4dc-c5b2-4c02-a811-d8f436268403",
    connectEvent = "connectEvent:28be1419-e9dc-4b95-aca0-de1d6256f354",
    pinEvent = "pinEvent:971efff2-e57c-49cd-9cbe-0e0df84cf280",
    starEvent = "starEvent:470b2e61-7f65-4267-92fe-c91cf44af206",
    collapseExpandContainer = "collapseExpandContainer:2632c135-7701-4eb6-bb56-9f9549f92188",
}

export const eventActionsMap = {
    [EventActionIds?.bypassEvent]: bypassEvent,
    [EventActionIds?.lockEvent]: lockEvent,
    [EventActionIds?.focusEvent]: focusEvent,
    [EventActionIds?.editEvent]: editEvent,
    [EventActionIds?.addEvent]: addEvent,
    [EventActionIds?.copyEvent]: copyEvent,
    [EventActionIds?.pasteEvent]: pasteEvent,
    [EventActionIds?.addEventForDeletion]: addEventForDeletion,
    [EventActionIds?.deleteEvents]: deleteEvents,
    [EventActionIds?.connectEvent]: connectEvent,
    [EventActionIds?.pinEvent]: pinEvent,
    [EventActionIds?.starEvent]: starEvent,
    [EventActionIds?.collapseExpandContainer]: collapseExpandContainer,
};

/**
 * @param eventId
 * Finds the event selected via the passed eventId and toggles its bypassed property. Dispatches TRIGGER_UPDATE to force a re-render.
 */
export function bypassEvent(
    eventId: string,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const selectedEvent: Event = manager?._findEvent(eventId);
        if (selectedEvent?.type === containerObject.constant()) {
            const containerEntity = getSingleEntity(
                selectedEvent?.entities?.[0] ?? ""
            );
            if (containerEntity) {
                const containedEventIds = Object.keys(
                    containerEntity?.data?.containedEvents ?? {}
                );
                manager._bypassContainedNodes(
                    !selectedEvent.bypassed,
                    containedEventIds
                );
            }
        }
        selectedEvent?.toggleBypassed();
        dispatch({
            type: TRIGGER_UPDATE,
            payload: uuidv4(),
        });
    };
}

/**
 * @param eventId
 * Finds the event selected via the passed eventId and toggles its locked property. Dispatches TRIGGER_UPDATE to force a re-render.
 */
export function lockEvent(eventId: string, isBaseline = false): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const selectedEvent: Event = manager?._findEvent(eventId);
        selectedEvent?.toggleLocked();
        dispatch({
            type: TRIGGER_UPDATE,
            payload: uuidv4(),
        });
    };
}

/**
 * @param eventId
 * Finds the event selected via the passed eventId and sets the applications focus to it.
 * @if connectionMode is true, you arent able to update the focus.
 * Instead check to see if the event selected is able to be connected to the connectionHead, and if so, update connectionsSelected accordingly.
 */
export function focusEvent(
    eventId: string | null,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const selectedEvent: Event = manager?._findEvent(eventId);

        const connectionMode = getState()?.scenario?.connectionMode;
        const connectableEvents = getState()?.scenario?.connectableEvents;

        if (connectionMode) {
            if (!eventId) {
                dispatch({
                    type: UPDATE_CONNECTIONS_SELECTED,
                    payload: null,
                });
            } else if (connectableEvents?.[eventId]) {
                dispatch({
                    type: UPDATE_CONNECTIONS_SELECTED,
                    payload: eventId,
                });
            }
        } else {
            const existingLine = getState()?.scenario?.line?.lineId;
            if (existingLine) {
                dispatch(lineActionsMap?.[LineActionIds?.focusLine](null));
            }

            const chartGraphGroupNodesMode =
                getState()?.chartGraphGroupNodesMode;

            if (chartGraphGroupNodesMode !== "default" && eventId) {
                const curNodeIds = getState()?.chartGraph?.nodeIds;
                if (chartGraphGroupNodesMode === "singleNode") {
                    const nodeIds: string[] = [eventId];
                    if (_.isEqual(curNodeIds, nodeIds)) return;

                    dispatch(
                        upsertChartGraph({
                            nodeIds,
                        })
                    );
                } else if (chartGraphGroupNodesMode === "cumulativeNodes") {
                    const curThread = getState().scenario?.highlightedThread;
                    const nodeIds = manager.getNodesFromThreadUpToNode(
                        curThread.signature,
                        eventId
                    );

                    const curNodeIds = getState().chartGraph.nodeIds;
                    if (_.isEqual(curNodeIds, nodeIds)) return;
                    dispatch(
                        upsertChartGraph({
                            nodeIds,
                        })
                    );
                }
            }

            dispatch({
                type: SET_FOCUS,
                payload: selectedEvent,
            });
        }
    };
}

/**
 * @param eventId
 * Finds the event selected via the passed eventId and updates the edit states held in redux. Causes the edit modal to open.
 */
export function editEvent(eventId: string, isBaseline = false): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const selectedEvent: Event = manager?._findEvent(eventId);
        dispatch({
            type: EDIT_NODE,
            payload: selectedEvent,
        });
    };
}

/**
 * Opens the add event modal based off the following conditions.
 * @if the passed eventId is equal to the focused event's id we dispatch the POP_UP_ACTION with the current focus.
 * @else-if there is no focused event we then need to set the focus given the passed eventId before dispatching the POP_UP_ACTION with the new focus.
 */
export function addEvent(eventId: string, isBaseline = false): AppThunk<void> {
    return (dispatch, getState) => {
        const currentFocus = getState()?.scenario?.focus;
        const currentLine = getState()?.scenario?.line;
        const manager = getManager(isBaseline, getState());
        const selectedEvent: Event = manager?._findEvent(eventId);

        const currentFocusCanAdd =
            currentFocus?.type === containerObject.constant() ||
            currentFocus?.type === decisionObject.constant() ||
            currentFocus?.children?.length < 1;
        const selectedEventCanAdd =
            selectedEvent?.type === containerObject.constant() ||
            selectedEvent?.type === decisionObject.constant() ||
            selectedEvent?.children?.length < 1;

        if (!currentFocusCanAdd || !selectedEventCanAdd) return;

        if (selectedEvent?.type === containerObject.constant()) {
            const containerEntity = getSingleEntity(
                selectedEvent?.entities?.[0] ?? ""
            );
            const tailNodeEvent: Event = manager?._findEvent(
                containerEntity?.data?.tailNode
            );
            if (tailNodeEvent?.children?.length > 0) {
                const tailNodeChildId = tailNodeEvent?.children?.[0]?.id;
                const lineIdString = `${tailNodeEvent?.id}_${tailNodeChildId}`;
                dispatch(focusEvent(null));
                dispatch(
                    lineActionsMap?.[LineActionIds?.focusLine](lineIdString)
                );
                const newLine = getState()?.scenario?.line;
                dispatch({
                    type: POP_UP_OPTION,
                    focus: null,
                    line: newLine,
                });
                return;
            }
        }
        if (currentFocus && currentFocus?.id === eventId) {
            dispatch({
                type: POP_UP_OPTION,
                focus: currentFocus,
                line: currentLine,
            });
        } else if (eventId) {
            dispatch(focusEvent(eventId));
            const newFocus = getState()?.scenario?.focus;
            dispatch({
                type: POP_UP_OPTION,
                focus: newFocus,
                line: currentLine,
            });
        }
    };
}

/**
 * @param eventId
 * Finds the event selected via the passed eventId, copies it, creating and storing the copied event in redux.
 */
export function copyEvent(eventId: string, isBaseline = false): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const selectedEvent: Event = manager?._findEvent(eventId);
        const copiedEvent: Event = selectedEvent?.copy();
        dispatch({
            type: COPY_EVENT,
            payload: copiedEvent,
        });
    };
}

/**
 * Accepts either a eventId or null.
 *
 * @if a eventId is provided, find the event via the passed id and paste from there.
 *
 * @else if no eventId is provided, paste from the focused event.
 */
export function pasteEvent(
    eventId: string | null,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const loadedScenario = getState()?.scenario?.loadedScenario;
        const copiedEvent: Event = getState()?.scenario?.copiedEvent;
        const onboardingData = getState()?.scenario?.onboardingData;
        const currentFocus = getState()?.scenario?.focus;
        const selectedEvent: Event = manager?._findEvent(eventId);
        const dependencyMap: DependencyMapSchema = {
            ...getState()?.scenario?.loadedScenario?.dependency_map,
        };

        if (!copiedEvent) {
            return;
        }

        if (selectedEvent?.relevantContainerId) return;

        const currentFocusCanAdd =
            currentFocus?.type === decisionObject.constant() ||
            currentFocus?.children?.length < 1;
        const selectedEventCanAdd =
            selectedEvent?.type === decisionObject.constant() ||
            selectedEvent?.children?.length < 1;

        if (!currentFocusCanAdd || !selectedEventCanAdd) return;

        const generateNewEvent = (newEventId?: string) => {
            const newEvent = manager?.createEvent(
                copiedEvent?.type,
                copiedEvent,
                // @ts-ignore
                newEventId ?? null,
                false,
                !newEventId ? true : false
            );

            newEvent.name = `${copiedEvent.name} Copy`;

            if (newEvent?.relevantContainerId) {
                newEvent.relevantContainerId = "";
            }

            if (eventId) {
                if (onboardingData === 11) {
                    manager.attachToNodes(
                        newEvent.id,
                        [selectedEvent.id],
                        manager?._findEvent(copiedEvent.id)?.children
                    );
                } else {
                    manager?.attachToNodes(
                        newEvent.id,
                        [selectedEvent.id],
                        [],
                        true,
                        !newEventId ? copiedEvent.id ?? "" : ""
                    );
                    if (
                        manager?.isBaseline &&
                        manager instanceof BaselineDataManager
                    ) {
                        const newScenario = manager.handleExport();
                        dispatch(updateBaseline(newScenario, manager));
                    }
                }
            } else {
                if (currentFocus) {
                    if (onboardingData === 11) {
                        manager?.attachToNodes(
                            newEvent.id,
                            [currentFocus.id],
                            manager?._findEvent(copiedEvent.id)?.children
                        );
                    } else {
                        manager?.attachToNodes(
                            newEvent.id,
                            [currentFocus.id],
                            []
                        );
                    }
                }
            }

            if (isBaseline) manager?._updateScenarioCanvas();
            manager?.calculate();

            dispatch(focusEvent(null));
        };

        const pasteCallback = (action) => {
            if (copiedEvent != null) {
                const loadedScenarioNode = loadedScenario?.data?.nodes?.filter(
                    (node) => {
                        return node?.type !== "Decision";
                    }
                );

                const loadedScenarioNodeCount = loadedScenarioNode?.length - 1;

                const nodeCount = getMaxEventsPerScenario(null);

                if (loadedScenarioNodeCount <= nodeCount) {
                    if (action === "duplicate") {
                        const newEventId = uuid.v4();
                        const newEntityIdsMap = {};
                        // Here we create a mapping between the old entity Ids and the new entity Ids
                        copiedEvent?.entities.forEach((entity) => {
                            newEntityIdsMap[entity.id] = uuid.v4();
                        });
                        createEntityCopies(
                            copiedEvent?.entities,
                            newEntityIdsMap,
                            dependencyMap,
                            newEventId
                        ).then((response) => {
                            copiedEvent.entities = response?.data?.map(
                                (id) => ({
                                    id,
                                    active: true,
                                })
                            );
                            dispatch(updateDependencyMap(dependencyMap));
                            generateNewEvent(newEventId);
                        });
                    } else {
                        generateNewEvent();
                    }
                }

                if (onboardingData === 11) {
                    setOnboardingData(12);
                }
            }
        };

        setShowPasteOptionModal({
            show: true,
            callback: pasteCallback,
        });
    };
}

/**
 * @param eventId
 * Finds the event selected via the passed eventId and sets the deletionData state held in redux based off the selected event.
 * Now deleteEvents can be called to delete based off the selected event.
 * Dispatches TOGGLE_DELETION_MODE to update the deletionData state held in redux.
 */
export function addEventForDeletion(
    eventId: string | null,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        if (eventId) {
            const selectedEvent: Event = manager?._findEvent(eventId);
            if (selectedEvent?.relevantContainerId) return;
            if (selectedEvent?.children?.length > 1) return;
            const deletionData = manager?.updateDeletionData(selectedEvent);
            dispatch({
                type: TOGGLE_DELETION_MODE,
                payload: deletionData,
            });
        } else {
            dispatch({
                type: TOGGLE_DELETION_MODE,
                payload: null,
            });
        }
    };
}

/**
 * Deletes events based off the deletionData held in redux. If deletionData is null it wont delete anything.
 * Dispatches TOGGLE_DELETION_MODE to to close the delete event pop up.
 */
export function deleteEvents(): AppThunk<void> {
    return (dispatch, getState) => {
        const deletionData = getState()?.scenario?.deletionData;
        const manager = getManager(deletionData?.isBaselineNode, getState());
        if (deletionData) {
            // Update dependency map
            if (deletionData?.toDelete) {
                const dependencyMap: DependencyMapSchema = {
                    ...getState().scenario?.loadedScenario?.dependency_map,
                };
                for (const event of deletionData?.toDelete) {
                    for (const entity of event.entities) {
                        const entityData: any = getSingleEntity(entity.id);
                        if (entityData?.type === containerObject.constant()) {
                            const containedEventIds = Object.keys(
                                entityData?.data?.containedEvents ?? {}
                            );
                            manager?._removeContainedNodes(containedEventIds);
                        }
                        deleteEntityFromMap(dependencyMap, entity.id);
                    }
                }
                dispatch(updateDependencyMap(dependencyMap));
            }

            manager?.deleteWithDeletionData(deletionData);
            if (manager?.isBaseline && manager instanceof BaselineDataManager) {
                const newScenario = manager?.handleExport();
                dispatch(updateBaseline(newScenario, manager));
            }
            dispatch({
                type: TOGGLE_DELETION_MODE,
                payload: null,
            });
        } else {
            dispatch({
                type: TRIGGER_UPDATE,
                payload: uuidv4(),
            });
        }
    };
}

/**
 * @If this action is called and the connectionMode state held in redux is false (the first click of the connect button):
 * Find the event selected via the passed eventId and set the connectionHead to that event, connectionMode to true, and set an array of connectable down stream events.
 * @If this action is called and the connectionMode state held in redux is true (the second click of the connect button):
 * Set all the connectionsSelected to be children of the connectionHead and update the scenario canvas.
 */
export function connectEvent(
    eventId: string,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const selectedEvent: Event = manager._findEvent(eventId);
        const connectionMode: Boolean = getState()?.scenario?.connectionMode;
        const scenarioNodes = getState()?.scenario?.loadedScenario?.data?.nodes;
        const connectionHead: string = getState()?.scenario?.connectionHead;
        const connectionsSelected = getState()?.scenario?.connectionsSelected;

        if (connectionMode) {
            const connectionHeadEvent: Event =
                manager?._findEvent(connectionHead);
            const connectionsSelectedEvents: Event[] = [];
            connectionsSelected?.forEach((selectedEventId) =>
                connectionsSelectedEvents?.push(
                    manager?._findEvent(selectedEventId)
                )
            );
            connectionHeadEvent?.setChildren(connectionsSelectedEvents, true);
            manager?.calculate();
            manager?._updateScenarioCanvas();

            dispatch({
                type: TOGGLE_COLLECTION_MODE,
                connectionMode: false,
                connectionHead: null,
                connectableEvents: [],
            });

            dispatch({
                type: UPDATE_CONNECTIONS_SELECTED,
                payload: null,
            });
        } else {
            const connectableEvents = {};
            scenarioNodes.forEach((node) => {
                const fullNode = manager?._findEvent(node.id);
                if (
                    fullNode?.depth >= selectedEvent?.depth &&
                    !selectedEvent?.children?.includes(fullNode) &&
                    fullNode?.id !== selectedEvent?.id
                ) {
                    connectableEvents[fullNode.id] = true;
                }
            });

            dispatch({
                type: TOGGLE_COLLECTION_MODE,
                connectionMode: true,
                connectionHead: selectedEvent.id,
                connectableEvents: connectableEvents,
            });
        }
    };
}

/**
 * @param eventId
 * The pin action doesn't currently exist, consider making one.
 */
export function pinEvent(_eventId: string): AppThunk<void> {
    return (dispatch, _getState) => {
        console.log(
            "The pin action doesn't currently exist, consider making one."
        );
        dispatch({
            type: TRIGGER_UPDATE,
            payload: uuidv4(),
        });
    };
}

/**
 * @param eventId
 * The star action doesn't currently exist, consider making one.
 */
export function starEvent(_eventId: string): AppThunk<void> {
    return (dispatch, _getState) => {
        console.log(
            "The star action doesn't currently exist, consider making one."
        );
        dispatch({
            type: TRIGGER_UPDATE,
            payload: uuidv4(),
        });
    };
}

export function collapseExpandContainer(
    _containerEventId: string,
    isBaseline = false
): AppThunk<void> {
    return (dispatch, getState) => {
        const manager = getManager(isBaseline, getState());
        const containerEvent: Event = manager?._findEvent(_containerEventId);
        const containerEntitiesMap = getRelevantEntities(
            containerEvent?.entities
        );
        const containerEntity = Object.values(containerEntitiesMap ?? {})?.[0];
        const newContainerEntitiesMap = {
            [containerEntity.id]: {
                ...containerEntity,
                data: {
                    ...containerEntity.data,
                    expanded: !containerEntity.data.expanded ?? false,
                },
            },
        };
        dispatch(
            handleSubmitNodesAndEntities(
                addNewEvent,
                updateEvent,
                containerEvent.exportData(),
                newContainerEntitiesMap,
                Object.keys(newContainerEntitiesMap),
                true,
                true,
                {}
            )
        );
    };
}

export const resetAllEventPositions = (): AppThunk<void> => {
    return (dispatch, getState) => {
        const state = getState();
        const manager = state?.scenario?.manager as EventManager;
        const loadedScenario = state?.scenario?.loadedScenario;
        const resetEvents = manager?.resetUserSetXY();

        const newScenario = {
            ...loadedScenario,
            data: {
                ...loadedScenario.data,
                nodes: resetEvents,
            },
        };

        loadScenario(newScenario, manager);
        dispatch(
            editScenario(newScenario, (err, data) => {
                if (data) {
                    dispatch(getUserScenarios());
                } else if (err) {
                    console.log("error favouriting an account", err);
                }
            })
        );
    };
};

import React from "react";

// Import Event Card and Event Input
import RentCard from "../Card/rentCard";
import RentInput from "../InputContainer/RentInput";

import rentConnectionImage from "../../Assets/_optionDescriptionIcons/Grant, Tuition, Rent.png";

// Import Coloured PNGs
import rentBlack from "../../Assets/_eventNodeTypes/rent-black.png";
import rentBlue from "../../Assets/_eventNodeTypes/rent-blue.png";
import rentWhite from "../../Assets/_eventNodeTypes/rent-white.png";
import rentGrey from "../../Assets/_eventNodeTypes/rent-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";

// Never used
// import { valueDoesNotExist } from "../../helpers/index";

export const rentObject = {
    constant: function () {
        return Rent_Constant();
    },

    checkInput: function (state) {
        return Rent_CheckInput(state);
    },

    svg: function () {
        return Rent_Svg();
    },

    eventNodeMenu: function (focus, getWalkScore) {
        return Rent_EventNodeMenu(focus, getWalkScore);
    },

    indexText: function () {
        return Rent_IndexText();
    },

    inputInfoPassing: function (minProps, libraryProps) {
        return Rent_InputComponentInfoPassing(minProps, libraryProps);
    },

    optionDescription: function () {
        return Rent_OptionDescription();
    },

    colouredPngs: function () {
        return Rent_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Rent_SideModalImage();
    },
    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Rent_CheckInput = (state) => {
    if (!state.name) return false;
    if (!state.cadence) return false;
    if (
        state.value === undefined ||
        state.value === null ||
        state.value === "" ||
        isNaN(state.value)
    )
        return false;
    if (!state.start) return false;
    else return true;
};

// EFFECTS: Returns constant for event
const Rent_Constant = () => {
    return "Rent";
};

// EFFECTS: Returns black png / svg for event
const Rent_Svg = () => {
    return rentBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Rent_EventNodeMenu = (focus, getWalkScore) => {
    return <RentCard data={focus} getWalkScore={getWalkScore} />;
};

// EFFECTS: Returns the Index Text for event
const Rent_IndexText = () => {
    return "Select an input field to learn more about how each value affects your Rent calculations.";
};

// EFFECTS: Returns all icons for event ??
const Rent_Icons = () => {
    let pngs = new Map([
        ["black", rentBlack],
        ["blue", rentBlue],
        ["white", rentWhite],
        ["grey", rentGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Rent_FrontEndExports = (node) => {
//     return undefined;
// };

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Rent_InputComponentInfoPassing = (minProps, libraryProps) => {
    return <RentInput {...minProps} {...libraryProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, rents for event ??
const Rent_OptionDescription = () => {
    const tags = ["Option"];
    const type = rentObject.constant();
    const description =
        "Renting or leasing is a common way to obtain for a long-term home without purchasing the property.";
    const usedForText =
        "Use the Rent Event to model your rental housing expenses.";
    const connection = "";
    const connectionImage = rentConnectionImage;
    const learnMoreLink =
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/housing/renting.html";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Rent_SideModalImage = () => {
    return sideImage;
};

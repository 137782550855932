// COPY AND PASTE THIS AS A TEMPLATE

// Default Imports
import React from "react";

// Import Event Card and Event Input
import BusinessCard from "../Card/BusinessCard";
import BusinessInput from "../InputContainer/BusinessInput";

// Import Connection Image (if exists)
import BusinessConnectionImage from "../../Assets/_optionDescriptionIcons/Business, Contract, Expense.png";

// Import Coloured PNGs
import businessBlack from "../../Assets/_eventNodeTypes/business-black-updated.svg";
import businessBlue from "../../Assets/_eventNodeTypes/business-blue.png";
import businessWhite from "../../Assets/_eventNodeTypes/business-white.png";
import businessGrey from "../../Assets/_eventNodeTypes/business-grey.png";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/SMBBasicModule2x.png";

// Notes
// To call an object with no parameters, can simply use
//  - businessObject.constant()

// I left constants alone for now, rather than removing the constant, as there are a lot of dependancies on it

// Left Front / Back end for until DE3.0 Is deployed

// In order to call a function with a PARAMETER inside of the Object, must use call() like:
// - businessObject.checkInput.call(businessObject, this.state);
// The first parameter must be referring to the object itself in order to find the proper "this"

// "Interface" object for Event
export const businessObject = {
    constant: function () {
        return Business_Constant();
    },
    name: function () {
        return "Business";
    },

    checkInput: function (state) {
        return Business_CheckInput(state);
    },

    svg: function () {
        return Business_Svg();
    },

    eventNodeMenu: function (focus) {
        return Business_EventNodeMenu(focus);
    },

    indexText: function () {
        return Business_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return Business_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return Business_OptionDescription();
    },

    colouredPngs: function () {
        return Business_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return Business_SideModalImage();
    },

    // Add Front/Back end data exports
};

// Helper functions for Event

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const Business_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.country || !entity.state) return false;
        return true;
    };
    Object.values(entitiesMap).values((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const Business_Constant = () => {
    return "8f8e3dcb-90c1-4544-aad1-48b20b9f1f0e";
};

// EFFECTS: Returns black png / svg for event
const Business_Svg = () => {
    return businessBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const Business_EventNodeMenu = (focus) => {
    return <BusinessCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const Business_IndexText = () => {
    return "Select an input field to learn more about your business calculations.";
};

// EFFECTS: Returns all icons for event ??
const Business_Icons = () => {
    let pngs = new Map([
        ["black", businessBlack],
        ["blue", businessBlue],
        ["white", businessWhite],
        ["grey", businessGrey],
    ]);
    return pngs;
};

// TODO AFTER DANIEL REFACTORS
// REQUIRES: Node
// EFFECTS: Returns metadeta structure for event ??
// const Business_FrontEndExports = (node) => {
//     return undefined
// }

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const Business_InputComponentInfoPassing = (minProps) => {
    return <BusinessInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, businesss for event ??
const Business_OptionDescription = () => {
    const tags = ["Option"];
    const type = businessObject.name();
    const description =
        "The Business Event is an organizational Utility Event most commonly used in the Baseline. By default, every Baseline starts with Business Event as the root for all Scenarios. It contains basic information about location and region that can be used by downstream Events for location-aware calculations. Additionally, if generating a model that has multiple offices or business units, use multiple Business Events in the Baseline to start new Threads for each of these units. Any downstream Events in the Baseline inherit their upstream Business Event’s properties.";
    const usedForText =
        "Use multiple Business Events in the Baseline to represent different business units or locations. For example, if a company has offices in Los Angeles, Amsterdam and Beijing, three Business Events can be added to the Baseline to represent each location.";
    const connection = "";
    const connectionImage = BusinessConnectionImage;
    const learnMoreLink = null;
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const Business_SideModalImage = () => {
    return sideImage;
};

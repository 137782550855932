// Default Imports
import React from "react";

import AverageOrderCard from "../Card/averageOrderCard";

// Import Connection Image
import debitCreditConnectionImage from "../../Assets/_optionDescriptionIcons/Bank, Debit Credit, Debit Credit.png";

// Import Coloured PNGs
import averageOrderBlack from "../../Assets/_eventNodeTypes/averageOrderSvg.svg";

// Import Side Modal Image, this is determined by which category the event belongs to
import sideImage from "../../Assets/_nodeInputs/whatifiBasics2x.png";
import AverageOrderInput from "Components/InputContainer/AverageOrder/AverageOrderInput";

export const averageOrderObject = {
    constant: function () {
        return averageOrderObject_Constant();
    },

    name: function () {
        return "Average Order";
    },

    checkInput: function (state) {
        return averageOrderObject_CheckInput(state);
    },

    svg: function () {
        return averageOrderObject_Svg();
    },

    eventNodeMenu: function (focus) {
        return averageOrderObject_EventNodeMenu(focus);
    },

    indexText: function () {
        return averageOrderObject_IndexText();
    },

    inputInfoPassing: function (minProps) {
        return averageOrderObject_InputComponentInfoPassing(minProps);
    },

    optionDescription: function () {
        return averageOrderObject_OptionDescription();
    },

    colouredPngs: function () {
        return averageOrderObject_Icons();
    },

    version: function () {
        return "1.0.0";
    },

    sideModal: function () {
        return averageOrderObject_SideModalImage();
    },

    generateDisplayFieldData: function () {
        return AverageOrder_GenerateDisplayFieldData();
    },
    // Add Front/Back end data exports
};

// REQUIRES: State of front-end input
// EFFECTS: Returns true if all necessary fields / dependancies are filled out
const averageOrderObject_CheckInput = (entitiesMap) => {
    let passedCheck = true;
    const checkEntity = (entity) => {
        if (!entity.name) return false;
        if (!entity.cadence) return false;
        if (!entity.data.schedule.length) return false;
        return true;
    };
    Object.values(entitiesMap).every((entity) => {
        passedCheck = checkEntity(entity);
        return passedCheck;
    });
    return passedCheck;
};

// EFFECTS: Returns constant for event
const averageOrderObject_Constant = () => {
    return "43458d42-e564-4062-a9c4-02339238ce2a";
};

// EFFECTS: Returns svg for event
const averageOrderObject_Svg = () => {
    return averageOrderBlack;
};

// REQUIRES: props, or focus, or state
// EFFECTS: Returns component with data for eventNodeMenu
const averageOrderObject_EventNodeMenu = (focus) => {
    return <AverageOrderCard data={focus} />;
};

// EFFECTS: Returns the Index Text for event
const averageOrderObject_IndexText = () => {
    return "Select an input field to learn more about your group calculations.";
};

// EFFECTS: Returns all icons for event ??
const averageOrderObject_Icons = () => {
    let pngs = new Map([
        ["black", averageOrderBlack],
        ["blue", averageOrderBlack],
        ["white", averageOrderBlack],
        ["grey", averageOrderBlack],
    ]);
    return pngs;
};

// REQUIRES: minProps
// EFFECTS: Returns Input Component's prop passing container
const averageOrderObject_InputComponentInfoPassing = (minProps) => {
    return <AverageOrderInput {...minProps} />;
};

// EFFECTS: Returns tags, type, desc, used for, connect, connection image, learn more, templates for event
const averageOrderObject_OptionDescription = () => {
    const tags = ["Option"];
    const type = averageOrderObject.name();
    const description =
        "The Average Order Event provides a more fine-grained way to calculate revenue.";
    const usedForText = "Use this Event to create a spend profile";
    const connection = "";
    const connectionImage = debitCreditConnectionImage;
    const learnMoreLink =
        "https://bench.co/blog/bookkeeping/debits-credits/#:~:text=need%20to%20know.-,What%20are%20debits%20and%20credits%3F,entry%20method%20for%20their%20accounting.";
    const templates = [];
    let descMap = new Map([
        ["tags", tags],
        ["type", type],
        ["description", description],
        ["usedForText", usedForText],
        ["connection", connection],
        ["connectionImage", connectionImage],
        ["learnMoreLink", learnMoreLink],
        ["templates", templates],
    ]);
    return descMap;
};

// EFFECTS: Returns the side modal image that will be displayed in the create/edit event container
const averageOrderObject_SideModalImage = () => {
    return sideImage;
};

const AverageOrder_GenerateDisplayFieldData = () => {
    const entityFieldsMap = {};

    const entityDataFieldsMap = {};

    return { entityFieldsMap, entityDataFieldsMap };
};

import type { AppThunk } from "store";

export const SHOW_ONBOARDING_MODAL = "SHOW_ONBOARDING_MODAL";

export const openOnboardingModal = (): AppThunk<void> => {
    return (dispatch, _getState) => {
        dispatch({
            type: SHOW_ONBOARDING_MODAL,
            payload: true,
        });
    };
};

export const closeOnboardingModal = (): AppThunk<void> => {
    return (dispatch, _getState) => {
        dispatch({
            type: SHOW_ONBOARDING_MODAL,
            payload: false,
        });
    };
};

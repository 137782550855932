// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
    Description,
    SelectDropDown,
    Rate,
    InputButtons,
    EventEntityWrapper,
    EventDetails,
    EventHeader,
    ModalRow,
    ModalRowHalf,
    ModalRowOneThird,
    SingleEntity,
    ToggledContainer,
    ToggledContainerHalf,
    EntityName,
    SelectEntities,
    PercentValue,
    NoUnitValue,
    MuiCalendar,
} from "../Components";
import { customerTransferObject } from "Components/Registry/CustomerTransfer";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const modifierTypes = ["Percent change", "Add to value"];

const options = [
    "annually",
    "semi-annually",
    "quarterly",
    "monthly"
];

export default function CustomerTransferInputView({
    entitiesMap,
    currentEntity,
    handleAddDynamicValue,
    handleDeleteDynamicValue,
    onChangeDynamicValues,
    eventData,
    onChangeNameDescription,
    handleDynamicDate,
    handleChangeInterpolationType,
    passedCheck,
    onHandleSubmit,
    edit,
    handleEntityStateChange,
    handleOnChange,
    handleDateSelection,
}) {
    return (
        <EventEntityWrapper>
            <EventDetails>
                <EventHeader
                    name={eventData.name}
                    eventType={customerTransferObject.name()}
                    onChangeInput={onChangeNameDescription}
                    image={customerTransferObject.svg()}
                />
                <ModalRow>
                    <Description
                        description={eventData.description}
                        onChangeInput={onChangeNameDescription}
                        placeholder={"Description"}
                    />
                </ModalRow>
            </EventDetails>
            <SingleEntity>
                <ModalRow>
                    <EntityName
                        name={entitiesMap?.[currentEntity]?.name}
                        onChangeInput={handleOnChange}
                        entityData={entitiesMap?.[currentEntity]}
                        handleEntityStateChange={handleEntityStateChange}
                        required={true}
                    />
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <MuiCalendar id="startDate" value={entitiesMap?.[currentEntity]?.startDate} required={true} onChangeInput={handleDateSelection} label="Start Date" />
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <MuiCalendar id="endDate" value={entitiesMap?.[currentEntity]?.endDate} onChangeInput={handleDateSelection} label="End Date" helperText="An end date is optional" />
                    </ModalRowHalf>
                </ModalRow>
                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <div className="HeaderText">Source Customer</div>
                    </ModalRowHalf>
                    <ModalRowHalf>
                        <div className="HeaderText ">Target Customer</div>
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow twoInputs>
                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.data
                                    ?.selectedSourceCustomer ?? {}
                            }
                            nodes={Object.values(
                                { ...entitiesMap?.[currentEntity]?.data?.customers, ...entitiesMap?.[currentEntity]?.data?.websiteVisitors } ?? []
                            )}
                            className="IncomeSelect"
                            id="sourceCustomer"
                            label="Select Customer"
                            required={true}
                        />
                    </ModalRowHalf>

                    <ModalRowHalf>
                        <SelectEntities
                            onChangeInput={handleOnChange}
                            showAll={false}
                            value={
                                entitiesMap?.[currentEntity]?.data
                                    ?.selectedTargetCustomer ?? {}
                            }
                            nodes={Object.values(
                                entitiesMap?.[currentEntity]?.data?.customers ||
                                []
                            )}
                            className="IncomeSelect"
                            id="targetCustomer"
                            label="Select Customer"
                            required={true}
                        />
                    </ModalRowHalf>
                </ModalRow>

                <ModalRow>
                    <SelectDropDown
                        onChangeInput={handleOnChange}
                        value={entitiesMap?.[currentEntity]?.data?.modType}
                        options={modifierTypes}
                        className="select-dropdown"
                        id="modType"
                        label="Modifier Type"
                        required={true}
                    />
                </ModalRow>

                <ToggledContainer>
                    <ToggledContainerHalf
                        id="constant"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data.subType ===
                            "constant"
                        }
                        onClick={handleChangeInterpolationType}
                        title="Constant Value"
                    />
                    <ToggledContainerHalf
                        id="dynamic"
                        isSelected={
                            entitiesMap?.[currentEntity]?.data.subType ===
                            "dynamic"
                        }
                        onClick={handleChangeInterpolationType}
                        title="Dynamic Value"
                    />
                </ToggledContainer>
                {entitiesMap?.[currentEntity]?.data.subType === "constant" ? (
                    <ModalRow twoInputs>
                        <ModalRowHalf>
                            <SelectDropDown
                                onChangeInput={handleOnChange}
                                value={entitiesMap?.[currentEntity]?.cadence}
                                options={options}
                                className="select-dropdown"
                                id="cadence"
                                label="Select Frequency"
                                required={true}
                            />
                        </ModalRowHalf>

                        <ModalRowHalf>
                            {entitiesMap?.[currentEntity]?.data?.modType ===
                                "Percent change" ? (
                                <PercentValue
                                    label="Amount"
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.value
                                    }
                                    id="value"
                                    required={true}
                                />
                            ) : (
                                <NoUnitValue
                                    label="Amount"
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.value
                                    }
                                    id="value"
                                    required={true}
                                />
                            )}
                        </ModalRowHalf>
                    </ModalRow>
                ) : (
                    <>
                        <ModalRow twoInputs>
                            <ModalRowHalf>
                                <SelectDropDown
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.interpolateSubType
                                    }
                                    options={[
                                        "Interpolate Growth Rate",
                                        "Step Function Growth Rate",
                                    ]}
                                    className="mlsInput"
                                    label="Select a Dynamic Growth Type"
                                    id="interpolateSubType"
                                    required={true}
                                />
                            </ModalRowHalf>
                            <ModalRowHalf>
                                <Rate
                                    label="Max Growth"
                                    onChangeInput={handleOnChange}
                                    value={
                                        entitiesMap?.[currentEntity]?.data
                                            ?.maxGrowth
                                    }
                                    id="maxGrowth"
                                    className="mlsInput"
                                    sign={true}
                                    required={true}
                                />
                            </ModalRowHalf>
                        </ModalRow>

                        <ModalRow>
                            <div className="PTTLabel">Specified Values:</div>
                        </ModalRow>
                        <DynamicPanel
                            specificPercentages={
                                entitiesMap?.[currentEntity]?.data
                                    ?.specificPercentages
                            }
                            handleDynamicDate={handleDynamicDate}
                            onChangeDynamicValues={onChangeDynamicValues}
                            handleAddDynamicValue={handleAddDynamicValue}
                            handleDeleteDynamicValue={handleDeleteDynamicValue}
                        />
                    </>
                )}
            </SingleEntity>
            <InputButtons
                edit={edit}
                updateValues={onHandleSubmit}
                onHandleSubmitValues={onHandleSubmit}
                onHandleSubmit={onHandleSubmit}
                passedCheck={passedCheck}
            />
        </EventEntityWrapper>
    );
}

function DynamicPanel({
    specificPercentages,
    handleDynamicDate,
    onChangeDynamicValues,
    handleAddDynamicValue,
    handleDeleteDynamicValue,
}) {
    return (
        <div>
            {specificPercentages.map((data, i) => {
                return (
                    <ModalRow twoInputs key={i}>
                        <ModalRowOneThird>
                            <SelectDropDown
                                onChangeInput={onChangeDynamicValues}
                                value={data.month}
                                options={months}
                                className="mlsInput"
                                mainId={data.id}
                                label="Select a Month"
                                id="month"
                                required={true}
                            />
                        </ModalRowOneThird>
                        <ModalRowOneThird>
                            <MuiCalendar id="year" value={data.year} required={true} dynamicId={data.id} onChangeInput={handleDynamicDate} label="Year" inputFormat="YYYY" disableOpenPicker={true} />
                        </ModalRowOneThird>
                        <ModalRowOneThird>
                            <Rate
                                label="Monthly Growth Rate"
                                onChangeInput={onChangeDynamicValues}
                                value={data.value}
                                id="value"
                                mainId={data.id}
                                className="mlsInput"
                                sign={true}
                                required={true}
                            />
                        </ModalRowOneThird>
                    </ModalRow>
                );
            })}
            <ModalRow>
                <div onClick={handleAddDynamicValue} className="textButton">
                    Add Another
                </div>
                {specificPercentages.length > 1 && (
                    <div
                        onClick={handleDeleteDynamicValue}
                        className="addButton"
                    >
                        Delete Recently Added
                    </div>
                )}
            </ModalRow>
        </div>
    );
}

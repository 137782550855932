import React, { Component } from "react";
import "./SignUp.css";
// import PersonalIcon from "../../Assets/_signUpIcons/personal.png";
import BusinessIcon from "../../Assets/_signUpIcons/updated-business.png";
import AgencyIcon from "../../Assets/_signUpIcons/updated-agency.png";
// import CloseIcon from "../../Assets/close.svg";
import RightPanel from "./RightPanel/RightPanel.js";
import VideoPanel from "./VideoPanel/VideoPanel.js";
// import PersonalIconOne from "../../Assets/_signUpIcons/guide.png";
// import PersonalIconTwo from "../../Assets/_signUpIcons/bulb.png";
// import PersonalIconThree from "../../Assets/_signUpIcons/calculator.png";
// import PersonalIconFour from "../../Assets/_signUpIcons/hand.png";
// import PersonalPlanImg from "../../Assets/_signUpIcons/personal-plan-img.png";
import BusinessIconOne from "../../Assets/_signUpIcons/business-icon-1.png";
import BusinessIconTwo from "../../Assets/_signUpIcons/business-icon-2.png";
import BusinessIconThree from "../../Assets/_signUpIcons/business-icon-3.png";
import BusinessIconFour from "../../Assets/_signUpIcons/business-icon-4.png";
import BusinessPlanImg from "../../Assets/_signUpIcons/updated-business-plan.png";
import AgencyIconOne from "../../Assets/_signUpIcons/agency-icon-1.png";
import AgencyIconTwo from "../../Assets/_signUpIcons/agency-icon-2.png";
import AgencyIconThree from "../../Assets/_signUpIcons/agency-icon-3.png";
import AgencyIconFour from "../../Assets/_signUpIcons/agency-icon-4.png";
import AgencyPlanImg from "../../Assets/_signUpIcons/updated-agency-plan.png";
import {
    SIGN_UP,
    LOG_IN,
    GUEST,
    // INDIVIDUAL,
    BUSINESS,
    AGENCY,
} from "../../helpers/constants";

export default class SignUpContainer extends Component {
    state = {
        selectedAccountType: "business",
        showVideo: false,
    };

    handleSelectAccountType = (accountType) => {
        this.setState({ selectedAccountType: accountType });
    };

    toggleShowVideo = () => {
        this.setState({ showVideo: !this.state.showVideo });
    };

    onClickSignIn = () => {
        this.props.close();
        this.props.showLogin(true, LOG_IN);
    };

    onClickSignUp = (buttonType) => {
        this.props.close();
        this.props.showLogin(true, SIGN_UP, buttonType);
    };

    handleContinueAsGuest = () => {
        this.props.close();
        this.props.showLogin(true, GUEST);
    };

    render() {
        let planDetails = {};
        // let personalAccountClass = "sign-up__left-panel-tab-wrapper";
        let businessAccountClass = "sign-up__left-panel-tab-wrapper";
        let agencyAccountClass = "sign-up__left-panel-tab-wrapper";

        switch (this.state.selectedAccountType) {
            // case "personal":
            //     personalAccountClass = "sign-up__left-panel-tab-wrapper-active";
            //     planDetails = {
            //         accountTitle: "whatifi Personal",
            //         accountDescription:
            //             "Build visual answers to your biggest 'what if I' questions.",
            //         videoLink: "https://www.youtube.com/embed/o9rL8K1oi6g",
            //         featureOneIcon: PersonalIconOne,
            //         featureOneTitle: "Step by Step",
            //         featureOneDescription:
            //             "Our templates guide you through some of life's most common big questions.",
            //         featureTwoIcon: PersonalIconTwo,
            //         featureTwoTitle: "Formula Free",
            //         featureTwoDescription:
            //             "We do the math. Taxes. Take home pay. Mortgage and interest rates. All of it.",
            //         featureThreeIcon: PersonalIconThree,
            //         featureThreeTitle: "No Spreadsheets",
            //         featureThreeDescription:
            //             "An easy to follow, visual roadmap of how your options in life are connected.",
            //         featureFourIcon: PersonalIconFour,
            //         featureFourTitle: "Make the Most",
            //         featureFourDescription:
            //             "We'll calculate which choices will maximize your financial situation.",
            //         planImg: PersonalPlanImg,
            //         buttonOneText: "Learn More whatifi Personal",
            //         buttonTwoText: "Start a Personal Account",
            //         buttonType: INDIVIDUAL,
            //         learnMoreLink: "https://subscribe.whatifi.io/for-families/",
            //         buttonsEnabled: true,
            //     };
            //     break;
            case "business":
                businessAccountClass = "sign-up__left-panel-tab-wrapper-active";
                planDetails = {
                    accountTitle: "whatifi for an Individual Business",
                    accountDescription: [
                        "Create clarity. ",
                        [
                            <span
                                key="highlightSpan2"
                                style={{ color: "#EA912F", fontWeight: "bold" }}
                            >
                                Visually
                            </span>,
                        ],
                        " build multiple “what if” financial scenarios for your business.",
                    ],
                    videoLink: "https://www.youtube.com/embed/o9rL8K1oi6g",
                    featureOneIcon: BusinessIconOne,
                    featureOneTitle: "Automate Your Cash Flow Projections",
                    featureOneDescription:
                        "Integrate with your bank and accounting packages. See how daily changes affect your business future.",
                    featureTwoIcon: BusinessIconTwo,
                    featureTwoTitle:
                        "Build “what if” Financial Models in Seconds",
                    featureTwoDescription:
                        "Tracking your acquisition, churn, growth and pricing options is daunting. We make it easy.",
                    featureThreeIcon: BusinessIconThree,
                    featureThreeTitle:
                        "Compare and Share Multiple Outcomes with Partners",
                    featureThreeDescription:
                        "Be your own CFO. Use our library of pre-built Events and templates to model your business. No MBA needed.",
                    featureFourIcon: BusinessIconFour,
                    featureFourTitle: "No Formulas. No Spreadsheets.",
                    featureFourDescription:
                        "Cloud-based Scenarios that you can share with your trusted network of professionals and stakeholders.",
                    planImg: BusinessPlanImg,
                    buttonOneText: "Learn More whatifi Business",
                    buttonTwoText: "Try whatifi for Free",
                    buttonType: BUSINESS,
                    learnMoreLink: "#",
                    buttonsEnabled: true,
                };
                break;
            case "agency":
                agencyAccountClass = "sign-up__left-panel-tab-wrapper-active";
                planDetails = {
                    accountTitle: "whatifi for an Agency",
                    accountDescription: [
                        "Create clarity. ",
                        [
                            <span
                                key="highlightSpan"
                                style={{ color: "#EA912F", fontWeight: "bold" }}
                            >
                                Visually
                            </span>,
                        ],
                        " build multiple “what if” financial scenarios for your clients.",
                    ],
                    videoLink: "https://www.youtube.com/embed/o9rL8K1oi6g",
                    featureOneIcon: AgencyIconOne,
                    featureOneTitle:
                        "Automate Your Cash Flow Projections and Scenarios",
                    featureOneDescription:
                        "Use our templates and pre-built Events to model scenarios visually. No formulas. No spreadsheets.",
                    featureTwoIcon: AgencyIconTwo,
                    featureTwoTitle:
                        "Build “what if” Financial Models in Seconds",
                    featureTwoDescription:
                        "Clearly communicate with your clients in a visual language that they can actually understand.",
                    featureThreeIcon: AgencyIconThree,
                    featureThreeTitle:
                        "Compare and Share Multiple Outcomes with Clients",
                    featureThreeDescription:
                        "Things change fast. Collect and update your client's data daily with minimal manual entry. 24/7.",
                    featureFourIcon: AgencyIconFour,
                    featureFourTitle:
                        "One Single Dashboard. Track Real-Time Updates and Alerts.",
                    featureFourDescription:
                        "Share and follow. All stakeholders can actively monitor actions, progress, and challenges. Together.",
                    planImg: AgencyPlanImg,
                    buttonOneText: "Learn More whatifi Agency",
                    buttonTwoText: process.env.NODE_ENV === "development" ? "Start an Agency Account" : "Agency Accounts Coming Soon",
                    buttonType: AGENCY,
                    learnMoreLink: "https://www.whatifi.io/",
                    buttonsEnabled: process.env.NODE_ENV === "development" ? true : false,
                };
                break;
        }

        return (
            <div className="sign-up-container">
                <div className="sign-up__wrapper">
                    {/* <div
                        className="sign-up__right-panel-close-wrapper"
                        onClick={this.props.close}
                    >
                        <img
                            className="sign-up__right-panel-close-icon"
                            src={CloseIcon}
                            alt="close icon"
                        />
                    </div> */}
                    {!this.state.showVideo ? (
                        <>
                            <div className="sign-up__left-panel-wrapper">
                                <div className="sign-up__left-panel-first-tab-wrapper">
                                    <div className="sign-up__left-panel-first-tab-content-wrapper">
                                        <h2 className="sign-up__left-panel-first-tab-title">
                                            Create an Account
                                        </h2>
                                        <div className="sign-up__left-panel-first-tab-text-wrapper">
                                            <span className="sign-up__left-panel-tab-text">
                                                Already have an account?
                                            </span>
                                            <span
                                                className="sign-up__left-panel-first-tab-text-cta"
                                                onClick={this.onClickSignIn}
                                            >
                                                Log In
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sign-up__left-panel-tabs-wrapper">
                                    {/* <div
                                        className={personalAccountClass}
                                        onClick={() =>
                                            this.handleSelectAccountType(
                                                "personal"
                                            )
                                        }
                                    >
                                        <div className="sign-up__left-panel-tab-content-wrapper">
                                            <div className="sign-up__left-panel-tab-header-wrapper">
                                                <div className="sign-up__left-panel-tab-icon-wrapper">
                                                    <img
                                                        className="sign-up__left-panel-tab-icon"
                                                        src={PersonalIcon}
                                                        alt="whatifi personal plans icon"
                                                    />
                                                </div>
                                                <div className="sign-up__left-panel-tab-title-wrapper">
                                                    <h3 className="sign-up__left-panel-tab-title">
                                                        whatifi Personal
                                                    </h3>
                                                    <span className="sign-up__left-panel-tab-plans-info-text">
                                                        3 plans starting $0
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div
                                        className={businessAccountClass}
                                        onClick={() =>
                                            this.handleSelectAccountType(
                                                "business"
                                            )
                                        }
                                    >
                                        <div className="sign-up__left-panel-tab-content-wrapper">
                                            <div className="sign-up__left-panel-tab-header-wrapper">
                                                <div className="sign-up__left-panel-tab-icon-wrapper">
                                                    <img
                                                        className="sign-up__left-panel-tab-icon"
                                                        src={BusinessIcon}
                                                        alt="whatifi business plans icon"
                                                    />
                                                </div>
                                                <div className="sign-up__left-panel-tab-title-wrapper">
                                                    <h3 className="sign-up__left-panel-tab-title">
                                                        whatifi Business
                                                    </h3>
                                                    <span className="sign-up__left-panel-tab-plans-info-text">
                                                        1 plan starting $50/month
                                                    </span>
                                                    <span className="sign-up__left-panel-tab-plans-info-text">
                                                        Try Business for free
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={agencyAccountClass}
                                        onClick={() =>
                                            this.handleSelectAccountType(
                                                "agency"
                                            )
                                        }
                                    >
                                        <div className="sign-up__left-panel-tab-content-wrapper">
                                            <div className="sign-up__left-panel-tab-header-wrapper">
                                                <div className="sign-up__left-panel-tab-icon-wrapper">
                                                    <img
                                                        className="sign-up__left-panel-tab-icon"
                                                        src={AgencyIcon}
                                                        alt="whatifi agency plans icon"
                                                    />
                                                </div>
                                                <div className="sign-up__left-panel-tab-title-wrapper">
                                                    <h3 className="sign-up__left-panel-tab-title">
                                                        whatifi Agency
                                                    </h3>
                                                    <span className="sign-up__left-panel-tab-plans-info-text">
                                                        1 plan starting $600/month
                                                    </span>
                                                    <span className="sign-up__left-panel-tab-plans-info-text">
                                                        Coming Soon
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="sign-up__left-panel-guest-tab-wrapper">
                                        <div className="sign-up__left-panel-tab-guest-wrapper">
                                            <span className="sign-up__left-panel-tab-plans-guest-text">
                                                Or
                                            </span>
                                            <span
                                                className="sign-up__left-panel-tab-plans-guest-text-cta"
                                                onClick={
                                                    this.handleContinueAsGuest
                                                }
                                            >
                                                Continue as a Guest
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <RightPanel
                                planDetails={planDetails}
                                toggleShowVideo={this.toggleShowVideo}
                                onClickSignUp={this.onClickSignUp}
                            />
                        </>
                    ) : (
                        <VideoPanel
                            planDetails={planDetails}
                            toggleShowVideo={this.toggleShowVideo}
                            onClickSignUp={this.onClickSignUp}
                        />
                    )}
                </div>
            </div>
        );
    }
}

import type { ChartGraphSchema } from "reducers/typesSchema/chartGraphSchema";

/**
 * Receives a ChartGraphSchema and returns a unique graph id as a string.
 *
 * The pattern is as so:
 * <monthlyCumulative>__<accountLedgerName>__<scenarioId>__<activeThreadId>__<threadIds>__<activeNodeId>__<nodeIds>
 */
export function getUniqueGraphId(
    chartGraphState: ChartGraphSchema & {
        selectedThreads: string[];
        activeThreadId: string;
    }
) {
    const {
        selectedAccounts,
        activeNodeId,
        activeThreadId,
        selectedThreads,
        monthlyCumulative,
        nodeIds,
        scenarioId,
    } = chartGraphState;

    // Step 1: Set up all strings as necessary before attaching strings together
    const selectedAccountsMap = Object.values(selectedAccounts ?? {});
    const threadIdsString = selectedThreads.join(":"); // eg. ["a", "b", "c"] => "a:b:c"
    const nodeIdsString = nodeIds.join(":");

    let uniqueGraphId = `${monthlyCumulative}__`;
    selectedAccountsMap.forEach((selectedAccount) => {
        uniqueGraphId += `${selectedAccount?.id}__`;
    });
    uniqueGraphId += `${scenarioId}__`;
    uniqueGraphId += `${activeThreadId}__${threadIdsString}__`;
    uniqueGraphId += `${activeNodeId}__${nodeIdsString}`;

    return uniqueGraphId;
}

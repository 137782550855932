const terms = [
    "1 year fixed closed",
    "2 year fixed closed",
    "3 year fixed closed",
    "4 year fixed closed",
    "5 year fixed closed",
    "7 year fixed closed",
    "10 year fixed closed",
    "1 year variable closed",
];

const amortization = [
    "5 years",
    "10 years",
    "15 years",
    "20 years",
    "25 years",
    "30 years",
];

const currentMortgageAmortization = [
    "1 year",
    "2 years",
    "3 years",
    "4 years",
    "5 years",
    "6 years",
    "7 years",
    "8 years",
    "9 years",
    "10 years",
    "11 years",
    "12 years",
    "13 years",
    "14 years",
    "15 years",
    "16 years",
    "17 years",
    "18 years",
    "19 years",
    "20 years",
    "21 years",
    "22 years",
    "23 years",
    "24 years",
    "25 years",
    "26 years",
    "27 years",
    "28 years",
    "29 years",
    "30 years",
];

const rates = {
    fixed: {
        oneyr: "3.64",
        twoyr: "3.39",
        threeyr: "3.89",
        fouryr: "3.94",
        fiveyr: "5.19",
        sevenyr: "5.69",
        tenyr: "6.10",
    },
    variable: {
        fiveyr: "3.60",
    },
};

export const getMortgageRate = (mortgageType, stateObject) => {
    let rate = stateObject["rate"];
    switch (mortgageType) {
        case "1 year fixed closed":
            rate = rates["fixed"]["oneyr"];
            break;
        case "2 year fixed closed":
            rate = rates["fixed"]["twoyr"];
            break;
        case "3 year fixed closed":
            rate = rates["fixed"]["threeyr"];
            break;
        case "4 year fixed closed":
            rate = rates["fixed"]["fouryr"];
            break;
        case "5 year fixed closed":
            rate = rates["fixed"]["fiveyr"];
            break;
        case "7 year fixed closed":
            rate = rates["fixed"]["sevenyr"];
            break;
        case "10 year fixed closed":
            rate = rates["fixed"]["tenyr"];
            break;
        case "5 year variable closed":
            rate = rates["variable"]["fiveyr"];
            break;
        default:
    }
    return rate;
};

export { rates, amortization, currentMortgageAmortization, terms };

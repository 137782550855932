import React, { Component } from "react";
import "./InputContainer.css";
import DeleteIcon from "../../Assets/_nodeIcons/delete.png";
import _ from "lodash";
import { throwError } from "../../helpers/swalError";
import {
    ValueOutlined,
    AccountDesc,
    SelectDropDown,
    Description,
} from "./Components";
import { getDefaultName } from "../../helpers";
import { Bank } from "../../helpers/constants";
import { bankAltObject } from "../Registry/BankAlt";
import { bankObject } from "../Registry/Bank";

const accountTypes = [
    "Chequing",
    "Saving",
    "RRSP",
    "RESP",
    "RDSP",
    "Investing",
    "Credit Card",
    "Other",
];

import * as uuid from "uuid";

class BankAlt extends Component {
    constructor(props) {
        super(props);

        const optionalProps = {};
        if (props.value) optionalProps.value = props.value;
        if (props.accounts) optionalProps.accounts = props.accounts;
        if (props.lastUpdated) optionalProps.lastUpdated = props.lastUpdated;

        const defaultState = BankAlt.getDefaultState(
            props.inflationRate,
            getDefaultName(Bank, props),
            props.baseline
        );
        this.state = props.edit
            ? {
                  ...defaultState,
                  ...props.editData,
                  ...optionalProps,
              }
            : {
                  ...defaultState,
                  ...optionalProps,
              };
    }

    static getDefaultState(inflationRate, name, isInBaseline) {
        return {
            isFilled: false,
            valid: false,
            name: name,
            accounts: [
                {
                    accountName: "",
                    accountType: "",
                    accountBalance: "",
                    id: uuid.v4(),
                },
            ],
            lastUpdated: "",
            value: "",
            tag: `@${bankObject.constant()}`,
            type: bankObject.constant(),
            rating: 0,
            inflation: false,
            description: "",
            baseline: isInBaseline,
            deleteAccountPrompt: false,
        };
    }

    addAccount = () => {
        let accountCounter = this.state.accounts.length;
        const newAccount = {
            accountName: `Bank Account ${accountCounter + 1}`,
            accountType: "",
            accountBalance: "",
            id: uuid.v4(),
        };
        const newAccounts = [...this.state.accounts, newAccount];
        this.setState({ accounts: newAccounts });
    };

    deleteAccountPrompt = () => {
        this.setState({ deleteAccountPrompt: true });
    };

    deleteAccount = (account) => {
        let today = new Date();
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
        ];
        const value = account.value;
        const id = account.id;
        const accounts = this.state.accounts;
        const dateStr = `${
            months[today.getMonth()]
        } ${today.getDate()}, ${today.getFullYear()}`;
        const newAccountsArray = [];

        for (let i = 0; i < accounts.length; i++) {
            if (accounts[i].id !== id) newAccountsArray.push(accounts[i]);
        }

        account.accountBalance = value;

        let totalBalance = 0;
        if (newAccountsArray.length > 0) {
            totalBalance = newAccountsArray
                .map((account) => {
                    if (account.accountType === "Credit Card") {
                        return parseInt(-account.accountBalance);
                    } else {
                        return parseInt(account.accountBalance);
                    }
                })
                .reduce((total, accountBalance) => {
                    if (!total) total = 0;
                    if (!accountBalance) accountBalance = 0;
                    if (isNaN(total + accountBalance)) return "0";
                    else return total + accountBalance;
                })
                .toString();
            this.setState({ accounts: newAccountsArray });
        } else {
            this.setState({
                accounts: [
                    {
                        accountName: "",
                        accountType: "",
                        accountBalance: "",
                        id: uuid.v4(),
                    },
                ],
            });
            totalBalance = "0";
        }

        this.setState({ value: totalBalance });
        this.props.deleteAccount(id);
        this.props.handleManualBank(accounts, dateStr, totalBalance);
    };

    onChangeInput = (accNum, e, id, star) => {
        let value = e.target.value;
        let today = new Date();
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
        ];
        const dateStr = `${
            months[today.getMonth()]
        } ${today.getDate()}, ${today.getFullYear()}`;
        this.setState({ lastUpdated: dateStr });
        let accounts = this.state.accounts;
        switch (id) {
            case "name":
                const finalString = _.camelCase(value);
                this.setState({
                    name: value,
                    tag: `@${finalString}`,
                });
                break;
            case "description":
                this.setState({ description: value });
                break;
            case "accName":
                accounts[accNum].accountName = value;
                this.setState({ accounts: accounts });
                this.props.handleManualBank(accounts, dateStr);
                break;
            case "accType":
                accounts[accNum].accountType = value;
                this.setState({ accounts: accounts });
                this.props.handleManualBank(accounts, dateStr);
                break;
            case "rating":
                this.setState({ rating: star });
                break;
            case "value":
                accounts[accNum].accountBalance = value;

                let totalBalance = 0;
                if (accounts.length > 0) {
                    totalBalance = accounts
                        .map((account) => {
                            if (account.accountType === "Credit Card") {
                                return parseInt(-account.accountBalance);
                            } else {
                                return parseInt(account.accountBalance);
                            }
                        })
                        .reduce((total, accountBalance) => {
                            if (!total) total = 0;
                            if (!accountBalance) accountBalance = 0;
                            if (isNaN(total + accountBalance)) return "0";
                            else return total + accountBalance;
                        })
                        .toString();
                    if (isNaN(totalBalance)) totalBalance = "0";
                    this.setState({ accounts: accounts });
                } else {
                    accounts = [
                        {
                            accountName: "",
                            accountType: "",
                            accountBalance: "",
                            id: uuid.v4(),
                        },
                    ];

                    this.setState({
                        accounts: accounts,
                    });
                    totalBalance = "0";
                }
                console.log(typeof totalBalance);
                console.log(totalBalance);
                this.setState({ value: totalBalance });
                this.props.handleManualBank(accounts, dateStr, totalBalance);
                break;
            default:
        }
    };

    onHandleClose = () => {
        this.props.onCloseModal();
    };

    updateValues = () => {
        const newState = {
            ...this.state,
            isFilled: true,
            valid: true,
        };
        this.props.updateValues(newState);
    };

    onHandleSubmitValues = () => {
        const newState = {
            ...this.state,
            isFilled: true,
            valid: true,
        };
        const { baseline, confirmBaselineAction } = this.props;
        if (bankAltObject.checkInput(this.state)) {
            if (baseline) {
                confirmBaselineAction(newState);
            } else {
                this.props.confirmAction(newState);
            }
        } else {
            throwError(
                "error",
                "Missing Input",
                "Please fill up all required inputs."
            );
        }
    };

    onHandleSubmit = () => {
        const newState = {
            ...this.state,
            isFilled: false,
            valid: false,
        };
        const { baseline, confirmBaselineAction } = this.props;

        if (baseline) {
            confirmBaselineAction(newState);
        } else {
            this.props.confirmAction(newState);
        }
    };

    updateInflation = (checked) => {
        this.setState({ inflation: checked });
    };

    render() {
        let showDeleteBtn;
        const { accounts, lastUpdated, description } = this.state;

        if (accounts.length > 1) showDeleteBtn = true;
        else showDeleteBtn = false;

        return (
            <div>
                <div className="cardContainer">
                    {accounts.map((account, i) => {
                        return (
                            <div key={account.id} className="MarginBottom">
                                <div className="ModalRowSpaceBetween PositionRelative">
                                    <div className="CounterElement">
                                        {i + 1}
                                    </div>

                                    <div className="twoThird">
                                        <div className="Required">*</div>
                                        <AccountDesc
                                            accNum={i}
                                            value={accounts[i].accountName}
                                            onChangeInput={(e, id, star) =>
                                                this.onChangeInput(
                                                    i,
                                                    e,
                                                    id,
                                                    star
                                                )
                                            }
                                            id="accName"
                                            label="Account Name"
                                        />
                                    </div>
                                    <div className="oneThird">
                                        <div className="Required">*</div>
                                        <SelectDropDown
                                            accNum={i}
                                            onChangeInput={(e, id, star) =>
                                                this.onChangeInput(
                                                    i,
                                                    e,
                                                    id,
                                                    star
                                                )
                                            }
                                            value={accounts[i].accountType}
                                            options={accountTypes}
                                            className="IncomeSelect"
                                            id="accType"
                                            label="Account Type"
                                        />
                                    </div>
                                </div>

                                <div className="ModalRowSpaceBetween">
                                    <div className="oneThird MarginRight">
                                        <div className="Required">*</div>
                                        <ValueOutlined
                                            accNum={i}
                                            label="Bank Balance"
                                            onChangeInput={(e, id, star) =>
                                                this.onChangeInput(
                                                    i,
                                                    e,
                                                    id,
                                                    star
                                                )
                                            }
                                            value={accounts[i].accountBalance}
                                            id="value"
                                        />
                                    </div>
                                    <div className="last-updated">
                                        Last Updated: {lastUpdated}
                                    </div>
                                    <div className="DeleteBankAccountIconWrapper">
                                        {showDeleteBtn && (
                                            <img
                                                className="DeleteBankAccountIcon"
                                                src={DeleteIcon}
                                                title="Delete Account"
                                                onClick={() =>
                                                    this.deleteAccount(account)
                                                }
                                                alt="delete account"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="ModalRow">
                    <span className="AddAccountBtn" onClick={this.addAccount}>
                        + Add Another Account
                    </span>
                </div>
                <div className="ModalRow">
                    <Description
                        description={description}
                        onChangeInput={this.onChangeInput}
                    />
                </div>
            </div>
        );
    }
}

export default BankAlt;
